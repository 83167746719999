import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { getI18nLocaleString } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";

interface PrintButtonProps {
    printableContainerId: string;
    context: CMSProvidedProperties;
}

const PrintButton: React.FC<PrintButtonProps> = ({ printableContainerId, context }) => {
    const convertCanvasToImage = (canvas: HTMLCanvasElement): HTMLImageElement => {
        const img = new Image();
        img.src = canvas.toDataURL("image/png");
        return img;
    };

    const handlePrintClick = () => {
        const printableElement = document.getElementById(printableContainerId);
        if (!printableElement) {
            context.alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "nothingToPrint") });
            return;
        }

        // Convert all canvas elements to images to ensure they print correctly
        const canvasElements = printableElement.querySelectorAll("canvas");
        canvasElements.forEach((canvas) => {
            const img = convertCanvasToImage(canvas as HTMLCanvasElement);
            canvas.parentNode?.replaceChild(img, canvas);
        });

        // Clone the modified content
        const clonedContent = printableElement.cloneNode(true) as HTMLElement;

        // Create a hidden iframe for printing
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        // Write the content to the iframe
        const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(`
                <html>
                    <head>
                        <title>${getI18nLocaleString(namespaceList.admin, "print")}</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                margin: 0;
                                padding: 20px;
                                box-sizing: border-box;
                            }
                            .print-button-wrapper, .button-print {
                                display: none;
                            }
                            @page {
                                margin: 20mm;
                            }
                            @media print {
                                body {
                                    zoom: 0.75;
                                }
                            }
                        </style>
                    </head>
                    <body>${clonedContent.innerHTML}</body>
                </html>
            `);
            iframeDoc.close();

            // Use requestAnimationFrame to ensure the content is fully loaded
            requestAnimationFrame(() => {
                iframe.contentWindow?.print();
                // Remove the iframe after printing
                requestAnimationFrame(() => document.body.removeChild(iframe));
            });
        } else {
            context.alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "printError") });
        }
    };

    return (
        <button onClick={handlePrintClick} className="button-print">
            {<FontAwesome name="print" />}
            {getI18nLocaleString(namespaceList.admin, "print")}
        </button>
    );
};

export default PrintButton;
