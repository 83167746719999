import { AddOn, AddonType, ApiCallOptions, Group, MxtsApiWrapper, PagedResult, ResourceType, SubjectQuantity } from "@maxxton/cms-mxts-api";
import { AddOnInfo, AddOnsWidgetOptions } from "./AddOns.types";
import { DATE_FORMAT, MXTS as MXTS_CONSTANTS } from "../../../utils/constants";
import { LocalizedContentBase, MultiSelectOptionNumberValue } from "@maxxton/cms-api";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../../mxts/selectOption.types";
import { SelectedAddOn, SelectedAddOnsState } from "../../../redux/reducers/add-ons/selectedAddOnsReducer";

import { AddOnFilterParams } from "./AddOnsContext";
import { DateUtil } from "../../../utils/date.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { MarketingGroupWithAddOns } from "../../../redux/reducers/add-ons/addOnsReducer";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { getAdminMxtsEnv } from "../../mxts";
import { getFlattenedMyEnvData } from "../../../redux/reducers/myEnv/myEnv.util";
import { getSubjectQuantitiesFromDynamicFilter } from "./add-on/view-styles/components/price/price.util";

export enum AddOnViewStyle {
    GRID = "GRID",
    LIST = "LIST",
}

export enum MarketingGroupViewStyle {
    NAME_COLLAPSIBLE = "NAME_COLLAPSIBLE",
    INFO_COLLAPSIBLE = "INFO_COLLAPSIBLE",
    NAME_BORDERED = "NAME_BORDERED",
}

export interface AddOnInfoSelectOption {
    text: string;
    value: AddOnInfo;
}

export interface LocalizedToggleButtonText extends LocalizedContentBase {
    open: string;
    close: string;
}

export interface LocalizedAddOnPrice extends LocalizedContentBase {
    freeAddOn: string;
    dynamicActivityPriceText?: string;
}

export const getMarketingGroups = async (mxtsApi: MxtsApiWrapper): Promise<NumberMultiSelectOption[]> => {
    const env: ApiCallOptions = await getAdminMxtsEnv();

    return mxtsApi
        .getGroups(env, {
            size: 100,
            type: "marketing",
            view: "detail",
            sort: "priority",
        })
        .catch(() => [])
        .then((result: PagedResult<Group>) => {
            const groups: Group[] = result.content;
            return groups.map((group: Group) => ({ value: group.groupId, text: group.name }));
        });
};

export const getAddonTypes = async (mxtsApi: MxtsApiWrapper): Promise<NumberMultiSelectOption[]> => {
    const env: ApiCallOptions = await getAdminMxtsEnv();

    return mxtsApi
        .addonTypes(env, {
            size: MXTS_CONSTANTS.PAGE_REQUEST_SIZE,
            view: "detail",
            sort: "addonTypeSettingId,DESC",
        })
        .catch(() => [])
        .then((result: PagedResult<AddonType>) => result.content.map((addonType: AddonType) => ({ value: addonType.addonTypeSettingId, text: addonType.name })));
};

export const sortMarketingGroupsWithAddOns = ({
    marketingGroupsWithAddOns,
    selectedMarketingGroupIds,
}: {
    marketingGroupsWithAddOns: MarketingGroupWithAddOns[];
    selectedMarketingGroupIds: number[];
}): MarketingGroupWithAddOns[] => {
    const orderedGroups: MarketingGroupWithAddOns[] = [];
    const unorderedGroups: MarketingGroupWithAddOns[] = marketingGroupsWithAddOns.filter((group) => !selectedMarketingGroupIds.includes(group.marketingGroup.groupId));
    selectedMarketingGroupIds.forEach((groupId: number) => {
        const marketingGroup: MarketingGroupWithAddOns | undefined = marketingGroupsWithAddOns.find((group) => group.marketingGroup.groupId === groupId);

        if (marketingGroup) {
            orderedGroups.push(marketingGroup);
        }
    });

    return orderedGroups.concat(unorderedGroups);
};

export const getSelectedAddOnsInsideCart = ({ selectedAddOnsState, cartReservedResourceId }: { selectedAddOnsState?: SelectedAddOnsState; cartReservedResourceId?: number }): SelectedAddOn[] =>
    selectedAddOnsState?.addOnCarts.find((addOnsCart) => addOnsCart.cartReservedResourceId === cartReservedResourceId)?.selectedAddOns || [];

export const getTempSelectedAddOnsInsideCart = ({ selectedAddOnsState, cartReservedResourceId }: { selectedAddOnsState?: SelectedAddOnsState; cartReservedResourceId?: number }): SelectedAddOn[] =>
    selectedAddOnsState?.tempAddOnCarts.find((addOnsCart) => addOnsCart.cartReservedResourceId === cartReservedResourceId)?.selectedAddOns || [];

export function convertDynamicFilterToAddOnFilterParams(dynamicFilter: DynamicFilter): AddOnFilterParams {
    const { cancellationPremiumId, startdate, enddate, unitid, resourceid, distributionChannel, rateType, reservationCategoryId, subject } = dynamicFilter;
    return {
        resourceId: resourceid,
        unitId: unitid,
        distributionChannelId: distributionChannel?.distributionChannelId ? +distributionChannel.distributionChannelId : distributionChannel?.distributionChannelId,
        rateTypeId: rateType?.rateTypeId,
        reservationCategoryId: reservationCategoryId ? +reservationCategoryId : reservationCategoryId,
        startDate: startdate,
        endDate: enddate,
        cancellationPremiumId,
        subjects: subject ? getSubjectQuantitiesFromDynamicFilter(subject) : undefined,
        reservationId: dynamicFilter.reservationCategoryId,
        currency: dynamicFilter.currency,
    };
}

export function convertMyEnvStateToAddOnFilterParams(params: { myEnvState: MyEnvState; dynamicFilter: DynamicFilter; subjectQuantities?: SubjectQuantity[] }): AddOnFilterParams {
    const { myEnvState, dynamicFilter, subjectQuantities } = params;
    const flattenedMyEnvData = getFlattenedMyEnvData(myEnvState, dynamicFilter);
    const { startDate, endDate } = flattenedMyEnvData;
    return {
        ...flattenedMyEnvData,
        startDate: startDate && DateUtil.reFormatDate(startDate, DATE_FORMAT.MXTS, DATE_FORMAT.DEFAULT),
        endDate: endDate && DateUtil.reFormatDate(endDate, DATE_FORMAT.MXTS, DATE_FORMAT.DEFAULT),
        subjects: subjectQuantities,
        rateTypeId: dynamicFilter.rateType?.rateTypeId,
        cancellationPremiumId: undefined,
        currency: dynamicFilter.currency,
    };
}

export const filterMarketingGroupsWithAddOnsByWidgetOptions = ({
    marketingGroupsWithAddOns,
    options,
}: {
    marketingGroupsWithAddOns?: MarketingGroupWithAddOns[];
    options: AddOnsWidgetOptions;
}): MarketingGroupWithAddOns[] => {
    let filteredMarketingGroupsWithAddOns: MarketingGroupWithAddOns[] = marketingGroupsWithAddOns || [];

    if (options.selectedMarketingGroups.length) {
        const selectedMarketingGroupIds: number[] = options.selectedMarketingGroups.map((group: MultiSelectOptionNumberValue) => group.value);

        if (options.onlyDisplaySelectedMarketingGroups) {
            filteredMarketingGroupsWithAddOns = filteredMarketingGroupsWithAddOns?.filter((group: MarketingGroupWithAddOns) => selectedMarketingGroupIds.includes(group.marketingGroup.groupId));
        }

        if (options.overrideMarketingGroupOrder) {
            filteredMarketingGroupsWithAddOns = sortMarketingGroupsWithAddOns({ marketingGroupsWithAddOns: filteredMarketingGroupsWithAddOns, selectedMarketingGroupIds });
        }
    }

    const allowedResourceTypes: ResourceType[] = getAllowedResourceTypes(options);
    if (allowedResourceTypes.length) {
        filteredMarketingGroupsWithAddOns = filteredMarketingGroupsWithAddOns
            .filter((group: MarketingGroupWithAddOns) => group.addOns.find((addOn: AddOn) => allowedResourceTypes.includes(addOn.type)))
            .map((group: MarketingGroupWithAddOns) => ({
                ...group,
                addOns: group.addOns.filter((addOn: AddOn) => allowedResourceTypes.includes(addOn.type)),
            }));
    }

    return filteredMarketingGroupsWithAddOns;
};

export const filterAddOnsByWidgetOptions = ({
    addOns,
    options,
    marketingGroupsWithAddOns,
}: {
    options: AddOnsWidgetOptions;
    addOns?: AddOn[];
    marketingGroupsWithAddOns?: MarketingGroupWithAddOns[];
}): AddOn[] => {
    if (!addOns || !marketingGroupsWithAddOns) {
        return [];
    }

    let filteredAddOns: AddOn[] = addOns || [];

    const allowedResourceTypes: ResourceType[] = getAllowedResourceTypes(options);
    if (allowedResourceTypes.length) {
        filteredAddOns = addOns.filter((addOn: AddOn) => allowedResourceTypes.includes(addOn.type));
    }

    if (options.onlyShowCancellationFund) {
        filteredAddOns = filteredAddOns?.filter((addOn: AddOn) => addOn.extraCancelPremium) || [];
    } else if (options.selectedMarketingGroups.length) {
        filteredAddOns = [];
        const filteredMarketingGroupsWithAddOns: MarketingGroupWithAddOns[] = filterMarketingGroupsWithAddOnsByWidgetOptions({ marketingGroupsWithAddOns, options });
        filteredMarketingGroupsWithAddOns.forEach((group: MarketingGroupWithAddOns) => filteredAddOns.push(...group.addOns));
    }

    return filteredAddOns;
};

export const getAllowedResourceTypes = (options: AddOnsWidgetOptions): ResourceType[] =>
    options.displayedResourceTypes?.map((selectedType: StringMultiSelectOption) => selectedType.value as ResourceType) || [];
