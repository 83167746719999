import { DATE_FORMAT, MXTS } from "../utils/constants";
import { MxtsApi, ReservedResource, ResourceType } from "@maxxton/cms-mxts-api";

import { ApiContext } from "../containers/cmsProvider.types";
import { DateUtil } from "../utils/date.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { getMxtsEnv } from "../plugins/mxts";
import { uniq } from "lodash";

export async function getDynamicFilterFromReservation(dynamicFilter: DynamicFilter, context: ApiContext): Promise<Partial<DynamicFilter>> {
    const reservationId = dynamicFilter.reservationId;
    const updatedDynamicFilter: Partial<DynamicFilter> = {};
    if (reservationId) {
        const env = await getMxtsEnv(context, dynamicFilter.currentLocale);
        const reservation = await context.mxtsApi.getReservation(env, {}, [
            {
                key: "reservationId",
                value: reservationId,
            },
        ]);
        const reservedResources: ReservedResource[] = (await MxtsApi.getReservedResources(env, { size: MXTS.MAX_RESULTS, reservationId })).content || [];
        const resourceActivities = getReservedResourcesByType(reservedResources, ResourceType.RESOURCEACTIVITY);
        const resourceActivityDetailsIds = uniq(
            resourceActivities
                .map((reservedResource) => reservedResource.resourceActivityDetailsId)
                .filter((resourceActivityDetailsId): resourceActivityDetailsId is number => !!resourceActivityDetailsId)
        );
        updatedDynamicFilter.resourceActivityDetailsIds = resourceActivityDetailsIds;
        updatedDynamicFilter.resourceActivityDetailsId = resourceActivityDetailsIds?.[0];

        const accoTypes = getReservedResourcesByType(reservedResources, ResourceType.ACCOMMODATIONTYPE);
        const resourceIds = uniq(accoTypes.map((reservedResource) => reservedResource.resourceId).filter((resourceId): resourceId is number => !!resourceId));
        const accoTypeResortIds = uniq(accoTypes.map((reservedResource) => reservedResource.resource?.resortId).filter((resortId): resortId is number => !!resortId));
        const resourceActivitiesResortIds = uniq(resourceActivities.map((reservedResource) => reservedResource.resource?.resortId).filter((resortId): resortId is number => !!resortId));
        updatedDynamicFilter.resourceids = resourceIds;
        updatedDynamicFilter.resortids = accoTypeResortIds.length ? accoTypeResortIds : resourceActivitiesResortIds;

        const accoType = accoTypes.length === 1 ? accoTypes[0] : undefined;
        if (accoType) {
            updatedDynamicFilter.resourceid = accoType.resourceId || undefined;
            updatedDynamicFilter.unitid = accoType.unitId || undefined;
        }

        if (reservation) {
            updatedDynamicFilter.startdate = reservation.arrivalDate ? DateUtil.reFormatMxtsDate(reservation.arrivalDate, DATE_FORMAT.DISPLAY) : undefined;
            updatedDynamicFilter.enddate = reservation.departureDate ? DateUtil.reFormatMxtsDate(reservation.departureDate, DATE_FORMAT.DISPLAY) : undefined;
            if (reservation.distributionChannelId) {
                const distributionChannel = await context.mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: reservation.distributionChannelId }]);
                if (distributionChannel) {
                    updatedDynamicFilter.distributionChannel = { distributionChannelId: reservation.distributionChannelId, code: distributionChannel.code };
                }
            }
            updatedDynamicFilter.reservationCategoryId = reservation.reservationCategoryId;
        }
    }
    return updatedDynamicFilter;
}

function getReservedResourcesByType(reservedResources: ReservedResource[], resourceType: ResourceType): ReservedResource[] {
    return reservedResources.filter((reservedResource) => reservedResource.type === resourceType);
}
