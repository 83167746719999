import * as moment from "moment";

import { ApiContext, CMSProviderProperties } from "../containers/cmsProvider.types";
import { DATE_FORMAT, MS, MXTS_DATE_FORMATS } from "./constants";

import { ApiCallOptions } from "@maxxton/cms-mxts-api";
import { getMxtsEnv } from "../plugins/mxts/index";
import { tz } from "moment-timezone";

export interface DateFormatOptions {
    nullifyTimeStamp?: boolean;
    convertToUTC?: boolean;
}

/**
 * Contains date utility methods
 */
export class DateUtil {
    private static readonly DEFAULT_CONCERN_TIME_ZONE = "Europe/Amsterdam";
    private static CONCERN_TIME_ZONE: string | undefined;

    static async getConcernTimeZone(apiContext: ApiContext): Promise<string> {
        if (!DateUtil.CONCERN_TIME_ZONE) {
            const env: ApiCallOptions = await getMxtsEnv(apiContext);
            DateUtil.CONCERN_TIME_ZONE = (await apiContext.mxtsApi.getConcernTimeZone(env, {})) || DateUtil.DEFAULT_CONCERN_TIME_ZONE;
            // Clear the cache after 1 hour
            setTimeout(() => {
                DateUtil.CONCERN_TIME_ZONE = undefined;
                DateUtil.getConcernTimeZone(apiContext);
            }, 1000 * 60 * 60);
        }
        return DateUtil.CONCERN_TIME_ZONE || DateUtil.DEFAULT_CONCERN_TIME_ZONE;
    }

    /**
     * Converts the date to the same time in a different timezone.
     *
     * Be aware that:
     * 1 dec 20:00 in Hawaii is the same point in time as
     * 2 dec 06:00 in Amsterdam(Concern timeZone)
     *
     * This method will convert
     * 1 dec 20:00 in Hawaii(User timeZone) to
     * 1 dec 20:00 in Amsterdam(Concern timeZone)
     * So the returned date will be a different point in time
     */
    static async convertToConcernTimeZone(apiContext: ApiContext, date: Date): Promise<Date> {
        const userTimeZoneDateString: string = moment(date).format(DATE_FORMAT.MXTS_DATETIME);
        return tz(userTimeZoneDateString, await DateUtil.getConcernTimeZone(apiContext)).toDate();
    }

    static createRangeOfDates(fromDate: Date, untilDate: Date): Date[] {
        if (DateUtil.isDateDayTheSame(fromDate, untilDate)) {
            return [fromDate];
        }

        const dates: Date[] = [];

        const currDate: moment.Moment = moment(fromDate).startOf("day");
        let currDateMs = currDate.toDate().getTime();
        const lastDateMs = moment(untilDate).startOf("day").toDate().getTime();

        while (currDateMs < lastDateMs) {
            const date = currDate.toDate();
            dates.push(date);
            currDateMs = date.getTime();
            currDate.add(1, "days");
        }
        return dates;
    }

    static getHoursBetween(date1: Date, date2: Date): number {
        return Math.abs(date1.getTime() - date2.getTime()) / MS.ONE_HOUR;
    }

    static getDaysBetween(date1: Date, date2: Date): number {
        const moment1 = moment(date1);
        const moment2 = moment(date2);
        return Math.abs(moment2.diff(moment1, "days"));
    }

    static isDateDayTheSame(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }

    static getNullifiedTimeStamp(date: Date): Date {
        const dateClone = new Date(date.getTime());
        dateClone.setHours(0, 0, 0, 0);
        return dateClone;
    }

    static parseDate(date: string, dateFormat: DATE_FORMAT = DATE_FORMAT.DEFAULT): Date {
        return moment(date, dateFormat).toDate();
    }

    static parseMxtsDate(date: string): Date {
        return moment(date, MXTS_DATE_FORMATS, true).toDate();
    }

    static formatDate(date: Date, dateFormat: string, options?: DateFormatOptions): string {
        let momentDate = moment(options?.nullifyTimeStamp ? this.getNullifiedTimeStamp(date) : date);
        if (options?.convertToUTC) {
            momentDate = momentDate.utc();
        }
        return momentDate.format(dateFormat);
    }

    static reFormatDate(formattedDate: string, currentDateFormat: DATE_FORMAT, targetDateFormat: DATE_FORMAT, options?: DateFormatOptions) {
        return this.formatDate(this.parseDate(formattedDate, currentDateFormat), targetDateFormat, options);
    }

    static reFormatMxtsDate(formattedDate: string, targetDateFormat: DATE_FORMAT, options?: DateFormatOptions) {
        return this.formatDate(this.parseMxtsDate(formattedDate), targetDateFormat, options);
    }

    /**
     * Converts the input dateString to the mxts dateformat taking the concern timezone into account.
     *
     * Date's with a timestamp received from mxts are in the UTC timezone.
     * We need to convert them into the concern timezone before converting them to YYYY-MM-DD format.
     * See this example:
     *   Obtained from mxts: "2021-08-08T22:00:00" (UTC timezone) (is actually "2021-08-09T00:00:00" in concern timezone).
     *   If we convert it to YYYY-MM-DD then we need to make sure it's formatted as 2021-08-09 instead of 2021-08-08.
     */
    static async getMXTSDateString(apiContext: ApiContext, dateString: string, dateFormat?: string): Promise<string> {
        let utcDate;
        if (!dateFormat) {
            if (dateString?.length === 8) {
                utcDate = tz(dateString, DATE_FORMAT.DEFAULT, "UTC");
            } else {
                utcDate = tz(dateString, "UTC");
            }
        } else {
            utcDate = tz(dateString, dateFormat, "UTC");
        }
        if (dateFormat === DATE_FORMAT.MXTS_DATETIME || dateString.length === DATE_FORMAT.MXTS_DATETIME.length || dateString.length === DATE_FORMAT.MXTS_DATETIME_UTC.length) {
            return utcDate.tz(await DateUtil.getConcernTimeZone(apiContext)).format(DATE_FORMAT.MXTS);
        }
        return utcDate.format(DATE_FORMAT.MXTS);
    }

    static async getMXTSDateTimeString(apiContext: ApiContext, date: Date | string): Promise<string> {
        const dateString = moment(date).format(DATE_FORMAT.MXTS_DATETIME);
        return tz(dateString, await DateUtil.getConcernTimeZone(apiContext))
            .utc()
            .format(DATE_FORMAT.MXTS_DATETIME);
    }

    static getEarliestDate(dates: Date[]): Date | null {
        return dates.reduce((accumulator, currentValue) => {
            if (accumulator == null) {
                return currentValue;
            } else if (currentValue == null) {
                return accumulator;
            }
            return accumulator! < currentValue ? accumulator : currentValue;
        }, null);
    }

    static getLatestDate(dates: Date[]): Date | null {
        return dates.reduce((accumulator, currentValue) => {
            if (accumulator == null) {
                return currentValue;
            } else if (currentValue == null) {
                return accumulator;
            }
            return accumulator! > currentValue ? accumulator : currentValue;
        }, null);
    }

    static async convertUTCStringToConcernDateTime(dateTime: string, context: CMSProviderProperties) {
        const concernTimeZone = await DateUtil.getConcernTimeZone(context);
        return moment.utc(dateTime).tz(concernTimeZone).format(DATE_FORMAT.LOCALE_TIME);
    }
}
