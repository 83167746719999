import * as fetch from "isomorphic-fetch";
import * as moment from "moment";

import { Action, ActionType } from "../redux/actions";
import {
    Amenity,
    AmenityLink,
    ApiCallOptions,
    BillLine,
    ChoiceReservedResource,
    ChoiceResourceType,
    ChoiceResult,
    Customer,
    DayAddonReservedResourceRequest,
    Imply,
    Instalment,
    MxtsApi,
    PagedResult,
    Person,
    RefreshInfotextRequest,
    ReservationRequest,
    ReservationResult,
    ReservationStatus,
    ReservationType,
    ReservationValidationStatus,
    ReservedResource,
    ReservedResourcePreferenceRequest,
    ReservedResourcePreferenceType,
    ReservedResourceResult,
    ReservedResourceWithChildren,
    Resource,
    ResourceType,
    SubjectQuantity,
    Unit,
    UnitDocument,
    VoucherDetailsResponse,
} from "@maxxton/cms-mxts-api";
import { AvailabilityUtil, SORTED_UNITS_AGGREGATION } from "../utils/availability.util";
import { BillChoice, BillState } from "../redux/reducers/billReducer";
import { BillUtil, getBillChoice, getEmptyBillChoice, updateReservedResourceSubjectQuantities } from "../utils/bill.util";
import { CreateReservationAction, LoadReservationReduxDataAction, ReservationAction, ReservationActionType, SetReservationAction, UpdateReservationAction } from "../redux/actions/reservationAction";
import { DisplayType, getDateSelectionFromDayProduct, isDayProduct } from "../plugins/dynamic/add-ons/add-on/addOn.util";
import { ForkEffect, actionChannel, all, call, put, select, take, takeEvery } from "redux-saga/effects";
import { MyEnvState, OwnerState } from "../redux/reducers/myEnv/myEnvState";
import { ProductDay, ProductSubjectSelection } from "../plugins/dynamic/additions/products/products.types";
import { SelectedAddOn, SelectedAddOnsState } from "../redux/reducers/add-ons/selectedAddOnsReducer";
import { flattenReservedResourcesTree, getAddOnReservedResourceRequestBodyBase, getReservedResourceByResourceId } from "../utils/reservation.util";
import { parseAddressContent, parseCustomerRequestBody } from "../utils/customer.util";

import { ActivityPlannerState } from "../redux/reducers/activityPlannerReducer";
import { AddOnSubject } from "../plugins/dynamic/add-ons/add-on/info-modal/modal-types/modalData.util";
import { AdditionState } from "../redux/reducers/additionReducer";
import { AdditionsUtil } from "../plugins/dynamic/additions/additions.util";
import { ArrayUtil } from "../utils/array.util";
import { AvailabilityState } from "../redux/reducers/availability.types";
import { Channel } from "redux-saga";
import { DATE_FORMAT } from "../utils/constants";
import { DateUtil } from "../utils/date.util";
import { DomainObjectUtil } from "../utils/domainobject.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { FilterChangeAction } from "../redux/actions/dynamicFilterAction.types";
import { InstalmentsState } from "../redux/reducers/instalmentsState";
import { OwnerBookChoiceSelection } from "../plugins/dynamic/owner/book/choiceSelection/ownerBookChoiceSelection.enum";
import { PriceTypes } from "../plugins/page/activityPlanner/activityPlanner.enum";
import { ReservationKind } from "../plugins/dynamic/reservation/reservation.enum";
import { ReservationState } from "../redux/reducers/reservationReducer";
import { SagaUtils } from "./SagaUtils";
import { SelectedAddition } from "../plugins/dynamic/additions/additions.types";
import { StringUtil } from "../utils/string.util";
import { TravelPartyType } from "../plugins/page/travel-party/travelparty.enum";
import { TravellerDetails } from "../plugins/page/travel-party";
import { dynamicFilterType } from "../redux/reducers/dynamicFilter.enum";
import { getDynamicFilterFromReservation } from "./reservationSaga.util";
import { getMainCustomerIdFromLoginToken } from "../redux/reducers/myEnv/myEnv.util";
import { getMxtsEnv } from "../plugins/mxts";
import { getSelectedAddOnsInsideCart } from "../plugins/dynamic/add-ons/addOns.util";
import { getValidMyEnvAuthToken } from "../utils/authToken.util";
import { getVoucherSetResource } from "../utils/voucher.util";
import { globalApiContext } from "../containers/CmsProvider";
import { globalLogger } from "@maxxton/cms-api";
import { onUnload } from "../components/utils";
import { reportError } from "../utils/report.utils";
import { travelPartyState } from "../redux/reducers/travelParty";
import { uniq } from "lodash";

// eslint-disable-next-line max-lines-per-function
function* createReservationDetails(action: CreateReservationAction) {
    window.addEventListener("beforeunload", onUnload);
    yield put({
        type: ActionType.Reservation,
        actionType: ReservationActionType.creating,
        payload: {},
    });
    const billState: BillState = yield select(SagaUtils.getBillState);
    const instalmentsState: InstalmentsState = yield select(SagaUtils.getInstalmentsState);
    const availabilityState: AvailabilityState = yield select(SagaUtils.getAvailabilityState);
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const additionState: AdditionState = yield select(SagaUtils.getAdditionState);
    const selectedAddOnsState: SelectedAddOnsState = yield select(SagaUtils.getSelectedAddOnsState);
    const selectedAddOns: SelectedAddOn[] = getSelectedAddOnsInsideCart({ selectedAddOnsState });
    const billLines: BillLine[] = yield select(SagaUtils.getBillLines);
    const billReservedResources: ChoiceReservedResource[] = yield select(SagaUtils.getBillReservedResources);
    const travelParty: travelPartyState = yield select(SagaUtils.getTravelPartyState);
    const filter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const activityPlannerState: ActivityPlannerState = yield select(SagaUtils.getActivityPlannerState);
    const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), filter.currentLocale);
    let reservationId: number | undefined;
    let memoManagerId: number | undefined;
    let memoCategoryId: number | undefined;
    let payingCustomer: Customer | undefined;
    if (action.payload.isOwnerBooking && !filter.unitid) {
        filter.unitid = myEnvState.ownerState?.selectedUnitId;
    }
    try {
        memoCategoryId = getMemoCategoryFromPayload(action);
    } catch (err) {
        // do nothing
    }
    try {
        if (!billLines.length || !billReservedResources.length) {
            throw new Error("Reservation creation failed because there is no bill!");
        }
        if (!filter.resourceid) {
            throw new Error("Reservation creation failed because there is no resourceId on the filter/url");
        }
        const resource: Resource = (yield call(MxtsApi.resources, env, { resourceIds: [filter.resourceid!] })).content[0];
        const { rateTypeId, autoAssignUnitToReservation, sortUnitsOnVSI, sourceId, reservationStatus, resourceActivityDetailsId } = action.payload;

        const customer: Customer = yield call(createCustomerForReservation, env, action, myEnvState);
        const { isPayingCustomer, payingCustomerReservationCategoryId } = action.payload;
        if (isPayingCustomer) {
            payingCustomer = yield call(createCustomerForReservation, env, action, myEnvState, isPayingCustomer);
        }
        const reservationKind = action.payload.kind;

        // create a reservation in mxts
        const reservationDate = moment().utc().format().replace("Z", "");
        let reservation: ReservationResult =
            action.payload.kind === ReservationKind.ADDON_RESERVATION && myEnvState.selectedReservation
                ? myEnvState.selectedReservation.reservation
                : yield call(createInitialReservation, env, filter, resource, reservationDate, customer, sourceId, payingCustomer, payingCustomerReservationCategoryId, reservationKind);
        reservationId = reservation.reservationId;
        memoManagerId = reservation.memoManagerId;
        fetch(`/reservation-failure-monitor?reservationId=${reservationId}&memoCategoryId=${memoCategoryId}`, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                "Access-Control-Allow-Origin": "*",
            },
        });

        yield call(addMemosToReservation, env, action, reservation, myEnvState, customer, !!action.payload.saveOnInvoice);

        const needsReallocation = yield call(isReallocationNeeded, { availabilityState, env, filter, autoAssignUnitToReservation, resource });

        // save all reserved resources in mxts
        const insertedReservedResources: ReservedResourceResult[] = yield addReservedResourcesToReservation({
            env,
            resource,
            filter: needsReallocation ? { ...filter, unitid: undefined } : filter,
            reservation,
            rateTypeId,
            billReservedResources,
            reservationDate,
            autoAssignUnitToReservation: needsReallocation ? false : autoAssignUnitToReservation,
            sortUnitsOnVSI,
            mainBill: getBillChoice(billState, "mainBill"),
            selectedAddOns,
            instalmentsState,
            additionState,
            resourceActivityDetailsId,
        });

        const activityReservedResources = billReservedResources.filter((reservedResource) => reservedResource.type === ChoiceResourceType.RESOURCEACTIVITY);
        const insertedActivityReservedResources: ReservedResource[] = yield call(insertActivityReservedResources, {
            env,
            reservationId,
            activityReservedResources,
            activityPlannerState,
            distributionChannelId: reservation.distributionChannelId,
        } as InsertActivityReservedResourcesParams);
        insertedReservedResources.push(...insertedActivityReservedResources);

        const accoTypeReservedResource: ReservedResourceResult | undefined = insertedReservedResources.find((reservedResource) => reservedResource.resourceId === filter.resourceid);
        if (!accoTypeReservedResource) {
            throw Error("Failed to insert the accoType reservedResource");
        }
        yield call(addDayProductsToReservation, {
            env,
            parentReservedResourceId: accoTypeReservedResource.reservedResourceId,
            reservationId: reservation.reservationId,
            selectedAddOnsState,
            additionState,
            reservation,
        });

        // Reallocate unit
        if (needsReallocation && filter.startdate && filter.enddate) {
            yield call(handleUnitReallocation, {
                env,
                resourceId: resource.resourceId,
                distributionChannelId: filter.distributionChannel?.distributionChannelId,
                filter,
                autoAssignUnitToReservation,
                startDate: DateUtil.reFormatDate(filter.startdate, DATE_FORMAT.DEFAULT, DATE_FORMAT.MXTS),
                endDate: DateUtil.reFormatDate(filter.enddate, DATE_FORMAT.DEFAULT, DATE_FORMAT.MXTS),
                reservationId,
                reservedResourceId: accoTypeReservedResource.reservedResourceId,
            } as HandleUnitReallocationParams);
        }

        yield addPreferencesToReservation(env, accoTypeReservedResource, filter, reservation);
        yield addAmenitiesToReservation(env, accoTypeReservedResource, filter, reservation);
        yield redeemVouchers(env, filter, reservation);

        reservation = yield call(MxtsApi.patchReservation, env, { status: billState.mainBill?.preBooking ? ReservationStatus.PRE_BOOKING : reservationStatus }, [
            { key: "reservationId", value: reservation.reservationId },
        ]);

        yield call(updateTravelParty, { travelParty, action, myEnvState, filter, env, reservationId: reservation.reservationId, customer, accoTypeReservedResource });

        if (filter.selectedActivities?.[0].resourceActivity.resortActivity.priceType === PriceTypes.FREE) {
            reservation = yield call(MxtsApi.patchReservation, env, { status: ReservationStatus.DEFINITIVE }, [{ key: "reservationId", value: reservation.reservationId }]);
        }

        // if booking for owner own use then will sign the reservation automatically
        // and automatically set the validationStatus to valid
        if (myEnvState.ownerState?.selectedBookingChoice === OwnerBookChoiceSelection.OWN_USE) {
            const ownerFullName = `${customer.firstName} ${customer.lastName}`;
            reservation = yield call(
                MxtsApi.patchReservation,
                env,
                { validationStatus: ReservationValidationStatus.VALID, tncSigned: true, tncSigneeCustomerName: ownerFullName, tncSignedDate: reservationDate },
                [{ key: "reservationId", value: reservation.reservationId }]
            );
        }

        yield checkIfBillRecalculationIsNeeded(env, reservation, billState.mainBill);

        const reservationAction: ReservationAction = {
            type: ActionType.Reservation,
            actionType: ReservationActionType.created,
            payload: {
                customer,
                reservation,
                reservedResource: accoTypeReservedResource,
            },
        };
        const filterChangeAction: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.addReservationId,
            payload: { reservationId: reservation.reservationId },
        };
        yield put(filterChangeAction);
        yield put(reservationAction);
    } catch (error) {
        reportError(error, undefined, `Error during reservation creation for stateUuid: ${filter.stateUuid} ReservationId: ${reservationId} `);
        /*
          Decline the failed reservation.
          The user assumes reservation creation failed (because we don't continue to resultPage but show an error message instead) and it's most likely stuck in initial state anyway.
          Also we make sure to do this at server side. Because if it fails(because the reservation is locked for example),
          then we want to retry it but that won't be possible if the user already closed the tab
        */
        if (reservationId) {
            fetch(`/decline-reservation?reservationId=${reservationId}&memoManagerId=${memoManagerId}&memoCategoryId=${memoCategoryId}`, {
                method: "POST",
                body: StringUtil.convertErrorObjToString(error)?.substring(0, 500),
                headers: {
                    "Access-Control-Allow-Headers": "X-Requested-With",
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "text/plain",
                },
            });
        }

        const reservationAction: ReservationAction = {
            type: ActionType.Reservation,
            actionType: ReservationActionType.error,
            payload: {
                error: error instanceof Error ? { message: error.message, name: error.name, stack: error.stack } : error,
            },
        };
        yield put(reservationAction);
    }
    window.removeEventListener("beforeunload", onUnload);
}

interface HandleUnitReallocationParams {
    env: ApiCallOptions;
    resourceId: number;
    distributionChannelId: number;
    autoAssignUnitToReservation: boolean;
    filter: DynamicFilter;
    startDate: string;
    endDate: string;
    reservationId: number;
    sortUnitsOnVSI: boolean;
    reservedResourceId: number;
}

interface InsertActivityReservedResourcesParams {
    env: ApiCallOptions;
    reservationId: number;
    distributionChannelId: number;
    activityReservedResources: ChoiceReservedResource[];
    activityPlannerState: ActivityPlannerState;
}

async function updateTravelParty({
    travelParty,
    action,
    myEnvState,
    filter,
    env,
    reservationId,
    customer,
    accoTypeReservedResource,
}: {
    travelParty: travelPartyState;
    action: CreateReservationAction;
    myEnvState: MyEnvState;
    filter: DynamicFilter;
    env: ApiCallOptions;
    reservationId: number;
    customer: Customer;
    accoTypeReservedResource: ReservedResourceResult;
}): Promise<void> {
    if (travelParty.travelPartyDetails?.length && !(action.payload.kind === ReservationKind.ADDON_RESERVATION && myEnvState.selectedReservation)) {
        const context = globalApiContext();
        const travelPartyDetails = travelParty.travelPartyDetails;
        const currentTravelParty: Person[] = await context.mxtsApi.getReservationPeople(env, { customerId: customer.customerId }, [{ key: "reservationId", value: reservationId }]);
        if (currentTravelParty.length) {
            let updatedTravelParty = currentTravelParty.map((travelPartyMember, index) => {
                if (index === 0) {
                    return { ...travelPartyMember, action: "UPDATE" };
                }
                const correspondingPartyMember = travelPartyDetails[index - 1];
                return { ...travelPartyMember, ...correspondingPartyMember, action: "UPDATE" };
            });
            context.mxtsApi.setTravelParty(env, { travelPartyMembers: updatedTravelParty }, [{ key: "reservationId", value: reservationId }]);
            if (updatedTravelParty.find((travelPartyMember: TravellerDetails) => travelPartyMember.vehicleRegistrationMark)) {
                updatedTravelParty = updatedTravelParty.map((travelPartyDetails) => ({
                    ...travelPartyDetails,
                    type: TravelPartyType.VEHICLE,
                }));
                context.mxtsApi.setTravelParty(env, { travelPartyMembers: updatedTravelParty }, [{ key: "reservationId", value: reservationId }]);
            }
        }
    }
}

async function isReallocationNeeded(params: {
    filter: DynamicFilter;
    resource: Resource;
    autoAssignUnitToReservation: boolean;
    availabilityState: AvailabilityState;
    env: ApiCallOptions;
}): Promise<boolean> {
    const { env, resource, availabilityState, filter, autoAssignUnitToReservation } = params;
    const isTypeLevelBooking = !filter.unitid && !autoAssignUnitToReservation;
    if (isTypeLevelBooking || !resource.reallocationEnabled) {
        return false;
    }
    if (availabilityState.availabilityResult?.response.units?.[0]) {
        return !!availabilityState.availabilityResult?.response.units?.[0]?.reallocatable;
    }
    if (availabilityState.availabilityResult?.response.sortedUnits?.[0]) {
        return !!availabilityState.availabilityResult?.response.sortedUnits?.[0]?.reallocatable;
    }
    const unitAvailability = await AvailabilityUtil.getAvailabilityByDynamicFilter(
        filter,
        {
            customAggregations: [SORTED_UNITS_AGGREGATION],
        },
        globalApiContext()
    );
    return !!unitAvailability.availabilityResult?.response.sortedUnits?.[0]?.reallocatable;
}

async function handleUnitReallocation(params: HandleUnitReallocationParams, prioUnits?: number[]) {
    const { filter, sortUnitsOnVSI, autoAssignUnitToReservation, distributionChannelId, startDate, endDate, env, resourceId, reservedResourceId, reservationId } = params;

    if (!filter.unitid && autoAssignUnitToReservation) {
        if (!prioUnits) {
            prioUnits = await getPrioUnitsForReservation(filter, sortUnitsOnVSI);
        }
    }
    const highestPriorityUnitId: number | undefined = getHighestPriorityUnit({ filter, autoAssignUnitToReservation, prioUnits });
    if (!filter.unitid && !highestPriorityUnitId) {
        throw Error("There is no bookable unit to reallocate for...");
    }
    try {
        await MxtsApi.handleUnitReallocation(env, {
            distributionChannelId,
            resourceId,
            unitId: filter.unitid || highestPriorityUnitId || -1,
            startDate,
            endDate,
            reservationId,
            reservedResourceId,
        });
    } catch (err) {
        if ((err.status === 500 || err.status === 400) && autoAssignUnitToReservation && !filter.unitid) {
            // eslint-disable-next-line no-console
            console.debug(`unit reallocation failed. PrioUnit ${highestPriorityUnitId} might already be booked, retrying with first one in the list:`, prioUnits);
            await handleUnitReallocation(params, prioUnits);
        } else {
            throw err;
        }
    }

    if (autoAssignUnitToReservation && !filter.unitid && highestPriorityUnitId && filter.startdate && filter.enddate) {
        const accoTypeHasUnitImply = await accoTypeHasUnitImplies(
            {
                unitId: highestPriorityUnitId,
                startDate: DateUtil.reFormatDate(filter.startdate, DATE_FORMAT.DEFAULT, DATE_FORMAT.MXTS),
                endDate: DateUtil.reFormatDate(filter.enddate, DATE_FORMAT.DEFAULT, DATE_FORMAT.MXTS),
            },
            env
        );
        if (accoTypeHasUnitImply) {
            // The mandatory unit implies are not added yet. We simply recalculate the bill to make sure they are added.
            await MxtsApi.recalculateBill(env, {}, [{ key: "reservationId", value: reservationId }]);
        }
    }
}

async function accoTypeHasUnitImplies(reservedResource: { unitId: number; startDate: string; endDate: string }, env: ApiCallOptions): Promise<boolean> {
    const unit = await DomainObjectUtil.getUnitById(MxtsApi, reservedResource.unitId, env);
    if (unit?.implicationManagerId) {
        const unitImplies: PagedResult<Imply> = await MxtsApi.getImplies(env, {
            size: 1,
            implicationManagerId: unit.implicationManagerId,
            unitImply: true,
            fromDate: reservedResource.startDate,
            tillDate: reservedResource.endDate,
        });
        return !!unitImplies?.totalElements;
    }
    return false;
}

// TODO: add proper types for Customer and Address in cms-mxts-api
function* createCustomerForReservation(env: ApiCallOptions, action: CreateReservationAction, myEnvState: MyEnvState, isPayingCustomer?: boolean): any {
    const { password, login } = action.payload;
    const { ownerState } = myEnvState;

    const isCustomerAuthenticated = !!(yield call(getValidMyEnvAuthToken));
    // If user logged in then will not create a new customer id and will use the logged in customer data and Id
    if (isCustomerAuthenticated && ownerState?.selectedBookingChoice !== OwnerBookChoiceSelection.GUEST_OWN_UNIT) {
        return yield call(getLoginCustomerData, ownerState, env);
    }

    const addressContent = parseAddressContent(action, isPayingCustomer);

    const customerRequestBody = parseCustomerRequestBody(addressContent, action, isPayingCustomer);
    // create customer in mxts
    const customer: Customer = yield call(MxtsApi.createCustomer as any, env, { ...customerRequestBody });

    // create address in mxts
    yield call(MxtsApi.createAddress as any, env, { ...addressContent, managerId: customer.mailAddressManagerId });

    return customer;
}

const getLoginCustomerData = async (ownerState: OwnerState | undefined, env: ApiCallOptions) => {
    const mainCustomerId = await getMainCustomerIdFromLoginToken();
    if (mainCustomerId) {
        const customerDetails: Customer = await MxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: mainCustomerId }]);
        return customerDetails;
    }
};

function* createInitialReservation(
    env: ApiCallOptions,
    filter: DynamicFilter,
    resource: Resource,
    reservationDate: string,
    customer: Customer,
    sourceId?: string,
    payingCustomer?: Customer,
    payingCustomerReservationCategoryId?: number,
    kind?: string
): ReservationResult {
    const mxtsConcern = (yield call(MxtsApi.concern, env, { shortName: env.env.concern })).content[0];

    let arrivalDate = "";
    let departureDate = "";
    if (filter.startdate && filter.enddate) {
        arrivalDate = moment(filter.startdate, DATE_FORMAT.DEFAULT).format();
        departureDate = moment(filter.enddate, DATE_FORMAT.DEFAULT).format();
    }

    const reservationRequestBody: ReservationRequest = {
        arrivalInformationStatus: 0,
        completed: false,
        concernId: mxtsConcern.concernId,
        customerId: customer.customerId,
        payingCustomerId: payingCustomer?.customerId || null,
        debitCardInformationStatus: 0,
        eventManagerId: null,
        resortId: resource.resortId,
        exp: false,
        financialStatus: 0,
        initials: null,
        isChanged: false,
        payingCustomerDiffers: true,
        refreshHoldingReport: false,
        status: ReservationStatus.INITIAL,
        tncSigned: false,
        type: ReservationType.RESERVATION,
        validationStatus: 0,
        distributionChannelId: filter.ownerShareableLink?.distributionChannelId || filter.distributionChannel!.distributionChannelId,
        reservationCategoryId: (payingCustomer && payingCustomerReservationCategoryId) || filter.ownerShareableLink?.reservationCategoryId || +filter.reservationCategoryId!,
        currency: {
            conversionRate: 1,
            multiCurrency: false,
        },
        modifiedDate: reservationDate,
        reservationDate,
        virtualReservationDate: reservationDate,
        sourceId: sourceId || null,
        kind,
        arrivalDate: kind === ReservationKind.ADDON_RESERVATION ? arrivalDate : undefined,
        departureDate: kind === ReservationKind.ADDON_RESERVATION ? departureDate : undefined,
    };

    // create a reservation in mxts
    const reservation: ReservationResult = yield call(MxtsApi.createReservation, env, reservationRequestBody);
    return reservation;
}

function* addMemosToReservation(env: ApiCallOptions, action: CreateReservationAction, reservation: ReservationResult, myEnvState: MyEnvState, customer: Customer, saveOnInvoice: boolean) {
    const memosContent: string[] = Object.keys(action.payload)
        .filter((key) => key.startsWith("__RESERVATION__MEMO__"))
        .map((memoKey) => (action.payload as any)[memoKey]);

    // Create memos in reservation if memo fields have been selected in customer form
    try {
        if (memosContent.length) {
            const memoCategoryId: number = getMemoCategoryFromPayload(action);
            yield all(
                memosContent.map((content: string) =>
                    call(MxtsApi.createMemo, env, {
                        content,
                        memocategoryId: memoCategoryId,
                        managerId: reservation.memoManagerId,
                        oninvoice: saveOnInvoice,
                    })
                )
            );
        }
        // if booking for owner and customer has the customerGroupId then create the memo for the owner
        if (customer.customerGroupId && myEnvState.ownerState?.selectedBookingChoice === OwnerBookChoiceSelection.OWN_USE) {
            const memoContent = `Owner booking was made by ${customer.firstName} ${customer.lastName}`;
            const memoCategoryId = 34029; // Adding a static id for now after some more discussion will add the dynamically fetched id
            call(MxtsApi.createMemo, env, {
                content: memoContent,
                memocategoryId: memoCategoryId,
                managerId: reservation.memoManagerId,
            });
        }
    } catch (e) {
        yield reportError(e);
    }
}

function getMemoCategoryFromPayload(action: CreateReservationAction): number {
    return parseInt(
        Object.keys(action.payload)
            .filter((key) => key.startsWith("__RESERVATION__MEMO__"))
            .toString()
            .split("-")[1] || "",
        10
    );
}

export async function insertReservedResources(reservedResources: ReservedResourceWithChildren[], reservationId: number, env: ApiCallOptions): Promise<ReservedResource[]> {
    if (!reservedResources.length) {
        return [];
    }
    // Make sure the accoTypes are first in the list to prevent the bug described in MCMS-5334.
    reservedResources.sort((firstReservedResource) => (firstReservedResource.type === ResourceType.ACCOMMODATIONTYPE ? -1 : 1));
    return MxtsApi.createReservedResources(noRetriesEnv(env), reservedResources, [{ key: "reservationId", value: reservationId }]);
}

export async function insertPriceEngineReservedResources(
    reservedResources: ChoiceReservedResource[],
    preBooking: boolean,
    reservationId: number,
    env: ApiCallOptions,
    filter: DynamicFilter
): Promise<ReservedResource[]> {
    if (!reservedResources.length) {
        return [];
    }
    const reservedResourcesWithoutDayAddOns: ChoiceReservedResource[] = [];
    const MOMENT_NAME = "ON_STORING_SELECTED_RESOURCES_IN_RESERVATION";
    let updatedChild: ChoiceReservedResource[];
    reservedResources.forEach((resource) => {
        updatedChild = resource.children.filter((child) => Object.keys(child).includes("resource") && child.resource?.resourceStock !== "DAY");
        reservedResourcesWithoutDayAddOns.push({ ...resource, children: updatedChild });
    });
    const reservedResourcesWithoutActivitiesAndDayAddOns: ChoiceReservedResource[] = reservedResourcesWithoutDayAddOns.filter((child) => child.type !== ChoiceResourceType.RESOURCEACTIVITY);
    let insertedReservedResources: ReservedResource[] = [];
    if (reservedResourcesWithoutActivitiesAndDayAddOns.length) {
        insertedReservedResources = await MxtsApi.createReservedResourcesUsingPriceEngine(noRetriesEnv(env), { reservedResources: reservedResourcesWithoutActivitiesAndDayAddOns, preBooking }, [
            { key: "reservationId", value: reservationId },
        ]);
    }

    const reservedResourceId = insertedReservedResources.find((reservedResource) => reservedResource.resourceId === filter.resourceid)?.reservedResourceId;
    if (reservedResourceId && filter.startdate && filter.enddate && reservationId) {
        // Note:- Refresh the infotext after creating reservation.
        const infoTextsParams: RefreshInfotextRequest = {
            reservedResourceId,
            reservationId,
            showMomentTypes: [MOMENT_NAME],
        };
        await MxtsApi.refreshInfotexts(env, infoTextsParams);
    }
    return insertedReservedResources;
}

/**
 * Removes all the ChoiceReservedResources that are actually added by a voucherCode. We do this because when we redeem the voucher the extra will be added to the reservation aswell.
 * So we make sure only the redeem endpoint will add the voucher extra and not also the addReservedResourcesToReservation method.
 *
 * TODO: We should implement a way in mxts to handle this. Either by providing an endpoint to redeem vouchers without adding their voucher extra to the reservation.
 *  Or by letting the reservation-service handle inserting the voucher AND all reservedResources. Instead of mcms doing that.
 */
async function removeVoucherReservedResources(params: { env: ApiCallOptions; filter: DynamicFilter; billReservedResources: ChoiceReservedResource[] }): Promise<void> {
    const { env, filter, billReservedResources } = params;
    if (!filter.voucherCodes?.length) {
        return;
    }
    const vouchersResult: PagedResult<VoucherDetailsResponse> | undefined = await MxtsApi.searchVouchers(env, { code: filter.voucherCodes, size: 100 }).catch(() => undefined);
    const resourceCodes: string[] =
        vouchersResult?.content?.map((voucher: VoucherDetailsResponse) => voucher?.voucherSetResourceCode || voucher?.resourceCode).filter((resourceCode: string) => resourceCode) || [];
    flattenReservedResourcesTree(billReservedResources).forEach(
        (reservedResource) =>
            (reservedResource.children = reservedResource.children.filter((child: ChoiceReservedResource) => !ArrayUtil.includes(resourceCodes, child.resource.code, { caseSensitive: false })))
    );
}

/**
 * Removes all the children of composition reservedResources. Because mxts will insert the children for us. If we pass the children, then mxts will insert them twice.
 * See: MCMS-6704
 *
 * TODO: Mxts should be smart and see if the children are already being passed. I've created a mxts ticket for this: MXTS-34861
 */
function removeCompositionChildren(params: { billReservedResources: ChoiceReservedResource[] }): void {
    const { billReservedResources } = params;
    flattenReservedResourcesTree(billReservedResources)
        .filter((billReservedResource) => billReservedResource.type === ChoiceResourceType.COMPOSITION)
        .forEach((reservedResource) => (reservedResource.children = []));
}

function getHighestPriorityUnit(params: { filter: DynamicFilter; autoAssignUnitToReservation: boolean; prioUnits?: number[] }): number | undefined {
    const { prioUnits, autoAssignUnitToReservation, filter } = params;
    let prioUnitTarget: number | undefined = !filter.unitid && autoAssignUnitToReservation ? prioUnits?.shift() : undefined;
    if (prioUnitTarget === -1) {
        // this is a quantity accoType without physical units. So we are booking on type level
        prioUnitTarget = undefined;
    }
    return prioUnitTarget;
}

// eslint-disable-next-line max-lines-per-function
function* addReservedResourcesToReservation(params: {
    env: ApiCallOptions;
    resource: Resource;
    filter: DynamicFilter;
    reservation: ReservationResult;
    rateTypeId: number;
    billReservedResources: ChoiceReservedResource[];
    reservationDate: string;
    autoAssignUnitToReservation: boolean;
    sortUnitsOnVSI: boolean;
    mainBill: BillChoice;
    additionState: AdditionState;
    instalmentsState: InstalmentsState;
    selectedAddOns: SelectedAddOn[];
    prioUnits?: number[];
    accoTypeHasUnitImply?: boolean;
    resourceActivityDetailsId?: number;
}): Generator<any, any, any> {
    const {
        env,
        resource,
        filter,
        reservation,
        rateTypeId,
        reservationDate,
        autoAssignUnitToReservation,
        sortUnitsOnVSI,
        mainBill,
        instalmentsState,
        additionState,
        selectedAddOns,
        resourceActivityDetailsId,
    } = params;
    let { billReservedResources, accoTypeHasUnitImply } = params;
    let { prioUnits } = params;

    if (!filter.unitid && autoAssignUnitToReservation) {
        if (!prioUnits) {
            prioUnits = yield call(getPrioUnitsForReservation, filter, sortUnitsOnVSI);
        }
        if (!prioUnits?.length) {
            throw Error("No bookable unit available");
        }
    }

    const prioUnitTarget: number | undefined = getHighestPriorityUnit({ filter, autoAssignUnitToReservation, prioUnits });

    try {
        if (autoAssignUnitToReservation && !filter.unitid && prioUnitTarget && filter.startdate && filter.enddate) {
            accoTypeHasUnitImply =
                accoTypeHasUnitImply === undefined
                    ? ((yield call(
                          accoTypeHasUnitImplies,
                          {
                              unitId: prioUnitTarget,
                              startDate: DateUtil.reFormatDate(filter.startdate, DATE_FORMAT.DEFAULT, DATE_FORMAT.MXTS),
                              endDate: DateUtil.reFormatDate(filter.enddate, DATE_FORMAT.DEFAULT, DATE_FORMAT.MXTS),
                          },
                          env
                      )) as boolean)
                    : accoTypeHasUnitImply;
            if (accoTypeHasUnitImply) {
                billReservedResources = yield getBillReservedResourcesForTargetUnit({ env, filter, billReservedResources, additionState, instalmentsState, selectedAddOns, prioUnitTarget });
            }
        }

        yield call(removeVoucherReservedResources, { env, filter, billReservedResources });
        removeCompositionChildren({ billReservedResources });
        const otherChoiceReservedResources = billReservedResources.filter((res: ChoiceReservedResource) => res.resourceId !== filter.resourceid);
        const accoTypeChoiceReservedResource: ChoiceReservedResource | null = billReservedResources.find((res: ChoiceReservedResource) => res.resourceId === filter.resourceid) || null;
        if (!resourceActivityDetailsId && !accoTypeChoiceReservedResource) {
            throw Error("The accoType is not found in the bill");
        }
        const targetChoiceReservedResource = { ...accoTypeChoiceReservedResource, unitId: filter.unitid || prioUnitTarget };
        return yield call(insertPriceEngineReservedResources as any, [targetChoiceReservedResource, ...otherChoiceReservedResources], !!mainBill.preBooking, reservation.reservationId, env, filter);
    } catch (error) {
        if ((error.status === 400 || error.status === 500) && autoAssignUnitToReservation && !filter.unitid) {
            // eslint-disable-next-line no-console
            console.debug(`prioUnit ${prioUnitTarget} already booked, retrying with first one in the list:`, prioUnits);
            return yield addReservedResourcesToReservation({
                env,
                resource,
                filter,
                reservation,
                rateTypeId,
                billReservedResources,
                reservationDate,
                autoAssignUnitToReservation,
                sortUnitsOnVSI,
                mainBill,
                selectedAddOns,
                additionState,
                instalmentsState,
                prioUnits,
                accoTypeHasUnitImply,
            });
        }
        throw error;
    }
}

/**
 * Re-obtain bill state with the selected unit. Because we'll need to obtain the mandatory unit implies for the unit that was picked. See: MCMS-3959
 */
function* getBillReservedResourcesForTargetUnit(params: {
    env: ApiCallOptions;
    filter: DynamicFilter;
    billReservedResources: ChoiceReservedResource[];
    additionState: AdditionState;
    instalmentsState: InstalmentsState;
    selectedAddOns: SelectedAddOn[];
    prioUnitTarget: number;
}) {
    const { env, filter, instalmentsState, additionState, selectedAddOns, prioUnitTarget } = params;
    const apiContext = globalApiContext();
    const billState: BillState = yield select(SagaUtils.getBillState);
    const generatedBill: ChoiceResult = yield call(BillUtil.generateBillByDynamicFilter, {
        instalmentsState,
        dynamicFilter: { ...filter, unitid: prioUnitTarget },
        additionState,
        selectedAddOns,
        billChoice: billState.mainBill || getEmptyBillChoice(),
        options: {},
        env,
        apiContext,
    });
    if (generatedBill?.choices?.[0].exception) {
        const unitNotBookableError: Error & { status?: number } = new Error("Unit not bookable");
        unitNotBookableError.status = 400;
        throw unitNotBookableError;
    }
    return generatedBill?.choices?.[0]?.reservedResources || [];
}

export async function getPrioUnitsForReservation(filter: DynamicFilter, sortUnitsOnVSI: boolean) {
    const sortedUnitsAggregation = { ...SORTED_UNITS_AGGREGATION };
    if (sortUnitsOnVSI) {
        sortedUnitsAggregation.sortField = "VSI";
    }
    const availabilityResponse = await AvailabilityUtil.getAvailabilityByDynamicFilter(filter, { customAggregations: [sortedUnitsAggregation] }, globalApiContext());
    if (availabilityResponse?.availabilityResult?.response?.sortedUnits?.length) {
        return availabilityResponse?.availabilityResult.response.sortedUnits.map((sortedUnit: UnitDocument) => sortedUnit.unitId);
    }
    return [];
}

async function getVoucherResources(filter: DynamicFilter, resortId: number, env: ApiCallOptions): Promise<Resource[]> {
    if (!filter.voucherCodes?.length) {
        return [];
    }
    const vouchersResult: PagedResult<VoucherDetailsResponse> = await MxtsApi.searchVouchers(env, { code: filter.voucherCodes });
    const voucherResources: Array<Resource | undefined> = await Promise.all(
        (vouchersResult.content || []).map((voucherDetail: VoucherDetailsResponse) => getVoucherSetResource(voucherDetail, resortId, env, MxtsApi))
    );
    return voucherResources.filter((resource) => resource) as Resource[];
}

async function getSpecialRequestBodies(
    env: ApiCallOptions,
    accoReservedResourceResult: ReservedResourceResult,
    filter: DynamicFilter,
    reservation: ReservationResult,
    billReservedResources: ChoiceReservedResource[] | undefined,
    reservationDate: string,
    additionState: AdditionState,
    selectedAddOns: SelectedAddOn[]
): Promise<ReservedResourceWithChildren[]> {
    const apiContext = globalApiContext();
    const accoReservedResource: ChoiceReservedResource | null = getReservedResourceByResourceId(billReservedResources, filter.resourceid);
    const voucherResources: Resource[] = await getVoucherResources(filter, reservation.resortId, env);
    const specials: ChoiceReservedResource[] | null =
        accoReservedResource?.children.filter(
            (child: ChoiceReservedResource) =>
                [ChoiceResourceType.SPECIAL, ChoiceResourceType.COMPOSITION].includes(child.type) &&
                !child.impliesId &&
                !voucherResources.some((voucherResource) => voucherResource.resourceId === child.resourceId) &&
                (!Object.keys(additionState.selectedAdditions || {}).includes(child.resourceId.toString()) || child.type !== ChoiceResourceType.COMPOSITION) &&
                !selectedAddOns.find((addOn: SelectedAddOn) => addOn.resourceId === child.resourceId)
        ) || null;

    if (!specials || !specials.length) {
        // nothing to store, bye
        return [];
    }

    const specialReservedResourceRequests: ReservedResourceWithChildren[] = [];
    for (const special of specials) {
        const specialReservedResourceRequest: ReservedResourceWithChildren = {
            completed: true,
            isChoiceAddition: false,
            isFullySubjectBased: special.fullySubjectBased,
            onBill: special.onBill,
            payerType: special.payerType,
            quantity: special.quantity,
            removable: special.removable,
            showPrice: special.showPrice,
            status: reservation.status,
            type: special.type,
            resourceId: special.resourceId,
            representationId: special.representationId,
            startDate: await DateUtil.getMXTSDateString(apiContext, special.startDate),
            endDate: await DateUtil.getMXTSDateString(apiContext, special.endDate),
            parentId: accoReservedResourceResult.reservedResourceId,
            reservationDate,
            name: special.name,
            code: special.code,
            reservationId: reservation.reservationId,
            impliesEnabled: false,
        };

        // process the possible children of the special
        if (special.children?.length) {
            specialReservedResourceRequest.children = await getChildImplyRequestBodies(
                specialReservedResourceRequest,
                { reservationId: reservation.reservationId, status: reservation.status },
                billReservedResources
            );
        }

        specialReservedResourceRequests.push(specialReservedResourceRequest);
    }
    return specialReservedResourceRequests;
}

/**
 * Also add the implies that are directly linked to the reservation and not a child of the accoType
 * See: MCMS-4017
 */
async function getReservationImplyRequestBodies(
    accoTypeReservedResource: ReservedResourceResult,
    reservation: ReservationResult,
    billReservedResources: ChoiceReservedResource[] | undefined
): Promise<ReservedResourceWithChildren[]> {
    const reservationImplies = (billReservedResources || []).filter(
        (reservedResource: ChoiceReservedResource) => reservedResource.resourceId !== accoTypeReservedResource.resourceId && reservedResource.impliesId
    );
    const reservationImplyRequestBodies: ReservedResourceWithChildren[] = [];
    for (const reservationImply of reservationImplies) {
        const implyRequestBody = await createImplyRequestBody(reservationImply, { reservationId: reservation.reservationId, status: reservation.status });
        if (reservationImply.children?.length) {
            implyRequestBody.children = await getChildImplyRequestBodies(implyRequestBody, { reservationId: reservation.reservationId, status: reservation.status }, billReservedResources);
        }

        reservationImplyRequestBodies.push(implyRequestBody);
    }
    return reservationImplyRequestBodies;
}

async function createImplyRequestBody(
    implyReservedResource: ChoiceReservedResource,
    reservation: { reservationId: number; status: number },
    children?: ReservedResourceWithChildren[],
    parentReservedResourceId?: number
): Promise<ReservedResourceWithChildren> {
    const context = globalApiContext();
    const implyRequestBody: ReservedResourceWithChildren = {
        status: ReservationStatus.INITIAL,
        reservationId: reservation.reservationId,
        startDate: await DateUtil.getMXTSDateString(context, implyReservedResource?.startDate),
        endDate: await DateUtil.getMXTSDateString(context, implyReservedResource?.endDate),
        resourceId: implyReservedResource.resourceId,
        children,
        type: implyReservedResource.type,
        parentId: parentReservedResourceId || implyReservedResource.parentId,
        impliesId: implyReservedResource.impliesId,
        representationId: implyReservedResource.representationId,
        quantity: implyReservedResource.quantity,
        payerType: implyReservedResource.payerType,
        name: implyReservedResource.name,
        code: implyReservedResource.code,
        impliesEnabled: false,
        included: implyReservedResource.included,
        currencyId: implyReservedResource.currencyId,
        compositionItemId: implyReservedResource.compositionItemId,
        removable: implyReservedResource.removable,
        completed: true,
        showPrice: implyReservedResource.showPrice,
        onBill: implyReservedResource.onBill,
        isFullySubjectBased: implyReservedResource.fullySubjectBased,
        implyOnce: implyReservedResource.implyOnce,
        hasPriceOverride: implyReservedResource.hasPriceOverride,
        voucher: null,
        rateTypeId: implyReservedResource.rateTypeId,
        isChoiceAddition: false,
        reservationDate: implyReservedResource.reservationDate,
        unitId: implyReservedResource.unitId,
    };
    if (implyRequestBody.hasPriceOverride) {
        implyRequestBody.price = implyReservedResource.price;
    }
    return implyRequestBody;
}

async function getChildImplyRequestBodies(
    parentReservedResourceResult: ReservedResource | ChoiceReservedResource,
    reservation: { reservationId: number; status: number },
    billReservedResources: ChoiceReservedResource[] | undefined
): Promise<ReservedResourceWithChildren[]> {
    const parentReservedResource: ChoiceReservedResource | null = getReservedResourceByResourceId(billReservedResources, parentReservedResourceResult.resourceId);
    if (!parentReservedResource) {
        return [];
    }

    const implies: ChoiceReservedResource[] = parentReservedResource.children.filter((childResource: ChoiceReservedResource) => {
        // if we know the implies already, let us decide what to store in the reservation
        if (!parentReservedResourceResult.impliesEnabled) {
            return childResource.impliesId;
        }
        // in case implies should be handled by mxts, we don't send anything
        return false;
    });

    const implyRequestBodies: ReservedResourceWithChildren[] = [];

    for (const implyReservedResource of implies) {
        // find the children of the implies
        let children: ReservedResourceWithChildren[] | undefined;
        if (implyReservedResource.children && implyReservedResource.children.length > 0) {
            children = await getChildImplyRequestBodies(implyReservedResource, reservation, billReservedResources);
        }

        implyRequestBodies.push(await createImplyRequestBody(implyReservedResource, reservation, children, parentReservedResourceResult.reservedResourceId));
    }
    return implyRequestBodies;
}

function* addPreferencesToReservation(env: ApiCallOptions, accoReservedResource: ReservedResourceResult, filter: DynamicFilter, reservation: ReservationResult) {
    if (filter.unitPreference && accoReservedResource.unitId) {
        const preferredUnit: Unit | null = yield call(DomainObjectUtil.getUnitById, MxtsApi, accoReservedResource.unitId, env);
        if (preferredUnit) {
            yield call(
                MxtsApi.insertReservedResourcePreference,
                env,
                {
                    type: ReservedResourcePreferenceType.OBJECT,
                    reservedResourcePreferenceId: null,
                    reservedResourceId: accoReservedResource.reservedResourceId,
                    remark: preferredUnit.name,
                } as ReservedResourcePreferenceRequest,
                [
                    {
                        key: "reservationId",
                        value: reservation.reservationId,
                    },
                    {
                        key: "reservedResourceId",
                        value: accoReservedResource.reservedResourceId,
                    },
                ]
            );
        }
    }
}

function* addAmenitiesToReservation(env: ApiCallOptions, accoReservedResource: ReservedResourceResult, filter: DynamicFilter, reservation: ReservationResult) {
    if (filter.amenities && accoReservedResource) {
        const preferredAmenities: Amenity[] | null = yield call(DomainObjectUtil.getAmenitiesByIdentifiers, MxtsApi, filter.amenities?.join(","), env);
        const preferredUnit: Unit | null = yield call(DomainObjectUtil.getUnitById, MxtsApi, accoReservedResource.unitId, env);
        const linkedAmenities: AmenityLink[] | null = yield call(DomainObjectUtil.getAmenityLinks, MxtsApi, preferredUnit?.amenityManagerId, env);
        const filteredPreferredAmenities = preferredAmenities?.filter((preferredAmenity) => linkedAmenities?.map((amenityLink) => amenityLink.amenityId)?.includes(preferredAmenity.amenityId));
        const preferredAmenitiesPayload = [];
        if (filteredPreferredAmenities?.length) {
            for (const preferredAmenity of filteredPreferredAmenities) {
                if (preferredAmenity.showInSearch) {
                    const preferredAmenityLink: AmenityLink | null = yield call(DomainObjectUtil.getAmenityLinkById, MxtsApi, env, preferredAmenity);
                    preferredAmenitiesPayload.push({
                        amenityId: preferredAmenity.amenityId,
                        extraId: preferredAmenityLink?.preferenceExtraId,
                        hasCosts: preferredAmenityLink?.preferenceExtraId ? true : false,
                        name: preferredAmenity.name,
                        reservedResourceId: accoReservedResource.reservedResourceId,
                        type: preferredAmenity.amenityOption === "PREFERENCE_AND_ESSENTIAL" ? "ESSENTIAL" : preferredAmenity.amenityOption,
                    });
                }
            }
        }
        yield call(MxtsApi.insertReservedResourceAmenities, env, preferredAmenitiesPayload, [
            {
                key: "reservationId",
                value: reservation.reservationId,
            },
            {
                key: "reservedResourceId",
                value: accoReservedResource.reservedResourceId,
            },
        ]);
    }
}

async function getSimpleAdditionRequestBodies(params: {
    accoReservedResource: ReservedResourceResult;
    reservation: ReservationResult;
    rateTypeId: number;
    billReservedResources: ChoiceReservedResource[] | undefined;
    additionState: AdditionState;
    selectedAddOnsState: SelectedAddOnsState;
    addOnCartId?: number;
}): Promise<ReservedResourceWithChildren[]> {
    const { accoReservedResource, reservation, rateTypeId, billReservedResources, additionState, selectedAddOnsState, addOnCartId } = params;
    return getSimpleAdditionReservedResources({
        billReservedResources,
        additionState,
        accoReservedResource: { parentReservedResourceId: accoReservedResource.reservedResourceId, endDate: accoReservedResource.endDate, startDate: accoReservedResource.startDate },
        reservation: { reservationId: reservation.reservationId, status: reservation.status },
        rateTypeId,
        selectedAddOnsState,
        addOnCartId,
    });
}

export async function getSimpleAdditionReservedResources(params: {
    accoReservedResource: { parentReservedResourceId: number; startDate: string; endDate: string };
    reservation: { reservationId: number; status: number };
    rateTypeId: number;
    billReservedResources: ChoiceReservedResource[] | undefined;
    additionState?: AdditionState;
    selectedAddOnsState: SelectedAddOnsState;
    addOnCartId?: number;
}): Promise<ReservedResourceWithChildren[]> {
    const { accoReservedResource, reservation, rateTypeId, billReservedResources, additionState, selectedAddOnsState, addOnCartId } = params;
    const apiContext = globalApiContext();

    const usingSelectedAddOnState = !!selectedAddOnsState?.addOnCarts?.some((cart) => cart?.selectedAddOns?.length);

    if (usingSelectedAddOnState) {
        const simpleSelectedAddOns: SelectedAddOn[] =
            getSelectedAddOnsInsideCart({ selectedAddOnsState, cartReservedResourceId: addOnCartId }).filter((selectedAddOn) => !isDayProduct(selectedAddOn)) || [];
        const simpleAddOnRequestBodies: ReservedResourceWithChildren[] = [];

        for (const selectedAddOn of simpleSelectedAddOns) {
            const { resourceId } = selectedAddOn;
            const addOnReservedResource: ChoiceReservedResource | null = getReservedResourceByResourceId(billReservedResources, resourceId);
            const addOnReservedResourceRequestBody: ReservedResourceWithChildren = await getAddOnReservedResourceRequestBodyBase({
                apiContext,
                addOnReservedResource,
                resourceId,
                reservation,
                rateTypeId,
                accoReservedResource,
            });

            if (selectedAddOn.displayType === DisplayType.SUBJECT) {
                addOnReservedResourceRequestBody.subjects = selectedAddOn.subjects?.map((addOnSubject: AddOnSubject) => ({ subjectId: addOnSubject.subjectId, quantity: addOnSubject.quantity }));
            }

            // process the possible children of the addon
            if (addOnReservedResource?.children?.length) {
                addOnReservedResourceRequestBody.children = await getChildImplyRequestBodies(addOnReservedResourceRequestBody, reservation, billReservedResources);
            }
            simpleAddOnRequestBodies.push(addOnReservedResourceRequestBody);
        }

        return simpleAddOnRequestBodies;
    }

    const simpleSelectedAdditions: Array<SelectedAddition & { resourceId: number }> = AdditionsUtil.getSelectedSimpleAdditions(additionState?.selectedAdditions || []).filter(
        (selectedAddition: SelectedAddition & { resourceId: number }) => selectedAddition.quantity > 0
    );
    const simpleAdditionRequestBodies: ReservedResourceWithChildren[] = [];

    for (const selectedAddition of simpleSelectedAdditions) {
        const { resourceId } = selectedAddition;
        // get the calculated reserved resource for this addition
        const additionReservedResource: ChoiceReservedResource | null = getReservedResourceByResourceId(billReservedResources, resourceId);
        const additionReservedResourceRequestBody: ReservedResourceWithChildren = await getAddOnReservedResourceRequestBodyBase({
            apiContext,
            addOnReservedResource: additionReservedResource,
            resourceId,
            reservation,
            rateTypeId,
            accoReservedResource,
        });

        let productSubjectSelections: ProductSubjectSelection[][] = [];
        productSubjectSelections = AdditionsUtil.getAllSubjectsFromSelectedAddition(selectedAddition as SelectedAddition & { resourceId: number });

        for (const subjects of productSubjectSelections.length ? productSubjectSelections : [[]]) {
            if (subjects.length) {
                additionReservedResourceRequestBody.subjects = subjects.map((productSubj) => ({ subjectId: productSubj.subjectId, quantity: productSubj.quantity }));
            }

            // process the possible children of the addon
            if (additionReservedResource?.children?.length) {
                additionReservedResourceRequestBody.children = await getChildImplyRequestBodies(additionReservedResourceRequestBody, reservation, billReservedResources);
            }
            simpleAdditionRequestBodies.push(additionReservedResourceRequestBody);
        }
    }

    return simpleAdditionRequestBodies;
}

export async function insertActivityReservedResources(params: InsertActivityReservedResourcesParams): Promise<ReservedResource[]> {
    const { env, reservationId, activityReservedResources, activityPlannerState, distributionChannelId } = params;
    const insertedActivityReservedResources: ReservedResource[] = [];
    activityReservedResources.forEach((reservedResource) => updateReservedResourceSubjectQuantities(reservedResource, activityPlannerState));

    if (activityReservedResources.length) {
        const resourceRepresentations = await MxtsApi.representations(env, {
            resourceIds: uniq(activityReservedResources.map((activity) => activity.resourceId)),
            distributionChannelId,
        }).catch((err) => {
            globalLogger.error(`Error while trying to obtain representation for activity during activity reservation creation. (reservationId: ${reservationId}) `, err);
            return undefined;
        });

        await Promise.all(
            activityReservedResources.map(async (choiceReservedResource) => {
                const activityReservedResource: ReservedResource = {
                    completed: true,
                    endDateTime: choiceReservedResource.endDate,
                    impliesEnabled: true,
                    isChoiceAddition: false,
                    onBill: true,
                    representationId: resourceRepresentations?.content?.find((representation) => representation.resourceId === choiceReservedResource.resourceId)?.representationId,
                    rateTypeId: choiceReservedResource.rateTypeId,
                    payerType: choiceReservedResource.payerType,
                    quantity: choiceReservedResource.quantity,
                    removable: true,
                    reservationId,
                    resourceActivityDetailsId: choiceReservedResource.resourceActivityDetailsId,
                    resourceId: choiceReservedResource.resourceId,
                    showPrice: true,
                    startDateTime: choiceReservedResource.startDate,
                    status: -1,
                    type: ChoiceResourceType.RESOURCEACTIVITY,
                    reservedResourceSubjectQuantities: choiceReservedResource.reservedResourceSubjectQuantities,
                };
                const insertedActivityReservedResource = await MxtsApi.createReservedResource(env, activityReservedResource, [{ key: "reservationId", value: reservationId }]);
                insertedActivityReservedResources.push(insertedActivityReservedResource);
            })
        );
    }
    return insertedActivityReservedResources;
}

export async function addDayProductsToReservation(params: {
    env: ApiCallOptions;
    parentReservedResourceId: number;
    reservationId: number;
    selectedAddOnsState?: SelectedAddOnsState;
    additionState?: AdditionState;
    addOnCartId?: number;
    reservation?: ReservationResult;
}): Promise<void> {
    const { env, parentReservedResourceId, reservationId, selectedAddOnsState, addOnCartId } = params;

    const selectedAddOns = getSelectedAddOnsInsideCart({ selectedAddOnsState, cartReservedResourceId: addOnCartId });
    if (selectedAddOns.length) {
        const dayProducts: SelectedAddOn[] = selectedAddOns.filter((selectedAddOn) => isDayProduct(selectedAddOn));

        if (dayProducts.length) {
            const allSubjects = (await MxtsApi.subjects(env, { types: ["PERSON", "PET"] })).content;
            const subjectList = allSubjects.map((subject) => {
                const singleSubject = { quantity: 0, subjectId: subject.subjectId };
                return singleSubject;
            });
            const dayAddonReservedResourceRequests: DayAddonReservedResourceRequest[] = dayProducts.map((dayProduct: SelectedAddOn) => {
                const days = getDateSelectionFromDayProduct(dayProduct);
                days.forEach((day) => {
                    const allSubjects = subjectList.map((subject) => {
                        const existingSubject = day.subjects?.find((existingSubject) => existingSubject.subjectId === subject.subjectId);
                        return existingSubject ? existingSubject : subject;
                    });
                    const isSubjectBasedDayAddon = allSubjects.find((subject) => subject.quantity > 0);
                    if (isSubjectBasedDayAddon) {
                        day.subjects = allSubjects;
                    }
                });
                const dayAddonReservedResourceRequest: DayAddonReservedResourceRequest = {
                    resourceId: dayProduct.resourceId,
                    parentId: parentReservedResourceId,
                    days,
                };
                return dayAddonReservedResourceRequest;
            });

            // TODO: do we need to add possible children implies of the addition like we do for simple additions?
            await Promise.all(
                dayAddonReservedResourceRequests.map((dayAddon: DayAddonReservedResourceRequest) =>
                    MxtsApi.createDayAddonReservedResource(noRetriesEnv(env), dayAddon, [{ key: "reservationId", value: reservationId }])
                )
            );
        }
    } else {
        return addOldAdditionStateDayProductsToReservation(params);
    }
}

// TODO this method is deprecated and will be removed soon once the old AdditionState is removed
export async function addOldAdditionStateDayProductsToReservation(params: {
    env: ApiCallOptions;
    parentReservedResourceId: number;
    reservationId: number;
    selectedAddOnsState?: SelectedAddOnsState;
    additionState?: AdditionState;
}): Promise<void> {
    const { env, parentReservedResourceId, reservationId, additionState } = params;

    if (!additionState) {
        return;
    }

    const dayProducts: Array<SelectedAddition & { resourceId: number }> = AdditionsUtil.getSelectedDayProducts(additionState.selectedAdditions).filter(
        (dayProduct: SelectedAddition) => dayProduct.quantity
    );

    if (dayProducts.length) {
        const dayAddonReservedResourceRequests: DayAddonReservedResourceRequest[] = [];
        dayProducts.forEach((dayProduct: SelectedAddition & { resourceId: number }) => {
            dayProduct.daysAndSubjectsConfig?.forEach((config) => {
                dayAddonReservedResourceRequests.push({
                    resourceId: dayProduct.resourceId,
                    parentId: parentReservedResourceId,
                    days: (config.days || [])
                        .filter((day) => day.checked)
                        .map((day: ProductDay) => {
                            const dayProductBody: { date: string; memo: string; subjects?: SubjectQuantity[]; quantity?: number } = {
                                date: DateUtil.formatDate(day.date, DATE_FORMAT.MXTS),
                                memo: "",
                            };
                            const dayProductSubjects = (config.subjects || [])
                                // TODO: enable again once MXTS-23849 is fixed
                                // .filter((subject) => subject.quantity)
                                .map((subject: ProductSubjectSelection) => ({ quantity: subject.quantity, subjectId: subject.subjectId }));
                            if (dayProductSubjects.some((subj) => subj.quantity)) {
                                dayProductBody.subjects = dayProductSubjects;
                            } else {
                                dayProductBody.quantity = 1;
                            }
                            return dayProductBody;
                        }),
                });
            });
        });

        // TODO: do we need to add possible children implies of the addition like we do for simple additions?
        await Promise.all(
            dayAddonReservedResourceRequests.map((dayAddon: DayAddonReservedResourceRequest) =>
                MxtsApi.createDayAddonReservedResource(noRetriesEnv(env), dayAddon, [{ key: "reservationId", value: reservationId }])
            )
        );
    }
}

function noRetriesEnv(env: ApiCallOptions) {
    return { ...env, retryCount: 0 };
}

function* checkIfBillRecalculationIsNeeded(env: ApiCallOptions, reservation: ReservationResult, billChoice?: BillChoice) {
    const reservationId = reservation.reservationId;
    const instalments: PagedResult<Instalment> = yield call(MxtsApi.getInstalments, env, { reservationId });
    const totalDue = instalments.content.map((instalment: Instalment) => instalment.due).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);

    const dueBillLine =
        billChoice?.customerBill?.find((totalBillLine) => totalBillLine.billLineType === "DUE_AMOUNT") || billChoice?.agentBill?.find((totalBillLine) => totalBillLine.billLineType === "DUE_AMOUNT");
    if (!dueBillLine || dueBillLine.total !== totalDue) {
        reportError(
            new Error("MCMS-5062 ERROR The mcms bill doesn't match the mxts bill. We should investigate why this happens. As a workaround we will now execute a mxts bill recalculation.."),
            undefined,
            `billStateTotal: ${dueBillLine?.total}  instalmentTotal: ${totalDue}  ReservationId:${reservationId}`
        );
        // Sleep 1s before doing the bill recalculation. This is a wild guess to hopefully workaround https://support.maxxton.com/browse/MXTS-30857
        yield new Promise((resolve) => setTimeout(resolve, 1000));
        if (!billChoice?.preBooking) {
            yield call(MxtsApi.recalculateBill, env, {}, [{ key: "reservationId", value: reservationId }]);
        }
    }
}

function* redeemVouchers(env: ApiCallOptions, filter: DynamicFilter, reservation: ReservationResult) {
    if (filter.voucherCodes?.length) {
        return yield all(filter.voucherCodes.map((voucherCode) => call(MxtsApi.redeemVoucher, env, { voucherCode }, [{ key: "reservationId", value: reservation.reservationId }])));
    }
}

function* updateReservationDetails(action: UpdateReservationAction) {
    try {
        const { reservation, paymentTermSetId, reminderSetId } = action.payload;
        const filter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
        const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), filter.currentLocale);
        const patchedReservation = yield call(MxtsApi.getReservation, env, {}, [{ key: "reservationId", value: reservation.reservationId }]);
        const updatedReservation = yield call(MxtsApi.updateReservation, env, { ...patchedReservation, paymentTermSetId, reminderSetId }, [{ key: "reservationId", value: reservation.reservationId }]);
        const reservationState: ReservationState = yield select(SagaUtils.getReservationState);
        const reservationAction: ReservationAction = {
            type: ActionType.Reservation,
            actionType: ReservationActionType.created,
            payload: {
                customer: reservationState.customer,
                reservation: updatedReservation,
                reservedResource: reservationState.reservedResource,
            },
        };
        const filterChangeAction: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.addReservationId,
            payload: { reservationId: reservation.reservationId },
        };
        yield put(filterChangeAction);
        yield put(reservationAction);
        if (paymentTermSetId) {
            const loadingAction: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.loadingAction,
                payload: {
                    isInstalmentLoading: false,
                },
            };
            yield put(loadingAction);
        }
        yield put({ type: ActionType.ReservationUpdated, payload: {} });
    } catch (error) {
        // eslint-disable-next-line no-console
        globalLogger.error(error);
        yield put(createReservationErrorAction(error));
    }
}

function* setReservationState(action: SetReservationAction) {
    try {
        const { reservationId } = action.payload;
        const filter = yield select(SagaUtils.getDynamicFilter);
        const env = yield call(getMxtsEnv, globalApiContext(), filter.currentLocale);
        const reservation = yield call(MxtsApi.getReservation, env, {}, [{ key: "reservationId", value: reservationId }]);
        const customer: Customer = yield call(MxtsApi.getCustomer as any, env, {}, [{ key: "customerId", value: reservation.customerId }]);
        const reservedResource = yield call(MxtsApi.getReservedResource, env, {}, [{ key: "reservationId", value: reservationId }]);
        const reservationAction: ReservationAction = {
            type: ActionType.Reservation,
            actionType: ReservationActionType.created,
            payload: {
                customer,
                reservation,
                reservedResource,
            },
        };
        yield put(reservationAction);
    } catch (error) {
        // eslint-disable-next-line no-console
        globalLogger.error(error);
        yield put(createReservationErrorAction(error));
    }
}

function createReservationErrorAction(error: Error): ReservationAction {
    return {
        type: ActionType.Reservation,
        actionType: ReservationActionType.error,
        payload: {
            error,
        },
    };
}

function* loadReservationReduxData(action: LoadReservationReduxDataAction) {
    try {
        const filter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
        const updatedDynamicFilter: Partial<DynamicFilter> = yield call(getDynamicFilterFromReservation, filter, action.payload.apiContext);
        if (Object.keys(updatedDynamicFilter).length) {
            yield put({
                type: ActionType.DynamicFilter,
                actionType: ActionType.DynamicFilter,
                filter: dynamicFilterType.blendFilters,
                payload: updatedDynamicFilter,
            });
        }
    } catch (error) {
        globalLogger.error(error);
    }
}

export function* watchCreateReservation(): Generator<ForkEffect, void, boolean> {
    yield takeEvery(ActionType.CreateReservation, createReservationDetails);
}

export function* watchUpdateReservation() /* : Generator<ForkEffect, void, boolean> */ {
    // Use a request channel to make sure only 1 updateReservation action can be executed at a time. If you execute multiple in parallel then changes could conflict with each other.
    const requestChan: Channel<Action> = yield actionChannel(ActionType.UpdateReservation);
    while (true) {
        yield call(updateReservationDetails, yield take(requestChan));
    }
}

export function* watchGetReservationState(): Generator<ForkEffect, void, boolean> {
    yield takeEvery(ActionType.SetReservationState, setReservationState);
}

export function* watchLoadReservationReduxData() {
    const requestChan: Channel<Action> = yield actionChannel(ActionType.LoadReservationReduxData);
    while (true) {
        yield call(loadReservationReduxData, yield take(requestChan));
    }
}
