import { ContextSlice, PluginSpec } from "../";

import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

export const DynamicPlugin: PluginSpec<Record<string, unknown>> = {
    id: "dynamic-plugin",
    name: getI18nLocaleObject(namespaceList.pluginBuiltinPage, "dynamicPlugin"),
    description: getI18nLocaleObject(namespaceList.pluginBuiltinPage, "dynamicPluginDescription"),
    widgets: [
        {
            id: "accokindWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-accoKind" */ "./accoKind"));
                context?.chunkExtractor?.addChunk("dynamic-accoKind");
                return await ref.load();
            },
            targetName: "accoKindWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "AccokindWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-accommodationKind" */ "./accommodationKind"));
                context?.chunkExtractor?.addChunk("dynamic-accommodationKind");
                return await ref.load();
            },
            targetName: "accokindWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resortWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-resort" */ "./resort"));
                context?.chunkExtractor?.addChunk("dynamic-resort");
                return await ref.load();
            },
            targetName: "resortWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "locationFacetWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-locationFacet" */ "./location-facet"));
                context?.chunkExtractor?.addChunk("dynamic-locationFacet");
                return await ref.load();
            },
            targetName: "locationFacetWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "stayDurationWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-stayDuration" */ "./stayDuration"));
                context?.chunkExtractor?.addChunk("stayDuration");
                return await ref.load();
            },
            targetName: "stayDurationWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "subjectWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-subject" */ "./subject"));
                context?.chunkExtractor?.addChunk("dynamic-subject");
                return await ref.load();
            },
            targetName: "subjectWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "availabilityDateWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-availabilityDate" */ "./availabilityDate"));
                context?.chunkExtractor?.addChunk("dynamic-availabilityDate");
                return await ref.load();
            },
            targetName: "availabilityDateWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "priceRangeWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-priceRange" */ "./priceRange"));
                context?.chunkExtractor?.addChunk("dynamic-priceRange");
                return await ref.load();
            },
            targetName: "priceRangeWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "facilityWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-facility" */ "./facility"));
                context?.chunkExtractor?.addChunk("dynamic-facility");
                return await ref.load();
            },
            targetName: "facilityWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "freeInputFilterWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-freeInputFilter" */ "./freeInputFilter"));
                context?.chunkExtractor?.addChunk("dynamic-freeInputFilter");
                return await ref.load();
            },
            targetName: "freeInputFilterWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "search-amenity-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-searchAmenities" */ "./search-amenities"));
                context?.chunkExtractor?.addChunk("dynamic-searchAmenities");
                return await ref.load();
            },
            targetName: "searchAmenitiesWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "uspAmenitiesWidgetWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-uspAmenities" */ "./uspAmenities/UspAmenities"));
                context?.chunkExtractor?.addChunk("dynamic-uspAmenities");
                return await ref.load();
            },
            targetName: "uspAmenitiesWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "amenitiesWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-amenities" */ "./amenities"));
                context?.chunkExtractor?.addChunk("dynamic-amenities");
                return await ref.load();
            },
            targetName: "amenitiesWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "descriptionWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-description" */ "./description/Description"));
                context?.chunkExtractor?.addChunk("dynamic-description");
                return await ref.load();
            },
            targetName: "descriptionWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "bedroomBathroomWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-bedroomBathroom" */ "./bedroom-bathroom"));
                context?.chunkExtractor?.addChunk("dynamic-bedroomBathroom");
                return await ref.load();
            },
            targetName: "bedroomBathroomWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "GuestReviewRatingWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-guestReviewRating" */ "../shared/guestReviewRating"));
                context?.chunkExtractor?.addChunk("shared-guestReviewRating");
                return await ref.load();
            },
            targetName: "sharedGuestReviewRatingWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "SingleReviewRatingWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-singleReviewRating" */ "./singleReviewRating"));
                context?.chunkExtractor?.addChunk("dynamic-singleReviewRating");
                return await ref.load();
            },
            targetName: "singleReviewRatingWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "specialWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-special" */ "./special"));
                context?.chunkExtractor?.addChunk("dynamic-special");
                return await ref.load();
            },
            targetName: "specialWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "specialSearchFacetWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-specialSearchFacet" */ "./specialSearchFacet"));
                context?.chunkExtractor?.addChunk("dynamic-specialSearchFacet");
                return await ref.load();
            },
            targetName: "specialSearchFacetWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "VoucherWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-voucher" */ "./voucher"));
                context?.chunkExtractor?.addChunk("dynamic-voucher");
                return await ref.load();
            },
            targetName: "voucherWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "ReservationContainerWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-reservationContainer" */ "./reservation/container"));
                context?.chunkExtractor?.addChunk("dynamic-reservationContainer");
                return await ref.load();
            },
            targetName: "reservationContainerWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "ReservationPickerWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-reservationPicker" */ "./reservation/picker"));
                context?.chunkExtractor?.addChunk("dynamic-reservationPicker");
                return await ref.load();
            },
            targetName: "reservationPickerWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "ReservationCancellationWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-reservationCancellation" */ "./reservation/cancellation/index"));
                context?.chunkExtractor?.addChunk("dynamic-reservationCancellation");
                return await ref.load();
            },
            targetName: "reservationCancellationWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "ReservationDatesAndTimingsWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-reservationDatesAndTimings" */ "./reservation/datesAndTimings"));
                context?.chunkExtractor?.addChunk("dynamic-reservationDatesAndTimings");
                return await ref.load();
            },
            targetName: "reservationDatesAndTimingsWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "ReservationDaysCountdownWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-reservationDaysCountdown" */ "./reservation/daysCountdown"));
                context?.chunkExtractor?.addChunk("dynamic-reservationDaysCountdown");
                return await ref.load();
            },
            targetName: "reservationDaysCountdownWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "ReservationTransactionOverviewWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-transactionOverview" */ "./reservation/transactionOverview"));
                context?.chunkExtractor?.addChunk("dynamic-transactionOverview");
                return await ref.load();
            },
            targetName: "reservationTransactionOverviewWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "UnitPreferenceWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-unitPreference" */ "./unitPreference"));
                context?.chunkExtractor?.addChunk("dynamic-unitPreference");
                return await ref.load();
            },
            targetName: "unitPreferenceWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "OwnerUnitPickerWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerUnitPicker" */ "./owner/unit/picker/index"));
                context?.chunkExtractor?.addChunk("dynamic-ownerUnitPicker");
                return await ref.load();
            },
            targetName: "ownerUnitPickerWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "OwnerBookChoiceSelectionWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerChoiceSelection" */ "./owner/book/choiceSelection"));
                context?.chunkExtractor?.addChunk("dynamic-ownerChoiceSelection");
                return await ref.load();
            },
            targetName: "ownerBookChoiceSelectionWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "OwnerReservationsOverviewWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerReservationOverview" */ "./owner/reservations/overview/index"));
                context?.chunkExtractor?.addChunk("dynamic-ownerReservationOverview");
                return await ref.load();
            },
            targetName: "ownerReservationsOverviewWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "ownerDocumentInvoiceWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerInvoicesAndDocuments" */ "./ownerInvoicesAndDocuments"));
                context?.chunkExtractor?.addChunk("dynamic-ownerInvoicesAndDocuments");
                return await ref.load();
            },
            targetName: "ownerDocumentAndInvoiceWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "OccupancyWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerOccupancy" */ "./owner/occupancy"));
                context?.chunkExtractor?.addChunk("dynamic-ownerOccupancy");
                return await ref.load();
            },
            targetName: "occupancyWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "OwnerRevenueStreamsWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerRevenueStreams" */ "./owner/revenue-streams"));
                context?.chunkExtractor?.addChunk("dynamic-ownerRevenueStreams");
                return await ref.load();
            },
            targetName: "ownerRevenueStreamsWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "ContractStatusWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerContract" */ "./owner/contract"));
                context?.chunkExtractor?.addChunk("dynamic-ownerContract");
                return await ref.load();
            },
            targetName: "contractStatusWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "ownerMaintenanceWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerMaintenance" */ "./owner/ownerMaintenance"));
                context?.chunkExtractor?.addChunk("dynamic-ownerMaintenance");
                return await ref.load();
            },
            targetName: "ownerMaintenanceWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "NotificationWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-notification" */ "../page/notification"));
                context?.chunkExtractor?.addChunk("page-notification");
                return await ref.load();
            },
            targetName: "notificationWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "DynamicMyEnvContainerWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-containerMyenv" */ "./container/myenv"));
                context?.chunkExtractor?.addChunk("dynamic-containerMyenv");
                return await ref.load();
            },
            targetName: "dynamicMyEnvContainerWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
            container: true,
        },
        {
            id: "dynamicSearchButtonWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-dynamicSearchButton" */ "../page/dynamicSearchButton"));
                context?.chunkExtractor?.addChunk("page-dynamicSearchButton");
                return await ref.load();
            },
            targetName: "dynamicSearchButtonWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "searchResults",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-searchResults" */ "../page/searchResults"));
                context?.chunkExtractor?.addChunk("page-searchResults");
                return await ref.load();
            },
            targetName: "searchResultWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
            container: true,
        },
        {
            id: "search",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-search" */ "../page/search"));
                context?.chunkExtractor?.addChunk("page-search");
                return await ref.load();
            },
            targetName: "searchWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
            container: true,
        },
        {
            id: "searchSortPanel",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-sortingPanel" */ "./sorting-panel"));
                context?.chunkExtractor?.addChunk("dynamic-sortingPanel");
                return await ref.load();
            },
            targetName: "sortingPanelWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "free-search",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-freeSearch" */ "../page/free-search"));
                context?.chunkExtractor?.addChunk("page-freeSearch");
                return await ref.load();
            },
            targetName: "freeSearchWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "dynamic-free-search",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-freeSearch" */ "./free-search"));
                context?.chunkExtractor?.addChunk("dynamic-freeSearch");
                return await ref.load();
            },
            targetName: "dynamicFreeSearchWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "search-direct-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-searchDirectly" */ "./search-directly"));
                context?.chunkExtractor?.addChunk("dynamic-searchDirectly");
                return await ref.load();
            },
            targetName: "searchDirectlyWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "displayGridListMapWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-displayGridListMap" */ "./display-grid-list-map"));
                context?.chunkExtractor?.addChunk("dynamic-displayGridListMap");
                return await ref.load();
            },
            targetName: "displayGridListMapWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "criteriaPanelWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-criteriaPanel" */ "./criteria-panel"));
                context?.chunkExtractor?.addChunk("dynamic-criteriaPanel");
                return await ref.load();
            },
            targetName: "criteriaPanelWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "currencySelectorWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-currencySelector" */ "./currency-selector"));
                context?.chunkExtractor?.addChunk("dynamic-currencySelector");
                return await ref.load();
            },
            targetName: "currencySelectorWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "containerWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-container" */ "./container/container"));
                context?.chunkExtractor?.addChunk("dynamic-container");
                return await ref.load();
            },
            targetName: "ContainerWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
            container: true,
        },
        {
            id: "typesearch-container-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-typeSearchContainer" */ "./typesearchContainer"));
                context?.chunkExtractor?.addChunk("dynamic-typeSearchContainer");
                return await ref.load();
            },
            targetName: "typesearchContainerWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
            container: true,
        },
        {
            id: "unitsearch-container-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-unitSearchContainer" */ "./unitsearchContainer"));
                context?.chunkExtractor?.addChunk("dynamic-unitSearchContainer");
                return await ref.load();
            },
            targetName: "unitsearchContainerWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "activitySearchContainerWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-activitySearchContainer" */ "./activitySearchContainer"));
                context?.chunkExtractor?.addChunk("dynamic-activitySearchContainer");
                return await ref.load();
            },
            targetName: "activitySearchContainerWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
            container: true,
        },
        {
            id: "agenda",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-agenda" */ "../page/agenda"));
                context?.chunkExtractor?.addChunk("page-agenda");
                return await ref.load();
            },
            targetName: "agendaWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "recent",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-recent" */ "../page/recent"));
                context?.chunkExtractor?.addChunk("page-recent");
                return await ref.load();
            },
            targetName: "recentWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "bathroomWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-bathroom" */ "./bathroom"));
                context?.chunkExtractor?.addChunk("dynamic-bathroom");
                return await ref.load();
            },
            targetName: "bathroomWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "bedroomWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-bedroom" */ "./bedroom"));
                context?.chunkExtractor?.addChunk("dynamic-bedroom");
                return await ref.load();
            },
            targetName: "bedroomWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "DoorlockWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-doorlock" */ "./doorlock"));
                context?.chunkExtractor?.addChunk("dynamic-doorlock");
                return await ref.load();
            },
            targetName: "doorlockWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "BarcodeWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-barcode" */ "./barcode"));
                context?.chunkExtractor?.addChunk("dynamic-barcode");
                return await ref.load();
            },
            targetName: "barcodeWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "emailPreferencesWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-emailPreferences" */ "./emailPreferences"));
                context?.chunkExtractor?.addChunk("dynamic-emailPreferences");
                return await ref.load();
            },
            targetName: "emailPreferencesWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "SearchResultsCounterWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-searchResultsCounter" */ "./searchResultsCounter"));
                context?.chunkExtractor?.addChunk("dynamic-searchResultsCounter");
                return await ref.load();
            },
            targetName: "searchResultsCounterWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "OwnerAccommodationLinksWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerAccommodationLinks" */ "./owner/accommodation-links"));
                context?.chunkExtractor?.addChunk("dynamic-ownerAccommodationLinks");
                return await ref.load();
            },
            targetName: "ownerAccommodationLinksWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "QualityLevelFilterWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-qualityFilter" */ "./qualityFilter"));
                context?.chunkExtractor?.addChunk("dynamic-qualityFilter");
                return await ref.load();
            },
            targetName: "qualityLevelFilterWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "step-selector",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-stepSelector" */ "../page/step-selector"));
                context?.chunkExtractor?.addChunk("page-stepSelector");
                return await ref.load();
            },
            targetName: "stepSelectorWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "prevnext",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-prevNext" */ "../page/prev-next"));
                context?.chunkExtractor?.addChunk("page-prevNext");
                return await ref.load();
            },
            targetName: "prevnextWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "guestWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-guestInterfaceWidget" */ "../page/guestInterfaceWidget"));
                context?.chunkExtractor?.addChunk("page-guestInterfaceWidget");
                return await ref.load();
            },
            targetName: "guest",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
            container: true,
        },
        {
            id: "userLogin",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-userLogin" */ "../page/userLogin"));
                context?.chunkExtractor?.addChunk("page-userLogin");
                return await ref.load();
            },
            targetName: "userLogin",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
            container: true,
        },
        {
            id: "Additions",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-additions" */ "./additions"));
                context?.chunkExtractor?.addChunk("dynamic-additions");
                return await ref.load();
            },
            targetName: "additionsWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "AddOns",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-addOns" */ "./add-ons"));
                context?.chunkExtractor?.addChunk("dynamic-addOns");
                return await ref.load();
            },
            targetName: "addOnsWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "customerBillWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-bill" */ "./Bill"));
                context?.chunkExtractor?.addChunk("dynamic-bill");
                return await ref.load();
            },
            targetName: "customerBillWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "CommunicationDocumentsWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-communicationDocuments" */ "../page/communicationDocuments"));
                context?.chunkExtractor?.addChunk("page-communicationDocuments");
                return await ref.load();
            },
            targetName: "communicationDocumentsWidget",
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "priceCompareWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-priceCompare" */ "./priceCompare"));
                context?.chunkExtractor?.addChunk("dynamic-priceCompare");
                return await ref.load();
            },
            targetName: "priceCompareWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "infotext-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-infotext" */ "./infotext"));
                context?.chunkExtractor?.addChunk("dynamic-infotext");
                return await ref.load();
            },
            targetName: "infotextWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "instalments-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-instalment" */ "./payment-widgets/instalment"));
                context?.chunkExtractor?.addChunk("dynamic-instalment");
                return await ref.load();
            },
            targetName: "instalmentsWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "payment-term-set-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-paymentTermSet" */ "./payment-widgets/payment-term-set"));
                context?.chunkExtractor?.addChunk("dynamic-paymentTermSet");
                return await ref.load();
            },
            targetName: "paymentTermSetsWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "payment-methods-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-paymentMethods" */ "./payment-widgets/payment-methods"));
                context?.chunkExtractor?.addChunk("dynamic-paymentMethods");
                return await ref.load();
            },
            targetName: "paymentMethodsWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "accoPrice",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-accoPrice" */ "./accoPrice"));
                context?.chunkExtractor?.addChunk("dynamic-accoPrice");
                return await ref.load();
            },
            targetName: "accoPriceWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "dynamic-status-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-dynamicStatus" */ "./payment-widgets/dynamic-status"));
                context?.chunkExtractor?.addChunk("dynamic-dynamicStatus");
                return await ref.load();
            },
            targetName: "dynamicStatusWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "overview-panel",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-overviewPanel" */ "./overview-panel"));
                context?.chunkExtractor?.addChunk("dynamic-overviewPanel");
                return await ref.load();
            },
            targetName: "overviewPanelWidget",
            type: "page",
            parameters: ["page"],
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
            container: true,
        },
        {
            id: "overviewNameWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-overviewName" */ "./overview-panel/overview-name"));
                context?.chunkExtractor?.addChunk("dynamic-overviewName");
                return await ref.load();
            },
            targetName: "overviewNameWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "overviewImageWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-overviewImage" */ "./overview-panel/overview-image"));
                context?.chunkExtractor?.addChunk("dynamic-overviewImage");
                return await ref.load();
            },
            targetName: "overviewImageWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "overviewSleepsWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-overviewSleeps" */ "./overview-panel/overview-sleeps"));
                context?.chunkExtractor?.addChunk("dynamic-overviewSleeps");
                return await ref.load();
            },
            targetName: "overviewSleepsWidget",
            type: "page",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "CustomerSummaryWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-customerSummary" */ "./customerSummary"));
                context?.chunkExtractor?.addChunk("dynamic-customerSummary");
                return await ref.load();
            },
            targetName: "customerSummaryWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "locationsearch-container-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-locationSearchContainer" */ "./locationSearchContainer"));
                context?.chunkExtractor?.addChunk("dynamic-locationSearchContainer");
                return await ref.load();
            },
            targetName: "locationSearchContainerWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
            container: true,
        },
        {
            id: "checkInCheckOut",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-checkInCheckOut" */ "../shared/checkInCheckOut"));
                context?.chunkExtractor?.addChunk("shared-checkInCheckOut");
                return await ref.load();
            },
            targetName: "checkInCheckOutWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
    ],
};

export default DynamicPlugin;
