export function cancelable<T>(prom: Promise<T>): [Promise<T>, () => void] {
    let isCanceled = false;
    const cancelableProm: Promise<T> = new Promise((resolve, reject) => {
        prom.then(
            (res) => {
                if (!isCanceled) {
                    resolve(res);
                }
            },
            (err) => {
                if (!isCanceled) {
                    reject(err);
                }
            }
        );
    });
    function cancel(): void {
        isCanceled = true;
    }
    return [cancelableProm, cancel];
}
