import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "krimV2",
    name: "Krim Theme V2",
    stylesheets: ["/styles/themes/krimv2/krim-admin.css"],
    frontendStylesheets: ["/styles/themes/krimv2/krim.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "use-alternative-modal-header",
            name: "Use alternative modal header",
            targets: ["revealer-link"],
            classnames: ["modal-header-2"],
        },
        {
            id: "resort-location-styling",
            name: "Use alternative location widget styling",
            targets: ["results-panel-location"],
            classnames: ["accommodation-location-2"],
        },
        {
            id: "font-size-18-px",
            name: "Font-size 1rem (18px)",
            targets: ["description"],
            classnames: ["font-18"],
        },
        {
            id: "text-ellipsis",
            name: "Add text ellipsis (98% width)",
            targets: ["description"],
            classnames: ["ellipsis"],
        },
        // Krim specific menu searchbox
        {
            id: "search-input",
            name: "Menu searchbox",
            targets: ["menu", "menu-template"],
            classnames: ["menu-searchbox"],
        },
        // Krim Assets for icons (beating heart)
        {
            id: "beating-heart",
            name: "Show the beating texel heart",
            targets: ["webcontent"],
            classnames: ["beating texel"],
        },
        {
            id: "special-card-panel",
            name: "Special card lower panel design",
            targets: ["webcontent"],
            classnames: ["special-card-lower-panel"],
        },
        {
            id: "body-x-small",
            name: "body-x-small",
            targets: ["webcontent"],
            classnames: ["body-x-small"],
        },
        {
            id: "call-out",
            name: "Call-out-free-cancellation",
            targets: ["webcontent"],
            classnames: ["call-out"],
        },
        // TODO:- need to look at classnames and move those classes from base to Krim theme css files
        // to make base more cleaner with generic things only
        {
            id: "use-as-header-h2",
            name: "Use as header h2",
            targets: ["webcontent"],
            classnames: ["use-as-header-h2"],
        },
        {
            id: "use-as-header-h3",
            name: "Use as header h3",
            targets: ["webcontent"],
            classnames: ["use-as-header-h3"],
        },
        // Divider widget
        {
            id: "divider-bottom-shadow",
            name: "Add bottom shadow",
            targets: ["divider"],
            classnames: ["divider--bottom__shadow"],
        },
        // Single-Review-Rating
        {
            id: "review-above-content",
            name: "Review above content",
            targets: ["single-review-rating"],
            classnames: ["review-above-content"],
        },
        // New DQS(dynamic quick search) design
        {
            id: "quicksearch-design",
            name: "Quick search design",
            targets: ["container", "subject"],
            classnames: ["quicksearch-design"],
        },
        // content
        {
            id: "style-content-on-image",
            name: "Style content on image",
            targets: ["container"],
            classnames: ["style-content-image"],
        },
        // quicksearch for mobile
        {
            id: "quicksearch-design-mobile",
            name: "DQS mobile version",
            targets: ["container"],
            classnames: ["dqs-mobile-version"],
        },
        // Website search bar design
        {
            id: "website-search-icon",
            name: "Website search icon",
            targets: ["menu"],
            classnames: ["website-search-icon"],
        },
        // Website search results design
        {
            id: "website-search-results",
            name: "Website search results",
            targets: ["website-search"],
            classnames: ["website-search-results"],
        },
        // activity planner
        {
            id: "activity-planner-specific",
            name: "Activity planner specific styling",
            targets: ["container"],
            classnames: ["activity-planner-specific"],
        },
        // Payment status
        {
            id: "payment-my-env-styling",
            name: "My env payment styling",
            targets: ["payment-status"],
            classnames: ["payment"],
        },
        // appartment texel styling
        {
            id: "at-quickesearch",
            name: "Appartment texel DQS styling",
            targets: ["container"],
            classnames: ["at-quickesearch-design"],
        },
        // Single date picker Activity
        {
            id: "activity-single-picker",
            name: "Styling for single picker Activity",
            targets: ["availability-date"],
            classnames: ["activity-single-picker"],
        },
    ],
};

export default theme;
