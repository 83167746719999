import { getClientIdFromToken, getClientName } from "../admin/utils";

import { AppProps } from "../App";
import { CMSProviderProperties } from "../../containers/cmsProvider.types";
import { MixPanelCoreEventEnum } from "./mixpanel.enum";
import { getMxtsClientId } from "../../utils/authToken.util";
import { getMxtsEnv } from "../../plugins/mxts";
import { getPermissions } from "../../utils/permissions.utils";
import mixpanel from "mixpanel-browser";

const MIX_PANEL_DEV_TOKEN = "44c8bae4ce0fe2e2be93a13702619f23";
const MIX_PANEL_ACC_TOKEN = "a2d90afae1af540862d6bfa7172980bd";
const MIX_PANEL_PROD_TOKEN = "fb32078eb2ce66bbc60f3298a337d95b";
const MIX_PANEL_STAGING_TOKEN = "a5bfa76af3ca55d3e9a8613e7bb1ecde";

interface MixpanelBaseProps {
    [key: string]: string | number;
}
let property: MixpanelBaseProps = {};
// Define the function to initialize Mixpanel
export const initializeMixPanel = (activeEnvironment: string, enableDebugMode = false, props: AppProps) => {
    try {
        let token: string | undefined;
        switch (activeEnvironment) {
            case "dev":
                token = MIX_PANEL_DEV_TOKEN;
                break;
            case "acc":
                token = MIX_PANEL_ACC_TOKEN;
                break;
            case "prod":
                token = MIX_PANEL_PROD_TOKEN;
                break;
            case "staging":
                token = MIX_PANEL_STAGING_TOKEN;
                break;
            default:
                token = MIX_PANEL_DEV_TOKEN;
                break;
        }
        if (token) {
            mixpanel.init(token, { debug: enableDebugMode, persistence: "localStorage" });
            getUserInfo(props);
        } else {
            console.error("MixPanel token is undefined");
        }
    } catch (error) {
        console.info("Failed to load MixPanel...");
        console.error(error);
    }
};

const getUserInfo = (props: AppProps) => {
    const { context, env } = props;
    const concernName = env?.concern;

    (async () => {
        const clientId = getMxtsClientId() || getClientIdFromToken();
        registerUserInMixPanel(clientId);
        const env = await getMxtsEnv(context);
        const UserType = (await getPermissions(context))?.webmanager?.clientType;
        const concern = concernName && (await context.mxtsApi.concern(env, { shortName: concernName }))?.content[0];
        const concernId = (concern && concern?.concernId) || "";
        property = {
            platform: getCurrentPlatform(context) || "",
            concern: concernName || "",
            concernId: concernId || "",
            applicationName: "Web Manager",
            name: getClientName() || "",
            clientId: clientId || "",
            userType: UserType || "",
            module_name: "MCMS-Web-Manager",
            PageName: context?.location?.pathname || "",
        };
    })().catch((e) => {
        console.error("Failed to load mixpanel user info", e);
    });
};

const getCurrentPlatform = (context: CMSProviderProperties) => {
    const device = context.device;
    if (navigator.userAgent) {
        const checkIfIosDevice = () => /iPad|iPhone|Macintosh/.test(navigator.userAgent);
        if (device?.isDesktop) {
            return "Web";
        }
        if (device?.isMobile && !checkIfIosDevice()) {
            return "Android";
        }
        if (checkIfIosDevice()) {
            return "iOS";
        }
        return "Web";
    }
};

const registerUserInMixPanel = (uniqueId: number | null) => {
    uniqueId && mixpanel.identify(uniqueId?.toString());
};

export const pageViewedEvent = () => {
    if (property?.platform) {
        mixpanel.track(MixPanelCoreEventEnum.PAGE_VIEW, property);
    }
};
