import { Action } from "./";

export enum GuestFormActionType {
    validate = "validate",
    change = "change",
    isValidationCheckList = "isValidationCheckList",
    updateMinimalMandatoryFields = "updateMinimalMandatoryFields",
    updateAgreeTerms = "updateAgreeTerms",
    updateDraftValues = "updateDraftValues",
}

export interface GuestFormAction extends Action {
    actionType: GuestFormActionType;
    payload: any;
}
