import * as MXTS from "@maxxton/cms-mxts-api";

import { CmsApi, CmsApiWrapper, Locale, LocaleApi, MaintenanceApi, Site, SiteApi, WithId, defaultThemeProps } from "@maxxton/cms-api";
import { FormSpec, InputSpecTheme, SomeInputGroup } from "../form-specs";
import { I18nLocaleObject, getI18nLocaleObject, getI18nLocaleString } from "../i18n";
import { PluginSpec, loadPlugin, themeOptions } from "./";

import { SelectOption } from "../form-specs/formSpec.types";
import { getReservationCategorySelectOptions } from "../utils/reservationCategories.util";
import { isClientSide } from "../utils/generic.util";
import namespaceList from "../i18n/namespaceList";
import { tz } from "moment-timezone";

export interface Theme {
    themeId: string;
    brandColor: string;
    brandAltColor: string;
    ctaColor: string;
    neutralColor: string;
    neutralAltColor: string;
    baseFontSize: string;
    baseLineHeight: string;
    spacingSmall: string;
    spacingLarge: string;
    spacingMedium: string;
    spacingXL: string;
    baseBorderRadius: string;
    primaryButtonColor: string;
    primaryButtonWidth: string;
    primaryButtonHeight: string;
    primaryButtonBorderRadius: string;
    primaryButtonTextColor: string;
    secondaryButtonColor: string;
    secondaryButtonWidth: string;
    secondaryButtonHeight: string;
    secondaryButtonBorderRadius: string;
    secondaryButtonTextColor: string;
}

interface ThemeOptions<E, P extends keyof E, TE> {
    variable: P;
    label?: I18nLocaleObject | string;
    content: Array<SomeInputGroup<TE, keyof TE>>;
}

export function themeSpec<E extends { P: TE[] }, P extends keyof E, TE>(options: ThemeOptions<E, P, TE>): InputSpecTheme<E, P, TE> {
    const { variable, label, content } = options;
    return {
        type: "theme",
        label,
        variable,
        content,
    };
}

export interface CMSOptions {
    locale: string;
    selectedLocales: Array<{
        value: string;
        text: string;
    }>;
    timezoneList: string;
    theme: Theme;
    initializeCrawlingButton: string;
    initializeCrawling: Array<{
        value: string;
        text: string;
    }>;
    distributionChannelId: string;
    reservationCategoryId: number;
    env: string;
    autoAssignUnitToReservation?: boolean;
    sortUnitsOnVSI?: boolean;
    alwaysAddUnitPreferenceCost?: boolean;
    addTrailingSlashToAllUrls?: boolean;
    enableTrailingSlashRedirectBehavior?: boolean;
    disableAutoUpdatePreview?: boolean;
    minBookAge?: number;
    disableRequiredFieldSet?: boolean;
    disableCacheWhenLoggedIn?: boolean;
}

async function getEnvironment(item: CMSOptions) {
    const MXTSPlugin = {
        id: "mxts",
        name: getI18nLocaleObject(namespaceList.pluginMxts, "mxts"),
        description: getI18nLocaleObject(namespaceList.pluginMxts, "mxtsDescription"),
        widgets: [],
    };
    const languageCode = item.locale && `${item.locale}_${item.locale.toUpperCase()}`;
    const plugin = await loadPlugin(CmsApi, MXTSPlugin);
    const ops = plugin.options;
    const env = ops.env;
    const [concern, type] = env ? env.split("-") : [];
    const options = {
        retryCount: 3,
        env: {
            baseUrl: MXTS.baseUrl + `/${env}`,
            concern,
            type,
        },
        locale: languageCode as MXTS.LanguageLocale,
    };
    return options;
}

async function distributionChannelOptions(item: CMSOptions): Promise<Array<SelectOption<number>>> {
    const options = await getEnvironment(item);
    if (!options.env.concern) {
        return [];
    }

    const dcs1 = await MXTS.MxtsApi.distributionChannels(options, { size: 999, page: 0 });
    const dcs2 = await MXTS.MxtsApi.distributionChannels(options, { size: 999, page: 1 });
    const dcs3 = await MXTS.MxtsApi.distributionChannels(options, { size: 999, page: 2 });
    const dcs = [...dcs1.content, ...dcs2.content, ...dcs3.content];
    return dcs
        .map(
            (dc: MXTS.DistributionChannel): SelectOption<number> => ({
                value: dc.distributionChannelId,
                label: dc.name,
            })
        )
        .sort((dc1, dc2) => {
            const dc1Label = dc1.label as string;
            const dc2Label = dc2.label as string;
            if (dc1Label.toLocaleLowerCase() < dc2Label.toLocaleLowerCase()) {
                return -1;
            }
            if (dc1Label.toLocaleLowerCase() > dc2Label.toLocaleLowerCase()) {
                return 1;
            }
            return 0;
        });
}

const {
    themeId,
    brandColor,
    brandAltColor,
    ctaColor,
    neutralColor,
    neutralAltColor,
    baseFontSize,
    baseLineHeight,
    spacingSmall,
    spacingLarge,
    spacingMedium,
    spacingXL,
    baseBorderRadius,
    primaryButtonBorderRadius,
    primaryButtonColor,
    primaryButtonWidth,
    primaryButtonHeight,
    primaryButtonTextColor,
    secondaryButtonBorderRadius,
    secondaryButtonColor,
    secondaryButtonWidth,
    secondaryButtonHeight,
    secondaryButtonTextColor,
} = defaultThemeProps;

const pluginOptionsForm: FormSpec<CMSOptions> = {
    id: "cms-options",
    name: getI18nLocaleObject(namespaceList.pluginSettings, "settingsDescription"),
    pluralName: getI18nLocaleObject(namespaceList.pluginSettings, "settingsDescription"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                type: "select",
                                variable: "locale",
                                label: getI18nLocaleObject(namespaceList.pluginSettings, "locale"),
                                default: "en",
                                async optionList() {
                                    const localeNamesList: Locale[] = [];
                                    const localeList = await LocaleApi.find();
                                    localeList.forEach((key) => {
                                        localeNamesList.push(key);
                                    });
                                    return localeNamesList.map(
                                        (locale: Locale): SelectOption<string> => ({
                                            label: locale.name,
                                            value: locale.code,
                                        })
                                    );
                                },
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "localeDescription"),
                            },
                            {
                                variable: "selectedLocales",
                                type: "multiselect",
                                label: getI18nLocaleObject(namespaceList.admin, "selectLocales"),
                                async optionList() {
                                    const localeNamesList: Locale[] = [];
                                    const localeList = await LocaleApi.find();
                                    localeList.forEach((key) => {
                                        localeNamesList.push(key);
                                    });
                                    return localeNamesList.map((locale: Locale) => ({
                                        text: locale.name,
                                        value: locale.code,
                                    }));
                                },
                            },
                            {
                                variable: "timezoneList",
                                label: getI18nLocaleObject(namespaceList.admin, "timezoneList"),
                                type: "select",
                                async optionList() {
                                    const timezoneList = tz.names();
                                    return timezoneList.map(
                                        (zone: string): SelectOption<string> => ({
                                            label: "(UTC " + tz(zone).format("Z") + ")" + zone,
                                            value: zone,
                                        })
                                    );
                                },
                            },
                            {
                                variable: "distributionChannelId",
                                label: getI18nLocaleObject(namespaceList.admin, "distributionChannel"),
                                type: "select",
                                optionList: (item: CMSOptions) => distributionChannelOptions(item),
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
                            },
                            {
                                variable: "reservationCategoryId",
                                label: getI18nLocaleObject(namespaceList.admin, "reservationCategoryId"),
                                type: "autocomplete" as const,
                                isClearable: false,
                                options: async (item: CMSOptions) => getReservationCategorySelectOptions(item),
                            },
                            {
                                variable: "minBookAge",
                                label: getI18nLocaleObject(namespaceList.admin, "minBookAge"),
                                type: "number",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "theming"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "previewTheme"),
                            },
                            themeSpec({
                                label: getI18nLocaleObject(namespaceList.pluginSettings, "customizeTheme"),
                                variable: "theme",
                                content: [
                                    [
                                        {
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "themes"),
                                            type: "paragraph",
                                        },
                                        {
                                            type: "select",
                                            variable: "themeId",
                                            default: themeId,
                                            label: getI18nLocaleObject(namespaceList.admin, "selectTheme"),
                                            optionList: () => themeOptions(),
                                        },
                                    ],
                                    [
                                        {
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "colors"),
                                            type: "paragraph",
                                        },
                                        {
                                            variable: "brandColor",
                                            default: brandColor,
                                            label: "Brand Color",
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "brandAltColor",
                                            default: brandAltColor,
                                            label: "Brand Alt Color",
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "ctaColor",
                                            default: ctaColor,
                                            label: "CTA Color",
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "neutralColor",
                                            default: neutralColor,
                                            label: "Neutral Color",
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "neutralAltColor",
                                            default: neutralAltColor,
                                            label: "Neutral Alt Color",
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                    ],
                                    [
                                        {
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "texts"),
                                            type: "paragraph",
                                        },
                                        {
                                            variable: "baseFontSize",
                                            default: baseFontSize,
                                            label: "Base font size",
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "baseLineHeight",
                                            default: baseLineHeight,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "baseLineHeight"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                    ],
                                    [
                                        {
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "spacing"),
                                            type: "paragraph",
                                        },
                                        {
                                            variable: "spacingSmall",
                                            default: spacingSmall,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "spaceingSmall"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "spacingMedium",
                                            default: spacingMedium,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "spacingMedium"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "spacingLarge",
                                            default: spacingLarge,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "spacingLarge"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "spacingXL",
                                            default: spacingXL,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "spacingXL"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                    ],
                                    [
                                        {
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "borders"),
                                            type: "paragraph",
                                        },
                                        {
                                            variable: "baseBorderRadius",
                                            default: baseBorderRadius,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "baseBorderRadius"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                    ],
                                    [
                                        {
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "preConfiguredButton1"),
                                            type: "paragraph",
                                        },
                                        {
                                            variable: "primaryButtonColor",
                                            default: primaryButtonColor,
                                            label: getI18nLocaleObject(namespaceList.widgetWebContent, "buttonColor"),
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "primaryButtonTextColor",
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonTextColor"),
                                            default: primaryButtonTextColor,
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "primaryButtonWidth",
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonWidth"),
                                            type: "number",
                                            default: primaryButtonWidth,
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "primaryButtonHeight",
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonHeight"),
                                            type: "number",
                                            default: primaryButtonHeight,
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "primaryButtonBorderRadius",
                                            default: primaryButtonBorderRadius,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonBorderRadius"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                    ],
                                    [
                                        {
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "preConfiguredButton2"),
                                            type: "paragraph",
                                        },
                                        {
                                            variable: "secondaryButtonColor",
                                            default: secondaryButtonColor,
                                            label: getI18nLocaleObject(namespaceList.widgetWebContent, "buttonColor"),
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "secondaryButtonTextColor",
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonTextColor"),
                                            default: secondaryButtonTextColor,
                                            type: "color",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "secondaryButtonWidth",
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonWidth"),
                                            type: "number",
                                            default: secondaryButtonWidth,
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "secondaryButtonHeight",
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonHeight"),
                                            type: "number",
                                            default: secondaryButtonHeight,
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                        {
                                            variable: "secondaryButtonBorderRadius",
                                            default: secondaryButtonBorderRadius,
                                            label: getI18nLocaleObject(namespaceList.pluginSettings, "buttonBorderRadius"),
                                            type: "number",
                                            theme: (item: any) => item.theme.themeId,
                                        },
                                    ],
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "connectivity"),
                    // permission: "webmanager.maxxtonservices",
                    properties: [
                        [
                            {
                                variable: "env",
                                default: "",
                                label: getI18nLocaleObject(namespaceList.pluginMxts, "environment"),
                                type: "select",
                                optionList: () =>
                                    MXTS.environments().then((envs: string[]) =>
                                        envs.map(
                                            (env: string) =>
                                                ({
                                                    value: env,
                                                    label: () => env,
                                                } as any)
                                        )
                                    ),
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "global"),
                    properties: [
                        [
                            {
                                variable: "autoAssignUnitToReservation",
                                label: getI18nLocaleObject(namespaceList.admin, "autoAssignUnitToReservation"),
                                type: "checkbox",
                            },
                            {
                                variable: "sortUnitsOnVSI",
                                label: getI18nLocaleObject(namespaceList.admin, "sortUnitsOnVSI"),
                                type: "checkbox",
                                visible: (options: CMSOptions) => !!options.autoAssignUnitToReservation,
                            },
                            {
                                variable: "alwaysAddUnitPreferenceCost",
                                label: getI18nLocaleObject(namespaceList.admin, "alwaysAddUnitPreferenceCost"),
                                type: "checkbox",
                            },
                            {
                                variable: "enableTrailingSlashRedirectBehavior",
                                label: getI18nLocaleObject(namespaceList.admin, "enableTrailingSlashRedirectBehavior"),
                                type: "checkbox",
                            },
                            {
                                variable: "addTrailingSlashToAllUrls",
                                label: getI18nLocaleObject(namespaceList.admin, "addTrailingSlashToAllUrls"),
                                type: "checkbox",
                                visible: (options: CMSOptions) => !!options.enableTrailingSlashRedirectBehavior,
                            },
                            {
                                variable: "disableAutoUpdatePreview",
                                label: getI18nLocaleObject(namespaceList.admin, "disableAutoUpdatePreview"),
                                type: "checkbox",
                            },
                            {
                                variable: "disableRequiredFieldSet",
                                label: getI18nLocaleObject(namespaceList.admin, "disableRequiredFieldSet"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "sites"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "siteGroups"),
                                type: "siteGroup",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "maintenance"),
                    properties: [
                        [
                            // {
                            //     type: "paragraph",
                            //     label: getI18nLocaleObject(namespaceList.admin, "flushWarningInfo"),
                            // },
                            // {
                            //     label: getI18nLocaleObject(namespaceList.admin, "flushCache"),
                            //     type: "button",
                            //     onClick: () => {
                            //         MaintenanceApi.flushCache();
                            //     },
                            //     alert: {
                            //         color: "success",
                            //         message: getI18nLocaleString(namespaceList.admin, "cacheFlushedSuccessfully"),
                            //     },
                            // },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "rebuildCache"),
                                type: "button",
                                onClick: () => {
                                    MaintenanceApi.rebuildCache();
                                },
                                alert: {
                                    color: "info",
                                    message: getI18nLocaleString(namespaceList.admin, "cacheRebuildInProgress"),
                                },
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "rebuildPages"),
                                type: "button",
                                onClick: () => {
                                    MaintenanceApi.rebuildPages();
                                },
                                alert: {
                                    color: "info",
                                    message: getI18nLocaleString(namespaceList.admin, "pageRebuildInProgress"),
                                },
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "rebuildMxtsApiCache"),
                                type: "button",
                                onClick: () => {
                                    MaintenanceApi.rebuildMxtsApiCache();
                                },
                                alert: {
                                    color: "info",
                                    message: getI18nLocaleString(namespaceList.admin, "rebuildMxtsApiCacheInProgress"),
                                },
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "flushQueue"),
                                type: "button",
                                classname: () => "hidden",
                                onClick: () => {
                                    MaintenanceApi.flushQueue();
                                },
                                alert: {
                                    color: "info",
                                    message: getI18nLocaleString(namespaceList.admin, "flushQueueInProgress"),
                                },
                            },
                            {
                                variable: "disableCacheWhenLoggedIn",
                                label: getI18nLocaleObject(namespaceList.admin, "disableCacheWhenLoggedIn"),
                                type: "checkbox",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "crawlAgain"),
                            },
                            {
                                variable: "initializeCrawling",
                                label: getI18nLocaleObject(namespaceList.admin, "siteSelectionforRecrawl"),
                                type: "multiselect",
                                async optionList() {
                                    const crawledSites: Array<Site & WithId> = (
                                        await SiteApi.find({
                                            projection: { sitemap: 0 },
                                        })
                                    ).filter((site) => ["*"].indexOf(site.host) === -1 && site.enableCrawling);
                                    return crawledSites.map((site) => ({
                                        text: site.host,
                                        value: site._id,
                                    }));
                                },
                            },
                            {
                                variable: "initializeCrawlingButton",
                                label: getI18nLocaleObject(namespaceList.admin, "initiateRecrawl"),
                                type: "button",
                                onClick: (item) => {
                                    MaintenanceApi.crawlSites({ crawlRequest: { siteIds: item.initializeCrawling.map((site) => site.value) } });
                                },
                            },
                        ],
                    ],
                },
            ],
        },
    ],
    // Note: commented this part because on the local environment, we are unable to set connectivity after db-sync
    // permission: "webmanager.settings",
};

const CMSSettingsPlugin: PluginSpec<CMSOptions> = {
    id: "cms-settings",
    name: getI18nLocaleObject(namespaceList.pluginSettings, "settings"),
    description: getI18nLocaleObject(namespaceList.pluginSettings, "settingsDescription"),
    optionsForm: pluginOptionsForm,
    showOptionsInAdminMenu: true,
    widgets: [],
};

export async function getCMSOptions(cmsApi: CmsApiWrapper): Promise<CMSOptions> {
    if (isClientSide() && (window as typeof window & { cmsOptions?: CMSOptions }).cmsOptions) {
        return (window as typeof window & { cmsOptions: CMSOptions }).cmsOptions;
    }

    const plugin = await loadPlugin(cmsApi, CMSSettingsPlugin);
    return plugin.options as CMSOptions;
}

export default CMSSettingsPlugin;
