import { Theme } from "./";
import baseTheme from "./base";
import myEnvironmentTheme from "./myenvironment";

const theme: Theme = {
    id: "vacansoleil",
    name: "Vacansoleil Theme",
    stylesheets: ["/styles/themes/vacansoleil/vacansoleil-admin.css"],
    frontendStylesheets: ["/styles/themes/vacansoleil/vacansoleil.css"],
    styles: [...baseTheme.styles, ...myEnvironmentTheme.styles],
};

export default theme;
