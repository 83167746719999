import {
    ACCOMMODATION_TYPE,
    ACTIVITY,
    CATEGORY,
    DESCRIPTION,
    DESCRIPTION2,
    DYNAMIC_FIELD_CODE,
    DescriptionFieldKey,
    HEAD_TEXT,
    LOCATION,
    NAME,
    RESORTS,
    SHORT_DESCRIPTION,
    SIGN_UP,
    ServerSideProps,
    TEXT,
    TIME,
    TITLE,
    WidgetOptionsMap,
} from "../../../components/utils";
import {
    AccommodationType,
    ActivityAreaResourcesResponse,
    ApiCallOptions,
    CustomFieldResult,
    DynamicFieldInfo,
    FieldResult,
    Image,
    MxtsApi,
    MxtsApiWrapper,
    Resort,
    Resource,
    ResourceActivityWithDynamicField,
    SendEmailRequest,
    Unit,
} from "@maxxton/cms-mxts-api";
import { Activity, ResourceActivitiesDetailsWithDynamicField } from "../../page/activityPlanner/activityPlanner.types";
import { CMSProvidedProperties, CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CombineDescriptions, DescriptionResult } from "../../resultsPanel/description/ResultsPanelDescription";
import { Content, getContent, getDynamicFieldCodeValue, getParentContent } from "../../../utils/content.util";
import { DescriptionProps, DescriptionState, ResourceActivityResult } from "./Description";
import { DescriptionResponse, GetDescriptionsProps, ParentContent, TipsAndTripsContent } from "./description.type";
import { cloneDeep, compact, flatten, isEmpty, isEqual } from "lodash";
import { getActivitiesDetailsQueryParams, getDistinguishedFullDayActivities, setLocalActivityTime } from "../../page/activityPlanner/activityPlanner.util";
import { getAdminMxtsEnv, getMxtsEnv } from "../../mxts";

import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { MXTS } from "../../../utils/constants";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { WidgetOptions } from "./";
import { addOwnerDetails } from "../amenities/amenitiesEditor.util";

export enum SendingStatus {
    Success = "Success",
    Failed = "Failed",
}

interface DescriptionDraftValueForMail {
    updatedDescription?: string | null | undefined;
    description?: string;
    updatedSecondDescription?: string | null | undefined;
    secondDescription?: string;
    updatedShortDescription?: string | null | undefined;
    shortDescription?: string;
    email?: string | null | undefined;
    resortName?: string;
    useForOwnerPortal?: boolean;
    ownerEmail?: string | null | undefined;
    ownerName?: string;
    unitId?: number;
    resourceName?: string;
}

// Sequentially arrange the response of dynamic field codes as configured
const rearrangeDynamicFieldsDescriptions = (params: CombineDescriptions): DescriptionResult[] | null => {
    const { dynamicFieldsDescriptions, selectedDynamicFieldCodes, showAllInventoryList } = params;
    let sortedDescriptions: Array<DescriptionResult | undefined> = [];
    if (selectedDynamicFieldCodes) {
        if (!showAllInventoryList) {
            sortedDescriptions = selectedDynamicFieldCodes?.map((selectedOption) => {
                const { value } = selectedOption;
                return dynamicFieldsDescriptions.find((item) => item.code === `${value}` && (item.value || item.imageUrl));
            });
        } else {
            dynamicFieldsDescriptions
                .filter((content) =>
                    selectedDynamicFieldCodes?.some((selectedOption) => {
                        const { value } = selectedOption;
                        return content.code === `${value}` && (content.value || content.imageUrl);
                    })
                )
                .forEach((content) => {
                    if (!sortedDescriptions?.some((desc) => desc?.value === content.value)) {
                        sortedDescriptions.push(content);
                    }
                });
        }
        return compact(sortedDescriptions);
    }
    return null;
};

const combineDescriptions = (params: CombineDescriptions): DescriptionResult[] => {
    const { descriptionMultiSelector, descriptions } = params;
    if (descriptionMultiSelector?.length) {
        const sortedDescriptions = descriptionMultiSelector.map((selectedOption) => {
            const { value } = selectedOption;
            if (value === DYNAMIC_FIELD_CODE) {
                return rearrangeDynamicFieldsDescriptions(params);
            }
            return descriptions.find((item) => item.type === value && item.value);
        });
        const flatDescriptions = flatten(sortedDescriptions);
        const compactDescriptions = compact(flatDescriptions);
        return compactDescriptions;
    }
    return [];
};

const getDescriptionsWithDynamicFields = (state: DescriptionState, props: DescriptionProps): DescriptionResult[] => {
    const {
        options: { descriptionTypeMultiSelector, dynamicFieldCodes, showAllInventoryList },
    } = props;
    const { descriptions, dynamicFieldsDescriptions } = state;
    const params = {
        descriptionMultiSelector: descriptionTypeMultiSelector,
        descriptions,
        dynamicFieldsDescriptions,
        selectedDynamicFieldCodes: dynamicFieldCodes,
        showAllInventoryList,
    };
    return combineDescriptions(params);
};

const getFallbackDescriptionsWithDynamicFields = (state: DescriptionState, props: DescriptionProps): DescriptionResult[] => {
    const {
        options: { descriptionMultiSelectorFallback, dynamicFieldFallback },
    } = props;
    const { fallbackDescriptions, dynamicFieldsFallbackDescriptions } = state;
    const params = {
        descriptionMultiSelector: descriptionMultiSelectorFallback,
        descriptions: fallbackDescriptions,
        dynamicFieldsDescriptions: dynamicFieldsFallbackDescriptions,
        selectedDynamicFieldCodes: dynamicFieldFallback,
    };
    return combineDescriptions(params);
};

export const getOverallDescriptions = (props: DescriptionProps, state: DescriptionState): DescriptionResult[] => {
    const { options } = props;
    const descriptions = getDescriptionsWithDynamicFields(state, props);
    if (options.fallbackDescription && isEmpty(descriptions)) {
        const fallbackDescriptions = getFallbackDescriptionsWithDynamicFields(state, props);
        return fallbackDescriptions;
    }
    const isActivityDynamicFieldExist = props.activity && state.descriptions.find((descripton) => descripton.type === DYNAMIC_FIELD_CODE);
    const isDynamicFieldAlreadyExist = descriptions.find((descripton) => descripton.type === DYNAMIC_FIELD_CODE);
    if (isActivityDynamicFieldExist && !isDynamicFieldAlreadyExist) {
        descriptions.push(isActivityDynamicFieldExist);
    }
    return descriptions;
};

export async function getCustomFieldOptions(mxtsApi: MxtsApiWrapper): Promise<NumberMultiSelectOption[]> {
    const env = await getAdminMxtsEnv();
    const customFields = (await mxtsApi.getFields(env, { size: MXTS.MAX_RESULTS })).content;
    const customFieldOptions = customFields.map(
        (customField: FieldResult): NumberMultiSelectOption => ({
            value: customField.fieldId,
            text: `${customField.name} (${customField.type})`,
        })
    );
    return customFieldOptions;
}

export const sendEmailToResort = async (props: {
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    context: CMSProviderProperties;
    myEnvState: MyEnvState;
    setEmailSendStatus: React.Dispatch<React.SetStateAction<SendingStatus | undefined>>;
    setIsEmailSent: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    getReCaptchaToken: () => Promise<string>;
    content: Unit;
    description: string | null | undefined;
    description2: string | null | undefined;
    shortDescription: string | null | undefined;
}) => {
    const { childOptions, context, getReCaptchaToken, myEnvState, setEmailSendStatus, setIsEmailSent, content, description, description2, shortDescription } = props;
    const { mainCustomer } = myEnvState;
    let draftValueForMail: DescriptionDraftValueForMail = {};
    const reCaptchaToken = (await getReCaptchaToken()) || "";
    const env = await getMxtsEnv(context, context.currentLocale.code);
    if (env && childOptions?.unitEditableFormWidgetList.length) {
        const formWidget = await context.cmsApi.widgetApi.findById({ id: childOptions.unitEditableFormWidgetList[0].value });
        const ownerDetails = await addOwnerDetails({ context, env, formWidget, mainCustomer, unit: content });
        draftValueForMail = { ...draftValueForMail, ...ownerDetails };
        if (!isEqual(description, (content as Unit).description)) {
            draftValueForMail.updatedDescription = description;
            draftValueForMail.description = (content as Unit).description || "";
        }
        if (!isEqual(description2, (content as Unit).description2)) {
            draftValueForMail.updatedSecondDescription = description2;
            draftValueForMail.secondDescription = (content as Unit).description2 || "";
        }
        if (!isEqual(shortDescription, (content as Unit).shortDescription)) {
            draftValueForMail.updatedShortDescription = shortDescription;
            draftValueForMail.shortDescription = (content as Unit).shortDescription || "";
        }
        const sendEmailRequest: SendEmailRequest = {
            reCaptchaToken,
            siteHost: location.host,
            formOptionId: formWidget?.options._id || "",
            localeId: context?.currentLocale.locale || "",
            submittedFormData: draftValueForMail,
            dynamicData: undefined,
        };
        await MxtsApi.sendEmail(env, sendEmailRequest)
            .then((emailResponse: any) => {
                if (emailResponse?.success === 200) {
                    setIsEmailSent(false);
                    setEmailSendStatus(SendingStatus.Success);
                }
            })
            .catch(() => {
                setIsEmailSent(false);
                setEmailSendStatus(SendingStatus.Failed);
            });
    }
};

export const setActivityDynamicCodeInDescription = async ({
    selectedActivity,
    env,
    widgetOptionsId,
    context,
}: {
    selectedActivity: Activity;
    env: ApiCallOptions;
    widgetOptionsId: string;
    context: CMSProviderProperties;
}) => {
    const widgetOptionsDynamicFieldIdsPaths: Array<keyof WidgetOptions> = ["dynamicFieldCodes", "activityDynamicFieldCodes"];
    const language = (await MxtsApi.languages(env, { shortName: context.currentLocale.code }))[0];
    const obtainedResourceActivities: ResourceActivityWithDynamicField[] = (
        await MxtsApi.getResourceActivities(env, {
            resortIds: [selectedActivity.resourceActivity.resortId],
            resortActivityIds: [selectedActivity.resourceActivity.resortActivity.resortActivityId],
            page: 0,
            size: 20,
            includeDynamicFieldsData: true,
            widgetOptionsId,
            widgetOptionsDynamicFieldIdsPaths,
            languageId: language.languageId,
        })
    )?.content;

    const foundActivity = obtainedResourceActivities.find((resourceActivity) => resourceActivity.resourceId === selectedActivity.resourceId);
    if (foundActivity) {
        selectedActivity = { ...selectedActivity, dynamicFieldData: foundActivity?.dynamicFieldData };
    }
    return selectedActivity;
};

export const loadDescription = async <T extends keyof WidgetOptionsMap>(props: ServerSideProps<T>) => {
    let descriptionResponse: DescriptionResponse = {};
    const { context, options, dynamicFilter, dynamicContainerOptions, myEnvState } = props;
    const { selectedReservation } = myEnvState ?? {};
    const widgetOptions = options as WidgetOptions;

    const { contentType, activityIds, descriptionTypeMultiSelector, activityDynamicFieldCodes, _id: widgetOptionsId, showAllInventoryList, customFieldIds } = widgetOptions;
    const env = await getMxtsEnv(context, context.currentLocale.code);
    const activityIdsArray =
        (activityIds?.length && activityIds.map((activity) => Number(activity.value))) ||
        (dynamicContainerOptions?.activityIds?.length && dynamicContainerOptions?.activityIds.map((activity) => Number(activity.value))) ||
        (dynamicFilter?.resourceActivityDetailsId && [Number(dynamicFilter?.resourceActivityDetailsId)]) ||
        [];
    if ((contentType === ACTIVITY || dynamicContainerOptions?.contentType === ACTIVITY) && dynamicFilter?.selectedActivities?.length) {
        const descriptionOptionsValue = descriptionTypeMultiSelector?.map((activitySelection) => activitySelection.value);
        let selectedActivity = cloneDeep(dynamicFilter.selectedActivities[0]);
        if (activityIdsArray.length && !activityIdsArray.includes(dynamicFilter.selectedActivities[0].resourceActivityDetailsId)) {
            const detailParams = await getActivitiesDetailsQueryParams({
                mxtsApi: context.mxtsApi,
                env,
                dynamicFilter,
                resourceActivityDetailsIds: activityIdsArray,
            });
            const obtainedResourceActivities: Activity[] = await context.mxtsApi.getResourceActivitiesDetails(env, detailParams).then((result) => result.content);
            selectedActivity = obtainedResourceActivities[0];
        }
        // Set activity area - Activity area and Activity signup area.
        if (descriptionOptionsValue?.includes(LOCATION) || descriptionOptionsValue?.includes(SIGN_UP)) {
            selectedActivity = await setActivityAreaInDescription({ selectedActivity, env, dynamicFilter });
        }
        // Set dynamic field code in activity.
        if (descriptionOptionsValue?.includes(DYNAMIC_FIELD_CODE) && activityDynamicFieldCodes?.length) {
            selectedActivity = await setActivityDynamicCodeInDescription({ selectedActivity, env, widgetOptionsId, context });
        }
        // Set resort in activity.
        if (descriptionOptionsValue?.includes(RESORTS)) {
            const resortResult = await context.mxtsApi.resorts(env, { size: 1, resortIds: [selectedActivity.resourceActivity.resortId] }).then((result) => result.content);
            (selectedActivity.resourceActivity as ResourceActivityResult).resortName = resortResult[0].name;
        }
        // Set Category in Activity
        if (descriptionOptionsValue?.includes(CATEGORY)) {
            const language = (await context.mxtsApi.languages(env, { shortName: context.currentLocale.code }))[0];
            const activityCategoryId = selectedActivity.resourceActivity.resortActivity.activityCategoryId;
            const categoryResults = await context.mxtsApi.getActivityCategoryTranslations(env, {}, [{ key: "activityCategoryId", value: activityCategoryId }]);
            const categoryValue = categoryResults?.find((categoryResult) => categoryResult.languageId === language.languageId)?.name || "";
            selectedActivity.activityCategory = categoryValue;
        }
        // distinguish if the selected activity is a full day activity
        selectedActivity = getDistinguishedFullDayActivities([selectedActivity])[0];
        // Format time to Concern timezone
        if (descriptionOptionsValue?.includes(TIME) && (selectedActivity.startTime.includes("Z") || selectedActivity.endTime.includes("Z"))) {
            selectedActivity = await setLocalActivityTime(selectedActivity, context);
        }
        descriptionResponse = { ...descriptionResponse, activity: selectedActivity };
    } else if (customFieldIds?.length && selectedReservation?.reservation?.reservationId && selectedReservation?.reservedResources?.[0].reservedResourceId) {
        const customFieldsList: CustomFieldResult[] = await context.mxtsApi.getCustomFields(env, { sort: "priority,ASC" }, [
            { key: "reservationId", value: selectedReservation?.reservation?.reservationId },
            { key: "reservedResourceId", value: selectedReservation?.reservedResources[0].reservedResourceId },
        ]);
        const filteredCustomFields: CustomFieldResult[] = customFieldsList.filter((customField) => customFieldIds?.map((customFieldId) => customFieldId.value).includes(customField.fieldId));
        descriptionResponse = { ...descriptionResponse, selectedCustomFields: filteredCustomFields };
    } else {
        const content = (await getContent({ ...props, skipContentTypeSelectorCheck: true, obtainMyEnvReservedResources: { showAllInventoryList } })) as Exclude<Content | Content[], Resort[]>;
        descriptionResponse = {
            ...descriptionResponse,
            content: Array.isArray(content) && showAllInventoryList ? content[0] : content,
            contents: Array.isArray(content) && showAllInventoryList ? content : undefined,
        };

        const result = await getDescriptions({ options: widgetOptions, context, env, myEnvState, ...descriptionResponse });
        descriptionResponse = { ...descriptionResponse, ...result };
        if (contentType === ACCOMMODATION_TYPE && descriptionTypeMultiSelector?.some((option) => option.value === NAME)) {
            const reservedResources = myEnvState?.selectedReservation?.reservedResources;
            const resourceName = reservedResources?.map((reservedResource) => reservedResource?.resource?.name || reservedResource?.name || "-");
            if (resourceName?.length) {
                descriptionResponse = { ...descriptionResponse, reservedResourcesName: resourceName, reservedResourcesNameWithFrequency: setReservedResourcesNameWithFrequency(resourceName) };
            }
        }
    }
    return descriptionResponse;
};

export const getDescriptions = async (props: GetDescriptionsProps) => {
    let allDescriptions: DescriptionResponse = {};
    const { options, context, env: envState, myEnvState, content } = props;
    const env = envState || (await getMxtsEnv(context, context.currentLocale.code));

    if (options?.dynamicFieldCodes?.length) {
        const dynamicFieldResult = (await handleDynamicFieldCodes({ options, env, context, content }, false))?.[0];
        allDescriptions = { ...allDescriptions, ...dynamicFieldResult };
    }
    const hideUnitDescriptions = options?.contentType === "unit" && myEnvState?.selectedReservation?.hasUnitPreference !== undefined && !myEnvState.selectedReservation?.hasUnitPreference;
    if (options?.descriptionTypeMultiSelector && !hideUnitDescriptions) {
        const descriptions = await Promise.all(options.descriptionTypeMultiSelector.map(mapDescriptions({ env, context, content }, true)));
        const filteredDescriptions = descriptions.filter((description) => description) as DescriptionResult[];
        allDescriptions = { ...allDescriptions, descriptions: filteredDescriptions };
    }

    if (options?.fallbackDescription && options?.dynamicFieldFallback) {
        const dynamicFieldFallbackResult = (await handleDynamicFieldCodes({ options, env, context, content }, true))?.[0];
        allDescriptions = { ...allDescriptions, ...dynamicFieldFallbackResult };
    }
    if (options?.fallbackDescription && options?.descriptionMultiSelectorFallback) {
        const fallbackDescriptions = await Promise.all(options.descriptionMultiSelectorFallback.map(mapDescriptions({ env, context, content }, false)));
        const filteredFallbackDescriptions = fallbackDescriptions.filter((fallbackDescription) => fallbackDescription) as DescriptionResult[];
        if (filteredFallbackDescriptions.length) {
            allDescriptions = { ...allDescriptions, fallbackDescriptions: filteredFallbackDescriptions };
        }
    }
    return allDescriptions;
};

const mapDescriptions = (props: GetDescriptionsProps, checkForParent: boolean, parentContentInfo?: ParentContent) => async (
    descriptionType: NumberMultiSelectOption
): Promise<DescriptionResult | null> => {
    const { content, env, context } = props;

    let parentContent = parentContentInfo;

    if (content) {
        const key = DescriptionFieldKey?.[+descriptionType.value as keyof typeof DescriptionFieldKey];
        const value = content?.[key as keyof Content] || "";
        if (!value && !parentContent && checkForParent && content && content && env) {
            parentContent = await getParentContent(context?.mxtsApi, { content, env });
        }

        switch (+descriptionType.value) {
            case NAME: {
                const response = await getDescriptionValue({ key: "name", checkForParent, content, env, context, parentContent });
                return {
                    value: response,
                    type: NAME,
                };
            }
            case DESCRIPTION: {
                return {
                    value: await getDescriptionValue({ key: "description", checkForParent, content, env, context, parentContent }),
                    type: DESCRIPTION,
                };
            }
            case SHORT_DESCRIPTION: {
                return {
                    value: await getDescriptionValue({ key: "shortDescription", checkForParent, content, env, context, parentContent }),
                    type: SHORT_DESCRIPTION,
                };
            }
            case DESCRIPTION2: {
                const value = await getDescriptionValue({ key: "description2", checkForParent, content, env, context, parentContent });
                return {
                    value,
                    type: DESCRIPTION2,
                };
            }
            case TITLE: {
                const value = await getDescriptionValue({ key: "title", checkForParent, content, env, context, parentContent });
                return {
                    value,
                    type: TITLE,
                };
            }
            case HEAD_TEXT: {
                const value = await getDescriptionValue({ key: "headText", checkForParent, content, env, context, parentContent });
                return {
                    value,
                    type: HEAD_TEXT,
                };
            }
            case TEXT:
                const value = await getDescriptionValue({ key: "text", checkForParent, content, env, context, parentContent });
                return {
                    value,
                    type: TEXT,
                };
        }
    }
    return null;
};

const setReservedResourcesNameWithFrequency = (reservedResourcesName: string[]) => {
    interface ReservedResourcesNameFrequency {
        [key: string]: number;
    }
    const reservedResourcesNameCounts: ReservedResourcesNameFrequency = {};
    reservedResourcesName?.forEach((element) => {
        reservedResourcesNameCounts[element] = (reservedResourcesNameCounts[element] || 0) + 1;
    });
    const reservedResourcesNameCountsArray = Object.keys(reservedResourcesNameCounts).map((key) => ({ resourceName: key, frequency: reservedResourcesNameCounts[key] }));
    return reservedResourcesNameCountsArray;
};

export const setActivityAreaInDescription = async ({ selectedActivity, env, dynamicFilter }: { selectedActivity: Activity; env: ApiCallOptions; dynamicFilter: DynamicFilter }) => {
    if (!selectedActivity.activityAreaResources.length || (dynamicFilter.showMainActivityOnPage && !(dynamicFilter.resourceActivityDetailsId || dynamicFilter.resourceActivityDetailsIds?.length))) {
        const activityAreaResources: ActivityAreaResourcesResponse[] = await MxtsApi.getActivityAreaResources(env, { resourceIds: [selectedActivity.resourceId] }).then(
            (areaResourceResult) => areaResourceResult.content
        );
        const foundActivityArea = activityAreaResources.filter((activityAreaResource) => activityAreaResource.resourceId === selectedActivity.resourceId);
        if (foundActivityArea.length) {
            selectedActivity.activityAreaResources = foundActivityArea;
        }
    }
    return selectedActivity;
};

const handleDynamicFieldCodes = async (props: GetDescriptionsProps, useFallbackDescription = false, parentContentInfo?: ParentContent) => {
    const { content, contents, options, env, context } = props;
    const parentContent = parentContentInfo;

    const { dynamicFieldCodes, dynamicFieldFallback, showAllInventoryList } = options ?? {};
    const descriptions = useFallbackDescription ? dynamicFieldFallback : dynamicFieldCodes;
    if (descriptions && options?._id) {
        const widgetOptionsDynamicFieldCodesPaths: Array<keyof WidgetOptions> = ["dynamicFieldCodes", "dynamicFieldFallback"];
        const dynamicFieldGetter = async (contentToUse: Resort | Resource | Unit | ResourceActivitiesDetailsWithDynamicField | Resort | AccommodationType | Unit | Content[] | undefined) => {
            const dynamicFieldResults = await Promise.all(
                descriptions.map(async (field) => {
                    const fetchresponse = await getDynamicFieldCodeValue({
                        content: contentToUse as any,
                        dynamicFieldCode: field.value,
                        env,
                        parentContent,
                        apiContext: context,
                        widgetOptionsId: options?._id,
                        widgetOptionsDynamicFieldCodesPaths,
                    });
                    return setDynamicFieldCodes(fetchresponse, useFallbackDescription);
                })
            );
            return dynamicFieldResults.filter(Boolean);
        };

        if (contents?.length && showAllInventoryList) {
            return await Promise.all(contents.map((contentItem) => dynamicFieldGetter(contentItem)));
        }
        return await dynamicFieldGetter(content);
    }
};

const setDynamicFieldCodes = (dynamicFieldInfo: DynamicFieldInfo | Image | null, useFallbackDescription = false, existingResponse?: DescriptionResponse) => {
    if (dynamicFieldInfo) {
        const { code, name, urls, value } = dynamicFieldInfo as DynamicFieldInfo & Image;
        const imageUrl = urls?.large.replace("t_newyse_large", "t_mcms_larger/f_auto");
        if (value || imageUrl) {
            const descriptions: DescriptionResult = { code, imageAlt: name, imageUrl, value, type: DYNAMIC_FIELD_CODE };
            if (useFallbackDescription) {
                return {
                    ...existingResponse,
                    dynamicFieldsFallbackDescriptions: [...(existingResponse?.dynamicFieldsFallbackDescriptions || []), descriptions],
                };
            }
            return {
                ...existingResponse,
                dynamicFieldsDescriptions: [...(existingResponse?.dynamicFieldsDescriptions || []), descriptions],
            };
        }
    }
};

const getDescriptionValue = async ({
    content,
    key,
    checkForParent,
    env,
    parentContent,
    context,
}: {
    context?: CMSProvidedProperties;
    parentContent?: ParentContent;
    content?: Exclude<Content | Content[], Resort[]>;
    env?: ApiCallOptions;
    checkForParent: boolean;
    key: "text" | "headText" | "title" | "name" | "description" | "shortDescription" | "description2";
}) => {
    const value = (content as Exclude<Content, Resort | Resort[] | Activity> & TipsAndTripsContent)?.[key] || "";

    if (!value && env && checkForParent && parentContent) {
        return parentContent?.[key] || "";
    }
    return value;
};
