import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { getI18nLocaleString } from "../../../i18n";
import namespacesList from "../../../i18n/namespaceList";

interface CalendarCloseIconParams {
    onCalendarClose: React.MouseEventHandler<HTMLSpanElement>;
    onCalendarClear?: React.MouseEventHandler<HTMLSpanElement>;
    onCalendarApply?: React.MouseEventHandler<HTMLSpanElement>;
    showClear?: boolean;
    showApply?: boolean;
    showCloseIcon?: boolean;
    modalLabel?: string;
    showClearAndApplyButton?: boolean;
    useAsModalPopup?: boolean;
}

export const CalendarFormActions = ({
    onCalendarClose,
    onCalendarClear,
    onCalendarApply,
    showClear,
    showApply,
    modalLabel,
    showCloseIcon,
    showClearAndApplyButton,
    useAsModalPopup,
}: CalendarCloseIconParams) => (
    <div className="date-submit-section">
        <div className="calendar-header">{modalLabel && <label className="label-inside-popup">{modalLabel}</label>}</div>
        <div className="close-popup">
            {showCloseIcon && !useAsModalPopup && (
                <span className="popup-close" onClick={onCalendarClose}>
                    <FontAwesome name="close" />
                </span>
            )}
        </div>
        {(showClear || showApply || showClearAndApplyButton) && !useAsModalPopup && (
            <div className="filter-action-buttons">
                {showClear && (
                    <a className="action-button__clear" role="button" onClick={onCalendarClear}>
                        {getI18nLocaleString(namespacesList.admin, "clear")}
                    </a>
                )}
                {showApply && (
                    <a
                        className="action-button__apply"
                        role="button"
                        onClick={(e) => {
                            onCalendarApply?.(e);
                            onCalendarClose?.(e);
                        }}
                    >
                        {getI18nLocaleString(namespacesList.admin, "apply")}
                    </a>
                )}
            </div>
        )}
    </div>
);
