export default {
    "500px": "500px",
    "adjust": "Adjust",
    "adn": "Adn",
    "align-center": "Align Center",
    "align-justify": "Align Justify",
    "align-left": "Align Left",
    "align-right": "Align Right",
    "amazon": "Amazon",
    "ambulance": "Ambulance",
    "anchor": "Anchor",
    "android": "Android",
    "angellist": "Angellist",
    "angle-double-down": "Angle Double Down",
    "angle-double-left": "Angle Double Left",
    "angle-double-right": "Angle Double Right",
    "angle-double-up": "Angle Double Up",
    "angle-down": "Angle Down",
    "angle-left": "Angle Left",
    "angle-right": "Angle Right",
    "angle-up": "Angle Up",
    "apple": "Apple",
    "archive": "Archive",
    "area-chart": "Area Chart",
    "arrow-circle-down": "Arrow Circle Down",
    "arrow-circle-left": "Arrow Circle Left",
    "arrow-circle-o-down": "Arrow Circle O Down",
    "arrow-circle-o-left": "Arrow Circle O Left",
    "arrow-circle-o-right": "Arrow Circle O Right",
    "arrow-circle-o-up": "Arrow Circle O Up",
    "arrow-circle-right": "Arrow Circle Right",
    "arrow-circle-up": "Arrow Circle Up",
    "arrow-down": "Arrow Down",
    "arrow-left": "Arrow Left",
    "arrow-right": "Arrow Right",
    "arrow-up": "Arrow Up",
    "arrows": "Arrows",
    "arrows-alt": "Arrows Alt",
    "arrows-h": "Arrows H",
    "arrows-v": "Arrows V",
    "asterisk": "Asterisk",
    "at": "At",
    "automobile": "Automobile",
    "backward": "Backward",
    "bath": "Bath",
    "balance-scale": "Balance Scale",
    "ban": "Ban",
    "bank": "Bank",
    "bar-chart": "Bar Chart",
    "bar-chart-o": "Bar Chart O",
    "barcode": "Barcode",
    "bars": "Bars",
    "battery-0": "Battery 0",
    "battery-1": "Battery 1",
    "battery-2": "Battery 2",
    "battery-3": "Battery 3",
    "battery-4": "Battery 4",
    "battery-empty": "Battery Empty",
    "battery-full": "Battery Full",
    "battery-half": "Battery Half",
    "battery-quarter": "Battery Quarter",
    "battery-three-quarters": "Battery Three Quarters",
    "bed": "Bed",
    "beer": "Beer",
    "behance": "Behance",
    "behance-square": "Behance Square",
    "bell": "Bell",
    "bell-o": "Bell O",
    "bell-slash": "Bell Slash",
    "bell-slash-o": "Bell Slash O",
    "bicycle": "Bicycle",
    "binoculars": "Binoculars",
    "birthday-cake": "Birthday Cake",
    "bitbucket": "Bitbucket",
    "bitbucket-square": "Bitbucket Square",
    "bitcoin": "Bitcoin",
    "black-tie": "Black Tie",
    "bluetooth": "Bluetooth",
    "bluetooth-b": "Bluetooth B",
    "bold": "Bold",
    "bolt": "Bolt",
    "bomb": "Bomb",
    "book": "Book",
    "bookmark": "Bookmark",
    "bookmark-o": "Bookmark O",
    "briefcase": "Briefcase",
    "btc": "Btc",
    "bug": "Bug",
    "building": "Building",
    "building-o": "Building O",
    "bullhorn": "Bullhorn",
    "bullseye": "Bullseye",
    "bus": "Bus",
    "buysellads": "Buysellads",
    "cab": "Cab",
    "calculator": "Calculator",
    "calendar": "Calendar",
    "calendar-check-o": "Calendar Check O",
    "calendar-minus-o": "Calendar Minus O",
    "calendar-o": "Calendar O",
    "calendar-plus-o": "Calendar Plus O",
    "calendar-times-o": "Calendar Times O",
    "camera": "Camera",
    "camera-retro": "Camera Retro",
    "car": "Car",
    "caret-down": "Caret Down",
    "caret-left": "Caret Left",
    "caret-right": "Caret Right",
    "caret-square-o-down": "Caret Square O Down",
    "caret-square-o-left": "Caret Square O Left",
    "caret-square-o-right": "Caret Square O Right",
    "caret-square-o-up": "Caret Square O Up",
    "caret-up": "Caret Up",
    "cart-arrow-down": "Cart Arrow Down",
    "cart-plus": "Cart Plus",
    "cc": "Cc",
    "cc-amex": "Cc Amex",
    "cc-diners-club": "Cc Diners Club",
    "cc-discover": "Cc Discover",
    "cc-jcb": "Cc Jcb",
    "cc-mastercard": "Cc Mastercard",
    "cc-paypal": "Cc Paypal",
    "cc-stripe": "Cc Stripe",
    "cc-visa": "Cc Visa",
    "certificate": "Certificate",
    "chain": "Chain",
    "chain-broken": "Chain Broken",
    "check": "Check",
    "check-circle": "Check Circle",
    "check-circle-o": "Check Circle O",
    "check-square": "Check Square",
    "check-square-o": "Check Square O",
    "chevron-circle-down": "Chevron Circle Down",
    "chevron-circle-left": "Chevron Circle Left",
    "chevron-circle-right": "Chevron Circle Right",
    "chevron-circle-up": "Chevron Circle Up",
    "chevron-down": "Chevron Down",
    "chevron-left": "Chevron Left",
    "chevron-right": "Chevron Right",
    "chevron-up": "Chevron Up",
    "child": "Child",
    "chrome": "Chrome",
    "circle": "Circle",
    "circle-o": "Circle O",
    "circle-o-notch": "Circle O Notch",
    "circle-thin": "Circle Thin",
    "clipboard": "Clipboard",
    "clock-o": "Clock O",
    "clone": "Clone",
    "close": "Close",
    "cloud": "Cloud",
    "cloud-download": "Cloud Download",
    "cloud-upload": "Cloud Upload",
    "cny": "Cny",
    "code": "Code",
    "code-fork": "Code Fork",
    "codepen": "Codepen",
    "codiepie": "Codiepie",
    "coffee": "Coffee",
    "cog": "Cog",
    "cogs": "Cogs",
    "columns": "Columns",
    "comment": "Comment",
    "comment-o": "Comment O",
    "commenting": "Commenting",
    "commenting-o": "Commenting O",
    "comments": "Comments",
    "comments-o": "Comments O",
    "compass": "Compass",
    "compress": "Compress",
    "connectdevelop": "Connectdevelop",
    "contao": "Contao",
    "copy": "Copy",
    "copyright": "Copyright",
    "creative-commons": "Creative Commons",
    "credit-card": "Credit Card",
    "credit-card-alt": "Credit Card Alt",
    "crop": "Crop",
    "crosshairs": "Crosshairs",
    "css3": "Css3",
    "cube": "Cube",
    "cubes": "Cubes",
    "cut": "Cut",
    "cutlery": "Cutlery",
    "dashboard": "Dashboard",
    "dashcube": "Dashcube",
    "database": "Database",
    "dedent": "Dedent",
    "delicious": "Delicious",
    "desktop": "Desktop",
    "deviantart": "Deviantart",
    "diamond": "Diamond",
    "digg": "Digg",
    "dollar": "Dollar",
    "dot-circle-o": "Dot Circle O",
    "download": "Download",
    "dribbble": "Dribbble",
    "dropbox": "Dropbox",
    "drupal": "Drupal",
    "edge": "Edge",
    "edit": "Edit",
    "eject": "Eject",
    "ellipsis-h": "Ellipsis H",
    "ellipsis-v": "Ellipsis V",
    "empire": "Empire",
    "envelope": "Envelope",
    "envelope-o": "Envelope O",
    "envelope-square": "Envelope Square",
    "eraser": "Eraser",
    "eur": "Eur",
    "euro": "Euro",
    "exchange": "Exchange",
    "exclamation": "Exclamation",
    "exclamation-circle": "Exclamation Circle",
    "exclamation-triangle": "Exclamation Triangle",
    "expand": "Expand",
    "expeditedssl": "Expeditedssl",
    "external-link": "External Link",
    "external-link-square": "External Link Square",
    "eye": "Eye",
    "eye-slash": "Eye Slash",
    "eyedropper": "Eyedropper",
    "facebook": "Facebook",
    "facebook-f": "Facebook F",
    "facebook-official": "Facebook Official",
    "facebook-square": "Facebook Square",
    "fast-backward": "Fast Backward",
    "fast-forward": "Fast Forward",
    "fax": "Fax",
    "feed": "Feed",
    "female": "Female",
    "fighter-jet": "Fighter Jet",
    "file": "File",
    "file-archive-o": "File Archive O",
    "file-audio-o": "File Audio O",
    "file-code-o": "File Code O",
    "file-excel-o": "File Excel O",
    "file-image-o": "File Image O",
    "file-movie-o": "File Movie O",
    "file-o": "File O",
    "file-pdf-o": "File Pdf O",
    "file-photo-o": "File Photo O",
    "file-picture-o": "File Picture O",
    "file-powerpoint-o": "File Powerpoint O",
    "file-sound-o": "File Sound O",
    "file-text": "File Text",
    "file-text-o": "File Text O",
    "file-video-o": "File Video O",
    "file-word-o": "File Word O",
    "file-zip-o": "File Zip O",
    "files-o": "Files O",
    "film": "Film",
    "filter": "Filter",
    "fire": "Fire",
    "fire-extinguisher": "Fire Extinguisher",
    "firefox": "Firefox",
    "flag": "Flag",
    "flag-checkered": "Flag Checkered",
    "flag-o": "Flag O",
    "flash": "Flash",
    "flask": "Flask",
    "flickr": "Flickr",
    "floppy-o": "Floppy O",
    "folder": "Folder",
    "folder-o": "Folder O",
    "folder-open": "Folder Open",
    "folder-open-o": "Folder Open O",
    "font": "Font",
    "fonticons": "Fonticons",
    "fort-awesome": "Fort Awesome",
    "forumbee": "Forumbee",
    "forward": "Forward",
    "foursquare": "Foursquare",
    "frown-o": "Frown O",
    "futbol-o": "Futbol O",
    "gamepad": "Gamepad",
    "gavel": "Gavel",
    "gbp": "Gbp",
    "ge": "Ge",
    "gear": "Gear",
    "gears": "Gears",
    "genderless": "Genderless",
    "get-pocket": "Get Pocket",
    "gg": "Gg",
    "gg-circle": "Gg Circle",
    "gift": "Gift",
    "git": "Git",
    "git-square": "Git Square",
    "github": "Github",
    "github-alt": "Github Alt",
    "github-square": "Github Square",
    "gittip": "Gittip",
    "glass": "Glass",
    "globe": "Globe",
    "google": "Google",
    "google-plus": "Google Plus",
    "google-plus-square": "Google Plus Square",
    "google-wallet": "Google Wallet",
    "graduation-cap": "Graduation Cap",
    "gratipay": "Gratipay",
    "group": "Group",
    "h-square": "H Square",
    "hacker-news": "Hacker News",
    "hand-grab-o": "Hand Grab O",
    "hand-lizard-o": "Hand Lizard O",
    "hand-o-down": "Hand O Down",
    "hand-o-left": "Hand O Left",
    "hand-o-right": "Hand O Right",
    "hand-o-up": "Hand O Up",
    "hand-paper-o": "Hand Paper O",
    "hand-peace-o": "Hand Peace O",
    "hand-pointer-o": "Hand Pointer O",
    "hand-rock-o": "Hand Rock O",
    "hand-scissors-o": "Hand Scissors O",
    "hand-spock-o": "Hand Spock O",
    "hand-stop-o": "Hand Stop O",
    "handshake-o": "Hand Shake",
    "hashtag": "Hashtag",
    "hdd-o": "Hdd O",
    "header": "Header",
    "headphones": "Headphones",
    "heart": "Heart",
    "heart-o": "Heart O",
    "heartbeat": "Heartbeat",
    "history": "History",
    "home": "Home",
    "hospital-o": "Hospital O",
    "hotel": "Hotel",
    "hourglass": "Hourglass",
    "hourglass-1": "Hourglass 1",
    "hourglass-2": "Hourglass 2",
    "hourglass-3": "Hourglass 3",
    "hourglass-end": "Hourglass End",
    "hourglass-half": "Hourglass Half",
    "hourglass-o": "Hourglass O",
    "hourglass-start": "Hourglass Start",
    "houzz": "Houzz",
    "html5": "Html5",
    "i-cursor": "I Cursor",
    "ils": "Ils",
    "image": "Image",
    "inbox": "Inbox",
    "indent": "Indent",
    "industry": "Industry",
    "info": "Info",
    "info-circle": "Info Circle",
    "inr": "Inr",
    "instagram": "Instagram",
    "institution": "Institution",
    "internet-explorer": "Internet Explorer",
    "intersex": "Intersex",
    "ioxhost": "Ioxhost",
    "italic": "Italic",
    "joomla": "Joomla",
    "jpy": "Jpy",
    "jsfiddle": "Jsfiddle",
    "key": "Key",
    "keyboard-o": "Keyboard O",
    "krw": "Krw",
    "language": "Language",
    "laptop": "Laptop",
    "lastfm": "Lastfm",
    "lastfm-square": "Lastfm Square",
    "leaf": "Leaf",
    "leanpub": "Leanpub",
    "legal": "Legal",
    "lemon-o": "Lemon O",
    "level-down": "Level Down",
    "level-up": "Level Up",
    "life-bouy": "Life Bouy",
    "life-buoy": "Life Buoy",
    "life-ring": "Life Ring",
    "life-saver": "Life Saver",
    "lightbulb-o": "Lightbulb O",
    "line-chart": "Line Chart",
    "link": "Link",
    "linkedin": "Linkedin",
    "linkedin-square": "Linkedin Square",
    "linux": "Linux",
    "list": "List",
    "list-alt": "List Alt",
    "list-ol": "List Ol",
    "list-ul": "List Ul",
    "location-arrow": "Location Arrow",
    "lock": "Lock",
    "long-arrow-down": "Long Arrow Down",
    "long-arrow-left": "Long Arrow Left",
    "long-arrow-right": "Long Arrow Right",
    "long-arrow-up": "Long Arrow Up",
    "magic": "Magic",
    "magnet": "Magnet",
    "mail-forward": "Mail Forward",
    "mail-reply": "Mail Reply",
    "mail-reply-all": "Mail Reply All",
    "male": "Male",
    "map": "Map",
    "map-marker": "Map Marker",
    "map-o": "Map O",
    "map-pin": "Map Pin",
    "map-signs": "Map Signs",
    "mars": "Mars",
    "mars-double": "Mars Double",
    "mars-stroke": "Mars Stroke",
    "mars-stroke-h": "Mars Stroke H",
    "mars-stroke-v": "Mars Stroke V",
    "maxcdn": "Maxcdn",
    "meanpath": "Meanpath",
    "medium": "Medium",
    "medkit": "Medkit",
    "meh-o": "Meh O",
    "mercury": "Mercury",
    "microphone": "Microphone",
    "microphone-slash": "Microphone Slash",
    "minus": "Minus",
    "minus-circle": "Minus Circle",
    "minus-square": "Minus Square",
    "minus-square-o": "Minus Square O",
    "mixcloud": "Mixcloud",
    "mobile": "Mobile",
    "mobile-phone": "Mobile Phone",
    "modx": "Modx",
    "money": "Money",
    "moon-o": "Moon O",
    "mortar-board": "Mortar Board",
    "motorcycle": "Motorcycle",
    "mouse-pointer": "Mouse Pointer",
    "music": "Music",
    "navicon": "Navicon",
    "neuter": "Neuter",
    "newspaper-o": "Newspaper O",
    "object-group": "Object Group",
    "object-ungroup": "Object Ungroup",
    "odnoklassniki": "Odnoklassniki",
    "odnoklassniki-square": "Odnoklassniki Square",
    "opencart": "Opencart",
    "openid": "Openid",
    "opera": "Opera",
    "optin-monster": "Optin Monster",
    "outdent": "Outdent",
    "pagelines": "Pagelines",
    "paint-brush": "Paint Brush",
    "paper-plane": "Paper Plane",
    "paper-plane-o": "Paper Plane O",
    "paperclip": "Paperclip",
    "paragraph": "Paragraph",
    "paste": "Paste",
    "pause": "Pause",
    "pause-circle": "Pause Circle",
    "pause-circle-o": "Pause Circle O",
    "paw": "Paw",
    "paypal": "Paypal",
    "pencil": "Pencil",
    "pencil-square": "Pencil Square",
    "pencil-square-o": "Pencil Square O",
    "percent": "Percent",
    "phone": "Phone",
    "phone-square": "Phone Square",
    "photo": "Photo",
    "picture-o": "Picture O",
    "pie-chart": "Pie Chart",
    "pied-piper": "Pied Piper",
    "pied-piper-alt": "Pied Piper Alt",
    "pinterest": "Pinterest",
    "pinterest-p": "Pinterest P",
    "pinterest-square": "Pinterest Square",
    "plane": "Plane",
    "play": "Play",
    "play-circle": "Play Circle",
    "play-circle-o": "Play Circle O",
    "plug": "Plug",
    "plus": "Plus",
    "plus-circle": "Plus Circle",
    "plus-square": "Plus Square",
    "plus-square-o": "Plus Square O",
    "power-off": "Power Off",
    "print": "Print",
    "product-hunt": "Product Hunt",
    "puzzle-piece": "Puzzle Piece",
    "qq": "Qq",
    "qrcode": "Qrcode",
    "question": "Question",
    "question-circle": "Question Circle",
    "quote-left": "Quote Left",
    "quote-right": "Quote Right",
    "ra": "Ra",
    "random": "Random",
    "rebel": "Rebel",
    "recycle": "Recycle",
    "reddit": "Reddit",
    "reddit-alien": "Reddit Alien",
    "reddit-square": "Reddit Square",
    "refresh": "Refresh",
    "registered": "Registered",
    "remove": "Remove",
    "renren": "Renren",
    "reorder": "Reorder",
    "repeat": "Repeat",
    "reply": "Reply",
    "reply-all": "Reply All",
    "retweet": "Retweet",
    "rmb": "Rmb",
    "road": "Road",
    "rocket": "Rocket",
    "rotate-left": "Rotate Left",
    "rotate-right": "Rotate Right",
    "rouble": "Rouble",
    "rss": "Rss",
    "rss-square": "Rss Square",
    "rub": "Rub",
    "ruble": "Ruble",
    "rupee": "Rupee",
    "safari": "Safari",
    "save": "Save",
    "scissors": "Scissors",
    "scribd": "Scribd",
    "search": "Search",
    "search-minus": "Search Minus",
    "search-plus": "Search Plus",
    "sellsy": "Sellsy",
    "send": "Send",
    "send-o": "Send O",
    "server": "Server",
    "share": "Share",
    "share-alt": "Share Alt",
    "share-alt-square": "Share Alt Square",
    "share-square": "Share Square",
    "share-square-o": "Share Square O",
    "shekel": "Shekel",
    "sheqel": "Sheqel",
    "shield": "Shield",
    "ship": "Ship",
    "shirtsinbulk": "Shirtsinbulk",
    "shopping-bag": "Shopping Bag",
    "shopping-basket": "Shopping Basket",
    "shopping-cart": "Shopping Cart",
    "sign-in": "Sign In",
    "sign-out": "Sign Out",
    "signal": "Signal",
    "simplybuilt": "Simplybuilt",
    "sitemap": "Sitemap",
    "skyatlas": "Skyatlas",
    "skype": "Skype",
    "slack": "Slack",
    "sliders": "Sliders",
    "slideshare": "Slideshare",
    "smile-o": "Smile O",
    "soccer-ball-o": "Soccer Ball O",
    "sort": "Sort",
    "sort-alpha-asc": "Sort Alpha Asc",
    "sort-alpha-desc": "Sort Alpha Desc",
    "sort-amount-asc": "Sort Amount Asc",
    "sort-amount-desc": "Sort Amount Desc",
    "sort-asc": "Sort Asc",
    "sort-desc": "Sort Desc",
    "sort-down": "Sort Down",
    "sort-numeric-asc": "Sort Numeric Asc",
    "sort-numeric-desc": "Sort Numeric Desc",
    "sort-up": "Sort Up",
    "soundcloud": "Soundcloud",
    "space-shuttle": "Space Shuttle",
    "spinner": "Spinner",
    "spoon": "Spoon",
    "spotify": "Spotify",
    "square": "Square",
    "square-o": "Square O",
    "stack-exchange": "Stack Exchange",
    "stack-overflow": "Stack Overflow",
    "star": "Star",
    "star-half": "Star Half",
    "star-half-empty": "Star Half Empty",
    "star-half-full": "Star Half Full",
    "star-half-o": "Star Half O",
    "star-o": "Star O",
    "steam": "Steam",
    "steam-square": "Steam Square",
    "step-backward": "Step Backward",
    "step-forward": "Step Forward",
    "stethoscope": "Stethoscope",
    "sticky-note": "Sticky Note",
    "sticky-note-o": "Sticky Note O",
    "stop": "Stop",
    "stop-circle": "Stop Circle",
    "stop-circle-o": "Stop Circle O",
    "street-view": "Street View",
    "strikethrough": "Strikethrough",
    "stumbleupon": "Stumbleupon",
    "stumbleupon-circle": "Stumbleupon Circle",
    "subscript": "Subscript",
    "subway": "Subway",
    "suitcase": "Suitcase",
    "sun-o": "Sun O",
    "superscript": "Superscript",
    "support": "Support",
    "table": "Table",
    "tablet": "Tablet",
    "tachometer": "Tachometer",
    "tag": "Tag",
    "tags": "Tags",
    "tasks": "Tasks",
    "taxi": "Taxi",
    "television": "Television",
    "tencent-weibo": "Tencent Weibo",
    "terminal": "Terminal",
    "text-height": "Text Height",
    "text-width": "Text Width",
    "th": "Th",
    "th-large": "Th Large",
    "th-list": "Th List",
    "thumb-tack": "Thumb Tack",
    "thumbs-down": "Thumbs Down",
    "thumbs-o-down": "Thumbs O Down",
    "thumbs-o-up": "Thumbs O Up",
    "thumbs-up": "Thumbs Up",
    "ticket": "Ticket",
    "times": "Times",
    "times-circle": "Times Circle",
    "times-circle-o": "Times Circle O",
    "tint": "Tint",
    "toggle-down": "Toggle Down",
    "toggle-left": "Toggle Left",
    "toggle-off": "Toggle Off",
    "toggle-on": "Toggle On",
    "toggle-right": "Toggle Right",
    "toggle-up": "Toggle Up",
    "trademark": "Trademark",
    "train": "Train",
    "transgender": "Transgender",
    "transgender-alt": "Transgender Alt",
    "trash": "Trash",
    "trash-o": "Trash O",
    "tree": "Tree",
    "trello": "Trello",
    "tripadvisor": "Tripadvisor",
    "trophy": "Trophy",
    "truck": "Truck",
    "try": "Try",
    "tty": "Tty",
    "tumblr": "Tumblr",
    "tumblr-square": "Tumblr Square",
    "turkish-lira": "Turkish Lira",
    "tv": "Tv",
    "twitch": "Twitch",
    "twitter": "Twitter",
    "twitter-square": "Twitter Square",
    "umbrella": "Umbrella",
    "underline": "Underline",
    "undo": "Undo",
    "university": "University",
    "unlink": "Unlink",
    "unlock": "Unlock",
    "unlock-alt": "Unlock Alt",
    "unsorted": "Unsorted",
    "upload": "Upload",
    "usb": "Usb",
    "usd": "Usd",
    "user": "User",
    "user-md": "User Md",
    "user-plus": "User Plus",
    "user-secret": "User Secret",
    "user-times": "User Times",
    "users": "Users",
    "venus": "Venus",
    "venus-double": "Venus Double",
    "venus-mars": "Venus Mars",
    "viacoin": "Viacoin",
    "video-camera": "Video Camera",
    "vimeo": "Vimeo",
    "vimeo-square": "Vimeo Square",
    "vine": "Vine",
    "vk": "Vk",
    "volume-down": "Volume Down",
    "volume-off": "Volume Off",
    "volume-up": "Volume Up",
    "warning": "Warning",
    "wechat": "Wechat",
    "weibo": "Weibo",
    "weixin": "Weixin",
    "whatsapp": "Whatsapp",
    "wheelchair": "Wheelchair",
    "wifi": "Wifi",
    "wikipedia-w": "Wikipedia W",
    "windows": "Windows",
    "won": "Won",
    "wordpress": "Wordpress",
    "wrench": "Wrench",
    "xing": "Xing",
    "xing-square": "Xing Square",
    "y-combinator": "Y Combinator",
    "y-combinator-square": "Y Combinator Square",
    "yahoo": "Yahoo",
    "yc": "Yc",
    "yc-square": "Yc Square",
    "yelp": "Yelp",
    "yen": "Yen",
    "youtube": "Youtube",
    "youtube-play": "Youtube Play",
    "youtube-square": "Youtube Square",
};
