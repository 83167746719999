import * as React from "react";

import { AutocompleteSelectOption, StringMultiSelectOption } from "../../plugins/mxts/selectOption.types";
import { DistributionChannelType, DynamicSitemapApi, DynamicSitemapBase, LocalizedOptions, Page, Site, SiteApi, WithId } from "@maxxton/cms-api";
import { MxtsApi, RateTypes } from "@maxxton/cms-mxts-api";
import { distributionChannelSpec, rateTypesSpec } from "../../utils/currency.util";
import { getDistributionChannelLazyLoadPage, getMxtsEnv } from "../../plugins/mxts";
import { getLocaleOptions, getMxtsLocaleOptions } from "../../utils/localizedContent.util";
import { getLockedPageNameJsx, renderJsxElement } from "../columnSpec.util";
import { getMxtsTokenType, removeUserLoggedInCookie } from "../../utils/authToken.util";
import { pageSpec, widgetHierarchy } from "./";

import { FormSpec } from "../";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { SelectOption } from "../formSpec.types";
import { autocompleteSiteSpec } from "./autocompleteSite";
import { flowSpec } from "./flow";
import { getClientIdFromToken } from "../../components/admin/utils";
import { getI18nLocaleObject } from "../../i18n";
import { getReservationCategorySelectOptions } from "../../utils/reservationCategories.util";
import { getResortsFilterSpec } from "../../utils/resortsFilter.utils";
import { globalApiContext } from "../../containers/CmsProvider";
import { isLocal } from "../../components/utils";
import { localeSpec } from "./locale";
import { localized } from "../utils";
import { menuSpec } from "./menu";
import namespaceList from "../../i18n/namespaceList";
import { templateSpec } from "./template";
import { themes } from "../../themes";
import { tz } from "moment-timezone";
import { webcontentSpecWithoutPreview } from "./autocompleteWebContent";

const siteDomainValidator = /^[A-Za-z0-9][A-Za-z0-9-.]*\.\D{2,}$/;

/* jscpd:ignore-start */
export const siteSpec: FormSpec<Site> = {
    id: "site",
    name: getI18nLocaleObject(namespaceList.admin, "site"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "sites"),
    api: SiteApi,
    permission: "webmanager.site",
    display(site: Site): string {
        return site.name;
    },
    tableColumns: [
        {
            name: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            uniqueId: "name",
            type: "jsx",
            jsxLoadingElement: (item) => <span>{item.name}</span>,
            jsxElement: async (item: Page & Site & WithId) => renderJsxElement(item, (item: Page & Site & WithId) => <span>{item.name}</span>, getLockedPageNameJsx),
        },
        { name: getI18nLocaleObject(namespaceList.admin, "host"), variable: "host", type: "text" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
    ],
    login(site: Site, e: React.MouseEvent<HTMLInputElement>) {
        const loginUrl = getSiteLoginUrl(site);
        if (loginUrl) {
            window.open(loginUrl);
        }
        e.stopPropagation();
    },
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    permission: "webmanager.site.settings.general",
                    properties: [
                        [
                            {
                                variable: "name",
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                type: "text",
                                required: true,
                            },
                            {
                                variable: "host",
                                label: getI18nLocaleObject(namespaceList.admin, "host"),
                                type: "text",
                                required: true,
                            },
                            {
                                variable: "locale",
                                label: getI18nLocaleObject(namespaceList.admin, "locale"),
                                type: "reference",
                                refType: localeSpec,
                                required: true,
                            },
                            {
                                variable: "fallbackLocaleMultiSelect",
                                label: getI18nLocaleObject(namespaceList.admin, "localeFallbackMultiSelect"),
                                type: "multiselect",
                                async optionList(): Promise<StringMultiSelectOption[]> {
                                    return getMxtsLocaleOptions();
                                },
                            },
                            {
                                variable: "siteFallbackLocalesHavePreference",
                                label: getI18nLocaleObject(namespaceList.admin, "siteFallbackLocalesHavePreference"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "image",
                                label: getI18nLocaleObject(namespaceList.admin, "favicon"),
                                type: "image",
                            },
                            {
                                variable: "hasSecurityCertificate",
                                label: getI18nLocaleObject(namespaceList.admin, "hasSecureCertificate"),
                                type: "checkbox",
                                default: true,
                            },
                            widgetHierarchy<Site, "sitemap">({
                                variable: "sitemap",
                                label: getI18nLocaleObject(namespaceList.admin, "sitemap"),
                                rootTitle: getI18nLocaleObject(namespaceList.admin, "sitemap"),
                                templatesTitle: getI18nLocaleObject(namespaceList.admin, "sitemapComponents"),
                                widgetType: "sitemap",
                                rootType: "multiple",
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "sitesettings"),
                    properties: [
                        [
                            {
                                variable: "maintenanceMode",
                                label: getI18nLocaleObject(namespaceList.admin, "maintenanceMode"),
                                type: "checkbox",
                            },
                            {
                                variable: "enableEmbedding",
                                label: getI18nLocaleObject(namespaceList.admin, "enableEmbedding"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "enablePwa",
                                label: getI18nLocaleObject(namespaceList.admin, "enablePwa"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaAddImage"),
                                visible: (item: Site) => !!item.enablePwa,
                            },
                            {
                                variable: "enableCrawling",
                                label: getI18nLocaleObject(namespaceList.admin, "enableCrawling"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "showUnavailableTypes",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showUnavailableTypes"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "addDecimalToPrices",
                                label: getI18nLocaleObject(namespaceList.admin, "addDecimalToPrices"),
                                type: "checkbox",
                            },
                            {
                                variable: "displayZeroDecimal",
                                label: getI18nLocaleObject(namespaceList.admin, "displayZeroDecimal"),
                                type: "checkbox",
                                visible: (item: Site) => !!item.addDecimalToPrices,
                            },
                            {
                                variable: "useHotJar",
                                label: getI18nLocaleObject(namespaceList.admin, "useHotJar"),
                                type: "checkbox",
                            },
                            {
                                type: "text",
                                variable: "hotJarAccountId",
                                visible: (item: Site) => item.useHotJar,
                                label: getI18nLocaleObject(namespaceList.admin, "hotJarAccountId"),
                            },
                            {
                                variable: "useTrengo",
                                label: getI18nLocaleObject(namespaceList.admin, "useTrengo"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localizedTrengoKey",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "localizedTrengoKey"),
                                        variable: "trengoKey",
                                        type: "text",
                                        default: "",
                                    },
                                ],
                                visible: (item: Site) => !!item.useTrengo,
                            }),
                            {
                                variable: "useNavis",
                                label: getI18nLocaleObject(namespaceList.admin, "useNavis"),
                                type: "checkbox",
                            },
                            {
                                type: "text",
                                variable: "navisAccountNumber",
                                visible: (item: Site) => item.useNavis,
                                label: getI18nLocaleObject(namespaceList.admin, "navisAccountNumber"),
                            },
                            {
                                type: "text",
                                variable: "navisJSPass",
                                visible: (item: Site) => item.useNavis,
                                label: getI18nLocaleObject(namespaceList.admin, "navisJSPass"),
                            },
                            {
                                type: "text",
                                variable: "navisDefaultPhone",
                                visible: (item: Site) => item.useNavis,
                                label: getI18nLocaleObject(namespaceList.admin, "navisDefaultPhone"),
                            },
                            {
                                type: "text",
                                variable: "navisSiteParam",
                                visible: (item: Site) => item.useNavis,
                                label: getI18nLocaleObject(namespaceList.admin, "navisSiteParam"),
                            },
                            {
                                variable: "enableConvertExperiment",
                                label: getI18nLocaleObject(namespaceList.admin, "enableConvertExperiment"),
                                type: "checkbox",
                            },
                            {
                                type: "number",
                                variable: "convertExperimentAccountId",
                                visible: (item: Site) => item.enableConvertExperiment,
                                label: getI18nLocaleObject(namespaceList.admin, "convertExperimentAccountId"),
                            },
                            {
                                type: "number",
                                variable: "convertExperimentProjectId",
                                visible: (item: Site) => item.enableConvertExperiment,
                                label: getI18nLocaleObject(namespaceList.admin, "convertExperimentProjectId"),
                            },
                            {
                                variable: "addDynamicSitemapId",
                                label: getI18nLocaleObject(namespaceList.admin, "addDynamicSitemapId"),
                                type: "checkbox",
                            },
                            {
                                variable: "dynamicSitemapId",
                                label: getI18nLocaleObject(namespaceList.admin, "selectDynamicSitemap"),
                                type: "multiselect",
                                async optionList() {
                                    const dynamicSitemapList = await DynamicSitemapApi.find();
                                    return dynamicSitemapList.map((dynamicSitemap: DynamicSitemapBase & WithId) => ({
                                        text: dynamicSitemap.name,
                                        value: dynamicSitemap._id,
                                    }));
                                },
                                placeholder: getI18nLocaleObject(namespaceList.admin, "selectDynamicSitemap"),
                                visible: (item: Site) => item.addDynamicSitemapId,
                            },
                            {
                                variable: "addTrailingSlashToAllUrls",
                                label: getI18nLocaleObject(namespaceList.admin, "addTrailingSlashToAllUrls"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "flowId",
                                label: getI18nLocaleObject(namespaceList.admin, "flow"),
                                type: "autocomplete",
                                refType: flowSpec,
                            },

                            {
                                variable: "timezoneList",
                                label: getI18nLocaleObject(namespaceList.admin, "timezoneList"),
                                type: "select",
                                async optionList() {
                                    const timezoneList = tz.names();
                                    return timezoneList.map(
                                        (zone: string): SelectOption<string> => ({
                                            label: "(UTC " + tz(zone).format("Z") + ")" + zone,
                                            value: zone,
                                        })
                                    );
                                },
                            },
                            {
                                variable: "enableMultiLanguage",
                                label: getI18nLocaleObject(namespaceList.admin, "enableMultiLanguage"),
                                type: "checkbox",
                                groupName: "localizationGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "localizationGroupTitle"),
                            },
                            {
                                variable: "baseDomainRedirectForDefaultLanguage",
                                label: getI18nLocaleObject(namespaceList.admin, "baseDomainRedirectForDefaultLanguage"),
                                type: "checkbox",
                                groupName: "localizationGroup",
                                visible: (item: Site) => item.enableMultiLanguage,
                            },
                            {
                                variable: "localeMultiSelect",
                                type: "multiselect",
                                label: getI18nLocaleObject(namespaceList.admin, "selectLocales"),
                                async optionList(): Promise<StringMultiSelectOption[]> {
                                    return getLocaleOptions();
                                },
                                groupName: "localizationGroup",
                            },
                            {
                                variable: "useGA",
                                label: getI18nLocaleObject(namespaceList.admin, "useGA"),
                                type: "checkbox",
                                groupName: "GAGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "GAGroupTitle"),
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionGA"),
                                visible: (item: Site) => item.useGA,
                                groupName: "GAGroup",
                            },
                            {
                                type: "text",
                                variable: "themeScriptGA",
                                visible: (item: Site) => item.useGA,
                                label: getI18nLocaleObject(namespaceList.admin, "themeScriptGA"),
                                groupName: "GAGroup",
                            },
                            {
                                variable: "useGA4",
                                label: getI18nLocaleObject(namespaceList.admin, "useGA4"),
                                type: "checkbox",
                                groupName: "GAGroup",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionGA4"),
                                visible: (item: Site) => !!item.useGA4,
                                groupName: "GAGroup",
                            },
                            {
                                type: "text",
                                variable: "trackingIdGA4",
                                visible: (item: Site) => !!item.useGA4,
                                label: getI18nLocaleObject(namespaceList.admin, "trackingIdGA4"),
                                groupName: "GAGroup",
                            },
                            {
                                variable: "useGOpt",
                                label: getI18nLocaleObject(namespaceList.admin, "useGOpt"),
                                type: "checkbox",
                                groupName: "GAGroup",
                            },
                            {
                                type: "text",
                                variable: "trackingIdGOpt",
                                visible: (item: Site) => !!item.useGOpt,
                                label: getI18nLocaleObject(namespaceList.admin, "trackingIdGOpt"),
                                groupName: "GAGroup",
                            },
                            {
                                variable: "useGTM",
                                label: getI18nLocaleObject(namespaceList.admin, "useGTM"),
                                type: "checkbox",
                                groupName: "GAGroup",
                            },
                            {
                                type: "text",
                                variable: "themeScriptGTM",
                                visible: (item: Site) => item.useGTM,
                                label: getI18nLocaleObject(namespaceList.admin, "themeScriptGTM"),
                                groupName: "GAGroup",
                            },
                            {
                                variable: "useDCGroup",
                                label: getI18nLocaleObject(namespaceList.admin, "useDCGroup"),
                                type: "checkbox",
                                groupName: "DCGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "DCGroupTitle"),
                            },
                            {
                                variable: "useDistributionChannelType",
                                label: getI18nLocaleObject(namespaceList.admin, "useDistributionChannelType"),
                                type: "select",
                                optionList: [
                                    {
                                        value: DistributionChannelType.DIRECT,
                                        label: getI18nLocaleObject(namespaceList.admin, DistributionChannelType.DIRECT),
                                    },
                                    {
                                        value: DistributionChannelType.RESELLER,
                                        label: getI18nLocaleObject(namespaceList.admin, DistributionChannelType.RESELLER),
                                    },
                                    {
                                        value: DistributionChannelType.TOUROPERATOR,
                                        label: getI18nLocaleObject(namespaceList.admin, DistributionChannelType.TOUROPERATOR),
                                    },
                                    {
                                        value: DistributionChannelType.CSE,
                                        label: getI18nLocaleObject(namespaceList.admin, DistributionChannelType.CSE),
                                    },
                                ],
                                visible: (item: Site) => !!item?.useDCGroup,
                                groupName: "DCGroup",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoForDcFallback"),
                                visible: (item: Site) => !!item?.useDCGroup,
                                groupName: "DCGroup",
                            },
                            {
                                variable: "dcFallbackWebContentId",
                                label: getI18nLocaleObject(namespaceList.admin, "dcFallbackWebContentId"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "noAccoTypeFoundContentPlaceholder"),
                                visible: (item: Site) => !!item?.useDCGroup && !!item?.useDistributionChannelType,
                                groupName: "DCGroup",
                            },
                            {
                                variable: "dcFallbackTemplateId",
                                label: getI18nLocaleObject(namespaceList.admin, "dcFallbackTemplateId"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "noAccoTypeFoundTemplatePlaceholder"),
                                visible: (item: Site) => !!item?.useDCGroup && !!item?.useDistributionChannelType,
                                groupName: "DCGroup",
                            },

                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    { ...distributionChannelSpec },
                                    { ...rateTypesSpec },
                                    {
                                        variable: "currencySelector",
                                        label: getI18nLocaleObject(namespaceList.admin, "currencySelector"),
                                        type: "autocomplete" as const,
                                        options: async (item: LocalizedOptions[] | undefined, spec: any, root: any, tabLocale: string) => {
                                            const localeContent = item?.find((i) => i.locale === tabLocale);
                                            const selectedRateTypeIds = localeContent?.rateTypes?.map((rateType) => rateType.value);
                                            let rateTypes: RateTypes[] | undefined;
                                            if (selectedRateTypeIds?.length) {
                                                const env = await getMxtsEnv(globalApiContext());
                                                rateTypes = await MxtsApi.rates(env, {
                                                    rateTypeId: selectedRateTypeIds,
                                                }).then((rates) => rates.content);
                                            }
                                            const currencies = rateTypes?.map((rateType) => rateType.currency);
                                            const currencyOptions: AutocompleteSelectOption[] = [];

                                            currencies?.forEach((currency) => {
                                                if (currency) {
                                                    const uniqueCurrencyOption = !currencyOptions.some((currencyOption) => currencyOption.value === currency.currencyId);
                                                    if (uniqueCurrencyOption) {
                                                        currencyOptions.push({ value: currency.currencyId, label: `${currency.code} (${currency.symbol})` });
                                                    }
                                                }
                                            });

                                            return currencyOptions;
                                        },
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
                                        visible: (localizedOptions?: LocalizedOptions) => !!localizedOptions?.rateTypes?.length,
                                    },
                                    {
                                        variable: "reservationCategoryId",
                                        label: getI18nLocaleObject(namespaceList.admin, "reservationCategoryId"),
                                        type: "autocomplete" as const,
                                        isClearable: false,
                                        options: async (item: any, spec: any, root: any, tabLocale: string) => getReservationCategorySelectOptions(item, tabLocale),
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "selectReservationCategoryId"),
                                        visible: (localizedOptions?: LocalizedOptions) => !!localizedOptions?.distributionChannelId,
                                    },
                                    {
                                        variable: "facebookMetaContent",
                                        type: "text",
                                        label: getI18nLocaleObject(namespaceList.admin, "facebookMetaContent"),
                                    },
                                    ...getResortsFilterSpec<LocalizedOptions>(),
                                    {
                                        variable: "enableExcludeReservationsDcSelect",
                                        label: getI18nLocaleObject(namespaceList.admin, "enableExcludeReservationsDcSelect"),
                                        type: "checkbox",
                                    },
                                    {
                                        variable: "reservationExcludingDcIds",
                                        label: getI18nLocaleObject(namespaceList.admin, "excludeReservationsDcSelect"),
                                        type: "lazyLoadMultiSelect",
                                        lazyLoadOptions: (page: number, searchQuery: string, ids) => getDistributionChannelLazyLoadPage(page, searchQuery, ids, MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "excludeReservationsDcSelectPlaceholder"),
                                        visible: (options: LocalizedOptions) => !!options?.enableExcludeReservationsDcSelect,
                                    },
                                ],
                                visible: (options: Site) => !options.useDCGroup,
                                groupName: "DCGroup",
                            }),
                            {
                                variable: "useMobileDC",
                                label: getI18nLocaleObject(namespaceList.admin, "useMobileDC"),
                                type: "checkbox",
                                groupName: "DCGroup",
                            },
                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    {
                                        variable: "mobileDCId",
                                        label: getI18nLocaleObject(namespaceList.admin, "mobileDC"),
                                        type: "lazyLoadAutoComplete",
                                        lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
                                    },
                                ],
                                visible: (item: Site) => item.useMobileDC,
                                groupName: "DCGroup",
                            }),
                            {
                                variable: "menuId",
                                label: getI18nLocaleObject(namespaceList.admin, "menu"),
                                type: "autocomplete",
                                refType: menuSpec,
                                groupName: "menuGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "menuGroupTitle"),
                            },
                            {
                                variable: "menuSize",
                                label: getI18nLocaleObject(namespaceList.admin, "menuSize"),
                                type: "text",
                                groupName: "menuGroup",
                            },
                            {
                                variable: "menuIconLocation",
                                label: getI18nLocaleObject(namespaceList.admin, "menuLocation"),
                                type: "checkbox",
                                default: false,
                                groupName: "menuGroup",
                            },
                            {
                                variable: "animation",
                                label: getI18nLocaleObject(namespaceList.admin, "sidebaranimation"),
                                type: "select",
                                default: "push",
                                groupName: "menuGroup",
                                optionList: [
                                    { value: "slide", label: getI18nLocaleObject(namespaceList.admin, "slide") },
                                    { value: "stack", label: getI18nLocaleObject(namespaceList.admin, "stack") },
                                    { value: "elastic", label: getI18nLocaleObject(namespaceList.admin, "elastic") },
                                    { value: "bubble", label: getI18nLocaleObject(namespaceList.admin, "bubble") },
                                    { value: "push", label: getI18nLocaleObject(namespaceList.admin, "push") },
                                    { value: "pushRotate", label: getI18nLocaleObject(namespaceList.admin, "pushrotate") },
                                    { value: "scaleDown", label: getI18nLocaleObject(namespaceList.admin, "scaleDown") },
                                    { value: "scaleRotate", label: getI18nLocaleObject(namespaceList.admin, "scaleRotate") },
                                    { value: "fallDown", label: getI18nLocaleObject(namespaceList.admin, "fallDown") },
                                    { value: "reveal", label: getI18nLocaleObject(namespaceList.admin, "reveal") },
                                ],
                            },
                            {
                                variable: "ismobile",
                                label: getI18nLocaleObject(namespaceList.admin, "mobiledevice"),
                                type: "checkbox",
                                default: false,
                                groupName: "deviceGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "deviceGroupTitle"),
                            },
                            {
                                variable: "isipad",
                                label: getI18nLocaleObject(namespaceList.admin, "ipaddevice"),
                                type: "checkbox",
                                default: false,
                                groupName: "deviceGroup",
                            },
                            {
                                variable: "isdesktop",
                                label: getI18nLocaleObject(namespaceList.admin, "desktopdevice"),
                                type: "checkbox",
                                default: false,
                                groupName: "deviceGroup",
                            },
                            {
                                variable: "autoAssignUnitToReservation",
                                label: getI18nLocaleObject(namespaceList.admin, "autoAssignUnitToReservation"),
                                type: "checkbox",
                                default: false,
                                groupName: "unitGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "unitGroupTitle"),
                            },
                            {
                                variable: "sortUnitsOnVSI",
                                label: getI18nLocaleObject(namespaceList.admin, "sortUnitsOnVSI"),
                                type: "checkbox",
                                default: false,
                                visible: (site: Site) => !!site.autoAssignUnitToReservation,
                                groupName: "unitGroup",
                            },
                            {
                                variable: "alwaysAddUnitPreferenceCost",
                                label: getI18nLocaleObject(namespaceList.admin, "alwaysAddUnitPreferenceCost"),
                                type: "checkbox",
                                default: false,
                                groupName: "unitGroup",
                            },
                            {
                                type: "text",
                                variable: "reCaptchaSiteKey",
                                label: getI18nLocaleObject(namespaceList.admin, "reCaptchaSiteKey"),
                                groupName: "reCaptchaGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "reCaptchaGroupTitle"),
                            },
                            {
                                type: "text",
                                variable: "reCaptchaSecretKey",
                                label: getI18nLocaleObject(namespaceList.admin, "reCaptchaSecretKey"),
                                groupName: "reCaptchaGroup",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "themeSettings"),
                    permission: "webmanager.site.settings.theme",
                    properties: [
                        [
                            {
                                type: "autocomplete",
                                variable: "themeId",
                                label: getI18nLocaleObject(namespaceList.pluginSettings, "theme"),
                                default: "base",
                                async options(): Promise<Array<SelectOption<any>>> {
                                    return themes.map((theme) => ({
                                        value: theme.id,
                                        label: theme.name,
                                    }));
                                },
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "extraSettings"),
                    properties: [
                        [
                            {
                                variable: "useCustomSmtpServer",
                                label: getI18nLocaleObject(namespaceList.admin, "useCustomSmtpServer"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "smtpHost",
                                label: getI18nLocaleObject(namespaceList.admin, "smtpHost"),
                                type: "text",
                                visible: (item: Site) => !!item.useCustomSmtpServer,
                            },
                            {
                                variable: "smtpPort",
                                label: getI18nLocaleObject(namespaceList.admin, "smtpPort"),
                                type: "number",
                                default: "",
                                visible: (item: Site) => !!item.useCustomSmtpServer,
                            },
                            {
                                variable: "smtpUsername",
                                label: getI18nLocaleObject(namespaceList.admin, "smtpUsername"),
                                type: "text",
                                visible: (item: Site) => !!item.useCustomSmtpServer,
                            },
                            {
                                variable: "smtpPassword",
                                label: getI18nLocaleObject(namespaceList.admin, "smtpPassword"),
                                type: "text",
                                visible: (item: Site) => !!item.useCustomSmtpServer,
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "robotsDescription"),
                            },
                            {
                                variable: "robots",
                                label: getI18nLocaleObject(namespaceList.admin, "robotsTxt"),
                                type: "textarea",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "loginSettings"),
                    properties: [
                        [
                            {
                                variable: "setupLoginPage",
                                label: getI18nLocaleObject(namespaceList.admin, "setupLoginPage"),
                                type: "checkbox",
                                default: false,
                            },
                            localized({
                                variable: "localizedLoginPageLink",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "site"),
                                        variable: "loginSiteId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: autocompleteSiteSpec,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "page"),
                                        variable: "loginPageId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "loginSiteId",
                                    },
                                ],
                                visible: (item: Site) => !!item.setupLoginPage,
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "ownerSettings"),
                    properties: [
                        [
                            {
                                variable: "showSharedOwnership",
                                label: getI18nLocaleObject(namespaceList.admin, "showSharedOwnership"),
                                type: "checkbox",
                                default: false,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "embedSettings"),
                    properties: [
                        [
                            {
                                variable: "overrideAppId",
                                label: getI18nLocaleObject(namespaceList.admin, "overrideAppId"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "newAppId",
                                label: getI18nLocaleObject(namespaceList.admin, "newAppId"),
                                type: "text",
                                visible: (item: Site) => item.overrideAppId,
                            },
                            {
                                variable: "whitelistedSitesForEmbed",
                                label: getI18nLocaleObject(namespaceList.admin, "whitelistedSitesForEmbed"),
                                type: "creatablegroup",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "whitelistPlaceholder"),
                                buttonLabel: getI18nLocaleObject(namespaceList.admin, "whitelistButtonLabel"),
                                validation: siteDomainValidator,
                            },
                        ],
                    ],
                    visible: (item: Site) => item.enableEmbedding,
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "iframeSettings"),
                    properties: [
                        [
                            {
                                variable: "whitelistedSitesForIframe",
                                label: getI18nLocaleObject(namespaceList.admin, "whitelistedSitesForIframe"),
                                type: "creatablegroup",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "whitelistPlaceholder"),
                                buttonLabel: getI18nLocaleObject(namespaceList.admin, "whitelistButtonLabel"),
                                validation: siteDomainValidator,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "pwaSettings"),
                    properties: [
                        [
                            {
                                variable: "pwaSiteName",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaSiteName"),
                                type: "text",
                            },
                            {
                                variable: "pwaShortName",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaShortName"),
                                type: "text",
                            },
                            {
                                variable: "pwaIcon",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaIcon"),
                                type: "image",
                            },
                            {
                                variable: "pwaStartUrl",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaStartUrl"),
                                type: "text",
                            },
                            {
                                variable: "pwaScope",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaScope"),
                                type: "text",
                            },
                            {
                                variable: "pwaDirection",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaDirection"),
                                type: "select",
                                optionList: [
                                    { label: "Left to Right", value: "ltr" },
                                    { label: "Right to Left", value: "rtl" },
                                ],
                            },
                            {
                                variable: "pwaDisplay",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaDisplay"),
                                type: "select",
                                optionList: [
                                    { label: "Fullscreen", value: "fullscreen" },
                                    { label: "Standalone", value: "standalone" },
                                    { label: "Minimal UI", value: "minimal-ui" },
                                    { label: "Browser", value: "browser" },
                                ],
                            },
                            {
                                variable: "pwaThemeColor",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaThemeColor"),
                                type: "dual-color",
                                default: "#FFFFFF",
                            },
                            {
                                variable: "pwaBackgroundColor",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaBackgroundColor"),
                                type: "dual-color",
                                default: "#FFFFFF",
                            },
                        ],
                        [
                            {
                                variable: "pwaServices",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaServices"),
                                type: "checkbox",
                            },
                        ],
                        [
                            {
                                variable: "pwaEnableGeolocation",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaEnableGeolocation"),
                                type: "checkbox",
                                visible: (item: Site) => !!item.pwaServices,
                            },
                            {
                                variable: "pwaEnableBluetooth",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaEnableBluetooth"),
                                type: "checkbox",
                                visible: (item: Site) => !!item.pwaServices,
                            },
                            {
                                variable: "pwaEnableCameraAccess",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaEnableCameraAccess"),
                                type: "checkbox",
                                visible: (item: Site) => !!item.pwaServices,
                            },
                            {
                                variable: "pwaEnableStorageAccess",
                                label: getI18nLocaleObject(namespaceList.admin, "pwaEnableStorageAccess"),
                                type: "checkbox",
                                visible: (item: Site) => !!item.pwaServices,
                            },
                        ],
                    ],
                    visible: (item: Site) => !!item.enablePwa,
                },
            ],
        },
    ],
};
/* jscpd:ignore-end */

export function getSiteLoginUrl(site: Site, additionalUrlPath?: string): string | undefined {
    const host = site.host;
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN);
    const clientId = getClientIdFromToken();
    if (host && token) {
        const tokenType = getMxtsTokenType().toLowerCase();
        const protocol = site.hasSecurityCertificate && !isLocal() ? "https" : "http";
        return `${protocol}://${host}${additionalUrlPath ? additionalUrlPath : ""}#access_token=${token}&token_type=${tokenType}&client_id=${clientId}`;
    } else if (!token) {
        removeUserLoggedInCookie();
    }
    return undefined;
}
