import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { Widget } from "@maxxton/cms-api";
import { getCMSOptions } from "../plugins/settings";

export class ContextUtil {
    public static async getCurrentFlow(context: CMSProviderProperties): Promise<Widget | undefined> {
        let sitePage: any = null;
        // if condition to get the current page in page preview
        // else condition to get the current page in actual page rendering using friendly url
        if ((context as any).item) {
            sitePage = await context.cmsApi.siteApi.findSitemapByPageAndSite({ siteId: context.site._id, pageId: (context as any).item._id });
        } else {
            sitePage = await context.cmsApi.siteApi.findSitemapFromSiteByFriendlyUrl({ siteId: context.site._id, url: (context.location as any).pathname });
        }
        if (context.flow && sitePage) {
            return context.flow.root.find((step) => step.options.pageId === sitePage.options.pageId);
        }
        return undefined;
    }

    public static async isAutoAssignUnitToReservation(context: CMSProviderProperties): Promise<boolean> {
        const cmsOptions = await getCMSOptions(context.cmsApi);
        const autoAssignUnitToReservationOptions = {
            cmsSetting: cmsOptions?.autoAssignUnitToReservation,
            siteSetting: context?.site?.autoAssignUnitToReservation,
            flowSetting: context?.flow?.autoAssignUnitToReservation,
        };
        return !!autoAssignUnitToReservationOptions.cmsSetting || !!autoAssignUnitToReservationOptions.siteSetting || !!autoAssignUnitToReservationOptions.flowSetting;
    }

    public static async shouldSortUnitsOnVSI(context: CMSProviderProperties): Promise<boolean> {
        const cmsOptions = await getCMSOptions(context.cmsApi);
        const sortUnitsOnVSIOptions = {
            cmsSetting: cmsOptions?.sortUnitsOnVSI,
            siteSetting: context?.site?.sortUnitsOnVSI,
            flowSetting: context?.flow?.sortUnitsOnVSI,
        };
        return !!sortUnitsOnVSIOptions.cmsSetting || !!sortUnitsOnVSIOptions.siteSetting || !!sortUnitsOnVSIOptions.flowSetting;
    }

    public static async shouldAlwaysAddUnitPreferenceCost(context: CMSProviderProperties): Promise<boolean> {
        const cmsOptions = await getCMSOptions(context.cmsApi);
        return !!cmsOptions?.alwaysAddUnitPreferenceCost || !!context?.site?.alwaysAddUnitPreferenceCost;
    }
}
