export enum Sort {
    defaultSorting,
    lowToHighPrice,
    highToLowPrice,
    ascendingName,
    descendingName,
    highToLowRating,
    lowToHighRating,
    priority,
    ascendingArrivalDate,
    descendingArrivalDate,
    minToMaxDuration,
    maxToMinDuration,
    minToMaxDateAndTime,
    maxToMinDateAndTime,
    lowToHighCapacity,
    highToLowCapacity,
}
