import * as React from "react";

interface EventOptions {
    capture?: boolean;
    passive?: boolean;
    once?: boolean;
}

export const useEventListener = (eventName: string, handler: ((event: Event) => void | null) | undefined, element: EventTarget | undefined, options: EventOptions = {}) => {
    const savedHandler = React.useRef<((event: Event) => void) | undefined>();
    const { capture, passive, once } = options;

    React.useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    React.useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) {
            return;
        }

        const eventListener = (event: Event) => savedHandler.current?.(event);
        const opts = { capture, passive, once };
        element?.addEventListener(eventName, eventListener, opts);
        return () => {
            element?.removeEventListener(eventName, eventListener, opts);
        };
    }, [eventName, element, capture, passive, once]);
};
