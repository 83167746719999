import { FormSpec, getSiteGroupOptions, localized, multiSelectStylePicker, tagSpec } from "../";
import { Post, PostApi } from "@maxxton/cms-api";

import { StringMultiSelectOption } from "../../plugins/mxts/selectOption.types";
import { autocompleteSiteSpec } from "./autocompleteSite";
import { categoryTableColumn } from "../columnSpec.util";
import { findMultiSelectStyleClassNames } from "../../themes";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import { templateSpec } from "./template";

const TARGETS = ["webcontent", "webcontent-model"];

export const postSpec: FormSpec<Post> = {
    id: "post",
    name: getI18nLocaleObject(namespaceList.admin, "post"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "posts"),
    api: PostApi,
    permission: "webmanager.posts",
    display(post: Post): string {
        return post.name;
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" },
        { name: getI18nLocaleObject(namespaceList.admin, "url"), variable: "permalink", type: "anchor" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
        categoryTableColumn(),
    ],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "site"),
                                variable: "siteId",
                                type: "autocomplete",
                                refType: autocompleteSiteSpec,
                                required: true,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "addPostToSiteGroups"),
                                variable: "addPostToSiteGroups",
                                type: "checkbox",
                                visible: (item: Post) => !!item.siteId,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "postSiteGroups"),
                                variable: "postSiteGroups",
                                type: "multiselect",
                                async optionList(): Promise<StringMultiSelectOption[]> {
                                    return getSiteGroupOptions();
                                },
                                visible: (item: Post) => !!item.addPostToSiteGroups,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "includedateinurl"),
                                variable: "isIncludeDateInUrl",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "customSubfolderDescription"),
                                type: "paragraph",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "includeCustomSubfolderStructure"),
                                variable: "customSubfolderStructure",
                                type: "text",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "postClickOnCard"),
                                variable: "postClickOnCard",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "url"),
                                variable: "permalink",
                                type: "anchor",
                                url: (item: Post) => item.permalink || "",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "useImageAsHeaderImage"),
                                variable: "useImageAsHeaderImage",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.linkingSpec, "openInNewTab"),
                                variable: "openInNewTab",
                                type: "checkbox",
                            },
                            multiSelectStylePicker("styleIds", TARGETS),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "localizedImageContent",
                                tabContent: [
                                    {
                                        variable: "image",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "postImage"),
                                        type: "image",
                                    },
                                ],
                            }),
                            {
                                variable: "image",
                                label: getI18nLocaleObject(namespaceList.admin, "thumbnailImage"),
                                type: "image",
                            },
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "videoLocalizedContent"),
                                variable: "videoLocalizedContent",
                                tabContent: [
                                    {
                                        variable: "content",
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "video"),
                                variable: "videoContent",
                                type: "text",
                            },
                            tagSpec({ variable: "tags", tags: (item: Post) => item.tags }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "title"),
                                variable: "title",
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.admin, "content"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "shortcontent"),
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.admin, "content"),
                                        type: "richtext",
                                        style: (item: Post, theme) => findMultiSelectStyleClassNames(theme, TARGETS, item.styleIds),
                                    },
                                ],
                            }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "detailcontent"),
                                variable: "localizedDetailContent",
                                // jscpd:ignore-start
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.admin, "content"),
                                        type: "richtext",
                                        style: (item: Post, theme) => findMultiSelectStyleClassNames(theme, TARGETS, item.styleIds),
                                    },
                                ],
                            }),
                            // jscpd:ignore-end
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "scheduling"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isPublishDateConfigured"),
                                variable: "isPublishDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "publishDate",
                                label: getI18nLocaleObject(namespaceList.admin, "publishDate"),
                                type: "datetime",
                                visible: (item: Post) => item.isPublishDateConfigured,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isExpirationDateConfigured"),
                                variable: "isExpirationDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "expirationDate",
                                label: getI18nLocaleObject(namespaceList.admin, "expirationDate"),
                                type: "datetime",
                                visible: (item: Post) => item.isExpirationDateConfigured,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "layout"),
                    properties: [
                        [
                            {
                                variable: "headerTemplateId",
                                label: getI18nLocaleObject(namespaceList.admin, "headertemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                required: true,
                            },
                            {
                                variable: "footerTemplateId",
                                label: getI18nLocaleObject(namespaceList.admin, "footertemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                required: true,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "seo"),
                    properties: [
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "seoItems"),
                                variable: "localizedContents",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "metaTitle"),
                                        variable: "metaTitleSeo",
                                        type: "text",
                                        required: true,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "metaDescription"),
                                        variable: "metaDescriptionSeo",
                                        type: "text",
                                        required: true,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "metaKeywords"),
                                        variable: "metaKeywordsSeo",
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "structuredDataBox"),
                                variable: "structuredData",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "metaRobots"),
                                variable: "metaRobots",
                                type: "text",
                                default: "index, follow",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "structuredData"),
                    visible: (options: Post) => options.structuredData,
                    properties: [
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "structuredData"),
                                variable: "localizedContents",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "image"),
                                        variable: "imageStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "title"),
                                        variable: "titleStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "url"),
                                        variable: "urlStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "description"),
                                        variable: "descriptionStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "author"),
                                        variable: "authorStructuredSeo",
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "structuredDataMarkupTab"),
                    properties: [
                        [
                            localized({
                                variable: "localizedContents",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "structuredDataMarkup"),
                                        variable: "structuredDataMarkup",
                                        type: "textarea",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "category"),
                    properties: [
                        [
                            {
                                variable: "category",
                                type: "category",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};
