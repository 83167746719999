import { CmsApi, CmsApiWrapper, Locale, Site, Translation, WithId, globalLogger } from "@maxxton/cms-api";

import { CurrentLocale } from "../app.types";
import { StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { translations as dynamicTranslations } from "./translations/index";

const res: any = {};
const builtinTranslations: any = {};
let cmsLocaleCode = "en";

export interface I18nLocaleObject {
    namespace: string;
    key: string;
    currentLocale?: CurrentLocale;
    site?: Site;
    text?: string;
}
interface Resources {
    [ns: string]: { [key: string]: string };
}

function cloneResources(resource: Resources): Resources {
    const copy: Resources = {};
    if (resource) {
        Object.keys(resource).forEach((ns) => {
            copy[ns] = {};
            Object.keys(resource[ns]).forEach((key) => {
                copy[ns][key] = resource[ns][key];
            });
        });
    }
    return copy;
}

export async function loadI18n(localeCode: string, localeId: string, site: Site & WithId, paramLocale: (Locale & WithId) | undefined, isAdmin: boolean, cmsApi: CmsApiWrapper = CmsApi) {
    cmsLocaleCode = isAdmin ? localeCode : paramLocale ? localeCode : site.locale.code;
    try {
        const [localeTranslations] = await Promise.all([cmsApi.translationApi.findByLocale({ locale: localeId })]);

        const filteredLocaleTranslations =
            localeTranslations &&
            localeTranslations.filter(
                (ts: Translation & WithId) =>
                    // Filtering all locale translations which don't have a Site, eg. those applicable to all sites
                    !ts.site || ts.site === site._id
            );

        // this way site specific translations override general translations.
        const translations = [...filteredLocaleTranslations];
        builtinTranslations[localeCode] = (await dynamicTranslations[localeCode]())[localeCode];
        res[localeCode] = cloneResources(builtinTranslations[localeCode]);
        translations.forEach((translation: Translation) => {
            const localeResources: any = (res[localeCode] = res[localeCode] || {});
            const ns = localeResources[translation.namespace] || {};
            ns[translation.key] = translation.value;
        });
    } catch (err) {
        globalLogger.error(`Error while loading i18n for localCode: ${localeCode}, paramLocale: ${JSON.stringify(paramLocale || {})}, cmsLocaleCode ${cmsLocaleCode}}.`, err);
    }
}

export const getI18nLocaleObject = (namespace: string, key: string, currentLocale?: CurrentLocale, site?: Site): I18nLocaleObject => ({ namespace, key, currentLocale, site });

export const getI18nLocaleObjectWithText = (namespace: string, key: string, text?: string, currentLocale?: CurrentLocale, site?: Site): I18nLocaleObject => ({
    namespace,
    key,
    currentLocale,
    site,
    text,
});

export function getI18nLocaleString(namespace: string, key: string, currentLocale?: CurrentLocale, site?: Site): string {
    let i18nLocaleString: string;
    if (currentLocale && site) {
        i18nLocaleString = res[currentLocale.code]?.[namespace]?.[key] || builtinTranslations?.[currentLocale.code]?.[namespace]?.[key];
        if (!i18nLocaleString) {
            const fallbackLocaleCodes: string[] = getFallbackLocaleCodesFromSettings({ site, currentLocale });
            for (const fallbackLocaleCode of fallbackLocaleCodes) {
                i18nLocaleString = res[fallbackLocaleCode]?.[namespace]?.[key] || builtinTranslations?.[fallbackLocaleCode]?.[namespace]?.[key];
                if (i18nLocaleString) {
                    return i18nLocaleString;
                }
            }
        }
    } else {
        i18nLocaleString = res[cmsLocaleCode]?.[namespace]?.[key] || builtinTranslations?.[cmsLocaleCode]?.[namespace]?.[key];
    }

    return i18nLocaleString ? i18nLocaleString : namespace + "." + key;
}

export function getI18nLocaleStringFromParams(i18nObj: I18nLocaleObject | string): string {
    if (typeof i18nObj === "string") {
        return i18nObj;
    }
    const { currentLocale, site, namespace, key, text } = { ...i18nObj };
    if (text) {
        return text.replace("$", getI18nLocaleString(namespace, key, currentLocale, site));
    }
    return getI18nLocaleString(namespace, key, currentLocale, site);
}

/**
 * Returns an ordered array of locale codes which will be used as fallback locales.
 *
 * Note: Ideally we would have used the similar function from localizedContent.util.ts, but this gives initialization issues due to imports.
 */
export const getFallbackLocaleCodesFromSettings = ({ site, currentLocale }: { site: Site; currentLocale: CurrentLocale }): string[] => {
    const { fallbackLocaleMultiSelect: currentLocaleFallbackLocaleMultiSelect } = currentLocale;
    const { fallbackLocaleMultiSelect: siteFallbackLocaleMultiSelect, siteFallbackLocalesHavePreference } = site;
    const currentLocaleFallbackLocaleCodes: string[] =
        currentLocaleFallbackLocaleMultiSelect?.map((option: StringMultiSelectOption) => (option.label && getI18nLocaleStringFromParams(option.label)) || "") || [];
    const siteFallbackLocales: string[] = siteFallbackLocaleMultiSelect?.map((option: StringMultiSelectOption) => (option.label && getI18nLocaleStringFromParams(option.label)) || "") || [];

    const fallbackLocaleCodes: string[] = [];

    if (siteFallbackLocalesHavePreference) {
        fallbackLocaleCodes.push(...siteFallbackLocales);
        fallbackLocaleCodes.push(...currentLocaleFallbackLocaleCodes);
    } else {
        fallbackLocaleCodes.push(...currentLocaleFallbackLocaleCodes);
        fallbackLocaleCodes.push(...siteFallbackLocales);
    }

    return fallbackLocaleCodes;
};
