import { Action } from "./";
import { AvailabilityState } from "../reducers/availability.types";

export enum AvailabilityActionType {
    fetching = "availability_fetching",
    fetched = "availability_fetched",
    error = "availability_error",
}

export interface AvailabilityAction extends Action {
    actionType: AvailabilityActionType;
    payload?: AvailabilityState;
}
