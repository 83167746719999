import { Theme } from "./";
import baseTheme from "./base";
import myEnvironmentTheme from "./myenvironment";

const theme: Theme = {
    id: "yosaa",
    name: "Yosaa Theme",
    stylesheets: ["/styles/themes/yosaa/yosaa-admin.css"],
    frontendStylesheets: ["/styles/themes/yosaa/yosaa.css"],
    styles: [
        ...baseTheme.styles,
        ...myEnvironmentTheme.styles,
        {
            id: "button-with-arrow-right",
            name: "Button with arrow (right)",
            targets: ["button"],
            classnames: ["button-with-arrow--right"],
        },
        {
            id: "button-with-arrow-left",
            name: "Button with arrow (left)",
            targets: ["button"],
            classnames: ["button-with-arrow--left"],
        },
        {
            id: "link-with-arrow-right",
            name: "Link with arrow (right)",
            targets: ["button"],
            classnames: ["link-with-arrow--right"],
        },
        {
            id: "link-with-arrow-left",
            name: "Link with arrow (left)",
            targets: ["button"],
            classnames: ["link-with-arrow--left"],
        },
    ],
};

export default theme;
