import { Customer, CustomerFullAddress } from "@maxxton/cms-mxts-api";
import { MyEnvReservation, MyEnvState } from "./myEnvState";
import { fetchMyEnvCustomer, fetchSelectedMyEnvReservation } from "../../actions/myEnvAction";

import { Dispatch } from "redux";
import { getMyEnvAuthToken } from "../../../utils/authToken.util";

export function shouldStoreMyEnvStateInUrl(myEnvState: MyEnvState): boolean {
    return !!myEnvState?.selectedReservationId || !!myEnvState.selectedReservation || !!myEnvState.ownerState?.selectedUnitId || !!myEnvState.mainCustomer;
}

export function getSelectedMyEnvReservation(
    reservationParams: {
        myEnvState: MyEnvState | undefined;
        esReservationResult?: MyEnvReservation;
        dispatchAction: Dispatch<any>;
    },
    isMyEnvWidget = true
): MyEnvReservation | undefined {
    const { myEnvState, esReservationResult, dispatchAction } = reservationParams;
    if (esReservationResult) {
        return esReservationResult;
    } else if (myEnvState?.selectedReservation) {
        return myEnvState.selectedReservation;
    }
    if (isMyEnvWidget) {
        dispatchAction(fetchSelectedMyEnvReservation());
    }
    return undefined;
}

export function getMyEnvMainCustomer(myEnvCustomerParams: { myEnvState: MyEnvState | undefined; dispatchAction: Dispatch<any> }): Customer | undefined {
    const { myEnvState, dispatchAction } = myEnvCustomerParams;
    if (myEnvState?.mainCustomer && myEnvState.mainCustomer.customerId === getMyEnvAuthToken()?.payload?.mainCustomerId) {
        return myEnvState.mainCustomer;
    }
    dispatchAction(fetchMyEnvCustomer());
    return undefined;
}

export function getMyEnvAddress(myEnvCustomerParams: { myEnvState: MyEnvState | undefined; dispatchAction: Dispatch<any> }): CustomerFullAddress | undefined {
    const customer: Customer | undefined = getMyEnvMainCustomer(myEnvCustomerParams);
    if (customer?.addresses?.length) {
        return customer.addresses[0];
    }
    return undefined;
}
