// Be careful while sorting imports of this file as it breaks the build
/* eslint-disable sort-imports */
import * as React from "react";
import { matchPath, PathMatch } from "react-router-dom";

import { Site, Widget, SiteApi, WithId } from "@maxxton/cms-api";
import { isSitemapWidget, parseApiWidget } from "../plugins";

import { stringify } from "query-string";

import { getEnvironment } from "../plugins/mxts";

import { ApiContext, CMSProvidedProperties, CMSProviderProperties } from "../containers/cmsProvider.types";

import { Config } from "..";
import { LocalizedFriendlyUrl, SitemapPageLinkWidgetOptions } from "../plugins/sitemap/sitemap.types";
import { CurrentLocale, PrefetchAppParams } from "../app.types";
import { PathComponent, Route } from "./routing.types";
import { contextType } from "react-fontawesome";

export interface PathCache {
    [index: string]: PathComponent;
}

export function pruneCache(pathCache: PathCache, maxSize: number): PathCache {
    // In JS keys are sorted by insertion order
    // Thus, the former keys are older pages
    const keys = Object.keys(pathCache);
    const prunedKeys = keys.slice(-maxSize); // copies at most maxSize from the end of keys
    const newCache: PathCache = {};
    prunedKeys.forEach((key) => {
        newCache[key] = pathCache[key];
    });
    return newCache;
}

export function findMatches(routes: Route[], path: string): Array<{ route: Route; match: PathMatch<string> }> {
    const matches = routes.map((route) => ({ route, match: matchPath(route.path, path) }));
    const filteredMatches: Array<{ route: Route; match: PathMatch<string> }> = matches.filter((m): m is { route: Route; match: PathMatch<string> } => m.match !== null);
    if (filteredMatches.length === 0) {
        console.error(`Unhandled prefetch patch: ${path}`);
    }
    return filteredMatches;
}

export async function loadPath(routes: Route[], path: string, authorized: boolean, context: CMSProviderProperties): Promise<{ key: string; value: PathComponent } | undefined> {
    const matches = findMatches(routes, path);
    for (const m of matches) {
        let comp: PathComponent | false;
        if (m.route.authorize && !authorized) {
            const env = await getEnvironment(context.cmsApi);
            if (env == null) {
                throw new Error("Environment should not be null");
            }
            try {
                const query = {
                    response_type: "token",
                    client_id: "newyse",
                    scope: "all",
                    concern: env.concern,
                    redirect_uri: location.href.replace(/#$/, ""),
                };
                const cmsConfig: Config = (window as any).cmsConfig;
                const host = cmsConfig.authorisation.baseUrl;
                const redirectUrl = `${host}/uaa/oauth/authorize?${stringify(query)}`;
                location.replace(redirectUrl);
            } catch (e) {
                // ignore: this happens server-side
            }
            // on the server we simply render an empty page
            comp = (props) => <div />;
        } else {
            comp = await m.route.load(m.match, context, path.substring(1));
        }
        if (comp) {
            return { key: path, value: comp };
        }
    }
}

export async function pageLink({
    site,
    pageId,
    context,
    locale,
}: {
    site: Site & WithId;
    pageId: string;
    context?: CMSProvidedProperties | ApiContext;
    locale?: CurrentLocale;
}): Promise<string | undefined> {
    if (!site._id || !pageId) {
        return undefined;
    }
    const sitemapPageLink = context ? await context.cmsApi.siteApi.findSitemapByPageAndSite({ siteId: site._id, pageId }) : await SiteApi.findSitemapByPageAndSite({ siteId: site._id, pageId });

    if (!sitemapPageLink) {
        return undefined;
    }

    const sitemapPageLinkOptions = sitemapPageLink.options as SitemapPageLinkWidgetOptions;
    const isDefaultLocale = !locale || site.locale.code === locale.code;

    if (sitemapPageLinkOptions.home) {
        return `/${site.enableMultiLanguage && locale && !isDefaultLocale ? locale.code : ""}`;
    }

    if (locale) {
        let friendlyUrl = sitemapPageLinkOptions.friendlyUrl;
        if (site.enableMultiLanguage && sitemapPageLinkOptions.localizedFriendlyUrl) {
            const localizedFriendlyUrl = sitemapPageLinkOptions.localizedFriendlyUrl.find((url) => url.locale === locale.locale);
            if (localizedFriendlyUrl?.friendlyUrl) {
                friendlyUrl = localizedFriendlyUrl.friendlyUrl;
            }
        }
        return `${site.enableMultiLanguage && !isDefaultLocale && !sitemapPageLinkOptions.localizedFriendlyUrl ? "/" + locale.code : ""}${friendlyUrl}`;
    }

    return sitemapPageLinkOptions.friendlyUrl;
}

function flatten<E>(ar: E[][]): E[] {
    return ([] as E[]).concat(...ar);
}

export async function loadSiteRoutes(context: CMSProvidedProperties, device?: PrefetchAppParams["device"]): Promise<Route[]> {
    const currentPath = context.customPath !== "" ? context.customPath : (context.location as any).pathname;
    const siteMap = await context.cmsApi.siteApi.findSitemapFromSiteByFriendlyUrl({ siteId: context.site._id, url: currentPath });
    const fetchRoutes = async () => {
        if (siteMap) {
            const widget = await parseApiWidget(siteMap, context);
            const widgetSpec = widget.spec;
            if (isSitemapWidget(widgetSpec)) {
                return widgetSpec.routes(widget, context, device);
            }
        }
        // throw new TypeError("Sitemap expects widgets to be be sitemap widgets");
    };
    const routes = await fetchRoutes();
    return routes || [];
}
