import { InstalmentsActionType, InstalmentsReducerAction } from "../actions/instalmentsAction";

import { Instalment } from "@maxxton/cms-mxts-api";
import { Reducer } from "redux";
import { StringMultiSelectOption } from "../../plugins/mxts/selectOption.types";

export interface InstalmentsState {
    instalments?: Instalment[];
    selectedInstalmentIds?: number[];
    payLater?: boolean;
    reservationId?: number;
    fetchingInstalments?: boolean;
    payerTypesToHide?: StringMultiSelectOption[];
}

export const instalmentsReducer: Reducer<InstalmentsState> = (state: InstalmentsState = {}, action: InstalmentsReducerAction): InstalmentsState => {
    const newState = { ...state, ...action.payload };
    const actionType = action.type;
    switch (actionType) {
        case InstalmentsActionType.INSTALMENTS_FETCHED:
        case InstalmentsActionType.UPDATE:
            return newState;
        default:
            return state;
    }
};
