import * as React from "react";

import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { State } from "../redux";
import { useSelector } from "react-redux";
import { withProvider } from "../utils/withProvider";

export interface Iconstyles {
    fill?: string;
    stroke?: string;
    stopColor?: string;
}
interface StarRatingProps {
    className?: string;
    maxRating?: number;
    rating: number;
    totalStars?: number;
    iconstyles?: Iconstyles;
    ratingIconSize?: number;
    context?: CMSProviderProperties;
}

function removeSpecialChars(str: string) {
    return str.replace(/[`()\W,/]/gi, "");
}

const RatingStars = ({ className, maxRating = 10, rating, totalStars = 5, iconstyles, ratingIconSize }: StarRatingProps) => {
    const [currentMaxRating, setcurrentMaxRating] = React.useState(maxRating);

    const maxRatingRedux = useSelector((state: State) => state.userInterfaceState.reviewRatingsWidget?.maxRating);
    React.useEffect(() => {
        if (maxRatingRedux) {
            if (rating > 5) {
                setcurrentMaxRating(10);
            } else if (maxRatingRedux <= 5) {
                setcurrentMaxRating(5);
            }
        }
    }, []);

    const ratingPerStar = currentMaxRating / totalStars;
    const filled = rating / ratingPerStar;
    let stars: JSX.Element[] = [];
    const lengths = Math.trunc(filled);
    let percValue = Math.trunc((filled - Math.floor(lengths)) * 100);

    for (let i = 0; i < totalStars; i++) {
        if (i > lengths - 1 && lengths - Math.floor(lengths) < 1) {
            if (Math.floor(filled) !== i) {
                percValue = 0;
            }
            const gradId = removeSpecialChars(`${iconstyles?.stopColor || "halfStar"}${percValue}`);
            stars = [
                ...stars,
                <svg
                    key={`rating${i}`}
                    className={`star ${className}  ${ratingIconSize ? `star-size-${ratingIconSize}` : ""}`}
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    style={{ stroke: iconstyles?.stopColor }}
                    data-testid={i === Math.floor(lengths) ? "half-star" : "null"}
                >
                    <linearGradient id={gradId} className="star__gradient" x1="0" x2="100%" y1="0" y2="0">
                        <stop offset={`${percValue}%`} className="svg-filled-stop" style={{ stopColor: iconstyles?.fill }}></stop>
                        <stop offset="0%" className="svg-empty-stop"></stop>
                    </linearGradient>
                    <path
                        d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z"
                        fill={`url(#${gradId})`}
                    />
                </svg>,
            ];
        } else if (i < lengths) {
            stars = [
                ...stars,
                <svg viewBox="-1 -1 35 35" key={`rating${i}`} className={`star star--filled ${className}  ${ratingIconSize ? `star-size-${ratingIconSize}` : ""}`} style={iconstyles && iconstyles}>
                    <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
                </svg>,
            ];
        }
    }
    return (
        <span data-testid="stars" className={className}>
            {stars}
        </span>
    );
};

export default withProvider(RatingStars);
