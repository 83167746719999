import * as React from "react";

import { WebContent as CmsApiWebContent, Template, TemplateApi, WebContentApi, WithId } from "@maxxton/cms-api";

import { AlternativeColumnOptions } from "../page/columns/index";
import { CMSProviderProperties } from "../../containers/cmsProvider.types";
import { DynamicWidgetBaseProps } from "./dynamicWidget.types";
import { ResultLayoutDisplayType } from "../../redux/reducers/userInterfaceReducer";
import { injectPropIntoElementTrees } from "../resultsPanel/resultsPanelUtil";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import { renderPageWidgets } from "../widget";

const WebContent = loadable(() => loadableRetry(() => import("../page/web-content/WebContent")), {
    resolveComponent: ({ WebContent }) => WebContent,
});

export async function getWebContentById(webContentId?: string): Promise<(CmsApiWebContent & WithId) | undefined> {
    if (webContentId) {
        return (await WebContentApi.findById({ id: webContentId })) || undefined;
    }
    return undefined;
}

export async function renderTemplateById(templateId: string | undefined, context: CMSProviderProperties): Promise<JSX.Element[] | undefined> {
    if (templateId) {
        const template: Template | undefined | null = await TemplateApi.findById({ id: templateId });
        if (template) {
            return await renderPageWidgets(template.root, context);
        }
    }
    return undefined;
}

export function renderNoResultsFoundContent(noResultContentParams: {
    noResultsFoundTemplate: JSX.Element[] | undefined | null;
    noResultsFoundWebContent: (CmsApiWebContent & WithId) | undefined | null;
    context: CMSProviderProperties;
}): JSX.Element {
    const { noResultsFoundTemplate, noResultsFoundWebContent, context } = noResultContentParams;
    return (
        <React.Fragment>
            {noResultsFoundWebContent && <WebContent id={noResultsFoundWebContent._id} content={noResultsFoundWebContent} context={context} />}
            {noResultsFoundTemplate && <div className={"template no-results"}>{noResultsFoundTemplate}</div>}
        </React.Fragment>
    );
}

export function getDeviceType(supportTablet: boolean, supportMobile: boolean): "mobile" | "tablet" | undefined {
    if (supportMobile && screen.width < 768) {
        return "mobile";
    } else if (supportTablet && screen.width > 767 && screen.width < 1025) {
        return "tablet";
    }
}

export function getCRPContainerLayoutOptions(
    resultLayoutDisplayType: ResultLayoutDisplayType | undefined,
    deviceType: "mobile" | "tablet" | undefined,
    crpLayouts: CRPLayouts,
    alternativeColumnOptions: AlternativeColumnOptions,
    additionalProps?: Partial<DynamicWidgetBaseProps<any>>
): { targetCrpWidgets: JSX.Element[] | undefined; displayTypeContainer: string; displayTypeChild: string; activityCrpWidgets: JSX.Element[] | undefined } {
    const { columnsTab, columnsDesk, columns, columnsResp, columnsExtraLargeDesk } = alternativeColumnOptions;
    let displayTypeContainer = "";
    let displayTypeChild = "";
    let targetCrpWidgets = crpLayouts.list;
    let activityCrpWidgets = crpLayouts.activityList;
    if (resultLayoutDisplayType === "grid-view" || resultLayoutDisplayType === "grid-view-map" || (resultLayoutDisplayType === undefined && crpLayouts.grid)) {
        targetCrpWidgets = crpLayouts.grid;
        activityCrpWidgets = crpLayouts.activityGrid;
        displayTypeContainer = "row";
        // eslint-disable-next-line max-len
        displayTypeChild = `mb-3 ${columns ? "col-sm-" + columns : ""} ${columnsResp ? "col-" + columnsResp : "col"} ${columnsTab ? "col-md-" + columnsTab : ""} ${
            columnsDesk ? "col-lg-" + columnsDesk : ""
        } ${columnsExtraLargeDesk ? "col-xl-" + columnsExtraLargeDesk : ""}`;
    } else if (deviceType === "mobile") {
        targetCrpWidgets = crpLayouts.mobile;
    } else if (deviceType === "tablet") {
        targetCrpWidgets = crpLayouts.tablet;
    }
    if (targetCrpWidgets && additionalProps) {
        targetCrpWidgets = injectPropIntoElementTrees(targetCrpWidgets, additionalProps);
    }
    if (activityCrpWidgets && additionalProps) {
        activityCrpWidgets = injectPropIntoElementTrees(activityCrpWidgets, additionalProps);
    }
    return { targetCrpWidgets, displayTypeChild, displayTypeContainer, activityCrpWidgets };
}

export interface CRPLayouts {
    grid?: JSX.Element[];
    list?: JSX.Element[];
    mobile?: JSX.Element[];
    tablet?: JSX.Element[];
    activityList?: JSX.Element[];
    activityGrid?: JSX.Element[];
}
