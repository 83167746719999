import { FreeSearch, FreeSearchApi } from "@maxxton/cms-api";
import { I18nACCOMMODATIONKIND, I18nAMENITY, I18nREGION, I18nRESORT, getAmenitiesAutocompleteList, regionOptions, resortOptions } from "../../components/utils";

import { ContentType } from "../../components/components.enum";
import { FormSpec } from "../";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { accoKindOptions } from "../../plugins/mxts";
import { getI18nLocaleObject } from "../../i18n";
import { iconList } from "../utils";
import namespaceList from "../../i18n/namespaceList";

export const freeSearchSpec: FormSpec<FreeSearch> = {
    id: "free-search",
    name: getI18nLocaleObject(namespaceList.admin, "freeSearch"),
    pluralName: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "freeSearches"),
    api: FreeSearchApi,
    permission: "webmanager.freesearch",
    display(freeSearchItem: FreeSearch): string {
        return freeSearchItem.name;
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
    ],
    properties: [
        {
            variable: "name",
            type: "text",
            label: getI18nLocaleObject(namespaceList.admin, "name"),
        },
        {
            variable: "contentType",
            type: "multiselect",
            label: getI18nLocaleObject(namespaceList.dynamicPlugin, "contentType"),
            optionList: () => [
                {
                    value: ContentType.RESORT,
                    text: I18nRESORT,
                },
                {
                    value: ContentType.REGION,
                    text: I18nREGION,
                },
                {
                    value: ContentType.ACCOMMODATION_KIND,
                    text: I18nACCOMMODATIONKIND,
                },
                {
                    value: ContentType.AMENITY,
                    text: I18nAMENITY,
                },
            ],
        },
        {
            variable: "resort",
            type: "multiselect",
            label: getI18nLocaleObject(namespaceList.dynamicPlugin, "resort"),
            optionList: async () => resortOptions(MxtsApi),
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.some((contentType) => contentType.value === ContentType.RESORT),
        },
        {
            variable: "region",
            type: "multiselect",
            label: getI18nLocaleObject(namespaceList.dynamicPlugin, "region"),
            optionList: async () => regionOptions(MxtsApi),
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.some((contentType) => contentType.value === ContentType.REGION),
        },
        {
            variable: "accoKind",
            type: "multiselect",
            label: getI18nLocaleObject(namespaceList.dynamicPlugin, "accoKind"),
            optionList: async () => accoKindOptions(MxtsApi),
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.some((contentType) => contentType.value === ContentType.ACCOMMODATION_KIND),
        },
        {
            variable: "amenity",
            type: "multiselect",
            label: getI18nLocaleObject(namespaceList.dynamicPlugin, "amenity"),
            optionList: async () => getAmenitiesAutocompleteList(MxtsApi),
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.some((contentType) => contentType.value === ContentType.AMENITY),
        },
        {
            variable: "subtitle",
            type: "text",
            label: getI18nLocaleObject(namespaceList.admin, "subtitle"),
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.length > 0,
        },
        {
            variable: "searchString",
            type: "text",
            label: getI18nLocaleObject(namespaceList.admin, "searchString"),
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.length > 0,
        },
        {
            variable: "icon",
            label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty"),
            type: "select",
            optionList: iconList,
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.length > 0,
        },
        {
            variable: "icon",
            type: "icons",
            visible: (item: FreeSearch) => !!item.icon && !!item.contentType && item.contentType.length > 0,
        },
        {
            variable: "isDefault",
            type: "checkbox",
            label: getI18nLocaleObject(namespaceList.admin, "isDefault"),
            visible: (item: FreeSearch) => !!item.contentType && item.contentType.length > 0,
        },
    ],
};
