import * as React from "react";

import { BackgroundPanel, Borders, ButtonColor, CardBorder, ContentBottomPadding, ContentTopPadding, IconPosition, IconSize, ImageOption } from "../../plugins/page/web-content";
import { WebContent as CmsApiWebContent, Locale, LocalizedCardContent, LocalizedImageContent, Site, WebContentApi, WithId } from "@maxxton/cms-api";
import { FormSpec, localized, multiSelectStylePicker, tagSpec } from "../";
import { IconColoring, findAffectedAssets } from "../utils";
import { ImageSpec, getImageSpec } from "../../media";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { autocompleteSiteSpec } from "./autocompleteSite";
import { findMultiSelectStyleClassNames } from "../../themes";
import { getLocalizedCardContent } from "../../plugins/page/web-content/webContent.util";
import { getLocalizedContent } from "../../utils/localizedContent.util";
import { getTimeStampOptions } from "../columnSpec.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";
import { pageLink } from "../../routing";
import { pageSpec } from "./page";

/* jscpd:ignore-start */
const WebContent = loadable(() => loadableRetry(() => import("../../plugins/page/web-content/WebContent")), {
    resolveComponent: ({ WebContent }) => WebContent,
});

const TARGETS = ["webcontent", "webcontent-model"];

export const webcontentSpec: FormSpec<CmsApiWebContent> = {
    id: "webcontent",
    name: getI18nLocaleObject(namespaceList.admin, "webcontent"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "webcontents"),
    api: WebContentApi,
    permission: "webmanager.webcontents",
    display(webContent: CmsApiWebContent): string {
        return webContent.name;
    },
    tableColumns: [
        {
            name: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            type: "text",
        },
        {
            name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "tableCards"),
            variable: "cardEnabled",
            type: "checkbox",
        },
        {
            name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "tableReviews"),
            variable: "reviewsEnabled",
            type: "checkbox",
        },
        ...getTimeStampOptions(),
    ],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            multiSelectStylePicker("styleIds", TARGETS),
                            localized({
                                variable: "isHidden",
                                tabContent: [
                                    {
                                        variable: "hideContent",
                                        type: "checkbox",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "hideContent"),
                                    },
                                ],
                            }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "localizedImageContent",
                                tabContent: [
                                    {
                                        variable: "image",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "image"),
                                        type: "image",
                                    },
                                ],
                            }),
                        ],
                        [
                            {
                                variable: "image",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "image"),
                                type: "image",
                            },
                            {
                                variable: "category",
                                type: "category",
                            },
                            tagSpec({ variable: "tags", tags: (item: CmsApiWebContent) => item.tags }),
                        ],
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "title"),
                                variable: "title",
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.admin, "content"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.admin, "content"),
                                        type: "richtext",
                                        style: (item: CmsApiWebContent, theme) => findMultiSelectStyleClassNames(theme, TARGETS, item.styleIds),
                                        classname: () => "richtext-sticky-toolbar",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "options"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "rawHtmlWidget"),
                                variable: "isRawHtmlWidget",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "validateCss"),
                                variable: "validateCss",
                                type: "checkbox",
                                visible: (item: CmsApiWebContent) => item.isRawHtmlWidget,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "executeAsJavascript"),
                                variable: "isJsWidget",
                                type: "checkbox",
                            },
                            {
                                variable: "cardEnabled",
                                label: getI18nLocaleObject(namespaceList.admin, "cards"),
                                type: "checkbox",
                                visible: (item: CmsApiWebContent) => !item.reviewsEnabled,
                            },
                            {
                                variable: "masonry",
                                label: getI18nLocaleObject(namespaceList.admin, "masonry"),
                                type: "checkbox",
                                visible: (item: CmsApiWebContent) => item.cardEnabled,
                            },
                            {
                                variable: "cardSpecial",
                                label: getI18nLocaleObject(namespaceList.admin, "special"),
                                type: "checkbox",
                                visible: (item: CmsApiWebContent) => item.cardEnabled,
                            },
                            {
                                variable: "cardTeaserEnabled",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "cardTeaser"),
                                type: "checkbox",
                                visible: (item: CmsApiWebContent) => item.cardEnabled,
                            },
                            {
                                variable: "cardRevealerEnabled",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "cardRevealerEnabled"),
                                type: "checkbox",
                                visible: (item: CmsApiWebContent) => item.cardEnabled && item.cardTeaserEnabled,
                            },
                            {
                                variable: "reviewsEnabled",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviews"),
                                type: "checkbox",
                                visible: (item: CmsApiWebContent) => !item.cardEnabled,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "scheduling"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isPublishDateConfigured"),
                                variable: "isPublishDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "publishDate",
                                label: getI18nLocaleObject(namespaceList.admin, "publishDate"),
                                type: "datetime",
                                visible: (item: CmsApiWebContent) => item.isPublishDateConfigured,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isExpirationDateConfigured"),
                                variable: "isExpirationDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "expirationDate",
                                label: getI18nLocaleObject(namespaceList.admin, "expirationDate"),
                                type: "datetime",
                                visible: (item: CmsApiWebContent) => item.isExpirationDateConfigured,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "cardLayout"),
                    visible: (item: CmsApiWebContent) => item.cardEnabled,
                    properties: [
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "localizedCardContent",
                                tabContent: [
                                    {
                                        variable: "cardImage",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "image"),
                                        type: "image",
                                    },
                                    {
                                        variable: "cardTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "title"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardSubTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "subTitle"),
                                        type: "text",
                                    },
                                    {
                                        variable: "location",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "location"),
                                        type: "text",
                                    },
                                    {
                                        variable: "startDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "date"),
                                        type: "date",
                                        default: "",
                                    },
                                    {
                                        variable: "endDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "endDate"),
                                        type: "date",
                                        default: "",
                                        visible: (item: any) => item.startDate,
                                    },
                                    {
                                        variable: "cardAlt",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "alt"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardDescription",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "description"),
                                        type: "textarea",
                                    },
                                    {
                                        variable: "richTextDesc",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "richTextDesc"),
                                        type: "richtext",
                                    },
                                    {
                                        variable: "clickOnCard",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "clickOnCard"),
                                        type: "checkbox",
                                    },
                                    {
                                        variable: "removeOverlayFromImage",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "removeOverlayFromImage"),
                                        type: "checkbox",
                                        visible: (option) => option.clickOnCard,
                                    },
                                    {
                                        variable: "isSitePageUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "useExtUrl"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                    {
                                        type: "paragraph",
                                        label: getI18nLocaleObject(namespaceList.admin, "externalLinkDescription"),
                                        visible: (item: any) => item.isSitePageUrl || (item.isSitePageUrl === undefined && item.externalLink),
                                    },
                                    {
                                        variable: "externalLink",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "externalLink"),
                                        type: "text",
                                        visible: (item: any) => item.isSitePageUrl || (item.isSitePageUrl === undefined && item.externalLink),
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "site"),
                                        visible: (item: any) =>
                                            (item.isSitePageUrl === undefined && !item.externalLink) ||
                                            (item.isSitePageUrl !== undefined && !item.isSitePageUrl) ||
                                            (item.isSitePageUrl && item.siteId && item.pageId && !item.externalLink),
                                        variable: "siteId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: autocompleteSiteSpec,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "page"),
                                        visible: (item: any) =>
                                            (item.isSitePageUrl === undefined && !item.externalLink) ||
                                            (item.isSitePageUrl !== undefined && !item.isSitePageUrl) ||
                                            (item.isSitePageUrl && item.siteId && item.pageId && !item.externalLink),
                                        variable: "pageId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "siteId",
                                    },
                                    {
                                        variable: "openNewTab",
                                        label: getI18nLocaleObject(namespaceList.admin, "openNewTab"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                    {
                                        variable: "noFollowLink",
                                        label: getI18nLocaleObject(namespaceList.admin, "noFollowLink"),
                                        type: "checkbox",
                                        default: false,
                                        visible: (item: any) => item.isSitePageUrl,
                                    },
                                    {
                                        variable: "cardButton",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "button"),
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialCardTab"),
                    visible: (item: CmsApiWebContent) => item.cardEnabled && item.cardSpecial,
                    properties: [
                        [
                            localized({
                                variable: "localizedSpecialCard",
                                tabContent: [
                                    {
                                        variable: "cardSpecialTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialTitle"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardSpecialDiscount",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialDiscount"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardOriginalPrice",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "originalPrice"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardSpecialPrice",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialPrice"),
                                        type: "text",
                                    },
                                    {
                                        variable: "priceDescription",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "priceDescription"),
                                        type: "text",
                                    },
                                    {
                                        variable: "rentability",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "rentability"),
                                        type: "text",
                                    },
                                    {
                                        variable: "addRequestParams",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "addRequestParams"),
                                        type: "checkbox",
                                    },
                                    {
                                        variable: "addStartDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "addStartDate"),
                                        type: "checkbox",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "startDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "date"),
                                        type: "date",
                                        visible: (item: any) => item.addRequestParams && item.addStartDate,
                                    },
                                    {
                                        variable: "addEndDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "addEndDate"),
                                        type: "checkbox",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "endDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "endDate"),
                                        type: "date",
                                        visible: (item: any) => item.addRequestParams && item.addEndDate,
                                    },
                                    {
                                        variable: "stay",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "stay"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "amenities",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "amenities"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "resort",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "resort"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "resourceId",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "resourceId"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "specialCode",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialCode"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewsTab"),
                    visible: (item: CmsApiWebContent) => item.reviewsEnabled,
                    properties: [
                        [
                            {
                                variable: "rating",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewRating"),
                                type: "text",
                            },
                            localized({
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "localizedReviewPerson"),
                                variable: "localizedReviewPerson",
                                tabContent: [
                                    {
                                        variable: "reviewPerson",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewPerson"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "reviewPerson",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewPerson"),
                                type: "text",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
    async preview(webContent: CmsApiWebContent & WithId, context: CMSProvidedProperties, activeLocale: Locale & WithId): Promise<JSX.Element> {
        const options = {
            webContentId: webContent._id,
            styleIds: [],
            backgroundPanel: "default" as BackgroundPanel,
            showIcons: false,
            centerAligned: false,
            iconProperties: "none",
            iconColor: "color-brand" as IconColoring,
            iconPosition: "top" as IconPosition,
            stylingImage: "rectangle" as ImageOption,
            stylingCardBorder: "" as CardBorder,
            buttonColor: "dk-button--primary" as ButtonColor,
            centeredText: false,
            addBullets: false,
            paddingTop: "default-pt" as ContentTopPadding,
            paddingBottom: "default-pb" as ContentBottomPadding,
            borderBox: "" as Borders,
            iconSize: "" as IconSize,
            iconDisplayColumns: false,
            showAnimate: false,
            animateProperties: "",
            horizontalView: false,
            removeOverlayFromImage: false,
        };

        const { site, mxtsApi } = context;

        let cardImageSpec: ImageSpec;
        let imageUrl = "";
        let cardImageUrl = "";
        let sitePageUrl = "";
        const currentLocale = { locale: activeLocale._id, name: activeLocale.name, code: activeLocale.code, fallbackLocaleMultiSelect: activeLocale.fallbackLocaleMultiSelect };
        const localImageContent: LocalizedImageContent | null = getLocalizedContent({
            site,
            currentLocale,
            localizedContent: webContent.localizedImageContent,
            keys: ["image"],
        });
        const imageSpec: ImageSpec = localImageContent?.image || webContent.image;
        if (imageSpec) {
            const image: any = await getImageSpec(context, imageSpec);
            if (Array.isArray(imageSpec) && imageSpec.length > 0 && imageSpec[0] && imageSpec[0].imageId) {
                imageUrl =
                    image !== null && imageSpec[0] && imageSpec[0].options[0]
                        ? image.renderArrayUrl(imageSpec[0].options[0].preset, imageSpec[0].options[0].width, imageSpec[0].options[0].height)
                        : "";
            } else {
                imageUrl = image !== null && imageSpec.options ? image.renderArrayUrl(imageSpec.options.preset, imageSpec.options.width, imageSpec.options.height) : "";
            }
        }

        const localCardContent: LocalizedCardContent | null = await getLocalizedCardContent({
            site,
            currentLocale,
            localizedCardContents: webContent.localizedCardContent,
        });
        if (localCardContent?.cardImage) {
            cardImageSpec = localCardContent.cardImage;
            if (cardImageSpec) {
                const cardImage: any = await getImageSpec(context, cardImageSpec);
                if (Array.isArray(cardImageSpec) && cardImageSpec.length > 0 && cardImageSpec[0] && cardImageSpec[0].options[0]) {
                    cardImageUrl = cardImage !== null ? cardImage.renderArrayUrl(cardImageSpec[0].options[0].preset, cardImageSpec[0].options[0].width, cardImageSpec[0].options[0].height) : "";
                } else {
                    cardImageUrl = cardImage !== null && cardImageSpec.options ? cardImage.renderArrayUrl(cardImageSpec.options.preset, cardImageSpec.options.width, cardImageSpec.options.height) : "";
                }
            }
            if (localCardContent.siteId && localCardContent.pageId) {
                const site: (Site & WithId) | null = await context.cmsApi.siteApi.findById({ id: localCardContent.siteId, projection: { sitemap: 0 } });
                sitePageUrl = sitePageUrl.concat("//");
                if (site) {
                    sitePageUrl = sitePageUrl.concat(site.host);
                    const url = await pageLink({ site, pageId: localCardContent.pageId, context });
                    sitePageUrl = sitePageUrl.concat(url ? url : "");
                }
            }
        }
        return (
            <React.Fragment>
                {webContent.isRawHtmlWidget && <h4 className="m-4">{getI18nLocaleString(namespaceList.widgetWebContent, "previewWarning", context.currentLocale, context.site)}</h4>}
                <WebContent id={webContent._id} imageUrl={imageUrl} cardImageUrl={cardImageUrl} sitePageUrl={sitePageUrl} options={options} context={context} content={webContent} />
            </React.Fragment>
        );
    },
    async linkage(id: string) {
        const affectedModels = await findAffectedAssets(id, "webContentId");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedPages"),
                links: affectedModels.affectedPages,
                specId: "page",
            },
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedTemplates"),
                links: affectedModels.affectedTemplates,
                specId: "template",
            },
        ];
    },
};
/* jscpd:ignore-end */
