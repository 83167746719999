import { SelectedAddOn } from "../redux/reducers/add-ons/selectedAddOnsReducer";

export abstract class BillSagaDataManager {
    private static updatedAddOns: SelectedAddOn[] = [];

    private constructor() {
        /* noop */
    }

    public static pushUpdatedAddOn = (updatedAddOn: SelectedAddOn) => {
        BillSagaDataManager.updatedAddOns = BillSagaDataManager.updatedAddOns.filter((addOn: SelectedAddOn) => addOn.resourceId !== updatedAddOn.resourceId).concat(updatedAddOn);
    };

    public static getUpdatedAddOns = (): SelectedAddOn[] => BillSagaDataManager.updatedAddOns;

    public static clearUpdatedAddOns = (): void => {
        BillSagaDataManager.updatedAddOns = [];
    };
}
