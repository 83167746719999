import { UserLoginFormAction, UserLoginFormActionType } from "../actions/userLoginFormAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface UserLoginFormState {
    draftValue?: { [key: string]: any };
    validations?: {
        [key: string]: {
            [id: string]: {
                isValid: boolean;
                message?: string;
            };
        };
    };
    mandatoryFields?: string[];
    isValidationCheckList?: boolean;
}

export const userLoginFormReducer: Reducer<UserLoginFormState> = (
    state: UserLoginFormState = { draftValue: {}, validations: {}, mandatoryFields: [] },
    action: UserLoginFormAction
): UserLoginFormState => {
    const { actionType, payload } = action;

    switch (action.type) {
        case ActionType.UserLoginForm:
            switch (actionType) {
                case UserLoginFormActionType.change:
                    return {
                        ...state,
                        draftValue: {
                            ...state.draftValue,
                            [payload.specId]: payload.draftValue,
                        },
                    };

                case UserLoginFormActionType.validate:
                    return {
                        ...state,
                        validations: {
                            ...state.validations,
                            [payload.specId]: payload.validations,
                        },
                        mandatoryFields: payload.mandatoryFields,
                    };

                case UserLoginFormActionType.isValidationCheckList:
                    return {
                        ...state,
                        isValidationCheckList: payload.isValidationCheckList,
                    };

                default:
                    return state;
            }
        default:
            return state;
    }
};
