import * as React from "react";

export const SpecialLegend = (props: { specialName: string; onClick?: () => void; isSelected?: boolean; useToggleButton?: boolean; specialButtonContent?: string }) => (
    <div onClick={props.onClick} className={`popup-legend${props.isSelected ? " active" : ""} ${props.useToggleButton ? "popup-legend-special" : ""}`}>
        {!props.useToggleButton && <div className="popup-legend__color" />}
        {props.specialButtonContent ? (
            <div className="popup-legend__text" dangerouslySetInnerHTML={{ __html: props.specialButtonContent }}></div>
        ) : (
            <div className="popup-legend__text">{props.specialButtonContent || props.specialName}</div>
        )}
        {props.useToggleButton && (
            <div className="form-checkbox__special">
                <input type="checkbox" checked={props.isSelected} />
                <span className="slider round"></span>
            </div>
        )}
    </div>
);
