import { ContextSlice, PluginSpec } from "../";

import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

interface PluginOptions {}

const PagelinkPlugin: PluginSpec<PluginOptions> = {
    id: "plugin-menu",
    name: getI18nLocaleObject(namespaceList.pluginMenu, "menuPlugin"),
    description: getI18nLocaleObject(namespaceList.pluginMenu, "menuPluginDescription"),
    widgets: [
        {
            id: "flexbox",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-flexbox" */ "../page/flexbox"));
                context?.chunkExtractor?.addChunk("page-flexbox");
                return await ref.load();
            },
            targetName: "flexboxWidget",
            parameters: ["menu"],
            type: "menu",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "tabs",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-tabs" */ "../page/tabs"));
                context?.chunkExtractor?.addChunk("page-tabs");
                return await ref.load();
            },
            targetName: "tabsWidget",
            parameters: ["menu"],
            type: "menu",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "pagelink",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "menu-pageLink" */ "./page-link"));
                context?.chunkExtractor?.addChunk("menu-pageLink");
                return await ref.load();
            },
            targetName: "pageLinkWidget",
            type: "menu",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "inlinepagelink",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "menu-inlinePageLink" */ "./inline-page-link"));
                context?.chunkExtractor?.addChunk("menu-inlinePageLink");
                return await ref.load();
            },
            targetName: "inlinePageLinkWidget",
            type: "menu",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "dropdownmenu",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "menu-dropdownmenu" */ "./dropdownmenu"));
                context?.chunkExtractor?.addChunk("menu-dropdownmenu");
                return await ref.load();
            },
            targetName: "dropDownMenuWidget",
            type: "menu",
            widgetGroup: WidgetGroup.OTHER,
            container: true,
        },
        {
            id: "group",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "menu-group" */ "./group"));
                context?.chunkExtractor?.addChunk("menu-group");
                return await ref.load();
            },
            targetName: "groupWidget",
            type: "menu",
            widgetGroup: WidgetGroup.OTHER,
            container: true,
        },
        {
            id: "label",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "menu-label" */ "./label"));
                context?.chunkExtractor?.addChunk("menu-label");
                return await ref.load();
            },
            targetName: "labelWidget",
            type: "menu",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "menutemplate",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "menu-template" */ "./template"));
                context?.chunkExtractor?.addChunk("menu-template");
                return await ref.load();
            },
            targetName: "menuTemplateWidget",
            type: "menu",
            widgetGroup: WidgetGroup.OTHER,
        },
    ],
};

export default PagelinkPlugin;
