import { Action } from "./";
import { BillTargetAction } from "./billAction";
import { SelectedAddition } from "../../plugins/dynamic/additions/additions.types";

export enum AdditionActionType {
    update = "addition_update",
    set = "addition_set",
    fetching = "addition_fetching",
    fetched = "addition_fetched",
    error = "addition_error",
}

export interface AdditionActionPayload {
    updatedAddition?: { [key: number]: SelectedAddition };
    selectedAdditions?: { [key: number]: SelectedAddition };
}

export interface AdditionAction extends Action, BillTargetAction {
    actionType: AdditionActionType;
    payload?: AdditionActionPayload;
}
