import * as moment from "moment";

import { AccommodationType, Unit } from "../plugins/mxts/index";
import {
    ActivityStatus,
    ApiCallOptions,
    BillLine,
    BillLineType,
    BillReservedResource,
    Choice,
    ChoiceRequest,
    ChoiceReservedResource,
    ChoiceResourceType,
    ChoiceResult,
    MxtsApiWrapper,
    PagedResult,
    PriceEngineState,
    PriceTask,
    RefreshBillRequest,
    ReservedResource,
    Resource,
    ResourceActivitiesDetailsResponse,
    ResourceType,
    SubjectQuantity,
    VoucherDetailsResponse,
    VoucherType,
    flatten,
} from "@maxxton/cms-mxts-api";
import { ApiContext, CMSProviderProperties } from "../containers/cmsProvider.types";
import { BillChoice, BillState, BillType } from "../redux/reducers/billReducer";
import { getActivitiesDetailsQueryParams, getActivitySections } from "../plugins/page/activityPlanner/activityPlanner.util";
import { getAllPriceEngineAddOnRequestBodies, getSinglePriceEngineAddOnRequestBodies } from "../sagas/billSaga.util";

import { ActivityPlannerState } from "../redux/reducers/activityPlannerReducer";
import { AdditionState } from "../redux/reducers/additionReducer";
import { AdditionsUtil } from "../plugins/dynamic/additions/additions.util";
import { DATE_FORMAT } from "./constants";
import { DateUtil } from "../utils/date.util";
import { DomainObjectUtil } from "./domainobject.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { InstalmentsState } from "../redux/reducers/instalmentsState";
import { ReservationKind } from "../plugins/dynamic/reservation/reservation.enum";
import { SelectedActivity } from "../plugins/page/ticketCounter/TicketCounter";
import { SelectedAddOn } from "../redux/reducers/add-ons/selectedAddOnsReducer";
import { SelectedAddition } from "../plugins/dynamic/additions/additions.types";
import { TicketingTypes } from "../plugins/page/activityPlanner/activityPlanner.enum";
import { cloneDeep } from "lodash";
import { fetchSpecialsByCodes } from "./resource.util";
import { getStartEndDateSubjectsForPrices } from "../components/utils";
import { getVoucherSetResource } from "./voucher.util";
import { reportError } from "./report.utils";

export interface GenerateBillOptions {
    accommodationType?: AccommodationType;
    unit?: Unit;
    applicableRateTypeId?: number;
    specialSearchCode?: string;
    removeSpecialCode?: boolean;
}

function getDefaultTasks(tasks?: string[]) {
    return tasks || [PriceTask.CHEAPEST_CHOICE, PriceTask.GENERATE_BILL];
}

// eslint-disable-next-line max-lines-per-function
export async function generateBillIncludingAddOns(params: {
    env: ApiCallOptions;
    billChoice: BillChoice;
    additionState: AdditionState;
    selectedAddOns: SelectedAddOn[];
    updatedAddOn?: SelectedAddOn;
    reservationId?: number;
    startDate: string;
    endDate: string;
    distributionChannelId: number;
    reservationCategoryId: number;
    rateTypeId?: number;
    offerSearchCode?: string;
    voucherCode?: string;
    resourceId: number;
    apiContext: ApiContext;
    tasks?: string[];
    lastUsedVoucherCode: string | undefined;
    excludedCode: string | undefined;
}): Promise<ChoiceResult> {
    const {
        env,
        billChoice,
        additionState,
        reservationId,
        startDate,
        endDate,
        distributionChannelId,
        reservationCategoryId,
        rateTypeId,
        selectedAddOns,
        updatedAddOn,
        offerSearchCode,
        voucherCode,
        resourceId,
        apiContext,
        lastUsedVoucherCode,
        excludedCode,
    } = params;
    let choiceRequest: ChoiceRequest | null = null;
    let updateAddOnInBillRequest: Partial<RefreshBillRequest> | null = null;
    const tasks = getDefaultTasks(params?.tasks);
    const isBaseChoiceExcluded = !tasks.includes(PriceTask.BASE_CHOICE);
    try {
        choiceRequest = {
            reservationId,
            startDate,
            endDate,
            distributionChannelId,
            reservationCategoryId,
            reservedResources: [],
            // Always get the cheapest choice, also if a special code is provided and price compare widget is not used
            tasks,
        };
        // When we already generated the bill then we can update small changes to it using the /refresh endpoint.
        // Here we are updating bill lines using /refresh endpoint whenever there is any update in an addOn.
        const selectedAdditions: Array<SelectedAddition & { resourceId: number }> = AdditionsUtil.getSelectedAdditionsAsArray(additionState.selectedAdditions);
        let voucherCodeResource: Resource | undefined;
        let lastUsedVoucherCodeResource: Resource | undefined;
        if (lastUsedVoucherCode) {
            lastUsedVoucherCodeResource = await BillUtil.getVoucherCodeResource({ apiContext, env, resourceId, billChoice, voucherCode: lastUsedVoucherCode });
        }
        if (voucherCode) {
            voucherCodeResource = await BillUtil.getVoucherCodeResource({ apiContext, env, resourceId, billChoice, voucherCode });
        }
        /* jscpd:ignore-start */
        const offerSearchCodeResource = await getOfferSearchCodeResource(params.apiContext, env, offerSearchCode);
        if (billChoice.customerBill.length || billChoice.agentBill.length) {
            const mxtsStartDate: string = await DateUtil.getMXTSDateTimeString(params.apiContext, startDate);
            const mxtsEndDate: string = await DateUtil.getMXTSDateTimeString(params.apiContext, endDate);
            if (updatedAddOn) {
                const refreshBillRequest: RefreshBillRequest = { ...choiceRequest, ...BillUtil.convertChoiceResultToRefreshRequest(billChoice), tasks: [PriceTask.RECALCULATE] };
                let childReservedResources: ChoiceReservedResource[] = refreshBillRequest.reservedResources[0].children;
                if (isBaseChoiceExcluded) {
                    if (
                        offerSearchCodeResource.length &&
                        !childReservedResources.find(
                            (childResources) =>
                                childResources?.searchCode === (offerSearchCodeResource as Resource[])[0].searchCode || childResources?.code === (offerSearchCodeResource as Resource[])[0].code
                        )
                    ) {
                        childReservedResources.push({ ...offerSearchCodeResource[0], startDate: mxtsStartDate, endDate: mxtsEndDate, status: "INITIAL", quantity: 1 } as any);
                    }
                    if (
                        voucherCodeResource &&
                        ((childReservedResources.some((childResource) => childResource.searchCode) &&
                            !childReservedResources.find((childResources) => childResources?.searchCode === (voucherCodeResource as Resource)?.searchCode)) ||
                            (childReservedResources.some((childResource) => childResource.code) &&
                                !childReservedResources.find((childResources) => childResources?.code === (voucherCodeResource as Resource)?.code)))
                    ) {
                        childReservedResources.push({ ...voucherCodeResource, startDate: mxtsStartDate, endDate: mxtsEndDate, status: "INITIAL", quantity: 1 } as any);
                    }
                }

                if (lastUsedVoucherCodeResource) {
                    childReservedResources = childReservedResources.filter((children) => lastUsedVoucherCodeResource?.code !== children.code);
                }

                if (excludedCode) {
                    childReservedResources = childReservedResources.filter((children) => excludedCode !== children.code);
                }

                const currentAddOnRequestBodies = await getSinglePriceEngineAddOnRequestBodies({ rateTypeId, selectedAddOn: updatedAddOn });
                childReservedResources = childReservedResources.filter((child) => child.resourceId !== updatedAddOn.resourceId).concat(currentAddOnRequestBodies);

                refreshBillRequest.reservedResources[0].children = childReservedResources;
                refreshBillRequest.customerBill = refreshBillRequest.customerBill.filter((bill) => bill.state !== PriceEngineState.DELETED);
                refreshBillRequest.agentBill = refreshBillRequest.agentBill?.filter((bill) => bill.state !== PriceEngineState.DELETED);

                return params.apiContext.mxtsApi.refreshBill(env, refreshBillRequest);
            } else if (selectedAddOns.length) {
                const refreshBillRequest: RefreshBillRequest = { ...choiceRequest, ...BillUtil.convertChoiceResultToRefreshRequest(billChoice), tasks: [PriceTask.RECALCULATE] };
                let childReservedResources: ChoiceReservedResource[] = refreshBillRequest.reservedResources[0].children;

                if (isBaseChoiceExcluded) {
                    if (
                        offerSearchCodeResource.length &&
                        !childReservedResources.find(
                            (childResources) =>
                                childResources?.searchCode === (offerSearchCodeResource as Resource[])[0].searchCode || childResources?.code === (offerSearchCodeResource as Resource[])[0].code
                        )
                    ) {
                        childReservedResources.push({ ...offerSearchCodeResource[0], startDate: mxtsStartDate, endDate: mxtsEndDate, status: "INITIAL", quantity: 1 } as any);
                    }
                    if (
                        voucherCodeResource &&
                        (!childReservedResources.find((childResources) => childResources?.searchCode === (voucherCodeResource as Resource)?.searchCode) ||
                            !childReservedResources.find((childResources) => childResources?.code === (voucherCodeResource as Resource)?.code))
                    ) {
                        childReservedResources.push({ ...voucherCodeResource, startDate: mxtsStartDate, endDate: mxtsEndDate, status: "INITIAL", quantity: 1 } as any);
                    }
                }

                if (lastUsedVoucherCodeResource) {
                    childReservedResources = childReservedResources.filter((children) => lastUsedVoucherCodeResource?.code !== children.code);
                }

                if (excludedCode) {
                    childReservedResources = childReservedResources.filter((children) => excludedCode !== children.code);
                }

                for (const selectedAddOn of selectedAddOns) {
                    const currentAddOnRequestBodies = await getSinglePriceEngineAddOnRequestBodies({ rateTypeId, selectedAddOn });
                    childReservedResources = childReservedResources.filter((child) => child.resourceId !== selectedAddOn.resourceId).concat(currentAddOnRequestBodies);
                }

                refreshBillRequest.reservedResources[0].children = childReservedResources;
                refreshBillRequest.customerBill = refreshBillRequest.customerBill.filter((bill) => bill.state !== PriceEngineState.DELETED);
                refreshBillRequest.agentBill = refreshBillRequest.agentBill?.filter((bill) => bill.state !== PriceEngineState.DELETED);

                return params.apiContext.mxtsApi.refreshBill(env, refreshBillRequest);
            } else if (selectedAdditions.length) {
                const childReservedResources: ChoiceReservedResource[] = [];
                const selectedAddOnsIds = selectedAdditions.map((addOn) => addOn.resourceId);
                const selectedOfferIds = (offerSearchCodeResource as Resource[]).map((offer) => offer.resourceId) || [];
                (await AdditionsUtil.getPriceEngineAdditionRequestBodies(params.apiContext, selectedAdditions, rateTypeId)).forEach((additionRequestBody: ChoiceReservedResource) =>
                    childReservedResources.push({ ...additionRequestBody, startDate: mxtsStartDate, endDate: mxtsEndDate })
                );
                if (isBaseChoiceExcluded) {
                    if (offerSearchCodeResource.length) {
                        childReservedResources.push({ ...offerSearchCodeResource[0], startDate: mxtsStartDate, endDate: mxtsEndDate, status: "INITIAL", quantity: 1 } as any);
                    }
                    if (voucherCodeResource) {
                        childReservedResources.push({ ...voucherCodeResource, startDate: mxtsStartDate, endDate: mxtsEndDate, status: "INITIAL", quantity: 1 } as any);
                    }
                }

                const updateRefreshBillRequest = { ...choiceRequest, ...BillUtil.convertChoiceResultToRefreshRequest(billChoice), tasks: [PriceTask.RECALCULATE] };
                const reservedResourcesWithAddOn = updateRefreshBillRequest.reservedResources.map((item, index) => {
                    if (index === 0 && item.children) {
                        let updatedChildren = item.children.filter((item) => ![...selectedAddOnsIds, ...selectedOfferIds].includes(item.resourceId));

                        if (lastUsedVoucherCodeResource) {
                            updatedChildren = updatedChildren.filter((children) => lastUsedVoucherCodeResource?.code !== children.code);
                        }

                        if (excludedCode) {
                            updatedChildren = updatedChildren.filter((children) => excludedCode !== children.code);
                        }
                        return { ...item, children: [...updatedChildren, ...childReservedResources] };
                    }
                    return item;
                });

                const customerBill = updateRefreshBillRequest.customerBill.filter((bill) => bill.state !== PriceEngineState.DELETED);
                const agentBill = updateRefreshBillRequest.agentBill?.filter((bill) => bill.state !== PriceEngineState.DELETED);
                updateAddOnInBillRequest = { ...updateRefreshBillRequest, reservedResources: reservedResourcesWithAddOn, customerBill, agentBill };

                return params.apiContext.mxtsApi.refreshBill(env, updateAddOnInBillRequest as RefreshBillRequest);
            }
            /* jscpd:ignore-end */
        }
    } catch (error) {
        reportError(error, undefined, `Bill generation for add-ons failed! Choice request: ${JSON.stringify(updateAddOnInBillRequest ? updateAddOnInBillRequest : choiceRequest)}`);
        throw error;
    }
    return {
        choices: [],
    };
}

export class BillUtil {
    // eslint-disable-next-line max-lines-per-function
    public static async generateBillByDynamicFilter(params: {
        instalmentsState: InstalmentsState;
        dynamicFilter: DynamicFilter;
        additionState: AdditionState;
        selectedAddOns: SelectedAddOn[];
        billChoice: BillChoice;
        options: GenerateBillOptions;
        env: ApiCallOptions;
        updatedAddOn?: SelectedAddOn;
        apiContext: ApiContext;
        tasks?: string[];
    }): Promise<ChoiceResult> {
        const {
            instalmentsState,
            dynamicFilter,
            additionState,
            billChoice,
            options: { accommodationType, unit, applicableRateTypeId, removeSpecialCode, specialSearchCode },
            env,
            selectedAddOns,
            updatedAddOn,
        } = params;

        const tasks = params.tasks ? [...params.tasks, PriceTask.GENERATE_BILL] : dynamicFilter?.selectedChoice ? [dynamicFilter?.selectedChoice, PriceTask.GENERATE_BILL] : undefined;

        let choiceRequest: ChoiceRequest | null = null;
        const refreshBillRequest: RefreshBillRequest | null = null;
        try {
            let { startDate, endDate, subjects } = getStartEndDateSubjectsForPrices(dynamicFilter);

            if (accommodationType) {
                /**
                 * Note:- When start & end date is not selected, bill will generate with this start & end date.
                 * it's a temp start & end date for generating bill in the CRP, we are not setting this dates in dynamic filter.
                 */
                const accoArrivalDate = moment(accommodationType?.arrivalDate, DATE_FORMAT.ELASTIC).format(DATE_FORMAT.DEFAULT);
                const accoDepartureDate = moment(accommodationType?.departureDate, DATE_FORMAT.ELASTIC).format(DATE_FORMAT.DEFAULT);
                if (startDate !== accoArrivalDate && endDate !== accoDepartureDate) {
                    startDate = moment(accoArrivalDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC);
                    endDate = moment(accoDepartureDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC);
                }
            }

            if (unit) {
                const unitArrivalDate = unit?.date || unit?.arrivalDate || "";
                const unitDepartureDate = unit?.departureDate || moment(unitArrivalDate, DATE_FORMAT.ELASTIC).add(unit?.duration?.[0], "days").format(DATE_FORMAT.MXTS);
                if (startDate !== unitArrivalDate && endDate !== unitDepartureDate) {
                    startDate = unitArrivalDate;
                    endDate = unitDepartureDate;
                }
            }

            if (!startDate || !endDate) {
                throw new Error("No startDate or endDate present in the DynamicFilter");
            }
            const resourceId: number | undefined = accommodationType?.resourceId || unit?.resourceId || dynamicFilter.resourceid;
            const rateTypeId: number | undefined = applicableRateTypeId || dynamicFilter.rateType?.rateTypeId || billChoice?.reservedResources?.[0]?.rateTypeId;

            const accoTypeHasSpecialCodes = !!accommodationType?.specialCode?.length;
            const unitHasSpecialCodes = !!unit?.specialCode?.length;
            const filterHasSpecialCodes = !!dynamicFilter?.specialcode?.length;
            const shouldUseSpecialCodeInRequest: boolean = !removeSpecialCode && (!!specialSearchCode || accoTypeHasSpecialCodes || unitHasSpecialCodes || filterHasSpecialCodes);
            let offerSearchCode: string | undefined = shouldUseSpecialCodeInRequest
                ? specialSearchCode || accommodationType?.specialCode[0] || unit?.specialCode?.[0] || dynamicFilter.specialcode?.[0]
                : undefined;
            const voucherCode: string | undefined = dynamicFilter.voucherCodes?.[0];
            if (offerSearchCode === "no-special") {
                offerSearchCode = undefined;
            }
            choiceRequest = {
                reservationId: dynamicFilter.reservationId,
                startDate,
                endDate,
                distributionChannelId: dynamicFilter.ownerShareableLink?.distributionChannelId || dynamicFilter.distributionChannel!.distributionChannelId,
                reservationCategoryId: dynamicFilter.ownerShareableLink?.reservationCategoryId || +dynamicFilter.reservationCategoryId!,
                reservedResources: [],
                // Always get the cheapest choice, also if a special code is provided
                tasks: tasks ? tasks : [PriceTask.CHEAPEST_CHOICE, PriceTask.GENERATE_BILL],
                offerSearchCode,
            };

            if (dynamicFilter.reservationId) {
                return await params.apiContext.mxtsApi.refreshReservationBill(env, { task: PriceTask.RECALCULATE }, [
                    {
                        key: "reservationId",
                        value: dynamicFilter.reservationId,
                    },
                ]);
            }
            let excludedCode: string | undefined;
            if (!shouldUseSpecialCodeInRequest && specialSearchCode === dynamicFilter?.lastUsedVoucherCode) {
                excludedCode = specialSearchCode;
            }

            // When we already generated the bill then we can update small changes to it using the /refresh endpoint.
            // Here we are updating bill lines using /refresh endpoint whenever there is any update in an addOn.
            const selectedAdditions: Array<SelectedAddition & { resourceId: number }> = AdditionsUtil.getSelectedAdditionsAsArray(additionState.selectedAdditions);
            if (billChoice && (selectedAdditions.length || selectedAddOns.length || updatedAddOn)) {
                return generateBillIncludingAddOns({
                    additionState,
                    rateTypeId,
                    distributionChannelId: choiceRequest.distributionChannelId!,
                    reservationCategoryId: choiceRequest.reservationCategoryId,
                    billChoice,
                    startDate,
                    endDate,
                    env,
                    reservationId: dynamicFilter.reservationId,
                    selectedAddOns,
                    updatedAddOn,
                    offerSearchCode,
                    voucherCode,
                    resourceId: resourceId!,
                    apiContext: params.apiContext,
                    tasks,
                    lastUsedVoucherCode: dynamicFilter?.lastUsedVoucherCode,
                    excludedCode,
                });
            }

            choiceRequest.reservedResources = [
                new ChoiceReservedResource({
                    task: "CALCULATE",
                    // Finding the rate type in current site context, or from Unit/typesearch container, or from redux store
                    rateTypeId,
                    quantity: 1,
                    resourceId,
                    type: ChoiceResourceType.ACCOMMODATIONTYPE,
                    initialResourceId: resourceId,
                    status: "INITIAL",
                    included: false,
                    addToParent: false,
                    unitId: unit?.unitId || dynamicFilter.unitid,
                    children: await BillUtil.getRequestChildReservedResources({
                        dynamicFilter,
                        additionState,
                        instalmentsState,
                        resourceId: resourceId!,
                        env,
                        rateTypeId,
                        billChoice,
                        selectedAddOns,
                        apiContext: params.apiContext,
                    }),
                    subjects,
                }),
            ];
            return params.apiContext.mxtsApi.choices(env, choiceRequest as ChoiceRequest);
        } catch (error) {
            reportError(error, undefined, `Bill generation failed! Choice request: ${JSON.stringify(refreshBillRequest ? refreshBillRequest : choiceRequest)}`);
            throw error;
        }
    }

    public static getPayLaterResource(reservedResources: ReservedResource[]): ChoiceReservedResource | undefined {
        const paymentTermSetCostsResourceIds = reservedResources
            .filter((reservedResource: ReservedResource) => reservedResource.paymentTermSetCosts === true)
            .map((res: ReservedResource) => res.resourceId);
        if (paymentTermSetCostsResourceIds.length) {
            return new ChoiceReservedResource({
                quantity: 1,
                resourceId: paymentTermSetCostsResourceIds[0] || undefined,
                type: ChoiceResourceType.EXTRA,
                status: "INITIAL",
            });
        }
        return undefined;
    }

    // eslint-disable-next-line max-lines-per-function
    public static async getRequestChildReservedResources({
        dynamicFilter,
        instalmentsState,
        additionState,
        selectedAddOns,
        resourceId,
        env,
        rateTypeId,
        billChoice,
        apiContext,
    }: {
        dynamicFilter: DynamicFilter;
        instalmentsState: InstalmentsState;
        selectedAddOns?: SelectedAddOn[];
        additionState?: AdditionState;
        resourceId: number;
        env: ApiCallOptions;
        rateTypeId?: number;
        billChoice?: BillChoice;
        apiContext: ApiContext;
    }): Promise<ChoiceReservedResource[]> {
        const childReservedResources: ChoiceReservedResource[] = [];

        // Vouchers
        if (dynamicFilter.voucherCodes?.length) {
            const vouchersResult: PagedResult<VoucherDetailsResponse> = await apiContext.mxtsApi.searchVouchers(env, { code: dynamicFilter.voucherCodes });
            await Promise.all(
                vouchersResult?.content?.map(async (voucherResult: VoucherDetailsResponse) => {
                    // Compensation and Cash vouchers
                    if (voucherResult.type === VoucherType.CASH || voucherResult.type === VoucherType.COMPENSATION) {
                        let voucherSetResource: Resource | undefined;
                        if (!voucherResult.voucherSetCashResourceId) {
                            voucherSetResource = await getVoucherSetResource(voucherResult, await BillUtil.getResortId(apiContext.mxtsApi, billChoice, resourceId, env), env, apiContext.mxtsApi);
                        }
                        childReservedResources.push(
                            new ChoiceReservedResource({
                                quantity: 1,
                                resourceId: voucherSetResource?.resourceId || voucherResult.voucherSetCashResourceId,
                                type: (voucherSetResource?.type && asPriceEngineResourceType(voucherSetResource.type)) || ChoiceResourceType.EXTRA,
                                status: "INITIAL",
                                ...(voucherResult.type === VoucherType.COMPENSATION ? BillUtil.getCompensationVoucherProps(voucherResult, billChoice) : {}),
                            })
                        );
                        // Discount voucher
                    } else if (voucherResult.type === VoucherType.DISCOUNT) {
                        const discountResource: Resource | undefined = await getVoucherSetResource(
                            voucherResult,
                            await BillUtil.getResortId(apiContext.mxtsApi, billChoice, resourceId, env),
                            env,
                            apiContext.mxtsApi
                        );
                        if (discountResource) {
                            childReservedResources.push(
                                new ChoiceReservedResource({
                                    quantity: 1,
                                    resourceId: discountResource.resourceId,
                                    type: asPriceEngineResourceType(discountResource.type),
                                    status: "INITIAL",
                                })
                            );
                        }
                    }
                })
            );
        }

        // Unit preference
        if (dynamicFilter?.unitid && dynamicFilter?.unitPreference) {
            const resource: Resource | null = await DomainObjectUtil.getResourceById(apiContext.mxtsApi, resourceId, env);
            if (resource?.accotypeObjectPrefId) {
                childReservedResources.push(
                    new ChoiceReservedResource({
                        quantity: 1,
                        resourceId: resource.accotypeObjectPrefId,
                        type: ChoiceResourceType.EXTRA,
                        status: "INITIAL",
                    })
                );
            }
        }

        // Pay later preference
        if (instalmentsState.payLater && dynamicFilter.reservationId) {
            const reservedResources: ReservedResource[] = (await apiContext.mxtsApi.getReservedResource(env, { size: 1000 }, [{ key: "reservationId", value: dynamicFilter.reservationId }])).content;
            const payLaterResource = BillUtil.getPayLaterResource(reservedResources);
            if (payLaterResource) {
                childReservedResources.push(payLaterResource);
            }
        }

        // Selected Additions - TODO: Remove when new add-ons widget is rolled out for all clients
        if (additionState) {
            const selectedAdditions: Array<SelectedAddition & { resourceId: number }> = AdditionsUtil.getSelectedAdditionsAsArray(additionState.selectedAdditions);
            if (selectedAdditions.length) {
                (await AdditionsUtil.getPriceEngineAdditionRequestBodies(apiContext, selectedAdditions, rateTypeId)).forEach((additionRequestBody) => childReservedResources.push(additionRequestBody));
            }
        }

        // Selected Add-ons
        if (selectedAddOns?.length) {
            (await getAllPriceEngineAddOnRequestBodies({ selectedAddOns, rateTypeId })).forEach((addOnRequestBody) => childReservedResources.push(addOnRequestBody));
        }

        return childReservedResources;
    }

    private static getCompensationVoucherProps(
        voucherResult: VoucherDetailsResponse,
        billChoice?: BillChoice
    ): {
        hasPriceOverride: true;
        price: number;
    } {
        let compensationAmount = 0;
        const totalBillLine: BillLine | undefined = billChoice
            ? getTotalBillLines(billChoice.customerBill || billChoice.agentBill).find((billLine: BillLine) => billLine.billLineType === "TOTAL")
            : undefined;
        if (totalBillLine && voucherResult.voucherValue) {
            compensationAmount = voucherResult.voucherValue > totalBillLine.total ? totalBillLine.total : voucherResult.voucherValue;
        }
        return {
            hasPriceOverride: true,
            price: -compensationAmount,
        };
    }

    public static convertChoiceResultToRefreshRequest(choice: BillChoice): Partial<RefreshBillRequest> & { customerBill: BillLine[] } {
        const refreshBillRequest: Partial<RefreshBillRequest> & { customerBill: BillLine[] } = { customerBill: [] };
        function cleanupReservedResource(reservedResource: ChoiceReservedResource): ChoiceReservedResource {
            delete reservedResource.billLines;
            // TODO add these to the interface
            delete (reservedResource as any).usedCashflowrules;
            delete (reservedResource as any).usedInternalCashflowrules;
            delete (reservedResource as any).reservationPriceRawList;
            delete (reservedResource as any).reservationPriceRefList;
            if (reservedResource.children?.length) {
                reservedResource.children = reservedResource.children.map((child: ChoiceReservedResource) => cleanupReservedResource(child));
            }
            return reservedResource;
        }
        refreshBillRequest.reservedResources = choice.reservedResources.map((reservedResource: ChoiceReservedResource) => cleanupReservedResource(reservedResource));
        function cleanupBillLines(billLines?: BillLine[]): BillLine[] | undefined {
            return billLines?.map((billLine: BillLine) => {
                delete billLine.reservedResource;
                return billLine;
            });
        }
        refreshBillRequest.customerBill = cleanupBillLines(choice?.customerBill) || [];
        refreshBillRequest.payingCustomerBill = cleanupBillLines(choice?.payingCustomerBill);
        refreshBillRequest.agentBill = cleanupBillLines(choice?.agentBill);
        return refreshBillRequest;
    }

    private static async getResortId(mxtsApi: MxtsApiWrapper, billChoice: BillChoice | undefined, resourceId: number, env: ApiCallOptions) {
        let resortId: number | undefined;
        if (billChoice?.reservedResources?.length) {
            const flattenedReservedResources: ChoiceReservedResource[] = flatten(billChoice?.reservedResources, (parent) => parent.children);
            const reservedResource = flattenedReservedResources.find((res: ChoiceReservedResource) => res.resourceId === resourceId);
            resortId = reservedResource?.resource?.resortId;
        }
        if (!resortId) {
            const resource = await DomainObjectUtil.getResourceById(mxtsApi, resourceId, env);
            resortId = resource?.resortId;
        }
        return resortId;
    }

    public static async getVoucherCodeResource({
        apiContext,
        env,
        resourceId,
        billChoice,
        voucherCode,
    }: {
        apiContext: ApiContext;
        env: ApiCallOptions;
        resourceId: number;
        billChoice?: BillChoice;
        voucherCode?: string;
    }): Promise<Resource | undefined> {
        let voucherSetResource: Resource | undefined;
        if (voucherCode?.length) {
            const vouchersResult: PagedResult<VoucherDetailsResponse> = await apiContext.mxtsApi.searchVouchers(env, { code: voucherCode });
            await Promise.all(
                vouchersResult?.content?.map(async (voucherResult: VoucherDetailsResponse) => {
                    voucherSetResource = await getVoucherSetResource(voucherResult, await BillUtil.getResortId(apiContext.mxtsApi, billChoice, resourceId, env), env, apiContext.mxtsApi);
                })
            );
        }
        return voucherSetResource;
    }
}

export const asPriceEngineResourceType = (resourceType: ResourceType): ChoiceResourceType => {
    switch (resourceType) {
        case ResourceType.ACCOMMODATIONTYPE:
            return ChoiceResourceType.ACCOMMODATIONTYPE;
        case ResourceType.ACTIVITY:
            return ChoiceResourceType.ACTIVITY;
        case ResourceType.COMPOSITION:
            return ChoiceResourceType.COMPOSITION;
        case ResourceType.EXTRA:
            return ChoiceResourceType.EXTRA;
        case ResourceType.PRODUCTTYPE:
            return ChoiceResourceType.PRODUCTTYPE;
        case ResourceType.RESOURCEACTIVITY:
            return ChoiceResourceType.RESOURCEACTIVITY;
        case ResourceType.SPECIAL:
            return ChoiceResourceType.SPECIAL;
    }
};

const TOTAL_BILL_LINE_TYPES = ["SUBTOTAL", "TOTAL", "WARRANT", "VAT", "VAT_EXCL_LINE", "TOTAL_EXCL_VAT", "RESORT_ARTICLES_TOTAL"];

export function getTotalBillLines(billLines: BillLine[]): BillLine[] {
    return billLines.filter((billLine: BillLine) => TOTAL_BILL_LINE_TYPES.includes(billLine.billLineType));
}

export function getNormalBillLines(billLines: BillLine[]): BillLine[] {
    return billLines.filter((billLine: BillLine) => !TOTAL_BILL_LINE_TYPES.includes(billLine.billLineType));
}

export function getAdditionBillLines(billLines: BillLine[], additionState: AdditionState): BillLine[] {
    const additionResourceIds: number[] = getSelectedAdditionIds(additionState);
    return billLines.filter((billLine: BillLine) => additionResourceIds.some((additionResourceId) => billLine.resourceId === additionResourceId));
}

export function getAddOnBillLines({ customerBill, selectedAddOns }: { customerBill: BillLine[]; selectedAddOns: SelectedAddOn[] }): BillLine[] {
    return customerBill.filter((billLine: BillLine) => selectedAddOns.some((addOn: SelectedAddOn) => billLine.resourceId === addOn.resourceId));
}

export function getAccoBillLines({ additionState, billLines, selectedAddOns }: { billLines: BillLine[]; additionState: AdditionState; selectedAddOns?: SelectedAddOn[] }): BillLine[] {
    const additionResourceIds: number[] = getSelectedAdditionIds(additionState);
    return (getNormalBillLines(billLines) || []).filter(
        (billLine: BillLine) =>
            !additionResourceIds.some((additionResourceId) => billLine.resourceId === additionResourceId) && !selectedAddOns?.some((addOn: SelectedAddOn) => billLine.resourceId === addOn.resourceId)
    );
}

export function getActivityBillLines({ customerBill }: { customerBill: BillLine[] }): BillLine[] {
    return customerBill.filter((billLine: BillLine) => [ChoiceResourceType.RESOURCEACTIVITY, "resourceactivity"].includes(billLine.resourceType));
}

function getSelectedAdditionIds(additionState: AdditionState): number[] {
    return AdditionsUtil.getSelectedAdditionsAsArray(additionState.selectedAdditions).map((selectedAddition: SelectedAddition & { resourceId: number }) => selectedAddition.resourceId);
}

export function convertChoiceToBillChoice(choice: Choice, choiceResult: ChoiceResult): BillChoice {
    return {
        reservedResources: choice.reservedResources || [],
        customerBill: choice.customerBill || [],
        payingCustomerBill: choice.payingCustomerBill || [],
        agentBill: choice.agentBill || [],
        billBalance: choice.billBalance || [],
        preBooking: choiceResult.preBooking,
    };
}

export function getEmptyBillChoice(): BillChoice {
    return { customerBill: [], agentBill: [], payingCustomerBill: [], billBalance: [], reservedResources: [] };
}

export function getBillChoice(billState: BillState, targetBill?: BillType): BillChoice {
    return (targetBill ? billState[targetBill] : billState.mainBill) || getEmptyBillChoice();
}

async function getOfferSearchCodeResource(apiContext: ApiContext, env: ApiCallOptions, offerSearchCode?: string) {
    let offerSearchCodeResource: Resource[] | VoucherDetailsResponse[] = [];
    if (offerSearchCode) {
        // TODO: As of now this endpoint didn't return results, will need to test it's senarios and work on it for actual voucher code.
        const voucherResult = await apiContext.mxtsApi.searchVouchers(env, { code: offerSearchCode });
        offerSearchCodeResource = voucherResult.content;
        if (!offerSearchCodeResource.length) {
            offerSearchCodeResource = await fetchSpecialsByCodes({ env, specialCodes: [offerSearchCode] }, apiContext.mxtsApi);
        }
    }
    return offerSearchCodeResource;
}
// eslint-disable-next-line max-lines-per-function
export async function fetchBillForActivityPlanner(dynamicFilter: DynamicFilter, env: ApiCallOptions, context: CMSProviderProperties, ticketCount?: number): Promise<Choice | undefined> {
    const { selectedActivities, showMainActivityOnPage } = dynamicFilter;
    const resourceActivityDetailsIds =
        selectedActivities
            ?.filter((selectedActivity) => {
                if (selectedActivity.status !== ActivityStatus.CANCELLED) {
                    if (dynamicFilter.resourceActivityDetailsId) {
                        return selectedActivity.resourceActivityDetailsId === Number(dynamicFilter.resourceActivityDetailsId);
                    } else if (dynamicFilter.resourceActivityDetailsIds?.length) {
                        return dynamicFilter.resourceActivityDetailsIds?.includes(selectedActivity.resourceActivityDetailsId);
                    }
                }
                return false;
            })
            .map((selectedActivity) => Number(selectedActivity.resourceActivityDetailsId)) || [];
    const reservedResources: ChoiceReservedResource[] = [];

    if (resourceActivityDetailsIds.length && dynamicFilter.reservationCategoryId) {
        const queryParamsArgs = {
            mxtsApi: context.mxtsApi,
            env,
            dynamicFilter,
            startDate:
                (dynamicFilter.startdate && moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) ||
                (selectedActivities?.[0].startTime && moment(selectedActivities?.[0].startTime, DATE_FORMAT.MXTS_DATETIME_UTC).format(DATE_FORMAT.MXTS)) ||
                "",
            endDate:
                (dynamicFilter.enddate && moment(dynamicFilter.enddate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) ||
                (selectedActivities?.[0].endTime && moment(selectedActivities?.[0].endTime, DATE_FORMAT.MXTS_DATETIME_UTC).format(DATE_FORMAT.MXTS)) ||
                "",
            resourceActivityDetailsIds,
        };
        const subjects: SubjectQuantity[] = [];
        let quantity = 1;
        const isSubjectBasedActivity = dynamicFilter.selectedActivities?.some(
            (selectedActivity) => selectedActivity?.resourceActivity?.resortActivity?.ticketingType === TicketingTypes.TICKET_PER_SUBJECT
        );
        const isTicketBasedActivity = dynamicFilter.selectedActivities?.some(
            (selectedActivity) =>
                selectedActivity?.resourceActivity?.resortActivity?.ticketingType === TicketingTypes.TICKET_PER_GROUP ||
                selectedActivity?.resourceActivity?.resortActivity?.ticketingType === TicketingTypes.TICKETS
        );
        const activitySections = await getActivitySections(context, env, selectedActivities?.[0]);
        const numberOfSections = new Set(activitySections.map((section) => section.resourceId)).size;
        if (dynamicFilter.subject) {
            dynamicFilter.subject.forEach((value, key) => subjects.push({ subjectId: key, quantity: value }));
            const totalSubjectCount = subjects.reduce((totalCount, subject) => totalCount + subject.quantity, 0);
            if (totalSubjectCount === 0) {
                quantity = 0;
            }
        } else if (!dynamicFilter.subject && isSubjectBasedActivity) {
            quantity = 0;
        }
        if (numberOfSections < 2) {
            if (isTicketBasedActivity && !ticketCount && !dynamicFilter.activityTicketQuantity) {
                quantity = 0;
            } else if (ticketCount) {
                quantity = ticketCount;
            } else if (dynamicFilter.activityTicketQuantity !== undefined) {
                quantity = dynamicFilter.activityTicketQuantity;
            }
        }
        const detailParams = await getActivitiesDetailsQueryParams(queryParamsArgs);
        const resourceActivitiesDetails: ResourceActivitiesDetailsResponse[] = await context.mxtsApi.getResourceActivitiesDetails(env, detailParams).then((result) => result.content);
        if (resourceActivitiesDetails.length) {
            resourceActivitiesDetails.forEach((resourceActivitiesDetail) => {
                if (showMainActivityOnPage) {
                    // This conditional block will assign the selected quantity from the ticket counter widget to the bills. This applies specifically when the bowling section table is utilized.
                    const selectedActivityTicketQuantity = (selectedActivities as SelectedActivity[])?.find(
                        (selectedActivity) => selectedActivity.resourceActivityDetailsId === resourceActivitiesDetail.resourceActivityDetailsId
                    )?.selectedActivityTicketQuantity;
                    if (selectedActivityTicketQuantity) {
                        quantity = selectedActivityTicketQuantity;
                    }
                }
                reservedResources.push(
                    new ChoiceReservedResource({
                        resourceActivityDetailsId: resourceActivitiesDetail.resourceActivityDetailsId,
                        resourceId: resourceActivitiesDetail.resourceId,
                        name: resourceActivitiesDetail.resourceActivity.name!,
                        type: ChoiceResourceType.RESOURCEACTIVITY,
                        status: "INITIAL",
                        subjects,
                        quantity,
                    })
                );
            });
        }
        const refreshBillRequest: RefreshBillRequest = {
            kind: ReservationKind.ADDON_RESERVATION,
            reservedResources,
            tasks: [PriceTask.RECALCULATE],
            reservationCategoryId: dynamicFilter.reservationCategoryId,
            customerBill: [],
            startDate: queryParamsArgs.startDate,
            endDate: queryParamsArgs.endDate,
            distributionChannelId: dynamicFilter.distributionChannel?.distributionChannelId,
        };
        const bill = await context.mxtsApi.refreshBill(env, refreshBillRequest).then((result) => result.choices![0]);
        return bill;
    }
}

export const updateReservedResourceSubjectQuantities = (reservedResource: BillReservedResource, activityPlannerState: ActivityPlannerState) => {
    const matchingSubjectQuantities = activityPlannerState.reservedResourceSubjectQuantities?.filter(
        (subjectQuantity) => Number(subjectQuantity.resourceActivityDetailsId) === Number(reservedResource.resourceActivityDetailsId)
    );
    if (matchingSubjectQuantities) {
        const reservedResourceSubjectQuantities: SubjectQuantity[] = matchingSubjectQuantities.map((subjectQuantity) => ({ subjectId: subjectQuantity.subjectId, quantity: subjectQuantity.quantity }));
        reservedResource.reservedResourceSubjectQuantities = reservedResourceSubjectQuantities;
    }
};

export const sortBillLinesForMultipleAccos = (billLines: BillLine[]): BillLine[] => {
    const accommodationTypes: BillLine[] = [];
    const otherBillLines: BillLine[] = [];

    billLines.forEach((line) => {
        if (line.billLineType === BillLineType.RESERVED_RESOURCE && line.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE) {
            const accommodationTypeBillLine = cloneDeep(line);
            // In case of accommodation being split for different reservation rules, combine those accommodation bill lines
            const existingAccoTypeIndex = accommodationTypes.findIndex(
                (accoType) => accoType.reservedResourceId === accommodationTypeBillLine.reservedResourceId && accoType.reservedResource?.unitId === accommodationTypeBillLine.reservedResource?.unitId
            );
            if (existingAccoTypeIndex !== -1) {
                accommodationTypes[existingAccoTypeIndex].total += accommodationTypeBillLine.total;
                if (accommodationTypes[existingAccoTypeIndex].value && accommodationTypeBillLine.value) {
                    accommodationTypes[existingAccoTypeIndex].value! += accommodationTypeBillLine.value;
                }
                if (accommodationTypes[existingAccoTypeIndex]?.virtualOverrideValue && accommodationTypeBillLine.virtualOverrideValue) {
                    accommodationTypes[existingAccoTypeIndex].virtualOverrideValue! += accommodationTypeBillLine.virtualOverrideValue;
                }
            } else {
                accommodationTypes.push(accommodationTypeBillLine);
            }
        } else {
            otherBillLines.push(line);
        }
    });

    // Merge the sorted accommodation types and other bill lines
    const sortedBillLines: BillLine[] = [];
    accommodationTypes.forEach((accommodation) => {
        sortedBillLines.push(accommodation);
        const correspondingOtherBillLines = otherBillLines.filter((line) => line.parentId === accommodation.reservedResourceId);
        sortedBillLines.push(...correspondingOtherBillLines);
    });

    const otherBillLinesWithoutParentId = otherBillLines.filter((line) => !line.parentId);
    sortedBillLines.push(...otherBillLinesWithoutParentId);

    return sortedBillLines;
};

export const evaluateBillLineTypeCondition = (line: BillLine, type: BillLineType): boolean => {
    switch (type) {
        case BillLineType.RESORT_ARTICLE:
            return line.billLineType === BillLineType.RESORT_ARTICLE && line.resourceType.toUpperCase() === ChoiceResourceType.EXTRA;
        case BillLineType.TAX:
            return line.billLineType === BillLineType.TAX && line.resourceType.toUpperCase() === ChoiceResourceType.EXTRA;
        case BillLineType.VAT:
            return (line.billLineType === BillLineType.RESERVED_RESOURCE || line.billLineType === BillLineType.TAX) && line.resourceType.toUpperCase() === ChoiceResourceType.EXTRA;
        // Add more cases if needed
        default:
            return false;
    }
};

/**
 * Merges bill lines of the specified type by grouping them based on their resource name.
 *
 * This function is designed to handle different types of bill lines, such as RESORT_ARTICLE and TAX.
 * It consolidates bill lines with the same resource name and type, summing up their totals and
 * virtual override values.
 *
 * @param {BillLine[]} billLines - An array of bill lines to be merged.
 * @param {string} type - The type of bill lines to merge (e.g., "RESORT_ARTICLE", "TAX").
 * @returns {BillLine[]} - A new array of bill lines, including the merged lines and any other lines
 *                         that didn't match the specified type.
 *
 * Usage:
 * - Use this function when you need to aggregate bill lines of a certain type (e.g., all tax lines
 *   or all resort article lines).
 * - The function works by grouping lines with the same resource name and summing up their totals
 *   and override values.
 * - If no lines match the specified type, the original array is returned unchanged.
 */

export const mergeBillLinesByType = (billLines: BillLine[], type: BillLineType): BillLine[] => {
    const groupedResortBillLines: Record<string, BillLine> = billLines.reduce((acc: Record<string, BillLine>, line: BillLine) => {
        const billTypeCondition = evaluateBillLineTypeCondition(line, type);

        if (billTypeCondition) {
            const key = line.resourceName;
            if (acc[key]) {
                acc[key].total += line.total;
                acc[key].virtualOverrideValue! += line.virtualOverrideValue ?? line.total;
            } else {
                acc[key] = { ...line, virtualOverrideValue: line.virtualOverrideValue ?? line.total };
            }
        }
        return acc;
    }, {});

    // If no matching bill lines, return the original array
    if (Object.keys(groupedResortBillLines).length === 0) {
        return billLines;
    }

    const mergedBillLines = Object.values(groupedResortBillLines);

    // Return a new array with the merged lines and other non-matching lines
    return [...billLines.filter((billLine) => !groupedResortBillLines[billLine.resourceName]), ...mergedBillLines];
};
