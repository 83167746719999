import { BillAction, BillActionType } from "../actions/billAction";
import { BillBalance, BillLine, ChoiceReservedResource } from "@maxxton/cms-mxts-api";

import { Reducer } from "redux";
import { getEmptyBillChoice } from "../../utils/bill.util";

export interface BillState {
    mainBill?: BillChoice;
    myEnvAdditionsBill?: BillChoice;
}

export type BillType = keyof BillState;

export interface BillChoice {
    reservedResources: ChoiceReservedResource[];
    customerBill: BillLine[];
    agentBill: BillLine[];
    payingCustomerBill: BillLine[];
    billBalance: BillBalance[];
    preBooking?: boolean;
    fetching?: boolean;
    error?: Error;
}

export const billReducer: Reducer<BillState> = (state: BillState = {}, action: BillAction): BillState => {
    const targetBillKey: BillType = action.targetBill || "mainBill";

    switch (action.actionType) {
        case BillActionType.fetching:
            return getUpdatedBillState(state, targetBillKey, { fetching: true });
        case BillActionType.fetched:
            if (action.payload) {
                return getUpdatedBillState(state, targetBillKey, {
                    reservedResources: action.payload?.reservedResources || [],
                    customerBill: action.payload?.customerBill || [],
                    payingCustomerBill: action.payload?.payingCustomerBill || [],
                    agentBill: action.payload?.agentBill || [],
                    billBalance: action.payload?.billBalance || [],
                    preBooking: action.payload?.preBooking || false,
                    fetching: false,
                });
            }
            return state;
        case BillActionType.error:
            return getUpdatedBillState({}, targetBillKey, { error: action.payload?.error });
        case BillActionType.remove:
            return getStateWithoutTargetBillChoice(state, targetBillKey);
        case BillActionType.specialCodeNotFound:
            return getUpdatedBillState(state, targetBillKey, { fetching: false });
        default:
            return state;
    }
};

function getUpdatedBillState(existingState: BillState, targetBillKey: BillType, newBillChoice: Partial<BillChoice>): BillState {
    const existingBillChoice: BillChoice | undefined = existingState[targetBillKey];
    return { ...existingState, ...{ [targetBillKey]: { ...getEmptyBillChoice(), ...existingBillChoice, ...newBillChoice } } };
}

function getStateWithoutTargetBillChoice(existingState: BillState, targetBillKey: BillType): BillState {
    const stateClone = { ...existingState };
    delete stateClone[targetBillKey];
    return stateClone;
}
