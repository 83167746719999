import { Action, ActionType } from "./";
import { ResultLayoutDisplayType, UserInterfaceState } from "../reducers/userInterfaceReducer";

export enum UserInterfaceActionType {
    UPDATE_CRITERIA_COUNT = "UPDATE_CRITERIA_COUNT",
    UPDATE_RESULT_LAYOUT_DISPLAY_TYPE = "UPDATE_RESULT_LAYOUT_DISPLAY_TYPE",
    UPDATE_MAX_RATING = "UPDATE_MAX_RATING",
    TOGGLE_SPECIAL_MODAL = "TOGGLE_SPECIAL_MODAL",
}
export interface UserInterfaceAction extends Action {
    actionType: UserInterfaceActionType;
    payload: UserInterfaceState;
}

export const updateCriteriaCount = (selectedCriteriaCount?: number): UserInterfaceAction => ({
    type: ActionType.UserInterface,
    actionType: UserInterfaceActionType.UPDATE_CRITERIA_COUNT,
    payload: {
        criteriaPanel: {
            selectedCriteriaCount,
        },
    },
});

export const updateResultLayoutDisplayType = (resultLayoutDisplayType?: ResultLayoutDisplayType): UserInterfaceAction => ({
    type: ActionType.UserInterface,
    actionType: UserInterfaceActionType.UPDATE_RESULT_LAYOUT_DISPLAY_TYPE,
    payload: {
        resultLayoutViewOptions: {
            resultLayoutDisplayType,
        },
    },
});

export const updateMaxRating = (maxRating?: number): UserInterfaceAction => ({
    type: ActionType.UserInterface,
    actionType: UserInterfaceActionType.UPDATE_MAX_RATING,
    payload: {
        reviewRatingsWidget: {
            maxRating,
        },
    },
});
export const toggleSpecialModal = (isSpecialModalOpen?: boolean): UserInterfaceAction => ({
    type: ActionType.UserInterface,
    actionType: UserInterfaceActionType.TOGGLE_SPECIAL_MODAL,
    payload: {
        specialSearchFacet: {
            isSpecialModalOpen,
        },
    },
});
