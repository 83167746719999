import { Addon, AddonsPriceRequest, AddonsPriceResult, ApiCallOptions, ChoiceReservedResource, SubjectQuantity as MxtsSubjectQuantity, ResourceType, SubjectQuantity } from "@maxxton/cms-mxts-api";
import { GenericAddition, SelectedAddition } from "./additions.types";
import { ProductDay, ProductSubjectSelection } from "./products/products.types";

import { ApiContext } from "../../../containers/cmsProvider.types";
import { DateUtil } from "../../../utils/date.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { StringUtil } from "../../../utils/string.util";
import { asPriceEngineResourceType } from "../../../utils/bill.util";
import { getStartEndDateSubjectsForPrices } from "../../../components/utils";

export class AdditionsUtil {
    public static getSelectedSimpleAdditions(selectedAdditions?: { [key: number]: SelectedAddition }): Array<SelectedAddition & { resourceId: number }> {
        return AdditionsUtil.getSelectedAdditionsAsArray(selectedAdditions).filter((selectedAddition) => !this.isSelectedAdditionDayProduct(selectedAddition));
    }

    public static isSelectedAdditionDayProduct(selectedAddition: SelectedAddition) {
        return selectedAddition.daysAndSubjectsConfig?.some((conf) => conf.days?.length);
    }

    public static getSelectedDayProducts(selectedAdditions?: { [key: number]: SelectedAddition }): Array<SelectedAddition & { resourceId: number }> {
        return AdditionsUtil.getSelectedAdditionsAsArray(selectedAdditions).filter((selectedAddition) => this.isSelectedAdditionDayProduct(selectedAddition));
    }

    public static getSelectedAdditionsAsArray(selectedAdditions?: { [key: number]: SelectedAddition }): Array<SelectedAddition & { resourceId: number }> {
        if (!selectedAdditions) {
            return [];
        }
        return Object.keys(selectedAdditions).map((resourceId: string) => ({ ...selectedAdditions[+resourceId], resourceId: +resourceId }));
    }

    public static isDayOrSubjectProduct(genericAddition: GenericAddition): boolean {
        return this.isDayProduct(genericAddition) || genericAddition.subjectsInternet;
    }

    public static isDayAndSubjectProduct(genericAddition: GenericAddition): boolean {
        return this.isDayProduct(genericAddition) && genericAddition.subjectsInternet;
    }

    public static isDayProduct(genericAddition: GenericAddition): boolean {
        return StringUtil.equalsIgnoreCase(genericAddition.resourceStock, "DAY");
    }

    public static isSubjectProduct(genericAddition: GenericAddition): boolean {
        return genericAddition.subjectsInternet;
    }

    public static isCompositionProduct(genericAddition: GenericAddition): boolean {
        return genericAddition.type === ResourceType.COMPOSITION;
    }

    public static getTotalSubjectsCount(selectedAddition: SelectedAddition): number {
        let totalSubjectsCount = 0;
        selectedAddition.daysAndSubjectsConfig?.forEach((config) => {
            totalSubjectsCount += (config?.subjects || []).map((subj) => subj.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        });
        return totalSubjectsCount;
    }

    public static async getPriceEngineAdditionRequestBodies(
        apiContext: ApiContext,
        selectedAdditions: Array<SelectedAddition & { resourceId: number }>,
        rateTypeId?: number
    ): Promise<ChoiceReservedResource[]> {
        const additionRequestBodies: ChoiceReservedResource[] = [];

        await Promise.all(
            selectedAdditions
                .filter((selectedAddition) => selectedAddition.quantity)
                .map(async (selectedAddition: SelectedAddition & { resourceId: number }) => {
                    const baseChildReservedResource: ChoiceReservedResource = new ChoiceReservedResource({
                        task: "CALCULATE",
                        resourceId: selectedAddition.resourceId,
                        quantity: 1,
                        rateTypeId,
                        status: "INITIAL",
                        type: asPriceEngineResourceType(selectedAddition.type),
                    });

                    if (selectedAddition?.daysAndSubjectsConfig?.length) {
                        await Promise.all(
                            selectedAddition?.daysAndSubjectsConfig.map(async (config) => {
                                let additionSubjects: SubjectQuantity[] = [];
                                if (config.subjects) {
                                    additionSubjects = config.subjects.map(
                                        (subject: ProductSubjectSelection) =>
                                            ({
                                                subjectId: subject.subjectId,
                                                quantity: subject.quantity,
                                            } as MxtsSubjectQuantity)
                                    );
                                }
                                if (config.days?.length) {
                                    await Promise.all(
                                        config.days
                                            .filter((day) => day.checked)
                                            .map(async (day: ProductDay) =>
                                                additionRequestBodies.push({
                                                    ...baseChildReservedResource,
                                                    subjects: additionSubjects.length ? additionSubjects : undefined,
                                                    startDate: await DateUtil.getMXTSDateTimeString(apiContext, day.date),
                                                    endDate: await DateUtil.getMXTSDateTimeString(apiContext, day.date),
                                                } as ChoiceReservedResource)
                                            )
                                    );
                                } else {
                                    additionRequestBodies.push({
                                        ...baseChildReservedResource,
                                        subjects: additionSubjects?.length ? additionSubjects : undefined,
                                    } as ChoiceReservedResource);
                                }
                            })
                        );
                    } else if (selectedAddition.startDate && selectedAddition.endDate) {
                        additionRequestBodies.push({
                            ...baseChildReservedResource,
                            startDate: await DateUtil.getMXTSDateTimeString(apiContext, selectedAddition.startDate),
                            endDate: await DateUtil.getMXTSDateTimeString(apiContext, selectedAddition.endDate),
                            quantity: selectedAddition.quantity,
                        } as ChoiceReservedResource);
                    } else {
                        additionRequestBodies.push({
                            ...baseChildReservedResource,
                            quantity: selectedAddition.quantity,
                        } as ChoiceReservedResource);
                    }
                })
        );
        return additionRequestBodies;
    }

    public static async getAddOnsRequest(props: {
        apiContext: ApiContext;
        env: ApiCallOptions;
        addition: GenericAddition;
        dynamicFilter: DynamicFilter;
        dynamicFieldCode?: string;
        rateType?: number;
        days?: ProductDay[];
        subjects?: ProductSubjectSelection[];
        startDate?: Date;
        endDate?: Date;
    }): Promise<Addon[]> {
        const {
            apiContext,
            addition: { resourceId, addition },
            days,
            subjects,
            startDate,
            endDate,
        } = props;

        const isDayAndSubjectProduct = this.isDayAndSubjectProduct(props.addition);
        const isDayProduct = this.isDayProduct(props.addition);
        const isSubjectProduct = this.isSubjectProduct(props.addition);

        const subjectsQuantity: SubjectQuantity[] | undefined = subjects?.map((subjectInfo) => {
            const { subjectId, quantity } = subjectInfo;
            return {
                subjectId,
                quantity,
            };
        });

        const selectedDays = days?.filter((day) => day.checked);

        const addOnParams = {
            resourceId,
            type: addition.type,
            quantity: 1,
            status: "INITIAL",
        };

        if (isDayAndSubjectProduct && selectedDays?.length) {
            const addOns = await Promise.all(
                selectedDays.map(async (day) => {
                    const date = await DateUtil.getMXTSDateTimeString(apiContext, day.date);
                    return {
                        ...addOnParams,
                        subjects: subjectsQuantity,
                        startDate: date,
                        endDate: date,
                    };
                })
            );
            return addOns;
        }

        if (isDayProduct && selectedDays?.length) {
            const addOns = await Promise.all(
                selectedDays.map(async (day) => {
                    const date = await DateUtil.getMXTSDateTimeString(apiContext, day.date);
                    return {
                        ...addOnParams,
                        startDate: date,
                        endDate: date,
                    };
                })
            );
            return addOns;
        }

        if (isSubjectProduct && subjectsQuantity?.length) {
            return [
                {
                    ...addOnParams,
                    subjects: subjectsQuantity,
                },
            ];
        }

        if (!isDayAndSubjectProduct && props.addition.datesInternet && startDate && endDate) {
            const startDateString = await DateUtil.getMXTSDateTimeString(apiContext, startDate);
            const endDateString = await DateUtil.getMXTSDateTimeString(apiContext, endDate);
            return [
                {
                    ...addOnParams,
                    startDate: startDateString,
                    endDate: endDateString,
                },
            ];
        }

        return [{ ...addOnParams }];
    }

    public static getDynamicFieldCode = (dynamicFieldCode?: NumberMultiSelectOption[]): string | undefined => {
        const dynamicFieldCodeIds = dynamicFieldCode?.map((code) => code.value || code);
        return dynamicFieldCodeIds?.toString();
    };

    public static async fetchAdditionPrice(props: {
        apiContext: ApiContext;
        env: ApiCallOptions;
        addition: GenericAddition;
        dynamicFilter: DynamicFilter;
        dynamicFieldCode?: string;
        rateType?: number;
        days?: ProductDay[];
        subjects?: ProductSubjectSelection[];
        startDate?: Date;
        endDate?: Date;
        widgetOptionsId: string;
        widgetOptionsDynamicFieldCodesPaths: string[];
    }): Promise<{ price: number | string; isRelativePrice: boolean }> {
        const {
            apiContext,
            env,
            addition: { dynamicManagerId },
            dynamicFilter,
            dynamicFieldCode,
            rateType,
            widgetOptionsId,
            widgetOptionsDynamicFieldCodesPaths,
        } = props;
        const fallbackPrice = "-";
        let price = null;
        let isRelativePrice = false;
        if (dynamicFieldCode) {
            if (dynamicManagerId) {
                price = await apiContext.mxtsApi
                    .dynamicFieldsInfoCustomized(env, {
                        managerId: dynamicManagerId,
                        code: dynamicFieldCode,
                        widgetOptionsId,
                        widgetOptionsDynamicFieldCodesPaths,
                    })
                    .then((res) => {
                        if (res && Array.isArray(res) && res.length) {
                            return res[0].value;
                        }
                        return "";
                    })
                    .catch(() => fallbackPrice);
            }
        } else {
            const { startDate, endDate, subjects: dynamicFilterSubjects } = getStartEndDateSubjectsForPrices(dynamicFilter);
            if (dynamicFilter.startdate && dynamicFilter.enddate && dynamicFilter.resourceid && dynamicFilter.distributionChannel?.distributionChannelId && dynamicFilter.reservationCategoryId) {
                const priceRequest: AddonsPriceRequest = {
                    startDate,
                    endDate,
                    distributionChannelId: dynamicFilter.distributionChannel.distributionChannelId,
                    reservationCategoryId: +dynamicFilter.reservationCategoryId,
                    rateTypeId: rateType,
                    addOns: await this.getAddOnsRequest(props),
                    accommodationType: {
                        resourceId: dynamicFilter.resourceid,
                        type: ResourceType.ACCOMMODATIONTYPE,
                        unitId: dynamicFilter.unitid,
                        quantity: 1,
                        subjects: dynamicFilterSubjects,
                        status: "INITIAL",
                    },
                };
                price = await apiContext.mxtsApi
                    .addonsPrice(env, priceRequest)
                    .then((addonsPriceResult: AddonsPriceResult) => {
                        const totalPrice = addonsPriceResult.addOns.map((addOn) => addOn.price).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
                        const quantifiers: string[] = [];
                        addonsPriceResult.addOns.forEach((addOn: any) => addOn?.usedCashflowrules?.forEach((usedCashflowrule: any) => quantifiers.push(usedCashflowrule?.quantifier)));
                        const addOnHasRelativePrice = quantifiers.includes("RELATIVE");
                        isRelativePrice = addOnHasRelativePrice;
                        return totalPrice || fallbackPrice;
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log("Failed to fetch the price break up from price engine:", error);
                        return fallbackPrice;
                    });
            }
        }
        return {
            price: price ?? fallbackPrice,
            isRelativePrice,
        };
    }

    static getAllSubjectsFromSelectedAddition(selectedAddition: SelectedAddition): ProductSubjectSelection[][] {
        const subjects: ProductSubjectSelection[][] | undefined = selectedAddition?.daysAndSubjectsConfig
            ?.map((config: { totalPrice?: number; days?: ProductDay[]; subjects?: ProductSubjectSelection[] }) => config.subjects)
            .filter((subjectSelection: ProductSubjectSelection[]) => subjectSelection?.length) as ProductSubjectSelection[][] | undefined;
        return subjects || [];
    }
}
