import { CMSProvidedProperties } from "../containers/cmsProvider.types";

export interface NumberLocaleFormatOptions {
    currency?: string;
    currencyDisplay?: string;
    localeMatcher?: string;
    maximumFractionDigits?: number;
    maximumSignificantDigits?: number;
    minimumFractionDigits?: number;
    minimumIntegerDigits?: number;
    minimumSignificantDigits?: number;
    style?: string;
    useGrouping?: boolean;
}

const fractionDigits = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
};

const fractionDigitsForRelative = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
};

/**
 * Util class with useful number related functions.
 *
 * @author P.Kromhout (p.kromhout@maxxton.com)
 */
export class NumberUtil {
    /**
     * Checks if an object is of type number. so will return false for input: '100'.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static isNumber(input: any): boolean {
        return typeof input === "number" && !isNaN(input);
    }

    /**
     * turns "some2string4with1numbers4" into: 2414
     * @param inputString the string that should be converted to a number
     */
    public static getNumberFromString(inputString: string): number {
        return +inputString.replace(/[^0-9.]+/g, "");
    }

    public static localeFormat(input: number | undefined | null, context: CMSProvidedProperties, options: NumberLocaleFormatOptions = fractionDigits): string | null | undefined {
        if (input == null) {
            return input;
        }
        return input.toLocaleString(context.currentLocale.code, options);
    }

    public static priceFormat(input: number | undefined, context: CMSProvidedProperties, options: NumberLocaleFormatOptions = fractionDigits): string | null | undefined {
        return this.localeFormat(input, context, options);
    }

    /**
     * Generates a formatted string representing the price with an optional currency symbol and decimal.
     *
     * @param {{
     *   price?: number | null;
     *   isRelativePrice?: boolean;
     *   context: CMSProvidedProperties;
     *   currencyCode: string | undefined;
     *   localeFormatOptions?: NumberLocaleFormatOptions;
     *   showCurrency?: boolean;
     * }} isRelativePrice - If isRelativePrice is true, the price will be rounded off, vice versa. Default value is false.
     *  And it will also rounded off the price if price has zero decimal.
     *
     * @returns {string | null | undefined} - Formatted price string or null/undefined if price is not provided.
     */

    public static priceWithCurrency({
        price,
        isRelativePrice = false,
        context,
        currencyCode,
        localeFormatOptions = fractionDigits,
        showCurrency = true,
    }: {
        price?: number | null | undefined;
        isRelativePrice?: boolean;
        context: CMSProvidedProperties;
        currencyCode: string | undefined;
        localeFormatOptions?: NumberLocaleFormatOptions;
        showCurrency?: boolean;
    }): string | null | undefined {
        if (price === undefined) {
            return undefined;
        }
        const baseCurrencyCode = currencyCode || context?.currency?.code;
        const localeCurrencyFormatOptions = baseCurrencyCode && showCurrency ? { currency: baseCurrencyCode, style: "currency" } : {};
        const displayZeroDecimal = context?.site?.addDecimalToPrices && context?.site?.displayZeroDecimal;
        const hasZeroDecimal = price ? price % 1 === 0 : false;
        if (isRelativePrice || (hasZeroDecimal && !displayZeroDecimal)) {
            localeFormatOptions = fractionDigitsForRelative;
        }
        return this.localeFormat(price, context, { ...localeFormatOptions, ...localeCurrencyFormatOptions });
    }
}

export const isDecimal = (value: number) => value % Math.floor(value) !== 0;
