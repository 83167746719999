import * as moment from "moment";

import { LinkedWidgetsActionType, LinkedWidgetsReducerAction } from "../../actions/linkedWidgetsAction";

import { Reducer } from "redux";

type Moment = moment.Moment;

export interface LinkedWidgetsData {
    selectedOwnerUnitId?: number;
    ownerInvoicePaymentStatusDatesFilter?: { startDate: Moment; endDate: Moment };
}

export interface LinkedWidgetsState {
    [widgetId: string]: LinkedWidgetsData;
}

export const linkedWidgetsReducer: Reducer<LinkedWidgetsState> = (state: LinkedWidgetsState = {}, action: LinkedWidgetsReducerAction): LinkedWidgetsState => {
    if (action.type === LinkedWidgetsActionType.UPDATE) {
        const { widgetIds, linkedWidgetsData: newLinkedWidgetsData } = action.payload;
        const newState = { ...state };
        widgetIds.forEach((widgetId) => {
            if (newState[widgetId]) {
                newState[widgetId] = { ...newState[widgetId], ...newLinkedWidgetsData };
            } else {
                newState[widgetId] = { ...newLinkedWidgetsData };
            }
        });
        return newState;
    }
    return state;
};
