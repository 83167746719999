import { ApiCallOptions } from "@maxxton/cms-mxts-api";
import { AvailabilityState } from "../redux/reducers/availability.types";
import { CMSProvidedProperties } from "../containers/cmsProvider.types";
import { getMxtsEnv } from "../plugins/mxts";
import { globalApiContext } from "../containers/CmsProvider";

interface GetEnvParams {
    availabilityState: AvailabilityState;
    context?: CMSProvidedProperties;
    paramEnv?: ApiCallOptions;
}

export const getEnv = async ({ availabilityState, context, paramEnv }: GetEnvParams): Promise<ApiCallOptions> => {
    const localeCode = context?.currentLocale ? context.currentLocale.code : context?.site.locale.code;
    const env = paramEnv || availabilityState.env || (await getMxtsEnv(context || globalApiContext(), localeCode));
    return env;
};
