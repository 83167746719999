export function fontColorPicker(fontColor: string) {
    if (fontColor?.includes("theme")) {
        return `color-${fontColor}`;
    } else if (fontColor && fontColor.indexOf("rgba") === -1) {
        return fontColor;
    }
    return "";
}

export function backgroundColorPicker(BackgroundColorTheme?: string, fontBackgroundColor?: string, borderColor?: string) {
    if (BackgroundColorTheme !== "default" && BackgroundColorTheme !== "row-bordered" && BackgroundColorTheme !== "underline-text" && fontBackgroundColor?.includes("theme")) {
        return `background-color-${fontBackgroundColor}`;
    } else if (BackgroundColorTheme !== "default" && BackgroundColorTheme !== "background-text" && borderColor?.includes("theme")) {
        return `border-color-${borderColor}`;
    }
    return "";
}
