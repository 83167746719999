import * as React from "react";

import { CmsApi, LocaleApi, OptionsApi, Page, PageApi, WithId } from "@maxxton/cms-api";
import { FormSpec, dynamicSpecs, mainSpecs, modelSpecs } from "../form-specs";
import { PathComponent, Route } from "./routing.types";
import { PluginSpec, findPluginSpecByOptions, loadPlugin, pluginSpecs, renderPageWidgets } from "../plugins";
import {
    categoryProjection,
    crpProjection,
    generalProjection,
    localeProjection,
    pageProjection,
    postProjection,
    siteProjection,
    translationProjection,
    webcontentProjection,
} from "../components/admin/utils";

import { AdminMode } from "../components/admin";
import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { ImitateUserAdminPage } from "../components/admin/imitate-user/ImitateUserAdminPage";
import { PathMatch } from "react-router-dom";
import { getI18nLocaleString } from "../i18n";
import { initDefaultFilterWithDispatcher } from "./routing.util";
import { isClientSide } from "../utils/generic.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../utils/loadableComponents.util";
import namespaceList from "../i18n/namespaceList";
import { pageLink } from "./";

const Localized = loadable(() => loadableRetry(() => import("../containers/Localized")), {
    resolveComponent: ({ Localized }) => Localized,
});

const AdminPage = loadable(() => loadableRetry(() => import("../components/admin/AdminPage")), {
    resolveComponent: ({ AdminPage }) => AdminPage,
});

const PageView = loadable(() => loadableRetry(() => import("../components/Page")), {
    resolveComponent: ({ PageView }) => PageView,
});

const noop = () => (): void => undefined;

export const staticRoutes: Route[] = [
    {
        path: "/webmanager/:panel/edit/:id",
        authorize: true,
        async load(m: PathMatch<string>, context: CMSProviderProperties): Promise<PathComponent | false> {
            const pageId = m.params.id;
            const itemId = m.params.id;
            const page: (Page & WithId) | null = pageId ? await PageApi.findById({ id: pageId }) : null;
            async function loadItems(): Promise<any[]> {
                const reqObject = { id: itemId as string, ignoreCache: true };
                const item = itemId ? await spec.api!.findById(reqObject, { includeConfidentialFields: true }) : [];
                return [item];
            }
            // WARNING: executing any redux dispatches before handleReduxStoreOnNavigation is called could cause redux state from the previous page to end up in the new page...
            initDefaultFilterWithDispatcher(context, page);

            const panelId = m.params.panel;
            const spec: FormSpec<any> =
                (modelSpecs.find((s) => s.id === panelId) as FormSpec<any>) ||
                (mainSpecs.find((s) => s.id === panelId) as FormSpec<any>) ||
                (dynamicSpecs.find((s) => s.id === panelId) as FormSpec<any>);
            if (spec != null) {
                const initialItems = await loadItems();
                return (props) => (
                    <AdminPage
                        {...props}
                        initialData={initialItems}
                        totalItems={0}
                        initialItemCount={0}
                        observe={spec.api!.asObservable(loadItems)}
                        spec={spec}
                        mainSpecs={mainSpecs}
                        modelSpecs={modelSpecs}
                        dynamicSpecs={dynamicSpecs}
                        pluginSpecs={pluginSpecs}
                        itemId={itemId}
                        mode="edit"
                    />
                );
            }
            return false;
        },
    },
    {
        path: "/webmanager/:panel/create",
        authorize: true,
        async load(m: PathMatch<string>): Promise<PathComponent | false> {
            const panelId = m.params.panel;
            const spec =
                (modelSpecs.find((s) => s.id === panelId) as FormSpec<any>) ||
                (mainSpecs.find((s) => s.id === panelId) as FormSpec<any>) ||
                (dynamicSpecs.find((s) => s.id === panelId) as FormSpec<any>);
            if (spec != null) {
                return (props) => (
                    <AdminPage
                        {...props}
                        initialData={[] as any[]}
                        totalItems={0}
                        initialItemCount={0}
                        observe={noop} // nohting to observe here
                        spec={spec}
                        mainSpecs={mainSpecs}
                        modelSpecs={modelSpecs}
                        dynamicSpecs={dynamicSpecs}
                        pluginSpecs={pluginSpecs}
                        mode="create"
                    />
                );
            }
            return false;
        },
    },
    {
        path: "/webmanager/:panel",
        authorize: true,
        async load(m: PathMatch<string>): Promise<PathComponent | false> {
            const panelId = m.params.panel;
            const mainSpec = mainSpecs.find((s) => s.id === panelId);
            const modelSpec = modelSpecs.find((s) => s.id === panelId);
            const dynamicSpec = dynamicSpecs.find((s) => s.id === panelId);
            const pluginOptionsSpec = pluginSpecs.find((s) => s.id === panelId);
            let countQuery = {};
            if (mainSpec != null || modelSpec != null || dynamicSpec != null || pluginOptionsSpec != null) {
                let mode: AdminMode;
                let spec: FormSpec<any>;
                let loadItems: () => Promise<any[]>;
                if (mainSpec) {
                    /* main */
                    mode = "list";
                    loadItems = () =>
                        mainSpec.id === "page"
                            ? mainSpec.api!.find({ query: { postId: { $exists: false } }, order: { name: 1 }, limit: "50", projection: pageProjection, ignoreCache: true })
                            : mainSpec.api!.find({
                                  order: { name: 1 },
                                  limit: "50",
                                  projection:
                                      mainSpec.id === "site"
                                          ? siteProjection
                                          : mainSpec.id === "webcontent"
                                          ? webcontentProjection
                                          : mainSpec.id === "post"
                                          ? postProjection
                                          : mainSpec.id === "category"
                                          ? categoryProjection
                                          : generalProjection,
                              });
                    spec = mainSpec;
                    if (mainSpec.id === "page") {
                        countQuery = { query: { postId: { $exists: false } } };
                    }
                } else if (modelSpec) {
                    /* model */
                    mode = "list";
                    loadItems = () => modelSpec.api!.find({ order: { name: 1 }, limit: "50", projection: modelSpec.id === "locale" ? localeProjection : translationProjection });
                    spec = modelSpec;
                } else if (dynamicSpec) {
                    /* dynamic */
                    mode = "list";
                    loadItems = () => dynamicSpec.api!.find({ order: { name: 1 }, limit: "50", projection: dynamicSpec.id === "results-panel" ? crpProjection : generalProjection });
                    spec = dynamicSpec;
                } else {
                    /* plugin options */
                    const pluginSpec: PluginSpec<any> | undefined = findPluginSpecByOptions(pluginOptionsSpec!);
                    if (pluginSpec === undefined) {
                        throw new Error("Cannot find plugin options");
                    }
                    mode = "options";
                    loadItems = () => loadPlugin(CmsApi, pluginSpec).then((plugin) => [plugin.options]);
                    spec = pluginOptionsSpec!;
                }
                const initialItems = await loadItems();
                const totalItems = mode !== "options" ? await spec.api!.findCount(countQuery) : 0;
                const initialItemCount = initialItems.length;
                const observer = mainSpec
                    ? mainSpec.api!.asObservable(loadItems)
                    : modelSpec
                    ? modelSpec.api!.asObservable(loadItems)
                    : dynamicSpec
                    ? dynamicSpec.api!.asObservable(loadItems)
                    : OptionsApi.asObservable(loadItems);
                return (props) => (
                    <AdminPage
                        {...props}
                        initialData={initialItems}
                        totalItems={totalItems}
                        initialItemCount={initialItemCount}
                        observe={observer}
                        spec={spec}
                        mainSpecs={mainSpecs}
                        modelSpecs={modelSpecs}
                        dynamicSpecs={dynamicSpecs}
                        pluginSpecs={pluginSpecs}
                        mode={mode}
                    />
                );
            }
            return false;
        },
    },
    {
        path: "/webmanager/imitate-user",
        authorize: true,
        async load(m: PathMatch<string>, context: CMSProviderProperties, path?: string): Promise<PathComponent> {
            return (props) => <ImitateUserAdminPage context={context} routeComponentProps={props}></ImitateUserAdminPage>;
        },
    },
    {
        path: "/webmanager",
        authorize: true,
        async load(m: PathMatch<string>): Promise<PathComponent> {
            if (isClientSide()) {
                window.location.replace(`/webmanager/${mainSpecs[0].id}`);
            }
            return () => <div />;
        },
    },
    {
        path: "/pages/:id",
        authorize: true,
        async load(m: PathMatch<string>, context: CMSProviderProperties, path?: string): Promise<PathComponent> {
            const pageId = m.params.id;
            const page: (Page & WithId) | null = pageId ? await PageApi.findById({ id: pageId }) : null;
            const pageLinkUrl = page?.pageId ? await pageLink({ site: context.site, pageId: page.pageId, locale: context.currentLocale, context }) : "";
            if (page == null) {
                if (isClientSide()) {
                    window.location.replace(`/404?originalUrl=${path}`);
                }
                return () => <div />;
            }
            // WARNING: executing any redux dispatches before handleReduxStoreOnNavigation is called could cause redux state from the previous page to end up in the new page...
            initDefaultFilterWithDispatcher(context, page);

            const children = await renderPageWidgets(page.root, context);
            return (props) => <PageView page={page} pageLink={pageLinkUrl} children={children} context={context} updateTitle />;
        },
    },
];

export async function notFoundPage(): Promise<PathComponent | false> {
    // eslint-disable-next-line  no-console
    console.warn("Page Not Found");
    if (isClientSide()) {
        throw new Error("Page Not Found");
    }
    return false;
}

export async function onMaintenance(m: PathMatch<string>, context: CMSProviderProperties): Promise<PathComponent> {
    const { currentLocale, site } = context;
    let { code } = currentLocale;
    const isMaintenancePage: any = await context.cmsApi.siteApi.findSitemapFromSiteByFriendlyUrl({ siteId: site._id, url: "/maintenance" });

    if (isMaintenancePage && site.maintenanceMode) {
        if (site.localeMultiSelect && site.localeMultiSelect.length > 0) {
            const languages: any[] = [];
            const allowedLanguages = site.localeMultiSelect;
            if (allowedLanguages && allowedLanguages.length > 0) {
                for (const lang of allowedLanguages) {
                    languages.push(await LocaleApi.findById({ id: lang.value }).then((content) => content!.code));
                }
            }
            // If the current Site language isn't allowed for that site
            if (languages.indexOf(code) <= -1) {
                code = "";
            }
        }
        if (isClientSide()) {
            window.location.replace(`${site.enableMultiLanguage ? "/" + code : ""}/maintenance`);
        }
        return () => <div />;
    }
    return (props) => <Localized key={getI18nLocaleString(namespaceList.global, "nothingAvailableOnThisUrl", currentLocale, site)} />;
}

export const notFoundRoute: Route = {
    path: "/",
    load: notFoundPage,
};

export const maintenanceRoute: Route = {
    path: "/",
    load: onMaintenance,
};
