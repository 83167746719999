import { BaseWidgetOptions } from "../../form-specs";
import { Dispatch } from "react";
import { LinkedWidgetsData } from "../reducers/linkedWidgets/linkedWidgetsState";
import { LinkedWidgetsSpecOptions } from "../../components/generic-form/linkedWidgets/LinkedWidgetsSelectSpec";
import { Action as ReduxAction } from "redux";
import { WithId } from "@maxxton/cms-api";

export enum LinkedWidgetsActionType {
    UPDATE = "linked_widgets_update",
}

export interface LinkedWidgetsReducerAction extends ReduxAction<LinkedWidgetsActionType> {
    payload: { widgetIds: string[]; linkedWidgetsData: Partial<LinkedWidgetsData> };
}

export const dispatchUpdateLinkedWidgetsData = (dispatchAction: Dispatch<any>, widgetOptions: LinkedWidgetsSpecOptions, linkedWidgetsData: Partial<LinkedWidgetsData>): void => {
    if (widgetOptions.linkedWidgetIds?.length) {
        dispatchAction(updateLinkedWidgetsData(widgetOptions, linkedWidgetsData));
    }
};

export const updateLinkedWidgetsData = (widgetOptions: LinkedWidgetsSpecOptions, linkedWidgetsData: Partial<LinkedWidgetsData>): LinkedWidgetsReducerAction => {
    const currentWidgetId: string | undefined = (widgetOptions as BaseWidgetOptions).customWidgetId || (widgetOptions as WithId)._id;
    const widgetIds = [...(widgetOptions.linkedWidgetIds || []), ...(currentWidgetId ? [currentWidgetId] : [])];
    return {
        type: LinkedWidgetsActionType.UPDATE,
        payload: { widgetIds: currentWidgetId && widgetIds.length ? widgetIds : [], linkedWidgetsData },
    };
};
