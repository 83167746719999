import { cloneDeep, uniq, uniqBy } from "lodash";

import { StringUtil } from "./string.util";

interface WithChildren {
    parentId?: number | null;
    children?: WithChildren[] | null;
}

/**
 * Contains array utility methods
 * TODO: create unit tests for these methods
 */
export class ArrayUtil {
    public static turnFlatArrayIntoTree<T extends WithChildren>(arr: T[], getId: (item: T) => number): T[] {
        const tree: T[] = [];
        const mappedArr: { [key: number]: T } = {};

        // First map the nodes of the array to an object -> create a hash table.
        for (const arrElem of arr) {
            mappedArr[getId(arrElem)] = arrElem;
        }

        for (const reservedResourceId in mappedArr) {
            if (mappedArr.hasOwnProperty(reservedResourceId)) {
                const mappedElem: T = mappedArr[reservedResourceId];
                if (mappedElem.parentId) {
                    // The element is not at the root level, add it to its parent array of children.
                    if (!mappedArr[mappedElem.parentId].children) {
                        mappedArr[mappedElem.parentId].children = [];
                    }
                    mappedArr[mappedElem.parentId]?.children?.push(mappedElem);
                } else {
                    // The element is at the root level, add it to first level elements array.
                    tree.push(mappedElem);
                }
            }
        }
        return tree;
    }

    public static getUniques(array: number[]) {
        return uniq(array);
    }

    public static getUniqueStrings(strings: string[], ignoreCase = false) {
        if (ignoreCase) {
            return uniqBy(strings, (stringItem: string) => stringItem?.toLowerCase());
        }
        return uniq(strings);
    }

    public static getMax(array: number[]) {
        return Math.max(...array);
    }

    public static getMaxByField<T>(array: T[], valueField: keyof T): T {
        return array.reduce((prev: T, current: T) => (prev[valueField] > current[valueField] ? prev : current));
    }

    public static spliceItems<T>(inputArray: T[], isRemoveItem: (item: T) => boolean, getRecursiveChildren?: (item: T) => T[] | undefined, immutable = true): T[] {
        if (!inputArray?.length || !isRemoveItem) {
            return [];
        }
        if (immutable) {
            inputArray = cloneDeep(inputArray);
        }
        let i = inputArray.length;
        while (i--) {
            const child = inputArray[i];
            if (isRemoveItem(child)) {
                inputArray.splice(i, 1);
            } else if (getRecursiveChildren) {
                const recursiveChildren = getRecursiveChildren(child);
                if (recursiveChildren?.length) {
                    this.spliceItems(recursiveChildren, isRemoveItem, getRecursiveChildren);
                }
            }
        }
        return inputArray;
    }

    public static includes(collection: string[], target: string, options: { caseSensitive: boolean } = { caseSensitive: true }) {
        for (const collectionItem of collection) {
            let match = false;
            if (options.caseSensitive) {
                match = collectionItem === target;
            } else {
                match = StringUtil.equalsIgnoreCase(collectionItem, target);
            }
            if (match) {
                return true;
            }
        }
        return false;
    }

    public static isIncludedIn(collection: string[], targets: string[], options: { caseSensitive: boolean } = { caseSensitive: true }) {
        for (const target of targets) {
            const match = this.includes(collection, target, options);
            if (match) {
                return true;
            }
        }
        return false;
    }

    /**
     * Flattens the 2 dimensional array into a 1 dimensional array.
     */
    public static flatten2Dimensions<E>(list: E[][]): E[] {
        return ([] as E[]).concat(...list);
    }
}
