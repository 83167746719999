export enum WidgetGroup {
    LAYOUT,
    DYNAMIC,
    CONTENT,
    OTHER,
    BOOKINGS_MODULE,
    DISPLAY,
    INPUT,
    MY_ENVIRONMENT,
}
