import * as React from "react";

import { DESCRIPTION, DESCRIPTION2, DYNAMIC_FIELD_CODE, NAME, SHORT_DESCRIPTION, TITLE } from "./utils";

import ContentLoader from "react-content-loader";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";

interface LoaderProps {
    dynamicFilter?: DynamicFilter;
    displayUnitContainer?: string;
    displayTypeContainer?: string;
    unitsLoading?: boolean;
    type?: string;
    views?: string | number | boolean;
}

interface LoaderState {
    dynamicFilter?: DynamicFilter;
    displayUnitContainer?: string;
}

// TODO: Create LoaderType enum

export class Loader extends React.Component<LoaderProps, LoaderState> {
    constructor(props: LoaderProps) {
        super(props);
        this.state = {
            dynamicFilter: props.dynamicFilter,
            displayUnitContainer: props.displayUnitContainer,
        };
    }
    private renderLocationSearchContainer(key: number) {
        const width = 100;
        const height = 34;

        return (
            <ContentLoader
                uniqueKey="location-search-container"
                key={key}
                viewBox={`0 0 ${width} ${height}`}
                width={`${width}%`}
                height={`${height}%`}
                speed={3}
                backgroundColor="#f5f5f5"
                foregroundColor="#fcfcfc"
            >
                <rect width="39" height="23" x="0" y="2" rx="1"></rect>
                <rect width="60" height="2" x="40" y="2" rx="1"></rect>
                <rect width="30" height="1" x="40" y="5" rx="0.5"></rect>
                <rect width="5" height="2" x="40" y="14" rx="1"></rect>
                <rect width="35" height="2" x="46" y="14" rx="0.5"></rect>
                <rect width="5" height="2" x="40" y="17" rx="1"></rect>
                <rect width="35" height="2" x="46" y="17" rx="0.5"></rect>
                <rect width="5" height="2" x="40" y="20" rx="1"></rect>
                <rect width="35" height="2" x="46" y="20" rx="0.5"></rect>
                <rect width="12" height="2" x="88" y="15" rx="1"></rect>
                <rect width="14" height="4" x="86" y="18" rx="1"></rect>
                <rect width="40" height="1" x="60" y="24"></rect>
                <rect width="40" height="5" x="60" y="24" rx="1"></rect>
            </ContentLoader>
        );
    }

    private renderTypeSearchContainerListView(key: number) {
        const width = 100;
        const height = 29;
        return (
            <ContentLoader
                uniqueKey="type-search-container-list-view"
                key={key}
                viewBox={`0 0 ${width} ${height}`}
                width={`${width}%`}
                height={`${height}%`}
                speed={3}
                backgroundColor="#f5f5f5"
                foregroundColor="#fcfcfc"
            >
                <rect width="39" height="25" x="0" y="2" rx="1"></rect>
                <rect width="60" height="2" x="40" y="2" rx="1"></rect>
                <rect width="30" height="1" x="40" y="5" rx="0.5"></rect>
                <rect width="48" height="2" x="40" y="9" rx="1"></rect>
                <rect width="40" height="2" x="40" y="12" rx="1"></rect>
                <rect width="35" height="2" x="40" y="15" rx="1"></rect>
                <rect width="5" height="4" x="40" y="23" rx="1"></rect>
                <rect width="5" height="4" x="48" y="23" rx="1"></rect>
                <rect width="5" height="4" x="57" y="23" rx="1"></rect>
                <rect width="5" height="4" x="66" y="23" rx="1"></rect>
                <rect width="7" height="3" x="93" y="16" rx="1"></rect>
                <rect width="12" height="2" x="88" y="20" rx="1"></rect>
                <rect width="14" height="4" x="86" y="23" rx="1"></rect>
            </ContentLoader>
        );
    }

    private renderTypeSearchContainerGridView(key: number) {
        const width = 100;
        const height = 59;

        return (
            <ContentLoader
                uniqueKey="type-search-container-grid-view"
                key={key}
                viewBox={`0 0 ${width} ${height}`}
                width={`${width}%`}
                height={`${height}%`}
                speed={3}
                backgroundColor="#f5f5f5"
                foregroundColor="#fcfcfc"
            >
                <rect width="48" height="30" rx="1" x="0" y="2"></rect>
                <rect width="8" height="2" rx="1" x="0" y="33"></rect>
                <rect width="8" height="2" rx="1" x="13" y="33"></rect>
                <rect width="8" height="2" rx="1" x="26" y="33"></rect>
                <rect width="8" height="2" rx="1" x="40" y="33"></rect>
                <rect width="48" height="7" rx="1" x="0" y="36"></rect>
                <rect width="10" height="4" rx="1" x="0" y="48"></rect>
                <rect width="10" height="4" rx="1" x="0" y="53"></rect>
                <rect width="8" height="2" rx="1" x="40" y="48"></rect>
                <rect width="15" height="5" rx="1" x="33" y="51"></rect>

                <rect width="48" height="30" rx="1" x="52" y="2"></rect>
                <rect width="8" height="2" rx="1" x="52" y="33"></rect>
                <rect width="8" height="2" rx="1" x="64" y="33"></rect>
                <rect width="8" height="2" rx="1" x="78" y="33"></rect>
                <rect width="8" height="2" rx="1" x="92" y="33"></rect>
                <rect width="48" height="7" rx="1" x="52" y="36"></rect>
                <rect width="10" height="4" rx="1" x="52" y="48"></rect>
                <rect width="10" height="4" rx="1" x="52" y="53"></rect>
                <rect width="8" height="2" rx="1" x="91" y="48"></rect>
                <rect width="15" height="5" rx="1" x="85" y="51"></rect>
            </ContentLoader>
        );
    }
    private renderActivityGridView(key: number) {
        const width = 100;
        const height = 49;

        return (
            <ContentLoader
                uniqueKey="activity-grid-view"
                key={key}
                viewBox={`0 0 ${width} ${height}`}
                width={`${width}%`}
                height={`${height}%`}
                speed={3}
                backgroundColor="#f5f5f5"
                foregroundColor="#fcfcfc"
            >
                <rect width="32" height="23" rx="1" x="0" y="2"></rect>
                <rect width="32" height="4" rx="1" x="0" y="26"></rect>
                <rect width="10" height="2" rx="1" x="0" y="37"></rect>
                <rect width="10" height="2" rx="1" x="0" y="40"></rect>
                <rect width="8" height="2" rx="1" x="24" y="35"></rect>
                <rect width="10" height="4" rx="1" x="22" y="38"></rect>

                <rect width="32" height="23" rx="1" x="34" y="2"></rect>
                <rect width="32" height="4" rx="1" x="34" y="26"></rect>
                <rect width="10" height="2" rx="1" x="34" y="37"></rect>
                <rect width="10" height="2" rx="1" x="34" y="40"></rect>
                <rect width="8" height="2" rx="1" x="58" y="35"></rect>
                <rect width="10" height="4" rx="1" x="56" y="38"></rect>

                <rect width="32" height="23" rx="1" x="68" y="2"></rect>
                <rect width="32" height="4" rx="1" x="68" y="26"></rect>
                <rect width="10" height="2" rx="1" x="68" y="37"></rect>
                <rect width="10" height="2" rx="1" x="68" y="40"></rect>
                <rect width="8" height="2" rx="1" x="92" y="35"></rect>
                <rect width="10" height="4" rx="1" x="90" y="38"></rect>
            </ContentLoader>
        );
    }

    private displayWidgets() {
        return (
            <ContentLoader uniqueKey="display-widgets" speed={3} backgroundColor={"#f8f8f8"}>
                <rect x="120" y="13.5" className="st1" width="200" height="15" />
            </ContentLoader>
        );
    }
    private longDescription() {
        const width = 100;
        const height = 10;

        return (
            <ContentLoader uniqueKey="long-description" viewBox="0 0 100 14" width={`${width}%`} height={`${height}%`} speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                <rect x="0" y="0" rx="1" ry="1" width="100" height="2"></rect>
                <rect x="0" y="4" rx="1" ry="1" width="80" height="2"></rect>
                <rect x="0" y="8" rx="1" ry="1" width="60" height="2"></rect>
            </ContentLoader>
        );
    }

    private staticImageGallery() {
        const width = 100;
        const height = 43;

        return (
            <ContentLoader uniqueKey="static-image-gallery" viewBox={`0 0 ${width} ${height}`} width={`${width}%`} height={`${height}%`} speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                <rect x="0" y="0" width="50" height="23"></rect>
                <rect x="51" y="0" width="50" height="23"></rect>
                <rect x="0" y="24" width="33" height="19"></rect>
                <rect x="34" y="24" width="33" height="19"></rect>
                <rect x="68" y="24" width="33" height="19"></rect>
            </ContentLoader>
        );
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): any {
        const { views, type } = this.props;
        if (type === "capacity" || type === "bedroom" || type === "bathroom") {
            return this.displayWidgets();
        } else if (type === "description") {
            switch (views) {
                case NAME:
                case TITLE:
                case DYNAMIC_FIELD_CODE: {
                    const width = 100;
                    const height = 3;
                    return (
                        <ContentLoader
                            uniqueKey="name-description"
                            viewBox={`0 0 ${width} ${height}`}
                            width={`${width}%`}
                            height={`${height}%`}
                            speed={3}
                            backgroundColor="#f5f5f5"
                            foregroundColor="#fcfcfc"
                        >
                            <rect x="0" y="0" rx="1" ry="1" width="100" height="3"></rect>
                        </ContentLoader>
                    );
                }
                case DESCRIPTION: {
                    return this.longDescription();
                }
                case SHORT_DESCRIPTION: {
                    return this.longDescription();
                }
                case DESCRIPTION2: {
                    return this.longDescription();
                }
                default: {
                    const width = 100;
                    const height = 5;
                    return (
                        <ContentLoader
                            uniqueKey="default-description"
                            viewBox={`0 0 ${width} ${height}`}
                            width={`${width}%`}
                            height={`${height}%`}
                            speed={3}
                            backgroundColor="#f5f5f5"
                            foregroundColor="#fcfcfc"
                        >
                            <rect x="0" y="0" rx="1" ry="1" width="100" height="5"></rect>
                        </ContentLoader>
                    );
                }
            }
        } else if (type === "additionsList" || type === "addOnsList" || type === "location-facet-list") {
            return (
                <ContentLoader uniqueKey="addOnsList" viewBox="0 0 400 150" speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="100%" rx="1" x="0" y="0" height="30" />
                    <rect width="100%" rx="1" x="0" y="35" height="30" />
                    <rect width="100%" rx="1" x="0" y="70" height="30" />
                    <rect width="100%" rx="1" x="0" y="105" height="30" />
                </ContentLoader>
            );
        } else if (type === "amenity" && views === "list-view-map") {
            return (
                <ContentLoader uniqueKey="amenity" height={10} width={200} speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="100%" rx="1" height="100%" />
                </ContentLoader>
            );
        } else if (type === "reservation-container") {
            const width = 100;
            const height = 20;
            return (
                <ContentLoader
                    uniqueKey="reservation-container"
                    viewBox={`0 0 ${width} ${height}`}
                    width={`${width}%`}
                    height={`${height}%`}
                    speed={3}
                    backgroundColor="#f5f5f5"
                    foregroundColor="#fcfcfc"
                >
                    <rect x="0" y="0" rx="1" ry="1" width="70" height="20"></rect>
                </ContentLoader>
            );
        } else if (type === "image") {
            return (
                <ContentLoader uniqueKey="image" viewBox="0 0 100 60" speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="100%" height="100%" />
                </ContentLoader>
            );
        } else if (type === "static-image-gallery") {
            return this.staticImageGallery();
        } else if (type === "amenity" && views === "grid-view-map") {
            return (
                <div className={"amenityLoad"}>
                    <ContentLoader uniqueKey="amenity-load" speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                        <rect x="0" y="0" className="st1" width="100%" height="100%" />
                    </ContentLoader>
                </div>
            );
        } else if (views === "list-view-map") {
            return (
                <ContentLoader uniqueKey="list-view-map" width={1033} height={219} speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="309" height="228" rx="6" x="0" y="0" />
                    <rect width="700" height="25" rx="6" x="326" y="0" />
                    <rect width="700" height="50" rx="6" x="326" y="39" />
                    <rect width="220" height="20" rx="6" x="361" y="126" />
                    <rect width="174" height="95" rx="6" x="852" y="126" />
                    <rect width="220" height="20" rx="6" x="361" y="163" />
                    <rect width="220" height="20" rx="6" x="361" y="200" />
                    <rect width="22" height="20" rx="6" x="327" y="126" />
                    <rect width="22" height="20" rx="6" x="327" y="163" />
                    <rect width="22" height="20" rx="6" x="327" y="200" />
                </ContentLoader>
            );
        } else if (type === "image-gallery-layout") {
            switch (views) {
                case "right-five-layout":
                case "right": {
                    const width = 100;
                    const height = 25;
                    return (
                        <ContentLoader
                            uniqueKey="right-five-layout"
                            className="image-gallery-loader--right"
                            viewBox={`0 0 ${width} ${height}`}
                            width={`${width}%`}
                            height={`${height}%`}
                            speed={3}
                            backgroundColor="#f5f5f5"
                            foregroundColor="#fcfcfc"
                        >
                            <rect fill="rgba(153,153,153,0.5)" className="svg-1" x="0" y="0" width="62%" height="100%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-2" x="63%" y="0" width="18%" height="48%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-3" x="82%" y="0" width="18%" height="48%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-4" x="63%" y="52%" width="18%" height="48%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-5" x="82%" y="52%" width="18%" height="48%"></rect>
                        </ContentLoader>
                    );
                }
                case "left-five-layout":
                case "left": {
                    const width = 100;
                    const height = 25;
                    return (
                        <ContentLoader
                            uniqueKey="left-five-layout"
                            className="image-gallery-loader--left"
                            viewBox={`0 0 ${width} ${height}`}
                            width={`${width}%`}
                            height={`${height}%`}
                            speed={3}
                            backgroundColor="#f5f5f5"
                            foregroundColor="#fcfcfc"
                        >
                            <rect fill="rgba(153,153,153,0.5)" className="svg-1" x="38%" y="0" width="62%" height="100%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-2" x="19%" y="0" width="18%" height="48%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-3" x="0" y="0" width="18%" height="48%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-4" x="19%" y="52%" width="18%" height="48%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-5" x="0" y="52%" width="18%" height="48%"></rect>
                        </ContentLoader>
                    );
                }
                case "top-five-layout":
                case "top": {
                    const width = 100;
                    const height = 25;
                    return (
                        <ContentLoader
                            uniqueKey="top-five-layout"
                            className="image-gallery-loader--top"
                            viewBox={`0 0 ${width} ${height}`}
                            width={`${width}%`}
                            height={`${height}%`}
                            speed={3}
                            backgroundColor="#f5f5f5"
                            foregroundColor="#fcfcfc"
                        >
                            <rect fill="rgba(153,153,153,0.5)" className="svg-1" x="0" y="0" width="25%" height="46%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-2" x="26%" y="0" width="25%" height="46%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-3" x="52%" y="0%" width="25%" height="46%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-4" x="78%" y="0" width="25%" height="46%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-5" x="0" y="49%" width="100%" height="60%"></rect>
                        </ContentLoader>
                    );
                }
                case "bottom-five-layout":
                case "bottom": {
                    const width = 100;
                    const height = 25;
                    return (
                        <ContentLoader
                            uniqueKey="bottom-five-layout"
                            className="image-gallery-loader--bottom"
                            viewBox={`0 0 ${width} ${height}`}
                            width={`${width}%`}
                            height={`${height}%`}
                            speed={3}
                            backgroundColor="#f5f5f5"
                            foregroundColor="#fcfcfc"
                        >
                            <rect fill="rgba(214,208,208,0.5)" className="svg-1" x="0" y="0" width="100%" height="60%"></rect>
                            <rect fill="rgba(153,153,153,0.5)" className="svg-2" x="0" y="49%" width="25%" height="46%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-3" x="26%" y="0" width="25%" height="46%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-4" x="52%" y="0%" width="25%" height="46%"></rect>
                            <rect fill="rgba(214,208,208,0.5)" className="svg-5" x="78%" y="0" width="25%" height="46%"></rect>
                        </ContentLoader>
                    );
                }
            }
        } else if (views === "slideShow") {
            return (
                <ContentLoader uniqueKey="slideshow" viewBox="0 0 100 30" width="100%" height="100%" speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="100%" rx="1" height="30" />
                </ContentLoader>
            );
        } else if (type === "typeSearchContainerGrid" && views === "type-search-container-grid") {
            return [...Array(3)].map((val, key) => this.renderTypeSearchContainerGridView(key));
        } else if (type === "typeSearchContainerList" && views === "type-search-container-list") {
            return <div className="loader__typesearch--list">{[...Array(6)].map((val, key) => this.renderTypeSearchContainerListView(key))}</div>;
        } else if (type === "locationSearchContainer") {
            return [...Array(3)].map((val, key) => this.renderLocationSearchContainer(key));
        } else if (type === "add-on-image") {
            const width = 100;
            const height = 60;
            return (
                <ContentLoader uniqueKey="add-on-image" speed={3} viewBox={`0 0 ${width} ${height}`} width={`${width}%`} height={`${height}%`} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="100%" height="100%" />
                </ContentLoader>
            );
        } else if (type === "acco-kind-facet-grid" || type === "location-facet-grid") {
            const width = 100;
            const height = 100;
            return (
                <ContentLoader
                    uniqueKey="acco-kind-facet-grid"
                    speed={3}
                    viewBox={`0 0 ${width} ${height}`}
                    width={`${width}%`}
                    height={`${height}%`}
                    backgroundColor="#f5f5f5"
                    foregroundColor="#fcfcfc"
                >
                    <rect x="0" y="5" width="50%" height="27%"></rect>
                    <rect x="52%" y="5" width="50%" height="27%"></rect>
                    <rect x="0" y="34%" width="50%" height="27%"></rect>
                    <rect x="52%" y="34%" width="50%" height="27%"></rect>
                    <rect x="0" y="63%" width="50%" height="27%"></rect>
                    <rect x="52%" y="63%" width="50%" height="27%"></rect>
                </ContentLoader>
            );
        } else if (type === "search-amenities") {
            const width = 100;
            const height = 80;
            return (
                <ContentLoader uniqueKey="search-amenities" speed={3} viewBox={`0 0 ${width} ${height}`} width={`${width}%`} height={`${height}%`} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="100" rx="1" x="0" y="0" height="15"></rect>
                    <rect width="100" rx="1" x="0" y="18" height="15"></rect>
                    <rect width="100" rx="1" x="0" y="36" height="15"></rect>
                    <rect width="100" rx="1" x="0" y="54" height="15"></rect>
                </ContentLoader>
            );
        } else if (type === "direct-search") {
            const width = 100;
            const height = 30;
            return (
                <ContentLoader uniqueKey="direct-search" viewBox={`0 0 ${width} ${height}`} width={`${width}%`} height={`${height}%`} speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect x="0" y="5" rx="1" ry="1" width="100" height="5"></rect>
                    <rect x="0" y="15" rx="1" ry="1" width="100" height="20"></rect>
                </ContentLoader>
            );
        } else if (type === "activity-grid-view") {
            return [...Array(4)].map((val, key) => this.renderActivityGridView(key));
        } else if (type === "responsive-menu") {
            return (
                <ContentLoader uniqueKey="responsive-menu" height={10} width={200} speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                    <rect width="100%" rx="1" height="10" />
                </ContentLoader>
            );
        }
        return (
            <ContentLoader uniqueKey="default-content-loader" width="100%" height="350" speed={3} backgroundColor="#f5f5f5" foregroundColor="#fcfcfc">
                <rect width="100%" x="0" y="0" height="200" rx="6"></rect>
                <rect width="30%" x="0" y="280" height="24" rx="6"></rect>
                <rect width="30%" x="0" y="320" height="24" rx="6"></rect>
                <rect width="40%" x="60%" y="280" height="61" rx="6"></rect>
                <rect width="100%" x="0" y="210" height="59" rx="6"></rect>
            </ContentLoader>
        );
    }
}
