import { ApiCallOptions, Customer, CustomerFullAddress, MxtsApi, ReservationResult } from "@maxxton/cms-mxts-api";
import { ReservationAction, ReservationActionType } from "../redux/actions/reservationAction";
import { ReservationCustomerSagaActionType, fetchReservationCustomerError, fetchReservationCustomerSuccess, startFetchingReservationCustomer } from "../redux/actions/reservationCustomerAction";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { ActionType } from "../redux/actions";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { ReservationCustomerState } from "../redux/reducers/reservationCustomerReducer";
import { ReservationState } from "../redux/reducers/reservationReducer";
import { SagaUtils } from "./SagaUtils";
import { getMxtsEnv } from "../plugins/mxts";
import { globalApiContext } from "../containers/CmsProvider";
import { isEmpty } from "lodash";

function* getReservationCustomerInfo() {
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const prevCustomerInfo: ReservationCustomerState = yield select(SagaUtils.getReservationCustomerState);
    const { fetching, customer } = prevCustomerInfo;
    if (typeof fetching === "undefined" || (!fetching && isEmpty(customer))) {
        yield put(startFetchingReservationCustomer());
        try {
            const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale);
            const reservationState: ReservationState = yield select(SagaUtils.getReservationState);
            let reservation: ReservationResult | undefined = reservationState?.reservation;
            if (isEmpty(reservation)) {
                reservation = yield call(MxtsApi.getReservation, env, {}, [{ key: "reservationId", value: dynamicFilter.reservationId }]);
                const reservationAction: ReservationAction = {
                    type: ActionType.Reservation,
                    actionType: ReservationActionType.created,
                    payload: {
                        reservation,
                    },
                };
                yield put(reservationAction);
            }
            const customer: Customer = yield call(MxtsApi.getCustomer, env, { view: "detail" }, [{ key: "customerId", value: reservation?.customerId }]);

            let currentAddress: CustomerFullAddress | undefined;
            if (customer?.addresses?.length) {
                const { managerId } = customer.addresses[0];
                currentAddress = managerId ? yield call(MxtsApi.getCurrentAddress, env, { manager: managerId, view: "address" }) : undefined;
            }

            yield put(fetchReservationCustomerSuccess({ customer, currentAddress }));
        } catch (error) {
            yield put(fetchReservationCustomerError(error));
        }
    }
}

export function* watchReservationCustomer() {
    yield takeEvery(ReservationCustomerSagaActionType.FETCH_RESERVATION_CUSTOMER, getReservationCustomerInfo);
}
