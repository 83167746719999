import { Page, PageApi } from "@maxxton/cms-api";

import { FormSpec } from "../";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";

export const autocompletePageSpec: FormSpec<Page> = {
    id: "page",
    name: getI18nLocaleObject(namespaceList.admin, "page"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "pages"),
    api: PageApi,
    display(page: Page): string {
        return page.name;
    },
    properties: [],
};
