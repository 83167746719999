import * as moment from "moment";

import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";

import { DATE_FORMAT } from "../utils/constants";
import { InputSpecSelect } from "../form-specs";
import namespaceList from "../i18n/namespaceList";

export function getDateFormatOptions() {
    const dateFormats = ["DD-MMM-YY", "DD-MM-YYYY", "MM-DD-YYYY", "MMM-DD-YYYY", "MMM DD, YYYY", "DD MMMM YYYY"];
    const dateFormatOptions = dateFormats.map((dateFormat: string) => ({
        label: moment().format(dateFormat),
        value: dateFormat,
    }));
    return dateFormatOptions;
}

function dateFormatsSelect<E, P extends keyof E>({ variable, label, defaultFormat }: { variable: P; label?: I18nLocaleObject | string; defaultFormat?: string }): InputSpecSelect<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "dateFormat"),
        type: "select",
        default: ((defaultFormat || DATE_FORMAT.DISPLAY) as any) as E[P],
        optionList: getDateFormatOptions() as any,
    };
}

export function getActivityDateFormatOptions() {
    const activityDateFormats = [
        DATE_FORMAT.DISPLAY, // DD-MM-YYYY
        DATE_FORMAT.MONTH_DATE_YEAR, // MM-DD-YYYY
        DATE_FORMAT.DATE_WITH_SHORT_MONTH, // MMM-DD-YYYY
        DATE_FORMAT.DATE_WITH_SHORT_MONTH_REVERSED, // DD-MMM-YYYY
        DATE_FORMAT.SHORT_MONTH_DAY_YEAR, // MMM DD, YYYY
        DATE_FORMAT.DAY_WITH_MONTH_NAME, // DD MMM YYYY
        DATE_FORMAT.DAY_DATE, // DD MMM
        DATE_FORMAT.SHORT_DAY_AND_MONTH, // ddd DD MM YYYY
        DATE_FORMAT.LOCALE_DATE, // LL
        DATE_FORMAT.LOCALE_DATE_WITH_SHORT_DAY, // ddd LL
        DATE_FORMAT.LOCALE_DATE_WITH_DAY, // dddd, LL
        DATE_FORMAT.DAY_DATE_SHORT_MONTH, // ddd DD MMM
        DATE_FORMAT.DAY_DATE_SHORT_MONTH_YEAR, // ddd DD MMM YYYY
    ];
    const activityDateFormatOptions = activityDateFormats.map((dateFormat: string) => ({
        label: dateFormat,
        value: dateFormat,
    }));
    return activityDateFormatOptions;
}

export default dateFormatsSelect;
