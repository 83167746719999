import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { getI18nLocaleString } from "../../i18n";
import namespacesList from "../../i18n/namespaceList";

interface ConfirmationModalProps {
    isModalOpen: boolean;
    confirmationTitle?: string;
    confirmationMessage?: string;
    handleConfirm?: () => void;
    handleCancel: () => void;
    handleDiscard?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isModalOpen, confirmationMessage, confirmationTitle, handleConfirm, handleCancel, handleDiscard }) => {
    let confirmButtonText;
    let cancelButtonText;
    let confirmIcon;
    let cancelIcon;
    if (confirmationMessage === getI18nLocaleString(namespacesList.admin, "confirmCancel")) {
        confirmButtonText = getI18nLocaleString(namespacesList.admin, "clear");
        cancelButtonText = getI18nLocaleString(namespacesList.admin, "keepChanges");
        confirmIcon = "trash";
        cancelIcon = "undo";
    } else {
        confirmButtonText = getI18nLocaleString(namespacesList.admin, "save");
        cancelButtonText = getI18nLocaleString(namespacesList.admin, "cancel");
        confirmIcon = "cloud-upload";
        cancelIcon = "times";
    }

    return (
        <Modal isOpen={isModalOpen} size="md" className="frontend-confirmation-modal">
            {confirmationTitle && <ModalHeader>{confirmationTitle}</ModalHeader>}
            {confirmationMessage && (
                <ModalBody>
                    <p className="confirm-message">{confirmationMessage}</p>
                </ModalBody>
            )}
            <ModalFooter>
                <div className="action-buttons frontend-confirmation-modal__footer">
                    <Button onClick={handleConfirm} color="primary">
                        <FontAwesome name={confirmIcon} />
                        <span className="confirm-button-text">{confirmButtonText}</span>
                    </Button>
                    {handleDiscard && (
                        <Button onClick={handleDiscard} color="danger">
                            <FontAwesome name="trash" />
                            <span className="confirm-button-text">{getI18nLocaleString(namespacesList.admin, "discard")}</span>
                        </Button>
                    )}
                    <Button onClick={handleCancel} color="secondary">
                        <FontAwesome name={cancelIcon} />
                        <span className="confirm-button-text">{cancelButtonText}</span>
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationModal;
