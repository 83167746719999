import { ApiCallOptions, Capacities, Country, MxtsApiWrapper, Person, ResourceType, Subject } from "@maxxton/cms-mxts-api";

import { CurrentLocale } from "../../../app.types";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { Site } from "@maxxton/cms-api";
import { SubjectsGroupById } from "./traveParty.types";
import { TravellerDetailsOption } from "./";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

interface CountriesGroupById {
    [key: number]: Country;
}

export async function getAccommodation({
    mxtsApi,
    selectedReservationId,
    travellerDetailsOptions,
    env,
    reservedResourceIds,
}: {
    mxtsApi: MxtsApiWrapper;
    selectedReservationId?: number;
    travellerDetailsOptions?: TravellerDetailsOption[];
    env: ApiCallOptions;
    reservedResourceIds?: number[];
}) {
    const accommodation: string[] = [];
    const showAccommodation = travellerDetailsOptions?.find((option) => option.value === "accommodation");
    if (showAccommodation) {
        const selectedResource = await mxtsApi.getReservedResources(env, { reservationId: selectedReservationId });
        const filteredResources = selectedResource.content.filter((resource) => resource.type === ResourceType.ACCOMMODATIONTYPE && reservedResourceIds?.includes(resource?.reservedResourceId!));
        const unitIds = filteredResources.map((data) => data.unitId!);
        const units = (await mxtsApi.units(env, { unitIds }))?.content;
        filteredResources.forEach((resource) => {
            const reservedResourceId = resource?.reservedResourceId;
            const matchingUnit = units.find((unit) => unit.unitId === resource.unitId);
            const accommodationName = `${resource?.name} (${matchingUnit?.name})`;
            accommodation[reservedResourceId!] = accommodationName;
        });
    }
    return accommodation;
}
export async function getCountriesGroupById({
    mxtsApi,
    travelParty,
    travellerDetailsOptions,
    env,
}: {
    mxtsApi: MxtsApiWrapper;
    travelParty: Person[];
    travellerDetailsOptions?: TravellerDetailsOption[];
    env: ApiCallOptions;
}) {
    let countriesGroupById: CountriesGroupById = {};
    const showCountry = isCountrySelected(travellerDetailsOptions);
    if (showCountry) {
        const countryIds: number[] = [];
        travelParty.forEach((person) => {
            if (person.countryId && !countryIds.includes(person.countryId)) {
                countryIds.push(person.countryId);
            }
        });
        if (countryIds.length) {
            countriesGroupById = await mxtsApi.countries(env, { countryIds }).then((response) => {
                const countries = response.content;
                const countriesGroupById = countries.reduce((acc: CountriesGroupById, country: Country) => {
                    acc[country.countryId] = country;
                    return acc;
                }, {});
                return countriesGroupById;
            });
        }
    }
    return countriesGroupById;
}

export function isCountrySelected(travellerDetailsOptions: TravellerDetailsOption[] | undefined) {
    return travellerDetailsOptions?.find((option) => option.value === "country");
}

export async function getSubjectsGroupById({
    mxtsApi,
    travelParty,
    travellerDetailsOptions,
    env,
    customerId,
}: {
    mxtsApi: MxtsApiWrapper;
    travelParty: Person[];
    travellerDetailsOptions?: TravellerDetailsOption[];
    env: ApiCallOptions;
    customerId?: number;
}) {
    let subjectsGroupById: SubjectsGroupById = {};
    const showAgeGroup = isAgeGroupSelected(travellerDetailsOptions);
    if (showAgeGroup) {
        const subjectIds: number[] = [];
        travelParty.forEach((person) => {
            if (person.subjectId && !subjectIds.includes(person.subjectId)) {
                subjectIds.push(person.subjectId);
            }
        });
        if (subjectIds.length) {
            subjectsGroupById = await mxtsApi.subjects(env, { subjectIds }).then((response) => {
                const subjects = response.content;
                const subjectsGroupById = subjects.reduce((acc: SubjectsGroupById, subject: Subject) => {
                    acc[subject.subjectId] = subject;
                    return acc;
                }, {});
                return subjectsGroupById;
            });
        }
    }
    return subjectsGroupById;
}

export function isAgeGroupSelected(travellerDetailsOptions: TravellerDetailsOption[] | undefined) {
    return travellerDetailsOptions?.some((option) => option.value === "ageGroup");
}

export const getGenderOptions = (currentLocale: CurrentLocale, site: Site) => [
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "unspecified", currentLocale, site), value: "UNSPECIFIED" },
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "male", currentLocale, site), value: "MALE" },
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "female", currentLocale, site), value: "FEMALE" },
];

export const getIdentityTypeOptions = (currentLocale: CurrentLocale, site: Site) => [
    { label: getI18nLocaleObject(namespaceList.genericCrud, "none"), value: "" },
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "passport", currentLocale, site), value: "PASSPORT" },
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "driverLicense", currentLocale, site), value: "DRIVERS_LICENCE" },
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "identityCard", currentLocale, site), value: "IDENTITY_CARD" },
];

export function getOption(options: Array<SelectOption<string>> | undefined, value: string | number | null = ""): SelectOption<string> | undefined {
    const newValue = typeof value === "number" ? String(value) : value || "";
    return options?.find((option) => option.value === newValue);
}

export function getSanitizedValue(value: string, returnNumber = false): string | number | null {
    if (!value) {
        return null;
    }
    if (returnNumber) {
        return +value;
    }
    return value;
}

export async function getCapacities({
    mxtsApi,
    env,
    resourceId,
    inherited,
    resourceActivityDetailsId,
    arrivalDate,
}: {
    mxtsApi: MxtsApiWrapper;
    env: ApiCallOptions;
    resourceId: number;
    inherited?: boolean;
    resourceActivityDetailsId?: number;
    arrivalDate?: string;
}): Promise<Capacities[]> {
    const resources = await mxtsApi.resources(env, { size: 1, resourceIds: [resourceId] }).then((res) => res.content[0]);
    const capacities = await mxtsApi.getCapacities(env, { inherited: inherited || false, view: "detail", resourceActivityDetailsId, arrivalDate }, [
        { key: "managerId", value: resources.capacityManagerId },
    ]);
    return capacities;
}
