import { Activity, SubjectQuantityWithDetailsId } from "../../plugins/page/activityPlanner/activityPlanner.types";
import { ActivityPlannerAction, ActivityPlannerActionType } from "../actions/activityPlannerAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";
import { ScheduledActivitiesSubjectsResponse } from "@maxxton/cms-mxts-api";

export interface ActivityPlannerState {
    selectedActivities?: Activity[];
    reservedResourceSubjectQuantities?: SubjectQuantityWithDetailsId[];
    selectedActivityCustomSubjects?: ScheduledActivitiesSubjectsResponse[];
}

export const initialActivityPlannerState: ActivityPlannerState = {
    selectedActivities: [],
    reservedResourceSubjectQuantities: [],
    selectedActivityCustomSubjects: [],
};

export const activityPlannerReducer: Reducer<ActivityPlannerState> = (state: ActivityPlannerState = initialActivityPlannerState, action: ActivityPlannerAction): ActivityPlannerState => {
    const actionType = action.actionType;
    const payload = action.payload;
    const filter = { ...state };
    switch (action.type) {
        case ActionType.activityPlanner:
            switch (actionType) {
                case ActivityPlannerActionType.SELECT_ACTIVITY:
                    return {
                        ...state,
                        selectedActivities: action.payload.selectedActivities?.length ? [...action.payload.selectedActivities] : [],
                    };
                case ActivityPlannerActionType.SET_SUBJECT_QUANTITIES:
                    if (payload.reservedResourceSubjectQuantities) {
                        filter.reservedResourceSubjectQuantities = payload.reservedResourceSubjectQuantities;
                    }
                    break;
                case ActivityPlannerActionType.SET_ACTIVITY_CUSTOM_SUBJECT: {
                    if (payload.selectedActivityCustomSubjects) {
                        filter.selectedActivityCustomSubjects = payload.selectedActivityCustomSubjects;
                    }
                    break;
                }
                case ActivityPlannerActionType.RESET_STATE: {
                    filter.reservedResourceSubjectQuantities = undefined;
                    filter.selectedActivities = undefined;
                    filter.selectedActivityCustomSubjects = undefined;
                    break;
                }
                case ActivityPlannerActionType.UPDATE:
                    return { ...state, ...payload };
                default:
                    return state;
            }
            return filter;
        default:
            return state;
    }
};
