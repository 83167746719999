import * as MXTS from "@maxxton/cms-mxts-api";
import * as fetch from "isomorphic-fetch";
import * as moment from "moment";

import { AmenityIcons, IconLibraryId } from "./Amenity";
import { ApiContext, CMSProviderProperties } from "../../../containers/cmsProvider.types";

import { DATE_FORMAT } from "../../../utils/constants";
import { getMxtsEnv } from "../../mxts";
import { isClientSide } from "../../../utils/generic.util";

export interface AmenitiesRequestOptions {
    amenityManagerId: number;
    origins: string[];
    context: CMSProviderProperties;
    size?: number;
    amenityCategoryIds?: number[];
    sortLinks: boolean;
    orderByCategorySequence?: boolean;
    signal?: AbortSignal;
}

export async function getAmenities(apiContext: ApiContext, amenityOptions: AmenitiesRequestOptions): Promise<MXTS.AmenityLink[]> {
    const ops = await getMxtsEnv(apiContext, amenityOptions.context.currentLocale.code);
    const today = moment().utc().format(DATE_FORMAT.ELASTIC);
    const amenityLinks = await apiContext.mxtsApi.amenityLinks(
        ops,
        {
            size: amenityOptions.size || 5,
            sort: amenityOptions.sortLinks ? "priority" : undefined,
            view: "detail",
            origins: amenityOptions.origins,
            orderByCategorySequence: amenityOptions.orderByCategorySequence,
            managerId: amenityOptions.amenityManagerId,
            startDate: today,
            endDate: today,
            amenityCategoryIds: amenityOptions.amenityCategoryIds || undefined,
        },
        undefined,
        amenityOptions.signal
    );
    return amenityLinks.content;
}

export const getAmenityIcons = async (amenityLinks: MXTS.AmenityLink[]): Promise<AmenityIcons[]> => {
    const amenityIcons: AmenityIcons[] = [];
    await Promise.all(
        amenityLinks
            .filter((amenityLink) => amenityLink.amenity.iconLibraryId === IconLibraryId.StreamLine)
            .map(async (filteredAmenity) => amenityIcons.push({ amenityId: filteredAmenity.amenityId, amenityIcon: await getSvgElement(filteredAmenity.amenity.iconName || "") }))
    );
    return amenityIcons;
};

async function getSvgElement(path: string): Promise<string> {
    const cachedSvgElementString = isClientSide() && sessionStorage.getItem(path);
    const cachedData = cachedSvgElementString && JSON.parse(cachedSvgElementString);
    if (cachedData) {
        return cachedData;
    }
    const response = await fetch(path);
    if (!response.ok) {
        throw new Error(`GET ${path} responded with status ${response.status} (${await response.text()})`);
    }
    const data = await response.text();
    if (isClientSide()) {
        sessionStorage.setItem(path, JSON.stringify(data));
    }
    return data;
}
