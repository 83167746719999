import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "castle",
    name: "Castle Theme",
    stylesheets: ["/styles/themes/castle/castle-admin.css"],
    frontendStylesheets: ["/styles/themes/castle/castle.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "image-text-shadow",
            name: "Add shadow to the texts on image",
            targets: ["flexbox", "webcontent"],
            classnames: ["image-text-shadow"],
        },
        {
            id: "text-brand",
            name: "Apply brand color to the texts",
            targets: ["flexbox", "webcontent"],
            classnames: ["section-brand-text"],
        },
        {
            id: "line-over-title",
            name: "sidelines to the title",
            targets: ["webcontent"],
            classnames: ["lined-title"],
        },
        {
            id: "light-font-weight",
            name: "Light text",
            targets: ["webcontent"],
            classnames: ["light-text"],
        },
        {
            id: "horizontal-divider",
            name: "Add horizontal line at the top and bottom",
            targets: ["flexbox"],
            classnames: ["horizontal-divider"],
        },
        {
            id: "search-page-full-header",
            name: "Search results page full header",
            targets: ["flexbox"],
            classnames: ["full-page-header"],
        },
        {
            id: "shadow-effect",
            name: "Add shadow effect at the bottom",
            targets: ["flexbox"],
            classnames: ["shadow-effect"],
        },
        {
            id: "quotation-mark",
            name: "Add double quotes to both sides",
            targets: ["webcontent"],
            classnames: ["quotation-mark"],
        },
        {
            id: "small-space-bottom",
            name: "Small space at the bottom",
            targets: ["webcontent"],
            classnames: ["small-space-bottom"],
        },
        {
            id: "check-list",
            name: "List with checkboxes ",
            targets: ["webcontent"],
            classnames: ["list-checkbox"],
        },
        {
            id: "hover-underline",
            name: "Underline on hover effect",
            targets: ["menu", "webcontent"],
            classnames: ["hover-underline"],
        },
        {
            id: "quicksearch-modal",
            name: "Quicksearch Modal",
            targets: ["revealer-link"],
            classnames: ["quicksearch-modal"],
        },
        {
            id: "header-desktop",
            name: "Header styling on desktop",
            targets: ["flexbox"],
            classnames: ["header-desktop"],
        },
        {
            id: "header-mobile",
            name: "Header styling on mobile view",
            targets: ["flexbox"],
            classnames: ["header-mobile"],
        },
        {
            id: "hamburger-menu",
            name: "Hamburger menu",
            targets: ["flexbox", "button"],
            classnames: ["hamburger-menu"],
        },
        {
            id: "footer",
            name: "Footer styling",
            targets: ["flexbox"],
            classnames: ["footer"],
        },
    ],
};

export default theme;
