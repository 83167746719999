import { AVAILABILITY_CONSTANTS, MXTS } from "./constants";
import { Aggregation, ApiCallOptions, MxtsApiWrapper, Resource } from "@maxxton/cms-mxts-api";
import { chunk, memoize } from "lodash";

import { ApiContext } from "../containers/cmsProvider.types";
import { NumberMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { getAvailability } from "./availability.util";
import { getMxtsEnv } from "../plugins/mxts";

const { NO_SPECIAL, APPLICATION } = AVAILABILITY_CONSTANTS;
const { PAGE_REQUEST_SIZE, MAX_RESULTS } = MXTS;

export const SPECIAL_CODE_AGGREGATION: Aggregation = { name: "SPECIAL_CODE_FACET", field: "SPECIAL_CODE", type: "FACET", size: 1000 };

export const fetchAllBookableSpecials = async ({ env, distributionChannelId }: { env: ApiCallOptions; distributionChannelId: number }, apiContext: ApiContext): Promise<Resource[]> => {
    const baseFilter = {
        application: APPLICATION,
        distributionChannelId,
        aggregations: [SPECIAL_CODE_AGGREGATION],
        releaseStatus: "OPEN" as const,
    };
    let availableSearchCodes = await getAvailability(apiContext, env, baseFilter).then((availability) => availability.response.specials || []);
    availableSearchCodes = availableSearchCodes.filter((code) => code !== NO_SPECIAL);
    const allBookableSpecials: Resource[] = await fetchSpecialsByCodes({ env, specialCodes: availableSearchCodes }, apiContext.mxtsApi);
    return allBookableSpecials;
};

export const memoizedFetchAllBookableSpecials = memoize(fetchAllBookableSpecials, ({ distributionChannelId }) => distributionChannelId);

export const fetchSpecialsByCodes = async ({ env, specialCodes }: { env: ApiCallOptions; specialCodes: string[] }, mxtsApi: MxtsApiWrapper): Promise<Resource[]> => {
    if (!specialCodes.length) {
        return [];
    }
    const specialSearchCodesChunk = chunk(specialCodes, PAGE_REQUEST_SIZE);
    const specialPromises = specialSearchCodesChunk.map((codes) =>
        mxtsApi.resources(env, { size: MAX_RESULTS, codes, strictEqualFields: ["searchCodes", "codes"], searchOnCodeAndSearchCode: true }).then((response) => response.content)
    );
    const specials = (await Promise.all(specialPromises)).flat();
    return specials;
};

export const getsources = async (type: string, apiContext: ApiContext): Promise<NumberMultiSelectOption[]> => {
    const options: NumberMultiSelectOption[] = [];
    const env = await getMxtsEnv(apiContext);
    const sources = await apiContext.mxtsApi.source(env, { size: 75 });
    sources.content.map((contents) => {
        const selectKey = type === "multiselect" ? "text" : "label";
        options.push({
            value: contents.sourceId,
            [selectKey]: contents.name,
        });
    });
    return options;
};
