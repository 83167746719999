// eslint-disable

// This is a dependency file, hence not to be changed.
import Select from "./Select";
import { Async } from "./Async";
import AsyncCreatable from "./AsyncCreatable";
import Creatable from "./Creatable";
import Value from "./Value";

(Select as any).Async = Async;
(Select as any).AsyncCreatable = AsyncCreatable;
(Select as any).Creatable = Creatable;
(Select as any).Value = Value;

export default Select;
export { Async, AsyncCreatable, Creatable, Value };
