import { Customer, DistributionChannel, EsReservationResult, MyEnvUserType, RateTypes } from "@maxxton/cms-mxts-api";
import { MyEnvActionType, MyEnvReducerAction } from "../../actions/myEnvAction";

import { Reducer } from "redux";

export interface MyEnvState {
    selectedReservationId?: number;
    selectedReservation?: MyEnvReservation | null;
    selectedReservationLoading?: boolean;
    mainCustomer?: Customer | null;
    myEnvUserTypes?: MyEnvUserType[];
    customerIds?: number[];
    customerLoading?: boolean;
    confirmingShoppingCart?: boolean;
    ownerState?: OwnerState;
    sortReservationsByArrivalDate?: boolean;
}

export interface OwnerState {
    selectedUnitId?: number;
    selectedBookingChoice?: string;
    bookingEngineOwnUnitLink?: string;
    bookingEngineOtherUnitLink?: string;
    ownerOwnUnitLink?: string;
    ownerOwnUnitLinkForGuest?: string;
    ownerDistributionChannel?: Pick<DistributionChannel, "distributionChannelId" | "code">;
    ownerRateType?: Pick<RateTypes, "rateTypeId" | "code">;
    ownerReservationCategoryId?: number;
    amenityUpdatedStartDate?: moment.Moment | null;
    amenityUpdatedEndDate?: moment.Moment | null;
    ownerUnitsResortIds?: number[];
}

export interface MyEnvReservation extends EsReservationResult {
    selectedReservedResourceId?: number;
    hasUnitPreference?: boolean;
}

export const myEnvReducer: Reducer<MyEnvState> = (state: MyEnvState = {}, action: MyEnvReducerAction): MyEnvState => {
    if (action.type === MyEnvActionType.OWNER_UPDATE) {
        const ownerState = { ...state.ownerState, ...action.payload.ownerState };
        return { ...state, ownerState };
    }
    const newState = { ...state, ...action.payload };
    const actionType = action.type;
    switch (actionType) {
        case MyEnvActionType.RESERVATION_FETCHED:
        case MyEnvActionType.CUSTOMER_FETCHED:
        case MyEnvActionType.UPDATE:
            return newState;
        default:
            return state;
    }
};
