import { poeditorTranslations } from "./poeditor/index";

export interface Translations {
    [code: string]: () => Promise<any>;
}

export const translations: Translations = {
    at: () => import("./poeditor/de"),
    ...poeditorTranslations,
};
