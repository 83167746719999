/* eslint-disable max-lines-per-function */
import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { Badge, Input } from "reactstrap";
import type { ChartData, ChartOptions, Tick } from "chart.js";
import { OwnerNetRevenueFilter, UnitNetRevenueMonth, UnitRevenueMonth } from "@maxxton/cms-mxts-api";
import { TrendingDownRounded, TrendingUpRounded } from "@mui/icons-material";
import { calculateYearCount, getAllOwnedUnitIdsFromToken } from "../reservations/overview/util/ownership.util";
import { getPercentageChangeInData, getUnitIdsFromContracts, getVisibleYears, getYearColor } from "../owner.util";

import { ArrayUtil } from "../../../../utils/array.util";
import { CMSProviderProperties } from "../../../../containers/cmsProvider.types";
import { DateType } from "../../additions/products/DaySubjectProduct";
import { DynamicWidgetBaseProps } from "../../dynamicWidget.types";
import { MyEnvState } from "../../../../redux/reducers/myEnv/myEnvState";
import { NoContentsFound } from "../../../../components/noContentFound/NoContentFound";
import { NumberLocaleFormatOptions } from "../../../../utils/number.util";
import { Price } from "../../../../components/generic-form/price";
import PrintButton from "../../../../components/printFunctionality/PrintButton";
import { State } from "../../../../redux";
import { WidgetOptions } from "./";
import { YearOrDateRangeFilter } from "../reservations/overview/table/YearOrDateRangeFilter";
import { createLocalizedTitleAndLabel } from "../../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { generateAllMonthNames } from "../../../../utils/chart/chart.util";
import { getCustomerIdsFromLoginToken } from "../../../../redux/reducers/myEnv/myEnv.util";
import { getI18nLocaleString } from "../../../../i18n";
import { getInitialDate } from "../reservations/overview/util/ownerReservationsOverview.util";
import { getMxtsEnv } from "../../../mxts";
import { isEqual } from "lodash";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../../utils/loadableComponents.util";
import namespaceList from "../../../../i18n/namespaceList";
import { useSelector } from "react-redux";

const Bar = loadable(() => loadableRetry(() => import("react-chartjs-2")), {
    resolveComponent: ({ Bar }) => Bar,
});

const Line = loadable(() => loadableRetry(() => import("react-chartjs-2")), {
    resolveComponent: ({ Line }) => Line,
});

type Moment = moment.Moment;

export interface OwnerRevenueStreamsWidgetProps extends DynamicWidgetBaseProps<WidgetOptions> {}

interface GenericRevenueFilter extends OwnerNetRevenueFilter {
    ownerIds: number[];
}
type GenericRevenueMonth = UnitRevenueMonth | UnitNetRevenueMonth;

export function OwnerRevenueStreamsWidget(props: OwnerRevenueStreamsWidgetProps) {
    const [loading, setLoading] = React.useState(true);
    const { context, options } = props;
    const {
        noContentFoundWebContentId,
        noContentFoundTemplateId,
        chartType,
        showMinimizedVersionOfRevenueStream,
        displayRevenuePercentage,
        yearToShowTheNetRevenue,
        revenueType,
        contractTypeIds,
        displayDataBasedOnContractTypeIds,
    } = options;
    const [isChartJsReady, setIsChartJsReady] = React.useState(false);
    const [startDate, setStartDate] = React.useState<Moment>(getInitialDate(Number(yearToShowTheNetRevenue), DateType.startDate));
    const [endDate, setEndDate] = React.useState<Moment>(getInitialDate(Number(yearToShowTheNetRevenue), DateType.endDate));
    const [yearCount, setYearCount] = React.useState<number | undefined>(undefined);
    const { currentLocale, mxtsEnv, site } = context;
    const languageCode = context.currentLocale?.code;
    const [revenueMonths, setRevenueMonths] = React.useState<GenericRevenueMonth[]>([]);
    const allMonthNames = React.useMemo(() => generateAllMonthNames(languageCode), [languageCode]);
    const [showPreviousYear, setShowPreviousYear] = React.useState(false);
    const [showNextYear, setShowNextYear] = React.useState(false);
    const [allOwnerUnitIds, setAllOwnerUnitIds] = React.useState<number[]>();
    const [percentageOfRevenue, setPercentageOfRevenue] = React.useState<number>(0);
    const visibleYears = getVisibleYears({ startDate, endDate, showPreviousYear, showNextYear });
    const myEnvState: MyEnvState = useSelector((state: State) => state.myEnvState);
    const selectedUnitIds = myEnvState.ownerState?.selectedUnitId ? [myEnvState.ownerState?.selectedUnitId] : [];
    const mostRecentNetRevenueFilter = React.useRef<GenericRevenueFilter>();
    const currencyCode = context.currency?.code;

    const YEAR_OR_DATE_RANGE_FILTER_TRANSLATIONS = {
        yearSelectTitle: getI18nLocaleString(namespaceList.ownerRevenueStreamsWidget, "yearSelectTitle", currentLocale, site),
        dateRangeSelectTitle: getI18nLocaleString(namespaceList.ownerRevenueStreamsWidget, "dateRangePickerTitle", currentLocale, site),
        selectedYearResultsTitle: getI18nLocaleString(namespaceList.ownerRevenueStreamsWidget, "reservationsFromYear", currentLocale, site),
        selectedRangeResultsTitle: getI18nLocaleString(namespaceList.ownerRevenueStreamsWidget, "reservationsFromDateRange", currentLocale, site),
    };

    React.useEffect(() => {
        // Dynamically import Chart.js
        Promise.all([import("chart.js")]).then(([chartJsModule]) => {
            const { Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip, LineElement, PointElement, Filler } = chartJsModule;
            chartJsModule.Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement, Filler);
            setIsChartJsReady(true);
        });
    }, []);

    React.useEffect(() => {
        calculateYearCount(context, setYearCount);
    }, [currentLocale, mxtsEnv]);

    React.useEffect(() => {
        if (!selectedUnitIds.length && allOwnerUnitIds == null) {
            getMxtsEnv(context, languageCode).then((env) => getAllOwnedUnitIdsFromToken({ env, mxtsApi: context.mxtsApi }).then((allOwnerUnitIds) => setAllOwnerUnitIds(allOwnerUnitIds)));
            setLoading(false);
        }
    }, [JSON.stringify(selectedUnitIds), allOwnerUnitIds]);

    React.useEffect(() => {
        const obtainOwnerNetRevenue = async () => {
            setLoading(true);
            const netRevenueFilter: GenericRevenueFilter = {
                years: visibleYears,
                months: getMonthsBetweenDates(startDate, endDate),
                unitIds: selectedUnitIds.length ? selectedUnitIds : allOwnerUnitIds,
                ownerIds: await getCustomerIdsFromLoginToken(),
                considerOwnership: true,
            };
            mostRecentNetRevenueFilter.current = netRevenueFilter;
            const obtainedRevenue = revenueType === "totalTurnover" ? await getUnitRevenue(context, netRevenueFilter) : await getOwnerNetRevenue(context, netRevenueFilter);
            if (isEqual(mostRecentNetRevenueFilter.current, netRevenueFilter)) {
                if (displayDataBasedOnContractTypeIds && obtainedRevenue.length && contractTypeIds?.length) {
                    const env = await getMxtsEnv(context, context.currentLocale?.code);
                    const contractUnitIds = await getUnitIdsFromContracts(env, contractTypeIds);
                    const filteredObtainedRevenue = ((obtainedRevenue as unknown) as GenericRevenueMonth[]).filter(
                        (revenueMonth: UnitRevenueMonth | UnitNetRevenueMonth) => !contractUnitIds?.some((contractUnitId) => contractUnitId === revenueMonth.unitId)
                    );
                    setRevenueMonths(filteredObtainedRevenue);
                } else {
                    setRevenueMonths(obtainedRevenue);
                }
                setLoading(false);
            }
        };
        if (selectedUnitIds.length || allOwnerUnitIds?.length) {
            obtainOwnerNetRevenue();
        }
    }, [JSON.stringify(selectedUnitIds), languageCode, JSON.stringify(visibleYears), allOwnerUnitIds, startDate, endDate]);

    React.useEffect(() => {
        (async () => {
            if (showMinimizedVersionOfRevenueStream && displayRevenuePercentage && yearToShowTheNetRevenue) {
                const previousYear = yearToShowTheNetRevenue - 1;
                const previousYearStartDate = getInitialDate(previousYear, DateType.startDate);
                const previousYearEndDate = getInitialDate(previousYear, DateType.endDate);
                const netRevenueFilter: GenericRevenueFilter = {
                    years: [previousYear],
                    months: getMonthsBetweenDates(previousYearStartDate, previousYearEndDate),
                    unitIds: selectedUnitIds.length ? selectedUnitIds : allOwnerUnitIds,
                    ownerIds: await getCustomerIdsFromLoginToken(),
                    considerOwnership: true,
                };
                let obtainedPreviousYearRevenue: GenericRevenueMonth[] =
                    revenueType === "totalTurnover" ? await getUnitRevenue(context, netRevenueFilter) : await getOwnerNetRevenue(context, netRevenueFilter);
                if (displayDataBasedOnContractTypeIds && obtainedPreviousYearRevenue.length && contractTypeIds?.length) {
                    const env = await getMxtsEnv(context, context.currentLocale?.code);
                    const contractUnitIds = await getUnitIdsFromContracts(env, contractTypeIds);
                    const filteredObtainedRevenue = ((obtainedPreviousYearRevenue as unknown) as GenericRevenueMonth[]).filter(
                        (revenueMonth: UnitRevenueMonth | UnitNetRevenueMonth) => !contractUnitIds?.some((contractUnitId) => contractUnitId === revenueMonth.unitId)
                    );
                    obtainedPreviousYearRevenue = filteredObtainedRevenue;
                }
                const totalNetTurnover = revenueMonths
                    .filter((revenueMonth) => revenueMonthIsInDateRange(revenueMonth, startDate, endDate))
                    .reduce((total: number, revenueMonth: GenericRevenueMonth) => total + ((revenueType === "ownerNet" ? revenueMonth.netTurnover : revenueMonth.totalTurnover) || 0), 0);
                const previousYearTotalNetTurnover = obtainedPreviousYearRevenue
                    .filter((previousYearRevenueMonth) => revenueMonthIsInDateRange(previousYearRevenueMonth, previousYearStartDate, previousYearEndDate))
                    .reduce(
                        (total: number, previousYearRevenueMonth: GenericRevenueMonth) =>
                            total + ((revenueType === "ownerNet" ? previousYearRevenueMonth.netTurnover : previousYearRevenueMonth.totalTurnover) || 0),
                        0
                    );
                const percentageOfReservations = getPercentageChangeInData(previousYearTotalNetTurnover, totalNetTurnover);
                setPercentageOfRevenue(percentageOfReservations);
            }
        })();
    }, [JSON.stringify(allOwnerUnitIds), selectedUnitIds, revenueMonths]);

    const baseChartDataSets = createBaseChartDataSets({ visibleYears, revenueMonths, allMonthNames, showPreviousYear, showNextYear, options, startDate, endDate });
    if (!isChartJsReady) {
        return null;
    }
    if (showMinimizedVersionOfRevenueStream) {
        return !loading && (noContentFoundWebContentId || noContentFoundTemplateId) && !revenueMonths.length ? (
            <NoContentsFound context={context} widgetOptions={options} />
        ) : (
            <TotalNetTurnover revenueMonths={revenueMonths} context={context} startDate={startDate} endDate={endDate} options={options} percentageOfRevenue={percentageOfRevenue}></TotalNetTurnover>
        );
    }
    return (
        <div className="owner-revenue-streams-wrapper">
            {createLocalizedTitleAndLabel(options, context)}
            <YearOrDateRangeFilter
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                translations={YEAR_OR_DATE_RANGE_FILTER_TRANSLATIONS}
                startDate={startDate}
                onlyAllowRangeWithinSingleYear={true}
                endDate={endDate}
                context={context}
                yearCount={yearCount}
                defaultSelectedYear={yearToShowTheNetRevenue}
            ></YearOrDateRangeFilter>
            {options.addPrintButton && options.addPrintButtonId && (
                <div className="print-button-wrapper">
                    <PrintButton printableContainerId={options.addPrintButtonId} context={context}></PrintButton>
                </div>
            )}
            <div id={options.addPrintButtonId}>
                <div className="owner-revenue-streams-detail">
                    {!loading && !!revenueMonths.length && (
                        <TotalNetTurnover revenueMonths={revenueMonths} context={context} startDate={startDate} endDate={endDate} options={options}></TotalNetTurnover>
                    )}
                    <div className="year-list">
                        <div className="year__item">
                            <Input type="checkbox" checked={showPreviousYear} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowPreviousYear(event.target.checked)} />
                            <span>{startDate.year() - 1}</span>
                        </div>
                        <div className="year__item">
                            <Input type="checkbox" checked={showNextYear} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowNextYear(event.target.checked)} />
                            <span>{endDate.year() + 1}</span>
                        </div>
                    </div>
                </div>
                <div className={classNames({ "loading-overlay": loading })}>
                    {loading && <FontAwesome name="spinner" className={"in-progress"} />}
                    {!loading && (noContentFoundWebContentId || noContentFoundTemplateId) && !revenueMonths.length && <NoContentsFound context={context} widgetOptions={options} />}
                    {!loading &&
                        (!!revenueMonths.length || (!noContentFoundWebContentId && !noContentFoundTemplateId)) &&
                        (chartType === "line" ? (
                            <Line options={createChartOptions(languageCode, currencyCode)} data={createLineChartData(allMonthNames, baseChartDataSets)} />
                        ) : (
                            <Bar options={createChartOptions(languageCode, currencyCode)} data={createBarChartData(allMonthNames, baseChartDataSets)} />
                        ))}
                </div>
            </div>
        </div>
    );
}

function createLineChartData(allMonthNames: string[], baseChartDataSets: Array<{ label: string; data: number[]; backgroundColor: string }>): ChartData<"line"> {
    return {
        labels: allMonthNames,
        datasets: baseChartDataSets.map((dataSet) => ({ ...dataSet, fill: false, borderColor: dataSet.backgroundColor })),
    };
}

function createBarChartData(allMonthNames: string[], baseChartDataSets: Array<{ label: string; data: number[]; backgroundColor: string }>): ChartData<"bar"> {
    return {
        labels: allMonthNames,
        datasets: baseChartDataSets,
    };
}

function formatChartPrice(value: React.ReactText, languageCode?: string, currencyCode?: string): string {
    const formatOptions: NumberLocaleFormatOptions = { maximumFractionDigits: 0 };
    if (currencyCode) {
        formatOptions.style = "currency";
        formatOptions.currency = currencyCode;
    }
    return value.toLocaleString(languageCode, formatOptions);
}

function createChartOptions(languageCode?: string, currencyCode?: string): ChartOptions<"bar" | "line"> {
    return {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || "";
                        return `${label}: ${formatChartPrice(context.parsed.y, languageCode, currencyCode)}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (tickValue: React.ReactText, index: number, ticks: Tick[]) => formatChartPrice(tickValue, languageCode, currencyCode),
                },
            },
        },
    };
}

function TotalNetTurnover(props: {
    context: CMSProviderProperties;
    revenueMonths: GenericRevenueMonth[];
    startDate: moment.Moment;
    endDate: moment.Moment;
    options: WidgetOptions;
    percentageOfRevenue?: number;
}) {
    const { revenueMonths, context, startDate, endDate, options, percentageOfRevenue } = props;
    const { revenueType, displayRevenuePercentage, showMinimizedVersionOfRevenueStream, yearToShowTheNetRevenue } = options;
    const totalNetTurnover = revenueMonths
        .filter((revenueMonth) => revenueMonthIsInDateRange(revenueMonth, startDate, endDate))
        .reduce((total: number, revenueMonth: GenericRevenueMonth) => total + ((revenueType === "ownerNet" ? revenueMonth.netTurnover : revenueMonth.totalTurnover) || 0), 0);
    return (
        <div className="owner-revenue-streams-detail__turnover">
            <label className="title">
                {showMinimizedVersionOfRevenueStream
                    ? getI18nLocaleString(namespaceList.ownerRevenueStreamsWidget, "totalNetRevenueTitle")
                    : getI18nLocaleString(namespaceList.ownerRevenueStreamsWidget, "totalNetTurnoverTitle")}
            </label>
            <div className="year-percentage-wrapper">
                <Price amount={Math.round(totalNetTurnover)} context={context} locale={context.currentLocale.code} />
                {showMinimizedVersionOfRevenueStream && !!yearToShowTheNetRevenue && (
                    <React.Fragment>
                        <Badge className="year-badge" pill>
                            {yearToShowTheNetRevenue}
                        </Badge>
                        {displayRevenuePercentage && percentageOfRevenue !== undefined && (
                            <Badge className={`year-percentage ${Math.sign(percentageOfRevenue) > 0 ? "year-percentage-up" : "year-percentage-down"}`} pill>
                                {Math.sign(percentageOfRevenue) > 0 && <TrendingUpRounded className="trending-up" />}
                                {Math.sign(percentageOfRevenue) < 0 && <TrendingDownRounded className="trending-down" />}
                                <span className="percentage-label">
                                    {Math.sign(percentageOfRevenue) > 0
                                        ? `+${percentageOfRevenue}% ${getI18nLocaleString(namespaceList.admin, "since", context.currentLocale)} ${yearToShowTheNetRevenue - 1}`
                                        : `${percentageOfRevenue}% ${getI18nLocaleString(namespaceList.admin, "since", context.currentLocale)} ${yearToShowTheNetRevenue - 1}`}
                                </span>
                            </Badge>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
    return null;
}

function revenueMonthIsInDateRange(revenueMonth: GenericRevenueMonth, startDate: moment.Moment, endDate: moment.Moment, showPreviousYear?: boolean, showNextYear?: boolean): boolean {
    const { year, month } = revenueMonth;
    let updatedStartDate = startDate;
    let updatedEndDate = endDate;
    if (showPreviousYear) {
        updatedStartDate = startDate.clone().subtract(1, "year");
    }
    if (showNextYear) {
        updatedEndDate = endDate.clone().add(1, "year");
    }
    const targetRevenueMonth = moment({ year, month: month - 1 });
    return targetRevenueMonth.isSameOrAfter(updatedStartDate, "month") && targetRevenueMonth.isSameOrBefore(updatedEndDate, "month");
}

async function getOwnerNetRevenue(context: CMSProviderProperties, genericRevenueFilter: GenericRevenueFilter) {
    const env = await getMxtsEnv(context, context.currentLocale?.code);
    // Do a separate call per year so if the user decides to obtain more years it won't have to re-obtain the already obtained years because they are client side cached now.
    return ArrayUtil.flatten2Dimensions(
        await Promise.all(
            genericRevenueFilter.years.map((year) =>
                context.mxtsApi
                    .getOwnerNetRevenue(env, {
                        ...genericRevenueFilter,
                        years: [year],
                    })
                    .catch(() => [])
            )
        )
    ).filter((unitNetRevenueMonth) => unitNetRevenueMonth.netTurnover > 0);
}

async function getUnitRevenue(context: CMSProviderProperties, genericRevenueFilter: GenericRevenueFilter): Promise<UnitRevenueMonth[]> {
    const env = await getMxtsEnv(context, context.currentLocale?.code);
    const revenueCalls: Array<Promise<UnitRevenueMonth[]>> = [];

    // TODO: there is a bug in the mxts endpoint. if you filter on year it leaves out januari... So for now we will obtain all years and filter on the frontend...
    // genericRevenueFilter.unitIds?.forEach((unitId) => {
    //     genericRevenueFilter.years.forEach((year) => {
    //         revenueCalls.push(context.mxtsApi.getUnitRevenue(env, { unitId, year }).catch(() => []));
    //     });
    // });
    genericRevenueFilter.ownerIds?.forEach((ownerId) => {
        revenueCalls.push(context.mxtsApi.getUnitRevenue(env, { ownerId }).catch(() => []));
    });

    return ArrayUtil.flatten2Dimensions(await Promise.all(revenueCalls))
        .filter((unitRevenueMonth: UnitRevenueMonth) => unitRevenueMonth.totalTurnover > 0)
        .filter((revenueMonth) => genericRevenueFilter.years.some((year) => revenueMonth.year === year) && genericRevenueFilter.months.some((month) => month === revenueMonth.month));
}

function getMonthsBetweenDates(startDate: moment.Moment, endDate: moment.Moment) {
    const months: number[] = [];
    const currentMonth = startDate.clone();
    while (currentMonth.isBefore(endDate) || currentMonth.isSame(endDate, "month")) {
        months.push(currentMonth.month() + 1);
        currentMonth.add(1, "month");
    }
    return months;
}

function createBaseChartDataSets(params: {
    allMonthNames: string[];
    visibleYears: number[];
    revenueMonths: GenericRevenueMonth[];
    showNextYear: boolean;
    showPreviousYear: boolean;
    options: WidgetOptions;
    startDate: moment.Moment;
    endDate: moment.Moment;
}) {
    const { revenueMonths, visibleYears, allMonthNames, showPreviousYear, showNextYear, options, startDate, endDate } = params;
    const { revenueType } = options;

    const yearMonthSumMap: { [yearMonth: string]: number } = {};
    revenueMonths.reduce((acc, revenueMonth) => {
        if (revenueMonthIsInDateRange(revenueMonth, startDate, endDate, showPreviousYear, showNextYear)) {
            const yearMonthKey = `${revenueMonth.year}-${revenueMonth.month}`;
            acc[yearMonthKey] = (acc[yearMonthKey] || 0) + ((revenueType === "ownerNet" ? revenueMonth.netTurnover : revenueMonth.totalTurnover) || 0);
        }
        return acc;
    }, yearMonthSumMap);

    return visibleYears.map((year, yearIndex) => ({
        label: `${year}`,
        data: allMonthNames.map((monthName, monthIndex) => Math.round(yearMonthSumMap[`${year}-${monthIndex + 1}`] || 0)),
        backgroundColor: getYearColor(yearIndex, showPreviousYear, options),
    }));
}
