// Later on when we are dealing with the updated version of our friendly url's
// the following shoulw be used to fetch the URL's of those  pages and site
import { DynamicSitemapApi, DynamicSitemapBase } from "@maxxton/cms-api";

import { FormSpec } from "../";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";

export const dynamicSitemapSpec: FormSpec<DynamicSitemapBase> = {
    id: "dynamicSitemap",
    name: getI18nLocaleObject(namespaceList.admin, "dynamicSitemap"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "dynamicSitemaps"),
    api: DynamicSitemapApi,
    permission: "webmanager.dynamicsitemaps",
    display(dynamicSitemap: DynamicSitemapBase): string {
        return dynamicSitemap.name;
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
    ],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "dynamicSitemapEntries"),
                                variable: "dynamicSitemap",
                                type: "textarea",
                            },
                        ],
                        // Site and pagespec, as we need to assign it to one of the sites.
                        // Or the other way around to assign it inside the SITE
                    ],
                },
            ],
        },
    ],
};
