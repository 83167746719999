import * as React from "react";
import * as _ from "lodash";

import { MultiSelectOptions, getMultiSelectOptions } from "../components/utils";

import { ErrorBoundary } from "../components/ErrorBoundary";
import InfoIcon from "@mui/icons-material/Info";
import { SomeInputSpec } from "../form-specs";
import { StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { Tooltip } from "@mui/material";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface AmenityInfoOption {
    showAmenityInfo: boolean;
    amenityFieldMultiSelect?: StringMultiSelectOption[];
}
export interface AmenityCategoryInfoOptions {
    showAmenityCategoryInfo: boolean;
    amenityCategoryFieldMultiSelect?: StringMultiSelectOption[];
}

export type AmenityOptionType = AmenityInfoOption & AmenityCategoryInfoOptions;

export const getAmenityOptions = <T extends AmenityOptionType>(isCategory?: boolean): Array<SomeInputSpec<T, keyof T>> => {
    const showKey = isCategory ? "showAmenityCategoryInfo" : "showAmenityInfo";
    const fieldkey = isCategory ? "amenityCategoryFieldMultiSelect" : "amenityFieldMultiSelect";
    return [
        {
            variable: showKey,
            label: getI18nLocaleObject(namespaceList.amenitiesWidget, showKey),
            type: "checkbox",
        },
        {
            variable: fieldkey,
            label: getI18nLocaleObject(namespaceList.facilityWidget, "contentTypeMultiSelector"),
            type: "multiselect",
            optionList: () => getMultiSelectOptions(),
            visible: (options: T): boolean => options[showKey],
        },
    ];
};

export function renderTooltip(name?: string, description?: string, amenityFieldMultiSelect?: StringMultiSelectOption[] | undefined) {
    const showAmenityContent = {
        title: amenityFieldMultiSelect?.some(({ value }) => value === MultiSelectOptions.TITLE && name),
        description: amenityFieldMultiSelect?.some(({ value }) => value === MultiSelectOptions.DESCRIPTION && description),
    };

    if (!showAmenityContent.title && !showAmenityContent.description) {
        return null;
    }

    return (
        <React.Fragment>
            <ErrorBoundary>
                <Tooltip
                    title={
                        <React.Fragment>
                            {showAmenityContent.title && <label className="title">{name}</label>}
                            {showAmenityContent.description && <div className="description">{description}</div>}
                        </React.Fragment>
                    }
                    PopperProps={{
                        className: "amenity-tooltip-wrapper",
                    }}
                    arrow
                >
                    <InfoIcon fontSize="small" className="amenity-info-icon" />
                </Tooltip>
            </ErrorBoundary>
        </React.Fragment>
    );
}
