import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "corporatehousingfactory",
    name: "Corporate Housing Factory Theme",
    stylesheets: ["/styles/themes/corporatehousingfactory/corporatehousingfactory-admin.css"],
    frontendStylesheets: ["/styles/themes/corporatehousingfactory/corporatehousingfactory.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "secondary-heading-styling",
            name: "Use secondary heading styling",
            targets: ["webcontent"],
            classnames: ["secondary-heading"],
        },
        {
            id: "custom-slider-arrows",
            name: "Arrows custom styling",
            targets: ["slideShow"],
            classnames: ["custom-slider-arrows"],
        },
        {
            id: "custom-slider-bullets",
            name: "Bullets custom styling",
            targets: ["slideShow"],
            classnames: ["custom-slider-bullets"],
        },
    ],
};

export default theme;
