import { CmsApi, Widget as CmsApiWidget, CmsApiWrapper, Locale, Page, Site, SiteGroup, Widget, WithId } from "@maxxton/cms-api";
import { LocalizedFriendlyUrl, LocalizedPageLink, SitemapPageLinkWidgetOptions } from "../plugins/sitemap/sitemap.types";

import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { SelectOption } from "../form-specs/formSpec.types";
import { UrlParamsUtil } from "./urlparam.util";
import { getCMSOptions } from "../plugins/settings";
import { pageLink } from "../routing";

// eslint-disable-next-line max-lines-per-function
export const getSiteMap = async ({ site, cmsApi }: { site: Site & WithId; cmsApi: CmsApiWrapper }) => {
    const sitemapUrls: string[] = [];
    const protocolString: string = site?.hasSecurityCertificate ? "https://" : "http://";
    let shouldAddUrlTrailingSlash: boolean | undefined;
    const cmsSettings = await getCMSOptions(cmsApi);
    if (cmsSettings.enableTrailingSlashRedirectBehavior) {
        shouldAddUrlTrailingSlash = await UrlParamsUtil.shouldAddUrlTrailingSlash(() => Promise.resolve(site));
    }
    function attuneUrl(url: string): string {
        if (shouldAddUrlTrailingSlash) {
            url = UrlParamsUtil.addTrailingSlashToUrl(url);
        } else if (shouldAddUrlTrailingSlash === false) {
            url = UrlParamsUtil.removeTrailingSlashFromUrl(url);
        }
        url = url && UrlParamsUtil.replaceAmpersands(url);
        return UrlParamsUtil.removeParenthesesFromUrl(url);
    }

    const hostUrl = `
    <url>
        <loc>${protocolString}${attuneUrl(`${site?.host}/`)}</loc>
        <priority>1</priority>
    </url>
`;

    // Add host URL first
    sitemapUrls.push(hostUrl);

    const locales: Array<Locale & WithId> = site && (await getSiteLocales(site));

    let sites: Array<Site & WithId> | undefined;
    if (!site?.enableMultiLanguage) {
        sites = (await getSitesInSiteGroup({ site, cmsApi })) || (await cmsApi.siteApi.find({ projection: { sitemap: 0 } }));
    }

    const onlyLocaleIds = locales.map((locale: (Locale & WithId) | null) => locale?._id);

    // Add all friendly URLs with host URL
    site?.sitemap.forEach((sitemapPageLinkWidget: Widget) => {
        const sitemapPageLinkOptions = sitemapPageLinkWidget.options as SitemapPageLinkWidgetOptions;

        const defaultPage =
            !sitemapPageLinkOptions.home && sitemapPageLinkOptions.localizedFriendlyUrl
                ? sitemapPageLinkOptions.localizedFriendlyUrl.find((localizedFriendlyUrl: LocalizedFriendlyUrl) => localizedFriendlyUrl.locale === site.locale._id)
                : undefined;
        if (sitemapPageLinkOptions.home || UrlParamsUtil.urlsEqualIgnoringTrailingSlash(sitemapPageLinkOptions.friendlyUrl, "/404")) {
            return "";
        }

        // Only selecting the valid locales for the Site, for Xml generation
        const filteredLocalizedFriendlyUrls: LocalizedFriendlyUrl[] = [];
        if (sitemapPageLinkOptions.localizedFriendlyUrl) {
            sitemapPageLinkOptions.localizedFriendlyUrl.forEach((localizedFriendlyUrl: LocalizedFriendlyUrl) => {
                const index = onlyLocaleIds.indexOf(localizedFriendlyUrl.locale);
                filteredLocalizedFriendlyUrls[index] = localizedFriendlyUrl;
            });
        }

        const siteUrl: string = !site.enableMultiLanguage
            ? `
    <url>
        <loc>${protocolString}${attuneUrl(`${site.host}${sitemapPageLinkOptions.friendlyUrl}`)}</loc>
        ${
            sitemapPageLinkOptions.localized && onlyLocaleIds.length > 1
                ? sitemapPageLinkOptions.localized.map(async (localizedPageLink: LocalizedPageLink) => {
                      const locale = locales.find((locale: Locale & WithId) => locale._id === localizedPageLink.locale);
                      const foundSite: (Site & WithId) | undefined = sites ? sites.find((site: Site & WithId) => site._id === localizedPageLink.siteId) : undefined;
                      const foundPageLink: string | undefined = foundSite && (await pageLink({ site: foundSite, pageId: localizedPageLink.pageId }));
                      return locale && foundSite && foundPageLink
                          ? `
                <xhtml:link
                           rel="alternate"
                           hreflang="${locale.isoCode || locale.code}"
                           href="${protocolString}${attuneUrl(`${foundSite.host}${foundPageLink}`)}"/>`
                          : "";
                  })
                : ""
        }
        <priority>1</priority>
    </url>
    `
            : `
    <url>
        <loc>${protocolString}${attuneUrl(`${site?.host}${defaultPage ? defaultPage.friendlyUrl : "/"}`)}</loc>
        ${
            filteredLocalizedFriendlyUrls?.length > 1
                ? filteredLocalizedFriendlyUrls.map((localizedFriendlyUrl: LocalizedFriendlyUrl) => {
                      const index = onlyLocaleIds.indexOf(localizedFriendlyUrl.locale);
                      return locales?.[index] && localizedFriendlyUrl.friendlyUrl
                          ? `
                <xhtml:link
                           rel="alternate"
                           hreflang="${locales?.[index]?.isoCode || locales?.[index]?.code || ""}"
                           href="${protocolString}${attuneUrl(`${site?.host}${localizedFriendlyUrl.friendlyUrl}`)}"/>`
                          : "";
                  })
                : ""
        }
        <priority>1</priority>
    </url>
    `;
        sitemapUrls.push(siteUrl.split(",").join(""));
    });

    // TODO Handle using HREFLANG - CMS-12213
    const sitemapxml = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
            xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
            xmlns:xhtml="http://www.w3.org/2001/xhtml"
            xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
        ${sitemapUrls.join("")}
    </urlset>
`;
    return sitemapxml;
};

export const getSiteLocales = async (siteData: Site, isolatedCacheApi: CmsApiWrapper = CmsApi) => {
    let locales: Array<Locale & WithId> = [];
    // Only finding allowed languages for the site
    const allowedLanguages = siteData?.localeMultiSelect;

    if (allowedLanguages?.length) {
        const localePromises: Array<Promise<(Locale & WithId) | null>> = [];
        for (const lang of allowedLanguages) {
            localePromises.push(isolatedCacheApi.localeApi.findById({ id: lang.value }));
        }
        const localesIncludingNulls: Array<(Locale & WithId) | null> = await Promise.all(localePromises);
        locales = localesIncludingNulls.filter((locale) => locale !== null) as Array<Locale & WithId>;
    } else {
        locales = (await isolatedCacheApi.localeApi.find()) || [];
    }
    return locales;
};

export const getSiteOptions = async (): Promise<Array<SelectOption<string>>> => {
    const sites = (await CmsApi.siteApi.find({ projection: { sitemap: 0 } })) || [];
    let siteOptions: Array<SelectOption<string>> = [];
    if (sites.length) {
        siteOptions = sites.map((site: Site & WithId) => ({ value: site._id, label: site.name }));
    }
    return siteOptions;
};

export const getSiteWithId = async ({ site, cmsApi }: { site: Site; cmsApi: CmsApiWrapper }): Promise<(Site & WithId) | null> => {
    const foundSite: Array<Site & WithId> = await cmsApi.siteApi.find({ query: { name: `${site.name}` }, projection: { sitemap: 0 } });
    const siteData = foundSite.length ? await cmsApi.siteApi.findById({ id: foundSite[0]._id, projection: { sitemap: 0 } }) : null;
    return siteData || null;
};

export const getSiteGroup = async ({ site, cmsApi }: { site: Site; cmsApi: CmsApiWrapper }): Promise<(SiteGroup & WithId) | null> => {
    const siteWithId: (Site & WithId) | null = await getSiteWithId({ site, cmsApi });
    const siteGroups: Array<SiteGroup & WithId> = await cmsApi.siteGroupApi.find();
    return siteGroups?.find((group: SiteGroup & WithId) => siteWithId && group.sites.includes(siteWithId._id)) || null;
};

export const getSitesInSiteGroup = async ({ site, cmsApi }: { site: Site; cmsApi: CmsApiWrapper }): Promise<Array<Site & WithId> | null> => {
    const siteWithId: (Site & WithId) | null = await getSiteWithId({ site, cmsApi });
    const siteGroups: Array<SiteGroup & WithId> = await cmsApi.siteGroupApi.find();
    const currentSiteGroup: (SiteGroup & WithId) | null = (siteWithId && siteGroups?.find((group: SiteGroup & WithId) => group.sites.includes(siteWithId._id))) || null;
    if (currentSiteGroup?.sites.length) {
        const sitesInCurrentSiteGroup: Array<Site & WithId> = await cmsApi.siteApi.findMany({ ids: currentSiteGroup?.sites });
        return sitesInCurrentSiteGroup.length ? sitesInCurrentSiteGroup : null;
    }
    return null;
};

export const getSiteInSiteGroupByLocale = async ({ site, cmsApi, localeId }: { site: Site; cmsApi: CmsApiWrapper; localeId: string }): Promise<(Site & WithId) | null> => {
    const sitesInSiteGroup: Array<Site & WithId> | null = await getSitesInSiteGroup({ site, cmsApi });
    const siteWithThisDefaultLocale: (Site & WithId) | null = sitesInSiteGroup?.find((site) => site.locale._id === localeId) || null;
    return siteWithThisDefaultLocale;
};

export const getCurrentPageWidget = async (context: CMSProviderProperties): Promise<CmsApiWidget | undefined> => {
    const { site } = context;
    let pageWidget: CmsApiWidget | null | undefined = null;
    pageWidget = context.location.pathname ? await context.cmsApi.siteApi.findSitemapFromSiteByFriendlyUrl({ siteId: site._id, url: context.location.pathname }) : undefined;
    return pageWidget || undefined;
};

export const getCurrentPage = async (context: CMSProviderProperties): Promise<Page | undefined> => {
    let currentPage: Page | null | undefined = null;
    const pageWidget: CmsApiWidget | undefined = await getCurrentPageWidget(context);
    if (pageWidget?.options?.pageId) {
        currentPage = await context.cmsApi.pageApi.findById({ id: pageWidget.options.pageId });
    }
    return currentPage || undefined;
};

export function checkPageWithRobotFile(pathName: string, robotFile: string): boolean {
    const fileContent = robotFile?.split("\n");
    const disAllowContent = fileContent?.map((content) => {
        const [type, pathName] = content.split(" ");
        if (
            type
                .toLocaleLowerCase()
                .trim()
                .match(/^disallow:$/)
        ) {
            return pathName;
        }
    });
    return !!disAllowContent?.some((url) => url === pathName.replace(/\/$/, ""));
}

export function isTestPage(url: string) {
    const regex = /test/i;
    return regex.test(url);
}
