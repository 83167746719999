import * as React from "react";

import { ActivePageManager, MxtsApiCache, MxtsDataAccessToken, getMxtsDataAccessTokenManager, globalMxtsApiCache } from "@maxxton/cms-mxts-api";
import { ActivityPlannerAction, ActivityPlannerActionType } from "../redux/actions/activityPlannerAction";
import { CMSProvidedProperties, CMSProviderProperties } from "../containers/cmsProvider.types";
import { CachedResponse, Category, CmsApiCache, CmsApiWrapper, Menu, Page, Site, WithId, globalCmsApiCache } from "@maxxton/cms-api";
import { MenuWidgetSpec, Widget, initDefaultFilterForPageApiWidgets, parseApiWidget, renderPageWidgets } from "../plugins/widget";
import { MxtsDataAccesTokenAction, MxtsDataAccesTokenActionType } from "../redux/actions/MxtsDataAccessTokenAction";
import { merge as _merge, filter, isEqual, keys } from "lodash";
import { getDynamicStore, getInitialStore } from "../redux";

import { ActionType } from "../redux/actions";
import { ContextUtil } from "../utils/context.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { FilterChangeAction } from "../redux/actions/dynamicFilterAction.types";
import { PageView } from "../components/Page";
import { PathComponent } from "./routing.types";
import { PrefetchAppParams } from "../app.types";
import { SitemapPageLinkWidgetOptions } from "../plugins/sitemap/sitemap.types";
import { dispatchEmptyAction } from "../components/utils";
import { dynamicFilterType } from "../redux/reducers/dynamicFilter.enum";
import { getValidMyEnvAuthToken } from "../utils/authToken.util";
import { injectPropIntoElementTrees } from "../plugins/resultsPanel/resultsPanelUtil";
import { isClientSide } from "../utils/generic.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../utils/loadableComponents.util";
import { pageLink } from "./index";
import { reportError } from "../utils/report.utils";

// TODO: this method might be removed in the future. (because we might also mark pages as gem page without it being in a specific category?)
export const isGemPage = async (page: Page, cmsApi: CmsApiWrapper): Promise<boolean> => {
    if (page.category?.length) {
        const categories: Category[] = await cmsApi.categoryApi.find({ query: { categoryId: { $in: page.category.filter((id: number) => id) } }, projection: { name: 1 } });
        // TODO improve this.
        if (categories?.some((category) => category.name?.toLowerCase().startsWith("my") || category.name?.toLowerCase().startsWith("mijn"))) {
            return true;
        }
    }
    return false;
};

export async function isGemAuthenticated(): Promise<boolean> {
    if (isClientSide()) {
        return !!(await getValidMyEnvAuthToken());
    }
    return false;
}

export const isActivityPlannerPage = async (page: Page, cmsApi: CmsApiWrapper): Promise<boolean> => {
    if (page.category?.length) {
        const categories: Category[] = await cmsApi.categoryApi.find({ query: { categoryId: { $in: page.category.filter((id: number) => id) } }, projection: { name: 1 } });
        if (categories?.some(({ name }) => name && ["activity", "activities", "activiteiten"].some((prefix) => name.toLowerCase().startsWith(prefix)))) {
            return true;
        }
    }
    return false;
};

export const fetchItems = async ({ context, widgetOptions, menu }: { context: CMSProvidedProperties; widgetOptions: SitemapPageLinkWidgetOptions | undefined; menu: Menu & WithId }) => {
    const sites: Array<Site & WithId> = await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
    const items = await Promise.all(
        menu.children
            .filter((item) => item !== null && !item.options.hidden)
            .map(async (menuItem, index) => {
                const menuWidget: Widget<any> = await parseApiWidget(menuItem, context);
                const childSpec = menuWidget.spec;
                return (
                    (childSpec as MenuWidgetSpec<any>)
                        .render(menuWidget, context, sites, widgetOptions)
                        .then((elem) => <ErrorBoundary key={menuWidget._id}>{elem}</ErrorBoundary>)
                        // silent the failure of the widget and report error in logstash
                        .catch((error) => {
                            reportError(error);
                            return <div key={index} />;
                        })
                );
            })
    );
    return items;
};

/**
 * This will fill the cms and mxts api cache with the cache data that the server already prepared for this page.
 */
export async function loadPageApiCaches(pageUrl: URL): Promise<void> {
    if (location.pathname.startsWith("/webmanager/")) {
        return;
    }
    const uri = `${pageUrl.hostname}${pageUrl.pathname}`;

    const cmsConfig: any = (window as any).cmsConfig;

    await Promise.all([
        (async () => {
            let shouldUseRenderHash = true;
            if (isClientSide()) {
                const serverUrl = new URL((window as any).pageUrl);
                const currentUrl = new URL(location.href);
                if (serverUrl.hostname === currentUrl.hostname && serverUrl.pathname !== currentUrl.pathname) {
                    shouldUseRenderHash = false;
                }
            }
            const url = `${cmsConfig.web?.cdnBaseUrl || (pageUrl.hostname !== location.hostname ? `https://${pageUrl.hostname}` : "")}/page-cms-api-cache/${
                shouldUseRenderHash ? (window as any).renderHash : ""
            }?pageUrl=${uri}`;
            const resp = await fetch(url);
            if (!resp.ok) {
                throw new Error(`Failed to GET ${url}, responsed with ${resp.status}`);
            }
            const pageCmsApiCacheString = await resp.text();
            const pageCmsApiCacheMap: Map<string, Map<string, CachedResponse>> | null = CmsApiCache.parseEntireCache(pageCmsApiCacheString);
            if (pageCmsApiCacheMap) {
                globalCmsApiCache.setEntireCache(pageCmsApiCacheMap);
            }
        })().catch((e) => {
            console.error("Failed to load page-cms-api-cache", e);
        }),
        (async () => {
            // mxts-api-cache is dynammic and should therefor not be fetched from the CDN
            const url = `${pageUrl.hostname !== location.hostname ? `https://${pageUrl.hostname}` : ""}/page-mxts-api-cache?pageUrl=${uri}`;
            const resp = await fetch(url);
            if (!resp.ok) {
                throw new Error(`Failed to GET ${url}, responsed with ${resp.status}`);
            }
            const pageMxtsApiCacheString = await resp.text();
            const pageMxtsApiCacheMap: Map<string, CachedResponse> | null = MxtsApiCache.parseEntireCache(pageMxtsApiCacheString);
            if (pageMxtsApiCacheMap) {
                globalMxtsApiCache.setEntireCache(pageMxtsApiCacheMap);
            }
        })().catch((e) => {
            console.error("Failed to load page-mxts-api-cache", e);
        }),
    ]);
}

async function loadInitialDynamicFilterFields(context: CMSProvidedProperties): Promise<Partial<DynamicFilter>> {
    const currentFlow = await ContextUtil.getCurrentFlow(context);
    if (currentFlow) {
        return { includeMissingPrices: true, dateMargin: undefined };
    }
    return {};
}

async function handleReduxStoreOnNavigation(context: CMSProvidedProperties): Promise<DynamicFilter> {
    if (isClientSide()) {
        const reduxStore = await getDynamicStore(window.location, context.currentLocale, context.site, context, context.distributionChannelId?.toString(), context.reservationCategoryId, context.flow);
        const oldReduxStore = { ...context.reduxStore.store.getState() };
        const oldDynamicFilterObj = { ...oldReduxStore.dynamicFilter };
        const ownerState = oldReduxStore.myEnvState.ownerState;
        const newDynamicFilterObj =
            ActivePageManager.get()?.getPageCategory() === "myEnv" && ownerState?.ownerDistributionChannel && ownerState.ownerRateType && ownerState.ownerReservationCategoryId
                ? {
                      ...reduxStore.initialState?.dynamicFilter,
                      distributionChannel: ownerState.ownerDistributionChannel,
                      currency: oldDynamicFilterObj.currency,
                      rateType: ownerState.ownerRateType,
                      reservationCategoryId: ownerState.ownerReservationCategoryId,
                  }
                : {
                      ...reduxStore.initialState?.dynamicFilter,
                      distributionChannel: oldDynamicFilterObj.distributionChannel,
                      currency: oldDynamicFilterObj.currency,
                      rateType: oldDynamicFilterObj.rateType,
                      reservationCategoryId: oldDynamicFilterObj.reservationCategoryId || context.reservationCategoryId,
                  };
        const initialDynamicFilters = await loadInitialDynamicFilterFields(context);
        let dynamicFilterObj = { ...newDynamicFilterObj, ...initialDynamicFilters };
        if (oldDynamicFilterObj.stateUuid && newDynamicFilterObj.stateUuid) {
            dynamicFilterObj = _merge({}, oldDynamicFilterObj, newDynamicFilterObj);
            const currentFlow = await ContextUtil.getCurrentFlow(context);
            if (currentFlow) {
                dynamicFilterObj.includeMissingPrices = true;
                dynamicFilterObj.dateMargin = undefined;
            }
        }
        if (oldReduxStore.mxtsDataAccessTokenState) {
            getMxtsDataAccessTokenManager()?.applyDataAccessKeysFromLocalStorage();
            const token = getMxtsDataAccessTokenManager()?.getToken();
            if (token?.accessKeys.length) {
                const mxtsDataAccessTokenUpdateAction: MxtsDataAccesTokenAction = {
                    type: ActionType.MxtsDataAccessToken,
                    actionType: MxtsDataAccesTokenActionType.update,
                    payload: token,
                };
                context.reduxStore.store.dispatch(mxtsDataAccessTokenUpdateAction);
            }
        }
        if (reduxStore.initialState && oldDynamicFilterObj.stateUuid !== newDynamicFilterObj.stateUuid) {
            reduxStore.initialState.dynamicFilter = dynamicFilterObj;
            context.reduxStore = await getInitialStore(reduxStore.initialState, context);
        }
        getMxtsDataAccessTokenManager()?.setOnTokenChangeCb((token: MxtsDataAccessToken) => {
            const mxtsDataAccessTokenUpdateAction: MxtsDataAccesTokenAction = {
                type: ActionType.MxtsDataAccessToken,
                actionType: MxtsDataAccesTokenActionType.update,
                payload: token,
            };
            context.reduxStore.store.dispatch(mxtsDataAccessTokenUpdateAction);
        });
        if (oldDynamicFilterObj.stateUuid !== newDynamicFilterObj.stateUuid && oldReduxStore.activityPlannerState) {
            const action: ActivityPlannerAction = {
                type: ActionType.activityPlanner,
                actionType: ActivityPlannerActionType.UPDATE,
                payload: oldReduxStore.activityPlannerState,
            };
            context.reduxStore.store.dispatch(action);
        }
        context.reduxStore.store.dispatch({
            type: ActionType.FilterChange,
            filter: dynamicFilterType.setDynamicFilters,
            payload: dynamicFilterObj,
        });
        return dynamicFilterObj;
    }
    return {};
}

export async function initDefaultFilterWithDispatcher(context: CMSProvidedProperties, page: (Page & WithId) | null) {
    const initialDynamicFilter: DynamicFilter = await handleReduxStoreOnNavigation(context);
    const mergedFilterChangeInitPayload: DynamicFilter = { ...initialDynamicFilter };
    let hasFilterChange = false;
    const initDispatcher = (action: FilterChangeAction) => {
        const changedKeys = filter(keys(initialDynamicFilter), (key: keyof DynamicFilter) => !isEqual(initialDynamicFilter[key], action.payload[key])).filter((changedKey) =>
            Object.keys(action.payload).includes(changedKey)
        );
        const newKeys = Object.keys(action.payload).filter((payloadKey) => !Object.keys(initialDynamicFilter).includes(payloadKey));
        changedKeys.concat(newKeys).forEach((key: keyof DynamicFilter) => (mergedFilterChangeInitPayload[key] = action.payload[key]));

        hasFilterChange = true;
        return context.reduxStore.store.dispatch({
            ...action,
            payload: mergedFilterChangeInitPayload,
            type: ActionType.FilterChangeInit,
        });
    };
    if (page?.root) {
        await initDefaultFilterForPageApiWidgets(page.root, context, initDispatcher as any);
        const isAPPage = await isActivityPlannerPage(page, context.cmsApi);
        if (hasFilterChange && !isAPPage) {
            dispatchEmptyAction(context.reduxStore.store.dispatch);
        }
    }
}

// eslint-disable-next-line max-lines-per-function
export const getRouteForPage = (pageParams: {
    home: boolean;
    isDefaultLocale: boolean;
    presentLocale: string;
    friendlyUrl: string;
    pageId: string;
    context: CMSProvidedProperties;
    widget: Widget<SitemapPageLinkWidgetOptions>;
    isLocalized?: boolean;
    deviceType?: PrefetchAppParams["device"];
}) => ({
    path: pageParams.home
        ? `/${pageParams.isDefaultLocale ? "" : pageParams.presentLocale}`
        : `${pageParams.isDefaultLocale || pageParams.isLocalized ? "" : "/" + pageParams.presentLocale}${pageParams.friendlyUrl}`,
    end: true,
    // eslint-disable-next-line max-lines-per-function
    async load(): Promise<PathComponent | false> {
        const { isDefaultLocale, presentLocale, friendlyUrl, pageId, context, widget, deviceType } = pageParams;
        const parsedFriendlyUrl = pageParams.home
            ? `/${pageParams.isDefaultLocale ? "" : pageParams.presentLocale}`
            : `${pageParams.isDefaultLocale || pageParams.isLocalized ? "" : "/" + pageParams.presentLocale}${pageParams.friendlyUrl}`;
        if (isClientSide() && localStorage.getItem("pageNavigation")?.length) {
            await loadPageApiCaches(new URL("https://" + context.site.host + parsedFriendlyUrl));
        }
        const site: Site & WithId = context.site;
        const page: (Page & WithId) | null = await context.cmsApi.pageApi.findById({ id: pageId });
        const redirectLanguagePrefix = `${isDefaultLocale ? "" : "/" + presentLocale}`;
        if (site && site.maintenanceMode && friendlyUrl !== `${redirectLanguagePrefix}/maintenance`) {
            if (isClientSide()) {
                window.location.replace(`${redirectLanguagePrefix}/maintenance`);
            }
            return () => <div />;
        }
        if (site && !site.maintenanceMode && friendlyUrl === `${redirectLanguagePrefix}/maintenance`) {
            if (isClientSide()) {
                window.location.replace(`/${isDefaultLocale ? "" : presentLocale}`);
            }
            return () => <div />;
        }
        if (page == null) {
            // eslint-disable-next-line no-console
            console.log("Page could not be found");
            if (isClientSide()) {
                window.location.replace(`${redirectLanguagePrefix}/404`);
            }
            return () => <div />;
        }
        const isMyEnvPage = await isGemPage(page, context.cmsApi);
        const isAPPage = await isActivityPlannerPage(page, context.cmsApi);
        ActivePageManager.get()?.setPageCategory(isMyEnvPage ? "myEnv" : isAPPage ? "ap" : "bm");
        if (isMyEnvPage && !(await isAlreadyOnLoginPage(context)) && !(await isGemAuthenticated())) {
            return getLoginPageRedirect(context);
        }
        if (page.enableRedirect && page.pageId && page.siteId) {
            const redirectSite: Site | null = page.siteId === site._id ? site : await context.cmsApi.siteApi.findById({ id: page.siteId, projection: { sitemap: 0 } });
            if (redirectSite && isClientSide()) {
                const pathname = await pageLink({ site, pageId: page.pageId, locale: context.currentLocale, context });
                const search = window.location.search;
                const toUrl = pathname + search;
                window.location.replace(toUrl);
            }
            return () => <div />;
        }
        let items: JSX.Element[] = [];
        let enable = false;

        let menu: (Menu & WithId) | null = null;
        if (deviceType?.isMobile) {
            enable = site.ismobile ? true : false;
        } else if (deviceType?.isTablet) {
            enable = site.isipad ? true : false;
        } else if (site.isdesktop) {
            enable = true;
        }
        let sideBarMenu: any;
        if (enable) {
            sideBarMenu = await loadable(() => loadableRetry(() => import(/* webpackChunkName: "react-burger-menu" */ "react-burger-menu")), {
                resolveComponent: (reactBurgerMenu) => {
                    if (site) {
                        switch (site.animation) {
                            case "slide":
                                return reactBurgerMenu.slide;
                            case "stack":
                                return reactBurgerMenu.stack;
                            case "fallDown":
                                return reactBurgerMenu.fallDown;
                            case "elastic":
                                return reactBurgerMenu.elastic;
                            case "bubble":
                                return reactBurgerMenu.bubble;
                            case "push":
                                return reactBurgerMenu.push;
                            case "pushRotate":
                                return reactBurgerMenu.pushRotate;
                            case "scaleDown":
                                return reactBurgerMenu.scaleDown;
                            case "scaleRotate":
                                return reactBurgerMenu.scaleRotate;
                            case "reveal":
                                return (reactBurgerMenu as any).reveal; // converted to any because the type checker doens't seem to understand reveal is there
                        }
                    }
                    return reactBurgerMenu.push;
                },
            });

            // const reactBurgerMenu = loadable(() => import(/* webpackChunkName: "react-burger-menu" */ "react-burger-menu"))
            // const reactBurgerMenu = await import(/* webpackChunkName: "react-burger-menu" */ "react-burger-menu");
            // sideBarMenu = reactBurgerMenu.push;
            const { menuId } = site;
            if (menuId && menuId != null) {
                menu = menuId != null ? await context.cmsApi.menuApi.findById({ id: menuId }) : null;
                if (menu) {
                    items = await fetchItems({ context, widgetOptions: widget.options, menu });
                }
            }
        }

        // WARNING: executing any redux dispatches before handleReduxStoreOnNavigation is called could cause redux state from the previous page to end up in the new page...
        await initDefaultFilterWithDispatcher(context, page);
        const pageLinkUrl = page.pageId ? await pageLink({ site, pageId: page.pageId, locale: context.currentLocale, context }) : "";
        let children = await renderPageWidgets(page.root, context, widget.options);
        if (ActivePageManager.get()?.getPageCategory() === "myEnv") {
            children = injectPropIntoElementTrees(children, { isMyEnvWidget: true });
        } else if (ActivePageManager.get()?.getPageCategory() === "ap") {
            children = injectPropIntoElementTrees(children, { isActivityPlannerWidget: true });
        }
        return (props) => (
            <PageView
                enable={enable}
                SideBarMenu={sideBarMenu}
                menu={menu}
                page={page}
                pageLink={pageLinkUrl}
                children={children}
                context={context}
                updateTitle
                sideBarMenuItems={items}
                widgetOptions={widget.options}
            />
        );
    },
});

const getLoginPageRedirect = async (context: CMSProviderProperties) => {
    const loginPageRedirectUrl = await getLoginPageRedirectUrl(context);
    if (!loginPageRedirectUrl) {
        return false;
    }
    if (isClientSide()) {
        window.location.replace(loginPageRedirectUrl);
    }
    return () => <div />;
};

export async function getLoginPageRedirectUrl(context: CMSProviderProperties): Promise<string | undefined> {
    const currentLocaleId = context.currentLocale.locale;
    const { localizedLoginPageLink, setupLoginPage } = context.site;
    const localizedLoginPage = localizedLoginPageLink?.find((pageLink) => pageLink.locale === currentLocaleId);
    const { loginSiteId, loginPageId } = localizedLoginPage || {};
    if (!setupLoginPage || !loginSiteId || !loginPageId) {
        return undefined;
    }
    let siteLoginPageUrl = "";
    const site: (Site & WithId) | null = context.site?._id === loginSiteId ? context.site : await context.cmsApi.siteApi.findById({ id: loginSiteId, projection: { sitemap: 0 } });
    siteLoginPageUrl = siteLoginPageUrl.concat("//");
    if (site) {
        siteLoginPageUrl = siteLoginPageUrl.concat(site.host);
        const url = (await pageLink({ site, pageId: loginPageId, locale: context.currentLocale, context })) || "";
        siteLoginPageUrl = siteLoginPageUrl.concat(url ? url : "");
        return siteLoginPageUrl;
    }
}

async function isAlreadyOnLoginPage(context: CMSProviderProperties) {
    const loginPageRedirectUrl = await getLoginPageRedirectUrl(context);
    return isClientSide() && !!loginPageRedirectUrl?.endsWith(window.location.host + window.location.pathname);
}
