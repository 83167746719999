import * as moment from "moment";

export const huLocaleSpecification: moment.LocaleSpecification = {
    calendar: {
        sameDay: "[ma] LT[-kor]",
        nextDay: "[holnap] LT[-kor]",
        lastDay: "[tegnap] LT[-kor]",
        sameElse: "L",
    },
    longDateFormat: {
        LTS: "H:mm:ss",
        LT: "H:mm",
        L: "YYYY.MM.DD.",
        LL: "YYYY. MMMM D.",
        LLL: "YYYY. MMMM D. H:mm",
        LLLL: "YYYY. MMMM D., dddd H:mm",
    },
    invalidDate: "Invalid date",
    ordinal: () => "%d.",
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    relativeTime: {
        future: "%s múlva",
        past: "%s",
        w: "a week",
        ww: "%d weeks",
    },
    months: ["január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"],
    monthsShort: ["jan.", "feb.", "márc.", "ápr.", "máj.", "jún.", "júl.", "aug.", "szept.", "okt.", "nov.", "dec."],
    week: {
        dow: 1,
        doy: 4,
    },
    weekdays: ["vasárnap", "hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat"],
    weekdaysMin: ["v", "h", "k", "sze", "cs", "p", "szo"],
    weekdaysShort: ["vas", "hét", "kedd", "sze", "csüt", "pén", "szo"],
    monthsParseExact: true,
    dayOfMonthOrdinalParseLenient: {},
};
