/* eslint-disable no-case-declarations */
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as moment from "moment";

import { ApiContext, CMSProvidedProperties, CMSProviderProperties } from "../containers/cmsProvider.types";
import { AvailabilityAction, AvailabilityActionType } from "../redux/actions/availabilityAction";
import { AvailabilityResultWithFilter, AvailabilityState } from "../redux/reducers/availability.types";
import { DATE_FORMAT, LOCAL_STORAGE_KEYS, MXTS as MXTS_CONSTANTS } from "../utils/constants";
import { FormSpec, InputSpecAuto, InputSpecMulti, InputSpecSelect, InputSpecSimple, SomeInputSpec, localized } from "../form-specs";
import { FreeSearchData, RegionWithChildRegions } from "../plugins/dynamic/free-search/dynamicFreeSearch.types";
import { I18nLocaleObject, getI18nLocaleObject, getI18nLocaleString, getI18nLocaleStringFromParams } from "../i18n";
import { Locale, LocaleApi, Site, SiteApi, SiteGroupApi, TemplateApi, WebContent, WebContentApi, WithId } from "@maxxton/cms-api";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { PaymentMethodBrands, PaymentMethodTypes } from "../plugins/dynamic/payment-widgets/payment-methods";
import { isEqual as _isEqual, some } from "lodash";
import { getAdminMxtsEnv, getMxtsEnv } from "../plugins/mxts";
import { getAvailability, getBaseFilter } from "../utils/availability.util";
import { getElasticSubjectFilter, getPetCapacity } from "../utils/searchFacet.utils";
import { reportError, reportWarn } from "../utils/report.utils";
import { resourcesAutocomplete, unitsAutocomplete } from "../inputSpecs";

import { ActionType } from "../redux/actions";
import { AddOnsWidgetOptions } from "../plugins/dynamic/add-ons/AddOns.types";
import { ContentType } from "./components.enum";
import { WidgetOptions as DescriptionWidgetProps } from "../plugins/dynamic/description";
import { Dispatch } from "redux";
import { WidgetOptions as DynamicContainerOptions } from "../plugins/dynamic/container/container.types";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { FilterChangeAction } from "../redux/actions/dynamicFilterAction.types";
import { WidgetOptions as ImageGalleryWidgetOptions } from "../plugins/page/image-gallery";
import { MyEnvState } from "../redux/reducers/myEnv/myEnvState";
import { PaymentStatuses } from "../plugins/dynamic/payment-widgets/dynamic-status/paymentStatus.enum";
import { SelectOption } from "../form-specs/formSpec.types";
import { SelectOptionLazyLoadResponse } from "./generic-form/LazyLoadAutoComplete";
import { Sort } from "../plugins/mxts/searchfacet/searchFacet.enum";
import { WidgetOptions as USPAmenityWidgetOptions } from "../plugins/dynamic/uspAmenities";
import { WidgetOptions } from "../plugins/dynamic/free-search";
import { dynamicFilterType } from "../redux/reducers/dynamicFilter.enum";
import { getCMSOptions } from "../plugins/settings";
import { getImages } from "./media/mxts-image-gallery/mxts";
import { isClientSide } from "../utils/generic.util";
import namespaceList from "../i18n/namespaceList";
import { removeUserLoggedInCookie } from "../utils/authToken.util";
import { renderPageWidgets } from "../plugins";

interface Property {
    required: boolean;
    variable: string;
    label: I18nLocaleObject | string;
}

interface FreeSearchOption {
    label: I18nLocaleObject | string;
    value: number;
}

interface Language {
    name: string;
    code: string;
}

export interface WidgetOptionsMap {
    "ImageGalleryWidgetProps": ImageGalleryWidgetOptions;
    "USPAmenityWidgetProps": USPAmenityWidgetOptions;
    "DescriptionWidgetProps": DescriptionWidgetProps;
}

export interface ServerSideProps<T extends keyof WidgetOptionsMap> {
    context: CMSProvidedProperties;
    options: WidgetOptionsMap[T];
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    dynamicContainerOptions?: DynamicContainerOptions;
    myEnvState?: MyEnvState;
}

export const allSites: Array<Site & WithId> = [];

// Function to make sure item and its child items do not
// have mongo DB timestamp and duplicate _id (used for cloning purpose)
export function sanitizeWidgetItems(item: any): any {
    if ("updatedAt" in item) {
        delete (item as any).updatedAt;
    }
    if ("_id" in item) {
        delete (item as any)._id;
    }
    if ("createdAt" in item) {
        delete (item as any).createdAt;
    }
    if ("options" in item) {
        const options = (item as any).options;
        if ("updatedAt" in options) {
            delete (item as any).options.updatedAt;
        }
        if ("createdAt" in options) {
            delete (item as any).options.createdAt;
        }
        if ("_id" in options) {
            delete (item as any).options._id;
        }
    }
    if ("item" in item) {
        sanitizeWidgetItems((item as any).item);
    }
    if ("children" in item && (item as any).children && (item as any).children.length > 0) {
        (item as any).children.forEach((key: any) => {
            sanitizeWidgetItems(key);
        });
    }
    return item;
}

export const RESORT = "resort";
export const ACCOMMODATION_TYPE = "accoType";
export const ACCOMMODATION_KIND = "accoKind";
export const UNIT = "unit";
export const UNIT_TYPE = "accommodation";
export const ACTIVITY = "ACTIVITY";
export const TIPS_AND_TRIPS = "tipsAndTrips";
export const RESERVATION = "reservation";
export const AMENITY = "amenity";
export const REGION = "region";
export const ALL = -1;
export const NAME = 0;
export const DESCRIPTION = 1;
export const SHORT_DESCRIPTION = 2;
export const DESCRIPTION2 = 3;
export const DYNAMIC_FIELD_CODE = 4;
export const TITLE = 5;
export const TIME = 6;
export const LOCATION = 7;
export const SIGN_UP = 8;
export const RESORTS = 9;
export const HEAD_TEXT = 10;
export const CUSTOM_FIELD = 11;
export const TEXT = 12;
export const DATE = 13;
export const CATEGORY = 14;
export const ACTIVITY_SUBJECT = 15;

export const NONE = getI18nLocaleObject(namespaceList.widgetWebContent, "none");
export const I18nRESORT = getI18nLocaleObject(namespaceList.dynamicPlugin, "resort");
export const I18nAMENITY = getI18nLocaleObject(namespaceList.dynamicPlugin, "amenity");
export const I18nREGION = getI18nLocaleObject(namespaceList.dynamicPlugin, "region");
export const I18nACCOMMODATIONTYPE = getI18nLocaleObject(namespaceList.dynamicPlugin, "accoType");
export const I18nACCOMMODATIONKIND = getI18nLocaleObject(namespaceList.dynamicPlugin, "accoKind");
export const I18nUNIT = getI18nLocaleObject(namespaceList.dynamicPlugin, "unit");
export const I18nFACILITY = getI18nLocaleObject(namespaceList.dynamicPlugin, "facility");
export const I18nACTIVITY = getI18nLocaleObject(namespaceList.dynamicPlugin, "activity");
export const I18N_TIPS_AND_TRIPS = getI18nLocaleObject(namespaceList.dynamicPlugin, "tipsAndTrips");
export const I18N_RESERVATION = getI18nLocaleObject(namespaceList.dynamicPlugin, "reservation");

export const I18N_NAME = getI18nLocaleObject(namespaceList.dynamicPlugin, "name");
export const I18N_DESCRIPTION = getI18nLocaleObject(namespaceList.dynamicPlugin, "description");
export const I18N_SHORTDESCRIPTION = getI18nLocaleObject(namespaceList.dynamicPlugin, "shortDescription");
export const I18N_HEAD_TEXT = getI18nLocaleObject(namespaceList.dynamicPlugin, "headText");
export const I18N_TEXT = getI18nLocaleObject(namespaceList.dynamicPlugin, "text");
export const I18N_DESCRIPTION2 = getI18nLocaleObject(namespaceList.dynamicPlugin, "description2");
export const I18nDYNAMICFIELDCODE = getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCode");
export const I18N_CUSTOM_FIELD_CODE = getI18nLocaleObject(namespaceList.dynamicPlugin, "customFieldCode");

export const I18nAll = getI18nLocaleObject(namespaceList.dynamicPlugin, "all");

export const DescriptionFieldKey = {
    [NAME]: "name",
    [DESCRIPTION]: "description",
    [SHORT_DESCRIPTION]: "shortDescription",
    [DESCRIPTION2]: "description2",
    [TITLE]: "title",
    [HEAD_TEXT]: "headText",
    [TEXT]: "text",
};

export const versioningModules = ["page", "template", "results-panel", "menu", "form", "flow"];
export const CONTENT_TAGS = ["div", "h6", "h5", "h4", "h3", "h2", "h1"];
export const AllOption = {
    value: ALL,
    text: I18nAll,
};

export enum MultiSelectOptions {
    TITLE = "title",
    DESCRIPTION = "description",
}

export interface CustomLanguageCalendarWidgetOptions {
    useCustomLocale?: boolean;
    localeForDatePicker?: string;
}

export enum MultiSelectDynamicData {
    TYPE_NAME = "Type name",
    UNIT_NAME = "Unit name",
    START_DATE = "Start date",
    END_DATE = "End date",
    DURATION = "Duration",
    ACCOKIND = "Accokind",
    RESORT = "Resort/location",
    SUBJECT = "Subjects",
    CUSTOMER_ID = "CustomerId",
}

export enum ItemType {
    RESORTS = "resorts",
    ACCOMMODATION_KINDS = "accommodationKinds",
    AMENITIES = "amenities",
    REGIONS = "regions",
}

export enum MultiSelectPaymentTermNames {
    DEPOSIT = "Deposit",
    TOTAL_AMOUNT = "Total amount",
    LACOMPTE = "L’Acompte",
    MONTANT_TOTAL = "Montant Total",
    BORG = "Borg",
}

export const isLocal = (): boolean => (typeof process !== "undefined" && !!process?.env?.isLocal) || (isClientSide() && window.location.hostname.indexOf("local") >= 0);

export function descriptionTypeMultiSelector<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, includeDynamicField = true, visibilityKey?: string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "descriptionTypeMultiSelector"),
        type: "multiselect",
        async optionList(options: any): Promise<NumberMultiSelectOption[]> {
            if (options.item.contentType === RESERVATION) {
                return [
                    {
                        value: CUSTOM_FIELD,
                        text: I18N_CUSTOM_FIELD_CODE,
                    },
                ];
            }
            return descriptionOptions(includeDynamicField);
        },
        placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "descriptionPlaceHolder"),
        visible: (options: any) => (visibilityKey ? options?.[visibilityKey] : !options.showDynamicField),
    };
}
export async function getDynamicFieldCodeList(mxtsApi: MXTS.MxtsApiWrapper): Promise<Array<SelectOption<number | null>>> {
    const env = await getAdminMxtsEnv();
    const dynamicFieldInfosList = await mxtsApi.dynamicFieldsInfoCustomizedList(env, { size: MXTS_CONSTANTS.MAX_RESULTS }).then((result: any) => result.content);
    const options: Array<SelectOption<number | null>> = dynamicFieldInfosList.map(
        (field: MXTS.DynamicFieldCodeInfo): StringMultiSelectOption => ({
            value: field.code,
            label: field.name,
        })
    );
    options.unshift({
        value: null,
        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "none"),
    });
    return options;
}

export function getDateFormat(dateFormat: string, label?: I18nLocaleObject | string): Array<SomeInputSpec<any, any>> {
    return [
        {
            variable: dateFormat,
            label: label || getI18nLocaleObject(namespaceList.admin, "dateTypes"),
            optionList: [
                { value: DATE_FORMAT.DISPLAY, label: DATE_FORMAT.DISPLAY },
                { value: DATE_FORMAT.MONTH_DATE_YEAR, label: DATE_FORMAT.MONTH_DATE_YEAR },
                { value: DATE_FORMAT.DATE_WITH_SHORT_MONTH, label: DATE_FORMAT.DATE_WITH_SHORT_MONTH },
                { value: DATE_FORMAT.SHORT_MONTH_DAY_YEAR, label: DATE_FORMAT.SHORT_MONTH_DAY_YEAR },
                { value: DATE_FORMAT.DAY_WITH_MONTH_NAME, label: DATE_FORMAT.DAY_WITH_MONTH_NAME },
                { value: DATE_FORMAT.SHORT_DAY_AND_MONTH, label: DATE_FORMAT.SHORT_DAY_AND_MONTH },
            ],
            type: "select",
            visible: (options: AddOnsWidgetOptions) => !options.showRecommendedAddOns,
        },
    ];
}

export function dynamicFieldPrice<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, visibilityVariable?: string, moduleName?: string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCodeMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return dynamicFieldCodeList(MXTS.MxtsApi, moduleName);
        },
        visible: (options: any) => {
            const { descriptionTypeMultiSelector: descriptionType, showRecommendedAddOns } = options;
            if (visibilityVariable) {
                return !!options[visibilityVariable];
            }
            if (descriptionType) {
                return some(descriptionType, ["value", 4]);
            }
            if (showRecommendedAddOns) {
                return false;
            }
            return true;
        },
    };
}

export function descriptionMultiSelectorFallback<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "descriptionFallbackTypeMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return descriptionOptions();
        },
        visible: (options: any) => options.fallbackDescription,
    };
}

export function dynamicFieldFallback<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCode"),
        type: "text",
        visible: (options: any) =>
            options.fallbackDescription && !!(options.descriptionMultiSelectorFallback !== undefined && options.descriptionMultiSelectorFallback.find((check: any) => check.value === 4)),
    };
}

export async function getSubjectsList(mxtsApi: MXTS.MxtsApiWrapper, useSubjectForActivity?: boolean): Promise<Array<SelectOption<number>>> {
    const ops = await getAdminMxtsEnv();
    const subjectRequestParams: MXTS.SubjectRequest = {
        types: ["PERSON", "PET"],
        endDate: moment().format(DATE_FORMAT.ELASTIC),
        sort: "subjectOrder",
        size: MXTS_CONSTANTS.MAX_RESULTS,
    };
    if (useSubjectForActivity) {
        subjectRequestParams.linkType = "activity";
    }

    const subjects = await mxtsApi.subjects(ops, subjectRequestParams).then((res) => res.content);
    const options: Array<SelectOption<any>> = subjects.map(
        (availableSubject: MXTS.Subject): SelectOption<number> => ({
            value: availableSubject.subjectId,
            label: useSubjectForActivity ? availableSubject.name : `${availableSubject.name} (Resort Id: ${availableSubject.resortId})`,
        })
    );
    (options as any).unshift({
        value: null,
        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "none"),
    });
    return options;
}
export function getApplicationsEndPoint(): string {
    return (window as any).cmsConfig.mxtsService.baseUrl + "/api/v1/maxxton/applications?sort=name,ASC";
}

export async function getMXTSConcernUrl(): Promise<string> {
    const ops = await getAdminMxtsEnv();
    const { concern } = ops.env;
    return (window as any).cmsConfig.mxtsService.baseUrl.replace("api", concern || "");
}

export function getWebManagerEnvironment(): "dev" | "acc" | "prod" {
    return isClientSide() ? (window as any).cmsConfig.env : "";
}

export function getDownloadableUrl(uuid: string): string {
    return (window as any).cmsConfig.mxtsService.baseUrl + "/api/v1/media/documents/file/" + uuid;
}

export async function descriptionOptions(includeDynamicField = true): Promise<NumberMultiSelectOption[]> {
    const descriptionOptions = [
        {
            value: NAME,
            text: I18N_NAME,
        },
        {
            value: DESCRIPTION,
            text: I18N_DESCRIPTION,
        },
        {
            value: DESCRIPTION2,
            text: I18N_DESCRIPTION2,
        },
        {
            value: SHORT_DESCRIPTION,
            text: I18N_SHORTDESCRIPTION,
        },
        {
            value: HEAD_TEXT,
            text: I18N_HEAD_TEXT,
        },
        {
            value: TEXT,
            text: I18N_TEXT,
        },
        { text: getI18nLocaleObject(namespaceList.admin, "title"), value: TITLE },
        { text: getI18nLocaleObject(namespaceList.admin, "time"), value: TIME },
        { text: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityArea"), value: LOCATION },
        { text: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activitySignupArea"), value: SIGN_UP },
        { text: I18nRESORT, value: RESORTS },
        { text: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "dates"), value: DATE },
        { text: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activityCategory"), value: CATEGORY },
        { text: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "activitySubject"), value: ACTIVITY_SUBJECT },
    ];
    if (includeDynamicField) {
        descriptionOptions.push({
            value: DYNAMIC_FIELD_CODE,
            text: I18nDYNAMICFIELDCODE,
        });
    }
    return descriptionOptions;
}

export async function getContentTypes(accoKind: boolean | undefined, resort?: boolean): Promise<Array<SelectOption<string>>> {
    const options = [];
    if (resort) {
        options.push({
            value: RESORT,
            label: I18nRESORT,
        });
    }
    options.push({
        value: ACCOMMODATION_TYPE,
        label: I18nACCOMMODATIONTYPE,
    });
    options.push({
        value: UNIT,
        label: I18nUNIT,
    });
    if (accoKind) {
        options.push({
            value: ACCOMMODATION_KIND,
            label: I18nACCOMMODATIONKIND,
        });
    }
    options.push({
        value: ACTIVITY,
        label: I18nACTIVITY,
    });
    options.push({
        value: TIPS_AND_TRIPS,
        label: I18N_TIPS_AND_TRIPS,
    });
    options.push({
        value: RESERVATION,
        label: I18N_RESERVATION,
    });
    return options;
}

export function getMultiSelectOptions(): StringMultiSelectOption[] {
    const options = [
        {
            value: MultiSelectOptions.TITLE,
            text: getI18nLocaleObject(namespaceList.admin, MultiSelectOptions.TITLE),
        },
        {
            value: MultiSelectOptions.DESCRIPTION,
            text: getI18nLocaleObject(namespaceList.admin, MultiSelectOptions.DESCRIPTION),
        },
    ];
    return options;
}

export function getMultiSelectDynamicData(): StringMultiSelectOption[] {
    const options = [
        {
            value: MultiSelectDynamicData.TYPE_NAME,
            text: MultiSelectDynamicData.TYPE_NAME,
        },
        {
            value: MultiSelectDynamicData.UNIT_NAME,
            text: MultiSelectDynamicData.UNIT_NAME,
        },
        {
            value: MultiSelectDynamicData.START_DATE,
            text: MultiSelectDynamicData.START_DATE,
        },
        {
            value: MultiSelectDynamicData.END_DATE,
            text: MultiSelectDynamicData.END_DATE,
        },
        {
            value: MultiSelectDynamicData.DURATION,
            text: MultiSelectDynamicData.DURATION,
        },
        {
            value: MultiSelectDynamicData.ACCOKIND,
            text: MultiSelectDynamicData.ACCOKIND,
        },
        {
            value: MultiSelectDynamicData.RESORT,
            text: MultiSelectDynamicData.RESORT,
        },
        {
            value: MultiSelectDynamicData.SUBJECT,
            text: MultiSelectDynamicData.SUBJECT,
        },
        {
            value: MultiSelectDynamicData.CUSTOMER_ID,
            text: MultiSelectDynamicData.CUSTOMER_ID,
        },
    ];
    return options;
}

export async function getMultiSelectPaymentMethodBrands(): Promise<StringMultiSelectOption[]> {
    const env = await getAdminMxtsEnv();
    const accountsWithTypeInternet = (await MXTS.MxtsApi.getAccounts(env, { size: MXTS_CONSTANTS.MAX_RESULTS, view: "detail", sort: "name,ASC", accountType: "INTERNET" })).content;
    const paymentMethodDefinitionsIds = accountsWithTypeInternet.map((account: MXTS.Account) => account.bankAccount.paymentmethodDefinitionId);
    const paymentMethodDefinitions = await MXTS.MxtsApi.getPaymentMethodDefinitions(env, { size: MXTS_CONSTANTS.MAX_RESULTS, accountType: "internet" });
    const filteredPaymentMethodDefinitions = paymentMethodDefinitions.content.filter((item: MXTS.PaymentMethodDefinition) => paymentMethodDefinitionsIds.includes(item.paymentmethodDefinitionId));
    const filteredPaymentMethods = (Object.keys(PaymentMethodBrands) as Array<keyof typeof PaymentMethodBrands>).filter((paymentMethodBrand) =>
        filteredPaymentMethodDefinitions.some(
            (paymentMethodDefinition) =>
                (paymentMethodDefinition.name === "DirectEbanking (BE)" && paymentMethodBrand === "DIRECT_E_BANKING_BE") ||
                (paymentMethodDefinition.name === "DirectEbanking (DE)" && paymentMethodBrand === "DIRECT_E_BANKING_DE") ||
                (paymentMethodDefinition.name === "Dexia Direct Net" && paymentMethodBrand === "BELFIUS") ||
                paymentMethodDefinition.name === getDCPaymentMethods(PaymentMethodBrands[paymentMethodBrand as keyof typeof PaymentMethodBrands])
        )
    );
    return filteredPaymentMethods.map(
        (paymentMethod: string): StringMultiSelectOption => ({
            value: PaymentMethodBrands[paymentMethod as keyof typeof PaymentMethodBrands],
            text: PaymentMethodBrands[paymentMethod as keyof typeof PaymentMethodBrands],
        })
    );
}

export const getDCPaymentMethods = (paymentMethodLabel: string): string => {
    switch (paymentMethodLabel) {
        case PaymentMethodBrands.IDEAL:
            return "iDEAL";
        case PaymentMethodBrands.VISA:
            return "VISA";
        case PaymentMethodBrands.MASTERCARD:
            return "MasterCard";
        case PaymentMethodBrands.MAESTRO:
            return "Maestro";
        case PaymentMethodBrands.AMERICAN_EXPRESS:
            return "americanExpress";
        case PaymentMethodBrands.DIRECT_E_BANKING_DE:
            return "Sofort Überweisung (DE)";
        case PaymentMethodBrands.DIRECT_E_BANKING_BE:
            return "Sofort Überweisung (BE)";
        case PaymentMethodBrands.BCMC:
            return "BCMC";
        case PaymentMethodBrands.SOFORT:
            return "Sofort Uberweisung";
        case PaymentMethodBrands.GIROPAY:
            return "giropay";
        case PaymentMethodBrands.KBC_ONLINE:
            return "KBC Online";
        case PaymentMethodBrands.BELFIUS:
            return "Bancontact/Mister Cash";
        case PaymentMethodBrands.Przelewy24:
            return "przelewy24";
        case PaymentMethodBrands.Cadeaukaart:
            return "Beekse Bergen cadeaukaart";
        case PaymentMethodBrands.APPLEPAY:
            return PaymentMethodTypes.APPLEPAY;
        case PaymentMethodBrands.GOOGLEPAY:
            return PaymentMethodTypes.GOOGLEPAY;
        default:
            return "";
    }
};

export async function getContentTypesMulti(resort: boolean, accoKind?: boolean): Promise<NumberMultiSelectOption[]> {
    const options = [];
    if (resort) {
        options.push({
            value: ContentType.RESORT,
            text: I18nRESORT,
        });
    }

    options.push(
        {
            value: ContentType.ACCOMMODATION_TYPE,
            text: I18nACCOMMODATIONTYPE,
        },
        {
            value: ContentType.UNIT,
            text: I18nUNIT,
        },
        {
            value: ContentType.FACILITY,
            text: I18nFACILITY,
        }
    );

    if (accoKind) {
        options.push({
            value: ContentType.ACCOMMODATION_KIND,
            text: I18nACCOMMODATIONKIND,
        });
    }
    return options;
}

export function contentTypeSelector<E, P extends keyof E>(variable: P, resort = true, accoKind?: boolean, label?: I18nLocaleObject | string, visibilityKey?: string): InputSpecSelect<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "contentType"),
        type: "select",
        optionList: () => getContentTypes(accoKind, resort) as any,
        required: false,
        visible: (options: any) => (visibilityKey ? options?.[visibilityKey] : !options.preFilteredAvailability),
    };
}

type ContentTypeSelectorType = "contentTypeSelector" | "contentType" | "resortMultiSelector" | "resourceId" | "unitId";

export const getContentTypeSelector = (): Array<SomeInputSpec<any, ContentTypeSelectorType>> => [
    {
        variable: "contentTypeSelector",
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "contentTypeSelector"),
        type: "checkbox",
        default: false,
    },
    {
        ...contentTypeSelector<any, any>("contentType"),
        visible: (options: any): boolean => !!options.contentTypeSelector,
    },
    {
        ...resortMultiSelector("resortMultiSelector"),
        visible: (options: any): boolean => options.contentType === RESORT && !!options.contentTypeSelector,
    },
    {
        ...resourcesAutocomplete("resourceId"),
        visible: (options: any): boolean => options.contentType === ACCOMMODATION_TYPE && !!options.contentTypeSelector,
    },
    {
        ...unitsAutocomplete("unitId"),
        visible: (options: any): boolean => options.contentType === UNIT && !!options.contentTypeSelector,
    },
];

export function contentTypeMultiSelector<E, P extends keyof E>(variable: P, resort?: boolean, accoKind?: boolean, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "contentType"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return getContentTypesMulti(resort ? resort : false, accoKind);
        },
        placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "resortsPlaceHolder"),
    };
}

export function resortMultiSelector<E, P extends keyof E>(
    variable: P,
    allOption?: boolean,
    label?: I18nLocaleObject | string,
    options?: { visible?: (options: E) => boolean; setVisibility?: boolean }
): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "resortMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return resortOptions(MXTS.MxtsApi, allOption);
        },
        placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "resortsPlaceHolder"),
        visible: options?.visible
            ? options.visible
            : (widgetOptions: any) => {
                  if (Array.isArray(widgetOptions.contentTypes)) {
                      let selected = false;
                      widgetOptions.contentTypes.forEach((contentType: any) => {
                          if (contentType.value === ContentType.RESORT) {
                              selected = true;
                          }
                      });
                      return widgetOptions?.setVisibility
                          ? widgetOptions.preFilteredAvailability !== undefined
                              ? widgetOptions.preFilteredAvailability
                              : true
                          : selected && !widgetOptions.preFilteredAvailability;
                  }
                  return widgetOptions?.setVisibility
                      ? widgetOptions.preFilteredAvailability !== undefined
                          ? widgetOptions.preFilteredAvailability
                          : true
                      : widgetOptions.contentType === RESORT && !widgetOptions.preFilteredAvailability;
              },
    };
}

export function dynamicFieldCode<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldId"),
        type: "text",
        visible: (options: any) =>
            !!(options.descriptionTypeMultiSelector !== undefined && options.descriptionTypeMultiSelector.find((check: any) => check.value === 4)) ||
            // Can be used with widgets other than description, eg Addition widget
            !options.descriptionTypeMultiSelector,
    };
}

export async function contractOptions(page: number, searchQuery: string | undefined, contractTypesIds: string[] | undefined): Promise<SelectOptionLazyLoadResponse<MXTS.ContractType, number>> {
    const env = await getAdminMxtsEnv();
    const contractTypesResults: MXTS.PagedResult<MXTS.ContractType> = await MXTS.MxtsApi.getContractsTypes(env, {
        size: MXTS_CONSTANTS.MAX_RESULTS,
        page,
        name: searchQuery,
        contractTypeIds: contractTypesIds?.map((contractTypesId) => +contractTypesId),
    });
    return {
        selectOptions: contractTypesResults.content.map(
            (contractType: MXTS.ContractType): SelectOption<number> => ({
                value: contractType.contractTypeId,
                label: `${contractType.name}`,
            })
        ),
        pagedResult: contractTypesResults,
    };
}

export function multiSelectReservationCategories<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "reservationCategoryMultiSelector"),
        type: "multiselect",
        async optionList({ context }): Promise<any[]> {
            return reservationCategoryOptions({ context });
        },
        placeholder: getI18nLocaleObject(namespaceList.admin, "reservationCategoryPlaceholder"),
    };
}

export async function reservationCategoryOptions({ context }: { context: Pick<CMSProviderProperties, "distributionChannelId"> }): Promise<NumberMultiSelectOption[]> {
    const env = await getAdminMxtsEnv();

    const reservationCategories: MXTS.ReservationCategory[] =
        (await MXTS.getAll((page: number) => MXTS.MxtsApi.getReservationCategories(env, { page, size: MXTS_CONSTANTS.MAX_RESULTS })))?.content || [];
    return reservationCategories.map((category: MXTS.ReservationCategory) => ({
        value: category.reservationCategoryId,
        text: category.name,
    }));
}

export async function pointsOfInterestOptions(page: number, searchQuery?: string, ids?: string[]): Promise<SelectOptionLazyLoadResponse<MXTS.PointsOfInterestResponse, number>> {
    const env = await getAdminMxtsEnv();
    const pointsOfInterest: MXTS.PagedResult<MXTS.PointsOfInterestResponse> = await MXTS.MxtsApi.getPointsOfInterest(env, {
        size: MXTS_CONSTANTS.MAX_RESULTS,
        page,
        name: searchQuery,
        ids: ids?.map((id) => +id),
    });
    return {
        selectOptions: pointsOfInterest.content?.map(
            (poi: MXTS.PointsOfInterestResponse): SelectOption<number> => ({
                value: poi.pointsOfInterestId,
                label: poi?.title || poi?.code,
            })
        ),
        pagedResult: pointsOfInterest,
    };
}

export function multiSelectSpecials<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, setVisibility?: boolean): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "specialMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<StringMultiSelectOption[]> {
            return specialOptions(MXTS.MxtsApi);
        },
        placeholder: getI18nLocaleObject(namespaceList.admin, "specialPlaceholder"),
        visible: setVisibility ? (options: any) => options.preFilteredAvailability : undefined,
    };
}

export function multiSelectFacilities<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, setVisibility?: boolean): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "facilityMultiSelector"),
        type: "multiselect",
        async optionList({ item }): Promise<any[]> {
            return facilityOptions(MXTS.MxtsApi, (item as unknown) as FacilityOptions);
        },
        placeholder: getI18nLocaleObject(namespaceList.admin, "facilityPlaceholder"),
        visible: (widgetOptions: any) => widgetOptions.contentType || (setVisibility ? widgetOptions.preFilteredAvailability : undefined),
    };
}

export function multiSelectRegions<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, setVisibility?: boolean): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "regionMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return regionOptions(MXTS.MxtsApi);
        },
        placeholder: getI18nLocaleObject(namespaceList.admin, "regionPlaceholder"),
        visible: setVisibility ? (options: any) => options.preFilteredAvailability : undefined,
    };
}

export async function getMemo(apiContext: ApiContext): Promise<any[]> {
    const env = await getMxtsEnv(apiContext);
    const memoCategories = await apiContext.mxtsApi.getMemoCategories(env, { size: 2000 }).then((categories) => categories.content);
    return memoCategories.map((cat: any) => ({
        label: cat.name,
        value: cat.categoryId,
    }));
}

export async function regionOptions(mxtsApi: MXTS.MxtsApiWrapper): Promise<NumberMultiSelectOption[]> {
    const ops = await getAdminMxtsEnv();
    const regions = await mxtsApi.regions(ops, { size: 999, view: "detail", returnGeoShapes: false }).then((pagedResult) => pagedResult.content);
    return regions.map(
        (region: MXTS.Region): NumberMultiSelectOption => {
            const parentRegion = regions.find((parentRegion) => parentRegion.regionId === region.parentId);
            return {
                value: region.regionId,
                text: region.childIds?.filter((childId) => childId.regionId !== childId.childId).length
                    ? `${region.name} (${getI18nLocaleString(namespaceList.admin, "parentRegion")})`
                    : region.parentId
                    ? `${region.name} (${getI18nLocaleString(namespaceList.admin, "childRegion")} ${parentRegion?.name})`
                    : region.name,
            };
        }
    );
}

export async function lazyLoadRegionOptions(page: number, searchQuery: string | undefined, regionIds: string[] | undefined): Promise<SelectOptionLazyLoadResponse<MXTS.Region, number>> {
    const ops = await getAdminMxtsEnv();
    const regions: MXTS.PagedResult<MXTS.Region> = await MXTS.MxtsApi.regions(ops, {
        size: 999,
        page,
        view: "detail",
        returnGeoShapes: false,
        name: searchQuery,
        regionIds: regionIds?.map((regionId) => Number(regionId)),
    });
    return {
        selectOptions: regions.content.map(
            (region: MXTS.Region): SelectOption<number> => {
                const parentRegion = regions.content.find((parentRegion) => parentRegion.regionId === region.parentId);
                return {
                    value: region.regionId,
                    label: region.childIds?.filter((childId) => childId.regionId !== childId.childId).length
                        ? `${region.name} (${getI18nLocaleString(namespaceList.admin, "parentRegion")})`
                        : region.parentId
                        ? `${region.name} (${getI18nLocaleString(namespaceList.admin, "childRegion")} ${parentRegion?.name})`
                        : region.name,
                };
            }
        ),
        pagedResult: regions,
    };
}

export async function paymentTermSetsOptionsLazyLoad(page: number, searchQuery?: string, ids?: string[]): Promise<SelectOptionLazyLoadResponse<MXTS.PaymentTermSets, string>> {
    const ops = await getAdminMxtsEnv();
    const paymentTermSets: MXTS.PagedResult<MXTS.PaymentTermSets> = await MXTS.MxtsApi.getAllPaymentTermSets(ops, {
        size: MXTS_CONSTANTS.MAX_RESULTS,
        page,
        searchQuery,
        ids: ids?.map((id) => +id),
    });
    return {
        selectOptions: paymentTermSets.content.map(
            (paymentTermSet: MXTS.PaymentTermSets): SelectOption<string> => ({
                value: paymentTermSet.paymentTermSetId.toString(),
                label: `${paymentTermSet.name} | ${paymentTermSet.paymentTermSetId}`,
            })
        ),
        pagedResult: paymentTermSets,
    };
}

export async function resortOptions(mxtsApi: MXTS.MxtsApiWrapper, allOption?: boolean): Promise<NumberMultiSelectOption[]> {
    const ops = await getAdminMxtsEnv();
    const resorts = await mxtsApi.resorts(ops, { size: MXTS_CONSTANTS.MAX_RESULTS }).then((pagedResult) => pagedResult.content);
    const resortSelectOptions: NumberMultiSelectOption[] = resorts.map(
        (resort: MXTS.Resort): NumberMultiSelectOption => ({
            value: resort.resortId,
            text: resort.name,
        })
    );
    if (allOption) {
        resortSelectOptions.splice(0, 0, AllOption);
    }
    return resortSelectOptions;
}

// Define a recursive function to fetch nested child regions
async function getRegionWithChildRegions(region: RegionWithChildRegions, currentEnv: MXTS.ApiCallOptions): Promise<RegionWithChildRegions> {
    const childRegionIds = region.childIds?.filter((child) => region.regionId !== child.childId && child.level === 1).map((childId) => childId.childId);

    if (childRegionIds?.length) {
        // Fetch child regions
        const childRegions = (
            await MXTS.MxtsApi.regions(currentEnv, {
                size: MXTS_CONSTANTS.MAX_RESULTS,
                regionIds: childRegionIds,
                view: "detail",
                returnGeoShapes: false,
            })
        ).content;

        // Recursively fetch nested child regions for each child
        const childRegionsWithNestedChildren = await Promise.all(childRegions.map(async (childRegion: RegionWithChildRegions) => await getRegionWithChildRegions(childRegion, currentEnv)));

        // Assign the nested child regions to the parent
        region.childRegions = childRegionsWithNestedChildren;
    }

    return region;
}

const getRegionWithNestedChildren = (regions: RegionWithChildRegions[], widgetOptions: WidgetOptions): FreeSearchData[] =>
    regions.map((region) => ({
        name: region?.name || "",
        regionId: region?.regionId ? [region.regionId] : [],
        searchString: region?.name || "",
        icon: widgetOptions.regionIcon,
        isDefault: true,
        priority: region?.priority,
        childRegions: getChildRegions(region?.childRegions || [], widgetOptions), // Get child regions recursively
    }));

const getChildRegions = (childRegions: RegionWithChildRegions[], widgetOptions: WidgetOptions): FreeSearchData[] => {
    const childRegionWithNestedRegions: FreeSearchData[] = childRegions.map((childRegion) => ({
        name: childRegion?.name || "",
        regionId: childRegion?.regionId ? [childRegion.regionId] : [],
        searchString: childRegion?.name || "",
        icon: widgetOptions.regionIcon,
        parentId: childRegion.parentId,
        isDefault: true,
        childRegions: childRegion?.childRegions
            ? getChildRegions(childRegion.childRegions, widgetOptions) // Recursively handle child regions
            : [], // If no child regions, return an empty array
    }));
    return childRegionWithNestedRegions;
};

export async function getFreeSearchItems(itemType: string, currentEnv: MXTS.ApiCallOptions, widgetOptions: WidgetOptions, availableRegions?: MXTS.ElasticFacetV2): Promise<FreeSearchData[]> {
    let items: FreeSearchData[] = [];

    switch (itemType) {
        case ItemType.REGIONS:
            const uniqueAvailableRegions = Array.from(new Set(availableRegions?.map((region) => Number(region.key))));
            let regionIds = [...widgetOptions.regions];
            if (widgetOptions.showRegionsBasedOnAvailability) {
                if (!widgetOptions.regions.length && uniqueAvailableRegions?.length) {
                    regionIds = [...uniqueAvailableRegions];
                } else {
                    regionIds = [...widgetOptions.regions].filter((region) => uniqueAvailableRegions?.some((availableRegion) => availableRegion === region));
                }
                if (widgetOptions.showUnavailableRegions) {
                    regionIds = [...widgetOptions.regions];
                }
            }
            const regions = await MXTS.MxtsApi.regions(currentEnv, { size: MXTS_CONSTANTS.MAX_RESULTS, regionIds, view: "detail", returnGeoShapes: false });
            let temporaryRegions: RegionWithChildRegions[] = regions.content;
            if (widgetOptions.showRegionsBasedOnAvailability && regionIds.length && uniqueAvailableRegions?.length && !widgetOptions.showUnavailableRegions) {
                temporaryRegions = temporaryRegions
                    .filter((tempRegion) => !tempRegion.parentId)
                    .map((temporaryRegion) => {
                        const childIds = temporaryRegion.childIds?.filter((child) => temporaryRegion.regionId !== child.childId && uniqueAvailableRegions.includes(child.childId));
                        if (childIds?.length) {
                            return { ...temporaryRegion, childIds };
                        }
                        return temporaryRegion;
                    });
            }
            // Get all regions with nested child regions
            const regionWithChildRegions = (
                await Promise.all(
                    temporaryRegions.map(async (region: RegionWithChildRegions) => {
                        // If the region has childIds or no parentId, process the region
                        if (region.childIds || (!region.childRegions?.length && !region.parentId)) {
                            return await getRegionWithChildRegions(region, currentEnv);
                        }
                        return region;
                    })
                )
            ).filter((data) => !!data);
            if (widgetOptions.showParentAndChildRegionsInDropdown) {
                items = getRegionWithNestedChildren(regionWithChildRegions, widgetOptions);
            } else {
                items = regions.content.map((region: MXTS.Region) => ({
                    name: region.name,
                    regionId: [region.regionId],
                    searchString: region.name,
                    icon: widgetOptions.regionIcon,
                    isDefault: true,
                    priority: region.priority,
                }));
            }
            break;

        case ItemType.RESORTS:
            const resortIds = widgetOptions.resorts.map((resort: FreeSearchOption) => resort.value);
            const resortsPromise = await MXTS.MxtsApi.resorts(currentEnv, { resortIds });
            items = resortsPromise.content.map((resort: MXTS.Resort) => ({
                name: resort.name,
                resortId: [resort.resortId],
                searchString: resort.name,
                icon: widgetOptions.resortIcon,
                isDefault: true,
            }));
            break;

        case ItemType.ACCOMMODATION_KINDS:
            const accoKindIds = widgetOptions.accoKinds.map((accoKind: FreeSearchOption) => accoKind.value);
            const accoKindsPromises = accoKindIds.map((accoKindId: number) => MXTS.MxtsApi.accommodationkinds(currentEnv, { accoKindId }));
            const accoKinds = await Promise.all(accoKindsPromises);
            items = accoKinds.map((accoKind) => ({
                name: accoKind.content[0].name,
                accoKindId: [accoKind.content[0].accommodationkindId],
                searchString: accoKind.content[0].name,
                icon: widgetOptions.accoKindIcon,
                isDefault: true,
            }));
            break;

        case ItemType.AMENITIES:
            const identifier = widgetOptions.amenities.map((amenity: FreeSearchOption) => amenity.value.toString()).join(",");
            const amenitiesPromise = await MXTS.MxtsApi.amenities(currentEnv, { identifier });
            items = amenitiesPromise.content.map((amenity: MXTS.Amenity) => ({
                name: amenity.name,
                amenities: [amenity.amenityId],
                searchString: amenity.name,
                icon: widgetOptions.amenityIcon,
                isDefault: true,
            }));
            break;
    }
    return items;
}

export async function specialOptions(mxtsApi: MXTS.MxtsApiWrapper): Promise<StringMultiSelectOption[]> {
    const env = await getAdminMxtsEnv();
    const offers =
        (
            await MXTS.getAll((page: number) =>
                mxtsApi.getAllOffers(env, { page, size: MXTS_CONSTANTS.PAGE_REQUEST_SIZE, offerFilter: "ACTIVE", lowestLevel: true, types: "SPECIAL,COMPOSITION", sort: "name,ASC" })
            )
        )?.content || [];
    return offers.map(
        ({ searchCode, code, name, offerPolicy, type }: MXTS.Offer): StringMultiSelectOption => {
            // priority is given to the searchCode, if it is not present then code is used
            const offerCode = searchCode || code;
            return {
                value: offerCode,
                text: `${name} (${offerCode}, ${offerPolicy}, ${type})`,
            };
        }
    );
}

interface FacilityOptions {
    resorts: Array<{ value: number }>;
}

export async function facilityOptions(mxtsApi: MXTS.MxtsApiWrapper, options: FacilityOptions): Promise<NumberMultiSelectOption[]> {
    const ops = await getAdminMxtsEnv();
    const resortIds: number[] = options.resorts?.map((item: any) => item.value);
    const facilities = await mxtsApi.facilities(ops, { size: 1500, resortId: resortIds }).then((pagedResult: any) => pagedResult.content);
    return facilities.map(
        (facility: MXTS.Facility): NumberMultiSelectOption => ({
            value: facility.facilityId,
            text: `${facility.name} (${facility.code})`,
        })
    );
}

export async function dynamicFieldCodeList(mxtsApi: MXTS.MxtsApiWrapper, moduleName?: string): Promise<NumberMultiSelectOption[]> {
    const env = await getAdminMxtsEnv();
    const size = moduleName === "Activity" ? 200 : MXTS_CONSTANTS.MAX_RESULTS;
    const dynamicFieldInfosList = await mxtsApi.dynamicFieldsInfoCustomizedList(env, { size, moduleName }).then((result: any) => result.content);
    return dynamicFieldInfosList.map(
        (field: any): NumberMultiSelectOption => ({
            value: moduleName === "Activity" ? field.fieldId : field.code,
            text: `${field.name} • code: ${field.code}`,
        })
    );
}

export async function paymentStatusOptions(): Promise<StringMultiSelectOption[]> {
    return (Object.keys(PaymentStatuses) as Array<keyof typeof PaymentStatuses>).map(
        (status: any): StringMultiSelectOption => ({
            value: PaymentStatuses[status as keyof typeof PaymentStatuses],
            text: `${status}`,
        })
    );
}

export function generateRandomKey(): string {
    return Math.random()
        .toString(36)
        .split("")
        .filter((value, index, self) => self.indexOf(value) === index)
        .join("")
        .substr(2, 5);
}

export function accoKindMultiSelector<E, P extends keyof E>(variable: P, allOption?: boolean, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "accoKind"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return accoKindOptions(MXTS.MxtsApi, allOption);
        },
        placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "accoKindsPlaceHolder"),
        visible: (options: any) => {
            if (Array.isArray(options.contentTypes)) {
                let selected = false;
                options.contentTypes.forEach((contentType: any) => {
                    if (contentType.value === ContentType.ACCOMMODATION_KIND) {
                        selected = true;
                    }
                });
                return selected;
            }
            return options.contentType === ACCOMMODATION_KIND;
        },
    };
}

export function assetSpec(selectAssetVariable: string, visibleOptionsVariable: string, mediaVariable?: string, fileVariable?: string, localizedAsset?: boolean): any {
    // variable declaraions for the selectbox
    const media = {
        label: getI18nLocaleObject(namespaceList.genericCrud, "media"),
        value: "media",
    };
    const file = {
        label: getI18nLocaleObject(namespaceList.genericCrud, "file"),
        value: "file",
    };
    // variable declaraions for the media/file fetching
    const assetSpecImage = {
        variable: mediaVariable,
        type: "image",
        label: getI18nLocaleObject(namespaceList.genericCrud, "selectMedia"),
    };
    const assetSpecFile = {
        variable: fileVariable,
        type: "document",
        label: getI18nLocaleObject(namespaceList.genericCrud, "selectFile"),
    };
    const assetSelectImage = {
        variable: mediaVariable,
        type: "image",
        label: getI18nLocaleObject(namespaceList.genericCrud, "selectMedia"),
        visible: (options: any) =>
            options[visibleOptionsVariable] !== undefined ? options[visibleOptionsVariable] && options[selectAssetVariable] === "media" : options[selectAssetVariable] === "media",
    };
    const assetSelectFile = {
        variable: fileVariable,
        type: "document",
        label: getI18nLocaleObject(namespaceList.genericCrud, "selectFile"),
        visible: (options: any) =>
            options[visibleOptionsVariable] !== undefined ? options[visibleOptionsVariable] && options[selectAssetVariable] === "file" : options[selectAssetVariable] === "file",
    };
    if (!localizedAsset || undefined) {
        // Below statements the select boxes will be populated correctly without any reselect
        if (mediaVariable === undefined && fileVariable !== undefined) {
            return [assetSpecFile];
        } else if (mediaVariable !== undefined && fileVariable === undefined) {
            return [assetSpecImage];
        }
        const assetSelect = {
            variable: selectAssetVariable,
            type: "select",
            disabled: true,
            label: getI18nLocaleObject(namespaceList.genericCrud, "selectAssetType"),
            optionList: [media, file],
            visible: (options: any) => (options[visibleOptionsVariable] !== undefined ? options[visibleOptionsVariable] : true),
            placeholder: getI18nLocaleObject(namespaceList.amenitiesWidget, "iconSetPlaceholder"),
        };
        // Need complete part for below as the visibility in type=select is not populated at first
        return [assetSelect, assetSelectImage, assetSelectFile];
    }
    // Below statements the select boxes will be populated correctly without any reselect
    if (mediaVariable === undefined && fileVariable !== undefined) {
        return localized({
            variable: "localized" as any,
            tabContent: [assetSpecFile as any],
            visible: (options: any) => (options[visibleOptionsVariable] !== undefined ? options[visibleOptionsVariable] : true),
        });
    } else if (mediaVariable !== undefined && fileVariable === undefined) {
        return localized({
            variable: "localized" as any,
            tabContent: [assetSpecImage as any],
            visible: (options: any) => (options[visibleOptionsVariable] !== undefined ? options[visibleOptionsVariable] : true),
        });
    }
    const assetSelect = {
        variable: selectAssetVariable,
        type: "select",
        disabled: true,
        label: getI18nLocaleObject(namespaceList.genericCrud, "selectAssetType"),
        optionList: [media, file],
        visible: (options: any) => (options[visibleOptionsVariable] !== undefined ? options[visibleOptionsVariable] : true),
        placeholder: getI18nLocaleObject(namespaceList.amenitiesWidget, "iconSetPlaceholder"),
    };
    // Below return is for the localized version.
    return localized({
        variable: "localized" as any,
        tabContent: [assetSelect as any, assetSelectImage as any, assetSelectFile as any],
        visible: (options: any) => (options[visibleOptionsVariable] !== undefined ? options[visibleOptionsVariable] : true),
    });
}

async function accoKindOptions(mxtsApi: MXTS.MxtsApiWrapper, allOption?: boolean, label?: boolean): Promise<NumberMultiSelectOption[]> {
    const ops = await getAdminMxtsEnv();
    const accommodationkinds = (await mxtsApi.accommodationkinds(ops, { size: 999, sort: "priority" })).content;
    const accoKindSelectOptions: NumberMultiSelectOption[] = accommodationkinds.map(
        (resort: MXTS.AccoKind): NumberMultiSelectOption => ({
            value: resort.accommodationkindId,
            ...(label ? { label: resort.name } : { text: resort.name }),
        })
    );
    if (allOption) {
        accoKindSelectOptions.splice(0, 0, AllOption);
    }
    return accoKindSelectOptions;
}

export function accoKindSelector<E, P extends keyof E>(variable: P, allOption = true, accoKind?: boolean, label?: I18nLocaleObject | string): InputSpecSelect<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.accokindWidget, "selectAccoKindLabel"),
        type: "select",
        placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
        optionList: () => accoKindOptions(MXTS.MxtsApi, false, true) as any,
        visible: (options: any) => options.staticAccoKind,
    };
}

export function unitIdField<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "unitId"),
        type: "text",
        visible: (options: any) => {
            if (Array.isArray(options.contentTypes)) {
                let selected = false;
                options.contentTypes.forEach((contentType: any) => {
                    if (contentType.value === ContentType.UNIT) {
                        selected = true;
                    }
                });
                return selected && !options.preFilteredAvailability;
            }
            return options.contentType === UNIT && !options.preFilteredAvailability;
        },
    };
}

const lastElasticFetch: {
    dynamicFilter?: DynamicFilter;
    request?: Promise<AvailabilityResultWithFilter>;
    result?: AvailabilityResultWithFilter;
} = {};

// keeping this intact to see the impact(it will only be triggered if there are not containers on the page)
// TODO: Remove this completely if nothing is broken
export function fetchElasticResponse(
    apiContext: ApiContext,
    dynamicFilter: DynamicFilter = {},
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction>,
    locale?: string
): Promise<AvailabilityResultWithFilter> {
    const { sortingOption, ...rest } = dynamicFilter;
    const filter = { ...rest };
    if (!lastElasticFetch || (!lastElasticFetch.result && lastElasticFetch.request) || !isEqual(lastElasticFetch.dynamicFilter, filter)) {
        lastElasticFetch.dynamicFilter = filter;
        lastElasticFetch.request = new Promise<AvailabilityResultWithFilter>((resolve, reject) => {
            getMxtsEnv(apiContext, locale)
                .then(async (env) => {
                    // Below dispatch should be done after a fetch call only otherwise it will throw:
                    // Error: Reducers may not dispatch actions.
                    // TODO: Keep it like this till we have Saga as middleware to handle async calls
                    const fetchingAction: AvailabilityAction = {
                        actionType: AvailabilityActionType.fetching,
                        type: ActionType.Availability,
                    };
                    let duration;
                    if (dynamicFilter.stayperioddefid) {
                        const stayPeriods = await apiContext.mxtsApi.stayPeriods(env, {
                            stayPeriodDefIds: [dynamicFilter.stayperioddefid],
                            startDate: moment().format("YYYY-MM-DD"),
                        });
                        const stayPeriod = stayPeriods.content[0];
                        if (stayPeriod) {
                            duration = stayPeriod.duration;
                        }
                    }
                    if (duration) {
                        const changeEndDateAction: FilterChangeAction = {
                            filter: dynamicFilterType.addDerivedEndDate,
                            payload: { duration },
                            type: ActionType.FilterChange,
                        };
                        dispatchAction(changeEndDateAction);
                    }
                    dispatchAction(fetchingAction);
                    let elasticSubjects: MXTS.ElasticSubject[] = [];
                    let petCapacity: number | undefined;
                    if (dynamicFilter.subject && dynamicFilter.subject.size > 0) {
                        const subjects = await apiContext.mxtsApi
                            .subjects(env, {
                                types: ["PERSON", "PET"],
                                endDate: moment().format("YYYY-MM-DD"),
                                sort: "subjectOrder",
                            })
                            .then((res) => res.content);
                        elasticSubjects = getElasticSubjectFilter(subjects, dynamicFilter.subject);
                        petCapacity = getPetCapacity(subjects, dynamicFilter.subject);
                    }
                    const baseFilter: MXTS.AvailabilityRequest = getBaseFilter({
                        options: dynamicFilter,
                        distributionChannelId: dynamicFilter.distributionChannel?.distributionChannelId!,
                        elasticSubjects,
                        petCapacity,
                        fetchUnitsWithPrice: dynamicFilter.shouldFetchUnitsWithPrice,
                        fetchSortedUnits: dynamicFilter.shouldFetchSortedUnits,
                        env: env.env,
                        includePreBookingPeriods: dynamicFilter.includePreBookingPeriods,
                    });
                    getAvailability(apiContext, env, baseFilter)
                        .then((availabilityResult) => {
                            const action: AvailabilityAction = {
                                actionType: AvailabilityActionType.fetched,
                                type: ActionType.Availability,
                                payload: {
                                    availabilityResult,
                                    env,
                                    availabilityRequest: baseFilter,
                                },
                            };
                            dispatchAction(action);
                            resolve(availabilityResult);
                        })
                        .catch((err) => {
                            // eslint-disable-next-line no-console
                            console.log(err);
                            reject(err);
                        });
                })
                .catch((e) => {
                    reject(e);
                });
        });
        return lastElasticFetch.request.then((result) => {
            lastElasticFetch.result = result;
            return result;
        });
    }
    if (lastElasticFetch.result) {
        return Promise.resolve(lastElasticFetch.result);
    }
    return lastElasticFetch.request as Promise<AvailabilityResultWithFilter>;
}

export const shouldGetAvailability = (action: FilterChangeAction) =>
    action.filter !== dynamicFilterType.dynamicMarkersIsFetchComplete &&
    action.filter !== dynamicFilterType.onMouseOverOut &&
    action.filter !== dynamicFilterType.markerIds &&
    action.filter !== dynamicFilterType.minprice &&
    action.filter !== dynamicFilterType.maxprice &&
    action.filter !== dynamicFilterType.directSearchInput &&
    action.filter !== dynamicFilterType.selectedDirectSearchId &&
    action.filter !== dynamicFilterType.sorting &&
    action.filter !== dynamicFilterType.addBookingsEngineUrl &&
    action.filter !== dynamicFilterType.updateMinMaxPrice &&
    action.filter !== dynamicFilterType.removePriceRangeFilters &&
    action.filter !== dynamicFilterType.loadingAction &&
    action.filter !== dynamicFilterType.addDerivedEndDate;

export const filterUnitByPrice = (singleUnit: any, props: any) => {
    const unitPrice =
        singleUnit[props.dynamicFilter?.priceRangeCriteria] || singleUnit.basePriceInclusive || singleUnit.unitNightPriceInclusive || singleUnit.unitBaseNightPriceInclusive || singleUnit.nightPrice;
    if (unitPrice) {
        return props.dynamicFilter.minprice && props.dynamicFilter.maxprice && Math.round(unitPrice) >= props.dynamicFilter.minprice && Math.round(unitPrice) <= props.dynamicFilter.maxprice;
    }
};

export const filterTypesByPrice = (singleType: any, props: any) => {
    const typePrice =
        singleType.nightPriceInclusive || singleType.baseNightPriceInclusive || singleType.priceInclusive || singleType.basePriceInclusive || singleType.nightPrice || singleType.baseNightPrice;
    if (typePrice) {
        return props.dynamicFilter.minprice && props.dynamicFilter.maxprice && Math.round(typePrice) >= props.dynamicFilter.minprice && Math.round(typePrice) <= props.dynamicFilter.maxprice;
    }
};

export function roundHalfToEven(num: number, placeToRound: number) {
    let divisor = "1";
    const fixed = placeToRound.toString().split(".").length < 2 ? 0 : placeToRound.toString().split(".")[1].length;
    for (let num = 0; num < fixed; num++) {
        divisor = divisor + "0";
    }
    const numParts = {
        mvDec: (num / placeToRound).toFixed(num.toString().length).toString().split("."),
        wholeNum() {
            return parseInt(this.mvDec[0], 10);
        },
        dec() {
            return this.mvDec.length > 1 ? parseFloat("0." + this.mvDec[1]) : 0;
        },
        oddEven() {
            return this.wholeNum() % 2 === 1 ? 1 : 0;
        },
    };

    if (numParts.dec() !== 0.5) {
        return numParts.dec() > 0.5 ? parseFloat(((numParts.wholeNum() + 1) / +divisor).toFixed(fixed)) : parseFloat((numParts.wholeNum() / +divisor).toFixed(fixed));
    }
    if (numParts.oddEven() === 1) {
        return parseFloat(((numParts.wholeNum() + 1) / +divisor).toFixed(fixed));
    }
    return parseFloat((numParts.wholeNum() / +divisor).toFixed(fixed));
}

export function formatReservationNumber(reservationNumber: string) {
    return reservationNumber?.slice(0, reservationNumber.length - 3);
}

export function isEqual(value: any, other: any) {
    const startTime = +new Date();
    const isEqualResult = _isEqual(value, other);
    const endTime = +new Date();
    const executionTimeMs = endTime - startTime;
    if (executionTimeMs > 25) {
        // eslint-disable-next-line
        console.trace(`Comparing variables that are too big in isEqual. Please optimize this! isEquals took: ${executionTimeMs} ms`);
        reportWarn(new Error(`Comparing variables that are too big in isEqual. Please optimize this! isEquals took: ${executionTimeMs} ms. Url: ${location?.href} . Stacktrace: ${Error().stack}`));
    }
    return isEqualResult;
}

/**
 * Temporary implementation for MCMS-3223 that prevents and informs us when we are doing some VERY HEAVY isEqual's.
 */
function isExcludeFromIsEqual<T>(value: T, key: keyof T) {
    const obj: any = value[key];
    if (obj && obj.i18n && obj.currentLocale && obj.site && obj.spec && obj.location && obj.theme) {
        Error.stackTraceLimit = 200;
        // eslint-disable-next-line
        console.trace("Performance error: It's trying to execute isEqual on the huge context object");
        reportError(new Error("Performance error: It's trying to execute isEqual on the huge context object"));
        return true;
    }
    return false;
}

export function checkRequiredFields<E>(activeSpec: FormSpec<E>, dataToBeChecked: any) {
    const properties: any = activeSpec.properties.reduce((tProps: Property[], prop: any) => {
        if (prop.type === "statictabs") {
            const tabsProperties = prop.tabs.reduce((tP: Property[], tab: any) => {
                tab.properties.forEach((p: any) => {
                    p.map((propertyElement: any) => {
                        if (propertyElement.variable === "localizedContents") {
                            const tabProp1 = propertyElement.tabContent.map((property: any) => ({
                                variable: property.variable,
                                required: !!property.required,
                                label: property.label,
                            }));
                            tP.push(tabProp1);
                        } else {
                            const tabProp2 = {
                                variable: propertyElement.variable,
                                required: !!propertyElement.required,
                                label: propertyElement.label,
                            };
                            tP.push(tabProp2);
                        }
                    });
                });
                return tP;
            }, []);
            tProps.push([].concat.apply([], tabsProperties));
            return [].concat.apply([], tProps);
        }

        tProps.push({ variable: prop.variable, required: !!prop.required, label: prop.label });
        return tProps;
    }, []);

    const mandatoryFilled = checkMandatoryFilled(properties, dataToBeChecked);
    return mandatoryFilled;
}

export function checkMandatoryFilled(requiredProperties: any, currentValue: any) {
    let isRequiredChecked = false;
    const labelArray: string[] = [];
    const requiredCheckedArray = requiredProperties.map((prop: any) => {
        if (prop.variable === "metaDescriptionSeo" && currentValue?.enableDynamicDescription) {
            return true;
        } else if (prop.variable === "metaTitleSeo" || prop.variable === "metaDescriptionSeo") {
            if (currentValue.localizedContents) {
                const requiredLocales = currentValue.localizedContents.map((prop2: any) => (prop.required ? typeof prop2[prop.variable] !== "undefined" && prop2[prop.variable] !== "" : true));
                isRequiredChecked = requiredLocales.every((el: boolean) => el);
                if (!isRequiredChecked) {
                    labelArray.push(prop.label);
                }
            } else {
                labelArray.push(prop.label);
                return false;
            }
        } else {
            isRequiredChecked = prop.required ? typeof currentValue[prop.variable] !== "undefined" && currentValue[prop.variable] !== "" : true;
            if (!isRequiredChecked) {
                labelArray.push(prop.label);
            }
        }
        return isRequiredChecked;
    });
    const requiredChecked = requiredCheckedArray.every((el: boolean) => el);
    return { labelArray, requiredChecked };
}

export async function checkDuplicateCategoryItems<E>(activeSpec: FormSpec<E>, dataToBeChecked: any, mode: string): Promise<boolean> {
    let isDuplicateCategory = false;
    const searchString = dataToBeChecked.name.toLowerCase();
    const matchedCategory = await activeSpec.api!.find({
        query: {
            name: { $regex: ".*" + searchString + ".*", $options: "i" },
            deletedDate: { $exists: false },
        },
    });
    if (matchedCategory && matchedCategory.length > 0) {
        matchedCategory.filter((rawCat: any) => {
            if (rawCat.name.toLowerCase() === dataToBeChecked.name.toLowerCase()) {
                isDuplicateCategory = mode === "create" ? true : rawCat._id !== dataToBeChecked._id;
            }
        });
    }
    return isDuplicateCategory;
}

export function checkDuplicateSpecials(specialCodes: string[], code: string): boolean {
    return specialCodes.indexOf(code) <= -1;
}

export async function checkDuplicatePermalink(draftValue: any): Promise<boolean> {
    const permalink = draftValue.permalink;
    const siteId = draftValue.siteId;
    const pageId = draftValue.pageId;
    let doesPermalinkExist = false;
    let siteIdList;
    let friendlyUrl: string;

    const selectedSite = await SiteApi.findById({ id: siteId });
    if (selectedSite) {
        friendlyUrl = permalink.substring(2 + selectedSite.host.length);
    }

    if (draftValue.addPostToSiteGroups) {
        const siteGroupIds: string[] =
            draftValue.postSiteGroups?.map((group: StringMultiSelectOption) => group.value) || draftValue.postSiteGroups.map((group: StringMultiSelectOption) => group.value);
        const siteGroups = await Promise.all(siteGroupIds?.map(async (id) => SiteGroupApi.findById({ id })));
        if (siteGroups) {
            const siteIdList2d = await Promise.all(siteGroups.map(async (group) => group?.sites.map((siteid) => siteid)));
            siteIdList = [...new Set(siteIdList2d.flat())];
        }

        if (siteIdList) {
            siteIdList.map(async (siteId) => {
                if (typeof siteId !== "undefined") {
                    const currentSite = await SiteApi.findById({ id: siteId });
                    if (currentSite) {
                        const currentSitemap = currentSite.sitemap;
                        const commonUrl = currentSitemap.find((page) => {
                            if (page.options.friendlyUrl && page.options.pageId !== pageId) {
                                return page.options.friendlyUrl === friendlyUrl;
                            } else if (page.options.localizedFriendlyUrl?.length && page.options.pageId !== pageId) {
                                return page.options.localizedFriendlyUrl[0].friendlyUrl === friendlyUrl;
                            }
                        });
                        if (commonUrl) {
                            doesPermalinkExist = true;
                        }
                    }
                }
            });
        }
    } else {
        if (selectedSite) {
            const currentSitemap = selectedSite.sitemap;
            const commonUrl = currentSitemap.find((page) => {
                if (page.options.friendlyUrl && page.options.pageId !== pageId) {
                    return page.options.friendlyUrl === friendlyUrl;
                } else if (page.options.localizedFriendlyUrl?.length && page.options.pageId !== pageId) {
                    return page.options.localizedFriendlyUrl[0].friendlyUrl === friendlyUrl;
                }
            });
            if (commonUrl) {
                doesPermalinkExist = true;
            }
        }
    }

    return doesPermalinkExist;
}

export function checkVisibilityOnPublishExpirationDate(clientNotLoggedInOnAdmin: boolean, cmsSettingZone: string, siteSettingZone: string, contentToBeChecked: any): boolean {
    const zone = (clientNotLoggedInOnAdmin ? siteSettingZone || cmsSettingZone : cmsSettingZone || siteSettingZone) || "Europe/Amsterdam";
    // converting back to the zone in which it was saved
    const momentPublishDate = moment(contentToBeChecked.publishDate).tz("Europe/Amsterdam");
    const publishDateClone = momentPublishDate.clone();
    const selectedPublishDate = publishDateClone.tz(zone, true).format("YYYY/MM/DD HH:mm:ss ZZ");
    const momentExpirationDate = moment(contentToBeChecked.expirationDate).tz("Europe/Amsterdam");
    const expirationDateClone = momentExpirationDate.clone();
    const selectedExpirationDate = expirationDateClone.tz(zone, true).format("YYYY/MM/DD HH:mm:ss ZZ");
    const momentLocalDate = moment(moment(), "YYYY/MM/DD HH:mm:ss ZZ");
    const diffDaysBtwLocalAndPublishDate = momentLocalDate.diff(momentPublishDate, "days");
    const diffDaysBtwLocalAndExpirationDate = momentLocalDate.diff(momentExpirationDate, "days");
    // if difference is less than 1 day we need to convert local date to zone's date
    if (diffDaysBtwLocalAndPublishDate === 0 || diffDaysBtwLocalAndExpirationDate === 0) {
        // converted local date to selected timezone
        const convertLocalDateAsPerZone = moment.tz(zone).format("YYYY/MM/DD HH:mm:ss ZZ");
        if (
            (contentToBeChecked &&
                contentToBeChecked.isPublishDateConfigured &&
                selectedPublishDate &&
                moment(convertLocalDateAsPerZone, "YYYY/MM/DD HH:mm:ss ZZ").isBefore(moment(selectedPublishDate, "YYYY/MM/DD HH:mm:ss ZZ"))) ||
            (contentToBeChecked &&
                contentToBeChecked.isExpirationDateConfigured &&
                selectedExpirationDate &&
                moment(convertLocalDateAsPerZone, "YYYY/MM/DD HH:mm:ss ZZ").isAfter(moment(selectedExpirationDate, "YYYY/MM/DD HH:mm:ss ZZ")))
        ) {
            return false;
        }
    } else if (
        contentToBeChecked &&
        ((contentToBeChecked.isPublishDateConfigured && contentToBeChecked.publishDate && moment().isBefore(moment(contentToBeChecked.publishDate))) ||
            (contentToBeChecked.isExpirationDateConfigured && contentToBeChecked.expirationDate && moment().isAfter(moment(contentToBeChecked.expirationDate))))
    ) {
        return false;
    }
    return true;
}

export const setOpacityOnHide = (options: any, hideContent?: boolean): string => (options?.showDetail || hideContent ? "disable-page-content" : "");

export const isWidgetHidden = (options: any): boolean => !!setOpacityOnHide(options) && !isClientLoggedIn();

let invalidTokenAlertDebounce: ReturnType<typeof setTimeout> | undefined;

export function onInvalidToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN);
    removeUserLoggedInCookie();
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CLIENT_NAME);
    if (!invalidTokenAlertDebounce) {
        invalidTokenAlertDebounce = setTimeout(() => (invalidTokenAlertDebounce = undefined), 10000);
        alert(getI18nLocaleStringFromParams(getI18nLocaleObject(namespaceList.admin, "loggedOut")));
        location.reload();
    }
}

export function isClientLoggedIn(): boolean {
    return !!(typeof localStorage !== "undefined" && localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN));
}

export function isAdminSidePage(): boolean {
    return isClientSide() && !!document.querySelector(".backend");
}

export const shouldDisableLink = (isFrontEndEditable: boolean): boolean => isClientLoggedIn() && !isAdminSidePage() && !!isFrontEndEditable;

// TODO when we move to the new availability endpoints (and we should), this sorting isn't required anymore: MCMS-3535
// eslint-disable-next-line max-lines-per-function
export function sortResults(resources: any[], sortingOption: string, allResources?: MXTS.Resource[], isResource = false, priceRangeCriteria?: string): any[] {
    // eslint-disable-next-line max-lines-per-function
    return resources.sort((r1: any, r2: any) => {
        if (sortingOption === Sort[Sort.highToLowPrice]) {
            // For locations
            if (resources.some((resource) => resource.curatedAccommodation)) {
                if (r1.curatedAccommodation.priceInclusive > r2.curatedAccommodation.priceInclusive) {
                    return -1;
                }
                if (r2.curatedAccommodation.priceInclusive > r1.curatedAccommodation.priceInclusive) {
                    return 1;
                }
            }
            if (priceRangeCriteria) {
                if (r1[priceRangeCriteria] > r2[priceRangeCriteria]) {
                    return -1;
                }
                if (r1[priceRangeCriteria] < r2[priceRangeCriteria]) {
                    return 1;
                }
            }
            // For Castle the night price field is different hence, checking it here
            if (r1.unitBaseNightPriceInclusive && r2.unitBaseNightPriceInclusive) {
                // If offer price is less than base price, sorting based on offer price
                if (r1.unitNightPriceInclusive && r2.unitNightPriceInclusive) {
                    if (r1.unitNightPriceInclusive > r2.unitNightPriceInclusive) {
                        return -1;
                    }
                    if (r1.unitNightPriceInclusive < r2.unitNightPriceInclusive) {
                        return 1;
                    }
                    // rating high to low secondary sort
                    if (r1.unitNightPriceInclusive === r2.unitNightPriceInclusive) {
                        return secondarySortBasedOnRatings(r1, r2, allResources, isResource);
                    }
                }
            } else if (resources.some((element) => element.basePriceInclusive !== element.priceInclusive)) {
                // if special is applied then sort with priceInclusive (the price which is not strike out)
                if (r1.priceInclusive > r2.priceInclusive) {
                    return -1;
                }
                if (r1.priceInclusive < r2.priceInclusive) {
                    return 1;
                }
                // rating high to low secondary sort
                if (r1.priceInclusive === r2.priceInclusive) {
                    return secondarySortBasedOnRatings(r1, r2, allResources, isResource);
                }
            } else {
                // when special is not applied means we don't have any strike out price then sort with basePriceInclusive
                if (r1.basePriceInclusive > r2.basePriceInclusive) {
                    return -1;
                }
                if (r1.basePriceInclusive < r2.basePriceInclusive) {
                    return 1;
                }
                // rating high to low secondary sort
                if (r1.basePriceInclusive === r2.basePriceInclusive) {
                    return secondarySortBasedOnRatings(r1, r2, allResources, isResource);
                }
            }
        } else if (sortingOption === Sort[Sort.lowToHighPrice]) {
            // For locations
            if (resources.some((resource) => resource.curatedAccommodation)) {
                if (r1.curatedAccommodation.priceInclusive > r2.curatedAccommodation.priceInclusive) {
                    return 1;
                }
                if (r2.curatedAccommodation.priceInclusive > r1.curatedAccommodation.priceInclusive) {
                    return -1;
                }
            }

            if (r1.unitBaseNightPriceInclusive && r2.unitBaseNightPriceInclusive) {
                if (r1.unitNightPriceInclusive && r2.unitNightPriceInclusive) {
                    if (r1.unitNightPriceInclusive > r2.unitNightPriceInclusive) {
                        return 1;
                    }
                    if (r1.unitNightPriceInclusive < r2.unitNightPriceInclusive) {
                        return -1;
                    }
                    // rating high to low secondary sort
                    if (r1.unitNightPriceInclusive === r2.unitNightPriceInclusive) {
                        return secondarySortBasedOnRatings(r1, r2, allResources, isResource);
                    }
                }
            } else if (resources.some((element) => element.basePriceInclusive !== element.priceInclusive)) {
                // if special is applied then sort with priceInclusive (the price which is not strike out)
                if (r1.priceInclusive > r2.priceInclusive) {
                    return 1;
                }
                if (r1.priceInclusive < r2.priceInclusive) {
                    return -1;
                }
                // rating high to low secondary sort
                if (r1.unitNightPriceInclusive === r2.unitNightPriceInclusive) {
                    return secondarySortBasedOnRatings(r1, r2, allResources, isResource);
                }
            } else {
                // when special is not applied means we don't have any strike out price then sort with basePriceInclusive
                if (r1.basePriceInclusive > r2.basePriceInclusive) {
                    return 1;
                }
                if (r1.basePriceInclusive < r2.basePriceInclusive) {
                    return -1;
                }
                // rating high to low secondary secondary sort
                if (r1.unitNightPriceInclusive === r2.unitNightPriceInclusive) {
                    if (allResources || isResource) {
                        // For types
                        if (r1.resourceRating > r2.resourceRating) {
                            return -1;
                        }
                        if (r1.resourceRating < r2.resourceRating) {
                            return 1;
                        }
                    } else {
                        // For units
                        if (r1.unitRating > r2.unitRating) {
                            return -1;
                        }
                        if (r1.unitRating < r2.unitRating) {
                            return 1;
                        }
                    }
                    return 0;
                }
            }
        } else if (sortingOption === Sort[Sort.priority]) {
            const r1Priority = r1!.priority || r1!.resortPriority;
            const r2Priority = r1!.priority || r2!.resortPriority;
            if (r1Priority > r2Priority) {
                return 1;
            }
            if (r1Priority < r2Priority) {
                return -1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.lowToHighRating]) {
            if (allResources || isResource) {
                // For locations
                if (resources.some((resource) => resource.curatedAccommodation)) {
                    if (r1.curatedAccommodation.resortRating > r2.curatedAccommodation.resortRating) {
                        return 1;
                    }
                    if (r2.curatedAccommodation.resortRating > r1.curatedAccommodation.resortRating) {
                        return -1;
                    }
                }
                // For types
                if (r1.resourceRating > r2.resourceRating) {
                    return 1;
                }
                if (r1.resourceRating < r2.resourceRating) {
                    return -1;
                }
                // Price low to high secondary sort
                if (r1.resourceRating === r2.resourceRating) {
                    if (r1.unitNightPriceInclusive && r2.unitNightPriceInclusive) {
                        if (r1.unitNightPriceInclusive > r2.unitNightPriceInclusive) {
                            return 1;
                        }
                        if (r1.unitNightPriceInclusive < r2.unitNightPriceInclusive) {
                            return -1;
                        }
                    } else if (resources.some((element) => element.basePriceInclusive !== element.priceInclusive)) {
                        // if special is applied then sort with priceInclusive (the price which is not strike out)
                        if (r1.priceInclusive > r2.priceInclusive) {
                            return 1;
                        }
                        if (r1.priceInclusive < r2.priceInclusive) {
                            return -1;
                        }
                    } else {
                        // when special is not applied means we don't have any strike out price then sort with basePriceInclusive
                        if (r1.basePriceInclusive > r2.basePriceInclusive) {
                            return 1;
                        }
                        if (r1.basePriceInclusive < r2.basePriceInclusive) {
                            return -1;
                        }
                    }
                    return 0;
                }
            } else {
                // For units
                if (r1.unitRating > r2.unitRating) {
                    return 1;
                }
                if (r1.unitRating < r2.unitRating) {
                    return -1;
                }
                // Price low to high secondary sort
                if (r1.unitRating === r2.unitRating) {
                    if (r1.unitNightPriceInclusive && r2.unitNightPriceInclusive) {
                        if (r1.unitNightPriceInclusive > r2.unitNightPriceInclusive) {
                            return 1;
                        }
                        if (r1.unitNightPriceInclusive < r2.unitNightPriceInclusive) {
                            return -1;
                        }
                    } else if (resources.some((element) => element.basePriceInclusive !== element.priceInclusive)) {
                        // if special is applied then sort with priceInclusive (the price which is not strike out)
                        if (r1.priceInclusive > r2.priceInclusive) {
                            return 1;
                        }
                        if (r1.priceInclusive < r2.priceInclusive) {
                            return -1;
                        }
                    } else {
                        // when special is not applied means we don't have any strike out price then sort with basePriceInclusive
                        if (r1.basePriceInclusive > r2.basePriceInclusive) {
                            return 1;
                        }
                        if (r1.basePriceInclusive < r2.basePriceInclusive) {
                            return -1;
                        }
                    }
                    return 0;
                }
            }
        } else if (sortingOption === Sort[Sort.highToLowRating]) {
            if (allResources || isResource) {
                // For locations
                if (resources.some((resource) => resource.curatedAccommodation)) {
                    if (r1.curatedAccommodation.resortRating > r2.curatedAccommodation.resortRating) {
                        return -1;
                    }
                    if (r2.curatedAccommodation.resortRating > r1.curatedAccommodation.resortRating) {
                        return 1;
                    }
                }
                // For types
                if (r1.resourceRating > r2.resourceRating) {
                    return -1;
                }
                if (r1.resourceRating < r2.resourceRating) {
                    return 1;
                }
                // price low to high secondary sort
                if (r1.resourceRating === r2.resourceRating) {
                    if (r1.unitNightPriceInclusive && r2.unitNightPriceInclusive) {
                        if (r1.unitNightPriceInclusive > r2.unitNightPriceInclusive) {
                            return 1;
                        }
                        if (r1.unitNightPriceInclusive < r2.unitNightPriceInclusive) {
                            return -1;
                        }
                    } else if (resources.some((element) => element.basePriceInclusive !== element.priceInclusive)) {
                        // if special is applied then sort with priceInclusive (the price which is not strike out)
                        if (r1.priceInclusive > r2.priceInclusive) {
                            return 1;
                        }
                        if (r1.priceInclusive < r2.priceInclusive) {
                            return -1;
                        }
                    } else {
                        // when special is not applied means we don't have any strike out price then sort with basePriceInclusive
                        if (r1.basePriceInclusive > r2.basePriceInclusive) {
                            return 1;
                        }
                        if (r1.basePriceInclusive < r2.basePriceInclusive) {
                            return -1;
                        }
                    }
                    return 0;
                }
            } else {
                // For units
                if (r1.unitRating > r2.unitRating) {
                    return -1;
                }
                if (r1.unitRating < r2.unitRating) {
                    return 1;
                }
                // Price low to high secondary sort
                if (r1.unitRating === r2.unitRating) {
                    if (r1.unitNightPriceInclusive && r2.unitNightPriceInclusive) {
                        if (r1.unitNightPriceInclusive > r2.unitNightPriceInclusive) {
                            return 1;
                        }
                        if (r1.unitNightPriceInclusive < r2.unitNightPriceInclusive) {
                            return -1;
                        }
                    } else if (resources.some((element) => element.basePriceInclusive !== element.priceInclusive)) {
                        // if special is applied then sort with priceInclusive (the price which is not strike out)
                        if (r1.priceInclusive > r2.priceInclusive) {
                            return 1;
                        }
                        if (r1.priceInclusive < r2.priceInclusive) {
                            return -1;
                        }
                    } else {
                        // when special is not applied means we don't have any strike out price then sort with basePriceInclusive
                        if (r1.basePriceInclusive > r2.basePriceInclusive) {
                            return 1;
                        }
                        if (r1.basePriceInclusive < r2.basePriceInclusive) {
                            return -1;
                        }
                    }
                    return 0;
                }
            }
        } else if (sortingOption === Sort[Sort.ascendingName]) {
            if (allResources || isResource) {
                if (allResources) {
                    allResources.forEach((resource: any) => {
                        if (r1.resourceId === resource.resourceId) {
                            r1 = resource;
                        }
                        if (r2.resourceId === resource.resourceId) {
                            r2 = resource;
                        }
                    });
                }
            }
            if (r1!.name > r2!.name) {
                return 1;
            }
            if (r1!.name < r2!.name) {
                return -1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.descendingName]) {
            if (allResources || isResource) {
                if (allResources) {
                    allResources.forEach((resource: any) => {
                        if (r1.resourceId === resource.resourceId) {
                            r1 = resource;
                        }
                        if (r2.resourceId === resource.resourceId) {
                            r2 = resource;
                        }
                    });
                }
            }
            if (r1!.name > r2!.name) {
                return -1;
            }
            if (r1!.name < r2!.name) {
                return 1;
            }
            return 0;
        } else if (sortingOption === Sort[Sort.ascendingArrivalDate]) {
            const r1ArrivalDate = r1.arrivalDate || r1.date || r1.curatedAccommodation.arrivalDate;
            const r2ArrivalDate = r2.arrivalDate || r2.date || r2.curatedAccommodation.arrivalDate;
            if (r1ArrivalDate && r2ArrivalDate) {
                if (new Date(r1ArrivalDate) < new Date(r2ArrivalDate)) {
                    return -1;
                }
                if (new Date(r1ArrivalDate) > new Date(r2ArrivalDate)) {
                    return 1;
                }
                return 0;
            }
        } else if (sortingOption === Sort[Sort.descendingArrivalDate]) {
            const r1ArrivalDate = r1.arrivalDate || r1.date || r1.curatedAccommodation.arrivalDate;
            const r2ArrivalDate = r2.arrivalDate || r2.date || r2.curatedAccommodation.arrivalDate;
            if (r1ArrivalDate && r2ArrivalDate) {
                if (new Date(r1ArrivalDate) > new Date(r2ArrivalDate)) {
                    return -1;
                }
                if (new Date(r1ArrivalDate) < new Date(r2ArrivalDate)) {
                    return 1;
                }
                return 0;
            }
        } else if (sortingOption === Sort[Sort.minToMaxDuration]) {
            const r1Duration = r1.duration || r1.curatedAccommodation.duration;
            const r2Duration = r2.duration || r2.curatedAccommodation.duration;
            if (r1Duration?.[0] && r2Duration?.[0]) {
                if (r1Duration[0] < r2Duration[0]) {
                    return -1;
                }
                if (r1Duration[0] > r2Duration[0]) {
                    return 1;
                }
                return 0;
            }
        } else if (sortingOption === Sort[Sort.maxToMinDuration]) {
            const r1Duration = r1.duration || r1.curatedAccommodation.duration;
            const r2Duration = r2.duration || r2.curatedAccommodation.duration;
            if (r1Duration?.[0] && r2Duration?.[0]) {
                // For types and units
                if (r1Duration[0] > r2Duration[0]) {
                    return -1;
                }
                if (r1Duration[0] < r2Duration[0]) {
                    return 1;
                }
                return 0;
            }
        } else if (sortingOption === Sort[Sort.lowToHighCapacity]) {
            // For locations
            if (resources.some((resource) => resource.curatedAccommodation)) {
                if (r1.curatedAccommodation.totalCapacity < r2.curatedAccommodation.totalCapacity) {
                    return -1;
                }
                if (r1.curatedAccommodation.totalCapacity > r2.curatedAccommodation.totalCapacity) {
                    return 1;
                }
            }

            if (r1.totalCapacity && r2.totalCapacity) {
                if (r1.totalCapacity < r2.totalCapacity) {
                    return -1;
                }
                if (r1.totalCapacity > r2.totalCapacity) {
                    return 1;
                }
                // rating high to low secondary sort
                if (r1.totalCapacity === r2.totalCapacity) {
                    return secondarySortBasedOnRatings(r1, r2, allResources, isResource);
                }
            }
        } else if (sortingOption === Sort[Sort.highToLowCapacity]) {
            // For locations
            if (resources.some((resource) => resource.curatedAccommodation)) {
                if (r1.curatedAccommodation.totalCapacity > r2.curatedAccommodation.totalCapacity) {
                    return -1;
                }
                if (r1.curatedAccommodation.totalCapacity < r2.curatedAccommodation.totalCapacity) {
                    return 1;
                }
            }

            if (r1.totalCapacity && r2.totalCapacity) {
                if (r1.totalCapacity > r2.totalCapacity) {
                    return -1;
                }
                if (r1.totalCapacity < r2.totalCapacity) {
                    return 1;
                }
                // rating high to low secondary sort
                if (r1.totalCapacity === r2.totalCapacity) {
                    return secondarySortBasedOnRatings(r1, r2, allResources, isResource);
                }
            }
        }
        return 0;
    });
}

function secondarySortBasedOnRatings(r1: any, r2: any, allResources?: MXTS.Resource[], isResource?: boolean) {
    if (allResources || isResource) {
        // For types
        if (r1.resourceRating > r2.resourceRating) {
            return -1;
        }
        if (r1.resourceRating < r2.resourceRating) {
            return 1;
        }
    } else {
        // For units
        if (r1.unitRating > r2.unitRating) {
            return -1;
        }
        if (r1.unitRating < r2.unitRating) {
            return 1;
        }
    }
    return 0;
}

export function onUnload(ev: any): void {
    ev.preventDefault();
    ev.returnValue = "";
}

export function resortAutocomplete<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, isNotDefaultVisible?: boolean): InputSpecAuto<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicResort, "resorts"),
        type: "autocomplete",
        isClearable: true,
        async options(): Promise<Array<SelectOption<any>>> {
            return resortGetSingleOptions(MXTS.MxtsApi);
        },
        placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "resortsPlaceHolder"),
        visible: (options: any) => {
            if (isNotDefaultVisible) {
                return options.useResortForSubjects;
            } else if (options.contentTypes) {
                if (Array.isArray(options.contentTypes)) {
                    let selected = false;
                    options.contentTypes.forEach((contentType: any) => {
                        if (contentType.value === ContentType.RESORT) {
                            selected = true;
                        }
                    });
                    return selected;
                }
                return options.contentType === RESORT;
            }
            return true;
        },
    };
}

export function amenityCategoryMultiSelect<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, visible?: (options: E) => boolean): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "amenityCategoriesSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return categoryGetSingleOptions(MXTS.MxtsApi);
        },
        placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "amenityCategoriesSelector"),
        visible,
    };
}

export function amenityMultiSelect<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, setVisibility?: boolean): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "amenityMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any> {
            return getAmenitiesAutocompleteList(MXTS.MxtsApi);
        },
        placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "selectAmenityMultiSelector"),
        visible: setVisibility ? (options: any) => options.preFilteredAvailability : undefined,
    };
}

export function defaultStayAutoComplete<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, visible?: (options: E) => boolean): InputSpecAuto<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "defaultStay"),
        type: "autocomplete",
        isClearable: true,
        async options(): Promise<Array<SelectOption<any>>> {
            return stayPeriodDefOptions(MXTS.MxtsApi);
        },
        visible,
    };
}
export function minCapacityField<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, visible?: (options: E) => boolean): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.widgetTypeSearch, "minCapacity"),
        type: "text",
        visible,
    };
}
export function readOnlyField<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSimple<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.pluginForm, "readOnly"),
        type: "checkbox",
    };
}
export async function stayPeriodDefOptions(mxtsApi: MXTS.MxtsApiWrapper): Promise<Array<SelectOption<any>>> {
    const ops = await getAdminMxtsEnv();
    const stayPeriodDefs = await mxtsApi.stayPeriodDefs(ops, { size: 999 }).then((pagedResult) => pagedResult.content);
    const options: Array<SelectOption<any>> = stayPeriodDefs.map(
        (stayPeriodDef: MXTS.StayPeriodDef): SelectOption<any> => ({
            value: stayPeriodDef.stayPeriodDefId,
            label: `${stayPeriodDef.name} (Id: ${stayPeriodDef.stayPeriodDefId})`,
        })
    );
    (options as any).unshift({
        value: null,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "none"),
    });
    return options;
}

export async function resortGetSingleOptions(mxtsApi: MXTS.MxtsApiWrapper): Promise<Array<SelectOption<any>>> {
    const ops = await getAdminMxtsEnv();
    const resorts = await mxtsApi.resorts(ops, { size: 999 }).then((pagedResult) => pagedResult.content);
    const options: Array<SelectOption<any>> = resorts.map(
        (resort: MXTS.Resort): SelectOption<any> => ({
            value: resort.resortId,
            label: `${resort.name} (Id: ${resort.resortId})`,
        })
    );
    (options as any).unshift({
        value: null,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "none"),
    });
    return options;
}

export function removeDuplicatesFromArray<T>(arrayWithDuplicates: T[]): T[] {
    return Array.from(new Set(arrayWithDuplicates));
}

export async function categoryGetSingleOptions(mxtsApi: MXTS.MxtsApiWrapper): Promise<NumberMultiSelectOption[]> {
    const categories = await getAmenityCategories(mxtsApi);
    const options = categories.map((category: any) => ({
        value: category.amenityCategoryId,
        text: category.name,
    }));
    return options;
}

export async function getAmenitiesAutocompleteList(mxtsApi: MXTS.MxtsApiWrapper): Promise<StringMultiSelectOption[]> {
    const amenities = await getamenityList(mxtsApi);
    const options = amenities.map((amenity: MXTS.Amenity) => ({
        value: amenity.identifier,
        text: amenity.name,
    }));
    return options;
}

export async function getAmenityById(amenitiesId: number, context: CMSProviderProperties) {
    const env = await getMxtsEnv(context);
    const amenities = context.mxtsApi.amenities(env, { amenityIds: [amenitiesId.toString()] });
    return amenities;
}

async function getamenityList(mxtsApi: MXTS.MxtsApiWrapper): Promise<MXTS.Amenity[]> {
    const ops = await getAdminMxtsEnv();
    const amenities = await mxtsApi.amenities(ops, { size: MXTS_CONSTANTS.MAX_RESULTS });
    return amenities.content;
}

export async function getAmenityCategories(mxtsApi: MXTS.MxtsApiWrapper, targetEnv?: MXTS.ApiCallOptions, amenityCategoryIds?: number[]): Promise<MXTS.AmenityCategory[]> {
    const ops = targetEnv ? targetEnv : await getAdminMxtsEnv();
    const categories = await mxtsApi
        .amenityCategories(ops, {
            size: 2000,
            amenityCategoryId: amenityCategoryIds,
            isInternalUse: false,
            view: "detail",
            sort: "priority",
        })
        .then((cat: MXTS.PagedResult<MXTS.AmenityCategory>) => cat.content);
    return categories;
}

export async function getAmenitiesByCategory(mxtsApi: MXTS.MxtsApiWrapper, categoryId: number, signal?: AbortSignal, targetEnv?: MXTS.ApiCallOptions): Promise<MXTS.Amenity[]> {
    const env = targetEnv ? targetEnv : await getAdminMxtsEnv();
    const amenities = await mxtsApi.amenitiesByCategory(env, { size: 200, view: "detail" }, [{ key: "categoryId", value: categoryId }], signal).then((category) => category.content);
    return amenities;
}

export function getUUID() {
    return Math.floor(Math.random() * 1e16);
}

export async function getSelectedAmenities(mxtsApi: MXTS.MxtsApiWrapper, selectedAmenityCategories: any[], signal?: AbortSignal, env?: MXTS.ApiCallOptions): Promise<MXTS.AmenityCategory[]> {
    const amenityCategories: MXTS.AmenityCategory[] = [];
    for (const singleCategory of selectedAmenityCategories) {
        const amenitiesFromACategory: any = {};
        amenitiesFromACategory.amenities = await getAmenitiesByCategory(mxtsApi, singleCategory.value, signal, env);
        amenitiesFromACategory.name = ((singleCategory as any) as { label: string; value: number }).label;
        amenitiesFromACategory.amenityCategoryId = singleCategory.value;
        amenityCategories.push(amenitiesFromACategory);
    }
    return amenityCategories;
}

export async function getRequiredFieldSet(apiContext: ApiContext, dcId: number): Promise<Partial<MXTS.RequiredFieldSet>> {
    const env = await getMxtsEnv(apiContext);
    const requiredFieldSet: MXTS.RequiredFieldSet | undefined = await apiContext.mxtsApi.requiredFieldSet(env, { type: "customer" }, [{ key: "dcId", value: dcId }]).catch(() => undefined);
    const requiredFields: Partial<MXTS.RequiredFieldSet> = { ...(requiredFieldSet || {}) };
    delete requiredFields.requiredFieldsId;
    delete requiredFields.name;
    return requiredFields;
}

export async function getMandatoryFields(apiContext: ApiContext, distributionChannelId?: number): Promise<string[]> {
    if (!distributionChannelId) {
        return [];
    }
    const cmsOptions = await getCMSOptions(apiContext.cmsApi);
    const fields: Partial<MXTS.RequiredFieldSet> = !cmsOptions.disableRequiredFieldSet ? await getRequiredFieldSet(apiContext, distributionChannelId) : {};
    return Object.keys(fields).filter((key: string) => `${fields[key as keyof MXTS.RequiredFieldSet]}` === "true");
}

export function getValidationMessage(props: any, spec: any): JSX.Element | null {
    if (props.validations && props.validations[spec.variable] && !props.validations[spec.variable].isValid) {
        return <p className="input-validation-message">{props.validations[spec.variable].message}</p>;
    }
    return null;
}

export function dispatchEmptyAction(dispatchAction: Dispatch<FilterChangeAction>) {
    const action = {
        type: ActionType.FilterChange,
        filter: dynamicFilterType.emptyDispatch,
        payload: {},
    };
    dispatchAction(action);
}

export function getStartEndDateSubjectsForPrices(dynamicFilter: DynamicFilter): { startDate: string; endDate: string; subjects: MXTS.SubjectQuantity[] } {
    // Convert to Client timezone + UTC and send to Price engine
    const startDateWithTimeZone = moment(dynamicFilter.startdate, DATE_FORMAT.DISPLAY);
    const endDateWithTimeZone = moment(dynamicFilter.enddate, DATE_FORMAT.DISPLAY);

    // Subjects to array
    const subjects: MXTS.SubjectQuantity[] = [];
    if (dynamicFilter.subject) {
        dynamicFilter.subject.forEach((value: number, key: number) => {
            if (value > 0) {
                subjects.push({
                    subjectId: key,
                    quantity: value,
                });
            }
        });
    }
    return {
        startDate: startDateWithTimeZone.format(DATE_FORMAT.ELASTIC),
        endDate: endDateWithTimeZone.format(DATE_FORMAT.ELASTIC),
        subjects,
    };
}

export async function getMarketingGroups(apiContext: ApiContext, selectedGroups: number[], targetEnv?: MXTS.ApiCallOptions): Promise<MXTS.AdditionGroup[]> {
    const env = targetEnv ? targetEnv : await getAdminMxtsEnv();
    const groups: MXTS.AdditionGroup[] = await apiContext.mxtsApi
        .groups(env, {
            size: MXTS_CONSTANTS.MAX_RESULTS,
            type: "marketing",
            view: "detail",
            sort: "priority",
        })
        .then((res: MXTS.PagedResult<MXTS.AdditionGroup>) => res.content)
        .catch(() => []);
    const selectedMarketingGroups = groups.filter((gr) => selectedGroups.indexOf(gr.groupId) > -1) || [];
    let count = 0;
    for (let group of selectedMarketingGroups) {
        const image = await getImages(apiContext, group.imageManagerId, undefined, undefined, undefined, 1);
        group = { ...group, image: image ? image[0] : undefined };
        selectedMarketingGroups[count++] = group;
    }
    return selectedMarketingGroups;
}

export async function getMarketingGroupSelectOptions(apiContext: ApiContext, targetEnv?: MXTS.ApiCallOptions): Promise<Array<{ value: number; text: string }>> {
    const env = targetEnv ? targetEnv : await getAdminMxtsEnv();
    const groups: MXTS.AdditionGroup[] = await apiContext.mxtsApi
        .groups(env, {
            size: MXTS_CONSTANTS.MAX_RESULTS,
            type: "marketing",
            view: "detail",
            sort: "priority",
        })
        .then((res: MXTS.PagedResult<MXTS.AdditionGroup>) => res.content)
        .catch(() => []);
    return groups.map((gr: MXTS.AdditionGroup) => ({ value: gr.groupId, text: gr.name }));
}

export async function getNoDataFoundTemplate(templateId: string, context: any): Promise<JSX.Element[] | null> {
    if (templateId) {
        const template = await TemplateApi.findById({ id: templateId });
        if (template) {
            return await renderPageWidgets(template.root, context);
        }
    }
    return null;
}

export async function getNoDataFoundContent(webContentId: string): Promise<(WebContent & WithId) | null> {
    if (webContentId) {
        return WebContentApi.findById({ id: webContentId });
    }
    return null;
}

/**
 * Used to check wheather the parentId for a category should be available or not.
 */
export function hideParentCategoryId(id: string): boolean {
    const categoryTypesWithoutParentId = ["page", "results-panel"];
    return categoryTypesWithoutParentId.includes(id);
}

export function getHideWidgetClass(options: any, disableWidget: boolean | undefined, hideContent?: boolean): string | null {
    const hideWidget = hideContent ? setOpacityOnHide(options, hideContent) : setOpacityOnHide(options);
    if ((disableWidget && hideWidget) || (!disableWidget && hideWidget && localStorage.getItem("isFrontEndEditable") === "false")) {
        return null;
    }
    return hideWidget;
}

export const getModalLabelOptions = (): Array<SomeInputSpec<any, any>> => [
    {
        variable: "enableModalLabel",
        label: getI18nLocaleObject(namespaceList.admin, "enableModalLabel"),
        type: "checkbox",
    },
    localized({
        variable: "localizedModalLabel",
        tabContent: [
            {
                label: getI18nLocaleObject(namespaceList.admin, "localizedModalLabel"),
                variable: "labelText",
                type: "text",
            },
        ],
        visible: (item) => item?.enableModalLabel,
    }),
];

export const getCustomLanguageCalendar = (): Array<SomeInputSpec<any, any>> => [
    {
        variable: "useCustomLocale",
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "useCustomLocale"),
        default: false,
        groupName: "customLanguageCalendarGroup",
        groupTitle: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "customLanguageCalendarGroupTitle"),
        type: "checkbox",
    },
    {
        variable: "localeForDatePicker",
        label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "localeForDatePicker"),
        type: "select",
        groupName: "customLanguageCalendarGroup",
        async optionList() {
            const localeNamesList: Locale[] = [];
            const languages: Language[] = [
                { name: "English", code: "en-gb" },
                { name: "French", code: "fr" },
                { name: "German", code: "de" },
                { name: "Dutch", code: "nl" },
            ];
            const LocaleLanguagesList: Array<SelectOption<string>> = [];
            const localeList = await LocaleApi.find();
            localeList.forEach((key) => {
                localeNamesList.push(key);
            });
            languages.map((language: Language) => {
                localeNamesList.map(
                    (locale: Locale) =>
                        locale.name !== language.name && LocaleLanguagesList.push({ label: `${locale.name} site with ${language.name} language`, value: `${locale.name}:${language.code} ` })
                );
            });
            return LocaleLanguagesList;
        },
        visible: (item: any) => !!item?.useCustomLocale,
    },
];

export function copyToClipboard(text: string) {
    if ("clipboard" in navigator) {
        return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
}

export function getMultiSelectPaymentTermNames(): StringMultiSelectOption[] {
    const multiSelectPaymentTermNames: StringMultiSelectOption[] = [];
    Object.values(MultiSelectPaymentTermNames).every((item) =>
        multiSelectPaymentTermNames.push({
            value: item,
            text: item,
        })
    );
    return multiSelectPaymentTermNames;
}

export const unitHasCurrentlyActiveContract = (contracts: MXTS.Contract[]) => {
    const filteredContract = contracts.filter((contract) => {
        const currentDate = moment();
        if (contract.ownershipStartDate || contract.ownershipEndDate) {
            const ownershipStartDate = moment(contract.ownershipStartDate || contract.startDate);
            const ownershipEndDate = moment(contract.ownershipEndDate || contract.endDate);
            const contractStartDate = moment(contract.startDate);
            const contractEndDate = moment(contract.endDate);
            return currentDate.isBetween(ownershipStartDate, ownershipEndDate, null, "[]") && currentDate.isBetween(contractStartDate, contractEndDate, null, "[]");
        }
        return false;
    });
    return !!filteredContract.length;
};
