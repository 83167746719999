import { Theme } from ".";

import baseTheme from "./base";

const theme: Theme = {
    id: "belterwiede",
    name: "Belterwiede Theme",
    stylesheets: ["/styles/themes/belterwiede/belterwiede-admin.css"],
    frontendStylesheets: ["/styles/themes/belterwiede/belterwiede.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "text-brandcolor",
            name: "Text as brand color",
            targets: ["webcontent"],
            classnames: ["text-brand-color"],
        },
        {
            id: "text-brandaltcolor",
            name: "Text as brand alt color",
            targets: ["webcontent"],
            classnames: ["text-brand-alt-color"],
        },
    ],
};

export default theme;
