import { FrontendPageEditAction, FrontendPageEditActionType } from "../actions/frontendPageEditAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface FrontendPageEditState {
    isFrontEndEditable?: boolean;
    areFrontendWidgetsEdited?: boolean;
    isFrontendEditedWidgetsReset?: boolean;
}

export const frontendPageEditReducer: Reducer<FrontendPageEditState> = (state: FrontendPageEditState = {}, action: FrontendPageEditAction): FrontendPageEditState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.FrontendPageEditPage:
            switch (actionType) {
                case FrontendPageEditActionType.SET_IS_PAGE_ENABLE_EDIT:
                    localStorage.setItem("isFrontEndEditable", "" + !!action.payload.isFrontEndEditable);
                    return {
                        ...state,
                        isFrontEndEditable: action.payload.isFrontEndEditable,
                    };
                case FrontendPageEditActionType.SET_FRONTEND_EDITED_WIDGETS_STATUS:
                    return {
                        ...state,
                        areFrontendWidgetsEdited: action.payload.areFrontendWidgetsEdited,
                    };
                case FrontendPageEditActionType.CLEAR_FRONTEND_EDITED_WIDGETS:
                    return {
                        ...state,
                        isFrontendEditedWidgetsReset: action.payload.isFrontendEditedWidgetsReset,
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
};
