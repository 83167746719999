import * as moment from "moment";

import {
    Activity,
    ActivityLocationOptions,
    ActivitySectionsWithTimeSlots,
    ActivityStockResult,
    DaysChunks,
    ResourceActivitiesDetailsWithDynamicField,
    SubjectQuantityWithDetailsId,
} from "./activityPlanner.types";
import {
    ActivityAreaResourcesResponse,
    ActivityReservabilities,
    ActivityStatus,
    AddOnPricesRequest,
    ApiCallOptions,
    AreaType,
    EsReservationResult,
    ImageDetailedView,
    MxtsApi,
    MxtsApiWrapper,
    PriceEngineAddOn,
    ReservedResource,
    ResourceActivitiesDetailsRequest,
    ResourceActivitiesDetailsResponse,
    ResourceActivitiesRequest,
    ResourceActivityWithDynamicField,
    ScheduledActivitiesSubjectsRequest,
    ScheduledActivitiesSubjectsResponse,
    SearchResourceActivitiesRequest,
    SearchResourceActivitiesResponse,
    SubjectQuantity,
    SupplierAddOnPricesRequest,
    TicketingType,
    getAllIds,
} from "@maxxton/cms-mxts-api";
import { ActivityInfo, DefaultDate, TicketingTypes } from "./activityPlanner.enum";
import { CMSProvidedProperties, CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT, MXTS } from "../../../utils/constants";
import { DYNAMIC_FIELD_CODE, LOCATION, RESORT, SIGN_UP, allSites } from "../../../components/utils";
import { SomeInputSpec, localized } from "../../../form-specs";
import { StructureCalendar, WholeMonthData } from "./activityCalendar";
import { chunk, cloneDeep } from "lodash";

import { ActionType } from "../../../redux/actions";
import { ActivityPlannerBaseProps } from "./activityPlanner";
import { AddOnInfo } from "../../dynamic/add-ons/AddOns.types";
import { AddOnViewStyle } from "../../dynamic/add-ons/addOns.util";
import { WidgetOptions as CRPActivityWidgetOptions } from "../../dynamic/activitySearchContainer/";
import { DateMap } from "../../mxts/mxts.types";
import { DateUtil } from "../../../utils/date.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { ReservationKind } from "../../dynamic/reservation/reservation.enum";
import { SelectedActivity } from "../ticketCounter/TicketCounter";
import { Sort } from "../../mxts/searchfacet/searchFacet.enum";
import { TimeslotStatus } from "../../shared/activityTimeslot/ActivityTimeslot.enum";
import { Widget } from "../../widget";
import { WidgetOptions } from "./";
import { createDateMap } from "../../mxts/mxts.util";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getI18nLocaleObject } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";
import { procureResortIds } from "../../../utils/resort.util";
import { reportError } from "../../../utils/report.utils";

export const getActivityLocationArea = <T extends ActivityLocationOptions>(
    isLocationVisible?: (options: T) => boolean,
    isSignupAreaVisible?: (options: T) => boolean
): Array<SomeInputSpec<T, keyof T>> => [
    localized({
        variable: "changeDefaultActivityAreaLabel",
        visible: isLocationVisible,
        tabContent: [
            {
                variable: "activityAreaText",
                label: getI18nLocaleObject(namespaceList.admin, "activityAreaText"),
                type: "text",
            },
        ],
    }),
    localized({
        variable: "changeDefaultActivitySignUpAreaLabel",
        visible: isSignupAreaVisible,
        tabContent: [
            {
                variable: "activitySignUpAreaText",
                label: getI18nLocaleObject(namespaceList.admin, "activitySignUpAreaText"),
                type: "text",
            },
        ],
    }),
];

export const getUniqueListBy = (arr: any[], key: string): ImageDetailedView[] => [...new Map(arr.map((item) => [item[key], item])).values()];

/**
 * @param {Date} start - example new Date("2018-05-01")
 * @param {Date} end - example new Date("2018-05-05")
 * */
export const getDaysArray = (start: Date, end: Date) => {
    const arr = [];
    for (let startDate = new Date(start); startDate <= new Date(end); startDate.setDate(startDate.getDate() + 1)) {
        arr.push(new Date(startDate));
    }
    return arr;
};

export const getActivitiesDetailsQueryParams = async ({
    resortIdMultiSelector,
    mxtsApi,
    env,
    dynamicFilter,
    startDate,
    endDate,
    resourceActivityDetailsIds,
    showOnlyPublishedActivity,
    showCancelledActivities,
}: {
    resortIdMultiSelector?: NumberMultiSelectOption[];
    mxtsApi: MxtsApiWrapper;
    env: ApiCallOptions;
    dynamicFilter?: DynamicFilter;
    startDate?: string;
    endDate?: string;
    resourceActivityDetailsIds?: number[];
    showOnlyPublishedActivity?: boolean;
    showCancelledActivities?: boolean;
}) => {
    const params: ResourceActivitiesDetailsRequest = {};
    if (startDate) {
        params.startDate = startDate;
    }
    if (endDate) {
        params.endDate = endDate;
    }
    if (startDate && !endDate) {
        params.endDate = startDate;
    }
    if (!params.resortId) {
        params.resortId = await procureResortIds({ resortIdMultiSelector, dynamicFilter, mxtsApi, env });
    }
    if (resourceActivityDetailsIds?.length) {
        params.resourceActivityDetailsIds = resourceActivityDetailsIds;
    }
    if (showOnlyPublishedActivity && !showCancelledActivities) {
        params.published = true;
    }
    params.size = MXTS.MAX_RESULTS;
    return params;
};

export const setImagesInActivityDetails = async (activitiesDetails: ResourceActivitiesDetailsResponse[], props: ActivityPlannerBaseProps, envResponse?: ApiCallOptions) => {
    try {
        const {
            options: { activitySelectionsGridView, activitySelectionsListView, activityViewStyle },
            context,
        } = props;
        const selectedActivityData = (activityViewStyle === AddOnViewStyle.GRID && activitySelectionsGridView) || (activityViewStyle === AddOnViewStyle.LIST && activitySelectionsListView) || [];
        const imageManagerIds = activitiesDetails.map((activity) => activity.resourceActivity.resortActivity.imageManagerId);
        const uniqueImageManagerIds = [...new Set(imageManagerIds)];
        if ([...selectedActivityData].map((activitySelection) => activitySelection.value).includes(ActivityInfo.IMAGE)) {
            const getImagesForAllManagerId: ImageDetailedView[] = envResponse
                ? await context.mxtsApi.imagesForAllManagerId(envResponse, {
                      imageManagerIds: uniqueImageManagerIds,
                      view: "detail",
                  })
                : [];
            for (const element of activitiesDetails) {
                const imageList = getUniqueListBy(getImagesForAllManagerId, "imageId");
                const imagesResponse = imageList.filter((image) => image.imageManagerIds?.includes(element.resourceActivity.resortActivity.imageManagerId));
                element.resourceActivity.resortActivity.images = imagesResponse;
            }
        }
    } catch (err) {
        console.error(`Failed to get activity image: ${err.message}`);
    }

    return activitiesDetails;
};

export const formatDays = (dynamicFilter: DynamicFilter) => {
    let startDate = moment().startOf("week").toDate();
    let endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    if (dynamicFilter.startdate && dynamicFilter.enddate) {
        startDate = new Date(moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS));
        endDate = new Date(moment(dynamicFilter.enddate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS));
    }
    const dayList = getDaysArray(startDate, endDate).map((day) => {
        const obj: DaysChunks = {
            day: "",
            activities: [],
        };
        obj.day = moment(day).format(DATE_FORMAT.DISPLAY);
        return obj;
    });
    return chunk(dayList, 7);
};

// Get dynamic fields for planned activity.
export const setDynamicFieldsInActivityDetails = async (
    activitiesDetails: ResourceActivitiesDetailsWithDynamicField[],
    props: ActivityPlannerBaseProps,
    env?: ApiCallOptions
): Promise<ResourceActivitiesDetailsWithDynamicField[]> => {
    const {
        options: { activitySelectionsGridView, activitySelectionsListView, activityViewStyle, contentType, _id: widgetOptionsId, resortIdMultiSelector },
        context,
        dynamicFilter,
    } = props;
    const selectedActivityData = (activityViewStyle === AddOnViewStyle.GRID && activitySelectionsGridView) || (activityViewStyle === AddOnViewStyle.LIST && activitySelectionsListView) || [];
    // Set dynamic fields
    if ([...selectedActivityData].map((activitySelection) => activitySelection.value).includes(ActivityInfo.DYNAMIC_FIELD) && env) {
        const resortActivityIdsArray: number[] = [...new Set(activitiesDetails.map((activityDetails) => activityDetails.resourceActivity.resortActivity.resortActivityId))];
        const widgetOptionsDynamicFieldIdsPaths: Array<keyof WidgetOptions> = ["dynamicFieldCodes"];
        const language = (await context.mxtsApi.languages(env, { shortName: context.currentLocale.code }))[0];
        const resourceActivitiesParams: ResourceActivitiesRequest = {
            page: 0,
            size: 20,
            includeDynamicFieldsData: true,
            widgetOptionsId,
            widgetOptionsDynamicFieldIdsPaths,
            languageId: language.languageId,
        };
        if (contentType === RESORT) {
            // When the content type is resort then only it returns activities belongs to that resort else it will return all the activities.
            resourceActivitiesParams.resortIds = await procureResortIds({ resortIdMultiSelector, dynamicFilter, mxtsApi: context.mxtsApi, env });
        }
        if (resortActivityIdsArray.length) {
            resourceActivitiesParams.resortActivityIds = resortActivityIdsArray;
        }
        const obtainedResourceActivities: ResourceActivityWithDynamicField[] = await context.mxtsApi.getResourceActivities(env, resourceActivitiesParams).then((result) => result.content);
        for (const activity of activitiesDetails) {
            const foundActivity = obtainedResourceActivities.find((resourceActivity) => resourceActivity.resourceId === activity.resourceId);
            if (foundActivity) {
                activity.dynamicFieldData = foundActivity.dynamicFieldData;
            }
        }
    }
    return activitiesDetails;
};

export const setCRPActivityDynamicField = async (props: {
    activity: Activity;
    selectedOptions: NumberMultiSelectOption[] | undefined;
    env: ApiCallOptions;
    widgetOptionsId: string;
    context: CMSProviderProperties;
}) => {
    const { activity, selectedOptions, env, widgetOptionsId, context } = props;
    // Set dynamic fields
    const activityOptionsValue = selectedOptions?.map((activitySelection) => activitySelection.value) ?? [];
    if (activityOptionsValue.includes(DYNAMIC_FIELD_CODE) && env) {
        const widgetOptionsDynamicFieldIdsPaths = ["dynamicFieldCodes", "dynamicFieldCodesForLinking"];
        const language = (await context.mxtsApi.languages(env, { shortName: context.currentLocale.code }))[0];
        const resourceActivitiesParams: ResourceActivitiesRequest = {
            page: 0,
            size: 20,
            includeDynamicFieldsData: true,
            widgetOptionsId,
            widgetOptionsDynamicFieldIdsPaths,
            languageId: language.languageId,
            resortIds: [activity.resourceActivity.resortId],
            resortActivityIds: [activity.resourceActivity.resortActivity.resortActivityId],
        };

        const obtainedResourceActivities: ResourceActivityWithDynamicField[] = await context.mxtsApi.getResourceActivities(env, resourceActivitiesParams).then((result) => result.content);
        const foundActivity = obtainedResourceActivities.find((resourceActivity) => resourceActivity.resourceId === activity.resourceId);
        if (foundActivity) {
            activity.dynamicFieldData = foundActivity.dynamicFieldData;
        }
    }
    return activity;
};

export const getDaysInMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
export const datesAreOnSameDay = (first: Date, second: Date) => first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
export const matchMonthAndYear = (first: Date, second: Date) => first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth();

export const getMonthCount = (end: number) => {
    const currentMonth = Array.from({ length: end }).reduce(
        ({ result, current }) => ({
            result: [...result, current],
            current: current + 1,
        }),
        { result: [], current: 1 }
    );
    return (currentMonth as { result: number[]; current: number }).result;
};

export const getMonthYear = (date: Date) => {
    const d = date.toDateString().split(" ");
    return `${d[1]} ${d[3]}`;
};

export const getSortedDays = (date: Date) => {
    const daysInMonth = getMonthCount(getDaysInMonth(date));
    const index = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return [...Array(index === 0 ? 6 : index - 1), ...daysInMonth];
};

export const getFirstDayOfMonth = (date?: Date) => {
    const getDate = date || new Date();
    return new Date(getDate.getFullYear(), getDate.getMonth(), 1);
};

export const getLastDayOfMonth = (date?: Date) => {
    const getDate = date || new Date();
    return new Date(getDate.getFullYear(), getDate.getMonth() + 1, 0);
};

export const structureCalendarActivity = (currentDate: Date, activitiesDetails: ResourceActivitiesDetailsResponse[], structureCalendar: StructureCalendar[]) => {
    const cloneStructureCalendar = [...structureCalendar];
    // Make sure that firstDayOfMonth is not already present in structureCalendar data.
    if (!cloneStructureCalendar.find((calendar) => matchMonthAndYear(calendar.firstDayOfMonth, currentDate))) {
        // Structure Whole Month Data
        const wholeMonthData: WholeMonthData[] = [];
        for (const element of activitiesDetails) {
            if (element.day) {
                const activityResponsedate = new Date(element.day);
                const foundDay = wholeMonthData.find((tempMonthElement) => datesAreOnSameDay(tempMonthElement.day, activityResponsedate as Date));
                if (!foundDay) {
                    wholeMonthData.push({ day: activityResponsedate, resourceActivityDetail: [element] });
                } else {
                    foundDay.resourceActivityDetail = [...foundDay.resourceActivityDetail, element];
                }
            }
        }
        // Structure month with whole month
        const structuredCalendar = {
            firstDayOfMonth: currentDate,
            wholeMonthData,
        };
        cloneStructureCalendar.push(structuredCalendar);
    }
    return [...cloneStructureCalendar];
};

type sortingValue = Date | string | number | undefined;

const ascendingOrder = (firstValue: sortingValue, secondValue: sortingValue) => {
    if (firstValue !== undefined && secondValue !== undefined) {
        if (firstValue < secondValue) {
            return -1;
        }
        if (firstValue > secondValue) {
            return 1;
        }
    }
    return 0;
};

const descendingOrder = (firstValue: sortingValue, secondValue: sortingValue) => {
    if (firstValue !== undefined && secondValue !== undefined) {
        if (firstValue < secondValue) {
            return 1;
        }
        if (firstValue > secondValue) {
            return -1;
        }
    }
    return 0;
};

export const sortActivities = (activities: Activity[], sortingOption: string): Activity[] => {
    activities.sort((first: Activity, second: Activity): any => {
        const date1 = new Date(first.startTime);
        const date2 = new Date(second.startTime);
        // Sort by activity startTime
        if (sortingOption === Sort[Sort.minToMaxDateAndTime] && activities.some((activity) => activity.startTime)) {
            return ascendingOrder(date1, date2);
        }
        if (sortingOption === Sort[Sort.maxToMinDateAndTime] && activities.some((activity) => activity.startTime)) {
            return descendingOrder(date1, date2);
        }
        // Sort by activity price
        // TODO: Commented out for now, as this way of sorting won't work for new combination of subjectBased/fixed prices, will refactor this code after the discussion.
        // if (sortingOption === Sort[Sort.lowToHighPrice] && activities.some((activity) => activity.price)) {
        //     return ascendingOrder(first.price, second.price);
        // }
        // if (sortingOption === Sort[Sort.highToLowPrice] && activities.some((activity) => activity.price)) {
        //     return descendingOrder(first.price, second.price);
        // }
    });
    return activities;
};

export const getPriceEngineActivity = (activity: Activity, quantity?: number, activityReservability?: ActivityReservabilities): PriceEngineAddOn => {
    const {
        resourceId,
        resourceActivity: { type },
    } = activity;

    const priceEngineAddOn: PriceEngineAddOn = {
        resourceId,
        type,
        quantity: quantity || 1,
        status: "INITIAL",
    };

    if (activity.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKETS || activity.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_GROUP) {
        // In case we have TICKETS or TICKET_PER_GROUP activity type we assume there could be 0 quantity.
        priceEngineAddOn.quantity = quantity ?? 1;
    }

    if (activityReservability?.activityStock.subjectId) {
        priceEngineAddOn.subjects = [{ subjectId: activityReservability?.activityStock.subjectId, quantity: 1 }];
    }

    return priceEngineAddOn;
};

export const getActivityPrice = async (activity: Activity, dynamicFilter: DynamicFilter, env: ApiCallOptions, selectedActivities?: ResourceActivitiesDetailsWithDynamicField[]): Promise<Activity> => {
    const { distributionChannel, rateType, reservationCategoryId, activityTicketQuantity, subject } = dynamicFilter;
    try {
        const subjects: SubjectQuantity[] = [];
        let quantity = 0;

        if (subject) {
            subject.forEach((value, key) => subjects.push({ subjectId: key, quantity: value }));
        }

        if (activityTicketQuantity !== undefined) {
            quantity = activityTicketQuantity;
        }

        if (activity.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_SUBJECT) {
            subjects.map((subject) => (quantity += subject.quantity));
        }

        if (activity.startTime && activity.endTime && distributionChannel?.distributionChannelId && reservationCategoryId && rateType?.rateTypeId && activity.activityReservabilities.length) {
            const priceRequest: AddOnPricesRequest | SupplierAddOnPricesRequest = {
                startDate: activity.startTime ? moment(activity.day).format(DATE_FORMAT.MXTS) : "",
                endDate: activity.endTime ? moment(activity.day).format(DATE_FORMAT.MXTS) : "",
                distributionChannelId: +distributionChannel?.distributionChannelId,
                reservationCategoryId: +reservationCategoryId,
                rateTypeId: rateType?.rateTypeId,
                addOns: [],
            };

            if (selectedActivities) {
                // This portion is to modify addOns array for bowling section table, when we select multiple timeslot (that means for more then one sections).
                const priceEngineAddOns: PriceEngineAddOn[] = [];
                selectedActivities.forEach((activity) => {
                    if (dynamicFilter.resourceActivityDetailsIds?.includes(activity.resourceActivityDetailsId) || !dynamicFilter.resourceActivityDetailsIds?.length) {
                        const activityPriceEngineAddOn = priceEngineAddOns.find((priceEngine) => priceEngine.resourceId === activity.resourceId);
                        if (activityPriceEngineAddOn?.quantity) {
                            activityPriceEngineAddOn.quantity = activityPriceEngineAddOn.quantity + ((activity as SelectedActivity).selectedActivityTicketQuantity || 0);
                        } else {
                            // This commented code may be utilized in the future for subject-based pricing with the bowling table.
                            // const priceEngineAddOnResponse = activity.activityReservabilities.map((activityReservability) =>
                            //     getPriceEngineActivity(activity, (activity as SelectedActivity).selectedActivityTicketQuantity, activityReservability)
                            // );

                            // For fixed pricing in group activities with the bowling table.
                            const priceEngineAddOnResponse = getPriceEngineActivity(activity, (activity as SelectedActivity).selectedActivityTicketQuantity, activity.activityReservabilities[0]);
                            priceEngineAddOns.push(priceEngineAddOnResponse);
                        }
                    }
                });
                priceRequest.addOns = priceEngineAddOns;
            } else {
                // This portion is to modify addOns array when we have only one section.
                priceRequest.addOns = activity.activityReservabilities.map((activityReservability) => getPriceEngineActivity(activity, quantity, activityReservability));
            }

            const activityPriceResult = await MxtsApi.calculateAddOnPrices(env, priceRequest);
            activity.pricesResult = activityPriceResult;
            return activity;
        }
    } catch (error) {
        reportError("Failed to fetch the price from price engine:", error);
    }
    return activity;
};

export const getActionButtonPageUrl = async (widget: Widget<WidgetOptions>, context: CMSProvidedProperties): Promise<string> => {
    const { currentLocale, site } = context;
    let url = "";
    const localContent = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localizedActivityActionButton });
    if (localContent) {
        if (localContent.providePageUrl) {
            url = localContent.nextPageUrl || "";
        } else if (localContent.siteId && localContent.pageId) {
            let site;
            if (allSites.length) {
                site = allSites.find((item) => item._id === localContent.siteId);
            } else {
                site = await context.cmsApi.siteApi.findById({ id: localContent.siteId, projection: { sitemap: 0 } });
            }
            if (site && localContent.pageId) {
                url = (await pageLink({ site, pageId: localContent.pageId, locale: context.currentLocale, context })) || "";
            }
        }
    }
    return url;
};

export const checkValueInActivityViewStyle = (options: WidgetOptions, selectedValue: ActivityInfo | AddOnInfo | AreaType): boolean => {
    const selectedActivityData =
        (options.activityViewStyle === AddOnViewStyle.GRID && options.activitySelectionsGridView) || (options.activityViewStyle === AddOnViewStyle.LIST && options.activitySelectionsListView) || [];
    return !![...selectedActivityData].map((activitySelection) => activitySelection.value).includes(selectedValue);
};

export const setSubjectName = async (activities: Activity[], props: ActivityPlannerBaseProps, dynamicFilter: DynamicFilter, env?: ApiCallOptions) => {
    const {
        options: { activitySelectionsGridView, activitySelectionsListView, activityViewStyle },
        context: { mxtsApi },
    } = props;
    const selectedActivityData = (activityViewStyle === AddOnViewStyle.GRID && activitySelectionsGridView) || (activityViewStyle === AddOnViewStyle.LIST && activitySelectionsListView) || [];
    if (
        ([...selectedActivityData].map((activitySelection) => activitySelection.value).includes(ActivityInfo.SUBJECT_NAME) ||
            [...selectedActivityData].map((activitySelection) => activitySelection.value).includes(ActivityInfo.PRICE)) &&
        env
    ) {
        const updatedActivity = await updateActivityWithSubjectName(activities, mxtsApi, env);
        activities = updatedActivity;
    }
    return activities;
};

export const updateActivityWithSubjectName = async (activities: Activity[], mxtsApi: MxtsApiWrapper, env: ApiCallOptions) => {
    const subjectIds = [];
    for (const activityResult of activities) {
        const activityStockSubjectIds = activityResult?.activityReservabilities.map((activityReservability) => activityReservability.activityStock.subjectId);
        subjectIds.push(...activityStockSubjectIds);
    }
    const getSubjects = (
        await mxtsApi.subjects(env, {
            endDate: moment().format(DATE_FORMAT.MXTS),
            linkType: "activity",
            sort: "subjectOrder",
            subjectIds: [...new Set(subjectIds)].filter(Boolean),
        })
    ).content;
    for (const activityResult of activities) {
        for (const reservability of activityResult.activityReservabilities) {
            const foundSubject = getSubjects.find((subject) => subject.subjectId === reservability.activityStock.subjectId);
            (reservability.activityStock as ActivityStockResult) = { ...reservability.activityStock, subjectName: foundSubject?.name };
        }
    }
    return activities;
};

export const setCRPSubjectName = async (activity: Activity, mxtsApi: MxtsApiWrapper, env: ApiCallOptions) => {
    const activityStockSubjectIds = activity?.activityReservabilities.map((activityReservability) => activityReservability.activityStock.subjectId);

    const getSubjects = (
        await mxtsApi.subjects(env, {
            endDate: moment().format(DATE_FORMAT.MXTS),
            sort: "subjectOrder",
            linkType: "activity",
            subjectIds: [...new Set(activityStockSubjectIds)].filter(Boolean),
        })
    ).content;
    for (const reservability of activity.activityReservabilities) {
        const foundSubject = getSubjects.find((subject) => subject.subjectId === reservability.activityStock.subjectId);
        (reservability.activityStock as ActivityStockResult) = { ...reservability.activityStock, subjectName: foundSubject?.name };
    }
    return activity;
};

export const setActivityArea = async (activities: Activity[], options: WidgetOptions, env?: ApiCallOptions) => {
    const { activitySelectionsGridView, activitySelectionsListView, activityViewStyle } = options;
    const resourceIds = activities.map((activity) => activity.resourceId);
    const uniqueResourceIds = [...new Set(resourceIds)];
    const selectedActivityData = (activityViewStyle === AddOnViewStyle.GRID && activitySelectionsGridView) || (activityViewStyle === AddOnViewStyle.LIST && activitySelectionsListView) || [];
    const activityOptionsValue = [...selectedActivityData].map((activitySelection) => activitySelection.value);
    if ((activityOptionsValue.includes(ActivityInfo.LOCATION) || activityOptionsValue.includes(ActivityInfo.SIGN_UP)) && env) {
        const activityAreaResources: ActivityAreaResourcesResponse[] = await MxtsApi.getActivityAreaResources(env, { resourceIds: uniqueResourceIds }).then(
            (areaResourceResult) => areaResourceResult.content
        );
        for (const activityResult of activities) {
            if (!activityResult.activityAreaResources.length) {
                const foundActivityArea = activityAreaResources.filter(
                    (activityAreaResource) => activityAreaResource.resourceId === activityResult.resourceId && checkValueInActivityViewStyle(options, activityAreaResource.type)
                );
                if (foundActivityArea.length) {
                    activityResult.activityAreaResources = foundActivityArea;
                }
            }
        }
    }
    return activities;
};

export const setCRPActivityArea = async (activity: Activity, selectedOptions: NumberMultiSelectOption[] | undefined, env?: ApiCallOptions) => {
    const activityOptionsValue = selectedOptions?.map((activitySelection) => activitySelection.value) ?? [];
    let activityAreaResources: ActivityAreaResourcesResponse[] = [];
    if ((activityOptionsValue.includes(LOCATION) || activityOptionsValue.includes(SIGN_UP)) && env && activity.resourceId && selectedOptions) {
        if (!activity.activityAreaResources.length) {
            activityAreaResources = await MxtsApi.getActivityAreaResources(env, { resourceIds: [activity.resourceId] }).then((areaResourceResult) => areaResourceResult.content);
            if (activityAreaResources.length) {
                activity.activityAreaResources = activityAreaResources;
            }
        }
    }
    return activity;
};

export const setLocalActivityTime = async (activity: Activity, context: CMSProviderProperties) => {
    activity.startTime = await DateUtil.convertUTCStringToConcernDateTime(activity.startTime, context);
    activity.endTime = await DateUtil.convertUTCStringToConcernDateTime(activity.endTime, context);
    return activity;
};

export const setCRPActivityCategory = async (activity: Activity, context: CMSProviderProperties, env: ApiCallOptions) => {
    const language = (await context.mxtsApi.languages(env, { shortName: context.currentLocale.code }))[0];
    const activityCategoryId = activity.resourceActivity.resortActivity.activityCategoryId;
    const categoryResults = await context.mxtsApi.getActivityCategoryTranslations(env, {}, [{ key: "activityCategoryId", value: activityCategoryId }]);
    const categoryValue = categoryResults.find((categoryResult) => categoryResult.languageId === language.languageId)?.name || "";
    activity.activityCategory = categoryValue;

    return activity;
};

export const setActivityPrice = async (activities: Activity[], options: WidgetOptions, dynamicFilter: DynamicFilter, env?: ApiCallOptions) => {
    const { activitySelectionsGridView, activitySelectionsListView, activityViewStyle } = options;
    const selectedActivityData = (activityViewStyle === AddOnViewStyle.GRID && activitySelectionsGridView) || (activityViewStyle === AddOnViewStyle.LIST && activitySelectionsListView) || [];
    const activityOptionsValue = [...selectedActivityData].map((activitySelection) => activitySelection.value);
    const activityPricePromises = [];
    if (activityOptionsValue.includes(ActivityInfo.PRICE) && env) {
        for (const activityResult of activities) {
            activityPricePromises.push(getActivityPrice(activityResult, dynamicFilter, env));
        }
        const resolvedActivities = await Promise.all(activityPricePromises);
        return resolvedActivities;
    }
    return activities;
};

export const getAvailableActivityDates = async ({
    startDate,
    endDate,
    env,
    context,
    resortIds,
    showSpecificActivityAvailability,
    resourceId,
    selectedDay,
}: {
    startDate: string;
    endDate: string;
    env: ApiCallOptions;
    context: CMSProviderProperties;
    resortIds: number[] | undefined;
    showSpecificActivityAvailability: boolean | undefined;
    resourceId: number | undefined;
    selectedDay?: string | undefined;
}): Promise<DateMap> => {
    let newAvailableDates: DateMap = {};
    // As of now startDate, endDate and resortId are mandatory for getting results form searchResourceActivitiesDetails
    if (startDate && endDate && env && resortIds && context) {
        let searchResourceActivitiesDetails: Array<{ date: string } | SearchResourceActivitiesResponse> = [];
        if (showSpecificActivityAvailability) {
            let startdate = startDate;
            let enddate = endDate;
            if (selectedDay) {
                const day = moment(selectedDay, DATE_FORMAT.DEFAULT);
                startdate = moment().format(DATE_FORMAT.MXTS);
                enddate = moment(new Date(day.year(), day!.month() + 1, 0)).format(DATE_FORMAT.MXTS);
            }
            if (resourceId) {
                const availabilityDates = await context.mxtsApi.getResourceActivityAvailability(env, { startDate: startdate, endDate: enddate, resortId: resortIds[0], published: true }, [
                    { key: "resourceId", value: resourceId },
                ]);
                searchResourceActivitiesDetails = [...availabilityDates.resourceActivityAvailabilities];
            }
        } else {
            await Promise.all(
                resortIds.map(async (resortId) => {
                    const availableDates =
                        (
                            await context.mxtsApi.searchResourceActivitiesDetails(env, {
                                startDate,
                                endDate,
                                page: 0,
                                resortId,
                                size: MXTS.MAX_RESULTS,
                            })
                        ).response || [];
                    searchResourceActivitiesDetails = [...availableDates];
                })
            );
        }
        const availableDatesArray: moment.Moment[] =
            [
                ...new Set(
                    searchResourceActivitiesDetails.map(
                        (searchResourceActivitity) => (searchResourceActivitity as SearchResourceActivitiesResponse).day || (searchResourceActivitity as { date: string }).date
                    )
                ),
            ].map((date) => moment(date)) || [];
        newAvailableDates = createDateMap(availableDatesArray);
    }
    return newAvailableDates;
};

export const updateStartDate = (selectedDay: string, dispatchAction: React.Dispatch<FilterChangeAction>) => {
    const action: FilterChangeAction = {
        type: ActionType.FilterChange,
        filter: dynamicFilterType.startdate,
        payload: {
            startdate: selectedDay,
        },
    };
    dispatchAction(action);
};

export const isDateValid = (date: Date) => date instanceof Date && !isNaN(date.getTime());

export const getActivityTimeSlots = async ({
    env,
    startDate,
    endDate,
    resortActivityId,
    resortId,
    context,
    resourceId,
    showPublishedTimeslots,
}: {
    env: ApiCallOptions;
    startDate: string | undefined;
    endDate: string | undefined;
    resortActivityId: number | undefined;
    resortId: number | undefined;
    context: CMSProviderProperties;
    resourceId: number | undefined;
    showPublishedTimeslots: boolean;
}) => {
    if (resortActivityId && startDate && endDate && resortId) {
        const queryParams: SearchResourceActivitiesRequest = {
            startDate,
            endDate,
            resortActivityId,
            resortId,
            size: MXTS.MAX_RESULTS,
        };
        if (showPublishedTimeslots) {
            queryParams.published = true;
        }
        let activityTimeSlots =
            (await context.mxtsApi.searchResourceActivitiesDetails(env, queryParams))?.response
                ?.filter((activity) => (resourceId ? activity.resourceId === resourceId : true))
                .map((activity) => ({
                    startTime: activity.startTime,
                    endTime: activity.endTime,
                    resourceActivityDetailsId: activity.resourceActivityDetailsId,
                    resourceId: activity.resourceId,
                    status:
                        activity.status === ActivityStatus.CANCELLED ? TimeslotStatus.CANCELLED : activity.reservabilities?.[0]?.reservable === 0 ? TimeslotStatus.SOLD_OUT : TimeslotStatus.AVAILABLE,
                })) || [];
        activityTimeSlots = await Promise.all(
            activityTimeSlots?.map(async (timeslot) => {
                timeslot.startTime = timeslot.startTime.includes("Z") ? await DateUtil.convertUTCStringToConcernDateTime(timeslot.startTime, context) : timeslot.startTime;
                timeslot.endTime = timeslot.endTime.includes("Z") ? await DateUtil.convertUTCStringToConcernDateTime(timeslot.endTime, context) : timeslot.endTime;
                return timeslot;
            })
        );
        return activityTimeSlots;
    }
    return [];
};

export const getActivitySections = async (context: CMSProviderProperties, env: ApiCallOptions, activity?: ResourceActivitiesDetailsWithDynamicField): Promise<SearchResourceActivitiesResponse[]> => {
    const activitySections =
        (
            activity &&
            (await context.mxtsApi.searchResourceActivitiesDetails(env, {
                startDate: activity?.day!,
                endDate: activity?.day!,
                resortId: activity?.resourceActivity.resortId,
                resortActivityId: activity?.resourceActivity.resortActivityId,
                size: MXTS.MAX_RESULTS,
            }))
        )?.response || [];

    return activitySections;
};

export const getGroupedActivitySectionThroughoutDay = (activities: ResourceActivitiesDetailsWithDynamicField[]) => {
    const groupedActivities = activities.reduce((acc, activity) => {
        const key = `${activity.resourceId}-${activity.day}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(activity);
        return acc;
    }, {} as { [key: string]: ResourceActivitiesDetailsWithDynamicField[] });

    return Object.values(groupedActivities).flatMap((group) => {
        if (group.length === 1) {
            return group; // Single instance, include it even if it's cancelled
        }
        const nonCancelled = group.filter((activity) => activity.status !== ActivityStatus.CANCELLED);
        return nonCancelled.length ? nonCancelled : [group[0]]; // Prefer non-cancelled, fallback to any if all are cancelled
    });
};

export const getDistinguishedFullDayActivities = (activities: Activity[]) => {
    const updatedActivities: Activity[] = activities.map((activity) => ({
        ...activity,
        isFullDayActivity: Number((moment(activity.endTime).diff(moment(activity.startTime), "minutes") / 60).toFixed(2)) === 23.98,
    }));
    return updatedActivities;
};

export const mapActivitySectionsWithTimeSlots = async (activities: SearchResourceActivitiesResponse[], context: CMSProviderProperties) => {
    const activitySectionsWithTimeSlots: ActivitySectionsWithTimeSlots[] = [];
    activities.forEach((activity) => {
        const foundActivity = activitySectionsWithTimeSlots.find((filteredActivity) => filteredActivity.resourceId === activity.resourceId);
        if (foundActivity) {
            foundActivity.resourceActivityWithTimes.push(activity);
        } else {
            const activitySectionsWithTimeSlot = {
                resourceId: activity.resourceId,
                resourceActivityName: activity.resourceActivityName,
                resourceActivityWithTimes: [activity],
                resourceActivityDetailsId: activity.resourceActivityDetailsId,
            };
            activitySectionsWithTimeSlots.push(activitySectionsWithTimeSlot);
        }
    });
    activitySectionsWithTimeSlots.forEach((section) => {
        section.resourceActivityWithTimes.map(async (timeslot) => {
            timeslot.startTime = timeslot.startTime.includes("Z") ? await DateUtil.convertUTCStringToConcernDateTime(timeslot.startTime, context) : timeslot.startTime;
            timeslot.endTime = timeslot.endTime.includes("Z") ? await DateUtil.convertUTCStringToConcernDateTime(timeslot.endTime, context) : timeslot.endTime;
            return timeslot;
        });
    });
    return activitySectionsWithTimeSlots;
};

export const mainActivityFromMultipleSections = (activities: Activity[], widgetOptions?: CRPActivityWidgetOptions) => {
    const filteredActivities: Activity[] = [];
    const sortedActivities = activities.sort((a, b) => moment(a.startTime).toDate().getTime() - moment(b.startTime).toDate().getTime());
    sortedActivities.forEach((activity) => {
        const foundActivities = !filteredActivities.find(
            (filteredActivity) => filteredActivity.resourceActivity.resortActivityId === activity.resourceActivity.resortActivityId && filteredActivity.day === activity.day
        );
        if (foundActivities) {
            const showMainActivity =
                sortedActivities.filter(
                    (originalActivity) => originalActivity.resourceActivity.resortActivityId === activity.resourceActivity.resortActivityId && originalActivity.day === activity.day
                ).length > 1;
            if (showMainActivity) {
                // This check is to identify whether we have more than one section or only one section.
                const activityData = {
                    ...activity,
                    showMainActivity,
                };
                filteredActivities.push(activityData);
            } else if (widgetOptions?.showMainActivityName) {
                const activityData = {
                    ...activity,
                    showMainActivityName: true,
                };
                filteredActivities.push(activityData);
            } else {
                filteredActivities.push(activity);
            }
        }
    });
    return filteredActivities;
};

export const removeActivityParamsFromUrl = (dispatchAction: React.Dispatch<FilterChangeAction>) => {
    const action: FilterChangeAction = {
        type: ActionType.FilterChange,
        filter: dynamicFilterType.removeActivityParams,
        payload: {},
    };
    dispatchAction(action);
};

export const setDefaultStartDate = (options: CRPActivityWidgetOptions, dynamicFilter: DynamicFilter, dispatchAction: React.Dispatch<FilterChangeAction>) => {
    let defaultStartDate;
    if (options.defaultStartDate === DefaultDate.TODAY) {
        defaultStartDate = moment().format(DATE_FORMAT.DEFAULT);
    } else if (options.defaultStartDate === DefaultDate.TOMORROW) {
        defaultStartDate = moment().add(1, "days").format(DATE_FORMAT.DEFAULT);
    } else if (options.defaultStartDate === DefaultDate.ANOTHER_DAY && options.selectedDefaultStartDate) {
        defaultStartDate = moment(options.selectedDefaultStartDate).format(DATE_FORMAT.DEFAULT);
    }
    if (defaultStartDate && !dynamicFilter.startdate) {
        updateStartDate(defaultStartDate, dispatchAction);
    }
};

export const getActivityReservableCount = (activity?: ResourceActivitiesDetailsWithDynamicField, ticketingType?: TicketingType): number => {
    const activityReservableCount = activity?.activityReservabilities[0]?.reservable || 0;
    const activityQuantity = activity?.activityReservabilities[0]?.activityStock.quantity || 0;
    const activityCapacity = activity?.activityReservabilities[0]?.activityStock.capacity || 0;

    if (ticketingType === TicketingTypes.TICKET_PER_GROUP) {
        if (activityQuantity === 1 && activityReservableCount) {
            return 1;
        }
        return activityReservableCount / activityCapacity;
    }
    return activityReservableCount;
};

export const getLinkedCustomSubjects = async (env: ApiCallOptions, activities: Activity[]) => {
    const resourceActivitySubjects = await getAllIds(
        activities.map((resourceActivity) => resourceActivity.resourceId),
        async (ids: number[], pageSize: number) => MxtsApi.getResourceActivitiesSubjects(env, { resourceIds: ids }),
        50
    );
    let subjectIds: number[] = [];
    activities.forEach((activity) => {
        const linkedCustomSubjects = resourceActivitySubjects.filter((resourceActivitySubject) => resourceActivitySubject.resourceId === activity.resourceId);
        if (linkedCustomSubjects.length) {
            activity.linkedCustomSubjects = linkedCustomSubjects;
            subjectIds = [...subjectIds, ...linkedCustomSubjects.map((linkedCustomSubject) => linkedCustomSubject.subjectId)];
        } else {
            activity.linkedCustomSubjects = [];
        }
    });
    const obtainedSubjects = (
        await MxtsApi.subjects(env, {
            endDate: moment().format(DATE_FORMAT.MXTS),
            sort: "subjectOrder",
            linkType: "activity",
            subjectIds: [...new Set(subjectIds)].filter(Boolean),
        })
    ).content;
    for (const activity of activities) {
        activity.linkedCustomSubjects?.forEach((linkedCustomSubject) => {
            const foundSubject = obtainedSubjects.find((subject) => subject.subjectId === linkedCustomSubject.subjectId);
            if (foundSubject) {
                linkedCustomSubject.name = foundSubject.name; // Directly set the name on the object
            }
        });
    }
    return activities;
};

export const filterByCustomSubjectSelection = (activities: Activity[], selectedCustomSubjects: ScheduledActivitiesSubjectsResponse[]) => {
    const filteredActivities: Activity[] = [];
    activities.forEach((activity) => {
        selectedCustomSubjects.forEach((selectedCustomSubject) => {
            const isCustomSubjectPresent =
                !!activity.linkedCustomSubjects?.find((linkedCustomSubject) => linkedCustomSubject.subjectId === selectedCustomSubject.subjectId) &&
                !filteredActivities.find((filteredActivity) => filteredActivity.resourceActivityDetailsId === activity.resourceActivityDetailsId);
            if (isCustomSubjectPresent) {
                filteredActivities.push(activity);
            }
        });
    });
    return filteredActivities;
};

export const loadReservationActivities = async (
    env: ApiCallOptions,
    context: CMSProviderProperties,
    reservationResults: EsReservationResult[]
): Promise<{ activityResources: ReservedResource[]; activities: Activity[] }> => {
    const activityResources: ReservedResource[] = [];
    await Promise.all(
        reservationResults.map(async (reservationResult) => {
            if (reservationResult.reservation.reservationKind === ReservationKind.ADDON_RESERVATION) {
                const reservationActivityResource = await context.mxtsApi
                    .getReservedResource(env, {}, [{ key: "reservationId", value: reservationResult.reservation.reservationId }])
                    .then((result) => result.content[0]);
                activityResources.push(reservationActivityResource);
            }
        })
    );
    const resourceActivityDetailsIds = activityResources
        .filter((activityResource) => typeof activityResource.resourceActivityDetailsId === "number")
        .map((activityResource) => activityResource.resourceActivityDetailsId as number);
    const resourceActivities: Activity[] = resourceActivityDetailsIds.length
        ? await context.mxtsApi.getResourceActivitiesDetails(env, { resourceActivityDetailsIds, size: MXTS.MAX_RESULTS }).then((result) => result.content)
        : [];

    return { activityResources, activities: resourceActivities };
};

export const setSubjectNameForActivityBillLine = async (
    env: ApiCallOptions,
    subjectQuantitiesForActivity?: SubjectQuantityWithDetailsId[],
    activity?: ResourceActivitiesDetailsWithDynamicField
): Promise<SubjectQuantityWithDetailsId[] | undefined> => {
    if (subjectQuantitiesForActivity && activity) {
        const subjectQuantitiesInfo = cloneDeep(subjectQuantitiesForActivity);
        await Promise.all(
            subjectQuantitiesInfo?.map(async (subjectInfo) => {
                const foundSubject = activity?.activityReservabilities.find(
                    (activityReservability) => activityReservability.activityStock.subjectId === subjectInfo.subjectId && subjectInfo.subjectName
                );
                if (foundSubject) {
                    subjectInfo.subjectName = (foundSubject.activityStock as ActivityStockResult).subjectName;
                } else {
                    const subject = await MxtsApi.subjects(env, { linkType: "activity", subjectIds: [subjectInfo.subjectId] }).then((result) => result.content?.[0]);
                    if (subject) {
                        subjectInfo.subjectName = subject.name;
                    }
                }
            })
        );
        return subjectQuantitiesInfo;
    }
};

export const setSelectedActivitiesAfterReservation = async (dynamicFilter: DynamicFilter, env: ApiCallOptions, dispatchAction: React.Dispatch<FilterChangeAction>) => {
    const resourceActivityDetailsIds =
        (dynamicFilter.resourceActivityDetailsId && [dynamicFilter.resourceActivityDetailsId]) || (dynamicFilter.resourceActivityDetailsIds?.length && dynamicFilter.resourceActivityDetailsIds) || [];
    // We have added a dynamicFilter.reservationId check, This will set the selectedActivities once we have the reservation or reservationId.
    if (!dynamicFilter.selectedActivities && resourceActivityDetailsIds.length && dynamicFilter.reservationId) {
        const selectedResourceActivities = await MxtsApi.getResourceActivitiesDetails(env, { resourceActivityDetailsIds }).then((result) => result.content);

        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.setSelectedActivities,
            payload: { selectedActivities: selectedResourceActivities },
        };
        dispatchAction(action);
    }
};

export const scrollToDay = (
    containerRef: React.RefObject<HTMLDivElement>,
    day: string, // DD-MM-YYYY format
    updateSelectedDate: (date: string) => void
) => {
    const containerElement = containerRef.current;
    if (!containerElement) {
        return;
    }

    const targetElement = containerElement.querySelector(`#day-${day}`);

    if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
        updateSelectedDate(day);
    }
};

export const getScheduledActivitiesSubjectsParams = (ActivityParams: ScheduledActivitiesSubjectsRequest) => {
    const { startDate, endDate, resortIds, languageIds } = ActivityParams;
    const params: ScheduledActivitiesSubjectsRequest = { published: true, languageIds };
    if (startDate || endDate) {
        params.startDate = moment(startDate || moment(), DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS);
        params.endDate = moment(endDate || startDate || moment(), DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS);
    } else {
        const now = moment().format(DATE_FORMAT.MXTS);
        params.startDate = now;
        params.endDate = now;
    }
    if (resortIds?.length) {
        params.resortIds = resortIds;
    }
    return params;
};
