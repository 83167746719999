import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "Glemm",
    name: "Glemm Theme",
    stylesheets: ["/styles/themes/glemm/glemm-admin.css"],
    frontendStylesheets: ["/styles/themes/glemm/glemm.css"],
    styles: [...baseTheme.styles],
};

export default theme;
