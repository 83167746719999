import * as MXTS from "@maxxton/cms-mxts-api";

import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";

import { InputSpecAuto } from "../form-specs";
import { MXTS as MXTS_CONSTANTS } from "../utils/constants";
import { SelectOption } from "../form-specs/formSpec.types";
import { getAdminMxtsEnv } from "../plugins/mxts";
import { map } from "lodash";
import namespaceList from "../i18n/namespaceList";

async function getRegionOptions(): Promise<Array<SelectOption<number | null>>> {
    const env = await getAdminMxtsEnv();
    const regions = await MXTS.MxtsApi.regions(env, {
        size: MXTS_CONSTANTS.MAX_RESULTS,
        returnGeoShapes: false,
    }).then((pagedResult) => pagedResult.content);
    return map(
        regions,
        (region: MXTS.Region): SelectOption<number> => {
            const { regionId, name } = region;
            return {
                value: regionId,
                label: `${name} • id: ${regionId}`,
            };
        }
    );
}

function regionsAutocomplete<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecAuto<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.widgetMap, "regionId"),
        type: "autocomplete",
        async options(): Promise<any[]> {
            return getRegionOptions();
        },
    };
}

export default regionsAutocomplete;
