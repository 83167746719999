import { Customer, CustomerFullAddress } from "@maxxton/cms-mxts-api";
import { ReservationCustomerActionType, ReservationCustomerReducerAction } from "../actions/reservationCustomerAction";

import { Reducer } from "redux";

export interface ReservationCustomerState {
    customer?: Customer;
    currentAddress?: CustomerFullAddress;
    fetching?: boolean;
    error?: unknown;
}

export const reservationCustomerReducer: Reducer<ReservationCustomerState> = (state: ReservationCustomerState = {}, action: ReservationCustomerReducerAction): ReservationCustomerState => {
    const actionType = action.actionType;
    switch (actionType) {
        case ReservationCustomerActionType.FETCHING:
            return { ...state, fetching: true };
        case ReservationCustomerActionType.FETCH_SUCCESS:
            return { ...state, ...action.payload, fetching: false };
        case ReservationCustomerActionType.FETCH_ERROR:
            return { ...state, error: action.payload?.error || "Something went wrong while fetching customer summary", fetching: false };
        default:
            return state;
    }
};
