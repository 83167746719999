import * as React from "react";

import { getI18nLocaleString } from "../i18n";
import namespaceList from "../i18n/namespaceList";

interface ErrorBoundaryState {
    hasError: boolean;
}

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error-boundary-message">
                    <h4>{getI18nLocaleString(namespaceList.admin, "errorBoundaryHeading")}</h4>
                    <p>{getI18nLocaleString(namespaceList.admin, "errorBoundaryDescription")}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundary };
