/* eslint-disable max-lines-per-function */
import * as Animate from "../animate";
import * as Icons from "../icons";
import * as React from "react";

import { WebContent as CmsApiWebContent, CmsApiWrapper, LocalizedCardContent, LocalizedContent, LocalizedImageContent, LocalizedImageSpecOptions, Site, WithId } from "@maxxton/cms-api";
import { FormSpec, IconColoring, multiSelectStylePicker } from "../../../form-specs";
import { Image, ImageSpec, getImageSpec } from "../../../media";
import { PageWidgetSpec, Widget } from "../../";
import { ResultOptions, TypesearchContainerWidgetSpec, WidgetSpec, WidgetType } from "../../widget";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WebContent } from "./WebContent";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedCardContent } from "./webContent.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface WidgetOptions {
    webContentId: string | null;
    styleIds: any[];
    backgroundPanel: BackgroundPanel;
    showAnimate: boolean;
    animateProperties: string;
    showIcons: boolean;
    centerAligned: boolean;
    iconDisplayColumns: boolean;
    iconProperties: string;
    buttonColor: ButtonColor;
    stylingImage: ImageOption;
    stylingCardBorder: CardBorder;
    centeredText: boolean;
    addBullets: boolean;
    paddingTop: ContentTopPadding;
    paddingBottom: ContentBottomPadding;
    borderBox: Borders;
    iconColor: IconColoring;
    iconPosition: IconPosition;
    isSitePageUrl?: boolean;
    iconSize: IconSize;
    horizontalView: boolean;
    checkReservationStatus?: boolean;
}

const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

const animateList = Object.keys(Animate.default).map((key: any) => ({ label: (Animate.default as any)[key], value: key }));

export type BackgroundPanel = "default" | "dk-panel--brand" | "dk-panel--alt" | "dk-panel--white";
export type IconPosition = "" | "start" | "center" | "end";
export type IconSize = "" | "--icon-bigger" | "--icon-smaller";
export type ImageOption = "rectangle" | "dk-circle";
export type ButtonColor = "ghost-color" | "dk-button--primary" | "dk-button--secondary" | "dk-button--brand-alternative";
export type CardBorder = "" | "border" | "noBorder" | "borderRadius";
export type ContentTopPadding = "default-pt" | "dk-pt-s" | "dk-pt-m" | "dk-pt-l" | "dk-pt-xxl";
export type ContentBottomPadding = "default-pb" | "dk-pb-s" | "dk-pb-m" | "dk-pb-l" | "dk-pb-xxl";
export type Borders = "" | "top-border" | "right-border" | "bottom-border" | "left-border" | "around-border";

const TARGETS = ["webcontent", "webcontent-model"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "webcontent-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetWebContent, "webcontentWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetWebContent, "webcontentWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContent, "general"),
                    properties: [
                        [
                            {
                                variable: "webContentId",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "content"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContent, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "backgroundPanel",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "bgPanel"),
                                type: "dual-color",
                                default: "default" as BackgroundPanel,
                            },
                            {
                                variable: "centeredText",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "centeredText"),
                                type: "checkbox",
                            },
                            {
                                variable: "showAnimate",
                                label: getI18nLocaleObject(namespaceList.admin, "showAnimate"),
                                type: "checkbox",
                            },
                            {
                                variable: "animateProperties",
                                label: getI18nLocaleObject(namespaceList.admin, "animateProperty"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.showAnimate,
                                optionList: animateList,
                            },
                            {
                                variable: "animateProperties",
                                type: "animate",
                                visible: (options: WidgetOptions) => options.showAnimate,
                            },
                            {
                                variable: "addBullets",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "addBullets"),
                                type: "checkbox",
                            },
                            {
                                variable: "showIcons",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle"),
                                type: "checkbox",
                            },
                            {
                                variable: "iconProperties",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconProperty"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.showIcons,
                                optionList: iconList,
                            },
                            {
                                variable: "iconProperties",
                                type: "icons",
                                visible: (options: WidgetOptions) => options.showIcons,
                            },
                            {
                                variable: "iconSize",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconSize"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.showIcons,
                                optionList: [
                                    {
                                        value: "",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconSizeDefault"),
                                    },
                                    {
                                        value: "--icon-bigger",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconBigger"),
                                    },
                                    {
                                        value: "--icon-smaller",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconSmaller"),
                                    },
                                ],
                            },
                            {
                                variable: "iconSize",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconSize"),
                                type: "range",
                                visible: (options: WidgetOptions) => options.showIcons,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconColor"),
                                type: "dual-color",
                                default: "color-brand-alt" as IconColoring,
                                visible: (options: WidgetOptions) => options.showIcons,
                            },
                            {
                                variable: "iconPosition",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconPosition"),
                                type: "select",
                                default: "start" as IconPosition,
                                visible: (options: WidgetOptions) => options.showIcons,
                                optionList: [
                                    {
                                        value: "start",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "top"),
                                    },
                                    {
                                        value: "center",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "center"),
                                    },
                                    {
                                        value: "end",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "bottom"),
                                    },
                                ],
                            },
                            {
                                variable: "iconDisplayColumns",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconDisplayColumns"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showIcons,
                            },
                            {
                                variable: "centerAligned",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "centerAligned"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.iconDisplayColumns,
                            },
                            {
                                variable: "paddingTop",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "paddingTop"),
                                type: "select",
                                default: "default-pt" as ContentTopPadding,
                                optionList: [
                                    {
                                        value: "default-pt",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "default"),
                                    },
                                    {
                                        value: "dk-pt-s",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "sSpacing"),
                                    },
                                    {
                                        value: "dk-pt-m",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "mSpacing"),
                                    },
                                    {
                                        value: "dk-pt-l",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "lSpacing"),
                                    },
                                    {
                                        value: "dk-pt-xxl",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "xlSpacing"),
                                    },
                                ],
                            },
                            {
                                variable: "paddingBottom",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "paddingBottom"),
                                type: "select",
                                default: "default-pb" as ContentBottomPadding,
                                optionList: [
                                    {
                                        value: "default-pb",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "default"),
                                    },
                                    {
                                        value: "dk-pb-s",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "sSpacing"),
                                    },
                                    {
                                        value: "dk-pb-m",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "mSpacing"),
                                    },
                                    {
                                        value: "dk-pb-l",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "lSpacing"),
                                    },
                                    {
                                        value: "dk-pb-xxl",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "xlSpacing"),
                                    },
                                ],
                            },
                            {
                                variable: "borderBox",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "borderBox"),
                                type: "select",
                                default: "" as Borders,
                                optionList: [
                                    {
                                        value: "",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "default"),
                                    },
                                    {
                                        value: "top-border",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "topBorder"),
                                    },
                                    {
                                        value: "right-border",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "rightBorder"),
                                    },
                                    {
                                        value: "bottom-border",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "bottomBorder"),
                                    },
                                    {
                                        value: "left-border",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "leftBorder"),
                                    },
                                    {
                                        value: "around-border",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "aroundBorder"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContent, "cardStyling"),
                    properties: [
                        [
                            {
                                variable: "stylingImage",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "stylingImage"),
                                type: "select",
                                default: "rectangle",
                                optionList: [
                                    {
                                        value: "rectangle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "stylingImageDefault"),
                                    },
                                    {
                                        value: "dk-circle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "stylingImageCircle"),
                                    },
                                ],
                            },
                            {
                                variable: "stylingCardBorder",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "stylingCardBorder"),
                                type: "select",
                                default: "border",
                                optionList: [
                                    {
                                        value: "yes",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "border"),
                                    },
                                    {
                                        value: "no",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "noBorder"),
                                    },
                                    {
                                        value: "border-radius",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "borderRadius"),
                                    },
                                ],
                            },
                            {
                                variable: "buttonColor",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "color"),
                                type: "dual-color",
                                default: "dk-button--primary",
                            },
                            {
                                variable: "horizontalView",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "horizontalView"),
                                type: "checkbox",
                                default: false,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

function getWebContentById(webContentId: string, cmsApi: CmsApiWrapper): Promise<(CmsApiWebContent & WithId) | null> {
    return cmsApi.webContentApi.findByIdDebounced({ id: webContentId });
}

// eslint-disable-next-line max-lines-per-function
export function webContentWidget(type: WidgetType) {
    const webcontent: WidgetSpec<WidgetOptions> = {
        id: "webcontent",
        type,
        widgetGroup: WidgetGroup ? (type === "page" || type === "resultsPanel" || type === "form" ? WidgetGroup.CONTENT : WidgetGroup.OTHER) : 2,
        name: getI18nLocaleObject(namespaceList.widgetWebContent, "webcontentWidget"),
        description: getI18nLocaleObject(namespaceList.widgetWebContent, "webcontentWidgetDescription"),
        optionsForm: widgetOptionsForm,
        defaultOptions: (): WidgetOptions => ({
            webContentId: null,
            styleIds: [],
            backgroundPanel: "default",
            showAnimate: false,
            animateProperties: "",
            showIcons: false,
            centerAligned: false,
            iconProperties: "none",
            iconColor: "color-brand",
            iconPosition: "start",
            stylingImage: "rectangle",
            stylingCardBorder: "",
            buttonColor: "dk-button--primary",
            centeredText: false,
            addBullets: false,
            paddingTop: "default-pt",
            paddingBottom: "default-pb",
            borderBox: "",
            iconSize: "",
            iconDisplayColumns: false,
            horizontalView: false,
        }),
        container: false,
    };
    (webcontent as PageWidgetSpec<WidgetOptions> | TypesearchContainerWidgetSpec<WidgetOptions>).render = async (
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapWidgetOptions: any,
        resultOptions: ResultOptions,
        dynamicContainerOptions: any,
        shouldReturnProps?: boolean
    ): Promise<any> => {
        const { currentLocale, site } = context;
        const { webContentId, styleIds } = widget.options;
        const env = await getMxtsEnv(context).then((res) => res.env);
        // TODO: Refactor everything related to webContents to use proper capitalization
        let webContent: (CmsApiWebContent & WithId) | null = webContentId ? await getWebContentById(webContentId, context.cmsApi) : null;
        const classNames = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        let imageSpec: ImageSpec;
        let cardImageSpec: ImageSpec;
        let imageUrl = "";
        let cardImageUrl = "";
        let sitePageUrl = "";
        let friendlyName = "";
        let alt = "";
        if (webContent) {
            const localizedContent: LocalizedContent | null = getLocalizedContent({ site, currentLocale, localizedContent: webContent.localizedContent });
            const localizedImageContent: LocalizedImageContent | null = getLocalizedContent({ site, currentLocale, localizedContent: webContent.localizedImageContent });
            imageSpec = localizedImageContent?.image || webContent.image;
            // TODO: all the code below is executed if the imageSpec is of type ImageSpec[] (array), which it never is... Need to carefully check what the real type is.
            const imageId = Array.isArray(imageSpec) && imageSpec[0]?.imageId;
            if (imageSpec?.imageId || imageId) {
                const image: Image | null = await getImageSpec(context, imageSpec);
                if (Array.isArray(imageSpec)) {
                    imageUrl = image !== null ? image.renderArrayUrl(imageSpec[0].options[0].preset, imageSpec[0].options[0].width, imageSpec[0].options[0].height) : "";
                }
            }
            const localizedCardContent: LocalizedCardContent = getLocalizedCardContent({ site, currentLocale, localizedCardContents: webContent.localizedCardContent });
            if (localizedCardContent?.cardImage) {
                cardImageSpec = localizedCardContent.cardImage;
                if (cardImageSpec && Array.isArray(cardImageSpec) && cardImageSpec.length) {
                    const cardImageSpecOptions = cardImageSpec[0].options[0];
                    const cardImage: Image | null = await getImageSpec(context, cardImageSpec);
                    const defaultLocalizedImageSpecOptions: LocalizedImageSpecOptions = {
                        locale: "",
                    };
                    const localizedImageSpecOptions: LocalizedImageSpecOptions =
                        getLocalizedContent({ site, currentLocale, localizedContent: cardImageSpecOptions.localized }) || defaultLocalizedImageSpecOptions;
                    if (Array.isArray(cardImageSpec)) {
                        cardImageUrl =
                            cardImage !== null ? cardImage.renderArrayUrl(cardImageSpecOptions.preset, cardImageSpecOptions.width, cardImageSpecOptions.height, localizedImageSpecOptions) : "";
                        const locale = context.currentLocale.locale;
                        const localizedOptions = cardImageSpecOptions.localized?.find((l: any) => l.locale === locale);
                        alt = localizedOptions?.alt;
                        friendlyName = localizedOptions?.friendlyName;
                    }
                }
                if (localizedCardContent.siteId) {
                    const linkedSite: (Site & WithId) | null =
                        site._id === localizedCardContent.siteId ? site : await context.cmsApi.siteApi.findById({ id: localizedCardContent.siteId, projection: { sitemap: 0 } });
                    sitePageUrl = sitePageUrl.concat("//");
                    if (linkedSite && localizedCardContent.pageId) {
                        // If we found localized content for the default locale of the current website (based on preference), take whatever sitehost we are linking to
                        // Else, default to the sitehost of the current website.
                        sitePageUrl = sitePageUrl.concat(context.site.enableMultiLanguage || localizedCardContent.locale === context.site.locale._id ? linkedSite.host : context.site.host);
                        const url = await pageLink({ site: linkedSite, pageId: localizedCardContent.pageId, locale: context.currentLocale, context });
                        sitePageUrl = sitePageUrl.concat(url || "");
                    }
                }
            }
            // This disables executing script tags directly on load
            if (localizedContent?.content?.includes("<script")) {
                webContent = null;
            }
        }
        return shouldReturnProps ? (
            {
                id: widget._id,
                options: widget.options,
                content: webContent,
                imageUrl,
                cardImageUrl,
                sitePageUrl,
                friendlyName,
                alt,
                className: classNames,
                context,
                env,
                spec: widget.spec,
            }
        ) : (
            <WebContent
                id={widget._id}
                options={widget.options}
                content={webContent}
                imageUrl={imageUrl}
                cardImageUrl={cardImageUrl}
                sitePageUrl={sitePageUrl}
                friendlyName={friendlyName}
                alt={alt}
                className={classNames}
                context={context}
                env={env}
            />
        );
    };
    (webcontent as PageWidgetSpec<WidgetOptions> | TypesearchContainerWidgetSpec<WidgetOptions>).instanceDescription = async ({ widget, context }): Promise<string> => {
        const { webContentId } = widget.options;
        const webContent: CmsApiWebContent | null = webContentId ? await getWebContentById(webContentId, context.cmsApi) : null;
        if (webContent) {
            return webContent.name;
        }
        return getI18nLocaleString(namespaceList.widgetWebContent, "noContent");
    };
    return webcontent;
}
