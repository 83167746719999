import { Theme } from ".";
import baseTheme from "./base";

const theme: Theme = {
    id: "europarcsnl",
    name: "Europarcsnl Theme",
    stylesheets: ["/styles/themes/europarcsnl/europarcsnl-admin.css"],
    frontendStylesheets: ["/styles/themes/europarcsnl/europarcsnl.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "price-on-button",
            name: "Show price on button",
            targets: ["flexbox"],
            classnames: ["price-on-button"],
        },
    ],
};

export default theme;
