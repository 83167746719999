import * as moment from "moment";

export const itLocaleSpecification: moment.LocaleSpecification = {
    calendar: {
        sameElse: "L",
    },
    longDateFormat: {
        LTS: "HH:mm:ss",
        LT: "HH:mm",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
    },
    invalidDate: "Invalid date",
    ordinal: () => "%d.",
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    relativeTime: {
        future: "tra %s",
        past: "%s fa",
        s: "alcuni secondi",
        ss: "%d secondi",
        m: "un minuto",
        mm: "%d minuti",
        h: "un'ora",
        hh: "%d ore",
        d: "un giorno",
        dd: "%d giorni",
        w: "una settimana",
        ww: "%d settimane",
        M: "un mese",
        MM: "%d mesi",
        y: "un anno",
        yy: "%d anni",
    },
    months: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
    monthsShort: ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"],
    week: {
        dow: 1,
        doy: 4,
    },
    weekdays: ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"],
    weekdaysMin: ["do", "lu", "ma", "me", "gi", "ve", "sa"],
    weekdaysShort: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
    dayOfMonthOrdinalParseLenient: {},
};
