import { Theme } from "./";
import baseTheme from "./base";
import myEnvironmentTheme from "./myenvironment";

const theme: Theme = {
    id: "estivo",
    name: "Estivo Theme",
    stylesheets: ["/styles/themes/estivo/estivo-admin.css"],
    frontendStylesheets: ["/styles/themes/estivo/estivo.css"],
    styles: [...baseTheme.styles, ...myEnvironmentTheme.styles],
};

export default theme;
