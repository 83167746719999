import * as React from "react";

import { PermissionType, Permissions } from "@maxxton/cms-mxts-api";

export const PermissionsContext = React.createContext<Permissions>({});

export const PermissionsProvider = PermissionsContext.Provider;

export const PermissionsConsumer = PermissionsContext.Consumer;

export interface WithPermissionsProps {
    permissions: Permissions;
}

export interface PermissionProps extends WithPermissionsProps {
    permission: PermissionType;
}

export function withPermissions<OwnProps>(Component: React.ComponentType<OwnProps>) {
    const WithPermissionsComponent = (props: OwnProps) => <PermissionsConsumer>{(permissions) => <Component {...props} permissions={permissions} />}</PermissionsConsumer>;
    WithPermissionsComponent.displayName = `WithPermissions(${Component.name})`;
    return WithPermissionsComponent;
}
