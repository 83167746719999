// eslint-disable-next-line max-classes-per-file
import * as moment from "moment";

import { AccommodationType, Resort, Unit, lookupDateMap } from "../plugins/mxts";
import { ApiCallOptions, Currency, DCRequest, DistributionChannel, MxtsDataAccessTokenManager, RateTypes, ReservationCategory, StayPeriodDef } from "@maxxton/cms-mxts-api";
import { CMSOptions, getCMSOptions } from "../plugins/settings";
import { CmsApi, Site } from "@maxxton/cms-api";
import { DynamicFilter, OwnerShareableLink, UTM } from "../redux/reducers/dynamicFilter.types";
import { MyEnvReservation, MyEnvState } from "../redux/reducers/myEnv/myEnvState";
import { parse, parseUrl, stringify } from "query-string";
import { parseInt, pick, uniq } from "lodash";

import { ApiContext } from "../containers/cmsProvider.types";
import { BrowserLocation } from "../app.types";
import { CategoryOptions } from "../plugins/page/categoryFilter/categoryFilter.enum";
import { ConfiguredLink } from "./linking.util";
import { DATE_FORMAT } from "./constants";
import { DateMap } from "../plugins/mxts/mxts.types";
import { NumberUtil } from "./number.util";
import { State } from "../redux/index";
import { StringUtil } from "./string.util";
import { UrlParam } from "../plugins/shared/priceMatrix/priceMatrix.types";
import { isClientSide } from "./generic.util";

export const LOCAL_STORAGE_STATE_UUIDS_KEY = "redux-state-uuids";

export const UNIVERSAL_AUTH_TOKEN_HASH_PARAM = "universalauthtoken";

const URL_PARAMS_THAT_ARE_NOT_CONVERTED_TO_STATE = ["imitateCustomerId"];

export interface UrlLinkParams {
    source?: string;
    stateUuid?: string;
    startdate?: string;
    enddate?: string;
    stay?: string;
    resort?: string;
    resortid?: string | number;
    resourceid?: number;
    accokindids?: number[];
    subject?: string[];
    useSubjectCategory?: boolean;
    specialcode?: string[];
    vouchercodes?: string;
    actiecode?: string;
    dc?: string;
    rt?: string;
    ccy?: string;
    lan?: string;
    objectid?: number;
    unitid?: number;
    stayperioddefid?: number;
    stayHolidayPeriodDefId?: number;
    duration?: number;
    amenities?: string[];
    amenity?: string;
    resortids?: number[];
    bedroom?: number;
    bathroom?: number;
    minbedroom?: number;
    maxbedroom?: number;
    minbathroom?: number;
    maxbathroom?: number;
    freeSearchId?: number | null;
    dynamicFreeSearchIds?: number[];
    ratetypeid?: number | null;
    regionIds?: number[];
    minprice?: number;
    maxprice?: number;
    minimumArrivalDate?: string;
    maximumArrivalDate?: string;
    unitPreference?: boolean;
    reservationid?: string;
    eec?: number;
    cancellationPremiumId?: number;
    reservationCategoryId?: number;
    minCapacity?: number;
    holiday?: string;
    ownerid?: number;
    rc?: string;
    rcid?: number;
    selectedreservationid?: string;
    matrixratetypecode?: string;
    dateMargin?: number;
    offerSearchCode?: string;
    resourceActivityDetailsId?: number;
    resourceActivityDetailsIds?: number[];
    selectedOwnerUnitId?: number;
    tipsAndTripsCategory?: number[];
    flowType?: string;
    selectedChoice?: string;
    resortActivityId?: number | null;
    activityTicketQuantity?: number;
    previouslySelectedResourceId?: number;
}

export interface UrlParamBuilderOptions {
    enableStateUuid?: boolean;
    includeMinBedBathZero?: boolean;
}

export function generateAndApplyStateUuid(location: BrowserLocation) {
    const generatedStateUuid = StringUtil.generateUUID();
    updateUrlWithNewStateId(generatedStateUuid, location);
    markStateUuidAsGeneratedByThisUser(generatedStateUuid);
    return generatedStateUuid;
}

class UrlParamValue {
    constructor(private urlParamValue?: string | number) {}

    get numberValue(): number | undefined {
        return this.urlParamValue ? +this.urlParamValue : undefined;
    }

    get stringValue(): string | undefined {
        return this.urlParamValue ? `${this.urlParamValue}` : undefined;
    }
}

function getUrlParam(allUrlParams: any, key: keyof UrlLinkParams, ignoreKeyCase = true): UrlParamValue {
    const foundKey = Object.keys(allUrlParams).find((urlParamKey) => (ignoreKeyCase ? urlParamKey.toLowerCase() === key.toLowerCase() : urlParamKey === key));
    return foundKey ? new UrlParamValue(allUrlParams[foundKey]) : new UrlParamValue(undefined);
}

// eslint-disable-next-line max-lines-per-function
export async function convertUrlParamsToState(fullUrl: string, apiContext: ApiContext, env: ApiCallOptions): Promise<Pick<State, "dynamicFilter" | "myEnvState" | "mxtsDataAccessTokenState">> {
    const options = parse(fullUrl);
    let subjects: Map<number, number> | undefined = new Map();
    if (options.subject) {
        if (Array.isArray(options.subject)) {
            options.subject.forEach((subject: string) => {
                const subjectArray = subject.split(",");
                subjects!.set(+subjectArray[0], +subjectArray[1]);
            });
        } else {
            const subjectArray = options.subject.split(",");
            subjects!.set(+subjectArray[0], +subjectArray[1]);
        }
    } else {
        subjects = undefined;
    }
    let stayPeriodDuration: number | undefined;
    if (options.stayperioddefid) {
        try {
            const stayperioddefid = parseInt(options.stayperioddefid, 10);
            stayPeriodDuration = (await apiContext.mxtsApi.stayPeriods(env, { stayPeriodDefIds: [stayperioddefid], startDate: moment().format("YYYY-MM-DD") })).content[0]?.duration;
        } catch (e) {
            console.warn(`Invalid number stayperioddefid: ${options.stayperioddefid}`);
        }
    }

    const ownerShareableLink: OwnerShareableLink = {
        distributionChannelCode: options.dc,
        reservationCategoryCode: options.rc,
        ownerId: (!isNaN(options.ownerid) && +options.ownerid) || undefined,
        unitId: (options.dc && options.rc && !isNaN(options.unitid) && +options.unitid) || (!isNaN(options.objectid) && +options.objectid) || undefined,
    };

    if (ownerShareableLink.reservationCategoryCode) {
        const reservationCategory: ReservationCategory = (await apiContext.mxtsApi.getReservationCategories(env, { codes: [ownerShareableLink.reservationCategoryCode] }))?.content?.[0];
        ownerShareableLink.reservationCategoryId = reservationCategory?.reservationCategoryId;
    }
    let pickedDistributionChannel: Pick<DistributionChannel, "distributionChannelId" | "code"> | undefined;
    if (options.dc) {
        const apiOptions: DCRequest = isNaN(parseInt(options.dc, 10)) ? { codes: [options.dc] } : { ids: [options.dc] };
        const distributionChannel = await apiContext.mxtsApi
            .distributionChannels(env, apiOptions)
            .then((response) => response?.content?.[0])
            .catch((error: Error) => {
                apiContext.logger.error(error);
                return undefined;
            });
        ownerShareableLink.distributionChannelId = distributionChannel?.distributionChannelId;
        // picking only required fields from the distributionChannel
        pickedDistributionChannel = distributionChannel && pick(distributionChannel, ["distributionChannelId", "code"]);
    }

    let rateType: RateTypes | undefined;
    const rateTypeCode = options.rt || options.matrixratetypecode;
    if (rateTypeCode) {
        rateType = await apiContext.mxtsApi
            .rates(env, { codes: [rateTypeCode] })
            .then((response) => response?.content?.[0])
            .catch((error: Error) => {
                apiContext.logger.error(error);
                return undefined;
            });
    }
    const pickedRateType = rateType && pick(rateType, ["rateTypeId", "code"]);
    let currency: Currency | undefined;
    if (options.ccy) {
        currency = await apiContext.mxtsApi
            .currencies(env, { codes: [options.ccy] })
            .then((currencies) => {
                const { symbol, code, name, currencyId, currencyLanguage, currencyCountry } = currencies[0] || {};
                return { symbol, code, name, currencyId, currencyLanguage, currencyCountry };
            })
            .catch((error: Error) => {
                apiContext.logger.error(error);
                return undefined;
            });
    } else {
        currency = rateType?.currency;
    }

    const utm: UTM = {
        referrer: options.referrer,
        utm_channel: options.utm_channel,
        utm_source: options.utm_source,
        utm_campaign: options.utm_campaign,
        utm_medium: options.utm_medium,
        utm_content: options.utm_content,
    };

    const myEnvState: MyEnvState = {
        selectedReservationId: options.selectedreservationid ? +options.selectedreservationid : undefined,
        ownerState: { selectedUnitId: getUrlParam(options, "selectedOwnerUnitId").numberValue },
    };

    if (options?.amenities?.length) {
        options.amenities = options.amenities?.length === 1 ? options.amenities[0].split(",") : options.amenities;
    }
    if (options.amenity?.length) {
        options.amenities = Array.isArray(options.amenities) ? [...(options.amenities || []), options.amenity] : [options.amenities, options.amenity];
    }

    // Now Dynamic filter supports all of it's URL params in lowercase as well.
    // Eg: regionIds, regionids and regionid(only for a single id)
    // all of the above three types of param will be supported for backward compatability
    // but the recommended way is to always use lower case letters for URL params.
    const dynamicFilter: DynamicFilter = {
        reservationId: options.reservationid ? decodeNumberIfEncoded(options.reservationid) : undefined,
        resourceid: options.resourceid ? +options.resourceid : undefined,
        unitid: ownerShareableLink.unitId || (options.unitid ? +options.unitid : undefined),
        unitPreference: options.unitPreference,
        resort: options.resort,
        startdate: options.startdate,
        enddate:
            options.enddate ||
            (options.startdate && options.duration
                ? moment(options.startdate, DATE_FORMAT.DEFAULT).add(options.duration, "days").format(DATE_FORMAT.DEFAULT)
                : stayPeriodDuration && options.startDate
                ? moment(options.startdate, DATE_FORMAT.DEFAULT).add(stayPeriodDuration, "days").format(DATE_FORMAT.DEFAULT)
                : undefined),
        stay: options.stay,
        holiday: options.holiday,
        stayperioddefid: options.stayperioddefid && +options.stayperioddefid,
        stayHolidayPeriodDefId: options.stayHolidayPeriodDefId && +options.stayHolidayPeriodDefId,
        specialcode: Array.isArray(options.specialcode) ? options.specialcode : options.specialcode?.split(","),
        voucherCodes: Array.isArray(options.vouchercodes) ? options.vouchercodes : options.vouchercodes?.split(","),
        amenities: Array.isArray(options.amenities) ? options.amenities.filter((amenity: string | null) => amenity !== null) : options.amenities ? [options.amenities] : undefined,
        accokindids: options.accokindids
            ? Array.isArray(options.accokindids)
                ? options.accokindids.map((accokindid: any) => +accokindid)
                : [+options.accokindids]
            : options.accokindid
            ? [options.accokindid]
            : undefined,
        resortids: options.resortids
            ? Array.isArray(options.resortids)
                ? options.resortids.map((resortid: any) => +resortid)
                : [+options.resortids]
            : options.resortid
            ? [options.resortid]
            : undefined,
        subject: subjects,
        useSubjectCategory: options.useSubjectCategory,
        bedroom: options.bedroom,
        bathroom: options.bathroom,
        minbedroom: options.minbedroom ? +options.minbedroom : undefined,
        maxbedroom: options.maxbedroom ? +options.maxbedroom : undefined,
        minbathroom: options.minbathroom ? +options.minbathroom : undefined,
        maxbathroom: options.maxbathroom ? +options.maxbathroom : undefined,
        distributionChannel: pickedDistributionChannel,
        reservationCategoryId: options.rcid,
        previouslySelectedResourceId: +options.previouslySelectedResourceId,
        rateType: pickedRateType,
        priceMatrixRateTypeCode: options.matrixratetypecode,
        currency,
        freeSearchId: (options.freesearchid ? +options.freesearchid : undefined) || (options.freeSearchId ? +options.freeSearchId : undefined),
        dynamicFreeSearchIds: Array.isArray(options.dynamicFreeSearchIds)
            ? options.dynamicFreeSearchIds?.map((id: string) => +id)
            : (options.dynamicFreeSearchIds && [+options.dynamicFreeSearchIds]) || [],
        regionIds: parseRegionIds(options),
        minimumArrivalDate: options.minimumArrivalDate ? moment(options.minimumArrivalDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC) : undefined,
        maximumArrivalDate: options.maximumArrivalDate ? moment(options.maximumArrivalDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC) : undefined,
        paymentStatus: options.status,
        isPayingCustomer: options.isPayingCustomer,
        payingCustomerReservationCategoryId: options.payingCustomerReservationCategoryId,
        eec: options.eec || 0,
        flowType: options.flowType,
        cancellationPremiumId: +options.cancellationPremiumId || 0,
        utm,
        utmSource: utm.utm_source || undefined,
        gclid: options.gclid,
        aaaid: options.aaaid,
        tduid: options.tduid,
        ownerShareableLink,
        dateMargin: options.dateMargin,
        offerSearchCode: options.offerSearchCode,
        minCapacity: (options.minCapacity && +options.minCapacity) || (options.mincapacity && +options.mincapacity) || undefined,
        maxArrivalDate: options.maxArrivalDate,
        minArrivalDate: options.minArrivalDate,
        shouldFetchResorts: !!options.shouldFetchResorts,
        shouldFetchUnitsWithPrice: !!options.shouldFetchUnitsWithPrice,
        shouldFetchSortedUnits: !!options.shouldFetchSortedUnits,
        shouldFetchStayPeriods: !!options.shouldFetchStayPeriods,
        resourceActivityDetailsId: options.resourceActivityDetailsId,
        facilityids: options.facilityids,
        pointsOfInterestIds: Array.isArray(options.pointsOfInterestIds) ? options.pointsOfInterestIds?.map((id: string) => +id) : (options.pointsOfInterestIds && [+options.pointsOfInterestIds]) || [],
        categoryFilters: setCategoryFilters(options),
        source: options.source,
        selectedChoice: options?.selectedChoice,
        resortActivityId: options.resortActivityId,
        activityTicketQuantity: options.activityTicketQuantity,
        resourceActivityDetailsIds:
            (Array.isArray(options.resourceActivityDetailsIds) && options.resourceActivityDetailsIds?.length && options.resourceActivityDetailsIds.map((id: string) => Number(id))) ||
            (options.resourceActivityDetailsIds && [Number(options.resourceActivityDetailsIds)]) ||
            [],
    };

    let allAccessKeys: string[] = [];
    if (isClientSide()) {
        const urlAccessKeys: string[] = options.dataAccessKeys ? atob(options.dataAccessKeys).split(",") : [];
        const localStorageAccessKeys: string[] = dynamicFilter.reservationId ? MxtsDataAccessTokenManager.loadDataAccessKeysFromLocalStorage() : [];
        allAccessKeys = uniq(urlAccessKeys.concat(localStorageAccessKeys).filter((key) => key));
    }

    return {
        myEnvState,
        dynamicFilter,
        mxtsDataAccessTokenState: { accessKeys: allAccessKeys },
    };
}

function setCategoryFilters(options: Record<string, any>) {
    const tipsAndTripsIds = options?.[CategoryOptions.tipsAndTrips];
    const activityCategoryIds = options?.[CategoryOptions.activities];
    if (tipsAndTripsIds?.length) {
        return {
            filterType: CategoryOptions.tipsAndTrips,
            selectedIds: Array.isArray(tipsAndTripsIds) ? tipsAndTripsIds?.map((id: string) => +id) : (tipsAndTripsIds && [+tipsAndTripsIds]) || [],
        };
    }
    if (activityCategoryIds?.length) {
        return {
            filterType: CategoryOptions.activities,
            selectedIds: Array.isArray(activityCategoryIds) ? activityCategoryIds?.map((id: string) => +id) : (activityCategoryIds && [+activityCategoryIds]) || [],
        };
    }
}

function decodeNumberIfEncoded(input: string | number | undefined): number | undefined {
    if (input != null && StringUtil.isString(input)) {
        if (NumberUtil.isNumber(+input)) {
            return +input;
        }
        return +StringUtil.decodeBase64("" + input);
    }
    return input as number | undefined;
}

function parseRegionIds(options: { regionIds?: string[] | string; regionId?: string; regionids?: string[] | string; regionid?: string }): number[] {
    if (options.regionIds) {
        return Array.isArray(options.regionIds) ? options.regionIds.map((regionId: string) => +regionId) : (options.regionIds && [+options.regionIds]) || [];
    }
    if (options.regionids) {
        return Array.isArray(options.regionids) ? options.regionids.map((regionId: string) => +regionId) : (options.regionids && [+options.regionids]) || [];
    }
    if (options.regionId) {
        return [+options.regionId];
    }
    if (options.regionid) {
        return [+options.regionid];
    }
    return [];
}

function updateUrlWithNewStateId(stateUuid: string, location: BrowserLocation) {
    const searchQueryParams = parse(location.search);
    searchQueryParams.stateUuid = stateUuid;
    updateUrlWithQueryParams(searchQueryParams);
}

function markStateUuidAsGeneratedByThisUser(generatedStateUuid: string) {
    if (typeof localStorage !== "undefined") {
        const localStorageStateUuids: string[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_UUIDS_KEY) || "[]");
        while (localStorageStateUuids.length > 999) {
            localStorageStateUuids.shift();
        }
        localStorageStateUuids.push(generatedStateUuid);
        localStorage.setItem(LOCAL_STORAGE_STATE_UUIDS_KEY, JSON.stringify(localStorageStateUuids));
    }
}

// eslint-disable-next-line max-lines-per-function
export function convertDynamicFilterToUrlLinkParams(filter: DynamicFilter): UrlLinkParams {
    const newParams: any = {};
    // For exact matches of beds and baths, we are sending 0 as well. Eg. 0 beds = Studio apartments
    if (filter.minbedroom || filter.minbedroom === 0) {
        newParams.minbedroom = filter.minbedroom;
    }
    if (filter.maxbedroom || filter.maxbedroom === 0) {
        newParams.maxbedroom = filter.maxbedroom;
    }
    if (filter.minbathroom || filter.minbathroom === 0) {
        newParams.minbathroom = filter.minbathroom;
    }
    if (filter.maxbathroom || filter.maxbathroom === 0) {
        newParams.maxbathroom = filter.maxbathroom;
    }
    if (filter.selectedChoice) {
        newParams.selectedChoice = filter.selectedChoice;
    }
    if (filter.minprice) {
        newParams.minprice = filter.minprice;
    }
    if (filter.maxprice) {
        newParams.maxprice = filter.maxprice;
    }
    if (filter.startdate) {
        newParams.startdate = filter.startdate;
    }
    if (filter.duration) {
        newParams.duration = filter.duration;
    }
    if (filter.minCapacity) {
        newParams.minCapacity = filter.minCapacity;
    }
    if (filter.enddate && !filter.stayperioddefid && !filter.duration) {
        newParams.enddate = filter.enddate;
    }
    if (filter.specialcode && filter.specialcode.length > 0) {
        newParams.specialcode = filter.specialcode.join();
    }
    if (filter.voucherCodes?.length) {
        newParams.voucherCodes = filter.voucherCodes.join();
    }
    if (filter.amenities && filter.amenities.length > 0) {
        newParams.amenities = filter.amenities;
    }
    if (filter.accokindids && filter.accokindids.length > 0) {
        newParams.accokindids = filter.accokindids;
    }
    if (filter.stay) {
        newParams.stay = filter.stay;
    }
    if (filter.stayperioddefid) {
        newParams.stayperioddefid = filter.stayperioddefid;
    }
    if (filter.stayHolidayPeriodDefId && !filter.holiday) {
        newParams.stayHolidayPeriodDefId = filter.stayHolidayPeriodDefId;
    }
    if (filter.holiday) {
        newParams.holiday = filter.holiday;
    }
    if (filter.resortids && filter.resortids.length > 0) {
        newParams.resortids = filter.resortids;
    }
    if (filter.resourceids?.length) {
        newParams.resourceids = filter.resourceids;
    }
    if (filter.subject) {
        const subjectParams: string[] = [];
        filter.subject.forEach((value: number, key: number) => {
            if (value > 0) {
                subjectParams.push(key.toString() + "," + value.toString());
            }
        });
        newParams.subject = subjectParams;
    }
    if (filter.useSubjectCategory) {
        newParams.useSubjectCategory = filter.useSubjectCategory;
    }
    if (filter.freeSearchId !== undefined && filter.freeSearchId !== null && !isNaN(filter.freeSearchId)) {
        newParams.freesearchid = filter.freeSearchId;
    }
    if (filter.dynamicFreeSearchIds?.length) {
        newParams.dynamicFreeSearchIds = filter.dynamicFreeSearchIds;
    }
    if (filter.regionIds && !filter.topLeftPoint && !filter.bottomRightPoint) {
        newParams.regionids = filter.regionIds;
    }
    if (filter.minimumArrivalDate) {
        newParams.minimumArrivalDate = moment(filter.minimumArrivalDate, DATE_FORMAT.ELASTIC).format(DATE_FORMAT.DEFAULT);
    }
    if (filter.maximumArrivalDate) {
        newParams.maximumArrivalDate = moment(filter.maximumArrivalDate, DATE_FORMAT.ELASTIC).format(DATE_FORMAT.DEFAULT);
    }
    if (filter.resourceid) {
        newParams.resourceid = filter.resourceid;
    }
    if (filter.unitid) {
        newParams.unitid = filter.unitid;
    }
    if (filter.source) {
        newParams.source = filter.source;
    }
    if (filter.unitPreference) {
        newParams.unitPreference = filter.unitPreference;
    }
    if (filter.reservationId) {
        newParams.reservationid = StringUtil.encodeBase64(filter.reservationId);
    }
    if (filter.eec) {
        newParams.eec = filter.eec;
    }
    if (filter.flowType) {
        newParams.flowType = filter.flowType;
    }
    if (filter.cancellationPremiumId) {
        newParams.cancellationPremiumId = filter.cancellationPremiumId;
    }
    if (filter.priceMatrixRateTypeCode) {
        newParams.matrixratetypecode = filter.priceMatrixRateTypeCode;
    }
    if (filter.stateUuid) {
        newParams.stateUuid = filter.stateUuid;
    }
    if (filter.gclid) {
        newParams.gclid = filter.gclid;
    }
    if (filter.utm?.referrer) {
        newParams.referrer = filter.utm.referrer;
    }
    if (filter.utm?.utm_campaign) {
        newParams.utm_campaign = filter.utm.utm_campaign;
    }
    if (filter.utm?.utm_content) {
        newParams.utm_content = filter.utm.utm_content;
    }
    if (filter.utm?.utm_medium) {
        newParams.utm_medium = filter.utm.utm_medium;
    }
    if (filter.utm?.utm_source) {
        newParams.utm_source = filter.utm.utm_source;
    }
    if (filter.utm?.utm_channel) {
        newParams.utm_channel = filter.utm.utm_channel;
    }
    if (filter.aaaid) {
        newParams.aaaid = filter.aaaid;
    }
    if (filter.tduid) {
        newParams.tduid = filter.tduid;
    }
    if (filter.ownerShareableLink?.distributionChannelCode) {
        newParams.dc = filter.ownerShareableLink.distributionChannelCode;
    }
    if (filter.ownerShareableLink?.reservationCategoryCode) {
        newParams.rc = filter.ownerShareableLink.reservationCategoryCode;
    }
    if (filter.ownerShareableLink?.ownerId) {
        newParams.ownerid = filter.ownerShareableLink.ownerId;
    }
    if (filter.dateMargin) {
        newParams.dateMargin = filter.dateMargin;
    }
    if (filter.offerSearchCode) {
        newParams.offerSearchCode = filter.offerSearchCode;
    }
    if (filter.categoryFilters) {
        const { filterType, selectedIds } = filter.categoryFilters;
        if (filterType) {
            newParams[filterType] = selectedIds;
        }
    }
    if (filter.resourceActivityDetailsId) {
        newParams.resourceActivityDetailsId = filter.resourceActivityDetailsId;
    }
    if (filter.activityTicketQuantity) {
        newParams.activityTicketQuantity = filter.activityTicketQuantity;
    }
    if (filter.resourceActivityDetailsIds) {
        newParams.resourceActivityDetailsIds = filter.resourceActivityDetailsIds;
    }
    if (filter.resortActivityId) {
        newParams.resortActivityId = filter.resortActivityId;
    }
    if (filter.showCurrencyDetailsInUrl) {
        if (filter.distributionChannel) {
            newParams.dc = filter.distributionChannel.code;
        }
        if (filter.rateType) {
            newParams.rt = filter.rateType.code;
        }
        if (filter.currency?.code) {
            newParams.ccy = filter.currency.code;
        }
    }
    if (filter.previouslySelectedResourceId) {
        newParams.previouslySelectedResourceId = filter.previouslySelectedResourceId;
    }
    return newParams;
}

export function updateUrlWithQueryParams(newParams: UrlLinkParams, previousParams?: UrlLinkParams) {
    const { origin, pathname, hash } = window.location;
    const otherUrlParams = parse(window.location.search);
    if ((newParams || previousParams) && otherUrlParams) {
        Object.keys(newParams || {})
            .concat(Object.keys(previousParams || {}))
            .filter((urlParamKey) => !URL_PARAMS_THAT_ARE_NOT_CONVERTED_TO_STATE.some((nonStateParam) => StringUtil.equalsIgnoreCase(nonStateParam, urlParamKey)))
            .forEach((urlParamKey) => delete otherUrlParams[urlParamKey]);
    }
    if (newParams.stateUuid) {
        // The state contains all dynamicFilters so no need to show them in the url.
        newParams = { stateUuid: newParams.stateUuid };
    }
    if (otherUrlParams.amenity) {
        delete otherUrlParams.amenity;
    }
    if (newParams?.stay && newParams?.stayperioddefid) {
        delete newParams.stayperioddefid;
    }
    if (newParams.resortids && newParams.resortids.length > 0 && otherUrlParams.resortid) {
        delete otherUrlParams.resortid;
    }
    const newURLParams = stringify({ ...otherUrlParams, ...newParams }, { encode: false, strict: false });
    const newUrl = `${origin}${pathname}${newURLParams ? "?" : ""}${newURLParams || ""}${hash}`;

    window.history.replaceState("Criteria", "Selected Criteria", newUrl);
}

export function setUrlParam(key: keyof UrlLinkParams, value?: string | number) {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    if (value) {
        searchParams.set(key, `${value}`);
    } else if (searchParams.has(key)) {
        searchParams.delete(key);
    } else {
        return;
    }
    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
    window.history.replaceState({ path: newUrl }, "", newUrl);
}

export class UrlParamsUtil {
    private static readonly DEFAULT_PARAM_BUILDER_OPTIONS: UrlParamBuilderOptions = { enableStateUuid: true, includeMinBedBathZero: false };

    public static getUrlParamsSelectedWithPriceMatrix = ({ props, accommodationType, unit, resort, content, startDate, endDate, matrixRateTypeCode }: UrlParam) => {
        const { dynamicFilter, context, options } = props;
        const locale = context.currentLocale.code;
        const localeId = context.currentLocale.locale;
        const localContent = options.localizedButton?.find((lc: any) => lc.locale === localeId);
        const params = UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter);
        if (locale && unit) {
            params.lan = locale;
        }
        if (matrixRateTypeCode) {
            params.matrixratetypecode = matrixRateTypeCode;
        }
        if (endDate) {
            params.enddate = endDate;
        }
        if (startDate) {
            params.startdate = startDate;
        }
        if (dynamicFilter.resourceid || accommodationType?.resourceId) {
            params.resourceid = accommodationType?.resourceId || dynamicFilter.resourceid;
        } else if (unit) {
            params.resourceid = unit.resourceId;
        } else if (resort) {
            params.resortid = resort.resortId;
            params.resourceid = (resort as Resort).curatedAccommodation?.resourceId;
        }
        if (dynamicFilter.unitid) {
            if (localContent?.bookButton) {
                params.objectid = dynamicFilter.unitid;
            } else {
                params.unitid = dynamicFilter.unitid;
            }
        } else if (unit?.unitId) {
            if (localContent?.bookButton) {
                params.objectid = unit.unitId;
            } else {
                params.unitid = unit.unitId;
            }
        }
        if (dynamicFilter?.specialcode?.length) {
            if (localContent?.bookButton) {
                params.actiecode = dynamicFilter ? dynamicFilter.specialcode[0] : undefined;
            } else {
                params.specialcode = (content as AccommodationType | Unit | undefined)?.specialCode;
            }
        }
        // if (dynamicFilter.amenities?.length && this.state.amenityCodes?.length) {
        //     params.amenity = Array.from(this.state.amenityCodes.values()).join();
        // }
        return params;
    };

    public static setPageViewEvent = (props: any, bookUrl: string, accommodationType?: AccommodationType, unit?: Unit) => {
        const { context } = props;
        if (localStorage) {
            if (accommodationType) {
                const accoType = JSON.parse(localStorage.getItem("accoType") || "[]");
                accoType.push(accommodationType);
                localStorage.setItem("accoType", JSON.stringify(accoType));
            } else if (unit) {
                const customUnit = JSON.parse(localStorage.getItem("customUnit") || "[]");
                let typename;
                if (unit.namePath) {
                    const unitNamePath = unit.namePath.split("/");
                    typename = unitNamePath[unitNamePath.length - 1];
                }
                customUnit.push({ ...unit, bookUrl, typeName: typename ? typename.trim() : unit.resortName });
                localStorage.setItem("customUnit", JSON.stringify(customUnit));
            }
            const stack = JSON.parse(localStorage.getItem("pageNavigation") || "[]");
            stack.push(window.location.protocol + "//" + window.location.hostname + bookUrl);
            localStorage.setItem("pageNavigation", JSON.stringify(stack));
        }
        if (!context.isAdmin && context.site.useGTM) {
            (window as any).dataLayer.push({
                event: "PageView",
                meta: {
                    referrer: window.location.href,
                    pathname: bookUrl,
                },
            });
        }
    };

    public static handleDispatchOpenLinkedTabAction = (props: any) => {
        const { dispatchOpenLinkedTabAction, options } = props;
        if (typeof dispatchOpenLinkedTabAction === "function") {
            dispatchOpenLinkedTabAction(options);
        }
    };

    // eslint-disable-next-line max-lines-per-function
    public static getUrlParamsFromFilter(dynamicFilter: DynamicFilter, options?: UrlParamBuilderOptions, checkAdditionStep?: boolean): UrlLinkParams {
        options = { ...this.DEFAULT_PARAM_BUILDER_OPTIONS, ...options };

        const params: UrlLinkParams = {};
        if (dynamicFilter.stateUuid && options.enableStateUuid && !checkAdditionStep) {
            params.stateUuid = dynamicFilter.stateUuid;
            // The stateUuid replaces all other params. So it's the only param
            return params;
        }
        if (dynamicFilter.bedroom) {
            params.bedroom = dynamicFilter.bedroom;
        }
        if (dynamicFilter.bathroom) {
            params.bathroom = dynamicFilter.bathroom;
        }
        if (dynamicFilter.minbedroom || (options.includeMinBedBathZero && dynamicFilter.minbedroom === 0)) {
            params.minbedroom = dynamicFilter.minbedroom;
        }
        if (dynamicFilter.maxbedroom || (options.includeMinBedBathZero && dynamicFilter.maxbedroom === 0)) {
            params.maxbedroom = dynamicFilter.maxbedroom;
        }
        if (dynamicFilter.minbathroom || (options.includeMinBedBathZero && dynamicFilter.minbathroom === 0)) {
            params.minbathroom = dynamicFilter.minbathroom;
        }
        if (dynamicFilter.maxbathroom || (options.includeMinBedBathZero && dynamicFilter.maxbathroom === 0)) {
            params.maxbathroom = dynamicFilter.maxbathroom;
        }
        if (dynamicFilter.minprice) {
            params.minprice = dynamicFilter.minprice;
        }
        if (dynamicFilter.selectedChoice) {
            params.selectedChoice = dynamicFilter.selectedChoice;
        }
        if (dynamicFilter.maxprice) {
            params.maxprice = dynamicFilter.maxprice;
        }
        if (dynamicFilter.startdate) {
            params.startdate = dynamicFilter.startdate;
        }
        if (dynamicFilter.duration) {
            params.duration = dynamicFilter.duration;
        }
        if (dynamicFilter.enddate && !dynamicFilter.duration && !dynamicFilter.stayperioddefid) {
            params.enddate = dynamicFilter.enddate;
        }
        if (dynamicFilter.dateMargin) {
            params.dateMargin = dynamicFilter.dateMargin;
        }
        if (dynamicFilter.specialcode?.length) {
            params.specialcode = dynamicFilter.specialcode;
        }
        if (dynamicFilter.voucherCodes?.length) {
            params.vouchercodes = dynamicFilter.voucherCodes.join(",");
        }
        if (dynamicFilter.amenities?.length) {
            params.amenities = dynamicFilter.amenities;
        }
        if (dynamicFilter?.accokindids?.length) {
            params.accokindids = dynamicFilter.accokindids;
        }
        if (dynamicFilter.stayperioddefid) {
            params.stayperioddefid = dynamicFilter.stayperioddefid;
        }
        if (dynamicFilter.stayHolidayPeriodDefId) {
            params.stayHolidayPeriodDefId = dynamicFilter.stayHolidayPeriodDefId;
        }
        if (dynamicFilter.holiday) {
            params.holiday = dynamicFilter.holiday;
        }
        if (dynamicFilter.stay) {
            params.stay = dynamicFilter.stay;
        }
        if (dynamicFilter?.resortids?.length) {
            params.resortids = dynamicFilter.resortids;
        }
        if (dynamicFilter.resort) {
            params.resort = dynamicFilter.resort;
        }
        if (dynamicFilter.subject) {
            const subjectParams: string[] = [];
            dynamicFilter.subject.forEach((value: number, key: number) => {
                if (value > 0) {
                    subjectParams.push(key.toString() + "," + value.toString());
                }
            });
            params.subject = subjectParams;
        }
        if (dynamicFilter.useSubjectCategory) {
            params.useSubjectCategory = dynamicFilter.useSubjectCategory;
        }
        if (dynamicFilter.freeSearchId !== undefined && dynamicFilter.freeSearchId !== null && !isNaN(dynamicFilter.freeSearchId)) {
            params.freeSearchId = dynamicFilter.freeSearchId;
        }
        if (dynamicFilter.dynamicFreeSearchIds?.length) {
            params.dynamicFreeSearchIds = dynamicFilter.dynamicFreeSearchIds;
        }
        if (dynamicFilter.regionIds && !dynamicFilter.topLeftPoint && !dynamicFilter.bottomRightPoint) {
            params.regionIds = dynamicFilter.regionIds;
        }
        if (dynamicFilter.minimumArrivalDate) {
            params.minimumArrivalDate = moment(dynamicFilter.minimumArrivalDate, DATE_FORMAT.ELASTIC).format(DATE_FORMAT.DEFAULT);
        }
        if (dynamicFilter.maximumArrivalDate) {
            params.maximumArrivalDate = moment(dynamicFilter.maximumArrivalDate, DATE_FORMAT.ELASTIC).format(DATE_FORMAT.DEFAULT);
        }
        if (dynamicFilter.resourceid) {
            params.resourceid = dynamicFilter.resourceid;
        }
        if (dynamicFilter.unitid) {
            params.unitid = dynamicFilter.unitid;
        }
        if (dynamicFilter.source) {
            params.source = dynamicFilter.source;
        }
        if (dynamicFilter.unitPreference) {
            params.unitPreference = dynamicFilter.unitPreference;
        }
        if (dynamicFilter.reservationId) {
            params.reservationid = StringUtil.encodeBase64(dynamicFilter.reservationId);
        }
        if (dynamicFilter.eec) {
            params.eec = dynamicFilter.eec;
        }
        if (dynamicFilter.flowType) {
            params.flowType = dynamicFilter.flowType;
        }
        if (dynamicFilter.cancellationPremiumId) {
            params.cancellationPremiumId = dynamicFilter.cancellationPremiumId;
        }
        if (dynamicFilter.offerSearchCode) {
            params.offerSearchCode = dynamicFilter.offerSearchCode;
        }
        if (dynamicFilter.categoryFilters?.selectedIds) {
            if (dynamicFilter.categoryFilters?.filterType === CategoryOptions.tipsAndTrips) {
                params.tipsAndTripsCategory = dynamicFilter.categoryFilters?.selectedIds;
            }
        }
        if (dynamicFilter.resourceActivityDetailsIds) {
            params.resourceActivityDetailsIds = dynamicFilter.resourceActivityDetailsIds;
        }
        if (dynamicFilter.resourceActivityDetailsId) {
            params.resourceActivityDetailsId = dynamicFilter.resourceActivityDetailsId;
        }
        if (dynamicFilter.resortActivityId) {
            params.resortActivityId = dynamicFilter.resortActivityId;
        }
        if (dynamicFilter.activityTicketQuantity) {
            params.activityTicketQuantity = dynamicFilter.activityTicketQuantity;
        }
        // Owner shareable link params
        if (dynamicFilter?.ownerShareableLink?.distributionChannelCode) {
            params.dc = dynamicFilter.ownerShareableLink.distributionChannelCode;
        }
        if (dynamicFilter?.ownerShareableLink?.reservationCategoryCode) {
            params.rc = dynamicFilter.ownerShareableLink.reservationCategoryCode;
        }
        if (dynamicFilter?.ownerShareableLink?.ownerId) {
            params.ownerid = dynamicFilter.ownerShareableLink.ownerId;
        }

        if (dynamicFilter.showCurrencyDetailsInUrl) {
            if (dynamicFilter.distributionChannel?.code) {
                params.dc = dynamicFilter.distributionChannel.code;
            }
            if (dynamicFilter.rateType?.code) {
                params.rt = dynamicFilter.rateType.code;
            }
            if (dynamicFilter.currency?.code) {
                params.ccy = dynamicFilter.currency.code;
            }
        }

        return params;
    }

    public static getBmUrlWithQueryParams(url: string, params: UrlLinkParams): string {
        const { url: urlWithoutQueryParams, query } = parseUrl(url);
        const queryParams = { ...params, ...query };
        return `${urlWithoutQueryParams}${url.endsWith("?") || Object.keys(queryParams).length > 0 ? "?" + stringify(queryParams, { encode: false, strict: false }) : ""}`;
    }

    public static getBookingsEngineUrl(
        locale: string,
        unit: Unit | undefined,
        accommodationType: AccommodationType | undefined,
        unitBookUri: string | undefined,
        dynamicFilter: DynamicFilter | undefined,
        amenityWithCodes?: string[]
    ): string {
        const params: UrlLinkParams = dynamicFilter ? UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter) : {};
        let url = "";
        if (unitBookUri) {
            url = unitBookUri;
        }
        if (locale) {
            params.lan = locale;
        }
        if (dynamicFilter && dynamicFilter.unitid) {
            params.objectid = dynamicFilter.unitid;
        } else if (unit && unit.unitId) {
            params.objectid = unit.unitId;
            if (unit.resourceId && dynamicFilter && !dynamicFilter.resourceid) {
                params.resourceid = unit.resourceId;
            }
        }
        if (dynamicFilter && dynamicFilter.specialcode) {
            params.actiecode = dynamicFilter.specialcode[0];
        }
        if (dynamicFilter && dynamicFilter.resourceid) {
            params.resourceid = dynamicFilter.resourceid;
        } else if (accommodationType && accommodationType.resourceId) {
            params.resourceid = accommodationType.resourceId;
        }
        if (dynamicFilter && dynamicFilter.enddate) {
            params.enddate = dynamicFilter.enddate;
        }
        if (dynamicFilter?.rateType?.rateTypeId) {
            params.ratetypeid = dynamicFilter.rateType?.rateTypeId;
        } else if (accommodationType && accommodationType.rateTypeId) {
            params.ratetypeid = accommodationType.rateTypeId;
        }

        // This util method seems to use amenity instead of amenities...
        delete params.amenities;
        if (dynamicFilter?.amenities?.length && amenityWithCodes?.length) {
            params.amenity = amenityWithCodes.join();
        }
        return UrlParamsUtil.getBmUrlWithQueryParams(url, params);
    }

    public static getMyEnvLink(dynamicFilter: DynamicFilter, esReservationResult: MyEnvReservation, link: ConfiguredLink): string | undefined {
        const params: UrlLinkParams = UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter);
        if (esReservationResult?.reservation?.reservationId) {
            params.selectedreservationid = `${esReservationResult.reservation.reservationId}`;
        }

        if (link) {
            const bookUrl = link.url && UrlParamsUtil.getBmUrlWithQueryParams(link.url, params);
            if (bookUrl || link.anchor) {
                return `${bookUrl}${link.anchor ? "#" + link.anchor : ""}`;
            }
        }

        return undefined;
    }

    public static addTrailingSlashToUrl(url: string): string {
        if (url.endsWith("/")) {
            return url;
        } else if (url.includes("?")) {
            const queryParamIndex = url.lastIndexOf("?");
            if (url.charAt(queryParamIndex - 1) !== "/") {
                return `${url.slice(0, queryParamIndex)}/${url.slice(queryParamIndex)}`;
            }
        } else if (url.includes("#")) {
            const anchorIndex = url.lastIndexOf("#");
            if (url.charAt(anchorIndex - 1) !== "/") {
                return `${url.slice(0, anchorIndex)}/${url.slice(anchorIndex)}`;
            }
        } else {
            return `${url}/`;
        }
        return url;
    }

    public static removeTrailingSlashFromUrl(url: string): string {
        if (url.includes("?")) {
            const queryParamIndex = url.lastIndexOf("?");
            if (url.charAt(queryParamIndex - 1) === "/" && queryParamIndex > 1) {
                url = url.slice(0, queryParamIndex - 1) + url.slice(queryParamIndex);
            }
        }
        if (url.includes("#")) {
            const anchorIndex = url.lastIndexOf("#");
            if (url.charAt(anchorIndex - 1) === "/" && anchorIndex > 1) {
                url = url.slice(0, anchorIndex - 1) + url.slice(anchorIndex);
            }
        }
        if (url.length > 1 && url.endsWith("/")) {
            url = url.substr(0, url.length - 1);
        }
        return url;
    }

    public static async shouldAddUrlTrailingSlash(getSite: () => Promise<Site | null | undefined>, cmsOptions?: CMSOptions): Promise<boolean> {
        if (!cmsOptions) {
            cmsOptions = await getCMSOptions(CmsApi);
        }
        if (cmsOptions?.addTrailingSlashToAllUrls) {
            return true;
        }
        const site = await getSite();
        return !!site?.addTrailingSlashToAllUrls;
    }

    public static removeParenthesesFromUrl(url: string | undefined): string {
        return url ? url.replace(/\(|\)/g, "") : "";
    }

    // TODO: This function can be removed once "&" will be removed from all friendly url
    public static replaceAmpersands(url: string) {
        if (url.includes("&")) {
            return url.replace(/&/g, "-");
        }
        return url;
    }

    public static urlsEqualIgnoringTrailingSlash(url1?: string, url2?: string): boolean {
        if (url1 == null || url2 == null) {
            return url1 === url2;
        }
        if ((url1 === "/" || url2 === "/") && (url1 === "" || url2 === "")) {
            return true;
        }
        return UrlParamsUtil.removeTrailingSlashFromUrl(url1) === UrlParamsUtil.removeTrailingSlashFromUrl(url2);
    }

    public static async correctTrailingSlash(url: string, site: Site): Promise<string> {
        const cmsSettings = await getCMSOptions(CmsApi);
        if (!cmsSettings.enableTrailingSlashRedirectBehavior) {
            return url;
        }
        if (await UrlParamsUtil.shouldAddUrlTrailingSlash(async () => site, cmsSettings)) {
            return UrlParamsUtil.addTrailingSlashToUrl(url);
        }
        return UrlParamsUtil.removeTrailingSlashFromUrl(url);
    }

    public static shouldAddSpecialParam({
        specialAvailableDates,
        specialAvailableDurations,
        specialAvailableStayPeriodDefs,
        stayPeriodDefCode,
        startDate,
        endDate,
        dateRangePicker,
    }: {
        specialAvailableDates?: DateMap;
        specialAvailableDurations?: number[];
        specialAvailableStayPeriodDefs?: StayPeriodDef[];
        stayPeriodDefCode?: string | null;
        startDate: moment.Moment | null;
        endDate: moment.Moment | null;
        dateRangePicker?: boolean;
    }) {
        if (dateRangePicker && specialAvailableDates && startDate && endDate) {
            const isSpecialStartDate = lookupDateMap(specialAvailableDates, startDate);
            const selectedDuration = endDate.diff(startDate, "day");
            const isSpecialDuration = specialAvailableDurations?.some((duration) => duration === selectedDuration);
            return isSpecialDuration && isSpecialStartDate;
        } else if (!dateRangePicker && specialAvailableDates && startDate && stayPeriodDefCode) {
            const isSpecialStartDate = lookupDateMap(specialAvailableDates, startDate);
            const isSpecialStayPeriodDef = specialAvailableStayPeriodDefs?.some((stayPeriodDef) => stayPeriodDef.code === stayPeriodDefCode);
            return isSpecialStartDate && isSpecialStayPeriodDef;
        }
        return false;
    }
}
