import { StringUtil } from "../string.util";

export const DEFAULT_CHART_COLOR = "#4bc0c0";

export function generateAllMonthNames(localeCode: string | undefined) {
    return new Array(12).fill(0).map((_, index) => {
        const date = new Date(new Date().getFullYear(), index, 1);
        return StringUtil.capitalizeFirstLetter(date.toLocaleString(localeCode, { month: "short" }));
    });
}
