import { ApiCallOptions, ContractsRequest, MxtsApiWrapper } from "@maxxton/cms-mxts-api";
import { Site, WithId } from "@maxxton/cms-api";

import { CMSProviderProperties } from "../../../../../../containers/cmsProvider.types";
import { MXTS as MXTS_CONSTANTS } from "../../../../../../utils/constants";
import { getCustomerIdsFromLoginToken } from "../../../../../../redux/reducers/myEnv/myEnv.util";
import { getMxtsEnv } from "../../../../../mxts";
import { getSharedOwnershipWithSharedOwnerId } from "../../../../../../utils/sharedOwnership.util";
import { uniq } from "lodash";

/**
 * Returns a list of all units that are owned by the owners at some point. (This will not filter out units from old/future contracts)
 * @param params
 */

export interface OwnershipData {
    content: Array<{
        startDate: string;
    }>;
}

export async function getAllOwnedUnitIdsFromToken(params: { env: ApiCallOptions; mxtsApi: MxtsApiWrapper; site?: Site & WithId; showInactiveContractUnits?: boolean }): Promise<number[]> {
    const { env, mxtsApi, site, showInactiveContractUnits } = params;
    const ownerIds = await getCustomerIdsFromLoginToken();
    if (!ownerIds.length) {
        return [];
    }
    if (site?.showSharedOwnership) {
        const sharedOwnerships = await getSharedOwnershipWithSharedOwnerId(env, ownerIds);
        if (sharedOwnerships?.length) {
            const ownerIdsForSharedOwnership = sharedOwnerships.map((sharedOwnership) => sharedOwnership.ownerId);
            ownerIdsForSharedOwnership.forEach((sharedOwnershipOwnerId) => {
                if (!ownerIds.includes(sharedOwnershipOwnerId)) {
                    ownerIds.push(sharedOwnershipOwnerId);
                }
            });
        }
    }
    const contractsRequest: ContractsRequest = { size: MXTS_CONSTANTS.MAX_RESULTS, ownerIds };
    if (!showInactiveContractUnits) {
        contractsRequest.isActiveContract = true;
    }
    const contracts = await mxtsApi.getContracts(env, contractsRequest);
    return uniq(contracts.content.map((contract) => contract.unitId));
}

export async function getOwnershipStartDate(params: { env: ApiCallOptions; mxtsApi: MxtsApiWrapper }): Promise<Date | undefined> {
    const { env, mxtsApi } = params;
    const ownerIds = await getCustomerIdsFromLoginToken();
    if (ownerIds.length) {
        const ownershipData = await mxtsApi.getOwnerships(env, { ownerIds });
        if (!ownershipData.content || ownershipData.content.length === 0) {
            return;
        }
        const startDates = ownershipData.content.map((ownership) => ownership.startDate).filter((date) => date);

        if (startDates.length === 0) {
            return;
        }
        const earliestStartDate = new Date(Math.min(...startDates.map((date) => new Date(date).getTime())));
        return earliestStartDate;
    }
}

export async function calculateYearCount(context: CMSProviderProperties, setYearCount: React.Dispatch<React.SetStateAction<number | undefined>>): Promise<void> {
    const env = await getMxtsEnv(context, context.currentLocale?.code);
    const ownerStartDate = await getOwnershipStartDate({ env, mxtsApi: context.mxtsApi });
    if (ownerStartDate) {
        const ownershipStartYear = ownerStartDate.getFullYear();
        const currentYear = new Date().getFullYear();
        const yearCount = currentYear - ownershipStartYear + 1;
        setYearCount(yearCount);
    }
}
