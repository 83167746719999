export enum ReservationDisplayType {
    UPCOMING = "UPCOMING",
    CHECKED_IN = "CHECKED_IN",
    CHECKED_IN_BASED_ON_ARRIVAL = "CHECKED_IN_BASED_ON_ARRIVAL",
    PAST = "PAST",
    PAST_BASED_ON_DEPARTURE = "PAST_BASED_ON_DEPARTURE",
    PAYMENT_DUE = "PAYMENT_DUE",
    PRE_BOOKING = "PRE_BOOKING",
    ALL = "ALL",
    SELECTED_RESERVATION = "SELECTED_RESERVATION",
}
