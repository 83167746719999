import { FormEditAction, FormEditActionType } from "../actions/FormAlertAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface EditFormState {
    editedItem?: any;
    editCancelled?: boolean;
}

// eslint-disable-next-line max-len
export const formEditReducer: Reducer<EditFormState> = (state: EditFormState = {}, action: FormEditAction): EditFormState => {
    switch (action.type) {
        case ActionType.FormEdited:
            if (action.actionType === FormEditActionType.edited) {
                return { editedItem: action.actionType === FormEditActionType.edited ? action.payload : undefined, editCancelled: false };
            } else if (action.actionType === FormEditActionType.saved) {
                return { editedItem: undefined, editCancelled: false };
            } else if (action.actionType === FormEditActionType.cancelled) {
                return { editedItem: undefined, editCancelled: true };
            }

        default:
            return state;
    }
};
