import { EditedWidget, WidgetOptions } from "../../form-specs";
import { FrontendPageEditAction, FrontendPageEditActionType } from "../../redux/actions/frontendPageEditAction";

import { ActionType } from "../../redux/actions";
import { Dispatch } from "redux";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";

export const saveWidgetOptionsInLocalStorage = (newOptions: WidgetOptions<any>, widgetId: string): WidgetOptions<any> => {
    const editedWidgets: Array<EditedWidget<any>> = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS) || "[]");
    const index = editedWidgets.findIndex((widget: any) => widget.widgetId === widgetId);
    if (index !== -1) {
        editedWidgets[index] = { widgetId, options: newOptions };
    } else {
        editedWidgets.push({ widgetId, options: newOptions });
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS, JSON.stringify(editedWidgets));
    return newOptions;
};

export const loadWidgetOptionsFromLocalStorage = (widgetId: string, fallbackWidgetOptions?: WidgetOptions<any>): WidgetOptions<any> => {
    const editedWidgets: Array<EditedWidget<any>> = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS) || "[]");
    const widget = editedWidgets.find((widget) => widget.widgetId === widgetId);
    return widget ? widget.options : fallbackWidgetOptions;
};

export const updateFrontendEditingButtonStatus = (dispatch: Dispatch<FrontendPageEditAction>) => {
    const hasFrontendEditedWidgets = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS) || "[]").length;
    const action = {
        type: ActionType.FrontendPageEditPage,
        actionType: FrontendPageEditActionType.SET_FRONTEND_EDITED_WIDGETS_STATUS,
        payload: {
            areFrontendWidgetsEdited: !!hasFrontendEditedWidgets,
        },
    };
    dispatch(action);
};

export const setResetStatusOnOptionsUpdate = (dispatch: Dispatch<FrontendPageEditAction>) => {
    const hasFrontendEditedWidgets = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS) || "[]").length;
    if (!hasFrontendEditedWidgets) {
        const action = {
            type: ActionType.FrontendPageEditPage,
            actionType: FrontendPageEditActionType.CLEAR_FRONTEND_EDITED_WIDGETS,
            payload: {
                isFrontendEditedWidgetsReset: false,
            },
        };
        dispatch(action);
    }
};
