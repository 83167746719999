import * as moment from "moment";

import { CustomFieldResult, Customer, CustomerAddress, CustomerFullAddress, CustomerRequest, Owner, OwnerFiscalType, OwnerType } from "@maxxton/cms-mxts-api";

import { CUSTOM_FIELDS_DATA_TYPE } from "../plugins/dynamic/description/Description";
import { CreateReservationAction } from "../redux/actions/reservationAction";
import { CurrentLocale } from "../app.types";
import { DATE_FORMAT } from "./constants";
import { Site } from "@maxxton/cms-api";
import { Validations } from "../redux/reducers/guestFormReducer";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface CustomerFormFields {
    customerId?: number;
    firstname?: string;
    lastname?: string;
    alternateName?: string;
    birthdate?: string;
    city?: string;
    address?: string;
    housenumber?: string;
    housenumbersuffix?: string;
    language?: number;
    mobilephone?: string;
    email?: string;
    country?: number;
    zipCode?: string;
    title?: number;
    middlename?: string;
    privatePhone?: string;
    workphone?: string;
    sex?: string;
    attentionOf?: string;
    state?: string;
    nationality?: string;
    secondaryMobilePhone?: string;
    buildingName?: string;
    address2?: string;
    address3?: string;
    poBox?: string;
    companyName?: string;
    bankAccountNumber?: string;
    subject?: string;
    identityNumber?: unknown;
    identityType?: unknown;
    photo?: unknown;
    sourcecode?: string;
    street?: string;
    suffix?: string;
    voucher?: string;
    mailAllowed?: boolean;
    emailAllowed?: boolean;
    questionnaireUnsubscribed?: boolean;
    vatNumber?: string;
    taxNumber?: string;
    owner?: boolean;
    ownerType?: string;
    companyIdentificationNumber?: string;
    socialSecurityNumber?: string;
    birthPlace?: string;
    ssnIssuingCountryId?: number;
    tnIssuingCountryId?: number;
    birthCountryId?: number;
    fiscalVat?: string;
}

export function parseCustomer(customerData: Partial<Customer>, ownerFiscalType?: OwnerFiscalType, owner?: Owner): CustomerFormFields {
    return {
        customerId: customerData.customerId || undefined,
        firstname: customerData.firstName || undefined,
        lastname: customerData.lastName || undefined,
        alternateName: customerData.alternateName || undefined,
        birthdate: customerData.birthDate || undefined,
        city: customerData.addresses?.[0].city || undefined,
        address: customerData.addresses?.[0].address1 || undefined,
        housenumber: customerData.addresses?.[0].houseNumber || undefined,
        housenumbersuffix: customerData.addresses?.[0].houseNumberSuffix || undefined,
        language: customerData.languageId || undefined,
        mobilephone: customerData.addresses?.[0].mobilePhone || undefined,
        email: customerData.addresses?.[0].email || undefined,
        country: customerData.addresses?.[0].countryId || undefined,
        zipCode: customerData.addresses?.[0].zipCode || undefined,
        title: customerData.titleId || undefined,
        middlename: customerData.middle || undefined,
        privatePhone: customerData.addresses?.[0].privatePhone || undefined,
        workphone: customerData.addresses?.[0].workPhone || undefined,
        sex: customerData.sex || undefined,
        attentionOf: customerData.attentionOf || undefined,
        state: customerData.addresses?.[0].stateId || undefined,
        nationality: customerData.addresses?.[0].nationality || undefined,
        secondaryMobilePhone: customerData.addresses?.[0].mobilePhone2 || undefined,
        buildingName: customerData.addresses?.[0].buildingName || undefined,
        address2: customerData.addresses?.[0].address2 || undefined,
        address3: customerData.addresses?.[0].address3 || undefined,
        poBox: customerData.addresses?.[0].poBox || undefined,
        companyName: customerData.companyName || undefined,
        bankAccountNumber: customerData.ibanNumber || undefined,
        mailAllowed: customerData.mailAllowed || undefined,
        emailAllowed: customerData.emailAllowed || undefined,
        questionnaireUnsubscribed: customerData.questionnaireUnsubscribed || undefined,
        vatNumber: customerData.vatNumber || undefined,
        taxNumber: customerData.taxNumber || undefined,
        companyIdentificationNumber: customerData.companyIdentificationNumber || undefined,
        socialSecurityNumber: customerData.socialSecurityNumber || undefined,
        birthPlace: customerData.birthPlace || undefined,
        ssnIssuingCountryId: customerData.ssnIssuingCountryId || undefined,
        tnIssuingCountryId: customerData.tnIssuingCountryId || undefined,
        owner: customerData.owner,
        ownerType: customerData.ownerType || OwnerType.UNKNOWN,
        birthCountryId: owner?.birthCountryId || undefined,
        fiscalVat: ownerFiscalType?.fiscalVat || undefined,
        // TODO: no idea about the mapping of the below fields, should be properly mapped when needed
        subject: undefined,
        identityNumber: undefined,
        identityType: undefined,
        photo: undefined,
        sourcecode: undefined,
        street: undefined,
        suffix: undefined,
        voucher: undefined,
    };
}

export function parseFormCustomerData(customerData: CustomerFormFields) {
    return {
        firstName: customerData.firstname,
        lastName: customerData.lastname,
        alternateName: customerData.alternateName,
        birthDate: customerData.birthdate,
        titleId: customerData.title,
        middle: customerData.middlename,
        ibanNumber: customerData.bankAccountNumber,
        sex: customerData.sex,
        nationality: customerData.nationality,
        secondaryMobilePhone: customerData.secondaryMobilePhone,
        taxNumber: customerData.taxNumber,
        vatNumber: customerData.vatNumber,
        companyIdentificationNumber: customerData.companyIdentificationNumber,
        socialSecurityNumber: customerData.socialSecurityNumber,
        birthPlace: customerData.birthPlace,
        ssnIssuingCountryId: customerData.ssnIssuingCountryId,
        tnIssuingCountryId: customerData.tnIssuingCountryId,
        companyName: customerData.companyName,
        ownerType: customerData.ownerType,
        birthCountryId: customerData.birthCountryId,
    };
}

export const getGenderOptions = (currentLocale: CurrentLocale, site: Site) => [
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "unspecified", currentLocale, site), value: "u" },
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "male", currentLocale, site), value: "m" },
    { label: getI18nLocaleObject(namespaceList.widgetTravelParty, "female", currentLocale, site), value: "f" },
];

export function parseFormAddressData(customerData: CustomerFormFields): CustomerFullAddress {
    return {
        address1: customerData.address,
        city: customerData.city,
        countryId: customerData.country,
        houseNumber: customerData.housenumber,
        houseNumberSuffix: customerData.housenumbersuffix,
        privatePhone: customerData.privatePhone,
        zipCode: customerData.zipCode,
        mobilePhone: customerData.mobilephone,
        email: customerData.email,
    };
}

export function parseAddressContent(action: CreateReservationAction, isPayingCustomer?: boolean): CustomerAddress {
    const {
        country,
        address,
        housenumber,
        housenumbersuffix,
        zipCode,
        city,
        email,
        privatePhone,
        mobilephone,
        workphone,
        Co_address,
        Co_city,
        Co_country,
        Co_email,
        Co_housenumber,
        Co_housenumbersuffix,
        Co_privatePhone,
        Co_zipCode,
        Co_mobilephone,
        Co_workphone,
    } = action.payload;
    const dateBegin = moment().utc().format(DATE_FORMAT.MXTS);
    return {
        accuracy: "APPROXIMATE",
        dateBegin,
        exposeLocation: true,
        address1: isPayingCustomer ? Co_address : address,
        city: isPayingCustomer ? Co_city : city,
        countryId: isPayingCustomer ? Co_country : country,
        email: isPayingCustomer ? Co_email : email,
        houseNumber: isPayingCustomer ? Co_housenumber : housenumber,
        houseNumberSuffix: isPayingCustomer ? Co_housenumbersuffix : housenumbersuffix,
        privatePhone: isPayingCustomer ? Co_privatePhone : privatePhone,
        zipCode: isPayingCustomer ? Co_zipCode : zipCode,
        mobilePhone: isPayingCustomer ? Co_mobilephone : mobilephone,
        workPhone: isPayingCustomer ? Co_workphone : workphone,
    };
}

export function parseCustomerRequestBody(addressContent: CustomerAddress, action: CreateReservationAction, isPayingCustomer?: boolean): CustomerRequest {
    const {
        sex,
        email,
        companyName,
        title,
        firstname,
        middlename,
        lastname,
        alternateName,
        birthdate,
        language,
        emailAllowed,
        password,
        login,
        Co_companyName,
        Co_email,
        Co_firstname,
        Co_middlename,
        Co_lastname,
    } = action.payload;
    return {
        addresses: [addressContent],
        birthDate: !isPayingCustomer && birthdate ? moment(birthdate, "YYYY-MM-DD").format("YYYY-MM-DD") : undefined,
        email: isPayingCustomer ? Co_email : email,
        emailAllowed,
        titleId: !isPayingCustomer ? title : undefined,
        firstName: isPayingCustomer ? Co_firstname : firstname,
        middle: isPayingCustomer ? Co_middlename : middlename || null,
        lastName: isPayingCustomer ? Co_lastname : lastname,
        languageId: !isPayingCustomer ? language : undefined,
        sex: !isPayingCustomer ? sex : undefined,
        password: !isPayingCustomer ? password : undefined,
        login: !isPayingCustomer ? login : undefined,
        companyName: isPayingCustomer ? Co_companyName : companyName,
        company: isPayingCustomer ? 1 : 0,
        alternateName,
    };
}

export enum CustomerFieldLabel {
    customerId = "Customer Id",
    firstname = "First Name",
    lastname = "Last Name",
    birthdate = "Birthdate",
    city = "City",
    address = "Address",
    housenumber = "House Number",
    housenumbersuffix = "House Number Suffix",
    language = "Language",
    mobilephone = "Mobile Phone",
    email = "Email",
    country = "Country",
    zipCode = "Zip Code",
    title = "Title",
    middlename = "Middle Name",
    privatePhone = "Private Phone",
    workphone = "Work Phone",
    sex = "Sex",
    attentionOf = "Attention Of",
    state = "State",
    nationality = "Nationality",
    secondaryMobilePhone = "Secondary Mobile Phone",
    buildingName = "Building Name",
    address2 = "Address 2",
    address3 = "Address 3",
    poBox = "PO Box",
    companyName = "Company Name",
    bankAccountNumber = "IBAN",
    alternateName = "Alternate Name",
}

export function validateCustomerData(customerData: CustomerFormFields, validation: Validations): Validations {
    if (Object.keys(validation).length === 0) {
        return Object.keys(customerData).reduce((acc: Validations, key: string) => {
            acc[key] = { isValid: true, message: "" };
            return acc;
        }, {});
    }

    return Object.entries(customerData).reduce(
        (acc: Validations, [key, value]: [string, string | undefined]) => {
            if (key in validation && value) {
                acc[key] = { isValid: true, message: "" };
            }
            return acc;
        },
        { ...validation }
    );
}

export const parseCustomFields = (fields: CustomFieldResult[]) =>
    fields.map((field: CustomFieldResult) => {
        let value;

        switch (field.dataType) {
            case CUSTOM_FIELDS_DATA_TYPE.BOOLEAN:
                value = field.booleanValue;
                break;
            case CUSTOM_FIELDS_DATA_TYPE.DATE:
                value = field.dateValue;
                break;
            case CUSTOM_FIELDS_DATA_TYPE.NUMERIC:
                value = field.numericValue;
                break;
            case CUSTOM_FIELDS_DATA_TYPE.MEMO:
            case CUSTOM_FIELDS_DATA_TYPE.TEXT:
                value = field.textValue;
                break;
            case CUSTOM_FIELDS_DATA_TYPE.SELECTION_INCL:
            case CUSTOM_FIELDS_DATA_TYPE.SELECTION_EXCL:
                value = field.selectValues?.length ? (field.selectValues as Array<string | number>).map((selectValue) => selectValue) : null;
                break;
            default:
                value = null;
        }

        return { [field.fieldId]: value };
    });
