import * as moment from "moment";

import { AccoKind, AdditionGroup, BillLine, ChoiceResourceType, MarketingGroups, PriceEngineState, ReservationTotalTurnover, Resort, Resource, Subject, getAllIds } from "@maxxton/cms-mxts-api";
import { DataLayer, DataLayerApi, WithId } from "@maxxton/cms-api";
import { DataLayerFields, enhancedEcommType } from "./datalayer.constants";
import { formatReservationNumber, getMarketingGroups } from "../components/utils";
import { getAdminMxtsEnv, getMxtsEnv } from "../plugins/mxts";
import { isEmpty, omit, set, unset } from "lodash";

import { ApiContext } from "../containers/cmsProvider.types";
import { DATE_FORMAT } from "./constants";
import { DomainObjectUtil } from "./domainobject.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { PageProps } from "../components/Page";
import { getTotalBillLines } from "./bill.util";
import { isClientSide } from "./generic.util";

export interface DynamicFilterPickObjectsInterface {
    accokindids?: string;
    amenities?: string;
    bathroom?: string;
    bedroom?: string;
    bookdate?: string;
    distributionchannel?: string;
    duration?: string;
    enddate?: string;
    freesearchid?: string;
    maxbathroom?: string;
    maxbedroom?: string;
    maximumarrivaldate?: string;
    minbathroom?: string;
    minbedroom?: string;
    minimumarrivaldate?: string;
    ratetypeid?: string;
    regionids?: string;
    reservationid?: string;
    resort?: string;
    resortids?: string;
    resourceid?: string;
    specialcode?: string;
    startdate?: string;
    status?: string;
    stay?: string;
    stayperioddefid?: string;
    subject?: string;
    unitid?: string;
}

export const dynamicFilterPickObjects: DynamicFilterPickObjectsInterface = {
    accokindids: "accokindids",
    amenities: "amenities",
    bathroom: "bathroom",
    bedroom: "bedroom",
    bookdate: "bookdate",
    distributionchannel: "distributionChannelId",
    duration: "stay",
    enddate: "enddate",
    freesearchid: "freeSearchId",
    maxbathroom: "maxbathroom",
    maxbedroom: "maxbedroom",
    maximumarrivaldate: "maximumArrivalDate",
    minbathroom: "minbathroom",
    minbedroom: "minbedroom",
    minimumarrivaldate: "minimumArrivalDate",
    ratetypeid: "ratetypeid",
    regionids: "regionIds",
    reservationid: "reservationId",
    resort: "resort",
    resortids: "resortids",
    resourceid: "resourceid",
    specialcode: "specialcode",
    startdate: "startdate",
    status: "paymentStatus",
    stay: "stay",
    stayperioddefid: "stayperioddefid",
    subject: "subject",
    unitid: "unitid",
};

// eslint-disable-next-line max-lines-per-function
export async function generateDataLayer(apiContext: ApiContext, dataLayerSettings: DataLayer & WithId, props: PageProps, eventName: string): Promise<void> {
    if (!props.context.isAdmin && !props.site.useGTM) {
        return;
    }
    const { mxtsApi } = apiContext;

    const ops = await getAdminMxtsEnv();
    const env = await getMxtsEnv(props.context, props.context.currentLocale?.code);

    const currencyCode = props.dynamicFilter.currency?.code;
    let resortData: Resort | undefined;
    let turnoverData: ReservationTotalTurnover | undefined;
    if (props.accoBillLines?.[0]?.resourceId) {
        const accommodationDetail: Resource = (
            await mxtsApi.resources(ops, {
                size: 1,
                resourceIds: [props.accoBillLines[0].resourceId],
            })
        )?.content?.[0];
        resortData = (await mxtsApi.resorts(ops, { resortIds: [accommodationDetail?.resortId] }))?.content?.[0];
    }

    if (props.dynamicFilter.reservationId) {
        turnoverData = await mxtsApi
            .getReservationTotalTurnover(ops, {}, [
                {
                    key: "reservationId",
                    value: props.dynamicFilter.reservationId,
                },
            ])
            .catch(() => undefined);
    }

    if (isClientSide()) {
        const dynamicObject: DataLayerFields = {};
        const dataLayerOptions = dataLayerSettings?.dataLayerContent;
        if (dataLayerOptions) {
            const keysToAvoid = ["bookdate", "duration", "enddate", "resort", "startdate", "subject"];
            const dynamicFilterKeyList = omit(dynamicFilterPickObjects, keysToAvoid);
            Object.keys(dynamicFilterKeyList).forEach((dynamicFilterKey: keyof typeof dynamicFilterPickObjects) => {
                if (dataLayerOptions.some((setting: any) => setting.value === dynamicFilterKey)) {
                    const dynamicFilterObjectKey = dynamicFilterPickObjects[dynamicFilterKey] as keyof DynamicFilter;
                    set(dynamicObject, (dataLayerSettings && dataLayerSettings[dynamicFilterKey]) || dynamicFilterKey, props.dynamicFilter[dynamicFilterObjectKey]);
                }
            });
            if (dataLayerOptions.some((setting: any) => setting.value === "startdate")) {
                set(dynamicObject, dataLayerSettings?.startdate || "startdate", moment(props.dynamicFilter.startdate, "DDMMYYYY").format(DATE_FORMAT.DISPLAY));
            }
            if (dataLayerOptions.some((setting: any) => setting.value === "enddate")) {
                set(dynamicObject, dataLayerSettings?.enddate || "enddate", moment(props.dynamicFilter.enddate, "DDMMYYYY").format(DATE_FORMAT.DISPLAY));
            }
            if (dataLayerOptions.some((setting: any) => setting.value === "resort")) {
                set(dynamicObject, dataLayerSettings?.resort || "resort", resortData?.name);
            }
            if (dataLayerOptions.some((setting: any) => setting.value === "language")) {
                set(dynamicObject, dataLayerSettings?.language || "language", props.dynamicFilter.currentLocale);
            }
            if (dataLayerOptions.some((setting: any) => setting.value === "flowid")) {
                set(dynamicObject, dataLayerSettings?.flowid || "flowid", props.site?.flowId);
            }
            if (dataLayerOptions.some((setting: any) => setting.value === "bookdate")) {
                set(dynamicObject, dataLayerSettings?.bookdate || "bookdate", moment().format(DATE_FORMAT.DISPLAY));
            }
            if (dataLayerOptions.some((setting: any) => setting.value === "transactionaffiliation")) {
                set(dynamicObject, dataLayerSettings?.transactionaffiliation || "transactionaffiliation", resortData ? `${resortData.name} (${resortData.resortId})` : "");
            }
            if (dataLayerOptions.some((setting: any) => setting.value === "subject") && props.dynamicFilter.subject) {
                const subject: string[] = [];
                if (props.dynamicFilter.subject) {
                    const subjectsResult: Subject[] = await DomainObjectUtil.getSubjectsByIds(apiContext.mxtsApi, [...(props.dynamicFilter.subject?.keys() || [])], env);
                    props.dynamicFilter.subject.forEach((value: number, key: number) => {
                        const foundSubject = subjectsResult.find((subjectResult) => subjectResult.subjectId === key);
                        if (foundSubject) {
                            subject.push(`${foundSubject?.name}, ${value}`);
                        }
                    });
                }
                set(dynamicObject, dataLayerSettings?.subject || "subject", subject);
            }
            const paymentTermSetId = props.reservationState?.reservation?.paymentTermSetId;
            if (dataLayerOptions.some((setting: any) => setting.value?.toLowerCase() === "paymenttermsetid") && paymentTermSetId) {
                set(dynamicObject, dataLayerSettings?.paymenttermsetid || "paymenttermsetid", paymentTermSetId);
            }
        }

        if (dataLayerSettings && dataLayerSettings.transactionProducts) {
            if (Object.keys(props.bill).length) {
                const transactionProducts: any = [];
                if (props.accoBillLines) {
                    let transformedAccoLines = props.accoBillLines.filter((billLine: BillLine) => billLine.state !== PriceEngineState.DELETED);
                    const accoSpecificLines = transformedAccoLines.filter(
                        (billLine: BillLine) => billLine.billLineType === "RESERVED_RESOURCE" && billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE
                    );
                    if (accoSpecificLines.length > 1) {
                        let mergedAccoBillLine = { ...accoSpecificLines[0] };
                        mergedAccoBillLine.total = 0;
                        mergedAccoBillLine.totalExclusiveVat = 0;
                        mergedAccoBillLine = accoSpecificLines.reduce((accumulator: BillLine, currentValue: BillLine) => {
                            accumulator.total += currentValue.total;
                            accumulator.totalExclusiveVat! += currentValue.totalExclusiveVat || 0;
                            return accumulator;
                        }, mergedAccoBillLine);
                        transformedAccoLines = transformedAccoLines.filter(
                            (billLine: BillLine) => !(billLine.billLineType === "RESERVED_RESOURCE" && billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE)
                        );
                        transformedAccoLines.unshift(mergedAccoBillLine);
                    }
                    const basicAccoBillLine = transformedAccoLines.find((billLine) => billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE);
                    const totalInclusiveVat = basicAccoBillLine?.total;
                    const totalExclusiveVat = basicAccoBillLine?.totalExclusiveVat;
                    set(dynamicObject, "transactionTotal", totalInclusiveVat);
                    set(dynamicObject, "basic-rent-incl-btw", totalInclusiveVat);
                    set(dynamicObject, "basic-rent-excl-btw", totalExclusiveVat);
                    transformedAccoLines.forEach((billLine: BillLine) => {
                        transactionProducts.push({
                            category: billLine.resourceType,
                            id: billLine.resourceId,
                            name: billLine.resourceName,
                            price: billLine.total,
                            quantity: billLine.multiplier,
                            sku: billLine.reservedResource?.code,
                        });
                    });
                }

                if (props.additionBillLines) {
                    props.additionBillLines.forEach((billLine: BillLine) => {
                        transactionProducts.push({
                            category: billLine.resourceType,
                            id: billLine.resourceId,
                            name: billLine.resourceName,
                            price: billLine.total,
                            quantity: billLine.multiplier,
                            sku: billLine.code,
                        });
                    });
                }
                set(dynamicObject, "transactionProducts", transactionProducts);

                const billTotalBillLines = getTotalBillLines(props.bill.mainBill?.customerBill || props.bill.mainBill?.agentBill || []);
                billTotalBillLines.forEach((billLine: BillLine) => {
                    if (billLine && dataLayerOptions) {
                        if (billLine.billLineType === "WARRANT" && dataLayerOptions.some((setting: any) => setting.value === "deposit")) {
                            set(dynamicObject, dataLayerSettings?.deposit || "deposit", billLine.total);
                        }
                        if (billLine.billLineType === "SUBTOTAL" && dataLayerOptions.some((setting: any) => setting.value === "subtotal")) {
                            set(dynamicObject, dataLayerSettings?.subtotal || "subtotal", billLine.total);
                        }
                        if (billLine.billLineType === "TOTAL" && dataLayerOptions.some((setting: any) => setting.value === "total")) {
                            set(dynamicObject, dataLayerSettings?.total || "total", billLine.total);
                        }
                    }
                });
            }
        }
        if (turnoverData) {
            set(dynamicObject, "turnover", turnoverData.turnoverAmount);
        }
        set(dynamicObject, "currency", currencyCode);
        set(dynamicObject, "event", eventName);
        set(dynamicObject, "url", props.location.href);
        set(dynamicObject, "view", props.location.pathname);
        if (props.dynamicFilter.reservationId) {
            apiContext.logger.info(`DataLayer Data with Turnover: ${JSON.stringify(dynamicObject)}`);
        }
        (window as any).dataLayer.push(JSON.parse(JSON.stringify(dynamicObject)));
    }
}

// eslint-disable-next-line max-lines-per-function
export async function generateEnhancedEcomm(apiContext: ApiContext, dataLayerSettings: (DataLayer & WithId) | null, props: PageProps, type: string, stepType?: string): Promise<void> {
    if (!props.context.isAdmin && !props.site.useGTM) {
        return;
    }

    if (!dataLayerSettings?.enableEec) {
        return;
    }
    const isGA4Enabled = dataLayerSettings?.enableGA4DataLayer;

    const env = await getAdminMxtsEnv();

    // ToDo Dynamic Language based on selecttion in DataLayer Settings, currently static due to EuroParcs requirement.
    set(env, "locale", "nl_NL");

    const availableAccoKinds: AccoKind[] = (
        await apiContext.mxtsApi.accommodationkinds(env, {
            sort: "priority",
            size: 999,
        })
    )?.content;

    let resortData: Resort | undefined;
    if (type === enhancedEcommType.purchase && props.accoBillLines?.[0]?.resourceId) {
        const accommodationDetail: Resource = (
            await apiContext.mxtsApi.resources(env, {
                size: 1,
                resourceIds: [props.accoBillLines[0].resourceId],
            })
        )?.content?.[0];
        resortData = (await apiContext.mxtsApi.resorts(env, { resortIds: [accommodationDetail?.resortId] }))?.content[0];
    }

    if (isClientSide()) {
        const eecObject = isGA4Enabled
            ? {
                  event: type,
              }
            : {
                  event: "eecEvent",
                  eecAction: type,
              };

        const transactionIdKey = isGA4Enabled ? "transaction_id" : "id";
        const purchaseActionField = {
            [transactionIdKey]: formatReservationNumber(props.reservationState.reservation?.reservationNumber.toString()),
            affiliation: resortData?.name || "",
        };
        if (props.dynamicFilter.flowType) {
            set(eecObject, "flowType", props.dynamicFilter.flowType);
        }
        if (props.dynamicFilter.specialcode) {
            set(purchaseActionField, "coupon", props.dynamicFilter.specialcode.join(", "));
        }

        const getCategory = (resourceId: number, groupIdsForProductAdditions: MarketingGroups[], groupsDetailedList: AdditionGroup[]) => {
            const groupForAddition = groupIdsForProductAdditions.find((group) => group.resourceId === resourceId);
            if (isEmpty(groupForAddition) || !groupForAddition) {
                return "Overige";
            }
            const resourceGroupData = groupsDetailedList.find((info) => info.groupId === groupForAddition.resourcegroupId);
            return resourceGroupData?.name;
        };

        const transactionProducts: any = [];
        const dataLayerProductKeys = {
            name: isGA4Enabled ? "item_name" : "name",
            id: isGA4Enabled ? "item_id" : "id",
            brand: isGA4Enabled ? "item_brand" : "brand",
            category: isGA4Enabled ? "item_category" : "category",
            variant: isGA4Enabled ? "item_variant" : "variant",
            price: "price",
            quantity: "quantity",
            revenue: isGA4Enabled ? "value" : "revenue",
        };
        if (Object.keys(props.bill).length) {
            if (props.accoBillLines) {
                let transformedAccoLines = props.accoBillLines.filter((billLine: BillLine) => billLine.state !== PriceEngineState.DELETED);
                const accoSpecificLines = transformedAccoLines.filter(
                    (billLine: BillLine) => billLine.billLineType === "RESERVED_RESOURCE" && billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE
                );
                if (accoSpecificLines.length > 1) {
                    let mergedAccoBillLine = { ...accoSpecificLines[0] };
                    mergedAccoBillLine.total = 0;
                    mergedAccoBillLine = accoSpecificLines.reduce((accumulator: BillLine, currentValue: BillLine) => {
                        accumulator.total += currentValue.total;
                        return accumulator;
                    }, mergedAccoBillLine);
                    transformedAccoLines = transformedAccoLines.filter(
                        (billLine: BillLine) => !(billLine.billLineType === "RESERVED_RESOURCE" && billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE)
                    );
                    transformedAccoLines.unshift(mergedAccoBillLine);
                }
                const brand = transformedAccoLines[0]?.resourceName?.replace(/\d+$/, "").trim();
                let variant = 0;
                props.dynamicFilter.subject?.forEach((a: number) => {
                    variant = Number(variant) + Number(a);
                });

                const resourceIdsOfProducts = transformedAccoLines
                    .filter((billLine: BillLine) => billLine.resourceType === ChoiceResourceType.PRODUCTTYPE)
                    ?.map((billLine: BillLine) => billLine.resourceId);
                const groupIdsForProductAdditions = await getAllIds(
                    resourceIdsOfProducts,
                    async (ids: number[], pageSize: number) => apiContext.mxtsApi.marketingGroups(env, { size: pageSize, resourceIds: ids }, undefined),
                    50
                );
                const groupsDetailedList = (await getMarketingGroups(
                    apiContext,
                    groupIdsForProductAdditions.map((group: MarketingGroups): number => group.resourcegroupId),
                    env
                )) as AdditionGroup[];

                const accommodationBillLine: BillLine | undefined = transformedAccoLines.find((billLine: BillLine) => billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE);
                let accoKind: AccoKind | undefined;
                if (accommodationBillLine) {
                    const accokindData = await apiContext.mxtsApi.resources(env, {
                        size: 1,
                        resourceIds: [accommodationBillLine.resourceId],
                    });
                    accoKind = availableAccoKinds.find((singleAccokind: AccoKind): boolean => singleAccokind.accommodationkindId === (accokindData?.content[0]?.accotypeKindId || undefined));
                }

                transformedAccoLines.forEach(async (billLine: BillLine) => {
                    if (billLine.billLineType === "VAT") {
                        set(purchaseActionField, "tax", billLine.total);
                    }
                    if (billLine.multiplier !== null) {
                        const transactionObject = {
                            [dataLayerProductKeys.name]: billLine.resourceName,
                            [dataLayerProductKeys.id]: billLine.resourceId,
                            [dataLayerProductKeys.price]: billLine.total,
                            [dataLayerProductKeys.brand]: brand,
                            [dataLayerProductKeys.category]: billLine.resourceDescription,
                            [dataLayerProductKeys.variant]: variant,
                            [dataLayerProductKeys.quantity]: billLine.multiplier,
                        };
                        if (billLine.resourceType === ChoiceResourceType.ACCOMMODATIONTYPE) {
                            set(transactionObject, dataLayerProductKeys.category, accoKind?.name || "");
                        }
                        if (billLine.resourceType === ChoiceResourceType.PRODUCTTYPE) {
                            set(transactionObject, dataLayerProductKeys.category, `Add-On - ${getCategory(billLine.resourceId, groupIdsForProductAdditions, groupsDetailedList)}`);
                            unset(transactionObject, dataLayerProductKeys.brand);
                            unset(transactionObject, dataLayerProductKeys.variant);
                        }
                        if (billLine.resourceType === ChoiceResourceType.EXTRA) {
                            set(transactionObject, dataLayerProductKeys.category, "Extra");
                        }
                        transactionProducts.push(transactionObject);
                    }
                });
            }

            if (props.additionBillLines) {
                const resourceIdsOfAdditions = props.additionBillLines.map((billLine: BillLine) => billLine.resourceId)?.filter((item: number) => item);
                const groupIdsForAdditions = await getAllIds(
                    resourceIdsOfAdditions,
                    async (ids: number[], pageSize: number) => apiContext.mxtsApi.marketingGroups(env, { size: pageSize, resourceIds: ids }, undefined),
                    50
                );
                const groupsDetailedList = (await getMarketingGroups(
                    apiContext,
                    groupIdsForAdditions.map((group: MarketingGroups): number => group.resourcegroupId),
                    env
                )) as AdditionGroup[];

                props.additionBillLines.forEach(async (billLine: BillLine) => {
                    const additionObject = {
                        [dataLayerProductKeys.name]: billLine.resourceName,
                        [dataLayerProductKeys.id]: billLine.resourceId,
                        [dataLayerProductKeys.price]: billLine.total,
                        [dataLayerProductKeys.category]: `Add-On - ${getCategory(billLine.resourceId, groupIdsForAdditions, groupsDetailedList)}`,
                        [dataLayerProductKeys.quantity]: billLine.multiplier,
                    };

                    transactionProducts.push(additionObject);
                });
            }

            const billTotalBillLines = getTotalBillLines(props.bill.mainBill?.customerBill || props.bill.mainBill?.agentBill || []);
            billTotalBillLines.forEach((billLine: BillLine) => {
                if (billLine) {
                    if (billLine.billLineType === "SUBTOTAL") {
                        set(purchaseActionField, dataLayerProductKeys.revenue, billLine.total);
                        if (isGA4Enabled) {
                            set(purchaseActionField, "currency", props.dynamicFilter.currency?.code);
                        }
                    }
                }
            });
        }

        const checkoutActionField = {};
        if (stepType === "addition") {
            set(checkoutActionField, "step", 1);
        } else if (stepType === "customer") {
            set(checkoutActionField, "step", 2);
        }

        if (isGA4Enabled) {
            set(eecObject, "ecommerce", { items: transactionProducts, ...(type === enhancedEcommType.purchase ? purchaseActionField : {}) });
        } else {
            set(eecObject, `ecommerce.${type}.actionField`, type === enhancedEcommType.checkout ? checkoutActionField : purchaseActionField);
            set(eecObject, `ecommerce.${type}.products`, transactionProducts);
        }

        if (type === enhancedEcommType.purchase) {
            apiContext.logger.info(`Is GTM available? ${(window as any).dataLayer ? "yes" : "no"} Purchase Data: ${JSON.stringify(eecObject)}`);
        }
        (window as any).dataLayer.push(eecObject);
    }
}

export async function displayEec(item?: any, locale?: string, options?: any, initialItem?: any): Promise<boolean> {
    const id = initialItem?.dataLayerId;
    let visibility = false;
    if (id) {
        const dataLayerSettings = await DataLayerApi.findById({ id });
        visibility = !!(dataLayerSettings && dataLayerSettings.enableEec && !dataLayerSettings.enableGA4DataLayer);
    }
    return visibility;
}

export async function displayGA4EventSelection(item?: any, locale?: string, options?: any, initialItem?: any): Promise<boolean> {
    const id = initialItem?.dataLayerId;
    let visibility = false;
    if (id) {
        const dataLayerSettings = await DataLayerApi.findById({ id });
        visibility = !!(dataLayerSettings && dataLayerSettings.enableEec && dataLayerSettings.enableGA4DataLayer);
    }
    return visibility;
}
