import { ContextSlice, PluginSpec } from "../";

import { COUNTRY_WIDGET_CONSTANTS } from "@maxxton/cms-mxts-api";
import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

export const FormPlugin: PluginSpec<{}> = {
    id: "form",
    name: getI18nLocaleObject(namespaceList.pluginForm, "form"),
    description: getI18nLocaleObject(namespaceList.pluginForm, "formDescription"),
    widgets: [
        {
            id: "flexbox",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-flexbox" */ "../page/flexbox"));
                context?.chunkExtractor?.addChunk("page-flexbox");
                return await ref.load();
            },
            targetName: "flexboxWidget",
            parameters: ["form"],
            type: "form",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "revealer",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-revealer" */ "../shared/revealer"));
                context?.chunkExtractor?.addChunk("shared-revealer");
                return await ref.load();
            },
            targetName: "revealerWidget",
            parameters: ["form"],
            type: "form",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "webcontent",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-webcontent" */ "../page/web-content"));
                context?.chunkExtractor?.addChunk("page-webcontent");
                return await ref.load();
            },
            targetName: "webContentWidget",
            parameters: ["form"],
            type: "form",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "form-attachment",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-attachment" */ "./attachment"));
                context?.chunkExtractor?.addChunk("form-attachment");
                return await ref.load();
            },
            targetName: "attachmentWidget",
            type: "form",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "form-text",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-text" */ "./text"));
                context?.chunkExtractor?.addChunk("form-text");
                return await ref.load();
            },
            targetName: "textWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-email",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-email" */ "./email"));
                context?.chunkExtractor?.addChunk("form-email");
                return await ref.load();
            },
            targetName: "emailWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-select",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-select" */ "./select"));
                context?.chunkExtractor?.addChunk("form-select");
                return await ref.load();
            },
            targetName: "selectWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-number",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-number" */ "./number"));
                context?.chunkExtractor?.addChunk("form-number");
                return await ref.load();
            },
            targetName: "numberWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-multiselect",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-multiselect" */ "./multiselect"));
                context?.chunkExtractor?.addChunk("form-multiselect");
                return await ref.load();
            },
            targetName: "multiSelectWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: COUNTRY_WIDGET_CONSTANTS.COUNTRIES_WIDGET_ID,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-countries" */ "./countries"));
                context?.chunkExtractor?.addChunk("form-countries");
                return await ref.load();
            },
            targetName: "countriesWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-select-customer-title",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-customerTitle" */ "./customerTitle"));
                context?.chunkExtractor?.addChunk("form-customerTitle");
                return await ref.load();
            },
            targetName: "customerTitleWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-select-language",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-languages" */ "./languages"));
                context?.chunkExtractor?.addChunk("form-languages");
                return await ref.load();
            },
            targetName: "languagesWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-date",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-date" */ "./date"));
                context?.chunkExtractor?.addChunk("form-date");
                return await ref.load();
            },
            targetName: "dateWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-checkbox",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-checkbox" */ "./checkbox"));
                context?.chunkExtractor?.addChunk("form-checkbox");
                return await ref.load();
            },
            targetName: "checkboxWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-radio",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-radio" */ "./radio"));
                context?.chunkExtractor?.addChunk("form-radio");
                return await ref.load();
            },
            targetName: "radioWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-textarea",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-textarea" */ "./textarea"));
                context?.chunkExtractor?.addChunk("form-textarea");
                return await ref.load();
            },
            targetName: "textareaWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-view",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-formView" */ "./formView"));
                context?.chunkExtractor?.addChunk("form-formView");
                return await ref.load();
            },
            targetName: "formViewWidget",
            type: "page",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "form-source",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-sourceWidget" */ "./sourceWidget"));
                context?.chunkExtractor?.addChunk("form-sourceWidget");
                return await ref.load();
            },
            targetName: "sourceWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-customField",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-customField" */ "./customField"));
                context?.chunkExtractor?.addChunk("form-customField");
                return await ref.load();
            },
            targetName: "customFieldWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
        {
            id: "form-dynamic-data",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "form-dynamicData" */ "./dynamicData"));
                context?.chunkExtractor?.addChunk("form-dynamicData");
                return await ref.load();
            },
            targetName: "dynamicDataFormWidget",
            type: "form",
            widgetGroup: WidgetGroup.INPUT,
        },
    ],
};

export default FormPlugin;
