import { Tag } from "@maxxton/cms-api";
import { getResortList } from "../ConditionalSetGroupInput/utils/conditionalSet.util";

export const getDynamicTags = async () => {
    const resorts = await getResortList();
    const resortTags: Tag[] = resorts?.map((resort) => {
        const { label, value } = resort;
        return { text: label?.toString(), id: +value, dynamicType: "resort" };
    });
    return resortTags;
};
