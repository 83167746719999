import { Action } from "./";
import { ActivityPlannerState } from "../reducers/activityPlannerReducer";

export enum ActivityPlannerActionType {
    SELECT_ACTIVITY = "SELECT_ACTIVITY",
    SET_SUBJECT_QUANTITIES = "SET_SUBJECT_QUANTITIES",
    UPDATE = "UPDATE",
    SET_ACTIVITY_CUSTOM_SUBJECT = "SET_ACTIVITY_CUSTOM_SUBJECT",
    RESET_STATE = "RESET_STATE",
}

export interface ActivityPlannerAction extends Action {
    actionType: ActivityPlannerActionType;
    payload: ActivityPlannerState;
}
