import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Add, Close, CloudUpload, ContentCopy, Download, HourglassBottomRounded, Redo, Undo, Visibility, Warning } from "@mui/icons-material";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { TranslationKey, getI18nLocaleObject, getI18nLocaleString, getI18nLocaleStringFromParams, wrapProps } from "../../i18n";
import { getClientIdFromToken, getPageLockedBy } from "../admin/utils";

import { Alerts } from "../../alerts";
import { EditMode } from "./form.types";
import { PermissionType } from "@maxxton/cms-mxts-api";
import { WidgetOptions } from "../../plugins/form/formView";
import { canOnlyRead } from "../../utils/permissions.utils";
import namespaceList from "../../i18n/namespaceList";

export interface SubmitButtonOptions {
    submitStartedCB?: () => void;
    submitFinishedCB?: () => void;
}

export interface GenericFormButtonsBaseProps {
    onSubmit: (submitOptions?: SubmitButtonOptions, frontPageEdit?: boolean) => void;
    onUndo?: () => void;
    onRedo?: () => void;
    mode: EditMode;
    submitLabel?: TranslationKey | string;
    disableFormSubmit?: boolean;
    cancelLabel?: TranslationKey | string;
    deleteLabel?: TranslationKey | string;
    enableCancel?: boolean;
    enableSubmit?: boolean;
    onCancel?: () => void;
    onDelete?: () => void;
    onClone?: () => void;
    onSite?: () => void;
    onExport?: (startDate: string, endDate: string) => void;
    generateSiteMap?: () => void;
    buttons?: "inline" | "aside" | "none";
    shouldDisableSubmit?: boolean;
    shouldDisableUndo?: boolean;
    shouldDisableRedo?: boolean;
    enableGenerateSitemap?: boolean;
    enableOnSite?: boolean;
    showCloneButton?: boolean;
    showExportButton?: boolean;
    specType?: any;
    alerts?: Alerts;
    permission: PermissionType;
    modalClassName?: string;
    widgetOptions?: WidgetOptions;
}

export type GenericFormButtonsProps = GenericFormButtonsBaseProps;
interface GenericFormButtonsState {
    isOpen: boolean;
    startDate: string;
    endDate: string;
    isSubmitting?: boolean;
    isLocked?: boolean;
}

const defaultSubmitLabel = {
    readonly: getI18nLocaleObject(namespaceList.genericCrud, "save"),
    create: getI18nLocaleObject(namespaceList.genericCrud, "create"),
    edit: getI18nLocaleObject(namespaceList.genericCrud, "save"),
    admin_edit: getI18nLocaleObject(namespaceList.genericCrud, "save"),
};

const defaultCancelLabel = getI18nLocaleObject(namespaceList.genericCrud, "cancel");
const undoRev = getI18nLocaleObject(namespaceList.genericCrud, "undoButton");
const redoRev = getI18nLocaleObject(namespaceList.genericCrud, "redoButton");
const defaultCloneLabel = getI18nLocaleObject(namespaceList.genericCrud, "clone");
const defaultOnsiteLabel = getI18nLocaleObject(namespaceList.genericCrud, "onSite");
const defaultGenerateSitemapLabel = getI18nLocaleObject(namespaceList.genericCrud, "generateSitemapLabel");
const defaultExportLabel = getI18nLocaleObject(namespaceList.genericCrud, "export");
// NO OPeration
// eslint-disable-next-line no-empty
function noop() {}

class GenericFormButtonsBase extends React.PureComponent<GenericFormButtonsProps, GenericFormButtonsState> {
    constructor(props: GenericFormButtonsProps) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            isOpen: false,
        };
    }

    public async componentDidUpdate() {
        const isLocked = await this.isPageLocked();
        if (this.state.isLocked !== isLocked) {
            this.setState({ isLocked });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { buttons, mode, submitLabel, cancelLabel, enableCancel, permission, enableSubmit, enableOnSite, showExportButton, specType, disableFormSubmit } = this.props;
        const { isOpen, startDate, endDate, isSubmitting, isLocked } = this.state;
        const readOnlyPermission = canOnlyRead(permission);
        return (
            <div className={`${isLocked || isSubmitting ? "action-buttons mcms-tooltip-wrap" : "action-buttons"}`}>
                {mode !== "readonly" && mode === "admin_edit" && enableSubmit !== false && (
                    <Button
                        className={`${isLocked || isSubmitting ? "button-lock" : "publish"}`}
                        onClick={this.handleSubmit}
                        color="primary"
                        disabled={readOnlyPermission || this.props.shouldDisableSubmit || isLocked || isSubmitting || disableFormSubmit}
                    >
                        {isLocked ? <Warning /> : isSubmitting ? <HourglassBottomRounded /> : <CloudUpload />}
                        {submitLabel ? getI18nLocaleStringFromParams(submitLabel as string) : getI18nLocaleStringFromParams(defaultSubmitLabel[mode])}
                        {isLocked && <span className="page-lock-info mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "pageLockedSaveButtonNotice")}</span>}
                        {isSubmitting && <span className="page-lock-info mcms-tooltip top">{getI18nLocaleString(namespaceList.admin, "pageIsBeingSavedNotice")}</span>}
                        {disableFormSubmit && <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />}
                    </Button>
                )}
                {specType && (specType.id === "webcontent" || specType.id === "page") && mode !== "readonly" && mode !== "admin_edit" && enableSubmit !== false && (
                    <Button
                        className="send button button--primary"
                        onClick={this.handleUndo}
                        color=""
                        disabled={readOnlyPermission || this.props.shouldDisableUndo}
                        title={getI18nLocaleString(namespaceList.admin, "undo")}
                    >
                        <Undo />
                        {getI18nLocaleStringFromParams(undoRev)}
                    </Button>
                )}
                {specType && (specType.id === "webcontent" || specType.id === "page") && mode !== "readonly" && mode !== "admin_edit" && enableSubmit !== false && (
                    <Button
                        className="send button button--primary"
                        onClick={this.handleRedo}
                        color=""
                        disabled={readOnlyPermission || this.props.shouldDisableRedo}
                        title={getI18nLocaleString(namespaceList.admin, "redo")}
                    >
                        <Redo />
                        {getI18nLocaleStringFromParams(redoRev)}
                    </Button>
                )}
                {mode !== "readonly" && mode !== "admin_edit" && enableSubmit !== false && (
                    <Button className="send button button--primary" onClick={this.handleSubmit} color="" disabled={readOnlyPermission || this.props.shouldDisableSubmit || disableFormSubmit}>
                        {mode === "create" && <Add />}
                        {submitLabel ? getI18nLocaleStringFromParams(submitLabel as string) : getI18nLocaleStringFromParams(defaultSubmitLabel[mode])}
                        {disableFormSubmit && <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />}
                    </Button>
                )}
                {mode !== "readonly" && mode === "admin_edit" && buttons === "aside" && enableOnSite && (
                    <Button disabled={readOnlyPermission} className="publish" color="primary" onClick={this.handleOnSite}>
                        <Visibility />
                        {getI18nLocaleStringFromParams(defaultOnsiteLabel)}
                    </Button>
                )}
                {specType &&
                    (specType.id === "webcontent" || (specType.id === "page" && enableOnSite)) &&
                    mode !== "readonly" &&
                    mode === "admin_edit" &&
                    buttons === "aside" &&
                    this.props.showCloneButton && (
                        <Button
                            className="publish"
                            color="primary"
                            onClick={this.handleUndo}
                            disabled={readOnlyPermission || this.props.shouldDisableUndo}
                            title={getI18nLocaleString(namespaceList.admin, "undo")}
                        >
                            <Undo />
                            {getI18nLocaleStringFromParams(undoRev)}
                        </Button>
                    )}
                {specType &&
                    (specType.id === "webcontent" || (specType.id === "page" && enableOnSite)) &&
                    mode !== "readonly" &&
                    mode === "admin_edit" &&
                    buttons === "aside" &&
                    this.props.showCloneButton && (
                        <Button
                            className="publish"
                            color="primary"
                            onClick={this.handleRedo}
                            disabled={readOnlyPermission || this.props.shouldDisableRedo}
                            title={getI18nLocaleString(namespaceList.admin, "redo")}
                        >
                            <Redo />
                            {getI18nLocaleStringFromParams(redoRev)}
                        </Button>
                    )}
                {mode !== "readonly" && enableCancel !== false && (
                    <Button disabled={readOnlyPermission} className="cancel" color="secondary" onClick={this.handleCancel}>
                        {!this.props.widgetOptions?.enableReadOnly && <Close />}
                        {cancelLabel ? getI18nLocaleStringFromParams(cancelLabel as string) : getI18nLocaleStringFromParams(defaultCancelLabel)}
                    </Button>
                )}
                {mode !== "readonly" && this.props.showCloneButton && mode === "admin_edit" && buttons === "aside" && (
                    <Button disabled={readOnlyPermission} className="publish" color="primary" onClick={this.handleClone}>
                        <ContentCopy />
                        {getI18nLocaleStringFromParams(defaultCloneLabel)}
                    </Button>
                )}
                {mode !== "readonly" && this.props.enableGenerateSitemap && mode === "admin_edit" && buttons === "aside" && (
                    <Button disabled={readOnlyPermission} className="publish" color="primary" onClick={this.generateSiteMap}>
                        <FontAwesome name="sitemap" />
                        {getI18nLocaleStringFromParams(defaultGenerateSitemapLabel)}
                    </Button>
                )}
                {mode !== "readonly" && showExportButton && mode === "admin_edit" && buttons === "aside" && (
                    <Button disabled={readOnlyPermission} className="publish" color="primary" onClick={this.handleDatePopup}>
                        <Download />
                        {getI18nLocaleStringFromParams(defaultExportLabel)}
                    </Button>
                )}
                {
                    <Modal isOpen={isOpen} toggle={this.handleDatePopup} size="lg" backdrop="static">
                        <ModalHeader tag="h4" toggle={this.handleDatePopup}>
                            {getI18nLocaleString(namespaceList.admin, "selectDateRange")}
                        </ModalHeader>
                        <ModalBody>
                            <label htmlFor="startDate">{getI18nLocaleString(namespaceList.admin, "exportStartDate")}</label>
                            <input
                                disabled={readOnlyPermission}
                                id="startDate"
                                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                placeholder={`${getI18nLocaleString(namespaceList.admin, "exportStartDate")}`}
                                value={startDate}
                                type="date"
                                onChange={this.handleDateChange.bind(this, "start")}
                            />
                            <label htmlFor="endDate">{getI18nLocaleString(namespaceList.admin, "exportEndDate")}</label>
                            <input
                                disabled={readOnlyPermission}
                                id="endDate"
                                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                                placeholder={`${getI18nLocaleString(namespaceList.admin, "exportEndDate")}`}
                                value={endDate}
                                type="date"
                                onChange={this.handleDateChange.bind(this, "end")}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={readOnlyPermission} className="publish" color="primary" onClick={this.handleExport}>
                                <Download />
                                {getI18nLocaleString(namespaceList.admin, "export")}
                            </Button>
                            <Button disabled={readOnlyPermission} className="cancel" color="secondary" onClick={this.handleDatePopup}>
                                <Close />
                                {getI18nLocaleString(namespaceList.admin, "cancel")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                }
            </div>
        );
    }

    private handleDateChange = (type: string, e: any) => {
        if (type === "start") {
            this.setState({ startDate: e.target.value });
        } else if (type === "end") {
            this.setState({ endDate: e.target.value });
        }
    };

    private handleCancel = () => {
        const callback = this.props.onCancel || noop;
        callback();
    };

    private handleSubmit = () => {
        const { onSubmit, modalClassName } = this.props;
        const isWebContentEdit = modalClassName === "front-edit-modal";
        if (onSubmit) {
            onSubmit(
                {
                    submitStartedCB: () => this.setState({ isSubmitting: true }),
                    submitFinishedCB: () => this.setState({ isSubmitting: false }),
                },
                isWebContentEdit
            );
        }
    };

    private handleUndo = () => {
        const callback = this.props.onUndo || noop;
        callback();
    };

    private handleRedo = () => {
        const callback = this.props.onRedo || noop;
        callback();
    };

    private handleClone = () => {
        const callback = this.props.onClone || noop;
        callback();
    };

    private handleOnSite = () => {
        const callback = this.props.onSite || noop;
        callback();
    };

    private handleExport = () => {
        const { startDate, endDate } = this.state;
        const callback = this.props.onExport || noop;
        callback(startDate, endDate);
        this.handleDatePopup();
    };

    private handleDatePopup = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    private generateSiteMap = () => {
        const callback = this.props.generateSiteMap || noop;
        callback();
    };

    private async isPageLocked(): Promise<boolean> {
        const pageId = (this.props as any)?.initialItem?._id;
        const clientId = getClientIdFromToken();
        if (this.props.mode === "admin_edit" && ((this.props.specType?.id === "page" && pageId) || this.props.specType?.id === "site") && clientId) {
            const pageLockedBy: number | undefined = await getPageLockedBy(pageId);
            return !!clientId && !!pageLockedBy && pageLockedBy !== clientId;
        }
        return false;
    }
}

export const GenericFormButtons = wrapProps<GenericFormButtonsBaseProps>(GenericFormButtonsBase);
