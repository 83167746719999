import * as React from "react";

export interface ActivityDynamicFieldProps {
    dynamicField: string | null;
    className?: string;
    style?: React.CSSProperties;
}

export const ActivityDynamicField = (props: ActivityDynamicFieldProps) => {
    const { dynamicField, className, style } = props;
    const dynamicSpanRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (dynamicSpanRef.current) {
            dynamicSpanRef.current.innerHTML = dynamicField || "";
        }
    }, [dynamicSpanRef]);

    return <div ref={dynamicSpanRef} className={`${className}`} style={style} />;
};
