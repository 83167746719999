import * as MXTS from "@maxxton/cms-mxts-api";

import { ApiContext } from "../../containers/cmsProvider.types";
import { DebounceUtil } from "../../utils/debounce.util";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { StringUtil } from "../../utils/string.util";
import { getMxtsClientId } from "../../utils/authToken.util";
import { getMxtsEnv } from "../../plugins/mxts";

export const generalProjection = { name: 1, createdAt: 1, updatedAt: 1 };
export const siteProjection = { name: 1, host: 1, createdAt: 1, updatedAt: 1 };
export const pageProjection = { name: 1, enableAsRedirect: 1, createdAt: 1, updatedAt: 1, category: 1 };
export const crpProjection = { name: 1, createdAt: 1, updatedAt: 1, category: 1 };
export const webcontentProjection = { name: 1, cardEnabled: 1, reviewsEnabled: 1, createdAt: 1, updatedAt: 1 };
export const postProjection = { name: 1, permalink: 1, createdAt: 1, updatedAt: 1, category: 1 };
export const categoryProjection = { ...generalProjection, categoryType: 1, categoryId: 1 };
export const localeProjection = { code: 1, localeName: 1, default: 1, createdAt: 1, updatedAt: 1 };
export const translationProjection = {
    value: 1,
    key: 1,
    namespace: 1,
    locale: 1,
    site: 1,
    createdAt: 1,
    updatedAt: 1,
};

export const getProjection = (specId: string): any => {
    let projection: any;
    switch (specId) {
        case "page":
            projection = pageProjection;
            break;
        case "site":
            projection = siteProjection;
            break;
        case "webcontent":
            projection = webcontentProjection;
            break;
        case "post":
            projection = postProjection;
            break;
        case "locale":
            projection = localeProjection;
            break;
        case "translation":
            projection = translationProjection;
            break;
        case "category":
            projection = categoryProjection;
            break;
        default:
            projection = generalProjection;
    }
    return projection;
};

export function getClientIdFromToken(): number | null {
    if (typeof localStorage !== "undefined") {
        const clientId = getMxtsClientId(localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN));
        if (typeof clientId !== "undefined") {
            return clientId;
        }
    }
    return null;
}

export function getClientName(): string | null {
    if (typeof localStorage !== "undefined") {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.CLIENT_NAME);
    }
    return null;
}

export async function setClientName(context: ApiContext, clientId: number | null = null): Promise<void> {
    if (!clientId) {
        clientId = getClientIdFromToken();

        // if still not set, we really aren't authenticated
        if (!clientId) {
            if (typeof localStorage !== "undefined") {
                localStorage.removeItem(LOCAL_STORAGE_KEYS.CLIENT_NAME);
            }
            return;
        }
    }
    const clientOptions: MXTS.ApiCallOptions = await getMxtsEnv(context);
    if (clientOptions.env.concern) {
        const clientName = await context.mxtsApi
            .client(clientOptions, {}, [{ key: "clientId", value: clientId }])
            .then((client: MXTS.Client) => StringUtil.joinFields([client.firstName, client.middleName, client.lastName]))
            .catch(() => "?");
        if (typeof localStorage !== "undefined" && clientName !== "?") {
            localStorage.setItem(LOCAL_STORAGE_KEYS.CLIENT_NAME, clientName);
        }
    }
}

export async function getClientNameById(context: ApiContext, clientId: number): Promise<string> {
    const env = await getMxtsEnv(context);
    return context.mxtsApi
        .client(env, {}, [{ key: "clientId", value: clientId }])
        .then((client: MXTS.Client) => StringUtil.joinFields([client.firstName, client.middleName, client.lastName]))
        .catch(() => "?");
}

export function getCookie(targetCookie: string): string | undefined {
    const allCookiesStr = `; ${document.cookie}`;
    const parts = allCookiesStr.split(`; ${targetCookie}=`);
    if (parts.length === 2) {
        return parts.pop()!.split(";").shift();
    }
}

export async function getPageLockedBy(pageId: string): Promise<number | undefined> {
    const pageLock: { userId: number } | undefined = await DebounceUtil.getPageLockByIdDebounced(pageId);
    return pageLock?.userId;
}

export interface UserPageLock {
    userId: number;
    pageId: string;
    lockingSessions?: string[];
}

export async function getAllPageLocks(): Promise<UserPageLock[]> {
    const url = "/page-locks";
    const pageLocks: UserPageLock[] = await fetch(url, {
        method: "GET",
        headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then(async (resp) => {
            if (!resp.ok) {
                throw new Error(`GET ${url} responded with status ${resp.status} (${await resp.text()})`);
            }
            return resp.json();
        })
        .catch((err) => undefined);
    return pageLocks || [];
}
