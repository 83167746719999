import * as React from "react";

import { AccountCircle, ArrowBackIos, ArrowForwardIos, Assignment, Language, Menu as MenuIcon, OpenInNew, SpaceDashboard, TextSnippet } from "@mui/icons-material";
import { Page, WithId } from "@maxxton/cms-api";

import { Link } from "react-router-dom";
import { getClientName } from "../admin/utils";
import { getI18nLocaleString } from "../../i18n";
import namespacesList from "../../i18n/namespaceList";
import useDarkMode from "./useDarkMode";

interface FrontendEditAdminContainerProps {
    toggleDropdownMenu: () => void;
    page: Page & WithId;
    protocol: string;
    isFrontEndEditable: boolean;
    handleSaveButtonClick: () => void;
    disableActionButtons: boolean;
    handleClearButtonClick: () => void;
    toggleFrontendEditable: () => void;
}
// eslint-disable-next-line max-lines-per-function
const FrontendEditSidebar: React.FC<FrontendEditAdminContainerProps> = (props) => {
    const { page, protocol, isFrontEndEditable, handleSaveButtonClick, disableActionButtons, handleClearButtonClick, toggleFrontendEditable } = props;
    const [showSidebar, setShowSidebar] = React.useState(isFrontEndEditable);
    const [showUserName, setShowUserName] = React.useState(false);
    const isDarkMode = useDarkMode();
    React.useEffect(() => {
        const { body } = document;
        if (isFrontEndEditable) {
            body.classList.add("frontend-edit-sidebar-left");
        } else if (!isFrontEndEditable) {
            body.classList.remove("frontend-edit-sidebar-left");
        }
    }, [isFrontEndEditable]);

    const toggleFrontendEditSidebar = () => {
        setShowSidebar(!showSidebar);
        document.body.classList.toggle("frontend-edit-sidebar-left");
        toggleFrontendEditable();
    };

    const handleProfileIconClick = () => {
        setShowUserName(!showUserName);
    };

    return (
        <div className={`frontend-edit-sidebar-container ${isDarkMode ? "dark-mode-container" : ""}`}>
            <div className="frontend-edit-admin-toggle" onClick={toggleFrontendEditSidebar}>
                {showSidebar ? <ArrowBackIos /> : <ArrowForwardIos />}
            </div>
            {showSidebar && (
                <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        right: "auto",
                    }}
                    className="frontend-edit-sidebar-modal"
                >
                    <div className={`frontend-edit-sidebar-wrapper ${isDarkMode ? "sidebar-dark-mode" : ""}`}>
                        <div className="frontend-sidebar-content">
                            <div className="frontend-sidebar-content__header">
                                <div className="page-details">
                                    <div className="page_details__active-page">
                                        <div className="page-edit-message">{getI18nLocaleString(namespacesList.admin, "pageEditingMessage")}</div>
                                        <div className="page-name">{page.name}</div>
                                    </div>
                                    <a href={`${protocol}://${window.location.hostname}/webmanager/page/edit/` + page._id} target="_blank">
                                        <OpenInNew />
                                        <div className="top-bar-tooltip">
                                            <label className="page-tooltip">{getI18nLocaleString(namespacesList.admin, "tooltipCreateInterlinking")}</label>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="frontend-sidebar-content__quick-link">
                                <div className="quick-links-title">{getI18nLocaleString(namespacesList.admin, "quickLinks")}</div>
                                <ul className="quick-link__list">
                                    <li className="quick-link__item">
                                        <Language /> <Link to={"admin/site"}>{getI18nLocaleString(namespacesList.admin, "site")}</Link>
                                    </li>
                                    <li className="quick-link__item">
                                        <SpaceDashboard /> <Link to={"admin/template"}>{getI18nLocaleString(namespacesList.admin, "template")}</Link>
                                    </li>
                                    <li className="quick-link__item">
                                        <MenuIcon /> <Link to={"admin/menu"}>{getI18nLocaleString(namespacesList.admin, "menu")}</Link>
                                    </li>
                                    <li className="quick-link__item">
                                        <TextSnippet /> <Link to={"admin/webcontent"}>{getI18nLocaleString(namespacesList.admin, "webcontent")}</Link>
                                    </li>
                                    <li className="quick-link__item">
                                        <Assignment /> <Link to={"admin/form"}>{getI18nLocaleString(namespacesList.admin, "form")}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="frontend-edit-sidebar__footer">
                            <div className="footer-item">
                                <div className="profile-detail">
                                    {showUserName && <div className="profile-name">{getClientName()}</div>}
                                    <div className="profile-icon" onClick={handleProfileIconClick}>
                                        <AccountCircle />
                                    </div>
                                </div>
                            </div>

                            <div className="frontend-action-button">
                                {isFrontEndEditable && (
                                    <React.Fragment>
                                        <div className="footer-item">
                                            <button className="frontend-action-button__save" onClick={handleSaveButtonClick} disabled={disableActionButtons}>
                                                {getI18nLocaleString(namespacesList.admin, "save")}
                                            </button>
                                        </div>

                                        {!disableActionButtons && (
                                            <div className="footer-item">
                                                <button className="frontend-action-button__clear" onClick={handleClearButtonClick}>
                                                    {getI18nLocaleString(namespacesList.imitateUser, "reset")}
                                                </button>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FrontendEditSidebar;
