import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";
import { MxtsApi, PagedResult, Resource } from "@maxxton/cms-mxts-api";
import { isArray, map } from "lodash";

import { ACCOMMODATION_TYPE } from "../components/utils";
import { ContentType } from "../components/components.enum";
import { ISelect } from "../components/generic-form/asyncSelect.types";
import { InputSpecAsyncSelect } from "../form-specs";
import { MXTS as MXTS_CONSTANTS } from "../utils/constants";
import { getAdminMxtsEnv } from "../plugins/mxts";
import namespaceList from "../i18n/namespaceList";

async function getResourceOptions(searchKeyword?: string): Promise<Array<ISelect<number>>> {
    const env = await getAdminMxtsEnv();
    const accommodationTypes = await MxtsApi.accommodationTypes(env, {
        size: MXTS_CONSTANTS.MAX_RESULTS,
        search: searchKeyword,
    }).then((pagedResult: PagedResult<Resource>) => pagedResult.content);
    return map(
        accommodationTypes,
        (resource: Resource): ISelect<number> => {
            const { resourceId, name } = resource;
            return {
                value: resourceId,
                label: name,
            };
        }
    );
}

function resourcesAutocomplete<E, P extends keyof E>(variable: string, label?: I18nLocaleObject | string, isVisible?: boolean): InputSpecAsyncSelect<E, P> {
    return {
        variable: (variable as string) as P,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "resourceId"),
        type: "asyncSelect",
        isClearable: true,
        async optionList(item, searchKeyword): Promise<any[]> {
            return getResourceOptions(searchKeyword);
        },
        isMulti: true,
        placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
        visible: (options: any) => {
            const { contentType, contentTypes, preFilteredAvailability } = options;
            if (isVisible) {
                return true;
            } else if (isArray(contentTypes)) {
                let selected = false;
                contentTypes.forEach((contentType: any) => {
                    if (contentType.value === ContentType.ACCOMMODATION_TYPE) {
                        selected = true;
                    }
                });
                return selected && !preFilteredAvailability;
            }
            return contentType === ACCOMMODATION_TYPE && !preFilteredAvailability;
        },
    };
}

export default resourcesAutocomplete;
