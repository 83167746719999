import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";
import { InputSpecSelect, SomeInputSpec } from "../form-specs";

import { SelectOption } from "../form-specs/formSpec.types";
import namespaceList from "../i18n/namespaceList";

export enum FontSize {
    DEFAULT_FONT = "default-font-size",
    MICRO_FONT = "micro-font-size",
    XXSMALL_FONT = "xx-small-font-size",
    XSMALL_FONT = "x-small-font-size",
    SMALL_FONT = "small-font-size",
    H6_FONT = "h6-text",
    H5_FONT = "h5-text",
    H4_FONT = "h4-text",
    H3_FONT = "h3-text",
    H2_FONT = "h2-text",
    H1_FONT = "h1-text",
}

export enum FontTextStyle {
    DEFAULT = "default",
    BACKGROUND_COLOR = "background-text",
    BORDER_LINE = "row-bordered",
}

export const I18N_DEFAULT_FONT = getI18nLocaleObject(namespaceList.admin, "defaultFontSize");
export const I18N_MICRO_FONT = getI18nLocaleObject(namespaceList.admin, "microFontSize");
export const I18N_XXSMALL_FONT = getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize");
export const I18N_XSMALL_FONT = getI18nLocaleObject(namespaceList.admin, "xSmallFontSize");
export const I18N_SMALL_FONT = getI18nLocaleObject(namespaceList.admin, "smallFontSize");
export const I18N_H6_FONT = getI18nLocaleObject(namespaceList.admin, "h6Fonts");
export const I18N_H5_FONT = getI18nLocaleObject(namespaceList.admin, "h5Fonts");
export const I18n_H4_FONT = getI18nLocaleObject(namespaceList.admin, "h4Fonts");
export const I18N_H3_FONT = getI18nLocaleObject(namespaceList.admin, "h3Fonts");
export const I18N_H2_FONT = getI18nLocaleObject(namespaceList.admin, "h2Fonts");
export const I18N_H1_FONT = getI18nLocaleObject(namespaceList.admin, "h1Fonts");
export const I18N_FONT_COLOR = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontColor");
export const I18N_FONT_DEFAULT = getI18nLocaleObject(namespaceList.admin, "default");
export const I18N_ALL_CAPS = getI18nLocaleObject(namespaceList.admin, "allCaps");
export const I18N_CAMELCASE = getI18nLocaleObject(namespaceList.admin, "camelCase");
export const I18N_LOWERCASE = getI18nLocaleObject(namespaceList.admin, "lowerCase");
export const I18N_BACKGROUNDTEXT = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "backgroundText");
export const I18N_UNDERLINE = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "underlineText");
export const I18N_DEFAULT = getI18nLocaleObject(namespaceList.admin, "default");
export const I18N_TEXTBACKGROUND = getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "textBackground");
export const I18N_BORDERWIDTH = getI18nLocaleObject(namespaceList.admin, "borderWidth");

export async function getFontSize(): Promise<Array<SelectOption<string>>> {
    const options = [
        {
            value: FontSize.DEFAULT_FONT,
            label: I18N_DEFAULT_FONT,
        },
        {
            value: FontSize.MICRO_FONT,
            label: I18N_MICRO_FONT,
        },
        {
            value: FontSize.XXSMALL_FONT,
            label: I18N_XXSMALL_FONT,
        },
        {
            value: FontSize.XSMALL_FONT,
            label: I18N_XSMALL_FONT,
        },
        {
            value: FontSize.SMALL_FONT,
            label: I18N_SMALL_FONT,
        },
        {
            value: FontSize.H6_FONT,
            label: I18N_H6_FONT,
        },
        {
            value: FontSize.H5_FONT,
            label: I18N_H5_FONT,
        },
        {
            value: FontSize.H4_FONT,
            label: I18n_H4_FONT,
        },
        {
            value: FontSize.H3_FONT,
            label: I18N_H3_FONT,
        },
        {
            value: FontSize.H2_FONT,
            label: I18N_H2_FONT,
        },
        {
            value: FontSize.H1_FONT,
            label: I18N_H1_FONT,
        },
    ];
    return options;
}

export async function getFontStyle(): Promise<Array<SelectOption<string>>> {
    const options = [
        {
            value: FontTextStyle.DEFAULT,
            label: I18N_DEFAULT,
        },
        {
            value: FontTextStyle.BACKGROUND_COLOR,
            label: I18N_BACKGROUNDTEXT,
        },
        {
            value: FontTextStyle.BORDER_LINE,
            label: I18N_UNDERLINE,
        },
    ];
    return options;
}

export function fontStyleSelector<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string, defaultFormat?: string): InputSpecSelect<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "nameStyle"),
        type: "select",
        required: false,
        optionList: () => getFontStyle() as any,
    };
}

export function fontSizeSelector<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecSelect<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontSize"),
        type: "select",
        required: false,
        optionList: () => getFontSize() as any,
    };
}

export const fontColorSelector = (variable: string): SomeInputSpec<any, any> => ({
    variable,
    label: I18N_FONT_COLOR,
    type: "dual-color",
    default: "font-default",
});

type fontStyleSelectorType = "fontType" | "fontStyle" | "fontBackgroundColor" | "borderWidth" | "fontColor";

export const getFontTypeSelector = ({ fontColorVariable = "fontColor" }: { fontColorVariable?: string } = {}): Array<SomeInputSpec<any, fontStyleSelectorType>> => [
    {
        ...fontSizeSelector<any, any>("fontType"),
    },
    {
        ...fontColorSelector(fontColorVariable),
    },
    {
        ...fontStyleSelector<any, any>("fontStyle"),
    },
    {
        variable: "fontBackgroundColor",
        label: I18N_TEXTBACKGROUND,
        type: "dual-color",
        default: "background-gray",
        visible: (options: any): boolean => options.fontStyle === FontTextStyle.BACKGROUND_COLOR,
    },
    {
        variable: "borderWidth",
        label: I18N_BORDERWIDTH,
        type: "number",
        visible: (options: any): boolean => options.fontStyle === FontTextStyle.BORDER_LINE,
    },
];
