import { Theme } from "./";

const theme: Theme = {
    id: "base",
    name: "Base Theme",
    stylesheets: ["/styles/themes/base/base-admin.css"],
    frontendStylesheets: ["/styles/themes/base/base.css"],
    styles: [
        {
            id: "scroll-to-top-button",
            name: "Scroll to top button",
            targets: ["menu"],
            classnames: ["go-to-top"],
        },
        {
            id: "horizontal-scroll",
            name: "Horizontal scroll",
            targets: ["menu"],
            classnames: ["horizontal-scroll"],
        },
        {
            id: "full-width-menu",
            name: "Show full width dropdown menu",
            targets: ["menu"],
            classnames: ["full-width-menu"],
        },
        {
            id: "list-style-none",
            name: "Remove list button style",
            targets: ["menu", "menu-dropdown", "webcontent"],
            classnames: ["remove-list-style"],
        },
        {
            id: "intro-block",
            name: "Introduction block",
            targets: ["container", "webcontent"],
            classnames: ["intro-block"],
        },
        {
            id: "result-map-block",
            name: "Scrollable results and sticky map",
            targets: ["container"],
            classnames: ["scrollable-results-wrapper"],
        },
        {
            id: "hero-tabs",
            name: "Hero Tabs",
            targets: ["tabs"],
            classnames: ["hero-tabs"],
        },
        {
            id: "dk-map",
            name: "Image map",
            targets: ["container"],
            classnames: ["map-view"],
        },
        {
            id: "full-width",
            name: "Full width elements",
            targets: ["button", "webcontent"],
            classnames: ["w-100 d-block"],
        },
        {
            id: "use-as-read-more-overlay",
            name: "Use flexbox as an read-more overlay",
            targets: ["container"],
            classnames: ["flexbox-overlay"],
        },
        // Full width paragraph
        {
            id: "extend-wrapper",
            name: "full width paragraph",
            targets: ["container", "webcontent", "description", "static"],
            classnames: ["full-width-texts"],
        },
        // Menu options
        {
            id: "dk-nav-main__sticky",
            name: "Stick the flexbox",
            targets: ["container", "menu"],
            classnames: ["nav-main__sticky border-v"],
        },
        {
            id: "dk-nav-page",
            name: "Sticky inline element",
            targets: ["container", "menu"],
            classnames: ["nav-page"],
        },
        {
            id: "menu-container-wrapper",
            name: "Menu Container wrapper",
            targets: ["container"],
            classnames: ["page-wrapper menu-container-wrapper"],
        },
        {
            id: "search-results-wrapper",
            name: "Search results wrapper",
            targets: ["container"],
            classnames: ["list-grid-map-container"],
        },
        {
            id: "dk-site-bar",
            name: "Top menu",
            targets: ["container"],
            classnames: ["site-bar"],
        },
        {
            id: "dk-nav-main",
            name: "Main menu",
            targets: ["container"],
            classnames: ["nav-main"],
        },
        {
            id: "dk-nav-contain",
            name: "contain",
            targets: ["menu-dropdown"],
            classnames: ["nav-dropdown-contain"],
        },
        {
            id: "nav-active",
            name: "Nav active",
            targets: ["menu-dropdown"],
            classnames: ["nav-active"],
        },
        {
            id: "primary-menu-type",
            name: "Primary menu",
            targets: ["menu"],
            classnames: ["primary-menu"],
        },
        {
            id: "sec-menu-type",
            name: "secondary menu type",
            targets: ["menu"],
            classnames: ["secondary-menu"],
        },
        // Boxes
        // Layout items
        {
            id: "dk-wrapper",
            name: "Container wrapper",
            targets: ["container", "recent"],
            classnames: ["page-wrapper"],
        },
        {
            id: "container-wrapper",
            name: "Container wrapper 40px 32px 24px",
            targets: ["container"],
            classnames: ["container-wrapper"],
        },
        {
            id: "container-fluid",
            name: "Container fluid",
            targets: ["container"],
            classnames: ["container-fluid"],
        },
        {
            id: "panel-box",
            name: "Information panel box",
            targets: ["container", "webcontent"],
            classnames: ["panel"],
        },
        {
            id: "reviews-block",
            name: "Review block",
            targets: ["container", "webcontent"],
            classnames: ["slider-reviews"],
        },
        {
            id: "customer-service",
            name: "Customer Service block",
            targets: ["container"],
            classnames: ["customer-service"],
        },
        {
            id: "full-height-on-children",
            name: "Full (100%) height on children",
            targets: ["container"],
            classnames: ["full-height"],
        },
        {
            id: "flexbox-shadow",
            name: "Add shadow to flexbox",
            targets: ["container"],
            classnames: ["flexbox-shadow"],
        },
        // Hero
        {
            id: "tab-switcher",
            name: "Tab switch images",
            targets: ["container"],
            classnames: ["tab-switcher"],
        },
        {
            id: "dk-hero",
            name: "Show as hero image",
            targets: ["container"],
            classnames: ["hero-image"],
        },
        {
            id: "dk-hero-larger",
            name: "Show a hero image larger in height",
            targets: ["container"],
            classnames: ["hero-image__larger-height"],
        },
        {
            id: "content-on-background",
            name: "Content on Background component",
            targets: ["container"],
            classnames: ["content-on-background"],
        },
        {
            id: "revealer-divider",
            name: "Revealer divider",
            targets: ["revealer-link"],
            classnames: ["revealer-divider"],
        },
        {
            id: "faq-revealer",
            name: "FAQ Revealer with white background ",
            targets: ["revealer-link"],
            classnames: ["faq"],
        },
        {
            id: "revealer-link-full",
            name: "Revealer full line link",
            targets: ["revealer-link"],
            classnames: ["revealer-link-full"],
        },
        {
            id: "list-map-results-modal",
            name: "Search results on modal",
            targets: ["revealer-link"],
            classnames: ["results-list-map-modal"],
        },
        {
            id: "nav-full",
            name: "Main menu full width",
            targets: ["container"],
            classnames: ["full-width-nav"],
        },
        // Amenities
        {
            id: "amenities-wrapper",
            name: "Amenities wrapper",
            targets: ["container"],
            classnames: ["amenities-wrapper"],
        },
        {
            id: "space-out-elements-with-padding-in-mansonry",
            name: "Spaced out elements in Mansonry",
            targets: ["dynamic-amenities"],
            classnames: ["spaced-out-elements"],
        },
        {
            id: "usp-big-icon-inline",
            name: "Show icon bigger inline",
            targets: ["usp-amenities"],
            classnames: ["usp-big-icon-inline"],
        },
        {
            id: "show-title-on-hover",
            name: "Show amenity title on hover",
            targets: ["usp-amenities"],
            classnames: ["hovered-title"],
        },
        {
            id: "overlay-container",
            name: "Overlay container",
            targets: ["container"],
            classnames: ["overlay-container"],
        },
        {
            id: "parallax-effect",
            name: "Add Parallax effect",
            targets: ["image"],
            classnames: ["parallax"],
        },
        {
            id: "image-full-width",
            name: "Full width image",
            targets: ["image"],
            classnames: ["image-full-width"],
        },
        {
            id: "boxshadow-on-image",
            name: "Add box-shadow on image",
            targets: ["image"],
            classnames: ["boxshadow-on-image"],
        },
        {
            id: "image-full-height",
            name: "Full height image",
            targets: ["container"],
            classnames: ["image-full-height"],
        },
        {
            id: "flexbox-border-radius",
            name: "Apply border-radius from Flexbox",
            targets: ["image", "image-gallery", "overview-image"],
            classnames: ["flexbox-border-radius"],
        },
        {
            id: "close-button-left",
            name: "Close button on left side",
            targets: ["revealer-link"],
            classnames: ["button-align-left"],
        },
        {
            id: "close-icon-black-color",
            name: "Close icon black color",
            targets: ["revealer-link"],
            classnames: ["close-icon-only"],
        },
        // Search Facet wrapper
        {
            id: "search-facet",
            name: "Full width search facet wrapper",
            targets: ["container"],
            classnames: ["search-facet-full-wrapper search-bar__container "],
        },
        {
            id: "dynamic-container",
            name: "Dynamic container style",
            targets: ["dynamic-container"],
            classnames: ["dynamic-container "],
        },
        // teaser card option
        {
            id: "fully-coverd-link",
            name: "Fully covered link of the block",
            targets: ["webcontent"],
            classnames: ["full-covered-link"],
        },
        {
            id: "inline-nav-button",
            name: "Inline navigation button style",
            targets: ["menu"],
            classnames: ["nav-page__button"],
        },
        {
            id: "results-panel-location",
            name: "Results panel - Location",
            targets: ["results-panel-location"],
            classnames: ["results-panel__location"],
        },
        {
            id: "mobile-center",
            name: "center text on mobile",
            targets: ["webcontent"],
            classnames: ["mobile-center"],
        },
        {
            id: "no-margin",
            name: "Remove margin from all childs",
            targets: ["webcontent", "static", "customer-bill"],
            classnames: ["no-margin"],
        },
        {
            id: "half-margin",
            name: "Use half margin on all childs",
            targets: ["webcontent", "static"],
            classnames: ["half-margin"],
        },
        {
            id: "heading-no-margin",
            name: "Remove margin on widget heading",
            targets: ["availability-date", "subject"],
            classnames: ["heading-no-margin"],
        },
        {
            id: "heading-half-margin",
            name: "Use half margin on widget heading",
            targets: ["availability-date", "subject"],
            classnames: ["heading-half-margin"],
        },
        // Review Rating Widget
        {
            id: "switch-order",
            name: "Stars after rating",
            targets: ["review-rating"],
            classnames: ["switch-order"],
        },
        {
            id: "circle-background",
            name: "Rating circle background",
            targets: ["review-rating"],
            classnames: ["circle-background"],
        },
        // Horizontal List
        {
            id: "horizontal-list",
            name: "Horizontal List",
            targets: ["webcontent"],
            classnames: ["horizontal-list"],
        },
        // Stepper Widget
        {
            id: "arrow-icon",
            name: "Arrow icon",
            targets: ["step-selector"],
            classnames: ["arrow-icon"],
        },
        // Font settings
        {
            id: "secondary-font-family",
            name: "Secondary Font Family",
            targets: ["webcontent", "description"],
            classnames: ["secondary-font-family"],
        },
        {
            id: "small-font-size-labels",
            name: "Smaller font size of the labels",
            targets: ["instalments"],
            classnames: ["small-font-size-labels"],
        },
        // Map settings
        {
            id: "smaller-map-height",
            name: "Show the map smaller (50% of the screen)",
            targets: ["map"],
            classnames: ["smaller-map-height"],
        },
        // Hover effects
        {
            id: "hover-effect",
            name: "Add a hover effect (underline in brand-color)",
            targets: ["menu"],
            classnames: ["hover-effect"],
        },
        // Space between images
        {
            id: "image-white-space",
            name: "Add white space between images",
            targets: ["image-gallery"],
            classnames: ["image-white-space"],
        },
        // Instalments Widget
        {
            id: "mobile-width",
            name: "Responsive sizing with theme styling",
            targets: ["instalments"],
            classnames: ["mobile-width"],
        },
        // Price on button
        {
            id: "price-on-button",
            name: "Show price on button",
            targets: ["container"],
            classnames: ["price-on-button"],
        },
        // Payment Widget
        {
            id: "show-payment-icons",
            name: "Show payment icons",
            targets: ["paymentMethods"],
            classnames: ["payment-icon"],
        },
        // Payment status
        {
            id: "switch-payment-button-link-and-price",
            name: "Show the payment button below the price",
            targets: ["payment-status"],
            classnames: ["ordered"],
        },
        {
            id: "only-border-bottom",
            name: "Only show bottom border",
            targets: ["paymentMethods"],
            classnames: ["only-border-bottom"],
        },
        // Content on image
        {
            id: "content-over-image",
            name: "content on image",
            targets: ["container"],
            classnames: ["content-over-image"],
        },
        // Custom input field
        {
            id: "outlined-text-fields",
            name: "Outlined text fields",
            targets: ["user-login", "guest-form", "form"],
            classnames: ["outlined-fields"],
        },
        {
            id: "label-inside-input",
            name: "Label inside the input field",
            targets: ["user-login", "guest-form", "form"],
            classnames: ["label-inside-input"],
        },
        {
            id: "label-before-input",
            name: "Label before the input field",
            targets: ["user-login", "guest-form", "form"],
            classnames: ["label-before-input"],
        },
        {
            id: "base-guest-form",
            name: "Apply white space on the labels, set a default height of 3 rem and use the same font-sizes",
            targets: ["guest-form"],
            classnames: ["base-guest-form"],
        },
        // Result panel image widget
        {
            id: "rounded-arrows",
            name: "Rounded arrows",
            targets: ["results-panel-image-gallery"],
            classnames: ["rounded-arrows"],
        },
        {
            id: "no-background-arrows",
            name: "Arrows without background",
            targets: ["results-panel-image-gallery"],
            classnames: ["no-background-arrows"],
        },
        {
            id: "same-size-indicators",
            name: "Show indicators same size",
            targets: ["results-panel-image-gallery"],
            classnames: ["same-size-indicators"],
        },
        {
            id: "special-on-image-gallery",
            name: "Place special widget on image gallery",
            targets: ["special"],
            classnames: ["special-on-gallery"],
        },
        {
            id: "Remove-whitespace-modal",
            name: "Remove whitespace from modal",
            targets: ["revealer-link"],
            classnames: ["remove-whitespace-modal"],
        },
        // Fieldset design
        {
            id: "fieldset-design",
            name: "fieldset design",
            targets: ["container"],
            classnames: ["fieldset-design"],
        },
        {
            id: "figure-remove-margin",
            name: "Remove margin on figure",
            targets: ["container", "overview-image"],
            classnames: ["figure-remove-margin"],
        },
        // Sticky footer
        {
            id: "sticky-footer",
            name: "Fixed footer for less content",
            targets: ["container"],
            classnames: ["sticky-footer"],
        },
        {
            id: "footer",
            name: "Footer styling",
            targets: ["container", "template"],
            classnames: ["footer"],
        },
        {
            id: "fixed-menu-top",
            name: "Fixed menu on top",
            targets: ["container", "template"],
            classnames: ["fixed-menu-top"],
        },
        {
            id: "lang-align",
            name: "flags in a row",
            targets: ["language"],
            classnames: ["lang-align"],
        },
        // Availability Widget
        {
            id: "remove-padding",
            name: "Remove all padding",
            targets: ["availability-date", "container"],
            classnames: ["no-padding"],
        },
        {
            id: "add-on-card-design",
            name: "Add on card design",
            targets: ["add-ons", "activity-planner", "activity-search-container"],
            classnames: ["add-on-card-design"],
        },
        // Assetpublisher
        {
            id: "Dark navigation arrows",
            name: "Dark navigation arrows",
            targets: ["assetpublisher"],
            classnames: ["arrows-dark"],
        },
        {
            id: "big-cards-three-rows",
            name: "Big cards in three rows",
            targets: ["assetpublisher"],
            classnames: ["row-cards-big"],
        },
        {
            id: "content-on-image",
            name: "Card content on background image",
            targets: ["assetpublisher"],
            classnames: ["content-on-image"],
        },
        // Activity Planner
        {
            id: "overlay-timings",
            name: "show timings on image",
            targets: ["activity-planner", "activity-search-container"],
            classnames: ["overlay-timings"],
        },
        // Bedroom Bathroom
        {
            id: "bordered-styling",
            name: "Add a border and width 100%",
            targets: ["bedroomBathroom"],
            classnames: ["bordered-dropdown"],
        },
        // Slideshow
        {
            id: "images-object-fit-contain",
            name: "Entire image is visible",
            targets: ["slideShow"],
            classnames: ["images-object-fit-contain"],
        },
        {
            id: "remove-aspect-ratio",
            name: "Remove aspect ratio preset",
            targets: ["slideShow"],
            classnames: ["remove-aspect-ratio"],
        },
        // Location selector
        {
            id: "small-label-centered-spacing",
            name: "Show a smaller label in dropdown and center the spacing",
            targets: ["resorts"],
            classnames: ["location-selector"],
        },
        // Reservation day countdown
        {
            id: "countdown-my-env-styling",
            name: "Hide icon and position items in a column",
            targets: ["ReservationDaysCountdown"],
            classnames: ["countdown-column"],
        },
        // Customer summary information
        {
            id: "show-content-inline",
            name: "Show the title and content inline",
            targets: ["customer-summary", "results-panel-location", "dynamic-status", "description"],
            classnames: ["inline"],
        },
        {
            id: "justified-spacing",
            name: "Space out the elements evenly",
            targets: ["customer-summary"],
            classnames: ["space-even"],
        },
        // Space between two contents(specially for price and button in crps)
        {
            id: "space-between-items",
            name: "Space between two contents",
            targets: ["container"],
            classnames: ["space-between-items"],
        },
        // Border around content
        {
            id: "bordered-box",
            name: "Add a border around the widget",
            targets: ["description"],
            classnames: ["bordered-box"],
        },
        // Full-width border around description
        {
            id: "bordered-description",
            name: "Add border around description",
            targets: ["description"],
            classnames: ["bordered-description"],
        },
        // Customer bill
        {
            id: "small-padding-price-align-right",
            name: "Add a small amount of padding on the bill lines and align the prices to the right",
            targets: ["customer-bill"],
            classnames: ["styled-bill"],
        },
        // Results Panel Image Gallery Widget inside flexbox
        {
            id: "crp-gallery-full-height",
            name: "Full height CRP Gallery",
            targets: ["container"],
            classnames: ["crp-gallery-full-height"],
        },
        // Travel Party Summary
        {
            id: "seperate-travellers-with-form-styling",
            name: "Seperate each traveller and display them as a form",
            targets: ["travel-party"],
            classnames: ["form"],
        },
        // Payment term set
        {
            id: "show-two-sets-next-to-each-other",
            name: "Show two sets next to each order, with a border around",
            targets: ["payment-term-sets"],
            classnames: ["boxed"],
        },
        // AP external link
        {
            id: "external-booking",
            name: "Add a class for external booking button",
            targets: ["description"],
            classnames: ["external-booking"],
        },
        // CRP completely clickable
        {
            id: "entire-block-clickable",
            name: "Make the entire block clickable",
            targets: ["button"],
            classnames: ["entire-block-clickable"],
        },
        // Hover state on flexbox
        {
            id: "Shadow on hover",
            name: "Add a shadow on hover",
            targets: ["container"],
            classnames: ["bg-shadow"],
        },
    ],
};

export default theme;
