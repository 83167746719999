import * as React from "react";

import { Template, TemplateApi } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { FormSpec } from "../";
import { findAffectedAssets } from "../utils";
import { getTimeStampOptions } from "../columnSpec.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";
import { renderPageWidgets } from "../../plugins";
import { widgetHierarchy } from "./";

const Localized = loadable(() => loadableRetry(() => import("../../containers/Localized")), {
    resolveComponent: ({ Localized }) => Localized,
});

export const templateSpec: FormSpec<Template> = {
    id: "template",
    name: getI18nLocaleObject(namespaceList.admin, "template"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "templates"),
    api: TemplateApi,
    permission: "webmanager.templates",
    display(template: Template): string {
        return template.name;
    },
    async preview(template: Template, context: CMSProvidedProperties): Promise<JSX.Element> {
        if (!Array.isArray(template.root) || template.root.length === 0) {
            return <Localized key={getI18nLocaleString(namespaceList.admin, "noWidget")} />;
        }
        const children = await renderPageWidgets(template.root, context);
        return <div className="template-preview">{children}</div>;
    },
    tableColumns: [{ name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" }, ...getTimeStampOptions()],
    properties: [
        {
            label: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            type: "text",
            required: true,
        },
        widgetHierarchy<Template, "root">({
            label: getI18nLocaleObject(namespaceList.admin, "content"),
            variable: "root",
            rootTitle: getI18nLocaleObject(namespaceList.admin, "onTemplate"),
            templatesTitle: getI18nLocaleObject(namespaceList.admin, "widgets"),
            widgetType: "page",
            rootType: "multiple",
        }),
    ],
    async linkage(id: string) {
        const affectedModels = await findAffectedAssets(id, "templateId");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedPages"),
                links: affectedModels.affectedPages,
                specId: "page",
            },
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedTemplates"),
                links: affectedModels.affectedTemplates,
                specId: "template",
            },
        ];
    },
};
