import { ContextSlice, PluginSpec } from "../";

import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

const ResultsPanelPlugin: PluginSpec<Record<string, unknown>> = {
    id: "plugin-results-panel",
    name: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "typesearchContainer"),
    description: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "typesearchContainerDescription"),
    widgets: [
        {
            id: "flexbox",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-flexbox" */ "../page/flexbox"));
                context?.chunkExtractor?.addChunk("page-flexbox");
                return await ref.load();
            },
            targetName: "flexboxWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "tabs",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-tabs" */ "../page/tabs"));
                context?.chunkExtractor?.addChunk("page-tabs");
                return await ref.load();
            },
            targetName: "tabsWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "spacer",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-spacer" */ "../page/spacer"));
                context?.chunkExtractor?.addChunk("page-spacer");
                return await ref.load();
            },
            targetName: "spacerWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.LAYOUT,
        },
        {
            id: "revealer",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-revealer" */ "../shared/revealer"));
                context?.chunkExtractor?.addChunk("shared-revealer");
                return await ref.load();
            },
            targetName: "revealerWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "webcontent",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-webcontent" */ "../page/web-content"));
                context?.chunkExtractor?.addChunk("page-webcontent");
                return await ref.load();
            },
            targetName: "webContentWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "template",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-template" */ "../page/template"));
                context?.chunkExtractor?.addChunk("page-template");
                return await ref.load();
            },
            targetName: "templateWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "resultsPanelImageGalleryWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-imageGallery" */ "./image-gallery"));
                context?.chunkExtractor?.addChunk("resultsPanel-imageGallery");
                return await ref.load();
            },
            targetName: "resultsPanelImageGalleryWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "image",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-image" */ "../page/image"));
                context?.chunkExtractor?.addChunk("page-image");
                return await ref.load();
            },
            targetName: "imageWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "resultsPanelDescriptionWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-description" */ "./description"));
                context?.chunkExtractor?.addChunk("resultsPanel-description");
                return await ref.load();
            },
            targetName: "resultsPanelDescriptionWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "uspAmenitiesWidgetWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-uspAmenities" */ "../dynamic/uspAmenities/UspAmenities"));
                context?.chunkExtractor?.addChunk("dynamic-uspAmenities");
                return await ref.load();
            },
            targetName: "uspAmenitiesWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "dynamic-status-widget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-dynamicStatus" */ "../dynamic/payment-widgets/dynamic-status/index"));
                context?.chunkExtractor?.addChunk("dynamic-dynamicStatus");
                return await ref.load();
            },
            targetName: "resultPanelStatusWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "ReservationDatesAndTimingsWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-datesAndTimings" */ "../dynamic/reservation/datesAndTimings/index"));
                context?.chunkExtractor?.addChunk("dynamic-datesAndTimings");
                return await ref.load();
            },
            targetName: "crpReservationDatesAndTimingsWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "ReservationDaysCountdownWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-daysCountdown" */ "../dynamic/reservation/daysCountdown/index"));
                context?.chunkExtractor?.addChunk("dynamic-daysCountdown");
                return await ref.load();
            },
            targetName: "crpReservationDaysCountdownWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "ReservationTransactionOverviewWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-transactionOverview" */ "../dynamic/reservation/transactionOverview/index"));
                context?.chunkExtractor?.addChunk("dynamic-transactionOverview");
                return await ref.load();
            },
            targetName: "crpReservationTransactionOverviewWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "UnitPreferenceWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-unitPreference" */ "../dynamic/unitPreference/index"));
                context?.chunkExtractor?.addChunk("dynamic-unitPreference");
                return await ref.load();
            },
            targetName: "crpUnitPreferenceWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "DynamicMyEnvContainerWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-containerMyenv" */ "../dynamic/container/myenv/index"));
                context?.chunkExtractor?.addChunk("dynamic-containerMyenv");
                return await ref.load();
            },
            targetName: "crpDynamicMyEnvContainerWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
            container: true,
        },
        {
            id: "static",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-static" */ "../page/static/index"));
                context?.chunkExtractor?.addChunk("page-static");
                return await ref.load();
            },
            targetName: "crpStaticWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "resultsPanelRatingsReviewWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-reviewRating" */ "./review-rating"));
                context?.chunkExtractor?.addChunk("resultsPanel-reviewRating");
                return await ref.load();
            },
            targetName: "resultsPanelRatingsReviewWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resultsPanelDynamicButtonWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-button" */ "./button"));
                context?.chunkExtractor?.addChunk("resultsPanel-button");
                return await ref.load();
            },
            targetName: "resultsPanelDynamicButtonWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resultsPanelPrice",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-price" */ "./price"));
                context?.chunkExtractor?.addChunk("resultsPanel-price");
                return await ref.load();
            },
            targetName: "resultsPanelPriceWidget",
            parameters: [{ widgetId: "resultsPanelPrice", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-price-widget" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resultsPanelLocationWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-location" */ "../shared/location"));
                context?.chunkExtractor?.addChunk("shared-location");
                return await ref.load();
            },
            targetName: "locationWidget",
            parameters: [{ widgetId: "resultsPanelLocationWidget", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-location-options" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resultsPanelSpecialWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-special" */ "../shared/special"));
                context?.chunkExtractor?.addChunk("shared-special");
                return await ref.load();
            },
            targetName: "sharedSpecialWidget",
            parameters: [{ widgetId: "resultsPanelSpecialWidget", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-special-widget" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resultsPanelTimeslotWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-activityTimeslot" */ "../shared/activityTimeslot"));
                context?.chunkExtractor?.addChunk("shared-activityTimeslot");
                return await ref.load();
            },
            targetName: "sharedActivityTimeslotWidget",
            parameters: [{ widgetId: "resultsPanelTimeslotWidget", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-timeslot-widget" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resultsPanelPriceMatrixWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-priceMatrix" */ "../shared/priceMatrix"));
                context?.chunkExtractor?.addChunk("shared-priceMatrix");
                return await ref.load();
            },
            targetName: "sharedPriceMatrixWidget",
            parameters: [{ widgetId: "resultsPanelPriceMatrixWidget", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-priceMatrix-widget" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "resultsPanelReservableUnitWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-reservableUnit" */ "../shared/reservable-unit"));
                context?.chunkExtractor?.addChunk("shared-reservableUnit");
                return await ref.load();
            },
            targetName: "reservableUnitWidget",
            parameters: [{ widgetId: "resultsPanelReservableUnitWidget", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-reservable-unit-options" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "GuestReviewRatingWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-guestReviewRating" */ "../shared/guestReviewRating"));
                context?.chunkExtractor?.addChunk("shared-guestReviewRating");
                return await ref.load();
            },
            targetName: "sharedGuestReviewRatingWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "accommodation-kind",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-accommodationKind" */ "./accommodation-kind"));
                context?.chunkExtractor?.addChunk("resultsPanel-accommodationKind");
                return await ref.load();
            },
            targetName: "resultsPanelAccommodationKindWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DISPLAY,
        },
        {
            id: "resultsPanelDisplayBathroom",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-bathroom" */ "./display/bathroom"));
                context?.chunkExtractor?.addChunk("resultsPanel-bathroom");
                return await ref.load();
            },
            targetName: "resultsPanelDisplayBathroomWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DISPLAY,
        },
        {
            id: "resultsPanelDisplayBedroom",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-bedroom" */ "./display/bedroom"));
                context?.chunkExtractor?.addChunk("resultsPanel-bedroom");
                return await ref.load();
            },
            targetName: "resultsPanelDisplayBedroomWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DISPLAY,
        },
        {
            id: "resultsPanelDisplayCapacity",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-capacity" */ "./display/capacity"));
                context?.chunkExtractor?.addChunk("resultsPanel-capacity");
                return await ref.load();
            },
            targetName: "resultsPanelDisplayCapacityWidget",
            parameters: [{ widgetId: "resultsPanelDisplayCapacity", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-diaplay-capacity-widget" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "resultsPanelMinimumMaximumStayWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-minimumStay" */ "./minimum-stay"));
                context?.chunkExtractor?.addChunk("resultsPanel-minimumStay");
                return await ref.load();
            },
            targetName: "resultsPanelMinimumStayWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DISPLAY,
        },
        {
            id: "customerBillWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-bill" */ "../dynamic/Bill"));
                context?.chunkExtractor?.addChunk("dynamic-bill");
                return await ref.load();
            },
            targetName: "customerBillWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "priceCompareWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-priceCompare" */ "../dynamic/priceCompare"));
                context?.chunkExtractor?.addChunk("dynamic-priceCompare");
                return await ref.load();
            },
            targetName: "priceCompareWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "checkInCheckOut",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-checkInCheckOut" */ "../shared/checkInCheckOut"));
                context?.chunkExtractor?.addChunk("shared-checkInCheckOut");
                return await ref.load();
            },
            targetName: "checkInCheckOutWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "overview-panel",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-overviewPanel" */ "../dynamic/overview-panel"));
                context?.chunkExtractor?.addChunk("dynamic-overviewPanel");
                return await ref.load();
            },
            targetName: "overviewPanelWidget",
            parameters: ["resultsPanel"],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.BOOKINGS_MODULE,
        },
        {
            id: "resultsPanelPaymentStatusWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-paymentStatus" */ "../shared/payment-status"));
                context?.chunkExtractor?.addChunk("shared-paymentStatus");
                return await ref.load();
            },
            targetName: "paymentStatusWidget",
            parameters: [{ widgetId: "resultsPanelPaymentStatusWidget", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-payment-status-options" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "resultsPanelTravelPartySummaryWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-travelPartySummary" */ "./travel-party-summary"));
                context?.chunkExtractor?.addChunk("resultsPanel-travelPartySummary");
                return await ref.load();
            },
            targetName: "travelPartySummaryWidget",
            parameters: [{ widgetId: "resultsPanelTravelPartySummaryWidget", widgetType: "resultsPanel", widgetOptionsFormId: "results-panel-travel-party-summary-options" }],
            type: "resultsPanel",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "qualityLevel",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-qualityLevel" */ "../shared/qualityLevel"));
                context?.chunkExtractor?.addChunk("shared-qualityLevel");
                return await ref.load();
            },
            parameters: [{ widgetId: "qualityLevel", widgetType: "resultsPanel" }],
            targetName: "QualityLevelWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "ownerMaintenanceWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "dynamic-ownerMaintenance" */ "../dynamic/owner/ownerMaintenance"));
                context?.chunkExtractor?.addChunk("dynamic-ownerMaintenance");
                return await ref.load();
            },
            targetName: "ownerMaintenanceWidget",
            type: "resultsPanel",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
    ],
};

export default ResultsPanelPlugin;
