import { AddOn, Group } from "@maxxton/cms-mxts-api";
import { AddOnsActionType, AddOnsReduxAction } from "../../actions/add-ons/addOnsAction";

import { Reducer } from "redux";

export interface AddOnsState {
    addOns?: AddOn[];
    marketingGroupsWithAddOns?: MarketingGroupWithAddOns[];
    fetching?: boolean;
    error?: unknown;
    recommendedAddOns?: AddOn[];
}

export interface MarketingGroupWithAddOns {
    marketingGroup: Group;
    addOns: AddOn[];
}

export const addOnsReducer: Reducer<AddOnsState> = (state: AddOnsState = { fetching: true }, action: AddOnsReduxAction): AddOnsState => {
    switch (action.actionType) {
        case AddOnsActionType.FETCHING_ADD_ONS: {
            return {
                ...state,
                fetching: true,
            };
        }
        case AddOnsActionType.FETCH_ADD_ONS_SUCCESS: {
            return {
                ...state,
                addOns: action.payload?.addOns,
                recommendedAddOns: action.payload?.recommendedAddOns,
                marketingGroupsWithAddOns: action.payload?.marketingGroupsWithAddOns,
                fetching: false,
            };
        }
        case AddOnsActionType.FETCH_ADD_ONS_ERROR: {
            return {
                ...state,
                addOns: undefined,
                error: action.payload?.error || "Error - Could not fetch add-ons",
                fetching: false,
            };
        }
        default:
            return state;
    }
};
