import { ApiCallOptions, MxtsApi, PagedResult } from "@maxxton/cms-mxts-api";

import { CustomUnit } from "../../mxts/mxts.types";
import { DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS } from "./revenue-streams";
import { MXTS } from "../../../utils/constants";
import { Moment } from "moment";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { StringUtil } from "../../../utils/string.util";
import { WidgetOptions } from "../../../form-specs";
import { getCustomerIdsFromLoginToken } from "../../../redux/reducers/myEnv/myEnv.util";
import { getI18nLocaleObject } from "../../../i18n/loader";
import namespaceList from "../../../i18n/namespaceList";
import { unitHasCurrentlyActiveContract } from "../../../components/utils";

export enum CustomerType {
    GUEST = "GUEST",
    OWNER = "OWNER",
}

export enum CalendarOrientation {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical",
}

export const CUSTOMER_TYPE_OPTIONS: Array<SelectOption<CustomerType>> = Object.keys(CustomerType).map((customerType: CustomerType) => ({
    value: customerType,
    label: getI18nLocaleObject(namespaceList.ownerDocumentInvoiceWidget, "customerType" + StringUtil.convertSnakeCaseToUpperCamelCase(customerType.toString())),
}));

export const getUnitIdsFromContracts = async (env: ApiCallOptions, contractTypeIds?: number[]) => {
    if (contractTypeIds?.length) {
        const customerIds = await getCustomerIdsFromLoginToken();
        const contractUnitIds = (await MxtsApi.getContracts(env, { contractTypeIds, size: MXTS.MAX_RESULTS, isActiveContract: true, ownerIds: customerIds })).content.map(
            (contract) => contract.unitId
        );
        return contractUnitIds;
    }
};

export function getPercentageChangeInData(previousYearCount: number, currentYearCount: number) {
    if (currentYearCount === 0 && previousYearCount === 0) {
        return 0;
    }
    if (currentYearCount && previousYearCount === 0) {
        return Number((((currentYearCount - previousYearCount) / currentYearCount) * 100).toFixed(0));
    }
    return Number((((currentYearCount - previousYearCount) / previousYearCount) * 100).toFixed(0));
}

export function getVisibleYears(params: { endDate: Moment; startDate: Moment; showPreviousYear: boolean; showNextYear: boolean }): number[] {
    const { showPreviousYear, showNextYear, startDate, endDate } = params;
    const visibleYears = new Set<number>();
    const startYear = startDate.year();
    const endYear = endDate.year();
    visibleYears.add(startYear);
    visibleYears.add(endYear);
    for (let year = startYear + 1; year < endYear; year++) {
        visibleYears.add(year);
    }
    if (showPreviousYear) {
        visibleYears.add(startYear - 1);
    }
    if (showNextYear) {
        visibleYears.add(endYear + 1);
    }
    const visibleYearsArray = Array.from(visibleYears);
    visibleYearsArray.sort();
    return visibleYearsArray;
}

export function getYearColor(yearIndex: number, showPreviousYear: boolean, options: WidgetOptions<any>) {
    let targetYearIndex = yearIndex + 1;
    if (showPreviousYear) {
        targetYearIndex--;
    }
    if (targetYearIndex === 0) {
        return options.previousYearColor || DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS.PREVIOUS_YEAR;
    } else if (targetYearIndex === 1) {
        return options.currentYearColor || DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS.CURRENT_YEAR;
    } else if (targetYearIndex === 2) {
        return options.nextYearColor || DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS.NEXT_YEAR;
    }
    return "";
}

export async function getUnitsWithInactiveContractField(env: ApiCallOptions, units: PagedResult<CustomUnit>) {
    const allUnitIds = units.content.map((unit) => unit.unitId);
    const allUnitContracts = await MxtsApi.getContracts(env, { size: MXTS.MAX_RESULTS, unitIds: allUnitIds });
    units.content.map((unit) => {
        const unitContracts = allUnitContracts.content.filter((unitContract) => unitContract.unitId === unit.unitId);
        unit.hasInactiveContract = !unitHasCurrentlyActiveContract(unitContracts);
    });
    return units;
}
