/**
 * Contains string utility methods
 * TODO: create unit tests for these methods
 */
export class StringUtil {
    public static equalsIgnoreCase(string1: string, string2: string): boolean {
        return string1?.toLowerCase() === string2?.toLowerCase();
    }

    public static encodeBase64(input: string | number): string {
        if (typeof Buffer === "function") {
            return Buffer.from("" + input).toString("base64");
        }
        // first we use encodeURIComponent to get percent-encoded UTF-8, then we convert
        // the percent encodings into raw bytes which can be fed into btoa.
        return btoa(
            encodeURIComponent(input).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
                return String.fromCharCode(("0x" + p1) as any);
            })
        );
    }

    public static decodeBase64(encodedInput: string): string {
        if (typeof Buffer === "function") {
            return Buffer.from(encodedInput, "base64").toString();
        }
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(encodedInput)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static isString(input: any): boolean {
        return typeof input === "string" || input instanceof String;
    }

    public static isEmpty(word: string): boolean {
        return !word;
    }

    public static isNotEmpty(word: string): boolean {
        return !StringUtil.isEmpty(word);
    }

    public static joinFields(fields: string[], separator = " "): string {
        return (fields || []).filter((a) => StringUtil.isNotEmpty(a)).join(separator);
    }

    public static generateUUID(): string {
        return this.generateRandomString("xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx");
    }

    public static generateRandomString(input = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"): string {
        return input.replace(/[xy]/g, (c) => {
            // eslint-disable-next-line no-bitwise
            const r = (Math.random() * 16) | 0;
            // eslint-disable-next-line no-bitwise
            const v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    public static convertToDocumentQuerySelectable(input?: string): string | undefined {
        if (!input) {
            return undefined;
        }
        if (!input.match(/^[a-zA-Z_]/)) {
            // querySelector can't start with number's or special characters
            input = "_" + input;
        }
        // remove spaces and special characters
        return input.replace(/[^a-zA-Z0-9_-]/g, "");
    }

    static convertErrorObjToString(error: Error | Record<string, unknown> | undefined | null): string | undefined {
        let stringifiedError: string | undefined;
        try {
            stringifiedError = JSON.stringify(error);
            stringifiedError = stringifiedError?.trim().length && stringifiedError?.trim() !== "{}" && stringifiedError?.trim() !== "null" ? stringifiedError : undefined;
        } catch (err) {
            // Do nothing
        }

        const errorStack: string | undefined = error && typeof error === "object" && "stack" in error && error.stack ? `${error.stack}` : undefined;
        const errorMessage: string | undefined = error && typeof error === "object" && "message" in error && error.message ? `${error.message}` : undefined;

        try {
            const errorToString = error && error.toString();
            const errorParts = [stringifiedError, errorStack, errorToString, errorMessage].filter((message) => !!message);
            return errorParts.length ? errorParts.join(" - ") : undefined;
        } catch (err) {
            return undefined;
        }
    }

    static convertSnakeCaseToUpperCamelCase(snake: string) {
        return this.capitalizeFirstLetter(
            snake
                .toLowerCase()
                .split("_")
                .map((snakePart) => this.capitalizeFirstLetter(snakePart))
                .join("")
        );
    }

    static capitalizeFirstLetter(word: string) {
        if (!word?.length) {
            return "";
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    static convertStringToNumber(str?: string): number | undefined {
        return str ? +str : undefined;
    }

    static removeWhiteSpaceFromString(str: string): string {
        return str.split(" ").join("");
    }

    public static utf8Encode(unicodeString: string): string {
        return unicodeString
            .replace(
                /[\u0080-\u07ff]/g, // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
                (c) => {
                    const cc = c.charCodeAt(0);
                    // eslint-disable-next-line no-bitwise
                    return String.fromCharCode(0xc0 | (cc >> 6), 0x80 | (cc & 0x3f));
                }
            )
            .replace(
                /[\u0800-\uffff]/g, // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
                (c) => {
                    const cc = c.charCodeAt(0);
                    // eslint-disable-next-line no-bitwise
                    return String.fromCharCode(0xe0 | (cc >> 12), 0x80 | ((cc >> 6) & 0x3f), 0x80 | (cc & 0x3f));
                }
            );
    }
}
