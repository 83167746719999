const whitelistedCacheKeyParams = ["unitid", "resourceid", "resortids", "resortid"];

const FEATURE_FLAG_QUERY_PARAMS_IN_CACHE_KEY = true;

export function filterCacheParams(searchParams: URLSearchParams, dynamicParams?: Partial<any>): string {
    if (!FEATURE_FLAG_QUERY_PARAMS_IN_CACHE_KEY) {
        return "";
    }

    const filteredSearchParams: { [key: string]: any } = {};
    if (dynamicParams) {
        for (const param of Object.keys(dynamicParams)) {
            const value = (dynamicParams as any)[param];
            const paramName = param.toLowerCase();
            if (whitelistedCacheKeyParams.includes(paramName) && value !== null) {
                filteredSearchParams[paramName] = value;
            }
        }
    }
    if (searchParams) {
        // sort params in alfabethic order
        for (const param of Array.from(searchParams.keys())) {
            const value = searchParams.get(param);
            const paramName = param.toLowerCase();
            if (whitelistedCacheKeyParams.includes(paramName) && value !== null) {
                filteredSearchParams[paramName] = value;
            }
        }
    }

    return Object.keys(filteredSearchParams)
        .sort()
        .map((s) => `${encodeURIComponent(s)}=${encodeURIComponent(filteredSearchParams[s])}`)
        .join("&");
}
