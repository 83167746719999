import * as moment from "moment";

import { AddOnsSagaAction, AddOnsSagaActionType } from "../redux/actions/add-ons/addOnsAction";
import { AdditionAction, AdditionActionPayload } from "../redux/actions/additionAction";
import {
    ApiCallOptions,
    BillBalance,
    BillLine,
    Choice,
    ChoiceResourceType,
    ChoiceResult,
    MxtsApi,
    PagedResult,
    PriceEngineException,
    PriceTask,
    ReservationBill,
    ReservationResult,
} from "@maxxton/cms-mxts-api";
import { BillAction, BillActionType, GenerateBillAction, GenerateBillPayload } from "../redux/actions/billAction";
import { BillChoice, BillState, BillType } from "../redux/reducers/billReducer";
import { BillUtil, convertChoiceToBillChoice, getEmptyBillChoice } from "../utils/bill.util";
import { SelectedAddOn, SelectedAddOnsState } from "../redux/reducers/add-ons/selectedAddOnsReducer";
import { SelectedAddOnsAction, SelectedAddOnsActionPayload, clearSelectedAddOns, updateSelectedAddOns } from "../redux/actions/add-ons/selectedAddOnsAction";
import { SpecialSearchAction, SpecialSearchActionType } from "../redux/actions/specialSearchAction";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { createAddOnNotification, refreshBillIncludingSelectedAddOns } from "./billSaga.util";

import { ActionType } from "../redux/actions";
import { AdditionState } from "../redux/reducers/additionReducer";
import { BillSagaDataManager } from "./BillSagaDataManager";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { InstalmentsState } from "../redux/reducers/instalmentsState";
import { MyEnvState } from "../redux/reducers/myEnv/myEnvState";
import { ReservationKind } from "../plugins/dynamic/reservation/reservation.enum";
import { SagaUtils } from "./SagaUtils";
import { SpecialSearchState } from "../redux/reducers/specialSearchReducer";
import { TaskExecutionManager } from "./TaskExecutionManager";
import { getFlattenedMyEnvData } from "../redux/reducers/myEnv/myEnv.util";
import { getMxtsEnv } from "../plugins/mxts";
import { getSelectedAddOnsInsideCart } from "../plugins/dynamic/add-ons/addOns.util";
import { globalApiContext } from "../containers/CmsProvider";
import { isEmpty } from "lodash";

interface GenerateBillActionParams extends GenerateBillPayload, AdditionActionPayload, SelectedAddOnsActionPayload {
    targetBill?: BillType;
}

interface EmptyAction {
    type: keyof ActionType;
    payload: Record<string, unknown>;
}

export const BillLineTypeInNumber: { [key: string]: any } = {
    0: "EMPTY",
    10: "RESERVED_RESOURCE",
    20: "RESORT_ARTICLE",
    30: "RESORT_ARTICLES_TOTAL",
    40: "SUBTOTAL",
    50: "WARRANT",
    60: "VAT",
    65: "VAT_EXCL_LINE",
    70: "TOTAL",
    75: "TOTAL_EXCL_VAT",
    80: "PAID_AMOUNT",
    90: "DUE_AMOUNT",
    100: "COMMISSION",
    110: "COMMISSION_VAT",
    120: "TO_PAY",
    130: "STAY_ARTICLE",
    170: "WARRANT_TOTAL",
    190: "CANCEL_COMPENSATION",
    200: "INTERNAL_PRICE",
    250: "TAX",
    260: "TOTAL_EXCL_TAX",
    281: "CC_AUTHORIZATION_RESERVATION",
} as const;

export type BillSagaAction = GenerateBillAction | AdditionAction | AddOnsSagaAction | EmptyAction;

function* generateBill(action: BillSagaAction) {
    const generateBillActionParams: GenerateBillActionParams = { ...action.payload };
    if ("targetBill" in action && action.targetBill) {
        generateBillActionParams.targetBill = action.targetBill;
    }

    const updatedAddOn: SelectedAddOn | undefined = (action as AddOnsSagaAction)?.payload?.updatedAddOn;
    if (updatedAddOn) {
        BillSagaDataManager.pushUpdatedAddOn(updatedAddOn);
    }

    const { specialSearchCode, removeSpecialCode, env: payloadEnv, reservationId: payloadReservationId } = generateBillActionParams;
    const taskExecutionManager = new TaskExecutionManager(generateBillActionParams.targetBill || "mainBill");
    yield call(dispatchBillAction, BillActionType.fetching, generateBillActionParams);

    try {
        if (specialSearchCode || removeSpecialCode) {
            yield call(dispatchSpecialSearchAction, SpecialSearchActionType.loading, generateBillActionParams);
        }

        const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
        const env: ApiCallOptions | undefined = payloadEnv || dynamicFilter.currentLocale ? yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale) : undefined;

        if (!env) {
            throw new Error("Cannot generate bill without providing the env");
        }
        const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
        const reservationId: number | undefined = dynamicFilter.reservationId || myEnvState.selectedReservationId || payloadReservationId;
        let choiceResult: ChoiceResult;
        if (reservationId) {
            const billCalculationParams: { reservationId: number; env: ApiCallOptions } = { reservationId, env };
            choiceResult = yield call(generateBillForReservation, {
                action,
                generateBillActionParams,
                reservationBillCalculationParams: billCalculationParams,
                updatedAddOns: BillSagaDataManager.getUpdatedAddOns(),
            });
        } else {
            choiceResult = yield call(generateBillWithoutReservation, generateBillActionParams);
        }

        if (taskExecutionManager.isLatestTask()) {
            if (action.type === AddOnsSagaActionType.START_UPDATE_SELECTED_ADD_ONS_SAGA || action.type === AddOnsSagaActionType.START_CLEAR_SELECTED_ADD_ONS_SAGA) {
                yield call(updateSelectedAddOnsInRedux, { action: action as AddOnsSagaAction, success: true });
            }
            yield call(updateBillAndSpecialSearchState, generateBillActionParams, choiceResult, specialSearchCode, removeSpecialCode);
            BillSagaDataManager.clearUpdatedAddOns();
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        if (taskExecutionManager.isLatestTask()) {
            if (specialSearchCode) {
                yield call(dispatchSpecialSearchAction, SpecialSearchActionType.error, generateBillActionParams, specialSearchCode);
            }
            if (action.type === AddOnsSagaActionType.START_UPDATE_SELECTED_ADD_ONS_SAGA || action.type === AddOnsSagaActionType.START_CLEAR_SELECTED_ADD_ONS_SAGA) {
                yield call(updateSelectedAddOnsInRedux, { action: action as AddOnsSagaAction, success: false });
            }
            yield call(dispatchBillAction, BillActionType.error, generateBillActionParams, { error });
            BillSagaDataManager.clearUpdatedAddOns();
        }
    }
}

function* generateBillWithoutReservation(generateBillActionParams: GenerateBillActionParams) {
    const { accommodationType, unit, applicableRateType, specialSearchCode, removeSpecialCode, env: payloadEnv } = generateBillActionParams;
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const instalmentsState: InstalmentsState = yield select(SagaUtils.getInstalmentsState);
    const env: ApiCallOptions = payloadEnv || (yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale));
    const additionState: AdditionState = yield select(SagaUtils.getAdditionState);
    const billState: BillState = yield select(SagaUtils.getBillState);
    const specialSearchState: SpecialSearchState = yield select(SagaUtils.getSpecialSearchState);

    const selectedAddOnsState: SelectedAddOnsState = yield select(SagaUtils.getSelectedAddOnsState);
    const selectedAddOns: SelectedAddOn[] = getSelectedAddOnsInsideCart({ selectedAddOnsState });
    const apiContext = globalApiContext();

    const choiceResult: ChoiceResult = yield call(BillUtil.generateBillByDynamicFilter, {
        instalmentsState,
        dynamicFilter,
        additionState,
        selectedAddOns,
        billChoice: billState.mainBill || getEmptyBillChoice(),
        options: { unit, accommodationType, applicableRateTypeId: applicableRateType as number, specialSearchCode: specialSearchCode || specialSearchState.appliedSearchCode, removeSpecialCode },
        env,
        updatedAddOn: generateBillActionParams.updatedAddOn,
        apiContext,
    });
    return choiceResult;
}

function* generateBillForReservation({
    action,
    generateBillActionParams,
    reservationBillCalculationParams,
    updatedAddOns,
}: {
    action: BillSagaAction;
    generateBillActionParams: GenerateBillActionParams;
    reservationBillCalculationParams: { reservationId: number; env: ApiCallOptions };
    updatedAddOns: SelectedAddOn[];
}) {
    const { reservationId, env } = reservationBillCalculationParams;
    const { targetBill, bypassRecalculation } = generateBillActionParams;

    const reservationDetail: ReservationResult = yield call(MxtsApi.getReservation, env, {}, [{ key: "reservationId", value: reservationId }]);
    const reservationDate = moment(reservationDetail.reservationDate);
    const reservationDateDiffInMinutes = moment().diff(reservationDate, "minutes");
    // If reservation creation time is over 30 minutes then do not refresh the bill
    const isRefreshBillTimeExceeded = reservationDateDiffInMinutes > 30;

    let choiceResult: ChoiceResult = {};
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);

    if (reservationDetail.type === "INVOICE" || (isRefreshBillTimeExceeded && !myEnvState.selectedReservation) || bypassRecalculation) {
        const reservationBills: ReservationBill[] = ((yield call(MxtsApi.getReservationBill, env, { size: 2000 }, [
            {
                key: "reservationId",
                value: reservationId,
            },
        ])) as PagedResult<ReservationBill>).content;

        const reservationBillLines: BillLine[] = reservationBills.map((reservationBill) => {
            const bill = { ...reservationBill };
            bill.billLineType = BillLineTypeInNumber[reservationBill.billLineType];
            return (bill as unknown) as BillLine;
        });

        const reservationAgentBillLines = reservationBillLines.filter((item) => item.payerType === "AGENT");

        const billChoice: Choice[] = [
            {
                reservedResources: [],
                customerBill: reservationBillLines,
                payingCustomerBill: [],
                agentBill: reservationAgentBillLines,
                billBalance: [],
                exception: undefined,
            },
        ];
        choiceResult = { choices: billChoice };
    } else {
        choiceResult = yield call(MxtsApi.refreshReservationBill, env, { task: PriceTask.RECALCULATE }, [
            {
                key: "reservationId",
                value: reservationId,
            },
        ]);
    }

    const choice: Choice | undefined = choiceResult.choices?.length ? choiceResult.choices[0] : undefined;

    if (targetBill === "myEnvAdditionsBill" && choice) {
        try {
            if (action.type === AddOnsSagaActionType.START_UPDATE_SELECTED_ADD_ONS_SAGA || action.type === AddOnsSagaActionType.START_CLEAR_SELECTED_ADD_ONS_SAGA) {
                const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
                const billChoice: BillChoice = convertChoiceToBillChoice(choice, choiceResult);
                const rateTypeId = dynamicFilter.rateType?.rateTypeId || billChoice.reservedResources?.[0]?.rateTypeId;
                const { selectedAccoReservedResourceId } = getFlattenedMyEnvData(myEnvState, dynamicFilter);
                const selectedAddOnsState: SelectedAddOnsState = yield select(SagaUtils.getSelectedAddOnsState);
                const selectedAddOns = getSelectedAddOnsInsideCart({ selectedAddOnsState, cartReservedResourceId: selectedAccoReservedResourceId });
                const clearAddOns = action.type === AddOnsSagaActionType.START_CLEAR_SELECTED_ADD_ONS_SAGA;

                return (yield call(refreshBillIncludingSelectedAddOns, { myEnvState, updatedAddOns, billChoice, rateTypeId, env, selectedAddOns, clearAddOns, dynamicFilter })) as ChoiceResult;
            }
        } catch (err) {
            // The error is already logged to cloud logging inside the getBillIncludingAdditions method. So we use console.error here
            // eslint-disable-next-line no-console
            console.error("Failed to refresh the additions bill with the selected additions due to an error:", err);
            return { choices: [] };
        }
    }
    if (reservationDetail.kind === ReservationKind.ADDON_RESERVATION && choiceResult.choices?.length) {
        const customerBill = choiceResult.choices[0].customerBill;
        if (customerBill?.length) {
            const filteredCustomerBill = customerBill.filter((billLine) => !(billLine.resourceType === ChoiceResourceType.RESOURCEACTIVITY && !(billLine as any).mainBill));
            choiceResult.choices[0].customerBill = filteredCustomerBill;
        }
        return choiceResult;
    }
    return choiceResult;
}

/**
 * Update the BillState according to the response of the price engine.
 */
function* updateBillAndSpecialSearchState(generateBillActionParams: GenerateBillActionParams, choiceResult: ChoiceResult, specialSearchCode?: string, removeSpecialCode?: boolean) {
    if (choiceResult) {
        /**
         * @todo -  Right now we only get one choice back from the PriceEngine, because we always ask for the CHEAPEST
         *   option. In the future we need to be able to get multiple different options back and compare them on
         *   certain aspects. Hence, keeping the array of Choices here, but needs further investigation later on.
         */
        if (!isEmpty(choiceResult.choices)) {
            const { choices } = choiceResult;
            const bill: BillLine[] = choices?.length ? choices[0].customerBill : [];
            const billBalance: BillBalance[] = choices?.length ? choices[0].billBalance || [] : [];
            const exception: PriceEngineException | null = choices?.[0].exception || null;
            const agentBill: BillLine[] = choices?.length && choices[0].agentBill ? choices[0].agentBill : [];

            if (exception) {
                throw new Error(`Price engine exception: ${JSON.stringify(exception)}`);
            }

            yield call(dispatchBillAction, BillActionType.fetched, generateBillActionParams, {
                reservedResources: choices?.[0].reservedResources,
                customerBill: bill,
                agentBill,
                billBalance,
                preBooking: choiceResult.preBooking,
            } as BillChoice);

            if (specialSearchCode) {
                yield call(dispatchSpecialSearchAction, SpecialSearchActionType.fetched, generateBillActionParams, specialSearchCode);
            }

            if (removeSpecialCode) {
                yield call(dispatchSpecialSearchAction, SpecialSearchActionType.removed, generateBillActionParams);
            }
        } else if (specialSearchCode) {
            yield call(dispatchBillAction, BillActionType.specialCodeNotFound, generateBillActionParams);
            yield call(dispatchSpecialSearchAction, SpecialSearchActionType.error, generateBillActionParams, specialSearchCode);
        } else {
            throw new Error("No choices returned from PriceEngine");
        }
    }
}

function* updateSelectedAddOnsInRedux({ action, success }: { action: AddOnsSagaAction; success: boolean }) {
    const { cmsContext, cartReservedResourceId, targetBill } = action;

    if (action.type === AddOnsSagaActionType.START_CLEAR_SELECTED_ADD_ONS_SAGA && success) {
        yield put<SelectedAddOnsAction>(clearSelectedAddOns({ cartReservedResourceId, targetBill }));
    }

    const selectedAddOnsState: SelectedAddOnsState = yield select(SagaUtils.getSelectedAddOnsState);
    const updatedAddOns: SelectedAddOn[] = BillSagaDataManager.getUpdatedAddOns();

    for (const updatedAddOn of updatedAddOns) {
        const selectedAddOn: SelectedAddOn | undefined = getSelectedAddOnsInsideCart({ selectedAddOnsState, cartReservedResourceId }).find(
            (addOn: SelectedAddOn) => addOn.resourceId === updatedAddOn?.resourceId
        );

        if (action.type === AddOnsSagaActionType.START_UPDATE_SELECTED_ADD_ONS_SAGA) {
            if (success) {
                yield put(updateSelectedAddOns({ updatedAddOn, cartReservedResourceId, targetBill }));
            }
            if (cmsContext) {
                createAddOnNotification({ cmsContext, updatedAddOn, selectedAddOn, success });
            }
        }
    }
}

function* dispatchBillAction(billActionType: BillActionType, generateBillActionParams: GenerateBillActionParams, payload: Partial<BillChoice> | undefined = undefined) {
    const action: BillAction = {
        type: ActionType.Bill,
        actionType: billActionType,
        targetBill: generateBillActionParams.targetBill,
        payload,
    };
    yield put(action);
}

function* dispatchSpecialSearchAction(specialActionType: SpecialSearchActionType, generateBillActionParams: GenerateBillActionParams, specialSearchCode: string | undefined = undefined) {
    const action: SpecialSearchAction = {
        type: ActionType.SpecialSearch,
        actionType: specialActionType,
        targetBill: generateBillActionParams.targetBill,
        payload: {
            lastSearchCode: specialSearchCode,
        },
    };
    yield put(action);
}

export function* watchBillRecalculation() {
    yield takeEvery(
        [
            ActionType.ReservationUpdated,
            ActionType.ChoiceAndPrice,
            ActionType.Addition,
            AddOnsSagaActionType.START_UPDATE_SELECTED_ADD_ONS_SAGA,
            AddOnsSagaActionType.START_CLEAR_SELECTED_ADD_ONS_SAGA,
        ],
        generateBill
    );
}
