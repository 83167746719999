import * as MXTS from "@maxxton/cms-mxts-api";

import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";
import { Unit, getAdminMxtsEnv } from "../plugins/mxts";
import { isArray, map } from "lodash";

import { ContentType } from "../components/components.enum";
import { InputSpecAsyncSelect } from "../form-specs";
import { MXTS as MXTS_CONSTANTS } from "../utils/constants";
import { SelectOption } from "../form-specs/formSpec.types";
import { UNIT } from "../components/utils";
import namespaceList from "../i18n/namespaceList";

async function getUnitOptions(mxtsApi: MXTS.MxtsApiWrapper, searchKeyword?: string): Promise<Array<SelectOption<number | null>>> {
    const env = await getAdminMxtsEnv();
    const units = await mxtsApi.units(env, { size: MXTS_CONSTANTS.MAX_RESULTS, search: searchKeyword }).then((pagedResult: MXTS.PagedResult<Unit>) => pagedResult.content);
    const selectOptions: Array<SelectOption<number | null>> = map(
        units,
        (unit: MXTS.Unit): SelectOption<number> => {
            const { unitId, name } = unit;
            return {
                value: unitId,
                label: name,
            };
        }
    );
    return selectOptions;
}

function unitsAutocomplete<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecAsyncSelect<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "unitId"),
        type: "asyncSelect",
        isClearable: true,
        async optionList(item, searchKeyword): Promise<any[]> {
            return getUnitOptions(MXTS.MxtsApi, searchKeyword);
        },
        isMulti: true,
        placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
        visible: (options: any) => {
            const { contentType, contentTypes, preFilteredAvailability } = options;
            if (isArray(contentTypes)) {
                let selected = false;
                contentTypes.forEach((contentType: any) => {
                    if (contentType.value === ContentType.UNIT) {
                        selected = true;
                    }
                });
                return selected && !preFilteredAvailability;
            }
            return contentType === UNIT && !preFilteredAvailability;
        },
    };
}

export default unitsAutocomplete;
