import { JwtToken, MyEnvAuthTokenPayload } from "@maxxton/cms-mxts-api";
import { onUserNotLoggedIn, refreshMyEnvAuthToken } from "../authToken.util";

import { MS } from "../constants";

abstract class AuthTokenRefresher<T> {
    private refreshTimeout: ReturnType<typeof setTimeout> | undefined;

    public setCurrentToken(jwtToken?: JwtToken<T> | undefined) {
        const exp: number | undefined = (jwtToken?.payload as any)?.exp;
        if (exp) {
            if (this.refreshTimeout) {
                clearTimeout(this.refreshTimeout);
            }

            const tokenExpireDate = new Date(exp * 1000);
            const currentDate = new Date();
            // Refresh the token 30 seconds before it expires
            const refreshCountdownMs = Math.abs(tokenExpireDate.getTime() - currentDate.getTime() - MS.THIRTY_SECONDS);
            this.refreshTimeout = setTimeout(async () => {
                const refreshTokenResult: { refreshSucceeded: boolean } = await this.refreshAuthToken();
                if (!refreshTokenResult.refreshSucceeded) {
                    this.onRefreshFailed();
                }
            }, refreshCountdownMs);
        }
    }

    protected abstract refreshAuthToken(): Promise<{ refreshSucceeded: boolean }>;
    protected abstract onRefreshFailed(): void;
}

export class MyEnvAuthTokenRefresher extends AuthTokenRefresher<MyEnvAuthTokenPayload> {
    protected async refreshAuthToken(): Promise<{ refreshSucceeded: boolean }> {
        return { refreshSucceeded: !!(await refreshMyEnvAuthToken())?.token };
    }

    protected onRefreshFailed(): void {
        onUserNotLoggedIn();
    }
}
