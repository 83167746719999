import { Action, ActionType } from "../";

import { BillTargetAction } from "../billAction";
import { BillType } from "../../reducers/billReducer";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { SelectedAddOn } from "../../reducers/add-ons/selectedAddOnsReducer";

export enum SelectedAddOnsActionType {
    CLEAR_SELECTED_ADD_ONS = "CLEAR_SELECTED_ADD_ONS",
    UPDATE_SELECTED_ADD_ONS = "UPDATE_SELECTED_ADD_ONS",
    RESET_SELECTED_ADD_ONS = "RESET_SELECTED_ADD_ONS",
}

export interface SelectedAddOnsAction extends Action, BillTargetAction {
    type: ActionType.SelectedAddOns;
    actionType: SelectedAddOnsActionType;
    payload?: SelectedAddOnsActionPayload;
    cartReservedResourceId: number | undefined;
    cmsContext?: CMSProviderProperties;
}

export interface ResetSelectedAddOnsAction {
    type: ActionType.SelectedAddOns;
    actionType: SelectedAddOnsActionType;
}
export interface SelectedAddOnsActionPayload {
    updatedAddOn?: SelectedAddOn;
}

export const clearSelectedAddOns = ({ targetBill, cartReservedResourceId }: { targetBill?: BillType; cartReservedResourceId?: number }): SelectedAddOnsAction => ({
    type: ActionType.SelectedAddOns,
    actionType: SelectedAddOnsActionType.CLEAR_SELECTED_ADD_ONS,
    targetBill,
    cartReservedResourceId,
});

export const updateSelectedAddOns = ({
    updatedAddOn,
    cartReservedResourceId,
    targetBill,
}: {
    updatedAddOn: SelectedAddOn;
    cartReservedResourceId?: number;
    targetBill?: BillType;
}): SelectedAddOnsAction => ({
    type: ActionType.SelectedAddOns,
    actionType: SelectedAddOnsActionType.UPDATE_SELECTED_ADD_ONS,
    targetBill,
    cartReservedResourceId,
    payload: { updatedAddOn },
});

export enum TempSelectedAddOnsActionType {
    UPDATE_TEMP_SELECTED_ADD_ONS = "UPDATE_TEMP_SELECTED_ADD_ONS",
}

export interface TempSelectedAddOnsAction extends Action {
    type: ActionType.TempSelectedAddOns;
    actionType: TempSelectedAddOnsActionType.UPDATE_TEMP_SELECTED_ADD_ONS;
    payload?: SelectedAddOnsActionPayload;
    cartReservedResourceId: number | undefined;
}

export const updateTempSelectedAddOns = ({ updatedAddOn, cartReservedResourceId }: { updatedAddOn: SelectedAddOn; cartReservedResourceId?: number }): TempSelectedAddOnsAction => ({
    type: ActionType.TempSelectedAddOns,
    actionType: TempSelectedAddOnsActionType.UPDATE_TEMP_SELECTED_ADD_ONS,
    cartReservedResourceId,
    payload: { updatedAddOn },
});

// This action can be used to sync the tempSelectedAddOns with the selectedAddOns on a page refresh.
// This makes sure the different quantity forms are populated with the already selected quantities in Redux when the page is refreshed.
export enum SyncTempSelectedAddOnsActionType {
    SYNC_TEMP_SELECTED_ADD_ONS = "SYNC_TEMP_SELECTED_ADD_ONS",
}

export interface SyncTempSelectedAddOnsAction extends Action {
    type: ActionType.SyncTempSelectedAddOns;
    actionType: SyncTempSelectedAddOnsActionType;
    cartReservedResourceId: number | undefined;
    payload: SelectedAddOn[];
}

export const syncTempSelectedAddOns = (selectedAddOns: SelectedAddOn[], cartReservedResourceId: number | undefined): SyncTempSelectedAddOnsAction => ({
    type: ActionType.SyncTempSelectedAddOns,
    actionType: SyncTempSelectedAddOnsActionType.SYNC_TEMP_SELECTED_ADD_ONS,
    cartReservedResourceId,
    payload: [...selectedAddOns],
});
