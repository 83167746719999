import * as MXTS from "@maxxton/cms-mxts-api";
import * as slugify from "slugify";

import { I18nImage, Image, ImageGroup, ImageGroupAndContent, ImagePresets, ImageProvider, ImageSpec, ImageSpecOptions, LocalizedImageSpecOptions, imageSpec } from "../../media";

import { ApiContext } from "../../containers/cmsProvider.types";
import { ImageType } from "../../inputSpecs/imageTypesMultiSelect";
import { getDefaultFallBackImageUrls } from "../../components/media/mxts-image-gallery/mxts";
import { getMxtsEnv } from "./";
import { loadLanguageMapping } from "../../i18n";

interface CloudinaryImage {
    baseUrl: string;
    id: string;
    name?: string;
    preset?: string | null;
    width?: number;
    height?: number;
    xPlacement?: number;
    yPlacement?: number;
    quality?: string | null;
    setBoundingBox?: boolean;
    boxWidth?: number;
    boxHeight?: number;

    extension: string;
    version?: string;
}

interface Preset {
    name: string;
    value: string | null;
}

export const PRESETS: Preset[] = [
    { name: "none", value: "t_mcms_larger" },
    { name: "small", value: "t_mcms_small" },
    { name: "normal", value: "t_mcms_normal" },
    { name: "medium", value: "t_mcms_medium" },
    { name: "medium_square", value: "t_mcms_medium_square" },
    { name: "medium_face", value: "t_mcms_medium_face" },
    { name: "large", value: "t_mcms_larger" },
    { name: "image_fill", value: "t_mcms_larger_fill_ratio" },
    { name: "desktop", value: "t_mcms_desktop" },
    { name: "original", value: "t_mcms_original" },
    { name: "favicon", value: "t_mcms_favicon" },
];

function parseCloudinaryUrl(url: string): CloudinaryImage {
    const cloudinaryRegex = /([^/\s]+:\/\/[^/]+\/[^/]+)[^\s]+\/(t_[^/]+)\/(?:(v[0-9]+)\/)?([^./]+)(?:\/([^.]+))?.([^\s]+)/i;
    const match = cloudinaryRegex.exec(url);
    if (match === null) {
        throw new Error(`Failed to parse cloudinary url: ${url}`);
    }
    const [, baseUrl, preset, version, id, name, extension] = match;
    return { baseUrl, preset, version, id, name, extension };
}

function renderCloudinaryUrl({ baseUrl, id, preset, width, height, version, name, quality, xPlacement, yPlacement, setBoundingBox, boxWidth, boxHeight, extension }: CloudinaryImage): string {
    let url = baseUrl;
    const defaultAutoOption = "f_auto";
    if (name) {
        if (url?.includes("/image")) {
            url += "s/";
        } else {
            url += "/images/";
        }
    } else {
        if (url && url.indexOf("/image") > -1) {
            url += "/upload/";
        } else {
            url += "/image/upload/";
        }
    }
    if (width && height) {
        url += `w_${width},h_${height}/`;
        if (setBoundingBox) {
            url += `/w_${width},h_${height},c_crop,g_xy_center,x_${xPlacement},y_${yPlacement}`;
        }
    } else if (preset) {
        url += preset + "/";
    }
    if (quality) {
        url += "";
    }
    url += `${defaultAutoOption}`;
    if (version) {
        url += `/${version}`;
    }
    url += `/${id}`;
    if (name) {
        const slug = slugify(name);
        url += `/${slug}`;
    }
    url += `.${extension}`;
    return url;
}

// eslint-disable-next-line max-lines-per-function
export function mapMXTSImage(apiContext: ApiContext, mxtsImage: MXTS.Image): Image {
    const cloudinaryImage: CloudinaryImage = (mxtsImage?.urls && parseCloudinaryUrl(mxtsImage.urls?.original)) || mxtsImage?.url;
    const presets: ImagePresets = {};
    PRESETS.forEach((preset) => {
        presets[preset.name] = {
            url: renderCloudinaryUrl({ ...cloudinaryImage, preset: preset.value }),
        };
    });
    const img: Image = {
        caption: mxtsImage?.caption || "",
        url: mxtsImage?.url || "",
        mediaType: mxtsImage?.mediaType || "",
        imageType: mxtsImage?.imageType || "",
        id: `${mxtsImage?.imageId}`,
        name: mxtsImage?.name || mxtsImage?.fileName,
        originalUrl: mxtsImage?.urls?.original || mxtsImage?.url,
        thumbnailUrl: mxtsImage && ([`${ImageType.VIDEO}`, `${ImageType.VIDEO360}`].includes(mxtsImage.mediaType) ? mxtsImage.thumbnailUrl : mxtsImage?.urls?.small),
        presets,
        namePath: mxtsImage?.namePath,
        loadGroup: () => loadGroup(apiContext, mxtsImage && mxtsImage.mediaGroupId),
        toSpec(options: ImageSpecOptions): ImageSpec {
            return imageSpec(imageProvider, img, options);
        },
        renderUrl(spec: ImageSpec, localeOptions: LocalizedImageSpecOptions, innerWidth?: number): string {
            const { preset: presetName, width, height, quality, xPlacement, yPlacement, setBoundingBox, boxWidth, boxHeight } = spec.options;
            const { friendlyName } = localeOptions;
            const preset = PRESETS.find((pr) => pr.name === presetName);
            if (preset === undefined && (width || height || quality || xPlacement || yPlacement || setBoundingBox || boxWidth || boxHeight)) {
                // eslint-disable-next-line no-console
                console.info(`Unable to find preset: ${presetName}`);
            }
            let urlString: string = renderCloudinaryUrl({
                ...cloudinaryImage,
                preset: preset ? preset.value : undefined,
                width,
                height,
                quality,
                xPlacement,
                yPlacement,
                setBoundingBox,
                boxWidth,
                boxHeight,
                name: friendlyName,
            });
            if (innerWidth) {
                const preset1 = presetHandler(preset!.value); // TODO change this
                if (innerWidth > 320 && innerWidth < 991) {
                    urlString = urlString.replace("t_mcms_" + preset1, "t_mcms_medium");
                } else if (innerWidth > 992) {
                    urlString = urlString.replace("t_mcms_" + preset1, "t_mcms_larger");
                } else {
                    return urlString;
                }
            }
            return urlString;
        },
        renderArrayUrl(
            presetName: string,
            width?: number,
            height?: number,
            localeOptions?: LocalizedImageSpecOptions,
            quality?: string,
            xPlacement?: number,
            yPlacement?: number,
            setBoundingBox?: boolean,
            boxWidth?: number,
            boxHeight?: number,
            innerWidth?: number
        ): string {
            const friendlyName = localeOptions?.friendlyName || "";
            const preset = PRESETS.find((pr) => pr.name === presetName);
            if (preset === undefined && (width || height || quality || xPlacement || yPlacement || setBoundingBox || boxWidth || boxHeight)) {
                // eslint-disable-next-line no-console
                console.info(`Unable to find preset: ${presetName}`);
            }
            let urlString: string = renderCloudinaryUrl({
                ...cloudinaryImage,
                preset: preset ? preset.value : undefined,
                width,
                height,
                quality,
                xPlacement,
                yPlacement,
                setBoundingBox,
                boxWidth,
                boxHeight,
                name: friendlyName,
            });
            if (innerWidth) {
                const preset1 = presetHandler(preset!.value); // TODO change this
                if (innerWidth > 320 && innerWidth < 991) {
                    urlString = urlString.replace("t_mcms_" + preset1, "t_mcms_medium");
                } else if (innerWidth > 992) {
                    urlString = urlString.replace("t_mcms_" + preset1, "t_mcms_larger");
                } else {
                    return urlString;
                }
            }
            return urlString;
        },
        async loadTranslations(apiContext: ApiContext): Promise<I18nImage[]> {
            const ops = await getMxtsEnv(apiContext);
            const [translations, languageMapping] = await Promise.all([
                apiContext?.mxtsApi.i18nImage(ops, {}, [{ key: "imageId", value: mxtsImage.imageId }]),
                loadLanguageMapping(apiContext?.mxtsApi, ops),
            ]);
            return translations
                ?.map((translation): I18nImage | undefined => {
                    if (translation.languageId in languageMapping) {
                        return {
                            locale: languageMapping[translation.languageId].locale._id,
                            name: translation.name || "",
                            description: translation.description || "",
                            alt: translation.caption || "",
                        };
                    }
                    return undefined;
                })
                .filter((mapped): mapped is I18nImage => mapped !== undefined);
        },
    };
    return img;
}

async function loadRoot(apiContext: ApiContext): Promise<ImageGroupAndContent> {
    const ops = await getMxtsEnv(apiContext);
    const [rootImages, rootGroups] = await Promise.all([apiContext?.mxtsApi.images(ops, { mediaGroupId: null, size: 1000 }), apiContext?.mxtsApi.mediaGroups(ops, { parentId: null, size: 1000 })]);
    const content = rootImages.content.map((c) => mapMXTSImage(apiContext, c));
    const contents: Image[] = content.filter((image: Image) => image && image.id !== "undefined");
    const root: ImageGroupAndContent = {
        name: "root",
        content: contents,
        subgroups: rootGroups.content.map((c) => mapMXTSImageGroup(apiContext, c)),
        loadContent: () => Promise.resolve(root),
        loadParent: () => Promise.resolve(null),
        loadParentWithContent: () => Promise.resolve(null),
    };
    return root;
}

async function loadGroup(apiContext: ApiContext, id: number): Promise<ImageGroupAndContent> {
    const ops = await getMxtsEnv(apiContext);
    const [group, images, groups] = await Promise.all([
        apiContext.mxtsApi.mediaGroupById(ops, {}, [
            {
                key: "mediaGroupId",
                value: id,
            },
        ]),
        apiContext.mxtsApi.images(ops, { mediaGroupId: id, size: 1000 }),
        apiContext.mxtsApi.mediaGroups(ops, { parentId: id, size: 1000 }),
    ]);
    const loadParentWithContent = () => (group.parentId ? loadGroup(apiContext, group.parentId) : loadRoot(apiContext));
    const content = images.content.map((i) => mapMXTSImage(apiContext, i));
    const groupAndContent = {
        name: group.name,
        subgroups: groups.content.map((g) => mapMXTSImageGroup(apiContext, g)),
        content: content.filter((image: Image) => image && image.id !== "undefined"),
        loadContent: () => Promise.resolve(groupAndContent),
        loadParent: loadParentWithContent, // TODO: could be more efficient
        loadParentWithContent,
    };
    return groupAndContent;
}

function mapMXTSImageGroup(apiContext: ApiContext, mediaGroup: MXTS.MediaGroup): ImageGroup {
    const loadParentWithContent = () => (mediaGroup.parentId ? loadGroup(apiContext, mediaGroup.parentId) : loadRoot(apiContext));
    const group = {
        name: mediaGroup.name,
        groupId: mediaGroup.mediaGroupId,
        async loadContent(): Promise<ImageGroupAndContent> {
            const ops = await getMxtsEnv(apiContext);
            const [images, groups] = await Promise.all([
                apiContext.mxtsApi.images(ops, { mediaGroupId: mediaGroup.mediaGroupId, size: 1000 }),
                apiContext.mxtsApi.mediaGroups(ops, { parentId: mediaGroup.mediaGroupId, size: 1000 }),
            ]);
            const contentImages = images.content.map((i) => mapMXTSImage(apiContext, i));
            const groupAndContent = {
                ...group,
                subgroups: groups.content.map((g) => mapMXTSImageGroup(apiContext, g)),
                content: contentImages.filter((image: Image) => image && image.id !== "undefined"),
                loadContent: () => Promise.resolve(groupAndContent),
            };
            return groupAndContent;
        },
        loadParent: loadParentWithContent, // TODO: could be more efficient
        loadParentWithContent,
    };
    return group;
}

function getFallbackImage(imageId: string) {
    return {
        imageId,
        urls: getDefaultFallBackImageUrls(),
        name: "FallackImage",
        mediaGroupId: null,
    };
}

export const imageProvider: ImageProvider = {
    id: "mxts",
    async images(apiContext: ApiContext): Promise<ImageGroupAndContent> {
        return loadRoot(apiContext);
    },
    async imageBySpec(apiContext: ApiContext, spec: ImageSpec): Promise<Image> {
        const ops = await getMxtsEnv(apiContext);
        let mxtsImage: any;
        // Fallback images have empty string as their Id, so adding the check here
        if (!mxtsImage) {
            if (spec.imageId !== "undefined" && spec.imageId) {
                await apiContext.mxtsApi.imageById(ops, { imageId: parseInt(spec.imageId, 10) }).then(
                    (response) => {
                        mxtsImage = response;
                    },
                    (error) => {
                        // eslint-disable-next-line no-console
                        console.log("Failed to load images ", error);
                        mxtsImage = getFallbackImage(spec.imageId);
                    }
                );
            } else {
                mxtsImage = getFallbackImage(spec.imageId);
            }
        }
        const image: Image = mapMXTSImage(apiContext, mxtsImage);
        return image;
    },
    async imagesBySpec(apiContext: ApiContext, specs: ImageSpec[]): Promise<Image[]> {
        const ops = await getMxtsEnv(apiContext);
        let mxtsImages: any[] = [];
        let preservedOrderImages: any[] = [];
        const imageIds = specs.map((spec) => spec.imageId);
        if (mxtsImages.length === 0 || imageIds.length !== mxtsImages.length) {
            mxtsImages = [];
            for (let index = 0; index < specs.length; index += 20) {
                let imgSpecs = specs.slice(index, index + 20);
                imgSpecs = imgSpecs.filter((spec) => Number(spec.imageId));
                await apiContext.mxtsApi.images(ops, { imageIds: imgSpecs.map((spec) => +spec.imageId) }).then(
                    (response) => {
                        mxtsImages = mxtsImages.concat(response.content);
                    },
                    (error) => {
                        // eslint-disable-next-line no-console
                        console.log("Failed to load images ", error);
                        mxtsImages.push(getFallbackImage("fallback"));
                    }
                );
            }
        }

        for (const spec of specs) {
            preservedOrderImages.push({ ...mxtsImages.find((image) => image.imageId === parseInt(spec.imageId, 10)), namePath: spec.namePath });
        }
        preservedOrderImages = preservedOrderImages.map((mxtsImage) => mapMXTSImage(apiContext, mxtsImage));
        return preservedOrderImages.filter((image: Image) => image && image.id !== "undefined");
    },
    mapToCloudinary(spec: ImageSpec) {
        const url = spec.originalUrl;
        const img = {
            id: spec.imageId,
            url,
        };

        return mapCloudinaryImage(img);
    },
};

function mapCloudinaryImage(img: any) {
    const cloudinaryImage: CloudinaryImage = parseCloudinaryUrl(img.url);
    const presets: ImagePresets = {};
    PRESETS.forEach((preset) => {
        presets[preset.name] = {
            url: renderCloudinaryUrl({ ...cloudinaryImage, preset: preset.value }),
        };
    });
    const image: any = {
        id: `${img.id}`,
        originalUrl: presets.original,
        thumbnailUrl: img.mediaType === "VIDEO" ? img.thumbnailUrl : presets.small,
        presets,
        renderUrl(spec: ImageSpec, localeOptions: LocalizedImageSpecOptions): string {
            const { preset: presetName, width, height, quality, xPlacement, yPlacement, setBoundingBox, boxWidth, boxHeight } = spec.options;
            const { friendlyName } = localeOptions;
            const preset = PRESETS.find((pr) => pr.name === presetName);
            if (preset === undefined && (width || height || quality || xPlacement || yPlacement || setBoundingBox || boxWidth || boxHeight)) {
                // eslint-disable-next-line no-console
                console.info(`Unable to find preset: ${presetName}`);
            }
            return renderCloudinaryUrl({
                ...cloudinaryImage,
                preset: preset ? preset.value : undefined,
                width,
                height,
                quality,
                xPlacement,
                yPlacement,
                setBoundingBox,
                boxWidth,
                boxHeight,
                name: friendlyName,
            });
        },
        renderArrayUrl(
            presetName: string,
            width?: number,
            height?: number,
            localeOptions?: LocalizedImageSpecOptions,
            quality?: string,
            xPlacement?: number,
            yPlacement?: number,
            setBoundingBox?: boolean,
            boxWidth?: number,
            boxHeight?: number
        ): string {
            const friendlyName = localeOptions?.friendlyName || "";
            const preset = PRESETS.find((pr) => pr.name === presetName);
            if (preset === undefined && (width || height || quality || xPlacement || yPlacement || setBoundingBox || boxWidth || boxHeight)) {
                // eslint-disable-next-line no-console
                console.info(`Unable to find preset: ${presetName}`);
            }
            return renderCloudinaryUrl({
                ...cloudinaryImage,
                preset: preset ? preset.value : undefined,
                width,
                height,
                quality,
                xPlacement,
                yPlacement,
                setBoundingBox,
                boxWidth,
                boxHeight,
                name: friendlyName,
            });
        },
    };

    return image;
}

function presetHandler(specImage: string | null) {
    let preset = "";
    switch (specImage) {
        case "none":
        case "original":
            preset = "original";
            break;
        default:
            preset = specImage ? specImage : "";
    }
    return preset;
}
