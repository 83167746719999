import { ApiCallOptions, MxtsApiWrapper, StayPeriodDef } from "@maxxton/cms-mxts-api";

import { AvailabilityResultWithFilter } from "../../redux/reducers/availability.types";
import { DATE_FORMAT } from "../../utils/constants";
import { DateMap } from "./mxts.types";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { Moment } from "moment";
import { SelectOption } from "../../form-specs/formSpec.types";

export function getStayPeriodDefs(stayPeriodDefs: StayPeriodDef[], availability: AvailabilityResultWithFilter): StayPeriodDef[] {
    let stays: StayPeriodDef[] = availability.response
        .stayPeriodDefs!.map((stayPeriodDefId) => {
            const stayPeriodDef = stayPeriodDefById(stayPeriodDefs, [stayPeriodDefId]);
            return stayPeriodDef;
        })
        .filter((stayPeriodDef) => stayPeriodDef !== null) as StayPeriodDef[];

    stays = stays.sort((stay1, stay2) => (stay1.priority > stay2.priority ? 1 : -1));
    return stays;
}

export function stayPeriodDefById(stayPeriodDefs: StayPeriodDef[], ids: number[]): StayPeriodDef | null {
    return ids && ids.length > 0 && stayPeriodDefs ? stayPeriodDefs.find((stayPeriodDef: StayPeriodDef) => ids.indexOf(stayPeriodDef.stayPeriodDefId) > -1) || null : null;
}

export async function getDefaultStayCode(env: ApiCallOptions, mxtsApi: MxtsApiWrapper, stayFilter: DynamicFilter, defaultStayValue?: number, stay?: string) {
    let stayCode;
    if (!defaultStayValue && stay) {
        stayCode = (await mxtsApi.stayPeriodDefs(env, { code: stay }).then((stay) => stay.content))[0]?.stayPeriodDefId || undefined;
    } else {
        stayFilter.stay = "";
    }
    const defaultStay = defaultStayValue || stayCode;
    return { defaultStay, stayFilter };
}

export function createDateMap(dates: Moment[]): DateMap {
    const map: DateMap = {};
    dates.forEach((date: Moment) => {
        map[date.format(DATE_FORMAT.DEFAULT)] = date;
    });
    return map;
}

export const getCountrySelectOptions = async (env: ApiCallOptions, mxtsApi: MxtsApiWrapper): Promise<Array<SelectOption<number>>> => {
    const countries: Array<SelectOption<number>> = (await mxtsApi.countries(env, { size: 1000 })).content.map((country) => ({
        label: country.longName,
        key: country.countryId,
        value: country.countryId,
        shortName: country.shortName,
        text: country.longName,
    }));
    return countries;
};
