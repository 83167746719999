import { Locale, LocaleApi } from "@maxxton/cms-api";

import { FormSpec } from "../";
import { StringMultiSelectOption } from "../../plugins/mxts/selectOption.types";
import { getI18nLocaleObject } from "../../i18n";
import { getLanguageLocale } from "../../plugins/mxts";
import { getMxtsLocaleOptions } from "../../utils/localizedContent.util";
import { getTimeStampOptions } from "../columnSpec.util";
import { localized } from "../utils";
import namespaceList from "../../i18n/namespaceList";

export const localeSpec: FormSpec<Locale> = {
    id: "locale",
    name: getI18nLocaleObject(namespaceList.admin, "locale"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "locales"),
    api: LocaleApi,
    permission: "webmanager.locales",
    display(locale: Locale): string {
        return locale.name;
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "code"), variable: "code", type: "text" },
        { name: getI18nLocaleObject(namespaceList.admin, "localeIsoCode"), variable: "isoCode", type: "text" },
        { name: getI18nLocaleObject(namespaceList.admin, "language"), variable: "localeName", type: "text" },
        { name: getI18nLocaleObject(namespaceList.admin, "default"), variable: "default", type: "checkbox" },
        ...getTimeStampOptions(),
    ],
    properties: [
        {
            label: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            type: "text",
            required: true,
        },
        {
            type: "paragraph",
            label: getI18nLocaleObject(namespaceList.admin, "localizedNameParagraph"),
        },
        localized({
            variable: "localizedName",
            tabContent: [
                {
                    variable: "name",
                    label: getI18nLocaleObject(namespaceList.admin, "localizedNames"),
                    type: "text",
                },
            ],
        }),
        {
            label: getI18nLocaleObject(namespaceList.admin, "localeName"),
            variable: "localeName",
            type: "text",
            required: true,
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "code"),
            variable: "code",
            type: "text",
            required: true,
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "infoLocaleNotSupportedByMxts"),
            type: "paragraph",
            visible: (item: Locale) => !!item.code && !getLanguageLocale(item.code),
        },
        {
            variable: "fallbackLocaleMultiSelect",
            label: getI18nLocaleObject(namespaceList.admin, "localeFallbackMultiSelect"),
            type: "multiselect",
            async optionList(): Promise<StringMultiSelectOption[]> {
                return getMxtsLocaleOptions();
            },
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "localeIsoCodeDescription"),
            type: "paragraph",
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "localeIsoCode"),
            variable: "isoCode",
            type: "text",
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "default"),
            variable: "default",
            type: "checkbox",
            required: false,
        },
    ],
};
