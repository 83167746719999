import { ApiCallOptions, ChoiceReservedResource, MemoCategoryResult, MemoRequest, MxtsApiWrapper, PAYER_TYPE, ReservationStatus, ReservedResourceWithChildren } from "@maxxton/cms-mxts-api";
import { ApiContext, CMSProvidedProperties } from "../containers/cmsProvider.types";

import { DateUtil } from "./date.util";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { MXTS } from "./constants";
import { formatReservationNumber } from "../components/utils";
import { getI18nLocaleString } from "../i18n";
import { getLocalizedContent } from "./localizedContent.util";
import namespaceList from "../i18n/namespaceList";
import { reportError } from "./report.utils";

const CANCELLATION_RETRY_COUNT = 15;
const CANCELLATION_RETRY_INTERVAL_MS = 1000 * 60;

interface DeclineParams {
    reservationId: number;
    memoManagerId: number | undefined;
    memoCategoryId: number | undefined;
    refererUrl: string | undefined;
    error: string | undefined;
    env: ApiCallOptions;
    hasToBeInitial: boolean;
}

export async function declineReservationDueToError(declineParams: DeclineParams, mxtsApi: MxtsApiWrapper): Promise<void> {
    const declineNotice = `MCMS reservation creation process failed at url ${declineParams.refererUrl} with error: ${JSON.stringify(declineParams.error)}`;
    declineReservation(mxtsApi, declineParams, declineNotice);
    addDeclinedMemoToReservation(mxtsApi, declineParams, declineNotice);
}

async function declineReservation(mxtsApi: MxtsApiWrapper, declineParams: DeclineParams, declineNotice: string, retryCount = 0): Promise<void> {
    const { hasToBeInitial, env, reservationId, refererUrl } = declineParams;
    try {
        const isInitialReservation = await isReservationInitial(mxtsApi, reservationId, env);
        if (!hasToBeInitial || isInitialReservation) {
            await mxtsApi.patchReservation(env, { status: isInitialReservation ? ReservationStatus.DELETED : ReservationStatus.DECLINED, cancelReason: declineNotice }, [
                { key: "reservationId", value: reservationId },
            ]);
        }
    } catch (err) {
        const declineFailedError = `Error during declining of initial reservationId ${reservationId}. With decline notice: ${declineNotice}`;
        reportError(err, typeof location !== "undefined" && location ? location.href : `${refererUrl}`, declineFailedError);
        addDeclinedMemoToReservation(mxtsApi, declineParams, declineFailedError);
        if (retryCount < CANCELLATION_RETRY_COUNT) {
            setTimeout(() => declineReservation(mxtsApi, declineParams, declineNotice, retryCount + 1), CANCELLATION_RETRY_INTERVAL_MS);
        }
    }
}

async function isReservationInitial(mxtsApi: MxtsApiWrapper, reservationId: number, env: ApiCallOptions): Promise<boolean> {
    const reservation = await mxtsApi.getReservation(env, {}, [
        {
            key: "reservationId",
            value: reservationId,
        },
    ]);
    return reservation?.status === ReservationStatus.INITIAL;
}

export async function getFallbackMemoCategoryId(mxtsApi: MxtsApiWrapper, env: ApiCallOptions): Promise<number | undefined> {
    const memoCategories: MemoCategoryResult[] | undefined = (await mxtsApi.getMemoCategories(env, { size: MXTS.MAX_RESULTS }))?.content?.filter(
        (memoCategory: MemoCategoryResult) => memoCategory.internetMemo
    );
    return memoCategories?.length ? memoCategories[0].categoryId : undefined;
}

async function addDeclinedMemoToReservation(mxtsApi: MxtsApiWrapper, declineParams: DeclineParams, declineNotice: string, retryCount = 0): Promise<void> {
    const { env, memoCategoryId, memoManagerId, refererUrl, reservationId } = declineParams;
    try {
        if (memoManagerId) {
            await mxtsApi.createMemo(env, {
                memocategoryId: memoCategoryId || (await getFallbackMemoCategoryId(mxtsApi, env)),
                content: declineNotice,
                managerId: memoManagerId,
            } as MemoRequest);
        } else {
            reportError(`Couldn't add a declined memo because no memoManagerId(${memoManagerId}) was provided... ReservationId: ${reservationId}`);
        }
    } catch (err) {
        reportError(
            err,
            typeof location !== "undefined" && location ? location.href : `${refererUrl}`,
            `Error while adding decline reason memo to reservation ${reservationId}. declineNotice: ${declineNotice}`
        );
        if (retryCount < CANCELLATION_RETRY_COUNT) {
            setTimeout(() => addDeclinedMemoToReservation(mxtsApi, declineParams, declineNotice, retryCount + 1), CANCELLATION_RETRY_INTERVAL_MS);
        }
    }
}

export interface LocalizedReservationStatus extends LocalizedContentBase {
    reservationNumberFallbackText: string;
}

export const getReservationNumberOrFallbackText = ({
    context,
    localizedReservationStatus = [],
    reservationNumber = undefined,
}: {
    context: CMSProvidedProperties;
    localizedReservationStatus?: LocalizedReservationStatus[];
    reservationNumber?: string;
}): string => {
    const { currentLocale, site } = context;
    const reservationNumberFallbackText: string =
        getLocalizedContent({ site, currentLocale, localizedContent: localizedReservationStatus })?.reservationNumberFallbackText ||
        getI18nLocaleString(namespaceList.admin, "reservationNumberDefaultFallbackText", currentLocale, site);

    return reservationNumber ? formatReservationNumber(reservationNumber.toString()) : reservationNumberFallbackText;
};

export const getAddOnReservedResourceRequestBodyBase = async (params: {
    apiContext: ApiContext;
    addOnReservedResource: ChoiceReservedResource | null;
    resourceId: number;
    reservation: { reservationId: number; status: number };
    rateTypeId?: number | null;
    accoReservedResource: { parentReservedResourceId: number; startDate: string; endDate: string };
}): Promise<ReservedResourceWithChildren> => {
    const { reservation, addOnReservedResource, rateTypeId, accoReservedResource } = params;

    if (!addOnReservedResource) {
        throw new Error(
            `Add-on with resourceId '${addOnReservedResource}' was not found inside the bill while creating reservationId '${reservation.reservationId}'...
                    This causes the reservation creation process to fail.`
        );
    }

    const addOnReservedResourceRequestBody: ReservedResourceWithChildren = {
        completed: true,
        onBill: true,
        payerType: PAYER_TYPE.CUSTOMER,
        quantity: addOnReservedResource?.quantity,
        removable: addOnReservedResource?.removable,
        showPrice: addOnReservedResource?.showPrice,
        status: reservation.status,
        type: addOnReservedResource?.type,
        resourceId: addOnReservedResource?.resourceId,
        parentId: accoReservedResource.parentReservedResourceId,
        code: addOnReservedResource?.code,
        name: addOnReservedResource?.name,
        shortDescription: addOnReservedResource?.shortDescription,
        description: addOnReservedResource?.description,
        reservationId: reservation.reservationId,
        rateTypeId,
        startDate: addOnReservedResource?.startDate ? await DateUtil.getMXTSDateString(params.apiContext, addOnReservedResource?.startDate) : accoReservedResource.startDate,
        endDate: addOnReservedResource?.endDate ? await DateUtil.getMXTSDateString(params.apiContext, addOnReservedResource?.endDate) : accoReservedResource.endDate,
        representationId: addOnReservedResource?.representationId,
        impliesEnabled: false,
        hasPriceOverride: addOnReservedResource?.hasPriceOverride,
        price: addOnReservedResource?.price,
    };

    return addOnReservedResourceRequestBody;
};

export const getReservedResourceByResourceId = (reservedResources: ChoiceReservedResource[] | undefined, resourceid: any): ChoiceReservedResource | null => {
    let reservedResource: ChoiceReservedResource | null = null;
    if (reservedResources?.length) {
        const flattenedReservedResources: ChoiceReservedResource[] = flattenReservedResourcesTree(reservedResources);
        // TODO this is dangerous with bookings containing multiple units, especially when they're from the same type
        // to be able to handle multi acco reservations, we should keep track of the current active one in the state
        reservedResource = flattenedReservedResources.find((res: ChoiceReservedResource) => res.resourceId === resourceid) || null;
    }
    return reservedResource;
};

export const flattenReservedResourcesTree = (reservedResources: ChoiceReservedResource[]): ChoiceReservedResource[] => {
    // get children of these resources plus all grandchildren and so on
    let result: ChoiceReservedResource[] = [];
    reservedResources.forEach((res) => (result = [...result, ...flattenReservedResourcesTree(res.children)]));
    return [...reservedResources, ...result];
};
