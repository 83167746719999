import { Theme } from ".";
import baseTheme from "./base";

const theme: Theme = {
    id: "krim",
    name: "Krim Theme",
    stylesheets: ["/styles/themes/krim/krim-admin.css"],
    frontendStylesheets: ["/styles/themes/krim/krim.css"],
    styles: [
        ...baseTheme.styles,
        // Krim specific menu searchbox
        {
            id: "search-input",
            name: "Menu searchbox",
            targets: ["menu", "menu-template"],
            classnames: ["menu-searchbox"],
        },
        // Krim Assets for icons (beating heart)
        {
            id: "beating-heart",
            name: "Show the beating texel heart",
            targets: ["webcontent"],
            classnames: ["beating texel"],
        },
        {
            id: "special-card-panel",
            name: "Special card lower panel design",
            targets: ["webcontent"],
            classnames: ["special-card-lower-panel"],
        },
        // TODO:- need to look at classnames and move those classes from base to Krim theme css files
        // to make base more cleaner with generic things only
        {
            id: "use-as-header-h2",
            name: "Use as header h2",
            targets: ["webcontent"],
            classnames: ["use-as-header-h2"],
        },
        {
            id: "use-as-header-h3",
            name: "Use as header h3",
            targets: ["webcontent"],
            classnames: ["use-as-header-h3"],
        },
        {
            id: "use-alternative-modal-header",
            name: "Use alternative modal header",
            targets: ["revealer-link"],
            classnames: ["modal-header-2"],
        },
    ],
};

export default theme;
