import * as React from "react";

import { ColumnsChildOptions, WidgetOptions } from "./";

import { PageWidgetBaseProps } from "../pageWidget.types";

interface ColumnsProps extends PageWidgetBaseProps<WidgetOptions> {
    childs: Array<{ element: JSX.Element; options: ColumnsChildOptions }>;
    className?: string;
}

interface ColumnsState {}

export class Columns extends React.PureComponent<ColumnsProps, ColumnsState> {
    public render(): JSX.Element | null {
        const { childs, className } = this.props;
        // Will be needed for better code
        // const classNameColumns = `${options.col ? "col-sm-" + options.col : "col-sm-")}`;
        // const classNameColumnsResp = `${options.col ? "col-" + options.col : "col-")}`;
        // const classNameColumnsTablet = `${options.col ? "col-md-" + options.col : "col-md-")}`;
        return (
            <div className={`plugin plugin-columns row ${className}`}>
                {childs.map(({ element, options }, ind) => (
                    <div
                        key={ind}
                        // eslint-disable-next-line max-len
                        className={`${options.col ? "col-sm-" + options.col : ""} ${options.columnsResponsive ? "col-" + options.columnsResponsive : "col"} ${
                            options.columnsTablet ? "col-md-" + options.columnsTablet : ""
                        } ${options.columnsDesktop ? "col-lg-" + options.columnsDesktop : ""} ${options.showMoment ? options.showMoment : ""}`}
                    >
                        {element}
                    </div>
                ))}
            </div>
        );
    }
}
