import { Theme } from ".";

import baseTheme from "./base";

const theme: Theme = {
    id: "glampingvoucher",
    name: "Glamping Voucher Theme",
    stylesheets: ["/styles/themes/glampingvoucher/glampingvoucher-admin.css"],
    frontendStylesheets: ["/styles/themes/glampingvoucher/glampingvoucher.css"],
    styles: [...baseTheme.styles],
};

export default theme;
