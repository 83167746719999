/* eslint-disable max-lines-per-function */
import * as moment from "moment";

import { ConditionalSet, ConditionalSetApi, ConditionalSetContent } from "@maxxton/cms-api";
import { MyEnvUserType, OwnerType, ReservationResult, convertReservationStatusNumberToText } from "@maxxton/cms-mxts-api";
import { daysRestrictions, evaluate, reduceBooleanArrayWithOperators } from "../../components/generic-form/ConditionalSetGroupInput/utils/conditionalSet.util";

import { ConditionalSetSelect } from "../../components/components.enum";
import { CustomerFormFields } from "../../utils/customer.util";
import { DATE_FORMAT } from "../../utils/constants";
import { DateUtil } from "../../utils/date.util";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { FormSpec } from "../";
import { MyEnvState } from "../../redux/reducers/myEnv/myEnvState";
import { getCustomerMainAddress } from "../../utils/address.util";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";

export interface ConditionalSetData {
    id?: number;
    name?: string;
    resortIds?: string[];
    amenities?: string;
    regionId?: string;
    accoKindIds?: string[];
    resourceIds?: string[];
    unitIds?: string[];
    startDate?: string;
    endDate?: string;
    userType?: string;
    ownerType?: string;
    customerCountries?: string[];
    distributionChannel?: string;
    reservationCategory?: string;
    paymentStatus?: string;
    reservationStatus?: string;
    contractsType?: string[];
    resortActivity?: string;
    minBathroom?: string;
    minBedroom?: string;
    rateType?: string;
    conditionalOperator?: string;
    logicalOperator?: string;
    conditionalSetContent?: ConditionalSetContent[];
    createdAt?: Date;
    updatedAt?: Date;
    arrivalDay?: string;
    departureDay?: string;
    reservationDate?: string;
    reservationType?: string;
    specialcode?: string;
    subject?: string;
}

export const conditionalSetSpec: FormSpec<ConditionalSetData> = {
    id: "conditional-set",
    name: getI18nLocaleObject(namespaceList.admin, "conditionalSet"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "conditionalSets"),
    api: ConditionalSetApi,
    permission: "webmanager.conditionalsets",
    display(conditionalSetItem: ConditionalSet) {
        return conditionalSetItem.name;
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
    ],
    properties: [
        {
            variable: "name",
            type: "text",
            label: getI18nLocaleObject(namespaceList.admin, "name"),
            required: true,
        },
        {
            variable: "conditionalSetContent",
            label: getI18nLocaleObject(namespaceList.admin, "conditionalSetContent"),
            type: "conditionalSetGroup",
        },
    ],
};

export const checkConditionalSet = (params: {
    allConditionalSetData: ConditionalSetData[];
    dynamicFilter: DynamicFilter;
    defaultVisibility?: string;
    myEnvState?: MyEnvState;
    myEnvUserTypes?: MyEnvUserType[];
    contractIds?: number[];
    reservation?: ReservationResult;
    formItem?: Partial<CustomerFormFields>;
    useFormDataInConditionalSet?: boolean;
}) => {
    const { allConditionalSetData, dynamicFilter, defaultVisibility, myEnvState, myEnvUserTypes, contractIds, reservation, formItem, useFormDataInConditionalSet } = params;
    let isConditionMatched: boolean | undefined = false;
    const filteredSet = allConditionalSetData.map((item) => {
        if (item.resortIds) {
            isConditionMatched = !!dynamicFilter.resortids?.some((dynamicId) => evaluate(item.resortIds, dynamicId.toString(), item.conditionalOperator));
            if (!dynamicFilter.resortids?.length && !isConditionMatched) {
                isConditionMatched = evaluate(item.resortIds, myEnvState?.selectedReservation?.reservation.resortId.toString(), item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.regionId) {
            isConditionMatched = !!dynamicFilter.regionIds?.some((dynamicId) => item.regionId && evaluate([item.regionId], dynamicId.toString(), item.conditionalOperator));
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.accoKindIds) {
            isConditionMatched = evaluate(
                item.accoKindIds,
                myEnvState?.selectedReservation?.reservedResources?.find((item) => item.reservationId === myEnvState?.selectedReservationId)?.resource?.accommodationKindId?.toString(),
                item.conditionalOperator
            );
            if (!myEnvState?.selectedReservation?.reservedResources?.length && !isConditionMatched) {
                isConditionMatched = !!dynamicFilter.accokindids?.some((dynamicId) => evaluate(item.accoKindIds, dynamicId.toString(), item.conditionalOperator));
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.amenities) {
            isConditionMatched = !!dynamicFilter.amenities?.some((dynamicId) => item.amenities && evaluate([item.amenities], dynamicId, item.conditionalOperator));
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.resourceIds) {
            isConditionMatched = evaluate(
                item.resourceIds,
                myEnvState?.selectedReservation?.reservedResources?.find((item) => item.reservationId === myEnvState?.selectedReservationId)?.resourceId.toString(),
                item.conditionalOperator
            );
            if (!myEnvState?.selectedReservation?.reservedResources?.length && !isConditionMatched) {
                isConditionMatched = evaluate(item.resourceIds, dynamicFilter.resourceid?.toString(), item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.unitIds) {
            isConditionMatched = evaluate(
                item.unitIds,
                myEnvState?.selectedReservation?.reservedResources?.find((item) => item.reservationId === myEnvState?.selectedReservationId)?.unitId?.toString(),
                item.conditionalOperator
            );
            if (!myEnvState?.selectedReservation?.reservedResources?.length && !isConditionMatched) {
                isConditionMatched = evaluate(item.unitIds, dynamicFilter.unitid?.toString(), item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.startDate) {
            isConditionMatched = evaluate([DateUtil.formatDate(new Date(item.startDate), DATE_FORMAT.DEFAULT)], dynamicFilter.startdate, item.conditionalOperator);
            if (!dynamicFilter.startdate && !isConditionMatched) {
                isConditionMatched = evaluate([DateUtil.formatDate(new Date(item.startDate), DATE_FORMAT.MXTS)], myEnvState?.selectedReservation?.reservation.arrivalDate, item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.endDate) {
            isConditionMatched = evaluate([DateUtil.formatDate(new Date(item.endDate), DATE_FORMAT.DEFAULT)], dynamicFilter.enddate, item.conditionalOperator);
            if (!dynamicFilter.enddate && !isConditionMatched) {
                isConditionMatched = evaluate([DateUtil.formatDate(new Date(item.endDate), DATE_FORMAT.MXTS)], myEnvState?.selectedReservation?.reservation.departureDate, item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.arrivalDay) {
            isConditionMatched = evaluate([moment().format(DATE_FORMAT.MXTS)], daysRestrictions(dynamicFilter.startdate, item.arrivalDay, item.conditionalOperator), item.conditionalOperator);
            if (!dynamicFilter.startdate && !isConditionMatched) {
                isConditionMatched = evaluate(
                    [moment().format(DATE_FORMAT.MXTS)],
                    daysRestrictions(moment(myEnvState?.selectedReservation?.reservation.arrivalDate).format(DATE_FORMAT.DEFAULT), item.arrivalDay, item.conditionalOperator),
                    item.conditionalOperator
                );
            }
            if (item.conditionalOperator === ConditionalSetSelect.BEFORE && moment().format(DATE_FORMAT.MXTS) > moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) {
                isConditionMatched = false;
            }
            if (item.conditionalOperator === ConditionalSetSelect.AFTER && moment().format(DATE_FORMAT.MXTS) < moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) {
                isConditionMatched = false;
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.departureDay) {
            isConditionMatched = evaluate([moment().format(DATE_FORMAT.MXTS)], daysRestrictions(dynamicFilter.enddate, item.departureDay, item.conditionalOperator), item.conditionalOperator);
            if (!dynamicFilter.enddate && !isConditionMatched) {
                isConditionMatched = evaluate(
                    [moment().format(DATE_FORMAT.MXTS)],
                    daysRestrictions(moment(myEnvState?.selectedReservation?.reservation.departureDate).format(DATE_FORMAT.DEFAULT), item.departureDay, item.conditionalOperator),
                    item.conditionalOperator
                );
            }
            if (item.conditionalOperator === ConditionalSetSelect.BEFORE && moment().format(DATE_FORMAT.MXTS) > moment(dynamicFilter.enddate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) {
                isConditionMatched = false;
            }
            if (item.conditionalOperator === ConditionalSetSelect.AFTER && moment().format(DATE_FORMAT.MXTS) < moment(dynamicFilter.enddate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) {
                isConditionMatched = false;
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.reservationDate) {
            const reservationDate = myEnvState?.selectedReservation?.reservation.reservationDate;
            const myEnvReservationDate = reservationDate ? moment(reservationDate, DATE_FORMAT.CMS_API_DATETIME).format(DATE_FORMAT.DEFAULT) : "";
            const currentDate = moment().format(DATE_FORMAT.MXTS);
            if (myEnvReservationDate) {
                isConditionMatched = evaluate([currentDate], daysRestrictions(myEnvReservationDate, item.reservationDate, item.conditionalOperator), item.conditionalOperator);
                if (item.conditionalOperator === ConditionalSetSelect.BEFORE && currentDate > moment(myEnvReservationDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) {
                    isConditionMatched = false;
                }
                if (item.conditionalOperator === ConditionalSetSelect.AFTER && currentDate < moment(myEnvReservationDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS)) {
                    isConditionMatched = false;
                }
                return { value: isConditionMatched, operator: item.logicalOperator };
            }
        }
        if (item.userType) {
            const userType = myEnvUserTypes?.find((myEnvUserType) => myEnvUserType === item.userType);
            isConditionMatched = evaluate([item.userType], userType, item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.ownerType) {
            isConditionMatched = evaluate(
                [item.ownerType],
                (useFormDataInConditionalSet ? formItem?.ownerType : undefined) || myEnvState?.mainCustomer?.ownerType || OwnerType.UNKNOWN,
                item.conditionalOperator
            );
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.customerCountries) {
            const customerCountryId = useFormDataInConditionalSet ? formItem?.country : getCustomerMainAddress(myEnvState?.mainCustomer || undefined)?.countryId;
            isConditionMatched = evaluate(item.customerCountries, customerCountryId ? `${customerCountryId}` : undefined, item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.distributionChannel) {
            isConditionMatched = evaluate([item.distributionChannel], myEnvState?.selectedReservation?.reservation.distributionChannelId.toString(), item.conditionalOperator);
            if (!myEnvState?.selectedReservation?.reservation.distributionChannelId && !isConditionMatched) {
                isConditionMatched = evaluate([item.distributionChannel], dynamicFilter.distributionChannel?.distributionChannelId?.toString(), item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.reservationCategory) {
            isConditionMatched = evaluate([item.reservationCategory], myEnvState?.selectedReservation?.reservation.reservationCategoryId.toString(), item.conditionalOperator);
            if (!myEnvState?.selectedReservation?.reservation.reservationCategoryId && !isConditionMatched) {
                isConditionMatched = evaluate([item.reservationCategory], dynamicFilter.reservationCategoryId?.toString(), item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.paymentStatus) {
            isConditionMatched = evaluate([item.paymentStatus], dynamicFilter.paymentStatus?.toString(), item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.reservationStatus) {
            const myEnvReservationStatus = myEnvState?.selectedReservation?.reservation.status;
            const selectedReservationStatus = convertReservationStatusNumberToText(+item.reservationStatus) || "";
            isConditionMatched = evaluate([selectedReservationStatus], myEnvReservationStatus, item.conditionalOperator);
            if (!myEnvReservationStatus && !isConditionMatched) {
                const reservationStatus = convertReservationStatusNumberToText(reservation?.status) || "";
                isConditionMatched = evaluate([selectedReservationStatus], reservationStatus, item.conditionalOperator);
            }
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.contractsType) {
            const contractTypeId = item.contractsType?.find((item) => contractIds?.some((contractId) => contractId.toString() === item));
            isConditionMatched = evaluate(item.contractsType, contractTypeId, item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.resortActivity) {
            isConditionMatched = evaluate([item.resortActivity], dynamicFilter.resortActivityId?.toString(), item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.minBathroom) {
            isConditionMatched = evaluate([item.minBathroom], dynamicFilter.minbathroom?.toString(), item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.minBedroom) {
            isConditionMatched = evaluate([item.minBedroom], dynamicFilter.minbedroom?.toString(), item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.rateType) {
            isConditionMatched = evaluate([item.rateType], dynamicFilter.rateType?.rateTypeId?.toString(), item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.specialcode) {
            const specialCode = dynamicFilter.specialcode?.find((code) => code === item.specialcode);
            isConditionMatched = evaluate([item.specialcode], specialCode, item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.subject && dynamicFilter.subject) {
            const subjectId = [...dynamicFilter.subject.keys()].find((sub) => sub.toString() === item.subject)?.toString();
            isConditionMatched = evaluate([item.subject], subjectId, item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
        if (item.reservationType) {
            isConditionMatched = evaluate([item.reservationType.toLowerCase()], reservation?.type?.toString().toLowerCase(), item.conditionalOperator);
            return { value: isConditionMatched, operator: item.logicalOperator };
        }
    });

    const filteredSetArray = filteredSet.filter((item) => item !== undefined);
    isConditionMatched = reduceBooleanArrayWithOperators(filteredSetArray);
    if (defaultVisibility === ConditionalSetSelect.HIDE) {
        isConditionMatched = !isConditionMatched;
    }
    return isConditionMatched;
};
