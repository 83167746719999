// eslint-disable

// This is a dependency file, hence not to be changed.
import * as classNames from "classnames";
import * as React from "react";

function menuRenderer({ focusedOption, instancePrefix, labelKey, onFocus, onSelect, optionClassName, optionComponent, optionRenderer, options, valueArray, valueKey, onOptionRef }: any) {
    const Option = optionComponent;

    return options.map((option: any, i: any) => {
        const isSelected = valueArray && valueArray.indexOf(option) > -1;
        const isFocused = option === focusedOption;
        const optionClass = classNames(optionClassName, {
            "Select-option": true,
            "is-selected": isSelected,
            "is-focused": isFocused,
            "is-disabled": option.disabled,
        });

        return (
            <Option
                className={optionClass}
                instancePrefix={instancePrefix}
                isDisabled={option.disabled}
                isFocused={isFocused}
                isSelected={isSelected}
                key={`option-${i}-${option[valueKey]}`}
                onFocus={onFocus}
                onSelect={onSelect}
                option={option}
                optionIndex={i}
                ref={(ref: any) => {
                    onOptionRef(ref, isFocused);
                }}
            >
                {optionRenderer(option, i)}
            </Option>
        );
    });
}

export default menuRenderer;
