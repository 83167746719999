export enum dynamicFilterType {
    // Assigning strings to enum to make it easier to debug
    subjects = "subjects",
    useSubjectCategory = "useSubjectCategory",
    resourceid = "resourceid",
    unitid = "unitid",
    unitPreference = "unitPreference",
    resort = "resort",
    selectedRegionId = "selectedRegionId",
    startdate = "startdate",
    enddate = "enddate",
    stay = "stay",
    stayperioddefid = "stayperioddefid",
    stayHolidayPeriodDefId = "stayHolidayPeriodDefId",
    holiday = "holiday",
    defaultStay = "defaultStay",
    duration = "duration",
    minCapacity = "minCapacity",
    addaccokind = "addaccokind",
    removeaccokind = "removeaccokind",
    addresort = "addresort",
    removeresort = "removeresort",
    updateresorts = "updateresorts",
    updateSelectedLocationIds = "updateSelectedLocationIds",
    addspecialcode = "addspecialcode",
    removespecialcode = "removespecialcode",
    addVoucherCodes = "addVoucherCodes",
    removeVoucherCodes = "removeVoucherCodes",
    addamenity = "addamenity",
    removeamenity = "removeamenity",
    bedroom = "bedroom",
    minbedroom = "minbedroom",
    maxbedroom = "maxbedroom",
    bathroom = "bathroom",
    minbathroom = "minbathroom",
    maxbathroom = "maxbathroom",
    maxBedBath = "maxBedBath",
    dynamicMarkersIsFetchComplete = "dynamicMarkersIsFetchComplete",
    onMouseOverOut = "onMouseOverOut",
    markerIds = "markerIds",
    minprice = "minprice",
    maxprice = "maxprice",
    priceRangeCriteria = "priceRangeCriteria",
    updateMinMaxPrice = "updateMinMaxPrice",
    directSearchInput = "directSearchInput",
    selectedDirectSearchId = "selectedDirectSearchId",
    sorting = "sorting",
    includeMissingPrices = "includeMissingPrices",
    addBookingsEngineUrl = "addBookingsEngineUrl",
    removeFilters = "removeFilters",
    removeBathroomFilters = "removeBathroomFilters",
    removeBedroomFilters = "removeBedroomFilters",
    removePriceRangeFilters = "removePriceRangeFilters",
    updatePriceSlider = "updatePriceSlider",
    updateDates = "updateDates",
    currencyId = "currencyId",
    updateCurrencyDetails = "updateCurrencyDetails",
    showCurrencyDetailsInUrl = "showCurrencyDetailsInUrl",
    distributionChannelId = "distributionChannelId",
    blendFilters = "blendFilters",
    addPreselectedFilters = "addPreselectedFilters",
    emptyDispatch = "emptyDispatch",
    resetAvailabilityFetch = "resetAvailabilityFetch",
    addcoordinates = "addcoordinates",
    removeMinArrivalDate = "removeMinArrivalDate",
    removeMaxArrivalDate = "removeMaxArrivalDate",
    addFreeSearch = "addFreeSearch",
    addDynamicFreeSearch = "addDynamicFreeSearch",
    removeFreeSearch = "removeFreeSearch",
    removeDynamicFreeSearch = "removeDynamicFreeSearch",
    additions = "additions",
    removeInstalments = "removeInstalments",
    loadingAction = "loadingAction",
    addReservationId = "addReservationId",
    shouldFetchUnitsWithPrice = "shouldFetchUnitsWithPrice",
    shouldFetchSortedUnits = "shouldFetchSortedUnits",
    shouldFetchStayPeriods = "shouldFetchStayPeriods",
    addDerivedEndDate = "addDerivedEndDate",
    currentLocale = "currentLocale",
    setEec = "setEec",
    setFlowType = "setFlowType",
    subjectPopup = "subjectPopup",
    shouldFetchResorts = "shouldFetchResorts",
    fetchLatestArrivalDate = "fetchLatestArrivalDate",
    smartDatePopup = "smartDatePopup",
    cancellationPremiumId = "cancellationPremiumId",
    reservationCategoryId = "reservationCategoryId",
    dateMargin = "dateMargin",
    isPayingCustomer = "isPayingCustomer",
    payingCustomerReservationCategoryId = "payingCustomerReservationCategoryId",
    setPaymentStatus = "setPaymentStatus",
    extraAggregations = "extraAggregations",
    guestRating = "guestRating",
    offerSearchCode = "offerSearchCode",
    setDynamicFilters = "setDynamicFilters",
    setResourceActivityDetailsId = "setResourceActivityDetailsId",
    setSelectedActivities = "setSelectedActivities",
    facilityids = "facilityids",
    pointsOfInterestIds = "pointsOfInterestIds",
    categoryFilters = "categoryFilters",
    selectedChoice = "selectedChoice",
    resortActivityId = "resortActivityId",
    paginatedData = "paginatedData",
    updateLastUsedVoucherCode = "lastUsedVoucherCode",
    addQualityLevel = "addQualityLevel",
    removeQualityLevel = "removeQualityLevel",
    resourceActivityDetailsIds = "resourceActivityDetailsIds",
    setActivityTicketQuantity = "setActivityTicketQuantity",
    updateReservationCurrencyCode = "updateReservationCurrencyCode",
    showMainActivityOnPage = "showMainActivityOnPage",
    removeActivityParams = "removeActivityParams",
    includeBookedPeriods = "includeBookedPeriods",
    includePreBookingPeriods = "includePreBookingPeriods",
    showResortAmenitiesCount = "showResortAmenitiesCount",
    previouslySelectedResourceId = "previouslySelectedResourceId",
    activityDateChange = "activityDateChange",
}
