import * as React from "react";
import * as classNames from "classnames";

import { CMSProvidedProperties, CMSProviderProperties } from "../containers/cmsProvider.types";
import { CategoryApi, DataLayer, Menu, OptionsApi, Page, Widget as WidgetType, WithId } from "@maxxton/cms-api";
import { DynamicFilterPickObjectsInterface, dynamicFilterPickObjects, generateDataLayer, generateEnhancedEcomm } from "../utils/datalayer.util";
import { FrontendPageEditAction, FrontendPageEditActionType } from "../redux/actions/frontendPageEditAction";
import { LOCAL_STORAGE_KEYS, META_ROBOTS_DEFAULT } from "../utils/constants";
import { accoBillLinesSelector, additionBillLinesSelector } from "../redux/selectors/BillStoreSelectors";
import { isEqual, pick } from "lodash";
import withRouter, { RouteComponentProps } from "../routing/withRouter";

import { ActionType } from "../redux/actions";
import { BillLine } from "@maxxton/cms-mxts-api";
import { BillState } from "../redux/reducers/billReducer";
import { CMSAware } from "../containers/CmsProvider";
import ConfirmationModal from "./frontend-editing/ConfirmationModal";
import { ContextUtil } from "../utils/context.util";
import { Dispatch } from "redux";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { EditedWidget } from "../form-specs";
import { FilterChangeAction } from "../redux/actions/dynamicFilterAction.types";
import { FlowWidgetTypes } from "../plugins/flow/flow.util";
import FrontendEditSidebar from "./frontend-editing/FrontendEditSidbar";
import { FrontendPageEditState } from "../redux/reducers/frontendPageEditReducer";
import { PageCustomDataLoader } from "./PageCustomDataLoader";
import { PageType } from "../plugins/flow/baseFlowIndex.enum";
import { ReservationState } from "../redux/reducers/reservationReducer";
import { SitemapPageLinkWidgetOptions } from "../plugins/sitemap/sitemap.types";
import { State } from "../redux";
import { connect } from "react-redux";
import { dynamicFilterType } from "../redux/reducers/dynamicFilter.enum";
import { enhancedEcommType } from "../utils/datalayer.constants";
import { fetchItems } from "../routing/routing.util";
import { getI18nLocaleString } from "../i18n";
import { isClientLoggedIn } from "./utils";
import { isMyEnvAuthTokenExpired } from "../utils/authToken.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../utils/loadableComponents.util";
import namespaceList from "../i18n/namespaceList";
import { pageViewedEvent } from "./mix-panel/mixpanel.util";
import { updateFrontendEditingButtonStatus } from "./frontend-editing/frontendEdit.utils";

const MenuView = loadable(() => loadableRetry(() => import("../plugins/menu/menu-view/Menu")), {
    resolveComponent: ({ MenuView }) => MenuView,
});

export interface PageBaseProps extends RouteComponentProps {
    page: Page & WithId;
    children: JSX.Element[];
    sideBarMenuItems?: JSX.Element[];
    updateTitle: boolean;
    context: CMSProviderProperties;
    SideBarMenu?: any;
    menu?: (Menu & WithId) | null;
    enable?: boolean;
    widgetOptions?: SitemapPageLinkWidgetOptions;
    pageLink: string;
}
export type PageProps = PageBaseProps & CMSProvidedProperties & PageStoreProps & PageDispatchProps;
interface PageState {
    sideBarMenuItems?: JSX.Element[];
    dropdownOpen: boolean;
    isLoggedIn: boolean;
    protocol: string;
    isMyEnvUserLoggedIn: boolean;
    isConfirmationModalOpen: boolean;
    confirmationMessage?: string;
    confirmationHandler?: () => void;
    dynamicPageSaveConfirmationMessage?: string;
    disableActionButtons?: boolean;
    discardHandler?: () => void;
}

interface PageDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | FrontendPageEditAction>;
}

interface PageStoreProps {
    bill: BillState;
    accoBillLines: BillLine[];
    additionBillLines: BillLine[];
    dynamicFilter: DynamicFilter;
    reservationState: ReservationState;
    frontendPageEditState: FrontendPageEditState;
}

export class PageViewBase extends React.PureComponent<PageProps, PageState> {
    dataLayerSettings: (DataLayer & WithId) | null = null;
    constructor(props: PageProps) {
        super(props);
        this.state = {
            sideBarMenuItems: props.sideBarMenuItems,
            dropdownOpen: false,
            isLoggedIn: false,
            protocol: "",
            isMyEnvUserLoggedIn: false,
            isConfirmationModalOpen: false,
            disableActionButtons: !props.frontendPageEditState.areFrontendWidgetsEdited,
        };
    }

    public componentDidMount() {
        this.fetchMenuItems();
        const { site, dispatchAction } = this.props;
        updateFrontendEditingButtonStatus(dispatchAction);
        const documentObject = document;
        const hotJarScript = documentObject.getElementById("hotJar");
        if (hotJarScript) {
            hotJarScript.remove();
        }

        const parsedFrontendEditedWidgets: Array<EditedWidget<any>> = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS) || "[]");
        if (parsedFrontendEditedWidgets.length && isClientLoggedIn() && this.props.frontendPageEditState.isFrontEndEditable) {
            this.setState({
                isConfirmationModalOpen: true,
                confirmationMessage: getI18nLocaleString(namespaceList.admin, "unsavedChangesConfirmation"),
                confirmationHandler: this.handlePageOptionsSave,
                discardHandler: this.handleClearAllEditedWidgets,
            });
        }
        if (site.useHotJar && site.hotJarAccountId) {
            const hotJar = documentObject.createElement("script");
            hotJar.setAttribute("id", "hotJar");
            hotJar.innerHTML = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${site.hotJarAccountId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
            documentObject.head.appendChild(hotJar);
        }

        if (location.hash) {
            setTimeout(() => {
                document.querySelector(location.hash)?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 2000);
        }
        this.setState({
            isLoggedIn: isClientLoggedIn(),
            protocol: window.location.hostname.indexOf("local") < 0 ? "https" : "http",
        });
        if (this.props.accoBillLines.length) {
            this.updateDataLayer(this.props, false);
        }
        const action: FilterChangeAction = {
            type: ActionType.DynamicFilter,
            filter: dynamicFilterType.resetAvailabilityFetch,
            payload: {},
        };
        this.props.dispatchAction(action);
        this.setState({ isMyEnvUserLoggedIn: !isMyEnvAuthTokenExpired() });
        if (isClientLoggedIn() && this.props.frontendPageEditState.isFrontEndEditable) {
            this.setDynamicPageSaveConfirmationMessage();
        }
        this.doFlowPageLoadActions();
        if ((window as any)?.mixpanel && isClientLoggedIn()) {
            setTimeout(() => {
                pageViewedEvent();
            }, 4000);
        }
    }
    async doFlowPageLoadActions() {
        const { context, dispatchAction } = this.props;
        const currentStep: WidgetType | undefined = await ContextUtil.getCurrentFlow(context);
        if (currentStep?.type === FlowWidgetTypes.RESULT) {
            dispatchAction({ type: ActionType.ReservationUpdated, payload: {} } as any);
        }
    }

    componentDidUpdate(prevProps: PageProps) {
        const {
            accoBillLines,
            frontendPageEditState: { areFrontendWidgetsEdited },
        } = this.props;
        if (prevProps.location !== this.props.location || (accoBillLines.length && !prevProps.accoBillLines.length)) {
            this.updateDataLayer(this.props, false);
            this.setState({ isMyEnvUserLoggedIn: !isMyEnvAuthTokenExpired() });
        }

        if (!isEqual(areFrontendWidgetsEdited, prevProps.frontendPageEditState.areFrontendWidgetsEdited)) {
            this.setState({ disableActionButtons: !areFrontendWidgetsEdited });
        }
    }

    private setDynamicPageSaveConfirmationMessage = async () => {
        const categories = await CategoryApi.find({ query: { categoryId: { $in: this.props.page.category } } });
        const isBookingModulePage = categories.some((category) => category.name === "Bookings module pages");
        if (isBookingModulePage) {
            this.setState({ dynamicPageSaveConfirmationMessage: getI18nLocaleString(namespaceList.admin, "dynamicPageSaveConfirmationMessage") });
        }
    };

    private toggleDropdownMenu = () => {
        this.setState((state) => ({
            ...state,
            dropdownOpen: !state.dropdownOpen,
        }));
    };

    private toggleConfirmationModal = () => {
        updateFrontendEditingButtonStatus(this.props.dispatchAction);
        this.setState({
            isConfirmationModalOpen: !this.state.isConfirmationModalOpen,
        });
    };

    private handlePageOptionsSave = async () => {
        const { alerts } = this.props.context;
        const parsedFrontendEditedWidgets: Array<EditedWidget<any>> = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS) || "[]");
        if (parsedFrontendEditedWidgets.length) {
            try {
                await Promise.all(parsedFrontendEditedWidgets.map((widget) => OptionsApi.update({ id: widget.options._id, item: widget.options })));
                alerts.push({ color: "success", message: getI18nLocaleString(namespaceList.admin, "saveSucceed") });
            } catch (error) {
                alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "saveFailed") });
            }
            localStorage.removeItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS);
        } else {
            alerts.push({ color: "warning", message: getI18nLocaleString(namespaceList.admin, "noEditedWidgets") });
        }
        this.toggleConfirmationModal();
    };

    private handleClearAllEditedWidgets = () => {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.FRONTEND_EDITED_WIDGETS);
        const action = {
            type: ActionType.FrontendPageEditPage,
            actionType: FrontendPageEditActionType.CLEAR_FRONTEND_EDITED_WIDGETS,
            payload: {
                isFrontendEditedWidgetsReset: true,
            },
        };
        this.props.dispatchAction(action);
        this.toggleConfirmationModal();
    };

    private handleActionButtonClick = (confirmationMessage?: string, confirmationHandler?: () => void) => {
        this.setState({
            isConfirmationModalOpen: !this.state.isConfirmationModalOpen,
            confirmationMessage,
            confirmationHandler,
        });
    };

    private handleSaveButtonClick = () => {
        const { dynamicPageSaveConfirmationMessage } = this.state;
        if (dynamicPageSaveConfirmationMessage) {
            this.handleActionButtonClick(dynamicPageSaveConfirmationMessage, this.handlePageOptionsSave);
        } else {
            this.handleActionButtonClick(getI18nLocaleString(namespaceList.admin, "frontendPageSaveConfirmation"), this.handlePageOptionsSave);
        }
    };

    private handleClearButtonClick = () => {
        this.handleActionButtonClick(getI18nLocaleString(namespaceList.admin, "confirmCancel"), this.handleClearAllEditedWidgets);
    };

    private renderConfirmationModal = () => {
        const { isConfirmationModalOpen, confirmationMessage, confirmationHandler, discardHandler } = this.state;
        if (isConfirmationModalOpen) {
            return (
                <ConfirmationModal
                    isModalOpen={isConfirmationModalOpen}
                    confirmationTitle={getI18nLocaleString(namespaceList.admin, "warning")}
                    confirmationMessage={confirmationMessage}
                    handleConfirm={confirmationHandler}
                    handleCancel={this.toggleConfirmationModal}
                    handleDiscard={discardHandler}
                />
            );
        }
    };
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            site,
            context,
            SideBarMenu,
            enable,
            page,
            children,
            onTitle,
            onMetaTitle,
            onMetaKeywords,
            onMetaDescription,
            onMetaRobots,
            onImageStructured,
            onTitleStructured,
            onUrlStructured,
            onDescriptionStructured,
            onAuthorStructured,
            onStructuredData,
            onStructuredDataMarkup,
            updateTitle,
            onEnableRedirect,
            onRedirect,
            onDisableCanonicalUrl,
            pageLink,
            frontendPageEditState: { isFrontEndEditable },
        } = this.props;
        const className = `sidebar-menu animate-${site?.animation}`;
        const localeContent = page.localizedContents && page.localizedContents.find((lc) => lc.locale === context.currentLocale.locale);
        if (!site.enableEmbedding) {
            if (onTitle && updateTitle) {
                onTitle(page.name);
            }
            if (onMetaTitle && localeContent) {
                onMetaTitle(localeContent.metaTitleSeo, context);
            }
            if (onMetaKeywords && localeContent) {
                onMetaKeywords(localeContent.metaKeywordsSeo);
            }
            if (onMetaDescription && localeContent) {
                onMetaDescription(localeContent.metaDescriptionSeo);
            }
            if (onMetaRobots) {
                onMetaRobots(page.metaRobots || META_ROBOTS_DEFAULT);
            }
            if (onImageStructured && localeContent) {
                onImageStructured(localeContent.imageStructuredSeo);
            }
            if (onTitleStructured && localeContent) {
                onTitleStructured(localeContent.titleStructuredSeo);
            }
            if (onUrlStructured && localeContent) {
                onUrlStructured(localeContent.urlStructuredSeo);
            }
            if (onDescriptionStructured && localeContent) {
                onDescriptionStructured(localeContent.descriptionStructuredSeo);
            }
            if (onAuthorStructured && localeContent) {
                onAuthorStructured(localeContent.authorStructuredSeo);
            }
            if (onStructuredData) {
                onStructuredData(page.structuredData);
            }
            if (onStructuredDataMarkup && localeContent) {
                onStructuredDataMarkup(localeContent.structuredDataMarkup);
            }
            if (onEnableRedirect) {
                onEnableRedirect(page.enableRedirect);
            }
            if (onRedirect) {
                if (page.useExternalLink && page.redirect) {
                    onRedirect(page.redirect);
                } else if (page.siteId && page.pageId) {
                    onRedirect(pageLink);
                }
            }
            if (onDisableCanonicalUrl) {
                onDisableCanonicalUrl(page.disableCanonicalUrl);
            }
        }
        const { sideBarMenuItems, isLoggedIn, isMyEnvUserLoggedIn, disableActionButtons, dropdownOpen, protocol } = this.state;
        // this.generatePageDataLayer(this.props);
        return (
            <div id="outer-container" className={isLoggedIn ? "logged-in" : ""}>
                <PageCustomDataLoader context={context}></PageCustomDataLoader>
                {isLoggedIn && (
                    <FrontendEditSidebar
                        toggleDropdownMenu={this.toggleDropdownMenu}
                        page={this.props.page}
                        protocol={protocol}
                        isFrontEndEditable={!!isFrontEndEditable}
                        handleSaveButtonClick={this.handleSaveButtonClick}
                        disableActionButtons={!!disableActionButtons}
                        handleClearButtonClick={this.handleClearButtonClick}
                        toggleFrontendEditable={this.toggleFrontendEditable}
                    />
                )}
                {enable && SideBarMenu && (
                    <SideBarMenu
                        width={`${site.menuSize}%`}
                        isOpen={false}
                        pageWrapId={"page-wrap"}
                        outerContainerId={"outer-container"}
                        className={site.menuIconLocation ? "right-bm-icon" : "left-bm-icon"}
                        right={!!site.menuIconLocation}
                    >
                        <div className="side-menu-header">
                            <a href="/" className="side-menu-logo" />
                        </div>
                        {sideBarMenuItems && <MenuView className={className} items={sideBarMenuItems} />}
                    </SideBarMenu>
                )}
                <div id="page-wrap" className={classNames(`page ${page.name.trim().split(" ").join("-")}`, { "customer-logged-in": isMyEnvUserLoggedIn })}>
                    {children}
                    {isFrontEndEditable && this.renderConfirmationModal()}
                </div>
            </div>
        );
    }

    private updateDataLayer = async (props: PageProps, checkChange: boolean) => {
        const { context, reservationState } = props;
        const currentStep: WidgetType | undefined = await ContextUtil.getCurrentFlow(context);
        const dataLayerPickArray: string[] = [];
        const { dataLayerSettings } = context;
        if (dataLayerSettings) {
            if (currentStep?.options.contentType) {
                const contentType = currentStep.options.contentType === PageType.TYPE_DETAIL_PAGE ? "Type Level" : "Unit Level";
                const action: FilterChangeAction = {
                    type: ActionType.DynamicFilter,
                    filter: dynamicFilterType.setFlowType,
                    payload: {
                        flowType: contentType,
                    },
                };
                this.props.dispatchAction(action);
            }
            dataLayerSettings?.dataLayerContent?.forEach((dataLayerSetting) => {
                const dynamicFilterValue = dynamicFilterPickObjects[dataLayerSetting?.value as keyof DynamicFilterPickObjectsInterface];
                if (dynamicFilterValue) {
                    dataLayerPickArray.push(dynamicFilterValue);
                }
            });
            const isEcommerceDataLayerEnabled =
                dataLayerSettings?.enableEec && (currentStep?.options.enableEecCheckout || (dataLayerSettings?.enableGA4DataLayer && currentStep?.options.ecommerceGA4Event));
            const ecommerceEvent = dataLayerSettings?.enableGA4DataLayer ? currentStep?.options.ecommerceGA4Event : enhancedEcommType.checkout;
            const isEcommercePurchaseDataLayerEnabled =
                dataLayerSettings?.enableEec && (currentStep?.options.enableEec || (dataLayerSettings?.enableGA4DataLayer && currentStep?.options.ecommerceGA4Event === enhancedEcommType.purchase));

            if (dataLayerSettings?.enableEec && props.context.flow?.dataLayerId && !props.dynamicFilter.eec && isEcommercePurchaseDataLayerEnabled) {
                context.logger.info(
                    "Purchase Conditions:" +
                        "EEC: " +
                        !props.dynamicFilter.eec +
                        "ReservationID: " +
                        props.dynamicFilter.reservationId +
                        "Reservation Number: " +
                        reservationState?.reservation?.reservationNumber
                );
                if (props.dynamicFilter.reservationId && reservationState.reservation?.reservationNumber) {
                    generateEnhancedEcomm(context, dataLayerSettings, props, enhancedEcommType.purchase);
                    this.resetUTM();
                    const action: any = {
                        type: ActionType.DynamicFilter,
                        filter: dynamicFilterType.setEec,
                        payload: undefined,
                    };
                    this.props.dispatchAction(action);
                }
            }

            if (isEcommerceDataLayerEnabled && ecommerceEvent) {
                generateEnhancedEcomm(context, dataLayerSettings, props, ecommerceEvent, currentStep?.type);
            }

            if (currentStep?.options.useDataLayer && props.context.flow?.dataLayerId) {
                const eventName = currentStep?.options.eventName;
                if (
                    (checkChange &&
                        (Object.keys(pick(props.dynamicFilter, dataLayerPickArray)).some(
                            (key) => !isEqual(this.props.dynamicFilter[key as keyof DynamicFilter], props.dynamicFilter[key as keyof DynamicFilter])
                        ) ||
                            !isEqual(this.props.accoBillLines, props.accoBillLines))) ||
                    !isEqual(this.props.additionBillLines, props.additionBillLines) ||
                    !isEqual(this.props.reservationState?.reservation?.paymentTermSetId, props.reservationState?.reservation?.paymentTermSetId) ||
                    !isEqual(this.props.bill, props.bill)
                ) {
                    generateDataLayer(context, dataLayerSettings, props, eventName);
                } else if (!checkChange) {
                    generateDataLayer(context, dataLayerSettings, props, eventName);
                }
            }
        }
    };

    private async generatePageDataLayer(props: PageProps) {
        if (props.page.enableDataLayer && props.context.dataLayerSettings) {
            generateDataLayer(props.context, props.context.dataLayerSettings, props, props.page.pageEventName || "");
        }
    }

    private resetUTM() {
        if (typeof localStorage !== "undefined") {
            localStorage.removeItem("referrer");
            localStorage.removeItem("utm_channel");
            localStorage.removeItem("utm_source");
            localStorage.removeItem("utm_campaign");
            localStorage.removeItem("utm_medium");
            localStorage.removeItem("utm_content");
        }
    }

    private async fetchMenuItems() {
        const { context, menu, widgetOptions } = this.props;
        if (menu) {
            const items = await fetchItems({ context, widgetOptions, menu });
            this.setState({ sideBarMenuItems: items });
        }
    }
    private toggleFrontendEditable = () => {
        const isFrontEndEditable = !!this.props.frontendPageEditState.isFrontEndEditable;
        const controlsOptions = document.querySelectorAll(".controls-wrap");
        Array.from(controlsOptions).forEach((ele: any) => {
            if (!isFrontEndEditable && ele && !ele!.classList.contains("show-controls") && ele.firstElementChild) {
                ele!.classList.add("show-controls");
                ele.firstElementChild?.classList.contains("configuration-bar") && (ele.firstElementChild.style.display = "flex");
            } else {
                ele!.classList.remove("show-controls");
                ele.firstElementChild?.classList.contains("configuration-bar") && (ele.firstElementChild.style.display = "none");
            }
        });
        const action: FrontendPageEditAction = {
            type: ActionType.FrontendPageEditPage,
            actionType: FrontendPageEditActionType.SET_IS_PAGE_ENABLE_EDIT,
            payload: {
                isFrontEndEditable: !isFrontEndEditable,
            },
        };
        this.props.dispatchAction(action);
    };
}

function mapStateToProps(state: State): PageStoreProps {
    return {
        // These are used in generateDataLayer method
        bill: state.billState,
        dynamicFilter: state.dynamicFilter,
        reservationState: state.reservationState,
        accoBillLines: accoBillLinesSelector(state),
        additionBillLines: additionBillLinesSelector(state),
        frontendPageEditState: state.frontendPageEditState,
    };
}
function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | FrontendPageEditAction>): PageDispatchProps {
    return { dispatchAction: dispatch };
}

export const PageViewConnected = connect<PageStoreProps, PageDispatchProps>(mapStateToProps, mapDispatchToProps)(PageViewBase);

export const PageView = withRouter(CMSAware<PageBaseProps>(PageViewConnected));
