import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "beachrentals",
    name: "Beach Rentals Theme",
    stylesheets: ["/styles/themes/beachrentals/beachrentals-admin.css"],
    frontendStylesheets: ["/styles/themes/beachrentals/beachrentals.css"],
    styles: [...baseTheme.styles],
};

export default theme;
