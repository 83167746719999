import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { ApiCallOptions, Arrival, ResourcePrice } from "@maxxton/cms-mxts-api";
import { FocusedInputShape, ModifiersShape, isInclusivelyAfterDay } from "react-dates";
import { getMxtsEnv, lookupDateMap } from "../../plugins/mxts";

import { AggregationRequest } from "../../plugins/shared/priceMatrix/priceMatrix.types";
import { AvailabilityUtil } from "../../utils/availability.util";
import { CMSProviderProperties } from "../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../utils/constants";
import { DateMap } from "../../plugins/mxts/mxts.types";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { SubjectUtil } from "../../plugins/dynamic/subject/SubjectUtil";
import { TotalPriceForDates } from "./DateRangePicker/dateRangePicker.types";
import { getPriceAggregation } from "../../utils/priceMatrix.utils";

interface PricesParmas {
    day: Moment;
    modifiers: ModifiersShape;
    startDate?: Moment | null;
    endDate: Moment | null;
    dynamicFilter?: DynamicFilter;
    customDataDisplayType?: string;
    showPerNightPrices?: boolean;
    showTotalPrices?: boolean;
    availableDatesDetail?: Arrival[];
    totalPricesForSelectedDate?: TotalPriceForDates[];
    isTotalPricesLoaded?: boolean;
    isSingleDatePicker?: boolean;
}

type Moment = moment.Moment;

export const START_DATE = "startDate";
export const END_DATE = "endDate";

export const isOutsideRange = (firstAvailableDay?: Moment) => (day: Moment): boolean => {
    const isOutsideRange = firstAvailableDay ? !isInclusivelyAfterDay(day, firstAvailableDay) : true;
    return isOutsideRange;
};

// for single day selection
export const isDayBlocked = (availableDates?: DateMap) => (day: Moment): boolean => (availableDates ? !lookupDateMap(availableDates, day) : true);

// for day range selection
export const isDayRangeBlocked = ({
    dates,
    durations,
    startDate,
    focusedInput,
    isFetchingResults,
}: {
    dates?: DateMap;
    durations?: number[];
    startDate: Moment | null;
    focusedInput: FocusedInputShape | null;
    isFetchingResults?: boolean;
}) => (day: Moment): boolean => {
    if (dates && durations) {
        if (focusedInput === START_DATE || isFetchingResults) {
            const blocked = !lookupDateMap(dates, day.hour(12));
            return blocked;
        } else if (startDate && focusedInput === END_DATE && !isFetchingResults) {
            startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            const diffDays = day.hour(12).diff(startDate, "day"); // Added .hour(12) ref: https://github.com/airbnb/react-dates/issues/1358
            const blocked = !durations.some((duration) => duration === diffDays);
            return blocked;
        }
    }
    return true;
};

// for single day selection
export const isDayHighlighted = (availableDates?: DateMap) => (day: Moment): boolean => {
    const isHighlighted = !!availableDates && lookupDateMap(availableDates, day);
    return isHighlighted;
};

// for day range selection
export const isDayRangeHighlighted = ({
    specialAvailableDates,
    specialAvailableDurations,
    focusedInput,
    startDate,
}: {
    specialAvailableDates?: DateMap;
    specialAvailableDurations?: number[];
    startDate: Moment | null;
    focusedInput: FocusedInputShape | null;
}) => (day: Moment): boolean => {
    if (specialAvailableDates && Object.keys(specialAvailableDates).length) {
        if (focusedInput === START_DATE) {
            const isHighlighted = lookupDateMap(specialAvailableDates, day);
            return isHighlighted;
        } else if (focusedInput === END_DATE && startDate && specialAvailableDurations?.length) {
            startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            const diffDays = day.diff(startDate, "day");
            const isHighlighted = specialAvailableDurations.some((duration) => duration === diffDays);
            return isHighlighted;
        }
    }
    return false;
};

export function getPrices(parmas: PricesParmas) {
    const {
        startDate,
        endDate,
        dynamicFilter,
        customDataDisplayType,
        showPerNightPrices,
        showTotalPrices,
        availableDatesDetail,
        totalPricesForSelectedDate,
        modifiers,
        day,
        isTotalPricesLoaded,
        isSingleDatePicker,
    } = parmas;
    const formattedDate = !isSingleDatePicker ? (startDate && !endDate ? startDate?.format(DATE_FORMAT.ELASTIC) : day.format(DATE_FORMAT.ELASTIC)) : day.format(DATE_FORMAT.ELASTIC);
    const pricesWithDuration = availableDatesDetail?.filter((item: Arrival) => item.arrivalDate === formattedDate);
    const dayBlocked: boolean = modifiers.has("blocked-calendar") || modifiers.has("blocked-minimum-nights");
    let priceToshow: number | undefined;
    if (showTotalPrices && !dayBlocked && !isTotalPricesLoaded) {
        return (
            <div className="custom-data">
                <div className="custom-data__date">{day.format("D")}</div>
                <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
            </div>
        );
    }
    if (!dayBlocked && pricesWithDuration?.length) {
        let prices: ResourcePrice = pricesWithDuration[0]?.durations[0].resourcePrice.prices[0];
        if (showPerNightPrices) {
            priceToshow = prices?.specialCode === "no-special" ? prices?.roundedBaseNightPriceInclusive : prices?.roundedSpecialNightPriceInclusive;
        } else {
            priceToshow = prices?.specialCode === "no-special" ? prices?.roundedBasePriceInclusive : prices?.roundedSpecialPriceInclusive;
        }
        if (startDate && !endDate && !showTotalPrices && !isSingleDatePicker) {
            const filterDuration = pricesWithDuration[0]?.durations?.filter((item: any) => item.resourcePrice.departureDate === day.format(DATE_FORMAT.ELASTIC));
            prices = filterDuration[0]?.resourcePrice?.prices[0];
            priceToshow = prices?.specialCode === "no-special" ? prices?.roundedBasePriceInclusive : prices?.roundedSpecialPriceInclusive;
        }
        // if total prices is enabled
        if (startDate && !endDate && showTotalPrices) {
            const totalPrice = totalPricesForSelectedDate?.find((item: any) => item?.departureDate === day.format(DATE_FORMAT.ELASTIC))?.totalPrice;
            priceToshow = totalPrice;
        }
    }
    return (
        <div className="custom-data">
            <div className="custom-data__date">{day.format("D")}</div>
            {!!priceToshow && (
                <div className={`price-inside-calendar ${customDataDisplayType === "inline" ? "custom-data__without-hover" : "custom-data__with-hover"}`}>
                    {dynamicFilter?.currency?.symbol} {priceToshow}
                </div>
            )}
        </div>
    );
}

export async function fetchPricesForAvailableDates(arrivalDate: Moment, arrivalsWisePrices?: Arrival[], context?: CMSProviderProperties, dynamicFilter?: DynamicFilter) {
    const env: ApiCallOptions | undefined = context && (await getMxtsEnv(context, context.currentLocale.code));
    if (env && dynamicFilter?.resourceid && dynamicFilter?.distributionChannel?.distributionChannelId && context) {
        const aggregationRequest: AggregationRequest = {
            arrivalDate: arrivalDate.format(DATE_FORMAT.MXTS),
            distributionChannelId: dynamicFilter?.distributionChannel?.distributionChannelId!,
            resourceId: dynamicFilter?.resourceid,
            env,
            size: 60,
        };
        const priceTable = await getPriceAggregation(context, aggregationRequest);
        const pricesWithDuration: Arrival[] | [] = priceTable?.response?.priceTable?.rateTypes[0]?.arrivals || [];
        if (arrivalsWisePrices?.length) {
            let modifiedArrivalsWiseList = arrivalsWisePrices;
            if (arrivalsWisePrices?.length > 200) {
                modifiedArrivalsWiseList = arrivalsWisePrices.slice(60);
            }
            arrivalsWisePrices = [...modifiedArrivalsWiseList, ...pricesWithDuration];
            return arrivalsWisePrices;
        }
        return pricesWithDuration;
    }
}

export async function getTotalPrice(endDate: string, dynamicFilter: DynamicFilter, startDate: Moment, context: CMSProviderProperties) {
    const env: ApiCallOptions | undefined = context && (await getMxtsEnv(context, context.currentLocale.code));

    if (
        startDate &&
        endDate &&
        dynamicFilter?.rateType?.rateTypeId &&
        dynamicFilter?.distributionChannel?.distributionChannelId &&
        dynamicFilter?.reservationCategoryId &&
        dynamicFilter?.resourceid &&
        dynamicFilter?.subject &&
        env
    ) {
        const calculateTotalRequest = {
            distributionChannelId: dynamicFilter?.distributionChannel?.distributionChannelId,
            reservationCategoryId: dynamicFilter?.reservationCategoryId,
            rateTypeId: dynamicFilter?.rateType?.rateTypeId,
            startDate: startDate.format(DATE_FORMAT.ELASTIC),
            endDate,
            includeBill: false,
            accommodationType: {
                subjects: SubjectUtil.getSubjectQuantitiesFromMap(dynamicFilter?.subject),
                resourceId: dynamicFilter?.resourceid,
                type: "ACCOMMODATIONTYPE",
                status: "INITIAL",
            },
        };
        const calculationResult = await context?.mxtsApi.calculateTotal(env, calculateTotalRequest);
        if (calculationResult?.totalPrice) {
            const totalPriceForDuration = { departureDate: endDate, totalPrice: calculationResult?.totalPrice };
            return totalPriceForDuration;
        }
    }
}

export async function loadTotalPrices(endDates: string[], component: any) {
    const { dynamicFilter, startDate, context } = component.props;
    if (dynamicFilter && startDate && context) {
        component.setState({ isTotalPricesLoaded: false });
        const allTotalPrices: Array<TotalPriceForDates | undefined> = await Promise.all(endDates.map((date) => getTotalPrice(date, dynamicFilter, startDate, context)));
        component.setState({ totalPricesForSelectedDate: allTotalPrices, isTotalPricesLoaded: true });
    }
}

export async function loadPrices(component: any, isSingleDatePicker?: boolean) {
    const { dynamicFilter, context, date, startDate, showCustomData, availableDates } = component.props;
    const { availableDatesDetail } = component.state;
    const firstAvailableDay: Moment | undefined = AvailabilityUtil.getFirstAvailableDay({ dateMap: availableDates });
    const arrivalDate: Moment | undefined = (isSingleDatePicker ? date : startDate) || firstAvailableDay;
    if (arrivalDate && showCustomData) {
        const pricesWithDuration = await fetchPricesForAvailableDates(arrivalDate, availableDatesDetail, context, dynamicFilter);
        component.setState({ availableDatesDetail: pricesWithDuration });
    }
}

export async function handleMonthChangeEvent(currentMonthDate: Moment, component: any, type: string) {
    const { dynamicFilter, context } = component.props;
    const { availableDatesDetail } = component.state;
    const lastFetchedArrivalDate = currentMonthDate.format(DATE_FORMAT.YEAR_MONTH);
    const addUpDays = type === "Prev" ? 30 : 60;
    const firstAvailableDateForCurrentMonth = moment(lastFetchedArrivalDate).add(addUpDays, "days").format(DATE_FORMAT.ELASTIC);

    if (firstAvailableDateForCurrentMonth) {
        const pricesWithDuration = await fetchPricesForAvailableDates(moment(firstAvailableDateForCurrentMonth), availableDatesDetail, context, dynamicFilter);
        component.setState({ availableDatesDetail: pricesWithDuration });
    }
}
