// eslint-disable
export default {
    bounce: "bounce",
    bounceIn: "bounce In",
    bounceInDown: "bounce In Down",
    bounceInLeft: "bounce In Left",
    bounceInRight: "bounce In Right",
    bounceInUp: "bounce In Up",
    bounceOut: "bounce Out",
    bounceOutDown: "bounce Out Down",
    bounceOutLeft: "bounce Out Left",
    bounceOutRight: "bounce Out Right",
    bounceOutUp: "bounce Out Up",
    fadeIn: "fade In",
    fadeInDown: "fade In Down",
    fadeInDownBig: "fade In Down Big",
    fadeInLeft: "fade In Left",
    fadeInLeftBig: "fade In Left Big",
    fadeInRight: "fade In Right",
    fadeInRightBig: "fade In Right Big",
    fadeInUp: "fade In Up",
    fadeInUpBig: "fade In Up Big",
    fadeOut: "fade Out",
    fadeOutDown: "fade Out Down",
    fadeOutDownBig: "fade Out Down Big",
    fadeOutLeft: "fade Out Left",
    fadeOutLeftBig: "fade Out Left Big",
    fadeOutRight: "fade Out Right",
    fadeOutRightBig: "fade Out Right Big",
    fadeOutUp: "fade Out Up",
    fadeOutUpBig: "fade Out Up Big",
    flash: "flash",
    flipInX: "flip In horizontal",
    flipInY: "flip In vertical",
    flipOutX: "flip Out horizontal",
    flipOutY: "flip Out vertical",
    headShake: "head Shake",
    heartBeat: "heartBeat",
    hinge: "hinge",
    jackInTheBox: "jack In The Box",
    jello: "jello",
    lightSpeedIn: "light Speed In",
    lightSpeedOut: "light Speed Out",
    pulse: "pulse",
    rollIn: "roll In",
    rollOut: "roll Out",
    rotateIn: "rotate In",
    rotateInDownLeft: "rotate In Down Left",
    rotateInDownRight: "rotate In Down Right",
    rotateInUpLeft: "rotate In Up Left",
    rotateInUpRight: "rotate In Up Right",
    rotateOut: "rotate Out",
    rotateOutDownLeft: "rotate Out Down Left",
    rotateOutDownRight: "rotate Out Down Right",
    rotateOutUpLeft: "rotate Out Up Left",
    rotateOutUpRight: "rotate Out Up Right",
    rubberBand: "rubber Band",
    shake: "shake",
    slideInDown: "slide In Down",
    slideInLeft: "slide In Left",
    slideInRight: "slide In Right",
    slideInUp: "slide In Up",
    slideOutDown: "slide Out Down",
    slideOutLeft: "slide Out Left",
    slideOutRight: "slide Out Right",
    slideOutUp: "slide Out Up",
    swing: "swing",
    tada: "tada",
    wobble: "wobble",
    zoomIn: "zoom In",
    zoomInDown: "zoom In Down",
    zoomInLeft: "zoom In Left",
    zoomInRight: "zoom In Right",
    zoomInUp: "zoom In Up",
    zoomOut: "zoom Out",
    zoomOutDown: "zoom Out Down",
    zoomOutLeft: "zoom Out Left",
    zoomOutRight: "zoom Out Right",
    zoomOutUp: "zoom Out Up",
};
// eslint-enable
