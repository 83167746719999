import * as Api from "@maxxton/cms-api";

export function isPwaEnabledForUrl(site: Api.Site & Api.WithId, url: string): boolean {
    const isPwaEnabled = !url.includes("/webmanager") ? !!site.enablePwa : false;
    return isPwaEnabled;
}

export async function isPwaEnabledForLocation(location: Location): Promise<boolean> {
    const site = await Api.SiteApi.findByHost({ host: location.hostname, projection: { sitemap: 0 } });
    const isPwaEnabled = site?.enablePwa ? !location.pathname.includes("/webmanager") : false;
    return isPwaEnabled;
}

export async function isPwaGeolocationEnabled(location: Location): Promise<boolean> {
    let isPwaEnabled = false;
    const site = await Api.SiteApi.findByHost({ host: location.hostname, projection: { sitemap: 0 } });
    if (site?.enablePwa && !location.pathname.includes("/webmanager")) {
        isPwaEnabled = !!site.pwaEnableGeolocation;
    }
    return isPwaEnabled;
}
