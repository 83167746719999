import * as React from "react";

import { PathComponent } from "../routing/routing.types";
import { PathMatch } from "react-router-dom";

interface IPageWrapper {
    pathName: string;
    childComponent: PathComponent | null;
}

class PageWrapperBase extends React.Component<IPageWrapper> {
    shouldComponentUpdate(nextProps: Readonly<IPageWrapper>): boolean {
        return nextProps.pathName !== this.props.pathName || !this.props.childComponent;
    }
    render() {
        const { childComponent } = this.props;
        if (childComponent) {
            const cached = childComponent;
            return cached(this.props as PathMatch<string> & IPageWrapper);
        }
        return null;
    }
}

export const PageWrapper = PageWrapperBase;
