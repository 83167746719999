import { Typesearch, TypesearchApi } from "@maxxton/cms-api";
import { categoryTableColumn, getTimeStampOptions } from "../columnSpec.util";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { FormSpec } from "../";
import { findAffectedAssets } from "../utils";
import namespaceList from "../../i18n/namespaceList";
import { widgetHierarchy } from "./";

export const resultsPanelSpec: FormSpec<Typesearch> = {
    id: "results-panel",
    name: getI18nLocaleObject(namespaceList.admin, "resultsPanel"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "resultsPanels"),
    api: TypesearchApi,

    permission: "webmanager.customresultspanel",
    display(resultsPanelModel: Typesearch): string {
        return resultsPanelModel.name;
    },
    async linkage(id: string) {
        let affectedModels = await findAffectedAssets(id, "resultsPanelList");
        if (affectedModels.affectedPages.length === 0 && affectedModels.affectedTemplates.length === 0) {
            affectedModels = await findAffectedAssets(id, "resultsPanelModelType");
        }
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedPages"),
                links: affectedModels.affectedPages,
                specId: "page",
            },
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedTemplates"),
                links: affectedModels.affectedTemplates,
                specId: "template",
            },
        ];
    },
    tableColumns: [{ name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" }, ...getTimeStampOptions(), categoryTableColumn()],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            widgetHierarchy<Typesearch, "root">({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "root",
                                rootTitle: getI18nLocaleObject(namespaceList.admin, "onResultsPanel"),
                                templatesTitle: getI18nLocaleObject(namespaceList.admin, "availableResultsPanelComponent"),
                                widgetType: "resultsPanel",
                                rootType: "multiple",
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "category"),
                    properties: [
                        [
                            {
                                variable: "category",
                                type: "category",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};
