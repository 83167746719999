import * as _ from "lodash";
import * as moment from "moment";

import {
    ApiCallOptions,
    ContractsRequest,
    Customer,
    CustomerFullAddress,
    EsReservationBillBalance,
    EsReservationRequest,
    EsReservationResult,
    EsReservationResultContainer,
    EsReservedResource,
    MxtsApi,
    MyEnvAuthTokenCustomerPayload,
    MyEnvUserType,
    PREDEFINED_ES_RESERVATION_REQUESTS,
    PagedResult,
    ReservationStatus,
    ResourceType,
    UnitRequest,
    createBaseEsReservationRequest,
    setCancelledReservationStatusOnEsRequest,
    setCustomerIdsOnEsRequest,
    setDistributionChannelIdsOnEsRequest,
    setExcludeDistributionChannelIdsOnEsRequest,
    setMaxDepartureDateOnEsRequest,
    setMinReservationStatusOnEsRequest,
    setReservationCategoriesOnEsRequest,
    setReservationIdsOnEsRequest,
    setReservedResourcesFilterOnEsRequest,
    setResortIdsOnEsRequest,
    setValidationStatusesOnEsRequest,
} from "@maxxton/cms-mxts-api";
import { DATE_FORMAT, MXTS } from "../../../utils/constants";
import { MyEnvReservation, MyEnvState } from "./myEnvState";
import { Unit, getMxtsEnv } from "../../../plugins/mxts";

import { ALL_SELECT_OPTION_VALUE } from "../../../plugins/dynamic/reservation/cancellation/reservationCancellation.enum";
import { ApiContext } from "../../../containers/cmsProvider.types";
import { DateUtil } from "../../../utils/date.util";
import { DynamicFilter } from "../dynamicFilter.types";
import { NumberMultiSelectOption } from "../../../plugins/mxts/selectOption.types";
import { ReservationDisplayType } from "../../../plugins/dynamic/reservation/container/reservationContainer.enum";
import { eyeCatcherConsoleLog } from "../../../utils/generic.util";
import { getValidMyEnvAuthToken } from "../../../utils/authToken.util";

export async function getMyEnvMainCustomer(env: ApiCallOptions): Promise<Customer | undefined> {
    const customerId = await getMainCustomerIdFromLoginToken();
    if (customerId) {
        return MxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: customerId }]);
    }
    return undefined;
}

export async function getCustomerAddress(customer: Customer | undefined, env: ApiCallOptions): Promise<CustomerFullAddress | undefined> {
    const { addresses } = customer || {};
    if (addresses?.length) {
        const { managerId } = addresses[0];
        return managerId ? MxtsApi.getCurrentAddress(env, { manager: managerId, view: "address" }) : undefined;
    }
    return undefined;
}

export async function getReservationsForCustomer(obtainCustomerReservationsParams: ObtainCustomerReservationsParams, env: ApiCallOptions): Promise<EsReservationResultContainer | undefined> {
    let esReservationRequest;
    if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.UPCOMING) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.upcoming(DateUtil.formatDate(new Date(), DATE_FORMAT.ELASTIC));
    } else if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.CHECKED_IN) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.checkedIn();
    } else if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.CHECKED_IN_BASED_ON_ARRIVAL) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.checkedInBasedOnArrival();
    } else if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.PAST_BASED_ON_DEPARTURE) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.pastBasedOnDeparture();
    } else if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.PAST) {
        if (obtainCustomerReservationsParams.showActivityReservations) {
            esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.pastWithActivities();
        } else {
            esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.past();
        }
        const today = moment();
        setMaxDepartureDateOnEsRequest(today.format(DATE_FORMAT.MXTS), esReservationRequest);
    } else if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.PAYMENT_DUE) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.paymentDue();
    } else if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.PRE_BOOKING) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.preBooking();
    } else if (obtainCustomerReservationsParams.displayType === ReservationDisplayType.ALL) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.all();
    } else {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.all();
    }

    esReservationRequest = { ...(esReservationRequest || {}), ...obtainCustomerReservationsParams.esReservationRequest };
    const customerIds: number[] = await getCustomerIdsFromLoginToken();
    if (!customerIds.length || !Object.keys(esReservationRequest).length) {
        return undefined;
    }
    if (obtainCustomerReservationsParams.showCancelledReservations) {
        setCancelledReservationStatusOnEsRequest(esReservationRequest);
    }
    setCustomerIdsOnEsRequest(customerIds, esReservationRequest);
    setValidationStatusesOnEsRequest(obtainCustomerReservationsParams.validationStatuses, esReservationRequest);
    setReservationCategoriesOnEsRequest(obtainCustomerReservationsParams.reservationCategories, esReservationRequest);
    setDistributionChannelFilterOnEsRequest(obtainCustomerReservationsParams, esReservationRequest);
    setReservationIdsOnEsRequest(obtainCustomerReservationsParams.reservationIds, esReservationRequest);
    setResortIdsOnEsRequest(obtainCustomerReservationsParams.resortIds, esReservationRequest);
    setReservedResourcesFilterOnEsRequest({ resourceIds: obtainCustomerReservationsParams.resourceIds }, esReservationRequest);
    return MxtsApi.getReservationEsForCustomer(env, esReservationRequest);
}

function setDistributionChannelFilterOnEsRequest(obtainCustomerReservationsParams: ObtainCustomerReservationsParams, esReservationRequest: EsReservationRequest) {
    const { distributionChannelIds, excludeDistributionChannelIds } = obtainCustomerReservationsParams;

    if (distributionChannelIds?.length) {
        const targetDcIds = distributionChannelIds?.length ? distributionChannelIds.filter((dcId) => !excludeDistributionChannelIds?.some((excludeDcId) => excludeDcId === dcId)) : [];
        setDistributionChannelIdsOnEsRequest(targetDcIds.length ? targetDcIds : [-1], esReservationRequest);
    } else if (excludeDistributionChannelIds?.length) {
        setExcludeDistributionChannelIdsOnEsRequest(excludeDistributionChannelIds, esReservationRequest);
    }
}

export async function getAccoUnitsForCustomer(props: {
    env: ApiCallOptions;
    contractUnitIds?: number[];
    disallowedContractUnitIds?: number[];
    reservationCategories?: NumberMultiSelectOption[];
    reservationsDisplayType?: ReservationDisplayType;
    displayUnitsWithoutContracts?: boolean;
    resourceIds?: number[];
    resortIds?: number[];
}): Promise<Unit[] | undefined> {
    const { env, contractUnitIds, disallowedContractUnitIds, reservationCategories, reservationsDisplayType, displayUnitsWithoutContracts, resourceIds, resortIds } = props;
    const customerId = await getMainCustomerIdFromLoginToken();
    const customerIds = await getCustomerIdsFromLoginToken();
    const reservationCategoryIds: number[] | undefined = reservationCategories?.map((categoryOption) => categoryOption.value);
    const allContractsExceptBlacklistAllowed = !!contractUnitIds?.some((allowedContracts) => ((allowedContracts as unknown) as string) === ALL_SELECT_OPTION_VALUE);
    let filteredUnitIds: number[] | undefined;
    if (reservationCategoryIds) {
        const reservationResult = await getReservationsForCustomer(
            {
                reservationCategories: reservationCategoryIds,
                displayType: reservationsDisplayType || ReservationDisplayType.UPCOMING,
                esReservationRequest: { size: MXTS.MAX_RESULTS },
            },
            env
        ).then((result) => result?.reservationResults);
        const reservedResources = reservationResult?.map((result) => result.reservedResources).flat(1);
        const filteredReservedResources = reservedResources?.filter((result) => result !== null);
        filteredUnitIds = filteredReservedResources?.map((result) => result?.unitId) as number[] | undefined;
        if (!filteredUnitIds?.length) {
            return [];
        }
    }

    const contractsRequest: ContractsRequest = { size: MXTS.MAX_RESULTS, ownerIds: customerIds };
    if (!displayUnitsWithoutContracts) {
        contractsRequest.isActiveContract = true;
    }
    const contracts = await MxtsApi.getContracts(env, contractsRequest);
    const activeContractUnitIds = _.uniq(contracts.content.map((contract) => contract.unitId));

    const unitParameterObject: UnitRequest =
        (contractUnitIds?.length && !allContractsExceptBlacklistAllowed) || reservationCategories?.length
            ? {
                  size: MXTS.MAX_RESULTS,
                  unitIds: contractUnitIds?.length ? contractUnitIds : filteredUnitIds,
              }
            : {
                  size: MXTS.MAX_RESULTS,
                  unitIds: activeContractUnitIds,
              };
    if (!displayUnitsWithoutContracts) {
        unitParameterObject.isWorkOrderObject = false;
    }
    if (resourceIds?.length) {
        unitParameterObject.resourceIds = resourceIds;
    }
    if (resortIds?.length) {
        unitParameterObject.resortIds = resortIds;
    }
    const unitResults =
        !activeContractUnitIds.length && !displayUnitsWithoutContracts && !unitParameterObject.unitIds?.length
            ? []
            : await MxtsApi.units(env, unitParameterObject).then((pagedResult: PagedResult<Unit>) => pagedResult.content);
    const filteredUnitResults = unitResults.filter((unit) => !disallowedContractUnitIds?.includes(unit.unitId));
    return filteredUnitResults;
}

export async function getCustomerIdsFromLoginToken(): Promise<number[]> {
    const customerData: MyEnvAuthTokenCustomerPayload | undefined = await getValidMyEnvAuthToken();
    if (!customerData?.customerIds?.length) {
        eyeCatcherConsoleLog("Not logged in!", "Warning");
        return [];
    }
    return customerData.customerIds;
}

export async function getMainCustomerIdFromLoginToken(): Promise<number | undefined> {
    const customerData: MyEnvAuthTokenCustomerPayload | undefined = await getValidMyEnvAuthToken();
    if (!customerData?.mainCustomerId) {
        eyeCatcherConsoleLog("Not logged in, or the customer doesn't exist in mxts", "Warning");
        return undefined;
    }
    return customerData.mainCustomerId;
}

export interface ObtainCustomerReservationsParams {
    esReservationRequest?: Partial<EsReservationRequest>;
    displayType?: ReservationDisplayType;
    validationStatuses?: number[];
    reservationCategories?: number[];
    reservationIds?: number[];
    distributionChannelIds?: number[];
    excludeDistributionChannelIds?: number[];
    resourceIds?: number[];
    resortIds?: number[];
    showActivityReservations?: boolean;
    showCancelledReservations?: boolean;
}

interface ObtainSelectedEsReservationParams {
    myEnvState?: MyEnvState;
    dynamicFilter?: DynamicFilter;
    isMyEnvWidget?: boolean;
    esReservationResult?: MyEnvReservation;
}

export async function getSelectedEsReservation(selectedEsReservationParams: ObtainSelectedEsReservationParams & { env: ApiCallOptions }): Promise<MyEnvReservation | undefined> {
    const { myEnvState, esReservationResult, env } = selectedEsReservationParams;
    if (esReservationResult?.reservation) {
        return esReservationResult;
    }

    const selectedReservationId = getSelectedReservationId(selectedEsReservationParams);
    if (selectedReservationId) {
        const selectedEsReservation: MyEnvReservation | undefined | null = myEnvState?.selectedReservation;
        if (selectedEsReservation?.reservation?.reservationId === selectedReservationId) {
            return selectedEsReservation;
        }
        const esReservationResultContainer = await getReservationsForCustomer({ reservationIds: [selectedReservationId] }, env);
        return esReservationResultContainer?.reservationResults?.length ? esReservationResultContainer?.reservationResults[0] : undefined;
    }
    return undefined;
}

export function getSelectedReservationId(selectedEsReservationParams: ObtainSelectedEsReservationParams): number | undefined {
    const { myEnvState, dynamicFilter, isMyEnvWidget, esReservationResult } = selectedEsReservationParams;
    if (esReservationResult?.reservation?.reservationId) {
        return esReservationResult?.reservation?.reservationId;
    } else if (isMyEnvWidget) {
        return myEnvState?.selectedReservationId;
    } else if (dynamicFilter) {
        return dynamicFilter.reservationId;
    }
    return undefined;
}

export function hasAnyDueAmount(reservation?: EsReservationResult | MyEnvReservation): boolean | undefined {
    const customerBillBalanceWithDue = !!reservation?.customer?.reservationBillBalance?.some(
        (billBalance: EsReservationBillBalance) => billBalance.reservationId === reservation.reservation.reservationId && billBalance.dueAmount > 0
    );
    return customerBillBalanceWithDue;
}

export function getUnitId(reservation?: EsReservationResult | MyEnvReservation): number | undefined {
    if (reservation && "selectedReservedResourceId" in reservation && reservation.selectedReservedResourceId) {
        return getSelectedAccoTypeReservedResource(reservation)?.unitId;
    }
    const accoTypeReservedResources: EsReservedResource[] = getAccoTypeReservedResources(reservation);
    return accoTypeReservedResources.find((accoTypeReservedResource: EsReservedResource) => accoTypeReservedResource.unitId)?.unitId;
}

export function getResourceId(reservation?: EsReservationResult | MyEnvReservation): number | undefined {
    if (reservation && "selectedReservedResourceId" in reservation && reservation.selectedReservedResourceId) {
        return getSelectedAccoTypeReservedResource(reservation)?.resourceId;
    }
    const accoTypeReservedResources: EsReservedResource[] = getAccoTypeReservedResources(reservation);
    return accoTypeReservedResources.find((accoTypeReservedResource: EsReservedResource) => accoTypeReservedResource.resourceId)?.resourceId;
}

export function getResortId(esReservationResult?: EsReservationResult | MyEnvReservation): number | undefined {
    return esReservationResult?.reservation?.resortId;
}

export function getAccoTypeReservedResources(reservation?: EsReservationResult | MyEnvReservation | null): EsReservedResource[] {
    if (reservation) {
        return reservation?.reservedResources?.filter((reservedResource: EsReservedResource) => reservedResource.type === ResourceType.ACCOMMODATIONTYPE) || [];
    }
    return [];
}

export function getSelectedAccoTypeReservedResource(reservation?: EsReservationResult | MyEnvReservation | null, dynamicFilter?: DynamicFilter): EsReservedResource | undefined {
    if (reservation && "selectedReservedResourceId" in reservation && reservation.selectedReservedResourceId) {
        return reservation.reservedResources?.find((accoTypeReservedResource: EsReservedResource) => accoTypeReservedResource.reservedResourceId === reservation.selectedReservedResourceId);
    }
    const allAccoTypes = getAccoTypeReservedResources(reservation);
    if (dynamicFilter?.resourceid && allAccoTypes.length > 1) {
        return allAccoTypes.find((accoType) => accoType.resourceId === dynamicFilter.resourceid);
    }
    return allAccoTypes.length ? allAccoTypes[0] : undefined;
}

export interface FlattenedMyEnvData {
    distributionChannelId?: number;
    reservationCategoryId?: number;
    startDate?: string;
    endDate?: string;
    resourceId?: number;
    unitId?: number;
    reservationId?: number;
    selectedAccoReservedResourceId?: number;
}

export function getFlattenedMyEnvData(myEnvState: MyEnvState, dynamicFilter?: DynamicFilter): FlattenedMyEnvData {
    const { distributionChannelId, reservationCategoryId, reservationId } = myEnvState.selectedReservation?.reservation || {};
    const selectedAccoTypeReservedResource = getSelectedAccoTypeReservedResource(myEnvState.selectedReservation, dynamicFilter);
    return {
        resourceId: selectedAccoTypeReservedResource?.resourceId,
        unitId: selectedAccoTypeReservedResource?.unitId,
        distributionChannelId,
        reservationCategoryId,
        startDate: selectedAccoTypeReservedResource?.startDate,
        endDate: selectedAccoTypeReservedResource?.endDate,
        reservationId,
        selectedAccoReservedResourceId: selectedAccoTypeReservedResource?.reservedResourceId,
    };
}

export async function getReservationsForSelectedDisplayType(
    obtainCustomerReservationsParams: ObtainCustomerReservationsParams,
    env: ApiCallOptions,
    reservationDisplayTypeValue: ReservationDisplayType[]
): Promise<EsReservationResult[]> {
    let otherReservationResults: EsReservationResult[] = [];
    await Promise.all(
        reservationDisplayTypeValue.map(async (type) => {
            try {
                const result = await getReservationsForCustomer({ ...obtainCustomerReservationsParams, displayType: type }, env);

                if (result?.reservationResults) {
                    otherReservationResults = [...result.reservationResults, ...otherReservationResults];
                }
            } catch (error) {
                console.error(`Error fetching reservations for display type ${type}:`, error);
            }
        })
    );
    const filterReservationResults = _.uniqBy(otherReservationResults, "reservation.reservationId");
    return filterReservationResults;
}

export async function getMyEnvUserTypes(customer: Customer, context: ApiContext): Promise<MyEnvUserType[]> {
    const userTypes = [];
    if (customer.owner) {
        userTypes.push(MyEnvUserType.OWNER);
    }
    if (customer.customerId && (await isNonRentableOwner([customer.customerId], context))) {
        userTypes.push(MyEnvUserType.NON_RENTABLE_OWNER);
    }
    if (customer.customerId && (await isCustomerWithFutureOrPastReservation([customer.customerId], context))) {
        userTypes.push(MyEnvUserType.CUSTOMER);
    }
    return userTypes;
}

export async function getMyEnvUserTypesFromCustomers(context: ApiContext): Promise<MyEnvUserType[]> {
    const env = await getMxtsEnv(context);
    const customerIds = await getCustomerIdsFromLoginToken();
    const userTypes: MyEnvUserType[] = [];
    await Promise.all(
        customerIds.map(async (customerId) => {
            if (customerId) {
                const customer = await context.mxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: customerId }]);
                (await getMyEnvUserTypes(customer, context)).forEach((userType) => userTypes.push(userType));
            }
        })
    );
    return [...new Set(userTypes)];
}

export async function getUserTypesOfLoggedInUser(myEnvState: MyEnvState, context: ApiContext): Promise<MyEnvUserType[]> {
    return myEnvState.mainCustomer ? getMyEnvUserTypes(myEnvState.mainCustomer, context) : [];
}

/**
 * We can come to know if a user is a non_rentable_owner if he has reservations on reservation categories with internetUserGroupId 2.
 */
async function isNonRentableOwner(customerIds: number[], context: ApiContext) {
    const esReservationRequest = createBaseEsReservationRequest();
    setMinReservationStatusOnEsRequest(ReservationStatus.PROVISIONAL, esReservationRequest);
    setCustomerIdsOnEsRequest(customerIds, esReservationRequest);
    const env = await getMxtsEnv(context);
    const nonRentableOwnerReservationCategories = await context.mxtsApi.getReservationCategories(env, {
        myEnvironmentExcluded: false,
        internetUserGroupId: 2,
        page: 0,
        size: MXTS.MAX_RESULTS,
    });
    if (nonRentableOwnerReservationCategories?.content?.length) {
        setReservationCategoriesOnEsRequest(
            nonRentableOwnerReservationCategories.content.map((category) => category.reservationCategoryId),
            esReservationRequest
        );
        esReservationRequest.size = 0;
        const emptyEsSearchResultContainer = await context.mxtsApi.getReservationEsForCustomer(env, esReservationRequest);
        if (emptyEsSearchResultContainer.reservationResultsSize > 0) {
            return true;
        }
    }
    return false;
}

async function isCustomerWithFutureOrPastReservation(customerIds: number[], context: ApiContext) {
    const esReservationRequest = createBaseEsReservationRequest();
    setMinReservationStatusOnEsRequest(ReservationStatus.PROVISIONAL, esReservationRequest);
    setCustomerIdsOnEsRequest(customerIds, esReservationRequest);
    const env = await getMxtsEnv(context);
    const myEnvReservationCategories = await MxtsApi.getReservationCategories(env, { myEnvironmentExcluded: false, page: 0, size: MXTS.MAX_RESULTS });
    const myEnvironmentRcIds = myEnvReservationCategories?.content?.map((category) => category.reservationCategoryId) || [];
    if (myEnvironmentRcIds.length) {
        setReservationCategoriesOnEsRequest(myEnvironmentRcIds, esReservationRequest);
    }
    esReservationRequest.size = 0;
    const emptyEsSearchResultContainer = await context.mxtsApi.getReservationEsForCustomer(env, esReservationRequest);
    if (emptyEsSearchResultContainer.reservationResultsSize > 0) {
        return true;
    }
    return false;
}

export function isUnitArchived(reservation?: EsReservationResult | MyEnvReservation | null, dynamicFilter?: DynamicFilter): boolean {
    const reservedResource = getSelectedAccoTypeReservedResource(reservation, dynamicFilter);
    return !!(reservedResource?.unitId && !reservedResource.unit);
}
export function isAccoTypeArchived(reservation?: EsReservationResult | MyEnvReservation | null, dynamicFilter?: DynamicFilter): boolean {
    const reservedResource = getSelectedAccoTypeReservedResource(reservation, dynamicFilter);
    return !!(reservedResource?.resourceId && !reservedResource.resource);
}

export const filterImpliedReservedResources = (reservationResults: EsReservationResult[]) =>
    reservationResults.map((reservationResult) => ({
        ...reservationResult,
        reservedResources: (reservationResult.reservedResources || []).filter((reservedResource) => !reservedResource.impliesId),
    }));
