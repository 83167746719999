import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { AmenityCategory, AmenityLink, ImageDetailedView, MxtsApi, PagedResult } from "@maxxton/cms-mxts-api";
import { AmenityIcons, IconLibraryId } from "../../mxts/amenity/Amenity";
import { WebContent as CmsApiWebContent, WithId } from "@maxxton/cms-api";
import { Content, getContent, isContentFilterChanged } from "../../../utils/content.util";
import { Resort, getMxtsEnv } from "../../mxts";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { UNIT, getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getWebContentById, renderNoResultsFoundContent, renderTemplateById } from "../containerWidget.util";
import { isEqual, sortBy } from "lodash";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { AddAmenitiesModal } from "./AmenitiesEditor";
import { AmenitiesEditorModalContent } from "./AmenitiesEditorModalContent";
import { AmenityLinkCategory } from "./amenitiesEditor.util";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Button } from "reactstrap";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { GetEditableModal } from "../container/myenv/myEnvContainerEditable.util";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MXTS } from "../../../utils/constants";
import Masonry from "react-masonry-css";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { RecaptchaProviderEnabler } from "../../page/guestInterfaceWidget/recaptcha/reCaptcha.util";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { getAmenityIcons } from "../../mxts/amenity/mxts";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderTooltip } from "../../../utils/amenity.util";
import withChildrenProps from "../../../utils/withChildrenProps.utils";
import withEditModal from "../../../components/frontend-editing/WithEditModal";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

const MasonryWithChildrenProps = withChildrenProps(Masonry);

interface AmenitiesProps extends AmenitiesBaseProps, AmenitiesStoreProps {}

interface AmenitiesBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    warmupState?: AmenitiesState;
    childOptions?: DynamicMyEnvContainerChildOptions;
}

interface AmenitiesState {
    amenityCategories: AmenityLinkCategory[];
    amenityImages: ImageDetailedView[];
    disableWidget: boolean;
    amenityIcons: AmenityIcons[];
    isEditModalOpen?: boolean;
    isAddAmenitiesModalOpen?: boolean;
    disableLinkAmenityButton?: boolean;
    fallbackWebContent?: CmsApiWebContent & WithId;
    fallbackTemplate?: JSX.Element[];
}

interface AmenitiesStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
}

export type AmenitiesStateHandler = StateHandler<AmenitiesProps, AmenitiesState>;

export class AmenitiesWidget extends React.Component<AmenitiesProps, AmenitiesState> {
    public static async warmupCache(props: AmenitiesProps): Promise<AmenitiesState> {
        return warmupState(props, AmenitiesWidget.defaultState(props), async (stateHandler) => {
            await AmenitiesWidget.fetchAmenities(stateHandler);
        });
    }

    private static defaultState(props: AmenitiesProps): AmenitiesState {
        return {
            amenityCategories: [],
            disableWidget: true,
            amenityIcons: [],
            amenityImages: [],
        };
    }

    constructor(props: AmenitiesProps) {
        super(props);
        this.state = {
            ...AmenitiesWidget.defaultState(props),
            ...(props.warmupState || {}),
        };
    }

    public componentDidMount() {
        AmenitiesWidget.fetchAmenities(this);
        this.setState({ disableWidget: !isClientLoggedIn() });
        this.loadNoResultFoundFallback();
    }

    private static async getAmenityImagesForAmenitiesWithoutIcon(params: {
        linkedAmenities: AmenityLink[];
        context: CMSProviderProperties;
        amenityIcons: AmenityIcons[];
    }): Promise<ImageDetailedView[]> {
        const { linkedAmenities, context, amenityIcons } = params;
        const allImageManagerIds: number[] = linkedAmenities
            .filter((linkedAmenity) => !amenityIcons.some((amenityIcon) => amenityIcon.amenityId === linkedAmenity.amenityId))
            .map((linkedAmenity) => linkedAmenity.amenity.imageManagerId)
            .filter((imageManagerId): imageManagerId is number => !!imageManagerId);
        const env = await getMxtsEnv(context, context.currentLocale.code);
        if (!allImageManagerIds.length) {
            return [];
        }
        const allImages = await context.mxtsApi.imagesForAllManagerId(env, {
            imageManagerIds: allImageManagerIds,
            size: allImageManagerIds.length,
            view: "detail",
        });
        return allImages || [];
    }

    public componentDidUpdate(prevProps: AmenitiesProps) {
        if (isContentFilterChanged(prevProps, this.props) || !isEqual(this.props.options, prevProps.options)) {
            AmenitiesWidget.fetchAmenities(this);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { options, context, childOptions, className } = this.props;
        const { isEditModalOpen, isAddAmenitiesModalOpen, disableLinkAmenityButton, fallbackWebContent, fallbackTemplate, amenityCategories } = this.state;
        const asterisk = "*";
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        const { currentLocale, site } = context;
        const { amenityFieldMultiSelect, amenityCategoryFieldMultiSelect, enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor } = options;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        if (hideWidget === null) {
            return null;
        }
        const gridStyles =
            options.gridMasonry === "gridView"
                ? // eslint-disable-next-line max-len
                  (options.columns ? "col-sm-" + options.columns : "") +
                  " " +
                  (options.columnsResp ? "col-" + options.columnsResp : "col") +
                  " " +
                  (options.columnsTab ? "col-md-" + options.columnsTab : "") +
                  " " +
                  (options.columnsDesk ? "col-lg-" + options.columnsDesk : "") +
                  " " +
                  (options.columnsExtraLargeDesk ? "col-xl-" + options.columnsExtraLargeDesk : "")
                : "";

        const amenities = amenityCategories.map((amenityCategory?: AmenityLinkCategory, index?) => (
            <div
                // eslint-disable-next-line max-len
                className={classNames("amenity-list", gridStyles, { ["background-color-" + options.listBackgroundColor]: !!options?.listBackgroundColor })}
                key={index}
                style={{ background: options.listBackgroundColor && options.listBackgroundColor.includes("rgba") ? options.listBackgroundColor : "" }}
            >
                <div className={`amenity-category amenity-categoryId-${amenityCategory?.amenityCategoryId}`}>
                    {amenityCategory?.amenities && amenityCategory?.amenities.length > 0 && amenityCategory.name}
                    {options.showAmenityCategoryInfo && renderTooltip(amenityCategory?.name, amenityCategory?.description, amenityCategoryFieldMultiSelect)}
                </div>
                <ul className={options.addIcon ? "amenity-list-wrap" : "default-amenity-list"}>
                    {amenityCategory?.amenities?.length &&
                        amenityCategory?.amenities?.map((amenity: AmenityLink) => (
                            <li
                                key={`icon-lib-${index}-${amenity.amenityId}`}
                                className={classNames({
                                    "icon-library": options.addIcon,
                                    "list-item": !options.addIcon,
                                    ["color-" + options.iconColor]: options.addIcon && options.iconColor?.includes("theme"),
                                    ["icon-" + options.iconColor]: options.addIcon && options.iconColor?.includes("theme"),
                                })}
                                style={{ color: options.addIcon && options.iconColor?.includes("rgba") ? options.iconColor : "" }}
                            >
                                {this.getIconsFromLibrary(amenity)}
                                {this.amenitiesContent(options, amenity, amenityCategory, asterisk)}
                                {options.showAmenityInfo && renderTooltip(amenity.amenity.name, amenity.amenity.description, amenityFieldMultiSelect)}
                            </li>
                        ))}
                </ul>
            </div>
        ));

        const breakpointColumnsObj = {
            default: options.amountOfMasonryGrids,
            1024: options.amountOfMasonryGrids,
            768: 2,
            480: 1,
        };
        return (
            /* jscpd:ignore-start */
            <ErrorBoundary>
                {!amenityCategories.length &&
                    (fallbackWebContent || fallbackTemplate?.length) &&
                    renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context })}
                <div className={`display-amenities ${className} ${hideWidget} ${options.gridMasonry === "gridView" ? "row" : ""}`}>
                    <LocalizedTitleAndLabel
                        localizedTitle={localizedWidgetTitle}
                        enableWidgetTitle={enableWidgetTitle}
                        useTitleHeadings={useTitleHeadings}
                        styleWidgetTitle={styleWidgetTitle}
                        className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                        style={classNames(fontColor?.includes("rgba") && fontColor)}
                    />
                    {options.gridMasonry === "masonryView" ? (
                        <MasonryWithChildrenProps
                            breakpointCols={breakpointColumnsObj}
                            className={`${options.gridMasonry === "masonryView" ? "my-masonry-grid" : ""}`}
                            columnClassName={`${options.gridMasonry === "masonryView" ? "my-masonry-grid_column" : "normal-layout"}`}
                        >
                            {amenities}
                        </MasonryWithChildrenProps>
                    ) : (
                        amenities
                    )}
                    {childOptions?.isEditable && (
                        <React.Fragment>
                            <div className="amenities-edit-buttons-wrapper">
                                <Button className="amenities-edit-button" onClick={this.toggleModal}>
                                    {getI18nLocaleString(namespaceList.amenitiesWidget, "editAmenities", currentLocale, site)}
                                </Button>
                                <Button className="amenities-add-button" onClick={this.addAmenitiesModalToggle}>
                                    {getI18nLocaleString(namespaceList.amenitiesWidget, "addAmenities", currentLocale, site)}
                                </Button>
                            </div>
                            <div className="edit-amenities-overlay"></div>
                        </React.Fragment>
                    )}
                    {
                        <RecaptchaProviderEnabler>
                            <GetEditableModal
                                isEditModalOpen={isEditModalOpen}
                                modalBodyData={() => (
                                    <AmenitiesEditorModalContent
                                        amenityCategories={amenityCategories}
                                        childOptions={childOptions}
                                        context={context}
                                        isEditModalOpen={isEditModalOpen}
                                        amenitiesStates={this}
                                    />
                                )}
                                modalHeading={getI18nLocaleString(namespaceList.amenitiesWidget, "editAmenities", currentLocale, site)}
                                toggleModal={this.toggleModal}
                                sizeOfModal="lg"
                            />
                        </RecaptchaProviderEnabler>
                    }
                    {
                        <RecaptchaProviderEnabler>
                            <AddAmenitiesModal
                                context={context}
                                childOptions={childOptions}
                                isAddAmenitiesModalOpen={!!isAddAmenitiesModalOpen}
                                addAmenitiesModalToggle={this.addAmenitiesModalToggle}
                                disableLinkAmenityButton={!!disableLinkAmenityButton}
                                setDisableLinkAmenityButton={this.setDisableLinkAmenityButton}
                                amenitiesStates={this}
                                amenityCategories={amenityCategories}
                            />
                        </RecaptchaProviderEnabler>
                    }
                </div>
            </ErrorBoundary>
            /* jscpd:ignore-end */
        );
    }

    public static async fetchAmenities(stateHandler: AmenitiesStateHandler, clearCache?: boolean): Promise<void> {
        const { options, context } = stateHandler.props;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const content = (await getContent({ ...stateHandler.props, env, skipContentTypeSelectorCheck: true })) as Exclude<Content, Resort[]>;
        const amenityManagerId = (content as Exclude<Content, Activity | Resort[]>)?.amenityManagerId;
        if (amenityManagerId) {
            if (clearCache) {
                MxtsApi.clearCacheAmenityLinks((cacheKey: string) => cacheKey.includes("managerId") && cacheKey.includes(amenityManagerId.toString()));
            }
            let linkedAmenities: AmenityLink[] = await context.mxtsApi
                .amenityLinks(env, {
                    size: MXTS.MAX_RESULTS,
                    sort: "priority",
                    view: "detail",
                    orderByCategorySequence: options.orderByCategorySequence,
                    origins: options.useParentAmenities ? ["SELF", "PARENT"] : ["SELF"],
                    managerId: amenityManagerId,
                })
                .then((value: PagedResult<AmenityLink>) => value.content);
            linkedAmenities = sortBy(linkedAmenities, ["priority", "name"]);

            // Making an array of unique amenityCategoryIds
            let amenityCategoryIds: number[] = [];
            if (!options.filterAmenitiesByCategory) {
                const ids: number[] = [].concat.apply(
                    [],
                    linkedAmenities.map((amenityLink: AmenityLink) => amenityLink.amenity.amenityCategoryIds)
                );
                amenityCategoryIds = Array.from(new Set(ids));
            } else if (options.amenityCategories?.length) {
                amenityCategoryIds = options.amenityCategories.map((category: NumberMultiSelectOption) => category.value);
            }

            let amenityCategories: AmenityCategory[] = [];
            let amenitySequenceCategories;
            if (linkedAmenities) {
                amenityCategories = await DomainObjectUtil.getAmenityCategoriesByIds(
                    context.mxtsApi,
                    amenityCategoryIds.filter((id: number) => id !== null),
                    {
                        size: MXTS.MAX_RESULTS,
                        internetSearchGroup: options.filterAmenitiesByCategory ? undefined : !!options.internetSearchGroup,
                        isInternalUse: false,
                        sort: "priority",
                    },
                    { sort: ["priority", "sequence", "name"] },
                    env
                );
            }
            if (options.orderByCategorySequence) {
                amenitySequenceCategories = amenityCategoryIds
                    .map((amenityCategoryId) => amenityCategories.find((amenity) => amenity.amenityCategoryId === amenityCategoryId))
                    .filter((element) => element);
            }
            // Adding the amenities in each amenityCategory with respect to amenityLinks
            const amenityCategoriesClone: AmenityLinkCategory[] = JSON.parse(JSON.stringify(amenitySequenceCategories || amenityCategories));
            const alreadyShownAmenityCodes: string[] = [];
            amenityCategoriesClone.forEach((category) => {
                category.amenities = [];
                linkedAmenities.forEach((link) => {
                    if (
                        link.amenity.amenityCategoryIds.indexOf(category.amenityCategoryId) > -1 &&
                        category.amenities !== undefined &&
                        !(alreadyShownAmenityCodes.indexOf(link.amenity.identifier) > -1) &&
                        (link.endDate === null || link.endDate.toString() > moment().format("YYYY-MM-DD"))
                    ) {
                        alreadyShownAmenityCodes.push(link.amenity.identifier);
                        category.amenities.push(link);
                    }
                });
            });
            const amenityIcons = await getAmenityIcons(linkedAmenities);
            const amenityImages = await AmenitiesWidget.getAmenityImagesForAmenitiesWithoutIcon({ linkedAmenities, amenityIcons, context });
            stateHandler.setState({ amenityImages, amenityCategories: amenityCategoriesClone.filter((category: AmenityLinkCategory) => category.amenities?.length), amenityIcons });
        } else {
            stateHandler.setState({ amenityCategories: [], amenityIcons: [], amenityImages: [] });
        }
    }

    public toggleModal = () => {
        this.setState({
            isEditModalOpen: !this.state.isEditModalOpen,
        });
    };

    public addAmenitiesModalToggle = () => {
        this.setState({
            isAddAmenitiesModalOpen: !this.state.isAddAmenitiesModalOpen,
        });
        this.setDisableLinkAmenityButton(true);
    };

    public setDisableLinkAmenityButton = (disableLinkAmenityButton: boolean) => {
        this.setState({ disableLinkAmenityButton });
    };

    private getAmenityName(amenity: AmenityLink): string {
        if (amenity.type === "NUMBER" && amenity.numberValue != null) {
            return amenity.name;
        }
        return amenity.name && amenity.name.charAt(0).toUpperCase() + amenity.name.substr(1).toLowerCase();
    }

    private getIconsFromLibrary = (amenity: AmenityLink) => {
        const { options } = this.props;
        // Check icon set is from font-awesome library
        if (amenity.amenity.iconLibraryId === IconLibraryId.FontAwesome) {
            return (
                options.addIcon && (
                    <div className="icon-library__font-awesome">
                        <FontAwesome name={amenity.amenity.iconName?.split(/-(.*)/)[1] || ""} className="font-awesome__icon" />
                    </div>
                )
            );
            // Check icon set is from stream-line library
        } else if (amenity.amenity.iconLibraryId === IconLibraryId.StreamLine) {
            const amenityIcon = this.state.amenityIcons.find((amenityLink) => amenityLink.amenityId === amenity.amenityId)?.amenityIcon;
            const iconColor = options.iconColor?.includes("rgba") ? options.iconColor : undefined;
            return (
                options.addIcon && (
                    <div className="icon-library__stream-line">
                        {amenityIcon && (
                            <div
                                className={`stream-line__svg ${
                                    options.iconColor?.includes("theme") ? `color-${options.iconColor}` : !options.iconColor?.includes("rgba") ? "icon-" + options.iconColor : ""
                                }`}
                                style={{ color: iconColor, fill: iconColor }}
                                dangerouslySetInnerHTML={{ __html: amenityIcon }}
                            />
                        )}
                    </div>
                )
            );
        } else if (!amenity.amenity.iconLibraryId) {
            const amenityImage = this.state.amenityImages.find((amenityImage) => amenityImage.imageManagerIds?.some((imageManagerId) => imageManagerId === amenity.amenity.imageManagerId));
            return (
                options.addIcon &&
                amenityImage && (
                    <div className="icon-image">{amenityImage && <img src={amenityImage.urls.small.replace("t_newyse_small", "t_mcms_small")} className={classNames("amenity-image")} />}</div>
                )
            );
        }
        return null;
    };

    private loadNoResultFoundFallback = async () => {
        const { options, context } = this.props;
        this.setState({ fallbackWebContent: await getWebContentById(options.fallbackWebContentId), fallbackTemplate: await renderTemplateById(options.fallbackTemplateId, context) });
    };

    private amenitiesContent = (options: WidgetOptions, amenity: AmenityLink, amenityCategory: AmenityLinkCategory, asterisk: string) =>
        options.showAmenityMetric ? (
            <React.Fragment>
                <span className="amenity-name">{this.getAmenityName(amenity)}</span>
                {amenity.type === "NUMBER" && amenity.numberValue ? <span className="amenity-count">{`: ${amenity.numberValue} ${amenity?.metric || ""}`}</span> : null}
                {amenity?.origin === "SELF" && options.contentType === UNIT && <span className="self-amenity">{asterisk}</span>}
            </React.Fragment>
        ) : (
            <React.Fragment>
                {amenity.type === "NUMBER" && amenity.numberValue ? <span className="amenity-count">{`${amenity.numberValue} ${amenity?.metric || ""} `}</span> : null}
                <span className="amenity-name">{this.getAmenityName(amenity)}</span>
                {amenity?.origin === "SELF" && options.contentType === UNIT && <span className="self-amenity">{asterisk}</span>}
            </React.Fragment>
        );
}

function mapStateToProps(state: State): AmenitiesStoreProps {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}

const AmenitiesWithEditModal = withEditModal(AmenitiesWidget);

const Amenities = connect<AmenitiesStoreProps>(mapStateToProps)(AmenitiesWithEditModal);

export const DynamicAmenities = wrapProps<AmenitiesBaseProps>(withGoogleReCaptcha(Amenities));
