import * as React from "react";

import { WebContent as CmsApiWebContent, WithId } from "@maxxton/cms-api";
import { SomeInputSpec, templateSpec } from "../../form-specs";
import { getWebContentById, renderNoResultsFoundContent, renderTemplateById } from "../../plugins/dynamic/containerWidget.util";

import { CMSProviderProperties } from "../../containers/cmsProvider.types";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import { webcontentSpecWithoutPreview } from "../../form-specs/models/autocompleteWebContent";

export const noContentFoundInputSpec = <T extends NoContentFoundWidgetOptions>(): Array<SomeInputSpec<T, keyof T>> => [
    {
        variable: "noContentFoundWebContentId",
        label: getI18nLocaleObject(namespaceList.admin, "noContentFoundWebContentId"),
        type: "autocomplete",
        refType: webcontentSpecWithoutPreview,
        placeholder: getI18nLocaleObject(namespaceList.admin, "noContentFoundWebContentPlaceholder"),
    },
    {
        variable: "noContentFoundTemplateId",
        label: getI18nLocaleObject(namespaceList.admin, "noContentFoundTemplateId"),
        type: "autocomplete",
        refType: templateSpec,
        placeholder: getI18nLocaleObject(namespaceList.admin, "noContentFoundTemplatePlaceholder"),
    },
];

export interface NoContentFoundWidgetOptions {
    noContentFoundWebContentId?: string;
    noContentFoundTemplateId?: string;
}

export function NoContentsFound(props: { widgetOptions: NoContentFoundWidgetOptions; context: CMSProviderProperties }) {
    const {
        widgetOptions: { noContentFoundTemplateId, noContentFoundWebContentId },
        context,
    } = props;
    const [noUnitsFoundWebContent, setNoUnitsFoundWebContent] = React.useState<CmsApiWebContent & WithId>();
    const [noUnitsFoundTemplate, setNoUnitsFoundTemplate] = React.useState<JSX.Element[]>();
    const [isContentObtained, setContentObtained] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            if (!isContentObtained) {
                setNoUnitsFoundWebContent(await getWebContentById(noContentFoundWebContentId));
                setNoUnitsFoundTemplate(await renderTemplateById(noContentFoundTemplateId, context));
                setContentObtained(true);
            }
        })();
    }, [noContentFoundTemplateId, noContentFoundWebContentId, isContentObtained]);

    return <React.Fragment>{renderNoResultsFoundContent({ noResultsFoundWebContent: noUnitsFoundWebContent, noResultsFoundTemplate: noUnitsFoundTemplate, context })}</React.Fragment>;
}
