export enum FilterType {
    TIPS_AND_TRIPS = "tipsAndTrips",
    ACTIVITES = "activities",
    BLOG_POSTS = "blogPosts",
    PAGES = "pages",
    WEB_CONTENTS = "webContents",
}

export enum DisplayType {
    LIST = "displayAsList",
    DROPDOWN = "displayAsDropdown",
    POPUP = "displayAsPopup",
    ACCORDION = "displayAsAccordion",
}

export enum CategoryOptions {
    tipsAndTrips = "tipsAndTripsCategory",
    activities = "activitiesCategory",
}
