import { LogEntry, LogWriter } from "@maxxton/cms-api";
import { getClientIdFromToken, getClientName } from "../components/admin/utils";
import { isClientSide, isServerSide } from "./generic.util";
import { reportError, reportWarn } from "./report.utils";

import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { DEVICE } from "ua-parser-js";
import { StringUtil } from "./string.util";
import { getLocation } from "./window.util";

export class WebLogWriter implements LogWriter {
    enabled = true;

    public log(entry: LogEntry): void {
        fetch("/log-data", {
            method: "POST",
            body: JSON.stringify({
                ...entry,
                username: getClientName(),
                clientId: getClientIdFromToken(),
                timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
            }),
            headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
        });
    }
}

export function getDeviceName(context: CMSProviderProperties): string {
    const device = context.device;
    return device?.type !== DEVICE.MOBILE && device?.type !== DEVICE.TABLET ? "desktop" : device?.type || "";
}

export const logUncaughtError = (error: Error | {}, ...restArgs: any[]) => {
    try {
        if (!isReactHydrateError(error, restArgs) && !isRequestAbortedError(error)) {
            (isConsideredWarning(error) ? reportWarn : reportError)(
                !error && restArgs.length ? restArgs[0] : error,
                getLocation()?.href,
                `Uncaught ${isServerSide() ? "server-side" : "client-side"} Error`
            );
        }
    } catch (stringifyError) {
        reportError(restArgs?.length ? restArgs[0] : {}, getLocation()?.href, "Failed to report actual error");
    }
};

function isReactHydrateError(error: Error | {}, restArgs: any[]) {
    let stringifiedError: string | undefined = StringUtil.convertErrorObjToString(error);
    if (!stringifiedError) {
        const firstRestArg = restArgs.length ? restArgs[0] : undefined;
        stringifiedError = StringUtil.convertErrorObjToString(firstRestArg);
    }
    if (
        stringifiedError?.includes("Minified React error") ||
        stringifiedError?.includes("There was an error while hydrating") ||
        stringifiedError?.includes("Hydration failed because the initial UI does not match what was rendered on the server")
    ) {
        return true;
    } else if (stringifiedError?.includes('"isTrusted":true') && stringifiedError?.includes("Script error.")) {
        return true;
    }
    return false;
}

export function isRequestAbortedError(error: Error | Record<string, unknown>): boolean {
    return error && typeof error === "object" && "name" in error && error.name === "AbortError";
}

export function isConsideredWarning(error: Error | Record<string, unknown>): boolean {
    // Consider "failed to fetch"/"load failed" errors as warnings because it could also be the client device lost internet connection for a moment.
    // It could also be some mcms service was unresponsive, but it's quite difficult to figure out if this was the cause.
    // We consider it an error if it happens at server side because the server shouldn't be having internet connection issues or anything like that.
    return isClientSide() && (isFailedToFetchError(error) || isLoadFailedError(error) || isNetworkError(error));
}

export function isFailedToFetchError(error: Error | Record<string, unknown>): boolean {
    return isTypeError(error) && error.message === "Failed to fetch";
}

export function isLoadFailedError(error: Error | Record<string, unknown>): boolean {
    return isTypeError(error) && error.message === "Load failed";
}

export function isNetworkError(error: Error | Record<string, unknown>): boolean {
    return isTypeError(error) && typeof error.message === "string" && error.message.includes("NetworkError");
}

function isTypeError(error: Error | Record<string, unknown>) {
    return error && typeof error === "object" && "name" in error && error.name === "TypeError" && "message" in error;
}
