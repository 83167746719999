import { Action as ReduxAction } from "redux";

export enum ActionType {
    MyEnv = "MyEnv",
    AddOns = "AddOns",
    Addition = "Addition",
    Availability = "Availability",
    Bill = "Bill",
    ChoiceAndPrice = "ChoiceAndPrice",
    ClearResultsPanelOpenLinkedTabState = "ClearResultsPanelOpenLinkedTabState",
    CreateReservation = "CreateReservation",
    DynamicFilterReady = "DynamicFilterReady",
    DynamicFilter = "DynamicFilter",
    FilterChangeInit = "FilterChangeInit",
    FilterChange = "FilterChange",
    FormEdited = "FormEdited",
    GuestForm = "GuestForm",
    MxtsDataAccessToken = "MxtsDataAccessToken",
    PageLock = "PageLock",
    PrevNextClick = "PrevNextClick",
    Reservation = "Reservation",
    ReservationCustomer = "ReservationCustomer",
    ReservationUpdated = "ReservationUpdated",
    Resort = "Resort",
    ResultsPanelOpenLinkedTab = "ResultsPanelOpenLinkedTab",
    RevealerClick = "RevealerClick",
    SearchEvent = "SearchEvent",
    SelectedAddOns = "SelectedAddOns",
    SetReservationState = "SetReservationState",
    SpecialSearch = "SpecialSearch",
    SyncTempSelectedAddOns = "SyncTempSelectedAddOns",
    TempSelectedAddOns = "TempSelectedAddOns",
    UpdateBill = "UpdateBill",
    UpdateReservation = "UpdateReservation",
    LoadReservationReduxData = "LoadReservationReduxData",
    UserInterface = "UserInterface",
    AmenitiesFetch = "AmenitiesFetch",
    SessionCountDown = "SessionCountDown",
    FrontendPageEditPage = "FrontendPageEditPage",
    travelPartyDetails = "travelPartyDetails",
    priceMatrix = "priceMatrix",
    activityPlanner = "activityPlanner",
    UserLoginForm = "userLoginForm",
    LinkedWidgets = "linkedWidgets",
}

export interface Action extends ReduxAction<ActionType> {
    payload?: any;
}
