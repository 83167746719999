import * as moment from "moment";

function processRelativeTime(num: number, withoutSuffix: any, key: string, isFuture: boolean) {
    const format: any = {
        m: ["eine Minute", "einer Minute"],
        h: ["eine Stunde", "einer Stunde"],
        d: ["ein Tag", "einem Tag"],
        dd: [num + " Tage", num + " Tagen"],
        M: ["ein Monat", "einem Monat"],
        MM: [num + " Monate", num + " Monaten"],
        y: ["ein Jahr", "einem Jahr"],
        yy: [num + " Jahre", num + " Jahren"],
    };
    return withoutSuffix ? format[key][0] : format[key][1];
}

export const deLocaleSpecification: moment.LocaleSpecification = {
    months: "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split("_"),
    monthsShort: "Jan._Febr._Mrz._Apr._Mai_Jun._Jul._Aug._Sept._Okt._Nov._Dez.".split("_"),
    monthsParseExact: true,
    weekdays: "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split("_"),
    weekdaysShort: "so._mo._di._mi._do._fr._sa.".split("_"),
    weekdaysMin: "so_mo_di_mi_do_fr_sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD.MM.YYYY",
        LL: "D. MMMM YYYY",
        LLL: "D. MMMM YYYY HH:mm",
        LLLL: "dddd, D. MMMM YYYY HH:mm",
    },
    calendar: {
        sameDay: "[heute um] LT [Uhr]",
        sameElse: "L",
        nextDay: "[morgen um] LT [Uhr]",
        nextWeek: "dddd [um] LT [Uhr]",
        lastDay: "[gestern um] LT [Uhr]",
        lastWeek: "[letzten] dddd [um] LT [Uhr]",
    },
    relativeTime: {
        future: "in %s",
        past: "vor %s",
        s: "ein paar Sekunden",
        m: processRelativeTime,
        mm: "%d Minuten",
        h: processRelativeTime,
        hh: "%d Stunden",
        d: processRelativeTime,
        dd: processRelativeTime,
        M: processRelativeTime,
        MM: processRelativeTime,
        y: processRelativeTime,
        yy: processRelativeTime,
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: (num: number) => "%d.",
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
};
