// This reducer can be used to store small values that are needed in the user interface, but don't really fit in any of the existing reducers
// When this file becomes to big, it should be split up
import { UserInterfaceAction, UserInterfaceActionType } from "../actions/userInterfaceAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface UserInterfaceState {
    criteriaPanel?: {
        selectedCriteriaCount?: number;
    };
    resultLayoutViewOptions?: {
        resultLayoutDisplayType?: ResultLayoutDisplayType;
    };
    reviewRatingsWidget?: {
        maxRating?: number;
    };
    specialSearchFacet?: {
        isSpecialModalOpen?: boolean;
    };
}

export type ResultLayoutDisplayType = "grid-view-map" | "list-view-map" | "map-view" | "extended-map" | "list-view" | "grid-view" | undefined;

// eslint-disable-next-line max-len
export const userInterfaceReducer: Reducer<UserInterfaceState> = (state: UserInterfaceState = {}, action: UserInterfaceAction): UserInterfaceState => {
    switch (action.type) {
        case ActionType.UserInterface:
            switch (action.actionType) {
                case UserInterfaceActionType.UPDATE_CRITERIA_COUNT:
                    return {
                        ...state,
                        criteriaPanel: {
                            selectedCriteriaCount: action.payload.criteriaPanel?.selectedCriteriaCount,
                        },
                    };
                case UserInterfaceActionType.UPDATE_RESULT_LAYOUT_DISPLAY_TYPE:
                    return {
                        ...state,
                        resultLayoutViewOptions: { ...state.resultLayoutViewOptions, resultLayoutDisplayType: action.payload.resultLayoutViewOptions?.resultLayoutDisplayType },
                    };
                case UserInterfaceActionType.UPDATE_MAX_RATING:
                    return {
                        ...state,
                        reviewRatingsWidget: { ...state.reviewRatingsWidget, maxRating: action.payload.reviewRatingsWidget?.maxRating },
                    };
                case UserInterfaceActionType.TOGGLE_SPECIAL_MODAL:
                    return {
                        ...state,
                        specialSearchFacet: { ...state.specialSearchFacet, isSpecialModalOpen: action.payload.specialSearchFacet?.isSpecialModalOpen },
                    };
            }

        default:
            return state;
    }
};
