import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as moment from "moment";

import {
    AuthApi,
    CmsApi,
    FormApi,
    FormResponse,
    FormResponseApi,
    IExportFormDate,
    Locale,
    LocaleApi,
    LocalizedCardContent,
    LocalizedContent,
    OptionsApi,
    Page,
    PageApi,
    Post,
    PostApi,
    Site,
    SiteApi,
    SiteGroupApi,
    Widget,
    WithId,
    getPageUrl,
} from "@maxxton/cms-api";
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CustomFieldResult, CustomerTitle, MxtsApi, flatten } from "@maxxton/cms-mxts-api";
import { DATE_FORMAT, LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { DynamicData, EditMode } from "./form.types";
import { DynamicDataParams, getDynamicData, getDynamicDataListMyEnv } from "../../utils/content.util";
import { EmailAlertMessageType, sendFormEmail } from "../../utils/form.util";
import { FormEditAction, FormEditActionType } from "../../redux/actions/FormAlertAction";
import { FormSpec, InputSpec, InputSpecSelect, SomeInputSpec } from "../../form-specs";
import { GenericFormButtons, SubmitButtonOptions } from "./buttons";
import { IWithGoogleReCaptchaProps, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { LocalizedWidgetOptions, WidgetOptions } from "../../plugins/form/formView";
import { MultiSelectDynamicData, checkDuplicateCategoryItems, checkDuplicatePermalink, checkRequiredFields, getDownloadableUrl, onInvalidToken, onUnload } from "../utils";
import { TranslationKey, getI18nLocaleString } from "../../i18n";
import { WithPermissionsProps, withPermissions } from "../../containers/PermissionsProvider";
import { cachedConvertFromRawString, cachedConvertToRaw } from "./richText";
import { canCreate, canEdit, canRead, getPermission } from "../../utils/permissions.utils";
import { getLocaleOptions, validateContentKeys } from "../../utils/localizedContent.util";
import { getMainCustomerIdFromLoginToken, getMyEnvMainCustomer, getSelectedAccoTypeReservedResource } from "../../redux/reducers/myEnv/myEnv.util";
import { getRevisions, getRevisionsSec, removeRevisions, removeRevisionsSec, setRevisions, setRevisionsSec } from "./util/revision.util";
import { parseFormAddressData, parseFormCustomerData } from "../../utils/customer.util";

import { ActionType } from "../../redux/actions";
import { Alerts } from "../../alerts";
import { AvailabilityState } from "../../redux/reducers/availability.types";
import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { FlexboxBase } from "../../plugins/page/flexbox/Flexbox";
import { Identity } from "../../containers/Identity";
import { MyEnvState } from "../../redux/reducers/myEnv/myEnvState";
import { State } from "../../redux";
import { StringMultiSelectOption } from "../../plugins/mxts/selectOption.types";
import { connect } from "react-redux";
import { convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { getCMSOptions } from "../../plugins/settings";
import { getFallbackLocaleIds } from "../../plugins/page/button/button.util";
import { getFallbackMemoCategoryId } from "../../utils/reservation.util";
import { getLocaleCodeFromCMSOptions } from "../../utils/widget.util";
import { getMxtsEnv } from "../../plugins/mxts";
import { getSiteMap } from "../../utils/site.util";
import { globalApiContext } from "../../containers/CmsProvider";
import { isClientSide } from "../../utils/generic.util";
import { isEqual } from "lodash";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";
import { refreshMyEnvCustomer } from "../../redux/actions/myEnvAction";
import { removeUserLoggedInCookie } from "../../utils/authToken.util";
import { saveAs } from "file-saver";
import { updateCustomFields } from "../../plugins/form/form.util";
import { validateCss } from "../../utils/admin.util";

const AdminPreview = loadable(() => loadableRetry(() => import("../admin/")), {
    resolveComponent: ({ AdminPreview }) => AdminPreview,
});
const GenericInput = loadable(() => loadableRetry(() => import("./input")), {
    resolveComponent: ({ GenericInput }) => GenericInput,
});
const Revealer = loadable(() => loadableRetry(() => import("../../plugins/shared/revealer/Revealer")), {
    resolveComponent: ({ Revealer }) => Revealer,
});
const WebContent = loadable(() => loadableRetry(() => import("../../plugins/page/web-content/WebContent")), {
    resolveComponent: ({ WebContent }) => WebContent,
});

export interface GenericFormBaseProps<E> {
    spec: FormSpec<E>;
    mode: EditMode;
    buttons: "inline" | "aside" | "none";
    value: E & { _id: string };
    className?: string;
    onChange?: (newVal: E) => void;
    onSave?: (newVal: E, options?: SubmitButtonOptions, token?: string) => Promise<string> | void;
    onClone?: (newVal: E) => void;
    onExport?: (newVal: E) => void;
    // button props
    shouldDisableSubmit?: boolean;
    localizedWidgetOptions?: LocalizedWidgetOptions | undefined;
    cancelLabel?: TranslationKey | string;
    deleteLabel?: TranslationKey | string;
    enableCancel?: boolean;
    enableSubmit?: boolean;
    enableDelete?: boolean;
    onCancel?: () => void;
    onDelete?: () => void;
    widgetOptions?: WidgetOptions;
    initialItem?: Record<string, unknown>;
    rootValue?: any;
    alerts?: Alerts;
    specType?: any;
    isReCaptchaApplied?: boolean;
    context?: CMSProvidedProperties;
    customFieldsList?: CustomFieldResult[];
}

export interface GenericFormProps<E> extends GenericFormBaseProps<E>, GenericFormDispatchProps, GenericFormStoreProps, WithPermissionsProps {}

type GenericFormFullProps<E> = GenericFormProps<E> & Partial<IWithGoogleReCaptchaProps>;
type ClickType = "clone" | "save";
interface GenericFormState {
    draftValue: any;
    shouldDisableSubmit: boolean;
    clickType?: ClickType;
    isConfirmationModalOpen: boolean;
    isFormEdited?: boolean;
    friendlyUrl?: string;
    revisionLength: number;
    modalCheck?: boolean;
    checkUndoRedo?: string;
    widgetName?: string;
    shouldDisableRedo: boolean;
    shouldDisableUndo: boolean;
    dynamicData: DynamicData;
    nextAuthCheckTime?: number;
    disableFormSubmit?: boolean;
    isChangeUpdated?: boolean;
    validations?: {
        [id: string]: {
            isValid: boolean;
            message?: string;
        };
    };
    requiredInputFields?: string[];
    isFormReadOnly?: boolean;
    formMode?: EditMode;
    emailSubmissionDetail?: {
        alertType?: EmailAlertMessageType;
        message?: string;
    };
    isAdminSide: boolean;
}

interface GenericFormDispatchProps {
    dispatchAction: Dispatch<FormEditAction>;
}

interface GenericFormStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    myEnvState: MyEnvState;
}

class GenericFormBase<E> extends React.Component<GenericFormFullProps<E>, GenericFormState> {
    constructor(props: GenericFormFullProps<E>) {
        super(props);
        this.state = {
            draftValue: this.getDefaultDraftValues(props),
            shouldDisableSubmit: props.shouldDisableSubmit || false,
            isConfirmationModalOpen: false,
            isFormEdited: false,
            shouldDisableRedo: true,
            shouldDisableUndo: true,
            revisionLength: 0,
            modalCheck: false,
            checkUndoRedo: "",
            widgetName: "",
            dynamicData: {},
            disableFormSubmit: false,
            isChangeUpdated: false,
            isFormReadOnly: true,
            formMode: props.mode,
            isAdminSide: isClientSide() && !!document.querySelector(".backend"),
        };
        this.generateSiteMap = this.generateSiteMap.bind(this);
    }

    // eslint-disable-next-line max-lines-per-function
    public componentDidMount() {
        const { draftValue } = this.state;
        const { mode, spec, specType, widgetOptions } = this.props;
        let newDraftValue: any = draftValue;
        const path = window.location.href;
        if (widgetOptions?.enableReadOnly) {
            this.setState({ isFormReadOnly: true, formMode: "readonly" });
        }
        if (path && (path.indexOf("edit") !== -1 || path.indexOf("create") !== -1)) {
            if (this.state.isFormEdited) {
                window.addEventListener("beforeunload", onUnload);
            }
        }
        if (spec && (spec.id === "webcontent" || spec.id === "page") && mode === "admin_edit" && draftValue) {
            const revisionOne = getRevisions(this.props.value?._id);
            const revisionSec = getRevisionsSec(this.props.value?._id);
            if (revisionOne.length === 0 && revisionSec.length === 0) {
                const objinfo: any = {};
                objinfo.draftValue = draftValue;
                revisionOne.push(objinfo);
                revisionSec.push(objinfo);
                setRevisions(this.props.value?._id, revisionOne);
                setRevisionsSec(this.props.value?._id, revisionSec);
            }
        }
        const widgetPopUp = localStorage ? JSON.parse(localStorage.getItem("editWidgetPopUp") || "[]") : [];
        if (widgetPopUp.length === 0 && mode === "admin_edit" && specType && specType === "page") {
            widgetPopUp.push([draftValue, "", ""]);
            if (localStorage) {
                localStorage.setItem("editWidgetPopUp", JSON.stringify(widgetPopUp));
            }
        }
        const revisionNew = getRevisions(this.props.value?._id);
        if (draftValue && Object.keys(draftValue || {}).length === 0 && mode === "create" && revisionNew.length > 0 && spec && (spec.id === "page" || spec.id === "webcontent")) {
            const undoRedoInfo = JSON.parse(localStorage.getItem("undoRedoInfo") || "[]");
            let lastDraftValue;
            if (undoRedoInfo.length > 0) {
                newDraftValue = undoRedoInfo[undoRedoInfo.length - 1].draftValue;
                lastDraftValue = undoRedoInfo[undoRedoInfo.length - 1];
                removeRevisions(this.props.value?._id);
                localStorage.removeItem("undoRedoInfo");
            } else {
                newDraftValue = revisionNew[revisionNew.length - 1].draftValue;
                lastDraftValue = revisionNew.pop();
                removeRevisions(this.props.value?._id);
            }
            lastDraftValue.mode = mode;
            const revisionSecond = getRevisions(this.props.value?._id);
            revisionSecond.push(lastDraftValue);
            setRevisions(this.props.value?._id, revisionSecond);
        }
        if (mode === "admin_edit" && spec && (spec.id === "webcontent" || spec.id === "page")) {
            let compareDraft = false;
            const revisionSec = getRevisionsSec(this.props.value?._id);
            if (revisionSec.length !== 0) {
                compareDraft = JSON.stringify(revisionSec[0].draftValue) === JSON.stringify(newDraftValue);
            }
            if (compareDraft && revisionNew.length > 0 && revisionSec.length > 0) {
                const undoRedoInfo = JSON.parse(localStorage.getItem("undoRedoInfo") || "[]");
                let lastDraftValue;
                if (undoRedoInfo.length > 0) {
                    newDraftValue = undoRedoInfo[undoRedoInfo.length - 1].draftValue;
                    lastDraftValue = undoRedoInfo[undoRedoInfo.length - 1];
                    removeRevisions(this.props.value?._id);
                    localStorage.removeItem("undoRedoInfo");
                } else {
                    newDraftValue = revisionNew[revisionNew.length - 1].draftValue;
                    lastDraftValue = revisionNew.pop();
                    removeRevisions(this.props.value?._id);
                }
                lastDraftValue.mode = mode;
                const revisionSecond = getRevisions(this.props.value?._id);
                revisionSecond.push(lastDraftValue);
                setRevisions(this.props.value?._id, revisionSecond);
            }
        }
        if ("home" in draftValue && "friendlyUrl" in draftValue) {
            this.setState({ friendlyUrl: (draftValue as any).friendlyUrl });
        }
        if (spec && (spec.id === "page" || spec.id === "webcontent")) {
            const revisionNewOne = getRevisions(this.props.value?._id);
            if (revisionNewOne.length === 1 && revisionNewOne[0] && revisionNewOne[0].mode && (revisionNewOne[0].mode === "create" || revisionNewOne[0].mode === "admin_edit")) {
                this.setState({ draftValue }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(newDraftValue);
                    }
                });
            }
        }
        const requiredInputFieldList: string[] = [];

        const processProperties = (properties: Array<SomeInputSpec<E, keyof E>>) => {
            properties.forEach((prop) => {
                if ((prop as any)?.children) {
                    processProperties((prop as any)?.children);
                }
                if (prop.required) {
                    requiredInputFieldList.push(prop.variable as string);
                }
            });
        };

        processProperties(spec.properties);
        if (widgetOptions?.showConditionCheckBox) {
            requiredInputFieldList.push("agreeTerms");
        }
        this.setState({ requiredInputFields: requiredInputFieldList }, () => {
            this.setValidationforRequiredFields();
            this.handleFormValidation();
        });
    }

    public async componentDidUpdate(prevProps: GenericFormFullProps<E>) {
        const { dynamicFilter, availabilityState, spec, myEnvState, context, widgetOptions } = this.props;
        const { dynamicData, draftValue } = this.state;
        const { availabilityState: prevAvailabilityState, myEnvState: prevMyEnvState } = prevProps;
        const property = spec?.properties && flatten(spec.properties, (parent: any) => parent.children).find(({ type }) => type === "dynamicData");
        const selectedDynamicData = property?.dynamicData;

        if (selectedDynamicData?.length && prevAvailabilityState?.fetching && !availabilityState?.fetching) {
            const dynamicDataParams: DynamicDataParams = {
                selectedDynamicData,
                dynamicFilter,
                availabilityState,
            };

            try {
                const dynamicDataResult = await getDynamicData(context?.mxtsApi || MxtsApi, dynamicDataParams);
                if (!isEqual(dynamicData, dynamicDataResult)) {
                    this.setState({ dynamicData: dynamicDataResult });
                }
            } catch (error) {
                context?.logger.error("Error fetching dynamic data", error);
            }
        }

        if (widgetOptions?.sendDynamicDataPerEmail && selectedDynamicData?.length && prevMyEnvState?.selectedReservation !== myEnvState?.selectedReservation && context) {
            try {
                const env = await getMxtsEnv(context, context?.currentLocale.code);
                const dynamicDataList = await getDynamicDataListMyEnv(context.mxtsApi, prevMyEnvState, env, selectedDynamicData);
                if (!isEqual(dynamicData, dynamicDataList)) {
                    this.setState({ dynamicData: dynamicDataList });
                }
            } catch (error) {
                context.logger.error("Error fetching dynamic data for MyEnv", error);
            }
        }

        const customerId = myEnvState?.selectedReservation?.customer?.customerId || draftValue?.customerId;
        if (customerId && selectedDynamicData?.some((item: any) => item.value === MultiSelectDynamicData.CUSTOMER_ID) && (!dynamicData?.customerId || dynamicData?.customerId !== customerId)) {
            this.setState({ dynamicData: { ...dynamicData, customerId } });
        }
    }

    shouldComponentUpdate(nextProps: Readonly<GenericFormFullProps<E>>) {
        return !!nextProps.spec;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<GenericFormFullProps<E>>) {
        const { revisionLength } = this.state;
        const { spec } = this.props;
        this.setState(
            {
                shouldDisableSubmit: nextProps.shouldDisableSubmit || this.state.shouldDisableSubmit,
                // eslint-disable-next-line max-len
                draftValue: revisionLength && revisionLength > 0 ? this.getDefaultDraftValues(nextProps, "localStorage", revisionLength) : this.getDefaultDraftValues(nextProps, "localStorage"),
            },
            () => {
                if (spec && (spec.id === "page" || spec.id === "webcontent")) {
                    const revisionNew = getRevisions(nextProps.value?._id);
                    const revisionSec = getRevisionsSec(nextProps.value?._id);
                    // eslint-disable-next-line max-len
                    if (revisionNew.length === 1 && revisionNew[0] && revisionNew[0].mode && revisionNew[0].mode === "admin_edit") {
                        if (!(JSON.stringify(revisionNew[revisionNew.length - 1]?.draftValue) === JSON.stringify(revisionSec[0]?.draftValue))) {
                            const action: FormEditAction = {
                                type: ActionType.FormEdited,
                                actionType: FormEditActionType.edited,
                                payload: this.state.draftValue,
                            };

                            if (this.props.onChange) {
                                this.props.dispatchAction(action);
                                this.props.onChange(this.state.draftValue);
                            }
                        }
                    }
                }
                this.handleFormValidation();
            }
        );
        const { draftValue } = this.state;
        if (draftValue && "home" in draftValue && "friendlyUrl" in draftValue) {
            this.setState({ friendlyUrl: (this.getDefaultDraftValues(nextProps) as any)?.friendlyUrl });
        }
        const path = window.location.href;
        if (path && (path.indexOf("edit") !== -1 || path.indexOf("create") !== -1)) {
            if (this.state.isFormEdited) {
                window.addEventListener("beforeunload", onUnload);
            }
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { spec, className, buttons, alerts, permissions, widgetOptions, mode, context } = this.props;
        const { currentLocale, site } = context || {};
        const localeId = currentLocale?.locale;
        const localContent = widgetOptions?.localized?.find((lc) => lc.locale === localeId) || undefined;
        const { isFormReadOnly, emailSubmissionDetail } = this.state;
        const { viewOnSite } = spec;
        const isSiteEdit = spec.id === "site";
        const isFormEdit = spec.id === "form";
        const { checkUndoRedo, widgetName, shouldDisableRedo, shouldDisableUndo, disableFormSubmit } = this.state;
        const permission = getPermission({ key: spec.permission, permissions });
        const showActionButtons = (mode === "admin_edit" && canEdit(permission)) || (mode === "create" && canCreate(permission));
        const show = canRead(permission);
        let { draftValue } = this.state;
        draftValue = draftValue || {};
        return show ? (
            <Form className={`${className} simple-form`}>
                <div className="form-elements content-box">
                    {!localContent?.enableFeedbackMethod && (
                        <div
                            className={
                                emailSubmissionDetail?.message && widgetOptions?.sendEmail
                                    ? `alert ${
                                          emailSubmissionDetail.alertType === EmailAlertMessageType.Error
                                              ? "alert-warning"
                                              : emailSubmissionDetail.alertType === EmailAlertMessageType.Success
                                              ? "alert-success"
                                              : ""
                                      } feedback`
                                    : ""
                            }
                            id="form-feedback-message"
                        >
                            <span>{emailSubmissionDetail?.message}</span>
                        </div>
                    )}
                    {spec.properties.map((prop, ind) => {
                        if ((prop as any).spec && (prop as any).spec.id === "flexbox") {
                            return this.renderFlexboxFormElement(prop, ind);
                        } else if ((prop as any).spec && (prop as any).spec.id === "revealer") {
                            return this.renderRevealerFormElement(prop, ind);
                        } else if ((prop as any).spec && (prop as any).spec.id === "webcontent") {
                            return (
                                <WebContent
                                    key={ind}
                                    id={(prop as any).id}
                                    options={(prop as any).options}
                                    content={(prop as any).content}
                                    imageUrl={(prop as any).imageUrl}
                                    cardImageUrl={(prop as any).cardImageUrl}
                                    sitePageUrl={(prop as any).sitePageUrl}
                                    friendlyName={(prop as any).friendlyName}
                                    alt={(prop as any).alt}
                                    className={(prop as any).className}
                                    context={(prop as any).context}
                                    env={(prop as any).env}
                                />
                            );
                        }
                        return <React.Fragment key={ind}>{this.renderGenericInput(prop, ind)}</React.Fragment>;
                    })}
                    {widgetOptions?.showConditionCheckBox && (
                        <div className={"version-table-custom-padding"}>
                            <div className="general-conditions">
                                <Label className="general-conditions__label">
                                    <Input className={"general-conditions__checkbox"} type="checkbox" onChange={this.handleSelectTerms} />
                                    <span>
                                        {getI18nLocaleString(namespaceList.admin, "generalConditionsText", currentLocale, site)}
                                        {localContent?.file && (
                                            <a className="general-conditions__link" onClick={this.downloadTerms} rel="nofollow">
                                                <small>{getI18nLocaleString(namespaceList.admin, "downloadGeneralConditionsLabel", currentLocale, site)}</small>
                                            </a>
                                        )}
                                    </span>
                                </Label>
                            </div>
                        </div>
                    )}
                </div>
                {spec.id === "webcontent" && (
                    <div className="webcontent-preview col-sm-6">
                        <AdminPreview spec={spec} item={this.props.value} />
                    </div>
                )}
                {buttons === "aside" && showActionButtons && (
                    <aside className="action-buttons-aside content-box">
                        <div className="box-heading">
                            <label>{getI18nLocaleString(namespaceList.admin, "headingSavePanel")}</label>
                        </div>
                        <GenericFormButtons
                            {...this.props}
                            submitLabel={this.props.localizedWidgetOptions && this.props.localizedWidgetOptions.submitLabel}
                            disableFormSubmit={disableFormSubmit}
                            onSubmit={this.save}
                            onClone={this.clone}
                            onSite={this.onSite}
                            onExport={this.onExport}
                            generateSiteMap={this.generateSiteMap}
                            enableOnSite={!!viewOnSite}
                            enableGenerateSitemap={isSiteEdit}
                            shouldDisableSubmit={this.state.shouldDisableSubmit}
                            showCloneButton={spec.id !== "post"}
                            showExportButton={isFormEdit}
                            onCancel={this.onCancelClick}
                            specType={spec}
                            onUndo={this.undo}
                            onRedo={this.redo}
                            shouldDisableRedo={shouldDisableRedo}
                            shouldDisableUndo={shouldDisableUndo}
                            alerts={alerts}
                            permission={permission}
                        />
                    </aside>
                )}
                {widgetOptions?.enableReadOnly && isFormReadOnly && (
                    <GenericFormButtons
                        {...this.props}
                        submitLabel={this.props.localizedWidgetOptions && this.props.localizedWidgetOptions.editLabel}
                        disableFormSubmit={false}
                        onSubmit={this.toggleReadOnlyState}
                        onClone={this.clone}
                        onSite={this.onSite}
                        onExport={this.onExport}
                        generateSiteMap={this.generateSiteMap}
                        enableOnSite={!!viewOnSite}
                        enableGenerateSitemap={isSiteEdit}
                        shouldDisableSubmit={false}
                        showCloneButton={spec.id !== "post"}
                        showExportButton={isFormEdit}
                        alerts={alerts}
                        permission={permission}
                    />
                )}
                {buttons === "inline" && (widgetOptions?.enableReadOnly ? !isFormReadOnly : true) && (
                    <GenericFormButtons
                        {...this.props}
                        submitLabel={this.props.localizedWidgetOptions && this.props.localizedWidgetOptions.submitLabel}
                        disableFormSubmit={disableFormSubmit}
                        onSubmit={this.save}
                        onClone={this.clone}
                        onSite={this.onSite}
                        onExport={this.onExport}
                        generateSiteMap={this.generateSiteMap}
                        enableOnSite={!!viewOnSite}
                        enableGenerateSitemap={isSiteEdit}
                        shouldDisableSubmit={this.state.shouldDisableSubmit}
                        showCloneButton={spec.id !== "post"}
                        showExportButton={isFormEdit}
                        alerts={alerts}
                        permission={permission}
                        cancelLabel={this.props.localizedWidgetOptions?.cancelLabel}
                        enableCancel={!!widgetOptions?.enableReadOnly}
                        onCancel={this.onCancelClick}
                    />
                )}
                {this.state.isConfirmationModalOpen ? (
                    <Modal className="admin-side-modal" isOpen={this.state.isConfirmationModalOpen} toggle={this.toggle} size="md" backdrop="static">
                        <ModalHeader tag="h4" toggle={this.toggle}>
                            {getI18nLocaleString(namespaceList.admin, "confirmationTemplate")}
                        </ModalHeader>
                        <ModalBody>
                            <div>{getI18nLocaleString(namespaceList.admin, "confirmationSaveMessage")}</div>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="publish" color="primary" onClick={this.taskAfterAlertPopupAction.bind(this, "Yes")}>
                                <FontAwesome name="check" />
                                {getI18nLocaleString(namespaceList.admin, "yes")}
                            </Button>
                            <Button className="publish" color="primary" onClick={this.taskAfterAlertPopupAction.bind(this, "No")}>
                                <FontAwesome name="ban" />
                                {getI18nLocaleString(namespaceList.admin, "noButton")}
                            </Button>
                            <Button className="cancel" color="secondary" onClick={this.toggle}>
                                <FontAwesome name="times" />
                                {getI18nLocaleString(namespaceList.admin, "cancel")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {spec.id === "page" && this.state.modalCheck ? (
                    <Modal className="admin-side-modal" isOpen={this.state.modalCheck} toggle={this.widgetConfigureToggle} size="md" backdrop="static">
                        <ModalHeader tag="h4" toggle={this.widgetConfigureToggle}>
                            {getI18nLocaleString(namespaceList.admin, "confirmationTemplate")}
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                {checkUndoRedo === "undo" ? widgetName + getI18nLocaleString(namespaceList.admin, "undoMessage") : widgetName + getI18nLocaleString(namespaceList.admin, "redoMessage")}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="publish" color="primary" onClick={checkUndoRedo === "undo" ? this.undo : this.redo}>
                                <FontAwesome name="check" />
                                {checkUndoRedo === "undo" ? getI18nLocaleString(namespaceList.admin, "undo") : getI18nLocaleString(namespaceList.admin, "redo")}
                            </Button>
                            <Button className="publish" color="primary" onClick={this.widgetConfigureToggle}>
                                <FontAwesome name="ban" />
                                {getI18nLocaleString(namespaceList.admin, "cancel")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
            </Form>
        ) : (
            <div>You don&quot;t have enough permissions to view this content. Please ask the admin to provide the permissions</div>
        );
    }

    private downloadTerms = async () => {
        const { widgetOptions, context } = this.props;
        const localeId = context?.currentLocale.locale;
        if (context) {
            const localContent = widgetOptions?.localized ? widgetOptions.localized.find((lc) => lc.locale === localeId) : undefined;
            const env = await getMxtsEnv(context, context.currentLocale.code);
            const documentUUID =
                localContent?.file?.document && context && widgetOptions?._id
                    ? await context.mxtsApi.getDocumentUUIDPublic(env, {
                          widgetOptionsId: widgetOptions._id,
                          widgetOptionsPathToFileId: "localized.file.document.fileId",
                          siteId: context.site._id,
                          localeId,
                          localeFallbackIds: getFallbackLocaleIds(context.currentLocale),
                      })
                    : "";
            if (documentUUID) {
                window.location.assign(getDownloadableUrl(documentUUID as string));
            }
        }
    };

    private handleSelectTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { validations, draftValue } = this.state;
        const { checked } = event.target;
        const updateDraftValue = { ...draftValue, agreeTerms: checked };
        const updatedValidations = { ...validations, agreeTerms: { isValid: checked } };
        this.setState({ draftValue: updateDraftValue, validations: updatedValidations }, () => this.handleFormValidation());
    };

    private isObject(variable: any) {
        return typeof variable === "object" && variable !== null && !variable.$$typeof;
    }

    private renderFlexboxFormElement(prop: SomeInputSpec<E, keyof E>, index: number) {
        const { spec, mode, rootValue, alerts, permissions, widgetOptions } = this.props;
        const { validations, isFormReadOnly, formMode, friendlyUrl } = this.state;
        const permission = getPermission({ key: spec.permission, permissions });
        const draftValue = this.state.draftValue || {};
        (prop as any).options.overrideReadOnly = widgetOptions?.overrideReadOnly && isFormReadOnly;
        return (
            <Identity key={index}>
                <FlexboxBase
                    id={(prop as any).id}
                    options={(prop as any).options}
                    children={this.renderFormElementChildren(prop, true)}
                    context={(prop as any).context || { cmsApi: CmsApi }}
                    className={(prop as any).className}
                    left={(prop as any).left}
                    right={(prop as any).right}
                    mode={formMode || mode}
                    root={draftValue}
                    item={draftValue}
                    initialItem={rootValue}
                    onChange={this.onPropChange}
                    friendlyUrl={friendlyUrl}
                    alerts={alerts}
                    permission={permission}
                    validate={this.validateInput}
                    validations={validations}
                    flexboxInsideForm={true}
                    formChildOptions={this.getFlexboxChildOptions(prop)}
                />
            </Identity>
        );
    }

    private renderRevealerFormElement(prop: SomeInputSpec<E, keyof E>, index: number) {
        const { spec, mode, rootValue, alerts, permissions, widgetOptions } = this.props;
        const { validations, isFormReadOnly, friendlyUrl } = this.state;
        const permission = getPermission({ key: spec.permission, permissions });
        const draftValue = this.state.draftValue || {};
        (prop as any).options.overrideReadOnly = widgetOptions?.overrideReadOnly && isFormReadOnly;
        return (
            <Identity key={index}>
                <Revealer
                    id={(prop as any).id}
                    childs={this.renderFormElementChildren(prop)}
                    options={(prop as any).options}
                    showLess={(prop as any).showLess}
                    showMore={(prop as any).showMore}
                    className={(prop as any).className}
                    key={index}
                    mode={mode}
                    root={draftValue}
                    item={draftValue}
                    initialItem={rootValue}
                    onChange={this.onPropChange}
                    friendlyUrl={friendlyUrl}
                    formAlerts={alerts}
                    permission={permission}
                    validate={this.validateInput}
                    validations={validations}
                />
            </Identity>
        );
    }

    private renderFormElementChildren(prop: SomeInputSpec<E, keyof E>, isFlexbox?: boolean) {
        const children = isFlexbox ? (prop as any).children : (prop as any).childs;
        if ((prop as any).spec?.id === (isFlexbox ? "flexbox" : "revealer") && children) {
            return children.map((child: SomeInputSpec<E, keyof E>, index: number) => {
                if ((child as any).spec?.id === "flexbox") {
                    return this.renderFlexboxFormElement(child, index);
                }
                if ((child as any).spec?.id === "revealer") {
                    return this.renderRevealerFormElement(child, index);
                }
                if (this.isObject(child)) {
                    this.renderGenericInput(child, index);
                }
                return child;
            });
        }
        return children;
    }

    private getFlexboxChildOptions(prop: SomeInputSpec<E, keyof E>) {
        const children = (prop as any).children;
        if ((prop as any).spec?.id === "flexbox" && children) {
            return children.map((child: SomeInputSpec<E, keyof E>) => child.options);
        }
        return children;
    }

    private renderGenericInput(child: SomeInputSpec<E, keyof E>, index: number) {
        const { spec, mode, rootValue, alerts, permissions } = this.props;
        const { validations, dynamicData, friendlyUrl } = this.state;
        const permission = getPermission({ key: spec.permission, permissions });
        const draftValue = this.state.draftValue || {};
        return (
            <div className="form-input-container" key={index}>
                <GenericInput
                    key={index}
                    spec={child}
                    mode={mode}
                    permission={permission}
                    root={draftValue}
                    item={draftValue}
                    initialItem={rootValue}
                    value={child.variable !== undefined ? draftValue[child.variable] : undefined}
                    onChange={this.onPropChange}
                    friendlyUrl={friendlyUrl}
                    alerts={alerts}
                    dynamicData={dynamicData}
                    validate={this.validateInput}
                />
                {validations && validations[child.variable as string] ? (
                    !validations[child.variable as string].isValid ? (
                        <p className="input-validation-message">{validations[child.variable as string].message}</p>
                    ) : null
                ) : null}
            </div>
        );
    }

    private modifyToRawDraftJSEditorState(content: string, isRawHtmlWidget = false) {
        let modifiedContent = content;
        if (content && !(content.startsWith('{"blocks"') || content.startsWith('{"entityMap"'))) {
            const editorState = cachedConvertFromRawString(content, isRawHtmlWidget);
            modifiedContent = cachedConvertToRaw(editorState.getCurrentContent());
        }
        return modifiedContent;
    }

    private getDefaultDraftValues(props: GenericFormFullProps<E>, localstorage?: string, revisionLength?: any) {
        let newDraftValue: any = props.value;
        const { mode, spec } = this.props;
        if (spec?.id === "webcontent") {
            if (!newDraftValue.isRawHtmlWidget) {
                newDraftValue.localizedContent?.map((content: LocalizedContent) => {
                    if (content.content) {
                        content.content = this.modifyToRawDraftJSEditorState(content.content);
                    }
                });
                newDraftValue.localizedCardContent?.map((content: LocalizedCardContent) => {
                    if (content.richTextDesc) {
                        content.richTextDesc = this.modifyToRawDraftJSEditorState(content.richTextDesc);
                    }
                });
            } else {
                newDraftValue.localizedContent?.map((content: LocalizedContent) => {
                    if (content.content) {
                        content.content = this.modifyToRawDraftJSEditorState(content.content, newDraftValue.isRawHtmlWidget);
                    }
                });
            }
        }
        if (props.spec?.properties) {
            props.spec.properties.forEach((property: any) => {
                if (property.default && property.variable) {
                    newDraftValue = {
                        ...newDraftValue,
                        [property.variable]: newDraftValue[property.variable] || property.default,
                    };
                }
            });
        }
        if (localstorage && localstorage === "localStorage") {
            const revisionNew = getRevisions(props.value?._id);
            if (mode === "admin_edit" && spec && (spec.id === "webcontent" || spec.id === "page")) {
                let compareDraft = false;
                let checkBool;
                const revisionSec = getRevisionsSec(props.value?._id);
                if (revisionSec.length !== 0) {
                    compareDraft = JSON.stringify(revisionSec[0].draftValue) === JSON.stringify(props.value);
                }
                if (revisionLength === undefined) {
                    checkBool = true;
                }
                if (revisionLength > 1) {
                    checkBool = false;
                }
                if (compareDraft && revisionNew.length > 0 && revisionSec.length > 0 && checkBool) {
                    const undoRedoInfo = JSON.parse(localStorage.getItem("undoRedoInfo") || "[]");
                    let lastDraftValue;
                    if (undoRedoInfo.length > 0) {
                        newDraftValue = undoRedoInfo[undoRedoInfo.length - 1].draftValue;
                        lastDraftValue = undoRedoInfo[undoRedoInfo.length - 1];
                        removeRevisions(props.value?._id);
                        localStorage.removeItem("undoRedoInfo");
                    } else {
                        newDraftValue = revisionNew[revisionNew.length - 1].draftValue;
                        lastDraftValue = revisionNew.pop();
                        removeRevisions(props.value?._id);
                    }
                    lastDraftValue.mode = mode;
                    const revisionSecond = getRevisions(props.value?._id);
                    revisionSecond.push(lastDraftValue);
                    setRevisions(props.value?._id, revisionSecond);
                }
            }
        }
        return newDraftValue;
    }

    private toggle = () => {
        this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen });
    };

    private toggleReadOnlyState = () => {
        if (this.state.formMode === "readonly") {
            this.setState({ formMode: "edit", isFormReadOnly: !this.state.isFormReadOnly });
        } else if (this.state.formMode === "edit") {
            this.setState({ formMode: "readonly", isFormReadOnly: !this.state.isFormReadOnly });
        }
    };

    private widgetConfigureToggle = () => {
        this.setState({ modalCheck: !this.state.modalCheck });
    };

    private onCancelClick = () => {
        const { draftValue } = this.state;
        const { spec, mode, widgetOptions } = this.props;
        if ((mode === "admin_edit" || mode === "create") && (spec.id === "webcontent" || spec.id === "page")) {
            this.setState({
                shouldDisableRedo: true,
                shouldDisableUndo: true,
            });
            removeRevisions(this.props.value?._id);
            removeRevisionsSec(this.props.value?._id);
            localStorage.removeItem("editWidgetPopUp");
            localStorage.removeItem("saveDraftValue");
            localStorage.removeItem("richtext");
            localStorage.removeItem("undoRedoInfo");
            localStorage.removeItem("widgetName");
            localStorage.removeItem("modalToast");
            localStorage.removeItem("copyClicked");
        }
        if (this.state.isFormEdited) {
            const action: FormEditAction = {
                type: ActionType.FormEdited,
                actionType: FormEditActionType.edited,
                payload: undefined,
            };
            this.props.dispatchAction(action);
        }
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        if (widgetOptions?.enableReadOnly) {
            this.setState({ draftValue: this.props.value });
            this.toggleReadOnlyState();
        }
    };

    private taskAfterAlertPopupAction = (buttonHitType: string) => {
        const { initialItem, alerts, spec } = this.props;
        const { draftValue, clickType } = this.state;
        let updateRedux = false;
        if (clickType === "clone" && this.props.onClone) {
            // User wish to clone with recent changes
            if (buttonHitType === "Yes") {
                // Check if save was successful then only clone should happen
                if (!this.save()) {
                    // Create new object of draftValue using spread
                    // operator to avoid the conflict between save() & clone()
                    this.props.onClone({ ...draftValue });
                    // Update redux store
                    updateRedux = true;
                } else {
                    this.toggle();
                }
                // User wish to clone without recent changes
            } else {
                const isRequiredCheckSuccessful = checkRequiredFields(spec, draftValue);
                if (!isRequiredCheckSuccessful.requiredChecked && alerts) {
                    isRequiredCheckSuccessful.labelArray.forEach((term: string) => {
                        alerts.push({ color: "danger", message: `${getI18nLocaleString(namespaceList.admin, "requiredFailed")}: ${term}` });
                    });
                    this.toggle();
                } else {
                    this.props.onClone(initialItem as any);
                    // Update redux store
                    updateRedux = true;
                }
            }
        }
        if (updateRedux) {
            if (this.state.isFormEdited) {
                const action: FormEditAction = {
                    type: ActionType.FormEdited,
                    actionType: FormEditActionType.edited,
                    payload: undefined,
                };
                this.props.dispatchAction(action);
            }
        }
    };

    private findInputWidgetChild(children: Array<SomeInputSpec<E, keyof E>> | undefined, id: string): SomeInputSpec<E, keyof E> | undefined {
        if (children) {
            for (const child of children) {
                if (child.variable === id) {
                    return child;
                }
                const foundChild = this.findInputWidgetChild((child as any).children, id);
                if (foundChild) {
                    return foundChild;
                }
            }
        }
        return undefined;
    }

    private validateInput = (id: string, isValid: boolean) => {
        const { spec } = this.props;
        const inputWidget = this.findInputWidgetChild(spec.properties, id);
        if (inputWidget) {
            const message = inputWidget.message;
            this.setState({ validations: { ...this.state.validations, [id]: { isValid, message } } }, () => {
                this.handleFormValidation();
            });
        }
    };

    private setValidationforRequiredFields = () => {
        const { widgetOptions } = this.props;
        const { requiredInputFields, draftValue } = this.state;
        if (widgetOptions?.fillContactForm && draftValue) {
            let validations = {};
            requiredInputFields?.map((field) => {
                if (draftValue[field]) {
                    validations = { ...validations, [field]: { isValid: true } };
                }
            });
            this.setState({ validations });
        }
    };

    private handleFormValidation = () => {
        const { requiredInputFields, draftValue, validations, isAdminSide } = this.state;
        if (isAdminSide) {
            this.setState({ shouldDisableSubmit: false });
        }
        if (requiredInputFields && draftValue && (!validations || isAdminSide)) {
            const isAllValid = requiredInputFields?.every((item: string) => draftValue[item]);
            this.setState({ shouldDisableSubmit: !isAllValid });
        } else if (validations) {
            const validationsNotEmpty = Object.keys(validations || {}).length > 0;
            const isAllValid = validationsNotEmpty && requiredInputFields?.every((field) => validations[field] && validations[field].isValid);
            // If any field does not have a validation or is invalid, disable submit
            this.setState({ shouldDisableSubmit: !isAllValid });
        }
        if (this.props.widgetOptions?.sendMemo) {
            const hasValidMemo = Object.entries(draftValue).some(
                ([key, value]) =>
                    // Check if the key starts with "__RESERVATION__MEMO__" and value is not null or empty
                    key.startsWith("__RESERVATION__MEMO__") && value !== null && value !== undefined && (value as string).trim() !== ""
            );
            this.setState({ shouldDisableSubmit: !hasValidMemo });
        }
    };

    private handleAlertCheck = (clickType: string) => {
        switch (clickType) {
            case "clone":
                this.setState({ isConfirmationModalOpen: true, clickType: "clone" });
                break;
        }
    };

    private checkAuthInterval = (): boolean => {
        const { nextAuthCheckTime } = this.state;
        const currentTime = new Date().getTime();
        const delay = 300000; // 5 minutes (5 * 60 * 1000) in miliseconds.
        if (!nextAuthCheckTime) {
            this.setState(() => ({
                nextAuthCheckTime: currentTime + delay,
            }));
            return true;
        }
        const checkAuthAgain = moment(currentTime).isSameOrAfter(moment(nextAuthCheckTime));
        if (checkAuthAgain) {
            this.setState(() => ({
                nextAuthCheckTime: currentTime + delay,
            }));
        }
        return checkAuthAgain;
    };

    private onPropChange = (newVal: any, prop: InputSpec<E, keyof E>, isWidgetModalFormEdit?: string) => {
        const { alerts, specType } = this.props;
        const { isChangeUpdated } = this.state;
        const token: string | null = localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN);
        const checkAuth = this.checkAuthInterval();
        if (token && checkAuth) {
            AuthApi.authCheck()
                .then(() => null)
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    if (alerts) {
                        alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "timeoutMessage") });
                        onInvalidToken();
                        return;
                    }
                });
        } else if (!token) {
            removeUserLoggedInCookie();
        }
        const { spec } = this.props;
        if (
            spec.id === "post" &&
            (prop.variable === "name" || prop.variable === "siteId" || prop.variable === "isIncludeDateInUrl" || prop.variable === "customSubfolderStructure") &&
            (this.props.mode === "create" || this.props.mode === "admin_edit")
        ) {
            this.setPostPermalink(newVal, prop);
        } else {
            let isFormEdited = false;
            const draftJsEd = localStorage.getItem("richtext");
            if (draftJsEd === "draftChangeTrue" && isChangeUpdated) {
                this.setState({ isChangeUpdated: !isChangeUpdated });
            }
            if (isWidgetModalFormEdit === "formEditedTrue") {
                isFormEdited = true;
            } else if (isWidgetModalFormEdit === "listOpen" || isWidgetModalFormEdit === "formSavedTrue" || isWidgetModalFormEdit === "formEditCancelled") {
                isFormEdited = false;
            } else if (typeof newVal === "string" && newVal !== this.state.draftValue[prop.variable]) {
                isFormEdited = true;
            } else if (typeof newVal === "object" && newVal !== null && newVal.length !== 0 && !isChangeUpdated) {
                isFormEdited = true;
            } else if (typeof newVal === "boolean" && newVal !== this.state.draftValue[prop.variable]) {
                isFormEdited = true;
            }
            const draftValue = { ...this.state.draftValue, [prop.variable as string]: newVal };
            this.setState({ draftValue, isFormEdited }, () => {
                this.handleFormValidation();
                let formEditActionType = isFormEdited ? FormEditActionType.edited : FormEditActionType.saved;
                if (isWidgetModalFormEdit === "formEditCancelled") {
                    formEditActionType = FormEditActionType.cancelled;
                }
                const action: FormEditAction = {
                    type: ActionType.FormEdited,
                    actionType: formEditActionType,
                    payload: isFormEdited ? this.state.draftValue : undefined,
                };

                if (this.props.onChange) {
                    this.props.dispatchAction(action);
                    this.props.onChange(this.state.draftValue);
                }
            });
            if (specType && specType === "page") {
                localStorage.removeItem("modalToast");
                const modalToast = JSON.parse(localStorage.getItem("modalToast") || "[]");
                modalToast.push([newVal, prop]);
                localStorage.setItem("modalToast", JSON.stringify(modalToast));
            }
            if (spec && (spec.id === "webcontent" || spec.id === "page")) {
                const draftJsEd = localStorage.getItem("richtext");
                if (spec.id === "webcontent" && (prop.variable === "localizedContent" || prop.variable === "localizedCardContent") && draftJsEd) {
                    if (draftJsEd === "draftBlurTrue") {
                        this.undoRedoLocalStorage(draftValue, newVal, prop);
                    }
                } else {
                    this.undoRedoLocalStorage(draftValue, newVal, prop);
                }
                localStorage.removeItem("undoRedoInfo");
                localStorage.removeItem("richtext");
                localStorage.removeItem("copyClicked");
            }
        }
    };

    private async setPostPermalink(newVal: any, prop: InputSpec<E, keyof E>) {
        let host: string;
        const name: string = prop.variable === "name" ? newVal : (this.state.draftValue as any).name !== undefined ? (this.state.draftValue as any).name : "";
        const postName: string = name !== undefined ? name.trim().toLowerCase().split(" ").join("-") : "";

        if ((this.state.draftValue as any)?.siteId || prop.variable === "siteId") {
            const site = await SiteApi.findById({ id: prop.variable === "siteId" && newVal ? newVal : (this.state.draftValue as any).siteId, projection: { sitemap: 0 } });
            host = site ? site.host : "no-host";
        } else {
            host = "no-host";
        }
        const draftValue = { ...this.state.draftValue, [prop.variable!]: newVal };
        if (postName && postName.length > 0) {
            const today = new Date();
            const dd = today.getDate();
            const mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            const dateString = (draftValue as any).isIncludeDateInUrl ? dd + "-" + mm + "-" + yyyy + "/" : "";
            const customPhrase = (draftValue as any).customSubfolderStructure ? (draftValue as any).customSubfolderStructure + "/" : "";
            const customPhraseString = customPhrase?.trim().toLowerCase().split(" ").join("-") || "";

            const urlString = "//" + host + "/" + customPhraseString + dateString + postName;
            (draftValue as any).permalink = urlString;
        }

        let edited = false;
        if (newVal !== this.state.draftValue[prop.variable]) {
            edited = true;
        }

        this.setState({ draftValue }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.draftValue);
            }
            const action: FormEditAction = {
                type: ActionType.FormEdited,
                actionType: edited ? FormEditActionType.edited : FormEditActionType.saved,
                payload: edited ? this.state.draftValue : undefined,
            };

            this.props.dispatchAction(action);
        });
    }

    private async setCardPermalink(saveOptions?: SubmitButtonOptions) {
        const { draftValue } = this.state;
        const url = await getPageUrl({ siteId: (draftValue as any).siteId, pageId: (draftValue as any).pageId, siteApi: SiteApi });
        if (url.length > 0) {
            (draftValue as any).permalink = url;
        }
        this.props.onSave!(draftValue, saveOptions);
    }

    // eslint-disable-next-line max-lines-per-function
    private undoRedoLocalStorage = (draftValue: any, newVal: any, prop: any) => {
        const { mode, spec } = this.props;
        const checkNewDraftValue = localStorage.getItem("saveDraftValue");
        const widgetName = localStorage.getItem("widgetName");
        const copyclick = localStorage.getItem("copyClicked");
        const revisionNew = getRevisions(this.props.value?._id);
        const { revisionLength } = this.state;
        if (spec.id === "page" && checkNewDraftValue === "false") {
            return;
        }
        if (revisionNew.length !== 0 && revisionNew[0] && revisionNew[0].mode === "create") {
            if (spec.id === "page" && JSON.stringify(revisionNew[0].draftValue) === JSON.stringify(draftValue)) {
                return;
            }
            delete revisionNew[0].mode;
            setRevisions(this.props.value?._id, revisionNew);
        } else {
            if ((mode !== "admin_edit" || (mode === "admin_edit" && revisionLength > 0)) && revisionLength < revisionNew.length) {
                let tempRevLength = revisionLength;
                const storageLength = revisionNew.length;
                while (tempRevLength < storageLength) {
                    revisionNew.pop();
                    tempRevLength++;
                }
                setRevisions(this.props.value?._id, revisionNew);
            }
        }
        if (revisionNew.length <= 9) {
            if (spec.id === "webcontent" && prop.variable && prop.variable === "styleIds") {
                const objinfo: any = {};
                objinfo.draftValue = draftValue;
                objinfo.variable = prop.variable;
                objinfo.label = prop.label;
                if (newVal.length !== 0) {
                    objinfo.value = newVal[newVal.length - 1].label;
                    revisionNew.push(objinfo);
                } else {
                    objinfo.value = "Empty";
                    revisionNew.push(objinfo);
                }
            } else if (spec.id === "webcontent" && prop.variable && prop.variable === "tags") {
                const objinfo: any = {};
                objinfo.draftValue = draftValue;
                objinfo.variable = prop.variable;
                objinfo.label = prop.label;
                if (newVal.length !== 0) {
                    objinfo.value = newVal[newVal.length - 1].text;
                    revisionNew.push(objinfo);
                } else {
                    objinfo.value = "Empty";
                    revisionNew.push(objinfo);
                }
            } else if (
                (spec.id === "page" && prop.label && (prop.label === "SEO Items" || prop.label === "Structured Data")) ||
                (spec.id === "webcontent" &&
                    prop.variable &&
                    (prop.variable === "title" ||
                        prop.variable === "localizedSpecialCard" ||
                        prop.variable === "localizedImageContent" ||
                        prop.variable === "localizedContent" ||
                        prop.variable === "localizedCardContent"))
            ) {
                const localized = prop.label === "Structured Data" ? prop.label : prop.variable;
                const copyBool = copyclick && typeof copyclick === "string";
                if (revisionNew.length > 0 && !(JSON.stringify(revisionNew[revisionNew.length - 1].draftValue) === JSON.stringify(draftValue))) {
                    const prevNewVal = revisionNew[revisionNew.length - 1].newVal;
                    if (prevNewVal && typeof prevNewVal === "object" && !copyBool) {
                        newVal.map((obj: any, index: number) => {
                            Object.keys(obj || {}).map((key) => {
                                if (key !== "locale" && key !== "_id") {
                                    if (spec.id === "webcontent" && (key === "image" || key === "cardImage")) {
                                        if (
                                            obj[key] &&
                                            obj[key].originalUrl &&
                                            prevNewVal[index][key] &&
                                            prevNewVal[index][key].originalUrl &&
                                            !(JSON.stringify(obj[key].originalUrl) === JSON.stringify(prevNewVal[index][key].originalUrl))
                                        ) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key].originalUrl;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                        if (obj[key]?.originalUrl && !prevNewVal[index][key]) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key].originalUrl;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                    } else if (
                                        (spec.id === "webcontent" && prop.variable === "localizedContent" && key === "content") ||
                                        (prop.variable === "localizedCardContent" && key === "richTextDesc")
                                    ) {
                                        if (obj[key] && prevNewVal[index][key] && !(JSON.stringify(obj[key]) === JSON.stringify(prevNewVal[index][key]))) {
                                            const richText = JSON.parse(obj[key]);
                                            const rawText = richText.blocks[richText.blocks.length - 1];
                                            const text = rawText.text;
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = text;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                        if (obj[key] && !prevNewVal[index][key]) {
                                            const richText = JSON.parse(obj[key]);
                                            const rawText = richText.blocks[richText.blocks.length - 1];
                                            const text = rawText.text;
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = text;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                    } else {
                                        if (obj[key] && prevNewVal[index][key] && !(JSON.stringify(obj[key]) === JSON.stringify(prevNewVal[index][key]))) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key];
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                        if (obj[key] && !prevNewVal[index][key]) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key];
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                    }
                                }
                            });
                        });
                    } else if (copyBool) {
                        const tabIndex = Number(copyclick);
                        localStorage.removeItem("copyClicked");
                        let copyAllClicked = false;
                        newVal.map((obj: any) => {
                            Object.keys(obj || {}).map((key) => {
                                if ((key === "image" || key === "cardImage") && obj[key]?.originalUrl) {
                                    copyAllClicked = true;
                                } else {
                                    if (key !== "locale" && key !== "_id" && obj[key]) {
                                        copyAllClicked = true;
                                    }
                                }
                            });
                        });
                        if (copyAllClicked) {
                            const label = prop.variable === "localizedSpecialCard" ? "Special Card" : prop.label;
                            const objinfo: any = {};
                            objinfo.draftValue = draftValue;
                            objinfo.variable = localized + tabIndex;
                            objinfo.label = label;
                            objinfo.value = "Tab " + (tabIndex + 1) + " Data";
                            objinfo.newVal = newVal;
                            revisionNew.push(objinfo);
                        }
                    } else {
                        const copyNewVal: any = revisionNew[revisionNew.length - 1].draftValue[prop.variable];
                        if (copyNewVal && copyNewVal.length !== 0) {
                            newVal.map((obj: any, index: number) => {
                                Object.keys(obj || {}).map((key) => {
                                    if (key !== "locale" && key !== "_id") {
                                        if (key === "content" || key === "richTextDesc") {
                                            copyNewVal[index][key] = this.modifyToRawDraftJSEditorState(copyNewVal[index]?.[key]);
                                        }
                                        if (
                                            (spec.id === "webcontent" && prop.variable === "localizedContent" && key === "content") ||
                                            (prop.variable === "localizedCardContent" && key === "richTextDesc")
                                        ) {
                                            if (obj[key] && copyNewVal[index]?.[key] && !(JSON.stringify(JSON.parse(obj[key])) === JSON.stringify(JSON.parse(copyNewVal[index][key])))) {
                                                const richText = JSON.parse(obj[key]);
                                                const rawText = richText.blocks[richText.blocks.length - 1];
                                                const text = rawText.text;
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = text;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                            if (obj[key] && !copyNewVal[index]?.[key]) {
                                                const richText = JSON.parse(obj[key]);
                                                const rawText = richText.blocks[richText.blocks.length - 1];
                                                const text = rawText.text;
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = text;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        } else if (spec.id === "webcontent" && (key === "image" || key === "cardImage")) {
                                            if (
                                                obj[key] &&
                                                obj[key].originalUrl &&
                                                copyNewVal[index]?.[key] &&
                                                copyNewVal[index][key].originalUrl &&
                                                !(JSON.stringify(obj[key].originalUrl) === JSON.stringify(copyNewVal[index][key].originalUrl))
                                            ) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key].originalUrl;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                            if (obj[key] && obj[key].originalUrl && !copyNewVal[index]?.[key]) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key].originalUrl;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        } else {
                                            if (obj[key] && copyNewVal[index]?.[key] && !(JSON.stringify(obj[key]) === JSON.stringify(copyNewVal[index][key]))) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key];
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                            if (obj[key] && !copyNewVal[index]?.[key]) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key];
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        } else {
                            newVal.map((obj: any, index: number) => {
                                Object.keys(obj || {}).map((key) => {
                                    if (key !== "locale" && key !== "_id") {
                                        if (
                                            (spec.id === "webcontent" && prop.variable === "localizedContent" && key === "content") ||
                                            (prop.variable === "localizedCardContent" && key === "richTextDesc")
                                        ) {
                                            const richText = JSON.parse(obj[key]);
                                            const rawText = richText.blocks[richText.blocks.length - 1];
                                            const text = rawText.text;
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = text;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        } else if ((key === "image" || key === "cardImage") && obj[key] && obj[key].originalUrl) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key].originalUrl;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        } else {
                                            if (obj[key]) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                const objinfo: any = {};
                                                if (result) {
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key];
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    }
                }
                if (revisionNew.length === 0) {
                    if (!copyBool) {
                        newVal.map((obj: any, index: number) => {
                            Object.keys(obj || {}).map((key) => {
                                if (key !== "locale" && key !== "_id") {
                                    if (
                                        (spec.id === "webcontent" && prop.variable === "localizedContent" && key === "content") ||
                                        (prop.variable === "localizedCardContent" && key === "richTextDesc")
                                    ) {
                                        const richText = JSON.parse(obj[key]);
                                        const rawText = richText.blocks[richText.blocks.length - 1];
                                        const text = rawText.text;
                                        const result = prop.tabContent.find((content: any) => content.variable === key);
                                        if (result) {
                                            const objinfo: any = {};
                                            objinfo.draftValue = draftValue;
                                            objinfo.variable = localized + index;
                                            objinfo.label = result.label;
                                            objinfo.value = text;
                                            objinfo.newVal = newVal;
                                            revisionNew.push(objinfo);
                                        }
                                    } else if ((key === "image" || key === "cardImage") && obj[key] && obj[key].originalUrl) {
                                        const result = prop.tabContent.find((content: any) => content.variable === key);
                                        if (result) {
                                            const objinfo: any = {};
                                            objinfo.draftValue = draftValue;
                                            objinfo.variable = localized + index;
                                            objinfo.label = result.label;
                                            objinfo.value = obj[key].originalUrl;
                                            objinfo.newVal = newVal;
                                            revisionNew.push(objinfo);
                                        }
                                    } else {
                                        if (obj[key]) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key];
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                    }
                                }
                            });
                        });
                    }
                }
            } else {
                if (revisionNew.length && !(JSON.stringify(revisionNew[revisionNew.length - 1].draftValue) === JSON.stringify(draftValue))) {
                    if (checkNewDraftValue) {
                        const objinfo: any = {};
                        objinfo.draftValue = draftValue;
                        objinfo.variable = prop.variable;
                        objinfo.label = prop.label;
                        objinfo.modalWidget = checkNewDraftValue;
                        objinfo.newVal = newVal;
                        revisionNew.push(objinfo);
                        localStorage.removeItem("saveDraftValue");
                    } else {
                        const objinfo: any = {};
                        objinfo.draftValue = draftValue;
                        if (spec.id === "page" && widgetName && prop.widgetType) {
                            objinfo.label = prop.variable;
                            objinfo.value = widgetName;
                            revisionNew.push(objinfo);
                            localStorage.removeItem("widgetName");
                        } else {
                            objinfo.variable = prop.variable;
                            objinfo.label = prop.label;
                            objinfo.value = newVal;
                            revisionNew.push(objinfo);
                        }
                    }
                }
                if (revisionNew.length === 0) {
                    const objinfo: any = {};
                    objinfo.draftValue = draftValue;
                    if (spec.id === "page" && widgetName && prop.widgetType) {
                        objinfo.variable = prop.variable;
                        objinfo.label = prop.label;
                        objinfo.value = widgetName;
                        revisionNew.push(objinfo);
                        localStorage.removeItem("widgetName");
                    } else {
                        objinfo.variable = prop.variable;
                        objinfo.label = prop.label;
                        objinfo.value = newVal;
                        revisionNew.push(objinfo);
                    }
                }
            }
            setRevisions(this.props.value?._id, revisionNew);
        } else if (revisionNew.length === 10) {
            if (!(JSON.stringify(revisionNew[revisionNew.length - 1].draftValue) === JSON.stringify(draftValue))) {
                if (spec.id === "webcontent" && prop.variable && prop.variable === "styleIds") {
                    const objinfo: any = {};
                    objinfo.draftValue = draftValue;
                    objinfo.variable = prop.variable;
                    objinfo.label = prop.label;
                    if (newVal.length !== 0) {
                        objinfo.value = newVal[newVal.length - 1].label;
                        revisionNew.shift();
                        revisionNew.push(objinfo);
                    } else {
                        objinfo.value = "Empty";
                        revisionNew.shift();
                        revisionNew.push(objinfo);
                    }
                } else if (spec.id === "webcontent" && prop.variable && prop.variable === "tags") {
                    const objinfo: any = {};
                    objinfo.draftValue = draftValue;
                    objinfo.variable = prop.variable;
                    objinfo.label = prop.label;
                    if (newVal.length !== 0) {
                        objinfo.value = newVal[newVal.length - 1].text;
                        revisionNew.shift();
                        revisionNew.push(objinfo);
                    } else {
                        objinfo.value = "Empty";
                        revisionNew.shift();
                        revisionNew.push(objinfo);
                    }
                } else if (
                    (spec.id === "page" && prop.label && (prop.label === "SEO Items" || prop.label === "Structured Data")) ||
                    (spec.id === "webcontent" &&
                        prop.variable &&
                        (prop.variable === "title" ||
                            prop.variable === "localizedSpecialCard" ||
                            prop.variable === "localizedImageContent" ||
                            prop.variable === "localizedContent" ||
                            prop.variable === "localizedCardContent"))
                ) {
                    const localized = prop.label === "Structured Data" ? prop.label : prop.variable;
                    const copyBool = copyclick && typeof copyclick === "string";
                    const prevNewVal = revisionNew[revisionNew.length - 1].newVal;
                    if (prevNewVal && typeof prevNewVal === "object" && !copyBool) {
                        // eslint-disable-next-line max-lines-per-function
                        newVal.map((obj: any, index: number) => {
                            // eslint-disable-next-line max-lines-per-function
                            Object.keys(obj || {}).map((key) => {
                                if (key !== "locale" && key !== "_id") {
                                    if (spec.id === "webcontent" && (key === "image" || key === "cardImage")) {
                                        if (
                                            obj[key] &&
                                            obj[key].originalUrl &&
                                            prevNewVal[index][key] &&
                                            prevNewVal[index][key].originalUrl &&
                                            !(JSON.stringify(obj[key].originalUrl) === JSON.stringify(prevNewVal[index][key].originalUrl))
                                        ) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key].originalUrl;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                        if (obj[key]?.originalUrl && !prevNewVal[index][key]) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key].originalUrl;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                    } else if (
                                        (spec.id === "webcontent" && prop.variable === "localizedContent" && key === "content") ||
                                        (prop.variable === "localizedCardContent" && key === "richTextDesc")
                                    ) {
                                        if (obj[key] && prevNewVal[index][key] && !(JSON.stringify(obj[key]) === JSON.stringify(prevNewVal[index][key]))) {
                                            const richText = JSON.parse(obj[key]);
                                            const rawText = richText.blocks[richText.blocks.length - 1];
                                            const text = rawText.text;
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = text;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                        if (obj[key] && !prevNewVal[index][key]) {
                                            const richText = JSON.parse(obj[key]);
                                            const rawText = richText.blocks[richText.blocks.length - 1];
                                            const text = rawText.text;
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = text;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                    } else {
                                        if (obj[key] && prevNewVal[index][key] && !(JSON.stringify(obj[key]) === JSON.stringify(prevNewVal[index][key]))) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key];
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                        if (obj[key] && !prevNewVal[index][key]) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key];
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        }
                                    }
                                }
                            });
                        });
                    } else if (copyBool) {
                        const tabIndex = Number(copyclick);
                        localStorage.removeItem("copyClicked");
                        let copyAllClicked = false;
                        newVal.map((obj: any) => {
                            Object.keys(obj || {}).map((key) => {
                                if ((key === "image" || key === "cardImage") && obj[key] && obj[key].originalUrl) {
                                    copyAllClicked = true;
                                } else {
                                    if (key !== "locale" && key !== "_id" && obj[key]) {
                                        copyAllClicked = true;
                                    }
                                }
                            });
                        });
                        if (copyAllClicked) {
                            const label = prop.variable === "localizedSpecialCard" ? "Special Card" : prop.label;
                            revisionNew.shift();
                            const objinfo: any = {};
                            objinfo.draftValue = draftValue;
                            objinfo.variable = localized + tabIndex;
                            objinfo.label = label;
                            objinfo.value = "Tab " + (tabIndex + 1) + " Data";
                            objinfo.newVal = newVal;
                            revisionNew.push(objinfo);
                        }
                    } else {
                        const copyNewVal: any = revisionNew[revisionNew.length - 1].draftValue[prop.variable];
                        if (copyNewVal && copyNewVal.length !== 0) {
                            // eslint-disable-next-line max-lines-per-function
                            newVal.map((obj: any, index: number) => {
                                // eslint-disable-next-line max-lines-per-function
                                Object.keys(obj || {}).map((key) => {
                                    if (key !== "locale" && key !== "_id") {
                                        if (
                                            (spec.id === "webcontent" && prop.variable === "localizedContent" && key === "content") ||
                                            (prop.variable === "localizedCardContent" && key === "richTextDesc")
                                        ) {
                                            if (obj[key] && copyNewVal[index][key] && !(JSON.stringify(JSON.parse(obj[key])) === JSON.stringify(JSON.parse(copyNewVal[index][key])))) {
                                                const richText = JSON.parse(obj[key]);
                                                const rawText = richText.blocks[richText.blocks.length - 1];
                                                const text = rawText.text;
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    revisionNew.shift();
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = text;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                            if (obj[key] && !copyNewVal[index][key]) {
                                                const richText = JSON.parse(obj[key]);
                                                const rawText = richText.blocks[richText.blocks.length - 1];
                                                const text = rawText.text;
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    revisionNew.shift();
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = text;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        } else if (spec.id === "webcontent" && (key === "image" || key === "cardImage")) {
                                            if (
                                                obj[key] &&
                                                obj[key].originalUrl &&
                                                copyNewVal[index][key] &&
                                                copyNewVal[index][key].originalUrl &&
                                                !(JSON.stringify(obj[key].originalUrl) === JSON.stringify(copyNewVal[index][key].originalUrl))
                                            ) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    revisionNew.shift();
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key].originalUrl;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                            if (obj[key]?.originalUrl && !copyNewVal[index][key]) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    revisionNew.shift();
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key].originalUrl;
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        } else {
                                            if (obj[key] && copyNewVal[index][key] && !(JSON.stringify(obj[key]) === JSON.stringify(copyNewVal[index][key]))) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    revisionNew.shift();
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key];
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                            if (obj[key] && !copyNewVal[index][key]) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    revisionNew.shift();
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key];
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        } else {
                            newVal.map((obj: any, index: number) => {
                                Object.keys(obj || {}).map((key) => {
                                    if (key !== "locale" && key !== "_id") {
                                        if (
                                            (spec.id === "webcontent" && prop.variable === "localizedContent" && key === "content") ||
                                            (prop.variable === "localizedCardContent" && key === "richTextDesc")
                                        ) {
                                            const richText = JSON.parse(obj[key]);
                                            const rawText = richText.blocks[richText.blocks.length - 1];
                                            const text = rawText.text;
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = text;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        } else if ((key === "image" || key === "cardImage") && obj[key] && obj[key].originalUrl) {
                                            const result = prop.tabContent.find((content: any) => content.variable === key);
                                            if (result) {
                                                revisionNew.shift();
                                                const objinfo: any = {};
                                                objinfo.draftValue = draftValue;
                                                objinfo.variable = localized + index;
                                                objinfo.label = result.label;
                                                objinfo.value = obj[key].originalUrl;
                                                objinfo.newVal = newVal;
                                                revisionNew.push(objinfo);
                                            }
                                        } else {
                                            if (obj[key]) {
                                                const result = prop.tabContent.find((content: any) => content.variable === key);
                                                if (result) {
                                                    revisionNew.shift();
                                                    const objinfo: any = {};
                                                    objinfo.draftValue = draftValue;
                                                    objinfo.variable = localized + index;
                                                    objinfo.label = result.label;
                                                    objinfo.value = obj[key];
                                                    objinfo.newVal = newVal;
                                                    revisionNew.push(objinfo);
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    }
                } else {
                    if (checkNewDraftValue) {
                        const objinfo: any = {};
                        objinfo.draftValue = draftValue;
                        objinfo.variable = prop.variable;
                        objinfo.label = prop.label;
                        objinfo.modalWidget = checkNewDraftValue;
                        objinfo.newVal = newVal;
                        revisionNew.shift();
                        revisionNew.push(objinfo);
                        localStorage.removeItem("saveDraftValue");
                    } else {
                        const objinfo: any = {};
                        objinfo.draftValue = draftValue;
                        if (spec.id === "page" && widgetName && prop.widgetType) {
                            objinfo.variable = prop.variable;
                            objinfo.label = prop.label;
                            objinfo.value = widgetName;
                            revisionNew.shift();
                            revisionNew.push(objinfo);
                            localStorage.removeItem("widgetName");
                        } else {
                            objinfo.variable = prop.variable;
                            objinfo.label = prop.label;
                            objinfo.value = newVal;
                            revisionNew.shift();
                            revisionNew.push(objinfo);
                        }
                    }
                }
            }
            setRevisions(this.props.value?._id, revisionNew);
        }
        this.setState({
            shouldDisableUndo: mode === "admin_edit" && spec.id === "page" && revisionNew.length > 1 ? false : mode === "admin_edit" && spec.id === "page" && revisionLength === 1,
            shouldDisableRedo: true,
            revisionLength: revisionNew.length,
        });
    };

    // eslint-disable-next-line max-lines-per-function
    private undo = () => {
        const { alerts, spec, mode } = this.props;
        const { revisionLength, modalCheck } = this.state;
        const revisionNew = getRevisions(this.props.value?._id);
        const undoRedoInfo = JSON.parse(localStorage.getItem("undoRedoInfo") || "[]");
        if (mode !== "admin_edit" && revisionLength === 1) {
            if (undoRedoInfo.length === 1) {
                if (spec.id === "webcontent") {
                    const webContent: any = {};
                    webContent.draftValue = { ...{}, name: "" };
                    webContent.variable = revisionNew[revisionLength - 1].variable;
                    webContent.spec = spec.id;
                    undoRedoInfo.shift();
                    undoRedoInfo.push(webContent);
                    localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                } else {
                    const page: any = {};
                    page.draftValue = { ...{}, root: [] };
                    page.variable = revisionNew[revisionLength - 1].variable;
                    page.spec = spec.id;
                    undoRedoInfo.shift();
                    undoRedoInfo.push(page);
                    localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                }
            }
            if (undoRedoInfo.length === 0) {
                if (spec.id === "webcontent") {
                    const webContent: any = {};
                    webContent.draftValue = { ...{}, name: "" };
                    webContent.variable = revisionNew[revisionLength - 1].variable;
                    webContent.spec = spec.id;
                    undoRedoInfo.push(webContent);
                    localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                } else {
                    const page: any = {};
                    page.draftValue = { ...{}, root: [] };
                    page.variable = revisionNew[revisionLength - 1].variable;
                    page.spec = spec.id;
                    undoRedoInfo.push(page);
                    localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                }
            }
            this.setState(
                {
                    draftValue: spec.id === "webcontent" ? { ...{}, name: "" } : { ...{}, root: [] },
                    shouldDisableRedo: false,
                    shouldDisableUndo: true,
                    revisionLength: 0,
                },
                () => {
                    const action: FormEditAction = {
                        type: ActionType.FormEdited,
                        actionType: FormEditActionType.edited,
                        payload: this.state.draftValue,
                    };

                    if (this.props.onChange) {
                        this.props.dispatchAction(action);
                        this.props.onChange(this.state.draftValue);
                    }
                }
            );
            if (alerts) {
                alerts.push({ color: "info", message: revisionNew[revisionLength - 1].value + " of " + revisionNew[revisionLength - 1].label + " has been undone" });
            }
        } else {
            if (revisionLength > 0) {
                if (spec.id === "page" && !modalCheck && revisionNew[revisionLength - 1].modalWidget && typeof revisionNew[revisionLength - 1].modalWidget === "string") {
                    this.setState({
                        modalCheck: true,
                        checkUndoRedo: "undo",
                        widgetName: revisionNew[revisionLength - 1].modalWidget,
                    });
                } else {
                    const objinfo: any = {};
                    objinfo.draftValue = revisionNew[revisionLength - 2].draftValue;
                    objinfo.variable = revisionNew[revisionLength - 1].variable;
                    objinfo.spec = spec.id;
                    if (undoRedoInfo.length === 1) {
                        undoRedoInfo.shift();
                        undoRedoInfo.push(objinfo);
                        localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                    }
                    if (undoRedoInfo.length === 0) {
                        undoRedoInfo.push(objinfo);
                        localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                    }
                    this.setState(
                        {
                            draftValue: revisionNew[revisionLength - 2].draftValue,
                            shouldDisableRedo: false,
                            shouldDisableUndo: mode === "admin_edit" && revisionLength - 2 === 0,
                            revisionLength: revisionLength - 1,
                            modalCheck: false,
                            checkUndoRedo: "",
                            widgetName: "",
                        },
                        () => {
                            const action: FormEditAction = {
                                type: ActionType.FormEdited,
                                actionType: FormEditActionType.edited,
                                payload: this.state.draftValue,
                            };

                            if (this.props.onChange) {
                                this.props.dispatchAction(action);
                                this.props.onChange(this.state.draftValue);
                            }
                        }
                    );
                    if (alerts) {
                        if (spec.id === "page" && revisionNew[revisionLength - 1] && revisionNew[revisionLength - 1].modalWidget && typeof revisionNew[revisionLength - 1].modalWidget === "string") {
                            alerts.push({ color: "info", message: revisionNew[revisionLength - 1].modalWidget + " configuration has been undone" });
                        } else {
                            alerts.push({ color: "info", message: revisionNew[revisionLength - 1].value + " of " + revisionNew[revisionLength - 1].label + " has been undone" });
                        }
                    }
                }
            }
        }
    };

    private redo = () => {
        const { revisionLength, modalCheck } = this.state;
        const { alerts, spec } = this.props;
        const revisionNew = getRevisions(this.props.value?._id);
        const undoRedoInfo = JSON.parse(localStorage.getItem("undoRedoInfo") || "[]");
        if (revisionLength === revisionNew.length) {
            const objinfo: any = {};
            objinfo.draftValue = revisionNew[revisionLength - 1].draftValue;
            if (undoRedoInfo.length === 0) {
                undoRedoInfo.push(objinfo);
                localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
            }
            if (undoRedoInfo.length === 1) {
                undoRedoInfo.shift();
                undoRedoInfo.push(objinfo);
                localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
            }
            this.setState(
                {
                    draftValue: revisionNew[revisionLength - 1].draftValue,
                    shouldDisableRedo: true,
                },
                () => {
                    const action: FormEditAction = {
                        type: ActionType.FormEdited,
                        actionType: FormEditActionType.edited,
                        payload: this.state.draftValue,
                    };

                    if (this.props.onChange) {
                        this.props.dispatchAction(action);
                        this.props.onChange(this.state.draftValue);
                    }
                }
            );
            if (alerts) {
                alerts.push({ color: "info", message: revisionNew[revisionLength - 1].value + " of " + revisionNew[revisionLength - 1].label + " has been redone" });
            }
        } else if (revisionLength < revisionNew.length) {
            if (spec.id === "page" && !modalCheck && revisionNew[revisionLength].modalWidget && typeof revisionNew[revisionLength].modalWidget === "string") {
                this.setState({
                    modalCheck: true,
                    checkUndoRedo: "redo",
                    widgetName: revisionNew[revisionLength].modalWidget,
                });
            } else {
                const objinfo: any = {};
                objinfo.draftValue = revisionNew[revisionLength].draftValue;
                objinfo.variable = revisionNew[revisionLength].variable;
                objinfo.spec = spec.id;
                if (undoRedoInfo.length === 1) {
                    undoRedoInfo.shift();
                    undoRedoInfo.push(objinfo);
                    localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                }
                if (undoRedoInfo.length === 0) {
                    undoRedoInfo.push(objinfo);
                    localStorage.setItem("undoRedoInfo", JSON.stringify(undoRedoInfo));
                }
                this.setState(
                    {
                        draftValue: revisionNew[revisionLength].draftValue,
                        shouldDisableRedo: false,
                        shouldDisableUndo: false,
                        revisionLength: revisionLength + 1,
                        modalCheck: false,
                        checkUndoRedo: "",
                        widgetName: "",
                    },
                    () => {
                        const action: FormEditAction = {
                            type: ActionType.FormEdited,
                            actionType: FormEditActionType.edited,
                            payload: this.state.draftValue,
                        };
                        if (this.props.onChange) {
                            this.props.dispatchAction(action);
                            this.props.onChange(this.state.draftValue);
                        }
                    }
                );
                if (revisionLength + 1 === revisionNew.length) {
                    this.setState({ shouldDisableRedo: true });
                }
                if (alerts) {
                    if (revisionNew[revisionLength] && revisionNew[revisionLength].modalWidget && typeof revisionNew[revisionLength].modalWidget === "string") {
                        alerts.push({ color: "info", message: revisionNew[revisionLength].modalWidget + " configuration has been redone" });
                    } else {
                        alerts.push({ color: "info", message: revisionNew[revisionLength].value + " of " + revisionNew[revisionLength].label + " has been redone" });
                    }
                }
            }
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private save = (saveOptions?: SubmitButtonOptions, frontPageEdit?: boolean) => {
        const { spec, alerts, mode, widgetOptions } = this.props;
        const { draftValue } = this.state;
        if (widgetOptions?.enableReadOnly) {
            this.toggleReadOnlyState();
        }
        if (frontPageEdit) {
            const options = { id: (draftValue as any)._id, item: draftValue };
            OptionsApi.update(options);
        }

        if (this.state.isFormEdited) {
            const contentEdited = true;
            localStorage.removeItem("contentEdited");
            localStorage.setItem("contentEdited", JSON.stringify(contentEdited));
        }
        if (mode === "admin_edit" && (spec.id === "webcontent" || spec.id === "page")) {
            this.setState({
                shouldDisableRedo: true,
                shouldDisableUndo: true,
            });
            removeRevisions(this.props.value?._id);
            removeRevisionsSec(this.props.value?._id);
            localStorage.removeItem("editWidgetPopUp");
            localStorage.removeItem("saveDraftValue");
            localStorage.removeItem("richtext");
            localStorage.removeItem("undoRedoInfo");
            localStorage.removeItem("widgetName");
            localStorage.removeItem("modalToast");
            localStorage.removeItem("copyClicked");
        }
        const isRequiredCheckSuccessful = checkRequiredFields(spec, draftValue);
        if (!isRequiredCheckSuccessful.requiredChecked && alerts) {
            isRequiredCheckSuccessful.labelArray.forEach((term: string | { key?: string }) => {
                term && alerts.push({ color: "danger", message: `${getI18nLocaleString(namespaceList.admin, "requiredFailed")}: ${typeof term === "string" ? term : term?.key}` });
            });
            return true;
        }
        if (!this.checkEmailFormat()) {
            if (alerts) {
                alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "wrongEmail") });
            }
            return true;
        }
        if (!this.checkNumberRange()) {
            if (alerts) {
                alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "notInRange") });
            }
            return true;
        }
        if (!this.checkNumberLength()) {
            if (alerts) {
                alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "lengthDoesNotMatch") });
            }
            return true;
        }
        if (spec.id === "site") {
            this.checkDuplicateHost(draftValue.host).then((isDuplicateHostExist) => {
                if (isDuplicateHostExist) {
                    if (alerts) {
                        alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "hostExists") });
                    }
                    return;
                }
                this.submit(saveOptions);
            });
        } else if (spec.id === "locale") {
            this.checkDuplicateLocaleItems(draftValue.code, draftValue.default).then((duplicatePart) => {
                if (duplicatePart) {
                    if (duplicatePart[0] === "duplicateCodeName") {
                        if (alerts) {
                            alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "codeExists") });
                        }
                    }
                    if (duplicatePart[1] === "duplicateDefault") {
                        if (alerts) {
                            alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "defaultExists") });
                        }
                    }
                    return;
                }
                this.submit(saveOptions);
            });
        } else if (spec.id === "category") {
            this.setState({ shouldDisableSubmit: true });
            checkDuplicateCategoryItems(spec, draftValue, mode).then((duplicateCategory) => {
                if (duplicateCategory && alerts) {
                    alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "codeExists") });
                    this.setState({ shouldDisableSubmit: false });
                    return;
                }
                this.submit(saveOptions);
            });
        } else if (spec.id === "post") {
            this.setState({ shouldDisableSubmit: true });
            draftValue.localizedContent?.map((content: any) => {
                if (content.content?.startsWith('{"blocks"') || content.content?.startsWith('{"entityMap"')) {
                    if (validateContentKeys({ content, keys: ["content"] })) {
                        const contentRaw = JSON.parse(content.content);
                        content.content = draftToHtml(contentRaw);
                    } else {
                        delete content.content;
                    }
                }
            });
            draftValue.localizedDetailContent?.map((content: any) => {
                if (content.content?.startsWith('{"blocks"') || content.content?.startsWith('{"entityMap"')) {
                    if (validateContentKeys({ content, keys: ["content"] })) {
                        const contentRaw = JSON.parse(content.content);
                        content.content = draftToHtml(contentRaw);
                    } else {
                        delete content.content;
                    }
                }
            });
            checkDuplicatePermalink(draftValue).then((doesUrlExist: boolean) => {
                if (doesUrlExist && alerts) {
                    alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "permalinkExists") });
                    this.setState({ shouldDisableSubmit: false });
                    return;
                }
                this.submit(saveOptions);
            });
        } else if (spec.id === "webcontent" && draftValue.isRawHtmlWidget && draftValue.validateCss && draftValue.localizedContent) {
            this.setState({ shouldDisableSubmit: true });
            let isCssValid = true;
            let localeList: StringMultiSelectOption[];
            (async function getLocale() {
                await getLocaleOptions().then((response) => (localeList = response));
            })();
            validateCss(draftValue?.localizedContent).then((results) => {
                results
                    .filter((response) => response)
                    ?.map((result) => {
                        if (!result?.valid) {
                            isCssValid = false;
                            const localeData = localeList?.filter((locale) => locale.value === result?.locale);
                            result?.errors.map((error) => alerts?.push({ color: "danger", message: `CSS Error: Line ${error.line} - ${error.message} for ${localeData[0]?.text}` }));
                        }
                    });
                if (isCssValid) {
                    alerts?.push({ color: "success", message: getI18nLocaleString(namespaceList.admin, "cssValidated") });
                    this.submit(saveOptions);
                }
                this.setState({ shouldDisableSubmit: false });
            });
        } else if (spec.id === "webcontent" && (draftValue.localizedContent || draftValue.localizedCardContent)) {
            if (!draftValue.isRawHtmlWidget) {
                draftValue.localizedContent?.map((content: any) => {
                    if (content.content?.startsWith('{"blocks"') || content.content?.startsWith('{"entityMap"')) {
                        if (validateContentKeys({ content, keys: ["content"] })) {
                            const contentRaw = JSON.parse(content.content);
                            content.content = draftToHtml(contentRaw);
                        } else {
                            delete content.content;
                        }
                    }
                });
                draftValue.localizedCardContent?.map((content: any) => {
                    if (content.richTextDesc?.startsWith('{"blocks"') || content.richTextDesc?.startsWith('{"entityMap"')) {
                        if (validateContentKeys({ content, keys: ["richTextDesc"] })) {
                            const contentRaw = JSON.parse(content.richTextDesc);
                            content.richTextDesc = draftToHtml(contentRaw);
                        } else {
                            delete content.richTextDesc;
                        }
                    }
                });
            } else {
                draftValue.localizedContent?.map((content: any) => {
                    if (content.content?.startsWith('{"blocks"') || content.content?.startsWith('{"entityMap"')) {
                        if (validateContentKeys({ content, keys: ["content"] })) {
                            const contentRaw = JSON.parse(content.content);
                            content.content = convertFromRaw(contentRaw).getPlainText();
                        } else {
                            delete content.content;
                        }
                    }
                });
            }
            this.submit(saveOptions);
        } else if (spec.id === "page") {
            draftValue.root?.map((widget: any) => {
                const recursiveCheck = (widget: any) => {
                    if (widget.type === "static") {
                        widget.options.localized.map((content: any) => {
                            if (content.content?.startsWith('{"blocks"') || content.content?.startsWith('{"entityMap"')) {
                                if (validateContentKeys({ content, keys: ["content"] })) {
                                    const contentRaw = JSON.parse(content.content);
                                    content.content = draftToHtml(contentRaw);
                                } else {
                                    delete content.content;
                                }
                            }
                        });
                    } else if (widget.children.length) {
                        widget.children.map((childWidget: any) => recursiveCheck(childWidget));
                    }
                };
                recursiveCheck(widget);
            });
            this.submit(saveOptions);
        } else if (widgetOptions?.sendMemo) {
            this.handleSendMemo();
        } else {
            this.submit(saveOptions);
        }
    };

    private handleSendMemo = async () => {
        const { context, dynamicFilter } = this.props;
        const { draftValue } = this.state;
        const apiContext = context || globalApiContext();
        const env = await getMxtsEnv(apiContext);
        if (dynamicFilter?.reservationId) {
            this.setState({ disableFormSubmit: true });
            const memoKeys = Object.entries(draftValue).reduce((acc, [key, value]) => {
                if (key.startsWith("__RESERVATION__MEMO__")) {
                    acc.push({ key, value });
                }
                return acc;
            }, [] as Array<{ key: string; value: any }>);

            const memoCategoryId = parseInt(memoKeys[0]?.key.split("-")[1] || "", 10) || (await getFallbackMemoCategoryId(apiContext.mxtsApi, env));

            try {
                const reservation = await apiContext.mxtsApi.getReservation(env, {}, [{ key: "reservationId", value: dynamicFilter.reservationId }]);

                if (reservation?.memoManagerId && memoCategoryId) {
                    for (const memoItem of memoKeys) {
                        await apiContext.mxtsApi.createMemo(env, {
                            memocategoryId: memoCategoryId,
                            content: memoItem.value,
                            managerId: reservation.memoManagerId,
                        });
                    }
                }
                this.setState({ draftValue: {}, shouldDisableSubmit: true, disableFormSubmit: false });
            } catch (error) {
                apiContext.logger.error(error);
            }
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private submit = async (saveOptions?: SubmitButtonOptions) => {
        const { spec, alerts, context, widgetOptions, localizedWidgetOptions, onSave, mode, dispatchAction, myEnvState, customFieldsList, dynamicFilter } = this.props;
        const apiContext = context || globalApiContext();
        const { dynamicData, draftValue, isAdminSide } = this.state;
        const env = await getMxtsEnv(apiContext);
        if (!widgetOptions?.updateCustomerDetails) {
            if (widgetOptions?.sendEmail && localizedWidgetOptions) {
                const { sendDynamicDataPerEmail } = widgetOptions;
                const reservation = myEnvState?.selectedReservation?.reservation;
                const draftValueForMail = { ...draftValue };
                this.setState({ disableFormSubmit: true });

                if (widgetOptions.sendReservationNumberWithEmail && reservation?.reservationNumber) {
                    draftValueForMail.reservationNumber = myEnvState?.selectedReservation?.reservation.reservationNumber;
                }

                if (widgetOptions?.useResortEmail && reservation?.resortId) {
                    const resortId = reservation?.resortId;
                    const resortAddress = await context?.mxtsApi?.resortsWithAddress(env, { resortIds: resortId ? [resortId] : [] });
                    const resortEmail = resortAddress?.addresses[0].email;
                    draftValueForMail.email = resortEmail;
                }
                if (draftValueForMail?.attachment) {
                    const base64Content = await this.convertBase64(draftValueForMail.attachment);
                    draftValueForMail.attachment = base64Content;
                }
                env.retryCount = 0;
                // TODO: Remove this logic after replacing title: customerData.titleId with title: customerData.title in parseCustomer (customer.util.ts).
                if (draftValueForMail?.title) {
                    const locale = context?.currentLocale.code || context?.site.locale.code;
                    const title = (await apiContext.mxtsApi.getCustomerTitles(env, locale ? { locale } : {})).find((customerTitle: CustomerTitle) => customerTitle.titleId === draftValueForMail?.title)
                        ?.title;
                    if (title) {
                        draftValueForMail.title = title;
                    }
                }
                if (widgetOptions?.formId) {
                    const dateFieldsPersistFormat: string[] = [];
                    const form = await FormApi.findById({ id: widgetOptions?.formId });
                    const fieldsArray = flatten(form!.elements, (parent: any) => parent.children);
                    const dateFields = fieldsArray.filter((field) => field.type === "form-date");
                    dateFields.forEach((dateField) => {
                        if (dateField.options.persistDateFormat) {
                            dateFieldsPersistFormat.push(dateField.options.fieldId as string);
                        }
                    });
                    dateFieldsPersistFormat.forEach((dateField) => {
                        if (draftValueForMail[dateField]) {
                            draftValueForMail[dateField] = moment(draftValueForMail[dateField], DATE_FORMAT.ELASTIC).format(DATE_FORMAT.DISPLAY);
                        }
                    });
                }
                await sendFormEmail({
                    env,
                    reCaptchaToken: await this.getReCaptchaToken(),
                    newValue: draftValueForMail,
                    widgetOptions,
                    context,
                    dynamicData: sendDynamicDataPerEmail ? dynamicData : undefined,
                    setEmailAlertMessage: (type: EmailAlertMessageType) => {
                        if (type === EmailAlertMessageType.Error) {
                            this.setState({
                                emailSubmissionDetail: { alertType: EmailAlertMessageType.Error, message: getI18nLocaleString(namespaceList.pluginForm, "errorMsgForEmail") },
                            });
                        }
                        if (type === EmailAlertMessageType.Success) {
                            this.setState(
                                () => ({
                                    emailSubmissionDetail: { alertType: EmailAlertMessageType.Success, message: localizedWidgetOptions.feedbackMessage || "" },
                                    draftValue: !widgetOptions?.fillContactForm ? {} : this.getDefaultDraftValues(this.props),
                                }),
                                () => {
                                    this.setValidationforRequiredFields();
                                }
                            );
                        }
                        this.focusToFeedbackMessage();
                    },
                });
            }

            if (onSave) {
                let shouldDisableSubmit = false;
                if (mode === "create" || (widgetOptions?.sendEmail && !isAdminSide)) {
                    shouldDisableSubmit = true;
                }
                if (spec.id === "webcontent" && (draftValue as any).buttonUrl) {
                    this.setCardPermalink(saveOptions);
                } else {
                    const sourceProperties = spec.properties?.find(({ variable }) => variable === "source") as InputSpecSelect<any, any> | undefined;
                    let draftValues = { ...draftValue };
                    if (sourceProperties && sourceProperties.visible && !sourceProperties.visible({} as any)) {
                        // NOTE:- set default soure value when Source widget will be hidden
                        draftValues = { ...draftValue, [sourceProperties.variable as string]: sourceProperties.default };
                    }
                    const id: Promise<string> | void = onSave(draftValues, saveOptions, await this.getReCaptchaToken());
                    if (spec.id === "post" && id && (mode === "create" || mode === "admin_edit")) {
                        // cloning and passing the state so that is stays in the function scope and not gets affected by state change.
                        // There is an extra effect going on here due to componentWillReceiveProps being fired while creating a post
                        this.performPostfallbackActions(id, { ...draftValues });
                    }
                }
                this.setState({ isFormEdited: false, isChangeUpdated: true, shouldDisableSubmit }, () => {
                    const action: FormEditAction = {
                        type: ActionType.FormEdited,
                        actionType: FormEditActionType.edited,
                        payload: undefined,
                    };
                    dispatchAction(action);
                });
            } else {
                throw new Error(`Save callback is required in ${mode} mode`);
            }
            const { feedbackMessage, siteId, pageId } = localizedWidgetOptions ?? {};
            if (localizedWidgetOptions && !widgetOptions?.sendEmail && feedbackMessage && alerts) {
                alerts.push({ color: "success", message: feedbackMessage });
            }
            if (localizedWidgetOptions?.enableFeedbackMethod && siteId && pageId) {
                const url = await getPageUrl({ siteId, pageId, siteApi: SiteApi });
                if (url) {
                    window.location.href = url;
                }
            }
        }
        if (widgetOptions?.updateCustomerDetails) {
            const mxtsApi = context?.mxtsApi || MxtsApi;
            const { customerDataUpdatedMessage } = localizedWidgetOptions || {};
            this.setState({ disableFormSubmit: true });
            const parsedCustomerData = parseFormCustomerData(draftValue);
            const isCustomerDataUpdated = await (draftValue.owner
                ? mxtsApi.updateOwnerDetails(env, { ...parsedCustomerData, ownerType: parsedCustomerData.ownerType as any }, [{ key: "ownerId", value: draftValue.customerId }])
                : mxtsApi.updateCustomerDetails(env, parsedCustomerData, [{ key: "customerId", value: draftValue.customerId }])
            )
                .then(() => true)
                .catch((error) => {
                    console.warn("Failed to update customer details", error);
                    return false;
                });
            const customerData = await getMyEnvMainCustomer(env);
            const customerFullAddress = customerData?.addresses && customerData.addresses[0];
            const parsedCustomerAddressData = parseFormAddressData(draftValue);
            if (customerFullAddress && parsedCustomerAddressData) {
                const filteredAddress = Object.assign(customerFullAddress, parsedCustomerAddressData);
                const isCustomerAddressUpdated = customerFullAddress && (await mxtsApi.updateCustomerAddress(env, filteredAddress, [{ key: "addressId", value: customerFullAddress.addressId }]));
                if (isCustomerDataUpdated && alerts && isCustomerAddressUpdated && customerDataUpdatedMessage) {
                    alerts.push({ color: isCustomerDataUpdated ? "success" : "danger", message: customerDataUpdatedMessage });
                }
            }
            const myEnvMainCustomerId = await getMainCustomerIdFromLoginToken();
            if (myEnvMainCustomerId === draftValue.customerId) {
                dispatchAction(refreshMyEnvCustomer() as any);
            }
        }
        // update/save custom fields on save
        const selectedAccoTypeReservedResource = getSelectedAccoTypeReservedResource(myEnvState.selectedReservation);

        if (customFieldsList?.length && myEnvState?.selectedReservation?.reservation?.reservationId && selectedAccoTypeReservedResource?.reservedResourceId) {
            const updatedCustomFields = updateCustomFields(customFieldsList, draftValue);
            await MxtsApi.createCustomFields(env, updatedCustomFields, [
                { key: "reservationId", value: myEnvState.selectedReservation?.reservation?.reservationId },
                { key: "reservedResourceId", value: selectedAccoTypeReservedResource.reservedResourceId },
            ]);
        }
        this.setState({ disableFormSubmit: false });
        if (widgetOptions?.fillContactForm && !isAdminSide) {
            this.handleFormValidation();
        }
    };

    private convertBase64 = (file: File) =>
        new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

    private getReCaptchaToken = async (): Promise<string> => {
        const { isReCaptchaApplied } = this.props;
        if (isReCaptchaApplied) {
            const { executeRecaptcha } = (this.props as IWithGoogleReCaptchaProps).googleReCaptchaProps;
            if (executeRecaptcha) {
                return executeRecaptcha("submit").catch((err) => {
                    console.error("Failed to verify recaptcha", err);
                    return "";
                });
            }
        }
        return "";
    };

    private checkDuplicateHost = async (host: string) => {
        // ** Check if user trying to create/edit the site with duplicate host
        const { spec, mode, context } = this.props;
        let duplicateHostNameSite: any = null;
        if (spec.id === "site") {
            const sites: Array<Site & WithId> = context ? await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } }) : await SiteApi.find({ projection: { sitemap: 0 } });
            if (sites && sites.length > 0) {
                duplicateHostNameSite = mode === "create" ? sites.find((si) => host === si.host) : sites.find((si) => this.state.draftValue._id !== si._id && host === si.host);
            }
        }
        return !!duplicateHostNameSite;
    };

    private checkDuplicateLocaleItems = async (code: string, isDefault: boolean) => {
        const { spec, mode } = this.props;
        const { draftValue } = this.state;
        let duplicateCodeName: any = null;
        let duplicateDefaultLocale: any = null;
        if (spec.id === "locale") {
            const locales: Array<Locale & WithId> = await LocaleApi.find();
            if (locales && locales.length > 0) {
                duplicateCodeName = mode === "create" ? locales.find((si) => code === si.code) : locales.find((si) => draftValue._id !== si._id && code === si.code);

                // check only if any default is selected previously
                if (isDefault) {
                    duplicateDefaultLocale =
                        mode === "create" ? locales.find((localeList) => localeList.default === isDefault) : locales.find((loc) => draftValue._id !== loc._id && loc.default === isDefault);
                }
            }
        }
        if (duplicateCodeName || duplicateDefaultLocale) {
            return [duplicateCodeName ? "duplicateCodeName" : undefined, duplicateDefaultLocale ? "duplicateDefault" : undefined];
        }
        return false;
    };

    private checkEmailFormat = () => {
        const { properties } = this.props.spec;
        const { draftValue } = this.state;
        let validEmails = true;
        properties.map((prop) => {
            const email = draftValue[prop.variable];
            if (prop.type === "email" && email) {
                // TODO move to a util method
                const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                validEmails = emailFormat.test(email.toLowerCase());
                if (!validEmails) {
                    return;
                }
            }
        });
        return validEmails;
    };

    private checkNumberRange = () => {
        const { properties } = this.props.spec;
        const { draftValue } = this.state;
        let validRange = true;
        properties.map((prop) => {
            const numberVal = draftValue[prop.variable];
            if (prop.type === "number" && numberVal && prop.minimumNumberRange && +prop.minimumNumberRange > 0 && prop.maximumNumberRange && +prop.maximumNumberRange > 0) {
                if (!(+numberVal + 1 > prop.minimumNumberRange && +prop.minimumNumberRange && +numberVal - 1 < prop.maximumNumberRange && +prop.maximumNumberRange)) {
                    validRange = false;
                }
                if (!validRange) {
                    return;
                }
            }
            if (prop.type === "number" && numberVal && ((prop.minimumNumberRange && +prop.minimumNumberRange > 0) || (prop.maximumNumberRange && +prop.maximumNumberRange > 0))) {
                const validationCheck = this.validateNumberRange(validRange, numberVal, prop);
                if (!validationCheck) {
                    validRange = false;
                    return;
                }
            }
        });
        return validRange;
    };

    private validateNumberRange = (validRange: boolean, numberVal: any, prop: any) => {
        if (prop.minimumNumberRange && +prop.minimumNumberRange > +numberVal) {
            validRange = false;
        }
        if (prop.maximumNumberRange && +prop.maximumNumberRange < +numberVal) {
            validRange = false;
        }
        return validRange;
    };

    private checkNumberLength = () => {
        const { properties } = this.props.spec;
        const { draftValue } = this.state;
        let validLength = true;
        properties.map((prop) => {
            const numberVal = draftValue[prop.variable];
            if (prop.type === "number" && numberVal && prop.minimumNumberLength && +prop.minimumNumberLength > 0 && prop.maximumNumberLength && +prop.maximumNumberLength > 0) {
                if (!(numberVal.length + 1 > prop.minimumNumberLength && +prop.minimumNumberLength && numberVal.length - 1 < prop.maximumNumberLength && +prop.maximumNumberLength)) {
                    validLength = false;
                }
                if (!validLength) {
                    return;
                }
            }
            if (prop.type === "number" && numberVal && ((prop.minimumNumberLength && +prop.minimumNumberLength > 0) || (prop.maximumNumberLength && +prop.maximumNumberLength > 0))) {
                const validationCheck = this.validateNumberLength(validLength, numberVal, prop);
                if (!validationCheck) {
                    validLength = false;
                    return;
                }
            }
        });
        return validLength;
    };

    private validateNumberLength = (validLength: boolean, numberVal: any, prop: any) => {
        if (prop.minimumNumberLength && +prop.minimumNumberLength > numberVal.length) {
            validLength = false;
        }
        if (prop.maximumNumberLength && +prop.maximumNumberLength < numberVal.length) {
            validLength = false;
        }
        return validLength;
    };

    private onSite = () => {
        const { viewOnSite } = this.props.spec;
        if (viewOnSite) {
            viewOnSite(this.props.initialItem);
        }
    };

    private checkDateRange = (startDate: string, endDate: string, submittedDate: string) =>
        moment(startDate, DATE_FORMAT.DISPLAY).isSameOrBefore(moment(submittedDate, DATE_FORMAT.DISPLAY)) &&
        moment(endDate, DATE_FORMAT.DISPLAY).isSameOrAfter(moment(submittedDate, DATE_FORMAT.DISPLAY));

    // eslint-disable-next-line max-lines-per-function
    private onExport = async (startDate: string, endDate: string) => {
        const formId = this.props.value._id;
        const cmsOptions = await getCMSOptions((this.props.context || globalApiContext()).cmsApi);
        const locale: (Locale & WithId) | null = await LocaleApi.findByCode({
            code: getLocaleCodeFromCMSOptions(cmsOptions),
        });
        const creationDate = moment((cmsOptions as any).createdAt).format(DATE_FORMAT.DISPLAY);
        const today = moment(new Date(), DATE_FORMAT.DISPLAY).format(DATE_FORMAT.DISPLAY);
        const formattedStartDate = startDate.length > 0 ? moment(startDate).format(DATE_FORMAT.DISPLAY) : creationDate;
        const formattedEndDate = endDate.length > 0 ? moment(endDate).format(DATE_FORMAT.DISPLAY) : today;
        const exportFormData: IExportFormDate = await FormResponseApi.exportFormData({ query: { form: formId } });
        const fieldIds: string[] = [];
        const formName: string = exportFormData?.form?.name || "";

        if (exportFormData?.formResponses.length) {
            globalApiContext().logger.info(
                `Form ${formName} sent out ${exportFormData?.formResponses.length} requests Form Data: ${encodeURIComponent(JSON.stringify(exportFormData?.formResponses))}`
            );
        }
        if (formName) {
            exportFormData?.formResponses?.forEach((formResponse: FormResponse & WithId) => {
                const submittedDate = moment((formResponse as any).createdAt).format(DATE_FORMAT.DISPLAY);
                const isValid = this.checkDateRange(formattedStartDate, formattedEndDate, submittedDate);
                if (isValid) {
                    exportFormData?.form?.elements?.forEach((element: Widget) => {
                        const fieldId = element.options?.fieldId;
                        if (fieldIds.indexOf(fieldId) === -1) {
                            fieldIds?.push(fieldId);
                        }
                    });
                }
            });
            const responses: string[] = [];
            exportFormData?.formResponses?.forEach((formResponse: FormResponse & WithId) => {
                const response: string[] = [];
                const submittedDate = moment((formResponse as any).createdAt).format(DATE_FORMAT.DISPLAY);
                const submittedTime = moment((formResponse as any).createdAt).format("hh:mm a");
                const isValid = this.checkDateRange(formattedStartDate, formattedEndDate, submittedDate);
                if (isValid) {
                    response.push(formattedStartDate);
                    response.push(formattedEndDate);
                    response.push(moment(submittedDate, DATE_FORMAT.DISPLAY).format("DD-MMMM-YYYY"));
                    response.push(submittedTime);
                    response.push(today);
                    fieldIds.forEach((fieldId) => {
                        const value = formResponse.response?.[fieldId];
                        if (value) {
                            if (Array.isArray(value)) {
                                response.push(value.map((val: any) => val.value.split("\n").join(" ")).join("&"));
                            } else if (value.split) {
                                response.push(value.split("\n").join(" "));
                            }
                        }
                    });
                }
                responses.push(response.join());
            });
            const localizedFieldNames = new Set();
            fieldIds.forEach((fieldId) => {
                let fieldFound = false;
                exportFormData?.formResponses?.forEach((formResponse: FormResponse & WithId) => {
                    if (fieldFound) {
                        return;
                    }
                    const submittedDate = moment((formResponse as any).createdAt).format(DATE_FORMAT.DISPLAY);
                    const isValid = this.checkDateRange(formattedStartDate, formattedEndDate, submittedDate);
                    if (isValid) {
                        localizedFieldNames.add(getI18nLocaleString(namespaceList.admin, "fromDate"));
                        localizedFieldNames.add(getI18nLocaleString(namespaceList.admin, "toDate"));
                        localizedFieldNames.add(getI18nLocaleString(namespaceList.admin, "submittedDate"));
                        localizedFieldNames.add(getI18nLocaleString(namespaceList.admin, "submittedTime"));
                        localizedFieldNames.add(getI18nLocaleString(namespaceList.admin, "exportedDate"));
                        (exportFormData?.form?.elements || []).forEach((element: Widget) => {
                            if (element.options?.fieldId === fieldId) {
                                element.options.localized?.forEach((localizedOption: any) => {
                                    if (locale && localizedOption.locale === locale._id) {
                                        localizedFieldNames.add(localizedOption.label);
                                    }
                                });
                                fieldFound = true;
                                return;
                            }
                        });
                    }
                });
            });
            let csvContent = Array.from(localizedFieldNames).join() + "\n\n";
            responses.forEach((response) => {
                if (response && response !== " ") {
                    csvContent = csvContent + response + "\n";
                }
            });
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, `${formName}(${formattedStartDate} - ${formattedEndDate}).csv`);
            } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url.indexOf("blob") > -1 ? url : url.replace("blob", ""));
                    link.setAttribute("download", `${formName}(${formattedStartDate} - ${formattedEndDate}).csv`);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } else if (this.props.alerts) {
            this.props.alerts.push({
                color: "danger",
                message: getI18nLocaleString(namespaceList.admin, "formNotSubmitted"),
            });
        }
    };

    // eslint-disable-next-line max-lines-per-function
    private performPostfallbackActions = async (postid: Promise<string>, draftValue: any) => {
        const { mode } = this.props;
        let postName = "";
        let selectedSite: (Site & WithId) | null = null;
        if ("name" in draftValue) {
            postName = draftValue.name;
        }
        let postidString = "";
        await postid.then((temp) => (postidString = temp));
        const post = await PostApi.findById({ id: postidString });
        let root: Widget[] = [];
        let addedPostToSiteGroups = false;
        if (mode === "admin_edit" && post?.pageId) {
            const page = await PageApi.findById({ id: post!.pageId });
            if (page) {
                root = page.root;
            }
        }
        const pageItem: Page = {
            name: post?.name || "",
            root:
                root && root.length > 0
                    ? root
                    : [
                          {
                              children: [],
                              options: { templateId: post && post.headerTemplateId ? post.headerTemplateId : "" },
                              type: "template",
                          },
                          {
                              children: [{ children: [], options: { postId: postidString }, type: "post" }],
                              options: { templateId: post && post.headerTemplateId ? post.headerTemplateId : "" },
                              type: "flexbox",
                          },
                          {
                              children: [],
                              options: { templateId: post && post.footerTemplateId ? post.footerTemplateId : "" },
                              type: "template",
                          },
                      ],
            localizedContents: post?.localizedContents || [],
            metaRobots: post?.metaRobots || "",
            structuredData: false,
            enableRedirect: false,
            useExternalLink: false,
            redirect: "",
            siteId: post?.siteId || "",
            pageId: "",
            deletedDate: undefined,
            postId: postidString,
            disableCanonicalUrl: false,
        };

        let siteIdList;

        if (post?.addPostToSiteGroups && post?.postSiteGroups) {
            const siteGroupIds: string[] = post.postSiteGroups.map((group: StringMultiSelectOption) => group.value);
            const siteGroups = await Promise.all(siteGroupIds?.map(async (id) => SiteGroupApi.findById({ id })));
            if (siteGroups) {
                const siteIdList2d = await Promise.all(siteGroups.map(async (group) => group?.sites.map((siteid) => siteid)));
                siteIdList = [...new Set(siteIdList2d.flat())];
            }
        }

        const pageItemArray = siteIdList?.map((id) => {
            const pageItemTemp = { ...pageItem };
            pageItemTemp.siteId = id || "";
            return pageItemTemp;
        });

        if (mode === "create" || (post?.pageId && mode === "admin_edit")) {
            let pageid = "";
            if (post?.addPostToSiteGroups && pageItemArray) {
                await Promise.all(
                    pageItemArray.map(async (pageItem) => {
                        const res = await PageApi!.create({ item: pageItem });
                        pageid = res._id;
                        this.addPostToSites({ pageid, pageItem, postidString, post, draftValue });
                    })
                );
                addedPostToSiteGroups = true;
            } else {
                const res = await PageApi!.create({ item: pageItem });
                pageid = res._id;
                this.addPostToSites({ pageid, pageItem, postidString, post, draftValue });
            }
        }

        if (mode === "admin_edit") {
            PageApi!.update({ id: post!.pageId, item: pageItem }).then().catch();
            if (post?.addPostToSiteGroups && pageItemArray && post?.pageId && !addedPostToSiteGroups) {
                await Promise.all(
                    pageItemArray.map(async (pageItem) => {
                        this.addPostToSites({ pageid: post.pageId, pageItem, postidString, post, draftValue });
                    })
                );
                addedPostToSiteGroups = true;
            }
            if ("siteId" in draftValue && draftValue?.siteId) {
                selectedSite = await SiteApi.findById({ id: draftValue.siteId });
            }
            let permalink = "";
            if ("permalink" in draftValue && selectedSite) {
                permalink = draftValue.permalink.substring(2 + selectedSite.host.length);
            }
            const sites = await SiteApi.find();
            let isUpdateInSameSite = false;
            let languages: any[] = [];
            for (const site of sites) {
                // Get allowed locales for a Site
                if (site && site.localeMultiSelect && site.localeMultiSelect.length > 0) {
                    for (const lang of site.localeMultiSelect) {
                        languages.push(await LocaleApi.findById({ id: lang.value }));
                    }
                } else {
                    languages = await LocaleApi.find();
                }
                for (const widget of site.sitemap) {
                    if (widget.options.pageId === post!.pageId) {
                        site!.sitemap = site!.sitemap.filter((subitem) => subitem.options.pageId !== post!.pageId);
                        if (selectedSite && selectedSite._id === site._id) {
                            if (!selectedSite.enableMultiLanguage) {
                                site.sitemap.push({ children: [], options: { pageId: post!.pageId, friendlyUrl: permalink }, type: "sitemap-page" });
                                SiteApi.update({ id: site!._id, item: site! });
                            } else {
                                if (selectedSite) {
                                    const urlArray = languages.map((locale: any) => {
                                        let url = "";
                                        if (selectedSite) {
                                            // eslint-disable-next-line max-len
                                            url = url.concat(`${selectedSite.locale.code !== locale.code ? "/" + locale.code : ""}`);
                                            url = url.concat(permalink);
                                        }
                                        return {
                                            locale: locale._id,
                                            friendlyUrl: url,
                                        };
                                    });
                                    site.sitemap.push({
                                        children: [],
                                        options: {
                                            pageId: post!.pageId,
                                            localizedFriendlyUrl: urlArray,
                                        },
                                        type: "sitemap-page",
                                    });
                                }
                                SiteApi.update({ id: site!._id, item: site! });
                            }
                            isUpdateInSameSite = true;
                        }
                    }
                }
                if (selectedSite && selectedSite._id === site._id && !isUpdateInSameSite) {
                    if (!selectedSite.enableMultiLanguage) {
                        site.sitemap.push({ children: [], options: { pageId: post!.pageId, friendlyUrl: permalink }, type: "sitemap-page" });
                        SiteApi.update({ id: site!._id, item: site! });
                    } else {
                        languages.forEach((locales) => {
                            if (selectedSite) {
                                const urlArray = locales.map((locale: Locale & WithId) => {
                                    let url = "";
                                    if (selectedSite) {
                                        // eslint-disable-next-line max-len
                                        url = url.concat(`${selectedSite.locale.code !== locale.code ? "/" + locale.code : ""}`);
                                        url = url.concat(permalink);
                                    }
                                    return {
                                        locale: locale._id,
                                        friendlyUrl: url,
                                    };
                                });
                                site.sitemap.push({
                                    children: [],
                                    options: {
                                        pageId: post!.pageId,
                                        localizedFriendlyUrl: urlArray,
                                    },
                                    type: "sitemap-page",
                                });
                                SiteApi.update({ id: site!._id, item: site! });
                            }
                        });
                    }
                }
            }
        }
    };

    private addPostToSites = async ({ pageid, pageItem, postidString, post, draftValue }: { pageid: string; pageItem: Page; postidString: string; post: (Post & WithId) | null; draftValue: any }) => {
        if (postidString && pageid) {
            draftValue.pageId = pageid;
            await PostApi!.update({ id: postidString, item: draftValue });
        }
        let friendlyUrl = "";

        if (post?.siteId) {
            SiteApi.findById({ id: post.siteId }).then(async (selectedSite) => {
                if (selectedSite) {
                    friendlyUrl = post?.permalink?.substring(2 + selectedSite.host.length) || "";
                }
            });
        }
        const siteId = post?.addPostToSiteGroups ? pageItem.siteId : post?.siteId;
        if (siteId) {
            SiteApi.findById({ id: siteId }).then(async (selectedSite) => {
                if (selectedSite) {
                    if (!selectedSite.enableMultiLanguage) {
                        const pageExists = selectedSite.sitemap.find((page) => {
                            if (page.options.friendlyUrl && page.options.pageId !== draftValue.pageId) {
                                return page.options.friendlyUrl === friendlyUrl;
                            }
                        });
                        if (!pageExists) {
                            selectedSite.sitemap.push({ children: [], options: { pageId: pageid, friendlyUrl }, type: "sitemap-page" });
                            SiteApi.update({ id: selectedSite._id, item: selectedSite });
                        }
                    } else {
                        const locales = await LocaleApi.find();
                        const urlArray = locales.map((locale) => {
                            let url = "";
                            if (selectedSite && friendlyUrl) {
                                url = url.concat(`${selectedSite.locale.code !== locale.code ? "/" + locale.code : ""}`);
                                url = url.concat(friendlyUrl);
                            }
                            return {
                                locale: locale._id,
                                friendlyUrl: url,
                            };
                        });
                        const pageExists = selectedSite.sitemap.find((page) => {
                            if (page.options.localizedFriendlyUrl?.length && page.options.pageId !== draftValue.pageId) {
                                return page.options.localizedFriendlyUrl[0].friendlyUrl === friendlyUrl;
                            }
                        });
                        if (!pageExists) {
                            selectedSite.sitemap.push({ children: [], options: { pageId: pageid, localizedFriendlyUrl: urlArray }, type: "sitemap-page" });
                            SiteApi.update({ id: selectedSite._id, item: selectedSite });
                        }
                    }
                }
            });
        }
    };

    private clone = async () => {
        if (this.props.onClone) {
            const { spec, alerts } = this.props;
            if (spec.id === "site") {
                const checkDuplicateHost = await this.checkDuplicateHost(this.state.draftValue.host + "...copy");
                if (checkDuplicateHost) {
                    if (alerts) {
                        alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "hostExists") });
                    }
                    return;
                }
            }

            const isRequiredCheckSuccessful = checkRequiredFields(spec, this.state.draftValue);
            if (this.state.isFormEdited) {
                this.handleAlertCheck("clone");
            } else {
                if (!isRequiredCheckSuccessful.requiredChecked && alerts) {
                    isRequiredCheckSuccessful.labelArray.forEach((term: string) => {
                        // eslint-disable-next-line max-len
                        alerts.push({ color: "danger", message: `${getI18nLocaleString(namespaceList.admin, "requiredFailed")}: ${term}` });
                    });
                } else {
                    this.props.onClone(this.state.draftValue);
                }
            }
        } else {
            throw new Error(`Clone callback is required in ${this.props.mode} mode`);
        }
    };

    private async generateSiteMap() {
        const { host } = this.state.draftValue;
        const site: (Site & WithId) | null = await SiteApi.findByHost({ host });
        if (!site) {
            throw new Error(`Clone callback is required in ${this.props.mode} mode`);
        }
        const sitemapxml = await getSiteMap({ site, cmsApi: CmsApi });
        const blob = new Blob([sitemapxml.trim()], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "sitemap.xml");
        window.open(`//${host}/sitemap.xml`);
    }

    private focusToFeedbackMessage() {
        const element = document.getElementById("form-feedback-message");
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
            element.focus();
        }
    }
}

function mapStateToProps(state: State): GenericFormStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        myEnvState: state.myEnvState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FormEditAction>): GenericFormDispatchProps {
    return { dispatchAction: dispatch };
}

const GenericFormBaseType = connect<GenericFormStoreProps, GenericFormDispatchProps>(mapStateToProps, mapDispatchToProps)(GenericFormBase);

export const GenericForm = withPermissions<GenericFormBaseProps<any>>(withGoogleReCaptcha(GenericFormBaseType));
