import { Action, ActionType } from "./";

import { ApiContext } from "../../containers/cmsProvider.types";
import { ReservationResult } from "@maxxton/cms-mxts-api";
import { ReservationState } from "../reducers/reservationReducer";

export enum ReservationActionType {
    creating = "reservation_creating",
    created = "reservation_created",
    setPayLaterResource = "set_pay_later_resource",
    error = "reservation_error",
    payingCustomerReservationCategoryId = "payingCustomerReservationCategoryId",
}

export interface ReservationAction extends Action {
    actionType: ReservationActionType;
    payload: Partial<ReservationState> & { payLaterResourceId?: number };
}

export interface LoadReservationReduxDataAction extends Action {
    type: ActionType;
    payload: { apiContext: ApiContext };
}

export interface CreateReservationAction extends Action {
    payload: {
        rateTypeId: number;
        sourceId?: string;
        autoAssignUnitToReservation: boolean;
        sortUnitsOnVSI: boolean;
        sex?: string;
        country?: any;
        address?: string;
        housenumber?: string;
        housenumbersuffix?: string;
        zipCode?: string;
        city?: string;
        email?: string;
        privatePhone?: string;
        companyName?: string;
        title?: number;
        firstname?: string;
        middlename?: string;
        lastname?: string;
        alternateName?: string;
        birthdate?: string;
        language?: number;
        emailAllowed?: boolean;
        mobilephone?: string;
        login?: string;
        password?: string;
        reservationStatus?: number;
        isPayingCustomer?: boolean;
        payingCustomerReservationCategoryId?: number;
        workphone?: string;
        Co_firstname?: string;
        Co_middlename?: string;
        Co_lastname?: string;
        Co_companyName?: string;
        Co_email?: string;
        Co_mobilephone?: string;
        Co_privatePhone?: string;
        Co_country?: any;
        Co_zipCode?: string;
        Co_housenumber?: string;
        Co_housenumbersuffix?: string;
        Co_street?: string;
        Co_city?: string;
        Co_address?: string;
        Co_workphone?: string;
        resourceActivityDetailsId?: number;
        kind?: string;
        saveOnInvoice?: boolean;
        isOwnerBooking?: boolean;
    };
}

export interface UpdateReservationAction extends Action {
    payload: {
        reservation: ReservationResult;
        paymentTermSetId: number;
        reminderSetId: number;
    };
}
export interface SetReservationAction extends Action {
    payload: { reservationId: number };
}
