import * as React from "react";

import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

export const getEditableButton = (toggleModal: () => void, buttonLabel: string) => (
    <div className="edit-button-wrapper">
        <Button onClick={toggleModal} className="editable-modal__button">
            {buttonLabel}
        </Button>
    </div>
);

export const GetEditableModal = (props: { toggleModal: () => void; modalHeading: string; isEditModalOpen?: boolean; modalBodyData: () => JSX.Element | JSX.Element[]; sizeOfModal: string }) => {
    const { isEditModalOpen, modalBodyData, modalHeading, sizeOfModal, toggleModal } = props;
    return (
        <React.Fragment>
            <Modal isOpen={!!isEditModalOpen} scrollable={false} className="container-editable-modal" toggle={toggleModal} size={sizeOfModal}>
                <ModalHeader toggle={toggleModal}>{modalHeading}</ModalHeader>
                <ModalBody>{modalBodyData()}</ModalBody>
            </Modal>
        </React.Fragment>
    );
};
