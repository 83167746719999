import { Site, SiteApi } from "@maxxton/cms-api";

import { FormSpec } from "../";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";

export const autocompleteSiteSpec: FormSpec<Site> = {
    id: "site",
    name: getI18nLocaleObject(namespaceList.admin, "site"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "sites"),
    api: SiteApi,
    display(site: Site): string {
        return site.name;
    },
    properties: [],
};
