import { Theme } from ".";

import baseTheme from "./base";

const theme: Theme = {
    id: "lafrancerentals",
    name: "La France Rentals Theme",
    stylesheets: ["/styles/themes/lafrancerentals/lafrancerentals-admin.css"],
    frontendStylesheets: ["/styles/themes/lafrancerentals/lafrancerentals.css"],
    styles: [...baseTheme.styles],
};

export default theme;
