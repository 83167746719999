import { ALL_SELECT_OPTION_VALUE } from "../plugins/dynamic/reservation/cancellation/reservationCancellation.enum";
import { CustomerType } from "../plugins/dynamic/owner/owner.util";
import { SomeInputSpec } from "../form-specs";
import { contractOptions } from "../components/utils";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface ContractWidgetOptions {
    contractTypeIds?: number[];
    displayDataBasedOnContractTypeIds?: boolean;
}
const contractsAutocomplete = (contractTypeLabel: string, isVisibilityCheck?: boolean): Array<SomeInputSpec<any, any>> => [
    {
        variable: "displayDataBasedOnContractTypeIds",
        label: getI18nLocaleObject(namespaceList.ownerReservationsOverview, "displayDataBasedOnContracts"),
        type: "checkbox",
        visible: (options: any) => isVisibilityCheck === undefined || options.selectCustomerType === CustomerType.OWNER,
    },
    {
        variable: "contractTypeIds",
        label: getI18nLocaleObject(namespaceList.admin, contractTypeLabel),
        type: "lazyLoadMultiSelect",
        allItemsSelectOption: { value: ALL_SELECT_OPTION_VALUE, label: getI18nLocaleObject(namespaceList.admin, "allContracts") },
        lazyLoadOptions: (page: number, searchQuery: string, contractTypesIds: string[] | undefined) => contractOptions(page, searchQuery, contractTypesIds),
        placeholder: getI18nLocaleObject(namespaceList.admin, "contractPlaceholder"),
        visible: (options: any) => !!options.displayDataBasedOnContractTypeIds,
    },
];

export default contractsAutocomplete;
