const namespacesList = {
    admin: "admin",
    criteriaPanelWidget: "widget-criteria-panel",
    descriptionWidget: "description-widget",
    accokindWidget: "widget-accokind",
    dynamicPlugin: "dynamic-plugin",
    dynamicContainer: "dynamic-container",
    dynamicAccoKind: "dynamic-acco-kind",
    dynamicResort: "dynamic-resort",
    dynamicLocationFacet: "dynamic-location-facet",
    dynamicSpecial: "dynamic-special",
    dynamicSpecialSearchFacet: "dynamic-special-search-facet",
    dynamicVoucher: "dynamic-voucher",
    dynamicGuestReviewRating: "dynamic-guest-review-rating",
    dynamicSingleReviewRating: "dynamic-single-review-rating",
    dynamicStayDuration: "dynamic-stay-duration",
    dynamicAvailabilityDate: "dynamic-availability-date",
    dynamicBedroom: "dynamic-bedroom",
    dynamicBathroom: "dynamic-bathroom",
    facilityWidget: "facility-widget",
    genericCrud: "generic-crud",
    pricePanelWidget: "price-panel-widget",
    priceCompareWidget: "price-compare",
    priceRangeWidget: "price-range-widget",
    priceMatrixWidget: "price-matrix-widget",
    customerBillWidget: "customer-bill-widget",
    pluginMenu: "plugin-menu",
    pluginMxts: "plugin-mxts",
    pluginForm: "plugin-form",
    pluginFlow: "plugin-flow",
    widgetWebContent: "widget-webcontent",
    pluginBuiltinPage: "plugin-builtin-page",
    pluginSitemap: "plugin-sitemap",
    pluginSettings: "plugin-settings",
    imitateUser: "imitate-user",
    pluginSearchResultsPanel: "plugin-search-results-panel",
    pluginTypesearchContainer: "plugin-typesearch-container",
    pluginUnitsearchContainer: "plugin-unitsearch-container",
    pluginActivitySearchContainer: "plugin-activity-search-container",
    widgetFlexbox: "widget-flexbox",
    widgetTypeSearch: "widget-typesearch",
    widgetColumns: "widget-columns",
    widgetTabs: "widget-tabs",
    global: "global",
    subject: "dynamic-subject",
    widgetDropdownMenu: "widget-dropdownmenu",
    widgetGroup: "widget-group",
    widgetLabel: "widget-label",
    widgetToggle: "widget-toggle",
    widgetImage: "widget-image",
    widgetVideo: "widget-video",
    widgetDivider: "widget-divider",
    widgetSlideShow: "widget-slideShow",
    widgetgoToTop: "widget-go-to-top",
    widgetSpacer: "widget-spacer",
    widgetStepSelector: "widget-step-selector",
    widgetIframe: "widget-iframe",
    widgetSocial: "widget-social",
    widgetStatic: "widget-static",
    widgetTemplate: "widget-template",
    widgetAssetPublisher: "widget-assetpublisher",
    widgetWebContentCard: "widget-webContentCard",
    widgetSearchfacet: "widget-searchfacet",
    widgetDirectSearch: "widget-directsearch",
    widgetSearchSort: "widget-searchSort",
    widgetImagegallery: "widget-imagegallery",
    widgetCard: "widget-card",
    widgetRecent: "widget-recent",
    widgetImageGallery: "widget-image-gallery",
    widgetResultsImageGallery: "widget-results-image-gallery",
    widgetResultsPrice: "widget-results-price",
    widgetAccoPrice: "widget-acco-price",
    widgetResultsLocation: "widget-results-location",
    widgetResultsReviewRating: "widget-results-review-rating",
    widgetPost: "widget-post",
    widgetUnitSearch: "widget-unitsearch",
    widgetRevealer: "widget-revealer",
    widgetMap: "widget-map",
    widgetBreadcrumb: "widget-breadcrumb",
    marker: "marker",
    widgetLanguage: "widget-language",
    widgetButton: "widget-button",
    widgetAgenda: "widget-agenda",
    widgetResultsMinimumMaximumStay: "widget-minimum-maximum-stay",
    amenitiesWidget: "amenities-widget",
    widgetAccodetail: "widget-accodetail",
    widgetPrevNext: "widget-prevnext",
    widgetOverviewPanel: "widget-overview-panel",
    widgetLocation: "widget-location",
    searchAmenities: "search-amenities",
    displayGridListMapWidget: "display-grid-list-map",
    dynamicSearchButtonWidget: "dynamic-search-button-widget",
    dynamicButtonWidget: "dynamic-button-widget",
    dynamicBedroomBathroom: "dynamic-bedroom-bathroom",
    widgetAdditions: "widget-additions",
    widgetAddOns: "widget-add-ons",
    widgetStatus: "widget-status",
    widgetPaymentStatus: "widget-payment-status",
    widgetTravelParty: "widget-travel-party",
    widgetTravelPartySummary: "widget-travel-party-summary",
    widgetInstalment: "widget-instalment",
    widgetPaymentMethods: "widget-payment-methods",
    widgetGuestInterface: "widget-guest-interface",
    customerSummaryWidget: "customer-summary-widget",
    widgetResultCount: "widget-result-count",
    pluginLocationSearchContainer: "plugin-locationsearch-container",
    widgetCheckInCheckOut: "widget-checkin-checkout",
    widgetReservableUnit: "widget-reservable-unit",
    userLoginWidget: "user-login-widget",
    currencySelectorWidget: "currency-selector-widget",
    reservationDetails: "reservation-details",
    reservationContainer: "reservation-container",
    reservationPicker: "reservation-picker",
    ownerUnitPicker: "owner-unit-picker",
    ownerReservationsOverview: "owner-reservations-overview",
    ownerBookChoiceSelection: "owner-book-choice-selection",
    dynamicMyEnvContainer: "dynamic-my-env-container",
    reservationDatesAndTimings: "reservation-dates-and-timings",
    reservationDaysCountdown: "reservation-days-countdown",
    reservationTransactionOverview: "reservation-transaction-overview",
    unitPreference: "unit-preference",
    tooltipOnEmptyInput: "tooltip-on-empty-input",
    notificationWidget: "notification-widget",
    communicationDocumentsWidget: "communication-documents-widget",
    linkingSpec: "linking-spec",
    widgetCountdownTimer: "widget-countdown-timer",
    widgetActivityPlanner: "widget-activity-planner",
    reservationCancellation: "reservation-cancellation",
    ownerDocumentInvoiceWidget: "owner-document-invoice-widget",
    widgetTipsAndTrips: "widget-tips-and-trips",
    widgetCategoryFilter: "widget-category-filter",
    widgetDoorlock: "widget-doorlock",
    occupancyWidget: "widget-occupancy",
    ownerRevenueStreamsWidget: "widget-owner-revenue-streams",
    widgetBarcode: "widget-barcode",
    contractStatusWidget: "widget-contract-status",
    searchResultsCounterWidget: "widget-counter",
    loyaltyWidget: "loyalty-widget",
    widgetEmailPreferences: "widget-email-preferences",
    widgetDateFilter: "widget-date-filter",
    ownerAccommodationLinksWidget: "widget-owner-accommodation-links",
    widgetQualityLevelFilter: "widget-quality-level-filter",
    releaseNotes: "release-notes",
    freeInputFilter: "free-input-filter",
    ownerMaintenance: "owner-maintenance",
};

export const namesCorrespondingNamespaces = {
    "admin": "Admin Terms",
    "description-widget": "Description Widget Terms",
    "dynamic-plugin": "Dynamic Plugin Terms",
    "dynamic-container": "Dynamic Container Widget Terms",
    "dynamic-acco-kind": "Dynamic Accommodation Kind Widget Terms",
    "dynamic-resort": "Dynamic Resort Widget Terms",
    "dynamic-special": "Dynamic Special Widget Terms",
    "dynamic-special-search-facet": "Dynamic Special Search Facet Terms",
    "dynamic-voucher": "Dynamic Voucher Terms",
    "dynamic-guest-review-rating": "Dynamic Guest Review Rating Widget Terms",
    "dynamic-location-facet": "Dynamic Location Facet Terms",
    "dynamic-single-review-rating": "Dynamic Single Review Rating Widget Terms",
    "dynamic-stay-duration": "Dynamic Stay Duration Widget Terms",
    "dynamic-availability-date": "Dynamic Availability Date Widget Terms",
    "dynamic-bedroom": "Dynamic Bedroon Widget Terms",
    "dynamic-bathroom": "Dynamic Bathroon Widget Terms",
    "facility-widget": "Facility Widget Terms",
    "generic-crud": "Generic CRUD Terms",
    "price-panel-widget": "Price Panel Widget Terms",
    "price-range-widget": "Price Range Widget Terms",
    "price-matrix-widget": "Price Matrix Widget Terms",
    "customer-bill-widget": "Customer bill widget Terms",
    "price-compare": "Price compare widget terms",
    "plugin-menu": "Menu Plugin Terms",
    "plugin-mxts": "MXTS Plugin Terms",
    "plugin-form": "Form Plugin Terms",
    "plugin-flow": "Flow Plugin Terms",
    "widget-webcontent": "Webcontent Widget Terms",
    "plugin-builtin-page": "BuiltIn Page Plugin Terms",
    "plugin-sitemap": "Sitemap Plugin Terms",
    "plugin-settings": "Settings Plugin Terms",
    "plugin-typesearch-container": "Typesearch Container Plugin Terms",
    "plugin-unitsearch-container": "Unitsearch Container Plugin Terms",
    "plugin-activity-search-container": "Activity Search Container Plugin Terms",
    "widget-flexbox": "Flexbox Widget Terms",
    "widget-typesearch": "Typesearch Widget Terms",
    "widget-columns": "Columns Widget Terms",
    "widget-tabs": "Tabs Widget Terms",
    "global": "Global Terms",
    "dynamic-subject": "Dynamic Subject Widget Terms",
    "widget-accokind": "Accokind Widget Terms",
    "widget-dropdownmenu": "Dropdown Menu Widget Terms",
    "widget-group": "Group Menu Widget Terms",
    "widget-label": "Label Widget Terms",
    "widget-toggle": "Toggle Widget Terms",
    "widget-image": "Image Widget Terms",
    "widget-video": "Video Widget Terms",
    "widget-divider": "Divider Widget Terms",
    "widget-slideShow": "SlideShow Widget Terms",
    "widget-facility": "Facility Widget Terms",
    "widget-go-to-top": "Go to top Widget Terms",
    "widget-spacer": "Spacer Widget Terms",
    "widget-step-selector": "Step selector terms",
    "widget-iframe": "Iframe Widget Terms",
    "widget-social": "Social Widget Terms",
    "widget-static": "Static Widget Terms",
    "widget-template": "Template Widget Terms",
    "widget-assetpublisher": "Assetpublisher Widget Terms",
    "widget-webContentCard": "Webcontent card terms",
    "widget-searchfacet": "Searchfacet Widget Terms",
    "widget-directsearch": "Directsearch Widget Terms",
    "widget-searchSort": "Search Sort Widget Terms",
    "widget-imagegallery": "Image-gallery Widget Terms",
    "widget-card": "Card Widget Terms",
    "widget-recent": "Recently Viewed Widget Terms",
    "widget-image-gallery": "Image-gallery Widget Terms",
    "widget-post": "Posts Widget Terms",
    "widget-unitsearch": "Unitsearch Widget Terms",
    "widget-revealer": "Revealer Widget Terms",
    "widget-map": "Map Widget Terms",
    "widget-breadcrumb": "Breadcrumb Widget Terms",
    "marker": "Marker terms",
    "widget-language": "Language Widget Terms",
    "widget-button": "Button Widget Terms",
    "widget-agenda": "Agenda Widget Terms",
    "widget-minimum-maximum-stay": "Minimum Maximum Stay Widget Terms",
    "amenities-widget": "Amenities Widget Terms",
    "widget-accodetail": "Accommodation Details Step Widget Terms",
    "widget-prevnext": "Previous Next Widget Terms",
    "widget-overview-panel": "Overview Panel Widget Terms",
    "widget-location": "Location Widget Terms",
    "search-amenities": "Amenities Filter Widget Terms",
    "display-grid-list-map": "Display Grid List Map Widget Terms",
    "dynamic-search-button-widget": "Dynamic Search Button Widget Terms",
    "dynamic-button-widget": "Dynamic Button Widget Terms",
    "dynamic-bedroom-bathroom": "Dynamic Bedroom Bathroom Widget Terms",
    "widget-criteria-panel": "Criteria Panel Widget Terms",
    "widget-results-image-gallery": "Dynamic Image-gallery Widget Terms",
    "widget-results-price": "Results price Widget Terms",
    "widget-acco-price": "Accommodation Price Widget Terms",
    "widget-results-location": "Results Location Widget Terms",
    "widget-results-review-rating": "Results Review Rating Terms",
    "plugin-search-results-panel": "Result Panel Terms",
    "widget-additions": "Dynamic Additions Widget Terms",
    "widget-add-ons": "Dynamic Add-ons Widget Terms",
    "widget-status": "Dynamic Status Widget Terms",
    "widget-instalment": "Instalment Widget Terms",
    "widget-payment-methods": "Payment Methods Widget Terms",
    "widget-payment-status": "Payment Status Widget Terms",
    "widget-travel-party": "Travel Party Widget Terms",
    "widget-travel-party-summary": "Travel Party Summary Widget Terms",
    "customer-summary-widget": "Customer summary widget terms",
    "widget-result-count": "Result Count Widget Terms",
    "plugin-locationsearch-container": "Location Search Container Plugin Terms",
    "widget-checkin-checkout": "Checkin Checkout Widget Terms",
    "widget-reservable-unit": "Reservable Unit Widget Terms",
    "user-login-widget": "User Login Widget Terms",
    "currency-selector-widget": "Currency Selector Widget Terms",
    "reservation-details": "Reservation Details Widget Terms",
    "reservation-container": "Reservation Container Widget Terms",
    "reservation-picker": "Reservation Picker Terms",
    "owner-document-invoice-widget": "Owner Document Invoice Widget Terms",
    "owner-book-choice-selection": "Owner Book Choice Selection Terms",
    "dynamic-my-env-container": "Dynamic My Environment Container Terms",
    "reservation-dates-and-timings": "Reservation Dates And Timings Widget Terms",
    "reservation-days-countdown": "Reservation Days Countdown Widget Terms",
    "reservation-transaction-overview": "Reservation Transaction Overview Widget Terms",
    "unit-preference": "Unit Preference Terms",
    "tooltip-on-empty-input": "Tooltip On Empty Input Terms",
    "notification-widget": "Notification Widget Terms",
    "communication-documents-widget": "Communication Documents Widget Terms",
    "linking-spec": "Linking Spec Terms",
    "widget-guest-interface": "Guest Interface Widget Terms",
    "widget-countdown-timer": "Countdown Timer Widget Terms",
    "widget-activity-planner": "Activity Planner Widget Terms",
    "loyalty-widget": "Loyalty Widget Terms",
    "owner-unit-picker": "Owner Unit Picker Terms",
    "owner-reservations-overview": "Owner Reservation Overview Terms",
    "reservation-cancellation": "Reservation Cancellation Terms",
    "widget-tips-and-trips": "Tips and Trips Widget Terms",
    "widget-category-filter": "Category Filter Widget Terms",
    "widget-doorlock": "Doorlock Widget Terms",
    "widget-occupancy": "Occupancy Widget Terms",
    "widget-owner-revenue-streams": "Owner Revenue Streams Widget Terms",
    "widget-barcode": "Barcode Widget Terms",
    "widget-contract-status": "Contract Widget Terms",
    "imitate-user": "Imitate User Terms",
    "widget-counter": "Search Results Counter Widget Terms",
    "widget-email-preferences": "Email Preferences Widget Terms",
    "widget-date-filter": "Date Filter Widget Terms",
    "widget-owner-accommodation-links": "Owner Accommodation Links Widget Terms",
    "widget-quality-level-filter": "Quality Level Filter Widget Terms",
    "release-notes": "Release Notes Terms",
};

export default namespacesList;
