import { GuestFormAction, GuestFormActionType } from "../actions/guestFormAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface Validations {
    [id: string]: {
        isValid: boolean;
        message?: string | undefined;
    };
}
export interface GuestFormState {
    draftValue?: any;
    validations?: Validations;
    mandatoryFields?: string[];
    isValidationCheckList?: boolean;
    minimalMandatoryFields?: string[];
    agreeTerms?: boolean;
}

// eslint-disable-next-line max-len
export const guestFormReducer: Reducer<GuestFormState> = (state: GuestFormState = { draftValue: {}, validations: {}, mandatoryFields: [] }, action: GuestFormAction): GuestFormState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.GuestForm:
            switch (actionType) {
                case GuestFormActionType.updateAgreeTerms:
                    return { ...state, agreeTerms: action.payload.agreeTerms, draftValue: action.payload.draftValue };
                case GuestFormActionType.change:
                    return { ...state, draftValue: action.payload.draftValue };
                case GuestFormActionType.updateDraftValues:
                    return { ...state, draftValue: { ...state.draftValue, ...action.payload.draftValue } };
                case GuestFormActionType.validate:
                    return { ...state, validations: { ...state.validations, ...action.payload.validations }, mandatoryFields: action.payload.mandatoryFields };
                case GuestFormActionType.isValidationCheckList:
                    return { ...state, isValidationCheckList: action.payload.isValidationCheckList };
                case GuestFormActionType.updateMinimalMandatoryFields:
                    return { ...state, minimalMandatoryFields: [...new Set([...(state.minimalMandatoryFields || []), ...action.payload.minimalMandatoryFields])] };
            }
        default:
            return state;
    }
};
