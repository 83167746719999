import { WebContent, WebContentApi } from "@maxxton/cms-api";
import { localized, multiSelectStylePicker, tagSpec } from "../utils";

import { FormSpec } from "../";
import { autocompleteSiteSpec } from "./autocompleteSite";
import { findMultiSelectStyleClassNames } from "../../themes";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import { pageSpec } from "./page";

const TARGETS = ["webcontent", "webcontent-model"];

export const webcontentSpecWithoutPreview: FormSpec<WebContent> = {
    id: "webcontent",
    name: getI18nLocaleObject(namespaceList.admin, "webcontent"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "webcontents"),
    api: WebContentApi,
    display(webContent: WebContent): string {
        return webContent.name;
    },
    tableColumns: [
        {
            name: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            type: "text",
        },
        {
            name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "tableCards"),
            variable: "cardEnabled",
            type: "checkbox",
        },
        {
            name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "tableReviews"),
            variable: "reviewsEnabled",
            type: "checkbox",
        },
    ],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            multiSelectStylePicker("styleIds", TARGETS),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "localizedImageContent",
                                tabContent: [
                                    {
                                        variable: "image",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "image"),
                                        type: "image",
                                    },
                                ],
                            }),
                            {
                                variable: "image",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "image"),
                                type: "image",
                            },
                            tagSpec({ variable: "tags", tags: (item: WebContent) => item.tags }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "title"),
                                variable: "title",
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.admin, "content"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "content",
                                        label: getI18nLocaleObject(namespaceList.admin, "content"),
                                        type: "richtext",
                                        style: (item: WebContent, theme) => findMultiSelectStyleClassNames(theme, TARGETS, item.styleIds),
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "options"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "rawHtmlWidget"),
                                variable: "isRawHtmlWidget",
                                type: "checkbox",
                            },
                            {
                                variable: "cardEnabled",
                                label: getI18nLocaleObject(namespaceList.admin, "cards"),
                                type: "checkbox",
                                visible: (item: WebContent) => !item.reviewsEnabled,
                            },
                            {
                                variable: "masonry",
                                label: getI18nLocaleObject(namespaceList.admin, "masonry"),
                                type: "checkbox",
                                visible: (item: WebContent) => item.cardEnabled,
                            },
                            {
                                variable: "cardSpecial",
                                label: getI18nLocaleObject(namespaceList.admin, "special"),
                                type: "checkbox",
                                visible: (item: WebContent) => item.cardEnabled,
                            },
                            {
                                variable: "cardTeaserEnabled",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "cardTeaser"),
                                type: "checkbox",
                                visible: (item: WebContent) => item.cardEnabled,
                            },
                            {
                                variable: "cardRevealerEnabled",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "cardRevealerEnabled"),
                                type: "checkbox",
                                visible: (item: WebContent) => item.cardEnabled && item.cardTeaserEnabled,
                            },
                            {
                                variable: "reviewsEnabled",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviews"),
                                type: "checkbox",
                                visible: (item: WebContent) => !item.cardEnabled,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "scheduling"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isPublishDateConfigured"),
                                variable: "isPublishDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "publishDate",
                                label: getI18nLocaleObject(namespaceList.admin, "publishDate"),
                                type: "datetime",
                                visible: (item: WebContent) => item.isPublishDateConfigured,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isExpirationDateConfigured"),
                                variable: "isExpirationDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "expirationDate",
                                label: getI18nLocaleObject(namespaceList.admin, "expirationDate"),
                                type: "datetime",
                                visible: (item: WebContent) => item.isExpirationDateConfigured,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "cardLayout"),
                    visible: (item: WebContent) => item.cardEnabled,
                    properties: [
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "localizedCardContent",
                                tabContent: [
                                    {
                                        variable: "cardImage",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "image"),
                                        type: "image",
                                    },
                                    {
                                        variable: "cardTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "title"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardSubTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "subTitle"),
                                        type: "text",
                                    },
                                    {
                                        variable: "location",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "location"),
                                        type: "text",
                                    },
                                    {
                                        variable: "startDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "date"),
                                        type: "date",
                                        default: "",
                                    },
                                    {
                                        variable: "endDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "endDate"),
                                        type: "date",
                                        default: "",
                                        visible: (item: any) => item.startDate,
                                    },
                                    {
                                        variable: "cardAlt",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "alt"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardDescription",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "description"),
                                        type: "textarea",
                                    },
                                    {
                                        variable: "richTextDesc",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "richTextDesc"),
                                        type: "richtext",
                                    },
                                    {
                                        type: "paragraph",
                                        label: getI18nLocaleObject(namespaceList.admin, "externalLinkDescription"),
                                    },
                                    {
                                        variable: "externalLink",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "externalLink"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardButton",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "button"),
                                        type: "text",
                                    },
                                    {
                                        variable: "isSitePageUrl",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "buttonUrl"),
                                        type: "checkbox",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "site"),
                                        visible: (item: any) => item.isSitePageUrl,
                                        variable: "siteId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: autocompleteSiteSpec,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "page"),
                                        visible: (item: any) => item.isSitePageUrl,
                                        variable: "pageId",
                                        type: "autocomplete",
                                        default: "",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "siteId",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialCardTab"),
                    visible: (item: WebContent) => item.cardEnabled && item.cardSpecial,
                    properties: [
                        [
                            localized({
                                variable: "localizedSpecialCard",
                                tabContent: [
                                    {
                                        variable: "cardSpecialTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialTitle"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardSpecialDiscount",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialDiscount"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardOriginalPrice",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "originalPrice"),
                                        type: "text",
                                    },
                                    {
                                        variable: "cardSpecialPrice",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialPrice"),
                                        type: "text",
                                    },
                                    {
                                        variable: "priceDescription",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "priceDescription"),
                                        type: "text",
                                    },
                                    {
                                        variable: "rentability",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "rentability"),
                                        type: "text",
                                    },
                                    {
                                        variable: "addRequestParams",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "addRequestParams"),
                                        type: "checkbox",
                                    },
                                    {
                                        variable: "addStartDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "addStartDate"),
                                        type: "checkbox",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "startDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "date"),
                                        type: "date",
                                        visible: (item: any) => item.addRequestParams && item.addStartDate,
                                    },
                                    {
                                        variable: "addEndDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "addEndDate"),
                                        type: "checkbox",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "endDate",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "endDate"),
                                        type: "date",
                                        visible: (item: any) => item.addRequestParams && item.addEndDate,
                                    },
                                    {
                                        variable: "stay",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "stay"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "amenities",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "amenities"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "resort",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "resort"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "resourceId",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "resourceId"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                    {
                                        variable: "specialCode",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "specialCode"),
                                        type: "text",
                                        visible: (item: any) => item.addRequestParams,
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewsTab"),
                    visible: (item: WebContent) => item.reviewsEnabled,
                    properties: [
                        [
                            {
                                variable: "rating",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewRating"),
                                type: "text",
                            },
                            localized({
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "localizedReviewPerson"),
                                variable: "localizedReviewPerson",
                                tabContent: [
                                    {
                                        variable: "reviewPerson",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewPerson"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "reviewPerson",
                                label: getI18nLocaleObject(namespaceList.widgetWebContentCard, "reviewPerson"),
                                type: "text",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};
