import * as React from "react";

import { CMSProviderProperties } from "../../containers/cmsProvider.types";
import { Currency } from "@maxxton/cms-mxts-api";
import { NumberLocaleFormatOptions } from "../../utils/number.util";
import { getMxtsEnv } from "../../plugins/mxts/index";

interface PriceProps {
    amount: number;
    locale: string;
    customNumberFormatOptions?: NumberLocaleFormatOptions;
    currencyId?: number;
    context?: CMSProviderProperties;
    currencyCode?: string;
}

export const Price = (props: PriceProps) => {
    const { amount, context, currencyCode: parameterCurrencyCode, currencyId, locale, customNumberFormatOptions = {} } = props;

    const [currencyCode, setCurrencyCode] = React.useState<string | undefined>(parameterCurrencyCode);

    React.useEffect(() => {
        if (currencyId && context && !currencyCode) {
            const {
                currentLocale: { code: currentLocaleCode },
            } = context;
            (async () => {
                const env = await getMxtsEnv(context, currentLocaleCode);
                const currencyResult: Currency | undefined = await context.mxtsApi.currency(env, {}, [
                    {
                        key: "currencyId",
                        value: currencyId,
                    },
                ]);
                setCurrencyCode(currencyResult?.code);
            })();
        }
    }, [context, currencyCode, currencyId]);

    const numberFormatOptions: NumberLocaleFormatOptions = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    if (currencyCode || context?.currency?.code) {
        numberFormatOptions.style = "currency";
        numberFormatOptions.currency = currencyCode || context?.currency?.code;
    }
    return <span className="price">{amount?.toLocaleString(locale, { ...numberFormatOptions, ...customNumberFormatOptions })}</span>;
};
