import * as moment from "moment";

export const gaLocaleSpecification: moment.LocaleSpecification = {
    calendar: {
        sameDay: "[Inniu ag] LT",
        nextDay: "[Amárach ag] LT",
        nextWeek: "dddd [ag] LT",
        lastDay: "[Inné ag] LT",
        lastWeek: "dddd [seo caite] [ag] LT",
        sameElse: "L",
    },
    longDateFormat: {
        LTS: "HH:mm:ss",
        LT: "HH:mm",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd, D MMMM YYYY HH:mm",
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    relativeTime: {
        future: "i %s",
        past: "%s ó shin",
        s: "cúpla soicind",
        ss: "%d soicind",
        m: "nóiméad",
        mm: "%d nóiméad",
        h: "uair an chloig",
        hh: "%d uair an chloig",
        d: "lá",
        dd: "%d lá",
        w: "a week",
        ww: "%d weeks",
        M: "mí",
        MM: "%d míonna",
        y: "bliain",
        yy: "%d bliain",
    },
    months: ["Eanáir", "Feabhra", "Márta", "Aibreán", "Bealtaine", "Meitheamh", "Iúil", "Lúnasa", "Meán Fómhair", "Deireadh Fómhair", "Samhain", "Nollaig"],
    monthsShort: ["Ean", "Feabh", "Márt", "Aib", "Beal", "Meith", "Iúil", "Lún", "M.F.", "D.F.", "Samh", "Noll"],
    week: {
        dow: 1,
        doy: 4,
    },
    weekdays: ["Dé Domhnaigh", "Dé Luain", "Dé Máirt", "Dé Céadaoin", "Déardaoin", "Dé hAoine", "Dé Sathairn"],
    weekdaysMin: ["Do", "Lu", "Má", "Cé", "Dé", "A", "Sa"],
    weekdaysShort: ["Domh", "Luan", "Máirt", "Céad", "Déar", "Aoine", "Sath"],
    monthsParseExact: true,
    dayOfMonthOrdinalParseLenient: {},
};
