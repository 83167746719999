import { ACCOMMODATION_KIND, ACCOMMODATION_TYPE, ALL, MultiSelectDynamicData, RESORT, TIPS_AND_TRIPS, UNIT, UNIT_TYPE } from "../components/utils";
import {
    AccoKind,
    ApiCallOptions,
    DynamicFieldInfo,
    Image,
    MxtsApi,
    MxtsApiWrapper,
    PagedResult,
    ReservedResourcePreferenceType,
    Resort,
    Resource,
    Subject,
    Unit,
    WithAddressResult,
} from "@maxxton/cms-mxts-api";
import { ApiContext, CMSProvidedProperties, CMSProviderProperties } from "../containers/cmsProvider.types";
import { AvailabilityResultWithFilter, AvailabilityState } from "../redux/reducers/availability.types";
import { DynamicData, SubjectResult } from "../components/generic-form/form.types";
import { Resort as ExtendedResort, AccommodationType as ExtendedResource, Unit as ExtendedUnit } from "../plugins/mxts";
import { MyEnvReservation, MyEnvState } from "../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { fetchAllResorts, fetchResortsByResortIds } from "./resort.util";
import { fetchPointsOfInterest, getPointsOfInterestCategories } from "../plugins/page/tipsAndTrips/tipsAndTrips.util";
import { getResortId, getResourceId as getResourceIdFromEsReservationResult, getSelectedEsReservation, getUnitId } from "../redux/reducers/myEnv/myEnv.util";

import { Activity } from "../plugins/page/activityPlanner/activityPlanner.types";
import { ContentType as CONTENT_TYPE } from "../components/components.enum";
import { WidgetOptions as DynamicContainerOptions } from "../plugins/dynamic/container/container.types";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { ReservationContainerCRPProps } from "../plugins/dynamic/reservation/container/reservationContainer.types";
import { getEnv } from "./env.util";
import { getImages } from "../components/media/mxts-image-gallery/mxts";
import { isClientSide } from "./generic.util";
import { isEqual } from "lodash";
import { parse } from "query-string";

export interface ContentParams extends ReservationContainerCRPProps {
    dynamicFilter: DynamicFilter;
    myEnvState?: MyEnvState;
    availabilityState: AvailabilityState;
    options?: any;
    context?: CMSProvidedProperties;
    resort?: Resort | ExtendedResort;
    accommodationType?: Resource | ExtendedResource;
    unit?: Unit | ExtendedUnit;
    dynamicContainerOptions?: DynamicContainerOptions;
    skipContentTypeSelectorCheck?: boolean;
    checkForMultipleContent?: boolean;
    checkForAvailability?: boolean;
    env?: ApiCallOptions;
    includeAddress?: boolean;
    isMyEnvWidget?: boolean;
    activity?: Activity;
    obtainMyEnvReservedResources?: ObtainMyEnvReservedResources;
    /**
     * If it's a result panel widget you want it to use the data provided by the result panel container. Not the data found on the dynamicFilter, etc.
     */
    prioritizeResultPanelParams?: boolean;
}

export interface ObtainMyEnvReservedResources {
    showAllInventoryList?: boolean;
}
export interface DynamicContentType {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    options: {
        contentType: string;
        contentTypeSelector: boolean;
    };
}
export interface DynamicDataParams {
    selectedDynamicData?: StringMultiSelectOption[];
    dynamicFilter?: DynamicFilter;
    availabilityState?: AvailabilityState;
}

// Resort[] type  is only valid when checkForMultipleContent is true (for now only used with Map Widget)
export type BaseContent = Resort | Resort[] | Resource | Unit | Activity | undefined;

export type ExtendedContent = ExtendedResort | ExtendedResort[] | ExtendedResource | ExtendedUnit | undefined;

export type Content = BaseContent | ExtendedContent;

export const getResortIdsFromAvailabilityState = (availabilityResult?: AvailabilityResultWithFilter, content?: Exclude<Content, Resort[]>): number[] | undefined => {
    const filter = availabilityResult?.filter;
    const response = availabilityResult?.response;
    if (filter) {
        if (filter.resortId || filter.resortIds?.length) {
            return filter.resortIds?.length ? filter.resortIds : [filter.resortId as number];
        }
        if (filter.resourceId || filter.resourceIds?.length || filter.unitId) {
            if (response?.resorts?.length) {
                return response.resorts;
            } else if (response?.units?.length) {
                return [response.units[0].resortId];
            } else if (response?.resources?.length) {
                return [response.resources[0].resortId];
            }
        }
    }

    return (content as Exclude<Content, Activity | Resort[]>)?.resortId ? [(content as Exclude<Content, Activity | Resort[] | undefined>).resortId] : undefined;
};

const getResourceIdFromAvailabilityState = (availabilityResult?: AvailabilityResultWithFilter, content?: Content): number | undefined => {
    const filter = availabilityResult?.filter;
    const response = availabilityResult?.response;
    if (filter?.resourceId || filter?.resourceIds?.length === 1) {
        return filter?.resourceId || filter?.resourceIds?.[0];
    }
    if (filter?.unitId) {
        if (response?.units?.length) {
            return response.units[0].resourceId;
        } else if (response?.resources?.length) {
            return response.resources[0].resourceId;
        }
    }
    return (content as Resource | Unit)?.resourceId;
};

const getUnitIdFromAvailabilityState = (content?: Unit): number | undefined => content?.unitId;

const isResourceAvailable = ({ resourceId, availabilityState }: { resourceId: number; availabilityState: AvailabilityState }) => {
    const availableResources = availabilityState.availabilityResult?.response.resources;
    const availableResourceIds = availableResources?.map((resource) => resource.resourceId) || [];
    return availableResourceIds.includes(resourceId);
};

const isUnitAvailable = ({ unitId, availabilityState }: { unitId: number; availabilityState: AvailabilityState }) => {
    const availableUnitIds = availabilityState.availabilityResult?.response.unitFacet || [];
    return availableUnitIds.includes(unitId);
};

async function getMyEnvUnitId(contentParams: ContentParams): Promise<number | undefined> {
    if (contentParams.isMyEnvWidget && contentParams.myEnvState?.ownerState?.selectedUnitId) {
        return contentParams.myEnvState?.ownerState?.selectedUnitId;
    }
    const selectedEsReservation: MyEnvReservation | undefined = await getMyEnvReservation(contentParams);
    return selectedEsReservation?.hasUnitPreference ? getUnitId(selectedEsReservation) : undefined;
}

async function getMyEnvResourceId(contentParams: ContentParams): Promise<number | undefined> {
    const selectedEsReservation: MyEnvReservation | undefined = await getMyEnvReservation(contentParams);
    return getResourceIdFromEsReservationResult(selectedEsReservation);
}

async function getMyEnvAccoKindId(contentParams: ContentParams): Promise<number | undefined> {
    // TODO: implement this method
    return undefined;
}

async function getMyEnvResortIds(contentParams: ContentParams): Promise<number[] | undefined> {
    const selectedEsReservation: MyEnvReservation | undefined = await getMyEnvReservation(contentParams);
    const resortId: number | undefined = getResortId(selectedEsReservation);
    return resortId ? [resortId] : undefined;
}

async function getMyEnvReservation(contentParams: ContentParams): Promise<MyEnvReservation | undefined> {
    const { isMyEnvWidget, esReservationResult, env: paramEnv, dynamicFilter, myEnvState, availabilityState, context } = contentParams;
    if (isMyEnvWidget) {
        const env = await getEnv({ availabilityState, context, paramEnv });
        return getSelectedEsReservation({ isMyEnvWidget, esReservationResult, env, myEnvState, dynamicFilter });
    }
    return undefined;
}

async function getMyEnvContentType(contentParams: ContentParams): Promise<CONTENT_TYPE | undefined> {
    const selectedReservation: MyEnvReservation | undefined = await getMyEnvReservation(contentParams);
    const hasUnitPreference = selectedReservation?.hasUnitPreference;
    if (hasUnitPreference && (await getMyEnvUnitId(contentParams))) {
        return CONTENT_TYPE.UNIT;
    } else if (await getMyEnvResourceId(contentParams)) {
        return CONTENT_TYPE.ACCOMMODATION_TYPE;
    } else if (await getMyEnvAccoKindId(contentParams)) {
        return CONTENT_TYPE.ACCOMMODATION_KIND;
    } else if ((await getMyEnvResortIds(contentParams))?.length) {
        return CONTENT_TYPE.RESORT;
    }
    return undefined;
}

export const isContentFilterChanged = (
    previousProps: { dynamicFilter?: DynamicFilter; myEnvState?: MyEnvState; isMyEnvWidget?: boolean },
    currentProps: { dynamicFilter?: DynamicFilter; myEnvState?: MyEnvState; isMyEnvWidget?: boolean },
    checkDynamicFilter = true
): boolean =>
    (checkDynamicFilter && !isEqual(previousProps.dynamicFilter, currentProps.dynamicFilter)) ||
    !isEqual(!!previousProps.isMyEnvWidget, !!currentProps.isMyEnvWidget) ||
    (!!currentProps.isMyEnvWidget &&
        (previousProps.myEnvState?.selectedReservation?.reservation?.reservationId !== currentProps.myEnvState?.selectedReservation?.reservation?.reservationId ||
            !!previousProps.myEnvState?.selectedReservation?.hasUnitPreference !== !!currentProps.myEnvState?.selectedReservation?.hasUnitPreference ||
            !isEqual(previousProps.myEnvState?.selectedReservation?.reservedResources, currentProps.myEnvState?.selectedReservation?.reservedResources) ||
            currentProps.myEnvState?.selectedReservation?.selectedReservedResourceId !== previousProps.myEnvState?.selectedReservation?.selectedReservedResourceId ||
            currentProps.myEnvState?.ownerState?.selectedUnitId !== previousProps.myEnvState?.ownerState?.selectedUnitId));

// eslint-disable-next-line max-lines-per-function
export const getContent = async (contentParams: ContentParams): Promise<Content | Content[]> => {
    const {
        options,
        dynamicContainerOptions,
        resort,
        accommodationType,
        unit,
        activity,
        context,
        dynamicFilter,
        myEnvState,
        availabilityState,
        skipContentTypeSelectorCheck,
        checkForMultipleContent,
        checkForAvailability,
        env: paramEnv,
        includeAddress,
        obtainMyEnvReservedResources,
        isMyEnvWidget,
        prioritizeResultPanelParams,
    } = contentParams;
    const mxtsApi = contentParams.context?.mxtsApi || MxtsApi;

    const contentTypeOption = options?.contentType || options?.contentType === 0;
    const contentType = contentTypeOption ? options.contentType : (await getMyEnvContentType(contentParams)) || dynamicContainerOptions?.contentType;
    // Note:- This check for CRP content coming from parent search container.
    const content: Content = resort || accommodationType || unit || activity;
    const { resortids, resourceid, unitid }: { resortids?: string | string[]; resourceid?: string | string[]; unitid?: string | string[] } = isClientSide()
        ? parse(window.location.search)
        : dynamicFilter || {};
    let newContent: Content = content;
    let newContents: Content[] | undefined;

    const allowContentTypeSelection = options.contentTypeSelector || skipContentTypeSelectorCheck;
    try {
        if ((allowContentTypeSelection && contentTypeOption) || dynamicContainerOptions?.contentType || (await getMyEnvContentType(contentParams))) {
            switch (contentType) {
                case RESORT:
                case CONTENT_TYPE.RESORT: {
                    let currentResorts = resort && [resort];
                    let resortIds: number[] | undefined = (await getResortIds({ ...contentParams, resortids, content })) || dynamicFilter?.resortids;

                    if (resortIds?.length && resortIds[0]) {
                        resortIds = checkForMultipleContent ? resortIds : [resortIds[0]];
                        if (!isEqual([currentResorts?.[0].resortId], resortIds)) {
                            const env = await getEnv({ availabilityState, context, paramEnv });
                            const allResortSelected = resortIds.includes(ALL);
                            if (allResortSelected) {
                                currentResorts = await fetchAllResorts({ env, includeAddress }, mxtsApi);
                            } else {
                                currentResorts = await fetchResortsByResortIds({ resortIds, env, includeAddress }, mxtsApi);
                            }
                        }
                    }
                    newContent = checkForMultipleContent ? currentResorts : currentResorts?.[0];
                    break;
                }
                case TIPS_AND_TRIPS:
                case CONTENT_TYPE.TIPS_AND_TRIPS: {
                    let tipsAndTrips: any = [];
                    const { pointsOfInterestIds, pointsOfInterestCategoryIds } = options;
                    const pointsOfInterestId = +pointsOfInterestIds?.[0];
                    const pointssOfInterestCategoryId = +pointsOfInterestCategoryIds?.[0];
                    const env = await getEnv({ availabilityState, context, paramEnv });
                    if (pointsOfInterestIds?.length) {
                        tipsAndTrips = await fetchPointsOfInterest(env, [pointsOfInterestId]);
                    } else if (pointsOfInterestCategoryIds?.length) {
                        tipsAndTrips = await getPointsOfInterestCategories(env, [pointssOfInterestCategoryId]);
                    }
                    newContent = tipsAndTrips?.[0];
                    break;
                }

                case ACCOMMODATION_TYPE:
                case CONTENT_TYPE.ACCOMMODATION_TYPE: {
                    let currentResource: ExtendedResource | Resource | undefined = accommodationType;
                    let currentResources: Resource[] | undefined;
                    const resourceId = (await getResourceId({ ...contentParams, resourceid, content })) || dynamicFilter?.resourceids?.[0];
                    if (resourceId) {
                        if (checkForAvailability && !isResourceAvailable({ resourceId, availabilityState })) {
                            return;
                        }
                        if (currentResource?.resourceId !== resourceId) {
                            const env = await getEnv({ availabilityState, context, paramEnv });
                            if (includeAddress) {
                                const resourcesAndAddresses: WithAddressResult<PagedResult<Resource>> = await mxtsApi.resourcesWithAddress(env, { size: 1, resourceIds: [resourceId] });
                                currentResource = resourcesAndAddresses?.data?.content?.[0];
                                if (currentResource) {
                                    (currentResource as ExtendedResource).address = resourcesAndAddresses?.addresses?.[0];
                                }
                            } else {
                                if (obtainMyEnvReservedResources?.showAllInventoryList) {
                                    const reservedResourceData = contentParams?.myEnvState?.selectedReservation?.reservedResources;
                                    const resourceIDs = reservedResourceData?.length ? reservedResourceData?.map((resource) => resource.resourceId) : [resourceId];

                                    currentResources = (await mxtsApi.resources(env, { size: reservedResourceData?.length || 1, resourceIds: resourceIDs }))?.content;
                                } else {
                                    currentResource = (await mxtsApi.resources(env, { size: 1, resourceIds: [resourceId] }))?.content[0];
                                }
                            }
                        }
                    }
                    newContents = currentResources;
                    newContent = currentResource;
                    break;
                }
                case ACCOMMODATION_KIND:
                case CONTENT_TYPE.ACCOMMODATION_KIND: {
                    let currentAccomondationKind: ExtendedResource | Resource | undefined;
                    const accotypeKindId: number | undefined = options.accoKindMultiSelector?.length
                        ? options.accoKindMultiSelector[0].value
                        : (await getMyEnvAccoKindId(contentParams)) || dynamicFilter.accokindids?.[0];
                    if (accotypeKindId) {
                        if (checkForAvailability && !isResourceAvailable({ resourceId: accotypeKindId, availabilityState })) {
                            return;
                        }
                        const env = await getEnv({ availabilityState, context, paramEnv });
                        if (includeAddress) {
                            const accoKindAndAddresses: WithAddressResult<PagedResult<Resource>> = await mxtsApi.accommodationTypesWithAddress(env, { size: 1, accotypeKindId });
                            currentAccomondationKind = accoKindAndAddresses?.data?.content?.[0];
                            if (currentAccomondationKind) {
                                (currentAccomondationKind as ExtendedResource).address = accoKindAndAddresses?.addresses?.[0];
                            }
                        } else {
                            currentAccomondationKind = await mxtsApi.accommodationTypes(env, { size: 1, accotypeKindId }).then((res: PagedResult<Resource>) => res.content[0]);
                        }
                    }
                    newContent = currentAccomondationKind;
                    break;
                }
                case UNIT:
                case CONTENT_TYPE.UNIT: {
                    let currentUnit: ExtendedUnit | Unit | undefined = unit;
                    if (!prioritizeResultPanelParams) {
                        const paramUnitId = typeof unitid === "string" && unitid;
                        let unitId = options.unitId || dynamicContainerOptions?.unitId || (await getMyEnvUnitId(contentParams)) || dynamicFilter.unitid || paramUnitId;
                        if (!unitId) {
                            unitId = getUnitIdFromAvailabilityState(content as Unit);
                        }
                        if (isMyEnvWidget && !unitId && myEnvState?.ownerState?.selectedUnitId) {
                            unitId = myEnvState.ownerState.selectedUnitId;
                        }
                        if (unitId) {
                            if (checkForAvailability && !isUnitAvailable({ unitId, availabilityState })) {
                                return;
                            }
                            if (currentUnit?.unitId !== unitId) {
                                const env = await getEnv({ availabilityState, context, paramEnv });
                                if (includeAddress) {
                                    const unitWithAddress: WithAddressResult<PagedResult<Unit>> = await mxtsApi.unitsWithAddress(env, { size: 1, unitIds: [unitId] });
                                    currentUnit = unitWithAddress?.data?.content?.[0];
                                    if (currentUnit) {
                                        (currentUnit as ExtendedUnit).address = unitWithAddress?.addresses?.[0];
                                    }
                                } else {
                                    currentUnit = await mxtsApi.unitWithId(env, { size: 1, view: "detail", fetchInheritedFields: true }, [{ key: "unitId", value: unitId }]).then((res: Unit) => res);
                                }
                            }
                        }
                    }
                    if (!currentUnit && prioritizeResultPanelParams) {
                        currentUnit = await getUnitFromCrpEsReservationResult({ esReservationResult: contentParams.esReservationResult, mxtsApi, availabilityState, context, paramEnv });
                    }
                    newContent = currentUnit;
                    break;
                }
            }
        }
    } catch (err) {
        return;
    }
    return obtainMyEnvReservedResources?.showAllInventoryList ? newContents : newContent;
};

async function getUnitFromCrpEsReservationResult(params: {
    esReservationResult?: MyEnvReservation;
    mxtsApi: MxtsApiWrapper;
    availabilityState: AvailabilityState;
    context?: CMSProviderProperties;
    paramEnv?: ApiCallOptions;
}) {
    const { esReservationResult, mxtsApi, availabilityState, context, paramEnv } = params;
    const reservedResourcesWithUnits = esReservationResult?.reservedResources?.filter((reservedResource) => reservedResource.unitId);
    // Only show unit details if there is a single unit. Otherwise, which unit name should you show?
    if (reservedResourcesWithUnits?.length === 1) {
        const reservedResourceWithUnit = reservedResourcesWithUnits[0];
        const unitId = reservedResourceWithUnit.unitId;
        const env = await getEnv({ availabilityState, context, paramEnv });
        const preferences = await mxtsApi.getReservedResourcePreferences(env, {}, [
            { key: "reservationId", value: reservedResourceWithUnit.reservationId },
            { key: "reservedResourceId", value: reservedResourceWithUnit.reservedResourceId },
        ]);
        const hasUnitPreference = preferences.some((preference) => preference.type === ReservedResourcePreferenceType.OBJECT);
        if (hasUnitPreference) {
            return await mxtsApi.unitWithId(env, { size: 1, view: "detail", fetchInheritedFields: true }, [{ key: "unitId", value: unitId }]);
        }
    }
}

export const getDynamicData = async (mxtsApi: MxtsApiWrapper, dynamicDataParams: DynamicDataParams) => {
    const { selectedDynamicData, dynamicFilter, availabilityState } = dynamicDataParams;
    const dynamicDataList: DynamicData = {};
    if (selectedDynamicData) {
        await Promise.all(
            selectedDynamicData.map(async (dynamicData) => {
                switch (dynamicData.label) {
                    case MultiSelectDynamicData.RESORT: {
                        if (dynamicFilter && availabilityState) {
                            const ContentParams: DynamicContentType = {
                                dynamicFilter,
                                availabilityState,
                                options: { contentType: RESORT, contentTypeSelector: true },
                            };
                            const resort: Resort | undefined = (await getContent({ ...ContentParams })) as Resort | undefined;
                            dynamicDataList.resortName = resort?.name;
                        }
                        break;
                    }
                    case MultiSelectDynamicData.TYPE_NAME: {
                        if (dynamicFilter && availabilityState) {
                            const ContentParams: DynamicContentType = {
                                dynamicFilter,
                                availabilityState,
                                options: { contentType: ACCOMMODATION_TYPE, contentTypeSelector: true },
                            };
                            const resource: Resource | undefined = (await getContent({ ...ContentParams })) as Resource | undefined;
                            dynamicDataList.typeName = resource?.name;
                        }
                        break;
                    }
                    case MultiSelectDynamicData.UNIT_NAME: {
                        if (dynamicFilter && availabilityState) {
                            const ContentParams: DynamicContentType = {
                                dynamicFilter,
                                availabilityState,
                                options: { contentType: UNIT, contentTypeSelector: true },
                            };
                            const unit: Unit | undefined = (await getContent({ ...ContentParams })) as Unit | undefined;
                            dynamicDataList.unitName = unit?.name;
                        }
                        break;
                    }
                    case MultiSelectDynamicData.ACCOKIND: {
                        if (dynamicFilter && availabilityState) {
                            const ContentParams: DynamicContentType = {
                                dynamicFilter,
                                availabilityState,
                                options: { contentType: ACCOMMODATION_KIND, contentTypeSelector: true },
                            };
                            const accoKind: AccoKind | undefined = (await getContent({ ...ContentParams })) as AccoKind | undefined;
                            dynamicDataList.accoKindName = accoKind?.name;
                        }
                        break;
                    }
                    case MultiSelectDynamicData.START_DATE: {
                        dynamicDataList.startDate = dynamicFilter?.startdate || "";
                        break;
                    }
                    case MultiSelectDynamicData.END_DATE: {
                        dynamicDataList.endDate = dynamicFilter?.enddate || "";
                        break;
                    }
                    case MultiSelectDynamicData.SUBJECT: {
                        const subjectInfo: SubjectResult[] = [];
                        const subjectResult: SubjectResult[] = [];
                        if (dynamicFilter?.subject && availabilityState?.env) {
                            for (const [subjectId, subjectCount] of dynamicFilter?.subject) {
                                if (subjectCount) {
                                    subjectInfo.push({ subjectId, subjectCount });
                                }
                            }
                            const subjectData = await mxtsApi.subjects(availabilityState?.env, {
                                subjectIds: subjectInfo.map((subject) => subject.subjectId),
                            });
                            subjectInfo.map((subject) => {
                                const result: Subject | undefined = subjectData.content.find(({ subjectId }) => subjectId === subject.subjectId);
                                if (result) {
                                    subjectResult.push({ ...subject, subjectName: result.name });
                                }
                            });
                        }
                        dynamicDataList.subjects = subjectResult;
                        break;
                    }
                    case MultiSelectDynamicData.DURATION: {
                        dynamicDataList.duration = availabilityState?.availabilityRequest?.duration;
                        break;
                    }

                    default:
                        break;
                }
            })
        );
    }
    return { ...dynamicDataList };
};

export async function getDynamicDataListMyEnv(mxtsApi: MxtsApiWrapper, myEnvState: MyEnvState, env: ApiCallOptions, selectedDynamicData: StringMultiSelectOption[]): Promise<DynamicData> {
    const dynamicDataList: DynamicData = { isMyEnv: true };

    const { arrivalDate, departureDate, resortId } = myEnvState?.selectedReservation?.reservation || {};
    const { resource, unit } = myEnvState?.selectedReservation?.reservedResources?.[0] || {};

    if (selectedDynamicData) {
        await Promise.all(
            selectedDynamicData.map(async (dynamicData) => {
                switch (dynamicData.label) {
                    case MultiSelectDynamicData.START_DATE: {
                        dynamicDataList.startDate = arrivalDate || "";
                        break;
                    }
                    case MultiSelectDynamicData.END_DATE: {
                        dynamicDataList.endDate = departureDate || "";
                        break;
                    }
                    case MultiSelectDynamicData.RESORT: {
                        if (resortId) {
                            const resort = await mxtsApi.resort(env, {}, [{ key: "resortId", value: [resortId] }]);
                            dynamicDataList.resortName = resort?.name || "";
                        }
                        break;
                    }
                    case MultiSelectDynamicData.TYPE_NAME: {
                        dynamicDataList.typeName = resource?.name || "";
                        break;
                    }
                    case MultiSelectDynamicData.UNIT_NAME: {
                        dynamicDataList.unitName = unit?.name || "";
                        break;
                    }
                    case MultiSelectDynamicData.ACCOKIND: {
                        const accoKindId = resource?.accommodationKindId;
                        if (accoKindId) {
                            const accoKindsResult = await mxtsApi.accommodationkinds(env, {
                                accoKindId,
                                sort: "priority",
                                size: 1,
                            });
                            dynamicDataList.accoKindName = accoKindsResult?.content[0]?.name || "";
                        }
                        break;
                    }
                    default:
                        break;
                }
            })
        );
    }

    return dynamicDataList;
}

export const getParentContent = async (mxtsApi: MxtsApiWrapper, { env, content }: { env: ApiCallOptions; content: Exclude<Content | Content[], undefined> }): Promise<Resource | undefined> => {
    // for now getting only parent of Unit
    if ((content as Exclude<Content, Resort | Resort[] | Activity | undefined>).type === UNIT_TYPE) {
        const parentContent = await mxtsApi.resources(env, { size: 1, resourceIds: [(content as Unit).resourceId] }).then((res: PagedResult<Resource>) => res.content[0]);
        return parentContent;
    }
};

const getResortIds = async (contentParams: ContentParams & { resortids?: string | string[]; content: Exclude<Content, Resort[]> }): Promise<number[] | undefined> => {
    const { options, dynamicContainerOptions, env: paramEnv, context, dynamicFilter, availabilityState, resortids, content, checkForMultipleContent, isMyEnvWidget } = contentParams;

    if (options.resortSelector) {
        return [options.resortSelector];
    }
    if (options.resortMultiSelector?.length || dynamicContainerOptions?.resortMultiSelector?.length) {
        const resortMultiSelector: NumberMultiSelectOption[] = options.resortMultiSelector?.length ? options.resortMultiSelector : dynamicContainerOptions?.resortMultiSelector;
        return resortMultiSelector.map((resort) => resort.value);
    }

    const myEnvResortIds: number[] | undefined = await getMyEnvResortIds(contentParams);
    if (isMyEnvWidget && myEnvResortIds?.length) {
        return myEnvResortIds;
    }

    if (typeof resortids === "string" && resortids) {
        return [+resortids];
    }
    // TODO: Add filters with multiple possibilities under this condition.
    if (checkForMultipleContent) {
        if (dynamicFilter?.resortids?.length || (Array.isArray(resortids) && resortids?.length)) {
            return dynamicFilter.resortids?.length ? dynamicFilter?.resortids : (resortids as string[]).map((resortId) => +resortId);
        }
        const resortIds = getResortIdsFromAvailabilityState(availabilityState.availabilityResult, content);
        if (resortIds?.length) {
            return resortIds;
        }
    }
    const resortCode = dynamicFilter.resort;
    if (resortCode) {
        const env = await getEnv({ availabilityState, context, paramEnv });
        const resort: Resort | undefined = await (contentParams.context?.mxtsApi || MxtsApi).resorts(env, { code: resortCode }).then((res) => res.content[0]);
        if (resort?.resortId) {
            return [resort.resortId];
        }
    }

    // try to get resortId from unitId
    const updatedOptions = { ...contentParams.options, contentType: UNIT };
    const unit = (await getContent({ ...contentParams, options: updatedOptions, checkForAvailability: false })) as Unit | undefined;
    if (unit?.resortId) {
        return [unit.resortId];
    }

    // try to get resortId from resourceId
    updatedOptions.contentType = ACCOMMODATION_TYPE;
    const resource = (await getContent({ ...contentParams, options: updatedOptions, checkForAvailability: false })) as Resource | undefined;
    if (resource?.resortId) {
        return [resource.resortId];
    }
};

export const getResourceId = async (contentParams: ContentParams & { resourceid?: string | string[]; content: Content }): Promise<number | undefined> => {
    const { options, dynamicContainerOptions, content, resourceid, dynamicFilter, availabilityState } = contentParams;
    const paramResourceId = typeof resourceid === "string" && resourceid;
    const optionsResourceId = Array.isArray(options.resourceId) && !options.resourceId.length ? undefined : options.resourceId?.map((resource: NumberMultiSelectOption) => resource?.value);
    let resourceId = optionsResourceId || dynamicContainerOptions?.resourceId || (await getMyEnvResourceId(contentParams)) || dynamicFilter?.resourceid || paramResourceId;
    if (resourceId) {
        return resourceId;
    }
    resourceId = getResourceIdFromAvailabilityState(availabilityState.availabilityResult, content);
    if (resourceId) {
        return resourceId;
    }

    // try to get resourceId from unitId
    const updatedOptions = { ...contentParams.options, contentType: UNIT };
    const unit = (await getContent({ ...contentParams, options: updatedOptions, checkForAvailability: false })) as Unit | undefined;
    return unit?.resourceId;
};

/* This method is used to fetch value of selected dynamicFields based on content type*/

export const getDynamicFieldCodeValue = async ({
    content,
    dynamicFieldCode,
    env,
    isParent = false,
    parentContent,
    apiContext,
    widgetOptionsId,
    widgetOptionsDynamicFieldCodesPaths,
}: {
    dynamicFieldCode: string | number;
    isParent?: boolean;
    content?: Exclude<Content | Content[], Resort[]>;
    env?: ApiCallOptions;
    parentContent?: Resource;
    apiContext: ApiContext;
    widgetOptionsId: string;
    widgetOptionsDynamicFieldCodesPaths: string[];
}): Promise<DynamicFieldInfo | Image | null> => {
    if (content && env) {
        let managerId: number | undefined = (content as Resource | Unit | Resort).dynamicManagerId;
        if (isParent) {
            if (!parentContent) {
                parentContent = await getParentContent(apiContext.mxtsApi, { content, env });
            }
            managerId = parentContent?.dynamicManagerId;
        }
        const dynamicFieldResponse = managerId
            ? await apiContext.mxtsApi.dynamicFieldsInfoCustomized(env, {
                  managerId,
                  code: dynamicFieldCode.toString(),
                  checkFallback: false,
                  widgetOptionsId,
                  widgetOptionsDynamicFieldCodesPaths,
              })
            : [];

        if (dynamicFieldResponse.length) {
            const { imageManagerId, name, value } = dynamicFieldResponse[0] || {};
            if (imageManagerId) {
                const imageResponse = await getImages(apiContext, imageManagerId);
                if (imageResponse[0] || isParent) {
                    return { ...imageResponse[0], code: dynamicFieldCode.toString(), name };
                }
                return getDynamicFieldCodeValue({ dynamicFieldCode, isParent: true, content, env, parentContent, apiContext, widgetOptionsId, widgetOptionsDynamicFieldCodesPaths });
            } else if (!value && !isParent) {
                return getDynamicFieldCodeValue({ dynamicFieldCode, isParent: true, content, env, parentContent, apiContext, widgetOptionsId, widgetOptionsDynamicFieldCodesPaths });
            }
        } else if (!isParent) {
            return getDynamicFieldCodeValue({ dynamicFieldCode, isParent: true, content, env, parentContent, apiContext, widgetOptionsId, widgetOptionsDynamicFieldCodesPaths });
        }
        return dynamicFieldResponse[0];
    }
    return null;
};
