import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { DayPickerSingleDateController, DayPickerSingleDateControllerShape } from "react-dates";
import { getPrices, isDayBlocked, isDayHighlighted, isOutsideRange, loadPrices } from "../datepicker.util";
import { isEqual, noop, omit } from "lodash";

import { Arrival } from "@maxxton/cms-mxts-api";
import { AvailabilityUtil } from "../../../utils/availability.util";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CalendarFormActions } from "../CalendarFormActions/CalendarFormActions";
import { DATE_FORMAT } from "../../../utils/constants";
import { DateMap } from "../../../plugins/mxts/mxts.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { initialVisibleMonthThunk } from "../../../plugins/mxts";

type Moment = moment.Moment;

interface DayPickerSingleDateControllerWrapperState {
    blockPrevMonthRender: boolean;
    availableDatesDetail?: Arrival[];
}

export type DayPickerSingleDateControllerWrapperProps = DayPickerSingleDateControllerShape & {
    onCalendarClose: () => void;
    showCloseIcon: boolean;
    availableDates?: DateMap;
    specialAvailableDates?: DateMap;
    loading: boolean;
    showBackdrop: boolean;
    showHighlightedDates: boolean;
    showOnlySpecialDates: boolean;
    availableDurations?: number[];
    dynamicFilter?: DynamicFilter;
    context?: CMSProviderProperties;
    showCustomData?: boolean;
    customDataDisplayType?: string;
    showPerNightPrices?: boolean;
    showTotalPrices?: boolean;
    isInLineCalendar?: boolean;
    onCalendarClear?: () => void;
};

const defaultProps = {
    date: null,
    displayFormat: DATE_FORMAT.DISPLAY,
    onDateChange: noop,
    onFocusChange: noop,
    onCalendarClose: noop,
    initialVisibleMonth: noop,
    onCalendarClear: noop,
    showCloseIcon: false,
    focused: false,
    numberOfMonths: 1,
    loading: false,
    hideKeyboardShortcutsPanel: true,
    showBackdrop: true,
    showHighlightedDates: false,
    readOnly: true,
    showOnlySpecialDates: false,
    isInLineCalendar: false,
};

class DayPickerSingleDateControllerWrapper extends React.Component<DayPickerSingleDateControllerWrapperProps, DayPickerSingleDateControllerWrapperState> {
    static defaultProps = defaultProps;

    constructor(props: DayPickerSingleDateControllerWrapperProps) {
        super(props);
        const { date } = props;
        const dates = this.getDates();
        const blockPrevMonthRender = dates && Object.keys(dates).length && date ? AvailabilityUtil.isFirstAvailableMonth({ dateMap: dates, day: date }) : true;
        this.state = {
            blockPrevMonthRender,
            availableDatesDetail: [],
        };
    }

    componentDidMount() {
        loadPrices(this, true);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<DayPickerSingleDateControllerWrapperProps>): void {
        const { availableDates, dynamicFilter } = this.props;
        if (!isEqual(availableDates, nextProps.availableDates) || !isEqual(dynamicFilter, nextProps.dynamicFilter)) {
            loadPrices(this, true);
        }
    }

    componentDidUpdate(prevProps: DayPickerSingleDateControllerWrapperProps) {
        const { availableDates, specialAvailableDates, showOnlySpecialDates, date } = this.props;
        const specialAvailableDatesChanged = showOnlySpecialDates && !prevProps.specialAvailableDates && specialAvailableDates;
        const availableDatesChanged = !showOnlySpecialDates && !prevProps.availableDates && availableDates;

        if ((specialAvailableDatesChanged || availableDatesChanged) && date) {
            const dates = this.getDates();
            const blockPrevMonthRender = AvailabilityUtil.isFirstAvailableMonth({ dateMap: dates, day: date });
            this.setState({ blockPrevMonthRender });
        }
    }

    render() {
        const {
            specialAvailableDates,
            showCloseIcon,
            date,
            loading,
            showHighlightedDates,
            focused,
            showBackdrop,
            dynamicFilter,
            customDataDisplayType,
            showPerNightPrices,
            showCustomData,
            isInLineCalendar,
        } = this.props;
        const { blockPrevMonthRender, availableDatesDetail } = this.state;
        const dayPickerSingleDateControllerProps = omit(this.props, [
            "availableDates",
            "specialAvailableDates",
            "showCloseIcon",
            "onCalendarClose",
            "loading",
            "showHighlightedDates",
            "showOnlySpecialDates",
            "showBackdrop",
            "showCustomData",
            "customDataDisplayType",
            "dynamicFilter",
            "showPerNightPrices",
            "displayFormat",
            "readOnly",
            "minDate",
            "isInLineCalendar",
            "onCalendarClear",
        ]) as DayPickerSingleDateControllerShape;
        const dates = this.getDates();
        const firstAvailableDay: Moment | undefined = AvailabilityUtil.getFirstAvailableDay({ dateMap: dates });
        const showDayHighlighted = showHighlightedDates ? isDayHighlighted(specialAvailableDates) : undefined;
        const startDate = date || firstAvailableDay;
        const { renderCalendarInfo } = dayPickerSingleDateControllerProps;
        if (loading) {
            return (
                <div className="calendar-loader">
                    <div className="calendar-overlay" />
                    <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                    <DayPickerSingleDateController {...dayPickerSingleDateControllerProps} focused={false} />
                </div>
            );
        }

        const paramToFetchPrices = {
            startDate,
            endDate: null,
            dynamicFilter,
            customDataDisplayType,
            showPerNightPrices,
            availableDatesDetail,
            isSingleDatePicker: true,
        };
        return (
            <div className={(classNames(blockPrevMonthRender && "prev-disabled"), "availability-calendar__item")}>
                <div className={focused && showBackdrop ? "backdrop-popup" : ""}></div>
                <DayPickerSingleDateController
                    {...dayPickerSingleDateControllerProps}
                    minDate={firstAvailableDay}
                    isDayBlocked={isDayBlocked(dates)}
                    isOutsideRange={isOutsideRange(firstAvailableDay)}
                    isDayHighlighted={showDayHighlighted}
                    initialVisibleMonth={initialVisibleMonthThunk(dates, date)}
                    renderCalendarInfo={isInLineCalendar || showCloseIcon ? this.getCalendarFormActions : renderCalendarInfo}
                    onPrevMonthClick={this.onMonthClick}
                    onNextMonthClick={this.onMonthClick}
                    hideKeyboardShortcutsPanel={true}
                    // eslint-disable-next-line react/jsx-no-bind
                    renderDayContents={(day, modifiers) => <React.Fragment>{showCustomData ? getPrices({ ...paramToFetchPrices, day, modifiers }) : day.format("D")}</React.Fragment>}
                />
            </div>
        );
    }

    private getDates = (): DateMap | undefined => {
        const { availableDates, specialAvailableDates, showOnlySpecialDates } = this.props;
        const dates = showOnlySpecialDates ? specialAvailableDates : availableDates;
        return dates;
    };

    private onMonthClick = (day: Moment): void => {
        const dates = this.getDates();
        const blockPrevMonthRender = dates ? AvailabilityUtil.isFirstAvailableMonth({ dateMap: dates, day }) : true;
        if (this.state.blockPrevMonthRender !== blockPrevMonthRender) {
            this.setState({ blockPrevMonthRender });
        }
    };

    private getCalendarFormActions = (): JSX.Element => {
        const { onCalendarClose, isInLineCalendar, onCalendarClear } = this.props;
        const showClearButton = isInLineCalendar;
        return <CalendarFormActions onCalendarClose={onCalendarClose} showClear={showClearButton} onCalendarClear={onCalendarClear} />;
    };
}

export { DayPickerSingleDateControllerWrapper };
