export enum FlowWidgetTypes {
    ACCODETAIL = "accodetail",
    ADDITION = "addition",
    CONFIRMATION = "confirmation",
    CONDITIONAL = "conditional",
    CUSTOMER = "customer",
    PAYMENT = "payment",
    PRICECOMPARE = "pricecompare",
    RESULT = "result",
}
