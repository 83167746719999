import { Address, ApiCallOptions, Facility, MxtsApiWrapper, PagedResult, Resort, WithAddressResult, getAllWithAddress } from "@maxxton/cms-mxts-api";
import { chunk, isEqual } from "lodash";

import { ArrayUtil } from "./array.util";
import { AvailabilityState } from "../redux/reducers/availability.types";
import { DomainObjectUtil } from "./domainobject.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { Resort as ExtendedResort } from "../plugins/mxts/index";
import { MXTS } from "./constants";
import { NumberMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { getRemainingPageIndexes } from "./mxtsPage.util";
import { getResortIdsFromAvailabilityState } from "./content.util";

const { PAGE_REQUEST_SIZE } = MXTS;

export const fetchAllResorts = async ({ env, includeAddress }: { env: ApiCallOptions; includeAddress?: boolean }, mxtsApi: MxtsApiWrapper): Promise<Array<Resort | ExtendedResort>> => {
    let allResorts: Array<Resort | ExtendedResort> = [];
    if (includeAddress) {
        const allResortsWithAddress: WithAddressResult<PagedResult<Resort>> = await getAllWithAddress((size: number, page: number) => mxtsApi.resortsWithAddress(env, { size, page }));
        allResorts = allResortsWithAddress.data?.content || [];
        allResorts.forEach((resort) => {
            (resort as ExtendedResort).address = (allResortsWithAddress.addresses || []).find((address) => address.managerId === resort.visitAddressManagerId);
        });
    } else {
        const intialResortsResponse = await mxtsApi.resorts(env, { size: PAGE_REQUEST_SIZE }).then((resortResponse: PagedResult<Resort>) => resortResponse);
        const { number: pageIndex, totalPages } = intialResortsResponse;
        const remainingResortPageIndexes = getRemainingPageIndexes({ pageIndex, totalPages });
        const resortPromises = remainingResortPageIndexes.map((pageIndex) =>
            mxtsApi.resorts(env, { size: PAGE_REQUEST_SIZE, page: pageIndex }).then((resortResponse: PagedResult<Resort>) => resortResponse.content)
        );
        const remainingResorts = (await Promise.all(resortPromises)).flat();
        allResorts = [...intialResortsResponse.content, ...remainingResorts];
    }
    return allResorts;
};

export const fetchResortsByResortIds = async (
    { resortIds, env, includeAddress, pageRequestSize }: { resortIds: number[]; env: ApiCallOptions; includeAddress?: boolean; pageRequestSize?: number },
    mxtsApi: MxtsApiWrapper
): Promise<Array<Resort | ExtendedResort>> => {
    if (!resortIds.length) {
        return [];
    }
    const resortIdsChunk = chunk(resortIds, pageRequestSize || PAGE_REQUEST_SIZE);

    let allResorts: Array<Resort | ExtendedResort> = [];
    if (includeAddress) {
        const allResortsAndAddresses: Array<WithAddressResult<PagedResult<Resort>>> = await Promise.all(
            resortIdsChunk.map((resortIds) => mxtsApi.resortsWithAddress(env, { size: pageRequestSize || PAGE_REQUEST_SIZE, resortIds }))
        );
        allResorts = ArrayUtil.flatten2Dimensions(allResortsAndAddresses.map((resortsAndAddresses: WithAddressResult<PagedResult<Resort>>) => resortsAndAddresses?.data?.content || []));
        const allAddresses: Address[] = ArrayUtil.flatten2Dimensions(allResortsAndAddresses.map((resortsAndAddresses: WithAddressResult<PagedResult<Resort>>) => resortsAndAddresses?.addresses || []));
        allResorts.forEach((resort) => {
            (resort as ExtendedResort).address = allAddresses.find((address) => address.managerId === resort.visitAddressManagerId);
        });
    } else {
        const resortPromises = resortIdsChunk.map((resortIds) =>
            mxtsApi.resorts(env, { size: pageRequestSize || PAGE_REQUEST_SIZE, resortIds }).then((resortResponse: PagedResult<Resort>) => resortResponse.content)
        );
        allResorts = (await Promise.all(resortPromises)).flat();
    }
    return allResorts;
};

export const getResortIdFromFilters = (dynamicFilter: DynamicFilter, availabilityState: AvailabilityState) => {
    const resortId = dynamicFilter.resortids?.[0] || getResortIdsFromAvailabilityState(availabilityState.availabilityResult)?.[0];
    return resortId;
};

export const fetchResortFacilitiesByResortIds = async (
    {
        resortIds,
        env,
        includeAddress,
    }: {
        resortIds: number[];
        env: ApiCallOptions;
        includeAddress?: boolean;
    },
    mxtsApi: MxtsApiWrapper
): Promise<{ facilities: Facility[]; addresses: Address[] }> => {
    if (!resortIds.length) {
        return { facilities: [], addresses: [] };
    }
    const resortIdsChunk = chunk(resortIds, PAGE_REQUEST_SIZE);
    let allFacilities: Facility[] = [];
    let allAddresses: Address[] = [];
    if (includeAddress) {
        const allFacilitiesAndAddresses: Array<WithAddressResult<PagedResult<Facility>>> = await Promise.all(
            resortIdsChunk.map((resortIds) => mxtsApi.facilitiesWithAddress(env, { size: PAGE_REQUEST_SIZE, resortId: resortIds }))
        );
        allFacilities = ArrayUtil.flatten2Dimensions(allFacilitiesAndAddresses.map((facilityAndAdresses: WithAddressResult<PagedResult<Facility>>) => facilityAndAdresses?.data?.content || []));
        allAddresses = ArrayUtil.flatten2Dimensions(allFacilitiesAndAddresses.map((facilitiesAndAddresses: WithAddressResult<PagedResult<Facility>>) => facilitiesAndAddresses?.addresses || []));
    } else {
        const resortFacilityPromises = resortIdsChunk.map((resortIds) =>
            mxtsApi.facilities(env, { size: PAGE_REQUEST_SIZE, resortId: resortIds }).then((facility: PagedResult<Facility>) => facility.content)
        );
        allFacilities = (await Promise.all(resortFacilityPromises)).flat();
    }
    return { facilities: allFacilities, addresses: allAddresses };
};

export async function procureResortIds(props: {
    resortIdMultiSelector?: NumberMultiSelectOption[];
    dynamicFilter?: DynamicFilter;
    mxtsApi: MxtsApiWrapper;
    env: ApiCallOptions;
    dynamicContainerResorts?: NumberMultiSelectOption[];
}): Promise<number[] | undefined> {
    const { resortIdMultiSelector, dynamicFilter, mxtsApi, env, dynamicContainerResorts } = props;
    let resortIds: number[] | undefined =
        (resortIdMultiSelector?.length && resortIdMultiSelector.map((resort: NumberMultiSelectOption) => resort.value)) ||
        (dynamicContainerResorts?.length && dynamicContainerResorts.map((resort) => resort.value)) ||
        undefined;
    if (!resortIds && dynamicFilter) {
        if (dynamicFilter?.resort) {
            const resort = await DomainObjectUtil.getResort(mxtsApi, { code: dynamicFilter.resort }, env);
            resortIds = resort?.resortId ? [resort?.resortId] : undefined;
        }
        if (!resortIds && dynamicFilter?.resourceid) {
            const resource = await DomainObjectUtil.getResourceById(mxtsApi, dynamicFilter.resourceid, env);
            resortIds = resource?.resortId ? [resource?.resortId] : undefined;
        }
        if (!resortIds && dynamicFilter?.unitid) {
            const unit = await DomainObjectUtil.getUnitById(mxtsApi, dynamicFilter.unitid, env);
            resortIds = unit?.resortId ? [unit?.resortId] : undefined;
        }
    }
    if (dynamicFilter?.resortids && !isEqual(resortIds, dynamicFilter.resortids)) {
        resortIds = resortIds ? [...new Set([...dynamicFilter.resortids, ...resortIds])] : dynamicFilter.resortids;
    }
    return resortIds;
}
