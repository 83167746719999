// Moved to a separate util to fix a circular dependency issue in jest

import { CustomUnit } from "../../mxts/mxts.types";
import { ImageDetailedView } from "@maxxton/cms-mxts-api";

export interface UnitSelectOption {
    value: number;
    label: string;
    unit: CustomUnit;
    image?: ImageDetailedView;
    sharedOwnershipUnit?: boolean;
}

export function getYearSelectOptions() {
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearCount = 10;
    const allYears = Array.from({ length: yearCount }, (_, index) => nextYear - index);
    const yearSelectOptions = allYears.map((year) => ({
        label:
            year === currentYear
                ? "Current year($year)".replace(new RegExp("\\$year"), year.toString())
                : year === nextYear
                ? "Next year($year)".replace(new RegExp("\\$year"), year.toString())
                : year.toString(),
        value: year.toString(),
    }));
    return yearSelectOptions;
}
