import {
    ApiCallOptions,
    ChoiceReservedResource,
    Customer,
    EsReservationResultContainer,
    MxtsApi,
    MyEnvUserType,
    PreferenceType,
    ReservedResource,
    ReservedResourcePreference,
    ReservedResourceWithChildren,
    convertReservationStatusTextToNumber,
} from "@maxxton/cms-mxts-api";
import { ForkEffect, call, put, select, takeEvery, throttle } from "redux-saga/effects";
import { MyEnvActionType, MyEnvReducerAction, fetchSelectedMyEnvReservation, setMyEnvCustomerLoading, setSelectedMyEnvReservationLoading, updateMyEnvState } from "../redux/actions/myEnvAction";
import { MyEnvReservation, MyEnvState } from "../redux/reducers/myEnv/myEnvState";
import { UrlLinkParams, setUrlParam } from "../utils/urlparam.util";
import { addDayProductsToReservation, getSimpleAdditionReservedResources, insertReservedResources } from "./reservationSaga";
import {
    getMainCustomerIdFromLoginToken,
    getMyEnvMainCustomer,
    getMyEnvUserTypesFromCustomers,
    getReservationsForCustomer,
    getSelectedAccoTypeReservedResource,
} from "../redux/reducers/myEnv/myEnv.util";

import { ActionType } from "../redux/actions/index";
import { BillState } from "../redux/reducers/billReducer";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { MXTS } from "../utils/constants";
import { ReservationState } from "../redux/reducers/reservationReducer";
import { SagaUtils } from "./SagaUtils";
import { SelectedAddOnsState } from "../redux/reducers/add-ons/selectedAddOnsReducer";
import { clearSelectedAddOns } from "../redux/actions/add-ons/selectedAddOnsAction";
import { getMxtsEnv } from "../plugins/mxts/index";
import { getMyEnvAuthToken } from "../utils/authToken.util";
import { globalApiContext } from "../containers/CmsProvider";
import { parse } from "query-string";
import { reportError } from "../utils/report.utils";

function* onMyEnvOwnerStateUpdate() {
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const searchQueryParams: UrlLinkParams = parse(location.search);
    if (typeof location !== "undefined" && (myEnvState.ownerState?.selectedUnitId || searchQueryParams.selectedOwnerUnitId) && !searchQueryParams?.stateUuid) {
        setUrlParam("selectedOwnerUnitId", myEnvState.ownerState?.selectedUnitId);
    }
}

function* onMyEnvStateUpdate() {
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const reservationState: ReservationState = yield select(SagaUtils.getReservationState);
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    if (myEnvState.selectedReservationId && myEnvState.selectedReservationId !== reservationState?.reservation?.reservationId) {
        yield put({
            payload: { reservationId: myEnvState.selectedReservationId },
            type: ActionType.SetReservationState,
        });
    }

    if (myEnvState.selectedReservationId && myEnvState.selectedReservation && myEnvState.selectedReservationId !== myEnvState.selectedReservation?.reservation.reservationId) {
        yield put(fetchSelectedMyEnvReservation(myEnvState.selectedReservationId));
    }

    const selectedReservedResource = getSelectedAccoTypeReservedResource(myEnvState.selectedReservation || undefined, dynamicFilter);
    if (selectedReservedResource && myEnvState.selectedReservation?.hasUnitPreference == null) {
        yield put({
            type: MyEnvActionType.FETCH_RESERVED_RESOURCE_PREFERENCES,
            payload: {},
        });
    }
}

function* fetchReservedResourcePreferences() {
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const selectedReservation = myEnvState.selectedReservation;
    const selectedReservedResource = getSelectedAccoTypeReservedResource(selectedReservation || undefined, dynamicFilter);
    if (selectedReservation?.hasUnitPreference == null && selectedReservedResource && selectedReservation?.reservation) {
        const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale);
        const preferences: ReservedResourcePreference[] | undefined = yield call(MxtsApi.getReservedResourcePreferences, env, {}, [
            { key: "reservationId", value: selectedReservation.reservation.reservationId },
            { key: "reservedResourceId", value: selectedReservedResource.reservedResourceId },
        ]);
        yield put(updateMyEnvState({ selectedReservation: { ...selectedReservation, hasUnitPreference: !!preferences?.some((pref) => pref.type === PreferenceType.OBJECT) } }));
    }
}

let lastFetchSelectedReservationTry: Date | undefined;

/**
 * If fetching the reservation failed for some reason we are not going to retry it 10 times a second. Only allow to retry it once every 3 seconds.
 */
function isLastReservationFetchLongAgo(): boolean {
    if (!lastFetchSelectedReservationTry) {
        return true;
    }
    return new Date().getTime() - lastFetchSelectedReservationTry.getTime() > 3000;
}

function* handleFetchSelectedReservationAction(action: MyEnvReducerAction) {
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale);

    const selectedReservationId = action.payload.selectedReservationId || myEnvState.selectedReservationId;
    const selectedEsReservation: MyEnvReservation | undefined | null = myEnvState.selectedReservation;

    if (
        selectedReservationId &&
        ((selectedEsReservation?.reservation?.reservationId !== selectedReservationId && (selectedEsReservation !== null || isLastReservationFetchLongAgo())) ||
            (action.forceUpdateSelectedReservation && (!action.ignoreIfAlreadyUpdatedRecently || isLastReservationFetchLongAgo())))
    ) {
        lastFetchSelectedReservationTry = new Date();
        yield put(setSelectedMyEnvReservationLoading(true));

        let obtainedEsReservation: MyEnvReservation | null = null;
        const esReservationResultContainer: EsReservationResultContainer = yield call(getReservationsForCustomer, { reservationIds: [selectedReservationId] }, env);
        if (esReservationResultContainer?.reservationResults?.length && esReservationResultContainer?.reservationResults[0]) {
            obtainedEsReservation = esReservationResultContainer?.reservationResults[0];
            obtainedEsReservation = {
                ...obtainedEsReservation,
                reservedResources: (obtainedEsReservation.reservedResources || []).filter((reservedResource) => !reservedResource.impliesId),
            };
        }
        yield put({
            type: MyEnvActionType.RESERVATION_FETCHED,
            payload: {
                selectedReservation: obtainedEsReservation || null,
                selectedReservationLoading: false,
            },
        });
    }
}

function* handleRefreshCustomerAction() {
    yield handleFetchCustomerAction(true);
}

function* handleFetchCustomerAction(refresh?: boolean) {
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale);

    const mainCustomer = myEnvState?.mainCustomer;
    const mainCustomerId: number | undefined = yield call(getMainCustomerIdFromLoginToken);
    if ((refresh && mainCustomerId) || (myEnvState?.mainCustomer?.customerId !== mainCustomerId && mainCustomer !== null)) {
        yield put(setMyEnvCustomerLoading(true));
        const newMainCustomer: Customer | undefined = yield call(getMyEnvMainCustomer, env);
        const myEnvUserTypes: MyEnvUserType[] = yield call(getMyEnvUserTypesFromCustomers, globalApiContext());
        const customerFetchedAction: MyEnvReducerAction = {
            type: MyEnvActionType.CUSTOMER_FETCHED,
            payload: {
                mainCustomer: newMainCustomer || null,
                myEnvUserTypes,
                customerIds: getMyEnvAuthToken()?.payload?.customerIds,
                customerLoading: false,
            },
        };
        yield put(customerFetchedAction);
    }
}

function* confirmShoppingCart() {
    const selectedAddOnsState: SelectedAddOnsState = yield select(SagaUtils.getSelectedAddOnsState);
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const billState: BillState = yield select(SagaUtils.getBillState);
    const billReservedResources: ChoiceReservedResource[] = billState.myEnvAdditionsBill?.reservedResources || [];
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const selectedReservation = myEnvState.selectedReservation;
    const selectedReservedResource = getSelectedAccoTypeReservedResource(selectedReservation || undefined, dynamicFilter);
    const rateTypeId = dynamicFilter.rateType?.rateTypeId;
    const reservationId = selectedReservation?.reservation?.reservationId;

    if (reservationId && selectedReservation && selectedReservedResource && rateTypeId) {
        yield put({
            type: MyEnvActionType.UPDATE,
            payload: {
                confirmingShoppingCart: true,
            },
        });
        try {
            const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale);

            // TODO: store reservationId/reservedResourceId in additionState, to allow us to maintain a shopping cart for multiple reservations/accoTypes at the same time.
            const simpleAdditionRequestBodies: ReservedResourceWithChildren[] = yield call(getSimpleAdditionReservedResources, {
                selectedAddOnsState,
                reservation: { reservationId, status: convertReservationStatusTextToNumber(selectedReservation.reservation.status) },
                rateTypeId,
                billReservedResources,
                accoReservedResource: {
                    parentReservedResourceId: selectedReservedResource.reservedResourceId,
                    endDate: selectedReservedResource.endDate,
                    startDate: selectedReservedResource.startDate,
                },
                addOnCartId: selectedReservedResource.reservedResourceId,
            });
            yield call(updateSimpleAdditionsInMxts, {
                simpleAdditionRequestBodies,
                reservationId,
                env,
            });

            yield call(addDayProductsToReservation, {
                env,
                parentReservedResourceId: selectedReservedResource.reservedResourceId,
                reservationId,
                selectedAddOnsState,
                addOnCartId: selectedReservedResource.reservedResourceId,
            });
            yield put(clearSelectedAddOns({ targetBill: "myEnvAdditionsBill", cartReservedResourceId: selectedReservedResource.reservedResourceId }));
            yield put({ type: ActionType.ReservationUpdated, payload: {} });

            MxtsApi.clearCacheGetReservedResources((cacheKey: string) => cacheKey.includes("reservationId") && cacheKey.includes(reservationId.toString()));
        } catch (err) {
            reportError(err, undefined, "ERROR during confirmation of shopping cart");
        }
        yield put({
            type: MyEnvActionType.UPDATE,
            payload: {
                confirmingShoppingCart: false,
            },
        });
    }
}

interface UpdateAdditionsInMxtsParams {
    env: ApiCallOptions;
    simpleAdditionRequestBodies: ReservedResourceWithChildren[];
    reservationId: number;
}
export async function updateSimpleAdditionsInMxts(params: UpdateAdditionsInMxtsParams): Promise<void> {
    const { reservationId, simpleAdditionRequestBodies, env } = params;
    const existingReservedResources: ReservedResource[] = (await MxtsApi.getReservedResources(env, { size: MXTS.MAX_RESULTS, reservationId })).content || [];
    const newAdditionRequestBodies = simpleAdditionRequestBodies.filter((additionBody) => !existingReservedResources.some((existing) => existing.resourceId === additionBody.resourceId));
    const existingAdditionRequestBodies = simpleAdditionRequestBodies.filter((additionBody) => existingReservedResources.some((existing) => existing.resourceId === additionBody.resourceId));
    if (existingAdditionRequestBodies.length) {
        await Promise.all(
            existingAdditionRequestBodies.map((addition) => {
                const existingReservedResource = existingReservedResources.find((reservedResource) => reservedResource.resourceId === addition.resourceId);
                if (addition.quantity && addition.quantity > 0 && existingReservedResource) {
                    return MxtsApi.updateReservedResource(env, { quantity: addition.quantity + (existingReservedResource?.quantity || 0) }, [
                        { key: "reservationId", value: reservationId },
                        { key: "reservedResourceId", value: existingReservedResource.reservedResourceId },
                    ]);
                }
                return Promise.resolve(null);
            })
        );
    }
    await insertReservedResources(newAdditionRequestBodies, reservationId, env);
}

export function* watchMyEnvChanges(): Generator<ForkEffect, void, boolean> {
    yield throttle(1000, MyEnvActionType.FETCH_RESERVED_RESOURCE_PREFERENCES, fetchReservedResourcePreferences);
    yield throttle(1000, MyEnvActionType.FETCH_RESERVATION, handleFetchSelectedReservationAction);
    yield throttle(1000, MyEnvActionType.FETCH_CUSTOMER, handleFetchCustomerAction);
    yield throttle(1000, MyEnvActionType.REFRESH_CUSTOMER, handleRefreshCustomerAction);
    yield takeEvery(MyEnvActionType.UPDATE, onMyEnvStateUpdate);
    yield takeEvery(MyEnvActionType.OWNER_UPDATE, onMyEnvOwnerStateUpdate);
    yield takeEvery(MyEnvActionType.CONFIRM_SHOPPING_CART, confirmShoppingCart);
}

export function getAllMyEnvSagas(): Array<Generator<ForkEffect, void, boolean>> {
    return [watchMyEnvChanges()];
}
