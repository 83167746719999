import { ActionType } from "../actions";
import { Reducer } from "redux";
import { RevealerAction } from "../actions/RevealerAction";

export interface RevealerState {
    revealerId: string;
}

// eslint-disable-next-line max-len
export const revealerReducer: Reducer<RevealerState> = (state: RevealerState = { revealerId: "" }, action: RevealerAction): RevealerState => {
    switch (action.type) {
        case ActionType.RevealerClick:
            return { ...state, revealerId: action.payload || "" };
        default:
            return state;
    }
};
