export interface Alert {
    color: "success" | "info" | "warning" | "danger";
    message: React.ReactNode;
    timeout?: number | boolean;
    dismissable?: boolean;
    appearTime?: number;
    active?: boolean;
    id?: string | number;
}

export type AlertCallback = (alert: Alert) => void;

export type AlertObserver = (nextAlerts: Alert[], prevAlerts?: Alert[]) => void;

export class Alerts {
    public hardDiscardTimeout = 5000;

    private observers: AlertObserver[] = [];

    constructor(public alerts: Alert[] = [], public defaultTimeout: number = 5000) {}

    public update(alerts: Alert[]): void {
        this.alerts = alerts;
        this.dispatch();
    }

    public dispatch(): void {
        this.observers.forEach((observer) => observer(this.alerts, this.alerts));
    }

    public push(alert: Alert): void {
        alert.appearTime = Date.now();
        alert.dismissable = alert.dismissable !== false;
        alert.active = true;
        this.update([...this.alerts, alert]);
        if (alert.timeout !== false) {
            const timeout = typeof alert.timeout === "number" ? alert.timeout : this.defaultTimeout;
            setTimeout(() => {
                this.discard(alert);
            }, timeout);
        }
    }

    public find(callback: (agr: Alert) => boolean): Alert | undefined {
        return this.alerts.find(callback);
    }

    public discard(alert: Alert): boolean {
        if (!alert.active || this.alerts.indexOf(alert) < 0) {
            return false;
        }
        // use a soft discard to allow GUI to animate removal
        alert.active = false;
        setTimeout(() => {
            this.update(this.alerts.filter((val: Alert) => val !== alert));
        }, this.hardDiscardTimeout);
        this.update(this.alerts.slice(0));
        return true;
    }

    public observe(observer: AlertObserver): () => void {
        this.observers.push(observer);
        observer(this.alerts);
        return () => {
            this.observers = this.observers.filter((o) => o !== observer);
        };
    }

    public readonly callback: AlertCallback = (alert: Alert) => {
        this.push(alert);
    };
}
