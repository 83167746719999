import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";

import { CurrentLocale } from "../app.types";
import { InputSpecAuto } from "../form-specs";
import { getTabLinkOptions } from "../plugins/resultsPanel/resultsPanelUtil";
import namespaceList from "../i18n/namespaceList";

function tabLinkAutocomplete<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecAuto<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "tabLink"),
        type: "autocomplete",
        options: async (item, spec, root, tabLocale, initialItem, currentLocale: CurrentLocale): Promise<any[]> => {
            const tabLinkOptions = getTabLinkOptions(initialItem, currentLocale);
            return tabLinkOptions;
        },
    };
}

export default tabLinkAutocomplete;
