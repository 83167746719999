import * as classNames from "classnames";

import { LocalizedContentBase, Site } from "@maxxton/cms-api";
import { SomeInputSpec, localized } from "../../form-specs";

import { CurrentLocale } from "../../app.types";
import { fontColorSelector } from "../../utils/font.util";
import { getI18nLocaleObject } from "../../i18n";
import { getLocalizedContent } from "../../utils/localizedContent.util";
import namespaceList from "../../i18n/namespaceList";

export interface LocalizedTitleOptions extends TitleOptions, TitleStylingOptions {}
export interface LocalizedLabelOptions extends LabelOptions, LabelStylingOptions {}

interface LabelOptions {
    enableWidgetLabel?: boolean;
    localizedWidgetLabel?: WidgetLabel[];
}

interface LabelStylingOptions {
    styleWidgetLabel?: boolean;
    labelHeadingType?: LabelHeading;
    fontColor?: string;
}
interface TitleOptions {
    enableWidgetTitle?: boolean;
    localizedWidgetTitle?: WidgetTitle[];
}

interface TitleStylingOptions {
    styleWidgetTitle?: boolean;
    useTitleHeadings?: TitleHeading;
    fontColor?: string;
}

export interface WidgetTitle extends LocalizedContentBase {
    widgetTitleText: string;
}

export type TitleHeading = "p" | "h6" | "h5" | "h4" | "h3" | "h2" | "h1";

export const getWidgetTitleOptions = <T extends TitleOptions>(): Array<SomeInputSpec<T, keyof T>> => [
    {
        variable: "enableWidgetTitle",
        label: getI18nLocaleObject(namespaceList.admin, "enableWidgetTitle"),
        type: "checkbox",
    },
    localized({
        variable: "localizedWidgetTitle",
        tabContent: [
            {
                variable: "widgetTitleText",
                label: getI18nLocaleObject(namespaceList.admin, "widgetTitleText"),
                type: "text",
                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForTitle"),
            },
        ],
        visible: (options: T) => !!options.enableWidgetTitle,
    }),
];

export interface WidgetLabel extends LocalizedContentBase {
    widgetLabelText: string;
}

export type LabelHeading = "h6" | "h5" | "h4" | "h3" | "h2" | "h1";

export const getWidgetLabelOptions = <T extends LabelOptions>(): Array<SomeInputSpec<T, keyof T>> => [
    {
        variable: "enableWidgetLabel",
        label: getI18nLocaleObject(namespaceList.admin, "enableWidgetLabel"),
        type: "checkbox",
    },
    localized({
        variable: "localizedWidgetLabel",
        tabContent: [
            {
                variable: "widgetLabelText",
                label: getI18nLocaleObject(namespaceList.admin, "widgetLabelText"),
                type: "text",
                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForLabel"),
            },
        ],
        visible: (options: T) => !!options.enableWidgetLabel,
    }),
];

export const labelStylingOptions = <T extends LocalizedLabelOptions>({ LabelFontColorVariable = "fontColor" }: { LabelFontColorVariable?: string } = {}): Array<SomeInputSpec<T, keyof T>> => [
    {
        variable: "styleWidgetLabel",
        label: getI18nLocaleObject(namespaceList.admin, "styleWidgetLabel"),
        type: "checkbox",
        default: false,
        visible: (options: T) => !!options.enableWidgetLabel,
    },
    {
        variable: "labelHeadingType",
        label: getI18nLocaleObject(namespaceList.admin, "labelHeadingType"),
        type: "select",
        default: "widget-label" as LabelHeading,
        visible: (options: T) => !!(options.enableWidgetLabel && options.styleWidgetLabel),
        optionList: [
            {
                value: "widget-label",
                label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
            },
            {
                value: "micro-font-size",
                label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
            },
            {
                value: "xx-small-font-size",
                label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
            },
            {
                value: "x-small-font-size",
                label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
            },
            {
                value: "small-font-size",
                label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
            },
            {
                value: "h6",
                label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
            },
            {
                value: "h5",
                label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
            },
            {
                value: "h4",
                label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
            },
            {
                value: "h3",
                label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
            },
            {
                value: "h2",
                label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
            },
            {
                value: "h1",
                label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
            },
        ],
    },
    {
        ...fontColorSelector(LabelFontColorVariable),
        visible: (options: T) => !!(options.enableWidgetLabel && options.styleWidgetLabel),
    },
];

export const titleStylingOptions = <T extends LocalizedTitleOptions>({ titleFontColorVariable = "fontColor" }: { titleFontColorVariable?: string } = {}): Array<SomeInputSpec<T, keyof T>> => [
    {
        variable: "styleWidgetTitle",
        label: getI18nLocaleObject(namespaceList.admin, "styleWidgetTitle"),
        type: "checkbox",
        default: false,
        visible: (options: T) => !!options.enableWidgetTitle,
    },
    {
        variable: "useTitleHeadings",
        label: getI18nLocaleObject(namespaceList.admin, "useTitleHeadings"),
        type: "select",
        default: "p" as TitleHeading,
        visible: (options: T) => !!(options.enableWidgetTitle && options.styleWidgetTitle),
        optionList: [
            {
                value: "p",
                label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
            },
            {
                value: "h6",
                label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
            },
            {
                value: "h5",
                label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
            },
            {
                value: "h4",
                label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
            },
            {
                value: "h3",
                label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
            },
            {
                value: "h2",
                label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
            },
            {
                value: "h1",
                label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
            },
        ],
    },
    {
        ...fontColorSelector(titleFontColorVariable),
        visible: (options: T) => !!(options.enableWidgetTitle && options.styleWidgetTitle),
    },
];

export function getLabelOptions(options: LabelOptions & LabelStylingOptions & { textLabelColor?: string }, site: Site, currentLocale: CurrentLocale) {
    const localizedWidgetLabel: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetLabel || [], keys: ["widgetLabelText"] })?.widgetLabelText || "";
    return {
        localizedLabel: localizedWidgetLabel,
        enableWidgetLabel: options.enableWidgetLabel,
        labelHeadingType: options.labelHeadingType,
        styleWidgetLabel: options.styleWidgetLabel,
        classNameLabel: classNames(`${options.textLabelColor?.includes("theme") ? `color-${options.textLabelColor}` : ""}`),
        styleLabel: options.textLabelColor?.includes("rgba") ? options.textLabelColor : "",
    };
}
