import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as moment from "moment";

import { AVAILABILITY_CONSTANTS, DATE_FORMAT, MXTS as MXTS_CONSTANTS } from "../../utils/constants";
import { Card, Collapse, Input, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { CmsApi, CmsApiWrapper, Locale, WithId } from "@maxxton/cms-api";
import { CustomUnit, DateMap } from "./mxts.types";
import { createDateMap, getStayPeriodDefs, stayPeriodDefById } from "./mxts.util";
import { getAvailability, getBaseFilter } from "../../utils/availability.util";
import { getElasticSubjectFilter, getPetCapacity } from "../../utils/searchFacet.utils";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { ApiContext } from "../../containers/cmsProvider.types";
import { DayPickerSingleDateControllerWrapper } from "../../components/datepicker/DayPickerSingleDateController/DayPickerSingleDateControllerWrapper";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { FormSpec } from "../../form-specs";
import { LocalizedBookingIsRestrictedMsg } from "../dynamic/typesearchContainer";
import { MXTSPlugin } from "./mxtsWidgets";
import { NumberMultiSelectOption } from "./selectOption.types";
import { SelectOption } from "../../form-specs/formSpec.types";
import { SelectOptionLazyLoadResponse } from "../../components/generic-form/LazyLoadAutoComplete";
import { SpecialLegend } from "../../components/Legend";
import { getCMSOptions } from "../settings";
import { getLocaleCodeFromCMSOptions } from "../../utils/widget.util";
import { getUnitsWithInactiveContractField } from "../dynamic/owner/owner.util";
import { globalApiContext } from "../../containers/CmsProvider";
import { imageProvider } from "./imageProvider";
import { isClientSide } from "../../utils/generic.util";
import { loadPlugin } from "../";
import namespaceList from "../../i18n/namespaceList";
import { registerImageProvider } from "../../media";

type Moment = moment.Moment;

export const LOCAL_STORAGE_KEY = "searchFacet";
export const MAX_DURATION = 28;

const { NO_SPECIAL } = AVAILABILITY_CONSTANTS;

let mxtsConcern = "";

// eslint-disable-next-line max-lines-per-function
export function getArrivalDateStayPopup(
    accommodationType: AccommodationType | null,
    handleSetNoSpecial: (isSpecial: boolean) => boolean,
    handleArrivalDateModal: () => void,
    handleStartDateFocusChange: () => void,
    handleModalStartDateChange: (modalStartDate: Moment) => void,
    handleModalStayChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    modalStartDate: Moment | null,
    modalStayPeriodDefId: number | null,
    startDatefocus: boolean,
    bookLink: JSX.Element,
    dateFormat?: string,
    arrivalDateModalOpen?: boolean,
    isFetching?: boolean,
    modalAvailableStayPeriodDefs?: MXTS.StayPeriodDef[],
    modalAvailableArrivalDates?: DateMap,
    modalSpecialAvailableArrivalDates?: DateMap,
    modalAvailableSpecialStayPeriodDefs?: MXTS.StayPeriodDef[],
    isSpecialSelected?: boolean,
    handleSpecialClick?: () => void
): JSX.Element {
    const getEnv = async () => {
        const ops = await getAdminMxtsEnv();
        mxtsConcern = ops.env.concern;
        if (typeof localStorage !== "undefined") {
            localStorage.setItem("concern", mxtsConcern);
        }
    };

    getEnv();

    const handleDateChange = (date: Moment) => {
        handleStartDateFocusChange();
        handleModalStartDateChange(date);
    };

    const handleSpecial = () => {
        handleSetNoSpecial(false);
        if (typeof localStorage !== "undefined") {
            localStorage.setItem("activeTab", "2");
        }
    };

    const handleNoSpecial = () => {
        handleSetNoSpecial(true);
        if (typeof localStorage !== "undefined") {
            localStorage.setItem("activeTab", "1");
        }
    };
    let activeTab: string | null = "";

    if (typeof localStorage !== "undefined") {
        activeTab = localStorage.getItem("activeTab");
    }

    const tooltipRef = React.createRef<HTMLAnchorElement>();

    return (
        <Modal
            isOpen={arrivalDateModalOpen}
            toggle={handleArrivalDateModal}
            size="s"
            className={`modal-box--full modal-search-calendar single-date-calendar search-filters-expanded modal--stay-picker modal-special-${activeTab === "1" ? "deactiveTab " : "activeTab"}`}
        >
            <ModalHeader tag="h4" toggle={handleArrivalDateModal} className="font-brand">
                {getI18nLocaleString(namespaceList.widgetSearchfacet, "selectArrivalDate")}
            </ModalHeader>
            <ModalBody>
                {mxtsConcern === "ons" && accommodationType && accommodationType.specialName && (
                    <div className="form-group special-selection">
                        {accommodationType.specialName && <p>{getI18nLocaleString(namespaceList.widgetTypeSearch, "selectedSpecial")}</p>}
                        <ul className="nav nav-tabs">
                            <li className="nav-item" onClick={handleNoSpecial}>
                                <a className={"nav-link "}>{getI18nLocaleString(namespaceList.widgetTypeSearch, "withoutSpecialSelection")}</a>
                            </li>
                            <li className="nav-item" onClick={handleSpecial}>
                                <a className={"nav-link "} ref={tooltipRef}>
                                    {`${accommodationType.specialName} `}
                                    <FontAwesome name="question-circle-o" size="lg" />
                                </a>
                            </li>
                        </ul>
                        {accommodationType.specialDescription && (
                            <ErrorBoundary>
                                <UncontrolledTooltip autohide={false} placement="bottom" target={tooltipRef} className={"notification--tooltip"}>
                                    {accommodationType.specialDescription}
                                </UncontrolledTooltip>
                            </ErrorBoundary>
                        )}
                    </div>
                )}
                <div className="modal--stay-picker__toggle" color="primary" onClick={handleStartDateFocusChange}>
                    <FontAwesome className="space-mr-xs" name="calendar" />
                    {modalStartDate ? modalStartDate.format(dateFormat || DATE_FORMAT.DISPLAY) : getI18nLocaleString(namespaceList.widgetSearchfacet, "arrivalDate")}
                </div>
                <Collapse isOpen={startDatefocus}>
                    <Card>
                        <DayPickerSingleDateControllerWrapper
                            date={modalStartDate}
                            showBackdrop={false}
                            focused={true}
                            onDateChange={handleDateChange}
                            availableDates={modalAvailableArrivalDates}
                            specialAvailableDates={modalSpecialAvailableArrivalDates}
                            loading={isFetching}
                            showHighlightedDates={true}
                            showOnlySpecialDates={isSpecialSelected}
                        />
                    </Card>
                    {accommodationType?.specialId && <SpecialLegend isSelected={isSpecialSelected} onClick={handleSpecialClick} specialName={accommodationType.specialName} />}
                </Collapse>
                <div className="duration-select">
                    <Input className="stay-select" type="select" disabled={isFetching} value={modalStayPeriodDefId || "null"} onChange={handleModalStayChange}>
                        <option key={"null"} value={"null"}>
                            {getI18nLocaleString(namespaceList.widgetSearchfacet, "stay")}
                        </option>
                        {!isSpecialSelected &&
                            modalAvailableStayPeriodDefs?.map((stay: MXTS.StayPeriodDef) => (
                                <option key={stay.stayPeriodDefId} value={stay.stayPeriodDefId}>
                                    {stay.name +
                                        `${
                                            accommodationType?.specialName && modalAvailableSpecialStayPeriodDefs?.find((stayPeriodDef) => stayPeriodDef.stayPeriodDefId === stay.stayPeriodDefId)
                                                ? " (" + accommodationType.specialName + ")"
                                                : ""
                                        }`}
                                </option>
                            ))}
                        {isSpecialSelected &&
                            modalAvailableSpecialStayPeriodDefs?.map((stay: MXTS.StayPeriodDef) => (
                                <option key={stay.stayPeriodDefId} value={stay.stayPeriodDefId}>
                                    {stay.name + `${accommodationType?.specialName && " (" + accommodationType.specialName + ")"}`}
                                </option>
                            ))}
                    </Input>
                </div>
            </ModalBody>
            <ModalFooter className="filter-footer">{bookLink}</ModalFooter>
        </Modal>
    );
}

export interface ArrivalDateStayModel {
    arrivalDateMap?: DateMap;
    stayPeriodDefs?: MXTS.StayPeriodDef[];
    specialArrivalDateMap?: DateMap;
    specialStayPeriodDefs?: MXTS.StayPeriodDef[];
}

export interface ArrivalDepartureModel {
    arrivalDateMap?: DateMap;
    durations?: number[];
    specialArrivalDateMap?: DateMap;
    specialDurations?: number[];
}

export async function getArrivalDateStayModel(
    apiContext: ApiContext,
    apiOptions: MXTS.ApiCallOptions,
    filter: any,
    modalStartDate: Moment | null,
    modalStayPeriodDefId: number | null,
    stayPeriodDefs: MXTS.StayPeriodDef[],
    resourceId?: number,
    unitId?: number,
    specialCodes?: string[],
    isDynamic?: boolean
): Promise<ArrivalDateStayModel> {
    const aggregations: MXTS.Aggregation[] = [
        {
            name: "ARRIVAL_DATE_FACET",
            field: "ARRIVAL_DATE",
            type: "FACET",
            excludeFields: ["ARRIVAL_DATE", "DURATION"],
            size: 1000,
        },
        {
            name: "STAY_PERIOD_DEF_FACET",
            field: "STAY_PERIOD_DEF_ID",
            type: "FACET",
            excludeFields: ["STAY_PERIOD_DEF_ID", "DURATION"],
            size: 1000,
        },
    ];
    filter.aggregations = aggregations;
    if (modalStartDate) {
        filter.arrivalDate = modalStartDate.format(DATE_FORMAT.ELASTIC);
    }
    if (modalStayPeriodDefId) {
        filter.stayPeriodDefId = modalStayPeriodDefId;
    }
    if (resourceId) {
        filter.resourceId = resourceId;
    } else if (unitId) {
        filter.unitId = unitId;
    }
    let elasticSubjects: MXTS.ElasticSubject[] = [];
    let petCapacity: number | undefined;
    if (isDynamic && filter.subject && filter.subject.size > 0) {
        const subjects = await apiContext.mxtsApi
            .subjects(apiOptions, {
                types: ["PERSON", "PET"],
                endDate: moment().format("YYYY-MM-DD"),
                sort: "subjectOrder",
            })
            .then((res) => res.content);
        elasticSubjects = getElasticSubjectFilter(subjects, filter.subject);
        petCapacity = getPetCapacity(subjects, filter.subject);
    }
    const baseFilter = isDynamic
        ? getBaseFilter({
              options: filter,
              distributionChannelId: filter.distributionChannel!.distributionChannelId,
              elasticSubjects,
              petCapacity,
              fetchUnitsWithPrice: filter.shouldFetchUnitsWithPrice,
              env: apiOptions.env,
          })
        : undefined;
    if (baseFilter) {
        baseFilter.aggregations = aggregations;
        if (modalStartDate) {
            baseFilter.arrivalDate = modalStartDate.format(DATE_FORMAT.ELASTIC);
        }
        if (modalStayPeriodDefId) {
            baseFilter.stayPeriodDefId = modalStayPeriodDefId;
        }
        if (resourceId) {
            baseFilter.resourceId = resourceId;
        } else if (unitId) {
            baseFilter.unitId = unitId;
        }
    }
    let specialAvailability;
    const validFilter = baseFilter || filter;
    const availability = await getAvailability(apiContext, apiOptions, { ...validFilter, specialCodes: undefined });
    if (specialCodes && specialCodes[0] !== NO_SPECIAL) {
        specialAvailability = await getAvailability(apiContext, apiOptions, { ...validFilter, specialCodes });
    }
    const availableDatesArray: Moment[] | undefined = availability?.response.arrivalDates?.map((date) => moment(date).utc());
    const specialAvailableDatesArray = specialAvailability?.response.arrivalDates?.map((date) => moment(date).utc());
    return {
        arrivalDateMap: availableDatesArray && createDateMap(availableDatesArray),
        stayPeriodDefs: availability && getStayPeriodDefs(stayPeriodDefs, availability),
        specialArrivalDateMap: specialAvailableDatesArray && createDateMap(specialAvailableDatesArray),
        specialStayPeriodDefs: specialAvailability && getStayPeriodDefs(stayPeriodDefs, specialAvailability),
    };
}

export async function getArrivalDepartureModel(
    apiContext: ApiContext,
    apiOptions: MXTS.ApiCallOptions,
    dynamicFilter: DynamicFilter,
    resourceId?: number,
    unitId?: number,
    startDate?: string,
    specialCodes?: string[],
    onlyDurations = false
): Promise<ArrivalDepartureModel> {
    /* jscpd:ignore-start */
    const aggregations: MXTS.Aggregation[] = [];
    if (!onlyDurations) {
        aggregations.push({
            name: "ARRIVAL_DATE_FACET",
            field: "ARRIVAL_DATE",
            type: "FACET",
            excludeFields: ["ARRIVAL_DATE", "DURATION"],
            size: 1000,
        });
    }
    aggregations.push({
        name: "DURATION_FACET",
        field: "DURATION",
        type: "FACET",
        excludeFields: ["DURATION"],
        size: 1000,
    });
    let elasticSubjects: MXTS.ElasticSubject[] = [];
    let petCapacity: number | undefined;
    if (dynamicFilter.subject && dynamicFilter.subject.size > 0) {
        const subjects = await apiContext.mxtsApi
            .subjects(apiOptions, {
                types: ["PERSON", "PET"],
                endDate: moment().format("YYYY-MM-DD"),
                sort: "subjectOrder",
            })
            .then((res) => res.content);
        elasticSubjects = getElasticSubjectFilter(subjects, dynamicFilter.subject);
        petCapacity = getPetCapacity(subjects, dynamicFilter.subject);
    }
    /* jscpd:ignore-end */
    const filter = getBaseFilter({
        options: dynamicFilter,
        distributionChannelId: dynamicFilter.distributionChannel!.distributionChannelId,
        elasticSubjects,
        petCapacity,
        fetchUnitsWithPrice: dynamicFilter.shouldFetchUnitsWithPrice,
        env: apiOptions.env,
    });
    filter.aggregations = aggregations;
    if (resourceId) {
        filter.resourceId = resourceId;
    }
    if (unitId) {
        filter.unitId = unitId;
    }
    if (startDate) {
        filter.arrivalDate = startDate;
    }
    if ((dynamicFilter as any).specialCodes) {
        filter.specialCodes = (dynamicFilter as any).specialCodes;
    }
    let availabilityWithSpecial;
    const availability = await getAvailability(apiContext, apiOptions, { ...filter, specialCodes: undefined });
    if (specialCodes && specialCodes[0] !== NO_SPECIAL) {
        availabilityWithSpecial = await getAvailability(apiContext, apiOptions, { ...filter, specialCodes });
    }
    const availableDatesArray: Moment[] | undefined = availability?.response.arrivalDates?.map((date) => moment(date));
    const specialAvailableDatesArray = availabilityWithSpecial?.response.arrivalDates?.map((date) => moment(date));
    return {
        arrivalDateMap: availableDatesArray && createDateMap(availableDatesArray),
        durations: availability?.response.durations,
        specialArrivalDateMap: specialAvailableDatesArray && createDateMap(specialAvailableDatesArray),
        specialDurations: availabilityWithSpecial?.response.durations,
    };
}

export function getAmenityById(amenities: MXTS.Amenity[], amenityId: number): MXTS.Amenity | null {
    return amenities.find((amenity) => amenity.amenityId === amenityId) || null;
}

export function getAmenityByCode(amenities: MXTS.Amenity[] | undefined, code: string): MXTS.Amenity | null {
    return (amenities && amenities.find((amenity) => amenity.identifier === code)) || null;
}

export function getResortAmenities(amenityCategories: MXTS.AmenityCategory[], checkCode = true): MXTS.Amenity[] {
    if (checkCode) {
        return amenityCategories.find((cat: MXTS.AmenityCategory) => cat.code === "resort")!.amenities!;
    }
    const amenityList = [];
    for (const amenities of amenityCategories) {
        amenityList.push(...amenities.amenities!);
    }
    return amenityList;
}

export function lookupDateMap(dateMap: DateMap, date: Moment): boolean {
    return dateMap[date.format(DATE_FORMAT.DEFAULT)] !== undefined;
}

export interface PluginOptions {
    mxtsApiGateway: string;
    env: string;
}

export interface StoredFilter {
    startDate?: string | null;
    endDate?: string | null;
    stayPeriodDefId?: number | null;
    subjects?: MXTS.SubjectQuantity[];
}

export interface SearchLinkParams {
    startdate?: string;
    enddate?: string;
    stay?: string;
    resort?: string;
    objectid?: number;
    unitid?: number;
    resourceid?: number;
    accokindids?: number[];
    subject?: string[];
    amenity?: string;
    specialcode?: string;
    actiecode?: string;
    dc?: string;
    lan?: string;
    resortid?: number;
    resortids?: number[];
}

export const pluginOptionsForm: FormSpec<PluginOptions> = {
    id: "mxts-plugin-options",
    name: getI18nLocaleObject(namespaceList.pluginMxts, "mxtsOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginMxts, "mxtsOptions"),
    properties: [
        {
            variable: "env",
            default: "",
            label: getI18nLocaleObject(namespaceList.pluginMxts, "environment"),
            type: "select",
            optionList: () =>
                MXTS.environments().then((envs: string[]) =>
                    envs.map(
                        (env: string) =>
                            ({
                                value: env,
                                label: () => env,
                            } as any)
                    )
                ),
        },
    ],
};

export async function getMxtsOptions(cmsApi: CmsApiWrapper): Promise<PluginOptions> {
    const plugin = await loadPlugin(cmsApi, MXTSPlugin);
    return plugin.options as PluginOptions;
}

export type EnvType = "live" | "acc";

export interface Environment {
    concern: string;
    type: EnvType;
}

export async function getEnvironment(cmsApi: CmsApiWrapper): Promise<Environment | null> {
    const ops: PluginOptions = await getMxtsOptions(cmsApi);
    if (ops.env?.indexOf("-")) {
        const [concern, type] = ops.env.split("-");
        return { concern, type: type as EnvType };
    }
    return null;
}

export async function getMxtsEnv(context: ApiContext, locale?: string): Promise<MXTS.ApiCallOptions> {
    // cache this funciton as it is used in a lot of places
    const cacheKey = locale || "default";
    if (context?.mxtsEnv?.[cacheKey]) {
        return context?.mxtsEnv?.[cacheKey];
    }

    const ops: PluginOptions = await getMxtsOptions(context?.cmsApi);
    const env = ops.env;
    const [concern, type] = env ? env.split("-") : [];
    const actualLocale: (Locale & WithId) | null = (locale && (await context?.cmsApi.localeApi.findByCode({ code: locale }))) || null;
    let languageLocale: MXTS.LanguageLocale | undefined = getLanguageLocale(locale);
    if (!languageLocale) {
        if (actualLocale?.fallbackLocaleMultiSelect?.length) {
            const fallbackLocaleId: string = actualLocale.fallbackLocaleMultiSelect[0].value;
            const fallbackLocale: (Locale & WithId) | null = await context?.cmsApi.localeApi.findById({ id: fallbackLocaleId });
            languageLocale = getLanguageLocale(fallbackLocale?.code);
        }
    }
    const result = {
        retryCount: 3,
        env: {
            baseUrl: MXTS.baseUrl + `/${env}`,
            concern,
            type,
        },
        locale: languageLocale,
    };
    // cache result
    if (context) {
        if (!context.mxtsEnv) {
            context.mxtsEnv = {};
        }
        context.mxtsEnv[cacheKey] = result;
    }
    return result;
}

export async function getAdminMxtsEnv(): Promise<MXTS.ApiCallOptions> {
    const cmsOptions = isClientSide() ? (window as any).cmsOptions || (await getCMSOptions(CmsApi)) : await getCMSOptions(CmsApi);
    return getMxtsEnv(globalApiContext(), getLocaleCodeFromCMSOptions(cmsOptions));
}

export async function distributionChannelOptions(mxtsApi: MXTS.MxtsApiWrapper): Promise<Array<SelectOption<string>>> {
    const ops = await getAdminMxtsEnv();
    const dcs1 = await mxtsApi.distributionChannels(ops, { size: 999, page: 0 });
    const dcs2 = await mxtsApi.distributionChannels(ops, { size: 999, page: 1 });
    const dcs3 = await mxtsApi.distributionChannels(ops, { size: 999, page: 2 });
    const dcs = [...dcs1.content, ...dcs2.content, ...dcs3.content];
    return dcs
        .map(
            (dc: MXTS.DistributionChannel): SelectOption<string> => ({
                value: String(dc.distributionChannelId),
                label: `${dc.name} (${dc.code})`,
            })
        )
        .sort((dc1, dc2) => {
            const dc1Label = dc1.label as string;
            const dc2Label = dc2.label as string;
            if (dc1Label.toLocaleLowerCase() < dc2Label.toLocaleLowerCase()) {
                return -1;
            }
            if (dc1Label.toLocaleLowerCase() > dc2Label.toLocaleLowerCase()) {
                return 1;
            }
            return 0;
        });
}

export async function getDistributionChannelLazyLoadPage(
    page: number,
    searchQuery: string | undefined,
    ids: string[] | undefined,
    mxtsApi: MXTS.MxtsApiWrapper
): Promise<SelectOptionLazyLoadResponse<MXTS.DistributionChannel, string>> {
    const ops = await getAdminMxtsEnv();
    const distributionChannels: MXTS.PagedResult<MXTS.DistributionChannel> = await mxtsApi.distributionChannels(ops, { size: 30, page, searchQuery, sort: "name", ids: ids?.map((id) => +id) });

    return {
        selectOptions: distributionChannels.content.map(
            (dc: MXTS.DistributionChannel): SelectOption<string> => ({
                value: String(dc.distributionChannelId),
                label: `${dc.name} (${dc.code})`,
            })
        ),
        pagedResult: distributionChannels,
    };
}

export async function getUnitLazyLoadPage(params: {
    page: number;
    searchQuery: string | undefined;
    pageSize?: number;
    ids: string[] | undefined;
    mxtsApi: MXTS.MxtsApiWrapper;
    env: MXTS.ApiCallOptions;
    additionalUnitRequestParams?: MXTS.UnitRequest;
    showInactiveContractUnits?: boolean;
}): Promise<SelectOptionLazyLoadResponse<CustomUnit, string>> {
    const { pageSize, page, additionalUnitRequestParams, searchQuery, ids, mxtsApi, env, showInactiveContractUnits } = params;
    const units: MXTS.PagedResult<CustomUnit> = await mxtsApi.units(env, { ...(additionalUnitRequestParams || {}), size: pageSize || 20, page, search: searchQuery, unitIds: ids?.map((id) => +id) });
    let unitsWithInactiveField: MXTS.PagedResult<CustomUnit> = units;
    if (showInactiveContractUnits) {
        unitsWithInactiveField = await getUnitsWithInactiveContractField(env, units);
    }
    return {
        selectOptions: units.content.map(
            (unit: MXTS.Unit): SelectOption<string> => ({
                value: String(unit.unitId),
                label: `${unit.name} (${unit.code})`,
            })
        ),
        pagedResult: unitsWithInactiveField,
    };
}

export async function getMxtsTemplateLazyLoadPage(
    page: number,
    searchQuery: string | undefined,
    ids: string[] | undefined,
    mxtsApi: MXTS.MxtsApiWrapper
): Promise<SelectOptionLazyLoadResponse<MXTS.Template, string>> {
    const ops = await getAdminMxtsEnv();
    const templates: MXTS.PagedResult<MXTS.Template> = await mxtsApi.getTemplates(ops, { size: 30, page, searchQuery, sort: "name", ids: ids?.map((id) => +id) });

    return {
        selectOptions: templates.content.map(
            (template: MXTS.Template): SelectOption<string> => ({
                value: String(template.templateId),
                label: `${template.name}`,
            })
        ),
        pagedResult: templates,
    };
}

export async function getEmployeeLazyLoadPage(
    page: number,
    searchQuery: string | undefined,
    id: string | undefined,
    mxtsApi: MXTS.MxtsApiWrapper
): Promise<SelectOptionLazyLoadResponse<MXTS.Client, string>> {
    const ops = await getAdminMxtsEnv();
    const clients: MXTS.PagedResult<MXTS.Client> = await mxtsApi.clients(ops, { size: 30, page, searchQuery, sort: "lastName", clientId: id });

    return {
        selectOptions: clients.content.map(
            (client: MXTS.Client): SelectOption<string> => ({
                value: String(client.clientId),
                label: `${client.lastName || ""} ${client.firstName || ""} (${client.clientId})`,
            })
        ),
        pagedResult: clients,
    };
}

export async function rateTypeOptions(mxtsApi: MXTS.MxtsApiWrapper, localizedOptions: any[], locale: string): Promise<NumberMultiSelectOption[]> {
    const localeOption = localizedOptions.find((i) => i.locale === locale);
    if (!localeOption || !localeOption.distributionChannelId) {
        return [];
    }
    const ops = await getAdminMxtsEnv();
    const rateTypesFroCurrentDc = (await MXTS.getAll((page: number) => mxtsApi.rateTypesPerDC(ops, { dcId: localeOption.distributionChannelId, page, size: MXTS_CONSTANTS.MAX_RESULTS }))).content;
    const rateTypeIds = rateTypesFroCurrentDc.map((trdc) => trdc.rateTypeId);
    const rateTypes = (
        await mxtsApi.rates(ops, {
            rateTypeId: [...Array.from(new Set(rateTypeIds))],
        })
    ).content;
    return rateTypes.map(
        (rate: MXTS.RateTypes): NumberMultiSelectOption => ({
            value: rate.rateTypeId,
            text: rate.name,
        })
    );
}

export const getRateTypes = async (mxtsApi: MXTS.MxtsApiWrapper, rateTypeId: number | number[], apiCallOptions: MXTS.ApiCallOptions): Promise<MXTS.RateTypes[] | undefined> => {
    const rateTypes = await mxtsApi
        .rates(apiCallOptions, { rateTypeId })
        .then((rates) => rates?.content)
        .catch(() => undefined);
    return rateTypes;
};

export async function accoKindOptions(mxtsApi: MXTS.MxtsApiWrapper): Promise<NumberMultiSelectOption[]> {
    const ops = await getAdminMxtsEnv();
    const accoKinds = (await mxtsApi.accommodationkinds(ops, { size: 999, sort: "priority" })).content;
    return accoKinds.map(
        (accoKind: MXTS.AccoKind): NumberMultiSelectOption => ({
            value: accoKind.accommodationkindId,
            text: accoKind.name,
        })
    );
}

export async function getHolidayByCode(mxtsApi: MXTS.MxtsApiWrapper, ops: MXTS.ApiCallOptions, code?: string): Promise<MXTS.StayPeriodDef> {
    return (await mxtsApi.stayPeriodDefs(ops, { code, type: "holiday" }).then((stay) => stay.content))[0];
}

export function getDurationFromOptions(options: any): any {
    const duration =
        options.enddate && options.startdate
            ? moment.duration(moment(options.enddate, DATE_FORMAT.DEFAULT).utc().diff(moment(options.startdate, DATE_FORMAT.DEFAULT).utc())).asDays()
            : options.duration;
    return Math.round(duration);
}

export function retrieveFilter(options: any): any {
    const storedFilterStr = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!storedFilterStr) {
        return options;
    }
    const storedFilter: StoredFilter = JSON.parse(storedFilterStr);
    if (!storedFilter) {
        return options;
    }
    if (storedFilter.startDate) {
        const startDate = moment(storedFilter.startDate, DATE_FORMAT.DEFAULT);
        if (!startDate.isBefore(moment("day"))) {
            options.startdate = options.startdate || storedFilter.startDate;
        }
    }
    if (storedFilter.endDate) {
        const endDate = moment(storedFilter.endDate, DATE_FORMAT.DEFAULT);
        if (!endDate.isBefore(moment("day"))) {
            options.enddate = options.enddate || storedFilter.endDate;
        }
    }
    options.stayperioddefid = options.stayperioddefid || storedFilter.stayPeriodDefId;
    options.accokindids = convertToNumberArray(options.accokindids);
    if (options.subject === undefined && storedFilter.subjects && storedFilter.subjects.length > 0) {
        const subjects: string[] = [];
        storedFilter.subjects.map((subject: MXTS.SubjectQuantity) => {
            subjects.push(subject.subjectId + "," + subject.quantity);
        });
        options.subject = subjects;
    }
    return options;
}

export function convertToNumberArray(array: any[]): number[] | undefined {
    if (array && array instanceof Array) {
        return array.map((item: any) => parseInt(item, 10));
    } else if (array) {
        return [+array];
    }
}

export function accoKindById(accoKinds: MXTS.AccoKind[], id: number): MXTS.AccoKind | null {
    return accoKinds.find((accoKind: MXTS.AccoKind) => accoKind.accommodationkindId === id) || null;
}

export function resourceById(resources: MXTS.Resource[], id: number): MXTS.Resource | null {
    return (resources && resources.find((resource: MXTS.Resource) => resource.resourceId === id)) || null;
}

export function resortById(resorts: MXTS.Resort[], id: number): MXTS.Resort | null {
    return (resorts && resorts.find((resort: MXTS.Resort) => resort.resortId === id)) || null;
}

export function resortByCode(resorts: MXTS.Resort[], code: string): MXTS.Resort | null {
    return (resorts && resorts.find((resort: MXTS.Resort) => resort.code === code)) || null;
}

export function stayPeriodDefByCode(stayPeriodDefs: MXTS.StayPeriodDef[], code: string): MXTS.StayPeriodDef | null {
    return (stayPeriodDefs && stayPeriodDefs.find((stayPeriodDef: MXTS.StayPeriodDef) => stayPeriodDef.code === code)) || null;
}

export function addressByManagerId(addresses: MXTS.Address[], id: number): MXTS.Address | null {
    return (addresses && addresses.find((address: MXTS.Address) => address.managerId === id)) || null;
}

export function unitById(units: MXTS.Unit[], id: number): MXTS.Unit | null {
    return (units && units.find((unit: MXTS.Unit) => unit.unitId === id)) || null;
}

export interface Unit extends MXTS.Unit {
    unitId: number;
    resourceId: number;
    basePriceInclusive: number | null;
    unitBaseNightPriceInclusive: number | null;
    unitNightPriceInclusive: number | null;
    unitRating: number;
    nrOfUnitReviews: number;
    unitLocation: MXTS.LocationCoordinates;
    maxageCapacity: number;
    resortName?: string;
    city?: string;
    specialName?: string;
    specialDescription?: string;
    price?: number;
    accommodationkindName?: string;
    latitude?: number;
    longitude?: number;
    baseNightPriceInclusive: number | null;
    referencePriceInclusive?: number;
    totalCapacity?: number;
    accommodationkindId?: number;
    specialId?: number;
    resourceLocation?: { lat: number; lon: number };
    nightPriceInclusive?: number;
    specialCode?: string[];
    choosableOnInternet: boolean;
    averageNightPrice?: number;
    rateTypeId?: number;
    averageBaseNightPrice?: number;
    duration?: number[];
    date?: string;
    minDuration?: number;
    stayPeriodDefCode?: string;
    stayPeriodDefName?: string;
    arrivalDate?: string;
    departureDate?: string;
    address?: MXTS.Address;
    alternative?: string;
    priceInclusive?: number;
    unitBasePriceInclusive?: number;
    unitSpecialPriceInclusive?: number;
    stayPeriodDefId?: number[];
    capacityManagerId?: number;
}

export interface ResourceLocation {
    lat: number;
    lon: number;
}

export interface AccommodationType extends MXTS.Resource {
    resourceId: number;
    resortName: string;
    arrivalDate: string;
    departureDate?: string;
    duration: number[];
    minDuration?: number;
    maxDuration?: number;
    accommodationkindId: number;
    accommodationkindName: string;
    stayPeriodId: number[];
    stayPeriodDefId: number[];
    stayPeriodDefCode: string;
    stayPeriodDefName: string;
    basePrice: number;
    basePriceInclusive: number;
    referencePriceInclusive: number;
    price: number;
    priceInclusive: number;
    nightPrice?: number;
    nightPriceInclusive?: number;
    totalCapacity: number;
    specialId: number;
    specialCode: string[];
    specialPolicy: string;
    specialName: string;
    specialDescription: string;
    rateTypeId: number;
    resourceRating: number;
    numberOfReviews: number;
    city?: string;
    reservableUnits: number;
    units?: Unit[];
    resourceLocation?: ResourceLocation;
    baseNightPriceInclusive?: number;
    requestQuote?: boolean;
    address?: MXTS.Address;
    reservable?: boolean;
    alternative?: string;
    isBookingRestricted?: boolean;
    localizedBookingIsRestrictedMsg?: LocalizedBookingIsRestrictedMsg[];
    specials?: MXTS.Resource[];
    petCapacity?: number | null;
    qualityLevelId?: number | null;
    bookLink?: string;
}

export interface Resort extends MXTS.Resort {
    city?: string;
    resortLocation?: ResourceLocation;
    accommodationTypes?: MXTS.Document[];
    curatedAccommodation?: AccommodationType;
    address?: MXTS.Address;
    countryName?: string;
}

export type TypeSearchResult = AccommodationType[];

export interface AvailabilityRequest {
    resources: MXTS.Resource[];
    availabileResources: MXTS.AvailabilityResult;
    availability: MXTS.AvailabilityResult;
    resorts: MXTS.Resort[];
    accoKinds: MXTS.AccoKind[];
    stayPeriodDefs: MXTS.StayPeriodDef[];
    addresses: MXTS.Address[];
    units?: MXTS.Unit[];
    amenityCategories: MXTS.AmenityCategory[];
    subjects: MXTS.Subject[];
    selectedSubjects?: Map<number, number>;
    amenityIds?: number[];
    holiday?: MXTS.StayPeriodDef;
    regionId?: number;
}

export function getUnitsFromElasticDoc(elasticUnitDocs: MXTS.UnitDocument[], units: MXTS.Unit[]): Unit[] {
    return units.map((unit) => {
        const currentUnits = elasticUnitDocs.filter((u) => u.unitId === unit.unitId);
        const currentUnit: Unit = {
            ...currentUnits[0],
            ...unit,
        };
        return currentUnit;
    });
}

export function parseAvailability(availabilityRequest: AvailabilityRequest, parseUnits: boolean): TypeSearchResult {
    const types: MXTS.Document[] | undefined = availabilityRequest.availabileResources.response.resources;
    const typeSearchResult: TypeSearchResult = [];
    types!.map((document: MXTS.Document) => {
        const resource: MXTS.Resource | null = resourceById(availabilityRequest.resources, document.resourceId);
        if (resource) {
            const accommodationkind: MXTS.AccoKind | null = accoKindById(availabilityRequest.accoKinds, document.accommodationkindId);
            const resort: MXTS.Resort | null = resortById(availabilityRequest.resorts, document.resortId);
            const special: MXTS.Resource | null = document.specialId == null ? null : resourceById(availabilityRequest.resources, document.specialId);
            const stayPeriodDef: MXTS.StayPeriodDef | null = stayPeriodDefById(availabilityRequest.stayPeriodDefs, document.stayPeriodDefId);
            const address: MXTS.Address | null = resource == null || resort == null ? null : addressByManagerId(availabilityRequest.addresses, resort!.visitAddressManagerId);
            const accoType: AccommodationType = {
                ...resource!,
                ...document,
                units: parseUnits ? getUnitsFromElasticDoc(availabilityRequest.availability.response.units || [], availabilityRequest.units!) : [],
                resortName: resort ? resort.name : "",
                accommodationkindName: accommodationkind ? accommodationkind.name : "",
                specialName: special == null ? "" : special.name,
                specialDescription: special == null ? "" : special.description,
                stayPeriodDefName: stayPeriodDef ? stayPeriodDef.name : "",
                stayPeriodDefCode: stayPeriodDef ? stayPeriodDef.code : "",
                city: (resource as any).city || (address == null ? "" : address.city),
            };
            typeSearchResult.push(accoType);
        }
    });
    return typeSearchResult;
}

export function getLanguageLocale(locale?: string): MXTS.LanguageLocale | undefined {
    switch (locale) {
        case "en":
            return "en_EN";
        case "nl":
            return "nl_NL";
        case "de":
            return "de_DE";
        case "fr":
            return "fr_FR";
        case "it":
            return "it_IT";
        case "da":
            return "da_DK";
        case "hu":
            return "hu_HU";
        case "pl":
            return "pl_PL";
        case "es":
            return "es_ES";
        case "ga":
            return "ga_IE";
        default:
            return undefined;
    }
}

registerImageProvider(imageProvider);

export async function getAmenityIdsFromCodes(mxtsApi: MXTS.MxtsApiWrapper, amenityCodes: string | string[], ops: MXTS.ApiCallOptions): Promise<number[]> {
    const amenities: MXTS.Amenity[] = await mxtsApi
        .amenities(ops, {
            identifier: amenityCodes,
        })
        .then((am) => am.content);
    return amenities.map((amenity: MXTS.Amenity) => amenity.amenityId);
}

export function initialVisibleMonthThunk(availableDates: DateMap | undefined, startDate: moment.Moment | null): () => Moment {
    let availableDatesArray: Moment[];
    if (startDate) {
        return () => startDate;
    } else if (availableDates) {
        availableDatesArray = Object.keys(availableDates).map((i) => availableDates[i]);
        return () => moment.min(...availableDatesArray);
    }
    return () => moment();
}
