import * as moment from "moment";

import { call, getContext, put, select, takeEvery } from "redux-saga/effects";

import { ActionType } from "../redux/actions";
import { ApiContext } from "../containers/cmsProvider.types";
import { AvailabilityActionType } from "../redux/actions/availabilityAction";
import { AvailabilityUtil } from "../utils/availability.util";
import { ContextFields } from "../redux";
import { FilterChangeAction } from "../redux/actions/dynamicFilterAction.types";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { SagaUtils } from "./SagaUtils";
import { StringUtil } from "../utils/string.util";
import { dynamicFilterType } from "../redux/reducers/dynamicFilter.enum";
import { getMxtsEnv } from "../plugins/mxts";
import { shouldGetAvailability } from "../components/utils";

let lastStartedTaskUuid: string;

function* updateFilter(action: FilterChangeAction) {
    yield put({
        type: ActionType.DynamicFilter,
        filter: action.filter,
        payload: action.payload,
    });
}

function* getAvailability(action: FilterChangeAction) {
    yield put({
        type: ActionType.DynamicFilter,
        filter: action.filter,
        payload: action.payload,
    });
    if (shouldGetAvailability(action)) {
        const taskUuid = StringUtil.generateUUID();
        lastStartedTaskUuid = taskUuid;
        try {
            yield put({ actionType: AvailabilityActionType.fetching, type: ActionType.Availability });
            const apiContext: ApiContext = yield getContext(ContextFields.apiContext);
            const dynamicFilter = yield select(SagaUtils.getDynamicFilter);
            const env = yield call(getMxtsEnv, apiContext, dynamicFilter.currentLocale);
            let duration;
            if (dynamicFilter.stayperioddefid) {
                const stays = yield call(MxtsApi.stayPeriods as any, env, { stayPeriodDefIds: [dynamicFilter.stayperioddefid], startDate: moment().format("YYYY-MM-DD") });
                const stayPeriod = stays.content[0];
                if (stayPeriod) {
                    duration = stayPeriod.duration;
                }
                yield put({
                    filter: dynamicFilterType.addDerivedEndDate,
                    type: ActionType.FilterChange,
                    payload: { duration },
                });
            }
            const availabilityResponse = dynamicFilter.shouldFetchAvailability ? yield call(AvailabilityUtil.getAvailabilityByDynamicFilter, dynamicFilter, undefined, apiContext) : {};
            // If this is not the latest availability call then do nothing. Because we don't want to override the data that a newer call might have already emitted.
            // (I tried implementing this using saga cancel but for some reason the cancel(task) didn't have any impact... )
            if (lastStartedTaskUuid === taskUuid) {
                yield put({
                    actionType: AvailabilityActionType.fetched,
                    type: ActionType.Availability,
                    payload: {
                        availabilityResult: availabilityResponse.availabilityResult,
                        env,
                        availabilityRequest: availabilityResponse.availabilityRequest,
                    },
                });
            }
        } catch (error) {
            if (lastStartedTaskUuid === taskUuid) {
                yield put({ actionType: AvailabilityActionType.error, type: ActionType.Availability, payload: { error } });
            }
        }
    }
}

export function* watchGetAvailability() {
    yield takeEvery(ActionType.FilterChangeInit, updateFilter);
    yield takeEvery(ActionType.FilterChange, getAvailability);
}
