import { PriceMatrixAction, PriceMatrixActionType } from "../actions/priceMatrixAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface PriceMatrixState {
    isMatrixFetched?: boolean;
    fetchedPriceMatrixResourceId?: number;
}

export const priceMatrixReducer: Reducer<PriceMatrixState> = (state: PriceMatrixState = {}, action: PriceMatrixAction): PriceMatrixState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.priceMatrix:
            switch (actionType) {
                case PriceMatrixActionType.SHOW_MATRIX_WIDGET:
                    return {
                        ...state,
                        isMatrixFetched: action.payload.isMatrixFetched,
                        fetchedPriceMatrixResourceId: action.payload.fetchedPriceMatrixResourceId,
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
};
