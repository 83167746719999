import { ElasticSubject, Subject } from "@maxxton/cms-mxts-api";

export function getPetCapacity(subjects: Subject[], selectedSubjects: Map<number, number>): number | undefined {
    let petCapacity: number | undefined;
    subjects.forEach((subject: Subject) => {
        if (subject.type === "PET") {
            petCapacity = selectedSubjects.get(subject.subjectId);
            return;
        }
    });
    return petCapacity;
}

export function getElasticSubjectFilter(subjects: Subject[], selectedSubjects: Map<number, number>): ElasticSubject[] {
    const elasticSubjects: ElasticSubject[] = [];
    subjects.forEach((subject: Subject) => {
        const quantity = selectedSubjects.get(subject.subjectId);
        if (quantity && subject.type !== "PET") {
            elasticSubjects.push({
                age: subject.maxAge ? subject.maxAge.toString() : "max",
                quantity,
            });
        }
    });
    return elasticSubjects;
}
