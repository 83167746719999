import { Theme } from ".";
import baseTheme from "./base";

const theme: Theme = {
    id: "myenvironment",
    name: "My Environment Theme",
    stylesheets: ["/styles/themes/myenvironment/myenvironment-admin.css"],
    frontendStylesheets: ["/styles/themes/myenvironment/myenvironment.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "stick-to-top",
            name: "Sticky to top",
            targets: ["container", "menu"],
            classnames: ["sticky-to-top"],
        },
    ],
};

export default theme;
