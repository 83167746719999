import * as React from "react";
import * as moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import { AmenitiesStateHandler } from "./Amenities";
import { AmenityLink } from "@maxxton/cms-mxts-api";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { SingleDatePicker } from "react-dates";
import { State } from "../../../redux";
import { handleDateChange } from "./amenitiesEditor.util";

export function AmenityDatePicker(props: {
    amenity: AmenityLink;
    dateType: string;
    placeholder: string;
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    context: CMSProviderProperties;
    setIsAmenityDatesUpdating: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setIsAmenityDateUpdated: React.Dispatch<React.SetStateAction<"Failed" | "Success" | undefined>>;
    setSelectedAmenityForDateUpdate: React.Dispatch<React.SetStateAction<number | undefined>>;
    amenitiesStates: AmenitiesStateHandler;
}) {
    const { amenity, dateType, childOptions, context, placeholder, setIsAmenityDateUpdated, setIsAmenityDatesUpdating, setSelectedAmenityForDateUpdate, amenitiesStates } = props;
    const [date, setDate] = React.useState<moment.Moment | null>(null);
    const [focus, setFocus] = React.useState(false);
    const [updatedStartDate, setUpdatedStartDate] = React.useState<moment.Moment | null>(null);
    const [updatedEndDate, setUpdatedEndDate] = React.useState<moment.Moment | null>(null);
    const myEnvState: MyEnvState = useSelector((state: State) => state.myEnvState);
    const dispatchAction = useDispatch();

    React.useEffect(() => {
        if (dateType === "start") {
            const startDate = amenity.startDate ? moment(amenity.startDate) : null;
            setDate(startDate);
            if (childOptions?.askForApprovalByEmail && updatedStartDate) {
                setDate(updatedStartDate);
            }
        } else {
            const endDate = amenity.endDate ? moment(amenity.endDate) : null;
            setDate(endDate);
            if (childOptions?.askForApprovalByEmail && updatedEndDate) {
                setDate(updatedEndDate);
            }
        }
    }, [amenity.endDate, amenity.startDate, updatedStartDate, updatedEndDate]);

    return (
        <div className={focus ? "focused SingleDatePicker_wrap" : "SingleDatePicker_wrap"}>
            <SingleDatePicker
                id={`${dateType}-${amenity.amenityLinkId}`}
                date={date}
                placeholder={placeholder}
                onDateChange={(date) =>
                    handleDateChange({
                        date,
                        amenityLinkId: amenity.amenityLinkId,
                        dateType,
                        childOptions,
                        context,
                        setIsAmenityDatesUpdating,
                        setIsAmenityDateUpdated,
                        setSelectedAmenityForDateUpdate,
                        setUpdatedEndDate,
                        setUpdatedStartDate,
                        amenitiesStates,
                        dispatchAction,
                        myEnvState,
                    })
                }
                focused={focus}
                onFocusChange={({ focused }) => setFocus(focused)}
                numberOfMonths={1}
                displayFormat={DATE_FORMAT.DATE_WITH_SHORT_MONTH}
                showClearDate={false}
                isOutsideRange={(date) => isOutsideRange({ date, dateType, amenity, myEnvState, childOptions })}
                hideKeyboardShortcutsPanel={true}
            />
        </div>
    );
}

const isOutsideRange = (props: { date: moment.Moment; dateType: string; amenity: AmenityLink; myEnvState: MyEnvState; childOptions: DynamicMyEnvContainerChildOptions | undefined }) => {
    const { date, dateType, amenity, myEnvState, childOptions } = props;
    const { ownerState } = myEnvState;
    const currentDate = moment();
    if (dateType === "start") {
        const endDate = amenity.endDate ? moment(amenity.endDate) : null;
        if (endDate) {
            return (endDate || ownerState?.amenityUpdatedEndDate) <= date || date <= currentDate;
        }
        if (childOptions?.askForApprovalByEmail && !endDate && ownerState?.amenityUpdatedEndDate) {
            return ownerState?.amenityUpdatedEndDate <= date || date <= currentDate;
        }
    } else {
        const startDate = amenity.startDate ? moment(amenity.startDate) : null;
        if (startDate) {
            return date <= (startDate || ownerState?.amenityUpdatedStartDate);
        }
        if (childOptions?.askForApprovalByEmail && !startDate && ownerState?.amenityUpdatedStartDate) {
            return date <= ownerState?.amenityUpdatedStartDate;
        }
    }
    return date <= currentDate;
};
