import { SearchAction, SearchActionType } from "../actions/SearchEventAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface SearchEventState {
    searchResult?: any;
}

// eslint-disable-next-line max-len
export const searchEventReducer: Reducer<SearchEventState> = (state: SearchEventState = {}, action: SearchAction): SearchEventState => {
    switch (action.type) {
        case ActionType.SearchEvent:
            if (action.actionType === SearchActionType.keyEvent) {
                return { searchResult: action.payload };
            }
        default:
            return state;
    }
};
