import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../redux/actions";
import { CMSProvidedProperties } from "../containers/cmsProvider.types";
import { LoadReservationReduxDataAction } from "../redux/actions/reservationAction";
import { State } from "../redux";

export function PageCustomDataLoader(props: { context: CMSProvidedProperties }) {
    const { context } = props;
    const dispatchAction = useDispatch();
    const dynamicFilter = useSelector((state: State) => state.dynamicFilter);

    React.useEffect(() => {
        if (dynamicFilter.reservationId && !dynamicFilter.resourceid && !dynamicFilter.resourceids?.length && !dynamicFilter.resourceActivityDetailsIds?.length && !dynamicFilter.resortActivityId) {
            (dispatchAction as React.Dispatch<LoadReservationReduxDataAction>)({ type: ActionType.LoadReservationReduxData, payload: { apiContext: context } } as LoadReservationReduxDataAction);
        }
    }, [dynamicFilter.reservationId]);

    return null;
}
