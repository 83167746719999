import {
    SelectedAddOnsAction,
    SelectedAddOnsActionType,
    SyncTempSelectedAddOnsAction,
    SyncTempSelectedAddOnsActionType,
    TempSelectedAddOnsAction,
    TempSelectedAddOnsActionType,
} from "../../actions/add-ons/selectedAddOnsAction";

import { AddOnSubject } from "../../../plugins/dynamic/add-ons/add-on/info-modal/modal-types/modalData.util";
import { DisplayType } from "../../../plugins/dynamic/add-ons/add-on/addOn.util";
import { Reducer } from "redux";
import { ResourceType } from "@maxxton/cms-mxts-api";
import { isEqual } from "../../../components/utils";

export interface SelectedAddOnsState {
    addOnCarts: SelectedAddOnsCart[];
    tempAddOnCarts: SelectedAddOnsCart[];
}

export interface SelectedAddOnsCart {
    // When the cartReservedResourceId is undefined it will mean the selectedAddOns inside that cart are not linked to a reservation (yet).
    // This occurs inside the bm for example. Inside the bm you are selecting addons but you don't have a reservation yet.
    // So all the addOns you add there will end up inside the "cartReservedResourceId: undefined" cart
    cartReservedResourceId: number | undefined;
    selectedAddOns: SelectedAddOn[];
}

/**
 * Stores the confirmed add-ons and their quantities.
 */
export interface SelectedAddOn {
    dateSubjectSelections?: DateSubjectSelection[];
    dates?: Date[];
    displayType: DisplayType;
    name: string;
    quantity?: number;
    resourceId: number;
    subjects?: AddOnSubject[];
    overridePrice?: number;
    extraCancelPremium?: boolean;
    addOnType?: string | null;
    resourceType?: ResourceType;
}

export interface DateSubjectSelection {
    date: Date;
    subjects: AddOnSubject[];
}

export const initialSelectedAddOnsState: SelectedAddOnsState = {
    addOnCarts: [{ cartReservedResourceId: undefined, selectedAddOns: [] }],
    tempAddOnCarts: [{ cartReservedResourceId: undefined, selectedAddOns: [] }],
};

export const selectedAddOnsReducer: Reducer<SelectedAddOnsState> = (
    state: SelectedAddOnsState = initialSelectedAddOnsState,
    action: SelectedAddOnsAction | TempSelectedAddOnsAction | SyncTempSelectedAddOnsAction
): SelectedAddOnsState => {
    switch (action.actionType) {
        case SelectedAddOnsActionType.UPDATE_SELECTED_ADD_ONS: {
            return {
                ...state,
                addOnCarts: action.payload?.updatedAddOn
                    ? updateAddOnInCarts({ state, updatedAddOn: action.payload.updatedAddOn, cartReservedResourceId: action.cartReservedResourceId })
                    : state.addOnCarts,
                tempAddOnCarts: action.payload?.updatedAddOn
                    ? updateAddOnInCarts({ state, updatedAddOn: action.payload.updatedAddOn, cartReservedResourceId: action.cartReservedResourceId, useTempCarts: true })
                    : state.tempAddOnCarts,
            };
        }
        case TempSelectedAddOnsActionType.UPDATE_TEMP_SELECTED_ADD_ONS: {
            return {
                ...state,
                tempAddOnCarts: action.payload?.updatedAddOn
                    ? updateAddOnInCarts({ state, updatedAddOn: action.payload.updatedAddOn, cartReservedResourceId: action.cartReservedResourceId, useTempCarts: true })
                    : state.tempAddOnCarts,
            };
        }
        case SelectedAddOnsActionType.CLEAR_SELECTED_ADD_ONS: {
            return {
                ...state,
                addOnCarts: state.addOnCarts.map((cart) =>
                    cart.cartReservedResourceId !== action.cartReservedResourceId ? cart : { cartReservedResourceId: action.cartReservedResourceId, selectedAddOns: [] }
                ),
                tempAddOnCarts: state.tempAddOnCarts.map((cart) =>
                    cart.cartReservedResourceId !== action.cartReservedResourceId ? cart : { cartReservedResourceId: action.cartReservedResourceId, selectedAddOns: [] }
                ),
            };
        }
        case SyncTempSelectedAddOnsActionType.SYNC_TEMP_SELECTED_ADD_ONS: {
            return {
                ...state,
                tempAddOnCarts: syncAddOnInCarts(state, action.payload, action.cartReservedResourceId),
            };
        }
        case SelectedAddOnsActionType.RESET_SELECTED_ADD_ONS: {
            return {
                ...state,
                addOnCarts: [{ cartReservedResourceId: undefined, selectedAddOns: [] }],
                tempAddOnCarts: [{ cartReservedResourceId: undefined, selectedAddOns: [] }],
            };
        }
        default:
            return state;
    }
};

const updateAddOnInCarts = ({
    state,
    updatedAddOn,
    cartReservedResourceId,
    useTempCarts,
}: {
    state: SelectedAddOnsState;
    updatedAddOn: SelectedAddOn;
    cartReservedResourceId?: number;
    useTempCarts?: boolean;
}): SelectedAddOnsCart[] => {
    let carts = useTempCarts ? state.tempAddOnCarts : state.addOnCarts;

    const targetCart: SelectedAddOnsCart | undefined = carts.find((cart) => cart.cartReservedResourceId === cartReservedResourceId);
    const selectedAddOn: SelectedAddOn | undefined = targetCart?.selectedAddOns.find((addOn: SelectedAddOn) => addOn.resourceId === updatedAddOn.resourceId);

    if (targetCart) {
        if (shouldAddAddOn({ updatedAddOn, selectedAddOn })) {
            targetCart.selectedAddOns = [...targetCart.selectedAddOns, updatedAddOn];
        } else if (shouldRemoveAddOn({ updatedAddOn, selectedAddOn })) {
            targetCart.selectedAddOns = targetCart.selectedAddOns.filter((addOn: SelectedAddOn) => addOn.resourceId !== updatedAddOn.resourceId);
        } else if (shouldUpdateAddOn({ updatedAddOn, selectedAddOn })) {
            targetCart.selectedAddOns = targetCart.selectedAddOns.map((addOn: SelectedAddOn) => (addOn.resourceId === updatedAddOn.resourceId ? updatedAddOn : addOn));
        }
    } else if (shouldAddAddOn({ updatedAddOn, selectedAddOn })) {
        carts = [...carts, { cartReservedResourceId, selectedAddOns: [updatedAddOn] }];
    }

    return carts;
};

/**
 * Determine whether the add-on in the payload should be added to the cart.
 * This should only be done when the add-on does not yet exist in the cart and the add-on in the payload has a positive selection.
 */
export const shouldAddAddOn = ({ updatedAddOn, selectedAddOn }: { updatedAddOn: SelectedAddOn; selectedAddOn?: SelectedAddOn }): boolean => {
    if (!selectedAddOn) {
        switch (updatedAddOn.displayType) {
            case DisplayType.SIMPLE:
            case DisplayType.PACKAGE:
                return !!updatedAddOn.quantity;
            case DisplayType.DATE_RANGE:
            case DisplayType.SUPPLIER_ADDON:
            case DisplayType.DATE_SELECT:
                return !!(updatedAddOn.quantity && updatedAddOn.dates?.length);
            case DisplayType.SUBJECT:
                return !!updatedAddOn.subjects?.length;
            case DisplayType.DATE_SELECT_SUBJECT:
                return !!updatedAddOn.dateSubjectSelections?.length;
        }
    }

    return false;
};

/**
 * Determine whether the add-on in the payload should be removed from the cart.
 * This should only be done when the add-on already exists in the cart and the add-on in the payload has no selection.
 */
export const shouldRemoveAddOn = ({ updatedAddOn, selectedAddOn }: { updatedAddOn: SelectedAddOn; selectedAddOn?: SelectedAddOn }): boolean => {
    if (selectedAddOn) {
        switch (updatedAddOn.displayType) {
            case DisplayType.SIMPLE:
            case DisplayType.PACKAGE:
                return !updatedAddOn.quantity;
            case DisplayType.DATE_RANGE:
            case DisplayType.SUPPLIER_ADDON:
            case DisplayType.DATE_SELECT:
                return !(updatedAddOn.quantity && updatedAddOn.dates?.length);
            case DisplayType.SUBJECT:
                return !updatedAddOn.subjects?.length;
            case DisplayType.DATE_SELECT_SUBJECT:
                return !updatedAddOn.dateSubjectSelections?.length;
        }
    }

    return false;
};

/**
 * Determine whether the add-on in the cart should be updated.
 * This should only be done when the add-on already exists in the cart and the add-on in the payload has a positive selection, different than the existing value.
 */
export const shouldUpdateAddOn = ({ updatedAddOn, selectedAddOn }: { updatedAddOn: SelectedAddOn; selectedAddOn?: SelectedAddOn }): boolean => {
    if (selectedAddOn) {
        switch (updatedAddOn.displayType) {
            case DisplayType.SIMPLE:
            case DisplayType.PACKAGE:
                return !!updatedAddOn.quantity && updatedAddOn.quantity !== selectedAddOn.quantity;
            case DisplayType.DATE_RANGE:
            case DisplayType.SUPPLIER_ADDON:
            case DisplayType.DATE_SELECT:
                return !!(updatedAddOn.quantity && updatedAddOn.dates?.length) && (updatedAddOn.quantity !== selectedAddOn.quantity || !isEqual(updatedAddOn.dates, selectedAddOn.dates));
            case DisplayType.SUBJECT:
                return !!updatedAddOn.subjects?.length && !isEqual(updatedAddOn.subjects, selectedAddOn.subjects);
            case DisplayType.DATE_SELECT_SUBJECT:
                return !!updatedAddOn.dateSubjectSelections?.length && !isEqual(updatedAddOn.dateSubjectSelections, selectedAddOn.dateSubjectSelections);
        }
    }

    return false;
};

function syncAddOnInCarts(state: SelectedAddOnsState, addOns: SelectedAddOn[], cartReservedResourceId: number | undefined): SelectedAddOnsCart[] {
    let carts = state.tempAddOnCarts;

    const targetCart: SelectedAddOnsCart | undefined = carts.find((cart) => cart.cartReservedResourceId === cartReservedResourceId);
    if (targetCart) {
        targetCart.selectedAddOns = [...addOns];
    } else {
        carts = [...carts, { cartReservedResourceId, selectedAddOns: [...addOns] }];
    }
    return carts;
}
