import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget, isPageWidget, reportWidgetRenderError } from "../../";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Columns } from "./Columns";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds: any[];
    showMoment: ShowMoments;
}

export type Col = "" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12" | "auto" | "fill";
export interface ColumnOptions {
    col?: Col;
    columnsResponsive?: Col;
    columnsTablet?: Col;
    columnsDesktop?: Col;
    columnsExtraLargeDesktop?: Col;
}

/**
 * Interface should be removed.
 * This can only be done if the respective properties are db patched in the following files:
 *  - src/plugins/dynamic/amenities/index.tsx
 *  - src/plugins/dynamic/locationSearchContainer/index.tsx
 *  - src/plugins/dynamic/typeSearchContainer/index.tsx
 *  - src/plugins/dynamic/unitSearchContainer/index.tsx
 *
 * For these widgets, these properties have to be patched:
 *  - columns --> col
 *  - columnsResp --> columnsResponsive
 *  - columnsTab --> columnsTablet
 *  - columnsDesk --> columnsDesktop
 * - columnsExtraLargeDesk --> columnsExtraLargeDesktop
 */
export interface AlternativeColumnOptions {
    columns?: Col;
    columnsResp?: Col;
    columnsTab?: Col;
    columnsDesk?: Col;
    columnsExtraLargeDesk?: Col;
}

type ShowMoments = "" | "dk-hidden-s" | "dk-hidden-m" | "dk-hidden-l" | "dk-hidden-from-l";

export interface ColumnsChildOptions extends ColumnOptions {
    showMoment?: ShowMoments;
}
function* range(from: number, to: number) {
    for (let i = from; i <= to; i++) {
        yield i;
    }
}

export const colSelectOptions: Array<SelectOption<Col>> = [
    ...Array.from(range(1, 12)).map((i) => ({
        value: ("" + i) as Col,
        label: "" + i,
    })),
    {
        value: "auto",
        label: getI18nLocaleObject(namespaceList.widgetColumns, "auto"),
    },
    {
        value: "",
        label: getI18nLocaleObject(namespaceList.widgetColumns, "fill"),
    },
];

export const getGridStylesFromWidgetOptions = <WidgetOptions extends ColumnOptions>(options: WidgetOptions): string =>
    (options.col ? "col-sm-" + options.col : "") +
    " " +
    (options.columnsResponsive ? "col-" + options.columnsResponsive : "col") +
    " " +
    (options.columnsTablet ? "col-md-" + options.columnsTablet : "") +
    " " +
    (options.columnsDesktop ? "col-lg-" + options.columnsDesktop : "") +
    " " +
    (options.columnsExtraLargeDesktop ? "col-xl-" + options.columnsExtraLargeDesktop : "");

const TARGETS = ["container", "columns"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "columns-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetColumns, "columnsWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetColumns, "columnsWidgetOptions"),
    properties: [multiSelectStylePicker("styleIds", TARGETS)],
};

export const columnsWidget: PageWidgetSpec<WidgetOptions> = {
    id: "columns",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.LAYOUT : 0,
    name: getI18nLocaleObject(namespaceList.widgetColumns, "columnsWidget"),
    description: getI18nLocaleObject(namespaceList.widgetColumns, "columnsWidgetDescription"),
    optionsForm: widgetOptionsForm,
    childOptions: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetColumns, "columnsWidget"),
                    properties: [
                        [
                            {
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.widgetColumns, "columns"),
                                variable: "col",
                                default: "",
                                optionList: colSelectOptions,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetColumns, "responsive"),
                    properties: [
                        [
                            {
                                variable: "showMoment",
                                label: getI18nLocaleObject(namespaceList.widgetColumns, "showOnMoment"),
                                type: "select",
                                default: "" as ShowMoments,
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.widgetColumns, "default"),
                                    },
                                    {
                                        value: "dk-hidden-s",
                                        label: getI18nLocaleObject(namespaceList.widgetColumns, "hiddenSmall"),
                                    },
                                    {
                                        value: "dk-hidden-m",
                                        label: getI18nLocaleObject(namespaceList.widgetColumns, "hiddenMedium"),
                                    },
                                    {
                                        value: "dk-hidden-l",
                                        label: getI18nLocaleObject(namespaceList.widgetColumns, "hiddenLarge"),
                                    },
                                    {
                                        value: "dk-hidden-from-l",
                                        label: getI18nLocaleObject(namespaceList.widgetColumns, "hiddenFromLarge"),
                                    },
                                ],
                            },
                            /* jscpd:ignore-start */
                            {
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.widgetColumns, "columnsResponsive"),
                                variable: "columnsResponsive",
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.widgetColumns, "columnsTablet"),
                                variable: "columnsTablet",
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.widgetColumns, "columnsDesktop"),
                                variable: "columnsDesktop",
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.widgetColumns, "columnsExtraLargeDesktop"),
                                variable: "columnsExtraLargeDesktop",
                                default: "",
                                optionList: colSelectOptions,
                            },
                            /* jscpd:ignore-end */
                        ],
                    ],
                },
            ],
        },
    ],
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        showMoment: "",
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const children = await Promise.all(
            widget.children.map((child, index) => {
                const childSpec = child.spec;
                if (isPageWidget(childSpec)) {
                    return childSpec
                        .render(child, context)
                        .then((element) => ({ element, options: child.options }))
                        .catch((err) => {
                            reportWidgetRenderError(widget, err, childSpec, context);
                            return { element: <div key={index} />, options: {} };
                        });
                }
                throw new TypeError("Expected child widgets to be page widgets");
            })
        );
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return <Columns options={widget.options} childs={children} className={styleClasses} />;
    },
};
