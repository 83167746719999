import { AddOn, ApiCallOptions } from "@maxxton/cms-mxts-api";
import { AddOnsSagaAction, AddOnsSagaActionType, fetchAddOnsError, fetchAddOnsSuccess, startFetchingAddOns } from "../../redux/actions/add-ons/addOnsAction";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getBookableAddOnsFromDynamicFilter, getBookableAddOnsFromMyEnvState, getMarketingGroupsWithAddOns } from "./fetchAddOnsSaga.util";

import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { MarketingGroupWithAddOns } from "../../redux/reducers/add-ons/addOnsReducer";
import { MyEnvState } from "../../redux/reducers/myEnv/myEnvState";
import { SagaUtils } from "../SagaUtils";
import { getMxtsEnv } from "../../plugins/mxts";
import { globalApiContext } from "../../containers/CmsProvider";

function* fetchAddOns(action: AddOnsSagaAction) {
    yield put(startFetchingAddOns());

    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const myEnvState: MyEnvState = yield select(SagaUtils.getMyEnvState);
    const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale);

    try {
        const addOns: AddOn[] = action.obtainMyEnvAddOns
            ? yield call(getBookableAddOnsFromMyEnvState, myEnvState, env, dynamicFilter)
            : yield call(getBookableAddOnsFromDynamicFilter, dynamicFilter, env);
        const recommendedAddOns: AddOn[] = action.obtainMyEnvAddOns
            ? yield call(getBookableAddOnsFromMyEnvState, myEnvState, env, dynamicFilter, action.recommendedAddOns)
            : yield call(getBookableAddOnsFromDynamicFilter, dynamicFilter, env, action.recommendedAddOns);
        const marketingGroupsWithAddOns: MarketingGroupWithAddOns[] = yield call(getMarketingGroupsWithAddOns, { addOns, env });
        yield put(fetchAddOnsSuccess({ addOns, marketingGroupsWithAddOns, recommendedAddOns }));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error - fetching add-ons:", error);
        yield put(fetchAddOnsError(error));
    }
}

export function* watchFetchAddOns() {
    yield takeEvery(AddOnsSagaActionType.START_FETCH_ADD_ONS_SAGA, fetchAddOns);
}
