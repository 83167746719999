import * as React from "react";
import * as ReactDOM from "react-dom";

import { FormSpec, WidgetOptions } from "../../form-specs";
import { loadWidgetOptionsFromLocalStorage, saveWidgetOptionsInLocalStorage, setResetStatusOnOptionsUpdate, updateFrontendEditingButtonStatus } from "./frontendEdit.utils";

import { Dispatch } from "redux";
import { FrontendPageEditAction } from "../../redux/actions/frontendPageEditAction";
import { GenericFormModal } from "../generic-form";
import { PermissionType } from "@maxxton/cms-mxts-api";
import { State } from "../../redux";
import { connect } from "react-redux";
import { getI18nLocaleString } from "../../i18n";
import { isClientSide } from "../../utils/generic.util";
import namespacesList from "../../i18n/namespaceList";

interface WithEditModalState {
    isModalOpen: boolean;
    isAdmin: boolean;
    options?: WidgetOptions<any>;
}

interface WithEditModalProps extends WithEditModalDispatchProps {
    isFrontEndEditable: boolean;
    isFrontendEditedWidgetsReset: boolean;
    options: WidgetOptions<any>;
    widgetOptionsForm: FormSpec<any>;
    widgetId: string;
}
interface WithEditModalDispatchProps {
    dispatchAction: Dispatch<FrontendPageEditAction>;
}

// eslint-disable-next-line max-lines-per-function
export default function withEditModal(Component: React.ComponentType<any>) {
    class WidgetWithEditModal extends React.Component<WithEditModalProps, WithEditModalState> {
        private static currentOpenModal: WidgetWithEditModal | null = null;
        constructor(props: WithEditModalProps) {
            super(props);
            this.state = {
                isModalOpen: false,
                isAdmin: isClientSide() && !!document.querySelector(".backend"),
                options: props.options,
            };
            this.handleModal = this.handleModal.bind(this);
            this.handleOnSave = this.handleOnSave.bind(this);
        }

        public componentDidMount() {
            const { isAdmin } = this.state;
            const { isFrontEndEditable, widgetId, options } = this.props;
            const optionsFromLocalStorage = loadWidgetOptionsFromLocalStorage(widgetId, options);
            this.setState({ options: optionsFromLocalStorage });
            const domNode = ReactDOM.findDOMNode(this) as any;
            if (domNode && isFrontEndEditable && !isAdmin) {
                domNode.addEventListener("click", this.handleWidgetClick);
                domNode.classList.add("show-controls", "edit-controls");
            }
        }
        public componentDidUpdate(prevProps: WithEditModalProps, prevState: WithEditModalState) {
            const { isFrontEndEditable, isFrontendEditedWidgetsReset, options } = this.props;
            const { isAdmin } = this.state;
            const domNode = ReactDOM.findDOMNode(this) as any;
            if (domNode && isFrontEndEditable !== prevProps.isFrontEndEditable && !isAdmin) {
                if (isFrontEndEditable) {
                    domNode.classList.add("show-controls", "edit-controls");
                    domNode.addEventListener("click", this.handleWidgetClick);
                } else {
                    domNode.classList.remove("show-controls", "edit-controls");
                    domNode.removeEventListener("click", this.handleWidgetClick);
                    this.setState({ isModalOpen: false });
                }
            }
            if (isFrontendEditedWidgetsReset !== prevProps.isFrontendEditedWidgetsReset && isFrontendEditedWidgetsReset) {
                this.setState({ options });
            }
        }

        public componentWillUnmount() {
            const { isFrontEndEditable } = this.props;
            const { isAdmin } = this.state;
            if (!isAdmin && isFrontEndEditable) {
                const domNode = ReactDOM.findDOMNode(this) as any;
                if (domNode) {
                    domNode.removeEventListener("click", this.handleWidgetClick);
                }
            }
        }

        public handleModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (typeof event.stopPropagation === "function") {
                event.stopPropagation();
            }
            if (!this.props.isFrontEndEditable || this.state.isAdmin) {
                return;
            }
            if (WidgetWithEditModal.currentOpenModal && WidgetWithEditModal.currentOpenModal !== this) {
                WidgetWithEditModal.currentOpenModal.setState({ isModalOpen: false });
            }
            this.setState(
                (prevState) => ({
                    isModalOpen: !prevState.isModalOpen,
                }),
                () => {
                    WidgetWithEditModal.currentOpenModal = this.state.isModalOpen ? this : null;
                }
            );

            const allEditableWidgets = document.querySelectorAll(".show-controls");
            allEditableWidgets.forEach((element) => {
                element.classList.remove("active-edit-controls");
            });
        };

        public handleWidgetClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            this.handleModal(event);
            const allEditableWidgets = document.querySelectorAll(".show-controls");
            allEditableWidgets.forEach((element) => {
                element.classList.remove("active-edit-controls");
            });
            event.currentTarget.classList.add("active-edit-controls");
        };

        public handleOnSave = (newOptions: WidgetOptions<any>) => {
            this.setState({ options: newOptions });
        };

        public handleChange = (newOptions: WidgetOptions<any>) => {
            const { widgetId, dispatchAction } = this.props;
            setResetStatusOnOptionsUpdate(dispatchAction);
            this.setState({ options: newOptions });
            saveWidgetOptionsInLocalStorage(newOptions, widgetId);
            updateFrontendEditingButtonStatus(dispatchAction);
        };

        public handleCancel = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (event && typeof event.stopPropagation === "function") {
                event.stopPropagation();
            }
            this.setState({ isModalOpen: false });
        };

        private getWidgetTitle = (widgetOptionsForm: FormSpec<any>): string => {
            let title: string;

            if (widgetOptionsForm.name) {
                if (typeof widgetOptionsForm.name === "string") {
                    title = getI18nLocaleString(namespacesList.admin, "edit");
                } else {
                    title = getI18nLocaleString(widgetOptionsForm.name.namespace, widgetOptionsForm.name.key);
                }
            } else {
                title = getI18nLocaleString(namespacesList.admin, "noWidgetTitle");
            }
            return title;
        };

        public render(): JSX.Element {
            const { isModalOpen, options, isAdmin } = this.state;
            const { isFrontEndEditable, widgetOptionsForm } = this.props;

            if (!isFrontEndEditable || isAdmin) {
                return <Component {...this.props} />;
            }

            return (
                <React.Fragment>
                    <Component {...this.props} options={options} handleFrontendEditModal={this.handleModal} />
                    {isFrontEndEditable && !isAdmin && (
                        <GenericFormModal
                            isOpen={isModalOpen}
                            label="front-edit-modal front-flexbox-modal"
                            toggle={this.handleModal}
                            title={this.getWidgetTitle(widgetOptionsForm)}
                            spec={widgetOptionsForm}
                            mode="admin_edit"
                            value={options}
                            onSave={this.handleOnSave}
                            onCancel={this.handleCancel}
                            permission={PermissionType.EXECUTE}
                            onChange={this.handleChange}
                        />
                    )}
                </React.Fragment>
            );
        }
    }

    const mapStateToProps = (state: State) => ({
        isFrontEndEditable: state.frontendPageEditState.isFrontEndEditable,
        isFrontendEditedWidgetsReset: state.frontendPageEditState.isFrontendEditedWidgetsReset,
    });
    const mapDispatchToProps = (dispatch: Dispatch<FrontendPageEditAction>): WithEditModalDispatchProps => ({ dispatchAction: dispatch });

    return connect(mapStateToProps, mapDispatchToProps)(WidgetWithEditModal);
}
