import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "nautic",
    name: "Nautic Rentals Theme",
    stylesheets: ["/styles/themes/nautic/nauticrentals-admin.css"],
    frontendStylesheets: ["/styles/themes/nautic/nauticrentals.css"],
    styles: [...baseTheme.styles],
};

export default theme;
