import { BaseOptions } from "./form.types";
import { CMSProviderProperties } from "../../containers/cmsProvider.types";
import { CUSTOM_FIELDS_DATA_TYPE } from "../dynamic/description/Description";
import { CustomFieldResult } from "@maxxton/cms-mxts-api";
import { getMandatoryFields } from "../../components/utils";

interface IFormFieldsDescription {
    fieldId: string;
    dynamicFieldId: string;
    useforBookingsModule: boolean | undefined;
    defaultDescription: string;
}

export const getFormFieldsDescription = (formFrieldDescriptionParams: IFormFieldsDescription): string => {
    const { fieldId, dynamicFieldId, useforBookingsModule, defaultDescription } = formFrieldDescriptionParams;
    const description: string = useforBookingsModule ? dynamicFieldId : fieldId;
    return description || defaultDescription;
};

export function getFormFieldVariable<T>(formWidgetBaseOptions: BaseOptions<T>): string | undefined {
    const { useforBookingsModule, dynamicFieldId, fieldId } = formWidgetBaseOptions;
    return useforBookingsModule ? dynamicFieldId : fieldId;
}

export async function isFormFieldRequired<T>(formWidgetBaseOptions: BaseOptions<T>, context: CMSProviderProperties): Promise<boolean> {
    const { useforBookingsModule, dynamicFieldId, required } = formWidgetBaseOptions;
    if (useforBookingsModule) {
        if (!dynamicFieldId) {
            return false;
        }
        const mandatoryFields = await getMandatoryFields(context, context.distributionChannelId);
        return mandatoryFields.indexOf(dynamicFieldId) > -1;
    }
    return required;
}

export const updateCustomFields = (customFieldsList: CustomFieldResult[], draftValue: any) =>
    customFieldsList.map((field) => {
        const formValue = draftValue[field.fieldId]; // Get the value from draftValue based on fieldId
        switch (field.dataType) {
            case CUSTOM_FIELDS_DATA_TYPE.BOOLEAN:
                field.booleanValue = formValue === "true" || formValue === true;
                break;
            case CUSTOM_FIELDS_DATA_TYPE.DATE:
                field.dateValue = formValue; // Assumes formValue is already formatted
                break;
            case CUSTOM_FIELDS_DATA_TYPE.NUMERIC:
                field.numericValue = formValue;
                break;
            case CUSTOM_FIELDS_DATA_TYPE.MEMO:
            case CUSTOM_FIELDS_DATA_TYPE.TEXT:
                field.textValue = formValue;
                break;
            case CUSTOM_FIELDS_DATA_TYPE.SELECTION_INCL:
            case CUSTOM_FIELDS_DATA_TYPE.SELECTION_EXCL:
                field.selectValues = Array.isArray(formValue) ? formValue : [formValue];
                break;
            default:
                // Do nothing for unknown data types
                break;
        }
        return field;
    });
