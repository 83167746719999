import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "rcn",
    name: "RCN Theme",
    stylesheets: ["/styles/themes/rcn/rcn-admin.css"],
    frontendStylesheets: ["/styles/themes/rcn/rcn.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "crp-activity",
            name: "Add activity crp styling",
            targets: ["flexbox"],
            classnames: ["crp-activity"],
        },
        {
            id: "crp-activity-popup",
            name: "Add activity popup styling",
            targets: ["flexbox"],
            classnames: ["crp-activity-popup"],
        },
        {
            id: "crp-activity-backdrop",
            name: "Add activity backdrop styling",
            targets: ["flexbox"],
            classnames: ["crp-activity-backdrop"],
        },
        {
            id: "clickable-activity",
            name: "Make activity card fully clickable",
            targets: ["flexbox"],
            classnames: ["clickable-activity"],
        },
    ],
};

export default theme;
