import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { WebContent as CmsApiWebContent, WithId } from "@maxxton/cms-api";
import { LocaleContent, PaymentMethodBrands, PaymentMethodTypes, WidgetOptions } from "./";
import { getDCPaymentMethods, getNoDataFoundContent, getNoDataFoundTemplate, getWebManagerEnvironment, isLocal, roundHalfToEven, setOpacityOnHide } from "../../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../../i18n";
import { getLanguageLocale, getMxtsEnv } from "../../../mxts";
import { getSelectedEsReservation, getSelectedReservationId } from "../../../../redux/reducers/myEnv/myEnv.util";

import { CMSProviderProperties } from "../../../../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { DomainObjectUtil } from "../../../../utils/domainobject.util";
import { DynamicFilter } from "../../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../../dynamicWidget.types";
import { FilterChangeAction } from "../../../../redux/actions/dynamicFilterAction.types";
import { InstalmentsState } from "../../../../redux/reducers/instalmentsState";
import LocalizedTitleAndLabel from "../../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MyEnvState } from "../../../../redux/reducers/myEnv/myEnvState";
import { NavLink } from "reactstrap";
import { PaymentStatuses } from "../dynamic-status/paymentStatus.enum";
import { ReservationState } from "../../../../redux/reducers/reservationReducer";
import { SmartLink } from "../../../../components/SmartLink";
import { State } from "../../../../redux";
import { connect } from "react-redux";
import { getLocalizedContent } from "../../../../utils/localizedContent.util";
import { getRateTypeFromReservation } from "../../../../utils/currency.util";
import namespaceList from "../../../../i18n/namespaceList";
import { pageLink } from "../../../../routing";
import { renderNoResultsFoundContent } from "../../containerWidget.util";

interface PaymentMethodState {
    selectedMethod?: PaymentMethodBrands;
    selectedBank?: string;
    instalments?: MXTS.Instalment[];
    currencyCode?: string;
    concern?: MXTS.ConcernResult;
    env?: MXTS.ApiCallOptions;
    loadingPaymentLink: boolean;
    isDirectPaymentProvider: boolean;
    fallbackTemplate: JSX.Element[] | null;
    fallbackWebContent: (CmsApiWebContent & WithId) | null;
    filteredDCPaymentMethods?: MXTS.DistributionChannelPaymentMethod[];
    paymentPageRedirectParams: MXTS.PaymentPageRedirectParams | null;
}

interface PaymentMethodStoreProps {
    myEnvState: MyEnvState;
    instalmentsState: InstalmentsState;
    dynamicFilter: DynamicFilter;
    reservationState: ReservationState;
}

interface PaymentMethodDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction>;
}

interface PaymentMethodBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {}

interface PaymentMethodProps extends PaymentMethodBaseProps, PaymentMethodStoreProps, PaymentMethodDispatchProps {}

class PaymentMethodBase extends React.Component<PaymentMethodProps, PaymentMethodState> {
    constructor(props: PaymentMethodProps) {
        super(props);
        const localeContent = props.options.localizedContent.find((content) => content.locale === props.context.currentLocale.locale);
        const selectedMethod = localeContent?.paymentMethods?.[0]?.label as PaymentMethodState["selectedMethod"];
        this.state = {
            selectedMethod,
            loadingPaymentLink: true,
            fallbackTemplate: null,
            fallbackWebContent: null,
            isDirectPaymentProvider: false,
            paymentPageRedirectParams: null,
        };
    }

    public componentDidMount() {
        this.loadState(this.props);
        const { options, context } = this.props;
        const { currentLocale, site } = context;
        const localeContent = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedContent });

        if (!localeContent?.paymentMethods?.length) {
            this.setPaymentFallback({ options, context });
        }
        this.loadDCPaymentMethods();
        this.loadPaymentProviderSettings();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: PaymentMethodProps) {
        if (this.isReservationIdChanged(this.props, nextProps)) {
            this.loadState(nextProps);
        }
        if (nextProps.instalmentsState.selectedInstalmentIds !== this.props.instalmentsState.selectedInstalmentIds) {
            this.loadState(nextProps);
        }
    }

    private isReservationIdChanged(previousProps: PaymentMethodProps, nextProps: PaymentMethodProps): boolean {
        const { dynamicFilter: prevDynamicFilter, isMyEnvWidget: prevIsMyEnvWidget, myEnvState: prevMyEnvState } = previousProps;
        const { dynamicFilter, isMyEnvWidget, myEnvState } = nextProps;
        const prevReservationId = getSelectedReservationId({ dynamicFilter: prevDynamicFilter, isMyEnvWidget: prevIsMyEnvWidget, myEnvState: prevMyEnvState });
        const reservationId = getSelectedReservationId({ dynamicFilter, isMyEnvWidget, myEnvState });
        return prevReservationId !== reservationId;
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            options,
            context,
            className,
            reservationState,
            instalmentsState: { selectedInstalmentIds, payLater },
            isMyEnvWidget,
        } = this.props;
        const { currentLocale, site } = context;
        const hideWidget = setOpacityOnHide(options);
        const localeContent = options.localizedContent.find((content) => content.locale === context.currentLocale.locale);
        const { enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor, alwaysShowPaymentMethods } = options;
        const { fallbackTemplate, fallbackWebContent, filteredDCPaymentMethods } = this.state;
        const selectedInstalmentAmount =
            selectedInstalmentIds?.length && this.props.instalmentsState.instalments?.filter((instalment) => selectedInstalmentIds?.includes(instalment.id)).find((item) => !!item.due)?.due;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        if (reservationState.reservation?.status === MXTS.ReservationStatus.DECLINED) {
            return null;
        }
        if (fallbackTemplate || fallbackWebContent) {
            return (
                <div className={`add-ons-page container ${hideWidget}`}>
                    {renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context })}
                </div>
            );
        }
        const filteredPaymentMethods = filteredDCPaymentMethods?.length
            ? localeContent?.paymentMethods?.filter(
                  (lCPaymentMethod) => !filteredDCPaymentMethods.some((dCPaymentMethod) => dCPaymentMethod.paymentMethod === getDCPaymentMethods(lCPaymentMethod.value))
              )
            : localeContent?.paymentMethods;

        const concern = context.mxtsEnv?.default?.env?.concern;
        if (
            selectedInstalmentIds?.length &&
            filteredPaymentMethods &&
            !!selectedInstalmentAmount &&
            // The payLaterResourceId is not configured for every client. Some clients don't even have an additional cost for choosing "pay later".
            // Ideally we would have a way to figure out if a payLaterResourceId applies for this concern, otherwise don't check on it.
            // For now i've added a hardcoded check on EST to quickly implement this for their go-live.
            (isMyEnvWidget || !payLater || (concern !== "est" && !reservationState?.payLaterResourceId) || alwaysShowPaymentMethods)
        ) {
            return (
                <div className={`payment-methods-container ${className} ${hideWidget}`}>
                    <LocalizedTitleAndLabel
                        localizedTitle={localizedWidgetTitle}
                        enableWidgetTitle={enableWidgetTitle}
                        useTitleHeadings={useTitleHeadings}
                        styleWidgetTitle={styleWidgetTitle}
                        className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                        style={classNames(fontColor?.includes("rgba") && fontColor)}
                    />
                    {filteredPaymentMethods.map((paymentMethod) => {
                        const methodType = paymentMethod.label;
                        switch (methodType) {
                            case PaymentMethodBrands.IDEAL:
                                return this.getPaymentMethodInput(methodType, "idealLabel", true);

                            case PaymentMethodBrands.VISA:
                                return this.getPaymentMethodInput(methodType, "visaLabel");

                            case PaymentMethodBrands.MASTERCARD:
                                return this.getPaymentMethodInput(methodType, "mastercardLabel");

                            case PaymentMethodBrands.MAESTRO:
                                return this.getPaymentMethodInput(methodType, "maestroLabel");

                            case PaymentMethodBrands.AMERICAN_EXPRESS:
                                return this.getPaymentMethodInput(methodType, "americanExpressLabel");

                            case PaymentMethodBrands.DIRECT_E_BANKING_DE:
                                return this.getPaymentMethodInput(methodType, "directEBankingDELabel");

                            case PaymentMethodBrands.DIRECT_E_BANKING_BE:
                                return this.getPaymentMethodInput(methodType, "directEBankingBELabel");

                            case PaymentMethodBrands.BCMC:
                                return this.getPaymentMethodInput(methodType, "bcmcLabel");

                            case PaymentMethodBrands.SOFORT:
                                return this.getPaymentMethodInput(methodType, "sofortLabel");

                            case PaymentMethodBrands.GIROPAY:
                                return this.getPaymentMethodInput(methodType, "giropayLabel");
                            case PaymentMethodBrands.KBC_ONLINE:
                                return this.getPaymentMethodInput(methodType, "kbcOnlineLabel");
                            case PaymentMethodBrands.BELFIUS:
                                return this.getPaymentMethodInput(methodType, "belfiusLabel");
                            case PaymentMethodBrands.Przelewy24:
                                return this.getPaymentMethodInput(methodType, "przelewy24Label");
                            case PaymentMethodBrands.Cadeaukaart:
                                return this.getPaymentMethodInput(methodType, "cadeaukaartLabel");
                            case PaymentMethodBrands.APPLEPAY:
                                return this.getPaymentMethodInput(methodType, "applePayLabel");
                            case PaymentMethodBrands.GOOGLEPAY:
                                return this.getPaymentMethodInput(methodType, "googlePayLabel");
                            default:
                                return null;
                        }
                    })}
                </div>
            );
        }
        return null;
    }

    private async setPaymentFallback({ options, context }: { options: WidgetOptions; context: CMSProviderProperties }) {
        const { paymentMethodFallbackTemplateId, paymentMethodFallbackWebContentId } = options;
        if (paymentMethodFallbackTemplateId) {
            const fallbackTemplate = await getNoDataFoundTemplate(paymentMethodFallbackTemplateId, context);
            this.setState({ fallbackTemplate });
        }
        if (paymentMethodFallbackWebContentId) {
            const fallbackWebContent = await getNoDataFoundContent(paymentMethodFallbackWebContentId);
            this.setState({ fallbackWebContent });
        }
    }

    private loadState = (props: PaymentMethodProps) => {
        const { context, dynamicFilter, myEnvState, isMyEnvWidget, reservationState, instalmentsState } = props;
        const reservationId = getSelectedReservationId({ dynamicFilter, isMyEnvWidget, myEnvState });
        if (reservationId) {
            getMxtsEnv(context, context.currentLocale.code).then(async (env) => {
                const [instalments, concern] = await Promise.all([context.mxtsApi.getInstalments(env, { reservationId }), context.mxtsApi.concern(env, { shortName: env.env.concern })]);
                let currencyCode = dynamicFilter.currency?.code || context?.currency?.code;
                if (isMyEnvWidget && dynamicFilter.reservationCurrencyCode && myEnvState.selectedReservationId === reservationId) {
                    currencyCode = dynamicFilter.reservationCurrencyCode;
                }
                const distributionChannelId = reservationState.reservation?.distributionChannelId || instalmentsState.instalments?.[0]?.reservation?.distributionchannelId;
                if (!currencyCode && distributionChannelId) {
                    const distributionChannel = await context.mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: +distributionChannelId }]).catch((error: Error) => undefined);
                    currencyCode = distributionChannel?.currency.currencyCode;
                }
                const instalmentsContent = instalments.content;
                this.setState(
                    {
                        instalments: instalmentsContent,
                        currencyCode,
                        concern: concern.content[0],
                        env,
                    },
                    () => {
                        this.getPaymentPageRedirectParams();
                    }
                );
            });
        }
    };

    private loadDCPaymentMethods = () => {
        const { context, dynamicFilter, reservationState } = this.props;
        getMxtsEnv(context, context.currentLocale.code).then(async (env) => {
            if (dynamicFilter.distributionChannel && reservationState.reservation) {
                const distributionChannelPaymentMethods = (await context.mxtsApi.distributionChannelPaymentMethods(env, { distributionChannelCode: dynamicFilter.distributionChannel.code })).content;
                const currentDate = moment();
                const arrivalDateMoment = moment(reservationState.reservation.arrivalDate);
                const numberOfDaysToArrival = arrivalDateMoment.diff(currentDate, "days");
                const filteredDCPaymentMethods = distributionChannelPaymentMethods
                    .find((item) => item.distributionChannelCode === dynamicFilter.distributionChannel?.code)
                    ?.paymentMethods.filter((paymentMethod) => {
                        if (paymentMethod.maxDaysBetweenBookAndArrivalDate) {
                            return paymentMethod.maxDaysBetweenBookAndArrivalDate >= numberOfDaysToArrival;
                        }
                        return (paymentMethod.minDaysBeforeArrival || 0) > numberOfDaysToArrival;
                    });
                this.setState({ filteredDCPaymentMethods });
            }
        });
    };

    private loadPaymentProviderSettings = async () => {
        const isDirectPaymentProvider = await this.isDirectPaymentServiceProvider(this.props);
        this.setState({ isDirectPaymentProvider });
    };

    private async isDirectPaymentServiceProvider(nextProps: PaymentMethodProps): Promise<boolean> {
        const { isMyEnvWidget, myEnvState, context, reservationState } = nextProps;
        const myEnvReservationId = getSelectedReservationId({ isMyEnvWidget, myEnvState });
        let resortId: number | undefined;
        let currencyCode: string | undefined;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        if (myEnvReservationId) {
            const reservation = await getSelectedEsReservation({ isMyEnvWidget, myEnvState, env });
            currencyCode = reservation?.currency?.baseCurrency || (await getRateTypeFromReservation({ reservationId: myEnvReservationId, env, mxtsApi: context.mxtsApi }))?.currency.code;
            resortId = reservation?.reservation.resortId;
        } else if (reservationState.reservation) {
            currencyCode = reservationState.reservation.currency?.basecurrency;
            resortId = reservationState.reservation.resortid;
        }
        const webManagerEnv = getWebManagerEnvironment();
        const resort = resortId ? await DomainObjectUtil.getResort(context.mxtsApi, { resortIds: [resortId] }, env) : null;
        const paymentServiceProvidersPage = await context.mxtsApi.getPaymentServiceProvidersPublic(env, {
            adminOrganisationId: resort?.adminOrganisationId,
            currencyCode,
            pspTypeEnvt: webManagerEnv === "prod" ? "Production" : webManagerEnv === "acc" ? "Test" : null,
        });
        return !!paymentServiceProvidersPage?.content?.[0]?.isDirectEnabled;
    }

    private getPaymentPageRedirectParams = async () => {
        const { env, instalments, currencyCode, selectedMethod, selectedBank } = this.state;
        const { dynamicFilter, isMyEnvWidget, myEnvState, context } = this.props;
        const reservationId = getSelectedReservationId({ dynamicFilter, isMyEnvWidget, myEnvState });
        const amount = this.getAmount(instalments);

        this.setState({
            loadingPaymentLink: true,
        });

        let isDirectPaymentProvider = false;
        if (amount && reservationId && env && currencyCode) {
            const paymentMethod = this.getPaymentMethodTypeFromBrands(selectedMethod) as string | undefined;
            const paymentPageRedirectParams: MXTS.PaymentPageRedirectParams = {
                operation: "PAYMENT",
                currencyCode,
                amount,
                payerType: MXTS.PAYER_TYPE.CUSTOMER,
                paymentMethod,
                brand: selectedMethod as string | undefined,
                issuerId: selectedBank,
                languageCode: getLanguageLocale(context.currentLocale.code),
            };
            isDirectPaymentProvider = await this.isDirectPaymentServiceProvider(this.props);
            if (isDirectPaymentProvider) {
                paymentPageRedirectParams.paymentMethods = selectedMethod ? [selectedMethod] : undefined;
                paymentPageRedirectParams.returnUrl = await this.getRedirectUrl();
            } else {
                const [acceptUrl, declineUrl, exceptionUrl, cancelUrl] = await Promise.all([
                    this.getRedirectUrl(PaymentStatuses.SUCCESS),
                    this.getRedirectUrl(PaymentStatuses.CANCEL),
                    this.getRedirectUrl(PaymentStatuses.FAILED),
                    this.getRedirectUrl(PaymentStatuses.CANCEL),
                ]);
                paymentPageRedirectParams.acceptUrl = acceptUrl;
                paymentPageRedirectParams.declineUrl = declineUrl;
                paymentPageRedirectParams.exceptionUrl = exceptionUrl;
                paymentPageRedirectParams.cancelUrl = cancelUrl;
            }
            this.setState({
                paymentPageRedirectParams,
                isDirectPaymentProvider,
                loadingPaymentLink: false,
            });
        }
    };

    private getRedirectUrl = async (status?: PaymentStatuses) => {
        const { options, context, dynamicFilter, isMyEnvWidget, myEnvState } = this.props;
        const reservationId = getSelectedReservationId({ dynamicFilter, isMyEnvWidget, myEnvState });
        const localeContent = options.localizedContent.find((content) => content.locale === context.currentLocale.locale);
        let statusUrl: string;
        if ((localeContent as LocaleContent).useExternalUrlForStatus) {
            statusUrl = (localeContent as LocaleContent).externalRedirectUrl || "";
        } else {
            const pageLinkUrl = await pageLink({
                site: context.site,
                pageId: (localeContent as LocaleContent).pageId,
                locale: context.currentLocale,
                context,
            });
            statusUrl = `${context.site.hasSecurityCertificate && !isLocal() ? "https" : "http"}://${context.site.host}${pageLinkUrl}`;
        }
        const stateUuidQueryParam = dynamicFilter.stateUuid ? `&stateUuid=${dynamicFilter.stateUuid}` : "";
        let redirectUrl = reservationId ? `${statusUrl}?reservationid=${reservationId}${stateUuidQueryParam}` : "";
        if (status) {
            redirectUrl += `&status=${status}`;
        }
        return status ? encodeURIComponent(redirectUrl) : redirectUrl;
    };

    private getAmount = (instalments?: MXTS.Instalment[]) => {
        const {
            instalmentsState: { selectedInstalmentIds = [] },
        } = this.props;
        const selectedInstalments = instalments?.filter((instalment) => selectedInstalmentIds?.includes(instalment.id));
        let amount;
        if (selectedInstalments?.length) {
            const instalmentAmounts = selectedInstalments.map((instalment) => instalment.due);
            amount = instalmentAmounts.reduce((due, total) => due + total);
            amount = roundHalfToEven(amount, 0.01);
        }
        return amount;
    };

    private getPaymentMethodTypeFromBrands = (brand: PaymentMethodState["selectedMethod"]) => {
        switch (brand) {
            case PaymentMethodBrands.IDEAL:
                return PaymentMethodTypes.IDEAL;
            case PaymentMethodBrands.VISA:
            case PaymentMethodBrands.MASTERCARD:
            case PaymentMethodBrands.MAESTRO:
            case PaymentMethodBrands.AMERICAN_EXPRESS:
                return PaymentMethodTypes.CREDITCARD;
            case PaymentMethodBrands.DIRECT_E_BANKING_DE:
            case PaymentMethodBrands.SOFORT:
                return PaymentMethodTypes.DIRECT_E_BANKING_DE;
            case PaymentMethodBrands.DIRECT_E_BANKING_BE:
                return PaymentMethodTypes.DIRECT_E_BANKING_BE;
            case PaymentMethodBrands.BCMC:
                return PaymentMethodTypes.BCMC;
            case PaymentMethodBrands.GIROPAY:
                return PaymentMethodTypes.GIROPAY;
            case PaymentMethodBrands.KBC_ONLINE:
                return PaymentMethodTypes.KBC_ONLINE;
            case PaymentMethodBrands.BELFIUS:
                return PaymentMethodTypes.BELFIUS;
            case PaymentMethodBrands.Przelewy24:
                return PaymentMethodTypes.Przelewy24;
            case PaymentMethodBrands.Cadeaukaart:
                return PaymentMethodTypes.Cadeaukaart;
            case PaymentMethodBrands.APPLEPAY:
                return PaymentMethodTypes.APPLEPAY;
            case PaymentMethodBrands.GOOGLEPAY:
                return PaymentMethodTypes.GOOGLEPAY;
        }
    };

    private handlePaymentMethodClick = (selectedMethod: PaymentMethodBrands) => () => {
        const { selectedBank } = this.state;
        const isIdeal = selectedMethod === PaymentMethodBrands.IDEAL;
        const newSelectedBank = isIdeal ? selectedBank : undefined;
        this.setState({ selectedMethod, selectedBank: newSelectedBank }, () => {
            this.getPaymentPageRedirectParams();
        });
    };

    private handleBankChange = (event: any) => {
        const selectedBank = event.target.value;
        this.setState({ selectedBank }, () => {
            this.getPaymentPageRedirectParams();
        });
    };

    private getPaymentMethodInput(methodType: PaymentMethodBrands, label: string, isIdeal?: boolean) {
        const { selectedMethod, selectedBank, loadingPaymentLink, isDirectPaymentProvider } = this.state;
        const { options, context } = this.props;
        const { currentLocale, site } = context;
        const localeContent = options.localizedContent.find((content) => content.locale === context.currentLocale.locale);
        const showPayNowButton = selectedMethod ? (selectedMethod === PaymentMethodBrands.IDEAL && !isDirectPaymentProvider ? !!selectedBank : true) : false;
        let banks;
        if (isIdeal) {
            banks = localeContent?.banks;
        }
        return (
            <div className={`payment-method-wrapper ${methodType === selectedMethod ? "selected" : ""}`} key={methodType}>
                <div className="payment-method" onClick={this.handlePaymentMethodClick(methodType)}>
                    <input className="payment-method__input" type="radio" name="paymentMethod" readOnly checked={selectedMethod === methodType} id={methodType} />
                    <label className="payment-method__label form-check-label" htmlFor={methodType}>
                        {getI18nLocaleString(namespaceList.widgetPaymentMethods, label, currentLocale, site)}
                    </label>
                    {options.showPaymentMethodsLabel && (
                        <div className={`payment-method__${methodType} payment-method__type`}>{getI18nLocaleString(namespaceList.widgetPaymentMethods, "paymentMethodLabel", currentLocale, site)}</div>
                    )}
                </div>
                {selectedMethod === PaymentMethodBrands.IDEAL && banks && banks.length > 0 && !isDirectPaymentProvider && (
                    <div className="payment-method-banks">
                        <select value={selectedBank} onChange={this.handleBankChange}>
                            <option value={undefined} key="null">
                                {getI18nLocaleString(namespaceList.widgetPaymentMethods, "selectBank", currentLocale, site)}
                            </option>
                            {banks.map((bank) => (
                                <option value={bank.value} key={bank.value}>
                                    {(bank as any).label}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {showPayNowButton && methodType === selectedMethod && (
                    <NavLink tag={SmartLink} disabled={loadingPaymentLink} className="button button--m button--secondary pay-button" onClick={this.redirectToPaymentPage}>
                        <FontAwesome name="spinner" className={classNames("searchfacet-progress", loadingPaymentLink ? "in-progress" : "no-progress")} />
                        {localeContent?.buttonLabel}
                        <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                    </NavLink>
                )}
            </div>
        );
    }

    private redirectToPaymentPage = async () => {
        const { paymentPageRedirectParams } = this.state;
        const { dynamicFilter, isMyEnvWidget, myEnvState, context } = this.props;
        const reservationId = getSelectedReservationId({ dynamicFilter, isMyEnvWidget, myEnvState });
        this.setState({
            loadingPaymentLink: true,
        });
        const env = await getMxtsEnv(context);
        if (paymentPageRedirectParams) {
            const paymentPageRedirectResponse = await context.mxtsApi.getPaymentPageRedirect(env, paymentPageRedirectParams, [{ key: "reservationId", value: reservationId }]);
            const paymentLink = paymentPageRedirectResponse?.url || "";
            this.setState({
                loadingPaymentLink: false,
            });
            window.location.href = paymentLink;
        }
    };
}

function mapStateToProps(state: State): PaymentMethodStoreProps {
    return {
        myEnvState: state.myEnvState,
        instalmentsState: state.instalmentsState,
        dynamicFilter: state.dynamicFilter,
        reservationState: state.reservationState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction>): PaymentMethodDispatchProps {
    return { dispatchAction: dispatch };
}

// eslint-disable-next-line max-len
export const PaymentMethod = wrapProps<PaymentMethodBaseProps>(connect<PaymentMethodStoreProps, PaymentMethodDispatchProps>(mapStateToProps, mapDispatchToProps)(PaymentMethodBase));
