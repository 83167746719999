export interface Revision {
    draftValue: any;
    mode?: string;
    variable?: string;
    label?: string;
    value?: any[];
    modalWidget?: string;
    newVal?: any;
}

export function setRevisions(id: string | undefined, revisions: Revision[]) {
    localStorage.setItem(getRevisionStorageKey(id), JSON.stringify(revisions));
}

export function setRevisionsSec(id: string | undefined, revisions: Revision[]) {
    localStorage.setItem(getRevisionSecStorageKey(id), JSON.stringify(revisions));
}

export function getRevisions(id: string | undefined): Revision[] {
    return localStorage ? JSON.parse(localStorage.getItem(getRevisionStorageKey(id)) || "[]") : [];
}

export function getRevisionsSec(id: string | undefined): Revision[] {
    return localStorage ? JSON.parse(localStorage.getItem(getRevisionSecStorageKey(id)) || "[]") : [];
}

export function removeRevisions(id: string | undefined): void {
    localStorage.removeItem(getRevisionStorageKey(id));
}

export function removeRevisionsSec(id: string | undefined): void {
    localStorage.removeItem(getRevisionSecStorageKey(id));
}

export function clearAllRevisions(): void {
    Object.keys(localStorage)
        .filter((key: string) => key?.startsWith(getRevisionStorageKey("")) || key?.startsWith(getRevisionSecStorageKey("")))
        .forEach((key) => localStorage.removeItem(key));
}

function getRevisionStorageKey(id: string | undefined): string {
    return `revision-${id}`;
}

function getRevisionSecStorageKey(id: string | undefined): string {
    return `revisionsec-${id}`;
}
