import {
    ApiCallOptions,
    ApiError,
    CreateMyEnvAuthTokenResponse,
    Customer,
    JwtToken,
    MxtsApi,
    MxtsApiWrapper,
    MyEnvAuthToken,
    MyEnvAuthTokenCustomerPayload,
    MyEnvAuthTokenPayload,
    parseJwtToken,
    parseMyEnvToken,
} from "@maxxton/cms-mxts-api";
import { isClientSide, isServerSide } from "./generic.util";

import { CmsApiWrapper } from "@maxxton/cms-api";
import { LOCAL_STORAGE_KEYS } from "./constants";
import { MyEnvAuthTokenRefresher } from "./auth/AuthTokenRefresher";
import { getMxtsEnv } from "../plugins/mxts/index";
import { globalApiContext } from "../containers/CmsProvider";

interface MxtsAuthToken {
    typ: string;
    details: { client_id: number };
}

export interface CustomerLoginResult {
    customer?: Partial<Customer>;
    errorType?: "tooManyTries" | "loginFailed";
}

export async function loginCustomer(params: {
    env: ApiCallOptions;
    email: string;
    password: string;
    includeAddress: boolean;
    mxtsApi: MxtsApiWrapper;
    cmsApi: CmsApiWrapper;
}): Promise<CustomerLoginResult> {
    const { env, email, password, includeAddress, mxtsApi, cmsApi } = params;
    const loginResult: CustomerLoginResult = {};
    const authToken: CreateMyEnvAuthTokenResponse | undefined = await mxtsApi
        .createMyEnvAuthToken(env, {
            login: email,
            password,
            includeAddress,
        })
        .catch((err: ApiError) => {
            loginResult.errorType = err.status === 429 ? "tooManyTries" : "loginFailed";
            return Promise.resolve(undefined);
        });
    await setLoginToken(authToken);
    if (authToken) {
        cmsApi.customerPreferencesApi.updateLastLoginDate();
    }
    loginResult.customer = authToken?.customer;
    return loginResult;
}

export async function imitateCustomer(customerId: number, env: ApiCallOptions, mxtsApi: MxtsApiWrapper): Promise<Partial<Customer> | undefined> {
    const authToken: CreateMyEnvAuthTokenResponse | undefined = await mxtsApi.createImitateMyEnvAuthToken(env, { customerId }).catch(() => undefined);
    await setLoginToken(authToken);
    return authToken?.customer;
}

export async function setLoginToken(authToken: CreateMyEnvAuthTokenResponse | undefined) {
    if (authToken) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN, authToken.token);
        await createMyEnvRefreshToken(authToken.token);
    }
}

/* jscpd:ignore-start */
export async function getCustomerDetailsByLogin(params: {
    env: ApiCallOptions;
    email: string;
    password: string;
    includeAddress: boolean;
    mxtsApi: MxtsApiWrapper;
}): Promise<Partial<Customer> | undefined> {
    const { env, email, password, includeAddress, mxtsApi } = params;
    const authToken: CreateMyEnvAuthTokenResponse | undefined = await mxtsApi
        .createMyEnvAuthToken(env, {
            login: email,
            password,
            includeAddress,
        })
        .catch(() => undefined);
    return authToken?.customer;
}
/* jscpd:ignore-end */

export function getMxtsTokenType(token?: string | null): string {
    if (token == null) {
        token = localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN);
    }
    const parsedToken: MxtsAuthToken | undefined = parseMxtsAuthToken(token);
    if (parsedToken) {
        return parsedToken.typ;
    }
    return "bearer";
}

export function getMxtsClientId(token: string | null = null): number | undefined {
    if (token == null && isClientSide()) {
        token = localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN);
    }
    const parsedToken: MxtsAuthToken | undefined = parseMxtsAuthToken(token);
    if (parsedToken) {
        return parsedToken.details.client_id;
    }
}

function isJwtTokenAboutToExpire(jwtToken: JwtToken<MyEnvAuthTokenPayload>): boolean {
    const expireTimeInMs = getJwtTokenExpireInMs(jwtToken.payload);
    if (expireTimeInMs) {
        return expireTimeInMs < 1000;
    }
    return true;
}

function getJwtTokenExpireInMs(myEnvAuthTokenPayload: MyEnvAuthTokenPayload | undefined): number | undefined {
    if (myEnvAuthTokenPayload?.exp) {
        return new Date(myEnvAuthTokenPayload.exp * 1000).getTime() - new Date().getTime();
    }
    return undefined;
}

export function isMyEnvAuthTokenExpired(): boolean {
    if (isClientSide()) {
        const expireTimeInMs = getJwtTokenExpireInMs(parseJwtToken<MyEnvAuthTokenPayload>(localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN))?.payload);
        if (expireTimeInMs) {
            return expireTimeInMs <= 0;
        }
    }
    return true;
}

const myEnvAuthTokenRefresher = new MyEnvAuthTokenRefresher();

export async function getValidMyEnvAuthToken(): Promise<MyEnvAuthTokenCustomerPayload | undefined> {
    if (isServerSide()) {
        return undefined;
    }
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN);
    if (token) {
        const myEnvJwtToken: JwtToken<MyEnvAuthTokenPayload> | undefined = parseJwtToken<MyEnvAuthTokenPayload>(token);
        if (myEnvJwtToken?.payload?.exp && myEnvJwtToken?.payload.payload) {
            myEnvAuthTokenRefresher.setCurrentToken(myEnvJwtToken);
            if (isJwtTokenAboutToExpire(myEnvJwtToken)) {
                // Only check the token validity if it's about to expire. Otherwise we keep doing the same check for a token we already know is valid.
                // If it's on the local storage then it's valid. We won't set an invalid token in localStorage.
                // And all security checks are at server side so no use to try and add security at client side.
                const env = await getMxtsEnv(globalApiContext());
                if (await MxtsApi.isMyEnvAuthTokenValid(env, { token }).catch(() => null)) {
                    return myEnvJwtToken.payload.payload;
                }
            } else {
                return myEnvJwtToken.payload.payload;
            }
        }
    }
    // No valid token found. Now try creating one from the refreshToken
    const newAuthToken: MyEnvAuthToken | undefined = await refreshMyEnvAuthToken();
    if (newAuthToken?.token) {
        return parseMyEnvToken(newAuthToken.token);
    }
    onUserNotLoggedIn();
}

export function getMyEnvAuthToken() {
    if (!isServerSide()) {
        return parseJwtToken<MyEnvAuthTokenPayload>(localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN))?.payload;
    }
}

export async function refreshMyEnvAuthToken(): Promise<MyEnvAuthToken | undefined> {
    const newAuthToken: MyEnvAuthToken | undefined = await createMyEnvTokenFromRefreshToken();
    if (newAuthToken?.token) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN, newAuthToken.token);
        myEnvAuthTokenRefresher.setCurrentToken(parseJwtToken<MyEnvAuthTokenPayload>(newAuthToken.token));
    }
    return newAuthToken;
}

export function onUserNotLoggedIn() {
    // TODO: add the correct action here
    // eslint-disable-next-line no-console
    const previousUrl = window.location.href;
    localStorage.setItem("myEnvPreviousUrl", previousUrl);
}

export async function createMyEnvRefreshToken(myEnvAuthToken: string): Promise<boolean> {
    const url = "/createMyEnvRefreshToken";
    const refreshToken: boolean = await fetch(url, {
        method: "POST",
        headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: myEnvAuthToken }),
    })
        .then(async (res) => {
            if (!res.ok) {
                throw new Error(`POST ${url} responded with status ${res.status} (${await res.text()})`);
            }
            return res.json();
        })
        .catch(() => false);
    return refreshToken;
}

export async function createMyEnvTokenFromRefreshToken(): Promise<MyEnvAuthToken | undefined> {
    const url = "/createMyEnvTokenFromRefreshToken";
    const myEnvAuthToken: MyEnvAuthToken | undefined = await fetch(url, {
        method: "POST",
        /* jscpd:ignore-start */
        headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            "Access-Control-Allow-Origin": "*",
        },
        /* jscpd:ignore-end */
    })
        .then(async (res) => {
            if (!res.ok) {
                throw new Error(`POST ${url} responded with status ${res.status} (${await res.text()})`);
            }
            return res.json();
        })
        .catch(() => undefined);
    return myEnvAuthToken;
}

function parseMxtsAuthToken(token: string | null): MxtsAuthToken | undefined {
    if (token) {
        const jwtParts = token.split(".");
        if (jwtParts.length > 1) {
            return JSON.parse(atob(jwtParts[1]));
        }
    }
}

export function setUserLoggedInCookie() {
    document.cookie = "userLoggedIn=true; Path=/;";
}

export function removeUserLoggedInCookie() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    document.cookie = "userLoggedIn=; Path=/;Expires=" + date;
}
