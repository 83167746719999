import { Action } from ".";

export enum FormEditActionType {
    edited = "form_edited",
    saved = "form_saved",
    cancelled = "form_cancelled",
}
export interface FormEditAction extends Action {
    actionType: FormEditActionType;
}
