import { CachePriority, CacheStrategy, Category, CategoryApi } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { FormSpec } from "../";
import { SelectOption } from "../formSpec.types";
import namespaceList from "../../i18n/namespaceList";

export const categorySpec: FormSpec<Category> = {
    id: "category",
    name: getI18nLocaleObject(namespaceList.admin, "category"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "categories"),
    api: CategoryApi,
    permission: "webmanager.categories",
    display(category: Category): string {
        return category.name;
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "categoryType"),
            variable: "categoryType",
            type: "text",
        },
    ],
    properties: [
        {
            label: getI18nLocaleObject(namespaceList.genericCrud, "categoryType"),
            variable: "categoryType",
            default: "default",
            type: "autocomplete",
            isClearable: false,
            options: async () => [
                {
                    label: getI18nLocaleObject(namespaceList.admin, "default"),
                    value: "default",
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "pages"),
                    value: "page",
                },
                {
                    label: getI18nLocaleObject(namespaceList.genericCrud, "categoryNameCustomResultsPanel"),
                    value: "results-panel",
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "posts"),
                    value: "post",
                },
            ],
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            type: "text",
            required: true,
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "parentCategoryId"),
            variable: "parentCategoryId",
            type: "select",
            visible: (options: Category) => options.categoryType === "default",
            async optionList() {
                const categoryNamesList: Category[] = [];
                const categoryList = await CategoryApi.find();
                categoryNamesList.push({ categoryId: 1, name: getI18nLocaleString(namespaceList.genericCrud, "uncategorized"), categoryType: "default", parentCategoryId: null });
                categoryList.forEach((key) => {
                    categoryNamesList.push(key);
                });
                return categoryNamesList.map(
                    (category: Category): SelectOption<number> => ({
                        label: category.name,
                        value: category.categoryId,
                    })
                );
            },
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "cacheStrategy"),
            variable: "cacheStrategy",
            default: CacheStrategy.AUTO,
            type: "autocomplete",
            isClearable: false,
            visible: (options: Category) => options.categoryType === "page",
            options: async () => [
                {
                    label: getI18nLocaleObject(namespaceList.admin, "optimized"),
                    value: CacheStrategy.OPTIMIZED,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "optimistic"),
                    value: CacheStrategy.OPTIMISTIC,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "lazy"),
                    value: CacheStrategy.LAZY,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "auto"),
                    value: CacheStrategy.AUTO,
                },
            ],
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "cachePriority"),
            variable: "cachePriority",
            default: CachePriority.AUTO,
            type: "autocomplete",
            isClearable: false,
            visible: (options: Category) => options.categoryType === "page",
            options: async () => [
                {
                    label: getI18nLocaleObject(namespaceList.admin, "high"),
                    value: CachePriority.HIGH,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "medium"),
                    value: CachePriority.MEDIUM,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "low"),
                    value: CachePriority.LOW,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "auto"),
                    value: CachePriority.AUTO,
                },
            ],
        },
    ],
};
