import { AdditionState } from "../reducers/additionReducer";
import { DATE_FORMAT } from "../../utils/constants";
import { DateUtil } from "../../utils/date.util";
import { DynamicFilter } from "../reducers/dynamicFilter.types";
import { SelectedAddition } from "../../plugins/dynamic/additions/additions.types";
import { State } from "../index";
import { createTransform } from "redux-persist";

// transform state on its way to being serialized and persisted.
export const StateTransform = createTransform(
    (inboundState: State[keyof State], key) => {
        if (key === "additionState") {
            return {
                ...inboundState,
                selectedAdditions: transformInboundSelectedAdditions((inboundState as AdditionState).selectedAdditions),
            };
        }
        if (key === "dynamicFilter") {
            return {
                ...inboundState,
                subject: stringifySubjects((inboundState as DynamicFilter).subject),
            };
        }
        return { ...inboundState };
    }, // transform state being rehydrated
    (outboundState, key) => {
        if (key === "dynamicFilter") {
            return {
                ...outboundState,
                subject: deStringifySubjects((outboundState as any).subject),
            };
        }
        return { ...outboundState };
    }
);

function stringifySubjects(subjects: Map<number, number> | undefined): string[] {
    const subjectParams: string[] = [];
    if (subjects) {
        subjects.forEach((value: number, key: number) => {
            if (value > 0) {
                subjectParams.push(key.toString() + "," + value.toString());
            }
        });
    }
    return subjectParams;
}

function deStringifySubjects(stringifiedSubjects: string | string[] | undefined): Map<number, number> {
    const subjects: Map<number, number> = new Map();
    if (stringifiedSubjects) {
        if (Array.isArray(stringifiedSubjects)) {
            stringifiedSubjects.forEach((subject: string) => {
                const subjectArray = subject.split(",");
                subjects!.set(+subjectArray[0], +subjectArray[1]);
            });
        } else {
            const subjectArray = stringifiedSubjects.split(",");
            subjects!.set(+subjectArray[0], +subjectArray[1]);
        }
    }
    return subjects;
}

function transformInboundSelectedAdditions(selectedAdditions: { [key: number]: SelectedAddition } | undefined) {
    if (!selectedAdditions) {
        return undefined;
    }
    Object.keys(selectedAdditions).forEach((resourceId: string) => {
        const selectedAddition = selectedAdditions[+resourceId];
        selectedAddition.daysAndSubjectsConfig?.forEach((config) => {
            config.days?.forEach((day) => {
                // Redux-persist stores the date as a string so we need to parse it back to date. See: https://github.com/rt2zz/redux-persist/issues/82
                day.date = DateUtil.parseDate((day.date as unknown) as string, DATE_FORMAT.REDUX_PERSIST_DATETIME);
            });
        });
    });
    return selectedAdditions;
}
