export enum TimeDisplayPattern {
    DROPDOWN_FORMAT = "DROPDOWN_FORMAT",
    GRID_FORMAT = "GRID_FORMAT",
}

export enum TimeslotStatus {
    CANCELLED = "CANCELLED",
    SOLD_OUT = "SOLD_OUT",
    AVAILABLE = "AVAILABLE",
}
