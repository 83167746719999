import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../../form-specs";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { NoContentFoundWidgetOptions, noContentFoundInputSpec } from "../../../../components/noContentFound/NoContentFound";
import { PrintConfigurationOptions, printConfigurationSpec } from "../../../../utils/print.util";
import contractsAutocomplete, { ContractWidgetOptions } from "../../../../inputSpecs/contractsAutocomplete";

import { OwnerRevenueStreamsWidget } from "./OwnerRevenueStreamsWidget";
import { PageWidgetSpec } from "../../../widget";
import { WidgetGroup } from "../../../widget.enum";
import { getI18nLocaleObject } from "../../../../i18n";
import { getYearSelectOptions } from "../ownerSelectOptions.util";
import namespaceList from "../../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions, NoContentFoundWidgetOptions, ContractWidgetOptions, PrintConfigurationOptions {
    styleIds?: string[];
    showMinimizedVersionOfRevenueStream?: boolean;
    displayRevenuePercentage?: boolean;
    yearToShowTheNetRevenue?: number;
    previousYearColor?: string;
    currentYearColor?: string;
    nextYearColor?: string;
    chartType?: "bar" | "line";
    revenueType?: "ownerNet" | "totalTurnover";
}

const yearSelectOptions = getYearSelectOptions();

export const DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS = {
    PREVIOUS_YEAR: "#4bc0c0",
    CURRENT_YEAR: "#ff6384",
    NEXT_YEAR: "#36a2eb",
};

const STYLE = ["OwnerRevenueStreamsWidget"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "ownerRevenueStreams-widget",
    name: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "ownerRevenueStreamsWidgetName"),
    pluralName: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "ownerRevenueStreamsWidgetsName"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getWidgetTitleOptions(),
                            {
                                variable: "showMinimizedVersionOfRevenueStream",
                                label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "showMinimizedVersionOfRevenueStream"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "displayRevenuePercentage",
                                label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "displayRevenuePercentage"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => !!options.showMinimizedVersionOfRevenueStream,
                            },
                            {
                                variable: "yearToShowTheNetRevenue",
                                label: getI18nLocaleObject(namespaceList.ownerDocumentInvoiceWidget, "selectedDefaultYear"),
                                type: "select",
                                optionList: yearSelectOptions,
                                default: yearSelectOptions[1].value,
                            },
                            {
                                type: "select",
                                variable: "revenueType",
                                label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "revenueType"),
                                optionList: ["ownerNet", "totalTurnover"].map((type) => ({
                                    label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, `revenueType-${type}`),
                                    value: type,
                                })),
                                default: "ownerNet",
                            },
                            ...contractsAutocomplete("contractTypeMultiSelector"),
                            ...printConfigurationSpec<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", STYLE),
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                type: "select",
                                variable: "chartType",
                                label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "chartType"),
                                optionList: ["bar", "line"].map((type) => ({
                                    label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, `chartType-${type}`),
                                    value: type,
                                })),
                                default: "bar",
                            },
                            {
                                variable: "previousYearColor",
                                label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "previousYearColor"),
                                type: "dual-color",
                                useRgbaInsteadOfThemeVariable: true,
                                default: DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS.PREVIOUS_YEAR,
                            },
                            {
                                variable: "currentYearColor",
                                label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "currentYearColor"),
                                type: "dual-color",
                                useRgbaInsteadOfThemeVariable: true,
                                default: DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS.CURRENT_YEAR,
                            },
                            {
                                variable: "nextYearColor",
                                label: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "nextYearColor"),
                                type: "dual-color",
                                useRgbaInsteadOfThemeVariable: true,
                                default: DEFAULT_OWNER_REVENUE_STREAM_WIDGET_COLORS.NEXT_YEAR,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            ...noContentFoundInputSpec<WidgetOptions>(),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const ownerRevenueStreamsWidget: PageWidgetSpec<WidgetOptions> = {
    id: "OwnerRevenueStreamsWidget",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.MY_ENVIRONMENT : 7,
    name: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "ownerRevenueStreamsWidgetName"),
    description: getI18nLocaleObject(namespaceList.ownerRevenueStreamsWidget, "ownerRevenueStreamsWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        yearToShowTheNetRevenue: Number(yearSelectOptions[1].value),
        revenueType: "ownerNet",
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        return <OwnerRevenueStreamsWidget options={widget.options} dynamicContainerOptions={dynamicContainerOptions} context={context} />;
    },
};
