import * as React from "react";

import { Provider, ReactReduxContext } from "react-redux";

export const withProvider = <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: any) => {
    const isProviderWrapped = React.useContext(ReactReduxContext);
    const reduxStore = props?.context?.reduxStore?.store;

    if (!isProviderWrapped && reduxStore) {
        return (
            <Provider store={reduxStore}>
                <WrappedComponent {...props} />
            </Provider>
        );
    }
    return <WrappedComponent {...props} />;
};
