import { CMSOptions } from "../plugins/settings";

export function getLocaleCodeFromCMSOptions(cmsOptions: CMSOptions): string {
    let code = "en";
    if (cmsOptions.selectedLocales?.[0]) {
        code = cmsOptions.selectedLocales[0].value;
    } else if (cmsOptions.locale) {
        code = cmsOptions.locale;
    }
    return code;
}
