import * as MXTS from "@maxxton/cms-mxts-api";

import { Locale, LocaleApi, WithId } from "@maxxton/cms-api";

import { ComponentConstructor } from "../containers";

export * from "./namespace";

export * from "./loader";

/*
Solution to: Property does not exist on type 'IntrinsicAttributes & { children?: ReactNode; }' tslint error.
Reference: https://stackoverflow.com/questions/61853338/property-does-not-exist-on-type-intrinsicattributes-children-reactnode
*/
export function wrapProps<P>(Wrapped: ComponentConstructor<P, any>): ComponentConstructor<P, {}> {
    return Wrapped;
}

export interface LanguageMapping {
    [languageId: number]: {
        language: MXTS.Language;
        locale: Locale & WithId;
    };
}

export async function loadLanguageMapping(mxtsApi: MXTS.MxtsApiWrapper, ops: MXTS.ApiCallOptions): Promise<LanguageMapping> {
    const [languages, locales] = await Promise.all([mxtsApi?.languages(ops, {}), LocaleApi.find()]);
    return matchLanguages(locales, languages);
}

export function matchLanguages(locales: Array<Locale & WithId>, languages: MXTS.Language[]): LanguageMapping {
    const mapping: LanguageMapping = {};
    languages?.forEach((language) => {
        const code = language.shortName.toLowerCase();
        const locale = locales.find((loc) => loc.code.toLocaleLowerCase().startsWith(code));
        if (locale) {
            mapping[language.languageId] = { language, locale };
        }
    });
    return mapping;
}
