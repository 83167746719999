import * as React from "react";

import { CachePriority, CacheStrategy, LocaleAssets, Page, PageApi, Site, WithId } from "@maxxton/cms-api";
import { FormSpec, localized } from "../";
import { categoryTableColumn, getLockedPageNameJsx, getTimeStampOptions, renderJsxElement } from "../columnSpec.util";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { DYNAMIC_SEO_OPTIONS } from "../../utils/dynamicSeo.util";
import { META_ROBOTS_DEFAULT } from "../../utils/constants";
import { autocompletePageSpec } from "./autocompletePage";
import { autocompleteSiteSpec } from "./autocompleteSite";
import { dataLayerSpec } from "./dataLayer";
import { findAffectedSites } from "../utils";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";
import { renderPageWidgets } from "../../plugins";
import { widgetHierarchy } from "./";

const Localized = loadable(() => loadableRetry(() => import("../../containers/Localized")), {
    resolveComponent: ({ Localized }) => Localized,
});

export const pageSpec: FormSpec<Page> = {
    id: "page",
    name: getI18nLocaleObject(namespaceList.admin, "page"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "pages"),
    api: PageApi,

    permission: "webmanager.pages",
    display(page: Page): string {
        return page.name;
    },
    async preview(page: Page, context: CMSProvidedProperties): Promise<JSX.Element> {
        if (!Array.isArray(page.root) || page.root.length === 0) {
            return <Localized key={getI18nLocaleString(namespaceList.admin, "noWidget")} />;
        }
        const children = await renderPageWidgets(page.root, context);
        return <div className="page-preview">{children}</div>;
    },
    async linkage(id: string) {
        const affectedSites = await findAffectedSites(id, "page");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedSites"),
                links: affectedSites,
                specId: "site",
            },
        ];
    },
    viewOnSite(page: any) {
        window.open(`//${window.location.hostname}/pages/${page._id}`);
    },
    tableColumns: [
        {
            name: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            uniqueId: "name",
            type: "jsx",
            jsxLoadingElement: (item) => <span>{item.name}</span>,
            jsxElement: async (item: Page & Site & WithId) => renderJsxElement(item, (item: Page & Site & WithId) => <span>{item.name}</span>, getLockedPageNameJsx),
        },
        {
            name: getI18nLocaleObject(namespaceList.admin, "enableRedirect"),
            variable: "enableRedirect",
            type: "checkbox",
        },
        ...getTimeStampOptions(),
        categoryTableColumn(),
    ],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            widgetHierarchy<Page, "root">({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "root",
                                rootTitle: getI18nLocaleObject(namespaceList.admin, "onPage"),
                                templatesTitle: getI18nLocaleObject(namespaceList.admin, "widgets"),
                                widgetType: "page",
                                rootType: "multiple",
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "seo"),
                    properties: [
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "seoItems"),
                                variable: "localizedContents",
                                visible: (options: Page) => !options?.enableDynamicDescription,
                                tabContent: [
                                    {
                                        variable: "metaTitleSeo",
                                        type: "inputWithTags",
                                        required: true,
                                        tagOptions: DYNAMIC_SEO_OPTIONS,
                                        widgetLabel: getI18nLocaleObject(namespaceList.admin, "metaTitle"),
                                    },
                                    {
                                        variable: "metaDescriptionSeo",
                                        type: "inputWithTags",
                                        required: true,
                                        tagOptions: DYNAMIC_SEO_OPTIONS,
                                        widgetLabel: getI18nLocaleObject(namespaceList.admin, "metaDescription"),
                                    },
                                    {
                                        variable: "metaTitleSeo",
                                        type: "seo",
                                        characterCounts: 60,
                                        visible: (options: LocaleAssets) => !!options.metaTitleSeo,
                                    },
                                    {
                                        variable: "metaDescriptionSeo",
                                        type: "seo",
                                        characterCounts: 160,
                                        visible: (options: LocaleAssets) => !!options.metaDescriptionSeo,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "metaKeywords"),
                                        variable: "metaKeywordsSeo",
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "seoItems"),
                                variable: "localizedContents",
                                visible: (options: Page) => !!options?.enableDynamicDescription,
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "metaTitle"),
                                        variable: "metaTitleSeo",
                                        type: "inputWithTags",
                                        required: true,
                                        tagOptions: DYNAMIC_SEO_OPTIONS,
                                    },
                                    {
                                        variable: "metaTitleSeo",
                                        type: "seo",
                                        characterCounts: 60,
                                        visible: (options: LocaleAssets) => !!options.metaTitleSeo,
                                    },

                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "metaKeywords"),
                                        variable: "metaKeywordsSeo",
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "disableCrawling"),
                                variable: "disableCrawling",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enableDynamicDescription"),
                                variable: "enableDynamicDescription",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "metaRobots"),
                                variable: "metaRobots",
                                type: "text",
                                default: META_ROBOTS_DEFAULT,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "disableCanonicalUrl"),
                                variable: "disableCanonicalUrl",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "structuredDataBox"),
                                variable: "structuredData",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enableRedirect"),
                                variable: "enableRedirect",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "site"),
                                visible: (item: any) => item.enableRedirect && !item.useExternalLink,
                                variable: "siteId",
                                type: "autocomplete",
                                default: "",
                                refType: autocompleteSiteSpec,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "page"),
                                visible: (item: any) => item.enableRedirect && !item.useExternalLink,
                                variable: "pageId",
                                type: "autocomplete",
                                default: "",
                                refType: autocompletePageSpec,
                                dependsOnSiteSpec: "siteId",
                            },
                            {
                                variable: "useExternalLink",
                                label: getI18nLocaleObject(namespaceList.admin, "useExternalLink"),
                                type: "checkbox",
                                visible: (options: Page) => options.enableRedirect,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "redirect"),
                                variable: "redirect",
                                type: "text",
                                visible: (options: Page) => options.useExternalLink,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "tabOptions"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enablePageDataLayer"),
                                variable: "enableDataLayer",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "selectDataLayer"),
                                variable: "dataLayerId",
                                type: "autocomplete",
                                refType: dataLayerSpec,
                                visible: (options: Page) => !!options.enableDataLayer,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "pageEventName"),
                                variable: "pageEventName",
                                type: "text",
                                visible: (options: Page) => !!options.enableDataLayer,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "shouldGenerateStateUuid"),
                                variable: "shouldGenerateStateUuid",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "cacheStrategy"),
                                variable: "cacheStrategy",
                                default: CacheStrategy.AUTO,
                                type: "autocomplete",
                                isClearable: false,
                                options: async () => [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "optimized"),
                                        value: CacheStrategy.OPTIMIZED,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "optimistic"),
                                        value: CacheStrategy.OPTIMISTIC,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "lazy"),
                                        value: CacheStrategy.LAZY,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "auto"),
                                        value: CacheStrategy.AUTO,
                                    },
                                ],
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "cachePriority"),
                                variable: "cachePriority",
                                default: CachePriority.AUTO,
                                type: "autocomplete",
                                isClearable: false,
                                options: async () => [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "high"),
                                        value: CachePriority.HIGH,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "medium"),
                                        value: CachePriority.MEDIUM,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "low"),
                                        value: CachePriority.LOW,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "auto"),
                                        value: CachePriority.AUTO,
                                    },
                                ],
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "structuredData"),
                    visible: (options: Page) => options.structuredData,
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enableDyanmicOgTags"),
                                variable: "enableDyanmicOgTags",
                                type: "checkbox",
                            },
                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "structuredData"),
                                variable: "localizedContents",
                                visible: (options: Page) => !options?.enableDyanmicOgTags,
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "image"),
                                        variable: "imageStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "title"),
                                        variable: "titleStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "url"),
                                        variable: "urlStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "description"),
                                        variable: "descriptionStructuredSeo",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "author"),
                                        variable: "authorStructuredSeo",
                                        type: "text",
                                    },
                                ],
                            }),

                            localized({
                                label: getI18nLocaleObject(namespaceList.admin, "structuredData"),
                                variable: "localizedContents",
                                visible: (options: Page) => !!options?.enableDyanmicOgTags,
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "title"),
                                        variable: "titleStructuredSeo",
                                        type: "inputWithTags",
                                        tagOptions: DYNAMIC_SEO_OPTIONS,
                                        inheritFrom: "metaTitleSeo",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "description"),
                                        variable: "descriptionStructuredSeo",
                                        type: "inputWithTags",
                                        tagOptions: DYNAMIC_SEO_OPTIONS,
                                        inheritFrom: "metaDescriptionSeo",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "author"),
                                        variable: "authorStructuredSeo",
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "structuredDataMarkupTab"),
                    properties: [
                        [
                            localized({
                                variable: "localizedContents",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.admin, "structuredDataMarkup"),
                                        variable: "structuredDataMarkup",
                                        type: "textarea",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "category"),
                    properties: [
                        [
                            {
                                variable: "category",
                                type: "category",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};
