import * as MXTS from "@maxxton/cms-mxts-api";

import { ApiContext, CMSProviderProperties } from "../../../containers/cmsProvider.types";

import { ActionType } from "../../../redux/actions/index";
import { ArrayUtil } from "../../../utils/array.util";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Dispatch } from "redux";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { StringUtil } from "../../../utils/string.util";
import { SubjectResult } from "./Subject";
import { WidgetOptions } from "./";
import { cloneDeep } from "lodash";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getCapacities } from "../../page/travel-party/travelParty.util";
import { getMxtsEnv } from "../../mxts";

export interface SubjectQuantityLimit {
    subjectId: number;
    quantityLimit: number;
    includeInTotal?: boolean;
    type: string;
    maxAge?: number;
    isTotal?: boolean;
    selfCapacity?: boolean;
}

// TODO: add unit tests
export class SubjectUtil {
    public static async getSubjectQuantityLimits(params: {
        availabilityState: AvailabilityState;
        subjects: Map<SubjectResult, number | undefined>;
        context: CMSProviderProperties;
        resourceId?: number;
        useSubjectForActivity?: boolean;
        resourceActivityDetailsId?: number;
        arrivalDate?: string;
        options?: WidgetOptions;
    }): Promise<{ subjectCountLimits: SubjectQuantityLimit[]; initialSubjectQuantityLimits: SubjectQuantityLimit[] }> {
        const { arrivalDate, availabilityState, subjects, context, resourceId, useSubjectForActivity, resourceActivityDetailsId, options } = params;
        const capacityOptions = this.getAvailabilityCapacityOptions(availabilityState);
        let initialSubjectQuantityLimits: SubjectQuantityLimit[] = [];
        if ((!capacityOptions?.length && !useSubjectForActivity) || !subjects?.size) {
            return { subjectCountLimits: [], initialSubjectQuantityLimits: [] };
        }
        const subjectCountLimits: SubjectQuantityLimit[] = [];
        subjects.forEach((count = 0, subject) => {
            subjectCountLimits.push({
                subjectId: subject.subjectId,
                quantityLimit: useSubjectForActivity ? subject.capacity : this.getSubjectQuantityLimit(subject, capacityOptions, count, resourceId, options),
                includeInTotal: subject.includeInTotal,
                type: subject.type,
                maxAge: subject.maxAge,
            });
        });

        const totalPersonLimit = subjectCountLimits.find((subject) => StringUtil.equalsIgnoreCase(subject.type, "PERSON") && subject.includeInTotal)?.quantityLimit ?? 0;

        if (resourceId) {
            const env = await getMxtsEnv(context);
            const capacitiesFromMxts = await getCapacities({ mxtsApi: MXTS.MxtsApi, env, resourceId, inherited: true, resourceActivityDetailsId, arrivalDate });
            capacitiesFromMxts.forEach((capacityFromMxts) => {
                const subjectIndex = subjectCountLimits.findIndex((subject) => subject.subjectId === capacityFromMxts.subjectId);
                if (subjectIndex !== -1) {
                    subjectCountLimits[subjectIndex].quantityLimit = capacityFromMxts.capacity;
                    subjectCountLimits[subjectIndex].selfCapacity = capacityFromMxts.selfCapacity;
                }
                initialSubjectQuantityLimits = cloneDeep(subjectCountLimits);
            });
        } else {
            initialSubjectQuantityLimits = cloneDeep(subjectCountLimits);
        }
        for (const [subject, count] of subjects.entries()) {
            subjectCountLimits.forEach((subjectCount) => {
                if (subjectCount.quantityLimit > 0 && count && count > 0 && subject.type === subjectCount.type) {
                    subjectCount.quantityLimit -= count || 0;
                }
            });
        }
        subjectCountLimits.push({ subjectId: -1, quantityLimit: totalPersonLimit, isTotal: true, type: "PERSON" });
        return { subjectCountLimits, initialSubjectQuantityLimits };
    }

    public static getMaxAllowedQuantityForSubject(subject: MXTS.Subject, subjectQuantityLimits: SubjectQuantityLimit[]): number {
        const maxSubjectSpecificLimit = subjectQuantityLimits.find((subjectLimit) => subjectLimit.subjectId === subject.subjectId)?.quantityLimit || 0;
        if (StringUtil.equalsIgnoreCase(subject.type, "PET")) {
            return maxSubjectSpecificLimit;
        }

        // Inherited Subjects are default, acco capacity is not required
        // Refactor/remove in MCMS-9428
        // if (StringUtil.equalsIgnoreCase(subject.type, "PERSON")) {
        //     if (subject.includeInTotal) {
        //         const maxCapacity = subjectQuantityLimits.find((limit) => limit.isTotal)?.quantityLimit || 0;
        //         return maxCapacity;
        //     }
        //     return maxSubjectSpecificLimit;
        // }

        return maxSubjectSpecificLimit;
    }

    /**
     * Converts subjectCategories back to their original subjects according to the resort
     * See: MCMS-3177
     */
    public static async convertSubjectCategoriesBackToSubjects(
        context: ApiContext,
        dynamicFilter: DynamicFilter,
        env: MXTS.ApiCallOptions,
        selectedSubjects: Map<number, number>,
        dispatchAction: Dispatch<FilterChangeAction>
    ): Promise<Map<number, number>> {
        const resortIds: number[] | undefined = await SubjectUtil.getParentResortIdsFromDynamicFilter(context.mxtsApi, dynamicFilter, env);
        selectedSubjects = await SubjectUtil.getSelectedSubjectsBySubjectCategories(context.mxtsApi, selectedSubjects, resortIds, env);

        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.subjects,
            payload: {
                subject: selectedSubjects,
                useSubjectCategory: false,
            },
        };
        dispatchAction(action);
        return selectedSubjects;
    }

    public static async getParentResortIdsFromDynamicFilter(
        mxtsApi: MXTS.MxtsApiWrapper,
        dynamicFilter: DynamicFilter,
        env: MXTS.ApiCallOptions,
        myEnvState?: MyEnvState
    ): Promise<number[] | undefined> {
        // TODO also support dynamicFilter.resortids
        let resortId: number | undefined;
        if (dynamicFilter.resort) {
            const resort = await DomainObjectUtil.getResort(mxtsApi, { code: dynamicFilter.resort }, env);
            resortId = resort?.resortId;
        }
        if (!resortId && dynamicFilter.resourceid) {
            const resource = await DomainObjectUtil.getResourceById(mxtsApi, dynamicFilter.resourceid, env);
            resortId = resource?.resortId;
        }
        if (!resortId && dynamicFilter.unitid) {
            const unit = await DomainObjectUtil.getUnitById(mxtsApi, dynamicFilter.unitid, env);
            resortId = unit?.resortId;
        }
        if (!resortId && myEnvState?.ownerState?.selectedUnitId) {
            const unit = await DomainObjectUtil.getUnitById(mxtsApi, myEnvState.ownerState.selectedUnitId, env);
            resortId = unit?.resortId;
        }
        if (resortId) {
            const resortParents: MXTS.Resort[] = await mxtsApi.resortParents(env, {}, [{ key: "resortId", value: resortId }]);
            // Exclude the top level resort.
            return resortParents.filter((parentResort) => parentResort.parentId != null).map((parentResort: MXTS.Resort) => parentResort.resortId);
        }
        return undefined;
    }

    public static getSubjectQuantitiesFromMap(subjectsMap?: Map<number, number>): MXTS.SubjectQuantity[] {
        const subjects: MXTS.SubjectQuantity[] = [];
        if (subjectsMap) {
            subjectsMap.forEach((quantity: number, subjectId: number) => {
                if (quantity) {
                    subjects.push({ subjectId, quantity });
                }
            });
        }
        return subjects;
    }

    private static getSubjectQuantityLimit(subject: MXTS.Subject, capacityOptions: Array<MXTS.Document | MXTS.UnitDocument>, subjectCount: number, resourceId?: number, widgetOptions?: WidgetOptions) {
        if (!capacityOptions?.length) {
            return 0;
        }

        if (StringUtil.equalsIgnoreCase(subject.type, "PET")) {
            return ArrayUtil.getMaxByField<any>(capacityOptions, "petCapacity").petCapacity ?? 0;
        }

        if (StringUtil.equalsIgnoreCase(subject.type, "PERSON")) {
            const capacity: number = resourceId
                ? this.getTotalPersonLimit(subject, capacityOptions)
                : 32 - ((widgetOptions?.isDefaultGuestSelected && subjectCount === widgetOptions.defaultGuestValue && widgetOptions.defaultGuestValue) || 0);
            return capacity;
        }
        return 0;
    }

    private static getTotalPersonLimit(subject: MXTS.Subject, capacityOptions: Array<MXTS.Document | MXTS.UnitDocument>): number {
        if (!capacityOptions?.length) {
            return 0;
        }
        if (StringUtil.equalsIgnoreCase(subject.type, "PERSON")) {
            const capacity: number = ArrayUtil.getMaxByField<any>(capacityOptions, "capacity").capacity;
            if (capacity === undefined) {
                // old availability endpoint uses the key "totalCapacity for maximum subject quantity/capacity"
                return ArrayUtil.getMaxByField(capacityOptions, "totalCapacity").totalCapacity ?? 0;
            }
            return capacity;
        }
        return 0;
    }

    private static getResourcesCapacityFromResortGroup(resortGroup: MXTS.ResortGroup): MXTS.Document[] {
        const resources: MXTS.ResourceGroup = [];
        resortGroup.forEach((resort) => {
            resort.accommodationTypes?.forEach((resource) => resources.push(resource));
        });
        return resources;
    }

    private static getAvailabilityCapacityOptions(availabilityState: AvailabilityState): Array<MXTS.Document | MXTS.UnitDocument> {
        const resources = availabilityState?.availabilityResult?.response?.resources;
        const units = availabilityState?.availabilityResult?.response?.units;
        const resortGroup = availabilityState?.availabilityResult?.response?.resortGroup;
        if (units?.length) {
            return units;
        }
        if (resources?.length) {
            return resources;
        }
        if (resortGroup?.length) {
            return this.getResourcesCapacityFromResortGroup(resortGroup);
        }
        return [];
    }

    /**
     * Convert back from subject categories to actual subjects of type "person"
     */
    private static async getSelectedSubjectsBySubjectCategories(
        mxtsApi: MXTS.MxtsApiWrapper,
        selectedSubjectCategories: Map<number, number>,
        resortIds: number[] | undefined,
        env: MXTS.ApiCallOptions
    ): Promise<Map<number, number>> {
        const selectedSubjects: Map<number, number> = new Map();
        if (!resortIds) {
            return selectedSubjects;
        }
        const subjects: MXTS.Subject[] = (
            await mxtsApi.subjects(env, {
                subjectCategoryIds: [...selectedSubjectCategories.keys()],
                types: ["PERSON", "PET"],
            })
        ).content;

        await Promise.all(
            [...selectedSubjectCategories.keys()].map(async (selectedSubjectCategoryId: number) => {
                const subjForThisResort = subjects.find((subj) => subj.subjectCategoryId === selectedSubjectCategoryId && resortIds.some((resortId) => resortId === subj.resortId));
                const selectedSubjectCategoryCount = selectedSubjectCategories.get(selectedSubjectCategoryId);
                if (subjForThisResort) {
                    const existingSubjectCount = selectedSubjects.get(subjForThisResort.subjectId);
                    selectedSubjects.set(subjForThisResort.subjectId, (existingSubjectCount || 0) + (selectedSubjectCategoryCount || 0));
                } else {
                    // The selected subject category doesn't exist on this resort. We'll have to fallback to a similar one according to the maxAge
                    const similarSubject: MXTS.Subject | undefined = await SubjectUtil.getSubjectForResortWithSimilarMaxAge(mxtsApi, selectedSubjectCategoryId, resortIds, env);
                    if (similarSubject) {
                        const existingSubjectCount = selectedSubjects.get(similarSubject.subjectId);
                        selectedSubjects.set(similarSubject.subjectId, (existingSubjectCount || 0) + (selectedSubjectCategoryCount || 0));
                    }
                }
            })
        );

        // We are going to assume that the missing subject is the pet
        const petSubjectIds = [...selectedSubjectCategories.keys()].filter((subjectCategoryId: number) => !subjects.find((subj) => subj.subjectCategoryId === subjectCategoryId));
        petSubjectIds.forEach((petSubjectId) => {
            const petSubjectCount = selectedSubjectCategories.get(petSubjectId);
            if (petSubjectCount) {
                selectedSubjects.set(petSubjectId, petSubjectCount);
            }
        });

        return selectedSubjects;
    }

    private static async getSubjectForResortWithSimilarMaxAge(mxtsApi: MXTS.MxtsApiWrapper, subjectId: number, resortIds: number[], env: MXTS.ApiCallOptions): Promise<MXTS.Subject | undefined> {
        const [resortSubjects, targetSubject] = await Promise.all([
            mxtsApi
                .subjects(env, {
                    resortId: resortIds,
                    // TODO: endpoint breaks when passing only a single string in the array. Fix this in a better way
                    types: ["PERSON", "PERSON"],
                })
                .then((resp: MXTS.PagedResult<MXTS.Subject>) => resp.content),
            mxtsApi
                .subjects(env, {
                    // TODO: endpoint breaks when passing only a single string in the array. Fix this in a better way
                    types: ["CATEGORY", "CATEGORY"],
                    subjectIds: [subjectId],
                })
                .then((resp: MXTS.PagedResult<MXTS.Subject>) => resp.content),
        ]);

        if (targetSubject.length) {
            const targetMaxAge = targetSubject[0].maxAge == null ? 999 : targetSubject[0].maxAge;
            let closestMatch: MXTS.Subject | undefined;
            let closestMatchMaxAge: number | undefined;
            resortSubjects.forEach((resortSubject: MXTS.Subject) => {
                const resortSubjectMaxAge = resortSubject.maxAge == null ? 999 : resortSubject.maxAge;

                if (closestMatchMaxAge == null || (resortSubjectMaxAge >= closestMatchMaxAge && resortSubjectMaxAge <= targetMaxAge)) {
                    closestMatch = resortSubject;
                    closestMatchMaxAge = closestMatch.maxAge == null ? 999 : closestMatch.maxAge;
                }
            });
            return closestMatch;
        }
        return undefined;
    }

    /**
     * Get the total selected subjects whose type is person and it is included in total
     * @param subjects
     * @return number
     */
    public static getTotalPersonSelected = (subjects: Map<MXTS.Subject, number | undefined>): number => {
        let totalPersonSelected = 0;
        subjects.forEach((count: number, subject: MXTS.Subject) => {
            if (StringUtil.equalsIgnoreCase(subject.type, "PERSON") && subject.includeInTotal) {
                totalPersonSelected += count;
            }
        });
        return totalPersonSelected;
    };
}
