import { SessionAction, SessionActionType } from "../actions/sessionAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface SessionState {
    sessionEndTime?: string;
    hiddenWidgetId?: string[];
}

export const sessionReducer: Reducer<SessionState> = (state: SessionState = {}, action: SessionAction): SessionState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.SessionCountDown:
            switch (actionType) {
                case SessionActionType.SET_SESSION_END_TIME:
                    return {
                        ...state,
                        sessionEndTime: action.payload.sessionEndTime,
                    };
                case SessionActionType.SET_HIDDEN_WIDGET:
                    return {
                        ...state,
                        hiddenWidgetId: action.payload.hiddenWidgetId,
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
};
