import { ApiContext } from "../containers/cmsProvider.types";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { globalApiContext } from "../containers/CmsProvider";
import { imageProvider } from "../plugins/mxts/imageProvider";

export interface ImageProvider {
    id: string;
    images(apiContext: ApiContext): Promise<ImageGroupAndContent>;
    imageBySpec(apiContext: ApiContext, spec: ImageSpec): Promise<Image | null>;
    imagesBySpec(apiContext: ApiContext, specs: ImageSpec[]): Promise<Image[]>;
    mapToCloudinary(spec: ImageSpec): Image;
}

export interface ImageGroup {
    name: string;
    groupId?: number;
    loadContent(): Promise<ImageGroupAndContent>;
    loadParent(): Promise<ImageGroup | null>;
    loadParentWithContent(): Promise<ImageGroupAndContent | null>;
}

export interface ImageGroupAndContent extends ImageGroup {
    name: string;
    subgroups: ImageGroup[];
    content: Image[];
}

export interface ImagePresets {
    [name: string]: {
        url: string | null;
    };
}

export interface I18nImage {
    locale: string;
    name: string;
    alt: string;
    description: string;
}

export interface Image {
    mediaType?: string;
    imageType?: string;
    caption?: string;
    id: string;
    url: string | null;
    namePath?: string;
    name: string;
    thumbnailUrl: string;
    originalUrl?: string;
    presets: ImagePresets;
    loadTranslations(context?: ApiContext): Promise<I18nImage[]>;
    loadGroup(): Promise<ImageGroupAndContent>;
    toSpec(options: ImageSpecOptions): ImageSpec;
    renderUrl(spec: ImageSpec, localeOptions: LocalizedImageSpecOptions, innerWidth?: number): string; // Component image rendering
    renderArrayUrl(
        presetName: string,
        width?: number,
        height?: number,
        localeOptions?: LocalizedImageSpecOptions,
        quality?: string | null,
        xPlacement?: number,
        yPlacement?: number,
        setBoundingBox?: boolean,
        boxWidth?: number,
        boxHeight?: number,
        innerWidth?: number // Component image rendering
    ): string;
}

export interface LocalizedImageSpecOptions extends LocalizedContentBase {
    friendlyName?: string;
    alt?: string;
}

export interface ImageSpecOptions {
    preset: string;
    width?: number;
    height?: number;
    originalUrl?: string;
    xPlacement?: number;
    yPlacement?: number;
    quality?: string | null;
    setBoundingBox?: boolean;
    boxWidth?: number;
    boxHeight?: number;
    useCircle?: boolean;
    useFace?: boolean;
    multipleFaces?: boolean;
    useImagePosition?: boolean;
    imagePosition?: string | null;
    zoomImage?: number;
    localized: LocalizedImageSpecOptions[];
}

export interface ImageSpec {
    providerId: string;
    imageId: string;
    options: ImageSpecOptions;
    originalUrl?: string;
    caption?: string;
    name?: string;
    namePath?: string;
}

export function imageSpec(provider: ImageProvider, img: Image, options: ImageSpecOptions): ImageSpec {
    return {
        providerId: provider.id,
        imageId: img.id,
        originalUrl: img.originalUrl,
        options,
        caption: img.caption,
        name: img.name,
        namePath: img.namePath,
    };
}

export const imageProviders: ImageProvider[] = [];

export const fallbackImage: ImageSpec = {
    imageId: "",
    options: {
        localized: [],
        preset: "none",
    },
    originalUrl: "https://newyse-res.cloudinary.com/image/upload/t_mcms_larger/f_auto/image-fallback.jpg",
    providerId: "mxts",
};

export function registerImageProvider(provider: ImageProvider): void {
    imageProviders.push(provider);
}

export function findProviderForSpec(spec: ImageSpec): ImageProvider | undefined {
    if (spec) {
        return imageProviders.find((ip) => ip.id === spec.providerId);
    }
    return undefined;
}

export async function findImageBySpec(apiContext: ApiContext, spec: ImageSpec): Promise<Image | null> {
    const provider = findProviderForSpec(spec);
    if (provider === undefined) {
        return null;
    }

    return provider.imageBySpec(apiContext, spec);
}

export async function findImagesBySpecs(apiContext: ApiContext, imageSpecs: ImageSpec[]): Promise<Image[]> {
    return imageProvider.imagesBySpec(apiContext, imageSpecs);
}

export async function renderImageByCloudinary(apiContext: ApiContext, spec: ImageSpec) {
    const provider = findProviderForSpec(spec);
    if (provider === undefined) {
        return null;
    }
    return provider.imageBySpec(apiContext, spec);
}

/**
 * Returns an ImageGroupAndContents which might be merged by combining the images of multiple providers
 */
export async function imagesRoot(apiContext: ApiContext): Promise<ImageGroupAndContent> {
    // TODO implement merging
    const provider = imageProviders[0];
    if (provider === undefined) {
        throw new Error("No image provider");
    }
    return provider.images(apiContext);
}

export async function getImageSpec(context: ApiContext, imgSpec: ImageSpec[] | ImageSpec): Promise<Image | null> {
    let image: Image | null;
    const apiContext = context?.mxtsApi ? context : globalApiContext();

    if (Array.isArray(imgSpec)) {
        image = await renderImageByCloudinary(apiContext, imgSpec[0]);
        if (!image) {
            image = await renderImageByCloudinary(apiContext, fallbackImage);
        }
    } else {
        image = await renderImageByCloudinary(apiContext, imgSpec);
        if (!image) {
            image = await renderImageByCloudinary(apiContext, fallbackImage);
        }
    }
    return image;
}
