import { validateText } from "w3c-css-validator";

interface LocalizedList {
    content?: string;
    locale: string;
}

export async function validateCss(localizedList: LocalizedList[]) {
    let verifiedLocalized: string[] = [];
    const validation = await Promise.all(
        localizedList?.map(async (localized) => {
            if (localized.content) {
                const css = JSON.parse(localized.content)
                    ?.blocks?.map((block: any) => (!block.text.trim() && "\n") || block.text)
                    .join("\n");

                if (!!css.replace(/\s/g, "").length && !isLocalizedContentSame(css, verifiedLocalized)) {
                    verifiedLocalized = [...verifiedLocalized, css];
                    const validationResult = await validateText(css);
                    const resultWithLocalizedId = { ...validationResult, locale: localized.locale };
                    return resultWithLocalizedId;
                }
            }
        })
    );
    return validation;
}

function isLocalizedContentSame(cssString: string, verifiedLocalized: string[]) {
    for (const locale in verifiedLocalized) {
        if (verifiedLocalized[locale].trim().replace(/\r?\n|\r/g, "") === cssString.trim().replace(/\r?\n|\r/g, "")) {
            return true;
        }
    }
    return false;
}
