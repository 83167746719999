// eslint-disable

// This is a dependency file, hence not to be changed.
import * as React from "react";
import * as classNames from "classnames";

class Option extends React.Component<any, any> {
    dragging: any;
    static propTypes: {
        children: any;
        className: any; // className (based on mouse position)
        instancePrefix: any; // unique prefix for the ids (used for aria)
        isDisabled: any; // the option is disabled
        isFocused: any; // the option is focused
        isSelected: any; // the option is selected
        onFocus: any; // method to handle mouseEnter on option element
        onSelect: any; // method to handle click on option element
        onUnfocus: any; // method to handle mouseLeave on option element
        option: any; // object that is base for that option
        optionIndex: any;
    };

    constructor(props: any) {
        super(props);

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    blockEvent(event: any) {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.tagName !== "A" || !("href" in event.target)) {
            return;
        }
        if (event.target.target) {
            window.open(event.target.href, event.target.target);
        } else {
            window.location.href = event.target.href;
        }
    }

    handleMouseDown(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onSelect(this.props.option, event);
    }

    handleMouseEnter(event: any) {
        this.onFocus(event);
    }

    handleMouseMove(event: any) {
        this.onFocus(event);
    }

    handleTouchEnd(event: any) {
        // Check if the view is being dragged, In this case
        // we don't want to fire the click event (because the user only wants to scroll)
        if (this.dragging) {
            return;
        }

        this.handleMouseDown(event);
    }

    handleTouchMove(event: any) {
        // Set a flag that the view is being dragged
        this.dragging = true;
    }

    handleTouchStart(event: any) {
        // Set a flag that the view is not being dragged
        this.dragging = false;
    }

    onFocus(event: any) {
        if (!this.props.isFocused) {
            this.props.onFocus(this.props.option, event);
        }
    }

    render() {
        const { option, instancePrefix, optionIndex } = this.props;
        const className = classNames(this.props.className, option.className);

        return option.disabled ? (
            <div className={className} onMouseDown={this.blockEvent} onClick={this.blockEvent}>
                {this.props.children}
            </div>
        ) : (
            <div
                className={className}
                style={option.style}
                role="option"
                onMouseDown={this.handleMouseDown}
                onMouseEnter={this.handleMouseEnter}
                onMouseMove={this.handleMouseMove}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
                id={instancePrefix + "-option-" + optionIndex}
                title={option.title}
            >
                {this.props.children}
            </div>
        );
    }
}

export default Option;
