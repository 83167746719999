import * as moment from "moment";

import {
    ApiCallOptions,
    DistributionChannel,
    EsReservationResult,
    MxtsApiWrapper,
    PlanningChartLock,
    PlanningChartReservation,
    PlanningChartReservedResource,
    PublicReservedResourceSubject,
    ReservationCategory,
    ResourceType,
    SettlementEntriesRequest,
    SettlementEntry,
    Unit,
    getAll,
    getAllIds,
    getAllPlanningChartResults,
} from "@maxxton/cms-mxts-api";
import { DATE_FORMAT, MXTS as MXTSConstants } from "../../../../../../utils/constants";
import { GuestReservationOverviewColumnKey, ReservationOverviewColumnKey } from "../index";

import { CMSProviderProperties } from "../../../../../../containers/cmsProvider.types";
import { DateType } from "../../../../additions/products/DaySubjectProduct";
import { DateUtil } from "../../../../../../utils/date.util";
import { StringUtil } from "../../../../../../utils/string.util";
import { getCustomerIdsFromLoginToken } from "../../../../../../redux/reducers/myEnv/myEnv.util";
import { getI18nLocaleString } from "../../../../../../i18n/loader";
import namespaceList from "../../../../../../i18n/namespaceList";
import { uniq } from "lodash";

type Moment = moment.Moment;

export interface SortMethod {
    column: typeof ReservationOverviewColumnKey[keyof typeof ReservationOverviewColumnKey] | typeof GuestReservationOverviewColumnKey[keyof typeof GuestReservationOverviewColumnKey];
    direction: "ASC" | "DESC";
}

export interface PlanningChartFilter {
    unitIds?: number[];
    startDate: Moment;
    endDate: Moment;
    customerIds?: number[];
}

export interface CustomPlanningChartReservation extends PlanningChartReservation {
    arrivalDate: Moment;
    departureDate: Moment;
    distributionChannel?: DistributionChannel;
    reservationCategory?: ReservationCategory;
    units?: Unit[];
    reservedResourceSubjects?: PublicReservedResourceSubject[];
    settlementEntry?: SettlementEntry | null;
    cellTexts?: Array<{ column: typeof ReservationOverviewColumnKey[keyof typeof ReservationOverviewColumnKey]; text: string | undefined }>;
    isMultiUnitBooking?: boolean;
    isNewSinceLastLogin?: boolean;
    ownedReservedResources?: PlanningChartReservedResource[];
}

export interface CustomEsReservationResult extends EsReservationResult {
    cellTexts?: Array<{ column: typeof GuestReservationOverviewColumnKey[keyof typeof GuestReservationOverviewColumnKey]; text: string | undefined }>;
}

export interface CustomPlanningChartLock extends PlanningChartLock {
    momentStartDate: Moment;
    momentEndDate: Moment;
}

export async function getPlanningChartLocks(params: { env: ApiCallOptions; mxtsApi: MxtsApiWrapper; planningChartFilter: PlanningChartFilter }): Promise<CustomPlanningChartLock[]> {
    const { env, mxtsApi, planningChartFilter } = params;
    if (!planningChartFilter.unitIds?.length) {
        return [];
    }

    const locks = await mxtsApi.getPlanningChartLocks(env, {
        startDate: planningChartFilter.startDate.format(DATE_FORMAT.MXTS),
        endDate: planningChartFilter.endDate.format(DATE_FORMAT.MXTS),
        unitIds: planningChartFilter.unitIds,
    });

    return (locks || []).map(
        (lock: PlanningChartLock): CustomPlanningChartLock => ({ ...lock, momentStartDate: moment(lock.startDate).startOf("day"), momentEndDate: moment(lock.endDate).startOf("day") })
    );
}

export async function getPlanningChartReservations(params: {
    env: ApiCallOptions;
    mxtsApi: MxtsApiWrapper;
    planningChartFilter: PlanningChartFilter;
    splitMultiUnitReservations: boolean;
}): Promise<CustomPlanningChartReservation[]> {
    const { env, mxtsApi, planningChartFilter, splitMultiUnitReservations } = params;
    if (!planningChartFilter.unitIds?.length) {
        return [];
    }
    const units = await mxtsApi.units(env, { size: MXTSConstants.MAX_RESULTS, unitIds: planningChartFilter.unitIds });
    const resortIds = units.content.map((unit) => unit.resortId);
    if (resortIds.length) {
        const reservationsPage = await getAllPlanningChartResults<PlanningChartReservation>((page: number, pageSize: number) =>
            mxtsApi.getPlanningChartReservations(env, {
                startDate: planningChartFilter.startDate.format(DATE_FORMAT.MXTS),
                endDate: planningChartFilter.endDate.format(DATE_FORMAT.MXTS),
                size: pageSize,
                includeUnassigned: false,
                resortIds,
                page,
                unitIds: planningChartFilter.unitIds,
            })
        );

        return (reservationsPage?.response || []).map((reservation: PlanningChartReservation) => {
            const reservedResource = reservation.reservedResources.find(
                (reservedResource: PlanningChartReservedResource) =>
                    reservedResource.resourceType === ResourceType.ACCOMMODATIONTYPE && reservedResource.actualStartDate && reservedResource.actualEndDate
            );
            return {
                ...reservation,
                arrivalDate: reservedResource ? moment(reservedResource.actualStartDate).startOf("day") : undefined,
                departureDate: reservedResource ? moment(reservedResource.actualEndDate).startOf("day") : undefined,
            } as CustomPlanningChartReservation;
        });
    }
    return [];
}

export interface PlanningChartDetailsFilter {
    includeParty?: boolean;
    includeDc?: boolean;
    includeRc?: boolean;
    includeNewSinceLastLogin?: boolean;
    includeUnits?: boolean;
    includeSettlementEntry?: boolean;
    allOwnerUnitIds?: number[];
    selectedUnitId?: number;
}

interface PlanningChartReservationDetailsParams {
    env: ApiCallOptions;
    context: CMSProviderProperties;
    planningChartDetailsFilter: PlanningChartDetailsFilter;
    reservations: CustomPlanningChartReservation[];
    startDate?: Moment;
    endDate?: Moment;
}

export async function loadPlanningChartReservationDetails(params: PlanningChartReservationDetailsParams): Promise<CustomPlanningChartReservation[]> {
    const { env, context, planningChartDetailsFilter, reservations } = params;
    const { includeNewSinceLastLogin, includeRc, includeSettlementEntry, includeDc, allOwnerUnitIds, selectedUnitId, includeUnits, includeParty } = planningChartDetailsFilter;
    const { cmsApi, mxtsApi } = context;

    let allOwnedReservedResources: PlanningChartReservedResource[] = [];
    if (includeUnits || includeParty || includeSettlementEntry) {
        const allReservedResources: PlanningChartReservedResource[] = reservations.reduce<PlanningChartReservedResource[]>(
            (accumulator, reservation) => accumulator.concat(reservation.reservedResources),
            []
        );
        allOwnedReservedResources = getAllOwnedReservedResources(allReservedResources, allOwnerUnitIds, selectedUnitId);
    }

    // Distribution channels
    let allDcs: DistributionChannel[] = [];
    if (includeDc) {
        const allDcIds = uniq((reservations || []).map((reservation) => reservation.distributionChannelId));
        allDcs = allDcIds.length ? (await mxtsApi.distributionChannels(env, { size: allDcIds.length, ids: allDcIds }))?.content || [] : [];
    }

    // Reservation categories
    let allRcs: ReservationCategory[] = [];
    if (includeRc) {
        const allRcIds = uniq((reservations || []).map((reservation) => reservation.reservationCategoryId));
        allRcs = allRcIds.length ? (await mxtsApi.getReservationCategories(env, { size: allRcIds.length, reservationCategoryIds: allRcIds }))?.content || [] : [];
    }

    // Units
    let allUnits: Unit[] = [];
    if (includeUnits && allOwnedReservedResources.length) {
        allUnits = await getAllIds<Unit>(
            allOwnedReservedResources.map((reservedResource) => reservedResource.unitId),
            async (ids: number[], pageSize: number) => mxtsApi.units(env, { size: pageSize, unitIds: ids }),
            50
        );
    }

    // Party
    let allReservedResourceSubjects: PublicReservedResourceSubject[] = [];
    if (includeParty && allOwnedReservedResources.length) {
        allReservedResourceSubjects = await getAllIds(
            allOwnedReservedResources.map((reservedResource) => reservedResource.reservedResourceId),
            async (ids: number[], pageSize: number) =>
                getAll((page: number) =>
                    mxtsApi.getReservedResourceSubjectsPublic(env, {
                        reservedResourceIds: ids,
                        size: MXTSConstants.MAX_RESULTS,
                        view: "detail",
                        page,
                    })
                ),
            50
        );
    }

    // Settlement entries
    let allSettlementEntries: SettlementEntry[] = [];
    if (includeSettlementEntry && allOwnedReservedResources.length) {
        allSettlementEntries = await getSettlementEntries(params, allOwnedReservedResources);
    }

    // New reservations since last login
    const lastLoginDate: Date | string | undefined = includeNewSinceLastLogin ? (await cmsApi.customerPreferencesApi.getCustomerPreferences())?.lastLoginDate : undefined;
    const parsedLastLoginDate: Date | undefined = StringUtil.isString(lastLoginDate) && lastLoginDate ? DateUtil.parseDate(lastLoginDate as string, DATE_FORMAT.CMS_API_DATETIME) : undefined;

    return (reservations || []).map((reservation: CustomPlanningChartReservation) => {
        const ownedReservedResources: PlanningChartReservedResource[] = getAllOwnedReservedResources(reservation.reservedResources, allOwnerUnitIds, selectedUnitId);
        const customReservation: CustomPlanningChartReservation = {
            ...reservation,
            ownedReservedResources,
            units: allUnits.filter((unit) => ownedReservedResources.some((ownedReservedResource) => ownedReservedResource.unitId === unit.unitId)),
            reservedResourceSubjects: allReservedResourceSubjects.filter((subject) =>
                ownedReservedResources.some((ownedReservedResource) => ownedReservedResource.reservedResourceId === subject.reservedResourceId)
            ),
            reservationCategory: allRcs.find((rc) => rc.reservationCategoryId === reservation.reservationCategoryId),
            distributionChannel: allDcs.find((dc) => dc.distributionChannelId === reservation.distributionChannelId),
            settlementEntry: allSettlementEntries.find((settlementEntry) => settlementEntry.reservationId === reservation.reservationId) || null,
            isMultiUnitBooking: ownedReservedResources.length > 1,
            isNewSinceLastLogin: isNewSinceLastLogin(parsedLastLoginDate, ownedReservedResources),
        };
        return customReservation;
    });
}

function isNewSinceLastLogin(parsedLastLoginDate: Date | undefined, ownedReservedResources: PlanningChartReservedResource[]) {
    if (parsedLastLoginDate) {
        return ownedReservedResources.some((accoType) => DateUtil.parseMxtsDate(accoType.reservationDate) > parsedLastLoginDate);
    }
    const aMonthAgo = new Date();
    aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);
    return ownedReservedResources.some((accoType) => DateUtil.parseMxtsDate(accoType.reservationDate) > aMonthAgo);
}

async function getSettlementEntries(params: PlanningChartReservationDetailsParams, ownedReservedResources: PlanningChartReservedResource[]): Promise<SettlementEntry[]> {
    const { startDate, endDate, context, env } = params;
    const ownerIds = await getCustomerIdsFromLoginToken();
    return getAllIds<SettlementEntry>(
        ownedReservedResources.map((reservedResource) => reservedResource.unitId),
        async (ids: number[], pageSize: number) =>
            getAll((page: number) => {
                const settlementEntriesParams: SettlementEntriesRequest = {
                    page,
                    size: MXTSConstants.MAX_RESULTS,
                    ownerIds,
                    unitIds: ids,
                };
                // Apply a margin of 1 year to the entryDate filters. This is because a reservation might have it's arrival/departure in 2023 but it could be the settlement is generated in 2024
                if (startDate) {
                    settlementEntriesParams.minEntryDate = moment(startDate).subtract(1, "years").format(DATE_FORMAT.MXTS_DATETIME_UTC);
                }
                if (endDate) {
                    settlementEntriesParams.maxEntryDate = moment(endDate).add(1, "years").format(DATE_FORMAT.MXTS_DATETIME_UTC);
                }
                return context.mxtsApi.getSettlementEntries(env, settlementEntriesParams);
            }),
        50
    );
    return [];
}

export function getAllOwnedReservedResources(reservedResources: PlanningChartReservedResource[], allOwnerUnitIds: number[] | undefined, selectedUnitId?: number): PlanningChartReservedResource[] {
    return reservedResources.filter(
        (reservedResource) =>
            reservedResource.resourceType === ResourceType.ACCOMMODATIONTYPE &&
            (!allOwnerUnitIds?.length || allOwnerUnitIds.some((ownerUnitId) => reservedResource.unitId === ownerUnitId)) &&
            (!selectedUnitId || reservedResource.unitId === selectedUnitId)
    );
}

export function getInitialDate(yearToShowTheResult: number | undefined, dateType: DateType) {
    if (dateType === DateType.startDate) {
        if (yearToShowTheResult) {
            return moment().year(yearToShowTheResult).startOf("year");
        }
        return moment().startOf("year");
    }
    if (yearToShowTheResult) {
        return moment().year(yearToShowTheResult).endOf("year");
    }
    return moment().endOf("year");
}

export function getNumberOfReservationsLabelText(reservations: CustomPlanningChartReservation[]) {
    return `${reservations.length} ${getI18nLocaleString(namespaceList.ownerReservationsOverview, "reservations")}`;
}

export function getReservationPeriodText(arrivalDate: Moment, departureDate: Moment) {
    const hasYearDifference = arrivalDate.year() !== departureDate.year();
    return `${arrivalDate.format(hasYearDifference ? DATE_FORMAT.DAY_WITH_MONTH_NAME : DATE_FORMAT.DAY_DATE)} - ${departureDate.format(DATE_FORMAT.DAY_WITH_MONTH_NAME)}`;
}
