import { Persistor, persistReducer, persistStore } from "redux-persist";
import { Reducer, Store } from "redux";

import { ReduxRedisStorage } from "./ReduxRedisStorage";
import { State } from "../index";
import { StateTransform } from "./reduxPersistenceTransformations";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

export class ReduxPersistenceUtil {
    public static getPersistedReducer(reducer: Reducer<State>, stateUuid: string) {
        const persistConfig = {
            key: stateUuid,
            storage: ReduxRedisStorage,
            transforms: [StateTransform],
            stateReconciler: autoMergeLevel2,
            // eslint-disable-next-line max-len
            // When the state structure changes we should update this version and create a migration method to update the state obtained from the db to the new structure.
            // See: https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
            version: 1,
            whitelist: [
                "dynamicFilter",
                "additionState",
                "reservationState",
                "billState",
                "specialSearchState",
                "mxtsDataAccessTokenState",
                "selectedAddOnsState",
                "myEnvState",
                "sessionState",
                "activityPlannerState",
            ],
        };
        return persistReducer(persistConfig, reducer);
    }

    public static persistStore(store: Store): Persistor {
        return persistStore(store);
    }

    public static doAfterReduxStateHydrated(persistor: Persistor | undefined, fn: () => void) {
        if (!persistor || persistor.getState().bootstrapped) {
            fn();
        } else {
            persistor.subscribe(() => fn());
        }
    }
}
