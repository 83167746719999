import * as moment from "moment";

import { checkDuplicateSpecials, removeDuplicatesFromArray } from "../../components/utils";
import { convertDynamicFilterToUrlLinkParams, updateUrlWithQueryParams } from "../../utils/urlparam.util";

import { ActionType } from "../actions";
import { ArrayUtil } from "../../utils/array.util";
import { DATE_FORMAT } from "../../utils/constants";
import { DynamicFilter } from "./dynamicFilter.types";
import { FilterChangeAction } from "../actions/dynamicFilterAction.types";
import { Reducer } from "redux";
import { dynamicFilterType } from "./dynamicFilter.enum";
import { freeSearchData } from "../../plugins/page/free-search/data";
import { isClientSide } from "../../utils/generic.util";

// eslint-disable-next-line max-lines-per-function
export const dynamicFilterReducer: Reducer<DynamicFilter> = (state: DynamicFilter = { subjectPopup: false }, action: FilterChangeAction): DynamicFilter => {
    const payload = action.payload;
    let filter = { ...state };
    let accokindids: number[] = payload?.accokindids || [];
    let selectedLocationIds: number[] = payload?.resortids || [];
    let specialcode: string[] = payload?.specialcode || [];
    let amenities: string[] = payload?.amenities || [];
    let selectedQualityLevelIds: number[] = payload?.qualityLevelIds || [];
    switch (action.type) {
        case ActionType.DynamicFilter:
            switch (action.filter) {
                case dynamicFilterType.setDynamicFilters:
                    filter = payload;
                    break;
                case dynamicFilterType.addDerivedEndDate:
                    filter.enddate = filter.startdate && moment(filter.startdate, DATE_FORMAT.DEFAULT).add(payload.duration, "days").format(DATE_FORMAT.DEFAULT);
                    break;
                case dynamicFilterType.addReservationId:
                    filter.reservationId = payload.reservationId;
                    break;
                case dynamicFilterType.shouldFetchUnitsWithPrice:
                    filter.shouldFetchUnitsWithPrice = payload.shouldFetchUnitsWithPrice;
                    break;
                case dynamicFilterType.shouldFetchSortedUnits:
                    filter.shouldFetchSortedUnits = payload.shouldFetchSortedUnits;
                    break;
                case dynamicFilterType.subjectPopup:
                    filter.subjectPopup = payload.subjectPopup;
                    break;
                case dynamicFilterType.shouldFetchResorts:
                    filter.shouldFetchResorts = payload.shouldFetchResorts;
                    break;
                case dynamicFilterType.fetchLatestArrivalDate:
                    filter.fetchLatestArrivalDate = payload.fetchLatestArrivalDate;
                    break;
                case dynamicFilterType.shouldFetchStayPeriods:
                    filter.shouldFetchStayPeriods = payload.shouldFetchStayPeriods;
                    break;
                case dynamicFilterType.unitPreference:
                    filter.unitPreference = payload.unitPreference;
                    filter.unitid = payload.unitid;
                    break;
                case dynamicFilterType.includeMissingPrices:
                    filter.includeMissingPrices = payload.includeMissingPrices;
                    break;
                case dynamicFilterType.loadingAction:
                    filter.isInstalmentLoading = payload.isInstalmentLoading;
                    break;
                case dynamicFilterType.selectedChoice:
                    filter.selectedChoice = payload.selectedChoice;
                    break;
                case dynamicFilterType.resourceid:
                    filter.resourceid = payload.resourceid;
                    break;
                case dynamicFilterType.unitid:
                    filter.unitid = payload.unitid;
                    break;
                case dynamicFilterType.resort:
                    filter.resort = payload.resort;
                    break;
                case dynamicFilterType.startdate:
                    filter.startdate = payload.startdate;
                    filter.enddate = undefined;
                    filter.maxprice = undefined;
                    filter.minprice = undefined;
                    break;
                case dynamicFilterType.enddate:
                    filter.enddate = payload.enddate;
                    filter.maxprice = undefined;
                    filter.minprice = undefined;
                    break;
                case dynamicFilterType.dateMargin:
                    filter.dateMargin = payload.dateMargin;
                    break;
                case dynamicFilterType.facilityids:
                    filter.facilityids = payload.facilityids;
                    break;
                case dynamicFilterType.pointsOfInterestIds:
                    filter.pointsOfInterestIds = payload.pointsOfInterestIds;
                    break;
                case dynamicFilterType.categoryFilters:
                    filter.categoryFilters = {
                        filterType: payload.categoryFilters?.filterType,
                        allIds: payload?.categoryFilters?.allIds,
                        selectedIds: payload?.categoryFilters?.selectedIds,
                    };
                    break;
                case dynamicFilterType.paginatedData:
                    filter.paginatedData = {
                        typesearch: payload?.paginatedData?.typesearch,
                    };
                    break;
                case dynamicFilterType.stay:
                    filter.stay = payload.stay;
                    break;
                case dynamicFilterType.stayperioddefid:
                    filter.stayperioddefid = payload.stayperioddefid;
                    break;
                case dynamicFilterType.stayHolidayPeriodDefId:
                    filter.stayHolidayPeriodDefId = payload.stayHolidayPeriodDefId;
                    break;
                case dynamicFilterType.holiday:
                    filter.holiday = payload.holiday;
                    break;
                case dynamicFilterType.guestRating:
                    filter.guestRating = payload.guestRating;
                    break;
                case dynamicFilterType.offerSearchCode:
                    filter.offerSearchCode = payload.offerSearchCode;
                    break;
                case dynamicFilterType.defaultStay:
                    filter.defaultStay = payload.defaultStay;
                    break;
                case dynamicFilterType.duration:
                    filter.duration = payload.duration;
                    break;
                case dynamicFilterType.minCapacity:
                    filter.minCapacity = payload.minCapacity;
                    break;
                case dynamicFilterType.smartDatePopup:
                    filter.smartDatePopup = payload.smartDatePopup;
                    break;
                case dynamicFilterType.isPayingCustomer:
                    filter.isPayingCustomer = payload.isPayingCustomer;
                    break;
                case dynamicFilterType.payingCustomerReservationCategoryId:
                    filter.payingCustomerReservationCategoryId = payload.payingCustomerReservationCategoryId;
                    break;
                case dynamicFilterType.addaccokind:
                    if (state.accokindids) {
                        let stateAccoKindIds = state.accokindids;
                        const { removedAccoKindIds } = payload;
                        if (removedAccoKindIds) {
                            stateAccoKindIds = stateAccoKindIds.filter((id) => removedAccoKindIds.indexOf(id) === -1);
                        }
                        accokindids = accokindids.concat(stateAccoKindIds);
                    }
                    filter.accokindids = removeDuplicatesFromArray(accokindids);
                    break;
                case dynamicFilterType.removeaccokind:
                    filter.minprice = undefined;
                    filter.maxprice = undefined;
                    filter.accokindids = state.accokindids ? state.accokindids.filter((accokindid) => payload.accokindids && payload.accokindids.indexOf(accokindid) === -1) : undefined;
                    break;
                case dynamicFilterType.addresort:
                    if (state.resortids) {
                        let stateResortIds = state.resortids;
                        const { removedResortIds } = payload;
                        if (removedResortIds) {
                            stateResortIds = stateResortIds.filter((id) => removedResortIds.indexOf(id) === -1);
                        }
                        selectedLocationIds = selectedLocationIds.concat(stateResortIds);
                    }
                    filter.resortids = removeDuplicatesFromArray(selectedLocationIds);
                    break;
                case dynamicFilterType.removeresort:
                    filter.resortids = state.resortids ? state.resortids.filter((resortid) => payload.resortids && payload.resortids.indexOf(resortid) === -1) : undefined;
                    break;
                case dynamicFilterType.updateresorts:
                    filter.resortids = payload.resortids || [];
                    break;
                case dynamicFilterType.updateSelectedLocationIds:
                    filter.resortids = selectedLocationIds;
                case dynamicFilterType.addspecialcode:
                    if (state.specialcode) {
                        specialcode = specialcode.concat(state.specialcode.filter((code) => checkDuplicateSpecials(specialcode, code)));
                    }
                    filter.specialcode = removeDuplicatesFromArray(specialcode);
                    filter.maxprice = payload.maxprice;
                    filter.minprice = payload.minprice;
                    break;
                case dynamicFilterType.removespecialcode:
                    filter.minprice = undefined;
                    filter.maxprice = undefined;
                    filter.specialcode = state.specialcode ? state.specialcode.filter((code) => payload.specialcode && payload.specialcode.indexOf(code) === -1) : undefined;
                    break;
                case dynamicFilterType.addVoucherCodes:
                    filter.voucherCodes = ArrayUtil.getUniqueStrings([...(payload.voucherCodes || []), ...(state.voucherCodes || [])], true);
                    break;
                case dynamicFilterType.removeVoucherCodes:
                    filter.voucherCodes = state.voucherCodes?.filter((code) => !payload?.voucherCodes?.find((removeCode) => removeCode === code));
                    break;
                case dynamicFilterType.addamenity:
                    if (state.amenities) {
                        state.amenities.forEach((amenity) => {
                            if (amenities.indexOf(amenity) > -1) {
                                amenities.pop();
                            }
                        });
                        amenities = amenities.concat(state.amenities);
                    }
                    filter.amenities = amenities.filter((amenity) => amenity !== null);
                    filter.freeSearchId = payload.freeSearchId !== undefined ? payload.freeSearchId : state.freeSearchId;
                    filter.topLeftPoint = payload.freeSearchId !== undefined ? undefined : state.topLeftPoint;
                    filter.bottomRightPoint = payload.freeSearchId !== undefined ? undefined : state.bottomRightPoint;
                    break;
                case dynamicFilterType.removeamenity:
                    filter.minprice = undefined;
                    filter.maxprice = undefined;
                    filter.amenities = state.amenities
                        ? state.amenities.length === 1
                            ? state.amenities[0].split(",").filter((amenity) => payload.amenities && payload.amenities.every((payloadAmenity) => payloadAmenity !== amenity))
                            : state.amenities.filter((amenity) => payload.amenities && payload.amenities.every((payloadAmenity) => payloadAmenity !== amenity))
                        : [];
                    filter.freeSearchId = payload.freeSearchId !== undefined ? payload.freeSearchId : state.freeSearchId;
                    break;
                case dynamicFilterType.subjects:
                    filter.subject = payload.subject;
                    filter.useSubjectCategory = payload.useSubjectCategory != null ? payload.useSubjectCategory : filter.useSubjectCategory;
                    break;
                case dynamicFilterType.useSubjectCategory:
                    filter.useSubjectCategory = payload.useSubjectCategory;
                    break;
                case dynamicFilterType.currentLocale:
                    filter.currentLocale = payload.currentLocale;
                    break;
                case dynamicFilterType.bedroom:
                    filter.bedroom = payload.bedroom;
                    break;
                case dynamicFilterType.minbedroom:
                    filter.minbedroom = payload.minbedroom;
                    break;
                case dynamicFilterType.maxbedroom:
                    filter.maxbedroom = payload.maxbedroom;
                    break;
                case dynamicFilterType.bathroom:
                    filter.bathroom = payload.bathroom;
                    break;
                case dynamicFilterType.maxBedBath:
                    filter = payload;
                    break;
                case dynamicFilterType.minbathroom:
                    filter.minbathroom = payload.minbathroom;
                    break;
                case dynamicFilterType.maxbathroom:
                    filter.maxbathroom = payload.maxbathroom;
                    break;
                case dynamicFilterType.dynamicMarkersIsFetchComplete:
                    filter.dynamicMarkers = payload.dynamicMarkers;
                    filter.isAllResultFetched = payload.isAllResultFetched;
                    break;
                case dynamicFilterType.onMouseOverOut:
                    filter.onMouseOverOut = payload.onMouseOverOut;
                    break;
                case dynamicFilterType.markerIds:
                    filter.markerIds = payload.markerIds;
                    break;
                case dynamicFilterType.minprice:
                    filter.minprice = payload.minprice;
                    filter.updatePriceSlider = payload.updatePriceSlider;
                    filter.updateMap = true;
                    break;
                case dynamicFilterType.maxprice:
                    filter.maxprice = payload.maxprice;
                    filter.updatePriceSlider = payload.updatePriceSlider;
                    filter.updateMap = true;
                    break;
                case dynamicFilterType.priceRangeCriteria:
                    filter.priceRangeCriteria = payload.priceRangeCriteria;
                    break;
                case dynamicFilterType.updateMinMaxPrice:
                    filter.minprice = payload.minprice;
                    filter.maxprice = payload.maxprice;
                    filter.updatePriceSlider = payload.updatePriceSlider;
                    filter.updateMap = payload.updateMap;
                    filter.finalmax = payload.finalmax;
                    filter.finalmin = payload.finalmin;
                    break;
                case dynamicFilterType.directSearchInput:
                    filter.directSearchInput = payload.directSearchInput;
                    break;
                case dynamicFilterType.selectedDirectSearchId:
                    filter.selectedDirectSearchId = payload.selectedDirectSearchId;
                    break;
                case dynamicFilterType.sorting:
                    filter.sortingOption = payload.sortingOption;
                    break;
                case dynamicFilterType.addBookingsEngineUrl:
                    filter.bookingsEngineUrl = action.payload.bookingsEngineUrl;
                    break;
                case dynamicFilterType.selectedRegionId:
                    filter.topLeftPoint = undefined;
                    filter.bottomRightPoint = undefined;
                    filter.regionIds = payload.regionIds;
                    filter.freeSearchId = payload.freeSearchId !== undefined ? payload.freeSearchId : state.freeSearchId;
                    break;
                case dynamicFilterType.showCurrencyDetailsInUrl: {
                    filter.showCurrencyDetailsInUrl = payload.showCurrencyDetailsInUrl;
                    break;
                }
                case dynamicFilterType.updateReservationCurrencyCode: {
                    filter.reservationCurrencyCode = payload.reservationCurrencyCode;
                    break;
                }
                case dynamicFilterType.updateCurrencyDetails: {
                    const { currency, distributionChannel, rateType } = payload;
                    filter.currency = currency;
                    filter.distributionChannel = distributionChannel;
                    filter.rateType = rateType;
                    break;
                }
                case dynamicFilterType.resetAvailabilityFetch:
                    filter.shouldFetchAvailability = false;
                    break;
                case dynamicFilterType.emptyDispatch:
                    filter.shouldFetchAvailability = true;
                    break;
                case dynamicFilterType.addcoordinates:
                    filter.topLeftPoint = payload.topLeftPoint;
                    filter.bottomRightPoint = payload.bottomRightPoint;
                    filter.regionIds = undefined;
                    if (filter.freeSearchId && freeSearchData[filter.freeSearchId]) {
                        const data = freeSearchData[filter.freeSearchId];
                        if (data && data.icon !== "sun-o" && data.icon !== "map-marker") {
                            if (filter.amenities && (filter.amenities.indexOf(data.amenityIdLive) > -1 || filter.amenities.indexOf(data.amenityIdAcc) > -1)) {
                                const index = filter.amenities.indexOf(data.amenityIdLive);
                                let filteredAmenities = filter.amenities.filter((am) => am !== data.amenityIdLive);
                                if (index === -1) {
                                    filteredAmenities = filter.amenities.filter((am) => am !== data.amenityIdAcc);
                                }
                                filter.amenities = filteredAmenities;
                            }
                        }
                    }
                    break;
                case dynamicFilterType.addDynamicFreeSearch:
                    if (payload.regionIds) {
                        filter.regionIds = filter.regionIds ? removeDuplicatesFromArray([...filter.regionIds, ...payload.regionIds]) : payload.regionIds;
                    }
                    if (payload.resortids) {
                        filter.resortids = filter.resortids ? removeDuplicatesFromArray([...filter.resortids, ...payload.resortids]) : payload.resortids;
                    }
                    if (payload.accokindids) {
                        filter.accokindids = filter.accokindids ? removeDuplicatesFromArray([...filter.accokindids, ...payload.accokindids]) : payload.accokindids;
                    }
                    if (payload.amenities) {
                        filter.amenities = filter.amenities ? removeDuplicatesFromArray([...filter.amenities, ...payload.amenities]) : payload.amenities;
                    }
                    filter.dynamicFreeSearchIds = payload.dynamicFreeSearchIds;
                    filter.isFreeSearch = payload.isFreeSearch;
                    break;
                /* jscpd:ignore-start */
                case dynamicFilterType.addFreeSearch:
                    if (payload.regionIds) {
                        filter.regionIds = filter.regionIds ? removeDuplicatesFromArray([...filter.regionIds, ...payload.regionIds]) : payload.regionIds;
                    }
                    if (payload.resortids) {
                        filter.resortids = filter.resortids ? removeDuplicatesFromArray([...filter.resortids, ...payload.resortids]) : payload.resortids;
                    }
                    if (payload.accokindids) {
                        filter.accokindids = filter.accokindids ? removeDuplicatesFromArray([...filter.accokindids, ...payload.accokindids]) : payload.accokindids;
                    }
                    if (payload.amenities) {
                        filter.amenities = filter.amenities ? removeDuplicatesFromArray([...filter.amenities, ...payload.amenities]) : payload.amenities;
                    }
                    filter.freeSearchId = payload.freeSearchId;
                    filter.isFreeSearch = payload.isFreeSearch;
                    break;
                /* jscpd:ignore-end */
                case dynamicFilterType.removeDynamicFreeSearch:
                    if (payload.regionIds && filter.regionIds) {
                        filter.regionIds = filter.regionIds.filter((regionId) => !payload.regionIds?.includes(regionId));
                    }
                    if (payload.resortids && filter.resortids) {
                        filter.resortids = filter.resortids.filter((resortId) => !payload.resortids?.includes(resortId));
                    }
                    if (payload.accokindids && filter.accokindids) {
                        filter.accokindids = filter.accokindids.filter((accoKindId) => !payload.accokindids?.includes(accoKindId));
                    }
                    if (payload.amenities && filter.amenities) {
                        filter.amenities = filter.amenities.filter((amenityId) => !payload.amenities?.includes(amenityId));
                    }
                    filter.dynamicFreeSearchIds = payload.dynamicFreeSearchIds;
                    filter.isFreeSearch = payload.isFreeSearch;
                    break;
                case dynamicFilterType.removeFreeSearch:
                    if (payload.regionIds && filter.regionIds) {
                        const newRegionIds: number[] = [];
                        filter.regionIds.forEach((regionId) => {
                            if (!(payload.regionIds!.indexOf(regionId) > -1)) {
                                newRegionIds.push(regionId);
                            }
                        });
                        filter.regionIds = newRegionIds;
                    }
                    if (payload.resortids && filter.resortids) {
                        const newResortIds: number[] = [];
                        filter.resortids.forEach((resortId) => {
                            if (!(payload.resortids!.indexOf(resortId) > -1)) {
                                newResortIds.push(resortId);
                            }
                        });
                        filter.resortids = newResortIds;
                    }
                    if (payload.accokindids && filter.accokindids) {
                        const newAccoKindIds: number[] = [];
                        filter.accokindids.forEach((accoKindId) => {
                            if (!(payload.accokindids!.indexOf(accoKindId) > -1)) {
                                newAccoKindIds.push(accoKindId);
                            }
                        });
                        filter.accokindids = newAccoKindIds;
                    }
                    if (payload.amenities && filter.amenities) {
                        const newAmenityIds: string[] = [];
                        filter.amenities.forEach((amenityId) => {
                            if (!(payload.amenities!.indexOf(amenityId) > -1)) {
                                newAmenityIds.push(amenityId);
                            }
                        });
                        filter.amenities = newAmenityIds;
                    }
                    filter.freeSearchId = payload.freeSearchId;
                    filter.isFreeSearch = payload.isFreeSearch;
                    break;
                case dynamicFilterType.blendFilters: {
                    const { showCurrencyDetailsInUrl } = filter;
                    // Somehow showCurrencyDetailsInUrl comes undefined in the payload from TypesearchContainerWidget.
                    // Thus, showCurrencyDetailsInUrl needs to be set again!
                    filter = { ...filter, ...payload, showCurrencyDetailsInUrl };
                    break;
                }
                case dynamicFilterType.addPreselectedFilters:
                case dynamicFilterType.removeFilters:
                case dynamicFilterType.removeBathroomFilters:
                case dynamicFilterType.removeBedroomFilters:
                case dynamicFilterType.removePriceRangeFilters:
                case dynamicFilterType.updateDates: {
                    // persist showCurrencyDetailsInUrl as addPreselectedFilters dispatch from the LocationSearchContainerWidget
                    // constructor removes showCurrencyDetailsInUrl from the filter
                    const { showCurrencyDetailsInUrl } = filter;
                    filter = { ...payload, showCurrencyDetailsInUrl };
                    break;
                }
                case dynamicFilterType.setEec:
                    filter.eec = 1;
                    break;
                case dynamicFilterType.setFlowType:
                    filter.flowType = payload.flowType;
                    break;
                case dynamicFilterType.cancellationPremiumId:
                    filter.cancellationPremiumId = payload.cancellationPremiumId;
                    break;
                case dynamicFilterType.reservationCategoryId:
                    filter.reservationCategoryId = payload.reservationCategoryId;
                    break;
                case dynamicFilterType.setPaymentStatus:
                    filter.paymentStatus = payload.paymentStatus;
                    break;
                case dynamicFilterType.extraAggregations:
                    filter.extraAggregations = payload.extraAggregations;
                    break;
                case dynamicFilterType.setResourceActivityDetailsId:
                    filter.resourceActivityDetailsId = payload.resourceActivityDetailsId;
                    break;
                case dynamicFilterType.resourceActivityDetailsIds:
                    filter.resourceActivityDetailsIds = payload.resourceActivityDetailsIds;
                    break;
                case dynamicFilterType.resortActivityId:
                    filter.resortActivityId = payload.resortActivityId;
                    break;
                case dynamicFilterType.showMainActivityOnPage:
                    filter.showMainActivityOnPage = payload.showMainActivityOnPage;
                    break;
                case dynamicFilterType.includeBookedPeriods:
                    filter.includeBookedPeriods = payload.includeBookedPeriods;
                    break;
                case dynamicFilterType.includePreBookingPeriods:
                    filter.includePreBookingPeriods = payload.includePreBookingPeriods;
                    break;
                case dynamicFilterType.setSelectedActivities:
                    filter.selectedActivities = payload.selectedActivities;
                    break;
                case dynamicFilterType.updateLastUsedVoucherCode:
                    filter.lastUsedVoucherCode = payload.lastUsedVoucherCode;
                    break;
                case dynamicFilterType.addQualityLevel:
                    if (state.qualityLevelIds) {
                        let stateQualityLevelIds = state.qualityLevelIds;
                        const { qualityLevelIds } = payload;
                        if (qualityLevelIds) {
                            stateQualityLevelIds = stateQualityLevelIds.filter((id) => qualityLevelIds.indexOf(id) === -1);
                        }
                        selectedQualityLevelIds = selectedQualityLevelIds.concat(stateQualityLevelIds);
                    }
                    filter.qualityLevelIds = removeDuplicatesFromArray(selectedQualityLevelIds);
                    break;
                case dynamicFilterType.removeQualityLevel:
                    filter.qualityLevelIds = state.qualityLevelIds
                        ? state.qualityLevelIds.filter((qualityLevelId) => payload.qualityLevelIds && payload.qualityLevelIds.indexOf(qualityLevelId) === -1)
                        : undefined;
                    break;
                case dynamicFilterType.setActivityTicketQuantity:
                    filter.activityTicketQuantity = payload.activityTicketQuantity;
                    break;
                case dynamicFilterType.removeActivityParams:
                    filter.activityTicketQuantity = undefined;
                    filter.resourceActivityDetailsId = undefined;
                    filter.resourceActivityDetailsIds = undefined;
                    filter.resortActivityId = undefined;
                    break;
                case dynamicFilterType.showResortAmenitiesCount:
                    filter.showResortAmenitiesCount = payload.showResortAmenitiesCount;
                    break;
                case dynamicFilterType.previouslySelectedResourceId:
                    if (payload.previouslySelectedResourceId) {
                        filter.previouslySelectedResourceId = payload.previouslySelectedResourceId;
                    }
                case dynamicFilterType.activityDateChange:
                    filter.resourceActivityDetailsIds = undefined;
                    filter.selectedActivities = undefined;
                    break;
            }

            filter.isFreeSearch = action.payload && action.payload.isFreeSearch;

            if (isClientSide()) {
                const newUrlParams = convertDynamicFilterToUrlLinkParams(filter);
                const oldUrlParams = convertDynamicFilterToUrlLinkParams({ ...state });
                updateUrlWithQueryParams(newUrlParams, oldUrlParams);
            }
            return filter;
        default:
            return state;
    }
};
