import * as moment from "moment";

import { ApiCallOptions, Customer, CustomerTitle, SendEmailRequest } from "@maxxton/cms-mxts-api";
import { ApiContext, CMSProvidedProperties } from "../containers/cmsProvider.types";

import { DynamicData } from "../components/generic-form/form.types";
import { MXTS } from "./constants";
import { ThirdPartyApi } from "@maxxton/cms-api";
import { WidgetOptions } from "../plugins/form/formView";
import { getChangedCustomerFieldsEmailComponentAsString } from "../components/generic-form/customerChangeRequest/ChangedCustomerFieldsEmailComponent";
import { getMxtsEnv } from "../plugins/mxts";

export enum EmailAlertMessageType {
    Success = "Success",
    Error = "Error",
}

export const appendDynamicDataWithEmailContent = (dynamicData: DynamicData, content: string) => {
    let template = "";
    if (dynamicData.startDate && dynamicData.endDate) {
        template = template + `<label>${moment(dynamicData.startDate, "DDMMYYYY").format("DD/MM/YYYY")} - ${moment(dynamicData.endDate, "DDMMYYYY").format("DD/MM/YYYY")}</label><br />`;
    } else if (dynamicData.startDate) {
        template = template + `<label>Arrival Date: ${moment(dynamicData.startDate, "DDMMYYYY").format("DD/MM/YYYY")}</label><br />`;
    } else if (dynamicData.endDate) {
        template = template + `<label>Departure Date: ${moment(dynamicData.endDate, "DDMMYYYY").format("DD/MM/YYYY")}</label><br />`;
    }

    if (dynamicData.resortName) {
        template = template + `<label>${dynamicData.resortName}</label><br />`;
    }
    if (dynamicData.typeName) {
        template = template + `<label>${dynamicData.typeName}</label><br />`;
    }
    if (dynamicData.unitName) {
        template = template + `<label>${dynamicData.unitName}</label><br />`;
    }
    if (dynamicData.accoKindName) {
        template = template + `<label>${dynamicData.accoKindName}</label><br />`;
    }
    if (dynamicData.duration) {
        template = template + `<label> Duration: ${dynamicData.duration}</label><br />`;
    }
    if (dynamicData.subjects) {
        dynamicData.subjects.map((subject: any) => {
            template = template + `<label>${subject.subjectCount} ${subject.subjectName}</label><br />`;
        });
    }

    const splitContent = content.split("</body>");
    const appendedContent = `${splitContent[0]}<br />${template}</body>${splitContent[1]} `;
    return appendedContent;
};

export interface ContactDetails extends Customer {
    title?: string | number | null;
    country?: string | number | null;
    language?: string | number | null;
    [key: string]: unknown;
}

export const appendContactDataWithEmailContent = async (context: ApiContext, updatedContactDetails: Partial<ContactDetails>, oldContactDetails: Partial<ContactDetails>, content: string) => {
    let template = "";
    const contactKeys = Object.keys(updatedContactDetails);
    if (contactKeys.some((contactKey) => ["title", "country", "language"].includes(contactKey))) {
        updatedContactDetails = await obtainActualValueForContactDetailIdFields(context, contactKeys, updatedContactDetails);
        oldContactDetails = await obtainActualValueForContactDetailIdFields(context, contactKeys, oldContactDetails);
    }
    template += getChangedCustomerFieldsEmailComponentAsString({ newContactDetails: updatedContactDetails, oldContactDetails });
    const splitContent = content.split("</body>");
    return `${splitContent[0]}<br />${template}</body>${splitContent[1]} `;
};

export async function getContactFormContent<E>(context: ApiContext, params: { keys: Array<keyof E | undefined>; content: string; originalValue: E & { _id: string }; newValue: any }): Promise<string> {
    const { originalValue, newValue, keys, content } = params;
    const updatedContactDetails: Partial<ContactDetails> = {};
    const oldContactDetails: Partial<ContactDetails> = {};
    keys.forEach((key) => {
        if (key && originalValue[key] !== newValue[key]) {
            updatedContactDetails[key as string] = newValue[key];
            oldContactDetails[key as string] = originalValue[key];
        }
    });
    return appendContactDataWithEmailContent(context, updatedContactDetails, oldContactDetails, content);
}

export async function sendFormEmail(params: {
    env: ApiCallOptions;
    widgetOptions: WidgetOptions;
    newValue: any;
    reCaptchaToken: string;
    context?: CMSProvidedProperties;
    dynamicData: DynamicData | undefined;
    setEmailAlertMessage: (type: EmailAlertMessageType) => void;
}) {
    const { widgetOptions, env, newValue, reCaptchaToken, context, dynamicData, setEmailAlertMessage } = params;
    const { sendFormContentToExternalUrl, externalUrl } = widgetOptions;

    try {
        if (sendFormContentToExternalUrl && externalUrl && widgetOptions._id) {
            // Include language in JSON content for external URL.
            newValue.language = context?.site.locale.name;
            await ThirdPartyApi.submitFormToExternalService({
                item: {
                    response: JSON.stringify(newValue),
                    widgetOptionId: widgetOptions._id,
                },
            });
        } else {
            const sendEmailRequest: SendEmailRequest = {
                reCaptchaToken,
                siteHost: location.host,
                formOptionId: widgetOptions._id || "",
                localeId: context?.currentLocale.locale || "",
                submittedFormData: newValue,
                dynamicData,
            };

            // Try to send the email
            await context?.mxtsApi.sendEmail(env, sendEmailRequest);
        }
        setEmailAlertMessage(EmailAlertMessageType.Success); // Notify on success
    } catch (error) {
        setEmailAlertMessage(EmailAlertMessageType.Error); // Notify on error
        context?.logger.error("Failed to send the email", error);
    }
}

async function obtainActualValueForContactDetailIdFields(context: ApiContext, targetKeys: string[], contactDetails: Partial<ContactDetails>) {
    const [title, country, language] = await Promise.all([
        getTitle(context, targetKeys, contactDetails.title),
        getCountry(context, targetKeys, contactDetails.country),
        getLanguage(context, targetKeys, contactDetails.language),
    ]);
    return { ...contactDetails, ...(title && { title }), ...(country && { country }), ...(language && { language }) };
}

async function getTitle(context: ApiContext, contactKeys: string[], titleId?: string | number | null): Promise<string | undefined> {
    if (contactKeys.includes("title")) {
        if (titleId) {
            const env = await getMxtsEnv(context);
            return (await context.mxtsApi.getCustomerTitles(env, {})).find((customerTitle: CustomerTitle) => customerTitle.titleId === titleId)!.title;
        }
    }
}

async function getCountry(context: ApiContext, contactKeys: string[], countryId?: string | number | null): Promise<string | undefined> {
    if (contactKeys.includes("country")) {
        if (countryId) {
            const env = await getMxtsEnv(context);
            return (await context.mxtsApi.countries(env, { size: MXTS.MAX_RESULTS })).content.find((country) => country.countryId === countryId)!.longName;
        }
    }
}

async function getLanguage(context: ApiContext, contactKeys: string[], languageId?: string | number | null): Promise<string | undefined> {
    if (contactKeys.includes("language")) {
        if (languageId) {
            const env = await getMxtsEnv(context);
            return (await context.mxtsApi.languages(env, {})).find((language) => language.languageId === languageId)!.name!;
        }
    }
}
