/**
 * Contain global contants
 * TODO: add global constants to this file from different directories.
 */
/* eslint-disable */
export enum DATE_FORMAT {
    DEFAULT = "DDMMYYYY",
    ELASTIC = "YYYY-MM-DD",
    MXTS = "YYYY-MM-DD",
    MXTS_DATETIME = "YYYY-MM-DDTHH:mm:ss",
    MXTS_DATETIME_UTC = "YYYY-MM-DDTHH:mm:ssZ",
    REDUX_PERSIST_DATETIME = "YYYY-MM-DDTHH:mm:ss.SSSZ",
    CMS_API_DATETIME = REDUX_PERSIST_DATETIME,
    DISPLAY = "DD-MM-YYYY",
    DAY_WITH_DATE = "dddd DD MMMM YYYY",
    SHORT_DAY_AND_MONTH = "ddd DD MMM YYYY",
    LONG_DATE = "DD MMMM YYYY",
    US_LONG_DATE = "MMMM DD YYYY",
    HOURS_MINUTES = "HH:mm",
    YEAR_MONTH = "YYYY-MM",
    MONTH_DATE_YEAR = "MM-DD-YYYY",
    DATE_WITH_SHORT_YEAR = "DD-MM-YY",
    DATE_WITH_SHORT_MONTH = "MMM-DD-YYYY",
    DATE_WITH_SHORT_MONTH_REVERSED = "DD-MMM-YYYY",
    SHORT_MONTH_DAY_YEAR = "MMM DD, YYYY",
    DAY_WITH_MONTH_NAME = "DD MMM YYYY",
    MONTH_YEAR = "MMMM-YYYY",
    TIME = "h:mm a",
    TWELVE_HOUR_WITH_AMPM = "HH:mm A",
    DATE_TIME_HOURS_MINUTES = "DD-MM-YYYY,HH:mm A",
    DATE_TIME_HOURS_MINUTES_SHORT = "DD-MM-YYYY h:mm a",
    SHORT_DAY_AND_MONTH_WITH_DATE = "dd, D MMM YYYY",
    BASED_ON_LOCALE = "L",
    LOCALE_TIME = "LT",
    LOCALE_DATE = "LL",
    DAY_DATE = "DD MMM",
    DAY_DATE_YEAR = "DD MMM YYYY",
    LOCALE_DATE_WITH_SHORT_DAY = "ddd LL",
    LOCALE_DATE_WITH_DAY = "dddd LL",
    HOUR = "hh",
    DAY_DATE_SHORT_MONTH = "ddd DD MMM",
    DAY_DATE_SHORT_MONTH_YEAR = "ddd DD MMM YYYY",
    DAY_DATE_MONTH_TIME = "ddd D MMM — hh:mm - hh:mm",
    DAY_TIME = "ddd: hh:mm - hh:mm",
}

export const AVAILABILITY_CONSTANTS = {
    NO_SPECIAL: "no-special",
    APPLICATION: "WEB_MANAGER",
    MAX_DURATION: 28,
};

export const MXTS_DATE_FORMATS = [DATE_FORMAT.MXTS_DATETIME_UTC, DATE_FORMAT.MXTS_DATETIME, DATE_FORMAT.MXTS];

export const META_ROBOTS_DEFAULT = "index, follow";

export const LOCAL_STORAGE_KEYS = {
    MXTS_TOKEN: "MXTS_TOKEN",
    CLIENT_NAME: "CLIENT_NAME",
    MY_ENV_TOKEN: "MY_ENV_TOKEN",
    UNIVERSAL_AUTH_TOKEN: "UNIVERSAL_AUTH_TOKEN",
    FRONTEND_EDITED_WIDGETS: "FRONTEND_EDITED_WIDGETS",
};

export const MXTS = {
    MAX_RESULTS: 2000,
    // recommended default page request size
    PAGE_REQUEST_SIZE: 150,
};

export const MS = {
    ONE_DAY: 24 * 60 * 60 * 1000, // hours*minutes*seconds*milliseconds
    ONE_HOUR: 1000 * 60 * 60,
    ONE_MINUTE: 1000 * 60,
    THIRTY_SECONDS: 1000 * 30,
    THREE_SECONDS: 1000 * 3,
};

// TODO: in the future we should probably make this configurable somewhere
export const ADULT_AGE = 18;

interface IWhitelistEmbedHostsPairing {
    [name: string]: string[];
}

// The domain/host names should not be followed by sub-url names or slashes
export const WHITELIST_EMBED_HOSTS_PAIRING: IWhitelistEmbedHostsPairing = {
    "cms-local.maxxton.net": ["127.0.0.1"],
    "localhost": ["127.0.0.1"],
    "embed.farmcamps.com": ["farmcamps.com", "staging-farmcamps.kinsta.cloud", "farmcamps.kinsta.cloud"],
    "embed.corporatehousingfactory.com": ["corporatehousingfactory.com"],
    "bookings.corporatehousingfactory.com": ["corporatehousingfactory.com"],
    "servicedapartments.nl": ["test.boost.systems", "sa.boost.systems"],
    "embed.servicedapartments.nl": ["test.boost.systems", "sa.boost.systems"],
    "embed.vodatent.nl": ["horstmannsbos.nl", "recreationparkuilenburg.nl", "recreatieparkuilenburg.nl"],
};

export const SERVER = {
    // How long to cache the url trailing slash preference setting?
    TRAILING_SLASH_CACHE_TIMEOUT_MS: 1000 * 60 * 10,
};

export enum ResultStyleSelector {
    DISPLAY_STYLE_HIDE = "displayStyleHide",
    DEFAULT = "default",
    SHOWING_X_RESULT = "displayStyle1",
    X_RESULT = "displayStyle2",
}
