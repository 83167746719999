import * as moment from "moment";

import {
    AddOn,
    AddOnPricesRequest,
    AddOnPricesResult,
    ApiCallOptions,
    ChoiceReservedResource,
    DynamicFieldInfo,
    MxtsApi,
    PriceEngineAddOn,
    Quantifier,
    ResourceType,
    SubjectQuantity,
    SupplierAddOnPricesRequest,
    SupplierAddOnPricesResult,
} from "@maxxton/cms-mxts-api";

import { AddOnFilterParams } from "../../../../AddOnsContext";
import { AddOnsWidgetOptions } from "../../../../AddOns.types";
import { BillState } from "../../../../../../../redux/reducers/billReducer";
import { CMSProviderProperties } from "../../../../../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../../../../../utils/constants";
import { DisplayType } from "../../../addOn.util";
import { DynamicFilter } from "../../../../../../../redux/reducers/dynamicFilter.types";
import { MyEnvState } from "../../../../../../../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption } from "../../../../../../mxts/selectOption.types";
import { NumberUtil } from "../../../../../../../utils/number.util";
import { SupplierAddOnContextProps } from "../../../../SupplierAddOnContext";
import { getCustomerStatusId } from "../../../../../../../sagas/billSaga.util";
import { isRequestAbortedError } from "../../../../../../../utils/logs.util";
import { reportError } from "../../../../../../../utils/report.utils";

export interface AddOnPrice {
    price: string;
    priceIsRelative: boolean;
    isAvailable?: boolean;
    pricePerItem?: number;
}

// eslint-disable-next-line max-lines-per-function
export const fetchAddOnPrice = async ({
    addOn,
    addOnFilterParams,
    dynamicFieldCodes,
    env,
    supplierAddOnParams,
    cmsContext,
    billState,
    widgetOptionsId,
    dynamicFilter,
    myEnvState,
}: {
    addOn: AddOn;
    addOnFilterParams: AddOnFilterParams;
    dynamicFieldCodes?: NumberMultiSelectOption[];
    env: ApiCallOptions;
    supplierAddOnParams?: Partial<SupplierAddOnContextProps>;
    cmsContext?: CMSProviderProperties;
    billState?: BillState;
    widgetOptionsId: string;
    dynamicFilter?: DynamicFilter;
    myEnvState?: MyEnvState;
}): Promise<AddOnPrice> => {
    const dynamicFieldCodeIds: string = getDynamicFieldCodesAsString(dynamicFieldCodes);
    const { subjects, resourceId, distributionChannelId, reservationCategoryId, rateTypeId, unitId } = addOnFilterParams;
    const fallbackPrice = "-";
    const mxtsApi = cmsContext?.mxtsApi || MxtsApi;
    const widgetOptionsDynamicFieldCodesPaths: Array<keyof AddOnsWidgetOptions> = ["dynamicFieldCodes"];
    let price: string | number | null = null;
    let pricePerItem;
    let priceIsRelative = false;
    let isAvailable;

    if (!price || price === fallbackPrice) {
        let supplierAddonParamStartDate;
        let supplierAddonParamEndDate;
        if (supplierAddOnParams) {
            supplierAddonParamStartDate = moment(supplierAddOnParams.startDate?.label, DATE_FORMAT.DISPLAY, true).isValid()
                ? supplierAddOnParams?.startDate?.label
                : moment(supplierAddOnParams?.startDate?.label).format(DATE_FORMAT.DISPLAY);
            supplierAddonParamEndDate = moment(supplierAddOnParams.endDate?.label, DATE_FORMAT.DISPLAY, true).isValid()
                ? supplierAddOnParams?.endDate?.label
                : moment(supplierAddOnParams?.endDate?.label).format(DATE_FORMAT.DISPLAY);
        }
        const startDateParam = supplierAddonParamStartDate || addOnFilterParams.startDate;
        const startDate: string | undefined = startDateParam && getPriceEngineDateString(startDateParam);
        const endDateParam = supplierAddonParamEndDate || addOnFilterParams.endDate;
        const endDate: string | undefined = endDateParam && getPriceEngineDateString(endDateParam);
        const reservedResources: ChoiceReservedResource[] = billState?.mainBill?.reservedResources[0]?.children.length ? [...billState.mainBill.reservedResources[0].children] : [];
        const customerStatusId = await getCustomerStatusId(env, myEnvState);

        if (startDate && endDate && resourceId && distributionChannelId && reservationCategoryId && rateTypeId) {
            const priceRequest: AddOnPricesRequest | SupplierAddOnPricesRequest = {
                startDate,
                endDate,
                distributionChannelId: +distributionChannelId,
                reservationCategoryId: +reservationCategoryId,
                rateTypeId,
                addOns: [getPriceEngineAddOn({ addOn })],
                customerStatusIds: customerStatusId ? [customerStatusId] : undefined,
                accommodationType: {
                    resourceId,
                    type: ResourceType.ACCOMMODATIONTYPE,
                    unitId,
                    quantity: 1,
                    subjects,
                    // some addOns also calculate over other resources in the reservation so
                    // we are passing all reservedResources which are in reservation container to get an accurate price for each addon
                    children: reservedResources,
                    status: "INITIAL",
                },
            };

            if ((addOn.supplierId && addOn.supplierOrigin) || addOn?.linkedWithSupplier) {
                const resourceLinking = (await mxtsApi.getSuppliersResourcesLinking(env, { resourceIds: [addOn?.resourceId], view: "detail" }))?.content;
                const isLinkedWithProductTypeSupplier = resourceLinking?.some((resource) => resource?.supplier?.type === ResourceType.PRODUCTTYPE);
                if (isLinkedWithProductTypeSupplier) {
                    if (resourceLinking?.[0]?.resourceLinkStatus === "ACTIVE") {
                        priceRequest.resourceId = addOn.resourceId;
                        priceRequest.quantity = supplierAddOnParams?.quantity || 1;
                        priceRequest.subjects = subjects;
                        price = await mxtsApi
                            .calculateSupplierAddOnPrices(env, priceRequest as SupplierAddOnPricesRequest)
                            .then((priceResult: SupplierAddOnPricesResult) => {
                                isAvailable = priceResult.status === "AVAILABLE";
                                if (priceResult.errorCode) {
                                    cmsContext?.alerts.push({ message: "The add-on is not available", color: "danger" });
                                    reportError("Failed to fetch the price break up from price engine:", priceResult.message);
                                    return fallbackPrice;
                                }

                                pricePerItem = priceResult.price;
                                return (priceResult.price * (supplierAddOnParams?.quantity || 1)).toString() || fallbackPrice;
                            })
                            .catch((error) => {
                                if (!isBrowserAbortError(error)) {
                                    reportError("Failed to fetch the price break up from price engine:", error);
                                }
                                return fallbackPrice;
                            });
                    } else {
                        isAvailable = false;
                        reportError("Suppliers Resource Link Status is INACTIVE");
                    }
                }
            } else {
                price = await mxtsApi
                    .calculateAddOnPrices(env, priceRequest)
                    .then((priceResult: AddOnPricesResult) => {
                        const totalPrice: number = priceResult.addOns.map((addOn) => addOn.price || 0).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
                        const quantifiers: Quantifier[] = [];
                        priceResult.addOns.forEach((addOn: ChoiceReservedResource) => addOn?.usedCashflowrules?.forEach((usedCashflowrule: any) => quantifiers.push(usedCashflowrule?.quantifier)));
                        priceIsRelative = quantifiers.includes(Quantifier.RELATIVE);
                        return totalPrice.toString() || fallbackPrice;
                    })
                    .catch((error) => {
                        if (!isBrowserAbortError(error)) {
                            reportError("Failed to fetch the price break up from price engine:", error);
                        }
                        return fallbackPrice;
                    });
            }
        }
    }

    if (dynamicFieldCodeIds) {
        if (addOn.dynamicManagerId) {
            const dynamicField = await mxtsApi
                .dynamicFieldsInfoCustomized(env, {
                    managerId: addOn.dynamicManagerId,
                    code: dynamicFieldCodeIds,
                    widgetOptionsId,
                    widgetOptionsDynamicFieldCodesPaths,
                })
                .then((dynamicFields: DynamicFieldInfo[]) => (dynamicFields?.length ? dynamicFields[0].value : ""))
                .catch(() => fallbackPrice);
            const priceAsNumber = Number(price);
            if (priceAsNumber && cmsContext) {
                price =
                    NumberUtil.priceWithCurrency({
                        price: priceAsNumber,
                        isRelativePrice: priceIsRelative,
                        context: cmsContext,
                        currencyCode: dynamicFilter?.reservationCurrencyCode || addOnFilterParams.currency?.code,
                    }) || null;
            }
            price = price && dynamicField ? price.concat(" ", dynamicField) : price;
        }
    }

    return {
        price: price ?? fallbackPrice,
        priceIsRelative,
        pricePerItem,
        isAvailable,
    };
};

function isBrowserAbortError(error: Error) {
    return !!error?.stack?.startsWith("TypeError: Failed to fetch") || isRequestAbortedError(error);
}

/**
 * Combines the dynamicFieldCodes from the widget configuration into one string and returns it.
 */
export const getDynamicFieldCodesAsString = (dynamicFieldCodes?: NumberMultiSelectOption[]): string => {
    const dynamicFieldCodeIds: number[] | undefined = dynamicFieldCodes?.filter((code: NumberMultiSelectOption) => code.value)?.map((code: NumberMultiSelectOption) => code.value);
    return dynamicFieldCodeIds?.length ? dynamicFieldCodeIds.toString() : "";
};

export const getPriceEngineDateString = (dateString: string): string => {
    const dateWithTimeZone = moment(dateString, DATE_FORMAT.DISPLAY);
    return dateWithTimeZone.format(DATE_FORMAT.ELASTIC);
};

/**
 * Transforms a map of subjects from the dynamic filter to a SubjectQuantity array
 */
export const getSubjectQuantitiesFromDynamicFilter = (subjectsMap: Map<number, number>): SubjectQuantity[] => {
    const subjectQuantities: SubjectQuantity[] = [];

    if (subjectsMap) {
        subjectsMap.forEach((value: number, key: number) => {
            if (value) {
                subjectQuantities.push({
                    subjectId: key,
                    quantity: value,
                });
            }
        });
    }

    return subjectQuantities;
};

/**
 * Transforms the add-on into a PriceEngine friendly format
 */
export const getPriceEngineAddOn = ({ addOn, displayType }: { addOn: AddOn; displayType?: DisplayType }): PriceEngineAddOn => {
    const { resourceId, type } = addOn;

    const priceEngineAddOn: PriceEngineAddOn = {
        resourceId,
        type,
        quantity: 1,
        status: "INITIAL",
    };

    switch (displayType) {
        default:
            return priceEngineAddOn;
    }
};
