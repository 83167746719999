import { PermissionKey, PermissionType, PermissionTypes, Permissions } from "@maxxton/cms-mxts-api";

import { ApiContext } from "../containers/cmsProvider.types";
import { getClientIdFromToken } from "../components/admin/utils";
import { getMxtsEnv } from "../plugins/mxts";

const { READ, CREATE, EDIT, DELETE, EXECUTE, NONE } = PermissionType;

// returns all permission details
export async function getPermissions(apiContext: ApiContext): Promise<Permissions> {
    let filteredPermissions: Permissions = {};
    if (localStorage) {
        const clientId = getClientIdFromToken();
        if (clientId) {
            const apiCallOptions = await getMxtsEnv(apiContext);
            if (apiCallOptions.env.concern) {
                filteredPermissions = await apiContext.mxtsApi.permissions(apiCallOptions, {}, [{ key: "clientId", value: clientId }]).catch((error: Error) => {
                    apiContext.logger.error(error);
                    return {};
                });
            }
        }
    }
    return filteredPermissions;
}

// returns active permission
export function getPermission({ permissions, key }: { permissions: Permissions; key?: PermissionKey }): PermissionType {
    if (!key) {
        return EXECUTE;
    }
    if (permissions[key]) {
        let { permissionTypes } = permissions[key]!;
        permissionTypes = customSort(permissionTypes);
        return permissionTypes[permissionTypes.length - 1] as Exclude<PermissionType, PermissionType.NONE>;
    }
    return NONE;
}

function customSort(array: PermissionTypes) {
    const order: PermissionTypes = [READ, CREATE, EDIT, DELETE, EXECUTE];
    return array.sort((a, b) => order.indexOf(a) - order.indexOf(b));
}

export function canOnlyRead(permission: PermissionType) {
    return permission === READ;
}

export function canRead(permission: PermissionType) {
    return [READ, CREATE, EDIT, DELETE, EXECUTE].includes(permission);
}

export function canCreate(permission: PermissionType) {
    return [CREATE, EDIT, DELETE, EXECUTE].includes(permission);
}

export function canEdit(permission: PermissionType) {
    return [EDIT, DELETE, EXECUTE].includes(permission);
}

export function canDelete(permission: PermissionType) {
    return [DELETE, EXECUTE].includes(permission);
}

export function canExecute(permission: PermissionType) {
    return EXECUTE === permission;
}

export function checkPermissionType({ permissions, key, permissionType }: { permissions: Permissions; key?: PermissionKey; permissionType: PermissionType }): boolean {
    if (!key) {
        return true;
    }
    if (key && permissions[key]) {
        return !!permissions[key]?.permissionTypes.includes(permissionType);
    }
    return true;
}
