import { Theme } from "./";
import baseTheme from "./base";
import myEnvironmentTheme from "./myenvironment";

const theme: Theme = {
    id: "athomeswitzerland",
    name: "At Home Switzerland Theme",
    stylesheets: ["/styles/themes/athomeswitzerland/athomeswitzerland-admin.css"],
    frontendStylesheets: ["/styles/themes/athomeswitzerland/athomeswitzerland.css"],
    styles: [...myEnvironmentTheme.styles, ...baseTheme.styles],
};

export default theme;
