import { BillBalance, BillLine, ChoiceReservedResource } from "@maxxton/cms-mxts-api";
import { OutputSelector, createSelector } from "reselect";
import { getAccoBillLines, getAdditionBillLines, getNormalBillLines, getTotalBillLines } from "../../utils/bill.util";

import { AdditionState } from "../reducers/additionReducer";
import { BillState } from "../reducers/billReducer";
import { State } from "../index";

const billStoreSelectors = (state: State) => state.billState;
const additionStateSelector = (state: State) => state.additionState;

export const billLinesSelector: OutputSelector<State, BillLine[], (billState: BillState) => BillLine[]> = createSelector([billStoreSelectors], (billState: BillState) =>
    billState.mainBill?.customerBill.length ? billState.mainBill?.customerBill : billState.mainBill?.agentBill || []
);
export const billReservedResourcesSelector: OutputSelector<State, ChoiceReservedResource[], (billState: BillState) => ChoiceReservedResource[]> = createSelector(
    [billStoreSelectors],
    (billState: BillState) => billState.mainBill?.reservedResources || []
);
export const billBalanceSelector: OutputSelector<State, BillBalance[], (billState: BillState) => BillBalance[]> = createSelector(
    [billStoreSelectors],
    (billState: BillState) => billState.mainBill?.billBalance || []
);

export const totalBillLinesSelector: OutputSelector<State, BillLine[], (billLines: BillLine[]) => BillLine[]> = createSelector([billLinesSelector], (billLines: BillLine[]) =>
    getTotalBillLines(billLines)
);

export const normalBillLinesSelector: OutputSelector<State, BillLine[], (billLines: BillLine[]) => BillLine[]> = createSelector([billLinesSelector], (billLines: BillLine[]) =>
    getNormalBillLines(billLines)
);

export const additionBillLinesSelector: OutputSelector<State, BillLine[], (billLines: BillLine[], additionState: AdditionState) => BillLine[]> = createSelector(
    [billLinesSelector, additionStateSelector],
    (billLines: BillLine[], additionState: AdditionState) => getAdditionBillLines(billLines, additionState)
);

export const accoBillLinesSelector: OutputSelector<State, BillLine[], (billLines: BillLine[], additionState: AdditionState) => BillLine[]> = createSelector(
    [billLinesSelector, additionStateSelector],
    (billLines: BillLine[], additionState: AdditionState) => getAccoBillLines({ billLines, additionState })
);
