import * as Api from "@maxxton/cms-api";

import { AnyAction, Store, applyMiddleware, compose, createStore } from "redux";
import { ApiContext, CMSProvidedProperties } from "../containers/cmsProvider.types";
import { Currency, DistributionChannel, MxtsDataAccessTokenManager, RateTypes } from "@maxxton/cms-mxts-api";
import { LOCAL_STORAGE_STATE_UUIDS_KEY, convertUrlParamsToState, generateAndApplyStateUuid } from "../utils/urlparam.util";
import { SelectedAddOnsState, initialSelectedAddOnsState } from "./reducers/add-ons/selectedAddOnsReducer";
import { cloneDeep, isEqual, isObject, pick } from "lodash";
import { getCurrencyRT, getRateTypeFromReservation } from "../utils/currency.util";

import { ActionType } from "./actions/index";
import { ActivityPlannerState } from "./reducers/activityPlannerReducer";
import { AddOnsState } from "./reducers/add-ons/addOnsReducer";
import { AdditionState } from "./reducers/additionReducer";
import { AmenitiesState } from "./reducers/amenitiesReducer";
import { AvailabilityState } from "./reducers/availability.types";
import { BillState } from "./reducers/billReducer";
import { BrowserLocation } from "../app.types";
import { DynamicFilter } from "./reducers/dynamicFilter.types";
import { EditFormState } from "./reducers/formAlert";
import { FrontendPageEditState } from "./reducers/frontendPageEditReducer";
import { GuestFormState } from "./reducers/guestFormReducer";
import { InstalmentsState } from "./reducers/instalmentsState";
import { LinkedWidgetsState } from "./reducers/linkedWidgets/linkedWidgetsState";
import { MxtsDataAccessTokenState } from "./reducers/mxtsDataAccessTokenReducer";
import { MyEnvState } from "./reducers/myEnv/myEnvState";
import { PageLockState } from "./reducers/pageLockReducer";
import { Persistor } from "redux-persist/es/types";
import { PrevNextState } from "./reducers/prevNext";
import { PriceMatrixState } from "./reducers/priceMatrixReducer";
import { ReduxPersistenceUtil } from "./persistence/ReduxPersistenceUtil";
import { ReservationCustomerState } from "./reducers/reservationCustomerReducer";
import { ReservationState } from "./reducers/reservationReducer";
import { ResultsPanelState } from "./reducers/resultsPanelReducer";
import { RevealerState } from "./reducers/revealer";
import { SearchEventState } from "./reducers/searchEvent";
import { SessionState } from "./reducers/sessionReducer";
import { SpecialSearchState } from "./reducers/specialSearchReducer";
import { UserInterfaceState } from "./reducers/userInterfaceReducer";
import { UserLoginFormState } from "./reducers/userLoginReducer";
import createSagaMiddleware from "redux-saga";
import { dynamicFilterType } from "./reducers/dynamicFilter.enum";
import { getGlobalFields } from "../utils/globalFields";
import { getMxtsEnv } from "../plugins/mxts";
import { globalApiContext } from "../containers/CmsProvider";
import { isClientLoggedIn } from "../components/utils";
import { isClientSide } from "../utils/generic.util";
import { matchRoutes } from "react-router-dom";
import { parse } from "query-string";
import { reducer } from "./reducers";
import { rootSaga } from "../sagas";
import { shouldStoreMyEnvStateInUrl } from "./reducers/myEnv/myEnvState.util";
import { staticRoutes } from "../routing/static";
import { travelPartyState } from "./reducers/travelParty";
import { updateMyEnvState } from "./actions/myEnvAction";

// eslint-disable-next-line no-underscore-dangle
const reduxDevtoolsExtensionCompose = isClientSide() && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 25 });
const composeEnhancers = reduxDevtoolsExtensionCompose || compose;

export enum ContextFields {
    apiContext = "apiContext",
}

export interface State {
    instalmentsState: InstalmentsState;
    myEnvState: MyEnvState;
    addOnsState: AddOnsState;
    additionState: AdditionState;
    availabilityState: AvailabilityState;
    billState: BillState;
    dynamicFilter: DynamicFilter;
    editFormState: EditFormState;
    guestFormState: GuestFormState;
    pageLockState: PageLockState;
    reservationState: ReservationState;
    resultsPanelState: ResultsPanelState;
    revealerState: RevealerState;
    prevNextState: PrevNextState;
    searchEventState: SearchEventState;
    selectedAddOnsState: SelectedAddOnsState;
    specialSearchState: SpecialSearchState;
    mxtsDataAccessTokenState: MxtsDataAccessTokenState;
    reservationCustomerState: ReservationCustomerState;
    userInterfaceState: UserInterfaceState;
    amenitiesState: AmenitiesState;
    sessionState: SessionState;
    frontendPageEditState: FrontendPageEditState;
    travelPartyState: travelPartyState;
    priceMatrixState: PriceMatrixState;
    activityPlannerState: ActivityPlannerState;
    userLoginFormState: UserLoginFormState;
    linkedWidgetsState: LinkedWidgetsState;
}

export interface ReduxStore {
    store: Store<State, AnyAction>;
    persistor?: Persistor;
    initialState: State;
}

export async function getStore(
    location: BrowserLocation,
    currentLocale: CMSProvidedProperties["currentLocale"],
    site: CMSProvidedProperties["site"],
    apiContext: ApiContext = globalApiContext(),
    distributionChannelId?: string,
    reservationCategoryId?: number,
    flow?: (Api.Flow & Api.WithId) | null
): Promise<ReduxStore> {
    const env = await getMxtsEnv(globalApiContext(), currentLocale.code);
    const urlParamsState: Pick<State, "dynamicFilter" | "myEnvState" | "mxtsDataAccessTokenState"> = await convertUrlParamsToState(location.search, apiContext, env);
    const doAfterReduxStateHydratedFn = async (store: Store) => {
        const emptyUrlParamsState = await convertUrlParamsToState("", apiContext, env);
        removeDefaultStateFields(urlParamsState, emptyUrlParamsState);
        if (urlParamsState.dynamicFilter) {
            store.dispatch({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.blendFilters,
                payload: urlParamsState?.dynamicFilter,
            });
        }
        if (urlParamsState.myEnvState) {
            store.dispatch(updateMyEnvState(urlParamsState.myEnvState));
        }
    };

    const initialState: Pick<State, "dynamicFilter" | "myEnvState" | "mxtsDataAccessTokenState"> = await generateInitialStateForReduxStore(
        location,
        currentLocale,
        site,
        apiContext,
        distributionChannelId,
        reservationCategoryId,
        flow
    );

    return getInitialStore(
        {
            ...getInitialState(),
            ...initialState,
        },
        apiContext,
        doAfterReduxStateHydratedFn
    );
}

/* jscpd:ignore-start */
export async function getDynamicStore(
    location: BrowserLocation,
    currentLocale: CMSProvidedProperties["currentLocale"],
    site: CMSProvidedProperties["site"],
    apiContext: ApiContext = globalApiContext(),
    distributionChannelId?: string,
    reservationCategoryId?: number,
    flow?: (Api.Flow & Api.WithId) | null
): Promise<Partial<ReduxStore>> {
    const initialState: Pick<State, "dynamicFilter" | "myEnvState" | "mxtsDataAccessTokenState"> = await generateInitialStateForReduxStore(
        location,
        currentLocale,
        site,
        apiContext,
        distributionChannelId,
        reservationCategoryId,
        flow
    );
    return {
        initialState: {
            ...getInitialState(),
            ...initialState,
        },
    };
}
/* jscpd:ignore-end */

/* jscpd:ignore-start */
async function generateInitialStateForReduxStore(
    location: BrowserLocation,
    currentLocale: CMSProvidedProperties["currentLocale"],
    site: CMSProvidedProperties["site"],
    apiContext: ApiContext = globalApiContext(),
    distributionChannelId?: string,
    reservationCategoryId?: number,
    flow?: (Api.Flow & Api.WithId) | null
): Promise<Pick<State, "dynamicFilter" | "myEnvState" | "mxtsDataAccessTokenState">> {
    /* jscpd:ignore-end */
    const env = await getMxtsEnv(apiContext, currentLocale.code);
    const urlParamsState: Pick<State, "dynamicFilter" | "myEnvState" | "mxtsDataAccessTokenState"> = await convertUrlParamsToState(location.search, apiContext, env);
    const initialState: Pick<State, "dynamicFilter" | "myEnvState" | "mxtsDataAccessTokenState"> = cloneDeep(urlParamsState);
    const dynamicFilterDC = initialState.dynamicFilter.distributionChannel;
    if (!urlParamsState.dynamicFilter.reservationCategoryId) {
        if (reservationCategoryId && distributionChannelId) {
            initialState.dynamicFilter.reservationCategoryId = reservationCategoryId;
        } else if (site.useDCGroup && dynamicFilterDC?.distributionChannelId) {
            // Automatically assign first linked reservation category if reservationCategoryId is not present
            const dcId = dynamicFilterDC.distributionChannelId;
            const dcReservationCategory = (await apiContext.mxtsApi.getDcReservationCategories(env, { size: 1, sort: "priority,ASC" }, [{ key: "dcId", value: dcId }])).content;
            initialState.dynamicFilter.reservationCategoryId = dcReservationCategory[0].reservationCategoryId;
        }
    }
    initialState.dynamicFilter.stateUuid = await handleStateUuid(parse(location.search), location, initialState.myEnvState, flow || undefined, site);
    if (!dynamicFilterDC && distributionChannelId) {
        const distributionChannel: DistributionChannel | undefined = await apiContext.mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: +distributionChannelId }]).catch((error: Error) => {
            apiContext.logger.error(error.message);
            return undefined;
        });
        initialState.dynamicFilter.distributionChannel = distributionChannel && pick(distributionChannel, ["distributionChannelId", "code"]);
    }
    if (!initialState.dynamicFilter.rateType) {
        let rateType: (Pick<RateTypes, "rateTypeId" | "code"> & { currency: Currency }) | undefined;
        if (initialState.dynamicFilter.reservationId) {
            rateType = await getRateTypeFromReservation({ reservationId: initialState.dynamicFilter.reservationId, env, mxtsApi: apiContext.mxtsApi });
        }
        if (!rateType) {
            rateType = await getCurrencyRT({
                currentLocale,
                site,
                apiCallOptions: env,
                mxtsApi: apiContext.mxtsApi,
                dcId: dynamicFilterDC?.distributionChannelId || Number(distributionChannelId),
            });
        }
        initialState.dynamicFilter.rateType = rateType && pick(rateType, ["rateTypeId", "code"]);
        if (!initialState.dynamicFilter.currency) {
            initialState.dynamicFilter.currency = rateType?.currency;
        }
    }
    if (!initialState.dynamicFilter.currentLocale) {
        initialState.dynamicFilter.currentLocale = currentLocale.code;
    }

    if (isClientSide()) {
        new MxtsDataAccessTokenManager({ accessKeys: initialState.mxtsDataAccessTokenState.accessKeys });
    }

    if (typeof localStorage !== "undefined") {
        const utm = initialState.dynamicFilter.utm;
        if (utm?.utm_source) {
            // When customer enters with a utm_source in the url, overwrite local storage utm values
            localStorage.setItem("referrer", utm.referrer || "");
            localStorage.setItem("utm_channel", utm.utm_channel || "");
            localStorage.setItem("utm_source", utm.utm_source || "");
            localStorage.setItem("utm_campaign", utm.utm_campaign || "");
            localStorage.setItem("utm_medium", utm.utm_medium || "");
            localStorage.setItem("utm_content", utm.utm_content || "");
        }
        if (!initialState.dynamicFilter.utmSource) {
            initialState.dynamicFilter.utmSource = (typeof localStorage !== "undefined" && localStorage.getItem("utm_source")) || undefined;
        }
    }
    return initialState;
}

function removeDefaultStateFields(urlParamsState: Partial<State>, emptyUrlParamsState: Partial<State>): Partial<State> {
    Object.keys(urlParamsState).forEach((stateFieldKey) => {
        const stateField = (urlParamsState as any)[stateFieldKey];
        if (stateField && !Array.isArray(stateField) && isObject(stateField)) {
            Object.keys(stateField)
                .filter((key) => isEqual(stateField[key] || {}, (emptyUrlParamsState as any)[stateFieldKey][key] || {}))
                .forEach((unchangedKey) => delete stateField[unchangedKey]);
        }
    });
    return urlParamsState;
}

async function handleStateUuid(options: any, location: BrowserLocation, myEnvState: MyEnvState, flow?: Api.Flow & Api.WithId, site?: Api.Site & Api.WithId): Promise<string | undefined> {
    if (typeof localStorage === "undefined") {
        return options.stateUuid;
    }
    const stateUuidUsage = await shouldGenerateStateUuidForThePage(site);

    if (options.stateUuid) {
        const localStorageStateUuids: string[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_UUIDS_KEY) || "[]");
        if (!localStorageStateUuids.find((localStorageUuid) => localStorageUuid === options.stateUuid)) {
            // This stateUuid was not generated by this user but by another user. So we have to clone it to a different id.
            const generatedStateUuid = generateAndApplyStateUuid(location);
            await copyRedisValueToKey(options.stateUuid, generatedStateUuid);
            return generatedStateUuid;
        }
        return options.stateUuid;
    }
    const flowDemandsStateUuid = await doesFlowDemandsStateUuid(flow, site);
    if (shouldStoreMyEnvStateInUrl(myEnvState) || flowDemandsStateUuid || stateUuidUsage) {
        const generatedStateUuid = generateAndApplyStateUuid(location);
        getGlobalFields().nonExistingStateUuid = generatedStateUuid;
        return generatedStateUuid;
    }
}

function copyRedisValueToKey(existingUuid: string, newUuid: string) {
    const url = `/redux-state-clone?existingUuid=${existingUuid}&newUuid=${newUuid}`;
    return fetch(url, {
        method: "POST",
        headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            "Access-Control-Allow-Origin": "*",
        },
    }).then(async (resp) => {
        if (!resp.ok) {
            throw new Error(`POST ${url} responded with status ${resp.status} (${await resp.text()})`);
        }
        return resp;
    });
}

async function doesFlowDemandsStateUuid(flow?: Api.Flow & Api.WithId, site?: Api.Site & Api.WithId): Promise<boolean> {
    if (matchRoutes(staticRoutes, location.pathname) || !site) {
        return false;
    }
    const sitePage: Api.Widget | null = await Api.SiteApi.findSitemapFromSiteByFriendlyUrl({ siteId: site._id, url: (location as any).pathname });
    const currentStep = sitePage && flow?.root?.find((step) => step.options.pageId === sitePage.options.pageId);
    return !!location.pathname && !!currentStep?.options?.enableStateUuid;
}

async function shouldGenerateStateUuidForThePage(site?: Api.Site & Api.WithId): Promise<boolean> {
    if (matchRoutes(staticRoutes, location.pathname) || !site) {
        return false;
    }
    const sitemapPage: Api.Widget | null = await Api.SiteApi.findSitemapFromSiteByFriendlyUrl({ siteId: site._id, url: location.pathname });
    if (sitemapPage) {
        const page = await Api.PageApi.findById({ id: sitemapPage?.options.pageId });
        return !!page?.shouldGenerateStateUuid;
    }
    return false;
}

// Can be used when we lose the store and need to provide a copy of the existing store (e.g. in Google Maps iframe)
export const getStoreFromReducerStates = ({
    apiContext,
    dynamicFilter,
    availabilityState = {},
    myEnvState = {},
}: {
    apiContext: ApiContext;
    dynamicFilter: DynamicFilter;
    availabilityState?: AvailabilityState;
    myEnvState?: MyEnvState;
}): ReduxStore => {
    const initialState: State = {
        ...getInitialState(),
        dynamicFilter,
        availabilityState,
        myEnvState,
    };

    return getInitialStore(initialState, apiContext);
};

export const getInitialState = (): State => ({
    myEnvState: {},
    additionState: {},
    addOnsState: {
        fetching: true,
    },
    availabilityState: {},
    billState: {},
    reservationCustomerState: {
        customer: undefined,
        currentAddress: undefined,
        fetching: undefined,
    },
    dynamicFilter: {},
    editFormState: {},
    guestFormState: {
        draftValue: {},
        validations: {},
        mandatoryFields: [],
    },
    pageLockState: {},
    prevNextState: {
        useConditionalStepFlow: false,
    },
    travelPartyState: {},
    reservationState: {},
    resultsPanelState: {
        resourceId: undefined,
        unitId: undefined,
        tabsWidgetId: undefined,
        tabId: undefined,
    },
    revealerState: {
        revealerId: "",
    },
    searchEventState: {},
    selectedAddOnsState: initialSelectedAddOnsState,
    specialSearchState: {},
    mxtsDataAccessTokenState: {
        accessKeys: [],
    },
    instalmentsState: {},
    userInterfaceState: {
        reviewRatingsWidget: {
            maxRating: 0,
        },
    },
    amenitiesState: {
        amenities: [],
    },
    sessionState: {
        sessionEndTime: undefined,
        hiddenWidgetId: [],
    },
    frontendPageEditState: {
        isFrontEndEditable: (isClientLoggedIn() && typeof localStorage !== "undefined" ? localStorage.getItem("isFrontEndEditable") || "false" : "false") === "true",
    },
    priceMatrixState: {
        isMatrixFetched: false,
    },
    activityPlannerState: {
        selectedActivities: [],
    },
    userLoginFormState: {
        draftValue: {},
        validations: {},
        mandatoryFields: [],
    },
    linkedWidgetsState: {},
});

export const getInitialStore = (initialState: State, apiContext: ApiContext, doAfterReduxStateHydratedFn?: (store: Store) => void): ReduxStore => {
    let store: Store<State>;
    const sagaMiddleware = createSagaMiddleware({
        context: {
            [ContextFields.apiContext]: apiContext,
        },
    });
    let persistor;
    const stateUuid = initialState.dynamicFilter.stateUuid;
    if (stateUuid) {
        store = createStore(ReduxPersistenceUtil.getPersistedReducer(reducer, stateUuid), initialState as any, composeEnhancers(applyMiddleware(sagaMiddleware))) as any;
        persistor = ReduxPersistenceUtil.persistStore(store);
        if (doAfterReduxStateHydratedFn) {
            ReduxPersistenceUtil.doAfterReduxStateHydrated(persistor, () => doAfterReduxStateHydratedFn(store));
        }
    } else {
        store = createStore(reducer, initialState as any, composeEnhancers(applyMiddleware(sagaMiddleware)));
    }

    sagaMiddleware.run(rootSaga);
    return { store, persistor, initialState };
};
