import * as React from "react";

import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from "react-router-dom";

export interface RouteComponentProps {
    params: Readonly<Params<string>>;
    navigate: NavigateFunction;
    location: Location;
}

const withRouter = (WrappedComponent: React.FunctionComponent | React.ComponentClass) => (props: any) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    return <WrappedComponent {...props} {...{ params, navigate, location }} />;
};

export default withRouter;
