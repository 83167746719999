import * as React from "react";

export const SwitchComponent = (props: { name: string; label: string; checked: boolean; setChecked: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element => {
    const { name, label, checked, setChecked } = props;
    const onCheckedChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked), [checked, setChecked]);
    return (
        <React.Fragment>
            <div className="checkbox row form-group">
                <input id={name} name={name} checked={checked} onChange={onCheckedChange} type="checkbox" className="cmn-toggle cmn-toggle-round form-check-input" />
                <label htmlFor={name} className="style-checkbox-label"></label>
                <label htmlFor={name} className="input-label">
                    {label}
                </label>
            </div>
        </React.Fragment>
    );
};
