import * as React from "react";

import { Template, WithId } from "@maxxton/cms-api";

import { AccommodationType } from "@maxxton/cms-mxts-api";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { Unit } from "../../mxts";
import { WidgetOptions } from "./";
import { injectPropIntoElementTree } from "../../resultsPanel/resultsPanelUtil";
import { isClientLoggedIn } from "../../../components/utils";

interface TemplateProps extends PageWidgetBaseProps<WidgetOptions> {
    classNames: string;
    hideWidget: string;
    template: Template & WithId;
    children: JSX.Element[];
    accommodationType?: AccommodationType;
    useCrpProps?: boolean;
    unit?: Unit;
}

interface TemplateState {
    disableWidget: boolean;
}

export class TemplateWidget extends React.PureComponent<TemplateProps, TemplateState> {
    constructor(props: TemplateProps) {
        super(props);
        this.state = {
            disableWidget: true,
        };
    }

    public componentDidMount() {
        this.setState({ disableWidget: !isClientLoggedIn() });
    }

    public render(): JSX.Element | null {
        const { classNames, hideWidget, children, template, accommodationType, useCrpProps, unit } = this.props;
        const { disableWidget } = this.state;
        if ((disableWidget && hideWidget) || (!disableWidget && hideWidget && localStorage.getItem("isFrontEndEditable") === "false")) {
            return null;
        }
        return (
            <div className={`template ${classNames} ${hideWidget}`}>
                {React.Children.map(children, (child) => injectPropIntoElementTree(child, { flexboxOptions: this.props.flexboxOptions, accommodationType, unit, useCrpProps }))}
            </div>
        );
    }
}
