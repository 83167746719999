import { ArrayUtil } from "../utils/array.util";
import alpinRentalsTheme from "./alpinrentals";
import athomeswitzerlandTheme from "./athomeswitzerland";
import baseTheme from "./base";
import beachRentalsTheme from "./beachrentals";
import beeksebergen from "./beeksebergen";
import belterwiedeTheme from "./belterwiede";
import bonaireTheme from "./bonaire";
import castleTheme from "./castle";
import cityUsTheme from "./cityus";
import colorfulRentalsTheme from "./colorfulrentals";
import corporatehousingfactoryPWATheme from "./corporatehousingfactorypwa";
import corporatehousingfactoryTheme from "./corporatehousingfactory";
import dierenbos from "./dierenbos";
import estivoTheme from "./estivo";
import europarcsnlTheme from "./europarcsnl";
import farmcampsTheme from "./farmcamps";
import giethoornTheme from "./giethoorn";
import glampingVoucherTheme from "./glampingvoucher";
import glemmTheme from "./glemm";
import krimTheme from "./krim";
import krimThemeV2 from "./krimV2";
import krimThemeV3 from "./krimV3";
import laFranceRentalsTheme from "./lafrancerentals";
import lifestyleHolidaysTheme from "./lifestyleholidays";
import molecatenTheme from "./molecaten";
import myEnvironmentTheme from "./myenvironment";
import nauticRentalsTheme from "./nauticrentals";
import olympiaTheme from "./olympia";
import ourtheSommeTheme from "./ourthesomme";
import rcnTheme from "./rcn";
import roompotTheme from "./roompot";
import sandayaTheme from "./sandaya";
import servicedApartmentsTheme from "./servicedapartments";
import skysideTheme from "./skyside";
import theHouseTheme from "./thehouse";
import vacansoleilTheme from "./vacansoleil";
import vodatentTheme from "./vodatent";
import waterstaeteTheme from "./waterstaete";
import weerribbenTheme from "./weerribben";
import weerribbenWiedenTheme from "./weerribbenwieden";
import yosaaTheme from "./yosaa";

export const defaultTheme = baseTheme;

export const themes: Theme[] = [
    alpinRentalsTheme,
    athomeswitzerlandTheme,
    baseTheme,
    beachRentalsTheme,
    belterwiedeTheme,
    bonaireTheme,
    castleTheme,
    cityUsTheme,
    colorfulRentalsTheme,
    corporatehousingfactoryPWATheme,
    corporatehousingfactoryTheme,
    estivoTheme,
    europarcsnlTheme,
    farmcampsTheme,
    giethoornTheme,
    glampingVoucherTheme,
    glemmTheme,
    krimTheme,
    krimThemeV2,
    krimThemeV3,
    laFranceRentalsTheme,
    beeksebergen,
    dierenbos,
    lifestyleHolidaysTheme,
    molecatenTheme,
    myEnvironmentTheme,
    nauticRentalsTheme,
    olympiaTheme,
    ourtheSommeTheme,
    sandayaTheme,
    servicedApartmentsTheme,
    skysideTheme,
    theHouseTheme,
    vacansoleilTheme,
    vodatentTheme,
    rcnTheme,
    roompotTheme,
    waterstaeteTheme,
    weerribbenTheme,
    weerribbenWiedenTheme,
    yosaaTheme,
];

export interface Theme {
    id: string;
    name: string;
    stylesheets: string[];
    frontendStylesheets: string[];
    styles: Style[];
}

interface Style {
    id: string;
    name: string;
    targets: string[];
    classnames: string[];
    options?: any;
}

/**
 * Find a theme by its id. This will return the default theme when no theme is found.
 * @param id
 */
export function findTheme(id: string): Theme {
    return themes.find((theme) => theme.id === id) || defaultTheme;
}

export function findStyles(theme: Theme, targets: string[]): Style[] {
    return ArrayUtil.flatten2Dimensions(targets.map((target) => theme.styles.filter((style) => style.targets.indexOf(target) >= 0)));
}

export function findStyle(theme: Theme, target: string, styleId: string): Style | undefined {
    return theme.styles.find((style) => style.targets.indexOf(target) >= 0 && style.id === styleId);
}

export function findStyleClassNames(theme: Theme, targets: string[], styleId: string | null): string {
    if (styleId == null) {
        return "";
    }
    return ArrayUtil.flatten2Dimensions(
        targets.map((target) => {
            const style = findStyle(theme, target, styleId);
            if (style !== undefined) {
                return style.classnames;
            }
            return [];
        })
    ).join(" ");
}

export function findMultiSelectStyleClassNames(theme: Theme, targets: string[], styleIds: any[]): string {
    if (styleIds == null || styleIds.length === 0) {
        return "";
    }
    let style = "";
    styleIds.forEach((styleId) => {
        const itemStyle = findStyleClassNames(theme, targets, styleId.value);
        if (itemStyle && itemStyle.length > 0) {
            style = style.concat((style.length > 0 ? " " : "") + itemStyle);
        }
    });
    return style;
}
