import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import {
    AffectedAsset,
    WebContent as CmsApiWebContent,
    Locale,
    LocalizedCardContent,
    LocalizedContent,
    LocalizedReviewPerson,
    LocalizedSpecialCardContent,
    WebContentApi,
    WithId,
} from "@maxxton/cms-api";
import { CMSOptions, getCMSOptions } from "../../settings";
import { Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Environment, PermissionType, ReservationStatus } from "@maxxton/cms-mxts-api";
import { WidgetOptions, widgetOptionsForm } from "./";
import { checkVisibilityOnPublishExpirationDate, getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getLocalizedCardContent, getLocalizedSpecialCardContent } from "./webContent.util";
import {
    loadWidgetOptionsFromLocalStorage,
    saveWidgetOptionsInLocalStorage,
    setResetStatusOnOptionsUpdate,
    updateFrontendEditingButtonStatus,
} from "../../../components/frontend-editing/frontendEdit.utils";

import { CMSAware } from "../../../containers/CmsProvider";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { CurrentLocale } from "../../../app.types";
import { Dispatch } from "redux";
import { FlexboxOptions } from "../flexbox";
import { FrontendPageEditAction } from "../../../redux/actions/frontendPageEditAction";
import { FrontendPageEditState } from "../../../redux/reducers/frontendPageEditReducer";
import { GenericFormModal } from "../../../components/generic-form/modal";
import RatingStars from "../../../components/RatingStars";
import { ReservationState } from "../../../redux/reducers/reservationReducer";
import RichTextEditor from "../../../components/frontend-editing/RichTextEditor";
import { SettingsOutlined } from "@mui/icons-material";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { findAffectedAssets } from "../../../form-specs";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isClientSide } from "../../../utils/generic.util";
import { isEmpty } from "lodash";
import namespaceList from "../../../i18n/namespaceList";

export interface WebContentPropsBase {
    id: string;
    options?: WidgetOptions;
    className?: string;
    content: (CmsApiWebContent & WithId) | null;
    context: CMSProvidedProperties;
    reviewInfoTexts?: string;
    imageUrl?: string;
    cardImageUrl?: string;
    sitePageUrl?: string;
    webcontentId?: string;
    friendlyName?: string;
    alt?: string;
    env?: Environment;
    previewLocale?: Locale & WithId;
    flexboxOptions?: FlexboxOptions;
}

interface WebContentDispatchProps {
    dispatchAction: Dispatch<FrontendPageEditAction>;
}

interface WebContentProps extends CMSProvidedProperties, WebContentStoreProps, WebContentPropsBase, WebContentDispatchProps {}

interface WebContentState {
    isOpen?: boolean;
    url?: string;
    siteZone: string;
    cmsSettingZone: string;
    isModalOpen: boolean;
    formOptions: WidgetOptions | undefined;
    animProp: string | undefined;
    content: CmsApiWebContent | null;
    disableWidget: boolean;
    isAdmin?: boolean;
    imageUrl?: string;
    cardImageUrl?: string;
    sitePageUrl?: string;
    friendlyName?: string;
    alt?: string;
    isLoggedIn: boolean;
    isFrontEndEditable?: boolean;
    isEditing: boolean;
    editableWebContent: string;
    newWebContent: string;
    isSaveModalOpen: boolean;
    reviewRating: string;
    localizedReviewPerson: string;
    reviewPerson: string;
    newReviewContent: string;
    editingReview: boolean;
    editingRating: boolean;
    linkedItems?: Array<{
        affectedModelName: string;
        links: AffectedAsset[];
        specId: string;
    }>;
    shouldRenderWebContent: boolean;
    options?: WidgetOptions;
}

interface WebContentStoreProps {
    reservationState: ReservationState;
    frontendPageEditState: FrontendPageEditState;
}

const MODEL_TARGETS = ["webcontent"];

export class WebContentBase extends React.PureComponent<WebContentProps, WebContentState, WebContentStoreProps> {
    private InnerHtmlRef: React.RefObject<HTMLDivElement>;
    constructor(props: WebContentProps) {
        super(props);
        this.handleWebContentEdit = this.handleWebContentEdit.bind(this);
        this.state = {
            isOpen: false,
            siteZone: "",
            cmsSettingZone: "",
            isModalOpen: false,
            formOptions: props.options,
            animProp: "",
            content: props.content,
            disableWidget: true,
            imageUrl: props.imageUrl,
            cardImageUrl: props.cardImageUrl,
            sitePageUrl: props.sitePageUrl,
            alt: props.alt,
            friendlyName: props.friendlyName,
            isEditing: false,
            editableWebContent: "",
            newWebContent: "",
            isSaveModalOpen: false,
            reviewRating: "",
            localizedReviewPerson: "",
            reviewPerson: "",
            newReviewContent: "",
            editingReview: false,
            editingRating: false,
            isLoggedIn: false,
            isFrontEndEditable: props.frontendPageEditState.isFrontEndEditable,
            shouldRenderWebContent: !(props.content?.isPublishDateConfigured || props.content?.isExpirationDateConfigured),
            options: props.options,
        };
        this.InnerHtmlRef = React.createRef();
    }

    async getLinkedItems(id: string) {
        const affectedModels = await findAffectedAssets(id, "webContentId");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedPages"),
                links: affectedModels.affectedPages,
                specId: "page",
            },
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedTemplates"),
                links: affectedModels.affectedTemplates,
                specId: "template",
            },
        ];
    }

    private getEditabaleContent(content: (CmsApiWebContent & WithId) | null) {
        const { context } = this.props;
        const { currentLocale } = context;
        if (content?.cardEnabled) {
            const allContent = content?.localizedCardContent;
            const localeIndex = allContent?.findIndex((elem) => elem.locale === currentLocale.locale);
            return allContent && allContent[localeIndex!].richTextDesc!;
        }
        const allContent = content?.localizedContent;
        const localeIndex = allContent?.findIndex((elem) => elem.locale === currentLocale.locale);
        return allContent && allContent[localeIndex!].content;
    }

    private async handleWebContentEdit() {
        const { context } = this.props;
        const { options } = this.state;
        this.setState({ isEditing: !this.state.isEditing });

        const currentWebContentId = options?.webContentId!;
        const content = await WebContentApi.findById({ id: currentWebContentId });
        const linkedItems = await this.getLinkedItems(currentWebContentId);
        this.setState({ linkedItems });

        if (options && content?.cardEnabled) {
            this.setState({ editableWebContent: this.getEditabaleContent(content)! });
        } else if (options && content?.reviewsEnabled) {
            content.localizedReviewPerson.map((elem) => {
                if (elem.locale === context.currentLocale.locale) {
                    this.setState({ localizedReviewPerson: elem.reviewPerson });
                }
            });
            this.setState({ reviewRating: content?.rating, reviewPerson: content?.reviewPerson, editableWebContent: this.getEditabaleContent(content)! });
        } else {
            this.setState({ editableWebContent: this.getEditabaleContent(content)! });
        }
    }

    private renderRichTextEditor() {
        return (
            <React.Fragment>
                <RichTextEditor webcontent={this.state.editableWebContent} getUpdatedWebContent={this.getUpdatedWebContent} cancelEditing={this.cancelEditing} />
                {this.renderConfirmModal()}
            </React.Fragment>
        );
    }

    private renderConfirmModal() {
        const { linkedItems, isSaveModalOpen } = this.state;
        return (
            <Modal isOpen={isSaveModalOpen} size="md">
                <ModalHeader>
                    <p>{getI18nLocaleString(namespaceList.admin, "WebcontentsEditAffectedMessage")}</p>
                </ModalHeader>
                <ModalBody>
                    {linkedItems?.map(
                        (linkedItem, index) =>
                            linkedItem.links.length > 0 && (
                                <div key={index} className="affectedItems form-elements content-box">
                                    <div className="results-table-wrap">
                                        <h5>{linkedItem.affectedModelName}</h5>
                                        <div className="affected-model-list">
                                            <table className="toc-layout table table-hover">
                                                <tbody>
                                                    {linkedItem.links.map((link, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span>{link.name}</span>
                                                            </td>
                                                            <td>
                                                                <SmartLink target="_blank" rel="noreferrer" href={`/webmanager/${linkedItem.specId}/edit/${link.id}`}>
                                                                    <span>
                                                                        <FontAwesome name="edit" />
                                                                        {getI18nLocaleString(namespaceList.admin, "goToPage")}
                                                                    </span>
                                                                </SmartLink>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                    )}
                </ModalBody>
                <ModalFooter>
                    <div className="btn-container">
                        <button onClick={() => this.handleWebContentSave()} className="btn btn-primary">
                            <FontAwesome name="cloud-upload" />
                            {getI18nLocaleString(namespaceList.admin, "save")}
                        </button>
                        <button onClick={this.cancelEditing} className="btn btn-secondary">
                            <FontAwesome name="times" />
                            {getI18nLocaleString(namespaceList.admin, "cancel")}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }

    private renderReviewEditor() {
        const { reviewRating, localizedReviewPerson, reviewPerson, editableWebContent } = this.state;
        return (
            <div className="review-editor">
                <Form>
                    <FormGroup>
                        <label className="floating-label">{getI18nLocaleString(namespaceList.widgetWebContentCard, "reviewRating")}</label>
                        <Input
                            className="inputText"
                            value={reviewRating}
                            type="text"
                            name="reviewRating"
                            id="reviewRating"
                            onChange={(event) => {
                                this.setState({ reviewRating: event.target.value });
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="floating-label">{getI18nLocaleString(namespaceList.widgetWebContentCard, "reviewPerson")}</label>
                        <Input
                            className="inputText"
                            value={localizedReviewPerson}
                            type="text"
                            name="localizedReviewPerson"
                            id="localizedReviewPerson"
                            onChange={(event) => {
                                this.setState({ localizedReviewPerson: event.target.value });
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label className="floating-label">{getI18nLocaleString(namespaceList.widgetWebContentCard, "reviewPerson")}</label>
                        <Input
                            className="inputText"
                            value={reviewPerson}
                            type="text"
                            name="reviewPerson"
                            id="reviewPerson"
                            onChange={(event) => {
                                this.setState({ reviewPerson: event.target.value });
                            }}
                        />
                    </FormGroup>
                </Form>
                <RichTextEditor webcontent={editableWebContent} getUpdatedWebContent={this.getUpdatedWebContent} cancelEditing={this.cancelEditing} />
                {this.renderConfirmModal()}
            </div>
        );
    }

    public async componentDidMount() {
        const { content, id } = this.props;
        const { options } = this.state;
        if (!this.props.previewLocale) {
            this.loadWebContentImages();
        }
        const optionsFromLocalStorage = loadWidgetOptionsFromLocalStorage(id, options);
        this.setState({ options: optionsFromLocalStorage as WidgetOptions });
        const isClientLoggedin = isClientLoggedIn();
        if (content?.isJsWidget) {
            this.executeContentAsJs();
        }

        if (isClientSide()) {
            this.setState({
                isAdmin: !!document.querySelector(".backend"),
            });
        }
        let settingOptions: CMSOptions;
        if (isClientSide() && (window as any).cmsOptions) {
            settingOptions = (window as any).cmsOptions;
        } else {
            settingOptions = await getCMSOptions(this.props.context.cmsApi);
        }
        this.props.context.cmsApi.siteApi.findByHost({ host: location.host, projection: { sitemap: 0 } }).then(async (site) => {
            this.setState({
                cmsSettingZone: settingOptions && settingOptions.timezoneList ? settingOptions.timezoneList : "",
                siteZone: site && site.timezoneList ? site.timezoneList : "",
            });
        });
        const animateElement = document.getElementsByClassName(`animate-id${this.props.id}`) ? document.getElementsByClassName(`animate-id${this.props.id}`).item(0) : null;
        if (animateElement) {
            const animate = animateElement.getBoundingClientRect();
            const wH = window.innerHeight;
            const y = animate.top;
            if (y > 0 && wH >= y) {
                const animProp = options && options.animateProperties;
                this.setState({ animProp });
            }
        }

        window.addEventListener("scroll", () => {
            const animateElementInView = document.getElementsByClassName(`animate-id${this.props.id}`) ? document.getElementsByClassName(`animate-id${this.props.id}`).item(0) : null;
            if (animateElementInView) {
                const animateInView = animateElementInView.getBoundingClientRect();
                const wHinView = window.innerHeight;
                const yInView = animateInView.top;
                if (yInView > 0 && wHinView >= yInView) {
                    const animProp = options && options.animateProperties;
                    this.setState({ animProp });
                }
            }
        });
        this.setState({ disableWidget: !isClientLoggedin, isLoggedIn: isClientLoggedin, shouldRenderWebContent: true });
    }
    public componentDidUpdate(prevProps: WebContentProps) {
        const {
            frontendPageEditState: { isFrontEndEditable, isFrontendEditedWidgetsReset },
            options,
        } = this.props;
        const { isModalOpen } = this.state;
        if (isFrontendEditedWidgetsReset !== prevProps.frontendPageEditState.isFrontendEditedWidgetsReset && isFrontendEditedWidgetsReset) {
            this.setState({ options, formOptions: options });
        }
        if (prevProps.frontendPageEditState.isFrontEndEditable !== isFrontEndEditable) {
            this.setState({ isFrontEndEditable });
            if (prevProps.frontendPageEditState.isFrontEndEditable && !isFrontEndEditable && isModalOpen) {
                this.setState({ isModalOpen: false });
            }
        }
    }
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { theme, className, context, env, reservationState, site, previewLocale } = this.props;
        let currentLocale: CurrentLocale = this.props.context.currentLocale;

        if (previewLocale) {
            currentLocale = { locale: previewLocale._id } as CurrentLocale;
        }
        const {
            cmsSettingZone,
            siteZone,
            formOptions,
            content,
            disableWidget,
            isAdmin,
            imageUrl,
            cardImageUrl,
            alt,
            sitePageUrl,
            isEditing,
            isLoggedIn,
            isFrontEndEditable,
            shouldRenderWebContent,
            options,
        } = this.state;

        if (!shouldRenderWebContent) {
            return null;
        }

        const localizedHideContent: boolean | null =
            getLocalizedContent({
                site,
                currentLocale,
                localizedContent: content?.isHidden || [],
                keys: ["hideContent"],
            })?.hideContent || null;

        const hideWidget = getHideWidgetClass(options, disableWidget);
        if (hideWidget === null || content?.isJsWidget || localizedHideContent) {
            return null;
        }
        if (content && (content.isPublishDateConfigured || content.isExpirationDateConfigured)) {
            const checkVisibilityOfContents = checkVisibilityOnPublishExpirationDate(disableWidget, cmsSettingZone, siteZone, content);
            if (!checkVisibilityOfContents) {
                return null;
            }
        }
        if (options?.checkReservationStatus && reservationState.reservation) {
            if (reservationState.reservation.status === ReservationStatus.DECLINED) {
                return (
                    <div className="reservation-feedback">
                        <span className="reservation-feedback__message">{getI18nLocaleString(namespaceList.widgetWebContent, "declinedReservationFeedback", context.currentLocale, context.site)}</span>
                    </div>
                );
            }
        }
        const modal =
            isFrontEndEditable && formOptions ? (
                <GenericFormModal
                    isOpen={this.state.isModalOpen}
                    label="front-edit-modal"
                    toggle={this.toggleModal}
                    title={getI18nLocaleString(namespaceList.admin, "edit", context.currentLocale, context.site)}
                    spec={widgetOptionsForm}
                    mode="admin_edit"
                    value={formOptions}
                    rootValue={formOptions}
                    onSave={this.handleOnSave}
                    onChange={this.handleWebcontentOptionsChange}
                    // TODO: pass apprpriate permission received by WebContent
                    permission={PermissionType.EXECUTE}
                />
            ) : null;

        const localizedContent: LocalizedContent | null = getLocalizedContent({
            site,
            currentLocale,
            localizedContent: content?.localizedContent || [],
            keys: ["content"],
        });
        const localizedCardContent: LocalizedCardContent = getLocalizedCardContent({
            site,
            currentLocale,
            localizedCardContents: content?.localizedCardContent || [],
        });
        const localizedSpecialCardContent: LocalizedSpecialCardContent = getLocalizedSpecialCardContent({
            site,
            currentLocale,
            localizedSpecialCardContents: content?.localizedSpecialCard || [],
        });
        const localizedReviewPerson: LocalizedReviewPerson | null = getLocalizedContent({
            site,
            currentLocale,
            localizedContent: content?.localizedReviewPerson || [],
        });

        let html = "";
        let altText: string | undefined;
        if (!isEmpty(localizedCardContent)) {
            altText = alt || localizedCardContent.cardTitle;
        }
        if (content && localizedContent?.content) {
            const contentStr = localizedContent.content;
            if (contentStr.startsWith('{"blocks"') || contentStr.startsWith('{"entityMap"')) {
                try {
                    const contentRaw = JSON.parse(contentStr);
                    html = content.isRawHtmlWidget ? convertFromRaw(contentRaw).getPlainText() : draftToHtml(contentRaw);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error("static content parse error", contentStr, e);
                    html = getI18nLocaleString(namespaceList.widgetWebContent, "failedToLoadContent", context.currentLocale, context.site);
                }
            } else {
                html = contentStr;
            }
        }

        const modelClasses = findMultiSelectStyleClassNames(theme, MODEL_TARGETS, content ? content.styleIds : []);
        const paddingControl = options ? `${options.paddingTop || ""} ${options.paddingBottom || ""}` : undefined;
        const animationControl = `${options?.showAnimate ? "animated " + this.state.animProp + " animate-id" + this.props.id : ""}`;
        const fullClassName = options
            ? `webcontent-content ${
                  options.backgroundPanel?.indexOf("theme") > -1 ? `background-color-${options.backgroundPanel}` : options.backgroundPanel?.indexOf("rgba") === -1 ? options.backgroundPanel : ""
              } ${options.addBullets ? "add-bullets" : ""} ${options.centeredText ? "content-centered" : ""} ${className} ${modelClasses}`
            : undefined;
        // usage of different icons here
        const iconClassName = options?.iconProperties ? (
            <FontAwesome
                name={options.iconProperties}
                className={`fontawesome-${options.iconProperties} ${
                    options.iconColor?.indexOf("theme") > -1 ? `color-${options.iconColor}` : options.iconColor?.indexOf("rgba") === -1 ? options.iconColor : ""
                } icon ${options.iconSize ? `icon${options.iconSize}` : ""}  ${options.iconPosition ? "align-self-" + options.iconPosition : ""}`}
                style={{ color: options.iconColor?.indexOf("rgba") > -1 ? options.iconColor : undefined }}
            />
        ) : undefined;
        const showIcon = options?.showIcons ? iconClassName : "";
        let richTextDesc;
        if (localizedCardContent?.richTextDesc?.startsWith('{"blocks"') || localizedCardContent?.richTextDesc?.startsWith('{"entityMap"')) {
            richTextDesc = draftToHtml(JSON.parse(localizedCardContent?.richTextDesc));
        } else {
            richTextDesc = localizedCardContent?.richTextDesc;
        }
        const configurationBar = !isAdmin && isFrontEndEditable && (
            <div className="configuration-bar">
                <div className="mcms-tooltip-wrap">
                    <a onClick={this.handleWebContentEdit}>
                        <FontAwesome name="edit" />
                        <span className="mcms-tooltip mcms-tooltip--left">{getI18nLocaleString(namespaceList.admin, "editFrontWebcontent", context.currentLocale, context.site)}</span>
                    </a>
                </div>
                <div className="mcms-tooltip-wrap">
                    <a onClick={this.toggleModal}>
                        <SettingsOutlined />
                        <span className="mcms-tooltip mcms-tooltip--left">{getI18nLocaleString(namespaceList.admin, "changeFrontWebcontent", context.currentLocale, context.site)}</span>
                    </a>
                </div>
            </div>
        );

        const useStyleDiv = (
            <div
                style={{
                    backgroundImage: `url(${cardImageUrl ? cardImageUrl.replace("t_mcms_larger", "t_mcms_medium") : ""})`,
                }}
                className="teasers__image"
            />
        );
        if (options && content?.isRawHtmlWidget && content.reviewsEnabled) {
            return (
                <div className={`general-reviews controls-wrap ${paddingControl} ${animationControl} ${isLoggedIn && isFrontEndEditable ? "show-controls" : ""} ${hideWidget}`}>
                    {isLoggedIn && configurationBar}
                    {isEditing ? (
                        this.renderReviewEditor()
                    ) : (
                        <div className="review-rating">
                            {!hideWidget && <div dangerouslySetInnerHTML={{ __html: html }} />}
                            <RatingStars rating={parseFloat(content.rating)} className="space-mr-xxs" />
                            <span className="rating__text space-mr-xxs">{`${localizedReviewPerson?.reviewPerson || content.reviewPerson}`}</span>
                            <a className="hover-link rating__info" onClick={this.handleToggle}>
                                <FontAwesome name="info-circle" />
                                {this.getReviewInfotextModal()}
                            </a>
                        </div>
                    )}
                    {modal}
                </div>
            );
        } else if (options && content?.reviewsEnabled) {
            return (
                <div className={`review-rating general-reviews controls-wrap ${paddingControl} ${animationControl} ${isLoggedIn && isFrontEndEditable ? "show-controls" : ""} ${hideWidget}`}>
                    {isLoggedIn && configurationBar}
                    {isEditing ? (
                        this.renderReviewEditor()
                    ) : (
                        <div
                            className={fullClassName}
                            style={{ background: options.backgroundPanel?.indexOf("rgba") > -1 ? options.backgroundPanel : undefined }}
                            {...(isLoggedIn && isFrontEndEditable ? { onClick: this.handleWebContentEdit } : {})}
                        >
                            {!hideWidget && <div dangerouslySetInnerHTML={{ __html: html }} />}
                            <div>
                                <RatingStars rating={parseFloat(content.rating)} className="space-mr-xxs" />
                                <span className="remark--rating__text font-brand font-700 space-mr-xxs">{content.rating}</span>
                                {!localizedReviewPerson && content.reviewPerson && <span className="rating__text">{`${content.reviewPerson}`}</span>}
                                {localizedReviewPerson && <span className="rating__text space-mr-xxs">{`${localizedReviewPerson.reviewPerson || content.reviewPerson}`}</span>}
                            </div>
                        </div>
                    )}
                    {modal}
                </div>
            );
        } else if (options && content && !content.cardEnabled) {
            return (
                <div
                    className={`controls-wrap ${paddingControl} ${animationControl} ${isLoggedIn && isFrontEndEditable ? "show-controls" : ""} ${options.centerAligned ? "text-center" : ""} ${
                        options.iconDisplayColumns ? "icon-columns" : ""
                    } ${showIcon ? "display-icon" : ""} ${options.borderBox || ""} ${hideWidget}`}
                >
                    {isLoggedIn && configurationBar}
                    {showIcon}
                    {imageUrl ? <img loading="lazy" src={imageUrl} /> : null}

                    {isEditing
                        ? this.renderRichTextEditor()
                        : !hideWidget && (
                              <div
                                  className={fullClassName}
                                  style={{ background: options.backgroundPanel?.indexOf("rgba") > -1 ? options.backgroundPanel : undefined }}
                                  ref={this.InnerHtmlRef}
                                  dangerouslySetInnerHTML={{ __html: html }}
                                  {...(isLoggedIn && isFrontEndEditable ? { onClick: this.handleWebContentEdit } : {})}
                              />
                          )}
                    {modal}
                </div>
            );
        } else if (options && content?.cardEnabled && content.cardSpecial && !content.cardTeaserEnabled) {
            const usingCardImage = localizedCardContent?.cardImage ? (
                <figure>
                    <div className="special-card-title">
                        <div>{`${localizedSpecialCardContent?.cardSpecialTitle}`}</div>
                        <div>{localizedSpecialCardContent?.cardSpecialDiscount}</div>
                    </div>
                    <CardImg
                        loading="lazy"
                        top
                        className={`${content.cardSpecial || ""}`}
                        width="100%"
                        src={`${cardImageUrl ? cardImageUrl.replace("t_mcms_larger", "t_mcms_medium") : ""}`}
                        alt={`${altText}`}
                    />
                </figure>
            ) : null;
            let requestParams = "";
            if (localizedSpecialCardContent?.addRequestParams) {
                if (localizedSpecialCardContent.addStartDate && localizedSpecialCardContent.startDate) {
                    requestParams += "&startdate=" + localizedSpecialCardContent.startDate.split("-").reverse().join("");
                }
                if (localizedSpecialCardContent.addEndDate && localizedSpecialCardContent.endDate) {
                    requestParams += "&enddate=" + localizedSpecialCardContent.endDate.split("-").reverse().join("");
                }
                if (localizedSpecialCardContent.stay) {
                    requestParams += "&stay=" + localizedSpecialCardContent.stay;
                }
                if (localizedSpecialCardContent.amenities) {
                    requestParams += "&amenity=" + localizedSpecialCardContent.amenities;
                }
                if (localizedSpecialCardContent.resort) {
                    requestParams += "&resort=" + localizedSpecialCardContent.resort;
                }
                if (localizedSpecialCardContent.resourceId) {
                    requestParams += "&resourceid=" + localizedSpecialCardContent.resourceId;
                }
                if (localizedSpecialCardContent.specialCode) {
                    requestParams += "&specialcode=" + localizedSpecialCardContent.specialCode;
                }
            }
            const useImageSetting = options.stylingImage === "dk-circle" ? useStyleDiv : usingCardImage;
            return (
                <div
                    className={`simple-card-wrapper controls-wrap ${content.cardSpecial ? "special-card" : ""} ${fullClassName} ${
                        isLoggedIn && isFrontEndEditable ? "show-controls" : ""
                    } ${hideWidget}`}
                    style={{ background: options.backgroundPanel?.indexOf("rgba") > -1 ? options.backgroundPanel : undefined }}
                >
                    {isLoggedIn && configurationBar}
                    {isEditing ? (
                        this.renderRichTextEditor()
                    ) : (
                        <div className="layout layout--xxs">
                            <Card className={`layout__item ${options.stylingCardBorder || ""} ${paddingControl} ${animationControl} ${localizedCardContent?.clickOnCard ? "clickable-card" : ""}`}>
                                <div className="card-wrap">
                                    <div className={`card-img-wrapper ${options.stylingImage || ""}`}>{useImageSetting}</div>
                                    <CardBody
                                        className={`space-p background-neutral-10 ${content.masonry ? "" : "equalcards"} ${
                                            localizedCardContent?.isSitePageUrl || (localizedCardContent?.siteId && localizedCardContent.pageId) ? "btn-added" : ""
                                        }`}
                                    >
                                        {localizedCardContent?.cardTitle ? <CardTitle tag="h4">{localizedCardContent.cardTitle}</CardTitle> : null}
                                        {localizedCardContent?.cardSubTitle ? <CardSubtitle>{localizedCardContent.cardSubTitle}</CardSubtitle> : null}
                                        {localizedCardContent?.location || localizedCardContent?.startDate ? (
                                            <div className="date-location">
                                                {localizedCardContent.location ? (
                                                    <span className="location space-mr-xs">
                                                        <FontAwesome name="map-marker" />
                                                        <span className="location-name">{localizedCardContent.location}</span>
                                                    </span>
                                                ) : null}
                                                {localizedCardContent.startDate ? (
                                                    <span className="date">
                                                        <FontAwesome name="calendar" />
                                                        {` ${localizedCardContent.startDate.toString().substr(0, 10).split("-").reverse().join("-")}`}
                                                    </span>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        {localizedCardContent?.cardDescription ? <CardText>{localizedCardContent.cardDescription}</CardText> : null}
                                        {richTextDesc && !hideWidget ? <div dangerouslySetInnerHTML={{ __html: richTextDesc }} /> : null}
                                        <div className="lower-panel">
                                            {localizedSpecialCardContent?.cardOriginalPrice && (
                                                <div className="original-price">
                                                    <span>{getI18nLocaleString(namespaceList.widgetTypeSearch, "from", context.currentLocale, context.site)}</span>
                                                    <span className="pl-2">{localizedSpecialCardContent.cardOriginalPrice}</span>
                                                </div>
                                            )}
                                            {localizedSpecialCardContent?.cardSpecialPrice && <h4 className="special-price m-0">{localizedSpecialCardContent.cardSpecialPrice}</h4>}
                                            {localizedSpecialCardContent?.cardSpecialPrice && <i className="fa fa-question-circle question-mark" aria-hidden="true" />}
                                            {localizedSpecialCardContent?.priceDescription && <div className="price-description-popover">{localizedSpecialCardContent.priceDescription}</div>}
                                            {localizedSpecialCardContent?.startDate && <label className="start-date">{localizedSpecialCardContent.startDate}</label>}
                                            {localizedSpecialCardContent?.rentability && <p className="rentability">{localizedSpecialCardContent.rentability}</p>}
                                        </div>
                                        <div className="card-btn">
                                            {((localizedCardContent?.externalLink && localizedCardContent.isSitePageUrl === undefined) || localizedCardContent?.isSitePageUrl) &&
                                            localizedCardContent.cardButton ? (
                                                <SmartLink
                                                    className={`button button--l ${
                                                        options.buttonColor?.indexOf("theme") > -1
                                                            ? `background-color-${options.buttonColor}`
                                                            : options.buttonColor?.indexOf("rgba") === -1
                                                            ? options.buttonColor
                                                            : ""
                                                    } button--full`}
                                                    style={{ background: options.buttonColor?.indexOf("rgba") > -1 ? options.buttonColor : undefined }}
                                                    href={`${localizedCardContent.externalLink}${requestParams ? "?" + requestParams : ""}`}
                                                    target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                    rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                    title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                                >
                                                    {localizedCardContent.cardButton}
                                                </SmartLink>
                                            ) : localizedCardContent && sitePageUrl && localizedCardContent.cardButton ? (
                                                <SmartLink
                                                    className={`button button--l ${
                                                        options.buttonColor?.indexOf("theme") > -1
                                                            ? `background-color-${options.buttonColor}`
                                                            : options.buttonColor?.indexOf("rgba") === -1
                                                            ? options.buttonColor
                                                            : ""
                                                    } button--full`}
                                                    style={{ background: options.buttonColor?.indexOf("rgba") > -1 ? options.buttonColor : undefined }}
                                                    href={`${sitePageUrl} ${requestParams ? "?" + requestParams : ""}`}
                                                    target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                    rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                    title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                                >
                                                    {localizedCardContent.cardButton}
                                                </SmartLink>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                    {(localizedCardContent?.isSitePageUrl && !localizedCardContent.externalLink) ||
                                    (!localizedCardContent?.isSitePageUrl && sitePageUrl && localizedCardContent?.clickOnCard) ? (
                                        <SmartLink
                                            className={"card-block-link"}
                                            href={`${sitePageUrl}${requestParams ? "?" + requestParams : ""}`}
                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                        />
                                    ) : localizedCardContent?.externalLink && localizedCardContent.clickOnCard ? (
                                        <SmartLink
                                            className={"card-block-link"}
                                            href={`${localizedCardContent.externalLink}?${requestParams}`}
                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                        />
                                    ) : null}
                                </div>
                            </Card>
                        </div>
                    )}
                    {modal}
                </div>
            );
        } else if (options && content?.cardEnabled && !content.cardTeaserEnabled) {
            const usingCardImage = localizedCardContent?.cardImage ? (
                <CardImg
                    loading="lazy"
                    top
                    className={`${content.cardSpecial || ""}`}
                    width="100%"
                    src={`${cardImageUrl ? cardImageUrl.replace("t_mcms_larger", "t_mcms_medium") : ""}`}
                    alt={`${altText}`}
                />
            ) : null;
            const useImageSetting = options.stylingImage === "dk-circle" ? useStyleDiv : usingCardImage;
            return (
                <div className={`simple-card-wrapper controls-wrap ${options.horizontalView ? "horizontal-card" : ""} ${isLoggedIn && isFrontEndEditable ? "show-controls" : ""} ${hideWidget}`}>
                    {isLoggedIn && configurationBar}
                    {isEditing ? (
                        this.renderRichTextEditor()
                    ) : (
                        <div className="layout layout--xxs">
                            <Card className={`layout__item ${options.stylingCardBorder || ""} ${paddingControl} ${animationControl} ${localizedCardContent?.clickOnCard ? "clickable-card" : ""}`}>
                                <div className="card-wrap">
                                    <div className={`card-img-wrapper ${options.stylingImage || ""}`}>{useImageSetting}</div>
                                    <CardBody
                                        className={`space-p ${content.masonry ? "" : "equalcards"} ${
                                            localizedCardContent?.isSitePageUrl || (localizedCardContent?.siteId && localizedCardContent.pageId) ? "btn-added" : ""
                                        } ${options.backgroundPanel && options.backgroundPanel?.includes("theme") ? `background-color-${options.backgroundPanel}` : ""}`}
                                        style={{ background: options.backgroundPanel?.includes("rgba") ? options.backgroundPanel : undefined }}
                                    >
                                        {localizedCardContent?.cardTitle ? (
                                            <CardTitle tag="h4">
                                                <span>{localizedCardContent.cardTitle}</span>
                                            </CardTitle>
                                        ) : null}
                                        {localizedCardContent?.cardSubTitle ? <CardSubtitle>{localizedCardContent.cardSubTitle}</CardSubtitle> : null}
                                        {localizedCardContent?.location || localizedCardContent?.startDate ? (
                                            <div className="date-location">
                                                {localizedCardContent.location ? (
                                                    <span className="location space-mr-xs">
                                                        <FontAwesome name="map-marker" />
                                                        <span className="location-name">{localizedCardContent.location}</span>
                                                    </span>
                                                ) : null}
                                                {localizedCardContent.startDate ? (
                                                    <div className={`dates ${localizedCardContent.endDate ? "space-mt-xxs" : "single-date"}`}>
                                                        <FontAwesome name="calendar" />
                                                        <span className="date">{localizedCardContent.startDate.toString().substr(0, 10).split("-").reverse().join("-")}</span>
                                                        {localizedCardContent.endDate ? (
                                                            <span className="date">
                                                                {` -
                                                                ${localizedCardContent.endDate.toString().substr(0, 10).split("-").reverse().join("-")}
                                                            `}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        {localizedCardContent?.cardDescription ? <CardText>{localizedCardContent.cardDescription}</CardText> : null}
                                        {richTextDesc && !hideWidget ? <div className="card-text" dangerouslySetInnerHTML={{ __html: richTextDesc }} /> : null}
                                        <div className="card-btn">
                                            {((localizedCardContent?.externalLink && localizedCardContent.isSitePageUrl === undefined) || localizedCardContent?.isSitePageUrl) &&
                                            localizedCardContent.cardButton ? (
                                                <SmartLink
                                                    className={`button button--l ${
                                                        options.buttonColor && options.buttonColor.indexOf("theme") > -1
                                                            ? `background-color-${options.buttonColor}`
                                                            : options.buttonColor && options.buttonColor.indexOf("rgba") === -1
                                                            ? options.buttonColor
                                                            : ""
                                                    } button--full`}
                                                    style={{ background: options.buttonColor && options.buttonColor.indexOf("rgba") > -1 ? options.buttonColor : undefined }}
                                                    href={`${localizedCardContent.externalLink}`}
                                                    target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                    rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                    title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                                >
                                                    {localizedCardContent.cardButton}
                                                </SmartLink>
                                            ) : sitePageUrl && localizedCardContent?.cardButton ? (
                                                <SmartLink
                                                    className={`button button--l ${
                                                        options.buttonColor?.indexOf("theme") > -1
                                                            ? `background-color-${options.buttonColor}`
                                                            : options.buttonColor?.indexOf("rgba") === -1
                                                            ? options.buttonColor
                                                            : ""
                                                    } button--full`}
                                                    style={{ background: options.buttonColor?.indexOf("rgba") > -1 ? options.buttonColor : undefined }}
                                                    href={`${sitePageUrl}`}
                                                    target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                    rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                    title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                                >
                                                    {localizedCardContent.cardButton}
                                                </SmartLink>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                    {(localizedCardContent?.isSitePageUrl && !localizedCardContent.externalLink) ||
                                    (!localizedCardContent?.isSitePageUrl && sitePageUrl && localizedCardContent?.clickOnCard) ? (
                                        <SmartLink
                                            className={"card-block-link"}
                                            href={`${sitePageUrl}`}
                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                        />
                                    ) : localizedCardContent?.externalLink && localizedCardContent.clickOnCard ? (
                                        <SmartLink
                                            className={"card-block-link"}
                                            href={`${localizedCardContent.externalLink}`}
                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                        />
                                    ) : null}
                                </div>
                            </Card>
                        </div>
                    )}
                    {modal}
                </div>
            );
        } else if (options && content?.cardEnabled && content.cardTeaserEnabled && content.cardRevealerEnabled) {
            const usingCardImage = localizedCardContent?.cardImage ? (
                <CardImg loading="lazy" top className={`${content.cardSpecial || ""}`} width="100%" src={`${cardImageUrl}`} alt={`${altText}`} />
            ) : null;
            const useImageSetting = options.stylingImage === "dk-circle" ? useStyleDiv : usingCardImage;
            return (
                <div className={`card-revealer-wrapper controls-wrap ${isLoggedIn && isFrontEndEditable ? "show-controls" : ""} ${hideWidget}`}>
                    {isLoggedIn && configurationBar}
                    {isEditing ? (
                        this.renderRichTextEditor()
                    ) : (
                        <div className="layout layout--xxs card-revealer">
                            <Card className={`layout__item ${options.stylingCardBorder || ""} ${paddingControl} ${animationControl}`}>
                                <div className="front background-neutral-10">
                                    <div className={`${options.stylingImage || ""}`}>{useImageSetting}</div>
                                    <CardBody className={"space-p-s"}>
                                        {localizedCardContent?.cardTitle ? <CardTitle tag="h4">{localizedCardContent.cardTitle}</CardTitle> : null}
                                        {localizedCardContent?.cardSubTitle ? <CardSubtitle>{localizedCardContent.cardSubTitle}</CardSubtitle> : null}
                                        {localizedCardContent?.location || localizedCardContent?.startDate ? (
                                            <div className="date-location">
                                                {localizedCardContent.location ? (
                                                    <span className="location space-mr-xs">
                                                        <FontAwesome name="map-marker" />
                                                        <span className="location-name">{localizedCardContent.location}</span>
                                                    </span>
                                                ) : null}
                                                {localizedCardContent.startDate ? (
                                                    <div className={`dates ${localizedCardContent.endDate ? "space-mt-xxs" : "single-date"}`}>
                                                        <FontAwesome name="calendar" />
                                                        <span className="date">
                                                            {`
                                                            ${localizedCardContent.startDate.toString().substr(0, 10).split("-").reverse().join("-")}
                                                        `}
                                                        </span>
                                                        {localizedCardContent.endDate ? (
                                                            <span className="date">
                                                                {` - ${localizedCardContent.endDate.toString().substr(0, 10).split("-").reverse().join("-")}
                                                            `}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        {localizedCardContent?.cardDescription ? (
                                            <CardText className="">{localizedCardContent.cardDescription}</CardText>
                                        ) : richTextDesc && !hideWidget ? (
                                            <div dangerouslySetInnerHTML={{ __html: richTextDesc }} />
                                        ) : null}
                                    </CardBody>
                                    <div className="read-more-link">
                                        <a>{getI18nLocaleString(namespaceList.widgetAssetPublisher, "readMoreLink", context.currentLocale, context.site)}</a>
                                    </div>
                                </div>
                                <div className="back">
                                    <CardBody className={"space-p"}>
                                        {!hideWidget && <div className="space-mb-m" dangerouslySetInnerHTML={{ __html: html }} />}
                                        {((localizedCardContent?.externalLink && localizedCardContent.isSitePageUrl === undefined) || !localizedCardContent?.isSitePageUrl) &&
                                        sitePageUrl &&
                                        localizedCardContent?.cardButton ? (
                                            <SmartLink
                                                className={`button button--l ${
                                                    options.buttonColor?.indexOf("theme") > -1
                                                        ? `background-color-${options.buttonColor}`
                                                        : options.buttonColor?.indexOf("rgba") === -1
                                                        ? options.buttonColor
                                                        : ""
                                                } button--full`}
                                                style={{ background: options.buttonColor?.indexOf("rgba") > -1 ? options.buttonColor : undefined }}
                                                href={`${sitePageUrl}`}
                                                target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                            >
                                                {localizedCardContent.cardButton}
                                            </SmartLink>
                                        ) : localizedCardContent?.externalLink && localizedCardContent.cardButton ? (
                                            <SmartLink
                                                className={`button button--l ${
                                                    options.buttonColor?.indexOf("theme") > -1
                                                        ? `background-color-${options.buttonColor}`
                                                        : options.buttonColor?.indexOf("rgba") === -1
                                                        ? options.buttonColor
                                                        : ""
                                                } button--full`}
                                                style={{ background: options.buttonColor?.indexOf("rgba") > -1 ? options.buttonColor : undefined }}
                                                href={`${localizedCardContent?.externalLink}`}
                                                target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                title={`${localizedCardContent.cardTitle ? localizedCardContent.cardTitle : localizedCardContent.cardButton}`}
                                            >
                                                {localizedCardContent.cardButton}
                                            </SmartLink>
                                        ) : null}
                                    </CardBody>
                                </div>
                            </Card>
                        </div>
                    )}
                    {modal}
                </div>
            );
        } else if (options && content?.cardEnabled && content.cardTeaserEnabled) {
            return (
                <div className={`"layout--xxs card-teaser-wrap controls-wrap" ${animationControl} ${isLoggedIn && isFrontEndEditable ? "show-controls" : ""} ${hideWidget}`}>
                    {isLoggedIn && configurationBar}
                    {isEditing ? (
                        this.renderRichTextEditor()
                    ) : (
                        <div className={"layout__item card-teaser"}>
                            <Card className={classNames("card-box-image", paddingControl, { "no-overlay": localizedCardContent.removeOverlayFromImage })}>
                                {localizedCardContent?.cardImage ? (
                                    <CardImg loading="lazy" top width="100%" src={`${cardImageUrl ? cardImageUrl.replace("t_mcms_larger", "t_mcms_medium") : ""}`} alt={`${altText}`} />
                                ) : null}
                                <CardBody className={`${localizedCardContent?.clickOnCard ? "clickable-card" : ""}`}>
                                    {localizedCardContent?.cardTitle ? (
                                        <CardTitle tag="h4" className="card-box-image__text">
                                            <span>{localizedCardContent.cardTitle}</span>
                                        </CardTitle>
                                    ) : null}
                                    <div className="card-box-image__overlay card-wrap">
                                        {sitePageUrl && localizedCardContent?.clickOnCard ? (
                                            <SmartLink
                                                className={"card-block-link"}
                                                href={`${sitePageUrl}`}
                                                target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            />
                                        ) : null}
                                        {((localizedCardContent?.externalLink && localizedCardContent.isSitePageUrl === undefined) || localizedCardContent?.isSitePageUrl) &&
                                        localizedCardContent.clickOnCard ? (
                                            <SmartLink
                                                className={"card-block-link"}
                                                href={`${localizedCardContent.externalLink}`}
                                                target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            />
                                        ) : null}
                                        <div className="card-box-image__text">
                                            {localizedCardContent?.cardTitle ? (
                                                <CardSubtitle>
                                                    <strong>{localizedCardContent.cardTitle}</strong>
                                                </CardSubtitle>
                                            ) : null}
                                            {localizedCardContent?.cardDescription ? (
                                                <div>{localizedCardContent.cardDescription} </div>
                                            ) : richTextDesc && !hideWidget ? (
                                                <div dangerouslySetInnerHTML={{ __html: richTextDesc }} />
                                            ) : null}
                                            {(localizedCardContent?.isSitePageUrl && !localizedCardContent.externalLink) || (!localizedCardContent?.isSitePageUrl && sitePageUrl) ? (
                                                <SmartLink
                                                    className={"card-teaser__link"}
                                                    href={`${sitePageUrl}`}
                                                    target={localizedCardContent?.openNewTab ? "_blank" : "_self"}
                                                    rel={localizedCardContent?.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                >
                                                    {getI18nLocaleString(namespaceList.widgetWebContent, "moreLink", context.currentLocale, context.site)}
                                                </SmartLink>
                                            ) : null}
                                            {localizedCardContent?.externalLink && (
                                                <SmartLink
                                                    className={"card-teaser__link"}
                                                    href={`${localizedCardContent.externalLink}`}
                                                    target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                    rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                >
                                                    {getI18nLocaleString(namespaceList.widgetWebContent, "moreLink", context.currentLocale, context.site)}
                                                </SmartLink>
                                            )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    )}
                    {modal}
                </div>
            );
        }
        return (
            <div className={`${paddingControl} ${hideWidget}`}>
                {isLoggedIn && env && (
                    <div>
                        <SmartLink href={options ? WebContentBase.getEditWebContentUrl(context, options?.webContentId) : "#"} target="_blank" rel="noreferrer" className="spacing-ph-xs">
                            <FontAwesome name="edit" />
                        </SmartLink>
                        <a onClick={this.toggleModal} target="_blank">
                            <FontAwesome name="exchange" />
                        </a>
                    </div>
                )}
                {!hideWidget && <div dangerouslySetInnerHTML={{ __html: html }} />}
                {modal}
            </div>
        );
    }

    // Run this function only on componentDidMount
    private static executeThirdPartyLibs(elm: React.RefObject<HTMLDivElement>) {
        if (elm.current) {
            Array.from(elm.current.querySelectorAll("script")).forEach((oldScript) => {
                const newScript = document.createElement("script");
                newScript.defer = true;
                Array.from(oldScript.attributes).forEach((attr) => newScript.setAttribute(attr.name, attr.value));
                newScript.appendChild(document.createTextNode(oldScript.innerHTML));
                oldScript?.parentNode?.replaceChild(newScript, oldScript);
            });
        }
    }

    private static getEditWebContentUrl(context: any, webContentId: string | null | undefined): string {
        const host = (context.location as Location).host;
        return `${context.location.protocol}//${host}/webmanager/webcontent/edit/${webContentId}`;
    }

    private handleOnSave = (newOptions: WidgetOptions) => {
        if (newOptions.webContentId) {
            WebContentApi.findById({ id: newOptions.webContentId }).then((content) => {
                this.setState({ formOptions: newOptions, content });
            });
        }
    };

    private handleWebContentSave = async () => {
        const { options, currentLocale } = this.props;
        const currentWebContentId = this.state.options?.webContentId!;
        const { newWebContent, isEditing, content } = this.state;

        if (!options) {
            return;
        }

        switch (true) {
            case content?.cardEnabled:
                content?.localizedCardContent.map((elem) => {
                    if (elem.locale === currentLocale.locale) {
                        elem.richTextDesc = newWebContent;
                    }
                });
                break;
            case content?.reviewsEnabled:
                content?.localizedReviewPerson.map((elem) => {
                    if (elem.locale === currentLocale.locale) {
                        elem.reviewPerson = this.state.localizedReviewPerson;
                    }
                });
                if (content?.reviewPerson) {
                    content.reviewPerson = this.state.reviewPerson;
                }
                if (content?.rating) {
                    content.rating = this.state.reviewRating;
                }
                if (content) {
                    content.localizedContent.map((elem) => {
                        if (elem.locale === currentLocale.locale) {
                            elem.content = newWebContent;
                        }
                    });
                }
                break;
            default:
                content?.localizedContent.map((elem) => {
                    if (elem.locale === currentLocale.locale) {
                        elem.content = newWebContent;
                    }
                });
        }

        const updatedContent = content as CmsApiWebContent;
        if (updatedContent) {
            const content = await WebContentApi.update({ id: currentWebContentId, item: updatedContent });
            this.setState({ content });
        }
        this.setState({ isEditing: !isEditing, isSaveModalOpen: false });
    };
    private getUpdatedWebContent = (data: string) => {
        this.setState({ newWebContent: data, isSaveModalOpen: !this.state.isSaveModalOpen });
    };

    private cancelEditing = async () => {
        const currentWebContentId = this.state.options?.webContentId!;
        this.setState({ isEditing: !this.state.isEditing, isSaveModalOpen: false });

        const content = await WebContentApi.findById({ id: currentWebContentId });
        this.setState({ content });
    };

    private toggleModal = (e: React.MouseEvent<any, MouseEvent>) => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
        const allFlexbox = document.querySelectorAll(".controls-wrap");
        allFlexbox.forEach((element) => {
            element.classList.remove("webContent-active-clicked-control");
        });
        e.currentTarget.parentElement.parentElement.parentElement.classList.add("webContent-active-clicked-control");
    };

    private handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    private handleWebcontentOptionsChange = (newOptions: WidgetOptions) => {
        const { dispatchAction, id } = this.props;
        this.setState({ options: newOptions });
        setResetStatusOnOptionsUpdate(dispatchAction);
        saveWidgetOptionsInLocalStorage(newOptions, id);
        updateFrontendEditingButtonStatus(dispatchAction);
        if (newOptions.webContentId) {
            WebContentApi.findById({ id: newOptions.webContentId }).then((content) => {
                this.setState({ formOptions: newOptions, content });
            });
        }
    };

    private getReviewInfotextModal(): JSX.Element | undefined {
        const { context } = this.props;
        return (
            <Modal isOpen={this.state.isOpen} toggle={this.handleToggle} className="info-modal">
                <ModalHeader tag="h4" toggle={this.handleToggle} />
                <ModalBody>{getI18nLocaleString(namespaceList.widgetWebContent, "reviewInfotexts", context.currentLocale, context.site)}</ModalBody>
            </Modal>
        );
    }

    private executeContentAsJs() {
        const {
            content,
            context: { currentLocale, site },
        } = this.props;
        const { options } = this.state;
        const localizedContent: LocalizedContent | null = getLocalizedContent({
            site,
            currentLocale,
            localizedContent: content?.localizedContent || [],
        });

        if (localizedContent?.content) {
            try {
                const targetJs: string | null = this.getJsFromWebContent(localizedContent);
                if (targetJs?.trim()) {
                    // eslint-disable-next-line no-eval
                    window.eval(targetJs);
                }
            } catch (error) {
                // eslint-disable-next-line
                console.info("Error during execution of js webcontent " + options?.webContentId + ": ", error);
            }
        }
    }

    private getJsFromWebContent(localizedContent: LocalizedContent): string | null {
        try {
            return convertFromRaw(JSON.parse(localizedContent.content)).getPlainText();
        } catch (err) {
            return localizedContent.content;
        }
    }

    private loadWebContentImages = async () => {
        const {
            content,
            context: { currentLocale, site },
            context,
        } = this.props;
        const { options } = this.state;
        if (!content) {
            // Executes script tags if present inside webcontent
            const webContentId = options?.webContentId;
            const webContent: (CmsApiWebContent & WithId) | null = webContentId ? await context.cmsApi.webContentApi.findByIdDebounced({ id: webContentId }) : null;
            if (webContent) {
                const localizedContent: LocalizedContent | null = getLocalizedContent({ site, currentLocale, localizedContent: webContent.localizedContent });
                if (localizedContent?.content?.includes("<script")) {
                    this.setState({ content: webContent }, () => {
                        if (this.InnerHtmlRef.current) {
                            WebContentBase.executeThirdPartyLibs(this.InnerHtmlRef);
                        }
                    });
                }
            }
        }
    };
}

function mapStateToProps(state: State): WebContentStoreProps {
    return {
        reservationState: state.reservationState,
        frontendPageEditState: state.frontendPageEditState,
    };
}

const mapDispatchToProps = (dispatch: Dispatch<FrontendPageEditAction>): WebContentDispatchProps => ({ dispatchAction: dispatch });

const WebContentConnect = connect<WebContentStoreProps>(mapStateToProps, mapDispatchToProps)(CMSAware<WebContentPropsBase>(WebContentBase));

export const WebContent = wrapProps<WebContentPropsBase>(WebContentConnect);
