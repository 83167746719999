export enum TipsAndTripsInfo {
    TITLE = "TITLE",
    HEADTEXT = "HEADTEXT",
    TEXT = "TEXT",
    DESCRIPTION = "DESCRIPTION",
    DESCRIPTION2 = "DESCRIPTION2",
    IMAGE = "IMAGE",
    ACTION_BUTTON = "ACTION_BUTTON",
}

export enum TipsAndTripsViewStyle {
    GRID = "GRID",
    LIST = "LIST",
}
