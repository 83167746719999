import { ContextSlice, PluginSpec } from "../";
import { PluginOptions, pluginOptionsForm } from "./";

import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

// TODO all mxts and api related types should be moved to cms-mxts-api
export const MXTSPlugin: PluginSpec<PluginOptions> = {
    id: "mxts",
    name: getI18nLocaleObject(namespaceList.pluginMxts, "mxts"),
    description: getI18nLocaleObject(namespaceList.pluginMxts, "mxtsDescription"),
    optionsForm: pluginOptionsForm,
    showOptionsInAdminMenu: true,
    // widgets: [searchFacetWidget, typeSearchWidget, unitSearchWidget],
    widgets: [
        {
            id: "searchfacet",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "mxts-searchfacet" */ "./searchfacet"));
                context?.chunkExtractor?.addChunk("mxts-searchfacet");
                return await ref.load();
            },
            targetName: "searchFacetWidget",
            type: "page",
            widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
        },
        {
            id: "typesearch",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "mxts-typesearch" */ "./typesearch"));
                context?.chunkExtractor?.addChunk("mxts-typesearch");
                return await ref.load();
            },
            targetName: "typeSearchWidget",
            type: "page",
            widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
        },
        {
            id: "unitsearch",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "mxts-unitsearch" */ "./unitsearch"));
                context?.chunkExtractor?.addChunk("mxts-unitsearch");
                return await ref.load();
            },
            targetName: "unitSearchWidget",
            type: "page",
            widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
        },
    ],
};

export default MXTSPlugin;
