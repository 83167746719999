// eslint-disable

// This is a dependency file, hence not to be changed.
import * as React from "react";
import * as Select from "./Select";
import stripDiacritics from "./utils/stripDiacritics";
import { OptionValues, ReactAsyncSelectProps, ReactSelectProps } from "./utils/types";

const defaultCache = {};

const defaultProps = {
    autoload: true,
    cache: defaultCache,
    children: defaultChildren,
    ignoreAccents: true,
    ignoreCase: true,
    loadingPlaceholder: "Loading...",
    options: [],
    searchPromptText: "Type to search",
};

export class Async<TValue = OptionValues> extends React.Component<ReactAsyncSelectProps<TValue>, any> {
    _cache: boolean | { [key: string]: any } | undefined;
    _callback: any;
    select: any;
    static propTypes: any;
    static defaultProps: any;
    constructor(props: ReactAsyncSelectProps<TValue>, context: any) {
        super(props, context);

        this._cache = props.cache === defaultCache ? {} : props.cache;

        this.state = {
            inputValue: "",
            isLoading: false,
            options: props.options,
        };

        this.onInputChange = this.onInputChange.bind(this);
    }

    componentDidMount() {
        const { autoload } = this.props;

        if (autoload) {
            this.loadOptions("");
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ReactAsyncSelectProps<TValue>) {
        if (nextProps.options !== this.props.options) {
            this.setState({
                options: nextProps.options,
            });
        }
    }

    componentWillUnmount() {
        this._callback = null;
    }

    loadOptions(inputValue: any) {
        const { loadOptions } = this.props;
        const cache = this._cache;

        if (cache && Object.prototype.hasOwnProperty.call(cache, inputValue)) {
            this.setState({
                options: (cache as any)[inputValue],
            });

            return;
        }

        const callback = (error: any, data: any) => {
            if (callback === this._callback) {
                this._callback = null;

                const options = (data && data.options) || [];

                if (cache) {
                    (cache as any)[inputValue] = options;
                }

                this.setState({
                    isLoading: false,
                    options,
                });
            }
        };

        // Ignore all but the most recent request
        this._callback = callback;

        const promise = (loadOptions as any)(inputValue, callback);
        if (promise) {
            promise.then(
                (data: any) => callback(null, data),
                (error: any) => (callback as any)(error)
            );
        }

        if (this._callback && !this.state.isLoading) {
            this.setState({
                isLoading: true,
            });
        }
    }

    onInputChange(inputValue: any) {
        const { ignoreAccents, ignoreCase, onInputChange } = this.props;
        let transformedInputValue = inputValue;

        if (ignoreAccents) {
            transformedInputValue = stripDiacritics(transformedInputValue);
        }

        if (ignoreCase) {
            transformedInputValue = transformedInputValue.toLowerCase();
        }

        if (onInputChange) {
            onInputChange(transformedInputValue);
        }

        this.setState({ inputValue });
        this.loadOptions(transformedInputValue);

        // Return the original input value to avoid modifying the user's view of the input while typing.
        return inputValue;
    }

    noResultsText() {
        const { loadingPlaceholder, noResultsText, searchPromptText } = this.props;
        const { inputValue, isLoading } = this.state;

        if (isLoading) {
            return loadingPlaceholder;
        }
        if (inputValue && noResultsText) {
            return noResultsText;
        }
        return searchPromptText;
    }

    focus() {
        this.select.focus();
    }

    render() {
        const { children, loadingPlaceholder, multi, onChange, placeholder, value } = this.props;
        const { isLoading, options } = this.state;

        const props = {
            noResultsText: this.noResultsText(),
            placeholder: isLoading ? loadingPlaceholder : placeholder,
            options: isLoading && loadingPlaceholder ? [] : options,
            ref: (ref: any) => (this.select = ref),
        };

        return (children as any)({
            ...this.props,
            ...props,
            isLoading,
            onInputChange: this.onInputChange,
        });
    }
}

Async.defaultProps = defaultProps;

function defaultChildren(props: ReactSelectProps) {
    return <Select.default {...(props as any)} />;
}
