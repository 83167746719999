import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { ContentState, EditorState, convertToRaw } from "draft-js";

import draftToHtml from "draftjs-to-html";
import { getI18nLocaleString } from "../../i18n";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";

const htmlToDraft = typeof window === "object" && require("html-to-draftjs").default;
const Editor = loadable(() => loadableRetry(() => import("react-draft-wysiwyg")), {
    resolveComponent: ({ Editor }) => Editor,
});

interface Props {
    webcontent: string;
    getUpdatedWebContent: (elem?: string) => void;
    cancelEditing: () => void;
}

export default function RichTextEditor(props: Props) {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [updatedItem, setUpdatedItem] = React.useState<string>();
    const { getUpdatedWebContent, webcontent, cancelEditing } = props;

    React.useEffect(() => {
        updateEditorState();
    }, [webcontent]);

    const handleConvertFromRaw = (rawString: string): EditorState => {
        const blocksFromRawString = htmlToDraft(rawString);
        const contentState = ContentState.createFromBlockArray(blocksFromRawString.contentBlocks, blocksFromRawString.entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const updateEditorState = async () => {
        const updatedEditorState = handleConvertFromRaw(webcontent);
        setEditorState(updatedEditorState);
    };

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        const updatedRawContent = convertToRaw(editorState.getCurrentContent());
        const updatedHtml = draftToHtml(JSON.parse(JSON.stringify(updatedRawContent)));
        setUpdatedItem(updatedHtml);
    };

    return (
        <div className="webcontent-editor frontend-editor-wrapper">
            <Editor editorState={editorState} wrapperClassName="frontend-wrapper" editorClassName="frontend-editor" onEditorStateChange={onEditorStateChange} />
            <button className="publish btn btn-primary" onClick={() => getUpdatedWebContent(updatedItem)}>
                <FontAwesome name="cloud-upload" />
                <span className="ml-2">{getI18nLocaleString(namespaceList.admin, "save")}</span>
            </button>
            <button className="cancel btn btn-secondary ml-2" onClick={() => cancelEditing()}>
                <FontAwesome name="times" />
                <span className="ml-2">{getI18nLocaleString(namespaceList.admin, "cancel")}</span>
            </button>
        </div>
    );
}
