import * as React from "react";

import { Button, ButtonDropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Draggable, { ControlPosition, DraggableData, DraggableEvent } from "react-draggable";
import { FormEditAction, FormEditActionType } from "../../redux/actions/FormAlertAction";
import { Redo, SettingsOutlined, Undo } from "@mui/icons-material";
import { TranslationKey, getI18nLocaleString, getI18nLocaleStringFromParams, wrapProps } from "../../i18n";

import { ActionType } from "../../redux/actions/index";
import { Alerts } from "../../alerts";
import { AlertsPopup } from "../AlertsPopup";
import { CMSAware } from "../../containers/CmsProvider";
import { Dispatch } from "redux";
import { EditMode } from "./form.types";
import { FormSpec } from "../../form-specs";
import FrontendEditingModal from "../frontend-editing/FrontendEditingModal";
import { GenericForm } from "./form";
import { PermissionType } from "@maxxton/cms-mxts-api";
import { connect } from "react-redux";
import { flatten } from "lodash";
import { isClientSide } from "../../utils/generic.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";

const GenericFormButtons = loadable(() => loadableRetry(() => import("./buttons")), {
    resolveComponent: ({ GenericFormButtons }) => GenericFormButtons,
});

interface GenericFormModalBaseProps<E> {
    isOpen: boolean;
    toggle: (isSaved?: boolean | React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => void;
    title: string;
    // form properties
    spec: FormSpec<E>;
    mode: EditMode;
    rootValue?: any;
    value: E;
    className?: string;
    onChange?: (newVal: E) => void;
    onCancel?: (event?: React.MouseEvent<any, MouseEvent>) => void;
    onSave?: (newVal: E) => Promise<string> | void;
    onDelete?: () => void;
    submitLabel?: TranslationKey | string;
    cancelLabel?: TranslationKey | string;
    deleteLabel?: TranslationKey | string;
    enableCancel?: boolean;
    enableSubmit?: boolean;
    enableDelete?: boolean;
    specType?: any;
    editItemType?: any;
    tabLocale?: string;
    permission: PermissionType;
    label?: string;
}

export interface GenericFormModalProps<E> extends GenericFormModalBaseProps<E>, GenericFormModalDispatchProps {}

interface Property {
    required: boolean;
    variable: string;
    visible?: boolean;
    isLocalized?: boolean;
}

interface GenericFormModalState<E> {
    draftValue: any | E;
    alerts: Alerts;
    revisionLengthPopUp: number;
    shouldDisableRedoPopUp: boolean;
    shouldDisableUndoPopUp: boolean;
    settingsToggle: boolean;
    controlPosition: ControlPosition;
}

interface GenericFormModalDispatchProps {
    dispatchAction: Dispatch<FormEditAction>;
}

export class GenericFormModalBase<E> extends React.PureComponent<GenericFormModalProps<E>, GenericFormModalState<E>> {
    private isAdmin: boolean;
    constructor(props: GenericFormModalProps<E>) {
        super(props);
        this.isAdmin = isClientSide() && !!document.querySelector(".backend");
        this.state = {
            draftValue: props.value,
            alerts: new Alerts(),
            revisionLengthPopUp: 0,
            shouldDisableRedoPopUp: true,
            shouldDisableUndoPopUp: true,
            settingsToggle: false,
            controlPosition: { x: 0, y: 0 },
        };
    }

    public componentDidUpdate(prevProps: Readonly<GenericFormModalProps<E>>): void {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({ controlPosition: { x: 0, y: 0 } });
        }
    }

    private handleStop = (_event: DraggableEvent, data: DraggableData) => {
        this.setState({ controlPosition: { x: data.x, y: data.y } });
    };
    // eslint-disable-next-line max-lines-per-function
    public render(): any {
        const { isOpen, label, toggle, title, rootValue, specType, editItemType, spec } = this.props;
        const { draftValue, alerts, shouldDisableRedoPopUp, shouldDisableUndoPopUp, settingsToggle, controlPosition } = this.state;
        const itemType = ["card", "breadcrumb", "language", "static", "menu", "columns", "prevnext"];
        const shouldRenderSettingsDiv = specType === "page" && editItemType && itemType.indexOf(editItemType.type) === -1;
        const draggableProps = {
            handle: ".modal-drag-handle",
            cancel: "button",
            enableUserSelectHack: false,
            defaultClassName: "draggable-admin-modal admin-modal",
        };
        const headerContent = (
            <div className="row">
                <div className={shouldRenderSettingsDiv ? "col-lg-6" : "col-md-12"}>{editItemType && spec ? getI18nLocaleStringFromParams(spec.pluralName) : title ? title : ""}</div>
                {shouldRenderSettingsDiv && (
                    <div className="col-lg-6 text-right">
                        <ButtonDropdown isOpen={settingsToggle} toggle={() => this.setState({ settingsToggle: !settingsToggle })} className="widget-settings">
                            <DropdownToggle>
                                <SettingsOutlined />
                            </DropdownToggle>
                            <DropdownMenu>
                                <div className="dropdown-item">
                                    <Input
                                        id={"hideWidget"}
                                        name="hideWidget"
                                        type="checkbox"
                                        className={"cmn-toggle cmn-toggle-round"}
                                        onChange={this.handleHideWidget}
                                        checked={!!(draftValue && draftValue.hasOwnProperty("showDetail") && draftValue.showDetail)}
                                    />
                                    <Label className="input-label align-middle mb-0 d-inline-block hide-widget-btn" for={"hideWidget"}>
                                        <span />
                                    </Label>
                                    <Label className="hide-menu-label" for={"hideWidget"}>
                                        {getI18nLocaleString(namespaceList.admin, "hideWidget")}
                                    </Label>

                                    <Label className="custom-widget-id-label" for={"customWidgetId"}>
                                        {getI18nLocaleString(namespaceList.admin, "customWidgetId")}
                                    </Label>
                                    <Input
                                        id={"customWidgetId"}
                                        name="customWidgetId"
                                        type="text"
                                        placeholder={editItemType._id}
                                        onChange={this.handleCustomWidgetIdChange}
                                        value={draftValue.customWidgetId}
                                    />
                                </div>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>
                )}
            </div>
        );
        return (
            <React.Fragment>
                {this.isAdmin ? (
                    <Draggable {...draggableProps} position={controlPosition} onStop={this.handleStop}>
                        <Modal isOpen={isOpen} toggle={toggle} size="md" backdrop="static" modalClassName={label}>
                            <ModalHeader tag="h4" toggle={toggle} className="modal-drag-handle">
                                {headerContent}
                            </ModalHeader>
                            <ModalBody>
                                <GenericForm
                                    {...this.props}
                                    buttons="none"
                                    value={draftValue}
                                    enableCancel={false}
                                    rootValue={rootValue}
                                    enableSubmit={false}
                                    onChange={this.handleChange}
                                    shouldDisableSubmit={false}
                                />
                            </ModalBody>
                            {alerts && <AlertsPopup alerts={alerts} />}
                            <ModalFooter className="modal-drag-handle">
                                {specType === "page" && editItemType && !editItemType.type.includes("breadcrumb") && (
                                    <div className="align-self-center undo-redo">
                                        <FormGroup>
                                            <Button
                                                className="send"
                                                color="primary"
                                                disabled={shouldDisableUndoPopUp}
                                                onClick={this.undoPopUp}
                                                title={getI18nLocaleString(namespaceList.admin, "undo")}
                                            >
                                                <Undo />
                                                {getI18nLocaleString(namespaceList.admin, "undo")}
                                            </Button>
                                            <Button
                                                className="send"
                                                color="primary"
                                                disabled={shouldDisableRedoPopUp}
                                                onClick={this.redoPopUp}
                                                title={getI18nLocaleString(namespaceList.admin, "redo")}
                                            >
                                                <Redo />
                                                {getI18nLocaleString(namespaceList.admin, "redo")}
                                            </Button>
                                        </FormGroup>
                                    </div>
                                )}
                                <GenericFormButtons {...this.props} onSubmit={this.handleSave} onCancel={this.handleCancel} shouldDisableSubmit={false} modalClassName={label} />
                            </ModalFooter>
                        </Modal>
                    </Draggable>
                ) : (
                    <FrontendEditingModal
                        restProps={this.props}
                        isOpen={isOpen}
                        toggle={toggle}
                        modalClassName={label}
                        headerContent={headerContent}
                        draftValue={draftValue}
                        rootValue={rootValue}
                        alerts={alerts}
                        handleChange={this.handleChange}
                        handleSave={this.handleSave}
                        handleCancel={this.handleCancel}
                    />
                )}
            </React.Fragment>
        );
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<GenericFormModalProps<E>>): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.setState((state) => ({ ...this.state, draftValue: nextProps.value }));
    }

    private handleHideWidget = () => {
        const { draftValue } = this.state;
        const newWidget: any = !draftValue.hasOwnProperty("showDetail") ? { ...draftValue, showDetail: true } : { ...draftValue, showDetail: !draftValue.showDetail };
        this.undoRedoLocalStoragePopUp(newWidget, "hideWidget");
        this.setState({
            draftValue: newWidget,
        });
        const action: FormEditAction = {
            type: ActionType.FormEdited,
            actionType: FormEditActionType.edited,
            payload: newWidget,
        };
        this.props.dispatchAction(action);
    };

    private handleCustomWidgetIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { draftValue } = this.state;
        const updatedDraftValue: any = { ...draftValue, customWidgetId: event.target.value };
        this.setState({
            draftValue: updatedDraftValue,
        });
        const action: FormEditAction = {
            type: ActionType.FormEdited,
            actionType: FormEditActionType.edited,
            payload: updatedDraftValue,
        };
        this.props.dispatchAction(action);
    };

    private handleSave = () => {
        const { editItemType } = this.props;
        const revisionNew = JSON.parse(localStorage.getItem("editWidgetPopUp") || "[]");
        localStorage.removeItem("saveDraftValue");
        if (revisionNew.length > 1) {
            localStorage.setItem("saveDraftValue", editItemType.type);
        }
        if (revisionNew.length === 1) {
            localStorage.setItem("saveDraftValue", "false");
        }
        localStorage.removeItem("editWidgetPopUp");
        localStorage.removeItem("modalToast");
        const { alerts } = this.state;
        const isRequiredCheckSuccessful = this.checkRequiredFields();
        if (!isRequiredCheckSuccessful) {
            if (alerts) {
                alerts.push({ color: "danger", message: getI18nLocaleString(namespaceList.admin, "requiredFailed") });
            }
            return;
        }
        if (this.props.onSave) {
            this.props.onSave(this.state.draftValue);
        }
        this.setState({ shouldDisableRedoPopUp: true, shouldDisableUndoPopUp: true }, () => {
            this.props.toggle(true);
        });
        // this.props.toggle(true);
    };

    private checkRequiredFields = () => {
        const { tabLocale, rootValue, spec } = this.props;
        const { draftValue } = this.state;
        const properties: any = spec.properties.reduce((totalProps: Property[], prop: any) => {
            if (prop.type === "statictabs") {
                const tabsProperties = prop.tabs.reduce((tabProperty: Property[], tab: any) => {
                    let tabProp: any = [];
                    tab.properties.map((property: any) => {
                        tabProp = property.map((p: any) => {
                            if (p.variable === "localizedContents" || p.variable === "localized") {
                                return p.tabContent.map((localizedProperty: any) => {
                                    const visible = localizedProperty.visible?.(draftValue, tabLocale, draftValue, rootValue);
                                    return { variable: localizedProperty.variable, required: !!localizedProperty.required, visible, isLocalized: true };
                                });
                            }
                            const visible = p.visible?.(draftValue, tabLocale, draftValue, rootValue);
                            return { variable: p.variable, required: !!p.required, visible, isLocalized: false };
                        });
                    });
                    return [...tabProperty, flatten(tabProp)];
                }, []);
                totalProps.push([].concat.apply([], tabsProperties));
                return [].concat.apply([], totalProps);
            }
            const visible: boolean = prop.visible?.(draftValue, tabLocale, draftValue, rootValue);
            totalProps.push({ variable: prop.variable, required: !!prop.required, visible });
            return totalProps;
        }, []);
        return properties.every((prop: any) => {
            if (prop.required && (typeof prop.visible === "undefined" || prop.visible)) {
                if (prop.isLocalized) {
                    if (draftValue.localized?.length) {
                        const doesRequiredFieldFilledInAllLocalizedTabs = draftValue.localized.reduce((accumulator: boolean, currentValue: any) => {
                            const requiredFieldValue = currentValue[prop.variable];
                            const isValueFilled = accumulator && typeof requiredFieldValue !== "undefined" && requiredFieldValue !== "";
                            return isValueFilled;
                        }, true);
                        return doesRequiredFieldFilledInAllLocalizedTabs;
                    }
                    return false;
                }
                return typeof draftValue[prop.variable] !== "undefined" && draftValue[prop.variable] !== "";
            }
            return true;
        });
    };

    private handleCancel = () => {
        const { specType } = this.props;
        localStorage.removeItem("saveDraftValue");
        localStorage.setItem("saveDraftValue", "false");
        localStorage.removeItem("editWidgetPopUp");
        localStorage.removeItem("modalToast");
        if (this.props.onCancel) {
            this.props.onCancel();
        }
        if (specType === "page") {
            this.setState(
                {
                    shouldDisableUndoPopUp: true,
                    shouldDisableRedoPopUp: true,
                },
                () => {
                    this.props.toggle();
                }
            );
        } else {
            this.props.toggle();
        }
    };

    private handleChange = (newVal: any) => {
        const { specType, editItemType, onChange } = this.props;
        if (specType && specType === "page" && editItemType && !editItemType.type.includes("breadcrumb")) {
            this.undoRedoLocalStoragePopUp(newVal);
        }
        this.setState(() => ({ draftValue: newVal }));
        onChange && onChange(newVal);
    };

    private undoRedoLocalStoragePopUp = (draftValue: any, hideWidget?: string) => {
        const { mode, spec } = this.props;
        const revisionNew = JSON.parse(localStorage.getItem("editWidgetPopUp") || "[]");
        const modalToast = JSON.parse(localStorage.getItem("modalToast") || "[]");
        const prop = modalToast.length !== 0 ? modalToast[0][1] : "";
        const newVal = modalToast.length !== 0 ? modalToast[0][0] : "";
        const { revisionLengthPopUp } = this.state;
        // eslint-disable-next-line max-len
        if (mode === "admin_edit" && revisionLengthPopUp > 0 && revisionLengthPopUp < revisionNew.length) {
            let tempRevLength = revisionLengthPopUp;
            const storageLength = revisionNew.length;
            while (tempRevLength < storageLength) {
                revisionNew.pop();
                tempRevLength++;
            }
            localStorage.setItem("editWidgetPopUp", JSON.stringify(revisionNew));
        }
        if (revisionNew.length <= 9) {
            if (prop.label && prop.label === getI18nLocaleString(namespaceList.admin, "style")) {
                if (newVal.length !== 0) {
                    revisionNew.push([draftValue, prop.label, newVal[newVal.length - 1].label]);
                } else {
                    revisionNew.push([draftValue, prop.label, "Empty"]);
                }
            } else if (hideWidget && hideWidget === "hideWidget") {
                revisionNew.push([draftValue, "Hide the Widget", draftValue.showDetail]);
            } else {
                // eslint-disable-next-line max-len
                if (revisionNew.length > 0 && !(JSON.stringify(revisionNew[revisionNew.length - 1][0]) === JSON.stringify(draftValue))) {
                    revisionNew.push([draftValue, prop.label, newVal]);
                }
                if (revisionNew.length === 0) {
                    revisionNew.push([draftValue, prop.label, newVal]);
                }
            }
            localStorage.setItem("editWidgetPopUp", JSON.stringify(revisionNew));
        } else if (revisionNew.length === 10) {
            if (!(JSON.stringify(revisionNew[revisionNew.length - 1][0]) === JSON.stringify(draftValue))) {
                if (prop.label && prop.label === getI18nLocaleString(namespaceList.admin, "style")) {
                    revisionNew.shift();
                    if (newVal.length !== 0) {
                        revisionNew.push([draftValue, prop.label, newVal[newVal.length - 1].label]);
                    } else {
                        revisionNew.push([draftValue, prop.label, "Empty"]);
                    }
                } else if (hideWidget && hideWidget === "hideWidget") {
                    revisionNew.push([draftValue, "Hide the Widget", draftValue.showDetail]);
                } else {
                    revisionNew.shift();
                    revisionNew.push([draftValue, prop.label, newVal]);
                }
            }
            localStorage.setItem("editWidgetPopUp", JSON.stringify(revisionNew));
        }
        this.setState({
            shouldDisableUndoPopUp: mode === "admin_edit" && spec.id === "page" && revisionLengthPopUp === 1,
            shouldDisableRedoPopUp: true,
            revisionLengthPopUp: revisionNew.length,
        });
    };

    private undoPopUp = () => {
        const { mode } = this.props;
        const { revisionLengthPopUp, alerts } = this.state;
        const revisionNew = JSON.parse(localStorage.getItem("editWidgetPopUp") || "[]");
        if (revisionLengthPopUp > 0) {
            this.setState({
                draftValue: revisionNew[revisionLengthPopUp - 2][0],
                shouldDisableRedoPopUp: false,
                shouldDisableUndoPopUp: mode === "admin_edit" && revisionLengthPopUp - 2 === 0,
                revisionLengthPopUp: revisionLengthPopUp - 1,
            });
            if (alerts) {
                alerts.push({ color: "danger", message: revisionNew[revisionLengthPopUp - 1][2] + " of " + revisionNew[revisionLengthPopUp - 1][1] + " has been undone" });
            }
        }
    };

    private redoPopUp = () => {
        const { revisionLengthPopUp, alerts } = this.state;
        const revisionNew = JSON.parse(localStorage.getItem("editWidgetPopUp") || "[]");
        if (revisionLengthPopUp === revisionNew.length) {
            this.setState({
                draftValue: revisionNew[revisionLengthPopUp - 1][0],
                shouldDisableRedoPopUp: true,
            });
            if (alerts) {
                alerts.push({ color: "success", message: revisionNew[revisionLengthPopUp - 1][2] + " of " + revisionNew[revisionLengthPopUp - 1][1] + " has been redone" });
            }
        } else if (revisionLengthPopUp < revisionNew.length) {
            this.setState({
                draftValue: revisionNew[revisionLengthPopUp][0],
                shouldDisableRedoPopUp: false,
                shouldDisableUndoPopUp: false,
                revisionLengthPopUp: revisionLengthPopUp + 1,
            });
            if (revisionLengthPopUp + 1 === revisionNew.length) {
                this.setState({ shouldDisableRedoPopUp: true });
            }
            if (alerts) {
                alerts.push({ color: "success", message: revisionNew[revisionLengthPopUp][2] + " of " + revisionNew[revisionLengthPopUp][1] + " has been redone" });
            }
        }
    };
}

function mapDispatchToProps(dispatch: Dispatch<FormEditAction>): GenericFormModalDispatchProps {
    return { dispatchAction: dispatch };
}

const GenericFormModalBaseType = connect<null, GenericFormModalDispatchProps>(null, mapDispatchToProps)(GenericFormModalBase);

export const GenericFormModal = wrapProps<GenericFormModalBaseProps<any>>(CMSAware<any>(GenericFormModalBaseType));
