// eslint-disable

// This is a dependency file, hence not to be changed.
import * as React from "react";
import Select from "./Select";
import defaultFilterOptions from "./utils/defaultFilterOptions";
import defaultMenuRenderer from "./utils/defaultMenuRenderer";
import { Option, OptionValues, ReactCreatableSelectProps } from "./utils/types";

class CreatableSelect<TValue = OptionValues> extends React.Component<ReactCreatableSelectProps<TValue>, any> {
    inputValue: any;
    labelKey: string;
    valueKey: string;
    select: any;
    _createPlaceholderOption: Option<TValue>;
    static isOptionUnique: any;
    static isValidNewOption: any;
    static newOptionCreator: any;
    static promptTextCreator: any;
    static shouldKeyDownEventCreateNewOption: any;
    static defaultProps: any;
    constructor(props: any, context: any) {
        super(props, context);

        this.filterOptions = this.filterOptions.bind(this);
        this.menuRenderer = this.menuRenderer.bind(this);
        this.onInputKeyDown = this.onInputKeyDown.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onOptionSelect = this.onOptionSelect.bind(this);
    }

    createNewOption() {
        const { isValidNewOption, newOptionCreator, onNewOptionClick, options = [], shouldKeyDownEventCreateNewOption } = this.props;

        if ((isValidNewOption as any)({ label: this.inputValue })) {
            const option = (newOptionCreator as any)({ label: this.inputValue, labelKey: this.labelKey, valueKey: this.valueKey });
            const isOptionUnique = this.isOptionUnique({ option } as any);

            // Don't add the same option twice.
            if (isOptionUnique) {
                if (onNewOptionClick) {
                    onNewOptionClick(option);
                } else {
                    options.unshift(option);

                    this.select.selectValue(option);
                }
            }
        }
    }

    filterOptions(...params: any[]) {
        const { filterOptions, isValidNewOption, options, promptTextCreator } = this.props;

        // TRICKY Check currently selected options as well.
        // Don't display a create-prompt for a value that's selected.
        // This covers async edge-cases where a newly-created Option isn't yet in the async-loaded array.
        const excludeOptions = params[2] || [];

        const filteredOptions = (filterOptions as any)(...params) || [];

        if ((isValidNewOption as any)({ label: this.inputValue })) {
            const { newOptionCreator } = this.props;

            const option = (newOptionCreator as any)({
                label: this.inputValue,
                labelKey: this.labelKey,
                valueKey: this.valueKey,
            });

            // TRICKY Compare to all options (not just filtered options) in case option has already been selected).
            // For multi-selects, this would remove it from the filtered list.
            const isOptionUnique = this.isOptionUnique({
                option,
                options: excludeOptions.concat(filteredOptions),
            });

            if (isOptionUnique) {
                const prompt = (promptTextCreator as any)(this.inputValue);

                this._createPlaceholderOption = (newOptionCreator as any)({
                    label: prompt,
                    labelKey: this.labelKey,
                    valueKey: this.valueKey,
                });

                filteredOptions.unshift(this._createPlaceholderOption);
            }
        }

        return filteredOptions;
    }

    isOptionUnique({ option, options }: { option: any; options: any }) {
        const { isOptionUnique } = this.props;

        options = options || this.select.filterOptions();

        return (isOptionUnique as any)({
            labelKey: this.labelKey,
            option,
            options,
            valueKey: this.valueKey,
        });
    }

    menuRenderer(params: any) {
        const { menuRenderer } = this.props;

        return (menuRenderer as any)({
            ...params,
            onSelect: this.onOptionSelect,
            selectValue: this.onOptionSelect,
        });
    }

    onInputChange(input: any) {
        const { onInputChange } = this.props;

        if (onInputChange) {
            onInputChange(input);
        }

        // This value may be needed in between Select mounts (when this.select is null)
        this.inputValue = input;
    }

    onInputKeyDown(event: any) {
        const { shouldKeyDownEventCreateNewOption, onInputKeyDown } = this.props;
        const focusedOption = this.select.getFocusedOption();

        if (focusedOption && focusedOption === this._createPlaceholderOption && (shouldKeyDownEventCreateNewOption as any)({ keyCode: event.keyCode })) {
            this.createNewOption();

            // Prevent decorated Select from doing anything additional with this keyDown event
            event.preventDefault();
        } else if (onInputKeyDown) {
            onInputKeyDown(event);
        }
    }

    onOptionSelect(option: any, event: any) {
        if (option === this._createPlaceholderOption) {
            this.createNewOption();
        } else {
            this.select.selectValue(option);
        }
    }

    focus() {
        this.select.focus();
    }

    render() {
        const { newOptionCreator, shouldKeyDownEventCreateNewOption, ...restProps } = this.props;

        let { children } = this.props;

        // We can't use destructuring default values to set the children,
        // because it won't apply work if `children` is null. A falsy check is
        // more reliable in real world use-cases.
        if (!children) {
            children = defaultChildren;
        }

        const props = {
            ...restProps,
            allowCreate: true,
            filterOptions: this.filterOptions,
            menuRenderer: this.menuRenderer,
            onInputChange: this.onInputChange,
            onInputKeyDown: this.onInputKeyDown,
            ref: (ref: any) => {
                this.select = ref;

                // These values may be needed in between Select mounts (when this.select is null)
                if (ref) {
                    this.labelKey = ref.props.labelKey;
                    this.valueKey = ref.props.valueKey;
                }
            },
        };

        return (children as any)(props);
    }
}

function defaultChildren(props: any) {
    return <Select {...props} />;
}

function isOptionUnique({ option, options, labelKey, valueKey }: any) {
    return options.filter((existingOption: any) => existingOption[labelKey] === option[labelKey] || existingOption[valueKey] === option[valueKey]).length === 0;
}

function isValidNewOption({ label }: any) {
    return !!label;
}

function newOptionCreator({ label, labelKey, valueKey }: any) {
    const option = {};
    (option as any)[valueKey] = label;
    (option as any)[labelKey] = label;
    (option as any).className = "Select-create-option-placeholder";
    return option;
}

function promptTextCreator(label: any) {
    return `Create option "${label}"`;
}

function shouldKeyDownEventCreateNewOption({ keyCode }: any) {
    switch (keyCode) {
        case 9: // TAB
        case 13: // ENTER
        case 188: // COMMA
            return true;
    }

    return false;
}

// Default prop methods
CreatableSelect.isOptionUnique = isOptionUnique;
CreatableSelect.isValidNewOption = isValidNewOption;
CreatableSelect.newOptionCreator = newOptionCreator;
CreatableSelect.promptTextCreator = promptTextCreator;
CreatableSelect.shouldKeyDownEventCreateNewOption = shouldKeyDownEventCreateNewOption;

CreatableSelect.defaultProps = {
    filterOptions: defaultFilterOptions,
    isOptionUnique,
    isValidNewOption,
    menuRenderer: defaultMenuRenderer,
    newOptionCreator,
    promptTextCreator,
    shouldKeyDownEventCreateNewOption,
};

export default CreatableSelect;
