import { CancellationFundOptions, ExtraOptions, MarketingGroupOptions, StylingOptions } from "./";
import { MultiSelectOptionNumberValue, WithId } from "@maxxton/cms-api";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../../mxts/selectOption.types";

import { ColumnOptions } from "../../page/columns";
import { LocalizeDcAndRcOptions } from "../owner/book/choiceSelection";
import { LocalizedAddOnPrice } from "./addOns.util";

export enum AddOnInfo {
    NAME = "NAME",
    IMAGE = "IMAGE",
    DESCRIPTION = "DESCRIPTION",
    SHORT_DESCRIPTION = "SHORT_DESCRIPTION",
    DESCRIPTION2 = "DESCRIPTION2",
    DYNAMIC_FIELD_CODE = "DYNAMIC_FIELD_CODE",
    QUANTITY = "QUANTITY",
    PRICE = "PRICE",
    ACTION_BUTTONS = "ACTION_BUTTONS",
    QUANTITY_AND_PRICE = "QUANTITY_AND_PRICE",
    NAME_AND_QUANTITY = "NAME_AND_QUANTITY",
    PRICE_AND_ACTION_BUTTONS = "PRICE_AND_ACTION_BUTTONS",
}

export interface AddOnsWidgetOptions extends CancellationFundOptions, ColumnOptions, MarketingGroupOptions, StylingOptions, ExtraOptions, WithId {
    addOnPriceLocalizations: LocalizedAddOnPrice[];
    dynamicFieldCodes: NumberMultiSelectOption[];
    quickAddSimpleAddOns: boolean;
    overrideCapacityOnAddOns: boolean;
    showAddOnDescription?: boolean;
    showAddOnDescriptionOnHover?: boolean;
    disableAddOns?: boolean;
    disableCheckedInReservationAddOns?: boolean;
    disableReservationAddOnsBeforeXHours?: string;
    disableHistoricalReservationAddOns?: boolean;
    displayedResourceTypes?: StringMultiSelectOption[];
    singleButtonForAddOns?: boolean;
    showAddOnModalImage?: boolean;
    styleIds: string[];
    dateFormat?: string;
    dynamicFields?: NumberMultiSelectOption[];
    selectedAddonTypesDynamicFields?: NumberMultiSelectOption[];
    fallbackDescription?: boolean;
    descriptionMultiSelectorFallback?: NumberMultiSelectOption[];
    dynamicFieldFallback?: NumberMultiSelectOption[];
    restrictAddonsBeforeArrival?: boolean;
    hoursBeforeArrival?: number;
    showUnavailableAdditions?: boolean;
    preFillTheSubjectsForDayAddon?: boolean;
    showRecommendedAddOns?: boolean;
    recommendedAddOnsSize?: number;
    showColdStartsRecommendedAddOns?: boolean;
    showRecommendedAddOnTitle?: boolean;
    recommendedAddOnTitleWebContentId?: string | null;
    recommendedAddOnTitleTemplateId?: string | null;
    selectedAddonTypes?: MultiSelectOptionNumberValue[];
    disableServicesBooking?: boolean;
    endDateForServicesBooking?: Date;
    servicesAddonLocalizedOptions?: LocalizeDcAndRcOptions[];
}
