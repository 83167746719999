import { AccommodationType, Unit } from "../../plugins/mxts/index";
import { BillChoice, BillType } from "../reducers/billReducer";

import { Action } from "./";
import { ApiCallOptions } from "@maxxton/cms-mxts-api";

export interface GenerateBillPayload {
    specialSearchCode?: string;
    removeSpecialCode?: boolean;
    env?: ApiCallOptions;
    accommodationType?: AccommodationType;
    unit?: Unit;
    applicableRateType?: number;
    bypassRecalculation?: boolean;
    reservationId?: number;
}

export enum BillActionType {
    fetching = "bill_fetching",
    fetched = "bill_fetched",
    error = "bill_error",
    remove = "bill_remove",
    specialCodeNotFound = "specialCodeNotFound",
}

export interface BillAction extends Action, BillTargetAction {
    actionType: BillActionType;
    payload?: Partial<BillChoice>;
}

export interface GenerateBillAction extends Action, BillTargetAction {
    payload: GenerateBillPayload;
}

export interface BillTargetAction {
    targetBill?: BillType;
}
