import * as React from "react";

import { Link } from "react-router-dom";
import { State } from "../redux";
import { shouldDisableLink } from "./utils";
import { useSelector } from "react-redux";

export const isExternal = (url: string) => url.includes(":") || url.includes("//");

export const SmartLink = (props: React.AllHTMLAttributes<HTMLAnchorElement>): JSX.Element => {
    const isFrontEndEditable = useSelector((state: State) => state.frontendPageEditState.isFrontEndEditable);
    const isLinkDisabled = shouldDisableLink(!!isFrontEndEditable);
    const className = `${props.className || ""} ${isLinkDisabled ? "disabled" : ""}`.trim();
    const { href, ...rest } = props;

    if (href && !isExternal(href)) {
        return <Link to={href} {...rest} className={className} />;
    }
    return <a href={href} {...rest} className={className} />;
};
