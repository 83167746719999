import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as moment from "moment";

import { MaxxtonDatePickerStylingProps, MaxxtonDateRangePickerStylingWrapper } from "../../../../../../components/datepicker/DayPickerRangeController/MaxxtonDateRangePickerStylingWrapper";

import { CMSProviderProperties } from "../../../../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../../../../utils/constants";
import { DateRangePicker } from "react-dates";
import Select from "react-select";
import { generateRandomKey } from "../../../../../../components/utils";
import { getI18nLocaleString } from "../../../../../../i18n";
import namespaceList from "../../../../../../i18n/namespaceList";

type Moment = moment.Moment;

export const DEFAULT_YEAR_OR_DATE_RANGE_FILTER_TRANSLATIONS = {
    yearSelectTitle: getI18nLocaleString(namespaceList.ownerReservationsOverview, "yearSelectTitle"),
    dateRangeSelectTitle: getI18nLocaleString(namespaceList.ownerReservationsOverview, "dateRangePickerTitle"),
    selectedYearResultsTitle: getI18nLocaleString(namespaceList.ownerReservationsOverview, "reservationsFromYear"),
    selectedRangeResultsTitle: getI18nLocaleString(namespaceList.ownerReservationsOverview, "reservationsFromDateRange"),
};

export interface YearOrDateRangeFilterTranslations {
    yearSelectTitle: string;
    dateRangeSelectTitle: string;
    selectedYearResultsTitle: string;
    selectedRangeResultsTitle: string;
}

// eslint-disable-next-line max-lines-per-function
export function YearOrDateRangeFilter(props: {
    startDate: Moment;
    endDate: Moment;
    setEndDate: React.Dispatch<React.SetStateAction<Moment>>;
    setStartDate: React.Dispatch<React.SetStateAction<Moment>>;
    translations?: YearOrDateRangeFilterTranslations;
    onlyAllowRangeWithinSingleYear?: boolean;
    context: CMSProviderProperties;
    yearCount?: number;
    defaultSelectedYear?: number;
}) {
    const { endDate, startDate, setStartDate, setEndDate, context, onlyAllowRangeWithinSingleYear, defaultSelectedYear, translations = { ...DEFAULT_YEAR_OR_DATE_RANGE_FILTER_TRANSLATIONS } } = props;
    const { selectedYearResultsTitle, selectedRangeResultsTitle, dateRangeSelectTitle } = translations;
    const [focusedInput, setFocusedInput] = React.useState<"startDate" | "endDate" | null>(null);
    const [orientation, setOrientation] = React.useState<"horizontal" | "vertical">("horizontal");

    const lastDayOfArrivalYear = startDate ? moment(startDate).endOf("year") : undefined;
    const maxDate = onlyAllowRangeWithinSingleYear && focusedInput === "endDate" ? lastDayOfArrivalYear : undefined;

    const selectedYear = getSelectedYear(startDate, endDate);
    const handleDateRangeChange = React.useCallback(
        (newDates: { startDate: Moment; endDate: Moment }) => {
            if (newDates.startDate) {
                setStartDate(newDates.startDate);
                if (!newDates.endDate && endDate && isYearLimitExceeded(onlyAllowRangeWithinSingleYear, newDates.startDate, endDate)) {
                    setEndDate(moment(newDates.startDate).endOf("year"));
                }
            }
            if (newDates.endDate) {
                setEndDate(isYearLimitExceeded(onlyAllowRangeWithinSingleYear, newDates.startDate || startDate, newDates.endDate) ? moment(newDates.startDate).endOf("year") : newDates.endDate);
            }
        },
        [setStartDate, setEndDate, onlyAllowRangeWithinSingleYear, startDate, endDate]
    );
    const handleFocusChange = React.useCallback(
        (newFocusedInput: "startDate" | "endDate" | null) => {
            setFocusedInput(newFocusedInput);
        },
        [setFocusedInput]
    );

    const dateRangePickerId = generateRandomKey();
    const startDateId = "reservationOverviewTableStartDate" + dateRangePickerId;
    const endDateId = "reservationOverviewTableEndDate" + dateRangePickerId;
    const maxxtonDatePickerStylingProps: Omit<MaxxtonDatePickerStylingProps, "children"> = {
        startDateId,
        endDateId,
        setStartDate,
        setEndDate,
        startDate,
        setOrientation,
        endDate,
        focusedInput,
        context,
        selectedStartDatePrefix: getI18nLocaleString(namespaceList.admin, "startDate"),
        selectedEndDatePrefix: getI18nLocaleString(namespaceList.admin, "endDate"),
        options: {
            dateRange: true,
            displayBtn: false,
            highlightColor: "rgba(0, 103, 194, 1)",
            showArrow: true,
            iconColor: "default",
            addIcon: true,
            closeIcon: true,
            highlightInput: true,
            enableVerticalCalendarScrolling: true,
            showStartEndDateLabel: true,
        },
    };

    return (
        <React.Fragment>
            <div className="owner-revenue-streams-calender">
                <YearSelect
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    context={context}
                    translations={translations}
                    yearCount={props?.yearCount || 10}
                    defaultSelectedYear={defaultSelectedYear}
                ></YearSelect>
                <div className="owner-revenue-streams-calender__date owner-revenue-streams-calender__item">
                    <label className="title">{dateRangeSelectTitle}</label>
                    <MaxxtonDateRangePickerStylingWrapper {...maxxtonDatePickerStylingProps}>
                        <DateRangePicker
                            startDatePlaceholderText={getI18nLocaleString(namespaceList.criteriaPanelWidget, "startDate")}
                            endDatePlaceholderText={getI18nLocaleString(namespaceList.criteriaPanelWidget, "endDate")}
                            startDate={startDate}
                            startDateId={startDateId}
                            endDate={endDate}
                            endDateId={endDateId}
                            maxDate={maxDate}
                            onDatesChange={handleDateRangeChange}
                            focusedInput={focusedInput}
                            onFocusChange={handleFocusChange}
                            displayFormat={DATE_FORMAT.DAY_WITH_MONTH_NAME}
                            hideKeyboardShortcutsPanel={true}
                            isDayBlocked={isDayRangeBlocked(maxDate)}
                            isOutsideRange={isOutsideRange}
                            customInputIcon={<FontAwesome name="calendar" />}
                            daySize={undefined}
                            orientation={orientation}
                            readOnly={true}
                            showClearDates={false}
                            showDefaultInputIcon={true}
                        />
                    </MaxxtonDateRangePickerStylingWrapper>
                </div>
            </div>
            <h3 className="turnover-title">
                {selectedYear && selectedYearResultsTitle.replace(new RegExp("\\$year"), selectedYear.toString())}
                {!selectedYear && selectedRangeResultsTitle.replace(new RegExp("\\$dateRange"), `${startDate.format(DATE_FORMAT.LONG_DATE)} - ${endDate.format(DATE_FORMAT.LONG_DATE)}`)}
            </h3>
        </React.Fragment>
    );
}

export function YearSelect(props: {
    startDate?: Moment;
    endDate?: Moment;
    setEndDate?: React.Dispatch<React.SetStateAction<Moment>>;
    setStartDate?: React.Dispatch<React.SetStateAction<Moment>>;
    translations?: Pick<YearOrDateRangeFilterTranslations, "yearSelectTitle">;
    context: CMSProviderProperties;
    enableAllYearOption?: boolean;
    selectedYear?: number;
    setSelectedYear?: React.Dispatch<React.SetStateAction<number>>;
    yearCount?: number;
    defaultSelectedYear?: number;
}) {
    const {
        startDate,
        endDate,
        setEndDate,
        setStartDate,
        translations = { ...DEFAULT_YEAR_OR_DATE_RANGE_FILTER_TRANSLATIONS },
        context,
        enableAllYearOption,
        selectedYear,
        setSelectedYear,
        defaultSelectedYear,
    } = props;
    const { yearSelectTitle } = translations;
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const { currentLocale } = context;
    const yearCount = props?.yearCount || 10;
    const allYears = Array.from({ length: yearCount }, (_, index) => nextYear - index);
    const yearSelectOptions = allYears.map((year) => ({
        value: year,
        label:
            year === currentYear
                ? getI18nLocaleString(namespaceList.ownerReservationsOverview, "yearPickerCurrentYear", currentLocale).replace(new RegExp("\\$year"), year.toString())
                : year === nextYear
                ? getI18nLocaleString(namespaceList.ownerReservationsOverview, "yearPickerNextYear", currentLocale).replace(new RegExp("\\$year"), year.toString())
                : year.toString(),
    }));
    if (defaultSelectedYear && !allYears.some((year) => year === defaultSelectedYear)) {
        yearSelectOptions.push({ value: Number(defaultSelectedYear), label: defaultSelectedYear.toString() });
    }
    if (enableAllYearOption) {
        yearSelectOptions.unshift({ value: -1, label: getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", context.currentLocale, context.site) });
    }

    const handleYearChange = React.useCallback(
        (event: any) => {
            if (enableAllYearOption && setSelectedYear) {
                setSelectedYear(event.value);
            } else if (setStartDate && setEndDate) {
                setStartDate(moment().year(event.value).startOf("year"));
                setEndDate(moment().year(event.value).endOf("year"));
            }
        },
        [setSelectedYear, setStartDate, setEndDate]
    );

    return (
        <div className="owner-revenue-streams-calender__year owner-revenue-streams-calender__item">
            {yearSelectTitle && <label className="title">{yearSelectTitle}</label>}
            <Select
                classNamePrefix="react-select"
                clearable={false}
                searchable={false}
                options={yearSelectOptions}
                value={
                    enableAllYearOption
                        ? yearSelectOptions.find((yearOption) => yearOption.value === selectedYear)
                        : yearSelectOptions.find((yearOption) => yearOption.value === getSelectedYear(startDate, endDate)) || {
                              value: undefined,
                              label: getI18nLocaleString(namespaceList.ownerReservationsOverview, "selectAYear", currentLocale),
                          }
                }
                onChange={handleYearChange}
                placeholder={getI18nLocaleString(namespaceList.ownerReservationsOverview, "selectAYear", currentLocale)}
            />
        </div>
    );
}

export function getSelectedYear(startDate?: Moment, endDate?: Moment) {
    if (startDate && endDate) {
        return startDate.isSame(endDate, "year") && startDate.isSame(moment(startDate).startOf("year"), "day") && endDate.isSame(moment(endDate).endOf("year"), "day") ? startDate.year() : undefined;
    }
}

const isDayRangeBlocked = (maxDate?: Moment) => (day: Moment): boolean => !!maxDate && day.isAfter(maxDate);

function isYearLimitExceeded(onlyAllowRangeWithinSingleYear?: boolean, startDate?: Moment, endDate?: Moment) {
    return onlyAllowRangeWithinSingleYear && startDate && startDate.year() !== endDate?.year();
}

const isDayBlocked = () => false;
const isOutsideRange = () => false;
