import getDeviceWidth from "../utils/devicewidth.util";

export function getRedisWebIndexKey(url: string): string {
    while (url?.endsWith("/")) {
        url = url.slice(0, url.lastIndexOf("/"));
    }
    return url;
}

export function isClientSide() {
    return typeof window !== "undefined";
}

export function isServerSide() {
    return !isClientSide();
}

/**
 * @param totalMinutes Takes minutes in number format.
 * @returns returns hours.
 */
export function convertMinutesToHours(totalMinutes: number) {
    return Math.floor(totalMinutes / 60);
}

export const icons = {
    PLUS: " + ",
};

export function eyeCatcherConsoleLog(message: string, label = "Look at me") {
    const logStyle = [
        "background: linear-gradient(#D33106, #571402)",
        "border: 1px solid #3E0E02",
        "border-radius: 1rem",
        "padding: .5rem",
        "color: white",
        "display: block",
        "text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)",
        "box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset, 0 5px 3px -5px rgba(0, 0, 0, 0.5), 0 -13px 5px -10px rgba(255, 255, 255, 0.4) inset",
        "line-height: 40px",
        "text-align: center",
        "font-weight: bold",
    ].join(";");
    console.log(`%c ${label}`, logStyle, message);
}

export function hideTooltip() {
    const deviceWidth = getDeviceWidth();
    if (deviceWidth <= 768) {
        const datepickerTooltip = document.querySelectorAll(".datepicker-tooltip");
        if (datepickerTooltip.length !== 0) {
            Array.from(datepickerTooltip).map((ele: any) => (ele.style.display !== "none" ? (ele.style.display = "none") : (ele.style.display = "block")));
        }
    }
}
