import * as React from "react";

import { AccommodationType, Resort, Unit } from "./mxts";
import { Widget as ApiWidget, Site, WithId } from "@maxxton/cms-api";
import { FormSpec, SomeInputSpec } from "../form-specs";

import { ActivityPlannerAction } from "../redux/actions/activityPlannerAction";
import { CMSProvidedProperties } from "../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { WidgetOptions as DynamicContainerOptions } from "./dynamic/container/container.types";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { FilterChangeAction } from "../redux/actions/dynamicFilterAction.types";
import { FlexboxOptions } from "./page/flexbox";
import { I18nLocaleObject } from "../i18n";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";
import { MyEnvReducerAction } from "../redux/actions/myEnvAction";
import { PrefetchAppParams } from "../app.types";
import type { Route } from "../routing/routing.types";
import { SitemapPageLinkWidgetOptions } from "./sitemap/sitemap.types";
import { WidgetGroup } from "./widget.enum";
import { findWidget } from "./";
import { reportError } from "../utils/report.utils";

export type WidgetType = "page" | "menu" | "sitemap" | "form" | "flow" | "resultsPanel";

export type Instance<T> = T | InstanceGroup<T>;

export type ContextSlice = Pick<CMSProvidedProperties, "currentLocale" | "site" | "cmsApi" | "chunkExtractor">;

export interface InstanceGroup<T> {
    name: string;
    description: string;
    children: Array<Instance<T>>;
}

export function isInstanceGroup<T>(x: Instance<T>): x is InstanceGroup<T> {
    const group = x as InstanceGroup<T>;
    return Array.isArray(group.children) && typeof group.name === "string" && typeof group.description === "string";
}

export interface WidgetInstance<T> {
    widget: Widget<T>;
}

export type InstanceNameParams<T> = WidgetInstance<T>;

export interface InstanceDescriptionParams<T> extends WidgetInstance<T> {
    context: ContextSlice;
}

export type InstanceAdditionalInfoParams<T> = WidgetInstance<T>;

export function reportWidgetRenderError(parent: WidgetSpec<any> | Widget<any> | string, err: any, childSpec: WidgetSpec<any>, context: CMSProvidedProperties) {
    let parentSpecId: string;
    if (typeof parent === "string") {
        parentSpecId = parent;
    } else if ((parent as Widget<any>).spec?.id) {
        parentSpecId = (parent as Widget<any>).spec.id;
    } else {
        parentSpecId = (parent as WidgetSpec<any>).id;
    }
    reportError(
        err,
        context?.location?.href,
        `Error while rendering a child widget(name: ${childSpec.name}, type:${childSpec.type}, id:${childSpec.id}) of the ${parentSpecId} widget at url ${context?.location?.href}`
    );
}

export interface WidgetSpec<T> {
    id: string;
    type: WidgetType;
    widgetGroup: WidgetGroup;
    name: I18nLocaleObject | string;
    description: I18nLocaleObject | string;
    optionsForm: FormSpec<T>;
    childOptions?: Array<SomeInputSpec<any, any>>;
    instances?: () => Promise<Array<Instance<T>>>;
    instanceName?: ({ widget }: InstanceNameParams<T>) => Promise<string>;
    instanceDescription?: ({ widget, context }: InstanceDescriptionParams<T>) => Promise<string> | string;
    instanceAdditionalInfo?: ({ widget }: InstanceAdditionalInfoParams<T>) => Promise<string> | string;
    defaultOptions: () => Omit<T, "_id">;
    /**
     * A container is a widget that supports child widgets
     */
    container?: boolean;
    singleton?: boolean;
}

export interface DynamicWidgetSpec {
    id: string;
    type: WidgetType;
    widgetGroup: WidgetGroup;
    targetName: string;
    parameters?: any[];
    pathToFile: (context: ContextSlice) => Promise<any>;
    container?: boolean;
}

export interface SitemapWidgetSpec<T> extends WidgetSpec<T> {
    type: "sitemap";
    routes(widget: Widget<T>, context: CMSProvidedProperties, device?: PrefetchAppParams["device"]): Promise<Route[]>;
}

export interface PageWidgetSpec<T> extends WidgetSpec<T> {
    type: WidgetType;
    // eslint-disable-next-line max-len
    render(
        widget: Widget<T>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions,
        shouldReturnProps?: boolean,
        allSites?: Array<Site & WithId>,
        flexboxOptions?: FlexboxOptions
    ): Promise<JSX.Element>;
    initDefaultFilter?(widget: Widget<T>, context: CMSProvidedProperties, dispatcher: Dispatch<FilterChangeAction | ActivityPlannerAction | MyEnvReducerAction>): Promise<void>;
}

export interface FlowWidgetSpec<T> extends WidgetSpec<T> {
    type: "flow";
}

export interface TypesearchContainerWidgetSpec<T> extends WidgetSpec<T> {
    type: WidgetType;
    render(
        widget: Widget<T>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions,
        flexboxOptions?: FlexboxOptions,
        dynamicFilter?: DynamicFilter
    ): Promise<JSX.Element>;
    initDefaultFilter?(widget: Widget<T>, context: CMSProvidedProperties, dispatcher: Dispatch<FilterChangeAction>): Promise<void>;
}

export interface ResultPanelWidgetSpec<T> extends WidgetSpec<T> {
    type: WidgetType;
    render(
        widget: Widget<T>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions
    ): Promise<JSX.Element>;
}

export interface LocationWidgetSpec<T> extends WidgetSpec<T> {
    type: WidgetType;
    render(widget: Widget<T>, context: CMSProvidedProperties, sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions, dynamicContainerOptions?: DynamicContainerOptions): Promise<JSX.Element>;
}

export interface MenuWidgetSpec<T> extends WidgetSpec<T> {
    type: "menu";
    render(
        widget: Widget<T>,
        context: CMSProvidedProperties,
        allSites?: Array<Site & WithId>,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        flexboxOptions?: FlexboxOptions
    ): Promise<JSX.Element>;
}

export interface FormWidgetSpec<T> extends WidgetSpec<T> {
    type: "form";
    toInputSpec(widget: Widget<T>, context: CMSProvidedProperties): Promise<SomeInputSpec<any, string>>;
}

export function isPageWidget<T>(widget: WidgetSpec<T>): widget is PageWidgetSpec<T> {
    return widget.type === "page";
}

export function isResultsPanelWidget<T>(widget: WidgetSpec<T>): widget is ResultPanelWidgetSpec<T> {
    return widget.type === "resultsPanel";
}

export function isTypeResultsWidget<T>(widget: WidgetSpec<T>): widget is TypesearchContainerWidgetSpec<T> {
    return widget.type === "resultsPanel";
}

export function isMenuWidget<T>(widget: WidgetSpec<T>): widget is MenuWidgetSpec<T> {
    return widget.type === "menu";
}

export function isSitemapWidget<T>(widget: WidgetSpec<T>): widget is SitemapWidgetSpec<T> {
    return widget.type === "sitemap";
}

export function isFormWidget<T>(widget: WidgetSpec<T>): widget is FormWidgetSpec<T> {
    return widget.type === "form";
}

export interface Widget<T> {
    _id: string;
    spec: WidgetSpec<T>;
    children: Array<Widget<any>>;
    options: T;
}

export interface SharedWidgetParams {
    widgetId: string;
    widgetType: WidgetType;
    widgetOptionsFormId: string;
}

export async function parseApiWidget<T>(apiWidget: ApiWidget, context: ContextSlice): Promise<Widget<T>> {
    let spec: any = findWidget<T>(apiWidget.type);
    if (spec === null) {
        throw new Error(`Tried to parse Api widget with unknown id "${apiWidget.type}"`);
    }
    const module = await spec.pathToFile(context);
    const func = module[spec.targetName];
    if (typeof func === "function") {
        // Execute the function with the provided parameters
        if (spec.parameters) {
            spec = func(...spec.parameters);
        } else {
            spec = func();
        }
    } else if (typeof func === "object") {
        spec = func;
    }
    const children: Array<Widget<any>> = await Promise.all(apiWidget.children.filter((item) => item !== null && shouldRenderHiddenWidget(item)).map(async (id) => await parseApiWidget(id, context)));
    const widget: Widget<T> = {
        _id: (apiWidget as any)._id,
        spec,
        children,
        options: ((apiWidget.options || {}) as any) as T,
    };
    return widget;
}

function shouldRenderHiddenWidget(apiWidget?: ApiWidget): boolean {
    return !!(typeof localStorage !== "undefined" && localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN)) || !apiWidget?.options?.showDetail;
}

// Recursively filters a tree of ApiWidgets based on the showMoment(responsive display) condition.
function shouldWidgetBeRendered(apiWidgets: Array<ApiWidget | undefined>, context: CMSProvidedProperties): ApiWidget[] {
    return apiWidgets
        .map((widget: ApiWidget | undefined) => {
            if (widget?.children) {
                const filteredNestedChildren = shouldWidgetBeRendered(widget.children, context);
                return {
                    ...widget,
                    children: filteredNestedChildren,
                };
            }

            return widget;
        })
        .filter((widget): widget is ApiWidget => {
            if (widget !== null && shouldRenderHiddenWidget(widget)) {
                if (widget?.options?.showMoment) {
                    return isRenderedWidgetForDevice(widget.options.showMoment, context.device);
                }
                return !!widget;
            }
            return false;
        });
}

export async function renderPageWidgets(
    apiWidgets: ApiWidget[],
    context: CMSProvidedProperties,
    sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
    resultOptions?: ResultOptions
): Promise<JSX.Element[]> {
    const widgets: Array<Widget<any>> = await Promise.all(shouldWidgetBeRendered(apiWidgets, context).map(async (apiWidget) => await parseApiWidget(apiWidget, context)));
    return Promise.all(
        widgets.map((widget, ind) => {
            const widgetSpec = widget.spec;
            if (isPageWidget(widgetSpec) || isTypeResultsWidget(widgetSpec)) {
                if (!widgetSpec.render) {
                    return <div key={ind} />;
                }
                return (
                    widgetSpec
                        .render(widget, context, sitemapPageLinkWidgetOptions, resultOptions)
                        .then((elem) => <ErrorBoundary key={widget._id}>{elem}</ErrorBoundary>)
                        // silent the failure of the widget and report error in logstash
                        .catch((error) => {
                            reportError(error);
                            return <div key={ind} />;
                        })
                );
            }
            throw new Error("Expected widget to be a page widget");
        })
    );
}

export async function initDefaultFilterForPageApiWidgets(
    apiWidgets: ApiWidget[],
    context: CMSProvidedProperties,
    dispatcher: Dispatch<FilterChangeAction | ActivityPlannerAction | MyEnvReducerAction>
): Promise<void> {
    const widgets: Array<Widget<any>> = await Promise.all(
        apiWidgets
            .filter((apiWidget) => apiWidget !== null && (apiWidget?.options?.showMoment ? isRenderedWidgetForDevice(apiWidget.options.showMoment, context.device) : apiWidget))
            .map(async (apiWidget) => await parseApiWidget(apiWidget, context))
    );
    return initDefaultFilterForPageWidgets(widgets, context, dispatcher);
}

export async function initDefaultFilterForPageWidgets(
    widgets: Array<Widget<any>>,
    context: CMSProvidedProperties,
    dispatcher: Dispatch<FilterChangeAction | ActivityPlannerAction | MyEnvReducerAction>
): Promise<void> {
    widgets = widgets.filter((widget) => widget !== null && (widget?.options?.showMoment ? isRenderedWidgetForDevice(widget.options.showMoment, context.device) : widget));
    for (const widget of widgets) {
        const widgetSpec = widget.spec;
        if ((isPageWidget(widgetSpec) || isTypeResultsWidget(widgetSpec)) && widgetSpec.initDefaultFilter) {
            await widgetSpec.initDefaultFilter(widget, context, dispatcher);
        } else if (widget.children) {
            await initDefaultFilterForPageWidgets(widget.children, context, dispatcher);
        }
    }
}

// checks flexbox to be rendered or not with combination of deviceType and showMoment
function isRenderedWidgetForDevice(showMoment: string, deviceType?: PrefetchAppParams["device"]): boolean {
    const forTabletAndDesktop = ["dk-hidden-s", "show-tablet-only", "dk-hidden-m", "dk-hidden-l"];
    const forAll = ["dk-hidden-from-l", "default"];
    return (
        (deviceType?.isMobile && showMoment === "show-xs-only") ||
        (deviceType && !deviceType?.isMobile && forTabletAndDesktop.includes(showMoment)) ||
        (showMoment === "hidden-till-tablet" && deviceType?.isDesktop) ||
        (showMoment === "dk-hidden-from-desktop" && (deviceType?.isTablet || deviceType?.isMobile)) ||
        forAll.includes(showMoment)
    );
}
interface InitialItemRootWidget<T> extends Widget<T> {
    type: string;
    options: any;
}

export interface InitialItem<T> {
    root: Array<InitialItemRootWidget<T>>;
    elements: Array<InitialItemRootWidget<T>>;
}

export interface ResultOptions {
    useCrpProps?: boolean;
    resort?: Resort;
    accommodationType?: AccommodationType;
    unit?: Unit;
    newRateTypeId?: number;
    rateTypeId?: number;
    unitBookUri?: string;
    dynamicFilter?: DynamicFilter;
    key?: string | number;
    amenityCodes?: string[];
}
