import { StringUtil } from "../utils/string.util";

interface Task {
    mainBill: string;
    myEnvAdditionsBill: string;
}

const lastStartedTaskUuid: Task = { mainBill: "", myEnvAdditionsBill: "" };

export class TaskExecutionManager {
    private taskUuid = StringUtil.generateUUID();

    constructor(private taskType: keyof Task) {
        lastStartedTaskUuid[this.taskType] = this.taskUuid;
    }

    isLatestTask(): boolean {
        return lastStartedTaskUuid[this.taskType] === this.taskUuid;
    }
}
