import { Action, ActionType } from "../actions";

import { Reducer } from "redux";
export interface ResultsPanelState {
    resourceId?: number;
    unitId?: number;
    tabsWidgetId?: string;
    tabId?: string;
}

const initialState = { resourceId: undefined, unitId: undefined, tabsWidgetId: undefined, tabId: undefined };

export const resultsPanelReducer: Reducer<ResultsPanelState> = (state: ResultsPanelState = initialState, action: Action): ResultsPanelState => {
    switch (action.type) {
        case ActionType.ResultsPanelOpenLinkedTab:
            return { ...action.payload };

        case ActionType.ClearResultsPanelOpenLinkedTabState:
            return {
                resourceId: undefined,
                unitId: undefined,
                tabsWidgetId: undefined,
                tabId: undefined,
            };

        default:
            return state;
    }
};
