import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export enum ContentType {
    RESORT,
    ACCOMMODATION_KIND,
    ACCOMMODATION_TYPE,
    UNIT,
    AMENITY,
    REGION,
    ACTIVITY,
    TIPS_AND_TRIPS,
    FACILITY,
}

export enum ConditionalSetSelect {
    START_DATE = "START_DATE",
    END_DATE = "END_DATE",
    EQUAL_TO = "EQUAL_TO",
    NOT_EQUAL_TO = "NOT_EQUAL_TO",
    GREATER_THAN = "GREATER_THAN",
    LESS_THAN = "LESS_THAN",
    SHOW = "SHOW",
    HIDE = "HIDE",
    AND = "&&",
    OR = "||",
    BEFORE = "BEFORE",
    AFTER = "AFTER",
    ARRIVAL_DAY = "ARRIVAL_DAY",
    DEPARTURE_DAY = "DEPARTURE_DAY",
}

export const I18nRESORT = getI18nLocaleObject(namespaceList.dynamicPlugin, "resort");
export const I18nREGION = getI18nLocaleObject(namespaceList.dynamicPlugin, "region");
export const I18nACCOMMODATIONKIND = getI18nLocaleObject(namespaceList.dynamicPlugin, "accoKind");
export const I18nAMENITY = getI18nLocaleObject(namespaceList.dynamicPlugin, "amenity");
