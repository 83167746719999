import * as moment from "moment";

export const daLocaleSpecification: moment.LocaleSpecification = {
    calendar: {
        sameDay: "[i dag kl.] LT",
        nextDay: "[i morgen kl.] LT",
        nextWeek: "på dddd [kl.] LT",
        lastDay: "[i går kl.] LT",
        lastWeek: "[i] dddd[s kl.] LT",
        sameElse: "L",
    },
    longDateFormat: {
        LTS: "HH:mm:ss",
        LT: "HH:mm",
        L: "DD.MM.YYYY",
        LL: "D. MMMM YYYY",
        LLL: "D. MMMM YYYY HH:mm",
        LLLL: "dddd [d.] D. MMMM YYYY [kl.] HH:mm",
    },
    invalidDate: "Invalid date",
    ordinal: () => "%d.",
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    relativeTime: {
        future: "om %s",
        past: "%s siden",
        s: "få sekunder",
        ss: "%d sekunder",
        m: "et minut",
        mm: "%d minutter",
        h: "en time",
        hh: "%d timer",
        d: "en dag",
        dd: "%d dage",
        w: "a week",
        ww: "%d weeks",
        M: "en måned",
        MM: "%d måneder",
        y: "et år",
        yy: "%d år",
    },
    months: ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"],
    monthsShort: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
    week: {
        dow: 1,
        doy: 4,
    },
    weekdays: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
    weekdaysMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
    weekdaysShort: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
    dayOfMonthOrdinalParseLenient: {},
};
