import { ContextSlice, PluginSpec } from "../";

import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

const PagelinkPlugin: PluginSpec<Record<string, unknown>> = {
    id: "sitemap-page",
    name: getI18nLocaleObject(namespaceList.pluginSitemap, "sitemap"),
    description: getI18nLocaleObject(namespaceList.pluginSitemap, "sitemapDescription"),
    // widgets: [pageLinkWidget],
    widgets: [
        {
            id: "sitemap-page",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "sitemap-page" */ "./page"));
                context?.chunkExtractor?.addChunk("sitemap-page");
                return await ref.load();
            },
            targetName: "pageLinkWidget",
            type: "sitemap",
            widgetGroup: WidgetGroup.OTHER,
        },
    ],
};

export default PagelinkPlugin;
