import { MxtsApi, Resort } from "@maxxton/cms-mxts-api";

import { LocalizedOptions } from "@maxxton/cms-api";
import { MXTS } from "./constants";
import { NumberMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { SomeInputSpec } from "../form-specs";
import { WidgetOptions } from "../plugins/dynamic/reservation/container";
import { getI18nLocaleObject } from "../i18n";
import { getMxtsEnv } from "../plugins/mxts";
import { globalApiContext } from "../containers/CmsProvider";
import namespacesList from "../i18n/namespaceList";

export interface ResortsFilterOption {
    enableResortsFilter?: boolean;
    filteredResorts?: NumberMultiSelectOption[];
}

export interface ResortsFilterParams {
    localizedSiteOptions?: LocalizedOptions | null;
    widgetOptions?: WidgetOptions;
}

export async function getResortsFilterOptions(): Promise<NumberMultiSelectOption[]> {
    const env = await getMxtsEnv(globalApiContext());
    const resorts = await MxtsApi.resorts(env, { size: MXTS.MAX_RESULTS });
    const resortSelectOptions: NumberMultiSelectOption[] = resorts.content.map(
        (resort: Resort): NumberMultiSelectOption => ({
            value: resort.resortId,
            text: resort.name,
        })
    );
    return resortSelectOptions;
}

export function getResortsFilterSpec<T extends ResortsFilterOption>(): Array<SomeInputSpec<T, keyof T>> {
    return [
        {
            variable: "enableResortsFilter",
            label: getI18nLocaleObject(namespacesList.reservationContainer, "enableResortsFilter"),
            type: "checkbox",
        },
        {
            variable: "filteredResorts",
            label: getI18nLocaleObject(namespacesList.admin, "reservationLocationSelector"),
            type: "multiselect",
            optionList: async () => getResortsFilterOptions(),
            placeholder: getI18nLocaleObject(namespacesList.admin, "reservationLocationsPlaceholder"),
            visible: (options: T) => !!options?.enableResortsFilter,
        },
    ];
}

export const getResortIdsByResortsConfig = async ({ localizedSiteOptions, widgetOptions }: ResortsFilterParams) => {
    const optionsFilteredResortIds = widgetOptions && widgetOptions.enableResortsFilter ? widgetOptions?.filteredResorts?.map((resortOption) => resortOption.value) : undefined;
    const siteFilteredResortIds = localizedSiteOptions?.enableResortsFilter ? localizedSiteOptions?.filteredResorts?.map((resortOption) => resortOption.value) : undefined;
    return optionsFilteredResortIds || siteFilteredResortIds;
};
