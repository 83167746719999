import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";

import { InputSpecMulti } from "../form-specs";
import { IntitialItemRootWidget } from "../plugins/resultsPanel/resultsPanelUtil";
import { StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import namespaceList from "../i18n/namespaceList";

interface InitialItem {
    root?: IntitialItemRootWidget[];
    elements?: IntitialItemRootWidget[];
}

export const getWidgetListOptions = (initialItem: InitialItem): StringMultiSelectOption[] => {
    const widgetListOption: StringMultiSelectOption[] = [];

    const searchTreeForWidget = (widget: IntitialItemRootWidget) => {
        if (!widget._id) {
            return;
        }

        widgetListOption.push({
            text: `${widget.type}, ${widget.options.name ? widget.options.name + "," : ""} id: ${widget._id}`,
            value: widget._id,
        });
        widget.children.forEach((child: IntitialItemRootWidget) => searchTreeForWidget(child));
    };
    (initialItem.root ? initialItem.root : initialItem.elements)?.forEach((widget: IntitialItemRootWidget) => searchTreeForWidget(widget));
    return widgetListOption;
};

function widgetListAutocomplete<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.admin, "widgetList"),
        type: "multiselect",
        async optionList({ initialItem }): Promise<any[]> {
            return getWidgetListOptions(initialItem);
        },
    };
}

export default widgetListAutocomplete;
