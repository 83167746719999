import { InstalmentsState } from "../reducers/instalmentsState";
import { Action as ReduxAction } from "redux";
import { StringMultiSelectOption } from "../../plugins/mxts/selectOption.types";

export enum InstalmentsActionType {
    UPDATE = "instalments_update",
    FETCH_INSTALMENTS = "instalments_fetch",
    INSTALMENTS_FETCHED = "instalments_fetched",
}

export interface InstalmentsReducerAction extends ReduxAction<InstalmentsActionType> {
    payload: InstalmentsState & { autoSelectSingularInstalment?: boolean; autoSelectAllInstalment?: boolean; payerTypesToHide?: StringMultiSelectOption[] };
}

export const updateInstalmentsState = (state: Partial<InstalmentsState>): InstalmentsReducerAction => ({
    type: InstalmentsActionType.UPDATE,
    payload: state,
});

export const setInstalmentsFetching = (fetching: boolean): InstalmentsReducerAction => ({
    type: InstalmentsActionType.UPDATE,
    payload: { fetchingInstalments: fetching },
});

export const fetchInstalments = (fetchOptions: {
    reservationId?: number;
    autoSelectSingularInstalment?: boolean;
    autoSelectAllInstalment?: boolean;
    payerTypesToHide?: StringMultiSelectOption[];
}): InstalmentsReducerAction => {
    const { reservationId, autoSelectSingularInstalment, autoSelectAllInstalment, payerTypesToHide } = fetchOptions;
    return {
        type: InstalmentsActionType.FETCH_INSTALMENTS,
        payload: { reservationId, autoSelectSingularInstalment, autoSelectAllInstalment, payerTypesToHide },
    };
};
