import { SomeInputSpec } from "../form-specs/index";
import { getI18nLocaleObject } from "../i18n";
import namespaceList from "../i18n/namespaceList";

export interface PrintConfigurationOptions {
    addPrintButton?: boolean;
    addPrintButtonId?: string;
}

export const printConfigurationSpec = <T extends PrintConfigurationOptions>(): Array<SomeInputSpec<T, keyof T>> => [
    {
        variable: "addPrintButton",
        label: getI18nLocaleObject(namespaceList.admin, "addPrintButton"),
        type: "checkbox",
        groupName: "printFunctionalityGroup",
        groupTitle: getI18nLocaleObject(namespaceList.admin, "printFunctionalityGroupTitle"),
        groupDescription: getI18nLocaleObject(namespaceList.admin, "printFunctionalityGroupDescription"),
    },
    {
        variable: "addPrintButtonId",
        label: getI18nLocaleObject(namespaceList.admin, "addPrintButtonId"),
        type: "text",
        groupName: "printFunctionalityGroup",
        visible: (options: T) => !!options.addPrintButton,
    },
];

export const defaultPrintConfigurationValues: PrintConfigurationOptions = {
    addPrintButton: false,
    addPrintButtonId: "",
};
