import { Form, FormApi } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { FormSpec } from "../";
import { findAffectedAssets } from "../utils";
import { getTimeStampOptions } from "../columnSpec.util";
import namespaceList from "../../i18n/namespaceList";
import { widgetHierarchy } from "./";

export const formSpec: FormSpec<Form> = {
    id: "form",
    name: getI18nLocaleObject(namespaceList.admin, "form"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "forms"),
    api: FormApi,
    permission: "webmanager.forms",
    display(form: Form): string {
        return form.name;
    },
    tableColumns: [{ name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" }, ...getTimeStampOptions()],
    properties: [
        {
            label: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "name",
            type: "text",
            required: true,
        },
        widgetHierarchy<Form, "elements">({
            label: getI18nLocaleObject(namespaceList.admin, "formElements"),
            variable: "elements",
            rootTitle: getI18nLocaleObject(namespaceList.admin, "form"),
            templatesTitle: getI18nLocaleObject(namespaceList.admin, "availableFormComponents"),
            widgetType: "form",
            rootType: "multiple",
        }),
    ],
    async linkage(id: string) {
        const affectedModels = await findAffectedAssets(id, "formId");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedPages"),
                links: affectedModels.affectedPages,
                specId: "page",
            },
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedTemplates"),
                links: affectedModels.affectedTemplates,
                specId: "template",
            },
        ];
    },
};
