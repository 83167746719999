import * as React from "react";

import { Alerts } from "../../alerts";
import { AlertsPopup } from "../AlertsPopup";
import { Button } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { GenericForm } from "../generic-form";
import { GenericFormModalProps } from "../generic-form/modal";
import Modal from "@mui/material/Modal";
import { SwapHoriz } from "@mui/icons-material";
import { WidgetOptions } from "../../form-specs";
import { getI18nLocaleString } from "../../i18n";
import namespacesList from "../../i18n/namespaceList";
import useDarkMode from "./useDarkMode";

enum DockPosition {
    LEFT = "left",
    RIGHT = "right",
}
interface EditingModalProps<E> {
    isOpen: boolean;
    toggle: () => void;
    modalClassName?: string;
    headerContent: JSX.Element;
    draftValue: WidgetOptions<E>;
    alerts: Alerts;
    rootValue: WidgetOptions<E>;
    handleChange: (value: WidgetOptions<E>) => void;
    handleSave: () => void;
    handleCancel: () => void;
    restProps: GenericFormModalProps<E>;
}

const FrontendEditingModal: React.FC<EditingModalProps<any>> = (props) => {
    const { isOpen, toggle, modalClassName, headerContent, draftValue, rootValue, alerts, handleChange, handleSave, handleCancel, restProps } = props;
    const [position, setPosition] = React.useState(DockPosition.LEFT);
    const isDarkMode = useDarkMode();

    const modalOpenClass = "frontend-edit-modal-open";
    const positionClasses = {
        [DockPosition.LEFT]: "frontend-edit-modal-left",
        [DockPosition.RIGHT]: "frontend-edit-modal-right",
    };

    React.useEffect(() => {
        const { body } = document;
        const positionClass = positionClasses[position];

        if (isOpen) {
            body.classList.add(modalOpenClass, positionClass);
        } else {
            body.classList.remove(modalOpenClass, ...Object.values(positionClasses));
        }

        return () => {
            body.classList.remove(modalOpenClass, ...Object.values(positionClasses));
        };
    }, [isOpen, position]);

    const switchPosition = () => {
        setPosition((prevPosition) => {
            const newPosition = prevPosition === DockPosition.LEFT ? DockPosition.RIGHT : DockPosition.LEFT;
            return newPosition;
        });
    };

    return (
        <Modal
            open={isOpen}
            onClose={toggle}
            className={`${modalClassName} frontend-edit-modal__${position}  ${isDarkMode ? "dark-mode-modal" : ""}`}
            hideBackdrop={true}
            style={{
                position: "fixed",
                top: "0",
                left: position === DockPosition.LEFT ? "0" : "auto",
                right: position === DockPosition.RIGHT ? "0" : "auto",
            }}
        >
            <React.Fragment>
                <div className="modal-header">
                    <h4 className="modal-title">{headerContent}</h4>
                    <Button className="dock-position-toggle" color="light" onClick={switchPosition}>
                        <SwapHoriz />
                        {getI18nLocaleString(namespacesList.admin, position === DockPosition.LEFT ? "dockRight" : "dockLeft")}
                    </Button>
                    <CloseIcon role="button" className="close" onClick={toggle} />
                </div>
                <div className="modal-body">
                    <GenericForm {...restProps} buttons="none" value={draftValue} enableCancel={false} rootValue={rootValue} enableSubmit={false} onChange={handleChange} shouldDisableSubmit={false} />
                </div>
                {alerts && <AlertsPopup alerts={alerts} />}
            </React.Fragment>
        </Modal>
    );
};

export default FrontendEditingModal;
