import { MS } from "./constants";

export interface LoadableRetryOptions {
    retries?: number;
    interval?: number;
    exponentialBackoff?: boolean;
}

/**
 * Taken from https://github.com/gregberge/loadable-components/issues/667
 */
export function loadableRetry<R>(fn: () => Promise<R>, { retries = 10, interval = 500, exponentialBackoff = true }: LoadableRetryOptions = {}) {
    return new Promise<R>((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retries === 1) {
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    loadableRetry(fn, {
                        retries: retries - 1,
                        interval: exponentialBackoff && interval < MS.THREE_SECONDS ? interval * 2 : interval,
                    }).then(resolve, reject);
                }, interval);
            });
    });
}
