import * as React from "react";

import { ContentState, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { debounce, isEqual } from "lodash";

import { CMSAware } from "../../containers/CmsProvider";
import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { GenericInputProps } from "./input.types";
import { InputSpecRichText } from "../../form-specs";
import { generateRandomKey } from "../utils";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import { wrapProps } from "../../i18n";

const htmlToDraft = typeof window === "object" && require("html-to-draftjs").default;

const Editor = loadable(() => loadableRetry(() => import("react-draft-wysiwyg")), {
    resolveComponent: ({ Editor }) => Editor,
});

type RichTextInputProps<E, P extends keyof E> = GenericInputProps<E, P, InputSpecRichText<E, P>> & CMSProvidedProperties;

interface GenericInputState {
    editorState: EditorState;
    editorContent: any;
    prevEditor: any;
}

const editorStateCache: WeakMap<ContentState, string> = new WeakMap();

export function cachedConvertToRaw(state: ContentState): string {
    const cached = editorStateCache.get(state);
    if (cached === undefined) {
        const raw = JSON.stringify(convertToRaw(state));
        editorStateCache.set(state, raw);
        return raw;
    }
    return cached;
}

function cachedConvertFromRaw(current: EditorState, rawString: string): EditorState {
    let content = current.getCurrentContent();
    if (editorStateCache.has(content) && editorStateCache.get(content) === rawString) {
        return current;
    }
    content = convertFromRaw(JSON.parse(rawString));
    editorStateCache.set(content, rawString);
    return EditorState.createWithContent(content);
}

export function cachedConvertFromRawString(rawString = "", isRawHtmlWidget = false): EditorState {
    let contentState: ContentState;
    if (isRawHtmlWidget) {
        contentState = ContentState.createFromText(rawString);
    } else {
        const blocksFromRawString = htmlToDraft(rawString);
        contentState = ContentState.createFromBlockArray(blocksFromRawString.contentBlocks, blocksFromRawString.entityMap);
    }
    return EditorState.createWithContent(contentState);
}

export class RichTextInputBase<E, P extends keyof E> extends React.PureComponent<RichTextInputProps<E, P>, GenericInputState> {
    constructor(props: RichTextInputProps<E, P>) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            editorContent: {},
            prevEditor: {},
        };
    }

    public componentDidMount() {
        this.updateEditorState(this.props);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: RichTextInputProps<E, P>) {
        if (!isEqual(this.props.value, nextProps.value)) {
            this.updateEditorState(nextProps);
        }
    }

    public render(): JSX.Element | null {
        const { spec, enabled, mode, root, theme, item } = this.props;
        const { editorState } = this.state;
        return (
            <Editor
                id={spec.variable! as any}
                readOnly={mode === "readonly" || !enabled}
                toolbarClassName={spec?.classname?.(item) || ""}
                name={(spec.variable as string) || ""}
                defaultEditorState={editorState}
                onEditorStateChange={this.handleChange}
                editorClassName={`form-richt-text-editor ${spec.style ? spec.style(root, theme) : ""}`}
                onBlur={this.handleBlur}
                key={generateRandomKey()}
            />
        );
    }

    private handleChange = (editorState: EditorState) => {
        const newEditorState: ContentState = editorState?.getCurrentContent();
        const rawString: string = cachedConvertToRaw(newEditorState);
        if (!(JSON.stringify(this.state.prevEditor) === JSON.stringify(rawString))) {
            this.setState({ editorState }, () => this.debouncedUpdateDraft());
        }
    };

    private debouncedUpdateDraft = debounce(() => this.updateDraft(), 200);

    private updateDraft = () => {
        const { spec } = this.props;
        const { editorState } = this.state;
        const newEditorState: ContentState = editorState.getCurrentContent();
        const rawString: string = cachedConvertToRaw(newEditorState);
        localStorage.setItem("richtext", "draftChangeTrue");
        this.props.onChange(rawString, spec);
    };

    private handleBlur = () => {
        const { spec } = this.props;
        const { editorState, prevEditor } = this.state;
        const newEditorState: ContentState = editorState.getCurrentContent();
        const rawString: string = cachedConvertToRaw(newEditorState);
        if (!(JSON.stringify(prevEditor) === JSON.stringify(rawString))) {
            localStorage.setItem("richtext", "draftBlurTrue");
            this.props.onChange(rawString, spec);
            this.setState((prevState) => ({ ...prevState, prevEditor: rawString }));
        }
    };

    private updateEditorState(props: RichTextInputProps<E, P>) {
        this.setState((state) => {
            const value = (props.value as any)?.toString();
            const editorState: EditorState =
                value?.startsWith('{"blocks"') || value?.startsWith('{"entityMap"') ? cachedConvertFromRaw(state.editorState, value) : cachedConvertFromRawString(value, props.root.isRawHtmlWidget);
            const rawString: string = cachedConvertToRaw(editorState.getCurrentContent());
            return { ...state, editorState, prevEditor: rawString };
        });
    }
}

export const RichTextInput = wrapProps<GenericInputProps<any, any, InputSpecRichText<any, any>>>(CMSAware(RichTextInputBase));
