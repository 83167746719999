import { Action, ActionType } from ".";

import { Action as ReduxAction } from "redux";
import { ReservationCustomerState } from "../reducers/reservationCustomerReducer";

export enum ReservationCustomerActionType {
    FETCHING = "RESERVATION_CUSTOMER_FETCHING",
    FETCH_SUCCESS = "RESERVATION_CUSTOMER_FETCH_SUCCESS",
    FETCH_ERROR = "RESERVATION_CUSTOMER_FETCH_ERROR",
}

export interface ReservationCustomerReducerAction extends Action {
    type: ActionType.ReservationCustomer;
    actionType: ReservationCustomerActionType;
    payload?: ReservationCustomerState;
}

export const startFetchingReservationCustomer = (): ReservationCustomerReducerAction => ({
    type: ActionType.ReservationCustomer,
    actionType: ReservationCustomerActionType.FETCHING,
});

export const fetchReservationCustomerSuccess = ({ customer, currentAddress }: ReservationCustomerState): ReservationCustomerReducerAction => ({
    type: ActionType.ReservationCustomer,
    actionType: ReservationCustomerActionType.FETCH_SUCCESS,
    payload: {
        customer,
        currentAddress,
    },
});

export const fetchReservationCustomerError = (error: unknown): ReservationCustomerReducerAction => ({
    type: ActionType.ReservationCustomer,
    actionType: ReservationCustomerActionType.FETCH_ERROR,
    payload: {
        error,
    },
});

export enum ReservationCustomerSagaActionType {
    FETCH_RESERVATION_CUSTOMER = "FETCH_RESERVATION_CUSTOMER",
}

export interface ReservationCustomerSagaAction extends ReduxAction<ReservationCustomerSagaActionType> {}

export const fetchReservationCustomerInfo = (): ReservationCustomerSagaAction => ({
    type: ReservationCustomerSagaActionType.FETCH_RESERVATION_CUSTOMER,
});
