import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import {
    ApiCallOptions,
    Contract,
    EsReservationResultContainer,
    MxtsApi,
    MyEnvUserType,
    PREDEFINED_ES_RESERVATION_REQUESTS,
    PagedResult,
    ReservationStatus,
    ReservationStatusText,
    createBaseEsReservationRequest,
    isEmailValid,
    setMinArrivalDateOnEsRequest,
    setMinReservationStatusOnEsRequest,
    setReservationCategoriesOnEsRequest,
    setReservationStatusRangeOnEsRequest,
    setReservedResourcesFilterOnEsRequest,
    setResortIdsOnEsRequest,
} from "@maxxton/cms-mxts-api";
import { Button, ButtonGroup } from "reactstrap";
import { DATE_FORMAT, MXTS as MXTS_CONSTANTS } from "../../../../utils/constants";

import { CMSProviderProperties } from "../../../../containers/cmsProvider.types";
import { DateUtil } from "../../../../utils/date.util";
import { ReactSelectOption } from "./SiteMultiSelect";
import { Site } from "@maxxton/cms-api";
import { StringUtil } from "../../../../utils/string.util";
import { SwitchComponent } from "./SwitchComponent";
import { getAdminMxtsEnv } from "../../../../plugins/mxts/index";
import { getI18nLocaleString } from "../../../../i18n/index";
import { getLocalizedContentByContext } from "../../../../utils/localizedContent.util";
import { getResortIdsByResortsConfig } from "../../../../utils/resortsFilter.utils";
import namespaceList from "../../../../i18n/namespaceList";

interface RandomUserPickerProps {
    context: CMSProviderProperties;
    setUserId: React.Dispatch<React.SetStateAction<number | undefined>>;
    sites: Array<ReactSelectOption<Site>>;
}
interface RandomEsReservationContainerParams {
    hasFutureReservation: boolean;
    userType: MyEnvUserType;
    hasUnpaidReservation: boolean;
    filterByLocation: boolean;
    filteredResortIds?: number[];
}

export const RandomUserPicker = (props: RandomUserPickerProps): JSX.Element => {
    const { setUserId, context, sites } = props;
    const [userType, setUserType] = React.useState<MyEnvUserType>(MyEnvUserType.CUSTOMER);
    const [noUserFound, setNoUserFound] = React.useState<boolean>(false);
    const [hasFutureReservation, setHasFutureReservation] = React.useState<boolean>(false);
    const [hasUnpaidReservation, setHasUnpaidReservation] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showFilterByLocation, setShowFilterByLocation] = React.useState<boolean>(false);
    const [filterByLocation, setFilterByLocation] = React.useState<boolean>(false);
    const [filteredResortIds, setFilteredResortIds] = React.useState<number[]>();
    React.useEffect(() => {
        const fetchFilteredResortIds = async () => {
            if (sites.length) {
                let allFilteredResortIds: number[] = [];
                let showFilterByLocation = false;
                for (const site of sites) {
                    const selectedSite = await context.cmsApi.siteApi.findById({ id: site.value, projection: { sitemap: 0 } });
                    const localizedSiteOptions = getLocalizedContentByContext({ context, localizedContentOptions: selectedSite?.localizedOptions });
                    const filteredResortIds = await getResortIdsByResortsConfig({ localizedSiteOptions });
                    if (filteredResortIds?.length) {
                        allFilteredResortIds = [...allFilteredResortIds, ...filteredResortIds];
                    }
                    if (localizedSiteOptions?.enableResortsFilter) {
                        showFilterByLocation = true;
                    }
                }
                setFilteredResortIds(allFilteredResortIds);
                setShowFilterByLocation(showFilterByLocation);
            } else {
                setFilteredResortIds([]);
                setShowFilterByLocation(false);
                setFilterByLocation(false);
            }
        };
        fetchFilteredResortIds();
    }, [sites, context]);
    const onGenerateRandomId = React.useCallback(async () => {
        setIsLoading(true);
        setUserId(undefined);
        setNoUserFound(false);
        const randomCustomerId = await getRandomUserId({ hasFutureReservation, hasUnpaidReservation, userType, filterByLocation, filteredResortIds });
        if (!randomCustomerId) {
            setNoUserFound(true);
        }
        setUserId(randomCustomerId);
        setIsLoading(false);
    }, [hasFutureReservation, hasUnpaidReservation, userType, setUserId, filterByLocation]);

    return (
        <React.Fragment>
            <p className="admin-paragraph">
                <FontAwesome name="bookmark-o" size="2x" />
                {getI18nLocaleString(namespaceList.imitateUser, "randomCustomerNotice")}
            </p>
            <div className="form-group">
                <label> {getI18nLocaleString(namespaceList.imitateUser, "randomizeId")}</label>
                <ButtonGroup className="imitate-buttton">
                    <Button color="primary" outline onClick={() => setUserType(MyEnvUserType.CUSTOMER)} active={userType === MyEnvUserType.CUSTOMER}>
                        {getI18nLocaleString(namespaceList.imitateUser, "customer")}
                    </Button>
                    <Button color="primary" outline onClick={() => setUserType(MyEnvUserType.OWNER)} active={userType === MyEnvUserType.OWNER}>
                        {getI18nLocaleString(namespaceList.imitateUser, "owner")}
                    </Button>
                    <Button color="primary" outline onClick={() => setUserType(MyEnvUserType.NON_RENTABLE_OWNER)} active={userType === MyEnvUserType.NON_RENTABLE_OWNER}>
                        {getI18nLocaleString(namespaceList.imitateUser, "nonRentableOwner")}
                    </Button>
                </ButtonGroup>
            </div>
            <div className="imitate-reservation-switch">
                <SwitchComponent
                    checked={hasFutureReservation}
                    setChecked={setHasFutureReservation}
                    name="futureReservationSwitch"
                    label={getI18nLocaleString(namespaceList.imitateUser, "futureReservationSwitch")}
                ></SwitchComponent>
                <SwitchComponent
                    checked={hasUnpaidReservation}
                    setChecked={setHasUnpaidReservation}
                    name="unpaidReservationSwitch"
                    label={getI18nLocaleString(namespaceList.imitateUser, "unpaidReservationSwitch")}
                ></SwitchComponent>
                {showFilterByLocation && (
                    <SwitchComponent
                        checked={filterByLocation}
                        setChecked={setFilterByLocation}
                        name="filterByLocation"
                        label={getI18nLocaleString(namespaceList.imitateUser, "filterByLocation")}
                    ></SwitchComponent>
                )}
            </div>
            <div className="form-group">
                <Button disabled={isLoading} className={`randomId-button ${isLoading ? "button-lock" : "publish"}`} color="primary" onClick={onGenerateRandomId}>
                    {isLoading && <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />}
                    {getI18nLocaleString(namespaceList.imitateUser, "obtainRandomUserId")}
                </Button>
            </div>
            {noUserFound && <small className="alert alert-warning">{getI18nLocaleString(namespaceList.imitateUser, "noUserFoundForTheseCriteria")}</small>}
        </React.Fragment>
    );
};

async function getRandomContract(filterByLocation: boolean, filteredResortIds?: number[]): Promise<Contract | undefined> {
    const env = await getAdminMxtsEnv();
    const allActiveContractsResult = await MxtsApi.getContracts(env, { size: 1, page: 0, isActiveContract: true });
    const totalContractsCount = allActiveContractsResult?.totalElements;
    if (totalContractsCount) {
        const pageSize = 10;
        const totalPages = Math.ceil(totalContractsCount / pageSize);
        const maxPageIndex = totalContractsCount > 9999 ? Math.floor(9999 / pageSize) : totalPages;
        const randomContractsIndex = randomIntFromInterval(0, maxPageIndex);
        const randomContractResults = await MxtsApi.getContracts(env, { size: pageSize, page: randomContractsIndex, isActiveContract: true });
        const contractWithRecentReservation = await getContractWithRecentReservation(randomContractResults, env, filterByLocation, filteredResortIds);
        if (contractWithRecentReservation && (await isCustomerValid(contractWithRecentReservation.ownerId, env))) {
            return contractWithRecentReservation;
        }
        return getRandomContract(filterByLocation, filteredResortIds);
    }
}

async function getContractWithRecentReservation(contracts: PagedResult<Contract>, env: ApiCallOptions, filterByLocation: boolean, filteredResortIds?: number[]): Promise<Contract | undefined> {
    const esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.all();
    setReservedResourcesFilterOnEsRequest({ unitIds: contracts.content.map((contract) => contract.unitId) }, esReservationRequest);
    setMinArrivalDateOnEsRequest(moment().subtract(3, "years").format(DATE_FORMAT.MXTS), esReservationRequest);
    esReservationRequest.size = 1;
    if (filterByLocation && filteredResortIds?.length) {
        setResortIdsOnEsRequest(filteredResortIds, esReservationRequest);
    }
    const randomEsReservationContainer = await MxtsApi.getReservationEsForCustomer(env, esReservationRequest);
    if (randomEsReservationContainer.reservationResultsSize) {
        const unitIdsWithRecentReservation = randomEsReservationContainer.reservationResults[0].reservedResources?.map((reservedResource) => reservedResource.unitId) || [];
        return contracts.content.find((contract) => unitIdsWithRecentReservation.some((unitId) => unitId === contract.unitId));
    }
    return undefined;
}

async function getRandomUserId(params: RandomEsReservationContainerParams): Promise<number | undefined> {
    const { userType, hasUnpaidReservation, hasFutureReservation, filterByLocation, filteredResortIds } = params;
    if (userType === MyEnvUserType.OWNER && !hasUnpaidReservation && !hasFutureReservation) {
        const randomContract = await getRandomContract(filterByLocation, filteredResortIds).catch(() => undefined);
        return randomContract?.ownerId;
    }
    const randomEsReservationContainer = await getRandomEsReservationContainer(params).catch(() => undefined);
    return randomEsReservationContainer?.reservationResults?.[0]?.customer?.customerId;
}

async function getRandomEsReservationContainer(params: RandomEsReservationContainerParams, retryCount = 0): Promise<EsReservationResultContainer | undefined> {
    const { hasFutureReservation, hasUnpaidReservation, userType, filterByLocation, filteredResortIds } = params;
    const env = await getAdminMxtsEnv();
    let esReservationRequest = createBaseEsReservationRequest();
    let targetReservationCategories: number[] | undefined;
    const targetReservationStatuses: ReservationStatusText[] = [];
    if (hasUnpaidReservation) {
        esReservationRequest = PREDEFINED_ES_RESERVATION_REQUESTS.paymentDue();
        setMinArrivalDateOnEsRequest(DateUtil.formatDate(new Date(), DATE_FORMAT.ELASTIC), esReservationRequest);
        setReservationStatusRangeOnEsRequest(ReservationStatus.PROVISIONAL, ReservationStatus.DEFINITIVE, esReservationRequest);
        targetReservationStatuses.push(...[ReservationStatusText.PROVISIONAL, ReservationStatusText.DEFINITIVE, ReservationStatusText.CHECKED_IN]);
    }
    if (hasFutureReservation) {
        setMinArrivalDateOnEsRequest(DateUtil.formatDate(new Date(), DATE_FORMAT.ELASTIC), esReservationRequest);
        setReservationStatusRangeOnEsRequest(ReservationStatus.PROVISIONAL, ReservationStatus.DEFINITIVE, esReservationRequest);
        targetReservationStatuses.push(...[ReservationStatusText.PROVISIONAL, ReservationStatusText.DEFINITIVE, ReservationStatusText.CHECKED_IN]);
    }
    if (filterByLocation && filteredResortIds?.length) {
        setResortIdsOnEsRequest(filteredResortIds, esReservationRequest);
    }
    if (!esReservationRequest.reservationStatusId) {
        // Reservations below provisional usually don't have a customer linked
        setMinReservationStatusOnEsRequest(ReservationStatus.PROVISIONAL, esReservationRequest);
    }
    if (userType === MyEnvUserType.OWNER && (hasUnpaidReservation || hasFutureReservation)) {
        const ownUseReservationCategories = await MxtsApi.getReservationCategories(env, { myEnvironmentExcluded: false, markForOwnUse: true, page: 0, size: MXTS_CONSTANTS.MAX_RESULTS });
        if (ownUseReservationCategories?.content?.length) {
            targetReservationCategories = ownUseReservationCategories.content.map((ownUseCategory) => ownUseCategory.reservationCategoryId);
        } else {
            return undefined;
        }
    } else if (userType === MyEnvUserType.NON_RENTABLE_OWNER) {
        // See https://bitbucket.maxxton.com/projects/cms/repos/portal/browse/nwsportal/newyse/service-impl/src/maxxton/newyse/customer/persistence/CustomerMapper.xml#275
        const nonRentableOwnerReservationCategories = await MxtsApi.getReservationCategories(env, { myEnvironmentExcluded: false, internetUserGroupId: 2, page: 0, size: MXTS_CONSTANTS.MAX_RESULTS });
        if (nonRentableOwnerReservationCategories?.content?.length) {
            targetReservationCategories = nonRentableOwnerReservationCategories.content.map((category) => category.reservationCategoryId);
        } else {
            return undefined;
        }
    } else {
        const myEnvReservationCategories = await MxtsApi.getReservationCategories(env, { myEnvironmentExcluded: false, page: 0, size: MXTS_CONSTANTS.MAX_RESULTS });
        targetReservationCategories = myEnvReservationCategories?.content?.map((category) => category.reservationCategoryId);
    }

    if (targetReservationCategories?.length) {
        setReservationCategoriesOnEsRequest(targetReservationCategories, esReservationRequest);
    }

    esReservationRequest.size = 0;
    const emptyEsSearchResultContainer = await MxtsApi.getReservationEsForCustomer(env, esReservationRequest);
    if (emptyEsSearchResultContainer.reservationResultsSize > 0) {
        const reservationResultsSize = emptyEsSearchResultContainer.reservationResultsSize - 1;
        const randomIndex = randomIntFromInterval(0, reservationResultsSize > 9999 ? 9999 : reservationResultsSize);
        esReservationRequest.offset = randomIndex;
        esReservationRequest.size = 1;
        const randomEsReservationContainer = await MxtsApi.getReservationEsForCustomer(env, esReservationRequest);
        if (
            (!targetReservationStatuses.length ||
                targetReservationStatuses.some((targetStatus: ReservationStatusText) =>
                    StringUtil.equalsIgnoreCase(randomEsReservationContainer?.reservationResults?.[0]?.reservation?.status, targetStatus)
                )) &&
            (await isCustomerValid(randomEsReservationContainer?.reservationResults?.[0]?.customer?.customerId, env))
        ) {
            return randomEsReservationContainer;
        }

        // the elastic index is out of date. The reservation has been checked_out/declined now. Or the reservation doesn't have a customer anymore. Retry...
        if (retryCount < 50) {
            return getRandomEsReservationContainer(params, retryCount + 1);
        }
    }
    return undefined;
}

function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

async function isCustomerValid(customerId: number | undefined, env: ApiCallOptions): Promise<boolean> {
    if (!customerId) {
        return false;
    }
    const customer = await MxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: customerId }]);
    const customerMailAddress = customer?.addresses?.find((address) => address.managerId === customer?.mailAddressManagerId);
    // Make sure the customer has a valid email. You cannot imitate a user with an invalid email address.
    if (customer?.mailAddressManagerId && customerMailAddress?.email && isEmailValid(customerMailAddress?.email)) {
        return !(await isCustomerWithTouroperatorEmail(customerMailAddress?.email, env));
    }
    return false;
}

/**
 * If a customer's email is linked to more than ~100 other customer's, then it's probably a touroperator customer.
 * These customers will never login so we shouldn't be imitating them either.
 * See MCMS-8665 for more info.
 */
async function isCustomerWithTouroperatorEmail(email: string, env: ApiCallOptions) {
    const MAX_CUSTOMERS_WITH_SAME_EMAIL = 100;
    const customerIds = await MxtsApi.getCustomerList(env, { email, size: MAX_CUSTOMERS_WITH_SAME_EMAIL });
    return customerIds.length >= MAX_CUSTOMERS_WITH_SAME_EMAIL;
}
