import { Action } from "./";
import { SessionState } from "../reducers/sessionReducer";

export enum SessionActionType {
    SET_SESSION_END_TIME = "SET_SESSION_END_TIME",
    SET_HIDDEN_WIDGET = "SET_HIDDEN_WIDGET",
}

export interface SessionAction extends Action {
    actionType: SessionActionType;
    payload: SessionState;
}
