import { ActionType } from "../actions";
import { PrevNextAction } from "../actions/prevNextAction";
import { Reducer } from "redux";

export interface PrevNextState {
    useConditionalStepFlow: boolean;
}

// eslint-disable-next-line max-len
export const prevNextReducer: Reducer<PrevNextState> = (state: PrevNextState = { useConditionalStepFlow: false }, action: PrevNextAction): PrevNextState => {
    switch (action.type) {
        case ActionType.PrevNextClick:
            return { ...state, useConditionalStepFlow: action.payload };
        default:
            return state;
    }
};
