import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

/* eslint-disable max-lines-per-function */
import { Amenity, AmenityLink } from "@maxxton/cms-mxts-api";
import {
    AmenityLinkCategory,
    Base64Content,
    SelectedAmenityType,
    executeRecaptchaCheck,
    getAmenityName,
    handleAmenitySelect,
    isOutsideRange,
    linkAmenities,
    loadSelectableAmenities,
    sendEmailToResort,
    unlinkAmenities,
} from "./amenitiesEditor.util";
import { Button, Input, Modal, ModalBody, ModalHeader, Table, UncontrolledAlert } from "reactstrap";
import { GroupedOptionsType, components } from "react-select";

import { AmenitiesStateHandler } from "./Amenities";
import { AmenityDatePicker } from "./AmenityDatePicker";
import AsyncSelect from "react-select/async";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { SendingStatus } from "../description/description.utils";
import { SingleDatePicker } from "react-dates";
import { State } from "../../../redux";
import { debounce } from "lodash";
import { getI18nLocaleString } from "../../../i18n";
import { globalLogger } from "@maxxton/cms-api";
import namespaceList from "../../../i18n/namespaceList";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useSelector } from "react-redux";

export function AmenitiesEditor(props: {
    context: CMSProviderProperties;
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    amenities: AmenityLink[];
    isEditModalOpen: boolean | undefined;
    amenitiesStates: AmenitiesStateHandler;
    getReCaptchaToken: () => Promise<string>;
}) {
    const { context, childOptions, amenities, amenitiesStates, isEditModalOpen, getReCaptchaToken } = props;
    const { currentLocale, site } = context;
    const [disableUnlinkAmenityButton, setDisableUnlinkAmenityButton] = React.useState<boolean>(true);
    const [isAmenityDatesUpdating, setIsAmenityDatesUpdating] = React.useState<boolean>();
    const [isAmenityUnlinking, setIsAmenityUnlinking] = React.useState<boolean>();
    const [isAmenitiesUnlinked, setIsAmenitiesUnlinked] = React.useState<SendingStatus | undefined>();
    const [isAmenityDateUpdated, setIsAmenityDateUpdated] = React.useState<SendingStatus | undefined>();
    const [isEmailSent, setIsEmailSent] = React.useState<boolean>();
    const [emailSendStatus, setEmailSendStatus] = React.useState<SendingStatus | undefined>();
    const [selectedAmenitiesLinkId, setSelectedAmenitiesLinkId] = React.useState<number[]>([]);
    const [selectedAmenityForDateUpdate, setSelectedAmenityForDateUpdate] = React.useState<number>();
    const myEnvState: MyEnvState = useSelector((state: State) => state.myEnvState);

    React.useEffect(() => {
        setIsAmenitiesUnlinked(undefined);
        setIsAmenityDateUpdated(undefined);
        setDisableUnlinkAmenityButton(true);
        setSelectedAmenitiesLinkId([]);
    }, [isEditModalOpen]);

    const handleUnlinkAmenities = () => {
        setIsAmenitiesUnlinked(undefined);
        unlinkAmenities({ context, selectedAmenitiesLinkId, setDisableUnlinkAmenityButton, setIsAmenitiesUnlinked, setIsAmenityUnlinking, setSelectedAmenitiesLinkId, amenitiesStates });
    };

    const handleEmailSend = () => {
        setIsEmailSent(true);
        setEmailSendStatus(undefined);
        sendEmailToResort({
            childOptions,
            context,
            myEnvState,
            setEmailSendStatus,
            setIsEmailSent,
            amenityLinkEndDate: undefined,
            amenityLinkStartDate: undefined,
            selectedAmenitiesLinkId,
            selectedAmenity: undefined,
            selectedAmenityForDateUpdate,
            getReCaptchaToken,
        });
    };

    return (
        <React.Fragment>
            <div className="amenity-list">
                <div className="edit-image-loader">
                    {(isAmenityUnlinking || isAmenityDatesUpdating || isEmailSent) && (
                        <React.Fragment>
                            <div className="edit-image-overlay" />
                            <div className="loader-wrapper">
                                <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                            </div>
                        </React.Fragment>
                    )}
                    <div className="editable-table-wrapper">
                        <Table hover className="table-striped">
                            <thead>
                                <tr>
                                    <th>{getI18nLocaleString(namespaceList.amenitiesWidget, "select", currentLocale, site)}</th>
                                    <th>{getI18nLocaleString(namespaceList.admin, "name", currentLocale, site)}</th>
                                    <th>{getI18nLocaleString(namespaceList.amenitiesWidget, "value", currentLocale, site)}</th>
                                    <th>{getI18nLocaleString(namespaceList.amenitiesWidget, "startArrivalDate", currentLocale, site)}</th>
                                    <th>{getI18nLocaleString(namespaceList.amenitiesWidget, "endArrivalDate", currentLocale, site)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {amenities.map((amenity: AmenityLink, index) => (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={() =>
                                                    handleAmenitySelect({ amenityLinkId: amenity.amenityLinkId, selectedAmenitiesLinkId, setDisableUnlinkAmenityButton, setSelectedAmenitiesLinkId })
                                                }
                                                name="amenity-checkbox"
                                                value={amenity.amenityLinkId}
                                            />
                                        </td>
                                        <td data-label="Name">{getAmenityName(amenity)}</td>
                                        <td data-label="Number_Value">{amenity.numberValue}</td>
                                        <td data-label="Start_Date">
                                            <AmenityDatePicker
                                                amenity={amenity}
                                                dateType="start"
                                                placeholder={getI18nLocaleString(namespaceList.admin, "startDate")}
                                                childOptions={childOptions}
                                                context={context}
                                                setIsAmenityDateUpdated={setIsAmenityDateUpdated}
                                                setIsAmenityDatesUpdating={setIsAmenityDatesUpdating}
                                                setSelectedAmenityForDateUpdate={setSelectedAmenityForDateUpdate}
                                                amenitiesStates={amenitiesStates}
                                            />
                                        </td>
                                        <td data-label="End_Date">
                                            <AmenityDatePicker
                                                amenity={amenity}
                                                dateType="end"
                                                placeholder={getI18nLocaleString(namespaceList.admin, "endDate")}
                                                childOptions={childOptions}
                                                context={context}
                                                setIsAmenityDateUpdated={setIsAmenityDateUpdated}
                                                setIsAmenityDatesUpdating={setIsAmenityDatesUpdating}
                                                setSelectedAmenityForDateUpdate={setSelectedAmenityForDateUpdate}
                                                amenitiesStates={amenitiesStates}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="alerts">
                    {isAmenitiesUnlinked === SendingStatus.Success && (
                        <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.amenitiesWidget, "amenitiesUnlinked", currentLocale, site)}</UncontrolledAlert>
                    )}
                    {isAmenitiesUnlinked === SendingStatus.Failed && (
                        <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.amenitiesWidget, "amenitiesUnlinkedFailed", currentLocale, site)}</UncontrolledAlert>
                    )}
                    {isAmenityDateUpdated === SendingStatus.Success && (
                        <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.amenitiesWidget, "amenityDateUpdated", currentLocale, site)}</UncontrolledAlert>
                    )}
                    {isAmenityDateUpdated === SendingStatus.Failed && (
                        <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.amenitiesWidget, "amenityDateUpdateFailed", currentLocale, site)}</UncontrolledAlert>
                    )}
                    {emailSendStatus === SendingStatus.Success && (
                        <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.amenitiesWidget, "emailSend", currentLocale, site)}</UncontrolledAlert>
                    )}
                    {emailSendStatus === SendingStatus.Failed && (
                        <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.amenitiesWidget, "emailSendFailed", currentLocale, site)}</UncontrolledAlert>
                    )}
                </div>
                <div className="button-wrapper">
                    {childOptions?.askForApprovalByEmail ? (
                        <button className="descriptions-edit-fields__button" onClick={handleEmailSend}>
                            {getI18nLocaleString(namespaceList.amenitiesWidget, "sendEmail", currentLocale, site)}
                        </button>
                    ) : (
                        <Button className="unlink-amenity" onClick={handleUnlinkAmenities} disabled={disableUnlinkAmenityButton}>
                            {getI18nLocaleString(namespaceList.amenitiesWidget, "unlinkAmenities", currentLocale, site)}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export const AddAmenitiesModal = (props: {
    context: CMSProviderProperties;
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    isAddAmenitiesModalOpen: boolean;
    addAmenitiesModalToggle: () => void;
    disableLinkAmenityButton: boolean;
    setDisableLinkAmenityButton: React.Dispatch<React.SetStateAction<boolean>>;
    amenitiesStates: AmenitiesStateHandler;
    amenityCategories: AmenityLinkCategory[];
}) => {
    const { context, childOptions, isAddAmenitiesModalOpen, addAmenitiesModalToggle, disableLinkAmenityButton, setDisableLinkAmenityButton, amenitiesStates, amenityCategories } = props;
    const { currentLocale, site } = context;
    const [selectedAmenity, setSelectedAmenity] = React.useState<Amenity>();
    const [base64Image, setbase64Image] = React.useState<Base64Content>();
    const [amenityMessage, setAmenityMessage] = React.useState<string>("");
    const [amenityLinkStartDate, setAmenityLinkStartDate] = React.useState<moment.Moment | null>();
    const [amenityLinkEndDate, setAmenityLinkEndDate] = React.useState<moment.Moment | null>();
    const [linkAmenityStartDateFocus, setLinkAmenityStartDateFocus] = React.useState<boolean>(false);
    const [linkAmenityEndDateFocus, setLinkAmenityEndDateFocus] = React.useState<boolean>(false);
    const [isAmenityLinked, setIsAmenityLinked] = React.useState<SendingStatus | undefined>();
    const [isAmenityLinking, setIsAmenityLinking] = React.useState<boolean>();
    const [addAmenityEmailSendStatus, setAddAmenityEmailSendStatus] = React.useState<SendingStatus | undefined>();
    const [isEmailSent, setIsEmailSent] = React.useState<boolean>();
    const [page, setPage] = React.useState<number>(0);
    const myEnvState: MyEnvState = useSelector((state: State) => state.myEnvState);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const getReCaptchaToken = React.useCallback(async (): Promise<string> => executeRecaptchaCheck(executeRecaptcha), [executeRecaptcha]);

    React.useEffect(() => {
        if (isAddAmenitiesModalOpen) {
            loadSelectableAmenities({ amenityCategories, context, page: 0 });
            setIsAmenityLinked(undefined);
        }
    }, [isAddAmenitiesModalOpen]);

    const handleAmenityLinkDate = (date: moment.Moment | null, isStartDate?: boolean) => {
        if (isStartDate) {
            setAmenityLinkStartDate(date);
        } else {
            setAmenityLinkEndDate(date);
        }
    };
    const Option = (props: any) => (
        <React.Fragment>
            <components.Option {...props}>
                <div className="amenityCategoryItems">
                    <label>{props.label}</label>
                </div>
            </components.Option>
        </React.Fragment>
    );
    const handleLinkAmenitySelect = (event: SelectedAmenityType) => {
        const amenity = event?.value;
        setSelectedAmenity(amenity || undefined);
        setAmenityLinkStartDate(undefined);
        setAmenityLinkEndDate(undefined);
        setDisableLinkAmenityButton(!amenity);
    };

    const handleLinkSelectedAmenities = () => {
        setIsAmenityLinked(undefined);
        linkAmenities({ amenityLinkEndDate, amenityLinkStartDate, context, selectedAmenity, setSelectedAmenity, setIsAmenityLinked, setIsAmenityLinking, amenitiesStates });
    };

    const handleEmailSend = () => {
        setIsEmailSent(true);
        setAddAmenityEmailSendStatus(undefined);
        sendEmailToResort({
            childOptions,
            context,
            myEnvState,
            setEmailSendStatus: setAddAmenityEmailSendStatus,
            setIsEmailSent,
            amenityLinkEndDate,
            amenityLinkStartDate,
            selectedAmenitiesLinkId: [],
            selectedAmenity,
            selectedAmenityForDateUpdate: undefined,
            getReCaptchaToken,
            base64Image,
            amenityMessage,
        });
    };
    const convertToBase64 = (file: File): Promise<Base64Content> =>
        new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const imageFile = event.target.files?.[0];
        try {
            const base64Content = imageFile && (await convertToBase64(imageFile));
            if (imageFile?.type.includes("image") && base64Content) {
                setbase64Image(base64Content);
            }
        } catch (error) {
            globalLogger.error(error);
        }
    };

    const debouncedChangeHandler = React.useMemo(
        () =>
            debounce((amenityMessage: string) => {
                setAmenityMessage(amenityMessage);
            }, 800),
        []
    );

    const handleMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedChangeHandler(event.target.value);
    };

    // Need to improve fetchOptions function to load the options asyncronously on scroll which will we done in MCMS-10182.
    function fetchOptions(inputValue: string) {
        try {
            setPage((prevPage) => prevPage + 1);
            return loadSelectableAmenities({ amenityCategories, context, page, searchString: inputValue }).then((amenityLinkCategories) => {
                const amenityGroupedOptions: GroupedOptionsType<SelectedAmenityType> = amenityLinkCategories.map((amenityCategory) => ({
                    options: amenityCategory.amenities!.map((amenityLink) => ({ value: amenityLink.amenity, label: amenityLink.name })),
                    label: amenityCategory.name,
                }));
                return amenityGroupedOptions;
            });
        } catch (error) {
            globalLogger.error(error);
        }
    }

    return (
        <React.Fragment>
            <Modal isOpen={isAddAmenitiesModalOpen} className="image-editable-modal" toggle={addAmenitiesModalToggle} size="md">
                <ModalHeader toggle={addAmenitiesModalToggle}>{getI18nLocaleString(namespaceList.amenitiesWidget, "linkAmenity", currentLocale, site)}</ModalHeader>
                <ModalBody>
                    <div className="edit-image-loader">
                        {(isAmenityLinking || isEmailSent) && (
                            <React.Fragment>
                                <div className="edit-image-overlay" />
                                <div className="loader-wrapper">
                                    <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                                </div>
                            </React.Fragment>
                        )}
                        <AsyncSelect
                            className="react-select-container"
                            classNamePrefix="react-select"
                            cacheOptions
                            loadOptions={fetchOptions}
                            components={{
                                Option,
                            }}
                            defaultOptions
                            value={selectedAmenity ? { value: selectedAmenity, label: selectedAmenity.name } : null}
                            isClearable
                            onMenuScrollToBottom={() => setPage((prevPage) => prevPage + 1)}
                            onChange={handleLinkAmenitySelect}
                            instanceId="react-select-amenities"
                        />
                        {selectedAmenity && (
                            <div className="dates-select">
                                <div className={linkAmenityStartDateFocus ? "focused SingleDatePicker_wrap" : "SingleDatePicker_wrap"}>
                                    <SingleDatePicker
                                        id="start-date"
                                        date={amenityLinkStartDate || null}
                                        onDateChange={(date) => handleAmenityLinkDate(date, true)}
                                        focused={linkAmenityStartDateFocus}
                                        onFocusChange={({ focused }) => setLinkAmenityStartDateFocus(focused)}
                                        numberOfMonths={1}
                                        displayFormat={DATE_FORMAT.DATE_WITH_SHORT_MONTH}
                                        showClearDate={false}
                                        isOutsideRange={(date) => isOutsideRange(date)}
                                        hideKeyboardShortcutsPanel={true}
                                    />
                                </div>
                                <div className={linkAmenityEndDateFocus ? "focused SingleDatePicker_wrap" : "SingleDatePicker_wrap"}>
                                    <SingleDatePicker
                                        id="end-date"
                                        date={amenityLinkEndDate || null}
                                        onDateChange={(date) => handleAmenityLinkDate(date)}
                                        focused={linkAmenityEndDateFocus}
                                        onFocusChange={({ focused }) => setLinkAmenityEndDateFocus(focused)}
                                        numberOfMonths={1}
                                        displayFormat={DATE_FORMAT.DATE_WITH_SHORT_MONTH}
                                        showClearDate={false}
                                        isOutsideRange={(date) => isOutsideRange(date, amenityLinkStartDate)}
                                        hideKeyboardShortcutsPanel={true}
                                        disabled={!amenityLinkStartDate}
                                    />
                                </div>
                                {childOptions?.uploadImage && (
                                    <div className="inputFlled">
                                        <Input name="image-file" id="attachment" type="file" accept="image/*" onChange={handleImageUpload} className="form-control" />
                                    </div>
                                )}
                                <div className="message-text-field-wrapper">
                                    <Input
                                        id="message"
                                        onChange={handleMessage}
                                        type="textarea"
                                        className="message-field"
                                        placeholder={getI18nLocaleString(namespaceList.amenitiesWidget, "amenityMessage", currentLocale, site)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="alerts">
                        {isAmenityLinked === SendingStatus.Success && (
                            <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.amenitiesWidget, "amenityLinked", currentLocale, site)}</UncontrolledAlert>
                        )}
                        {isAmenityLinked === SendingStatus.Failed && (
                            <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.amenitiesWidget, "amenityLinkFailed", currentLocale, site)}</UncontrolledAlert>
                        )}
                        {addAmenityEmailSendStatus === SendingStatus.Success && (
                            <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.amenitiesWidget, "emailSend", currentLocale, site)}</UncontrolledAlert>
                        )}
                        {addAmenityEmailSendStatus === SendingStatus.Failed && (
                            <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.amenitiesWidget, "emailSendFailed", currentLocale, site)}</UncontrolledAlert>
                        )}
                    </div>
                    <div className="button-wrapper">
                        {childOptions?.askForApprovalByEmail ? (
                            <button className="descriptions-edit-fields__button" disabled={disableLinkAmenityButton} onClick={handleEmailSend}>
                                {getI18nLocaleString(namespaceList.amenitiesWidget, "sendEmail", currentLocale, site)}
                            </button>
                        ) : (
                            <Button onClick={handleLinkSelectedAmenities} disabled={disableLinkAmenityButton}>
                                {getI18nLocaleString(namespaceList.amenitiesWidget, "linkAmenity", currentLocale, site)}
                            </Button>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
