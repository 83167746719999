import * as moment from "moment";

import { ApiCallOptions, MxtsApiWrapper, PagedResult, Resort, Resource, VoucherDetailsResponse } from "@maxxton/cms-mxts-api";

export async function getVoucherSetResource(voucher: VoucherDetailsResponse, resortId: number | undefined, env: ApiCallOptions, mxtsApi: MxtsApiWrapper): Promise<Resource | undefined> {
    if (!resortId) {
        return undefined;
    }
    const resorts: Resort[] = await mxtsApi.resortParents(env, {}, [{ key: "resortId", value: resortId }]);
    const resortIds: number[] = [...resorts?.map((resort) => resort.resortId), resortId].filter((resortId) => resortId) as number[];
    const voucherSetResources: PagedResult<Resource> = await mxtsApi.resources(env, { size: 50, codes: [voucher.voucherSetResourceCode], resortIds });
    const voucherSpecialResource: Resource[] | undefined = voucherSetResources?.content?.filter(
        (voucherSetResource: Resource) => voucherSetResource.searchCode === voucher.resourceCode && (!voucherSetResource?.archivedFrom || moment(voucherSetResource.archivedFrom).isAfter(moment()))
    );
    return voucherSpecialResource[0];
}
