import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { Category, CategoryApi, Page, Post, Site, Typesearch, WithId } from "@maxxton/cms-api";
import { getClientIdFromToken, getClientNameById } from "../components/admin/utils";
import { getI18nLocaleObject, getI18nLocaleString } from "../i18n/loader";

import { DebounceUtil } from "../utils/debounce.util";
import { SomeColumnSpec } from "./index";
import { globalApiContext } from "../containers/CmsProvider";
import namespaceList from "../i18n/namespaceList";

type JsxElementFunction = (item: Page & Site & WithId, clientName?: string) => JSX.Element;

export const renderJsxElement = async (item: Page & Site & WithId, jsxLoadingElement: JsxElementFunction, jsxContentElement: JsxElementFunction) => {
    const clientId = getClientIdFromToken();
    if (clientId && item._id) {
        const allPageLocks = await DebounceUtil.getAllPageLocksDebounced(200);
        const pageLockedBy = allPageLocks.find((pageLock) => pageLock.pageId === item._id);
        if (pageLockedBy && pageLockedBy.userId !== clientId) {
            const clientName = await getClientNameById(globalApiContext(), pageLockedBy.userId);
            if (clientName) {
                return jsxContentElement(item, clientName);
            }
        }
    }
    return jsxLoadingElement(item);
};
export function getLockedPageNameJsx(item: Page & Site, clientName: string): JSX.Element {
    const nameRegexp = new RegExp("\\$name");
    const isContainHost = !!item.host;
    return (
        <div className="page-lock">
            <FontAwesome name="lock text-warning" />
            <span className="page-lock-name">{item.name}</span>
            <div className="text-warning lock-text">{`(${getI18nLocaleString(namespaceList.admin, isContainHost ? "siteLockedByUserTableNotice" : "pageLockedByUserTableNotice").replace(
                nameRegexp,
                clientName
            )})`}</div>
        </div>
    );
}

export function categoryTableColumn<T extends Page | Typesearch | Post>(): SomeColumnSpec<T, keyof T> {
    return {
        name: getI18nLocaleObject(namespaceList.admin, "category"),
        variable: "category",
        uniqueId: "category",
        type: "jsx",
        jsxLoadingElement: (item) => <span></span>,
        jsxElement: async (item: { category?: number[] }) => {
            const categoryIds: number[] | undefined = item?.category;
            if (categoryIds?.length) {
                const categories: Category[] = await CategoryApi.find({ query: { categoryId: { $in: categoryIds.filter((id: number) => id) } }, projection: { name: 1 } });
                return <span>{categories.map((category) => category.name).join(", ")}</span>;
            }
            return <span></span>;
        },
    };
}

interface Timestamped {
    createdAt: Date;
    updatedAt: Date;
}

export function getTimeStampOptions<T extends Timestamped>(): Array<SomeColumnSpec<T, keyof T>> {
    return [
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
    ];
}
