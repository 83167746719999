import {
    Amenity,
    AmenityCategory,
    AmenityCategoryRequest,
    AmenityLink,
    ApiCallOptions,
    ImageDetailedView,
    MxtsApiWrapper,
    MxtsError,
    PagedResult,
    Resort,
    Resource,
    SpecialType,
    StayPeriodDef,
    Subject,
    SubjectRequest,
    Unit,
} from "@maxxton/cms-mxts-api";
import { chunk, sortBy, uniq } from "lodash";

import { ArrayUtil } from "./array.util";
import { MXTS } from "./constants";
import { globalLogger } from "@maxxton/cms-api";

/**
 * Util for obtaining domain objects from mxts
 */
export class DomainObjectUtil {
    /* jscpd:ignore-start */
    public static async getResourceById(mxtsApi: MxtsApiWrapper, resourceId: number | undefined, env: ApiCallOptions, extraOptions?: { view?: "detail" }): Promise<Resource | null> {
        if (!resourceId) {
            return null;
        }
        return mxtsApi
            .resources(env, { size: 1, resourceIds: [resourceId], ...extraOptions })
            .then((res: PagedResult<Resource>) => DomainObjectUtil.getSingleResult(res))
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getResourceByCode(mxtsApi: MxtsApiWrapper, code: string, env: ApiCallOptions, extraOptions?: { view?: "detail" }): Promise<Resource | null> {
        return mxtsApi
            .resources(env, { size: 1, codes: [code], ...extraOptions })
            .then((res: PagedResult<Resource>) => DomainObjectUtil.getSingleResult(res))
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getResourceBySearchCode(
        mxtsApi: MxtsApiWrapper,
        searchCode: string,
        env: ApiCallOptions,
        extraOptions?: { view?: "detail"; specialType?: SpecialType }
    ): Promise<Resource | null> {
        return mxtsApi
            .resources(env, { size: 1, searchCodes: [searchCode], ...extraOptions })
            .then((res: PagedResult<Resource>) => DomainObjectUtil.getSingleResult(res))
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }
    /* jscpd:ignore-end */

    public static async getUnitById(mxtsApi: MxtsApiWrapper, unitId: number | undefined, env: ApiCallOptions): Promise<Unit | null> {
        if (!unitId) {
            return null;
        }
        return mxtsApi
            .units(env, { size: 1, unitIds: [unitId] })
            .then((res: PagedResult<Unit>) => DomainObjectUtil.getSingleResult(res))
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getAmenitiesByIdentifiers(mxtsApi: MxtsApiWrapper, identifiers: string, env: ApiCallOptions): Promise<Amenity[] | null> {
        return mxtsApi
            .amenities(env, { identifier: identifiers, view: "detail" })
            .then((amenity: PagedResult<Amenity>) => amenity.content)
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getAmenityLinks(mxtsApi: MxtsApiWrapper, amenityManagerId: number | undefined, env: ApiCallOptions): Promise<AmenityLink[] | null> {
        return mxtsApi
            .amenityLinks(env, { size: MXTS.MAX_RESULTS, sort: "priority", view: "detail", managerId: amenityManagerId })
            .then((amenityLink: PagedResult<AmenityLink>) => amenityLink.content)
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getAmenityLinkById(mxtsApi: MxtsApiWrapper, env: ApiCallOptions, preferredAmenity: Amenity): Promise<AmenityLink | null> {
        return mxtsApi
            .amenityLinkById(env, {}, [{ key: "amenityId", value: preferredAmenity?.amenityId }])
            .then((amenityLink: PagedResult<AmenityLink>) => DomainObjectUtil.getSingleResult(amenityLink))
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getUnitsByIds(mxtsApi: MxtsApiWrapper, unitIds: number[], env: ApiCallOptions): Promise<Unit[]> {
        if (!unitIds?.length) {
            return [];
        }
        const idChunks: number[][] = chunk(unitIds, 50);
        const allObtainedChunks: Unit[][] = await Promise.all(
            idChunks.map((ids) => mxtsApi.units(env, { size: MXTS.MAX_RESULTS, unitIds: ids }).then((unitsPage: PagedResult<Unit>) => unitsPage?.content || []))
        );
        return ArrayUtil.flatten2Dimensions(allObtainedChunks);
    }

    public static async getResort(mxtsApi: MxtsApiWrapper, requestParams: { code?: string; resortIds?: number[] }, env: ApiCallOptions): Promise<Resort | null> {
        return mxtsApi
            .resorts(env, requestParams)
            .then((res: PagedResult<Resort>) => DomainObjectUtil.getSingleResult(res))
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getStayPeriodDef(mxtsApi: MxtsApiWrapper, stayPeriodDefId: number, env: ApiCallOptions): Promise<StayPeriodDef | null> {
        return mxtsApi
            .stayPeriodDefs(env, { stayPeriodDefIds: [stayPeriodDefId] })
            .then((pagedResult: PagedResult<StayPeriodDef>) => DomainObjectUtil.getSingleResult(pagedResult))
            .catch((error: MxtsError) => {
                globalLogger.error(error);
                return null;
            });
    }

    public static async getResourcesByIds(mxtsApi: MxtsApiWrapper, resourceIds: number[], env: ApiCallOptions): Promise<Resource[]> {
        if (!resourceIds.length) {
            return [];
        }
        const idChunks: number[][] = chunk(resourceIds, 50);
        const allObtainedChunks: Resource[][] = await Promise.all(
            idChunks.map((ids) => mxtsApi.resources(env, { size: MXTS.MAX_RESULTS, resourceIds: ids }).then((resourcesPage: PagedResult<Resource>) => resourcesPage?.content || []))
        );
        return ArrayUtil.flatten2Dimensions(allObtainedChunks);
    }

    public static async getSubjectsByIds(mxtsApi: MxtsApiWrapper, subjectIds: number[], env: ApiCallOptions, useWithActivityPlanner?: boolean): Promise<Subject[]> {
        if (!subjectIds?.length) {
            return [];
        }
        const subjectRequestParams: SubjectRequest = {
            subjectIds,
        };
        if (useWithActivityPlanner) {
            subjectRequestParams.linkType = "activity";
        }
        return mxtsApi.subjects(env, subjectRequestParams).then((resp: PagedResult<Subject>) => resp.content || []);
    }

    public static async getAmenityCategoriesByIds(
        mxtsApi: MxtsApiWrapper,
        amenityCategoryIds: number[],
        amenityCategoriesRequest: AmenityCategoryRequest,
        options: { sort?: string[] } = {},
        env: ApiCallOptions
    ): Promise<AmenityCategory[]> {
        if (!amenityCategoryIds.length) {
            return [];
        }
        const idChunks: number[][] = chunk(amenityCategoryIds, 50);
        const allObtainedChunks: AmenityCategory[][] = await Promise.all(
            idChunks.map((ids) => {
                amenityCategoriesRequest.amenityCategoryId = ids;
                return mxtsApi.amenityCategories(env, amenityCategoriesRequest).then((categoriesPage: PagedResult<AmenityCategory>) => categoriesPage?.content || []);
            })
        );
        const allResults = ArrayUtil.flatten2Dimensions(allObtainedChunks);
        if (options?.sort?.length) {
            return sortBy(allResults, options.sort);
        }
        return allResults;
    }

    private static getSingleResult<T>(pagedResult: PagedResult<T>): T | null {
        return pagedResult.content?.length ? pagedResult.content[0] : null;
    }
}

export async function getUnitImages(units: Unit[], fallbackToTypeImage: boolean, env: ApiCallOptions, mxtsApi: MxtsApiWrapper): Promise<Array<{ unitId: number; image: ImageDetailedView }>> {
    const allUnitImages: Array<{ unitId: number; image: ImageDetailedView }> = [];
    const unitImageManagerIds = uniq(units.map((unit) => unit.imageManagerId).filter((imageManagerId) => imageManagerId));
    const unitImages = unitImageManagerIds.length
        ? await mxtsApi.imagesForAllManagerId(env, {
              imageManagerIds: unitImageManagerIds,
              size: unitImageManagerIds.length,
              view: "detail",
          })
        : [];
    units.forEach((unit) => {
        const unitImage = unitImages.find((image) => image.imageManagerIds?.some((imageManagerId) => imageManagerId === unit.imageManagerId));
        if (unitImage) {
            allUnitImages.push({ unitId: unit.unitId, image: unitImage });
        }
    });
    if (fallbackToTypeImage) {
        const unitsWithoutImage = units.filter((unit) => !unitImages.some((image) => image.imageManagerIds?.some((imageManagerId) => imageManagerId === unit.imageManagerId)));
        const missingImageResourceIds = unitsWithoutImage.map((unit) => unit.resourceId).filter((resourceId) => resourceId);
        if (missingImageResourceIds.length) {
            const accoTypes = missingImageResourceIds.length
                ? await mxtsApi
                      .resources(env, {
                          resourceIds: missingImageResourceIds,
                          size: missingImageResourceIds.length,
                      })
                      .then((resp) => resp.content)
                : [];
            const accoTypeImageManagerIds = uniq(accoTypes.map((accoType) => accoType.imageManagerId).filter((imageManagerId) => imageManagerId));
            const accoTypeImages = accoTypeImageManagerIds.length
                ? await mxtsApi.imagesForAllManagerId(env, {
                      imageManagerIds: accoTypeImageManagerIds,
                      size: accoTypeImageManagerIds.length,
                      view: "detail",
                  })
                : [];
            unitsWithoutImage.forEach((unit) => {
                const unitResourceImageManagerId = accoTypes.find((accoType) => accoType.resourceId === unit.resourceId)?.imageManagerId;
                const targetImage = accoTypeImages.find((image) => image.imageManagerIds?.some((imageManagerId) => imageManagerId === unitResourceImageManagerId));
                if (targetImage) {
                    allUnitImages.push({ unitId: unit.unitId, image: targetImage });
                }
            });
        }
    }
    return allUnitImages;
}
