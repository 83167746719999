import * as moment from "moment";

import {
    AccoKind,
    ActivityResponse,
    Amenity,
    ContractType,
    MxtsApi,
    MyEnvUserType,
    OwnerType,
    RateTypes,
    Region,
    ReservationCategory,
    ReservationStatus,
    ReservationStatusText,
    ReservationType,
    Resort,
    Resource,
    Subject,
    Unit,
} from "@maxxton/cms-mxts-api";
import { CmsApi, LocaleApi, Site, SiteApi, WithId } from "@maxxton/cms-api";
import { ConditionalSetSelect, ContentType } from "../../../components.enum";
import { DATE_FORMAT, MXTS as MXTS_CONSTANTS } from "../../../../utils/constants";
import { FilteredSet, InputGroupItem } from "../inputGroup.types";
import { capitalize, lowerCase, uniqueId } from "lodash";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../../i18n";

import { PaymentStatuses } from "../../../../plugins/dynamic/payment-widgets/dynamic-status/paymentStatus.enum";
import { SelectOption } from "../../../../form-specs/formSpec.types";
import { getAdminMxtsEnv } from "../../../../plugins/mxts";
import { getCMSOptions } from "../../../../plugins/settings";
import { getLocaleCodeFromCMSOptions } from "../../../../utils/widget.util";
import namespaceList from "../../../../i18n/namespaceList";

export const transformInputToGroupItem = (
    logicalOperators?: string,
    dynamicFilter?: string,
    conditionalOperators?: string,
    dynamicFilterData?: SelectOption<string>,
    dynamicFilterMultiData?: Array<SelectOption<string>>
): InputGroupItem => ({
    id: uniqueId(),
    logicalOperators,
    dynamicFilter,
    conditionalOperators,
    dynamicFilterData,
    dynamicFilterMultiData,
});

export enum ConditionalSetDynamicFilterList {
    USER_TYPE = "userType",
    OWNER_TYPE = "ownerType",
    CUSTOMER_COUNTRY = "customerCountry",
    DISTRIBUTION_CHANNEL = "distributionChannel",
    RESERVATION_CATEGORY = "reservationCategory",
    PAYMENT_STATUS = "paymentStatus",
    RESERVATION_STATUS = "reservationStatus",
    RESERVATION_TYPE = "ReservationType",
    RESERVATION_DATE = "ReservationDate",
    CONTRACTS_TYPE = "contractsType",
    RESORT_ACTIVITY = "resortActivity",
    MIN_BATHROOM = "minBathroom",
    MIN_BEDROOM = "minBedroom",
    RATE_TYPE = "rateType",
    SPECIALCODE = "specialcode",
    SUBJECT = "subject",
    FREE_SEARCH_ID = "freeSearchId",
    FACILITY_ID = "facilityId",
}

export const dynamicFilterList = [
    {
        value: ContentType.RESORT.toString(),
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "resort"),
    },
    {
        value: ContentType.REGION.toString(),
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "region"),
    },
    {
        value: ContentType.ACCOMMODATION_KIND.toString(),
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "accoKind"),
    },
    {
        value: ContentType.AMENITY.toString(),
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "amenity"),
    },
    {
        value: ContentType.ACCOMMODATION_TYPE.toString(),
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "accoType"),
    },
    {
        value: ContentType.UNIT.toString(),
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "unit"),
    },
    {
        value: ConditionalSetSelect.START_DATE,
        label: getI18nLocaleObject(namespaceList.admin, "startDate"),
    },
    {
        value: ConditionalSetSelect.END_DATE,
        label: getI18nLocaleObject(namespaceList.admin, "endDate"),
    },
    {
        value: ConditionalSetSelect.ARRIVAL_DAY,
        label: getI18nLocaleObject(namespaceList.admin, "arrivalDay"),
    },
    {
        value: ConditionalSetSelect.DEPARTURE_DAY,
        label: getI18nLocaleObject(namespaceList.admin, "departureDay"),
    },
    {
        value: ConditionalSetDynamicFilterList.USER_TYPE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "userType"),
    },
    {
        value: ConditionalSetDynamicFilterList.OWNER_TYPE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "ownerType"),
    },
    {
        value: ConditionalSetDynamicFilterList.CUSTOMER_COUNTRY,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "customerCountry"),
    },
    {
        value: ConditionalSetDynamicFilterList.DISTRIBUTION_CHANNEL,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "distributionChannel"),
    },
    {
        value: ConditionalSetDynamicFilterList.RESERVATION_CATEGORY,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "reservationCategory"),
    },
    {
        value: ConditionalSetDynamicFilterList.PAYMENT_STATUS,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "paymentStatus"),
    },
    {
        value: ConditionalSetDynamicFilterList.RESERVATION_STATUS,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "reservationStatus"),
    },
    {
        value: ConditionalSetDynamicFilterList.RESERVATION_DATE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "ReservationDate"),
    },
    {
        value: ConditionalSetDynamicFilterList.CONTRACTS_TYPE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "contractsType"),
    },
    {
        value: ConditionalSetDynamicFilterList.RESORT_ACTIVITY,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "resortActivity"),
    },
    {
        value: ConditionalSetDynamicFilterList.MIN_BATHROOM,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "minBathroom"),
    },
    {
        value: ConditionalSetDynamicFilterList.MIN_BEDROOM,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "minBedroom"),
    },
    {
        value: ConditionalSetDynamicFilterList.RATE_TYPE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "rateType"),
    },
    {
        value: ConditionalSetDynamicFilterList.RESERVATION_TYPE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "ReservationType"),
    },
    {
        value: ConditionalSetDynamicFilterList.SPECIALCODE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "specialcode"),
    },
    {
        value: ConditionalSetDynamicFilterList.SUBJECT,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "subject"),
    },
    {
        value: ConditionalSetDynamicFilterList.FREE_SEARCH_ID,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "freeSearchId"),
    },
    {
        value: ConditionalSetDynamicFilterList.FACILITY_ID,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "facilityId"),
    },
];

export const conditionalOperators = [
    {
        value: ConditionalSetSelect.EQUAL_TO,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "equalTo"),
    },
    {
        value: ConditionalSetSelect.NOT_EQUAL_TO,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "notEqualTo"),
    },
    {
        value: ConditionalSetSelect.GREATER_THAN,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "greaterThan"),
    },
    {
        value: ConditionalSetSelect.LESS_THAN,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "lessThan"),
    },
    {
        value: ConditionalSetSelect.BEFORE,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "before"),
    },
    {
        value: ConditionalSetSelect.AFTER,
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "after"),
    },
];

export const logicalOperators = [
    {
        value: ConditionalSetSelect.AND,
        label: "AND",
    },
    {
        value: ConditionalSetSelect.OR,
        label: "OR",
    },
];

export const myEnvUserTypes = [
    {
        value: MyEnvUserType.CUSTOMER,
        label: getI18nLocaleObject(namespaceList.imitateUser, "customer"),
    },
    {
        value: MyEnvUserType.OWNER,
        label: getI18nLocaleObject(namespaceList.imitateUser, "owner"),
    },
    {
        value: MyEnvUserType.NON_RENTABLE_OWNER,
        label: getI18nLocaleObject(namespaceList.imitateUser, "nonRentableOwner"),
    },
];

export const ownerTypeSelectOptions = [
    {
        value: OwnerType.COMPANY,
        label: getI18nLocaleObject(namespaceList.admin, "companyOwner"),
    },
    {
        value: OwnerType.PRIVATE_OWNER,
        label: getI18nLocaleObject(namespaceList.admin, "privateOwner"),
    },
    {
        value: OwnerType.UNKNOWN,
        label: getI18nLocaleObject(namespaceList.admin, "ownerTypeUnknown"),
    },
];

export const paymentStatuses = [
    {
        value: PaymentStatuses.SUCCESS,
        label: getI18nLocaleObject(namespaceList.widgetStatus, "success"),
    },
    {
        value: PaymentStatuses.CANCEL,
        label: getI18nLocaleObject(namespaceList.widgetStatus, "cancel"),
    },
    {
        value: PaymentStatuses.FAILED,
        label: getI18nLocaleObject(namespaceList.widgetStatus, "failed"),
    },
    {
        value: PaymentStatuses.SKIP,
        label: getI18nLocaleObject(namespaceList.widgetStatus, "skip"),
    },
];

export const getNumberCounts = (): Array<SelectOption<string>> => {
    const days = Array.from({ length: 100 }, (_, num) => ({
        value: (num + 1).toString(),
        label: `${num + 1}`,
    }));
    return days;
};

export const getReservationStatusSelectOptions = (): Array<SelectOption<string>> =>
    (Object.keys(ReservationStatusText) as Array<keyof typeof ReservationStatusText>).map(
        (reservationStatus: keyof typeof ReservationStatusText): SelectOption<string> => ({
            label: capitalize(lowerCase(reservationStatus)),
            value: ReservationStatus[reservationStatus].toString(),
        })
    );

export const getReservationTypeSelectOptions = (): Array<SelectOption<string>> =>
    (Object.keys(ReservationType) as Array<keyof typeof ReservationType>).map(
        (reservationType: keyof typeof ReservationType): SelectOption<string> => ({
            label: reservationType,
            value: reservationType,
        })
    );

export const getResortList = async (): Promise<Array<SelectOption<string>>> => {
    const ops = await getAdminMxtsEnv();
    const resorts = (await MxtsApi.resorts(ops, { size: MXTS_CONSTANTS.MAX_RESULTS })).content;
    return resorts.map((resort: Resort) => ({
        value: resort.resortId.toString(),
        label: resort.name,
    }));
};

export const getRegionList = async (): Promise<Array<SelectOption<string>>> => {
    const ops = await getAdminMxtsEnv();
    const regions = (await MxtsApi.regions(ops, { size: MXTS_CONSTANTS.MAX_RESULTS, returnGeoShapes: false })).content;
    return regions.map((region: Region) => ({
        value: region.regionId.toString(),
        label: region.name,
    }));
};

export const getAmentiesList = async (): Promise<Array<SelectOption<string>>> => {
    const ops = await getAdminMxtsEnv();
    const amenities = (await MxtsApi.amenities(ops, { size: MXTS_CONSTANTS.MAX_RESULTS })).content;
    const options = amenities.map((amenity: Amenity) => ({
        value: amenity.identifier,
        label: amenity.name,
    }));
    return options;
};

export const getAccoKindList = async (): Promise<Array<SelectOption<string>>> => {
    const ops = await getAdminMxtsEnv();
    const accoKinds = (await MxtsApi.accommodationkinds(ops, { size: MXTS_CONSTANTS.MAX_RESULTS })).content;
    return accoKinds.map((accoKind: AccoKind) => ({
        value: accoKind.accommodationkindId.toString(),
        label: accoKind.name,
    }));
};

export const getAccommodationTypesList = async (): Promise<Array<SelectOption<string>>> => {
    const env = await getAdminMxtsEnv();
    const accommodationTypes = (
        await MxtsApi.accommodationTypes(env, {
            size: MXTS_CONSTANTS.MAX_RESULTS,
        })
    ).content;
    return accommodationTypes.map((accommodationType: Resource) => ({
        value: accommodationType.resourceId.toString(),
        label: accommodationType.name,
    }));
};

export const getUnitsList = async (): Promise<Array<SelectOption<string>>> => {
    const env = await getAdminMxtsEnv();
    const units = (await MxtsApi.units(env, { size: MXTS_CONSTANTS.MAX_RESULTS })).content;
    return units.map((unit: Unit) => ({
        value: unit.unitId.toString(),
        label: unit.name,
    }));
};

export const getReservationCategoryList = async (): Promise<Array<SelectOption<string>>> => {
    const env = await getAdminMxtsEnv();
    const reservationCategories = (await MxtsApi.getReservationCategories(env, { size: MXTS_CONSTANTS.MAX_RESULTS })).content;
    return reservationCategories.map((category: ReservationCategory) => ({
        value: category.reservationCategoryId.toString(),
        label: category.name,
    }));
};

export const getContractsTypesList = async (): Promise<Array<SelectOption<string>>> => {
    const env = await getAdminMxtsEnv();
    const contractTypes = (await MxtsApi.getContractsTypes(env, { size: MXTS_CONSTANTS.MAX_RESULTS })).content;
    return contractTypes.map((contractType: ContractType) => ({
        value: contractType.contractTypeId.toString(),
        label: contractType.name,
    }));
};

export const getResortActivityList = async (): Promise<Array<SelectOption<string>>> => {
    const env = await getAdminMxtsEnv();
    const activities = (await MxtsApi.getActivities(env, { size: MXTS_CONSTANTS.MAX_RESULTS })).content;
    return activities.map((activity: ActivityResponse) => ({
        value: activity.resortActivityId.toString(),
        label: activity.title,
    }));
};

export const getSubjectList = async (): Promise<Array<SelectOption<string>>> => {
    const env = await getAdminMxtsEnv();
    const subjects = (await MxtsApi.subjects(env, { types: ["PERSON", "PET"] })).content;
    return subjects.map((subject: Subject) => ({
        value: subject.subjectId.toString(),
        label: subject.name,
    }));
};

export const getRateTypes = async (): Promise<Array<SelectOption<string>>> => {
    const env = await getAdminMxtsEnv();
    const currentSite = (window as typeof window & { currentSite?: Site & WithId }).currentSite;
    const homepageUrl = new URL((window as any).pageUrl);
    const locationHostname = homepageUrl.host;
    const site = currentSite && currentSite.host === locationHostname ? currentSite : await SiteApi.findByHost({ host: locationHostname, projection: { sitemap: 0 } });
    const cmsOptions = await getCMSOptions(CmsApi);
    const locale = await LocaleApi.findByCode({ code: getLocaleCodeFromCMSOptions(cmsOptions) });
    const localeContent = site && site.localizedOptions && site.localizedOptions.find((lc) => lc.locale === locale?._id);
    const distributionChannelId = localeContent && localeContent.distributionChannelId;
    const rateTypesFromCurrentDc = distributionChannelId ? (await MxtsApi.rateTypesPerDC(env, { dcId: [+distributionChannelId], size: MXTS_CONSTANTS.MAX_RESULTS })).content : undefined;
    const rateTypeIds = rateTypesFromCurrentDc?.map((rtdc) => rtdc.rateTypeId);
    const rateTypes = (
        await MxtsApi.rates(env, {
            rateTypeId: [...Array.from(new Set(rateTypeIds))],
        })
    ).content;
    return rateTypes.map((rate: RateTypes) => ({
        value: rate.rateTypeId.toString(),
        label: rate.name,
    }));
};

export const getDaysList = (): Array<SelectOption<string>> => {
    // Generate the list of days
    const days = Array.from({ length: 31 }, (_, num) => ({
        value: (num + 1).toString(),
        label: `${num + 1} ${num + 1 === 1 ? getI18nLocaleString(namespaceList.admin, "day") : getI18nLocaleString(namespaceList.admin, "days")}`,
    }));
    return days;
};

export const reduceBooleanArrayWithOperators = (array: Array<FilteredSet | undefined>) =>
    array.reduce((acc: boolean, curr: FilteredSet) => {
        const { value, operator } = curr;

        if (operator === ConditionalSetSelect.OR) {
            return acc || value;
        } else if (operator === ConditionalSetSelect.AND) {
            return acc && value;
        }
        return value; // For the first element or when operator is undefined
    }, array[0]?.value);

export const evaluate = (param1?: boolean | string[], param2?: boolean | string | moment.Moment, operator?: string) => {
    let evaluation = false;
    if (operator) {
        if (typeof param1 === "boolean") {
            switch (operator) {
                case ConditionalSetSelect.AND:
                    return !!(param1 && param2);
                case ConditionalSetSelect.OR:
                    return !!(param1 || param2);
            }
        } else {
            param1?.forEach((item) => {
                if (!evaluation) {
                    switch (operator) {
                        case ConditionalSetSelect.EQUAL_TO:
                            evaluation = item === param2;
                            break;
                        case ConditionalSetSelect.NOT_EQUAL_TO:
                            evaluation = item !== param2;
                            break;
                        case ConditionalSetSelect.BEFORE:
                            evaluation = (param2 && item >= param2) || false;
                            break;
                        case ConditionalSetSelect.AFTER:
                            evaluation = (param2 && item <= param2) || false;
                            break;
                    }
                }
            });
            return evaluation;
        }
    }
};

export const daysRestrictions = (date: string | undefined, arrivalDay: string, conditionalOperator: string | undefined) => {
    if (conditionalOperator === ConditionalSetSelect.BEFORE) {
        const beforeDeadLineDate = moment(date, DATE_FORMAT.DEFAULT).subtract(Number(arrivalDay), "day");
        return beforeDeadLineDate.isValid() ? beforeDeadLineDate.format(DATE_FORMAT.MXTS) : undefined;
    }
    if (conditionalOperator === ConditionalSetSelect.AFTER) {
        const afterDeadLineDate = moment(date, DATE_FORMAT.DEFAULT).add(Number(arrivalDay), "day");
        return afterDeadLineDate.isValid() ? afterDeadLineDate.format(DATE_FORMAT.MXTS) : undefined;
    }
};
