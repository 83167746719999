import { ReservationAction, ReservationActionType } from "../actions/reservationAction";
import { ReservationResult, ReservedResourceResult } from "@maxxton/cms-mxts-api";

export interface ReservationState {
    customer?: any;
    reservation?: ReservationResult;
    reservedResource?: ReservedResourceResult | ReservedResourceResult[];
    payLaterResourceId?: number;
    creating?: boolean;
    error?:
        | { name: string; message: string; stack?: string }
        | {
              status: number;
              statusText: string;
              errorBody?: {
                  uniqueId: string;
                  timestamp: string;
                  errorCode: string;
                  message: string;
                  path: string;
                  parameters?: any;
              };
          };
}

export const reservationReducer = (state: ReservationState = {}, action: ReservationAction) => {
    switch (action.actionType) {
        case ReservationActionType.setPayLaterResource: {
            const { payLaterResourceId } = action.payload;
            return { ...state, payLaterResourceId };
        }
        case ReservationActionType.creating:
            return { ...state, customer: undefined, reservation: undefined, reservedResource: undefined, creating: true, error: undefined };
        case ReservationActionType.created:
            return { ...state, ...action.payload, creating: false };
        case ReservationActionType.error:
            return { error: action.payload.error };
        default:
            return state;
    }
};
