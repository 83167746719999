import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { AccommodationType, Unit, getAdminMxtsEnv, getMxtsEnv } from "../../mxts";
import { ConditionalSetApi, Site, Template, TemplateApi, WebContent, WebContentApi, WithId } from "@maxxton/cms-api";
import { ConditionalSetData, checkConditionalSet } from "../../../form-specs/models/conditionalSet";
import { ConditionalSetSelect, ContentType } from "../../../components/components.enum";
import { FlexboxChildOptions, WidgetOptions, widgetOptionsForm } from "./";
import { MyEnvReservation, MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { PermissionType, ReservationResult, Resort } from "@maxxton/cms-mxts-api";
import ReactIdSwiper, { ReactIdSwiperProps } from "react-id-swiper";
import { checkVisibilityOnPublishExpirationDate, getHideWidgetClass, getValidationMessage, isClientLoggedIn } from "../../../components/utils";
import { getCustomerIdsFromLoginToken, getSelectedReservationId } from "../../../redux/reducers/myEnv/myEnv.util";
import { getI18nLocaleString, getI18nLocaleStringFromParams } from "../../../i18n";
import { getInlineFlexboxStyles, getPreconfiguredBorderClassnames, getPreconfiguredColClassnames, getPreconfiguredFlexboxChildClassnames, getSpacingControl } from "../../../utils/flexboxOptions.util";
import {
    loadWidgetOptionsFromLocalStorage,
    saveWidgetOptionsInLocalStorage,
    setResetStatusOnOptionsUpdate,
    updateFrontendEditingButtonStatus,
} from "../../../components/frontend-editing/frontendEdit.utils";

import { Activity } from "../activityPlanner/activityPlanner.types";
import { Alerts } from "../../../alerts";
import { Button } from "reactstrap";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ConditionalSetDynamicFilterList } from "../../../components/generic-form/ConditionalSetGroupInput/utils/conditionalSet.util";
import { CrpProps } from "../../dynamic/typesearchContainer/TypesearchContainerWidget";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { EditMode } from "../../../components/generic-form/form.types";
import { Element } from "react-scroll";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { FrontendPageEditAction } from "../../../redux/actions/frontendPageEditAction";
import { FrontendPageEditState } from "../../../redux/reducers/frontendPageEditReducer";
import { GenericFormModal } from "../../../components/generic-form";
import { InputSpecType } from "../../../form-specs";
import { Loader } from "../../../components/Loader";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MXTS } from "../../../utils/constants";
import { OverviewPanelChildOptions } from "../../dynamic/overview-panel";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { ReservationContainerCRPProps } from "../../dynamic/reservation/container/reservationContainer.types";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { SessionState } from "../../../redux/reducers/sessionReducer";
import { SettingsOutlined } from "@mui/icons-material";
import { State } from "../../../redux";
import { StringUtil } from "../../../utils/string.util";
import { TemplateWidget } from "../template/Template";
import { WebContent as WebContentWidget } from "../web-content/WebContent";
import { isEqual as _isEqual } from "lodash";
import { connect } from "react-redux";
import { getCMSOptions } from "../../settings";
import getDeviceWidth from "../../../utils/devicewidth.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isClientSide } from "../../../utils/generic.util";
import { isMyEnvAuthTokenExpired } from "../../../utils/authToken.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { removeSelfNestedTemplates } from "../template";
import { renderPageWidgets } from "../../";

const GenericInput = loadable(() => loadableRetry(() => import("../../../components/generic-form/input")), {
    resolveComponent: ({ GenericInput }) => GenericInput,
});

const Swiper = loadable(() => loadableRetry(() => import("react-id-swiper")), {
    resolveComponent: (Swiper) => Swiper.default as React.ForwardRefExoticComponent<any>,
});

interface FlexboxStoreProps {
    sessionState?: SessionState;
    frontendPageEditState: FrontendPageEditState;
    dynamicFilter?: DynamicFilter;
    myEnvState?: MyEnvState;
}
interface FlexboxDispatchProps {
    dispatchAction: Dispatch<FrontendPageEditAction>;
}
export interface FlexboxChildElement {
    type: InputSpecType;
    variable?: string;
    element: JSX.Element;
    el?: JSX.Element;
    options: FlexboxChildOptions;
    enableReadOnly?: boolean;
}
interface FlexboxProps extends FlexboxStoreProps, CrpProps, FlexboxDispatchProps, ReservationContainerCRPProps, PageWidgetBaseProps<WidgetOptions> {
    id: string;
    children: Array<{ element: JSX.Element; options: FlexboxChildOptions; _id: string }>;
    className?: string;
    left: string;
    right: string;
    resort?: Resort;
    unit?: Unit;
    activity?: Activity;
    handleTimeSelection?: (oldResourceActivityDetailsId: number, newResourceActivityDetailsId: number) => void;
    context: CMSProvidedProperties;
    rateTypeId?: number;
    unitBookUri?: string;
    dynamicFilter?: DynamicFilter;
    childOptions?: OverviewPanelChildOptions;
    mode?: EditMode;
    root?: any;
    item?: any;
    initialItem?: any;
    onChange?: (newVal: any, prop: any, formModalEdit?: string) => void;
    friendlyUrl?: string;
    alerts?: Alerts;
    validate?: (id: string, isValid: boolean) => void;
    validations?: {
        [id: string]: {
            isValid: boolean;
            message?: string;
        };
    };
    permission: PermissionType;
    sites: Array<Site & WithId>;
    flexboxInsideForm?: boolean;
    formChildOptions?: any[];
    esReservationResult?: MyEnvReservation;
}

interface FlexboxState {
    index: number;
    setSticky: string;
    getStickyId: string;
    displaycardcount: number;
    siteZone: string;
    cmsSettingZone: string;
    animProp: string;
    disableWidget: boolean;
    slidePosition: number;
    stickyElementHeight: number;
    isConditionalOptions: boolean;
    referrerCondition: boolean;
    isAdmin?: boolean;
    isLoggedIn: boolean;
    isMyEnvUserLoggedIn: boolean;
    isOpened: boolean;
    isModalOpen: boolean;
    options: WidgetOptions;
    content?: (WebContent & WithId) | null;
    template?: (Template & WithId) | null;
    templateChildren?: JSX.Element[];
    conditionalSetFallbackWebContent?: (WebContent & WithId) | null;
    conditionalSetFallbackTemplate?: (Template & WithId) | null;
    conditionalSetFallbackTemplateChildren?: JSX.Element[];
    isFrontEndEditable?: boolean;
    isLoading?: boolean;
    isObjectSelectionAllowed?: boolean;
    allConditionalSetData?: ConditionalSetData[];
    contractTypeIds?: number[];
    reservation?: ReservationResult;
    deviceWidth: number;
}
export class Flexbox extends React.PureComponent<FlexboxProps, FlexboxState> {
    private item: any;
    private items: any;
    private pos: number;
    private slideWidth: number;
    private static currentOpenModal: Flexbox | null = null;
    // Swiper instance
    private swiper: typeof ReactIdSwiper = React.createRef();
    private cancelObtainSites: () => void = () => undefined;

    constructor(props: FlexboxProps) {
        super(props);
        this.state = {
            index: 0,
            setSticky: "",
            getStickyId: "",
            displaycardcount: props.options.displaycardcount,
            siteZone: "",
            cmsSettingZone: "",
            animProp: "",
            disableWidget: true,
            slidePosition: 0,
            stickyElementHeight: 0,
            isConditionalOptions: false,
            referrerCondition: false,
            isOpened: false,
            isModalOpen: false,
            isLoggedIn: false,
            isMyEnvUserLoggedIn: false,
            options: props.options,
            content: null,
            template: null,
            isFrontEndEditable: props.frontendPageEditState.isFrontEndEditable,
            isLoading: false,
            isObjectSelectionAllowed: true,
            deviceWidth: getDeviceWidth(),
        };
        this.pos = 0;
        this.slideWidth = 0;
    }

    /* jscpd:ignore-start */
    // eslint-disable-next-line max-lines-per-function
    public componentDidMount() {
        const { options } = this.state;
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.setState({ displaycardcount: this.calculateDisplayCount() });
        if (isClientSide()) {
            this.setState({
                isAdmin: !!document.querySelector(".backend"),
            });
        }
        this.setState({ isLoading: true });
        if (options.showConditionalContent && options.useConditionalSets) {
            this.handleConditionalSets();
            this.obtainReservation();
        }
        const optionsFromLocalStorage = loadWidgetOptionsFromLocalStorage(this.props.id, options);
        this.setState({ options: optionsFromLocalStorage as WidgetOptions });
        const getStickyId = options.setStickyId ? options.setStickyId : "";
        if (getStickyId || options.showAnimate) {
            let originalFlexboxTop = 0;
            const windowObject = window;
            const sticky = document.getElementsByClassName(getStickyId) ? document.getElementsByClassName(getStickyId).item(0) : null;

            const stickyFlexbox = document.getElementsByClassName(`sticky-flexbox-${getStickyId}`) ? document.getElementsByClassName(`sticky-flexbox-${getStickyId}`).item(0) : null;

            const animateElement = document.getElementsByClassName(`animate-id${this.props.id}`) ? document.getElementsByClassName(`animate-id${this.props.id}`).item(0) : null;
            if (animateElement) {
                const animate = animateElement.getBoundingClientRect();
                const wH = window.innerHeight;
                const y = animate.top;
                if (y > 0 && wH >= y) {
                    const animProp = options.animateProperties;
                    this.setState({ animProp });
                }
            }
            let stickyOriginalWidth = 0;
            if (sticky && stickyFlexbox) {
                stickyOriginalWidth = sticky.getBoundingClientRect().width;
                const stickyHeight = sticky.getBoundingClientRect().height;
                stickyFlexbox.setAttribute("style", `height: ${stickyHeight}px; width: ${stickyOriginalWidth}px;`);
            }

            window.addEventListener("scroll", () => {
                const animateElementInView = document.getElementsByClassName(`animate-id${this.props.id}`) ? document.getElementsByClassName(`animate-id${this.props.id}`).item(0) : null;
                if (animateElementInView) {
                    const animateInView = animateElementInView.getBoundingClientRect();
                    const wHinView = window.innerHeight;
                    const yInView = animateInView.top;
                    if (yInView > 0 && wHinView >= yInView) {
                        const animProp = options.animateProperties;
                        this.setState({ animProp });
                    }
                }
                if (sticky && stickyFlexbox) {
                    const stickyHeight = sticky.getBoundingClientRect().height;
                    stickyFlexbox.setAttribute("style", `height: ${stickyHeight}px; width: ${stickyOriginalWidth}px;`);
                    const allStickyItems = Array.from(document.getElementsByClassName("sticky"));
                    let index = -1;
                    allStickyItems.forEach((em, ind) => {
                        if (Array.from(em.classList).includes(`sticky-flexbox-${options.setStickyId}`)) {
                            index = ind;
                        }
                    });
                    if (options.stickyStacking) {
                        let temp = 0;
                        for (let i = 0; i < index; i++) {
                            temp = temp + allStickyItems[i].getBoundingClientRect().height;
                        }
                        originalFlexboxTop = stickyFlexbox.getBoundingClientRect().top + window.pageYOffset - temp;
                        const elTopFlexbox = stickyFlexbox.getBoundingClientRect().top - temp;
                        if (windowObject.pageYOffset > elTopFlexbox || elTopFlexbox === 0) {
                            sticky.setAttribute("style", `position: fixed; top:${temp}px; width: ${stickyOriginalWidth}px;`);
                        }
                    } else {
                        originalFlexboxTop = stickyFlexbox.getBoundingClientRect().top + window.pageYOffset;
                        const elTopFlexbox = stickyFlexbox.getBoundingClientRect().top;
                        if ((windowObject.pageYOffset > originalFlexboxTop && elTopFlexbox) || elTopFlexbox === 0) {
                            sticky.setAttribute("style", "position: fixed;width: inherit;");
                        } else if (windowObject.pageYOffset > elTopFlexbox || elTopFlexbox === 0) {
                            sticky.setAttribute("style", "position: fixed;width: inherit;");
                        }
                    }
                    if (windowObject.pageYOffset < originalFlexboxTop) {
                        sticky.setAttribute("style", "position: relative;");
                    }
                }
            });
            if (sticky && stickyFlexbox) {
                const stickyHeight = sticky.getBoundingClientRect().height;
                this.setState({ stickyElementHeight: stickyHeight });
            }
            window.addEventListener("resize", () => {
                /* jscpd:ignore-start */
                if (sticky && stickyFlexbox) {
                    const stickyHeight = sticky.getBoundingClientRect().height;
                    stickyFlexbox.setAttribute("style", `height: ${stickyHeight}px; width: ${stickyOriginalWidth}px;`);
                    const allStickyItems = Array.from(document.getElementsByClassName("sticky"));
                    let index = -1;
                    allStickyItems.forEach((em, ind) => {
                        if (Array.from(em.classList).includes(`sticky-flexbox-${options.setStickyId}`)) {
                            index = ind;
                        }
                    });
                    let temp = 0;
                    for (let i = 0; i < index; i++) {
                        temp = temp + allStickyItems[i].getBoundingClientRect().height;
                    }
                    if (options.stickyStacking) {
                        originalFlexboxTop = stickyFlexbox.getBoundingClientRect().top + window.pageYOffset - temp;
                        const elTopFlexbox = stickyFlexbox.getBoundingClientRect().top - temp;
                        if (windowObject.pageYOffset > elTopFlexbox || elTopFlexbox === 0) {
                            sticky.setAttribute("style", `position: fixed; top: ${temp}px; width: ${stickyOriginalWidth}px;`);
                        }
                    } else {
                        originalFlexboxTop = stickyFlexbox.getBoundingClientRect().top + window.pageYOffset;
                        const elTopFlexbox = stickyFlexbox.getBoundingClientRect().top;
                        if (windowObject.pageYOffset > elTopFlexbox || elTopFlexbox === 0) {
                            sticky.setAttribute("style", "position: fixed; width: inherit;");
                        }
                        if (windowObject.pageYOffset > originalFlexboxTop) {
                            allStickyItems.forEach((em, ind) => {
                                if (index !== ind) {
                                    em.children[0].setAttribute("style", "position: relative;");
                                }
                            });
                        } else if (windowObject.pageYOffset > elTopFlexbox || elTopFlexbox === 0) {
                            sticky.setAttribute("style", "position: fixed;width: inherit;");
                        }
                    }

                    if (windowObject.pageYOffset < originalFlexboxTop) {
                        sticky.setAttribute("style", "position: relative;");
                    }
                }
                /* jscpd:ignore-end */
            });
        }

        if (isClientSide() && (window as any).cmsOptions) {
            const cmsOptions = (window as any).cmsOptions;
            this.setState({
                cmsSettingZone: cmsOptions.timezoneList || "",
            });
        } else {
            getCMSOptions(this.props.context.cmsApi).then((settingOptions) => {
                this.setState({ cmsSettingZone: settingOptions && settingOptions.timezoneList ? settingOptions.timezoneList : "" });
            });
        }
        this.setState({ siteZone: this.props.context.site?.timezoneList || "" });

        window.addEventListener("orientationchange", () => {
            this.pos = 0;
            if (this.item) {
                this.setTransform();
            }
        });

        this.setState({ disableWidget: !isClientLoggedIn(), isLoggedIn: isClientLoggedIn() });
        this.setState({ isMyEnvUserLoggedIn: !isMyEnvAuthTokenExpired() });

        const referrerCondition = options.useReferrerLink && !!options.externalUrl && !this.checkConditionalUsage();
        if (referrerCondition) {
            this.setState({ referrerCondition });
        }
        if (options.showConditionalContent) {
            if (options.enableConditionalPaymentStatus && options.conditionalPaymentStatus) {
                options.conditionalPaymentStatus.map(async (status) => {
                    const statusLabel = status.label && getI18nLocaleStringFromParams(status.label);
                    if (statusLabel?.toLowerCase() === this.props.dynamicFilter?.paymentStatus?.toLowerCase()) {
                        if (options.conditionalWebContentId) {
                            const webContent = await WebContentApi.findById({ id: options.conditionalWebContentId });
                            this.setState({ content: webContent });
                        }
                        if (options.conditionalTemplateId) {
                            const template = await TemplateApi.findById({ id: options.conditionalTemplateId });
                            let templateChildren;
                            if (template) {
                                const filteredRootTemplates = removeSelfNestedTemplates(template._id, template.root);
                                templateChildren = await renderPageWidgets(filteredRootTemplates, this.props.context);
                            }
                            this.setState({ template, templateChildren });
                        }
                    }
                });
            } else if (options.useForUnitSelection) {
                this.checkIfObjectSelectionIsAllowed(this.props);
            }
        }
        if (options.useConditionalSets && options.conditionalSetsItems && options.useConditionalSetsFallback) {
            this.setConditionalSetFallback();
        }
    }
    /* jscpd:ignore-end */

    public componentWillUnmount() {
        window.removeEventListener("resize", () => 0);
        window.removeEventListener("resize", this.handleResize);
        this.cancelObtainSites();
    }

    public handleResize = () => {
        this.setState({ deviceWidth: getDeviceWidth() });
    };

    public getResponsiveStyles = (childrenOptions: WidgetOptions) => {
        const { deviceWidth } = this.state;
        let width;
        if (childrenOptions.responsiveLayoutWidth) {
            if (deviceWidth < 768) {
                width = `${childrenOptions.mobileWidth}%`;
            } else if (deviceWidth < 1024) {
                width = `${childrenOptions.tabletWidth}%`;
            } else {
                width = `${childrenOptions.desktopWidth}%`;
            }
        }
        return { width };
    };

    public handleFlexBoxMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const {
            frontendPageEditState: { isFrontEndEditable },
        } = this.props;
        const { isAdmin } = this.state;
        if (!isAdmin && isFrontEndEditable) {
            const flexbox = (event.target as HTMLElement).closest(".flex-wrapper.controls-wrap");
            if (flexbox) {
                document.querySelectorAll(".flex-wrapper.controls-wrap").forEach((element: HTMLElement) => {
                    element.classList.remove("show-active-flex-controls");
                });
                flexbox.classList.add("show-active-flex-controls");
            }
        }
    };

    public handleFlexBoxMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const {
            frontendPageEditState: { isFrontEndEditable },
        } = this.props;
        const { isAdmin } = this.state;
        if (!isAdmin && isFrontEndEditable) {
            const flexbox = event.currentTarget;
            flexbox.classList.remove("show-active-flex-controls");
        }
    };

    public componentDidUpdate(prevProps: FlexboxProps) {
        const {
            frontendPageEditState: { isFrontEndEditable, isFrontendEditedWidgetsReset },
            options,
            myEnvState,
        } = this.props;
        if (!_isEqual(isFrontEndEditable, prevProps.frontendPageEditState.isFrontEndEditable)) {
            this.setState({ isFrontEndEditable });
            if (!isFrontEndEditable) {
                this.setState({ isModalOpen: false });
            }
        }
        if (isFrontendEditedWidgetsReset !== prevProps.frontendPageEditState.isFrontendEditedWidgetsReset && isFrontendEditedWidgetsReset) {
            this.setState({ options });
        }
        if (!_isEqual(myEnvState, prevProps.myEnvState) && options.showConditionalContent && options.useConditionalSets && !this.state.reservation) {
            this.obtainReservation();
        }
    }
    /* jscpd:ignore-start */
    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            children,
            className,
            accommodationType,
            unit,
            activity,
            handleTimeSelection,
            childOptions,
            unitBookUri,
            dynamicFilter,
            rateTypeId,
            amenityCodes,
            key,
            mode,
            root,
            item,
            initialItem,
            onChange,
            friendlyUrl,
            alerts,
            resort,
            dispatchOpenLinkedTabAction,
            permission,
            useCrpProps,
            sessionState,
            myEnvState,
            context: { currentLocale, site },
        } = this.props;
        const {
            cmsSettingZone,
            siteZone,
            disableWidget,
            referrerCondition,
            isConditionalOptions,
            isAdmin,
            isLoggedIn,
            isMyEnvUserLoggedIn,
            isFrontEndEditable,
            options,
            allConditionalSetData,
            contractTypeIds,
            conditionalSetFallbackWebContent,
            conditionalSetFallbackTemplate,
            conditionalSetFallbackTemplateChildren,
            reservation,
        } = this.state;
        const hideContent = options.showConditionalContent && !options.useReferrerLink ? isConditionalOptions : false;
        const hideWidget = getHideWidgetClass(options, hideContent);
        const hideWhenObjectSelectionIsNotAllowed = options.showConditionalContent && options.useForUnitSelection && !this.state.isObjectSelectionAllowed;
        if (
            (options.showContentIfMyEnvUserIsLoggedIn === "showIfLoggedIn" && !isMyEnvUserLoggedIn) ||
            (options.showContentIfMyEnvUserIsLoggedIn === "showIfNotLoggedIn" && isMyEnvUserLoggedIn) ||
            hideWidget === null ||
            (options.showConditionalContent && ((options?.useUnitStepLogic && dynamicFilter?.unitid) || (options?.useTypeStepLogic && !dynamicFilter?.unitid))) ||
            hideWhenObjectSelectionIsNotAllowed
        ) {
            return null;
        } else if (referrerCondition) {
            return null;
        }
        if (options.useConditionalSets && allConditionalSetData && dynamicFilter) {
            const isConditionsMatched = checkConditionalSet({
                allConditionalSetData,
                dynamicFilter,
                defaultVisibility: options.defaultVisibility,
                myEnvState,
                myEnvUserTypes: myEnvState?.myEnvUserTypes,
                contractIds: contractTypeIds,
                reservation,
                formItem: item,
                useFormDataInConditionalSet: options.useFormDataInConditionalSet,
            });
            if (isConditionsMatched) {
                if (options.useConditionalSetsFallback) {
                    return (
                        <React.Fragment>
                            {conditionalSetFallbackWebContent && <WebContentWidget id={conditionalSetFallbackWebContent._id} content={conditionalSetFallbackWebContent} context={this.props.context} />}
                            {conditionalSetFallbackTemplate && conditionalSetFallbackTemplateChildren && (
                                <TemplateWidget
                                    hideWidget={hideWidget}
                                    classNames={className || ""}
                                    template={conditionalSetFallbackTemplate}
                                    options={{ ...options, templateId: conditionalSetFallbackTemplate._id, layHierarchy: false }}
                                    children={conditionalSetFallbackTemplateChildren}
                                />
                            )}
                        </React.Fragment>
                    );
                }
                return null;
            }
        }
        if (options && (options.isPublishDateConfigured || options.isExpirationDateConfigured)) {
            const checkVisibilityOfContents = checkVisibilityOnPublishExpirationDate(disableWidget, cmsSettingZone, siteZone, options);
            if (!checkVisibilityOfContents) {
                return null;
            }
        }
        if (options.showConditionalContent && options.enableConditionalPaymentStatus && options.conditionalPaymentStatus) {
            return (
                <React.Fragment>
                    {this.state.content && <WebContentWidget id={this.state.content._id} content={this.state.content} context={this.props.context} />}
                    {this.state.template && this.state.templateChildren && (
                        <TemplateWidget
                            hideWidget={hideWidget}
                            classNames={className || ""}
                            template={this.state.template}
                            options={{ ...options, templateId: this.state.template._id, layHierarchy: false }}
                            children={this.state.templateChildren}
                        />
                    )}
                </React.Fragment>
            );
        }
        const modal =
            isFrontEndEditable && options ? (
                <GenericFormModal
                    isOpen={this.state.isModalOpen}
                    label="front-edit-modal front-flexbox-modal"
                    toggle={this.toggleModal}
                    title={getI18nLocaleString(namespaceList.admin, "edit")}
                    spec={widgetOptionsForm}
                    mode="admin_edit"
                    value={options}
                    rootValue={options}
                    onSave={this.handleOnSave}
                    onChange={this.handleChange}
                    // TODO: pass apprpriate permission received by WebContent
                    permission={PermissionType.EXECUTE}
                />
            ) : null;
        const animationControl = `${options.showAnimate ? "animated " + this.state.animProp + " animate-id" + this.props.id : ""}`;
        const stickyHeight = className?.includes("scrollable-results-wrapper") ? "scrollable-results-wrapper" : "";
        const filteredChildren = this.filterChildern(children, sessionState);
        const configurationBar = !isAdmin && isFrontEndEditable && (
            <div className="configuration-bar configuration-bar-flexBox" onMouseEnter={this.handleFlexBoxMouseEnter} onClick={this.handleFlexBoxMouseEnter}>
                <div className="mcms-tooltip-wrap">
                    <a onClick={this.handleFlexboxEdit}>
                        <SettingsOutlined />
                        <span className="mcms-tooltip mcms-tooltip--left">{getI18nLocaleString(namespaceList.admin, "changeFrontFlexBox")}</span>
                    </a>
                </div>
            </div>
        );
        const content = filteredChildren.map((child, ind) => (
            <div
                key={ind}
                // eslint-disable-next-line max-len

                className={classNames("flex-child plugin", getPreconfiguredFlexboxChildClassnames(child.options), {
                    "list-grid-map-view": className?.includes("list-grid-map-container"),
                    "grids": !className?.includes("list-grid-map-container"),
                    "scrollable-results-wrapper": !!stickyHeight,
                })}
                // eslint-disable-next-line max-len
                data-toggle-element={
                    accommodationType
                        ? `${child.options.toggleBlockName}-${(accommodationType as AccommodationType).resourceId}`
                        : unit
                        ? `${child.options.toggleBlockName}-${(unit as Unit).unitId}`
                        : activity
                        ? `${child.options.toggleBlockName}-${(activity as Activity).resourceActivityDetailsId}`
                        : (child.options ? child.options.toggleBlockName && child.options.toggleBlock : "")
                        ? child.options.toggleBlockName
                        : ""
                }
            >
                <ErrorBoundary>
                    {accommodationType || unit || resort || activity ? (
                        React.cloneElement(child.element, {
                            accommodationType,
                            rateTypeId,
                            unit,
                            activity,
                            resort,
                            unitBookUri,
                            dynamicFilter,
                            amenityCodes,
                            dispatchOpenLinkedTabAction,
                            useCrpProps,
                            handleTimeSelection,
                        })
                    ) : childOptions ? (
                        React.cloneElement(child.element, { childOptions })
                    ) : (child as FlexboxChildElement).variable !== undefined ? (
                        <div className="input-container" key={"" + (child as FlexboxChildElement).variable}>
                            {options.overrideReadOnly && ((child as FlexboxChildElement).enableReadOnly = false)}
                            <GenericInput
                                spec={child as FlexboxChildElement}
                                key={key}
                                mode={mode!}
                                permission={permission}
                                root={root}
                                item={item}
                                initialItem={initialItem}
                                value={(child as FlexboxChildElement).variable ? item[(child as any).variable] : undefined}
                                onChange={onChange!}
                                friendlyUrl={friendlyUrl}
                                alerts={alerts}
                                validate={this.props.validate}
                            />
                            {getValidationMessage(this.props, child)}
                        </div>
                    ) : (
                        child?.element || child?.el || child
                    )}
                </ErrorBoundary>
            </div>
        ));
        const dataToggleElement = options.toggleBlockName + "-" + (accommodationType?.resourceId || unit?.unitId || activity?.resourceActivityDetailsId);
        const stickyComponent = (
            <Element
                name={StringUtil.convertToDocumentQuerySelectable(options.name)?.toLowerCase() || ""}
                id={StringUtil.convertToDocumentQuerySelectable(options.name)?.toLowerCase()}
                data-toggle-element={options.toggleBlock ? dataToggleElement : ""}
                // eslint-disable-next-line max-len
                className={classNames("flex-wrapper controls-wrap", options.setStickyId, className, options.borderRadius, animationControl, hideWidget, backgroundClassnames(options), {
                    ["background-color-" + options.background]: options.background?.includes("theme"),
                    "flex-show-controls": isLoggedIn,
                    "show-controls": isLoggedIn && isFrontEndEditable,
                    [options.toggleType]: options.toggleBlock && !!options.toggleType,
                    "simpleToggle": options.toggleBlock,
                    "toggle-block-hidden": options.toggleBlock && !!options.toggleBlockName,
                })}
                style={inlineFlexBoxStyle(options)}
                onMouseEnter={this.handleFlexBoxMouseEnter}
                onMouseLeave={this.handleFlexBoxMouseLeave}
                // eslint-disable-next-line max-len
            >
                {isLoggedIn && configurationBar}
                <div
                    // eslint-disable-next-line max-len
                    className={classNames(
                        "plugin plugin-flexbox",
                        "direction-" + options.direction,
                        "spacing-" + options.spacing,
                        "type-" + options.type,
                        options.showMoment,
                        getPreconfiguredBorderClassnames(options),
                        getSpacingControl(options),
                        options.rowGap,
                        {
                            "flex-no-wrap": options.flexWrap,
                        }
                    )}
                    style={getInlineFlexboxStyles(options)}
                >
                    {content}
                </div>
            </Element>
        );

        if (options.type === "slide") {
            return this.renderSlider(hideWidget);
        }
        if (options.flexType === "flex-grid") {
            return (
                <React.Fragment>
                    {this.renderFlexGrid(hideWidget)}
                    {modal}
                </React.Fragment>
            );
        }
        return options.setStickyId ? (
            // eslint-disable-next-line max-len
            <div
                style={stickyHeight === "scrollable-results-wrapper" ? { height: `calc(100vh - ${this.state.stickyElementHeight}px)` } : undefined}
                className={`sticky sticky-flexbox-${options.setStickyId} ${hideWidget} ${stickyHeight === "scrollable-results-wrapper" ? "scrollable-results" : ""}`}
            >
                {stickyComponent}
                {modal}
            </div>
        ) : (
            <React.Fragment>
                {stickyComponent}
                {modal}
            </React.Fragment>
        );
    }
    // eslint-disable-next-line max-lines-per-function
    private renderFlexGrid(hideWidget: string) {
        const {
            children,
            accommodationType,
            unit,
            activity,
            handleTimeSelection,
            unitBookUri,
            dynamicFilter,
            childOptions,
            className,
            amenityCodes,
            rateTypeId,
            key,
            mode,
            root,
            item,
            initialItem,
            onChange,
            friendlyUrl,
            alerts,
            resort,
            dispatchOpenLinkedTabAction,
            permission,
            useCrpProps,
            sessionState,
            flexboxInsideForm,
            formChildOptions,
        } = this.props;
        const { isAdmin, isFrontEndEditable, isLoggedIn, options } = this.state;
        const animationControl = `${options.showAnimate ? "animated" + this.props.id + "" + this.state.animProp : ""}`;
        const stickyHeight = className?.includes("scrollable-results-wrapper") ? "scrollable-results-wrapper" : "";
        const filteredChildren = this.filterChildern(children, sessionState);
        const customPaddingTopWithpixels = options.customPaddingTop + "px";
        const customPaddingBottomWithpixels = options.customPaddingBottom + "px";
        const customSpacingBelowContainer = options.customSpacingBelowContainer + "px";
        const configurationBar = !isAdmin && isFrontEndEditable && (
            <div className="configuration-bar configuration-bar-flexBox">
                <div className="mcms-tooltip-wrap">
                    <a onClick={this.handleFlexboxEdit}>
                        <SettingsOutlined />
                        <span className="mcms-tooltip mcms-tooltip--left">{getI18nLocaleString(namespaceList.admin, "changeFrontFlexBox")}</span>
                    </a>
                </div>
            </div>
        );
        return (
            <div
                // eslint-disable-next-line max-len
                id={StringUtil.convertToDocumentQuerySelectable(options.name)?.toLowerCase()}
                className={classNames(
                    "flex-wrapper controls-wrap",
                    options.setStickyId,
                    className,
                    options.borderRadius,
                    animationControl,
                    hideWidget,
                    "spacing-" + options.spacing,
                    getPreconfiguredBorderClassnames(options),
                    backgroundClassnames(options),
                    {
                        ["background-color-" + options.background]: options.background?.includes("theme"),
                        "flex-show-controls": isLoggedIn,
                        "show-controls": isLoggedIn && isFrontEndEditable,
                        [options.toggleType]: options.toggleBlock && !!options.toggleType,
                        "simpleToggle": options.toggleBlock,
                        "toggle-block-hidden": options.toggleBlock && !!options.toggleBlockName,
                        "flex-no-wrap": options.flexWrap,
                    }
                )}
                style={inlineFlexBoxStyle(options)}
                onMouseEnter={this.handleFlexBoxMouseEnter}
                onMouseLeave={this.handleFlexBoxMouseLeave}
                data-toggle-element={options.toggleBlockName && options.toggleBlock ? options.toggleBlockName : ""}
            >
                {isLoggedIn && configurationBar}

                <div
                    // eslint-disable-next-line max-len
                    // Leaving a comment as this seems unsused: type-${options.type}
                    className={classNames("plugin plugin-flexbox", "direction-" + options.direction, options.showMoment, getSpacingControl(options))}
                    style={{
                        height: stickyHeight === "scrollable-results-wrapper" ? `calc(100vh - ${this.state.stickyElementHeight}px)` : undefined,
                        borderColor: options.addBorder && options.borderColor?.includes("rgba") ? options.borderColor : undefined,
                        ...(options.useCustomSpacingValues && options.customPaddingTop && { paddingTop: customPaddingTopWithpixels }),
                        ...(options.useCustomSpacingValues && options.customPaddingBottom && { paddingBottom: customPaddingBottomWithpixels }),
                        ...(options.useCustomSpacingValues && options.customSpacingBelowContainer && { marginBottom: customSpacingBelowContainer }),
                    }}
                >
                    <div
                        // eslint-disable-next-line max-len
                        className={classNames("plugin plugin-columns row", "spacing-" + options.spacing, options.columnGap, {
                            "flex-no-wrap": options.flexWrap,
                            "no-gutters": options.noGutters,
                        })}
                    >
                        {filteredChildren.map((child, ind) => {
                            const childrenOptions: WidgetOptions | undefined = flexboxInsideForm && !child.options && formChildOptions ? formChildOptions[ind] : child.options;
                            const preconfiguredColClass = !childrenOptions?.responsiveLayoutWidth ? getPreconfiguredColClassnames(childrenOptions) : "";
                            return (
                                <div
                                    key={ind}
                                    // eslint-disable-next-line max-len
                                    style={
                                        childrenOptions && childrenOptions.responsiveLayoutWidth
                                            ? {
                                                  ...this.getResponsiveStyles(childrenOptions),
                                              }
                                            : {}
                                    }
                                    className={classNames("flex-child flex-wrapper__items", getPreconfiguredFlexboxChildClassnames(childrenOptions), preconfiguredColClass, {
                                        "list-grid-map-view": className?.includes("list-grid-map-container"),
                                        "grids": !className?.includes("list-grid-map-container"),
                                        "scrollable-results-wrapper": !!stickyHeight,
                                    })}
                                    // eslint-disable-next-line max-len
                                    data-toggle-element={
                                        accommodationType
                                            ? `${childrenOptions?.toggleBlockName}-${(accommodationType as AccommodationType).resourceId}`
                                            : unit
                                            ? `${childrenOptions?.toggleBlockName}-${(unit as Unit).unitId}`
                                            : activity
                                            ? `${childrenOptions?.toggleBlockName}-${(activity as Activity).resourceActivityDetailsId}`
                                            : (childrenOptions ? childrenOptions.toggleBlockName && childrenOptions.toggleBlock : "")
                                            ? childrenOptions?.toggleBlockName
                                            : ""
                                    }
                                >
                                    {accommodationType || unit || resort || activity ? (
                                        React.cloneElement(child.element, {
                                            accommodationType,
                                            rateTypeId,
                                            unit,
                                            activity,
                                            handleTimeSelection,
                                            resort,
                                            unitBookUri,
                                            dynamicFilter,
                                            amenityCodes,
                                            dispatchOpenLinkedTabAction,
                                            useCrpProps,
                                        })
                                    ) : childOptions ? (
                                        React.cloneElement(child.element, { childOptions })
                                    ) : (child as FlexboxChildElement).variable !== undefined ? (
                                        <div className="input-container" key={"" + (child as FlexboxChildElement).variable}>
                                            {options.overrideReadOnly && ((child as FlexboxChildElement).enableReadOnly = false)}
                                            <GenericInput
                                                spec={child as FlexboxChildElement}
                                                key={key}
                                                mode={mode!}
                                                permission={permission}
                                                root={root}
                                                item={item}
                                                initialItem={initialItem}
                                                value={(child as FlexboxChildElement).variable ? item[(child as any).variable] : undefined}
                                                onChange={onChange!}
                                                friendlyUrl={friendlyUrl}
                                                alerts={alerts}
                                                validate={this.props.validate}
                                            />
                                            {getValidationMessage(this.props, child)}
                                        </div>
                                    ) : (
                                        child?.element || child?.el || child
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    // eslint-disable-next-line max-lines-per-function
    private renderSlider(hideWidget: string) {
        const {
            children,
            accommodationType,
            unit,
            activity,
            handleTimeSelection,
            unitBookUri,
            dynamicFilter,
            childOptions,
            className,
            amenityCodes,
            rateTypeId,
            key,
            mode,
            root,
            item,
            initialItem,
            onChange,
            friendlyUrl,
            alerts,
            resort,
            dispatchOpenLinkedTabAction,
            permission,
            context: { currentLocale, site },
            useCrpProps,
            sessionState,
        } = this.props;
        const { displaycardcount, isLoading, options } = this.state;
        const sliderParams: ReactIdSwiperProps = {
            slidesPerView: options.slidesPerView ?? 1,
            loopFillGroupWithBlank: true,
            centerInsufficientSlides: true,
            lazy: true,
            preloadImages: false,
        };

        if (options.sneakPreview) {
            sliderParams.scrollbar = {
                el: ".swiper-scrollbar",
                draggable: true,
                snapOnRelease: true,
            };
            sliderParams.breakpoints = {
                1024: {
                    slidesPerView: (+options.slidesPerView || 1) + 0.5,
                },
                768: {
                    slidesPerView: 2.5,
                },
                320: {
                    slidesPerView: 1.5,
                },
            };
        }
        if (options.displayDots) {
            sliderParams.pagination = {
                el: ".swiper-pagination",
                clickable: true,
            };
        }
        let indicators: JSX.Element = <div />;
        if (options.displayDots) {
            indicators = (
                <ul className="slide-jumps">
                    {children.map((child, index) => (
                        <li className="slide-dots" onClick={this.jumpSlide.bind(this, index)} key={index}>
                            <a className={classNames({ dots: true, active: index === this.state.slidePosition })} />
                        </li>
                    ))}
                </ul>
            );
        }
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const containerCardscount = options.displaycardcount;
        const animationControl = `${options.showAnimate ? "animated" + this.props.id + "" + this.state.animProp : ""}`;
        const filteredChildren = this.filterChildern(children, sessionState);
        const sliderContent = filteredChildren.map((child, ind) => (
            <div
                ref={(input) => {
                    this.item = input;
                }}
                key={ind}
                className={`flex-child item slide-${ind}-${this.props.id.slice(-5)} `}
            >
                {accommodationType || unit || resort || activity ? (
                    React.cloneElement(child.element, {
                        accommodationType,
                        rateTypeId,
                        unit,
                        activity,
                        handleTimeSelection,
                        resort,
                        unitBookUri,
                        dynamicFilter,
                        amenityCodes,
                        dispatchOpenLinkedTabAction,
                        useCrpProps,
                    })
                ) : childOptions ? (
                    React.cloneElement(child.element, { childOptions })
                ) : (child as FlexboxChildElement).variable !== undefined ? (
                    <div className="input-container" key={"" + (child as FlexboxChildElement).variable}>
                        <GenericInput
                            spec={child as FlexboxChildElement}
                            key={key}
                            mode={mode!}
                            permission={permission}
                            root={root}
                            item={item}
                            initialItem={initialItem}
                            value={(child as FlexboxChildElement).variable ? item[(child as any).variable] : undefined}
                            onChange={onChange!}
                            friendlyUrl={friendlyUrl}
                            alerts={alerts}
                            validate={this.props.validate}
                        />
                        {getValidationMessage(this.props, child)}
                    </div>
                ) : (
                    child?.element || child?.el || child
                )}
            </div>
        ));
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.fontColor?.includes("theme") ? `color-${options.fontColor}` : ""}`),
            style: options.fontColor?.includes("rgba") ? options.fontColor : "",
        };
        if (options.useAdvancedSlider && isLoading) {
            return (
                <div className="slider">
                    <LocalizedTitleAndLabel {...titleOptions} />
                    <div className={"slideshow-container slideshow-advanced"}>
                        <Swiper {...sliderParams} className="slideShow-wrapper" ref={this.swiper}>
                            {sliderContent}
                        </Swiper>
                        {children.length > 1 ? (
                            <div
                                className={`positioned-swiper-arrows ${options?.arrowPosition || "arrowCenter"} ${options.arrowsTogether ? "arrows-together" : "arrows-apart"} ${
                                    options.arrowsTogether && options.arrowsHorizontalPosition ? `align-h-${options.arrowsHorizontalPosition}` : ""
                                } ${options.arrowLocation === "arrowOuter" && options.arrowsOutsideSlider ? "arrowOutSide-slider" : " "}`}
                            >
                                <div
                                    onClick={this.goPrev}
                                    className={classNames("swiper-button-prev", options.arrowLocation, { ["background-color-" + options.arrowBackgroundColor]: !!options?.arrowBackgroundColor })}
                                    style={getInlineFlexboxStyles(options)}
                                />
                                <div
                                    onClick={this.goNext}
                                    className={classNames("swiper-button-next", options.arrowLocation, { ["background-color-" + options.arrowBackgroundColor]: !!options?.arrowBackgroundColor })}
                                    style={getInlineFlexboxStyles(options)}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        }
        if (options.useAdvancedSlider && !isLoading) {
            return <Loader views="slideShow" />;
        }
        return (
            <div
                // eslint-disable-next-line max-len
                className={`carousel-wrapper ${options.slideboxcount === 1 ? "single-slider" : "multi-card-slider"} ${options.arrowLocation ? options.arrowLocation : "arrowOuter"} ${
                    options.setStickyId ? options.setStickyId : "no"
                } ${className} ${
                    options.background?.includes("theme-") ? `background-color-${options.background}` : options.background && options.background.indexOf("rgba") === -1 ? options.background : "no"
                } ${animationControl} ${hideWidget}`}
                style={
                    options && options.responsiveLayoutWidth
                        ? {
                              ...this.getResponsiveStyles(options),
                              ...getInlineFlexboxStyles(options),
                          }
                        : { ...getInlineFlexboxStyles(options) }
                }
            >
                <LocalizedTitleAndLabel {...titleOptions} />
                {children.length > displaycardcount && (
                    <Button
                        className={classNames("carousel--buttons left", options.arrowPosition || "arrowcenter", {
                            ["background-color-" + options.arrowBackgroundColor]: options.arrowBackgroundColor?.includes("theme"),
                        })}
                        style={getInlineFlexboxStyles(options)}
                        onClick={this.transformLeft}
                    >
                        <FontAwesome name="angle-left" />
                    </Button>
                )}
                <div className="scroller">
                    <div
                        // eslint-disable-next-line max-len
                        className={classNames("items carousel-cards-wrapper", "direction-" + options.direction, options.showMoment, getSpacingControl(options), "total-cards-" + containerCardscount)}
                        ref={(input) => (this.items = input)}
                    >
                        {
                            <Element
                                id={StringUtil.convertToDocumentQuerySelectable(options.name)?.toLowerCase()}
                                name={StringUtil.convertToDocumentQuerySelectable(options.name)?.toLowerCase() || ""}
                                className="d-flex carousel-cards-inner-wrap"
                            >
                                {sliderContent}
                            </Element>
                        }
                    </div>
                </div>
                {children.length > displaycardcount && (
                    <Button
                        className={classNames("carousel--buttons right", options.arrowPosition || "arrowcenter", {
                            ["background-color-" + options.arrowBackgroundColor]: options.arrowBackgroundColor?.includes("theme"),
                        })}
                        style={getInlineFlexboxStyles(options)}
                        onClick={this.transformRight}
                    >
                        <FontAwesome name="angle-right" />
                    </Button>
                )}
                {indicators}
            </div>
        );
    }
    /* jscpd:ignore-end */

    private setTransform = () => {
        this.slideWidth = -this.pos * this.item.offsetWidth;
        this.items.style.transform = `translate3d(${this.slideWidth}px,0,0)`;
        this.setState({ slidePosition: this.pos });
    };

    private jumpSlide = (index: number) => {
        this.pos = index;
        this.setTransform();
    };

    private calculateDisplayCount() {
        const { options } = this.state;
        let displaycardcount;
        if (window.innerWidth <= 600) {
            displaycardcount = 1;
        } else if (window.innerWidth > 600 && window.innerWidth <= 1000) {
            displaycardcount = 2;
        } else {
            displaycardcount = options.displaycardcount;
        }
        return displaycardcount;
    }

    private transformRight = () => {
        const { children } = this.props;
        const displaycardcount = this.calculateDisplayCount();
        this.pos = this.pos === children.length - displaycardcount ? 0 : this.pos + 1;
        this.setTransform();
    };

    private transformLeft = () => {
        const { children } = this.props;
        const displaycardcount = this.calculateDisplayCount();
        this.pos = this.pos === 0 ? children.length - displaycardcount : this.pos - 1;
        this.setTransform();
    };

    private obtainReservation = async () => {
        const { myEnvState, context, esReservationResult, isMyEnvWidget, dynamicFilter } = this.props;
        const env = await getAdminMxtsEnv();
        const reservationId: number | undefined = getSelectedReservationId({ esReservationResult, isMyEnvWidget, myEnvState, dynamicFilter });
        if (reservationId) {
            const reservation = await context.mxtsApi.getReservation(env, {}, [
                {
                    key: "reservationId",
                    value: reservationId,
                },
            ]);
            this.setState({ reservation });
        }
    };

    private checkConditionalUsage = () => {
        const { showConditionalContent, externalOrigin, specificSite, widgetList, useReferrerLink, externalUrl } = this.state.options;
        const isConditionalOption = externalOrigin || specificSite || widgetList || useReferrerLink;
        if (typeof document !== "undefined" && showConditionalContent && isConditionalOption) {
            const localUrl = JSON.parse(localStorage.getItem("localUrl") || "[]");
            const currentUrl = document.URL.replace("#", "");
            if (!widgetList && !document.referrer && externalOrigin && !externalUrl && localUrl !== currentUrl) {
                return true;
            }
            if (widgetList) {
                return true;
            }
            localStorage.setItem("localUrl", JSON.stringify(currentUrl));
            const prevUrl = (document.referrer || localUrl.length) && new URL(document.referrer || localUrl);
            const foundSite = this.props.sites?.some((site: Site) => site.host === prevUrl.host);
            if (!foundSite && prevUrl) {
                localStorage.setItem("localUrl", JSON.stringify(prevUrl.href.replace("#", "")));
            }
            if (useReferrerLink) {
                const filteredPrevUrl = this.filterUrl(document.referrer || "");
                const filteredExternalUrl = this.filterUrl(externalUrl || "");
                if (externalUrl && filteredExternalUrl === filteredPrevUrl) {
                    return true;
                }
            }
            if (externalOrigin && !foundSite) {
                if (prevUrl) {
                    localStorage.setItem("localUrl", JSON.stringify(prevUrl.href.replace("#", "")));
                }
                return true;
            } else if (specificSite && foundSite) {
                return true;
            }
            return false;
        }
        return false;
    };

    private checkIfObjectSelectionIsAllowed = async (props: FlexboxProps) => {
        const { context, dynamicFilter } = props;
        const env = await getMxtsEnv(context);

        let isObjectSelectionAllowed: boolean | undefined;
        if (dynamicFilter?.resourceid) {
            const resources = await context.mxtsApi.resources(env, { size: 1, resourceIds: [dynamicFilter.resourceid] }).then((res) => res.content);
            isObjectSelectionAllowed = resources[0].objectSelectionForInternet;
            if (isObjectSelectionAllowed !== undefined) {
                this.setState({ isObjectSelectionAllowed });
            }
        }
    };

    private setConditionalSetFallback = async () => {
        const { options } = this.props;
        if (options.conditionalSetFallbackWebContentId) {
            const conditionalSetFallbackWebContent = await WebContentApi.findById({ id: options.conditionalSetFallbackWebContentId });
            this.setState({ conditionalSetFallbackWebContent });
        }
        if (options.conditionalSetFallbackTemplateId) {
            const conditionalSetFallbackTemplate = await TemplateApi.findById({ id: options.conditionalSetFallbackTemplateId });
            let conditionalSetFallbackTemplateChildren;
            if (conditionalSetFallbackTemplate) {
                const filteredRootTemplates = removeSelfNestedTemplates(conditionalSetFallbackTemplate._id, conditionalSetFallbackTemplate.root);
                conditionalSetFallbackTemplateChildren = await renderPageWidgets(filteredRootTemplates, this.props.context);
            }
            this.setState({ conditionalSetFallbackTemplate, conditionalSetFallbackTemplateChildren });
        }
    };

    private filterUrl = (url: string) => {
        const filteredUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        return filteredUrl.endsWith("/") ? filteredUrl.slice(0, -1) : filteredUrl;
    };
    private goNext = () => {
        this.swiper.current?.swiper?.slideNext("active");
    };
    private goPrev = () => {
        this.swiper.current?.swiper?.slidePrev("active");
    };

    private isShowControls = (flexBoxIndex: string, event: React.MouseEvent<HTMLElement>) => {
        const showControlElement = document.getElementsByClassName("show-controls");
        if (showControlElement.length > 0) {
            event?.stopPropagation?.();
            this.setState({ isOpened: !this.state.isOpened });
        }
    };
    private handleFlexboxEdit = (e: React.MouseEvent<any, MouseEvent>) => {
        if (Flexbox.currentOpenModal && Flexbox.currentOpenModal !== this) {
            Flexbox.currentOpenModal.setState({ isModalOpen: false });
        }
        this.setState(
            (prevState) => ({
                isModalOpen: !prevState.isModalOpen,
            }),
            () => {
                Flexbox.currentOpenModal = this.state.isModalOpen ? this : null;
            }
        );
        const allFlexbox = document.querySelectorAll(".flex-show-controls");
        allFlexbox.forEach((element) => {
            element.classList.remove("flex-active-clicked-control");
        });
        e.currentTarget.parentElement.parentElement.parentElement.classList.add("flex-active-clicked-control");
    };

    private toggleModal = () => {
        this.setState(
            (prevState) => ({
                isModalOpen: !prevState.isModalOpen,
            }),
            () => {
                Flexbox.currentOpenModal = this.state.isModalOpen ? this : null;
            }
        );

        const allFlexbox = document.querySelectorAll(".flex-show-controls");
        allFlexbox.forEach((element) => {
            element.classList.remove("flex-active-clicked-control");
        });
    };

    private handleOnSave = (newOptions: WidgetOptions) => {
        this.setState({ options: newOptions });
    };

    private handleChange = (newOptions: WidgetOptions) => {
        const { id, dispatchAction } = this.props;
        setResetStatusOnOptionsUpdate(dispatchAction);
        this.setState({ options: newOptions });
        saveWidgetOptionsInLocalStorage(newOptions, id);
        updateFrontendEditingButtonStatus(dispatchAction);
    };

    private handleConditionalSets = async () => {
        const { options } = this.state;
        if (options.useConditionalSets && options.conditionalSetsItems) {
            const conditionalSetsResults = await ConditionalSetApi.findMany({ ids: [options.conditionalSetsItems] });
            if (conditionalSetsResults) {
                const conditionalSetsItems = conditionalSetsResults[0].conditionalSetContent?.map((item) => ({
                    resortIds: item.dynamicFilter === ContentType.RESORT.toString() ? this.getDynamicFilterDataFromSelectOptions(item.dynamicFilterMultiData) : undefined,
                    regionId: item.dynamicFilter === ContentType.REGION.toString() ? item.dynamicFilterData?.value : undefined,
                    accoKindIds: item.dynamicFilter === ContentType.ACCOMMODATION_KIND.toString() ? this.getDynamicFilterDataFromSelectOptions(item.dynamicFilterMultiData) : undefined,
                    amenities: item.dynamicFilter === ContentType.AMENITY.toString() ? item.dynamicFilterData?.value : undefined,
                    resourceIds: item.dynamicFilter === ContentType.ACCOMMODATION_TYPE.toString() ? this.getDynamicFilterDataFromSelectOptions(item.dynamicFilterMultiData) : undefined,
                    unitIds: item.dynamicFilter === ContentType.UNIT.toString() ? this.getDynamicFilterDataFromSelectOptions(item.dynamicFilterMultiData) : undefined,
                    startDate: item.dynamicFilter === ConditionalSetSelect.START_DATE ? item.dynamicFilterData?.value : undefined,
                    endDate: item.dynamicFilter === ConditionalSetSelect.END_DATE ? item.dynamicFilterData?.value : undefined,
                    userType: item.dynamicFilter === ConditionalSetDynamicFilterList.USER_TYPE ? item.dynamicFilterData?.value : undefined,
                    ownerType: item.dynamicFilter === ConditionalSetDynamicFilterList.OWNER_TYPE ? item.dynamicFilterData?.value : undefined,
                    customerCountries: item.dynamicFilter === ConditionalSetDynamicFilterList.CUSTOMER_COUNTRY ? item.dynamicFilterMultiData?.map((item) => item.value) : undefined,
                    distributionChannel: item.dynamicFilter === ConditionalSetDynamicFilterList.DISTRIBUTION_CHANNEL ? item.dynamicFilterData?.value : undefined,
                    reservationCategory: item.dynamicFilter === ConditionalSetDynamicFilterList.RESERVATION_CATEGORY ? item.dynamicFilterData?.value : undefined,
                    paymentStatus: item.dynamicFilter === ConditionalSetDynamicFilterList.PAYMENT_STATUS ? item.dynamicFilterData?.value : undefined,
                    reservationStatus: item.dynamicFilter === ConditionalSetDynamicFilterList.RESERVATION_STATUS ? item.dynamicFilterData?.value : undefined,
                    contractsType: item.dynamicFilter === ConditionalSetDynamicFilterList.CONTRACTS_TYPE ? this.getDynamicFilterDataFromSelectOptions(item.dynamicFilterMultiData) : undefined,
                    resortActivity: item.dynamicFilter === ConditionalSetDynamicFilterList.RESORT_ACTIVITY ? item.dynamicFilterData?.value : undefined,
                    conditionalOperator: item.conditionalOperators,
                    logicalOperator: item.logicalOperators,
                    arrivalDay: item.dynamicFilter === ConditionalSetSelect.ARRIVAL_DAY ? item.dynamicFilterData?.value : undefined,
                    departureDay: item.dynamicFilter === ConditionalSetSelect.DEPARTURE_DAY ? item.dynamicFilterData?.value : undefined,
                    reservationDate: item.dynamicFilter === ConditionalSetDynamicFilterList.RESERVATION_DATE ? item.dynamicFilterData?.value : undefined,
                    minBathroom: item.dynamicFilter === ConditionalSetDynamicFilterList.MIN_BATHROOM ? item.dynamicFilterData?.value : undefined,
                    minBedroom: item.dynamicFilter === ConditionalSetDynamicFilterList.MIN_BEDROOM ? item.dynamicFilterData?.value : undefined,
                    rateType: item.dynamicFilter === ConditionalSetDynamicFilterList.RATE_TYPE ? item.dynamicFilterData?.value : undefined,
                    reservationType: item.dynamicFilter === ConditionalSetDynamicFilterList.RESERVATION_TYPE ? item.dynamicFilterData?.value : undefined,
                    specialcode: item.dynamicFilter === ConditionalSetDynamicFilterList.SPECIALCODE ? item.dynamicFilterData?.value : undefined,
                    subject: item.dynamicFilter === ConditionalSetDynamicFilterList.SUBJECT ? item.dynamicFilterData?.value : undefined,
                }));
                this.setState({ allConditionalSetData: conditionalSetsItems });
                const isContractsTypeSelected = conditionalSetsResults[0].conditionalSetContent?.some((item) => item.dynamicFilter === ConditionalSetDynamicFilterList.CONTRACTS_TYPE.toString());
                if (isContractsTypeSelected) {
                    const ownerIds = await getCustomerIdsFromLoginToken();
                    if (ownerIds.length) {
                        const env = await getAdminMxtsEnv();
                        const contracts = await this.props.context.mxtsApi.getContracts(env, { size: MXTS.MAX_RESULTS, ownerIds });
                        this.setState({ contractTypeIds: contracts.content.map((item) => item.contractTypeId) });
                    }
                }
            }
        }
    };
    private getDynamicFilterDataFromSelectOptions = (options?: Array<SelectOption<string>>): string[] | undefined => options?.map((option) => option.value);

    private filterChildern = (
        children: Array<{ element: JSX.Element; el?: JSX.Element; options: FlexboxChildOptions; _id: string }>,
        sessionState?: SessionState
    ): Array<{ element: JSX.Element; el?: JSX.Element; options: FlexboxChildOptions }> => {
        let filteredChildren = [...children];
        if (sessionState?.hiddenWidgetId?.length) {
            // Note: This check is for Countdown timer widget, so once session is expired this widgetId should be remove from flexbox child.
            filteredChildren = children.filter((child) => !sessionState.hiddenWidgetId?.includes(child._id));
        }
        return filteredChildren;
    };
}

const backgroundClassnames = (options: WidgetOptions) => ({
    "background-image": options.useBackgroundImage,
    ["default-img-height-" + options.backgroundImageHeightDesktop]: options.backgroundImageMoreHeight && !!options.backgroundImageHeightDesktop,
    ["tab-img-height-" + options.backgroundImageHeightTablet]: options.backgroundImageMoreHeight && !!options.backgroundImageHeightTablet,
    ["mob-img-height-" + options.backgroundImageHeightMobile]: options.backgroundImageMoreHeight && !!options.backgroundImageHeightMobile,
    ["align-items-" + options.backgroundImageAlignContentVertically]: options.useBackgroundImage && !!options.backgroundImageAlignContentVertically,
    ["justify-content-" + options.backgroundImageAlignContentHorizontally]: options.useBackgroundImage && !!options.backgroundImageAlignContentHorizontally,
});

const inlineFlexBoxStyle = (options: WidgetOptions) => {
    const imageUrl = options.backgroundImageSpec?.originalUrl;
    if (options) {
        return {
            ...(options.addBorder && options.borderColor?.includes("rgba") && { borderColor: options.borderColor }),
            ...(options.background?.includes("rgba") && { background: options.background }),
            ...(options.arrowBackgroundColor?.includes("rgba") && { background: options.arrowBackgroundColor }),
            ...(options.useBackgroundImage && { backgroundImage: "url(" + imageUrl + ")" || undefined }),
        };
    }
    return {};
};

function mapStateToProps(state: State): FlexboxStoreProps {
    return {
        sessionState: state.sessionState,
        frontendPageEditState: state.frontendPageEditState,
        dynamicFilter: state.dynamicFilter,
        myEnvState: state.myEnvState,
    };
}

const mapDispatchToProps = (dispatch: Dispatch<FrontendPageEditAction>): FlexboxDispatchProps => ({ dispatchAction: dispatch });

export const FlexboxBase = connect<FlexboxStoreProps, FlexboxDispatchProps>(mapStateToProps, mapDispatchToProps)(Flexbox);
