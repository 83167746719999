export enum DisplayPattern {
    DAY_SELECTION = "DAY_SELECTION",
    CALENDAR = "CALENDAR",
}

export enum PriceTypes {
    FREE = "FREE",
    PAID = "PAID",
}

export enum TicketingTypes {
    NO_TICKETS = "NO_TICKETS",
    TICKETS = "TICKETS",
    TICKET_PER_SUBJECT = "TICKET_PER_SUBJECT",
    TICKET_PER_GROUP = "TICKET_PER_GROUP",
}

export enum ActivityInfo {
    TITLE = "TITLE",
    DESCRIPTION = "DESCRIPTION",
    IMAGE = "IMAGE",
    TIME = "TIME",
    DYNAMIC_FIELD = "DYNAMIC_FIELD",
    PRICE = "PRICE",
    CAPACITY = "CAPACITY",
    LOCATION = "LOCATION",
    SIGN_UP = "SIGN_UP",
    SUBJECT_NAME = "SUBJECT_NAME",
    SUBJECT_COUNT = "SUBJECT_COUNT",
    SUBJECT_NAME_COUNT = "SUBJECT_NAME_COUNT",
}

export enum DefaultDate {
    TODAY = "TODAY",
    TOMORROW = "TOMORROW",
    ANOTHER_DAY = "ANOTHER_DAY",
}
