import { Theme } from "./";
import baseTheme from "./base";
import krimV2 from "./krimV2";

const theme: Theme = {
    id: "krimV3",
    name: "Krim Theme V3",
    stylesheets: ["/styles/themes/krimv3/krim-admin.css"],
    frontendStylesheets: ["/styles/themes/krimv3/krim.css"],
    styles: [
        ...baseTheme.styles,
        ...krimV2.styles,
        {
            id: "use-alternative-modal-header",
            name: "Use alternative modal header",
            targets: ["revealer-link"],
            classnames: ["modal-header-2"],
        },
        // Krim specific menu searchbox
        {
            id: "search-input",
            name: "Menu searchbox",
            targets: ["menu", "menu-template"],
            classnames: ["menu-searchbox"],
        },
        // Krim Assets for icons (beating heart)
        {
            id: "beating-heart",
            name: "Show the beating texel heart",
            targets: ["webcontent"],
            classnames: ["beating texel"],
        },
        {
            id: "special-card-panel",
            name: "Special card lower panel design",
            targets: ["webcontent"],
            classnames: ["special-card-lower-panel"],
        },
        // TODO:- need to look at classnames and move those classes from base to Krim theme css files
        // to make base more cleaner with generic things only
        {
            id: "use-as-header-h2",
            name: "Use as header h2",
            targets: ["webcontent"],
            classnames: ["use-as-header-h2"],
        },
        {
            id: "use-as-header-h3",
            name: "Use as header h3",
            targets: ["webcontent"],
            classnames: ["use-as-header-h3"],
        },
        {
            id: "body-x-small",
            name: "body-x-small",
            targets: ["webcontent"],
            classnames: ["body-x-small"],
        },
        {
            id: "call-out",
            name: "Call-out-free-cancellation",
            targets: ["webcontent"],
            classnames: ["call-out"],
        },
        // Divider widget
        {
            id: "divider-bottom-shadow",
            name: "Add bottom shadow",
            targets: ["divider"],
            classnames: ["divider--bottom__shadow"],
        },
        // Single-Review-Rating
        {
            id: "review-above-content",
            name: "Review above content",
            targets: ["single-review-rating"],
            classnames: ["review-above-content"],
        },
        // Payment status
        {
            id: "payment-my-env-styling",
            name: "My env payment styling",
            targets: ["payment-status"],
            classnames: ["payment"],
        },
    ],
};

export default theme;
