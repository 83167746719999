import * as React from "react";

import { SortableContainer, SortableElement } from "react-sortable-hoc";

// sortable wrapper can be used for reordering the list item
// onSort, handleChange needs to be overridden in the component which is using the  SortableList to pass
// the list items for reordering (Ex. imageGallery.tsx)
interface SortableItemProps {
    item: JSX.Element;
}

interface SortableListProps {
    items: JSX.Element[];
}

export type SortableComponentState = SortableListProps;

class Item extends React.Component<SortableItemProps> {
    constructor(props: SortableItemProps) {
        super(props);
    }

    public render(): JSX.Element | null {
        return this.props.item;
    }
}

const SortableItem = SortableElement(Item);

export const SortableList = SortableContainer(
    (props: SortableListProps): JSX.Element => {
        const items: JSX.Element[] = props.items.map((item: JSX.Element, index: number): JSX.Element => <SortableItem key={`item-${index}`} index={index} item={item} />);
        return <ul className="m-0">{items}</ul>;
    }
);
