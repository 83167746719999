import { Action } from ".";
import { SpecialSearchState } from "../reducers/specialSearchReducer";
import { BillTargetAction } from "./billAction";

export enum SpecialSearchActionType {
    loading = "special_loading",
    fetched = "special_fetched",
    error = "special_error",
    removed = "special_removed",
}

export interface SpecialSearchAction extends Action, BillTargetAction {
    actionType: SpecialSearchActionType;
    payload?: SpecialSearchState;
}
