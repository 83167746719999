import { globalLogger } from "@maxxton/cms-api";

export async function reportError(error: Error | {}, baseUrl?: string, extraMessage?: string): Promise<void> {
    const opts: any[] = [error];
    if (baseUrl) {
        opts.unshift(baseUrl);
    }
    if (extraMessage) {
        opts.unshift(extraMessage);
    }
    globalLogger.error(...opts);
}

export async function reportWarn(error: Error | {}, baseUrl?: string, extraMessage?: string): Promise<void> {
    const opts: any[] = [error];
    if (baseUrl) {
        opts.unshift(baseUrl);
    }
    if (extraMessage) {
        opts.unshift(extraMessage);
    }
    globalLogger.warn(...opts);
}
