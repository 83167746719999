import * as React from "react";

import { Alert, Alerts } from "../alerts";

import { Alert as AlertView } from "reactstrap";
import { uniqueId } from "lodash";

interface AlertsPopupProps {
    alerts: Alerts;
}

interface AlertsPopupPropsState {
    unobserve: () => void;
    alerts: Alert[];
}

export class AlertsPopup extends React.PureComponent<AlertsPopupProps, AlertsPopupPropsState> {
    constructor(props: AlertsPopupProps) {
        super(props);
        this.state = {
            unobserve: () => undefined,
            alerts: [],
        };
    }

    public componentDidMount() {
        const unobserve = this.props.alerts.observe(this.alertsDidUpdate);
        this.setState(() => ({ unobserve }));
    }

    public componentWillUnmount() {
        this.state.unobserve();
    }

    public render(): JSX.Element | null {
        const { alerts } = this.state;
        if (alerts.length) {
            return (
                <div className="alerts">
                    {alerts.map((alert: Alert, ind) => (
                        <AlertView
                            className="alert"
                            key={uniqueId(`${JSON.stringify(alert.message)}_`) || ind}
                            color={alert.color}
                            isOpen={alert.active}
                            toggle={alert.dismissable ? this.handleDismiss(alert) : undefined}
                        >
                            {alert.message}
                        </AlertView>
                    ))}
                </div>
            );
        }
        return null;
    }

    private alertsDidUpdate = (nextAlerts: Alert[]) => {
        this.setState(() => ({ alerts: nextAlerts }));
    };

    private handleDismiss(alert: Alert): () => void {
        return () => {
            this.props.alerts.discard(alert);
        };
    }
}
