import { Action } from "./";
import { FrontendPageEditState } from "../reducers/frontendPageEditReducer";

export enum FrontendPageEditActionType {
    SET_IS_PAGE_ENABLE_EDIT = "SET_IS_PAGE_ENABLE_EDIT",
    SET_FRONTEND_EDITED_WIDGETS_STATUS = "SET_FRONTEND_EDITED_WIDGETS_STATUS",
    CLEAR_FRONTEND_EDITED_WIDGETS = "CLEAR_FRONTEND_EDITED_WIDGETS",
}

export interface FrontendPageEditAction extends Action {
    actionType: FrontendPageEditActionType;
    payload: FrontendPageEditState;
}
