import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";

import { InputSpecMulti } from "../form-specs";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { NumberMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { dynamicFieldCodeList } from "../components/utils";
import namespaceList from "../i18n/namespaceList";

function dynamicFieldMultiSelectorFallback<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCodeMultiSelector"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            return dynamicFieldCodeList(MxtsApi);
        },
        visible: (options: any) => options.fallbackDescription && options.descriptionMultiSelectorFallback?.some((description: NumberMultiSelectOption) => description.value === 4),
    };
}

export default dynamicFieldMultiSelectorFallback;
