import { MyEnvState, OwnerState } from "../reducers/myEnv/myEnvState";

import { Action as ReduxAction } from "redux";

export enum MyEnvActionType {
    CONFIRM_SHOPPING_CART = "my_env_confirm_shopping_cart",
    UPDATE = "my_env_update",
    OWNER_UPDATE = "my_env_owner_update",
    FETCH_RESERVATION = "my_env_fetch_reservation",
    FETCH_RESERVED_RESOURCE_PREFERENCES = "my_env_fetch_reserved_resource_preferences",
    RESERVATION_FETCHED = "my_env_reservation_fetched",
    FETCH_CUSTOMER = "my_env_fetch_customer",
    REFRESH_CUSTOMER = "my_env_refresh_customer",
    CUSTOMER_FETCHED = "my_env_customer_fetched",
}

export interface MyEnvReducerAction extends ReduxAction<MyEnvActionType> {
    payload: MyEnvState;
    forceUpdateSelectedReservation?: boolean;
    ignoreIfAlreadyUpdatedRecently?: boolean;
}

export const updateMyEnvState = (state: Partial<MyEnvState>): MyEnvReducerAction => ({
    type: MyEnvActionType.UPDATE,
    payload: state,
});

export const updateMyEnvOwnerState = (state: Partial<OwnerState>): MyEnvReducerAction => ({
    type: MyEnvActionType.OWNER_UPDATE,
    payload: { ownerState: state },
});

export const setSelectedMyEnvReservationLoading = (loading: boolean): MyEnvReducerAction => ({
    type: MyEnvActionType.UPDATE,
    payload: { selectedReservationLoading: loading },
});

export const setMyEnvCustomerLoading = (loading: boolean): MyEnvReducerAction => ({
    type: MyEnvActionType.UPDATE,
    payload: { customerLoading: loading },
});

export const fetchSelectedMyEnvReservation = (selectedReservationId?: number): MyEnvReducerAction => ({
    type: MyEnvActionType.FETCH_RESERVATION,
    payload: { selectedReservationId },
});

export const refreshSelectedMyEnvReservation = (selectedReservationId?: number, ignoreIfAlreadyUpdatedRecently?: boolean): MyEnvReducerAction => ({
    type: MyEnvActionType.FETCH_RESERVATION,
    payload: { selectedReservationId },
    forceUpdateSelectedReservation: true,
    ignoreIfAlreadyUpdatedRecently,
});

export const fetchMyEnvCustomer = (): MyEnvReducerAction => ({
    type: MyEnvActionType.FETCH_CUSTOMER,
    payload: {},
});

export const refreshMyEnvCustomer = (): MyEnvReducerAction => ({
    type: MyEnvActionType.REFRESH_CUSTOMER,
    payload: {},
});
