import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "vodatent",
    name: "Vodatent Theme",
    stylesheets: ["/styles/themes/vodatent/vodatent-admin.css"],
    frontendStylesheets: ["/styles/themes/vodatent/vodatent.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "asset-publisher-card",
            name: "Discover card design in Assetpublisher",
            targets: ["assetpublisher"],
            classnames: ["asset-publisher-card"],
        },
    ],
};

export default theme;
