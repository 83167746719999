import * as classNames from "classnames";

import { FlexboxChildOptions, WidgetOptions } from "../plugins/page/flexbox/index";

export const getPreconfiguredBorderClassnames = (options: WidgetOptions): string => {
    if (options) {
        return classNames({
            "flex-bordered": options.addBorder,
            "no-border": !options.addBorder,
            ["border-" + options.borderSide + "-" + options.borderWidth]: options.addBorder && !!options.borderWidth && !!options.borderSide,
            ["border-" + options.borderSide + "-" + "style" + "-" + options.borderStyle]: options.addBorder && !!options.borderStyle,
            ["border-color-" + options.borderColor]: options.addBorder && options.borderColor?.includes("theme"),
        });
    }
    return "";
};

export const getPreconfiguredFlexboxChildClassnames = (child?: FlexboxChildOptions): string => {
    if (child) {
        return classNames(`align-self-${child?.alignSelf || "auto"}`, child?.showMoment, {
            "flex-zero": child && child?.removeflexible,
            "toggle-block-hidden": !!child?.toggleBlockName && child.toggleBlock,
            "simpleToggle": child?.toggleBlock,
            [child.toggleType]: child?.toggleBlock && !!child?.toggleType,
        });
    }
    return "";
};

export const getPreconfiguredColClassnames = (child?: FlexboxChildOptions): string => {
    if (child) {
        return classNames(child?.showMoment, {
            col: child && !child.columnsResponsive,
            ["col-sm-" + child.col]: !!child?.col,
            ["col-" + child.columnsResponsive]: !!child?.columnsResponsive,
            ["col-md-" + child.columnsTablet]: !!child?.columnsTablet,
            ["col-lg-" + child.columnsDesktop]: !!child?.columnsDesktop,
            ["col-xl-" + child.columnsExtraLargeDesktop]: !!child?.columnsExtraLargeDesktop,
        });
    }
    return "";
};

export const getSpacingControl = (options: WidgetOptions): string => {
    if (options) {
        return classNames({
            [options.paddingTop]: !!options?.paddingTop && !options.useCustomSpacingValues,
            [options.paddingBottom]: !!options?.paddingBottom && !options.useCustomSpacingValues,
            [options.contentMargin]: !!options?.contentMargin && !options.useCustomSpacingValues,
        });
    }
    return "";
};

export const getInlineFlexboxStyles = (options: WidgetOptions) => {
    const customPaddingTopWithpixels = options.customPaddingTop + "px";
    const customPaddingBottomWithpixels = options.customPaddingBottom + "px";
    const customSpacingBelowContainer = options.customSpacingBelowContainer + "px";

    if (options) {
        return {
            ...(options.addBorder && options.borderColor?.includes("rgba") && { borderColor: options.borderColor }),
            ...(options.background?.includes("rgba") && { background: options.background }),
            ...(options.arrowBackgroundColor?.includes("rgba") && { background: options.arrowBackgroundColor }),
            ...(options.useCustomSpacingValues && options.customPaddingTop && { paddingTop: customPaddingTopWithpixels }),
            ...(options.useCustomSpacingValues && options.customPaddingBottom && { paddingBottom: customPaddingBottomWithpixels }),
            ...(options.useCustomSpacingValues && options.customSpacingBelowContainer && { marginBottom: customSpacingBelowContainer }),
        };
    }
    return {};
};
