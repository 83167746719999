import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "weerribben",
    name: "Weerribben Theme",
    stylesheets: ["/styles/themes/weerribben/weerribben-admin.css"],
    frontendStylesheets: ["/styles/themes/weerribben/weerribben.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "text-brandcolor",
            name: "Text as brand color",
            targets: ["webcontent"],
            classnames: ["text-brand-color"],
        },
        {
            id: "text-brandaltcolor",
            name: "Text as brand alt color",
            targets: ["webcontent"],
            classnames: ["text-brand-alt-color"],
        },
    ],
};

export default theme;
