import { Action } from ".";
import { PageLockState } from "../reducers/pageLockReducer";

export enum PageLockActionType {
    takeOverLock = "takeOverLock",
}

export interface PageLockAction extends Action {
    actionType: PageLockActionType;
    payload: PageLockState;
}
