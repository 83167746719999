import * as React from "react";

import { AccommodationType, Unit } from "../mxts";
import { WidgetSpec, renderPageWidgets } from "../widget";

import { ActionType } from "../../redux/actions";
import { Activity } from "../page/activityPlanner/activityPlanner.types";
import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { ConfiguredLink } from "../../utils/linking.util";
import { CurrentLocale } from "../../app.types";
import { DATE_FORMAT } from "../../utils/constants";
import { Dispatch } from "redux";
import { ResultsPanelAction } from "../../redux/actions/resultsPanelAction";
import { Typesearch } from "@maxxton/cms-api";
import { UrlLinkParams } from "../../utils/urlparam.util";
import { generateRandomKey } from "../../components/utils";
import { stringify } from "query-string";

export function setPageViewEvent(context: CMSProvidedProperties, bookUrl: string, accommodationType?: AccommodationType, unit?: Unit) {
    if (localStorage) {
        if (accommodationType) {
            const accoType = JSON.parse(localStorage.getItem("accoType") || "[]");
            accoType.push(accommodationType);
            localStorage.setItem("accoType", JSON.stringify(accoType));
        } else if (unit) {
            const customUnit = JSON.parse(localStorage.getItem("customUnit") || "[]");
            let typename;
            if (unit.namePath) {
                const unitNamePath = unit.namePath.split("/");
                typename = unitNamePath[unitNamePath.length - 1];
            }
            customUnit.push({ ...unit, bookUrl, typeName: typename ? typename.trim() : unit.resortName });
            localStorage.setItem("customUnit", JSON.stringify(customUnit));
        }
        const stack = JSON.parse(localStorage.getItem("pageNavigation") || "[]");
        stack.push(window.location.protocol + "//" + window.location.hostname + bookUrl);
        localStorage.setItem("pageNavigation", JSON.stringify(stack));
    }
    if (!context.isAdmin && context.site.useGTM) {
        (window as any).dataLayer.push({
            event: "PageView",
            meta: {
                referrer: window.location.href,
                pathname: bookUrl,
            },
        });
    }
}

export interface TabLinkOption {
    label: string;
    value: string;
}

export interface IntitialItemRootWidget<T = any> {
    _id: string;
    spec: WidgetSpec<T>;
    children: Array<IntitialItemRootWidget<any>>;
    options: T;
    type: string;
}

interface InitialItem {
    root: IntitialItemRootWidget[];
}

interface TabLocale {
    locale: string;
    tabName: string;
}

export const getTabLinkOptions = (initialItem: InitialItem, currentLocale: CurrentLocale): TabLinkOption[] => {
    const tabLinkOptions: TabLinkOption[] = [];
    const searchTreeForTabs = (widget: IntitialItemRootWidget) => {
        if (!widget._id) {
            return;
        }

        if (widget.type === "tabs") {
            widget.children.forEach((child) => {
                const { tabName }: TabLocale = child.options.localized.find(({ locale }: TabLocale) => locale === currentLocale.locale);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                tabLinkOptions.push({
                    label: tabName,
                    value: JSON.stringify({ tabsWidgetId: widget._id, tabId: child._id }),
                });
            });
        } else {
            widget.children.forEach((child: IntitialItemRootWidget) => searchTreeForTabs(child));
        }
    };

    initialItem.root.forEach((widget: IntitialItemRootWidget) => searchTreeForTabs(widget));
    return [...tabLinkOptions];
};

interface TabLinkParam1 {
    resourceId?: number;
    unitId?: number;
    dispatchAction: Dispatch<ResultsPanelAction>;
}

export interface DispatchOpenLinkedTabOptions {
    tabLink?: string;
    ElementToToggle?: string;
    toggleButton?: boolean;
}

interface ShowLinkedBlock {
    resourceId?: number;
    unitId?: number;
    ElementToToggle?: string;
    toggleButton?: boolean;
}

export const showLinkedBlock = ({ resourceId, unitId, ElementToToggle, toggleButton }: ShowLinkedBlock): void => {
    if (ElementToToggle && toggleButton) {
        const toggleElement = `${ElementToToggle}-${resourceId || unitId || ""}`;
        const toggleBlock = document.querySelector(`[data-toggle-element='${toggleElement}']`);

        if (toggleBlock?.classList.contains("toggle-block-hidden")) {
            toggleBlock.classList.remove("toggle-block-hidden");
            toggleBlock.classList.add("toggle-block-visible");
        }
    }
};

export const getDynamicBookLink = (params: UrlLinkParams, link: ConfiguredLink, resourceId?: number, unitId?: number) => {
    const connector = link.url?.indexOf("?") > -1 ? "&" : "?";
    if (resourceId) {
        params.resourceid = resourceId;
    }
    if (unitId) {
        params.unitid = unitId;
    }
    let bookLink = `${link.url}${connector}${stringify(params, { encode: false, strict: false })}`;
    if (link.anchor) {
        bookLink = `${bookLink}#${link.anchor}`;
    }
    return bookLink;
};

export const dispatchOpenLinkedTabAction = ({ resourceId, unitId, dispatchAction }: TabLinkParam1) => ({ tabLink, ElementToToggle, toggleButton }: DispatchOpenLinkedTabOptions): void => {
    const tabLinkOption: { tabsWidgetId?: string; tabId?: string } = tabLink ? JSON.parse(tabLink) : {};

    if (tabLinkOption.tabsWidgetId && tabLinkOption.tabId && (resourceId || unitId)) {
        dispatchAction({
            type: ActionType.ResultsPanelOpenLinkedTab,
            payload: {
                resourceId,
                unitId,
                ...tabLinkOption,
            },
        });

        showLinkedBlock({ resourceId, unitId, ElementToToggle, toggleButton });
    }
};

export const getResultPanelElements = async (resultPanelId: string | undefined, context: CMSProvidedProperties): Promise<JSX.Element[] | undefined> => {
    const resultPanelsPage: Typesearch | null = resultPanelId ? await context.cmsApi.typesearchApi.findById({ id: resultPanelId }) : null;
    return resultPanelsPage ? renderPageWidgets(resultPanelsPage.root, context) : undefined;
};

export const renderResultPanelElements = (resultPanelElements: JSX.Element[] | undefined, additionalProps: { [key: string]: any }): JSX.Element[] =>
    React.Children.map(resultPanelElements || [], (child) => React.cloneElement((child as React.ReactElement<any>).props.children, additionalProps));

export const injectPropIntoElementTrees = (elements: JSX.Element[], additionalProps: { [key: string]: any }) => elements.map((element) => injectPropIntoElementTree(element, additionalProps));

export const injectPropIntoElementTree = (element: React.ReactElement<any>, additionalProps: { [key: string]: any }): React.ReactElement<any> => {
    const elementChildren = element?.props?.children || element?.props?.childrenElements;
    let newChildren: React.ReactElement<any> | Array<React.ReactElement<any>> | undefined;
    if (!elementChildren) {
        newChildren = undefined;
    } else if (Array.isArray(elementChildren)) {
        newChildren = elementChildren.map((child) => {
            const clonedElement = injectPropIntoElementTree(child?.element ? child.element : child, additionalProps);
            return child?.element ? { ...child, element: clonedElement } : clonedElement;
        });
    } else {
        newChildren = injectPropIntoElementTree(elementChildren, additionalProps);
    }
    const updatedElementProps = { ...additionalProps };
    if (elementChildren) {
        if (element?.props?.childrenElements) {
            updatedElementProps.childrenElements = newChildren;
        } else {
            updatedElementProps.children = newChildren;
        }
    }
    return React.cloneElement(element, typeof element.type === "string" ? {} : updatedElementProps);
};

export const renderUnitSearchPanel = ({ childrenArray, elementsArray: slicedUnits, index }: { childrenArray: JSX.Element[]; elementsArray: Unit[]; index: number }) => {
    const unit = slicedUnits[index];
    const childrenWithProps = React.Children.map(childrenArray, (child) =>
        React.cloneElement((child as React.ReactElement<any>).props.children, {
            unit,
            key: unit?.unitId || generateRandomKey(),
        })
    );
    return childrenWithProps;
};

export const renderActivitySearchPanel = ({
    childrenArray,
    elementsArray: slicedActivities,
    index,
    handleTimeSelection,
}: {
    childrenArray: JSX.Element[];
    elementsArray: Activity[];
    index: number;
    handleTimeSelection: (oldResourceActivityDetailsId: number, newResourceActivityDetailsId: number) => void;
}) => {
    const slicedActivity = slicedActivities[index];
    const childrenWithProps = React.Children.map(childrenArray, (child) =>
        React.cloneElement((child as React.ReactElement<any>).props.children, {
            activity: slicedActivity,
            handleTimeSelection,
        })
    );
    return childrenWithProps;
};

export const dateFormats = [
    { value: DATE_FORMAT.DISPLAY, label: DATE_FORMAT.DISPLAY },
    { value: DATE_FORMAT.MONTH_DATE_YEAR, label: DATE_FORMAT.MONTH_DATE_YEAR },
    { value: DATE_FORMAT.DATE_WITH_SHORT_YEAR, label: DATE_FORMAT.DATE_WITH_SHORT_YEAR },
    { value: DATE_FORMAT.DATE_WITH_SHORT_MONTH, label: DATE_FORMAT.DATE_WITH_SHORT_MONTH },
    { value: DATE_FORMAT.SHORT_MONTH_DAY_YEAR, label: DATE_FORMAT.SHORT_MONTH_DAY_YEAR },
    { value: DATE_FORMAT.DAY_WITH_MONTH_NAME, label: DATE_FORMAT.DAY_WITH_MONTH_NAME },
    { value: DATE_FORMAT.SHORT_DAY_AND_MONTH, label: DATE_FORMAT.SHORT_DAY_AND_MONTH },
    { value: DATE_FORMAT.US_LONG_DATE, label: DATE_FORMAT.US_LONG_DATE },
];
