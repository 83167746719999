// Be careful while sorting imports of this file as it breaks the build
/* eslint-disable sort-imports */
import "regenerator-runtime/runtime";
import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";

import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import * as fetch from "isomorphic-fetch";
import * as uncaught from "uncaught";

import { AuthApi, CachedResponse, CmsApi, CmsApiCache, DataLayerApi, Flow, FlowApi, Locale, Logger, LocaleApi, Site, SiteApi, WithId, globalCmsApiCache, globalLogger, init } from "@maxxton/cms-api";
import { globalApiContext } from "./containers/CmsProvider";
import { DEVICE, UAParser } from "ua-parser-js";
import { PrefetchedApp, getCurrentLocaleCode, getCurrentLocaleId, prefetchApp } from "./app";
import { getClientIdFromToken, setClientName } from "./components/admin/utils";
import { getLocaleCodeFromPath, isMobileDeviceDetected, transformLocaleIntoCurrentLocale } from "./utils/localizedContent.util";
import { isPwaEnabledForLocation, isPwaGeolocationEnabled } from "./utils/pwa.util";
import { CMSProviderProperties } from "./containers/cmsProvider.types";

import { BrowserRouter } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "./utils/constants";
import { findTheme } from "./themes";
import { getCMSOptions } from "./plugins/settings";
import { getGlobalFields } from "./utils/globalFields";
import { getLocaleCodeFromCMSOptions } from "./utils/widget.util";
import { loadI18n } from "./i18n";
import { loadableReady } from "@loadable/component";
import { isClientLoggedIn, onInvalidToken } from "./components/utils";
import { parse } from "query-string";
import { removeUserLoggedInCookie, setUserLoggedInCookie } from "./utils/authToken.util";
import { logUncaughtError, WebLogWriter } from "./utils/logs.util";
import { DynamicFilter } from "./redux/reducers/dynamicFilter.types";
import { getMxtsEnv } from "./plugins/mxts";
import { MetaData, applyMetaTagsToField, getRequiredDynamicFields, getMetaEndpoint } from "./utils/dynamicSeo.util";
import { BrowserLocation, CurrentLocale } from "./app.types";
import { setUniversalAuthToken } from "./utils/auth/universalAuthToken.util";
import { UNIVERSAL_AUTH_TOKEN_HASH_PARAM } from "./utils/urlparam.util";
import { localeFallbackConfig } from "./utils/locale.util";
import { filterCacheParams } from "./utils/cache.util";
import { isClientSide } from "./utils/generic.util";

if (!getGlobalFields().landingPageUrl) {
    getGlobalFields().landingPageUrl = window.location.pathname + window.location.search;
}

uncaught.start();
uncaught.addListener(logUncaughtError);

const device = new UAParser().getDevice().type;

let isMobile = false;

let waitForCache: Promise<void> | undefined;

if (device === DEVICE.MOBILE) {
    isMobile = true;
} else if (device === DEVICE.TABLET) {
    isMobile = true;
}

if (window.location.hash.indexOf("access_token") > -1 || window.location.hash.toLowerCase().indexOf(UNIVERSAL_AUTH_TOKEN_HASH_PARAM) > -1) {
    extractAuthTokensFromHash();
}

window.onload = async () => {
    if (waitForCache) {
        await waitForCache;
    }
    if ("serviceWorker" in navigator && (await isPwaEnabledForLocation(window.location))) {
        try {
            await navigator.serviceWorker.register("/service.worker.js");
        } catch (e) {
            console.error("SW registration failed with error:", e);
        }
    }
    if ("geolocation" in navigator && (await isPwaGeolocationEnabled(window.location))) {
        try {
            navigator.geolocation.getCurrentPosition((position) => console.info("You are located at latitude:", position.coords.latitude, "& longitude: ", position.coords.longitude));
        } catch (e) {
            console.warn("Geolocation failed with error:", e);
        }
    }
};

const token: string | null = localStorage.getItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN);
const getMyEnvAuthToken = () => localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN) || undefined;
const clientId: number | null = getClientIdFromToken();

/**
 *
 * @param url
 */
function parseConfigUrl(configObj: Config["cmsApi"] | Config["mxtsGateway"]): string {
    const homepageUrl = new URL((window as any).pageUrl);
    let url: string = location.hostname !== homepageUrl.host ? configObj.fullUrl : configObj.baseUrl;
    if (url.startsWith(":")) {
        url = `${location.protocol}//${location.hostname}${url}`;
    }
    return url;
}

export interface Config {
    env?: string;
    web: {
        baseUrl: string;
        cdnBaseUrl: string;
    };
    cmsApi: {
        baseUrl: string;
        fullUrl: string;
    };
    mxtsGateway: {
        baseUrl: string;
        fullUrl: string;
    };
    authorisation: {
        baseUrl: string;
    };
}

const config: Config = (window as any).cmsConfig;

const userAgent = "cms-web";
init({ baseUrl: parseConfigUrl(config.cmsApi), fetch, token, getMyEnvAuthToken, onInvalidToken, clientId, isClientSide: true, userAgent });
MXTS.init({ baseUrl: parseConfigUrl(config.mxtsGateway), fetch, userAgent, globalLogger: (globalLogger as any) as MXTS.LoggerContext, localeFallbackConfig });
Logger.logWriters.push(new WebLogWriter());

if (token) {
    AuthApi.authCheck()
        .then(() => null)
        // eslint-disable-next-line no-console
        .catch(() => {
            console.warn("stored token is invalid");
            removeUserLoggedInCookie();
        });
} else {
    removeUserLoggedInCookie();
}

setClientName(globalApiContext(), clientId);

const generateClientSideMeta = async (metaTitle: string, context: CMSProviderProperties, requiredDynamicFields: string[]) => {
    const dynamicFilter = context.reduxStore.store.getState()?.dynamicFilter;
    try {
        let serverSideDynamicFilter: Partial<DynamicFilter> = {};
        serverSideDynamicFilter = { unitid: Number(dynamicFilter?.unitid), resourceid: Number(dynamicFilter?.resourceid), resortids: dynamicFilter?.resortids };

        if (requiredDynamicFields?.length) {
            const metaData: MetaData = {};
            const env = await getMxtsEnv(context, context.currentLocale?.code);

            for (const field of requiredDynamicFields) {
                const tags = await getMetaEndpoint(env, serverSideDynamicFilter, field);
                if (tags) {
                    metaData[field] = tags;
                }
            }
            const transformedData = applyMetaTagsToField(metaTitle, metaData).trim();
            document.title = transformedData;
        }
    } catch (error) {
        console.error(error);
    }
};

function onTitle(title: string) {
    return undefined;
}
async function onMetaTitle(metaTitle: string, context?: CMSProviderProperties) {
    const requiredDynamicFields = getRequiredDynamicFields({ metaTitleSeo: metaTitle });
    if (requiredDynamicFields?.length && context) {
        generateClientSideMeta(metaTitle, context, requiredDynamicFields);
    } else {
        document.title = metaTitle;
    }
}
function onMetaKeywords(metaKeywords: string) {
    return undefined;
}
function onMetaDescription(metaDescription: string) {
    return undefined;
}
function onMetaRobots(metaRobots: string) {
    return undefined;
}
function onImageStructured(imageStructured: string) {
    return undefined;
}
function onTitleStructured(titleStructured: string) {
    return undefined;
}
function onUrlStructured(urlStructured: string) {
    return undefined;
}
function onDescriptionStructured(descriptionStructured: string) {
    return undefined;
}
function onAuthorStructured(authorStructured: string) {
    return undefined;
}
function onStructuredData(structuredData: boolean) {
    return undefined;
}
function onStructuredDataMarkup(structuredData: string) {
    return undefined;
}
function onEnableRedirect(enableRedirect: boolean) {
    return undefined;
}
function onRedirect(redirect: string) {
    window.location.replace(redirect);
}
function onDisableCanonicalUrl(disableCanonicalUrl: boolean) {
    return undefined;
}

/**
 * This will fill the cms and mxts api cache with the cache data that the server already prepared for this page.
 */
async function loadPageApiCaches(pageUrl: URL): Promise<void> {
    if (location.pathname.startsWith("/webmanager/")) {
        return;
    }
    const uri = `${pageUrl.hostname}${pageUrl.pathname}?${filterCacheParams(pageUrl.searchParams)}`;

    const cmsConfig: Config = (window as any).cmsConfig;

    await Promise.all([
        (async () => {
            let resp: Response;
            if ((window as any).pageCmsApiCacheData) {
                resp = (window as any).pageCmsApiCacheData;
                delete (window as any).pageCmsApiCacheData;
            } else {
                const url = `${cmsConfig.web?.cdnBaseUrl || (pageUrl.hostname !== location.hostname ? `https://${pageUrl.hostname}` : "")}/page-cms-api-cache/${
                    (window as any).renderHash
                }?pageUrl=${encodeURIComponent(uri)}`;
                resp = await fetch(url);
                if (!resp.ok) {
                    throw new Error(`Failed to GET ${url}, responsed with ${resp.status}`);
                }
            }
            const pageCmsApiCacheString = await resp.text();
            const pageCmsApiCacheMap: Map<string, Map<string, CachedResponse>> | null = CmsApiCache.parseEntireCache(pageCmsApiCacheString);
            if (pageCmsApiCacheMap) {
                globalCmsApiCache.setEntireCache(pageCmsApiCacheMap);
            }
        })().catch((e) => {
            console.error("Failed to load page-cms-api-cache", e);
        }),
        (async () => {
            let resp: Response;
            if ((window as any).pageCmsMxtsApiCacheData) {
                resp = (window as any).pageCmsMxtsApiCacheData;
                delete (window as any).pageCmsMxtsApiCacheData;
            } else {
                const url = `${cmsConfig.web?.cdnBaseUrl || (pageUrl.hostname !== location.hostname ? `https://${pageUrl.hostname}` : "")}/page-mxts-api-cache?pageUrl=${encodeURIComponent(uri)}`;
                resp = await fetch(url);
                if (!resp.ok) {
                    throw new Error(`Failed to GET ${url}, responsed with ${resp.status}`);
                }
            }
            const pageMxtsApiCacheString = await resp.text();
            const pageMxtsApiCacheMap: Map<string, CachedResponse> | null = MXTS.MxtsApiCache.parseEntireCache(pageMxtsApiCacheString);
            if (pageMxtsApiCacheMap) {
                MXTS.globalMxtsApiCache.setEntireCache(pageMxtsApiCacheMap);
            }
        })().catch((e) => {
            console.error("Failed to load page-mxts-api-cache", e);
        }),
    ]);
}

function getCurrentUrl(): URL {
    // Use window.pageUrl to get the correct page when embeded
    const pageUrl = (window as typeof window & { pageUrl?: string }).pageUrl;
    if (pageUrl) {
        const url = new URL(pageUrl);
        // query parameters are dynamic, so not part of the pageUrl
        url.search = location.search;
        return url;
    }
    return new URL(location.href);
}

// eslint-disable-next-line max-lines-per-function
async function initReact() {
    const currentUrl = getCurrentUrl();
    localStorage.removeItem("pageNavigation");
    const currentSite = (window as typeof window & { currentSite?: Site & WithId }).currentSite;
    const homepageUrl = new URL((window as any).pageUrl);
    const locationHostname = homepageUrl.host;

    // Check if caching and hydration should be enabled based on client-side rendering, presence of a frontend element, and login status
    const isCacheAndHydrationEnabled = !(isClientSide() && !!document.querySelector(".frontend") && isClientLoggedIn());
    if (isCacheAndHydrationEnabled) {
        waitForCache = loadPageApiCaches(currentUrl);
        await waitForCache;
    }

    // await new Promise<void>((resolve) => {
    //     if (isServerSide()) {
    //         resolve();
    //     }
    // });
    waitForCache = undefined;
    const site = currentSite && currentSite.host === locationHostname ? currentSite : await SiteApi.findByHost({ host: locationHostname, projection: { sitemap: 0 } });
    const cmsOptions = await getCMSOptions(CmsApi);
    const theme =
        window.location.href.indexOf("/edit") > -1
            ? findTheme(cmsOptions.theme?.themeId)
            : window.location.hostname.replace("www.", "") === site!.host.replace("www.", "")
            ? findTheme(site!.themeId)
            : findTheme(cmsOptions.theme?.themeId);
    if (site == null) {
        throw new Error("Unable to find current site");
    }
    let browserLocation: BrowserLocation = location;
    const options = parse(browserLocation.search);
    let paramLocale: (Locale & WithId) | null = null;
    let appId = "app";
    let pathName = "";
    if (site?.enableEmbedding) {
        if (site?.overrideAppId) {
            appId = site?.newAppId || "app";
        }
        if (currentUrl.hostname !== location.hostname) {
            pathName = document.getElementById(appId)?.getAttribute("data-url") || "";
            browserLocation = new URL(`${location.protocol}${site.host}${pathName}`);
            if (pathName && pathName !== "") {
                pathName = pathName.split("?")[0];
            }
        }
    }
    const localeCode = getLocaleCodeFromPath(browserLocation.href.split(site.host)[1]);

    let tempLocale;
    const locales = await LocaleApi.find();
    if (options && localeCode && localeCode !== "/" && localeCode !== "#" && localeCode !== "?") {
        paramLocale = locales.find((locale) => locale.code === localeCode) || null;
        tempLocale = paramLocale ? { ...paramLocale } : undefined;
    }
    let flow: (Flow & WithId) | null = null;
    if (site.flowId) {
        flow = await FlowApi.findById({ id: site.flowId });
    }

    // If valid locale isn't present for the Site, sending Site's default locale as Current locale
    const validLocaleIds = site.localeMultiSelect ? site.localeMultiSelect.map((loc: any) => loc.value) : [];
    if (site.localeMultiSelect && site.localeMultiSelect.length > 0 && paramLocale) {
        if (validLocaleIds.indexOf(paramLocale._id) < 0) {
            tempLocale = paramLocale ? { ...paramLocale } : undefined;
            paramLocale = null;
        }
    }
    const currentLocale: CurrentLocale = paramLocale ? transformLocaleIntoCurrentLocale(paramLocale) : transformLocaleIntoCurrentLocale(site.locale);
    getGlobalFields().currentLocale = currentLocale;
    let isAdmin = false;
    if (document.querySelector(".backend")) {
        isAdmin = true;
        const locale = cmsOptions.locale && locales.find((locale) => locale.code === cmsOptions.locale);
        if (locale) {
            currentLocale.code = locale.code;
            currentLocale.locale = locale._id;
            currentLocale.name = locale.name;
        }
    }

    const localizedSiteOptions = site?.localizedOptions?.find((lo) => lo.locale === currentLocale.locale);
    let distributionChannelId = "";
    if (localizedSiteOptions?.mobileDCId && isMobileDeviceDetected() && site.useMobileDC) {
        distributionChannelId = localizedSiteOptions.mobileDCId;
    } else if (localizedSiteOptions?.distributionChannelId) {
        distributionChannelId = localizedSiteOptions.distributionChannelId;
    } else {
        distributionChannelId = cmsOptions.distributionChannelId || "";
    }

    let locale: (Locale & WithId) | null = locales.find((locale) => locale.code === getLocaleCodeFromCMSOptions(cmsOptions)) || null;

    let foundLocale = getCurrentLocaleCode(locale, site, browserLocation.href.split(site.host)[1], tempLocale, validLocaleIds);
    if (isAdmin) {
        foundLocale = getLocaleCodeFromCMSOptions(cmsOptions);
    }
    if (locale && browserLocation.href.indexOf("/webmanager") === -1 && locale.code !== foundLocale) {
        locale = locales.find((locale) => locale.code === foundLocale) || null;
    }
    const localeId = getCurrentLocaleId(locale, site);
    const dataLayerSettings = (flow?.dataLayerId && (await DataLayerApi.findById({ id: flow.dataLayerId }))) || undefined;
    await loadI18n(foundLocale, localeId, site, tempLocale, isAdmin);

    const rateTypes = localizedSiteOptions?.rateTypes ? localizedSiteOptions.rateTypes.map((rateType) => rateType.value) : [];
    const reservationCategoryId = localizedSiteOptions ? localizedSiteOptions.reservationCategoryId : cmsOptions.reservationCategoryId;
    const app = await prefetchApp({
        site,
        theme,
        location: browserLocation,
        onTitle,
        onMetaTitle,
        onMetaKeywords,
        onMetaDescription,
        onMetaRobots,
        onImageStructured,
        onTitleStructured,
        onUrlStructured,
        onDescriptionStructured,
        onAuthorStructured,
        onStructuredData,
        onStructuredDataMarkup,
        onEnableRedirect,
        onRedirect,
        onDisableCanonicalUrl,
        currentLocale,
        flow,
        distributionChannelId: distributionChannelId.toString(),
        device: {
            type: device,
            isMobile: device === DEVICE.MOBILE,
            isTablet: device === DEVICE.TABLET,
            isDesktop: device !== DEVICE.MOBILE && device !== DEVICE.TABLET,
        },
        rateTypes,
        reservationCategoryId,
        // To ensure valid locale in Path matches
        paths: localeCode ? [self.location.pathname.replace(localeCode, site.locale.code === currentLocale.code ? "" : currentLocale.code)] : [self.location.pathname],
        customPath: pathName || "",
        logger: globalLogger,
        dataLayerSettings,
    });

    const fullApp = <BrowserRouter>{(app as PrefetchedApp).app}</BrowserRouter>;
    const container = document.getElementById(appId);
    if (container) {
        loadableReady(() => {
            if (isCacheAndHydrationEnabled) {
                hydrateRoot(container, fullApp);
                globalLogger.info(`Hydrated ${device !== DEVICE.MOBILE && device !== DEVICE.TABLET ? "desktop" : device} dom at: ${window.performance.now() / 1000}`);
            } else {
                const root = createRoot(container);
                root.render(fullApp);
                globalLogger.info("Rendered without hydration due to disabled page API caches.");
            }
        });
    }
}

// eslint-disable-next-line no-console
initReact().catch(console.error);

function extractAuthTokensFromHash() {
    const regex = /([a-z_]+)=([^&]+)/gi;
    const str: string = location.hash;
    let m;

    // eslint-disable-next-line no-constant-condition
    while (true) {
        m = regex.exec(str);
        if (m === null) {
            break;
        }
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        const [, key, value] = m;
        switch (key?.toLowerCase()) {
            case "access_token": {
                localStorage.setItem(LOCAL_STORAGE_KEYS.MXTS_TOKEN, value);
                setUserLoggedInCookie();
                break;
            }
            case UNIVERSAL_AUTH_TOKEN_HASH_PARAM: {
                setUniversalAuthToken(value);
                break;
            }
        }
    }

    location.hash = "";
}
