import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { CacheStrategy } from "@maxxton/cms-api";
import { ResultOptions } from "../plugins/widget";
import { isServerSide } from "./generic.util";

export async function warmupState<P, S>(props: P, defaultState: S, stateHandler: StateHandlerCallback<P, S>): Promise<S> {
    const setState = (state: any, callbackFunc?: () => void) => {
        if (typeof state === "function") {
            state = state(defaultState, props);
        }
        for (const field in state) {
            if (state[field] !== undefined) {
                (defaultState as any)[field] = state[field];
            }
        }
        if (callbackFunc) {
            callbackFunc();
        }
    };
    await stateHandler({
        setState,
        state: defaultState,
        props,
    });

    return defaultState;
}

export type StateHandlerCallback<P, S> = (stateHandler: StateHandler<P, S>) => Promise<void>;

export interface StateHandler<P, S> {
    setState<K extends keyof S>(state: ((prevState: Readonly<S>, props: Readonly<P>) => Pick<S, K> | S | null) | (Pick<S, K> | S | null), callback?: () => void): void;
    state: S;
    props: P;
}

export function shouldWarmupCache(context: CMSProviderProperties, resultOptions?: ResultOptions) {
    return isServerSide() && context.cacheStrategy === CacheStrategy.OPTIMIZED && (!resultOptions || Object.keys(resultOptions).length > 0);
}
