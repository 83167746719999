import { AdditionAction, AdditionActionType } from "../actions/additionAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";
import { SelectedAddition } from "../../plugins/dynamic/additions/additions.types";

export interface AdditionState {
    selectedAdditions?: { [key: number]: SelectedAddition };
}

export const additionReducer: Reducer<AdditionState> = (state: AdditionState = {}, action: AdditionAction): AdditionState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.Addition:
            switch (actionType) {
                case AdditionActionType.update:
                    return { selectedAdditions: { ...state.selectedAdditions, ...action.payload?.updatedAddition } };
                case AdditionActionType.set:
                    return { selectedAdditions: action.payload?.selectedAdditions };
                default:
                    return state;
            }
        default:
            return state;
    }
};
