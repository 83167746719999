import * as React from "react";

import { Widget as ApiWidget, CmsApiWrapper, Site, Template, TemplateApi, WithId } from "@maxxton/cms-api";
import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget, renderPageWidgets } from "../../";
import { ResultOptions, WidgetType, initDefaultFilterForPageApiWidgets } from "../../widget";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { ArrayUtil } from "../../../utils/array.util";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { FlexboxOptions } from "../flexbox";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { TemplateWidget } from "./Template";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import namespaceList from "../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../components/utils";
import { templateSpec } from "../../../form-specs/models";

export interface WidgetOptions {
    templateId: string | null;
    styleIds: any[];
    layHierarchy: boolean;
}

const TARGETS = ["template"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "template-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.widgetTemplate, "template"),
                                type: "autocomplete",
                                refType: templateSpec,
                            },
                            {
                                variable: "layHierarchy",
                                label: getI18nLocaleObject(namespaceList.widgetTemplate, "layHierarchy"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

/**
 * Prevents an endless loop when template A is inside template A. (bad config)
 */
export function removeSelfNestedTemplates(parentTemplateId: string, childTemplates: ApiWidget[]): ApiWidget[] {
    return ArrayUtil.spliceItems<ApiWidget>(
        childTemplates,
        (childTemplate) => childTemplate?.options?.templateId === parentTemplateId,
        (childTemplate) => childTemplate?.children
    );
}

export const templateWidget = (type: WidgetType): PageWidgetSpec<WidgetOptions> => ({
    id: "template",
    type,
    widgetGroup: WidgetGroup ? WidgetGroup.OTHER : 3,
    name: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidget"),
    description: getI18nLocaleObject(namespaceList.widgetTemplate, "templateWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        templateId: null,
        styleIds: [],
        layHierarchy: false,
    }),
    container: false,
    async render(
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions,
        shouldReturnProps?: boolean,
        allSites?: Array<Site & WithId>,
        flexboxOptions?: FlexboxOptions
    ) {
        const { templateId, styleIds } = widget.options;
        const hideWidget = setOpacityOnHide(widget.options);
        const template: (Template & WithId) | null = templateId ? await context.cmsApi.templateApi.findById({ id: templateId }) : null;
        if (template === null) {
            return <div className="text-center bg-info text-white p-3 mb-4">{getI18nLocaleString(namespaceList.widgetTemplate, "noTemplateConfigured")}</div>;
        }
        const classNames = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        const filteredRootTemplates = removeSelfNestedTemplates(template._id, template?.root);
        const children = await renderPageWidgets(filteredRootTemplates, context, sitemapPageLinkWidgetOptions);
        return <TemplateWidget hideWidget={hideWidget} children={children} classNames={classNames} template={template} options={widget.options} flexboxOptions={flexboxOptions} />;
    },
    async instanceDescription({ widget, context }): Promise<string> {
        const { templateId } = widget.options;
        const template: Template | null = templateId ? await getTemplateById(templateId, context.cmsApi) : null;
        if (template) {
            return template.name;
        }
        return getI18nLocaleString(namespaceList.widgetTemplate, "noTemplate");
    },
    async instances(): Promise<WidgetOptions[]> {
        const templates = await TemplateApi.find({ projection: { _id: 1 } });
        const instances = templates.map(
            (template): WidgetOptions => ({
                templateId: template._id,
                styleIds: [],
                layHierarchy: false,
            })
        );
        return instances;
    },
    async initDefaultFilter(widget: Widget<WidgetOptions>, context: CMSProvidedProperties, dispatcher) {
        const { templateId } = widget.options;
        const template: (Template & WithId) | null = templateId ? await context.cmsApi.templateApi.findById({ id: templateId }) : null;
        if (template) {
            const filteredRootTemplates = removeSelfNestedTemplates(template._id, template?.root);
            await initDefaultFilterForPageApiWidgets(filteredRootTemplates, context, dispatcher);
        }
    },
});
function getTemplateById(templateId: string, cmsApi: CmsApiWrapper): Promise<Template | null> {
    return cmsApi.templateApi.findByIdDebounced({ id: templateId, projection: { name: 1 } });
}
