import { ActionType } from "../actions";
import { Reducer } from "redux";
import { TravellerDetails } from "../../plugins/page/travel-party";
import { travelPartyAction } from "../actions/travelPartyAction";

export interface travelPartyState {
    travelPartyDetails?: TravellerDetails[];
}

export const travelPartyReducer: Reducer<travelPartyState> = (state: travelPartyState = {}, action: travelPartyAction): travelPartyState => {
    switch (action.type) {
        case ActionType.travelPartyDetails:
            return { ...state, travelPartyDetails: action.payload };
        default:
            return state;
    }
};
