export type DataLayerFields = {
    [key in typeof dataLayerContent[number]]?: any;
};

export const dataLayerContent = [
    "accokindids",
    "amenities",
    "bathroom",
    "bedroom",
    "bookdate",
    "deposit",
    "distributionchannel",
    "duration",
    "enddate",
    "flowid",
    "freesearchid",
    "language",
    "maxbathroom",
    "maxbedroom",
    "maximumarrivaldate",
    "minbathroom",
    "minbedroom",
    "minimumarrivaldate",
    "paymenttermsetid",
    "ratetypeid",
    "regionids",
    "reservationid",
    "resort",
    "resortids",
    "resourceid",
    "specialcode",
    "startdate",
    "status",
    "stay",
    "stayperioddefid",
    "subject",
    "subtotal",
    "total",
    "transactionaffiliation",
    "unitid",
] as const;

export const enhancedEcommType = {
    purchase: "purchase",
    checkout: "checkout",
};
