/* eslint-disable */

import { Translations } from "../index";

/**
 * THIS FILE IS GENERATED BY syncTerms.ts. DON'T MAKE ANY CHANGES HERE BECAUSE THEY WILL BE OVERRIDDEN DURING THE JENKINS BUILD
 */
export const poeditorTranslations: Translations = {
	da: () => import("./da"),
	nl: () => import("./nl"),
	en: () => import("./en"),
	fr: () => import("./fr"),
	de: () => import("./de"),
	hu: () => import("./hu"),
	ga: () => import("./ga"),
	it: () => import("./it"),
	pl: () => import("./pl"),
	es: () => import("./es"),
	wa: () => import("./wa")
};
/* eslint-enable */
