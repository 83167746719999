import { ContentState, convertFromRaw } from "draft-js";
import { Locale, LocaleApi, LocalizedContentBase, Menu, PageBase, Site, WithId, getFallbackLocaleIdsFromSettings } from "@maxxton/cms-api";
import { isEmpty, isObject } from "lodash";

import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { CurrentLocale } from "../app.types";
import { StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { getLanguageLocale } from "../plugins/mxts";
import { isClientSide } from "./generic.util";

export const getLocaleOptions = async (): Promise<StringMultiSelectOption[]> => {
    const localeList = await LocaleApi.find();
    return localeList.map((locale: Locale & WithId) => ({
        value: locale._id,
        text: locale.localeName,
    }));
};

export const getMxtsLocaleOptions = async (): Promise<StringMultiSelectOption[]> => {
    let localeList = await LocaleApi.find();
    localeList = localeList.filter((locale: Locale & WithId) => !!getLanguageLocale(locale.code)); // Only use the locales supported by MXTS
    return localeList.map((locale: Locale & WithId) => ({
        value: locale._id,
        text: locale.code,
    }));
};

export const transformLocaleIntoCurrentLocale = (locale: Locale & WithId): CurrentLocale => ({
    locale: locale._id,
    code: locale.code,
    name: locale.name,
    fallbackLocaleMultiSelect: locale.fallbackLocaleMultiSelect,
});

export const getLocalizedContentByContext = <LC extends LocalizedContentBase>(props: { context: CMSProviderProperties; localizedContentOptions?: LC[] }): LC | null => {
    const {
        context: { currentLocale, site },
        localizedContentOptions,
    } = props;
    if (localizedContentOptions) {
        return getLocalizedContent({ site, currentLocale, localizedContent: localizedContentOptions });
    }
    return null;
};

export const getLocalizedContent = <LC extends LocalizedContentBase, Key extends keyof LC>({
    site,
    keys = [],
    localizedContent = [],
    currentLocale,
}: {
    site: Site;
    keys?: Key[];
    localizedContent: LC[];
    currentLocale: CurrentLocale;
}): LC | null => {
    let preview;
    if (isClientSide()) {
        preview = document.querySelector(".backend");
    }
    if (!localizedContent.length) {
        return null;
    }

    let content: LC | null;

    // First try to obtain the localised content from the current locale
    content = localizedContent.find((lc) => lc.locale === currentLocale.locale) || null;
    if (content && validateKeys({ content, keys })) {
        return content;
    }

    // If we are in preview mode and no content was found, return null
    if (preview) {
        return null;
    }

    // If there is no content for the current locale, try to obtain it from the fallback locales
    const { fallbackLocaleMultiSelect: currentLocaleFallbackLocaleMultiSelect } = currentLocale;
    const currentLocaleFallbackLocaleIds: string[] = currentLocaleFallbackLocaleMultiSelect?.map((option: StringMultiSelectOption) => option.value) || [];
    const fallbackLocaleIds: string[] = getFallbackLocaleIdsFromSettings(site, currentLocaleFallbackLocaleIds);

    if (fallbackLocaleIds.length) {
        for (const localeId of fallbackLocaleIds) {
            content = localizedContent.find((lc) => lc.locale === localeId) || null;

            if (content && validateKeys({ content, keys })) {
                return content;
            }
        }
    }

    return null;
};

/**
 * Validates whether the content that is found is valid.
 * Content is not valid when it is an empty string or an empty RichText string.
 */
export const validateContentKeys = <LC extends LocalizedContentBase, Key extends keyof LC>({ content, keys }: { content: LC; keys: Key[] }): boolean =>
    keys.every((key) => {
        const keyContent = content[key];

        if (isObject(keyContent)) {
            return Object.keys(keyContent).length && Object.values(keyContent).some((value) => !isEmpty(value));
        }

        if (typeof keyContent === "string") {
            // We don't know from the code which string is actually a RichText, so we need to keep track of the different interface properties that store RichText in this array.
            const richTextKeys = ["richTextDesc", "content"];
            if (richTextKeys.includes(key as string)) {
                const convertedRichText: ContentState = convertFromRaw(JSON.parse(keyContent));
                return convertedRichText.hasText();
            }

            return !!keyContent.trim();
        }

        // For other types falsy values are allowed, as long as they are defined
        return keyContent !== null && keyContent !== undefined;
    });

export const validateKeys = <LC extends LocalizedContentBase, Key extends keyof LC>({ content, keys }: { content: LC; keys: Key[] }): boolean =>
    keys.every((key) => {
        const keyContent = content[key];

        if (isObject(keyContent)) {
            return Object.keys(keyContent).length && Object.values(keyContent).some((value) => !isEmpty(value));
        }

        if (typeof keyContent === "string") {
            return !!keyContent.trim();
        }

        // For other types falsy values are allowed, as long as they are defined
        return keyContent !== null && keyContent !== undefined;
    });

export const getLocaleCodeFromPath = (path?: string | null): string => {
    // Retrieve the locale code from the path
    const splitPath: string[] = path?.split("/") || [];
    let languagePath = "";
    if (splitPath.length > 1) {
        languagePath = splitPath[1]?.split("?")[0]; // The path always starts with a "/", so we need the second item in the split array
    }
    const isLanguagePresent = !!languagePath.match("^[a-zA-Z]{2}$") || !!languagePath.match("^[a-zA-Z]{2}-[a-zA-Z]{2}$");
    const localeCode = isLanguagePresent ? languagePath : "";

    return localeCode;
};

const updatePages = (options: any, linkingItem?: PageBase[], pages?: any) => {
    const filteredLinkingItem = (linkingItem && options?.linking?.localizedLinkButtonOptions.filter((item: { locale: string | undefined }) => item.locale === (linkingItem[0] as any)?.locale)) || [];
    if (options?.pageId && options?.siteId) {
        pages.push({
            pageId: options.pageId,
            siteId: options.siteId,
            localized: options.localized,
            useAsBreadcrumb: true, // Having this by default true as we this last root of the menu
        });
    } else {
        if ("linking" in options) {
            pages.push({
                pageId: filteredLinkingItem?.[0]?.pageId,
                siteId: filteredLinkingItem?.[0]?.siteId,
                localized: options.localized,
                useAsBreadcrumb: true, // Having this by default true as we this last root of the menu
            });
        }
    }
};

export const setMenuLinks = (item: Menu, pageId: string, pages: any, locale?: string) => {
    let isUpdated = true;
    const constructBreadcrumb = (item: any, path: PageBase[] = []) => {
        const newPath = [...path, item];
        const linkingItem = item.options?.linking?.localizedLinkButtonOptions.filter((item: { locale: string | undefined }) => item.locale === locale);
        if (isUpdated && (pageId === item.options.pageId || pageId === linkingItem?.[0]?.pageId)) {
            newPath.forEach((breadcrumbItem) => updatePages(breadcrumbItem.options, linkingItem, pages));
            isUpdated = false;
        } else if (item.children.length) {
            item.children.forEach((child: PageBase) => constructBreadcrumb(child, newPath));
        }
    };

    if ("children" in item && (item as any).children?.length) {
        const children = (item as any).children;
        for (item of children) {
            constructBreadcrumb(item);
        }
    }
};

// detect whether a device is a mobile device or not.
export const isMobileDeviceDetected = () => {
    if (isClientSide()) {
        const viewportWidth = window.innerWidth;
        return viewportWidth < 768;
    }
};
