import { ActionType } from "../actions";
import { AmenitiesAction } from "../actions/amenitiesAction";
import { ElasticFacetV2 } from "@maxxton/cms-mxts-api";
import { Reducer } from "redux";

export interface AmenitiesState {
    amenities: ElasticFacetV2;
}

// eslint-disable-next-line max-len
export const amenitiesReducer: Reducer<AmenitiesState> = (state: AmenitiesState = { amenities: [] }, action: AmenitiesAction): AmenitiesState => {
    switch (action.type) {
        case ActionType.AmenitiesFetch:
            return { ...state, amenities: action.payload };
        default:
            return state;
    }
};
