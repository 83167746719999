import * as React from "react";
import * as classNames from "classnames";

import { LocalizedTitleOptions, WidgetTitle } from "./localizedLableTitle.util";

import { CMSProviderProperties } from "../../containers/cmsProvider.types";
import { getLocalizedContentByContext } from "../../utils/localizedContent.util";

export function createLocalizedTitleAndLabel(widgetOptions: LocalizedTitleOptions, context: CMSProviderProperties) {
    const { localizedWidgetTitle, enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor } = widgetOptions;
    const widgetTitleLocalizedContent: WidgetTitle | null = getLocalizedContentByContext({ context, localizedContentOptions: localizedWidgetTitle });
    return (
        <LocalizedTitleAndLabel
            localizedTitle={widgetTitleLocalizedContent?.widgetTitleText || ""}
            enableWidgetTitle={enableWidgetTitle}
            useTitleHeadings={useTitleHeadings}
            styleWidgetTitle={styleWidgetTitle}
            className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
            style={classNames(fontColor?.includes("rgba") && fontColor)}
        />
    );
}

export interface LocalizedTitleProps {
    className?: string;
    localizedTitle?: string;
    enableWidgetTitle?: boolean;
    useTitleHeadings?: string;
    styleWidgetTitle?: boolean;
    style?: string;
}
export interface LocalizedLabelProps {
    classNameLabel?: string;
    localizedLabel?: string;
    enableWidgetLabel?: boolean;
    labelHeadingType?: string;
    styleWidgetLabel?: boolean;
    styleLabel?: string;
}
const labelHeading = ["p", "h6", "h5", "h4", "h3", "h2", "h1"];
type LocalizedTitleAndLabelProps = LocalizedTitleProps & LocalizedLabelProps;
const LocalizedTitleAndLabel = (props: LocalizedTitleAndLabelProps) => {
    const {
        localizedTitle,
        enableWidgetTitle,
        useTitleHeadings,
        styleWidgetTitle,
        classNameLabel,
        className,
        style,
        styleLabel,
        localizedLabel,
        enableWidgetLabel,
        labelHeadingType,
        styleWidgetLabel,
    } = props;
    const TagTitle = (useTitleHeadings || "div") as keyof JSX.IntrinsicElements;
    const TagLabel = (labelHeadingType || "div") as keyof JSX.IntrinsicElements;
    if (enableWidgetTitle) {
        return useTitleHeadings && styleWidgetTitle ? (
            <TagTitle className={className} style={{ color: style?.includes("rgba") ? style : undefined }}>
                {localizedTitle}
            </TagTitle>
        ) : (
            <div className="widget-heading">{localizedTitle}</div>
        );
    }
    if (enableWidgetLabel) {
        return labelHeadingType && labelHeading.includes(labelHeadingType) && styleWidgetLabel ? (
            <TagLabel style={{ color: styleLabel?.includes("rgba") ? styleLabel : undefined }}>{localizedLabel}</TagLabel>
        ) : (
            <div className={classNames(labelHeadingType)} style={{ color: styleLabel?.includes("rgba") ? styleLabel : undefined }}>
                {localizedLabel}
            </div>
        );
    }
    return null;
};
export default LocalizedTitleAndLabel;
