import { Theme } from ".";

import baseTheme from "./base";

const theme: Theme = {
    id: "ourthesomme",
    name: "OurtheSomme Theme",
    stylesheets: ["/styles/themes/ourthesomme/ourthesomme-admin.css"],
    frontendStylesheets: ["/styles/themes/ourthesomme/ourthesomme.css"],
    styles: [...baseTheme.styles],
};

export default theme;
