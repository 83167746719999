import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";
import { MxtsApi, ResourceActivitiesDetailsResponse } from "@maxxton/cms-mxts-api";
import { isArray, map } from "lodash";

import { ACTIVITY } from "../components/utils";
import { ContentType } from "../components/components.enum";
import { ISelect } from "../components/generic-form/asyncSelect.types";
import { InputSpecAsyncSelect } from "../form-specs";
import { MXTS } from "../utils/constants";
import { getAdminMxtsEnv } from "../plugins/mxts";
import namespaceList from "../i18n/namespaceList";

async function getActivityOptions(): Promise<Array<ISelect<number>>> {
    const env = await getAdminMxtsEnv();
    const activitiesDetails: ResourceActivitiesDetailsResponse[] = await MxtsApi.getResourceActivitiesDetails(env, {
        size: MXTS.MAX_RESULTS,
    }).then((pagedResult) => pagedResult.content);
    return map(
        activitiesDetails,
        (activityDetails): ISelect<number> => {
            const {
                resourceActivityDetailsId,
                resourceActivity: { name },
            } = activityDetails;
            return {
                value: resourceActivityDetailsId,
                label: name || "",
            };
        }
    );
}

function activitiesAutocomplete<E, P extends keyof E>(variable: string, label?: I18nLocaleObject | string, isVisible?: boolean): InputSpecAsyncSelect<E, P> {
    return {
        variable: (variable as string) as P,
        label: label || getI18nLocaleObject(namespaceList.dynamicPlugin, "activityIds"),
        type: "asyncSelect",
        isClearable: true,
        async optionList() {
            return getActivityOptions();
        },
        isMulti: true,
        placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
        visible: (options: any) => {
            const { contentType, contentTypes } = options;
            if (isVisible) {
                return true;
            } else if (isArray(contentTypes)) {
                let selected = false;
                contentTypes.forEach((contentType) => {
                    if (contentType.value === ContentType.ACTIVITY) {
                        selected = true;
                    }
                });
                return selected;
            }
            return contentType === ACTIVITY;
        },
    };
}

export default activitiesAutocomplete;
