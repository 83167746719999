/**
 * Util for obtaining box model stylings and properties.
 */

export class BoxModelUtil {
    /**
     * Provides the required styling for highlighting element
     * @param color It's a dynamic color, mostly selected from the color picker
     * @returns styling object
     */
    public static highlightStyling(color: string) {
        return {
            borderColor: color,
            boxShadow: `inset 0 0 0.375rem 0.187rem ${color}`,
        };
    }
}
