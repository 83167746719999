import { Theme } from ".";
import baseTheme from "./base";

const theme: Theme = {
    id: "corporatehousingfactoryPWA",
    name: "Corporate Housing Factory PWA Theme",
    stylesheets: ["/styles/themes/corporatehousingfactorypwa/corporatehousingfactorypwa-admin.css"],
    frontendStylesheets: ["/styles/themes/corporatehousingfactorypwa/corporatehousingfactorypwa.css"],
    styles: [
        ...baseTheme.styles,
        {
            id: "my-env-button",
            name: "My Environment button",
            targets: ["button"],
            classnames: ["my-env-button"],
        },
    ],
};

export default theme;
