// Be careful while sorting imports of this file as it breaks the build
/* eslint-disable sort-imports */
import { FormSpec, HierarchyOptions, HierarchyTemplate, InputSpecHierarchy, mergeProperties } from "..";
import { ArrayUtil } from "../../utils/array.util";

// eslint-disable-next-line max-lines-per-function
export function widgetHierarchy<E, P extends keyof E>({ label, variable, rootTitle, templatesTitle, rootType, widgetType }: WidgetHierarchy<E, P>): InputSpecHierarchy<E, P> {
    const hierarchyOptions: HierarchyOptions<Api.Widget> = {
        rootType,
        childVariable: "children",
        optionsVariable: "options",
        rootTitle,
        templatesTitle,
        // eslint-disable-next-line max-lines-per-function
        async templates({ context, excludeIds }): Promise<Array<HierarchyTemplate<Api.Widget>>> {
            async function mapWidgetToTemplate(widget: WidgetSpec<any> | DynamicWidgetSpec): Promise<HierarchyTemplate<Api.Widget>> {
                if ((widget as any).pathToFile) {
                    const dynamicWidget = widget as DynamicWidgetSpec;
                    const module = await dynamicWidget.pathToFile(context);
                    const func = module[dynamicWidget.targetName];
                    if (typeof func === "function") {
                        // Execute the function with the provided parameters
                        if (dynamicWidget.parameters) {
                            widget = func(...dynamicWidget.parameters) as WidgetSpec<any>;
                        } else {
                            widget = func() as WidgetSpec<any>;
                        }
                    } else if (typeof func === "object") {
                        widget = func as WidgetSpec<any>;
                    }
                }
                async function mapInstanceToTemplate(instance: Instance<any>): Promise<HierarchyTemplate<Api.Widget>> {
                    if (isInstanceGroup(instance)) {
                        return {
                            title: instance.name,
                            description: instance.description,
                            create: () => {
                                throw new Error("Cannot instantiate instance group");
                            },
                            singleton: false,
                            children: await Promise.all(instance.children.map(mapInstanceToTemplate)),
                        };
                    }
                    widget = widget as WidgetSpec<any>;
                    const pseudoWidget: Widget<any> = {
                        _id: "",
                        children: [],
                        options: instance,
                        spec: widget,
                    };
                    const title = widget.instanceName ? await widget.instanceName({ widget: pseudoWidget }) : widget.name;
                    const description = widget.instanceDescription ? await widget.instanceDescription({ widget: pseudoWidget, context }) : widget.description;
                    return {
                        title,
                        description,
                        create: (): Api.Widget => ({
                            type: widget.id,
                            children: [],
                            options: instance,
                        }),
                        singleton: widget.singleton === true,
                        children: [],
                    };
                }
                widget = widget as WidgetSpec<any>;
                const instances = (widget.instances ? await widget.instances() : []).filter(
                    (widgetInstance) => !widgetInstance?.templateId || !excludeIds?.length || !ArrayUtil.includes(excludeIds, widgetInstance?.templateId)
                );
                return {
                    title: widget.name,
                    description: widget.description,
                    create: (): Api.Widget => ({
                        type: widget.id,
                        children: [],
                        options: (widget as WidgetSpec<any>).defaultOptions(),
                    }),
                    singleton: widget.singleton === true,
                    children: await Promise.all(instances.map(mapInstanceToTemplate)),
                };
            }

            const getPseudoGroupWidget = async (widgetGroup: DynamicWidgetSpec[], title: string, description: string): Promise<HierarchyTemplate<Api.Widget>> => ({
                title: getI18nLocaleObject(namespaceList.admin, title),
                description: getI18nLocaleObject(namespaceList.admin, description),
                create: () => {
                    const alerts = new Alerts();
                    alerts.push({
                        color: "danger",
                        message: getI18nLocaleString(namespaceList.admin, "nonInstantiable"),
                    });
                    throw new Error("Cannot instantiate instance group");
                },
                singleton: false,
                children: await Promise.all(widgetGroup.filter((widget) => widget.type === widgetType).map(mapWidgetToTemplate)),
            });

            const dynamicWidgetGroup = getPseudoGroupWidget(dynamicWidgets, "dynamicWidgets", "dynamicWidgetsDescription");

            const layoutWidgetGroup = getPseudoGroupWidget(layoutWidgets, "layoutWidgets", "layoutWidgetsDescription");

            const contentWidgetGroup = getPseudoGroupWidget(contentWidgets, "contentWidgets", "contentWidgetsDescription");

            const bookingsModuleWidgetGroup = getPseudoGroupWidget(bookingsModuleWidgets, "bookingsModuleWidgets", "bookingsModuleWidgetsDescription");

            const myEnvironmentWidgetGroup = getPseudoGroupWidget(myEnvironmentWidgets, "myEnvironmentWidgets", "myEnvironmentWidgetsDescription");

            const displayWidgetGroup = getPseudoGroupWidget(displayWidgets, "resultsPanelDisplay", "resultsPanelDisplayDescription");

            const inputWidgetGroup = getPseudoGroupWidget(inputWidgets, "formInputGroup", "formInputGroupDescription");
            const otherWidgetGroup =
                widgetType === "page" || widgetType === "resultsPanel"
                    ? getPseudoGroupWidget(otherWidgets, "otherWidgets", "otherWidgetsDescription")
                    : otherWidgets.filter((widget) => widget.type === widgetType).map(mapWidgetToTemplate);

            if (widgetType === "page") {
                return Promise.all([
                    layoutWidgetGroup,
                    contentWidgetGroup,
                    dynamicWidgetGroup,
                    bookingsModuleWidgetGroup,
                    myEnvironmentWidgetGroup,
                    otherWidgetGroup as Promise<HierarchyTemplate<Api.Widget>>,
                ]);
            } else if (widgetType === "resultsPanel") {
                return Promise.all([
                    layoutWidgetGroup,
                    contentWidgetGroup,
                    dynamicWidgetGroup,
                    displayWidgetGroup,
                    bookingsModuleWidgetGroup,
                    myEnvironmentWidgetGroup,
                    otherWidgetGroup as Promise<HierarchyTemplate<Api.Widget>>,
                ]);
            } else if (widgetType === "form") {
                return Promise.all([layoutWidgetGroup, contentWidgetGroup, inputWidgetGroup]);
            } else if (widgetType === "menu") {
                return Promise.all([layoutWidgetGroup, ...(otherWidgetGroup as Array<Promise<HierarchyTemplate<Api.Widget>>>)]);
            }
            return Promise.all([...(otherWidgetGroup as Array<Promise<HierarchyTemplate<Api.Widget>>>)]);
        },
        itemTitle: async ({ item, context }): Promise<I18nLocaleObject | string> => {
            const widget = await parseApiWidget(item, context);
            if (typeof widget.spec.instanceName === "function") {
                return widget.spec.instanceName({ widget });
            }
            return Promise.resolve(widget.spec.name);
        },
        itemSubtitle: async ({ item, context }): Promise<I18nLocaleObject | string> => {
            const widget = await parseApiWidget(item, context);
            if (typeof widget.spec.instanceDescription === "function") {
                return widget.spec.instanceDescription({ widget, context });
            }
            return Promise.resolve(widget.spec.description);
        },
        itemAdditionalInfo: async ({ item, context }): Promise<string> => {
            const widget = await parseApiWidget(item, context);
            if (typeof widget.spec.instanceAdditionalInfo === "function") {
                return widget.spec.instanceAdditionalInfo({ widget });
            }
            return "";
        },
        async itemOptions({ item, parent, context }) {
            const widget = (await parseApiWidget(item, context))?.spec;
            const parentWidget = parent != null ? (await parseApiWidget(parent, context))?.spec : null;
            if (parentWidget != null && parentWidget.childOptions !== undefined && parent) {
                return {
                    ...widget.optionsForm,
                    properties: mergeProperties(widget.optionsForm.properties, parentWidget.childOptions, parent),
                };
            }
            return widget.optionsForm;
        },
        itemContainer: ({ item }) => findWidget(item.type).container === true,
    };

    return {
        label,
        variable,
        type: "hierarchy",
        hierarchyOptions,
        widgetType,
    };
}

import { categorySpec } from "./category";
import { dataLayerSpec } from "./dataLayer";
import { dynamicSitemapSpec } from "./dynamicSitemap";
import { flowSpec } from "./flow";
import { formSpec } from "./form";
import { localeSpec } from "./locale";
import { menuSpec } from "./menu";
import { pageSpec } from "./page";
import { postSpec } from "./post";
import { siteSpec } from "./site";
import { templateSpec } from "./template";
import { translationSpec } from "./translation";
import { webcontentSpec } from "./webContent";

export * from "./dataLayer";
export * from "./dynamicSitemap";
export * from "./flow";
export * from "./form";
export * from "./freeSearch";
export * from "./locale";
export * from "./menu";
export * from "./page";
export * from "./post";
export * from "./site";
export * from "./category";
export * from "./template";
export * from "./translation";
export * from "./webContent";

import { getI18nLocaleObject, getI18nLocaleString, I18nLocaleObject } from "../../i18n";

import * as Api from "@maxxton/cms-api";
import { Alerts } from "../../alerts";
import namespaceList from "../../i18n/namespaceList";
import {
    DynamicWidgetSpec,
    Instance,
    Widget,
    WidgetSpec,
    WidgetType,
    bookingsModuleWidgets,
    contentWidgets,
    displayWidgets,
    dynamicWidgets,
    findWidget,
    inputWidgets,
    isInstanceGroup,
    layoutWidgets,
    myEnvironmentWidgets,
    otherWidgets,
    parseApiWidget,
} from "../../plugins";
import { freeSearchSpec } from "./freeSearch";
import { conditionalSetSpec } from "./conditionalSet";
import { resultsPanelSpec } from "./resultsPanel";

export const mainSpecs: Array<FormSpec<any>> = [siteSpec, pageSpec, webcontentSpec, postSpec, freeSearchSpec, conditionalSetSpec, categorySpec, templateSpec, menuSpec, formSpec];

export const modelSpecs: Array<FormSpec<any>> = [localeSpec, translationSpec];

export const dynamicSpecs: Array<FormSpec<any>> = [flowSpec, resultsPanelSpec, dynamicSitemapSpec, dataLayerSpec];

export interface WidgetHierarchy<E, P> {
    label: I18nLocaleObject | string;
    rootTitle: I18nLocaleObject | string;
    templatesTitle: I18nLocaleObject | string;

    variable: P;
    rootType: "single" | "multiple";
    widgetType: WidgetType;
}
