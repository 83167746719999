import { UserPageLock, getAllPageLocks } from "../components/admin/utils";
import { PageLock } from "./server/websocket/PageLockWsMessageHandler";

const DEBOUNCER_TIMEOUTS: { [key: string]: ReturnType<typeof setTimeout> } = {};
let debouncers: Debouncer[] = [];

export interface Debouncer {
    key: string;
    queue: Array<{ id?: string; resolve: (obtainedItem: any) => void; reject: (err: any) => void }>;
    trigger: () => void;
}

/**
 * Used for debouncing a variety of calls
 */
export class DebounceUtil {
    public static async getAllPageLocksDebounced(debounceMs = 50): Promise<UserPageLock[]> {
        const debouncerKey = "allPageLocks";
        const debouncer = DebounceUtil.getDebouncerByKey(debouncerKey, debounceMs, async () => {
            // debounce finally firing. Let's go!
            const currentDebouncer = DebounceUtil.getDebouncerByKey(debouncerKey, debounceMs);
            // remove the debouncer so no new bounces come on this debouncer while it's executing the rest call
            this.removeDebouncer(debouncerKey);
            const allPageLocks: UserPageLock[] = await getAllPageLocks();
            currentDebouncer.queue.forEach((queueItem) => queueItem.resolve(allPageLocks));
        });
        const itemObtainedPromise = new Promise<UserPageLock[]>((resolve, reject) => {
            debouncer.queue.push({ resolve, reject });
        });
        debouncer.trigger();
        return itemObtainedPromise;
    }

    public static async getPageLockByIdDebounced(pageId: string): Promise<{ userId: number } | undefined> {
        const debouncerKey = "pageLockById";
        const debounceMs = 50;
        const debouncer = DebounceUtil.getDebouncerByKey(debouncerKey, debounceMs, async () => {
            // debounce finally firing. Let's go!
            const currentDebouncer = DebounceUtil.getDebouncerByKey(debouncerKey, debounceMs);
            // remove the debouncer so no new bounces come on this debouncer while it's executing the rest call
            this.removeDebouncer(debouncerKey);
            const pageIds = currentDebouncer.queue.map((queueItem) => queueItem.id);
            const allPageLocks: UserPageLock[] = await DebounceUtil.getAllPageLocksDebounced(10);
            currentDebouncer.queue.forEach((queueItem) => {
                const obtainedPage = allPageLocks.find((pageLock) => pageLock?.pageId === queueItem.id);
                queueItem.resolve(obtainedPage ? { userId: obtainedPage.userId } : obtainedPage);
            });
        });
        const pageObtainedPromise = new Promise<{ userId: number } | undefined>((resolve, reject) => {
            debouncer.queue.push({ id: pageId, resolve, reject });
        });
        debouncer.trigger();
        return pageObtainedPromise;
    }

    private static getDebouncerByKey(debouncerKey: string, debounceMs: number, obtainAll?: () => any): Debouncer {
        let debouncer: Debouncer | undefined = debouncers.find((debouncer: Debouncer) => debouncer.key === debouncerKey);
        if (!debouncer) {
            debouncer = {
                key: debouncerKey,
                queue: [],
                trigger: () => {
                    const timeoutVarKey = "debounceTimeout" + debouncerKey;
                    if (DEBOUNCER_TIMEOUTS[timeoutVarKey]) {
                        clearTimeout(DEBOUNCER_TIMEOUTS[timeoutVarKey]);
                    }
                    DEBOUNCER_TIMEOUTS[timeoutVarKey] = setTimeout(() => {
                        delete DEBOUNCER_TIMEOUTS[timeoutVarKey];
                        obtainAll?.();
                    }, debounceMs);
                },
            };
            debouncers.push(debouncer);
        }
        return debouncer;
    }

    private static removeDebouncer(debouncerKey: string) {
        debouncers = debouncers.filter((debouncer: Debouncer) => debouncer.key !== debouncerKey);
    }
}
