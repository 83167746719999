import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import {
    ACTIVITY,
    CATEGORY,
    CONTENT_TAGS,
    CUSTOM_FIELD,
    DATE,
    DESCRIPTION,
    DESCRIPTION2,
    DYNAMIC_FIELD_CODE,
    LOCATION,
    NAME,
    RESERVATION,
    RESORTS,
    SHORT_DESCRIPTION,
    SIGN_UP,
    ServerSideProps,
    TIME,
    TITLE,
    UNIT,
    getHideWidgetClass,
    isClientLoggedIn,
} from "../../../components/utils";
import { ApiCallOptions, CustomFieldResult, Resort, Resource, ResourceActivity, Unit } from "@maxxton/cms-mxts-api";
import { ConfiguredLink, getLinkFromLinkingSpec, getUrlWithAnchor, linkingSpecDefaultValues } from "../../../utils/linking.util";
import { Content, isContentFilterChanged } from "../../../utils/content.util";
import { GetEditableModal, getEditableButton } from "../container/myenv/myEnvContainerEditable.util";
import { WidgetOptions, widgetOptionsForm } from "./";
import { backgroundColorPicker, fontColorPicker } from "../../../utils/colorpicker.util";
import { getI18nLocaleObject, getI18nLocaleString, wrapProps } from "../../../i18n";
import { getOverallDescriptions, loadDescription } from "./description.utils";

import { ActionType } from "../../../redux/actions";
import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { ActivityDynamicField } from "../../page/activityPlanner/ActivityDynamicField";
import { ActivityInfo } from "../../page/activityPlanner/activityPlanner.enum";
import { ActivityLocation } from "../../page/activityPlanner/activityLocation";
import { ActivityPlannerState } from "../../../redux/reducers/activityPlannerReducer";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { Badge } from "reactstrap";
import { DATE_FORMAT } from "../../../utils/constants";
import { DescriptionEditor } from "./DescriptionEditor";
import { DescriptionResponse } from "./description.type";
import { DescriptionResult } from "../../resultsPanel/description/ResultsPanelDescription";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec } from "../../";
import { RecaptchaProviderEnabler } from "../../page/guestInterfaceWidget/recaptcha/reCaptcha.util";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { WidgetGroup } from "../../widget.enum";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { getSharedOwnersUnits } from "../../../utils/sharedOwnership.util";
import { isEqual } from "lodash";
import namespaceList from "../../../i18n/namespaceList";
import withEditModal from "../../../components/frontend-editing/WithEditModal";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

export interface DescriptionProps extends DynamicWidgetBaseProps<WidgetOptions>, DescriptionStoreProps, DescriptionDispatchProps {
    link: ConfiguredLink;
    activity?: Activity;
    childOptions?: DynamicMyEnvContainerChildOptions;
}

export interface ReservedResourcesNameFrequency {
    resourceName: string;
    frequency: number;
}

interface DescriptionLoadContentProps extends DescriptionProps {
    skipContentTypeSelectorCheck?: boolean;
}

export interface DescriptionState {
    descriptions: DescriptionResult[];
    dynamicFieldsDescriptions: DescriptionResult[];
    fallbackDescriptions: DescriptionResult[];
    dynamicFieldsFallbackDescriptions: DescriptionResult[];
    content?: Exclude<Content | Content[], Resort[]>;
    contents?: Exclude<Content[], Resort[]>;
    env?: ApiCallOptions;
    isLoading: boolean;
    activity?: Activity;
    reservedResourcesName?: string[];
    reservedResourcesNameWithFrequency?: ReservedResourcesNameFrequency[];
    selectedCustomFields?: CustomFieldResult[];
    isEditModalOpen?: boolean;
    isDescriptionsUpdating?: boolean;
    parentContent?: Resource & TipsAndTripsContent;
    isSharedOwnershipUnit?: boolean;
}
interface DescriptionDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction>;
}
interface DescriptionStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    activityPlannerState?: ActivityPlannerState;
}

export interface TipsAndTripsContent {
    title?: string;
    headText?: string;
    text?: string;
}

export enum CUSTOM_FIELDS_DATA_TYPE {
    DATE = "DATE", // dateValue
    BOOLEAN = "BOOLEAN", // booleanValue
    SELECTION_EXCL = "SELECTION_EXCL", // selectValue
    SELECTION_INCL = "SELECTION_INCL", // selectValue
    NUMERIC = "NUMERIC", // numericValue
    MEMO = "MEMO", // textValue
    TEXT = "TEXT", // textValue
}

export interface ResourceActivityResult extends ResourceActivity {
    resortName?: string;
}

class DescriptionWidget extends React.PureComponent<DescriptionProps, DescriptionState> {
    constructor(props: DescriptionProps) {
        super(props);
        const {
            descriptions,
            dynamicFieldsDescriptions,
            fallbackDescriptions,
            dynamicFieldsFallbackDescriptions,
            reservedResourcesName,
            activity,
            reservedResourcesNameWithFrequency,
            selectedCustomFields,
            content,
            contents,
            parentContent,
        } = props?.fetchedData ?? ({} as DescriptionResponse);
        this.state = {
            descriptions: descriptions || [],
            dynamicFieldsDescriptions: dynamicFieldsDescriptions || [],
            fallbackDescriptions: fallbackDescriptions || [],
            dynamicFieldsFallbackDescriptions: dynamicFieldsFallbackDescriptions || [],
            isLoading: false,
            reservedResourcesName: reservedResourcesName || [],
            selectedCustomFields: selectedCustomFields || [],
            isEditModalOpen: false,
            activity,
            content,
            contents,
            reservedResourcesNameWithFrequency: reservedResourcesNameWithFrequency || [],
            parentContent,
        };
    }

    public componentDidMount() {
        const {
            context: { site },
            options: { contentType, descriptionTypeMultiSelector },
        } = this.props;
        this.loadDescriptions();
        if (site.showSharedOwnership && contentType === UNIT && descriptionTypeMultiSelector?.some((option) => option.value === NAME)) {
            this.checkSharedOwnerShipDetails();
        }
    }

    public componentDidUpdate(prevProps: DescriptionProps) {
        const {
            context: { site },
            options: { contentType, descriptionTypeMultiSelector },
        } = this.props;
        if (isContentFilterChanged(prevProps, this.props) || !isEqual(this.props.options, prevProps.options)) {
            this.loadDescriptions();
        }
        if (site.showSharedOwnership && contentType === UNIT && descriptionTypeMultiSelector?.some((option) => option.value === NAME)) {
            this.checkSharedOwnerShipDetails();
        }
    }

    private async checkSharedOwnerShipDetails() {
        const { context } = this.props;
        const { content } = this.state;
        const env = await getMxtsEnv(context);
        const sharedOwnersUnitIds = await getSharedOwnersUnits(env);
        this.setState({ isSharedOwnershipUnit: sharedOwnersUnitIds?.includes((content as Unit)?.unitId) });
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { className, options, context, dynamicContainerOptions, childOptions } = this.props;
        const overallDescriptions = getOverallDescriptions(this.props, this.state);
        const disableWidget = !isClientLoggedIn();
        const hideWidget = getHideWidgetClass(options, disableWidget);
        const { isLoading, reservedResourcesName, reservedResourcesNameWithFrequency, isEditModalOpen, isDescriptionsUpdating, isSharedOwnershipUnit } = this.state;
        const { currentLocale, site } = context;
        const {
            enableWidgetTitle,
            useTitleHeadings,
            styleWidgetTitle,
            fontColor,
            descFontColor,
            nameStyle,
            nameTextBackground,
            nameTextUndrlinedSize,
            nameTextUndrlinedColor,
            deleteOptionForUnit,
            descFontStyle,
            descFontSize,
            descriptionTypeMultiSelector,
            contentType,
            hideSingleAccoCount,
        } = options;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        if (isLoading || isDescriptionsUpdating) {
            return (
                <div className="description-loading">
                    {descriptionTypeMultiSelector?.map((descriptionType, key) => (
                        <Loader key={key} type="description" views={descriptionType.value} />
                    ))}
                </div>
            );
        }
        if (hideWidget === null) {
            return null;
        }
        const styles: any = {
            color: descFontColor?.indexOf("rgba") > -1 ? descFontColor : undefined,
            borderColor: nameStyle === "row-bordered" && nameTextUndrlinedColor && nameTextUndrlinedColor.indexOf("rgba") > -1 ? nameTextUndrlinedColor : undefined,
            backgroundColor: nameStyle === "background-text" && nameTextBackground && nameTextBackground.indexOf("rgba") > -1 ? nameTextBackground : undefined,
        };
        const ContentTag = options?.contentTag && CONTENT_TAGS.some((contentTag) => contentTag === options.contentTag) ? (options.contentTag as keyof JSX.IntrinsicElements) : "div";
        const isActivitySelected = contentType === ACTIVITY || dynamicContainerOptions?.contentType === ACTIVITY;
        const isCustomFieldSelected = options.customFieldIds?.length && options.contentType === RESERVATION && options.descriptionTypeMultiSelector?.find((check) => check.value === CUSTOM_FIELD);
        return (
            <div
                className={classNames("display-desc", hideWidget, {
                    [contentType + "__description"]: !!contentType,
                    inline: options.displayTitleInline,
                })}
            >
                <LocalizedTitleAndLabel
                    localizedTitle={localizedWidgetTitle}
                    enableWidgetTitle={enableWidgetTitle}
                    useTitleHeadings={useTitleHeadings}
                    styleWidgetTitle={styleWidgetTitle}
                    className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                    style={classNames(fontColor?.includes("rgba") && fontColor)}
                />
                {isActivitySelected
                    ? this.renderActivity(descriptionTypeMultiSelector)
                    : isCustomFieldSelected
                    ? this.renderCustomFields(options.customFieldIds)
                    : overallDescriptions.map((description, index) => {
                          const { imageUrl, value } = description;
                          if (value || imageUrl) {
                              return (
                                  <React.Fragment key={index}>
                                      <ContentTag
                                          key={index}
                                          // eslint-disable-next-line max-len
                                          className={` ${className} ${descFontSize || ""}
                                    ${fontColorPicker(descFontColor)}
                                    ${nameStyle && nameTextBackground && nameTextUndrlinedColor && backgroundColorPicker(nameStyle, nameTextBackground, nameTextUndrlinedColor)}
                                    ${descFontStyle ? descFontStyle : ""}
                                    border-width-${nameStyle === "row-bordered" && nameTextUndrlinedSize ? nameTextUndrlinedSize + " pb-1 border-bottom-style-solid" : ""} m-0 ${
                                              reservedResourcesName?.length ? "reserved-resources-name" : ""
                                          }`}
                                          style={styles}
                                      >
                                          {reservedResourcesName?.length
                                              ? reservedResourcesNameWithFrequency?.map((resource, index) => (
                                                    <span key={index}>
                                                        {hideSingleAccoCount && resource.frequency === 1 ? resource.resourceName : `${resource.frequency} x ${resource.resourceName}`}
                                                    </span>
                                                ))
                                              : this.renderDescription(description, index)}
                                      </ContentTag>
                                      {deleteOptionForUnit && (
                                          <span onClick={() => this.handleRemovePreference()} className="unit-preference">
                                              {getI18nLocaleString(namespaceList.dynamicPlugin, "removeUnitPreference", currentLocale, site)}
                                          </span>
                                      )}
                                      {!!isSharedOwnershipUnit && (
                                          <div className="shared-ownership-badge-wrapper">
                                              <Badge className="shared-ownership-badge" pill>
                                                  {getI18nLocaleString(namespaceList.admin, "sharedOwnership", currentLocale, site)}
                                              </Badge>
                                          </div>
                                      )}
                                  </React.Fragment>
                              );
                          }
                      })}
                {childOptions?.isEditable && getEditableButton(this.toggleModal, getI18nLocaleString(namespaceList.descriptionWidget, "editDescription", currentLocale, site))}
                {isEditModalOpen && (
                    <GetEditableModal
                        toggleModal={this.toggleModal}
                        modalHeading={getI18nLocaleString(namespaceList.descriptionWidget, "editDescription", currentLocale, site)}
                        modalBodyData={this.renderEditableDescriptionFields}
                        sizeOfModal="sm"
                        isEditModalOpen={isEditModalOpen}
                    />
                )}
            </div>
        );
    }

    // eslint-disable-next-line max-lines-per-function
    private renderActivity(descriptionTypeMultiSelector?: NumberMultiSelectOption[]) {
        const { activity } = this.state;
        const { className, options, context, dynamicFilter } = this.props;
        const { currentLocale, site } = context;
        const { descFontColor, nameStyle, nameTextBackground, nameTextUndrlinedSize, nameTextUndrlinedColor, changeDefaultActivityAreaLabel, changeDefaultActivitySignUpAreaLabel } = options;
        const ContentTag = (options?.contentTag as keyof JSX.IntrinsicElements) || "div";
        const styles: React.CSSProperties = {
            color: options.descFontColor?.indexOf("rgba") > -1 ? options.descFontColor : undefined,
            borderColor: options.nameStyle === "row-bordered" && options.nameTextUndrlinedColor && options.nameTextUndrlinedColor.indexOf("rgba") > -1 ? options.nameTextUndrlinedColor : undefined,
            backgroundColor: options.nameStyle === "background-text" && options.nameTextBackground && options.nameTextBackground.indexOf("rgba") > -1 ? options.nameTextBackground : undefined,
        };
        function getStyleClassName(options: WidgetOptions) {
            return classNames(
                className,
                options.descFontSize,
                options.descFontStyle,
                `${fontColorPicker(descFontColor)} ${
                    nameStyle && nameTextBackground && nameTextUndrlinedColor && backgroundColorPicker(nameStyle, nameTextBackground, nameTextUndrlinedColor)
                } border-width-${nameStyle === "row-bordered" && nameTextUndrlinedSize ? nameTextUndrlinedSize + " pb-1 border-bottom-style-solid" : ""} m-0`
            );
        }
        if (activity) {
            return descriptionTypeMultiSelector?.map((selectedInfo) => {
                switch (selectedInfo.value) {
                    case TITLE:
                        return (
                            <ContentTag className={classNames("title activity-item", getStyleClassName(options))} style={styles}>
                                {dynamicFilter.showMainActivityOnPage ? activity.resourceActivity.resortActivity.title : activity.resourceActivity?.name}
                            </ContentTag>
                        );
                    case DESCRIPTION:
                        return (
                            <p className={classNames("description activity-item", getStyleClassName(options))} style={styles}>
                                {dynamicFilter.showMainActivityOnPage ? activity.resourceActivity.resortActivity.description : activity.resourceActivity?.description}
                            </p>
                        );
                    case DESCRIPTION2:
                        return (
                            <p className={classNames("description activity-item", getStyleClassName(options))} style={styles}>
                                {activity.resourceActivity?.description2}
                            </p>
                        );
                    case SHORT_DESCRIPTION:
                        return (
                            <p className={classNames("description activity-item", getStyleClassName(options))} style={styles}>
                                {activity.resourceActivity?.shortDescription}
                            </p>
                        );
                    case TIME:
                        return (
                            <div className={classNames("time activity-item", getStyleClassName(options))} style={styles}>
                                {activity && (dynamicFilter.resourceActivityDetailsId || dynamicFilter.resourceActivityDetailsIds?.length) ? (
                                    <React.Fragment>
                                        {selectedInfo.value === TIME && <FontAwesome name="clock-o mr-1" />}
                                        {activity.isFullDayActivity ? getI18nLocaleString(namespaceList.admin, "fullDay", currentLocale, site) : `${activity.startTime} - ${activity.endTime}`}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>{getI18nLocaleString(namespaceList.widgetActivityPlanner, "selectTimeslot")}</React.Fragment>
                                )}
                            </div>
                        );
                    case LOCATION:
                        return (
                            <ActivityLocation
                                className={classNames("activity-area", getStyleClassName(options))} // not in use due to activity file conflicts
                                style={styles}
                                activityAreaResources={activity.activityAreaResources.filter((activityAreaResource) => activityAreaResource.type === ActivityInfo.LOCATION)}
                                locationKey={changeDefaultActivityAreaLabel && getLocalizedContent({ site, currentLocale, localizedContent: changeDefaultActivityAreaLabel })?.activityAreaText}
                            />
                        );
                    case SIGN_UP:
                        return (
                            <ActivityLocation
                                className={classNames("signup-area", getStyleClassName(options))}
                                style={styles}
                                activityAreaResources={activity.activityAreaResources.filter((activityAreaResource) => activityAreaResource.type === ActivityInfo.SIGN_UP)}
                                locationKey={
                                    changeDefaultActivitySignUpAreaLabel && getLocalizedContent({ site, currentLocale, localizedContent: changeDefaultActivitySignUpAreaLabel })?.activitySignUpAreaText
                                }
                            />
                        );
                    case DATE:
                        return (
                            <div className={classNames("date activity-item", getStyleClassName(options))}>
                                {selectedInfo.value === DATE ? <FontAwesome name="calendar-o mr-1" /> : null}
                                {moment(activity.day as string).format(DATE_FORMAT.LONG_DATE)}
                            </div>
                        );
                    case DYNAMIC_FIELD_CODE:
                        return activity.dynamicFieldData?.length
                            ? activity.dynamicFieldData.map((dynamicField, index) => (
                                  <div className={classNames("dynamic-field-code", getStyleClassName(options))} style={styles} key={index}>
                                      <ActivityDynamicField className="activity-dynamic-field" dynamicField={dynamicField.value} />
                                  </div>
                              ))
                            : null;
                    case RESORTS:
                        return (
                            <h6 className={classNames("resort activity-item", getStyleClassName(options))} style={styles}>
                                {(activity.resourceActivity as ResourceActivityResult).resortName}
                            </h6>
                        );
                    case CATEGORY:
                        return (
                            <div className={classNames("category activity-item", getStyleClassName(options))} style={styles}>
                                {activity.activityCategory}
                            </div>
                        );
                }
            });
        }
    }

    private renderCustomFields(customFieldIds: NumberMultiSelectOption[] | undefined) {
        const { selectedCustomFields } = this.state;
        if (selectedCustomFields?.length) {
            return (
                <div className="custom-fields">
                    {customFieldIds?.map((customFieldId) => {
                        const foundCustomFieldId = selectedCustomFields?.find((selectedCustomFiled) => selectedCustomFiled.fieldId === customFieldId.value);
                        if (foundCustomFieldId) {
                            switch (foundCustomFieldId.dataType) {
                                case CUSTOM_FIELDS_DATA_TYPE.BOOLEAN:
                                    return (
                                        <div className="custom-fields__boolean-value">
                                            <span>{foundCustomFieldId.booleanValue}</span>
                                        </div>
                                    );
                                case CUSTOM_FIELDS_DATA_TYPE.DATE:
                                    return (
                                        <div className="custom-fields__date-value">
                                            <span>{foundCustomFieldId.dateValue}</span>
                                        </div>
                                    );
                                case CUSTOM_FIELDS_DATA_TYPE.NUMERIC:
                                    return (
                                        <div className="custom-fields__number-value">
                                            <span>{foundCustomFieldId.numericValue}</span>
                                        </div>
                                    );
                                case CUSTOM_FIELDS_DATA_TYPE.MEMO:
                                case CUSTOM_FIELDS_DATA_TYPE.TEXT:
                                    return (
                                        <div className="custom-fields__text-value">
                                            <p>{foundCustomFieldId.textValue}</p>
                                        </div>
                                    );
                                case CUSTOM_FIELDS_DATA_TYPE.SELECTION_INCL:
                                case CUSTOM_FIELDS_DATA_TYPE.SELECTION_EXCL: {
                                    if (foundCustomFieldId.selectValues?.length) {
                                        return (
                                            <div className="custom-fields__select-value">
                                                {(foundCustomFieldId.selectValues as string[]).map((selectValue: string, index: number) => (
                                                    <div key={index}>
                                                        <span>{selectValue}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    }
                                }
                            }
                        }
                    })}
                </div>
            );
        }
    }

    private handleRemovePreference = () => {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.unitPreference,
            payload: {},
        };
        this.props.dispatchAction(action);
    };

    public renderEditableDescriptionFields = () => {
        const { content, isEditModalOpen } = this.state;
        const {
            options: { descriptionTypeMultiSelector },
            context,
            childOptions,
        } = this.props;
        return (
            <RecaptchaProviderEnabler>
                <DescriptionEditor
                    childOptions={childOptions}
                    content={content as Unit}
                    context={context}
                    descriptionTypeMultiSelector={descriptionTypeMultiSelector}
                    isEditModalOpen={isEditModalOpen}
                    setIsdescriptionUpdating={this.setIsdescriptionUpdating}
                    toggleModal={this.toggleModal}
                    updateContent={this.updateContent}
                />
            </RecaptchaProviderEnabler>
        );
    };

    public renderDescription = (description: DescriptionResult, index: number): JSX.Element => {
        const { imageAlt, imageUrl, value, type } = description;
        const { contents } = this.state;
        const { link, options } = this.props;
        let htmlContent = value as string;
        if (options.showAllInventoryList) {
            const splitValue = (value as string).split('">');
            const resortName = (contents?.[index] as Resort)?.name;
            const lastWordOfResortName = resortName ? resortName.split(" ").pop() : "";
            htmlContent = `${splitValue[0]}">${imageAlt} ${lastWordOfResortName}</a>`;
        }
        const styles: any = {
            color: options.descFontColor?.indexOf("rgba") > -1 ? options.descFontColor : undefined,
            borderColor: options.nameStyle === "row-bordered" && options.nameTextUndrlinedColor && options.nameTextUndrlinedColor.indexOf("rgba") > -1 ? options.nameTextUndrlinedColor : undefined,
            backgroundColor: options.nameStyle === "background-text" && options.nameTextBackground && options.nameTextBackground.indexOf("rgba") > -1 ? options.nameTextBackground : undefined,
        };
        if (imageUrl) {
            return (
                <figure className="dynamic-field__image-wrapper">
                    <img loading="lazy" src={imageUrl} alt={imageAlt} />
                </figure>
            );
        }
        if ((value as string).includes("</")) {
            return <span dangerouslySetInnerHTML={{ __html: htmlContent }} />;
        }
        return (
            <>
                {type === 0 && link.url ? (
                    <SmartLink href={getUrlWithAnchor(link)} style={styles} target={link.target}>
                        {value as string}
                    </SmartLink>
                ) : (
                    value
                )}
            </>
        );
    };

    public toggleModal = () => {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen });
    };

    public setIsdescriptionUpdating = (isDescriptionsUpdating: boolean) => {
        this.setState({ isDescriptionsUpdating });
    };

    public updateContent = () => {
        this.loadDescriptions({ ...this.props, skipContentTypeSelectorCheck: true });
        const defaultDescriptions = {
            defaultDescription: (this.state.content as Exclude<Content, Resort | Resort[] | Activity> & TipsAndTripsContent)?.description || "",
            defaultDescription2: (this.state.content as Exclude<Content, Resort | Resort[] | Activity> & TipsAndTripsContent)?.description2 || "",
            defaultShortDescription: (this.state.content as Exclude<Content, Resort | Resort[] | Activity> & TipsAndTripsContent)?.shortDescription || "",
        };
        return defaultDescriptions;
    };

    private loadDescriptions = async (props?: DescriptionLoadContentProps): Promise<void> => {
        const {
            descriptions,
            dynamicFieldsDescriptions,
            fallbackDescriptions,
            dynamicFieldsFallbackDescriptions,
            activity,
            content,
            reservedResourcesName,
            reservedResourcesNameWithFrequency,
        } = (await loadDescription({
            ...(props || this.props),
        })) as DescriptionResponse;
        this.setState({
            descriptions: descriptions || [],
            dynamicFieldsDescriptions: dynamicFieldsDescriptions || [],
            fallbackDescriptions: fallbackDescriptions || [],
            dynamicFieldsFallbackDescriptions: dynamicFieldsFallbackDescriptions || [],
            isLoading: false,
            activity,
            content,
            reservedResourcesName,
            reservedResourcesNameWithFrequency,
        });
    };
}

function mapStateToProps(state: State): DescriptionStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        myEnvState: state.myEnvState,
        availabilityState: state.availabilityState,
        activityPlannerState: state.activityPlannerState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction>): DescriptionDispatchProps {
    return { dispatchAction: dispatch };
}

const TARGETS = ["description"];

const DescriptionWithEditModal = withEditModal(DescriptionWidget);

const Description = connect<DescriptionStoreProps, DescriptionDispatchProps>(mapStateToProps, mapDispatchToProps)(DescriptionWithEditModal);

const DynamicDescription = wrapProps<DynamicWidgetBaseProps<WidgetOptions, DescriptionResponse>>(withGoogleReCaptcha(Description));

export const descriptionWidget: PageWidgetSpec<WidgetOptions> = {
    id: "descriptionWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidget"),
    description: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        descFontSize: "default-font-size",
        descFontColor: "font-default",
        descFontStyle: "default",
        nameStyle: "default",
        nameTextBackground: "",
        nameTextUndrlinedSize: "",
        nameTextUndrlinedColor: "font-default",
        contentTag: "div",
        styleIds: [],
        enableBookingsEngineLink: false,
        linking: linkingSpecDefaultValues,
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const link = await getLinkFromLinkingSpec({ linkingSpecOptions: widget.options.linking, context });
        const reduxStoreState = context?.reduxStore?.store?.getState();
        const props: ServerSideProps<"DescriptionWidgetProps"> = {
            context,
            options: widget.options,
            dynamicFilter: reduxStoreState?.dynamicFilter,
            myEnvState: reduxStoreState?.myEnvState,
            availabilityState: reduxStoreState?.availabilityState,
        };
        const fetchedData = await loadDescription({ ...props });
        return (
            <DynamicDescription
                dynamicContainerOptions={dynamicContainerOptions}
                options={widget.options}
                context={context}
                className={styleClasses}
                link={link}
                widgetOptionsForm={widgetOptionsForm}
                widgetId={widget._id}
                fetchedData={fetchedData}
            />
        );
    },
};
