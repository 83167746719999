// eslint-disable-next-line max-classes-per-file
import * as PropTypes from "prop-types";
import * as React from "react";

import { ApiContext, CMSProvidedProperties, CMSProviderProperties } from "./cmsProvider.types";
import { CmsApi, CmsApiWrapper, LoggerContext, globalCmsApiCache, globalLogger } from "@maxxton/cms-api";
import { MxtsApi, MxtsApiWrapper, globalMxtsApiCache } from "@maxxton/cms-mxts-api";

import { ComponentConstructor } from "./";

export function globalApiContext(logContext?: LoggerContext): ApiContext {
    if (logContext) {
        return {
            cmsApi: new CmsApiWrapper(globalCmsApiCache, logContext),
            mxtsApi: new MxtsApiWrapper(globalMxtsApiCache, logContext),
            logger: logContext,
        };
    }
    return {
        cmsApi: CmsApi,
        mxtsApi: MxtsApi,
        logger: globalLogger,
    };
}

const contextTypes = {
    site: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    alerts: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    onTitle: PropTypes.func.isRequired,
    onMetaTitle: PropTypes.func.isRequired,
    onMetaKeywords: PropTypes.func.isRequired,
    onMetaDescription: PropTypes.func.isRequired,
    onMetaRobots: PropTypes.func.isRequired,
    onImageStructured: PropTypes.func.isRequired,
    onTitleStructured: PropTypes.func.isRequired,
    onUrlStructured: PropTypes.func.isRequired,
    onDescriptionStructured: PropTypes.func.isRequired,
    onAuthorStructured: PropTypes.func.isRequired,
    onStructuredData: PropTypes.func.isRequired,
    onStructuredDataMarkup: PropTypes.func.isRequired,
    onEnableRedirect: PropTypes.func.isRequired,
    onRedirect: PropTypes.func.isRequired,
    onDisableCanonicalUrl: PropTypes.func.isRequired,
    currentLocale: PropTypes.object.isRequired,
    distributionChannelId: PropTypes.number,
    currency: PropTypes.object,
    chunkExtractor: PropTypes.object,
};

export class CMSProvider extends React.Component<CMSProviderProperties, {}> {
    public static childContextTypes = contextTypes;

    public getChildContext() {
        const {
            site,
            theme,
            alerts,
            location,
            onTitle,
            onMetaTitle,
            onMetaKeywords,
            onMetaDescription,
            onMetaRobots,
            onImageStructured,
            onTitleStructured,
            onUrlStructured,
            onDescriptionStructured,
            onAuthorStructured,
            onStructuredData,
            onStructuredDataMarkup,
            onEnableRedirect,
            onRedirect,
            onDisableCanonicalUrl,
            currentLocale,
            distributionChannelId,
            chunkExtractor,
        } = this.props;
        const ctx = {
            site,
            theme,
            alerts,
            location,
            onTitle,
            onMetaTitle,
            onMetaKeywords,
            onMetaDescription,
            onMetaRobots,
            onImageStructured,
            onTitleStructured,
            onUrlStructured,
            onDescriptionStructured,
            onAuthorStructured,
            onStructuredData,
            onStructuredDataMarkup,
            onEnableRedirect,
            onRedirect,
            onDisableCanonicalUrl,
            currentLocale,
            distributionChannelId,
            chunkExtractor,
        };
        return ctx;
    }

    public render() {
        // Children.only enables us not to add a <div /> for nothing
        return React.Children.only(this.props.children);
    }
}

export function CMSAware<P>(WrappedComponent: ComponentConstructor<P & CMSProvidedProperties, any>): ComponentConstructor<P, {}> {
    // eslint-disable-next-line max-classes-per-file
    class CMSAwareComponent extends React.Component<P, {}> {
        public static contextTypes = contextTypes;

        constructor(props: any) {
            super(props);
        }

        public render(): JSX.Element | null {
            const {
                site,
                theme,
                alerts,
                location,
                onTitle,
                onMetaKeywords,
                onMetaTitle,
                onMetaDescription,
                onMetaRobots,
                onImageStructured,
                onTitleStructured,
                onUrlStructured,
                onDescriptionStructured,
                onAuthorStructured,
                onStructuredData,
                onStructuredDataMarkup,
                onEnableRedirect,
                onRedirect,
                onDisableCanonicalUrl,
                currentLocale,
                distributionChannelId,
            } = this.context as CMSProvidedProperties;
            return (
                <WrappedComponent
                    site={site}
                    theme={theme}
                    alerts={alerts}
                    onTitle={onTitle}
                    onMetaTitle={onMetaTitle}
                    onMetaKeywords={onMetaKeywords}
                    onMetaDescription={onMetaDescription}
                    onMetaRobots={onMetaRobots}
                    onImageStructured={onImageStructured}
                    onTitleStructured={onTitleStructured}
                    onUrlStructured={onUrlStructured}
                    onDescriptionStructured={onDescriptionStructured}
                    onAuthorStructured={onAuthorStructured}
                    onStructuredData={onStructuredData}
                    onStructuredDataMarkup={onStructuredDataMarkup}
                    onEnableRedirect={onEnableRedirect}
                    onRedirect={onRedirect}
                    onDisableCanonicalUrl={onDisableCanonicalUrl}
                    location={location}
                    currentLocale={currentLocale}
                    distributionChannelId={distributionChannelId}
                    {...(this.props as any)}
                />
            );
        }
    }

    (CMSAwareComponent as any).displayName = `CMSAwareComponent(${WrappedComponent.name})`;

    return CMSAwareComponent;
}

export default CMSProvider;
