import { CMSProviderProperties } from "../../../../../containers/cmsProvider.types";
import { ConfiguredLink } from "../../../../../utils/linking.util";
import { FilterChangeAction } from "../../../../../redux/actions/dynamicFilterAction.types";
import { LocalizeDcAndRcOptions } from "./";
import { MyEnvReducerAction } from "../../../../../redux/actions/myEnvAction";
import { MyEnvState } from "../../../../../redux/reducers/myEnv/myEnvState";

export type calendarTypeOption = "Show inline" | "Show as a modal popup";

export const CalendarType = {
    showInline: "Show inline",
    showAsModalPopup: "Show as a modal popup",
} as const;

export enum OwnerBookChoiceSelection {
    OWN_USE = "OWN_USE",
    GUEST_OWN_UNIT = "GUEST_OWN_UNIT",
    BOOKINGENGINE_OTHER_UNIT = "BOOKINGENGINE_OTHER_UNIT",
    BOOKINGENGINE_OWN_UNIT = "BOOKINGENGINE_OWN_UNIT",
    OWNER_SHAREABLE_LINK = "OWNER_SHAREABLE_LINK",
}

export enum OwnerOwnUseSubSelection {
    OWN_DAYS = "OWN_DAYS",
    PAY_FOR_BOOKING = "PAY_FOR_BOOKING",
    LAST_MINUTE_BOOKING = "LAST_MINUTE_BOOKING",
}

export interface LocalizedContentProps {
    localizedOptions: LocalizeDcAndRcOptions[] | undefined;
    context: CMSProviderProperties;
    myEnvState: MyEnvState;
    selectedValue: string;
    ownerOwnUnitLink: ConfiguredLink | undefined;
    ownerOwnUnitLinkForGuest: ConfiguredLink | undefined;
    bookingEngineOwnUnitLink: ConfiguredLink | undefined;
    dispatchAction: React.Dispatch<MyEnvReducerAction | FilterChangeAction>;
}
