import { ConditionalLocalizedLinkButtonOptions, LinkingSpecOptions, LocalizedLinkButtonOptions } from "../inputSpecs/linkingSpec";
import { Site, WithId } from "@maxxton/cms-api";
import { UrlLinkParams, UrlParamsUtil } from "./urlparam.util";

import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { NumberMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { StringUtil } from "./string.util";
import { getLocalizedContent } from "./localizedContent.util";
import { pageLink } from "../routing";

export interface ConfiguredLink {
    rel?: string;
    target?: string;
    url: string;
    anchor?: string;
    isExternalLink?: boolean;
}

export const getLinkFromLinkingSpec = async ({
    context,
    linkingSpecOptions,
    accommodationKindId,
    shouldFetchLinkWithDynamicFilters,
}: {
    accommodationKindId?: number;
    context: CMSProviderProperties;
    linkingSpecOptions?: LinkingSpecOptions;
    shouldFetchLinkWithDynamicFilters?: boolean;
}): Promise<ConfiguredLink> => {
    if (!linkingSpecOptions || linkingSpecOptions?.useDynamicFieldAsLink) {
        return { url: "" };
    }

    // conditional linking flow
    if (linkingSpecOptions.conditionalContentTypes?.length) {
        return getLinkFromConditionalLinkingFlow({ context, linkingSpecOptions, accommodationKindId });
    }

    // normal linking flow
    return getLinkFromNormalLinkingFlow({ context, linkingSpecOptions, shouldFetchLinkWithDynamicFilters });
};

const getLinkFromNormalLinkingFlow = async ({
    context,
    linkingSpecOptions,
    shouldFetchLinkWithDynamicFilters,
}: {
    context: CMSProviderProperties;
    linkingSpecOptions: LinkingSpecOptions;
    shouldFetchLinkWithDynamicFilters?: boolean;
}): Promise<ConfiguredLink> => {
    const { currentLocale, site } = context;

    const link: ConfiguredLink = {
        url: "",
    };

    const localizedLinkButtonOptions: LocalizedLinkButtonOptions | null = getLocalizedContent({
        site,
        currentLocale,
        localizedContent: linkingSpecOptions.localizedLinkButtonOptions || [],
    });

    if (localizedLinkButtonOptions) {
        const { useExternalUrl, externalUrl, pageId, siteId, openInNewTab, noFollowLink, selectReservationCategory, reservationCategoryId } = localizedLinkButtonOptions;

        link.target = openInNewTab ? "_blank" : "_self";

        if (useExternalUrl && externalUrl != null) {
            link.url = StringUtil.removeWhiteSpaceFromString(externalUrl);
            link.isExternalLink = true;
            if (noFollowLink) {
                link.rel = "nofollow";
            }
            return link;
        }

        if (siteId && pageId) {
            const site: (Site & WithId) | null = context.site._id === siteId ? context.site : await context.cmsApi.siteApi.findById({ id: siteId, projection: { sitemap: 0 } });
            if (site) {
                link.url = (await pageLink({ site, pageId, locale: context.currentLocale, context })) || "";
            }
        }
        if (selectReservationCategory && reservationCategoryId) {
            link.url = `${link.url}?rcid=${reservationCategoryId}`;
        }
    }

    if (linkingSpecOptions.useAnchorLink && linkingSpecOptions.anchorLink) {
        link.anchor = StringUtil.removeWhiteSpaceFromString(linkingSpecOptions.anchorLink);
    }
    if (shouldFetchLinkWithDynamicFilters) {
        const dynamicFilter = context.reduxStore.store.getState().dynamicFilter;
        const params: UrlLinkParams = UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter);
        if (params) {
            link.url = link.url && UrlParamsUtil.getBmUrlWithQueryParams(link.url, params);
        }
    }

    return link;
};

const getLinkFromConditionalLinkingFlow = async ({
    context,
    linkingSpecOptions,
    accommodationKindId,
}: {
    accommodationKindId?: number;
    context: CMSProviderProperties;
    linkingSpecOptions: LinkingSpecOptions;
}): Promise<ConfiguredLink> => {
    const { conditionalAccoKindIds: conditionalAccoKindIdSelections } = linkingSpecOptions;
    const conditionalAccoKindIds: number[] = conditionalAccoKindIdSelections?.map((option: NumberMultiSelectOption) => option.value) || [];
    const mustUseConditionalLink = accommodationKindId ? conditionalAccoKindIds.includes(accommodationKindId) : false;

    const link: ConfiguredLink = {
        url: "",
    };

    if (mustUseConditionalLink) {
        const { currentLocale, site } = context;

        const localizedLinkButtonOptions: ConditionalLocalizedLinkButtonOptions | null = getLocalizedContent({
            site,
            currentLocale,
            localizedContent: linkingSpecOptions.conditionalLocalizedLinkButtonOptions || [],
        });

        if (localizedLinkButtonOptions) {
            const { conditionalExternalUrl, useConditionalExternalUrl, conditionalPageId, conditionalSiteId, conditionalOpenInNewTab, conditionalNoFollowLink } = localizedLinkButtonOptions;

            link.target = conditionalOpenInNewTab ? "_blank" : "_self";

            if (useConditionalExternalUrl && conditionalExternalUrl != null) {
                link.url = StringUtil.removeWhiteSpaceFromString(conditionalExternalUrl);
                link.isExternalLink = true;
                if (conditionalNoFollowLink) {
                    link.rel = "nofollow";
                }
                return link;
            }

            if (conditionalSiteId && conditionalPageId) {
                const site: (Site & WithId) | null = await context.cmsApi.siteApi.findById({ id: conditionalSiteId, projection: { sitemap: 0 } });
                if (site) {
                    link.url = (await pageLink({ site, pageId: conditionalPageId, locale: context.currentLocale, context })) || "";
                }
            }
        }

        if (linkingSpecOptions.useConditionalAnchorLink && linkingSpecOptions.conditionalAnchorLink) {
            link.anchor = StringUtil.removeWhiteSpaceFromString(linkingSpecOptions.conditionalAnchorLink);
        }

        return link;
    }

    return getLinkFromNormalLinkingFlow({ context, linkingSpecOptions });
};

export const getUrlWithAnchor = (link: ConfiguredLink): string => {
    if (link.anchor) {
        return `${link.url}#${link.anchor}`;
    }
    return link.url;
};

export const linkingSpecDefaultValues: LinkingSpecOptions = {
    anchorLink: "",
    localizedLinkButtonOptions: [],
    useAnchorLink: false,
};

export enum ConditionalLinkContentType {
    LOCATION,
    ACCO_KIND,
}
