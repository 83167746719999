import { Reducer, combineReducers } from "redux";

import { State } from "../";
import { activityPlannerReducer } from "./activityPlannerReducer";
import { addOnsReducer } from "./add-ons/addOnsReducer";
import { additionReducer } from "./additionReducer";
import { amenitiesReducer } from "./amenitiesReducer";
import { availabilityReducer } from "./availability";
import { billReducer } from "./billReducer";
import { dynamicFilterReducer } from "./dynamicFilter";
import { formEditReducer } from "./formAlert";
import { frontendPageEditReducer } from "./frontendPageEditReducer";
import { guestFormReducer } from "./guestFormReducer";
import { instalmentsReducer } from "./instalmentsState";
import { linkedWidgetsReducer } from "./linkedWidgets/linkedWidgetsState";
import { mxtsDataAccessTokenReducer } from "./mxtsDataAccessTokenReducer";
import { myEnvReducer } from "./myEnv/myEnvState";
import { pageLockReducer } from "./pageLockReducer";
import { prevNextReducer } from "./prevNext";
import { priceMatrixReducer } from "./priceMatrixReducer";
import { reservationCustomerReducer } from "./reservationCustomerReducer";
import { reservationReducer } from "./reservationReducer";
import { resultsPanelReducer } from "./resultsPanelReducer";
import { revealerReducer } from "./revealer";
import { searchEventReducer } from "./searchEvent";
import { selectedAddOnsReducer } from "./add-ons/selectedAddOnsReducer";
import { sessionReducer } from "./sessionReducer";
import { specialSearchReducer } from "./specialSearchReducer";
import { travelPartyReducer } from "./travelParty";
import { userInterfaceReducer } from "./userInterfaceReducer";
import { userLoginFormReducer } from "./userLoginReducer";

export const reducer: Reducer<State> = combineReducers({
    myEnvState: myEnvReducer,
    instalmentsState: instalmentsReducer,
    addOnsState: addOnsReducer,
    additionState: additionReducer,
    availabilityState: availabilityReducer,
    billState: billReducer,
    dynamicFilter: dynamicFilterReducer,
    editFormState: formEditReducer,
    guestFormState: guestFormReducer,
    mxtsDataAccessTokenState: mxtsDataAccessTokenReducer,
    pageLockState: pageLockReducer,
    prevNextState: prevNextReducer,
    reservationState: reservationReducer,
    resultsPanelState: resultsPanelReducer,
    revealerState: revealerReducer,
    searchEventState: searchEventReducer,
    selectedAddOnsState: selectedAddOnsReducer,
    specialSearchState: specialSearchReducer,
    reservationCustomerState: reservationCustomerReducer,
    userInterfaceState: userInterfaceReducer,
    amenitiesState: amenitiesReducer,
    sessionState: sessionReducer,
    travelPartyState: travelPartyReducer,
    frontendPageEditState: frontendPageEditReducer,
    priceMatrixState: priceMatrixReducer,
    activityPlannerState: activityPlannerReducer,
    userLoginFormState: userLoginFormReducer,
    linkedWidgetsState: linkedWidgetsReducer,
});
