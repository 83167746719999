// Be careful while sorting imports of this file as it breaks the build
/* eslint-disable sort-imports */
import { FormSpec } from "../form-specs";
import { SelectOption } from "../form-specs/formSpec.types";

import { DynamicWidgetSpec, PageWidgetSpec, WidgetSpec } from "./widget";
import { WidgetGroup } from "./widget.enum";
export * from "./widget";
import * as React from "react";

import { CmsApiWrapper, globalLogger, OptionsApi, Plugin, Theme, ThemeApi, WithId } from "@maxxton/cms-api";
import { getI18nLocaleObject, I18nLocaleObject } from "../i18n/loader";
import namespaceList from "../i18n/namespaceList";

export interface PluginSpec<T> {
    id: string;
    name: I18nLocaleObject | string;
    description: I18nLocaleObject | string;
    optionsForm?: FormSpec<T>;
    showOptionsInAdminMenu?: boolean;
    widgets: Array<WidgetSpec<any>> | DynamicWidgetSpec[];
    permission?: PermissionKey;
}

export const plugins: Array<PluginSpec<any>> = [];
export const dynamicWidgets: DynamicWidgetSpec[] = [];
export const layoutWidgets: DynamicWidgetSpec[] = [];
export const contentWidgets: DynamicWidgetSpec[] = [];
export const otherWidgets: DynamicWidgetSpec[] = [];
export const bookingsModuleWidgets: DynamicWidgetSpec[] = [];
export const displayWidgets: DynamicWidgetSpec[] = [];
export const inputWidgets: DynamicWidgetSpec[] = [];
export const myEnvironmentWidgets: DynamicWidgetSpec[] = [];

export async function themeOptions(): Promise<Array<SelectOption<string | undefined>>> {
    const themes = await ThemeApi.find({ projection: { name: 1, themeId: 1 } });
    return themes
        .filter(({ name }) => name)
        .map(({ themeId, name }) => ({
            value: themeId,
            label: name as string,
        }));
}

function registerPlugin(plugin: PluginSpec<any>) {
    if (plugin && plugin.optionsForm != null && !plugin.optionsForm.api) {
        plugin.optionsForm.api = OptionsApi;
    }
    plugins.push(plugin);
    plugin.widgets.forEach((widget: WidgetSpec<any> | DynamicWidgetSpec) => {
        if (widget) {
            widget = widget as DynamicWidgetSpec;
            if (widget.widgetGroup === WidgetGroup.LAYOUT) {
                layoutWidgets.push(widget);
            } else if (widget.widgetGroup === WidgetGroup.DYNAMIC) {
                dynamicWidgets.push(widget);
            } else if (widget.widgetGroup === WidgetGroup.CONTENT) {
                contentWidgets.push(widget);
            } else if (widget.widgetGroup === WidgetGroup.BOOKINGS_MODULE) {
                bookingsModuleWidgets.push(widget);
            } else if (widget.widgetGroup === WidgetGroup.DISPLAY) {
                displayWidgets.push(widget);
            } else if (widget.widgetGroup === WidgetGroup.INPUT) {
                inputWidgets.push(widget);
            } else if (widget.widgetGroup === WidgetGroup.MY_ENVIRONMENT) {
                myEnvironmentWidgets.push(widget);
            } else {
                otherWidgets.push(widget);
            }
        }
    });
}

export function findWidget<T>(id: string): WidgetSpec<T> | DynamicWidgetSpec {
    const widget =
        layoutWidgets.find((w) => w.id === id) ||
        dynamicWidgets.find((w) => w.id === id) ||
        contentWidgets.find((w) => w.id === id) ||
        bookingsModuleWidgets.find((w) => w.id === id) ||
        displayWidgets.find((w) => w.id === id) ||
        inputWidgets.find((w) => w.id === id) ||
        myEnvironmentWidgets.find((w) => w.id === id) ||
        otherWidgets.find((w) => w.id === id);

    if (widget === undefined) {
        globalLogger.error(`Failed to find widget with id: "${id}". Please check the widgets in the page hierarchy. There is probably some "Error: This Widget No Longer Exists" widget`);
        return {
            id: "widgetNotFound",
            pathToFile: () => import("./widgetNotFound"),
            targetName: "widgetNotFound",
            type: "page",
            widgetGroup: WidgetGroup ? WidgetGroup.OTHER : 3,
        };
    }
    return widget;
}

export function findPluginSpecByOptions<E>(options: FormSpec<E>): PluginSpec<E> | undefined {
    return plugins.find((plugin) => plugin.optionsForm != null && plugin.optionsForm.id === options.id);
}

async function createPlugin<E>(cmsApi: CmsApiWrapper, spec: PluginSpec<E>): Promise<Plugin> {
    const theme: (Theme & WithId) | null = await cmsApi?.themeApi.findByThemeId({ themeId: "base" });
    const plugin: Plugin = {
        name: spec.id,
        enabled: true,
        options: (spec.id === "cms-options" || spec.id === "cms-settings") && theme ? { theme } : {},
    };
    try {
        return await cmsApi.pluginApi.create({ item: plugin });
    } catch (e) {
        // eslint-disable-next-line no-console
        // TODO: should we do something with this error? console.warn("Cannot create plugin. Check authorization");
        return plugin;
    }
}

export async function loadPlugin<E>(cmsApi: CmsApiWrapper, spec: PluginSpec<E>): Promise<Plugin> {
    let plugin: Plugin | null = await cmsApi?.pluginApi.findByName({ name: spec.id });
    const environmentPlugin = await cmsApi?.pluginApi.findByName({ name: "mxts" });
    if (spec.id !== "cms-options" && !environmentPlugin) {
        plugin = await createPlugin(cmsApi, MXTS);
    }
    if (!plugin) {
        plugin = await createPlugin(cmsApi, spec);
    }
    if (environmentPlugin) {
        plugin.options.env = environmentPlugin.options?.env;
    }
    return plugin;
}

/* eslint-disable no-console, sort-imports */
import MXTS from "./mxts/mxtsWidgets";
registerPlugin(MXTS);

import Page from "./page";
registerPlugin(Page);

import Menu from "./menu";
registerPlugin(Menu);

import Form from "./form/formWidgets";
registerPlugin(Form);

import Sitemap from "./sitemap";
registerPlugin(Sitemap);

import Settings from "./settings";
registerPlugin(Settings);

import DynamicPlugin from "./dynamic";
registerPlugin(DynamicPlugin);

import FlowPlugin from "./flow";
registerPlugin(FlowPlugin);

import ResultsPanelPlugin from "./resultsPanel";
import { PermissionKey } from "@maxxton/cms-mxts-api";
registerPlugin(ResultsPanelPlugin);
/* eslint-enable */

export const pluginSpecs = plugins.filter((plugin) => plugin.showOptionsInAdminMenu).map((plugin) => plugin.optionsForm as FormSpec<any>);
