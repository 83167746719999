import * as React from "react";

import { AmenityLinkCategory, executeRecaptchaCheck } from "./amenitiesEditor.util";

import { AmenitiesEditor } from "./AmenitiesEditor";
import { AmenitiesStateHandler } from "./Amenities";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function AmenitiesEditorModalContent(props: {
    amenityCategories: AmenityLinkCategory[];
    isEditModalOpen?: boolean;
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    context: CMSProviderProperties;
    amenitiesStates: AmenitiesStateHandler;
}) {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const getReCaptchaToken = React.useCallback(async (): Promise<string> => executeRecaptchaCheck(executeRecaptcha), [executeRecaptcha]);
    const { amenityCategories, childOptions, context, isEditModalOpen, amenitiesStates } = props;
    const amenities = amenityCategories.flatMap((amenityCategory) => amenityCategory.amenities || []);
    return (
        <AmenitiesEditor
            amenities={amenities}
            childOptions={childOptions}
            context={context}
            isEditModalOpen={isEditModalOpen}
            amenitiesStates={amenitiesStates}
            getReCaptchaToken={getReCaptchaToken}
        />
    );
}
