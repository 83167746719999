// eslint-disable

// This is a dependency file, hence not to be changed.
import * as React from "react";
import Select from "./Select";
import { Async } from "./Async";
import Creatable from "./Creatable";

function reduce(obj: any, props = {}) {
    return Object.keys(obj).reduce((props, key) => {
        const value = obj[key];
        if (value !== undefined) {
            (props as any)[key] = value;
        }
        return props;
    }, props);
}

class AsyncCreatableSelect extends React.Component {
    select: any;

    focus() {
        this.select.focus();
    }

    render() {
        return (
            <Async {...(this.props as any)}>
                {(asyncProps: any) => (
                    <Creatable {...this.props}>
                        {(creatableProps: any) => (
                            <Select
                                {...reduce(asyncProps, reduce(creatableProps, {}))}
                                onInputChange={(input) => {
                                    creatableProps.onInputChange(input);
                                    return asyncProps.onInputChange(input);
                                }}
                                ref={(ref: any) => {
                                    this.select = ref;
                                    creatableProps.ref(ref);
                                    asyncProps.ref(ref);
                                }}
                            />
                        )}
                    </Creatable>
                )}
            </Async>
        );
    }
}

export default AsyncCreatableSelect;
