import * as React from "react";

import LinearProgress from "@mui/material/LinearProgress";

interface LinearProgressBarProps {
    loading: boolean;
}

interface LinearProgressBarState {
    value: number;
    visible: boolean;
}

class LinearProgressBar extends React.Component<LinearProgressBarProps, LinearProgressBarState> {
    private interval: NodeJS.Timeout | null = null;
    private timeout: NodeJS.Timeout | null = null;

    state: LinearProgressBarState = {
        value: 0,
        visible: false,
    };

    componentDidUpdate(prevProps: LinearProgressBarProps) {
        if (this.props.loading && !prevProps.loading) {
            this.startProgressBar();
        } else if (!this.props.loading && prevProps.loading) {
            this.stopProgressBar();
        }
    }

    componentWillUnmount() {
        this.stopProgressBar();
    }

    startProgressBar() {
        this.setState({ visible: true, value: 10 }, () => {
            this.interval = setInterval(() => {
                if (this.state.value < 70) {
                    this.setState((prevState) => ({ value: prevState.value + 10 }));
                }
            }, 150);
        });
    }

    stopProgressBar() {
        clearInterval(this.interval!);
        this.interval = null;
        this.setState({ value: 100 }, () => {
            this.timeout = setTimeout(() => {
                this.timeout = null;
                this.setState({ value: 0, visible: false });
            }, 200);
        });
    }

    render() {
        const { value, visible } = this.state;

        return visible && <LinearProgress variant="determinate" value={value} />;
    }
}

export default LinearProgressBar;
