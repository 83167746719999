import * as moment from "moment";

import { AVAILABILITY_CONSTANTS, DATE_FORMAT, MXTS as MXTS_CONSTANTS } from "./constants";
import { AggrSortField, Aggregation, ApiCallOptions, AvailabilityRequest, AvailabilityResult, ElasticSubject, Environment, Size } from "@maxxton/cms-mxts-api";
import { ApiContext, CMSProvidedProperties } from "../containers/cmsProvider.types";
import { convertToNumberArray, getDurationFromOptions } from "../plugins/mxts";
import { getElasticSubjectFilter, getPetCapacity } from "./searchFacet.utils";

import { DateMap } from "../plugins/mxts/mxts.types";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { LocalizedOptions } from "@maxxton/cms-api";
import { PaginationData } from "./Pagination/pagination.type";
import { getLocalizedContent } from "./localizedContent.util";
import { getMxtsEnv } from "../plugins/mxts/index";
import { uniqBy } from "lodash";

type Moment = moment.Moment;

export const RESOURCES_AGGREGATION: Aggregation = {
    name: "RESOURCE_GROUP",
    field: "RESOURCE_ID",
    type: "GROUP",
    size: 1,
};
/**
 * This aggregation simply returns the resourceIds that are bookable
 */
export const RESOURCE_IDS_AGGREGATION: Aggregation = {
    name: "RESOURCE_FACET",
    field: "RESOURCE_ID",
    type: "FACET",
    size: 10000,
};
/**
 * This aggregation simply returns the unitIds that are bookable
 */
export const UNIT_IDS_AGGREGATION: Aggregation = {
    name: "UNIT_FACET",
    field: "UNIT_ID",
    type: "FACET",
    size: 10000,
};
/**
 * This aggregation returns the bookable unit documents along with the price. Also contains the arrival/duration and (optional) special price.
 * Sorted on cheapest price
 */
export const UNITS_WITH_PRICE_AGGREGATION: Aggregation = {
    name: "UNIT_GROUP",
    type: "GROUP",
    field: "UNIT_ID",
    size: 1,
};
/**
 * This aggregation returns the bookable unit documents sorted on priority.
 */
export const SORTED_UNITS_AGGREGATION: Aggregation = {
    name: "SORTED_UNITS",
    field: "UNIT_ID",
    type: "OTHER",
    sort: "ASC",
};
/**
 * This aggregation simply returns the amenities that are bookable
 */
export const ALL_AMENITIES_AGGREGATION: Aggregation = {
    name: "ALL_AMENITIES_FACET",
    field: "ALL_AMENITIES",
    type: "FACET",
    size: 1000,
};
/**
 * This aggregation returns the bookable unit documents sorted on priority.
 */
const RESORT_AGGREGATION: Aggregation = {
    name: "RESORT_GROUP",
    field: "RESORT_ID",
    type: "GROUP",
    size: 1,
};

/**
 * This aggregation simply returns the regions that are bookable
 */
export const ALL_REGIONS_AGGREGATION: Aggregation = {
    name: "ALL_REGIONS_FACET",
    field: "ALL_REGIONS",
    type: "FACET",
    size: 1000,
};

/**
 * This aggregation returns the available StayPeriodDefs
 */
export const STAY_PERIOD_DEF_AGGREGATION: Aggregation = {
    name: "STAY_PERIOD_DEF_FACET",
    field: "STAY_PERIOD_DEF_ID",
    type: "FACET",
    excludeFields: ["STAY_PERIOD_DEF_ID", "DURATION"],
    size: 1000,
};

const PRICE_BREAKDOWN_AGGREGATION: Aggregation = {
    name: "PRICE_BREAKDOWN",
    type: "OTHER",
    field: "UNIT_ID",
};
const AMENITIES_RESORT_COUNT_AGGREGATION: Aggregation = {
    count: "RESORT_ID",
    name: "ALL_AMENITIES_FACET",
    field: "ALL_AMENITIES",
    type: "FACET",
    size: 1000,
};
const { APPLICATION } = AVAILABILITY_CONSTANTS;
export class AvailabilityUtil {
    public static async getAvailabilityByDynamicFilter(
        dynamicFilter: DynamicFilter,
        options: { customAggregations?: Aggregation[]; rateTypeId?: number } = {
            customAggregations: undefined,
        },
        apiContext: ApiContext,
        paginationData?: PaginationData
    ): Promise<{
        availabilityResult: AvailabilityResult;
        availabilityRequest: AvailabilityRequest;
        env: ApiCallOptions;
    }> {
        const env = await getMxtsEnv(apiContext, dynamicFilter.currentLocale);
        let elasticSubjects: ElasticSubject[] = [];
        let petCapacity: number | undefined;

        if (dynamicFilter.subject && dynamicFilter.subject.size > 0) {
            let subjectsRequestParams;
            if (dynamicFilter.useSubjectCategory) {
                subjectsRequestParams = {
                    subjectIds: [...dynamicFilter.subject.keys()],
                };
            } else {
                subjectsRequestParams = {
                    subjectIds: [...dynamicFilter.subject.keys()],
                    types: ["PERSON", "PET"],
                    endDate: moment().format("YYYY-MM-DD"),
                    sort: "subjectOrder",
                };
            }
            const subjects = (await apiContext.mxtsApi.subjects(env, subjectsRequestParams)).content;
            elasticSubjects = getElasticSubjectFilter(subjects, dynamicFilter.subject);
            petCapacity = getPetCapacity(subjects, dynamicFilter.subject);
        }
        const baseFilter = getBaseFilter({
            options: dynamicFilter,
            distributionChannelId: dynamicFilter.distributionChannel!.distributionChannelId,
            customAggregations: options.customAggregations,
            elasticSubjects,
            petCapacity,
            fetchUnitsWithPrice: dynamicFilter.shouldFetchUnitsWithPrice,
            fetchSortedUnits: dynamicFilter.shouldFetchSortedUnits,
            fetchUnitFacet: false,
            fetchBedBathFacet: false,
            env: env.env,
            fetchResorts: dynamicFilter.shouldFetchResorts,
            fetchLatestArrivalDate: dynamicFilter.fetchLatestArrivalDate,
            qualityLevelIds: dynamicFilter.qualityLevelIds,
            includePreBookingPeriods: dynamicFilter.includePreBookingPeriods,
        });
        if (!paginationData) {
            const resourceGroup = baseFilter.aggregations?.find((aggregation) => aggregation.name === "RESOURCE_GROUP");
            if (resourceGroup && dynamicFilter.paginatedData?.typesearch?.size) {
                paginationData = { ...dynamicFilter?.paginatedData?.typesearch, sort: dynamicFilter.sortingOption || "highToLowRating", page: 1 };
            }
        }
        const availability = await fetchAvailability(baseFilter, env, apiContext, paginationData);
        return {
            availabilityResult: availability,
            availabilityRequest: baseFilter,
            env,
        };
    }

    public static async getUnfilteredAvailability(
        context: CMSProvidedProperties,
        dynamicFilter: DynamicFilter,
        options: { customAggregations?: Aggregation[] } = {
            customAggregations: undefined,
        }
    ): Promise<{
        availabilityResult: AvailabilityResult;
        availabilityRequest: AvailabilityRequest;
        env: ApiCallOptions;
    }> {
        const {
            currentLocale,
            site,
            site: { localizedOptions },
        } = context;
        const { rateType: { rateTypeId } = {}, distributionChannel: { distributionChannelId } = {} } = dynamicFilter;
        const env: ApiCallOptions = await getMxtsEnv(context, currentLocale.code);
        const newDynamicFilter = { ...dynamicFilter };
        const localizedDcOptions: LocalizedOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: localizedOptions || [] });
        const newRateTypeId = localizedDcOptions?.rateTypes?.length ? localizedDcOptions.rateTypes[0].value : rateTypeId;
        if (newRateTypeId && newRateTypeId !== rateTypeId) {
            const rateType = await context.mxtsApi
                .rates(env, {
                    rateTypeId: newRateTypeId,
                })
                .then((rates) => rates?.content?.[0])
                .catch((error: Error) => {
                    context.logger.error(error);
                    return undefined;
                });
            if (rateType) {
                newDynamicFilter.rateType = { rateTypeId: rateType.rateTypeId, code: rateType.code };
            }
        }
        // Get distribution channel from localized options, otherwise from dynamicFilter
        const newDistributionChannelId = (localizedDcOptions?.distributionChannelId && +localizedDcOptions.distributionChannelId) || distributionChannelId;
        if (newDistributionChannelId && newDistributionChannelId !== distributionChannelId) {
            const distributionChannel = await context.mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: newDistributionChannelId }]).catch((error: Error) => {
                context.logger.error(error);
                return undefined;
            });
            if (distributionChannel) {
                newDynamicFilter.distributionChannel = { distributionChannelId: distributionChannel.distributionChannelId, code: distributionChannel.code };
            }
        }
        const { distributionChannel, rateType } = newDynamicFilter;
        return this.getAvailabilityByDynamicFilter({ distributionChannel, rateType }, options, context);
    }

    public static isFirstAvailableMonth = ({ dateMap, day }: { dateMap?: DateMap; day?: Moment }): boolean => {
        const firstAvailableDay = AvailabilityUtil.getFirstAvailableDay({ dateMap })?.format(DATE_FORMAT.DEFAULT);
        let isFirstAvailableMonth = false;
        if (firstAvailableDay) {
            // used ! with day and dateMap as firstAvailableDay is defined
            isFirstAvailableMonth = day!.month() === dateMap![firstAvailableDay].month() && day!.year() === dateMap![firstAvailableDay].year();
        }
        return isFirstAvailableMonth;
    };

    public static getFirstAvailableDay({ dateMap }: { dateMap?: DateMap }): Moment | undefined {
        const dates: Moment[] | undefined = dateMap && Object.values(dateMap);
        if (dates?.length) {
            const firstAvailableDay = moment.min(...dates);
            return firstAvailableDay;
        }
    }
}

export interface BaseFilterParamInterface {
    options: any;
    distributionChannelId: number;
    rateTypeId?: number;
    customAggregations?: Aggregation[];
    resortId?: number;
    resourceId?: number;
    accoKindIds?: number[] | null;
    elasticSubjects?: ElasticSubject[];
    petCapacity?: number;
    fetchUnitsWithPrice?: boolean | string;
    fetchSortedUnits?: boolean;
    fetchUnitFacet?: boolean;
    fetchBedBathFacet?: boolean;
    env?: Environment;
    resortIds?: number[];
    fetchResorts?: boolean | undefined;
    fetchLatestArrivalDate?: string | undefined;
    page?: number;
    size?: Size;
    sortingOption?: string;
    qualityLevelIds?: number[];
    includePreBookingPeriods?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function getBaseFilter(baseFilterParam: BaseFilterParamInterface): AvailabilityRequest {
    const {
        options,
        distributionChannelId,
        customAggregations,
        resortId,
        resourceId,
        accoKindIds,
        elasticSubjects,
        petCapacity,
        fetchUnitsWithPrice,
        fetchUnitFacet,
        fetchBedBathFacet,
        fetchSortedUnits,
        env,
        resortIds,
        fetchResorts,
        fetchLatestArrivalDate,
        page,
        size,
        sortingOption,
        qualityLevelIds,
        includePreBookingPeriods,
    } = baseFilterParam;
    const arrivalDate = options.startdate ? moment(options.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC) : undefined;
    const duration = getDurationFromOptions(options);
    const specialCodes = options.specialcode && typeof options.specialcode === "string" ? [options.specialcode] : options.specialcode;
    const accommodationkindIds = accoKindIds || convertToNumberArray(options.accokindids);
    const selectedResortIds = resortIds;
    const stayPeriodDefId = options.stayperioddefid;
    const rateTypeId = options.rateType?.rateTypeId;
    // Note: dateMargin (flexible days) results are only available when we have startdate and enddate.
    const dateMargin = options.startdate && options.enddate ? options.dateMargin : undefined;
    const aggregations: Aggregation[] = customAggregations || [
        ...(options.extraAggregations || []),
        {
            name: "RESORT_FACET",
            field: "RESORT_ID",
            excludeFields: ["RESORT_ID"],
            type: "FACET",
            size: 1000,
        },
        {
            name: "ACCOMMODATIONKIND_FACET",
            field: "ACCOMMODATIONKIND_ID",
            excludeFields: ["ACCOMMODATIONKIND_ID"],
            type: "FACET",
            size: 1000,
        },
        {
            name: "ARRIVAL_DATE_FACET",
            field: "ARRIVAL_DATE",
            type: "FACET",
            excludeFields: ["ARRIVAL_DATE", "DURATION"],
            size: 1000,
        },
        {
            name: "DURATION_FACET",
            field: "DURATION",
            type: "FACET",
            excludeFields: ["DURATION"],
            size: 1000,
        },
        ALL_REGIONS_AGGREGATION,
    ];

    if (fetchUnitsWithPrice) {
        if (typeof fetchUnitsWithPrice === "string") {
            if (fetchUnitsWithPrice === "units") {
                aggregations.push(UNITS_WITH_PRICE_AGGREGATION);
            } else if (!(includePreBookingPeriods || options.includePreBookingPeriods)) {
                aggregations.push(RESOURCES_AGGREGATION);
            }
        } else {
            aggregations.push(UNITS_WITH_PRICE_AGGREGATION);
            // TODO: Remove PRICE_BREAKDOWN_AGGREGATION usages
            // when useOldAvailabilityEndpoints option is completely removed
            if (env?.concern === "csh" && options.startdate && options.enddate) {
                aggregations.push(PRICE_BREAKDOWN_AGGREGATION);
            }
        }
    } else if (fetchResorts || options.shouldFetchResorts) {
        const resortAggr: Aggregation = { ...RESORT_AGGREGATION };
        if (fetchLatestArrivalDate) {
            resortAggr.sortField = fetchLatestArrivalDate as AggrSortField;
        }
        aggregations.push(resortAggr as Aggregation);
    } else if (!(includePreBookingPeriods || options.includePreBookingPeriods)) {
        aggregations.push(RESOURCES_AGGREGATION);
    }
    if (fetchSortedUnits) {
        aggregations.push(SORTED_UNITS_AGGREGATION);
    }

    if (options.shouldFetchStayPeriods) {
        if (aggregations.findIndex((aggregation) => aggregation.name === "STAY_PERIOD_DEF_FACET") === -1) {
            aggregations.push(STAY_PERIOD_DEF_AGGREGATION);
        }
    }
    if (fetchUnitFacet) {
        aggregations.push(UNIT_IDS_AGGREGATION);
    }
    if (fetchBedBathFacet) {
        aggregations.push({
            name: "NR_OF_BEDROOMS_FACET",
            field: "NR_OF_BEDROOMS",
            type: "FACET",
            size: 100,
        });
        aggregations.push({
            name: "NR_OF_BATHROOMS_FACET",
            field: "NR_OF_BATHROOMS",
            type: "FACET",
            size: 100,
        });
    }
    if (options?.showResortAmenitiesCount) {
        aggregations.push(AMENITIES_RESORT_COUNT_AGGREGATION);
    } else {
        aggregations.push(ALL_AMENITIES_AGGREGATION);
    }
    const baseFilter: AvailabilityRequest = {
        application: APPLICATION,
        distributionChannelId,
        rateTypeIds: rateTypeId ? [rateTypeId] : undefined,
        duration: stayPeriodDefId ? undefined : duration || undefined,
        stayPeriodDefId: stayPeriodDefId ? +stayPeriodDefId : undefined,
        stayHolidayPeriodDefId: options.stayHolidayPeriodDefId,
        arrivalDate,
        resortId: options.resortId || +options.resortid,
        resortIds:
            selectedResortIds ||
            (resortId || options.resortids
                ? (Array.from(new Set(resortId ? [+resortId] : Array.isArray(options.resortids) ? options.resortids.map((id: string) => +id) : [+options.resortids])) as number[])
                : undefined),
        resourceIds: (Array.isArray(options.resourceid) && options.resourceid) || options.resourceids || resourceId,
        resourceId: (!Array.isArray(options.resourceid) && options.resourceid) || resourceId || undefined,
        unitId: options.unitid,
        accommodationkindIds: accommodationkindIds || options.accokindids,
        specialCodes,
        amenities: options.amenities,
        subjects: elasticSubjects && elasticSubjects.length > 0 ? elasticSubjects : undefined,
        petCapacity,
        minCapacity: options.minCapacity,
        maxDuration: customAggregations || (env && env.concern === "csh") ? undefined : AVAILABILITY_CONSTANTS.MAX_DURATION,
        aggregations: uniqBy(aggregations, (aggregation) => aggregation.name),
        nrOfBedrooms: options.bedroom,
        minNrOfBedrooms: options.minbedroom,
        maxNrOfBedrooms: options.maxbedroom,
        nrOfBathrooms: options.bathroom,
        minNrOfBathrooms: options.minbathroom,
        maxNrOfBathrooms: options.maxbathroom,
        minArrivalDate: options.minimumArrivalDate || (options.minArrivalDate ? moment(options.minArrivalDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC) : undefined),
        maxArrivalDate:
            options.maximumArrivalDate ?? (moment(options.maxArrivalDate, DATE_FORMAT.DEFAULT).isValid() ? moment(options.maxArrivalDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC) : null),
        releaseStatus: "OPEN",
        regionIds: options.regionIds ? options.regionIds : undefined,
        topLeftPoint: options.topLeftPoint,
        bottomRightPoint: options.bottomRightPoint,
        guestRating: options.guestRating,
        includeBookedPeriods: options.includeBookedPeriods,
        includeMissingPrices: options.includeMissingPrices,
        includePreBookingPeriods: options.includePreBookingPeriods || includePreBookingPeriods,
        dateMargin,
        qualityLevelIds: options.qualityLevelIds || qualityLevelIds,
        previouslySelectedResourceId: options.previouslySelectedResourceId,
    };
    if (fetchResorts || options.shouldFetchResorts) {
        baseFilter.resultSize = MXTS_CONSTANTS.PAGE_REQUEST_SIZE;
    }
    if (baseFilter.amenities && baseFilter.amenities.length === 0) {
        baseFilter.amenities = undefined;
    }
    if (baseFilter.resortIds && baseFilter.resortIds.length === 0) {
        baseFilter.resortIds = undefined;
    }
    if (baseFilter.resortId === null) {
        baseFilter.resortId = undefined;
    }
    if (baseFilter.accommodationkindIds && baseFilter.accommodationkindIds.length === 0) {
        baseFilter.accommodationkindIds = undefined;
    }
    if (baseFilter.specialCodes && baseFilter.specialCodes.length === 0) {
        baseFilter.specialCodes = undefined;
    }
    if (page && size && sortingOption) {
        baseFilter.page = +page;
        baseFilter.size = size;
        baseFilter.sort = sortingOption;
    }
    return baseFilter;
}

async function fetchAvailability(baseFilter: AvailabilityRequest, env: ApiCallOptions, apiContext: ApiContext, paginationData?: PaginationData): Promise<AvailabilityResult> {
    let availability = {
        filter: {},
        response: {},
    } as any;
    if (baseFilter.aggregations?.find((aggregation) => aggregation.name === RESORT_AGGREGATION.name)) {
        const resortBaseFilter = { ...baseFilter, resultSize: 0, resultFrom: 0 };
        resortBaseFilter.aggregations = [RESORT_AGGREGATION];
        const resortAvailability = await getAvailability(apiContext, env, resortBaseFilter);
        const numberOfResorts = resortAvailability?.response.numberOfResorts!;
        const chunkSize = 20;
        const total = Math.max(10, numberOfResorts);
        const chunks = Array(Math.ceil(total / chunkSize)).fill(chunkSize);
        const allObtainedDcIdChunks = await Promise.all(
            chunks.map((size: number, page: number) => {
                const modifiedFilter = {
                    ...baseFilter,
                    ...paginationData,
                    resultSize: size,
                    resultFrom: page,
                };
                return getAvailability(apiContext, env, modifiedFilter);
            })
        );
        allObtainedDcIdChunks.map((mainObj) => {
            if (mainObj && typeof mainObj === "object" && mainObj.filter) {
                availability.filter = { ...mainObj.filter };
            }
            Object.keys(mainObj?.response || {}).map((key) => {
                const responseValue = (mainObj as any)?.response[key];
                if (Array.isArray(responseValue)) {
                    if (!availability?.response?.[key]) {
                        availability.response[key] = new Set();
                    }
                    for (const value of responseValue) {
                        if (typeof value === "object" && value !== null) {
                            const valueAsString = JSON.stringify(value);
                            availability.response[key].add(valueAsString);
                        } else {
                            availability.response[key].add(value);
                        }
                    }
                } else {
                    availability.response[key] = responseValue;
                }
            });
        });
        for (const key in availability.response) {
            if (availability.response[key] instanceof Set) {
                availability.response[key] = Array.from(availability.response[key]);
                if (availability.response[key].length > 0 && typeof availability.response[key][0] === "string") {
                    availability.response[key] = availability.response[key].map((item: any) => {
                        try {
                            return JSON.parse(item);
                        } catch {
                            return item;
                        }
                    });
                }
            }
        }
    } else {
        availability = await getAvailability(apiContext, env, { ...baseFilter, ...paginationData });
    }
    return availability as AvailabilityResult;
}
export const getAvailability = async (apiContext: ApiContext, ops: ApiCallOptions, baseFilter: AvailabilityRequest, amenityId?: number): Promise<AvailabilityResult> => {
    if (amenityId) {
        baseFilter.amenities = [amenityId!];
    }
    if (baseFilter.amenities) {
        const fetchedAmenities = await apiContext.mxtsApi.amenities(ops, { identifier: baseFilter.amenities?.join(",") }, undefined).then((amenity) => amenity.content);
        if (fetchedAmenities) {
            baseFilter.amenities = fetchedAmenities.map((fetchedAmenity) => fetchedAmenity.amenityId);
        }
    }
    const amenitiesAggregationIndex = baseFilter.aggregations.findIndex((aggregation) => ALL_AMENITIES_AGGREGATION.name === aggregation.name);
    const amenitiesAggregation = { ...baseFilter.aggregations[amenitiesAggregationIndex] };
    const isUnit = baseFilter.aggregations.some((aggregation) => [UNITS_WITH_PRICE_AGGREGATION.name, SORTED_UNITS_AGGREGATION.name].includes(aggregation.name));
    if (isUnit) {
        const isNightIndex = isNightIndexMode(await getAvailabilityIndexMode(apiContext));
        const hasResourceId = !!baseFilter.resourceId || (baseFilter.resourceIds ? baseFilter.resourceIds.length > 0 : false);
        if (!hasResourceId && !isNightIndex) {
            throw new Error("Unit search without resourceId is only allowed for night index mode");
        }

        if (isNightIndex && baseFilter.arrivalDate && baseFilter.duration) {
            baseFilter.aggregations = baseFilter.aggregations.filter((aggregation) => aggregation.name !== PRICE_BREAKDOWN_AGGREGATION.name);
        }
        return apiContext.mxtsApi.availabilityUnit(ops, baseFilter);
    }
    if (amenitiesAggregation) {
        const isResource = baseFilter.aggregations.some((aggregation) => RESOURCES_AGGREGATION.name === aggregation.name);
        const isResort = baseFilter.aggregations.some((aggregation) => RESORT_AGGREGATION.name === aggregation.name);
        if (isResource || isResort || isUnit) {
            baseFilter.aggregations[amenitiesAggregationIndex] = amenitiesAggregation;
        }
    }
    return apiContext.mxtsApi.availabilityResource2(ops, baseFilter);
};

export enum AVAILABILITY_INDEX_MODE {
    LOS_PRICECACHE = "los.pricecache",
    LOS_CASHFLOWRULE = "los.cashflowrule",
    UNIT_NIGHT_CASHFLOWRULE = "unit.night.cashflowrule",
    TYPE_NIGHT_CASHFLOWRULE = "type.night.cashflowrule",
}

export async function getAvailabilityIndexMode(apiContext: ApiContext): Promise<AVAILABILITY_INDEX_MODE | null> {
    const env = await getMxtsEnv(apiContext);
    const features = await apiContext.mxtsApi.getAllFeatureFlags(env, { code: "availability.index.mode" });
    return (features.content?.[0]?.flag?.valueVarchar2 as AVAILABILITY_INDEX_MODE) || null;
}

export function isNightIndexMode(indexMode: AVAILABILITY_INDEX_MODE | null): boolean {
    return indexMode === AVAILABILITY_INDEX_MODE.TYPE_NIGHT_CASHFLOWRULE || indexMode === AVAILABILITY_INDEX_MODE.UNIT_NIGHT_CASHFLOWRULE;
}
