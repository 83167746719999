import { ContextSlice, PluginSpec } from "../";

import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

const PagePlugin: PluginSpec<Record<string, unknown>> = {
    id: "plugin-page",
    name: getI18nLocaleObject(namespaceList.pluginBuiltinPage, "pagePlugin"),
    description: getI18nLocaleObject(namespaceList.pluginBuiltinPage, "pagePluginDescription"),
    widgets: [
        {
            id: "flexbox",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-flexbox" */ "./flexbox"));
                context?.chunkExtractor?.addChunk("page-flexbox");
                return await ref.load();
            },
            targetName: "flexboxWidget",
            type: "page",
            widgetGroup: WidgetGroup.LAYOUT,
            parameters: ["page"],
            container: true,
        },
        {
            id: "revealer",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-revealer" */ "../shared/revealer"));
                context?.chunkExtractor?.addChunk("shared-revealer");
                return await ref.load();
            },
            targetName: "revealerWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "slideShow",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-slideShow" */ "./slideShow"));
                context?.chunkExtractor?.addChunk("page-slideShow");
                return await ref.load();
            },
            targetName: "slideShowWidget",
            type: "page",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "tabs",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-tabs" */ "./tabs"));
                context?.chunkExtractor?.addChunk("page-tabs");
                return await ref.load();
            },
            targetName: "tabsWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "spacer",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-spacer" */ "./spacer"));
                context?.chunkExtractor?.addChunk("page-spacer");
                return await ref.load();
            },
            targetName: "spacerWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.LAYOUT,
        },
        {
            id: "divider",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-divider" */ "./divider"));
                context?.chunkExtractor?.addChunk("page-divider");
                return await ref.load();
            },
            targetName: "dividerWidget",
            type: "page",
            widgetGroup: WidgetGroup.LAYOUT,
        },
        {
            id: "columns",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-columns" */ "./columns"));
                context?.chunkExtractor?.addChunk("page-columns");
                return await ref.load();
            },
            targetName: "columnsWidget",
            type: "page",
            widgetGroup: WidgetGroup.LAYOUT,
            container: true,
        },
        {
            id: "image",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-image" */ "./image"));
                context?.chunkExtractor?.addChunk("page-image");
                return await ref.load();
            },
            targetName: "imageWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "imagegallery",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-imageGallery" */ "./image-gallery"));
                context?.chunkExtractor?.addChunk("page-imageGallery");
                return await ref.load();
            },
            targetName: "imagesWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "webcontent",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-webcontent" */ "./web-content"));
                context?.chunkExtractor?.addChunk("page-webcontent");
                return await ref.load();
            },
            targetName: "webContentWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "button",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-button" */ "./button"));
                context?.chunkExtractor?.addChunk("page-button");
                return await ref.load();
            },
            targetName: "buttonWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "menu",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "menu-menuView" */ "../menu/menu-view"));
                context?.chunkExtractor?.addChunk("menu-menuView");
                return await ref.load();
            },
            targetName: "menuViewWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "breadcrumb",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-breadcrumb" */ "./breadcrumb"));
                context?.chunkExtractor?.addChunk("page-breadcrumb");
                return await ref.load();
            },
            targetName: "breadcrumbWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "assetpublisher",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-assetpublisher" */ "./assetpublisher"));
                context?.chunkExtractor?.addChunk("page-assetpublisher");
                return await ref.load();
            },
            targetName: "assetPublisherWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "map",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-map" */ "./map"));
                context?.chunkExtractor?.addChunk("page-map");
                return await ref.load();
            },
            targetName: "mapWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "static",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-static" */ "./static"));
                context?.chunkExtractor?.addChunk("page-static");
                return await ref.load();
            },
            targetName: "staticWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "iframe",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-iframe" */ "./iframe"));
                context?.chunkExtractor?.addChunk("page-iframe");
                return await ref.load();
            },
            targetName: "iframeWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "language",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-language" */ "./language"));
                context?.chunkExtractor?.addChunk("page-language");
                return await ref.load();
            },
            targetName: "languageWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "goToTop",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-goToTop" */ "./goToTop"));
                context?.chunkExtractor?.addChunk("page-goToTop");
                return await ref.load();
            },
            targetName: "goToTopWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "social",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-social" */ "./social"));
                context?.chunkExtractor?.addChunk("page-social");
                return await ref.load();
            },
            targetName: "socialWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "video",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-video" */ "./video"));
                context?.chunkExtractor?.addChunk("page-video");
                return await ref.load();
            },
            targetName: "videoWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "post",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-posts" */ "./posts"));
                context?.chunkExtractor?.addChunk("page-posts");
                return await ref.load();
            },
            targetName: "postsWidget",
            type: "page",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "card",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-card" */ "./card"));
                context?.chunkExtractor?.addChunk("page-card");
                return await ref.load();
            },
            targetName: "cardWidget",
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "template",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-template" */ "./template"));
                context?.chunkExtractor?.addChunk("page-template");
                return await ref.load();
            },
            targetName: "templateWidget",
            parameters: ["page"],
            type: "page",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "pagePanelPrice",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-price" */ "../resultsPanel/price"));
                context?.chunkExtractor?.addChunk("resultsPanel-price");
                return await ref.load();
            },
            targetName: "resultsPanelPriceWidget",
            parameters: [{ widgetId: "pagePanelPrice", widgetType: "page", widgetOptionsFormId: "page-panel-price-widget" }],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageLocationWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-location" */ "../shared/location"));
                context?.chunkExtractor?.addChunk("shared-location");
                return await ref.load();
            },
            targetName: "locationWidget",
            parameters: [{ widgetId: "pageLocationWidget", widgetType: "page", widgetOptionsFormId: "page-location-options" }],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageSpecialWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-special" */ "../shared/special"));
                context?.chunkExtractor?.addChunk("shared-special");
                return await ref.load();
            },
            targetName: "sharedSpecialWidget",
            parameters: [{ widgetId: "pageSpecialWidget", widgetType: "page", widgetOptionsFormId: "page-special-widget" }],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageTimeslotWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-activityTimeslot" */ "../shared/activityTimeslot"));
                context?.chunkExtractor?.addChunk("shared-activityTimeslot");
                return await ref.load();
            },
            targetName: "sharedActivityTimeslotWidget",
            parameters: [{ widgetId: "pageTimeslotWidget", widgetType: "page", widgetOptionsFormId: "page-timeslot-widget" }],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pagePriceMatrixWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-priceMatrix" */ "../shared/priceMatrix"));
                context?.chunkExtractor?.addChunk("shared-priceMatrix");
                return await ref.load();
            },
            targetName: "sharedPriceMatrixWidget",
            parameters: [{ widgetId: "pagePriceMatrixWidget", widgetType: "page", widgetOptionsFormId: "page-priceMatrix-widget" }],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageReservableUnitWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-reservableUnit" */ "../shared/reservable-unit"));
                context?.chunkExtractor?.addChunk("shared-reservableUnit");
                return await ref.load();
            },
            targetName: "reservableUnitWidget",
            parameters: [{ widgetId: "pageReservableUnitWidget", widgetType: "page", widgetOptionsFormId: "page-reservable-unit-options" }],
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "countdownTimer",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-countdownTimer" */ "./countdownTimer"));
                context?.chunkExtractor?.addChunk("page-countdownTimer");
                return await ref.load();
            },
            targetName: "countdownTimerWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "activity",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-activityPlanner" */ "./activityPlanner"));
                context?.chunkExtractor?.addChunk("page-activityPlanner");
                return await ref.load();
            },
            targetName: "activityPlannerWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageTicketCounterWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-ticketCounter" */ "./ticketCounter"));
                context?.chunkExtractor?.addChunk("page-ticketCounter");
                return await ref.load();
            },
            targetName: "ticketCounterWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageTipsAndTripsWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-tipsAndTrips" */ "./tipsAndTrips"));
                context?.chunkExtractor?.addChunk("page-tipsAndTrips");
                return await ref.load();
            },
            targetName: "tipsAndTripsWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageCategoryFilterWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-categoryFilter" */ "./categoryFilter"));
                context?.chunkExtractor?.addChunk("page-categoryFilter");
                return await ref.load();
            },
            targetName: "categoryFilterWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "customerLoyalty",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-customerLoyalty" */ "./customerLoyalty"));
                context?.chunkExtractor?.addChunk("page-customerLoyalty");
                return await ref.load();
            },
            targetName: "customerLoyaltyWidget",
            type: "page",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: "pageDateFilterWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-dateFilter" */ "./dateFilter"));
                context?.chunkExtractor?.addChunk("page-dateFilter");
                return await ref.load();
            },
            targetName: "dateFilterWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "pageDisplayCapacityWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-capacity" */ "../resultsPanel/display/capacity"));
                context?.chunkExtractor?.addChunk("resultsPanel-capacity");
                return await ref.load();
            },
            targetName: "resultsPanelDisplayCapacityWidget",
            parameters: [{ widgetId: "pageDisplayCapacityWidget", widgetType: "page", widgetOptionsFormId: "page-display-capacity-options" }],
            type: "page",
            widgetGroup: WidgetGroup.CONTENT,
        },
        {
            id: "pagePaymentStatusWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-paymentStatus" */ "../shared/payment-status"));
                context?.chunkExtractor?.addChunk("shared-paymentStatus");
                return await ref.load();
            },
            targetName: "paymentStatusWidget",
            parameters: [{ widgetId: "pagePaymentStatusWidget", widgetType: "page", widgetOptionsFormId: "page-payment-status-options" }],
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "pageTravelPartyWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-travelParty" */ "./travel-party"));
                context?.chunkExtractor?.addChunk("page-travelParty");
                return await ref.load();
            },
            targetName: "travelPartyWidget",
            parameters: [{ widgetId: "pageTravelPartyWidget", widgetType: "page", widgetOptionsFormId: "page-travel-party-options" }],
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "pageTravelPartySummaryWidget",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "resultsPanel-travelPartySummary" */ "../resultsPanel/travel-party-summary"));
                context?.chunkExtractor?.addChunk("resultsPanel-travelPartySummary");
                return await ref.load();
            },
            targetName: "travelPartySummaryWidget",
            parameters: [{ widgetId: "pageTravelPartySummaryWidget", widgetType: "page", widgetOptionsFormId: "page-travel-party-summary-options" }],
            type: "page",
            widgetGroup: WidgetGroup.MY_ENVIRONMENT,
        },
        {
            id: "activitySectionTable",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-activitySection" */ "./activitySection"));
                context?.chunkExtractor?.addChunk("page-activitySection");
                return await ref.load();
            },
            targetName: "activitySectionWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "activitySubjectFilter",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "page-activitySubjectFilter" */ "./activitySubjectFilter"));
                context?.chunkExtractor?.addChunk("page-activitySubjectFilter");
                return await ref.load();
            },
            targetName: "activitySubjectFilterWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
        {
            id: "qualityLevel",
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "shared-qualityLevel" */ "../shared/qualityLevel"));
                context?.chunkExtractor?.addChunk("shared-qualityLevel");
                return await ref.load();
            },
            parameters: [{ widgetId: "qualityLevel", widgetType: "page" }],
            targetName: "QualityLevelWidget",
            type: "page",
            widgetGroup: WidgetGroup.DYNAMIC,
        },
    ],
};

export default PagePlugin;
