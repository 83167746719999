import { Address, ApiCallOptions, Customer, CustomerFullAddress, MxtsApiWrapper, PagedResult } from "@maxxton/cms-mxts-api";

import { MXTS } from "./constants";
import { chunk } from "lodash";

const { PAGE_REQUEST_SIZE } = MXTS;

export const fetchAddressesByMangerIds = async (mxtsApi: MxtsApiWrapper, { managerIds, env }: { managerIds: number[]; env: ApiCallOptions }): Promise<Address[]> => {
    const managerIdsChunk = chunk(managerIds, PAGE_REQUEST_SIZE);
    const addressPromises = managerIdsChunk.map((managerIds) =>
        mxtsApi.addresses(env, { size: PAGE_REQUEST_SIZE, managerIds }).then((addressResponse: PagedResult<Address>) => addressResponse.content)
    );
    const addresses = (await Promise.all(addressPromises)).flat();
    return addresses;
};

export function getCustomerMailAddress(customer?: Customer): string | undefined {
    return getCustomerMainAddress(customer)?.email?.trim() || undefined;
}

export function getCustomerMainAddress(customer?: Customer): CustomerFullAddress | undefined {
    const mailAddress = customer?.addresses?.find((address) => address.managerId === customer.mailAddressManagerId);
    return mailAddress || undefined;
}
