export enum DISPLAY_PRICE_INFO {
    DROPDOWN = "viewAsDropdown",
    MODAL = "viewAsModal",
    NONE = "none",
}

export enum MatrixOptions {
    SUMMARY = "summary",
    MATRIX = "matrix",
    ARRIVAL = "arrival",
    DURATION = "duration",
}

export const DEFAULT_PRICE_STAY_TABLE_SIZE = 15;
