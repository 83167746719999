import {
    ACTIVITY,
    CONTENT_TAGS,
    CUSTOM_FIELD,
    DYNAMIC_FIELD_CODE,
    LOCATION,
    RESERVATION,
    SIGN_UP,
    TIPS_AND_TRIPS,
    UNIT,
    contentTypeSelector,
    descriptionMultiSelectorFallback,
    descriptionTypeMultiSelector,
    dynamicFieldPrice,
    pointsOfInterestOptions,
    resortMultiSelector,
} from "../../../components/utils";
import { FormSpec, getHelpId, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { NumberMultiSelectOption as MultiSelectOptionAsNumber, NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { MultiSelectOptionNumberValue, WithId } from "@maxxton/cms-api";
import { dynamicFieldMultiSelectorFallback, resourcesAutocomplete, unitsAutocomplete } from "../../../inputSpecs";

import { ActivityLocationOptions } from "../../page/activityPlanner/activityPlanner.types";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import activitiesAutocomplete from "../../../inputSpecs/activityAutocomplete";
import { getActivityLocationArea } from "../../page/activityPlanner/activityPlanner.util";
import { getCustomFieldOptions } from "./description.utils";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { pointsOfInterestCategoryOptions } from "../../page/tipsAndTrips/tipsAndTrips.util";

export interface WidgetOptions extends LocalizedTitleOptions, WithId, ActivityLocationOptions {
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    activityIds?: MultiSelectOptionAsNumber[];
    pointsOfInterestIds?: MultiSelectOptionAsNumber[];
    pointsOfInterestCategoryIds?: MultiSelectOptionAsNumber[];
    styleIds: string[];
    descFontSize: string;
    descFontColor: string;
    descFontStyle: string;
    nameStyle?: string;
    nameTextBackground?: string;
    nameTextUndrlinedSize?: string;
    nameTextUndrlinedColor?: string;
    descriptionTypeMultiSelector?: NumberMultiSelectOption[];
    showDynamicField?: boolean;
    dynamicFieldCodes?: NumberMultiSelectOption[];
    descriptionMultiSelectorFallback?: NumberMultiSelectOption[];
    dynamicFieldFallback?: NumberMultiSelectOption[];
    fallbackDescription?: boolean;
    showAllInventoryList?: boolean;
    deleteOptionForUnit?: boolean;
    enableWidgetTitle?: boolean;
    contentTag?: string;
    linking: LinkingSpecOptions;
    enableBookingsEngineLink: boolean;
    activityDynamicFieldCodes?: NumberMultiSelectOption[];
    customFieldIds?: NumberMultiSelectOption[];
    displayTitleInline?: boolean;
    hideSingleAccoCount?: boolean;
}

const TARGETS = ["description"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "description-widget-options",
    name: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.descriptionWidget, "descriptionWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            contentTypeSelector("contentType"),
                            resortMultiSelector("resortMultiSelector"),
                            resourcesAutocomplete("resourceId"),
                            unitsAutocomplete("unitId"),
                            activitiesAutocomplete("activityIds"),
                            {
                                variable: "pointsOfInterestIds",
                                label: getI18nLocaleObject(namespaceList.admin, "pointsOfInterestIds"),
                                type: "lazyLoadMultiSelect",
                                lazyLoadOptions: (page: number, searchQuery: string, ids: string[] | undefined) => pointsOfInterestOptions(page, searchQuery, ids),
                                visible: (options: WidgetOptions) => options?.contentType === TIPS_AND_TRIPS && !options?.pointsOfInterestCategoryIds?.length,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "pointsOfInterestIds"),
                            },
                            {
                                variable: "pointsOfInterestCategoryIds",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "pointsOfInterestCategories"),
                                type: "lazyLoadMultiSelect",
                                lazyLoadOptions: (page: number, searchQuery: string, ids: string[] | undefined) => pointsOfInterestCategoryOptions(page, searchQuery, ids),
                                visible: (options: WidgetOptions) => options?.contentType === TIPS_AND_TRIPS && !options?.pointsOfInterestIds?.length,
                                placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "pointsOfInterestCategories"),
                            },
                            {
                                variable: "deleteOptionForUnit",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "deleteOptionForUnit"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.contentType === UNIT,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            {
                                variable: "hideSingleAccoCount",
                                label: getI18nLocaleObject(namespaceList.descriptionWidget, "hideSingleAccoCount"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "content"),
                    help: getHelpId("zaA4xd-content"),
                    properties: [
                        [
                            descriptionTypeMultiSelector("descriptionTypeMultiSelector"),
                            {
                                variable: "customFieldIds",
                                label: getI18nLocaleObject(namespaceList.descriptionWidget, "customFieldIds"),
                                type: "multiselect",
                                optionList: async () => getCustomFieldOptions(MxtsApi),
                                placeholder: getI18nLocaleObject(namespaceList.admin, "contractPlaceholder"),
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === CUSTOM_FIELD) && options.contentType === RESERVATION,
                            },
                            {
                                ...dynamicFieldPrice("dynamicFieldCodes"),
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE) && options.contentType !== ACTIVITY,
                            },
                            {
                                ...dynamicFieldPrice("activityDynamicFieldCodes", undefined, undefined, "Activity"),
                                visible: (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === DYNAMIC_FIELD_CODE) && options.contentType === ACTIVITY,
                            },
                            {
                                variable: "contentTag",
                                label: getI18nLocaleObject(namespaceList.descriptionWidget, "contentTag"),
                                type: "select",
                                visible: (options: WidgetOptions) => !!options.descriptionTypeMultiSelector?.length,
                                optionList: CONTENT_TAGS.map((tag) => ({ value: tag, label: getI18nLocaleObject(namespaceList.descriptionWidget, tag) })),
                            },
                            {
                                variable: "fallbackDescription",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "fallbackDescription"),
                                type: "checkbox",
                            },
                            descriptionMultiSelectorFallback("descriptionMultiSelectorFallback"),
                            dynamicFieldMultiSelectorFallback("dynamicFieldFallback"),
                            ...getActivityLocationArea<WidgetOptions>(
                                (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === LOCATION) && options.contentType === ACTIVITY,
                                (options: WidgetOptions) =>
                                    !!options.descriptionTypeMultiSelector?.find((check: MultiSelectOptionNumberValue) => check.value === SIGN_UP) && options.contentType === ACTIVITY
                            ),
                            {
                                variable: "showAllInventoryList",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "showAllInventoryList"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                { name: getI18nLocaleObject(namespaceList.admin, "linking"), properties: [[linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" })]] },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "displayTitleInline",
                                label: getI18nLocaleObject(namespaceList.subject, "displayInline"),
                                type: "checkbox",
                            },
                            {
                                variable: "descFontSize",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontSize"),
                                type: "select",
                                default: "default-font-size",
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "descFontColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontColor"),
                                type: "dual-color",
                                default: "font-default",
                            },
                            {
                                variable: "descFontStyle",
                                label: getI18nLocaleObject(namespaceList.admin, "descFontStyle"),
                                type: "select",
                                default: "default",
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "text-uppercase",
                                        label: getI18nLocaleObject(namespaceList.admin, "allCaps"),
                                    },
                                    {
                                        value: "text-capitalize",
                                        label: getI18nLocaleObject(namespaceList.admin, "camelCase"),
                                    },
                                    {
                                        value: "text-lowercase",
                                        label: getI18nLocaleObject(namespaceList.admin, "lowerCase"),
                                    },
                                ],
                            },
                            {
                                variable: "nameStyle",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "nameStyle"),
                                type: "select",
                                default: "default",
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "background-text",
                                        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "backgroundText"),
                                    },
                                    {
                                        value: "row-bordered",
                                        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "underlineText"),
                                    },
                                ],
                            },
                            {
                                variable: "nameTextBackground",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "textBackground"),
                                type: "dual-color",
                                default: "background-gray",
                                visible: (options: WidgetOptions) => options.nameStyle === "background-text",
                            },
                            {
                                variable: "nameTextUndrlinedSize",
                                label: getI18nLocaleObject(namespaceList.admin, "borderWidth"),
                                type: "text",
                                visible: (options: WidgetOptions) => options.nameStyle === "row-bordered",
                            },
                            {
                                variable: "nameTextUndrlinedColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "borderLineColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => options.nameStyle === "row-bordered",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>(),
                        ],
                    ],
                },
            ],
        },
    ],
};
