import { ApiCallOptions, MxtsApi } from "@maxxton/cms-mxts-api";
import { compact, uniq } from "lodash";

import { MXTS } from "./constants";
import { getCustomerIdsFromLoginToken } from "../redux/reducers/myEnv/myEnv.util";

export const getSharedOwnershipWithSharedOwnerId = async (env: ApiCallOptions, sharedOwnerIds: number[]) => {
    const sharedOwnership = await Promise.all(
        sharedOwnerIds.map(async (sharedOwnerId) => {
            const shareOwnership = (await MxtsApi.getSharedOwnership(env, { shareOwnerId: sharedOwnerId })).content;
            if (shareOwnership.length) {
                return shareOwnership[0];
            }
        })
    );
    if (sharedOwnership.length) {
        return compact(sharedOwnership);
    }
};

export const getSharedOwnersUnits = async (env: ApiCallOptions) => {
    const ownerIds = await getCustomerIdsFromLoginToken();
    if (ownerIds.length) {
        const sharedOwnerships = await getSharedOwnershipWithSharedOwnerId(env, ownerIds);
        if (sharedOwnerships?.length) {
            const sharedOwnerIds: number[] = sharedOwnerships.map((sharedOwnership) => sharedOwnership.ownerId);
            const contracts = await MxtsApi.getContracts(env, { size: MXTS.MAX_RESULTS, ownerIds: sharedOwnerIds });
            return uniq(contracts.content.map((contract) => contract.unitId));
        }
    }
};
