import * as moment from "moment";

import { ApiCallOptions, BillLine, Instalment, MxtsApi, PagedResult } from "@maxxton/cms-mxts-api";
import { ForkEffect, call, put, select, throttle } from "redux-saga/effects";
import { InstalmentsActionType, InstalmentsReducerAction, setInstalmentsFetching } from "../redux/actions/instalmentsAction";

import { DATE_FORMAT } from "../utils/constants";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { InstalmentsState } from "../redux/reducers/instalmentsState";
import { SagaUtils } from "./SagaUtils";
import { getMxtsEnv } from "../plugins/mxts/index";
import { globalApiContext } from "../containers/CmsProvider";

function* handleFetchInstalments(action: InstalmentsReducerAction) {
    const instalmentsState: InstalmentsState = yield select(SagaUtils.getInstalmentsState);
    const dynamicFilter: DynamicFilter = yield select(SagaUtils.getDynamicFilter);
    const totalBillLines: BillLine[] = yield select(SagaUtils.getTotalBillLines);

    const autoSelectSingularInstalment = action.payload.autoSelectSingularInstalment;
    const reservationId = action.payload.reservationId || instalmentsState.reservationId;
    const autoSelectAllInstalment = action.payload.autoSelectAllInstalment;
    const payerTypesToHide = action.payload.payerTypesToHide;

    if (reservationId) {
        yield put(setInstalmentsFetching(true));
        const env: ApiCallOptions = yield call(getMxtsEnv, globalApiContext(), dynamicFilter.currentLocale);
        const instalmentsResult: PagedResult<Instalment> = yield call(MxtsApi.getInstalments, env, { reservationId });
        const instalments = instalmentsResult.content || [];
        let selectedInstalments = [...instalmentsResult.content];
        if (payerTypesToHide?.length) {
            selectedInstalments = selectedInstalments?.filter((instalment) => !payerTypesToHide.some((item) => item.value === instalment.payerType));
        }

        const instalmentsTotalDueIds = getInstalmentsTotalDueIds(selectedInstalments, totalBillLines);
        let selectedInstalmentIds = selectedInstalments.filter((instalment: Instalment) => instalment.dueDate === moment().format(DATE_FORMAT.MXTS)).map((instalment: Instalment) => instalment.id);
        if (autoSelectSingularInstalment && selectedInstalments.length === 1) {
            selectedInstalmentIds = [selectedInstalments[0].id];
        }
        if (autoSelectAllInstalment && selectedInstalments.length > 1) {
            selectedInstalmentIds = selectedInstalments.map((instalment: Instalment) => instalment.id);
        }
        const payLater = selectedInstalments.every((instalment: Instalment) => instalment.dueDate !== moment().format(DATE_FORMAT.MXTS));
        yield put({
            type: InstalmentsActionType.INSTALMENTS_FETCHED,
            payload: {
                instalments,
                payLater,
                selectedInstalmentIds: instalmentsTotalDueIds || selectedInstalmentIds,
                fetchingInstalments: false,
                payerTypesToHide,
            },
        });
    }
}

const getInstalmentsTotalDueIds = (instalments: Instalment[], totalBillLines: BillLine[]) => {
    const billTotal: BillLine | undefined = totalBillLines.find((billLine) => billLine.billLineType === "TOTAL");
    const totalDueInstalmentIds = instalments.filter((instalment) => instalment.due === billTotal?.total).map((instalment) => instalment.id);
    if (totalDueInstalmentIds.length) {
        return totalDueInstalmentIds;
    }
    return null;
};

export function* watchInstalmentsActions(): Generator<ForkEffect, void, boolean> {
    yield throttle(1000, InstalmentsActionType.FETCH_INSTALMENTS, handleFetchInstalments);
}
