import { Action } from "./";

export enum UserLoginFormActionType {
    validate = "validate",
    change = "change",
    isValidationCheckList = "isValidationCheckList",
}

export interface UserLoginFormAction extends Action {
    actionType: UserLoginFormActionType;
    payload: any;
}
