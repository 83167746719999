import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../../form-specs";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../../components/widgetTitleAndLabel/localizedLableTitle.util";

import { MultiSelectOption } from "../../../resultsPanel/price";
import { PageWidgetSpec } from "../../../";
import { PaymentMethod } from "./PaymentMethod";
import { WidgetGroup } from "../../../widget.enum";
import { autocompleteSiteSpec } from "../../../../form-specs/models/autocompleteSite";
import { findMultiSelectStyleClassNames } from "../../../../themes";
import { getI18nLocaleObject } from "../../../../i18n";
import { getMultiSelectPaymentMethodBrands } from "../../../../components/utils";
import namespaceList from "../../../../i18n/namespaceList";
import { pageSpec } from "../../../../form-specs/models/page";
import { templateSpec } from "../../../../form-specs/models/template";
import { webcontentSpecWithoutPreview } from "../../../../form-specs/models/autocompleteWebContent";

export enum PaymentMethodTypes {
    IDEAL = "ideal",
    CREDITCARD = "creditcard",
    DIRECT_E_BANKING_DE = "DirectEbankingDE",
    DIRECT_E_BANKING_BE = "DirectEbankingBE",
    BCMC = "CreditCard",
    GIROPAY = "giropay",
    KBC_ONLINE = "KBC Online",
    BELFIUS = "Dexia Direct Net",
    Przelewy24 = "Przelewy24",
    Cadeaukaart = "Intersolve",
    APPLEPAY = "APPLEPAY",
    GOOGLEPAY = "GOOGLEPAY",
}

export enum PaymentMethodBrands {
    IDEAL = "ideal",
    VISA = "visa",
    MASTERCARD = "mastercard",
    MAESTRO = "maestro",
    AMERICAN_EXPRESS = "American Express",
    DIRECT_E_BANKING_DE = "DirectEBankingDE",
    DIRECT_E_BANKING_BE = "DirectEBankingBE",
    BCMC = "BCMC",
    SOFORT = "Sofort",
    GIROPAY = "GIROPAY",
    KBC_ONLINE = "KBC Online",
    BELFIUS = "Dexia Direct Net",
    Przelewy24 = "Przelewy24",
    Cadeaukaart = "Beekse Bergen cadeaukaart",
    APPLEPAY = "APPLEPAY",
    GOOGLEPAY = "GOOGLEPAY",
}

export enum BankNames {
    ABN = "ABN AMRO",
    ASN = "ASN Bank",
    ING = "ING",
    RABOBANK = "Rabobank",
    SNS = "SNS Bank",
    REGIO = "Regio Bank",
    TRIODOS = "Triodos Bank",
    KNAB = "Knab Bank",
    HANDELSBANKEN = "Handelsbanken",
    MONEYOU = "Moneyou",
    BUNQ = "Bunq",
    VAN_LANSCHOT_BANKIERS = "Van Lanschot Bankiers",
}

export enum BankCodes {
    ABN = "ABNANL2A",
    ASN = "ASNBNL21",
    ING = "INGBNL2A",
    RABOBANK = "RABONL2U",
    SNS = "SNSBNL2A",
    REGIO = "RBRBNL21",
    TRIODOS = "TRIONL2U",
    KNAB = "KNABNL2H",
    HANDELSBANKEN = "HANDNL2A",
    MONEYOU = "MOYONL21",
    BUNQ = "BUNQNL2A",
    VAN_LANSCHOT_BANKIERS = "FVLBNL22",
}

export interface LocaleContent {
    locale: string;
    paymentMethods?: MultiSelectOption[];
    banks?: MultiSelectOption[];
    siteId: string;
    pageId: string;
    templateUrl: string;
    buttonLabel: string;
    useExternalUrlForStatus?: boolean;
    externalRedirectUrl?: string;
}

export interface WidgetOptions extends LocalizedTitleOptions {
    localizedContent: LocaleContent[];
    styleIds?: string[];
    paymentMethodFallbackWebContentId?: string | null;
    paymentMethodFallbackTemplateId?: string | null;
    alwaysShowPaymentMethods: boolean;
    showPaymentMethodsLabel: boolean;
}

const TARGETS = ["paymentMethods"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "payment-methods",
    name: getI18nLocaleObject(namespaceList.admin, "paymentMethodsOptionForm"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "paymentMethodsOptionForm"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "selectPaymentMethods"),
                                        variable: "paymentMethods",
                                        type: "multiselect" as const,
                                        optionList: async () => getMultiSelectPaymentMethodBrands(),
                                    },

                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "selectBanks"),
                                        variable: "banks",
                                        type: "multiselect" as const,
                                        optionList: async () => [
                                            {
                                                text: BankNames.ABN,
                                                value: BankCodes.ABN,
                                            },
                                            {
                                                text: BankNames.ASN,
                                                value: BankCodes.ASN,
                                            },
                                            {
                                                text: BankNames.BUNQ,
                                                value: BankCodes.BUNQ,
                                            },
                                            {
                                                text: BankNames.HANDELSBANKEN,
                                                value: BankCodes.HANDELSBANKEN,
                                            },
                                            {
                                                text: BankNames.ING,
                                                value: BankCodes.ING,
                                            },
                                            {
                                                text: BankNames.KNAB,
                                                value: BankCodes.KNAB,
                                            },
                                            {
                                                text: BankNames.MONEYOU,
                                                value: BankCodes.MONEYOU,
                                            },
                                            {
                                                text: BankNames.RABOBANK,
                                                value: BankCodes.RABOBANK,
                                            },
                                            {
                                                text: BankNames.REGIO,
                                                value: BankCodes.REGIO,
                                            },
                                            {
                                                text: BankNames.SNS,
                                                value: BankCodes.SNS,
                                            },
                                            {
                                                text: BankNames.TRIODOS,
                                                value: BankCodes.TRIODOS,
                                            },
                                            {
                                                text: BankNames.VAN_LANSCHOT_BANKIERS,
                                                value: BankCodes.VAN_LANSCHOT_BANKIERS,
                                            },
                                        ],
                                        visible: (item: any) => item.paymentMethods && item.paymentMethods.some((method: any) => method.value === PaymentMethodBrands.IDEAL),
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "statusPageSite"),
                                        variable: "siteId",
                                        type: "autocomplete",
                                        refType: autocompleteSiteSpec,
                                        visible: (options: LocaleContent) => !options.useExternalUrlForStatus,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "statusPage"),
                                        variable: "pageId",
                                        type: "autocomplete",
                                        refType: pageSpec,
                                        dependsOnSiteSpec: "siteId",
                                        visible: (options: LocaleContent) => !options.useExternalUrlForStatus,
                                    },
                                    {
                                        variable: "useExternalUrlForStatus",
                                        label: getI18nLocaleObject(namespaceList.admin, "useExternalRedirectUrl"),
                                        type: "checkbox",
                                        default: false,
                                    },
                                    {
                                        variable: "externalRedirectUrl",
                                        label: getI18nLocaleObject(namespaceList.admin, "externalUrl"),
                                        type: "text",
                                        default: "",
                                        visible: (options: any) => !!options.useExternalUrlForStatus,
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "templateUrl"),
                                        variable: "templateUrl",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "buttonLabel"),
                                        variable: "buttonLabel",
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "alwaysShowPaymentMethods",
                                label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "alwaysShowPaymentMethods"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "showPaymentMethodsLabel",
                                label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "showPaymentMethodsLabel"),
                                type: "checkbox",
                                default: false,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS), ...titleStylingOptions<WidgetOptions>()]],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "paymentMethodFallbackWebContentId",
                                label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "paymentMethodFallbackWebContentId"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                            },
                            {
                                variable: "paymentMethodFallbackTemplateId",
                                label: getI18nLocaleObject(namespaceList.widgetPaymentMethods, "paymentMethodFallbackTemplateId"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const paymentMethodsWidget: PageWidgetSpec<WidgetOptions> = {
    id: "payment-methods-widget",
    type: "page",
    widgetGroup: WidgetGroup.BOOKINGS_MODULE,
    name: getI18nLocaleObject(namespaceList.admin, "paymentMethodsWidget"),
    description: getI18nLocaleObject(namespaceList.admin, "paymentMethodsWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localizedContent: [],
        styleIds: [],
        alwaysShowPaymentMethods: false,
        showPaymentMethodsLabel: false,
    }),
    async render(widget, context) {
        const styledClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds || []);
        return <PaymentMethod options={widget.options} context={context} className={styledClasses} />;
    },
};
