import { Action } from "./";
import { MxtsDataAccessTokenState } from "../reducers/mxtsDataAccessTokenReducer";

export enum MxtsDataAccesTokenActionType {
    update = "MxtsDataAccessTokenUpdate",
}

export interface MxtsDataAccesTokenAction extends Action {
    actionType: MxtsDataAccesTokenActionType;
    payload: MxtsDataAccessTokenState;
}
