import * as moment from "moment";

import { AmenitiesStateHandler, AmenitiesWidget } from "./Amenities";
import { Amenity, AmenityCategory, AmenityLink, AmenityRequestPayload, ApiCallOptions, Customer, MxtsApi, SendEmailRequest, Unit } from "@maxxton/cms-mxts-api";
import { Content, getContent } from "../../../utils/content.util";
import { DATE_FORMAT, MXTS } from "../../../utils/constants";
import { MyEnvReducerAction, updateMyEnvOwnerState } from "../../../redux/actions/myEnvAction";
import { Resort, getMxtsEnv } from "../../mxts";
import { Widget, WithId } from "@maxxton/cms-api";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { SendingStatus } from "../description/description.utils";

export type Base64Content = string | ArrayBuffer | null | undefined;
export interface AmenityLinkCategory extends Omit<AmenityCategory, "amenities"> {
    amenities?: AmenityLink[];
}

export interface AmenityCategoryOptions {
    label?: string;
    options?: SelectedAmenityType[];
}
export interface SelectedAmenityType {
    label?: string;
    value?: Amenity;
}

interface AmenityDraftValueForMail {
    amenityName?: string;
    changeStatus?: string;
    period?: string;
    amenityPeriod?: string;
    updatedAmenityPeriod?: string;
    email?: string | null | undefined;
    resortName?: string;
    useForOwnerPortal?: boolean;
    ownerEmail?: string | null | undefined;
    ownerName?: string;
    unitId?: number;
    resourceName?: string;
    attachment?: Base64Content;
    message?: string;
}

export const handleAmenitySelect = (props: {
    amenityLinkId: number;
    selectedAmenitiesLinkId: number[];
    setDisableUnlinkAmenityButton: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setSelectedAmenitiesLinkId: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
    const { amenityLinkId, selectedAmenitiesLinkId, setDisableUnlinkAmenityButton, setSelectedAmenitiesLinkId } = props;
    let amenityLinkIds = [...selectedAmenitiesLinkId];
    if (amenityLinkIds.includes(amenityLinkId)) {
        amenityLinkIds = amenityLinkIds.filter((linkId) => linkId !== amenityLinkId);
    } else {
        amenityLinkIds.push(amenityLinkId);
    }
    setDisableUnlinkAmenityButton(!amenityLinkIds.length);
    setSelectedAmenitiesLinkId(amenityLinkIds);
};

export function getAmenityName(amenity: AmenityLink): string {
    if (amenity.type === "NUMBER" && amenity.numberValue != null) {
        return amenity.name;
    }
    return amenity.name && amenity.name.charAt(0).toUpperCase() + amenity.name.substr(1).toLowerCase();
}

export const handleDateChange = (props: {
    date: moment.Moment | null;
    amenityLinkId: number;
    dateType: string;
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    context: CMSProviderProperties;
    setIsAmenityDatesUpdating: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setIsAmenityDateUpdated: React.Dispatch<React.SetStateAction<"Failed" | "Success" | undefined>>;
    setUpdatedStartDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
    setUpdatedEndDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
    setSelectedAmenityForDateUpdate: React.Dispatch<React.SetStateAction<number | undefined>>;
    amenitiesStates: AmenitiesStateHandler;
    dispatchAction: React.Dispatch<MyEnvReducerAction | FilterChangeAction>;
    myEnvState: MyEnvState;
}) => {
    const {
        childOptions,
        amenityLinkId,
        date,
        dateType,
        context,
        setIsAmenityDatesUpdating,
        setIsAmenityDateUpdated,
        setSelectedAmenityForDateUpdate,
        setUpdatedEndDate,
        setUpdatedStartDate,
        amenitiesStates,
        dispatchAction,
        myEnvState,
    } = props;
    const selectedStartDate = date;
    setIsAmenityDatesUpdating(undefined);
    setIsAmenityDateUpdated(undefined);
    if (selectedStartDate && !childOptions?.askForApprovalByEmail) {
        updateAmenityLink({ amenityLinkId, selectedDate: selectedStartDate, dateType, context, setIsAmenityDatesUpdating, setIsAmenityDateUpdated, amenitiesStates });
    } else {
        setSelectedAmenityForDateUpdate(amenityLinkId);
        if (dateType === "start") {
            setUpdatedStartDate(selectedStartDate);
            dispatchAction(
                updateMyEnvOwnerState({
                    ...myEnvState.ownerState,
                    amenityUpdatedStartDate: selectedStartDate,
                })
            );
        } else {
            setUpdatedEndDate(selectedStartDate);
            dispatchAction(
                updateMyEnvOwnerState({
                    ...myEnvState.ownerState,
                    amenityUpdatedEndDate: selectedStartDate,
                })
            );
        }
    }
};

const updateAmenityLink = async (props: {
    amenityLinkId: number;
    selectedDate: moment.Moment;
    dateType: string;
    context: CMSProviderProperties;
    setIsAmenityDatesUpdating: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setIsAmenityDateUpdated: React.Dispatch<React.SetStateAction<"Failed" | "Success" | undefined>>;
    amenitiesStates: AmenitiesStateHandler;
}) => {
    const { amenityLinkId, selectedDate, dateType, context, setIsAmenityDateUpdated, setIsAmenityDatesUpdating, amenitiesStates } = props;
    setIsAmenityDatesUpdating(true);
    const env = await getMxtsEnv(context, context.currentLocale.code);
    MxtsApi.clearCacheGetAmenityLink((cacheKey: string) => cacheKey.includes("amenityLinkId") && cacheKey.includes(amenityLinkId.toString()));
    const amenityLink = await MxtsApi.getAmenityLink(env, {}, [{ key: "amenityLinkId", value: amenityLinkId }]);
    const amenityLinkRequest = { ...amenityLink };
    if (dateType === "start") {
        amenityLinkRequest.startDate = selectedDate.toDate();
    } else {
        amenityLinkRequest.endDate = selectedDate.toDate();
    }
    MxtsApi.updateAmenityLink(env, amenityLinkRequest, [{ key: "amenityLinkId", value: amenityLinkId }])
        .then(() => {
            setIsAmenityDateUpdated("Success");
            AmenitiesWidget.fetchAmenities(amenitiesStates, true).then(() => setIsAmenityDatesUpdating(false));
        })
        .catch(() => {
            setIsAmenityDateUpdated("Failed");
            setIsAmenityDatesUpdating(false);
        });
};

export const unlinkAmenities = async (props: {
    selectedAmenitiesLinkId: number[];
    context: CMSProviderProperties;
    setIsAmenityUnlinking: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setIsAmenitiesUnlinked: React.Dispatch<React.SetStateAction<"Failed" | "Success" | undefined>>;
    setSelectedAmenitiesLinkId: React.Dispatch<React.SetStateAction<number[]>>;
    setDisableUnlinkAmenityButton: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    amenitiesStates: AmenitiesStateHandler;
}) => {
    const { context, selectedAmenitiesLinkId, setDisableUnlinkAmenityButton, setIsAmenitiesUnlinked, setIsAmenityUnlinking, setSelectedAmenitiesLinkId, amenitiesStates } = props;
    if (selectedAmenitiesLinkId.length) {
        setIsAmenityUnlinking(true);
        const env = await getMxtsEnv(context, context.currentLocale.code);
        MxtsApi.unlinkAmenities(env, { amenityLinkIds: selectedAmenitiesLinkId })
            .then(() => {
                setIsAmenitiesUnlinked("Success");
                setIsAmenityUnlinking(false);
                setSelectedAmenitiesLinkId([]);
                setDisableUnlinkAmenityButton(true);
                AmenitiesWidget.fetchAmenities(amenitiesStates, true);
            })
            .catch(() => {
                setIsAmenitiesUnlinked("Failed");
                setIsAmenityUnlinking(false);
            });
    }
};

export const loadSelectableAmenities = async (props: { context: CMSProviderProperties; amenityCategories: AmenityLinkCategory[]; page: number; searchString?: string }) => {
    const { context, amenityCategories, page, searchString } = props;
    const amenityIds = amenityCategories.map((amenityCategory: AmenityLinkCategory) => amenityCategory.amenities!.map((amenity) => amenity.amenityId)).flat();
    const env = await getMxtsEnv(context, context.currentLocale.code);
    const linkedAmenities = await context.mxtsApi.amenityLinks(env, {
        size: MXTS.MAX_RESULTS,
        sort: "priority",
        view: "detail",
        page,
    });
    let amenityCategoryIds: number[] = [];
    const ids: number[] = [].concat.apply(
        [],
        linkedAmenities.content.map((amenityLink: AmenityLink) => amenityLink.amenity.amenityCategoryIds)
    );
    amenityCategoryIds = Array.from(new Set(ids));
    let allAmenityCategories: AmenityCategory[] = [];
    if (linkedAmenities) {
        allAmenityCategories = await DomainObjectUtil.getAmenityCategoriesByIds(
            context.mxtsApi,
            amenityCategoryIds.filter((id: number) => id !== null),
            {
                size: MXTS.MAX_RESULTS,
                isInternalUse: false,
                sort: "priority",
            },
            { sort: ["priority", "sequence", "name"] },
            env
        );
    }
    const amenityCategoriesClone: AmenityLinkCategory[] = JSON.parse(JSON.stringify(allAmenityCategories));
    const alreadyShownAmenityCodes: string[] = [];
    amenityCategoriesClone.forEach((category) => {
        category.amenities = [];
        linkedAmenities.content.forEach((link) => {
            if (
                link.amenity.amenityCategoryIds.indexOf(category.amenityCategoryId) > -1 &&
                category.amenities !== undefined &&
                !(alreadyShownAmenityCodes.indexOf(link.amenity.identifier) > -1) &&
                (link.endDate === null || link.endDate.toString() > moment().format("YYYY-MM-DD"))
            ) {
                alreadyShownAmenityCodes.push(link.amenity.identifier);
                category.amenities.push(link);
            }
        });
    });
    // amenityCategoriesClone = amenityCategoriesClone.filter((amenityCategoryClone) => amenityCategoryClone.amenities?.length);
    return amenityCategoriesClone.filter((category: AmenityLinkCategory) => category.amenities?.length);
};

export const isOutsideRange = (date: moment.Moment, startDate?: moment.Moment | null) => {
    const currentDate = moment();
    if (startDate) {
        return date <= startDate;
    }
    return date <= currentDate;
};

export const linkAmenities = async (props: {
    selectedAmenity: Amenity | undefined;
    amenityLinkStartDate: moment.Moment | null | undefined;
    amenityLinkEndDate: moment.Moment | null | undefined;
    context: CMSProviderProperties;
    setSelectedAmenity: React.Dispatch<React.SetStateAction<Amenity | undefined>>;
    setIsAmenityLinked: React.Dispatch<React.SetStateAction<"Failed" | "Success" | undefined>>;
    setIsAmenityLinking: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    amenitiesStates: AmenitiesStateHandler;
}) => {
    const { context, amenityLinkEndDate, amenityLinkStartDate, selectedAmenity, setSelectedAmenity, setIsAmenityLinked, setIsAmenityLinking, amenitiesStates } = props;
    if (selectedAmenity) {
        setIsAmenityLinking(true);
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const content = (await getContent({ ...amenitiesStates.props, env, skipContentTypeSelectorCheck: true })) as Exclude<Content, Resort[]>;
        const amenityManagerId = (content as Exclude<Content, Activity | Resort[]>)?.amenityManagerId;
        const amenity: AmenityRequestPayload = {
            activate: true,
            amenityCategories: [],
            amenityCategoryIds: selectedAmenity.amenityCategoryIds,
            amenityId: selectedAmenity.amenityId,
            amenityLinkId: null,
            amenityStandardCode: null,
            archivedFrom: null,
            children: [],
            childsDiffer: null,
            concernId: null,
            datesDiffer: 0,
            description: null,
            endDate: amenityLinkEndDate?.toISOString() || null,
            eventManagerId: selectedAmenity.eventManagerId!,
            groupId: null,
            groupName: null,
            i18n: selectedAmenity.i18n!,
            iconLibraryId: null,
            iconName: null,
            identifier: selectedAmenity.identifier,
            imageManagerId: selectedAmenity.imageManagerId!,
            linkedAmenityId: null,
            listMulti: null,
            managerId: amenityManagerId,
            metric: null,
            metricDetail: null,
            metricId: null,
            name: selectedAmenity.name,
            numberMaximum: null,
            numberMinimum: null,
            numberValue: null,
            origin: null,
            originFrom: null,
            originId: null,
            parentAmenityId: null,
            percentageDiscountPriority: null,
            preferenceExtraId: null,
            priority: 500,
            source: null,
            standardCodeAmenityId: null,
            startDate: amenityLinkStartDate?.toISOString() || null,
            textValue: null,
            type: selectedAmenity.type,
            visible: true,
            amenityOption: "ESSENTIAL",
            forSearching: false,
            quickSearch: false,
            showInSearch: false,
        };
        MxtsApi.linkAmenities(env, { amenity })
            .then(() => {
                setIsAmenityLinked("Success");
                AmenitiesWidget.fetchAmenities(amenitiesStates, true);
            })
            .catch(() => {
                setIsAmenityLinked("Failed");
            });
        setIsAmenityLinking(false);
    }
    setSelectedAmenity(undefined);
};

export const sendEmailToResort = async (props: {
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    context: CMSProviderProperties;
    setIsEmailSent: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setEmailSendStatus: React.Dispatch<React.SetStateAction<SendingStatus | undefined>>;
    myEnvState: MyEnvState;
    selectedAmenity: Amenity | undefined;
    selectedAmenitiesLinkId: number[];
    amenityLinkEndDate: moment.Moment | null | undefined;
    amenityLinkStartDate: moment.Moment | null | undefined;
    selectedAmenityForDateUpdate: number | undefined;
    getReCaptchaToken: () => Promise<string>;
    base64Image?: Base64Content;
    amenityMessage?: string;
}) => {
    const {
        context,
        myEnvState: { mainCustomer, ownerState },
        childOptions,
        setEmailSendStatus,
        setIsEmailSent,
        amenityLinkEndDate,
        amenityLinkStartDate,
        selectedAmenitiesLinkId,
        selectedAmenity,
        selectedAmenityForDateUpdate,
        getReCaptchaToken,
        base64Image,
        amenityMessage,
    } = props;
    let draftValueForMail: AmenityDraftValueForMail = {};
    const env = await getMxtsEnv(context, context.currentLocale.code);
    const reCaptchaToken = (await getReCaptchaToken()) || "";
    if (env && childOptions?.unitEditableFormWidgetList.length) {
        const formWidget = await context.cmsApi.widgetApi.findById({ id: childOptions.unitEditableFormWidgetList[0].value });
        const unit = await MxtsApi.unitWithId(env, {}, [{ key: "unitId", value: ownerState?.selectedUnitId }]);
        const ownerDetails = await addOwnerDetails({ context, env, formWidget, mainCustomer, unit });
        draftValueForMail = { ...draftValueForMail, ...ownerDetails };
        if (base64Image && childOptions?.uploadImage) {
            draftValueForMail.attachment = base64Image;
        }
        if (amenityMessage) {
            draftValueForMail.message = amenityMessage;
        }
        if (selectedAmenity) {
            draftValueForMail.changeStatus = "add";
            draftValueForMail.amenityName = selectedAmenity.name;
            draftValueForMail.period = `${amenityLinkStartDate?.format(DATE_FORMAT.MXTS) || "not available"} to ${amenityLinkEndDate?.format(DATE_FORMAT.MXTS) || "not available"}`;
        } else if (selectedAmenitiesLinkId.length) {
            draftValueForMail.changeStatus = "delete";
            const amenityLink = await MxtsApi.getAmenityLink(env, {}, [{ key: "amenityLinkId", value: selectedAmenitiesLinkId[0] }]);
            draftValueForMail.amenityName = amenityLink.name;
            draftValueForMail.period = `${amenityLink.startDate || "not available"} to ${amenityLink.endDate || "not available"}`;
        } else {
            draftValueForMail.changeStatus = "update";
            const amenityLink = await MxtsApi.getAmenityLink(env, {}, [{ key: "amenityLinkId", value: selectedAmenityForDateUpdate }]);
            draftValueForMail.amenityPeriod = `${amenityLink.startDate || "not available"} to ${amenityLink.endDate || "not available"}`;
            draftValueForMail.amenityName = amenityLink.name;
            draftValueForMail.updatedAmenityPeriod = `${ownerState?.amenityUpdatedStartDate?.format(DATE_FORMAT.MXTS) || "not available"} to ${
                ownerState?.amenityUpdatedEndDate?.format(DATE_FORMAT.MXTS) || "not available"
            }`;
        }
        const sendEmailRequest: SendEmailRequest = {
            reCaptchaToken,
            siteHost: location.host,
            formOptionId: formWidget?.options._id || "",
            localeId: context?.currentLocale.locale || "",
            submittedFormData: draftValueForMail,
            dynamicData: undefined,
        };
        await MxtsApi.sendEmail(env, sendEmailRequest)
            .then((response: any) => {
                if (response?.success) {
                    setEmailSendStatus(SendingStatus.Success);
                    setIsEmailSent(false);
                }
            })
            .catch(() => {
                setEmailSendStatus(SendingStatus.Failed);
                setIsEmailSent(false);
            });
    }
};

export const addOwnerDetails = async (props: { formWidget: (Widget & WithId) | null; unit: Unit; mainCustomer: Customer | null | undefined; context: CMSProviderProperties; env: ApiCallOptions }) => {
    const draftValueForMail: AmenityDraftValueForMail = {};
    const { context, env, formWidget, mainCustomer, unit } = props;
    if (formWidget?.options?.useResortEmail && unit?.resortId) {
        const resortId = unit?.resortId;
        const resortAddress = await context?.mxtsApi?.resortsWithAddress(env, { resortIds: resortId ? [resortId] : [] });
        const resortEmail = resortAddress?.addresses[0].email;
        draftValueForMail.email = resortEmail;
        draftValueForMail.resortName = resortAddress.data.content[0].name;
    }
    draftValueForMail.useForOwnerPortal = formWidget?.options?.useForOwnerPortal;
    draftValueForMail.ownerEmail = mainCustomer?.addresses?.[0]?.email;
    draftValueForMail.ownerName = `${mainCustomer?.firstName} ${mainCustomer?.lastName}`;
    draftValueForMail.unitId = unit?.unitId;
    draftValueForMail.resourceName = unit?.name;
    return draftValueForMail;
};

export const executeRecaptchaCheck = (executeRecaptcha: ((action?: string | undefined) => Promise<string>) | undefined) => {
    if (!executeRecaptcha) {
        console.error("Execute recaptcha not yet available");
        return "";
    }
    return executeRecaptcha("submit").catch((err) => {
        console.error("Failed to verify recaptcha", err);
        return "";
    });
};
