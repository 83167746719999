import { ContextSlice, PluginSpec } from "../";

import { FlowWidgetTypes } from "./flow.util";
import { WidgetGroup } from "../widget.enum";
import { getI18nLocaleObject } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";
import withDynamicImport from "../WithDynamicImportHoc";

const FlowPlugin: PluginSpec<Record<string, unknown>> = {
    id: "plugin-flow",
    name: getI18nLocaleObject(namespaceList.pluginFlow, "flow"),
    description: getI18nLocaleObject(namespaceList.pluginFlow, "flowDescription"),
    widgets: [
        {
            id: FlowWidgetTypes.ACCODETAIL,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-accodetail" */ "./accodetail"));
                context?.chunkExtractor?.addChunk("flow-accodetail");
                return await ref.load();
            },
            targetName: "accodetailWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
            container: true,
        },
        {
            id: FlowWidgetTypes.CONDITIONAL,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-conditional" */ "./conditional"));
                context?.chunkExtractor?.addChunk("flow-conditional");
                return await ref.load();
            },
            targetName: "conditionalWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: FlowWidgetTypes.ADDITION,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-addition" */ "./addition"));
                context?.chunkExtractor?.addChunk("flow-addition");
                return await ref.load();
            },
            targetName: "additionWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: FlowWidgetTypes.CONFIRMATION,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-confirmation" */ "./confirmation"));
                context?.chunkExtractor?.addChunk("flow-confirmation");
                return await ref.load();
            },
            targetName: "confirmationWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: FlowWidgetTypes.CUSTOMER,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-customer" */ "./customer"));
                context?.chunkExtractor?.addChunk("flow-customer");
                return await ref.load();
            },
            targetName: "customerWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: FlowWidgetTypes.PAYMENT,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-payment" */ "./payment"));
                context?.chunkExtractor?.addChunk("flow-payment");
                return await ref.load();
            },
            targetName: "paymentWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: FlowWidgetTypes.PRICECOMPARE,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-pricecompare" */ "./pricecompare"));
                context?.chunkExtractor?.addChunk("flow-pricecompare");
                return await ref.load();
            },
            targetName: "pricecompareWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
        },
        {
            id: FlowWidgetTypes.RESULT,
            pathToFile: async (context: ContextSlice) => {
                const ref = withDynamicImport(() => import(/* webpackChunkName: "flow-result" */ "./result"));
                context?.chunkExtractor?.addChunk("flow-result");
                return await ref.load();
            },
            targetName: "resultWidget",
            type: "flow",
            widgetGroup: WidgetGroup.OTHER,
        },
    ],
};

export default FlowPlugin;
