import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "bonaire",
    name: "Bonaire Theme",
    stylesheets: ["/styles/themes/bonaire/bonaire-admin.css"],
    frontendStylesheets: ["/styles/themes/bonaire/bonaire.css"],
    styles: [...baseTheme.styles],
};

export default theme;
