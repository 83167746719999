import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as moment from "moment";

import { Button, Input, NavLink, Table } from "reactstrap";
import { ColumnSpec, FormSpec, JsxColumnSpec, RefColumnSpec, categoryList, isJsxColumnSpec, isRefColumnSpec, tagList } from "../../form-specs";
import { DeleteOutline, Home, KeyboardArrowDown, SentimentVeryDissatisfied } from "@mui/icons-material";
import { getI18nLocaleString, getI18nLocaleStringFromParams, wrapProps } from "../../i18n";

import { DATE_FORMAT } from "../../utils/constants";
import { PermissionType } from "@maxxton/cms-mxts-api";
import ProgressBar from "../../components/ProgressBar";
import { SelectOption } from "../../form-specs/formSpec.types";
import { cancelable } from "../../promise/cancelable";
import { escapeRegExp } from "lodash";
import { getProjection } from "../admin/utils";
import loadable from "@loadable/component";
import { loadableRetry } from "../../utils/loadableComponents.util";
import namespaceList from "../../i18n/namespaceList";

const GenericInput = loadable(() => loadableRetry(() => import("./input")), {
    resolveComponent: ({ GenericInput }) => GenericInput,
});

export interface GenericTableProps<E> {
    spec: FormSpec<E>;
    onClick?: (val: E) => void;
    items: E[];
    totalItems: number | null;
    initialItemCount: number;
    offset: number;
    selected: Set<E>;
    enableSelect?: boolean;
    onSelect: (newSelected: Set<E>) => void;
    onRecycleBinClick: () => void;
    onAllValidClick: () => void;
    loadMoreItems: () => void;
    isScroll: boolean;
    handleSort: (columnVariable: string, sortOrder: 1 | -1) => void;
    permission: PermissionType;
}

interface ShowColumns {
    [key: string]: boolean[] | undefined;
}

interface RefDisplayStore {
    [variable: string]: {
        [itemId: string]: string | Promise<void>;
    };
}

interface JsxElementDisplayStore {
    colUniqueId: string;
    items: Array<{ itemId: string; jsxElement: JSX.Element }>;
}

interface GenericTableState<E> {
    refDisplaysByVar: RefDisplayStore;
    jsxElementStore: JsxElementDisplayStore[];
    isDescending: boolean;
    sortingColumn: string;
    searchString: string;
    searchItems: E[];
    selectedCount: number;
    isChecked: boolean;
    infiniteLoading: boolean;
    isSearch: boolean;
    active: string;
    noneFound: boolean;
    tab: string;
    value: string;
    tags?: Array<SelectOption<string>>;
    categories?: Array<SelectOption<string>>;
    showColumns?: ShowColumns;
    showColumnInputs?: boolean;
}

interface ItemProps<E> {
    item: E;
    refDisplayStore: RefDisplayStore;
    jsxElementStore: JsxElementDisplayStore[];
    selected: boolean;
    cols: Array<ColumnSpec<E, keyof E>>;
    onClick: ((val: E) => void) | undefined;
    onSelectChange: (item: E, isSelected: boolean) => void;
    login?: (item: E, e: React.MouseEvent<HTMLInputElement>) => void;
    visible: boolean[];
}

// eslint-disable-next-line max-len
function getJsxElementFromDisplayStore<E extends { _id: string }, P extends keyof E>(col: JsxColumnSpec<E, P>, item: E, jsxElementStore: JsxElementDisplayStore[]) {
    const storedCol = jsxElementStore.find((displayStore: JsxElementDisplayStore) => displayStore.colUniqueId === col.uniqueId);
    if (storedCol && storedCol?.items?.length) {
        const storedItem = storedCol.items.find((jsxStoreItem) => jsxStoreItem.itemId === item._id);
        if (storedItem && storedItem.jsxElement) {
            return storedItem.jsxElement;
        }
    } else {
        return (col.jsxLoadingElement && col.jsxLoadingElement(item)) || <span />;
    }
}

function renderCell<E extends { _id: string }, P extends keyof E>(
    // eslint-disable-next-line max-len
    item: E,
    refDisplaysByVar: RefDisplayStore,
    jsxElementStore: JsxElementDisplayStore[],
    col: ColumnSpec<E, P>,
    colInd: number
): React.ReactNode {
    const value = item[col.variable];
    const refCol: RefColumnSpec<E, P> = col as RefColumnSpec<E, P>;
    const refDisplaysByItem = (refDisplaysByVar as any)[refCol.variable] || {};
    const refDisplay = refDisplaysByItem[item._id];
    const refText = refDisplay !== undefined ? refDisplay : "...";
    switch (col.type) {
        case "date":
            return (
                <div className="date-wrap">
                    <span>{moment(value).format(DATE_FORMAT.LONG_DATE)}</span>
                    <span className="date-tooltip">{moment(value).format(DATE_FORMAT.DATE_TIME_HOURS_MINUTES)}</span>
                </div>
            );
        case "text":
            return (value as any) as React.ReactNode;
        case "jsx":
            return (getJsxElementFromDisplayStore<E, P>(col as JsxColumnSpec<E, P>, item, jsxElementStore) as any) as React.ReactNode;
        case "checkbox":
            return (
                <div>
                    <Input className="toc-layout__checkbox" type="checkbox" readOnly checked={(value as any) as boolean} />
                    <label className="toc-layout__label" />
                </div>
            );
        case "anchor":
            return (value as any) as React.ReactNode;
        case "ref":
            return refText;
        default:
            throw new Error(`Unknown column type "${col.type}"`);
    }
}

// eslint-disable-next-line max-len
function Item<E extends { _id: string }>({ item, refDisplayStore, jsxElementStore, selected, cols, onClick, onSelectChange, login, visible }: ItemProps<E>) {
    const handleClick = () => {
        if (onClick !== undefined) {
            onClick(item);
        }
    };
    const handleSelectClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
    };
    const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        onSelectChange(item, value);
    };
    return (
        <tr onClick={handleClick} className={`${selected ? "font-weight-bold" : ""}`}>
            <td>
                <Input type="checkbox" checked={selected} className="table-checkbox" onChange={handleSelectChange} onClick={handleSelectClick} />
                <label>{selected}</label>
            </td>
            {cols.map((col: ColumnSpec<E, keyof E>, colInd) => visible[colInd] && <td key={colInd}>{renderCell(item, refDisplayStore, jsxElementStore, col, colInd)}</td>)}
            {login && (
                <td>
                    <button type="button" className="btn-admin-login btn btn-primary" onClick={login.bind(login, item)}>
                        {getI18nLocaleString(namespaceList.genericCrud, "login")}
                    </button>
                </td>
            )}
        </tr>
    );
}

export class GenericTableBase<E extends { _id: string }> extends React.PureComponent<GenericTableProps<E>, GenericTableState<E>> {
    private iScroll: HTMLDivElement | null;

    private timer: any = null;

    constructor(props: GenericTableProps<E>) {
        super(props);
        this.state = {
            refDisplaysByVar: {},
            jsxElementStore: [],
            isDescending: false,
            sortingColumn: "",
            searchString: "",
            searchItems: [],
            selectedCount: 0,
            isChecked: false,
            infiniteLoading: props.isScroll,
            isSearch: false,
            active: "all",
            noneFound: false,
            tab: "all",
            value: "",
            showColumns: {},
            showColumnInputs: false,
        };
    }

    public componentDidUpdate(prevProps: GenericTableProps<E>, prevState: GenericTableState<E>) {
        const { selected, spec } = this.props;
        const { value, showColumns } = this.state;
        if (selected.size !== 0) {
            const sortedItems = this.state.searchString || value ? this.state.searchItems : this.props.items;
            let found = false;
            sortedItems.forEach((item) => {
                if (selected.has(item)) {
                    found = true;
                }
            });
            if (!found) {
                this.setState({ selectedCount: 0 });
            }
        }
        if (spec.id !== "webcontent" && spec.id !== "page") {
            this.updateRefDisplays();
        }
        if (prevState.showColumns !== showColumns) {
            localStorage.setItem(`visibleColumns_${spec.id}`, JSON.stringify(showColumns![spec.id]));
        }
        if (prevProps.spec.id !== spec.id) {
            this.loadVisibleColumns();
        }
    }

    public componentDidMount() {
        const { spec } = this.props;
        this.loadVisibleColumns();
        if (spec.id === "webcontent") {
            this.setTags();
        }
        if (spec.id === "page") {
            this.setCategories();
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: GenericTableProps<E>) {
        const specId = nextProps.spec.id;
        if (this.props.spec.id !== specId) {
            this.setState({ searchString: "", isSearch: false, tab: "all", searchItems: [], selectedCount: 0, infiniteLoading: true }, () => {
                this.setTableLayout();
            });
            this.setState({ value: "" });
        } else {
            this.setState({ selectedCount: nextProps.selected.size });
        }
        if (nextProps.isScroll) {
            const { infiniteLoading } = this.state;
            if (infiniteLoading || this.props.spec.id !== specId) {
                this.iScroll!.addEventListener("scroll", this.handleScrollEvent);
            }
        } else {
            this.iScroll!.removeEventListener("scroll", this.handleScrollEvent);
        }

        const { spec } = nextProps;
        if (spec.id === "webcontent") {
            this.setTags();
        }
        if (spec.id === "page") {
            this.setCategories();
        }
        this.updateJsxDisplays(nextProps);
    }

    public componentWillUnmount() {
        this.cancelTagsObtain();
        this.cancelCategoriesObtain();
    }

    private handleShowHideColumn = (index: number) => {
        const { spec } = this.props;
        this.setState((prevState) => {
            const showColumns = {
                ...prevState.showColumns,
                [spec.id]: [...(prevState.showColumns![spec.id] ?? [])],
            };
            showColumns[spec.id]![index] = !showColumns[spec.id]![index];
            return { showColumns };
        });
    };

    private loadVisibleColumns() {
        const { spec } = this.props;
        const showColumns = localStorage.getItem(`visibleColumns_${spec.id}`);
        const defaultColumns = spec.tableColumns?.map(() => true) ?? [];
        this.setState({ showColumns: { [spec.id]: showColumns ? JSON.parse(showColumns) : defaultColumns } });
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { selected, enableSelect, spec, permission, onClick, items, totalItems, offset } = this.props;
        const { refDisplaysByVar, jsxElementStore, infiniteLoading, isSearch, searchString, noneFound, searchItems, value, tags, categories, showColumnInputs, showColumns } = this.state;
        const sortedItems = this.state.searchString || value ? this.state.searchItems : items;
        const cols: Array<ColumnSpec<E, keyof E>> = spec.tableColumns!;
        const selectSpec = {
            type: "autocomplete" as const,
            variable: "tag",
            options: this.getTags,
            placeholder: getI18nLocaleString(namespaceList.admin, "selectTagPlaceholder"),
            isClearable: !!value,
        };

        const categorySpec = {
            type: "autocomplete" as const,
            variable: "category",
            options: this.getCategories,
            placeholder: getI18nLocaleString(namespaceList.admin, "selectCategoryPlaceholder"),
            isClearable: !!value,
        };

        const visibleColumns = cols?.filter((col, index) => showColumns![spec.id]?.[index]);

        if (items === null) {
            return <ProgressBar />;
        }
        const isAllSelected = sortedItems.length === selected.size;
        const filterRegExp = new RegExp("\\$filteredCount");
        const totalRegExp = new RegExp("\\$totalCount");
        const selectRegExp = new RegExp("\\$selectedCount");
        return (
            <div id="tableWrapper" className="table-wrapper content-box">
                <div className="box-heading toc-header">
                    <div className="toc-inner-wrapper">
                        <div className="toc-label">
                            <label>{getI18nLocaleString(namespaceList.genericCrud, "labelContentOf")}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className={`col-sm-12 ${spec.id === "webcontent" ? " col-md-5" : " col-md-6"} content-search mb-2 mt-2`}>
                            <Input type="text" className="search" placeholder={getI18nLocaleString(namespaceList.genericCrud, "search")} onChange={this.handleOnSearch} value={searchString} />
                        </div>
                        {spec.id === "webcontent" && tags && (
                            <div className="tag-input">
                                <GenericInput permission={permission} mode="admin_edit" root={[]} item={[]} onChange={this.onTagSelect} spec={selectSpec} value={value} />
                            </div>
                        )}
                        {spec.id === "page" && categories && (
                            <div className="tag-input">
                                <GenericInput permission={permission} mode="admin_edit" root={[]} item={[]} onChange={this.onCategorySelect} spec={categorySpec} value={value} />
                            </div>
                        )}
                        <Button className="show-hide-btn" color="link" onClick={() => this.setState({ showColumnInputs: !showColumnInputs })}>
                            {getI18nLocaleString(namespaceList.admin, "showHideColumn")} <KeyboardArrowDown />
                        </Button>
                    </div>

                    <div className="row admin-crud-lists">
                        <NavLink className={`hover-link ${this.state.active === "all" ? "active" : ""}`} onClick={this.getAllValid}>
                            <Home />
                            {getI18nLocaleString(namespaceList.genericCrud, "allValid")}
                        </NavLink>
                        <NavLink className={`hover-link ${this.state.active === "recycle" ? "active" : ""}`} onClick={this.getRecycleBin}>
                            <DeleteOutline />
                            {getI18nLocaleString(namespaceList.genericCrud, "recycleBin")}
                        </NavLink>
                        <div className="show-hide-columns-wrapper">
                            {showColumnInputs &&
                                cols?.map((col: ColumnSpec<E, keyof E>, index) => (
                                    <div key={index} className="show-hide-columns">
                                        <Input
                                            type="checkbox"
                                            className="table-checkbox"
                                            onChange={() => this.handleShowHideColumn(index)}
                                            checked={showColumns![spec.id]?.[index]}
                                            disabled={index === 0}
                                        />
                                        <label>{getI18nLocaleStringFromParams(col.name)}</label>
                                        <span className="checkmark"></span>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="content-count">
                        <label>
                            {getI18nLocaleString(namespaceList.genericCrud, "showingCountValue")
                                .replace(filterRegExp, (isSearch || value ? searchItems.length : items.length).toString())
                                .replace(totalRegExp, (isSearch || value ? searchItems.length : totalItems!).toString())}
                        </label>
                        {this.state.selectedCount > 0 && <label>{getI18nLocaleString(namespaceList.genericCrud, "selectedCount").replace(selectRegExp, this.state.selectedCount.toString())}</label>}
                    </div>
                </div>
                <div
                    className="results-table-wrap"
                    ref={(input) => {
                        this.iScroll = input;
                    }}
                >
                    <Table className={"toc-layout"} hover>
                        {sortedItems.length > 0 && (
                            <thead className={`${sortedItems.length ? "position-fixed" : "position-relative"}`}>
                                <tr>
                                    <th>
                                        {enableSelect !== false && (
                                            <Input
                                                type="checkbox"
                                                checked={isAllSelected && this.state.isChecked}
                                                onChange={this.handleSelectAllChange}
                                                title={getI18nLocaleString(namespaceList.admin, "selectAll")}
                                            />
                                        )}
                                    </th>
                                    {visibleColumns.map((col: ColumnSpec<E, keyof E>, ind) => (
                                        <th key={ind} onClick={this.handleSortClick.bind(this, col.variable)}>
                                            <div className="header-title">
                                                {this.state.isDescending && this.state.sortingColumn === col.variable && (
                                                    <div className="sort-icon-wrap">
                                                        <FontAwesome name="caret-up" className="caret-up disabled" />
                                                        <FontAwesome name="caret-down" className="caret-down " />
                                                    </div>
                                                )}
                                                {!this.state.isDescending && this.state.sortingColumn === col.variable && (
                                                    <div className="sort-icon-wrap">
                                                        <FontAwesome name="caret-up" className="caret-up " />
                                                        <FontAwesome name="caret-down" className="caret-down disabled " />
                                                    </div>
                                                )}
                                                {getI18nLocaleStringFromParams(col.name)}
                                            </div>
                                        </th>
                                    ))}
                                    {spec.login && <th key={"login"}>{getI18nLocaleString(namespaceList.genericCrud, "login")}</th>}
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {sortedItems.length > 0 &&
                                sortedItems.map((item, index) => (
                                    <Item
                                        key={item._id}
                                        cols={cols}
                                        item={item}
                                        refDisplayStore={refDisplaysByVar}
                                        jsxElementStore={jsxElementStore}
                                        selected={selected.has(item)}
                                        onClick={onClick}
                                        onSelectChange={this.handleSelectChange}
                                        login={spec.login}
                                        visible={showColumns![spec.id] || []}
                                    />
                                ))}
                        </tbody>
                    </Table>
                    {infiniteLoading && totalItems && totalItems > offset && items.length > 0 && !noneFound ? <ProgressBar /> : ""}
                    {!sortedItems.length && (
                        <div className="no-data-wrap">
                            <SentimentVeryDissatisfied />
                            <div className="no-data-text">
                                <span>{getI18nLocaleString(namespaceList.admin, "nothingFoundData")}</span>
                                <span>{getI18nLocaleString(namespaceList.admin, "notFound")}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private getTags = async () => this.state.tags;

    private getCategories = async () => this.state.categories;
    private setTags = async () => {
        const [tagsProm, cancelTags] = cancelable(tagList());
        this.cancelTagsObtain = cancelTags;
        const tags = await tagsProm;
        this.setState({
            tags: tags.map((t) => ({
                label: t.text,
                value: t.text,
            })),
        });
    };

    private setCategories = async () => {
        const [categoriesProm, cancelCategories] = cancelable(categoryList());
        this.cancelCategoriesObtain = cancelCategories;
        const categories = await categoriesProm;
        this.setState({
            categories: categories.map((category) => ({
                label: category.name,
                value: category.categoryId.toString(),
            })),
        });
    };

    private cancelTagsObtain: () => void = () => undefined;

    private cancelCategoriesObtain: () => void = () => undefined;
    private onTagSelect = (val: string) => {
        this.setState({ value: val }, async () => {
            if (this.state.value) {
                const { spec, isScroll } = this.props;
                const projection = getProjection(this.props.spec.id);
                const searchItems = isScroll
                    ? await spec.api!.find({
                          query: {
                              "tags.text": val,
                              "deletedDate": { $exists: false },
                          },
                          order: { name: 1 },
                          projection,
                      })
                    : await spec.api!.find({
                          query: {
                              "tags.text": val,
                              "deletedDate": { $exists: true },
                          },
                          order: { name: 1 },
                          projection,
                      });
                this.setState({ searchItems, noneFound: searchItems.length === 0 && !!val, infiniteLoading: false, isChecked: false, selectedCount: 0 }, () => {
                    let newSelected = this.props.selected;
                    if (newSelected.size !== 0) {
                        const { onSelect } = this.props;
                        newSelected = new Set();
                        onSelect(newSelected);
                    }
                });
            }
        });
    };

    private onCategorySelect = (val: string) => {
        this.setState({ value: val, searchItems: [] }, async () => {
            if (this.state.value) {
                const { spec, isScroll } = this.props;
                const projection = getProjection(this.props.spec.id);
                const searchItems = isScroll
                    ? await spec.api!.find({
                          query: {
                              category: { $in: val },
                              deletedDate: { $exists: false },
                          },
                          order: { name: 1 },
                          projection,
                      })
                    : await spec.api!.find({
                          query: {
                              category: { $in: val },
                              deletedDate: { $exists: true },
                          },
                          order: { name: 1 },
                          projection,
                      });

                this.setState({ searchItems, noneFound: searchItems.length === 0 && !!val, infiniteLoading: true, isChecked: false, selectedCount: 0 }, () => {
                    this.updateJsxDisplays(this.props);
                    if (this.props.selected.size !== 0) {
                        const { onSelect } = this.props;
                        onSelect(new Set());
                    }
                });
            }
        });
    };

    private handleScrollEvent = () => {
        if (this.iScroll && this.iScroll.scrollTop + 1 + this.iScroll.clientHeight >= this.iScroll.scrollHeight) {
            this.loadMoreItems();
        }
    };

    private loadMoreItems = async () => {
        const { totalItems, offset } = this.props;
        await this.props.loadMoreItems();
        if ((totalItems && !(totalItems > offset)) || this.state.searchItems.length) {
            this.setState({ infiniteLoading: false });
        }
    };

    private getAllValid = () => {
        this.setTableLayout();
        this.setState((state) => ({
            ...state,
            infiniteLoading: true,
            searchString: this.state.tab === "all" ? this.state.searchString : "",
            isSearch: this.state.tab === "all" && this.state.searchString ? true : false,
            tab: "all",
            active: "all",
        }));
        this.props.onAllValidClick();
    };

    private getRecycleBin = async () => {
        this.setState({
            infiniteLoading: false,
            searchString: this.state.tab === "recycle-bin" ? this.state.searchString : "",
            isSearch: this.state.tab === "recycle-bin" && this.state.searchString ? true : false,
            tab: "recycle-bin",
            active: "recycle",
        });
        this.setTableLayout();
        await this.props.onRecycleBinClick();
    };

    private setTableLayout() {
        const tableLayout = document.querySelector(".toc-layout");
        if (tableLayout) {
            tableLayout.scrollIntoView();
        }
    }

    private getSearchedItems = async (searchString: string, columnVariable?: string, order?: -1 | 1) => {
        const { spec, isScroll } = this.props;
        const projection = getProjection(this.props.spec.id);
        const name = spec.id === "locale" ? "code" : spec.id === "translation" ? "value" : "name";
        const searchItems = await spec.api!.find({
            query: {
                [name]: { $regex: `.*${escapeRegExp(searchString)}.*`, $options: "i" },
                deletedDate: { $exists: !isScroll },
            },
            order: columnVariable && order ? { [columnVariable]: order } : { name: 1 },
            projection,
        });
        this.setState({ searchItems, noneFound: searchItems.length === 0 && searchString !== "", isSearch: true, infiniteLoading: false, isChecked: false, selectedCount: 0 }, () => {
            let newSelected = this.props.selected;
            if (newSelected.size !== 0) {
                const { onSelect } = this.props;
                newSelected = new Set();
                onSelect(newSelected);
            }
        });
    };

    private handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        clearTimeout(this.timer);
        if (value) {
            this.timer = setTimeout(() => {
                const documentObject = window.document;
                const table = documentObject.getElementById("tableWrapper");
                table!.scrollTop = 0;
                this.getSearchedItems(value).then(() => this.updateJsxDisplays(this.props));
            }, 500);
            this.setState({ searchString: value });
        } else {
            this.setState({ searchString: value, isSearch: false, infiniteLoading: true });
        }
    };

    private handleSortClick = async (columnVariable: string) => {
        let sortOrder: 1 | -1 = 1;
        const { searchString, value } = this.state;
        if (this.state.sortingColumn === columnVariable) {
            sortOrder = this.state.isDescending ? 1 : -1;
            this.setState((state) => ({ sortingColumn: columnVariable, isDescending: !state.isDescending }));
        } else {
            sortOrder = 1;
            this.setState(() => ({ sortingColumn: columnVariable, isDescending: false }));
        }
        if (searchString || value) {
            this.getSearchedItems(searchString, columnVariable, sortOrder);
            this.setTableLayout();
            return;
        }
        this.setTableLayout();
        this.props.handleSort(columnVariable, sortOrder);
    };

    private handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.checked;
        const { onSelect } = this.props;
        const { value } = this.state;
        let selectedCount = 0;
        const sortedItems = this.state.searchString || value ? this.state.searchItems : this.props.items;
        let newSelected: Set<E> = new Set();
        sortedItems.forEach(() => {
            selectedCount++;
        });
        newSelected = searchValue ? new Set(sortedItems) : newSelected;
        this.setState((state) => ({
            ...state,
            selectedCount: searchValue ? selectedCount : 0,
            isChecked: searchValue ? true : state.isChecked,
        }));
        onSelect(newSelected);
    };

    private handleSelectChange = (newItem: E, isSelected: boolean): void => {
        const { isChecked, value } = this.state;
        const { onSelect, selected } = this.props;
        const newSelected = new Set(selected);
        let selectedCount = this.state.selectedCount;
        if (isSelected) {
            selectedCount++;
            newSelected.add(newItem);
        } else {
            selectedCount--;
            newSelected.delete(newItem);
        }
        const sortedItems = this.state.searchString || value ? this.state.searchItems : this.props.items;
        const isAllSelected = sortedItems.every((item) => newSelected.has(item));
        this.setState((state) => ({
            ...state,
            selectedCount,
            isChecked: isAllSelected && isChecked ? state.isChecked : !state.isChecked,
        }));
        onSelect(newSelected);
    };

    private updateRefDisplaysWithNewData(refCol: RefColumnSpec<E, keyof E>, data: any[]): void {
        const { items } = this.props;
        const { refDisplaysByVar } = this.state;
        const refDisplaysByItem = (refDisplaysByVar as any)[refCol.variable];
        let needsUpdate = false;
        items.forEach((item: E) => {
            const refDisplay = refDisplaysByItem[item._id];
            const refId = item[refCol.variable];
            if (refDisplay === undefined && typeof refId === "string" && refId.length > 0) {
                const ref = data.find((r) => r._id === refId);
                if (ref !== undefined) {
                    refDisplaysByItem[item._id] = refCol.refSpec.display!(ref);
                    needsUpdate = true;
                }
            }
        });
        if (needsUpdate) {
            this.forceUpdate();
        }
    }

    private updateRefDisplays() {
        const { refDisplaysByVar } = this.state;
        const { spec, items } = this.props;
        let needsUpdate = false;
        spec.tableColumns!.forEach((col: ColumnSpec<E, keyof E>) => {
            if (isRefColumnSpec(col)) {
                const refCol: RefColumnSpec<E, keyof E> = col;
                const refDisplaysByItem = ((refDisplaysByVar as any)[refCol.variable] = (refDisplaysByVar as any)[refCol.variable] || {});
                const loadIdsMap: { [id: string]: boolean } = {};
                items.forEach((item: E) => {
                    const refDisplay = refDisplaysByItem[item._id];
                    const refId = item[refCol.variable];
                    if (refDisplay === undefined) {
                        if (typeof refId === "string" && refId.length > 0) {
                            loadIdsMap[refId] = true;
                        } else {
                            refDisplaysByItem[item._id] = "";
                            needsUpdate = true;
                        }
                    }
                });
                const loadIds: string[] = Object.keys(loadIdsMap);
                if (loadIds.length > 0) {
                    refCol.refSpec.api!.findMany({ ids: loadIds }).then((refItems) => {
                        this.updateRefDisplaysWithNewData(refCol, refItems);
                    });
                }
            }
        });
        if (needsUpdate) {
            this.forceUpdate();
        }
    }

    private updateJsxDisplays(nextProps: GenericTableProps<E>) {
        const { jsxElementStore, searchItems } = this.state;
        const { spec, items } = nextProps;
        const newJsxElementStore: JsxElementDisplayStore[] = [];

        spec.tableColumns!.forEach((col: ColumnSpec<E, keyof E>) => {
            if (isJsxColumnSpec(col)) {
                const jsxCol: JsxColumnSpec<E, keyof E> = col;
                const jsxElementStoreForCol = jsxElementStore.find((jsxColStore) => jsxColStore.colUniqueId === col.uniqueId);
                let storedJsxCol: JsxElementDisplayStore | undefined = jsxElementStoreForCol ? { ...jsxElementStoreForCol } : undefined;
                if (!storedJsxCol) {
                    storedJsxCol = { colUniqueId: jsxCol.uniqueId, items: [] };
                    jsxElementStore.push(storedJsxCol);
                } else if (!col.useCache) {
                    storedJsxCol.items = [];
                }

                // For some reason items doesn't contain search items in some cases...
                const searchItemsNotPresentInItems = searchItems.filter((searchItem) => !items.some((item) => item._id === searchItem._id));
                const allItems = items.concat(searchItemsNotPresentInItems);
                allItems.forEach((item: E) => {
                    let storedJsxItem = storedJsxCol!.items.find((jsxItem) => item._id === jsxItem.itemId);
                    if (!storedJsxItem) {
                        col.jsxElement(item).then((element: JSX.Element) => {
                            storedJsxItem = { itemId: item._id, jsxElement: element };
                            storedJsxCol!.items.push(storedJsxItem);
                        });
                    }
                });
                newJsxElementStore.push(storedJsxCol);
            }
        });
        this.setState({ jsxElementStore: newJsxElementStore });
    }
}

export const GenericTable = wrapProps<GenericTableProps<any>>(GenericTableBase);
