import * as Animate from "../animate";
import * as React from "react";

import { Widget as ApiWidget, ConditionalSetApi, MultiSelectOptionStringValue, Site, WithId } from "@maxxton/cms-api";
import { ColumnOptions, colSelectOptions } from "../columns";
import { FormSpec, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { InitialItem, ResultOptions, TypesearchContainerWidgetSpec, WidgetSpec, WidgetType, isMenuWidget, isTypeResultsWidget } from "../../widget";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { PageWidgetSpec, Widget, isPageWidget } from "../../";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ConditionalSetSelect } from "../../../components/components.enum";
import { WidgetOptions as DynamicContainerOptions } from "../../dynamic/container/container.types";
import { FlexboxBase } from "./Flexbox";
import { ImageSpec } from "../../../media";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { PermissionType } from "@maxxton/cms-mxts-api";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import namespaceList from "../../../i18n/namespaceList";
import { paymentStatusOptions } from "../../../components/utils";
import { reportError } from "../../../utils/report.utils";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";
import { widgetListAutocomplete } from "../../../inputSpecs";

export interface WidgetOptions extends LocalizedTitleOptions, FlexboxOptions {
    name: string;
    direction: Direction;
    flexType: FlexType;
    spacing: SpacingOptions;
    styleIds: MultiSelectOptionStringValue[];
    slideboxcount: number;
    displayDots: boolean;
    useAdvancedSlider: boolean;
    displaycardcount: number;
    arrowPosition: string;
    arrowLocation: string;
    type: string;
    showAnimate: boolean;
    animateProperties: string;
    background: Background;
    bgOpacity: string;
    contentMargin: OuterContentMargin;
    paddingTop: ContentTopPadding;
    paddingBottom: ContentBottomPadding;
    setStickyId: string;
    stickyStacking: false;
    flexWrap: false;
    noGutters: boolean;
    isPublishDateConfigured: boolean;
    publishDate?: Date;
    isExpirationDateConfigured: boolean;
    expirationDate?: Date;
    addBorder: boolean;
    borderSide: string;
    borderStyle: string;
    borderWidth: string;
    borderColor: string;
    borderRadius: BorderRadius;
    toggleBlock: boolean;
    toggleBlockName: string;
    toggleType: string;
    showConditionalContent: boolean;
    externalOrigin: boolean;
    specificSite: boolean;
    widgetList?: string;
    arrowBackgroundColor: string;
    enableCancellationFundAddOn: boolean;
    useReferrerLink: boolean;
    externalUrl?: string;
    checkReservationStatus: boolean;
    sneakPreview: boolean;
    slidesPerView: number;
    arrowsTogether: boolean;
    arrowsHorizontalPosition: string;
    arrowsOutsideSlider: boolean;
    enableConditionalPaymentStatus?: boolean;
    conditionalPaymentStatus?: NumberMultiSelectOption[];
    conditionalWebContentId?: string;
    conditionalTemplateId?: string;
    useUnitStepLogic?: boolean;
    useTypeStepLogic?: boolean;
    useForUnitSelection?: boolean;
    useConditionalSets?: boolean;
    conditionalSetsItems?: string;
    useConditionalSetsFallback?: boolean;
    useFormDataInConditionalSet?: boolean;
    conditionalSetFallbackWebContentId?: string;
    conditionalSetFallbackTemplateId?: string;
    showContentIfMyEnvUserIsLoggedIn?: ShowContentIfMyEnvUserIsLoggedIn;
    defaultVisibility?: string;
    backgroundImageSpec?: ImageSpec;
    useBackgroundImage: boolean;
    backgroundImageMoreHeight?: boolean;
    backgroundImageHeightDesktop?: string;
    backgroundImageHeightTablet?: string;
    backgroundImageHeightMobile?: string;
    backgroundImageAlignContentVertically?: string;
    backgroundImageAlignContentHorizontally?: string;
    useCustomSpacingValues?: boolean;
    customSpacingBelowContainer?: number;
    customPaddingTop?: number;
    customPaddingBottom?: number;
    overrideReadOnly?: boolean;
    rowGap?: string;
    columnGap?: string;
    responsiveLayoutWidth?: boolean;
    mobileWidth?: number;
    tabletWidth?: number;
    desktopWidth?: number;
}

export interface FlexboxOptions {
    showMoment?: ShowMoment;
    name?: string;
    showConditionalContent?: boolean;
}
const animateList = Object.keys(Animate.default).map((key: string) => ({ label: (Animate.default as any)[key], value: key }));

type ShowContentIfMyEnvUserIsLoggedIn = "alwaysShow" | "showIfLoggedIn" | "showIfNotLoggedIn";
type Direction = "horizontal" | "vertical";
type FlexType = "flex" | "flex-grid";
type SpacingOptions = "start" | "end" | "sides" | "around" | "between";
type AlignSelf = "auto" | "start" | "end" | "center" | "baseline" | "stretch";
// eslint-disable-next-line max-len
type Background = "" | "default" | "background-black" | "dk-background-brand-alt-10" | "dk-background-neutral-10" | "dk-background-brand";
type OuterContentMargin = "dk-mb-s" | "dk-mb-m" | "dk-mb-l" | "dk-mb-xxl";
type ContentTopPadding = "default-pt" | "dk-pt-s" | "dk-pt-m" | "dk-pt-l" | "dk-pt-xxl";
type ContentBottomPadding = "default-pb" | "dk-pb-s" | "dk-pb-m" | "dk-pb-l" | "dk-pb-xxl";
export type ShowMoment = "" | "default" | "dk-hidden-s" | "dk-hidden-m" | "dk-hidden-l" | "dk-hidden-from-l" | "dk-hidden-from-desktop" | "show-xs-only" | "show-tablet-only";
type BorderRadius = "" | "border-radius-0" | "border-radius-s" | "border-radius-m" | "border-radius-l";
type RowGaps = "" | "row-gap-s" | "row-gap-m" | "row-gap-l" | "row-gap-xl";
type ColumnGaps = "" | "column-gap-s" | "column-gap-m" | "column-gap-l" | "column-gap-xl";

enum Alignment {
    START = "Start",
    CENTER = "Center",
    END = "End",
}

const TARGETS = ["container", "flexbox", "columns"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "flexbox-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexboxWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexboxWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "general"),
                    properties: [
                        [
                            {
                                variable: "direction",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "direction"),
                                type: "select",
                                default: "vertical" as Direction,
                                optionList: [
                                    {
                                        value: "horizontal",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "horizontal"),
                                    },
                                    {
                                        value: "vertical",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "vertical"),
                                    },
                                ],
                            },
                            {
                                variable: "flexType",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexType"),
                                type: "select",
                                default: "flex" as FlexType,
                                groupName: "flexLayoutGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexLayoutGroupTitle"),
                                optionList: [
                                    {
                                        value: "flex",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "typeFlex"),
                                    },
                                    {
                                        value: "flex-grid",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "typeFlexGrid"),
                                    },
                                ],
                            },
                            {
                                variable: "noGutters",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "noGutters"),
                                type: "checkbox",
                                default: false,
                                groupName: "flexLayoutGroup",
                                visible: (options: WidgetOptions) => options.flexType === "flex-grid",
                            },
                            {
                                variable: "rowGap",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "rowGap"),
                                type: "select",
                                default: "" as RowGaps,
                                groupName: "flexLayoutGroup",
                                optionList: [
                                    { value: "row-gap-s", label: "Row gap small (1rem)" },
                                    { value: "row-gap-m", label: "Row gap medium (2rem)" },
                                    { value: "row-gap-l", label: "Row gap large (2.5rem)" },
                                    { value: "row-gap-xl", label: "Row gap extra large (3rem)" },
                                ],
                                visible: (options: WidgetOptions) => options.flexType === "flex",
                            },
                            {
                                variable: "columnGap",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "columnGap"),
                                type: "select",
                                default: "" as ColumnGaps,
                                groupName: "flexLayoutGroup",
                                optionList: [
                                    { value: "column-gap-s", label: "Row gap small (1rem)" },
                                    { value: "column-gap-m", label: "Row gap medium (2rem)" },
                                    { value: "column-gap-l", label: "Row gap large (2.5rem)" },
                                    { value: "column-gap-xl", label: "Row gap extra large (3rem)" },
                                ],
                                visible: (options: WidgetOptions) => options.flexType === "flex-grid",
                            },
                            {
                                variable: "spacing",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "spacing"),
                                type: "select",
                                default: "around" as SpacingOptions,
                                groupName: "flexLayoutGroup",
                                optionList: [
                                    { value: "start", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "start") },
                                    { value: "end", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "end") },
                                    { value: "sides", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "sides") },
                                    { value: "around", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "around") },
                                    { value: "between", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "between") },
                                ],
                            },
                            {
                                variable: "setStickyId",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "setStickyId"),
                                type: "text",
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForStickyStacking"),
                                groupName: "stickyGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetFlexbox, "stickyGroupTitle"),
                            },
                            {
                                variable: "stickyStacking",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "stickyStacking"),
                                type: "checkbox",
                                default: false,
                                groupName: "stickyGroup",
                            },
                            {
                                variable: "flexWrap",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexWrap"),
                                type: "checkbox",
                                default: false,
                            },

                            {
                                variable: "showConditionalContent",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showConditionalContent"),
                                type: "checkbox",
                                default: false,
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "contentMargin",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "contentMargin"),
                                type: "select",
                                default: "dk-mb-xxl" as OuterContentMargin,
                                groupName: "contentSpacingGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetFlexbox, "contentSpacingGroupTitle"),
                                optionList: [
                                    { value: "default", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "default") },
                                    { value: "dk-mb-s", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "sSpacing") },
                                    { value: "dk-mb-m", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "mSpacing") },
                                    { value: "dk-mb-l", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "lSpacing") },
                                    { value: "dk-mb-xxl", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "xlSpacing") },
                                ],
                                visible: (options: WidgetOptions) => !options.useCustomSpacingValues,
                            },
                            {
                                variable: "paddingTop",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "paddingTop"),
                                type: "select",
                                default: "default" as ContentTopPadding,
                                groupName: "contentSpacingGroup",
                                optionList: [
                                    { value: "default-pt", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "default") },
                                    { value: "dk-pt-s", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "sSpacing") },
                                    { value: "dk-pt-m", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "mSpacing") },
                                    { value: "dk-pt-l", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "lSpacing") },
                                    { value: "dk-pt-xxl", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "xlSpacing") },
                                ],
                                visible: (options: WidgetOptions) => !options.useCustomSpacingValues,
                            },
                            {
                                variable: "paddingBottom",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "paddingBottom"),
                                type: "select",
                                default: "default" as ContentBottomPadding,
                                groupName: "contentSpacingGroup",
                                optionList: [
                                    { value: "default-pb", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "default") },
                                    { value: "dk-pb-s", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "sSpacing") },
                                    { value: "dk-pb-m", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "mSpacing") },
                                    { value: "dk-pb-l", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "lSpacing") },
                                    { value: "dk-pb-xxl", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "xlSpacing") },
                                ],
                                visible: (options: WidgetOptions) => !options.useCustomSpacingValues,
                            },
                            {
                                variable: "useCustomSpacingValues",
                                label: getI18nLocaleObject(namespaceList.admin, "useCustomSpacingValues"),
                                type: "checkbox",
                                groupName: "contentSpacingGroup",
                            },
                            {
                                variable: "customSpacingBelowContainer",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "contentMargin"),
                                type: "text",
                                groupName: "contentSpacingGroup",
                                visible: (options: WidgetOptions) => !!options.useCustomSpacingValues,
                            },
                            {
                                variable: "customPaddingTop",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "paddingTop"),
                                type: "text",
                                groupName: "contentSpacingGroup",
                                visible: (options: WidgetOptions) => !!options.useCustomSpacingValues,
                            },
                            {
                                variable: "customPaddingBottom",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "paddingBottom"),
                                type: "text",
                                groupName: "contentSpacingGroup",
                                visible: (options: WidgetOptions) => !!options.useCustomSpacingValues,
                            },
                            {
                                variable: "showAnimate",
                                label: getI18nLocaleObject(namespaceList.admin, "showAnimate"),
                                type: "checkbox",
                                groupName: "animateContentBlockGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetFlexbox, "animateContentBlockGroupTitle"),
                            },
                            {
                                variable: "animateProperties",
                                label: getI18nLocaleObject(namespaceList.admin, "animateProperty"),
                                type: "select",
                                optionList: animateList,
                                groupName: "animateContentBlockGroup",
                                visible: (options: WidgetOptions) => options.showAnimate,
                            },
                            {
                                variable: "animateProperties",
                                type: "animate",
                                groupName: "animateContentBlockGroup",
                                visible: (options: WidgetOptions) => options.showAnimate,
                            },
                            {
                                variable: "background",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "bg"),
                                type: "dual-color",
                                default: "default" as Background,
                            },
                            // temporary leaving this options for testing.
                            // {
                            //     variable: "bgOpacity",
                            //     label: getI18nLocaleObject(namespaceList.admin, "bgOpacity"),
                            //     type: "text",
                            //     visible: (options: WidgetOptions) => options.background !== "default",
                            // },
                            {
                                variable: "addBorder",
                                label: getI18nLocaleObject(namespaceList.admin, "addBorder"),
                                type: "checkbox",
                                groupName: "borderAndBorderRadiusGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetFlexbox, "borderAndBorderRadiusGroupTitle"),
                            },
                            {
                                variable: "borderSide",
                                label: getI18nLocaleObject(namespaceList.admin, "borderSide"),
                                type: "select",
                                default: "around",
                                groupName: "borderAndBorderRadiusGroup",
                                visible: (options: WidgetOptions) => options.addBorder,
                                optionList: [
                                    {
                                        value: "around",
                                        label: getI18nLocaleObject(namespaceList.admin, "around"),
                                    },
                                    {
                                        value: "top",
                                        label: getI18nLocaleObject(namespaceList.admin, "top"),
                                    },
                                    {
                                        value: "right",
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                    },
                                    {
                                        value: "left",
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                    },
                                    {
                                        value: "bottom",
                                        label: getI18nLocaleObject(namespaceList.admin, "bottom"),
                                    },
                                ],
                            },
                            {
                                variable: "borderStyle",
                                label: getI18nLocaleObject(namespaceList.admin, "borderStyle"),
                                type: "select",
                                default: "solid",
                                groupName: "borderAndBorderRadiusGroup",
                                visible: (options: WidgetOptions) => options.addBorder,
                                optionList: [
                                    {
                                        value: "solid",
                                        label: getI18nLocaleObject(namespaceList.admin, "borderSolid"),
                                    },
                                    {
                                        value: "dashed",
                                        label: getI18nLocaleObject(namespaceList.admin, "borderDashed"),
                                    },
                                    {
                                        value: "dotted",
                                        label: getI18nLocaleObject(namespaceList.admin, "borderDotted"),
                                    },
                                    {
                                        value: "double",
                                        label: getI18nLocaleObject(namespaceList.admin, "borderDouble"),
                                    },
                                ],
                            },
                            {
                                variable: "borderWidth",
                                label: getI18nLocaleObject(namespaceList.admin, "borderWidth"),
                                type: "text",
                                groupName: "borderAndBorderRadiusGroup",
                                visible: (options: WidgetOptions) => options.addBorder,
                            },
                            {
                                variable: "borderColor",
                                label: getI18nLocaleObject(namespaceList.admin, "borderColor"),
                                type: "dual-color",
                                default: "default",
                                groupName: "borderAndBorderRadiusGroup",
                                visible: (options: WidgetOptions) => options.addBorder,
                            },
                            {
                                variable: "borderRadius",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "borderRadius"),
                                type: "select",
                                default: "border-radius-0" as BorderRadius,
                                groupName: "borderAndBorderRadiusGroup",
                                optionList: [
                                    { value: "border-radius-0", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "default") },
                                    { value: "border-radius-s", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "smallBorderRadius") },
                                    { value: "border-radius-m", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "mediumBorderRadius") },
                                    { value: "border-radius-l", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "largeBorderRadius") },
                                    { value: "border-radius-xl", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "extraLargeBorderRadius") },
                                ],
                            },
                            {
                                variable: "useBackgroundImage",
                                label: getI18nLocaleObject(namespaceList.admin, "useBackgroundImage"),
                                type: "checkbox",
                                groupName: "useBackgroundImageGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetFlexbox, "useBackgroundImageGroupTitle"),
                            },
                            {
                                variable: "backgroundImageSpec",
                                type: "image",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "image"),
                                groupName: "useBackgroundImageGroup",
                                visible: (options: WidgetOptions) => options.useBackgroundImage,
                            },
                            {
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "moreHeight"),
                                variable: "backgroundImageMoreHeight",
                                groupName: "useBackgroundImageGroup",
                                visible: (options: WidgetOptions) => options.useBackgroundImage,
                            },
                            {
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "heightDesktop"),
                                variable: "backgroundImageHeightDesktop",
                                groupName: "useBackgroundImageGroup",
                                visible: (options: WidgetOptions) => options.useBackgroundImage && !!options.backgroundImageMoreHeight,
                            },
                            {
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "heightTablet"),
                                variable: "backgroundImageHeightTablet",
                                placeholder: "If this field is empty it will take above desktop field height",
                                groupName: "useBackgroundImageGroup",
                                visible: (options: WidgetOptions) => options.useBackgroundImage && !!options.backgroundImageMoreHeight,
                            },
                            {
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.widgetImage, "heightMobile"),
                                variable: "backgroundImageHeightMobile",
                                placeholder: "If this field is empty it will take 60vh",
                                groupName: "useBackgroundImageGroup",
                                visible: (options: WidgetOptions) => options.useBackgroundImage && !!options.backgroundImageMoreHeight,
                            },
                            {
                                variable: "backgroundImageAlignContentVertically",
                                label: getI18nLocaleObject(namespaceList.admin, "backgroundImageAlignContentVertically"),
                                type: "select",
                                groupName: "useBackgroundImageGroup",
                                visible: (options: WidgetOptions) => options.useBackgroundImage,
                                optionList: [
                                    {
                                        value: "start",
                                        label: Alignment.START,
                                    },
                                    {
                                        value: "center",
                                        label: Alignment.CENTER,
                                    },
                                    {
                                        value: "end",
                                        label: Alignment.END,
                                    },
                                ],
                            },
                            {
                                variable: "backgroundImageAlignContentHorizontally",
                                label: getI18nLocaleObject(namespaceList.admin, "backgroundImageAlignContentHorizontally"),
                                type: "select",
                                groupName: "useBackgroundImageGroup",
                                visible: (options: WidgetOptions) => options.useBackgroundImage,
                                optionList: [
                                    {
                                        value: "start",
                                        label: Alignment.START,
                                    },
                                    {
                                        value: "center",
                                        label: Alignment.CENTER,
                                    },
                                    {
                                        value: "end",
                                        label: Alignment.END,
                                    },
                                ],
                            },
                            ...titleStylingOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "slider"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "useSlideshow"),
                                type: "paragraph",
                            },
                            {
                                variable: "type",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "type"),
                                type: "select",
                                default: "flex",
                                optionList: [
                                    { value: "flex", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "flex") },
                                    { value: "slide", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "slider") },
                                ],
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "useAdvancedSlider"),
                                variable: "useAdvancedSlider",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.type === "slide",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "sneakPreview"),
                                variable: "sneakPreview",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.useAdvancedSlider && options.type === "slide",
                            },
                            {
                                variable: "slidesPerView",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesPerView"),
                                type: "number",
                                visible: (options: WidgetOptions) => options.useAdvancedSlider && options.type === "slide",
                            },
                            {
                                variable: "displaycardcount",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "displaycardcount"),
                                type: "number",
                                default: 3,
                                visible: (options: WidgetOptions) => options.type === "slide" && !options.useAdvancedSlider,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "displayDots"),
                                variable: "displayDots",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.type === "slide",
                            },
                            {
                                variable: "arrowPosition",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowPosition"),
                                type: "select",
                                default: "arrowCenter",
                                visible: (options: WidgetOptions) => options.type === "slide",
                                optionList: [
                                    { value: "arrowTop", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowTop") },
                                    // eslint-disable-next-line max-len
                                    { value: "arrowCenter", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowCenter") },
                                    // eslint-disable-next-line max-len
                                    { value: "arrowBottom", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowBottom") },
                                ],
                            },
                            {
                                variable: "arrowsTogether",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsTogether"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.useAdvancedSlider && options.type === "slide",
                            },
                            {
                                variable: "arrowsHorizontalPosition",
                                label: getI18nLocaleObject(namespaceList.widgetImageGallery, "arrowsHorizontalPosition"),
                                type: "select",
                                default: "center",
                                visible: (options: WidgetOptions) => options.arrowsTogether && options.useAdvancedSlider && options.type === "slide",
                                optionList: [
                                    {
                                        value: "left",
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                    },
                                    {
                                        value: "center",
                                        label: getI18nLocaleObject(namespaceList.admin, "center"),
                                    },
                                    {
                                        value: "right",
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                    },
                                ],
                            },

                            {
                                variable: "arrowLocation",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowLocation"),
                                type: "select",
                                default: "arrowOuter",
                                visible: (options: WidgetOptions) => options.type === "slide",
                                optionList: [
                                    // eslint-disable-next-line max-len
                                    { value: "arrowInner", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowInner") },
                                    // eslint-disable-next-line max-len
                                    { value: "arrowOuter", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowOuter") },
                                ],
                            },
                            {
                                variable: "arrowsOutsideSlider",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "arrowsOutsideSlider"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.arrowLocation === "arrowOuter" && options.arrowsTogether && options.useAdvancedSlider && options.type === "slide",
                            },
                            {
                                variable: "arrowBackgroundColor",
                                label: getI18nLocaleObject(namespaceList.admin, "arrowBackgroundColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => options.type === "slide",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "linking"),
                    properties: [
                        [
                            {
                                variable: "name",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "name"),
                                type: "text",
                            },
                            {
                                variable: "toggleBlock",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "toggleBlock"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "toggleBlockName",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "toggleBlockName"),
                                type: "text",
                                visible: (item: WidgetOptions) => item.toggleBlock,
                            },
                            {
                                variable: "toggleType",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "toggleType"),
                                type: "select",
                                optionList: [
                                    {
                                        value: "simpleToggle",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "simpleToggle"),
                                    },
                                    {
                                        value: "slidebySide",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "slidebySide"),
                                    },
                                    {
                                        value: "slideBottom",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "slideBottom"),
                                    },
                                ],
                                visible: (item: WidgetOptions) => item.toggleBlock,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "responsiveDisplay"),
                    properties: [
                        [
                            {
                                variable: "showMoment",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showOnMoment"),
                                type: "select",
                                default: "" as ShowMoment,
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "default"),
                                    },
                                    {
                                        value: "dk-hidden-s",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "hiddenSmall"),
                                    },
                                    {
                                        value: "show-xs-only",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showForSmall"),
                                    },
                                    {
                                        value: "show-tablet-only",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showForTablet"),
                                    },
                                    {
                                        value: "dk-hidden-m",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "hiddenMedium"),
                                    },
                                    {
                                        value: "dk-hidden-l",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "hiddenLarge"),
                                    },
                                    {
                                        value: "dk-hidden-from-desktop",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "hiddenFromDesktop"),
                                    },
                                    {
                                        value: "dk-hidden-from-l",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "hiddenFromLarge"),
                                    },
                                    {
                                        value: "hidden-till-tablet",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "hiddenTillTablet"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "scheduling"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isPublishDateConfigured"),
                                variable: "isPublishDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "publishDate",
                                label: getI18nLocaleObject(namespaceList.admin, "publishDate"),
                                type: "datetime",
                                visible: (item: WidgetOptions) => item.isPublishDateConfigured,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "isExpirationDateConfigured"),
                                variable: "isExpirationDateConfigured",
                                type: "checkbox",
                            },
                            {
                                variable: "expirationDate",
                                label: getI18nLocaleObject(namespaceList.admin, "expirationDate"),
                                type: "datetime",
                                visible: (item: WidgetOptions) => item.isExpirationDateConfigured,
                            },
                        ],
                    ],
                },
                /* jscpd:ignore-start */
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "responsive"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "responsiveLayoutWidth"),
                                variable: "responsiveLayoutWidth",
                                type: "checkbox",
                            },
                            {
                                variable: "mobileWidth",
                                label: getI18nLocaleObject(namespaceList.admin, "mobileWidth"),
                                type: "range",
                                visible: (item: WidgetOptions) => !!item.responsiveLayoutWidth,
                            },
                            {
                                variable: "tabletWidth",
                                label: getI18nLocaleObject(namespaceList.admin, "tabletWidth"),
                                type: "range",
                                visible: (item: WidgetOptions) => !!item.responsiveLayoutWidth,
                            },
                            {
                                variable: "desktopWidth",
                                label: getI18nLocaleObject(namespaceList.admin, "desktopWidth"),
                                type: "range",
                                visible: (item: WidgetOptions) => !!item.responsiveLayoutWidth,
                            },
                        ],
                    ],
                    visible: (item: WidgetOptions) => item.type === "slide",
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "conditionalUsage"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "externalOrigin"),
                                variable: "externalOrigin",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "useReferrerLink"),
                                variable: "useReferrerLink",
                                type: "checkbox",
                            },
                            {
                                variable: "externalUrl",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "externalUrl"),
                                type: "text",
                                visible: (item: WidgetOptions) => item.useReferrerLink,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "specificSite"),
                                variable: "specificSite",
                                type: "checkbox",
                            },
                            widgetListAutocomplete("widgetList"),
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "useForUnitSelection"),
                                variable: "useForUnitSelection",
                                type: "checkbox",
                            },
                            {
                                variable: "enableCancellationFundAddOn",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "enableCancellationFundAddOn"),
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "checkReservationStatus"),
                                variable: "checkReservationStatus",
                                type: "checkbox",
                            },
                            {
                                variable: "enableConditionalPaymentStatus",
                                label: getI18nLocaleObject(namespaceList.widgetPaymentStatus, "enableConditionalPaymentStatus"),
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetPaymentStatus, "conditionalPaymentParagraph"),
                                type: "paragraph",
                                visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                            },
                            {
                                variable: "conditionalPaymentStatus",
                                label: getI18nLocaleObject(namespaceList.widgetPaymentStatus, "conditionalPaymentStatus"),
                                type: "multiselect",
                                optionList: () => paymentStatusOptions(),
                                visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                            },
                            {
                                variable: "conditionalWebContentId",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "content"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                            },
                            {
                                variable: "conditionalTemplateId",
                                label: getI18nLocaleObject(namespaceList.widgetTemplate, "template"),
                                type: "autocomplete",
                                refType: templateSpec,
                                visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                            },
                            {
                                variable: "useUnitStepLogic",
                                label: getI18nLocaleObject(namespaceList.admin, "useUnitStepLogic"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "useTypeStepLogic",
                                label: getI18nLocaleObject(namespaceList.admin, "useTypeStepLogic"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "showContentIfMyEnvUserIsLoggedIn",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showContentIfMyEnvUserIsLoggedIn"),
                                type: "select",
                                default: "alwaysShow" as ShowContentIfMyEnvUserIsLoggedIn,
                                optionList: [
                                    {
                                        value: "alwaysShow",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "alwaysShow"),
                                    },
                                    {
                                        value: "showIfLoggedIn",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showIfLoggedIn"),
                                    },
                                    {
                                        value: "showIfNotLoggedIn",
                                        label: getI18nLocaleObject(namespaceList.widgetFlexbox, "showIfNotLoggedIn"),
                                    },
                                ],
                            },
                            {
                                variable: "useConditionalSets",
                                label: getI18nLocaleObject(namespaceList.admin, "useConditionalSets"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "useFormDataInConditionalSet",
                                label: getI18nLocaleObject(namespaceList.admin, "useFormDataInConditionalSet"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => !!options.useConditionalSets,
                            },
                            {
                                variable: "defaultVisibility",
                                label: getI18nLocaleObject(namespaceList.admin, "defaultVisibility"),
                                type: "select",
                                optionList: [
                                    {
                                        value: ConditionalSetSelect.SHOW,
                                        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "show"),
                                    },
                                    {
                                        value: ConditionalSetSelect.HIDE,
                                        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "hide"),
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.useConditionalSets,
                            },
                            {
                                variable: "conditionalSetsItems",
                                label: getI18nLocaleObject(namespaceList.admin, "conditionalSetsItems"),
                                type: "select",
                                optionList: async (): Promise<Array<{ label: string; value: string }>> =>
                                    ConditionalSetApi.find({ projection: { name: 1 } }).then((result) =>
                                        result.map((conditionalSetsItem) => ({ value: conditionalSetsItem._id, label: conditionalSetsItem.name }))
                                    ),
                                visible: (options: WidgetOptions) => !!options.useConditionalSets,
                            },
                            {
                                variable: "useConditionalSetsFallback",
                                label: getI18nLocaleObject(namespaceList.admin, "useConditionalSetsFallback"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => !!(options.useConditionalSets && options.conditionalSetsItems),
                            },
                            {
                                variable: "conditionalSetFallbackWebContentId",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "conditionalSetFallbackWebContent"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                visible: (options: WidgetOptions) => !!(options.useConditionalSets && options.conditionalSetsItems && options.useConditionalSetsFallback),
                            },
                            {
                                variable: "conditionalSetFallbackTemplateId",
                                label: getI18nLocaleObject(namespaceList.widgetTemplate, "conditionalSetFallbackTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                visible: (options: WidgetOptions) => !!(options.useConditionalSets && options.conditionalSetsItems && options.useConditionalSetsFallback),
                            },
                        ],
                    ],
                    visible: (item: WidgetOptions) => item.showConditionalContent,
                },
                /* jscpd:ignore-end */
            ],
        },
    ],
};

export interface FlexboxChildOptions extends ColumnOptions, FlexboxOptions {
    alignSelf?: AlignSelf;
    removeflexible?: boolean;
    toggleBlock: boolean;
    toggleBlockName: string;
    toggleType: string;
}

// eslint-disable-next-line max-lines-per-function
export function flexboxWidget(type: WidgetType) {
    const flexbox: WidgetSpec<WidgetOptions> = {
        id: "flexbox",
        type,
        widgetGroup: WidgetGroup.LAYOUT,
        name: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexboxWidget"),
        description: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexboxWidgetDescription"),
        optionsForm: widgetOptionsForm,
        childOptions: [
            {
                type: "statictabs",
                tabs: [
                    {
                        name: getI18nLocaleObject(namespaceList.widgetFlexbox, "flexboxWidget"),
                        properties: [
                            [
                                {
                                    variable: "alignSelf",
                                    label: getI18nLocaleObject(namespaceList.widgetFlexbox, "alignment"),
                                    type: "select",
                                    default: "auto",
                                    optionList: [
                                        { value: "auto", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "auto") },
                                        { value: "start", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "start") },
                                        { value: "end", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "end") },
                                        { value: "center", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "center") },
                                        { value: "baseline", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "baseline") },
                                        { value: "stretch", label: getI18nLocaleObject(namespaceList.widgetFlexbox, "stretch") },
                                    ],
                                },
                                {
                                    variable: "removeflexible",
                                    label: getI18nLocaleObject(namespaceList.widgetFlexbox, "removeflexible"),
                                    type: "checkbox",
                                    default: false,
                                },
                            ],
                        ],
                        visible: (item: WidgetOptions, initialItem: InitialItem<any>, parent?: ApiWidget): boolean => !parent || parent.options.flexType === "flex-grid",
                    },
                    /* jscpd:ignore-start */
                    {
                        name: getI18nLocaleObject(namespaceList.widgetFlexbox, "responsive"),
                        properties: [
                            [
                                {
                                    type: "select",
                                    label: getI18nLocaleObject(namespaceList.admin, "columnsResponsive"),
                                    variable: "columnsResponsive",
                                    default: "",
                                    optionList: colSelectOptions,
                                    visible: (item: WidgetOptions) => !item.responsiveLayoutWidth,
                                },
                                {
                                    type: "select",
                                    label: getI18nLocaleObject(namespaceList.admin, "columnsMobile"),
                                    variable: "col",
                                    default: "",
                                    optionList: colSelectOptions,
                                    visible: (item: WidgetOptions) => !item.responsiveLayoutWidth,
                                },
                                {
                                    type: "select",
                                    label: getI18nLocaleObject(namespaceList.admin, "columnsTablet"),
                                    variable: "columnsTablet",
                                    default: "",
                                    optionList: colSelectOptions,
                                    visible: (item: WidgetOptions) => !item.responsiveLayoutWidth,
                                },
                                {
                                    type: "select",
                                    label: getI18nLocaleObject(namespaceList.admin, "columnsDesktop"),
                                    variable: "columnsDesktop",
                                    default: "",
                                    optionList: colSelectOptions,
                                    visible: (item: WidgetOptions) => !item.responsiveLayoutWidth,
                                },
                                {
                                    type: "select",
                                    label: getI18nLocaleObject(namespaceList.admin, "columnsExtraLargeDesktop"),
                                    variable: "columnsExtraLargeDesktop",
                                    default: "",
                                    optionList: colSelectOptions,
                                    visible: (item: WidgetOptions) => !item.responsiveLayoutWidth,
                                },
                                {
                                    label: getI18nLocaleObject(namespaceList.admin, "responsiveLayoutWidth"),
                                    variable: "responsiveLayoutWidth",
                                    type: "checkbox",
                                },
                                {
                                    variable: "mobileWidth",
                                    label: getI18nLocaleObject(namespaceList.admin, "mobileWidth"),
                                    type: "range",
                                    visible: (item: WidgetOptions) => !!item.responsiveLayoutWidth,
                                },
                                {
                                    variable: "tabletWidth",
                                    label: getI18nLocaleObject(namespaceList.admin, "tabletWidth"),
                                    type: "range",
                                    visible: (item: WidgetOptions) => !!item.responsiveLayoutWidth,
                                },
                                {
                                    variable: "desktopWidth",
                                    label: getI18nLocaleObject(namespaceList.admin, "desktopWidth"),
                                    type: "range",
                                    visible: (item: WidgetOptions) => !!item.responsiveLayoutWidth,
                                },
                            ],
                        ],
                        visible: (item: WidgetOptions, initialItem: InitialItem<any>, parent?: ApiWidget): boolean => !parent || parent.options.flexType === "flex-grid",
                    },
                    {
                        name: getI18nLocaleObject(namespaceList.admin, "conditionalUsage"),
                        properties: [
                            [
                                {
                                    label: getI18nLocaleObject(namespaceList.admin, "externalOrigin"),
                                    variable: "externalOrigin",
                                    type: "checkbox",
                                },
                                {
                                    label: getI18nLocaleObject(namespaceList.widgetFlexbox, "useReferrerLink"),
                                    variable: "useReferrerLink",
                                    type: "checkbox",
                                },
                                {
                                    variable: "externalUrl",
                                    label: getI18nLocaleObject(namespaceList.widgetFlexbox, "externalUrl"),
                                    type: "text",
                                    visible: (item: WidgetOptions) => item.useReferrerLink,
                                },
                                {
                                    label: getI18nLocaleObject(namespaceList.admin, "specificSite"),
                                    variable: "specificSite",
                                    type: "checkbox",
                                },
                                widgetListAutocomplete("widgetList"),
                                {
                                    variable: "enableCancellationFundAddOn",
                                    label: getI18nLocaleObject(namespaceList.widgetRevealer, "enableCancellationFundAddOn"),
                                    type: "checkbox",
                                },
                                {
                                    label: getI18nLocaleObject(namespaceList.widgetFlexbox, "checkReservationStatus"),
                                    variable: "checkReservationStatus",
                                    type: "checkbox",
                                },
                                {
                                    variable: "enableConditionalPaymentStatus",
                                    label: getI18nLocaleObject(namespaceList.widgetPaymentStatus, "enableConditionalPaymentStatus"),
                                    type: "checkbox",
                                },
                                {
                                    label: getI18nLocaleObject(namespaceList.widgetPaymentStatus, "conditionalPaymentParagraph"),
                                    type: "paragraph",
                                    visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                                },
                                {
                                    variable: "conditionalPaymentStatus",
                                    label: getI18nLocaleObject(namespaceList.widgetPaymentStatus, "conditionalPaymentStatus"),
                                    type: "multiselect",
                                    optionList: () => paymentStatusOptions(),
                                    visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                                },
                                {
                                    variable: "conditionalWebContentId",
                                    label: getI18nLocaleObject(namespaceList.widgetWebContent, "content"),
                                    type: "autocomplete",
                                    refType: webcontentSpecWithoutPreview,
                                    visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                                },
                                {
                                    variable: "conditionalTemplateId",
                                    label: getI18nLocaleObject(namespaceList.widgetTemplate, "template"),
                                    type: "autocomplete",
                                    refType: templateSpec,
                                    visible: (item: WidgetOptions) => !!item.enableConditionalPaymentStatus,
                                },
                            ],
                        ],
                        visible: (item: WidgetOptions, initialItem: InitialItem<any>, parent?: ApiWidget): boolean => !parent || parent.options.showConditionalContent,
                    },
                    /* jscpd:ignore-end */
                ],
            },
        ],
        defaultOptions: (): WidgetOptions => ({
            name: "",
            direction: "vertical",
            flexType: "flex",
            spacing: "around",
            styleIds: [],
            slideboxcount: 1,
            displayDots: false,
            displaycardcount: 3,
            arrowPosition: "arrowCenter",
            arrowLocation: "arrowOuter",
            type: "flex",
            showAnimate: false,
            animateProperties: "",
            background: "default",
            bgOpacity: "",
            contentMargin: "dk-mb-xxl",
            paddingTop: "default-pt",
            paddingBottom: "default-pb",
            showMoment: "",
            setStickyId: "",
            stickyStacking: false,
            flexWrap: false,
            noGutters: false,
            isPublishDateConfigured: false,
            isExpirationDateConfigured: false,
            addBorder: false,
            borderSide: "around",
            borderStyle: "solid",
            borderWidth: "",
            borderColor: "default",
            borderRadius: "border-radius-0",
            toggleBlock: false,
            toggleBlockName: "",
            toggleType: "",
            showConditionalContent: false,
            externalOrigin: false,
            specificSite: false,
            arrowBackgroundColor: "default",
            enableCancellationFundAddOn: false,
            useReferrerLink: false,
            checkReservationStatus: false,
            useAdvancedSlider: false,
            sneakPreview: false,
            slidesPerView: 1,
            arrowsTogether: false,
            arrowsHorizontalPosition: "center",
            arrowsOutsideSlider: false,
            enableWidgetTitle: false,
            localizedWidgetTitle: [],
            styleWidgetTitle: false,
            useTitleHeadings: "p",
            useBackgroundImage: false,
        }),
        async instanceDescription({ widget }): Promise<string> {
            const { name } = widget.options || "";
            if (name) {
                return name;
            }
            return getI18nLocaleString(namespaceList.widgetFlexbox, "noName");
        },
    };
    (flexbox as PageWidgetSpec<WidgetOptions> | TypesearchContainerWidgetSpec<WidgetOptions>).render = async (
        widget: Widget<WidgetOptions>,
        context: CMSProvidedProperties,
        sitemapPageLinkWidgetOptions?: SitemapPageLinkWidgetOptions,
        resultOptions?: ResultOptions,
        dynamicContainerOptions?: DynamicContainerOptions,
        shouldReturnProps?: boolean,
        allSites?: Array<Site & WithId>,
        flexboxOptions?: FlexboxOptions
    ) => {
        const left = "<";
        const right = ">";

        const parentOptions: FlexboxOptions = {
            showMoment: widget.options.showMoment && widget.options.showMoment !== "default" ? widget.options.showMoment : flexboxOptions?.showMoment,
            name: widget?.options?.name || "",
        };

        const sites = await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
        const children = await Promise.all(
            widget.children.map((child, index) => {
                /* jscpd:ignore-start */
                const childSpec = child.spec;
                if (isPageWidget(childSpec)) {
                    return childSpec
                        .render(child, context, sitemapPageLinkWidgetOptions, resultOptions, dynamicContainerOptions, shouldReturnProps, allSites, parentOptions)
                        .then((element) => ({ element, options: child.options, _id: child._id }))
                        .catch((err) => {
                            reportError(
                                err,
                                undefined,
                                `Error while rendering a child widget( type: ${childSpec?.type}, name: ${childSpec?.name}, id: ${childSpec?.id || (childSpec as any)?._id}, type: ${
                                    childSpec?.type
                                }) of the flexboxWidget`
                            );
                            return { element: <div key={index} />, options: {} };
                        });
                } else if (isTypeResultsWidget(childSpec)) {
                    return childSpec
                        .render(child, context, sitemapPageLinkWidgetOptions, resultOptions, dynamicContainerOptions, parentOptions)
                        .then((element) => ({ element, options: child.options }))
                        .catch((err) => {
                            reportError(
                                err,
                                undefined,
                                `Error while rendering a child widget( type: ${childSpec?.type}, name: ${childSpec?.name}, id: ${childSpec?.id || (childSpec as any)?._id}, type: ${
                                    childSpec?.type
                                }) of the flexboxWidget`
                            );
                            return { element: <div key={index} />, options: {} };
                        });
                } else if (isMenuWidget(childSpec)) {
                    return childSpec
                        .render(child, context, allSites, sitemapPageLinkWidgetOptions, parentOptions)
                        .then((element) => ({ element, options: child.options }))
                        .catch((err) => {
                            reportError(
                                err,
                                undefined,
                                `Error while rendering a child widget( type: ${childSpec?.type}, name: ${childSpec?.name}, id: ${childSpec?.id || (childSpec as any)?._id}, type: ${
                                    childSpec?.type
                                }) of the flexboxWidget`
                            );
                            return { element: <div key={index} />, options: {} };
                        });
                }
                throw new TypeError("Expected child widgets to be page widgets");
                /* jscpd:ignore-end */
            })
        );
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return (
            <FlexboxBase
                permission={PermissionType.EXECUTE}
                id={widget._id}
                options={widget.options}
                children={children}
                context={context}
                className={styleClasses}
                left={left}
                right={right}
                flexboxOptions={flexboxOptions}
                sites={sites}
            />
        );
    };
    return flexbox;
}
