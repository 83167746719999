import * as moment from "moment";

import {
    AddOn,
    ApiCallOptions,
    Group,
    GroupType,
    MxtsApi,
    PagedResult,
    RecommendedAddOnsRequest,
    ReservedResourceSubject,
    Resource,
    ResourceMarketingGroup,
    ResourceShowMoment,
    ResourceType,
    StayPeriod,
    getAllIds,
} from "@maxxton/cms-mxts-api";
import { DATE_FORMAT, MXTS } from "../../utils/constants";

import { DomainObjectUtil } from "../../utils/domainobject.util";
import { DynamicFilter } from "../../redux/reducers/dynamicFilter.types";
import { MarketingGroupWithAddOns } from "../../redux/reducers/add-ons/addOnsReducer";
import { MyEnvState } from "../../redux/reducers/myEnv/myEnvState";
import { RecommendedAddOns } from "../../redux/actions/add-ons/addOnsAction";
import { StringUtil } from "../../utils/string.util";
import { SubjectUtil } from "../../plugins/dynamic/subject/SubjectUtil";
import { difference } from "lodash";
import { getFlattenedMyEnvData } from "../../redux/reducers/myEnv/myEnv.util";

// Get the bookable add-ons based on the dynamicFilter
export const getBookableAddOnsFromDynamicFilter = async (dynamicFilter: DynamicFilter, env: ApiCallOptions, recommendedAddOns?: RecommendedAddOns): Promise<AddOn[]> => {
    const {
        distributionChannel: { distributionChannelId } = {},
        ownerShareableLink,
        reservationCategoryId,
        resourceid: resourceId,
        startdate: startDate,
        subject: subjects,
        unitid: unitId,
    } = dynamicFilter;
    const derivedEndDate: string = await getDerivedEndDateFromDynamicFilter({ dynamicFilter, env });
    const requestBody = {
        reservationCategoryId: ownerShareableLink?.reservationCategoryId || reservationCategoryId,
        unitId,
        subjects: SubjectUtil.getSubjectQuantitiesFromMap(subjects),
        distributionChannelId: ownerShareableLink?.distributionChannelId || +distributionChannelId!,
        validFrom: moment(startDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC),
        validTo: derivedEndDate,
        resourceShowMoment: ResourceShowMoment.INTERNET,
        returnTranslations: true,
    };
    let bookableAddOns: AddOn[] = [];
    if (recommendedAddOns?.showRecommendedAddOns) {
        (requestBody as RecommendedAddOnsRequest).size = recommendedAddOns.recommendedAddOnsSize ? Number(recommendedAddOns.recommendedAddOnsSize) : 4;
        (requestBody as RecommendedAddOnsRequest).showColdStarts = recommendedAddOns.showColdStartsRecommendedAddOns;
        bookableAddOns = await MxtsApi.getBookableRecommendedAddOns(env, requestBody, [{ key: "resourceId", value: resourceId }]);
    } else {
        bookableAddOns = await MxtsApi.getBookableAddOns(env, requestBody, [{ key: "resourceId", value: resourceId }]);
    }

    const filteredAddOns = bookableAddOns.filter((addOn: AddOn) => addOn.type in ResourceType);
    return filteredAddOns;
};

export const getBookableAddOnsFromMyEnvState = async (myEnvState: MyEnvState, env: ApiCallOptions, dynamicFilter?: DynamicFilter, recommendedAddOns?: RecommendedAddOns): Promise<AddOn[]> => {
    const { startDate, endDate, reservationCategoryId, distributionChannelId, resourceId, unitId, selectedAccoReservedResourceId, reservationId } = getFlattenedMyEnvData(myEnvState, dynamicFilter);

    if (!selectedAccoReservedResourceId || !reservationId || !reservationCategoryId || !distributionChannelId) {
        return [];
    }

    const subjectsResult: PagedResult<ReservedResourceSubject> | undefined = await MxtsApi.getReservedResourceSubjects(env, {
        reservedResourceId: selectedAccoReservedResourceId,
        reservationId,
        size: MXTS.MAX_RESULTS,
    });
    const subjectQuantities = (subjectsResult.content || []).map((subj) => ({ subjectId: subj.subjectId, quantity: subj.quantity }));
    const requestBody = {
        reservationCategoryId,
        unitId,
        subjects: subjectQuantities,
        distributionChannelId,
        validFrom: startDate,
        validTo: endDate,
        resourceShowMoment: ResourceShowMoment.MY_ENVIRONMENT,
        returnTranslations: true,
    };
    let bookableAddOns: AddOn[] = [];
    if (recommendedAddOns?.showRecommendedAddOns) {
        (requestBody as RecommendedAddOnsRequest).size = recommendedAddOns.recommendedAddOnsSize ? Number(recommendedAddOns.recommendedAddOnsSize) : 4;
        (requestBody as RecommendedAddOnsRequest).showColdStarts = recommendedAddOns.showColdStartsRecommendedAddOns;
        bookableAddOns = await MxtsApi.getBookableRecommendedAddOns(env, requestBody, [{ key: "resourceId", value: resourceId }]);
    } else {
        bookableAddOns = await MxtsApi.getBookableAddOns(env, requestBody, [{ key: "resourceId", value: resourceId }]);
    }

    const filteredAddOns = bookableAddOns.filter((addOn: AddOn) => addOn.type in ResourceType);
    return filteredAddOns;
};

// TODO: Decide whether this should be in the date util
// Determine the enddate, taking into account that enddate might not be defined in the filter
export const getDerivedEndDateFromDynamicFilter = async ({ dynamicFilter, env }: { dynamicFilter: DynamicFilter; env: ApiCallOptions }): Promise<string> => {
    const { duration, enddate: endDate, startdate: startDate, stayperioddefid: stayPeriodDefId } = dynamicFilter;

    if (endDate) {
        return moment(endDate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.ELASTIC);
    } else if (startDate && duration) {
        return moment(startDate, DATE_FORMAT.DEFAULT).add(duration, "days").format(DATE_FORMAT.ELASTIC);
    } else if (startDate && stayPeriodDefId) {
        const stayPeriods: PagedResult<StayPeriod> = await MxtsApi.stayPeriods(env, { stayPeriodDefIds: [stayPeriodDefId], startDate: moment().format(DATE_FORMAT.ELASTIC) });

        if (stayPeriods.content[0]?.duration) {
            return moment(startDate, DATE_FORMAT.DEFAULT).add(stayPeriods.content[0].duration, "days").format(DATE_FORMAT.ELASTIC);
        }

        throw new Error("The end date could not be determined based on the criteria in the dynamic filter. No stay period was found.");
    }

    throw new Error("The end date could not be determined based on the criteria in the dynamic filter");
};

// Find all marketing groups associated with a list of add-ons, create articifial marketing groups for add-ons without a marketing group
export const getMarketingGroupsWithAddOns = async ({ addOns, env }: { addOns: AddOn[]; env: ApiCallOptions }): Promise<MarketingGroupWithAddOns[]> => {
    const addOnResourceIds: number[] = addOns.map((addOn: AddOn) => addOn.resourceId);
    let addOnMarketingGroupIntersections: ResourceMarketingGroup[] = []; // Stores the individual links between addOn.resourceId and marketingGroup.groupId

    if (addOnResourceIds.length) {
        addOnMarketingGroupIntersections = addOnMarketingGroupIntersections.concat(
            await getAllIds(addOnResourceIds, async (ids: number[], pageSize: number) => MxtsApi.getResourceMarketingGroups(env, { size: pageSize, resourceIds: ids }), 50)
        );
    }

    const resourceIdsWithMarketingGroup: number[] = [...new Set(addOnMarketingGroupIntersections.map((intersection: ResourceMarketingGroup) => intersection.resourceId))];
    const resourceIdsWithoutMarketingGroup: number[] = difference(addOnResourceIds, resourceIdsWithMarketingGroup);
    const addOnsWithoutMarketingGroup: AddOn[] = addOns.filter((addOn: AddOn) => resourceIdsWithoutMarketingGroup.includes(addOn.resourceId));

    let marketingGroups: Group[] = [];
    if (addOnMarketingGroupIntersections.length) {
        const marketingGroupIds: number[] = [...new Set(addOnMarketingGroupIntersections.map((intersection: ResourceMarketingGroup) => intersection.resourcegroupId))];
        marketingGroups = marketingGroups.concat(
            await getAllIds(
                marketingGroupIds,
                async (ids: number[], pageSize: number) => MxtsApi.getGroups(env, { size: pageSize, type: "marketing", view: "detail", sort: "priority", groupIds: ids }),
                100
            )
        );
    }

    let artificialMarketingGroups: Group[] = [];
    if (addOnsWithoutMarketingGroup.length) {
        // For each add-on, the parent resource is converted into an artificial marketing group.
        // The parentId becomes the groupId, so we can enhance the intersections array with the link between these add-ons and their parentIds
        addOnMarketingGroupIntersections = addOnMarketingGroupIntersections.concat(
            addOnsWithoutMarketingGroup.map((addOn: AddOn): ResourceMarketingGroup => ({ resourceId: addOn.resourceId, resourcegroupId: addOn.parentId }))
        );
        artificialMarketingGroups = artificialMarketingGroups.concat(await getMarketingGroupsFromParents({ addOns: addOnsWithoutMarketingGroup, env }));
    }

    return combineGroupsAndAddOns({ addOns, marketingGroups, artificialMarketingGroups, addOnMarketingGroupIntersections });
};

const getMarketingGroupsFromParents = async ({ addOns, env }: { addOns: AddOn[]; env: ApiCallOptions }): Promise<Group[]> => {
    const parentResourceIds: number[] = [...new Set(addOns.map((addOn: AddOn) => addOn.parentId))].filter((parentId) => parentId);
    const parentResources: Resource[] = parentResourceIds.length ? await DomainObjectUtil.getResourcesByIds(MxtsApi, parentResourceIds, env) : [];
    return parentResources.map(
        (parent: Resource): Group => ({
            groupId: parent.resourceId,
            code: parent.code,
            parentId: undefined,
            imageManagerId: parent.imageManagerId,
            priority: parent.priority,
            questionnaireStatisticsManagerId: parent.questionnaireStatsManagerId,
            resortId: parent.resortId,
            type: GroupType.MARKETING,
            useIn: "all",
            eventManagerId: parent.eventManagerId,
            generalGroups: [],
            name: parent.name,
            description: parent.description,
            shortDescription: parent.shortDescription,
            namePath: parent.namePath,
        })
    );
};

const combineGroupsAndAddOns = ({
    addOns,
    marketingGroups,
    artificialMarketingGroups,
    addOnMarketingGroupIntersections,
}: {
    addOns: AddOn[];
    marketingGroups: Group[];
    artificialMarketingGroups: Group[];
    addOnMarketingGroupIntersections: ResourceMarketingGroup[];
}): MarketingGroupWithAddOns[] => {
    const allMarketingGroupsWithAddOns: MarketingGroupWithAddOns[] = marketingGroups.map((marketingGroup: Group) => {
        const resourceIdsInThisMarketingGroup: number[] = getAddOnResourceIdsForMarketingGroupId({
            addOnMarketingGroupIntersections,
            marketingGroupId: marketingGroup.groupId,
        });

        return {
            marketingGroup,
            addOns: addOns.filter((addOn: AddOn) => resourceIdsInThisMarketingGroup.includes(addOn.resourceId)),
        };
    });

    artificialMarketingGroups.forEach((artificialMarketingGroup: Group) => {
        const resourceIdsInThisMarketingGroup: number[] = getAddOnResourceIdsForMarketingGroupId({
            addOnMarketingGroupIntersections,
            marketingGroupId: artificialMarketingGroup.groupId,
        });
        const addOnsInThisArtificialGroup: AddOn[] = addOns.filter((addOn: AddOn) => resourceIdsInThisMarketingGroup.includes(addOn.resourceId));

        // We want to avoid having different marketing groups with the same name or code, if this is the case, combine them
        const existingMarketingGroupWithAddOns: MarketingGroupWithAddOns | undefined = allMarketingGroupsWithAddOns.find(
            (marketingGroupWithAddOns: MarketingGroupWithAddOns) =>
                artificialMarketingGroup.groupId === marketingGroupWithAddOns.marketingGroup.groupId ||
                StringUtil.equalsIgnoreCase(artificialMarketingGroup.name, marketingGroupWithAddOns.marketingGroup.name) ||
                StringUtil.equalsIgnoreCase(artificialMarketingGroup.code, marketingGroupWithAddOns.marketingGroup.code)
        );

        if (existingMarketingGroupWithAddOns) {
            existingMarketingGroupWithAddOns.addOns = existingMarketingGroupWithAddOns.addOns.concat(addOnsInThisArtificialGroup);
        } else {
            allMarketingGroupsWithAddOns.push({ marketingGroup: artificialMarketingGroup, addOns: addOnsInThisArtificialGroup });
        }
    });

    return allMarketingGroupsWithAddOns;
};

const getAddOnResourceIdsForMarketingGroupId = ({
    addOnMarketingGroupIntersections,
    marketingGroupId,
}: {
    addOnMarketingGroupIntersections: ResourceMarketingGroup[];
    marketingGroupId: number;
}): number[] =>
    addOnMarketingGroupIntersections
        .filter((intersection: ResourceMarketingGroup) => intersection.resourcegroupId === marketingGroupId)
        .map((intersection: ResourceMarketingGroup) => intersection.resourceId);
