import { AffectedAsset, Widget as ApiWidget, ClientCrudService, Locale, ObservableService, Tag, WithId } from "@maxxton/cms-api";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../plugins/mxts/selectOption.types";

import { CMSProvidedProperties } from "../containers/cmsProvider.types";
import { ContextSlice } from "../plugins";
import { CurrentLocale } from "../app.types";
import { I18nLocaleObject } from "../i18n";
import { ISelect } from "../components/generic-form/asyncSelect.types";
import { PermissionKey } from "@maxxton/cms-mxts-api";
import { SelectOption } from "./formSpec.types";
import { SelectOptionLazyLoadResponse } from "../components/generic-form/LazyLoadAutoComplete";
import { Theme } from "../themes";

export * from "./utils";

export type ColumnType = "text" | "checkbox" | "ref" | "anchor" | "date" | "jsx";

export interface ColumnSpec<E, P extends keyof E> {
    name: I18nLocaleObject | string;
    variable: P;
    type: ColumnType;
}

export interface BaseWidgetOptions extends WithId {
    customWidgetId?: string;
}

export interface WidgetOptions<T> {
    [key: string]: T;
}

export interface EditedWidget<T> {
    widgetId: string;
    options: T;
}

export interface RefColumnSpec<E, P extends keyof E> extends ColumnSpec<E, P> {
    type: "ref";
    refSpec: FormSpec<any>;
}

export interface JsxColumnSpec<E, P extends keyof E> extends ColumnSpec<E, P> {
    type: "jsx";
    uniqueId: string;
    useCache?: boolean;
    jsxElement: (item: E) => Promise<JSX.Element>;
    jsxLoadingElement?: (item: E) => JSX.Element;
}

export function isRefColumnSpec<E, P extends keyof E>(col: ColumnSpec<E, P>): col is RefColumnSpec<E, P> {
    return col.type === "ref";
}

export function isJsxColumnSpec<E, P extends keyof E>(col: ColumnSpec<E, P>): col is JsxColumnSpec<E, P> {
    return col.type === "jsx";
}

export type SomeColumnSpec<E, P extends keyof E> = ColumnSpec<E, P> | RefColumnSpec<E, P> | JsxColumnSpec<E, P>;

export interface FormSpec<E> {
    id: string;
    name: I18nLocaleObject | string;
    pluralName: I18nLocaleObject | string;
    properties: Array<SomeInputSpec<E, keyof E>>;
    api?: ClientCrudService<E> & ObservableService;
    display?: (obj: E) => string;
    tableColumns?: Array<SomeColumnSpec<E, keyof E>>;
    preview?: (obj: E, context: CMSProvidedProperties, activeLocale?: Locale & WithId) => Promise<JSX.Element>;
    viewOnSite?: (obj: any) => void;
    login?: (obj: E, e: React.MouseEvent<HTMLInputElement>) => void;
    linkage?: (
        id: string
    ) => Promise<
        Array<{
            affectedModelName: string;
            links: AffectedAsset[];
            specId: string;
        }>
    >;
    permission?: PermissionKey;
}

export type InputSpecType =
    | "paragraph"
    | "icons"
    | "animate"
    | "colorFromSelect"
    | "email"
    | "password"
    | "text"
    | "directText"
    | "inputWithTags"
    | "textarea"
    | "number"
    | "select"
    | "checkbox"
    | "range"
    | "radio"
    | "radioImage"
    | "radioForms"
    | "file"
    | "reference"
    | "richtext"
    | "seo"
    | "hierarchy"
    | "tabbed"
    | "group"
    | "statictabs"
    | "image"
    | "document"
    | "video"
    | "tag"
    | "category"
    | "autocomplete"
    | "lazyLoadAutoComplete"
    | "lazyLoadMultiSelect"
    | "asyncSelect"
    | "multiselect"
    | "anchor"
    | "imagegallery"
    | "map"
    | "breadcrumb"
    | "date"
    | "dateSelect"
    | "datetime"
    | "marker"
    | "button"
    | "color"
    | "dual-color"
    | "theme"
    | "json-selector"
    | "siteGroup"
    | "dynamicData"
    | "creatablegroup"
    | "linkedWidgetsSelect"
    | "conditionalSetGroup";

export interface HierarchyTemplate<E> {
    title: I18nLocaleObject | string;
    description: I18nLocaleObject | string;
    singleton: boolean;
    create: () => E;
    children: Array<HierarchyTemplate<E>>;
}

export interface Item<E> {
    item: E;
}

export interface ItemTitleParams<E> extends Item<E> {
    context: ContextSlice;
}

export interface ItemSubtitleParams<E> extends Item<E> {
    context: ContextSlice;
}

export interface ItemAdditionalInfoParams<E> extends Item<E> {
    context: ContextSlice;
}

export interface ItemOptionsParams<E> extends Item<E> {
    parent: E | null;
    context: ContextSlice;
}

export type ItemContainerParams<E> = Item<E>;

export interface TemplatesParams {
    context: ContextSlice;
    excludeIds?: string[];
}

export interface HierarchyOptions<E> {
    optionsVariable: keyof E;
    childVariable: keyof E;
    rootType: "single" | "multiple";

    templates: ({ context, excludeIds }: TemplatesParams) => Promise<Array<HierarchyTemplate<E>>>;

    rootTitle: I18nLocaleObject | string;
    rootSubtitle?: string;
    templatesTitle: I18nLocaleObject | string;
    templatesSubtitle?: string;

    itemTitle: ({ item, context }: ItemTitleParams<E>) => Promise<I18nLocaleObject | string>;
    itemSubtitle?: ({ item, context }: ItemSubtitleParams<E>) => Promise<I18nLocaleObject | string> | string;
    itemAdditionalInfo?: ({ item, context }: ItemAdditionalInfoParams<E>) => Promise<string> | string;
    itemOptions: ({ item, parent, context }: ItemOptionsParams<E>) => Promise<FormSpec<any>>;
    itemContainer: ({ item }: ItemContainerParams<E>) => boolean;
}

export type SomeInputGroup<E, P extends keyof E> = Array<SomeInputSpec<E, P>>;

export interface GroupOptionSpecs {
    groupName?: string;
    groupTitle?: I18nLocaleObject | string;
    groupDescription?: I18nLocaleObject | string;
}
export type SomeInputSpec<E, P extends keyof E> = GroupOptionSpecs &
    (
        | InputSpecSimple<E, P>
        | InputSpecSelect<E, P>
        | InputSpecColor<E, P>
        | InputSpecRadio<E, P>
        | InputSpecAttachment<E, P>
        | InputSpecRef<E, P>
        | InputSpecHierarchy<E, P>
        | InputSpecRichText<E, P>
        | InputSpecTabbed<any, P, any, any>
        | InputSpecGroup<E, P, any, any>
        | InputSpecTheme<any, P, any>
        | InputSpecStaticTabs<E, P>
        | InputSpecImage<E, P>
        | InputSpecVideo<E, P>
        | InputSpecImageGallery<E, P>
        | InputSpecTag<E, P>
        | InputSpecCategory<E, P>
        | InputSpecAuto<E, P>
        | InputSpecLazyLoadAuto<E, P>
        | InputSpecLazyLoadMulti<E, P>
        | InputSpecLinkedWidgetsSelect<E, P>
        | InputSpecAnchor<E, P>
        | InputSpecMulti<E, P>
        | InputSpecAsyncSelect<E, P>
        | InputSpecMap<E, P>
        | InputSpecBreadcrumb<E, P>
        | InputSpecMarker<E, P>
        | InputSpecDocument<E, P>
        | InputSpecJSONSelector<E, P>
        | InputSpecSiteGroup<E, P>
        | InputSpecCreatableGroup<E, P>
        | InputSpecConditionalSetGroup<E, P>
    );

export interface GroupPropertiesSpec {
    [key: string]: any;
}
export interface InputSpec<E, P extends keyof E, T = any> {
    type: InputSpecType;
    variable?: P;
    visible?: (item: E | FormSpec<E>, locale?: string, options?: any, initialItem?: any) => boolean | Promise<boolean>;
    enabled?: (item: E) => boolean;
    label?: I18nLocaleObject | string;
    image?: string;
    element?: JSX.Element;
    classname?: (item: E | FormSpec<E>) => string;
    required?: boolean;
    placeholder?: I18nLocaleObject | string;
    permission?: PermissionKey;
    characterCounts?: number | null;
    message?: string;
    dynamicData?: StringMultiSelectOption[];
    useAsConfirmPassword?: boolean;
    useAsOldPassword?: boolean;
    passwordVisibility?: boolean;
    enableMaxCharacterLength?: boolean;
    maxCharacterLength?: number;
    tagOptions?: string[];
    inheritFrom?: string;
    options?: WidgetOptions<T>;
    widgetLabel?: I18nLocaleObject;
    buttonLabel?: I18nLocaleObject | string;
    validation?: RegExp;
    enableReadOnly?: boolean;
}

export interface InputPropertySpec<E, P extends keyof E> extends InputSpec<E, P>, GroupOptionSpecs {
    default?: E[P];
}

export interface InputSpecJSONSelector<E, P extends keyof E> extends InputSpec<E, P> {
    type: "json-selector";
    enableMapping?: boolean;
    value?: any;
    loadData?: (options: E, tabLocale?: string) => Promise<any>;
}

export function isInputPropertySpec<E, P extends keyof E>(spec: InputSpec<E, P>): spec is InputPropertySpec<E, P> {
    return typeof (spec as InputPropertySpec<E, P>).variable! === "string";
}

export interface InputSpecSimple<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type:
        | "paragraph"
        | "icons"
        | "animate"
        | "colorFromSelect"
        | "email"
        | "password"
        | "text"
        | "directText"
        | "textarea"
        | "inputWithTags"
        | "number"
        | "select"
        | "checkbox"
        | "radio"
        | "radioImage"
        | "date"
        | "dateSelect"
        | "datetime"
        | "select"
        | "range"
        | "seo"
        | "button"
        | "radioForms"
        | "file"
        | "color"
        | "dual-color"
        | "dynamicData";
    onClick?: (item: E, event?: React.ChangeEvent<HTMLInputElement>) => void;
    alert?: { color: "success" | "info" | "warning" | "danger"; message: string };
    value?: string;
    minimumNumberRange?: number;
    maximumNumberRange?: number;
    minimumNumberLength?: number;
    maximumNumberLength?: number;
    step?: number;
    theme?: (item: E) => string;
    maxDate?: string;
    minDate?: string;
    dynamicData?: StringMultiSelectOption[];
    disableValidation?: boolean;
    useAsConfirmPassword?: boolean;
    useAsOldPassword?: boolean;
    passwordVisibility?: boolean;
    enableMaxCharacterLength?: boolean;
    maxCharacterLength?: number;
    enableReadOnly?: boolean;
}

export interface InputSpecColor<E, P extends keyof E> extends InputSpecSimple<E, P> {
    variable: P;
    type: "color" | "dual-color";
    theme: (item: E) => string;
    useRgbaInsteadOfThemeVariable?: boolean;
}

export interface InputSpecSelect<E, P extends keyof E, T = any> extends InputSpecSimple<E, P> {
    type: "select";
    optionList: Array<SelectOption<E[P]>> | ((item: E, spec: InputPropertySpec<E, P>) => Promise<Array<SelectOption<E[P]>>>);
    options?: WidgetOptions<T>;
    useReactSelect?: boolean;
    priorityCountryList?: Array<SelectOption<number>>;
}

export interface InputSpecRadio<E, P extends keyof E, T = any> extends InputSpecSimple<E, P> {
    type: "radio" | "radioImage" | "radioForms";
    radioOptions: Array<SelectOption<E[P]>>;
    options?: WidgetOptions<T>;
}

export interface InputSpecAttachment<E, P extends keyof E, T = any> extends InputSpecSimple<E, P> {
    type: "file";
    options?: WidgetOptions<T>;
}

export interface InputSpecRef<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "reference";
    refType: FormSpec<any>;
}

export interface InputSpecHierarchy<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "hierarchy";
    hierarchyOptions: HierarchyOptions<any>;
    widgetType?: string;
}

export interface InputSpecRichText<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "richtext";
    style?: (root: any, theme: Theme) => string;
}

// eslint-disable-next-line max-len
export interface InputSpecTabbed<E extends { P: TE[] }, P extends keyof E, TE, TP extends keyof TE> extends InputPropertySpec<E, P>, GroupOptionSpecs {
    type: "tabbed";
    tabVariable: TP;
    visible?: (item: E) => boolean;
    tabContent: Array<SomeInputSpec<TE, keyof TE>>;
    tabs: (item: E) => Promise<Array<Tab<TE[TP]>>>;
}

export interface InputSpecGroup<E, P extends keyof E, TE, TP extends keyof TE> extends InputPropertySpec<E, P> {
    type: "group";
    visible?: (item: E) => boolean;
    specs: Array<SomeInputSpec<TE, TP>>;
}

export interface InputSpecTheme<E extends { P: TE[] }, P extends keyof E, TE> extends InputPropertySpec<E, P> {
    type: "theme";
    content: Array<SomeInputGroup<TE, keyof TE>>;
}

export interface Tab<E> {
    name: I18nLocaleObject | string;
    visible?: (item: E) => boolean;
    value: E;
}

export interface StaticTab<E> {
    name: I18nLocaleObject | string;
    visible?: (item: E, initialItem?: any, parentValues?: ApiWidget) => boolean;
    properties: Array<SomeInputGroup<E, keyof E>>;
    help?: string;
    permission?: PermissionKey;
}

export interface InputSpecStaticTabs<E, P extends keyof E> extends InputSpec<E, P> {
    type: "statictabs";
    visible?: (item: E) => boolean;
    tabs: Array<StaticTab<E>>;
    permission?: PermissionKey;
}

export function isStaticTabsSpec<E, P extends keyof E>(spec: InputSpec<E, P>): spec is InputSpecStaticTabs<E, P> {
    return spec.type === "statictabs";
}

export interface InputSpecImage<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "image";
}

export interface InputSpecDocument<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "document";
}

export interface InputSpecImageGallery<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "imagegallery";
}

export interface InputSpecVideo<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "video";
}

export interface InputSpecBreadcrumb<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "breadcrumb";
}

export interface InputSpecTag<E, P extends keyof E> extends InputSpec<E, P> {
    type: "tag";
    tags: (item?: E) => Tag[];
    suggestions: () => Promise<Tag[]>;
}

export interface InputSpecCategory<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "category";
    refType?: FormSpec<any>;
}

export interface InputSpecAuto<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "autocomplete";
    refType?: FormSpec<any>;
    isClearable?: boolean;
    dependsOnSiteSpec?: P;
    options?: (item: E, spec: InputSpecAuto<E, P>, root?: any, tabLocale?: string, initialItem?: any, currentLocale?: CurrentLocale) => Promise<Array<SelectOption<E[P]>>>;
}

export interface InputSpecLazyLoadAuto<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "lazyLoadAutoComplete";
    refType?: FormSpec<any>;
    lazyLoadOptions: (page: number, searchQuery: string | undefined, id: string | undefined) => Promise<SelectOptionLazyLoadResponse<any, E[P]>>;
}

export interface InputSpecWithTags<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "inputWithTags";
    refType?: FormSpec<any>;
    inheritFrom?: string;
    tagOptions?: string[];
}

export interface InputSpecLazyLoadMulti<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "lazyLoadMultiSelect";
    refType?: FormSpec<any>;
    allItemsSelectOption?: SelectOption<E[P]>;
    lazyLoadOptions: (page: number, searchQuery: string | undefined, ids: string[] | undefined) => Promise<SelectOptionLazyLoadResponse<any, E[P]>>;
}

export interface InputSpecLinkedWidgetsSelect<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "linkedWidgetsSelect";
    refType?: FormSpec<any>;
}

export interface InputSpecAnchor<E, P extends keyof E> extends InputSpec<E, P> {
    type: "anchor";
    url: (item: E) => string;
}

export interface InputSpecMulti<E, P extends keyof E> extends InputSpec<E, P>, InputPropertySpec<E, P>, GroupOptionSpecs {
    type: "multiselect";
    message?: string;
    optionList: ({
        item,
        locale,
        context,
        initialItem,
    }: {
        item?: E;
        locale?: string;
        context: Pick<CMSProvidedProperties, "distributionChannelId" | "site" | "currentLocale">;
        initialItem?: any;
    }) => Promise<StringMultiSelectOption[]> | Promise<NumberMultiSelectOption[]> | StringMultiSelectOption[] | NumberMultiSelectOption[];
}

export interface InputSpecAsyncSelect<E, P extends keyof E> extends InputPropertySpec<E, P> {
    isClearable?: boolean;
    isMulti?: boolean;
    type: "asyncSelect";
    cacheOptions?: boolean;
    optionList?: (item?: E, searchKeyword?: string) => Promise<ISelect[]>;
}

export interface InputSpecMap<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "map";
}

export interface InputSpecMarker<E, P extends keyof E> extends InputPropertySpec<E, P> {
    type: "marker";
}

export interface InputSpecSiteGroup<E, P extends keyof E> extends InputSpec<E, P> {
    type: "siteGroup";
}

export interface InputSpecCreatableGroup<E, P extends keyof E> extends InputSpec<E, P> {
    type: "creatablegroup";
}

export interface InputSpecConditionalSetGroup<E, P extends keyof E> extends InputSpec<E, P> {
    type: "conditionalSetGroup";
}

export interface LocalizedOption<E> {
    [code: string]: E;
}

export * from "./models";
