import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { isLandscapeOrientation, isPortraitOrientation } from "../../../utils/devicewidth.util";

import { BoxModelUtil } from "../../../utils/boxmodel.util";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { getI18nLocaleString } from "../../../i18n";
import { isClientSide } from "../../../utils/generic.util";
import { isEmpty } from "lodash";
import namespacesList from "../../../i18n/namespaceList";
import { useEventListener } from "../../../utils/hooks/useEventListener";

type Moment = moment.Moment;

export interface MaxxtonDatePickerStylingProps {
    context: CMSProviderProperties;
    focusedInput: "startDate" | "endDate" | null;
    startDate?: Moment | null;
    endDate?: Moment | null;
    setEndDate: React.Dispatch<React.SetStateAction<Moment | null>>;
    setStartDate: React.Dispatch<React.SetStateAction<Moment | null>>;
    setOrientation: React.Dispatch<React.SetStateAction<"horizontal" | "vertical">>;
    startDateId?: string;
    endDateId?: string;
    className?: string;
    selectedStartDatePrefix?: string;
    selectedEndDatePrefix?: string;
    options: {
        dateRange?: boolean;
        inLineCalender?: boolean;
        modalPopupCalendar?: boolean;
        closeIcon?: boolean;
        displayBtn?: boolean;
        showArrow?: boolean;
        addIcon?: boolean;
        iconOutside?: boolean;
        iconRight?: boolean;
        iconColor?: string;
        highlightInput?: boolean;
        highlightColor?: string;
        enableVerticalCalendarScrolling?: boolean;
        orientationOfCalendar?: string;
        showStartEndDateLabel?: boolean;
        addAdvancedClearButton?: boolean;
    };
    children: React.ReactNode;
}

export function MaxxtonDateRangePickerStylingWrapper(props: MaxxtonDatePickerStylingProps) {
    const { setOrientation, options, children, focusedInput, startDate, endDate } = props;
    const { highlightColor, highlightInput } = options;
    // const randomKey = generateRandomKey();
    const [isMobile, setIsMobile] = React.useState(isMobileAccordingToOrientation());
    const isVerticalCalendarScrollEnabled = options.enableVerticalCalendarScrolling && (options.orientationOfCalendar === "vertical" || isMobile);

    const onWindowSizeChange = () => {
        const isMobileOrientation = isMobileAccordingToOrientation();
        setIsMobile(isMobileOrientation);
        setOrientation(isMobileOrientation ? "vertical" : "horizontal");
    };
    useEventListener("resize", () => onWindowSizeChange(), isClientSide() ? window : undefined);
    useEventListener("orientationchange", () => onWindowSizeChange(), isClientSide() ? window : undefined);

    React.useEffect(() => {
        setOrientation(isMobile ? "vertical" : "horizontal");
    }, []);

    return (
        <div id="date-picker-date-range" className={getDatePickerClassNames(props)}>
            <div
                className={classNames("datepicker-inner search-filter-box--inner", {
                    "close-icon-added": options.closeIcon,
                    [`box-shadow-${highlightColor}`]: (isEmpty(startDate) || isEmpty(endDate)) && highlightInput && !highlightColor?.includes("rgba"),
                    "calendar-scroll-vertical": isVerticalCalendarScrollEnabled,
                })}
                style={(isEmpty(startDate) || isEmpty(endDate)) && highlightInput && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {}}
            >
                <div className={focusedInput ? "backdrop-popup" : ""}></div>
                {children}
                <CustomStartEndDateLabelsAndClearButtons {...props} />
            </div>
        </div>
    );
}

export function CustomStartEndDateLabelsAndClearButtons(props: MaxxtonDatePickerStylingProps) {
    const { selectedStartDatePrefix, selectedEndDatePrefix, setEndDate, setStartDate, startDate, endDate, startDateId, endDateId, context, options } = props;
    const { showStartEndDateLabel, addAdvancedClearButton } = options;
    const [elementsLoaded, setElementsLoaded] = React.useState(false);

    React.useEffect(() => {
        if (startDateId && endDateId) {
            setElementsLoaded(true);
            const startDatePickerElements = document.getElementsByName(startDateId);
            const endDatePickerElements = document.getElementsByName(endDateId);
            if (addAdvancedClearButton) {
                const startDateCloseButtonElements = document.getElementsByClassName(`remove-startdate-${startDateId}`);
                const endDateCloseButtonElements = document.getElementsByClassName(`remove-enddate-${endDateId}`);
                if (startDatePickerElements && startDateCloseButtonElements) {
                    startDatePickerElements.forEach((item, index) => item.after(startDateCloseButtonElements[index]));
                }
                if (endDatePickerElements && endDateCloseButtonElements) {
                    endDatePickerElements.forEach((item, index) => item.after(endDateCloseButtonElements[index]));
                }
            }

            if (showStartEndDateLabel) {
                const startDateLabelElements = document.getElementsByClassName(`start-date-label-${startDateId}`);
                const endDateLabelElements = document.getElementsByClassName(`end-date-label-${endDateId}`);

                if (startDatePickerElements.length && startDateLabelElements.length) {
                    startDatePickerElements.forEach((item, index) => item.after(startDateLabelElements[index]));
                }
                if (endDatePickerElements.length && endDateLabelElements.length) {
                    endDatePickerElements.forEach((item, index) => item.after(endDateLabelElements[index]));
                }
            }
        }
    }, [elementsLoaded, addAdvancedClearButton, showStartEndDateLabel, endDateId, startDateId]);

    if (!startDateId || !endDateId || !elementsLoaded) {
        return <React.Fragment />;
    }
    return (
        <React.Fragment>
            {addAdvancedClearButton && <FontAwesome name="remove" onClick={() => setStartDate(null)} className={`${!startDate && "hidden"} remove-startdate remove-startdate-${startDateId}`} />}
            {addAdvancedClearButton && <FontAwesome name="remove" onClick={() => setEndDate(null)} className={`${!endDate && "hidden"} remove-enddate remove-enddate-${endDateId}`} />}
            {showStartEndDateLabel && (
                <span className={`start-date-label start-date-label-${startDateId}`}>
                    {selectedStartDatePrefix || getI18nLocaleString(namespacesList.admin, "arrivalLabelAbovePlaceholder", context.currentLocale)}{" "}
                </span>
            )}
            {showStartEndDateLabel && (
                <span className={`end-date-label end-date-label-${endDateId}`}>
                    {" "}
                    {selectedEndDatePrefix || getI18nLocaleString(namespacesList.admin, "departureLabelAbovePlaceholder", context.currentLocale)}{" "}
                </span>
            )}
        </React.Fragment>
    );
}

export function getDatePickerClassNames(maxxtonDatePickerStylingProps: Pick<MaxxtonDatePickerStylingProps, "options" | "className">) {
    const { options, className } = maxxtonDatePickerStylingProps;
    return classNames(
        "date-picker search-filter-box",
        className,
        {
            "display-filter-btn": options.displayBtn,
            "date-range-picker-wrap": options.dateRange,
            "add-icon": options.addIcon,
            "show-arrows": options.showArrow,
            "highlight-color": options.highlightInput,
            "date-picker-inline": options.inLineCalender,
            "date-picker-modal-popup": options.modalPopupCalendar,
        },
        `${
            options.addIcon && options.iconColor && options.iconColor.indexOf("theme") > -1
                ? `color-${options.iconColor}`
                : options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") === -1
                ? "icon-" + options.iconColor
                : ""
        } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"}`
    );
}

function isMobileAccordingToOrientation(): boolean {
    if (isPortraitOrientation()) {
        return true;
    } else if (isLandscapeOrientation()) {
        return false;
    }
    return false;
}
