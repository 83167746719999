import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { getAmenityIcons } from "./mxts";
import { wrapProps } from "../../../i18n";

export interface AmenityBaseProps {
    amenityLinks: MXTS.AmenityLink[];
}

export interface AmenityProps extends AmenityBaseProps {}

export interface AmenityState {
    amenityIcons: AmenityIcons[];
}

export enum IconLibraryId {
    FontAwesome = 1,
    StreamLine = 2,
}

export interface AmenityIcons {
    amenityId: number;
    amenityIcon: string;
}

class AmenityBase extends React.Component<AmenityProps, AmenityState> {
    constructor(props: AmenityProps) {
        super(props);
        this.state = {
            amenityIcons: [],
        };
    }

    componentDidMount() {
        const { amenityLinks } = this.props;
        getAmenityIcons(amenityLinks).then((amenityIcons) => {
            this.setState({ amenityIcons });
        });
    }

    public render(): JSX.Element | null {
        const { amenityLinks } = this.props;
        return (
            <ul className="list-specs card-box-object__specs">
                {amenityLinks.map((amenityLink, index) => (
                    <li key={index} className="list-specs__item">
                        {amenityLink.amenity.iconLibraryId ? (
                            this.getIconsFromLibrary(amenityLink)
                        ) : (
                            <React.Fragment>
                                <i className={`amenity amenity-${amenityLink.amenity.identifier}`} />
                                <span className="map-amenity">
                                    {amenityLink.type === "NUMBER" && amenityLink.numberValue && <span>{`${amenityLink.numberValue} `}</span>}
                                    {amenityLink.amenity.name}
                                </span>
                            </React.Fragment>
                        )}
                    </li>
                ))}
            </ul>
        );
    }

    private getIconsFromLibrary = (amenityLink: MXTS.AmenityLink) => {
        const amenityLinkNumber = amenityLink.type === "NUMBER" && amenityLink.numberValue;
        // Check icon set is from font-awesome library
        if (amenityLink.amenity.iconLibraryId === IconLibraryId.FontAwesome) {
            return (
                <div className="icon-library__font-awesome">
                    <FontAwesome name={amenityLink.amenity.iconName?.split(/-(.*)/)[1] || ""} className="font-awesome__icon" />
                    <span className="map-amenity">
                        {amenityLinkNumber && <span className="map-amenity-number">{amenityLinkNumber}</span>}
                        {amenityLink.amenity.name}
                    </span>
                </div>
            );
            // Check icon set is from stream-line library
        } else if (amenityLink.amenity.iconLibraryId === IconLibraryId.StreamLine) {
            const amenityIcon = this.state.amenityIcons.find((amenity) => amenity.amenityId === amenityLink.amenityId)?.amenityIcon;
            return (
                <div className="icon-library__stream-line">
                    {amenityIcon && <div className="stream-line__svg" dangerouslySetInnerHTML={{ __html: amenityIcon }} />}
                    <span className="map-amenity">
                        {amenityLinkNumber && <span className="map-amenity-number">{amenityLinkNumber}</span>}
                        {amenityLink.amenity.name}
                    </span>
                </div>
            );
        }
        return null;
    };
}

export const Amenity = wrapProps<AmenityBaseProps>(AmenityBase);
