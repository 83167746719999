import { watchCreateReservation, watchGetReservationState, watchLoadReservationReduxData, watchUpdateReservation } from "./reservationSaga";

import { all } from "redux-saga/effects";
import { getAllMyEnvSagas } from "./myEnvSaga";
import { watchBillRecalculation } from "./billSaga";
import { watchFetchAddOns } from "./add-ons/fetchAddOnsSaga";
import { watchGetAvailability } from "./availabilitySaga";
import { watchInstalmentsActions } from "./instalmentsSaga";
import { watchReservationCustomer } from "./reservationCustomerSaga";

export function* rootSaga() {
    yield all([
        watchBillRecalculation(),
        watchCreateReservation(),
        watchFetchAddOns(),
        watchGetAvailability(),
        watchUpdateReservation(),
        watchGetReservationState(),
        watchLoadReservationReduxData(),
        watchReservationCustomer(),
        watchInstalmentsActions(),
        ...getAllMyEnvSagas(),
    ]);
}
