import { AddOn, ApiCallOptions, DynamicFieldInfo, Image, MxtsApi, ResourceType, StockType } from "@maxxton/cms-mxts-api";
import { AddOnInfo, AddOnsWidgetOptions } from "../AddOns.types";
import { DateSubjectSelection, SelectedAddOn } from "../../../../redux/reducers/add-ons/selectedAddOnsReducer";

import { AddOnSubject } from "./info-modal/modal-types/modalData.util";
import { ApiContext } from "../../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../../utils/constants";
import { DateUtil } from "../../../../utils/date.util";
import { DescriptionResult } from "../../../resultsPanel/description/ResultsPanelDescription";
import { NumberMultiSelectOption } from "../../../mxts/selectOption.types";
import { getDynamicFieldCodesAsString } from "./view-styles/components/price/price.util";
import { getImages } from "../../../../components/media/mxts-image-gallery/mxts";

export enum DisplayType {
    DATE_RANGE = "DATE_RANGE",
    DATE_RANGE_SUBJECT = "DATE_RANGE_SUBJECT",
    DATE_SELECT = "DATE_SELECT",
    DATE_SELECT_SUBJECT = "DATE_SELECT_SUBJECT",
    PACKAGE = "PACKAGE",
    SIMPLE = "SIMPLE",
    SUBJECT = "SUBJECT",
    SUPPLIER_ADDON = "SUPPLIER_ADDON",
}

export enum AddOnType {
    DEPOSIT = "deposit",
}

export const fetchAddOnImage = async (apiContext: ApiContext, addOn: AddOn): Promise<Image | undefined> => (await getImages(apiContext, addOn.imageManagerId, undefined, undefined, undefined, 1))?.[0];

export const determineDisplayType = (addOn: AddOn): DisplayType => {
    const isDateSelect = addOn.stockType === StockType.DAY;
    const isDateRange = addOn.datesInternet && addOn.stockType !== StockType.DAY;
    const isSubjectBased = addOn.subjectsInternet;
    const isPackage = addOn.type === ResourceType.COMPOSITION;
    const isSupplierAddOn = (addOn.supplierId && addOn.supplierOrigin) || addOn?.linkedWithSupplier;

    if (isSupplierAddOn) {
        return DisplayType.SUPPLIER_ADDON;
    }

    if (isPackage) {
        return DisplayType.PACKAGE;
    }

    if (isDateSelect && isSubjectBased) {
        return DisplayType.DATE_SELECT_SUBJECT;
    }

    if (isDateRange && isSubjectBased) {
        return DisplayType.DATE_RANGE_SUBJECT;
    }

    if (isDateSelect) {
        return DisplayType.DATE_SELECT;
    }

    if (isDateRange) {
        return DisplayType.DATE_RANGE;
    }

    if (isSubjectBased) {
        return DisplayType.SUBJECT;
    }

    return DisplayType.SIMPLE;
};

/**
 * Checks whether the add-on has a date select functionality.
 * Important note: DATE_RANGE is not a day product in the backend, as it is a "simple" add-on with a start- and end date.
 */
export const isDayProduct = (addOn: SelectedAddOn): boolean => [DisplayType.DATE_SELECT, DisplayType.DATE_SELECT_SUBJECT].includes(addOn.displayType);

export interface DayProductDateSelection {
    date: string;
    memo: string;
    quantity?: number;
    subjects?: Array<{ quantity: number; subjectId: number }>;
}

export const getDateSelectionFromDayProduct = (addOn: SelectedAddOn): DayProductDateSelection[] => {
    if (addOn.displayType === DisplayType.DATE_SELECT) {
        return (
            addOn.dates?.map(
                (date: Date): DayProductDateSelection => ({
                    date: DateUtil.formatDate(date, DATE_FORMAT.MXTS),
                    memo: "",
                    quantity: addOn.quantity,
                })
            ) || []
        );
    }

    if (addOn.displayType === DisplayType.DATE_SELECT_SUBJECT) {
        return (
            addOn.dateSubjectSelections?.map(
                (dateSubjectSelection: DateSubjectSelection): DayProductDateSelection => ({
                    date: DateUtil.formatDate(dateSubjectSelection.date, DATE_FORMAT.MXTS),
                    memo: "",
                    subjects: dateSubjectSelection.subjects.map((subject: AddOnSubject) => ({
                        quantity: subject.quantity,
                        subjectId: subject.subjectId,
                    })),
                })
            ) || []
        );
    }

    return [];
};

export const clearAddOnSelection = (addOn: SelectedAddOn): SelectedAddOn => {
    switch (addOn.displayType) {
        case DisplayType.DATE_SELECT_SUBJECT:
            return { ...addOn, dateSubjectSelections: [] };
        case DisplayType.DATE_RANGE:
        case DisplayType.SUPPLIER_ADDON:
        case DisplayType.DATE_SELECT:
            return { ...addOn, dates: [], quantity: 0 };
        default:
            return { ...addOn, quantity: 0 };
    }
};

/**
 * Determines the newQuantity, taking into account the currentQuantity and the minQuantity.
 * If the minQuantity is greater than 1 and the newQuantity is an increase, but still lower than the minQuantity, it immediately jumps to the minQuantity.
 * If the minQuantity is greater than 1 and the newQuantity is a decrease and lower than the minQuantity, then it immediately jumpst to 0.
 */
export const determineNewQuantityBasedOnMinQuantity = ({ newQuantity, currentQuantity, minQuantity }: { newQuantity: number; currentQuantity: number; minQuantity: number }): number => {
    if (newQuantity > currentQuantity && newQuantity < minQuantity) {
        newQuantity = minQuantity;
    }

    if (!newQuantity || (newQuantity < currentQuantity && newQuantity < minQuantity)) {
        newQuantity = 0;
    }

    return newQuantity;
};

export const fetchDynamicFieldCodeData = async (
    dynamicFieldOption: NumberMultiSelectOption[] | undefined,
    addOn: AddOn,
    env: ApiCallOptions,
    widgetOptionsId: string,
    context: ApiContext,
    fallBack: boolean
): Promise<DynamicFieldInfo | Image | null | undefined> => {
    const mxtsApi = context?.mxtsApi || MxtsApi;
    const managerId = addOn?.dynamicManagerId;
    try {
        if (env && managerId) {
            const code = getDynamicFieldCodesAsString(dynamicFieldOption);
            const widgetOptionsDynamicFieldCodesPaths: Array<keyof AddOnsWidgetOptions> = ["dynamicFieldFallback", "dynamicFields", "selectedAddonTypesDynamicFields"];
            let dynamicFieldResponse: DynamicFieldInfo[] = [];
            if (code) {
                dynamicFieldResponse = await mxtsApi.dynamicFieldsInfoCustomized(env, {
                    managerId,
                    code,
                    checkFallback: fallBack,
                    widgetOptionsId,
                    widgetOptionsDynamicFieldCodesPaths,
                });
            }
            if (dynamicFieldResponse.length) {
                const { imageManagerId, name } = dynamicFieldResponse[0];
                if (imageManagerId) {
                    const imageResponse = await getImages(context, imageManagerId);
                    return { ...imageResponse[0], code, name };
                }
            }
            return dynamicFieldResponse[0];
        }
        return null;
    } catch (error) {
        if (!(error instanceof DOMException && error.code === DOMException.ABORT_ERR)) {
            context.logger.error(error);
        }
    }
};

export const mapFallbackDescriptions = (addOn: AddOn, context?: ApiContext) => (descriptionType: NumberMultiSelectOption): DescriptionResult | null => {
    switch (descriptionType.value.toString()) {
        case AddOnInfo.NAME: {
            return {
                value: addOn.name,
                type: AddOnInfo.NAME,
            };
        }
        case AddOnInfo.DESCRIPTION: {
            return {
                value: addOn.description,
                type: AddOnInfo.DESCRIPTION,
            };
        }
        case AddOnInfo.DESCRIPTION2: {
            return {
                value: addOn.i18n?.[0]?.description2!,
                type: AddOnInfo.DESCRIPTION2,
            };
        }
        case AddOnInfo.SHORT_DESCRIPTION: {
            return {
                value: addOn.shortDescription!,
                type: AddOnInfo.SHORT_DESCRIPTION,
            };
        }
    }
    return null;
};
