// eslint-disable

// This is a dependency file, hence not to be changed.
import * as React from "react";

import * as classNames from "classnames";

import { SmartLink } from "../../SmartLink";

class Value extends React.Component<any, any> {
    dragging: any;
    static propTypes: {
        children: any;
        disabled: any; // disabled prop passed to ReactSelect
        id: any; // Unique id for the value - used for aria
        onClick: any; // method to handle click on value label
        onRemove: any; // method to handle removal of the value
        value: any;
    };

    constructor(props: any) {
        super(props);

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.handleTouchEndRemove = this.handleTouchEndRemove.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
    }

    handleMouseDown(event: any) {
        if (event.type === "mousedown" && event.button !== 0) {
            return;
        }
        if (this.props.onClick) {
            event.stopPropagation();
            this.props.onClick(this.props.value, event);
            return;
        }
        if (this.props.value.href) {
            event.stopPropagation();
        }
    }

    onRemove(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.props.onRemove(this.props.value);
    }

    handleTouchEndRemove(event: any) {
        // Check if the view is being dragged, In this case
        // we don't want to fire the click event (because the user only wants to scroll)
        if (this.dragging) {
            return;
        }

        // Fire the mouse events
        this.onRemove(event);
    }

    handleTouchMove(event: any) {
        // Set a flag that the view is being dragged
        this.dragging = true;
    }

    handleTouchStart(event: any) {
        // Set a flag that the view is not being dragged
        this.dragging = false;
    }

    renderRemoveIcon() {
        if (this.props.disabled || !this.props.onRemove) {
            return;
        }
        return (
            <span
                className="Select-value-icon"
                aria-hidden="true"
                onMouseDown={this.onRemove}
                onTouchEnd={this.handleTouchEndRemove}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
            >
                &times;
            </span>
        );
    }

    renderLabel() {
        const className = "Select-value-label";
        return this.props.onClick || this.props.value.href ? (
            <SmartLink className={className} href={this.props.value.href} target={this.props.value.target} onMouseDown={this.handleMouseDown} onTouchEnd={this.handleMouseDown}>
                {this.props.children}
            </SmartLink>
        ) : (
            <span className={className} role="option" aria-selected="true" id={this.props.id}>
                {this.props.children}
            </span>
        );
    }

    render() {
        return (
            <div className={classNames("Select-value", this.props.value.className)} style={this.props.value.style} title={this.props.value.title}>
                {this.renderLabel()}
                {this.renderRemoveIcon()}
            </div>
        );
    }
}

export default Value;
