import { Flow, FlowApi } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { FormSpec } from "../";
import { dataLayerSpec } from "./dataLayer";
import { findAffectedSites } from "../utils";
import namespaceList from "../../i18n/namespaceList";
import { widgetHierarchy } from "./";

export const flowSpec: FormSpec<Flow> = {
    id: "flow",
    name: getI18nLocaleObject(namespaceList.admin, "flow"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "flows"),
    api: FlowApi,
    permission: "webmanager.flows",
    display(flow: Flow): string {
        return flow.name;
    },
    async linkage(id: string) {
        const affectedSites = await findAffectedSites(id, "flow");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedSites"),
                links: affectedSites,
                specId: "site",
            },
        ];
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
    ],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "selectDataLayer"),
                                variable: "dataLayerId",
                                type: "autocomplete",
                                refType: dataLayerSpec,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "autoAssignUnitToReservation"),
                                variable: "autoAssignUnitToReservation",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "sortUnitsOnVSI"),
                                variable: "sortUnitsOnVSI",
                                type: "checkbox",
                                visible: (flow: Flow) => !!flow.autoAssignUnitToReservation,
                            },
                            widgetHierarchy<Flow, "root">({
                                label: getI18nLocaleObject(namespaceList.admin, "content"),
                                variable: "root",
                                rootTitle: getI18nLocaleObject(namespaceList.admin, "onFlow"),
                                templatesTitle: getI18nLocaleObject(namespaceList.admin, "availableFlowComponents"),
                                widgetType: "flow",
                                rootType: "multiple",
                            }),
                        ],
                    ],
                },
            ],
        },
    ],
};
