// Be careful while sorting imports of this file as it breaks the build
/* eslint-disable sort-imports */
import * as React from "react";
import "react-dates/initialize";

import App from "./components/App";

import { globalApiContext } from "./containers/CmsProvider";

import { PathCache, loadPath, loadSiteRoutes } from "./routing";
import { PathComponent, Route } from "./routing/routing.types";
import { maintenanceRoute, notFoundRoute, staticRoutes } from "./routing/static";

import * as Api from "@maxxton/cms-api";

import { Environment, getEnvironment, getMxtsEnv } from "./plugins/mxts";

import { Alerts } from "./alerts";

import * as StackTraceGPS from "stacktrace-gps";

import { MxtsDataAccessToken, getMxtsDataAccessTokenManager, MxtsApi } from "@maxxton/cms-mxts-api";

import { State, getStore, ReduxStore } from "./redux";

import { Store } from "redux";

import { MxtsDataAccesTokenAction, MxtsDataAccesTokenActionType } from "./redux/actions/MxtsDataAccessTokenAction";
import { ActionType } from "./redux/actions/index";
import { isClientSide, isServerSide } from "./utils/generic.util";
import { ReduxPersistenceUtil } from "./redux/persistence/ReduxPersistenceUtil";
import { parse } from "query-string";
import { imitateCustomer } from "./utils/authToken.util";
import { BrowserLocation, PrefetchAppParams } from "./app.types";
import { CMSProvidedProperties, CMSProviderProperties } from "./containers/cmsProvider.types";
import { fetchCurrencyForDC } from "./utils/currency.util";
import { getNoDataFoundContent, getNoDataFoundTemplate } from "./components/utils";
import { renderNoResultsFoundContent } from "./plugins/dynamic/containerWidget.util";
import { setLocaleFallbackConfig } from "./utils/locale.util";
import { matchRoutes } from "react-router-dom";

function objFromEntries<T>(entries: Array<{ key: string; value: T }>): { [index: string]: T } {
    return entries
        .filter((entry) => !!entry)
        .reduce((p: any, c: { key: string; value: T }) => {
            p[c.key] = c.value;
            return p;
        }, {});
}

async function loadRoutes(context: CMSProvidedProperties, device?: PrefetchAppParams["device"]) {
    if (!context.site) {
        // no site configured. Use only static routes
        return staticRoutes.concat([notFoundRoute]);
    }
    const matchedRoute = matchRoutes(staticRoutes, context.location);
    let siteRoutes: Route[] = [];
    if (!matchedRoute && !context.location.pathname?.startsWith("/webmanager")) {
        siteRoutes = await loadSiteRoutes(context, device);
    }
    return staticRoutes.concat(...siteRoutes, [notFoundRoute, maintenanceRoute]); // flatten widget routes and add static routes
}

export function isAuthorized(env: Environment | null): boolean {
    return Api.options().token != null || env == null;
}

export function getCurrentLocaleCode(locale: (Api.Locale & Api.WithId) | null, site: Api.Site & Api.WithId, url: string, paramLocale: any, validLocaleIds?: string[]): string {
    // If the locale in the url is a valid locale for the current site
    let validSiteLocaleCode = "";
    if (validLocaleIds && validLocaleIds.length > 0 && paramLocale && url.indexOf("/webmanager") === -1) {
        if (validLocaleIds.indexOf(paramLocale._id) === -1) {
            validSiteLocaleCode = site.locale.code;
        }
    }

    if (locale !== null && url?.indexOf("/webmanager") > -1) {
        return locale.code;
    }

    if (paramLocale && !validSiteLocaleCode) {
        return paramLocale.code;
    }

    if (site) {
        return site.locale.code;
    }

    return "en";
}

export function getCurrentLocaleId(locale: (Api.Locale & Api.WithId) | null, site: Api.Site & Api.WithId): string {
    return locale !== null ? locale._id : site ? site.locale._id : "-1";
}

export interface PrefetchedApp {
    app: JSX.Element;
    store: Store<State>;
}

export function initMxtsDataAccessTokenManager(reduxStore: ReduxStore) {
    getMxtsDataAccessTokenManager()?.setTokenState({ ...reduxStore.store.getState().mxtsDataAccessTokenState });
    getMxtsDataAccessTokenManager()?.setOnTokenChangeCb((token: MxtsDataAccessToken) => {
        const mxtsDataAccessTokenUpdateAction: MxtsDataAccesTokenAction = {
            type: ActionType.MxtsDataAccessToken,
            actionType: MxtsDataAccesTokenActionType.update,
            payload: token,
        };
        reduxStore.store.dispatch(mxtsDataAccessTokenUpdateAction);
    });
}

export async function handleCustomerImitateUrlParams(location: BrowserLocation): Promise<void> {
    if (isClientSide()) {
        const options = parse(location.search);
        if (options.imitateCustomerId) {
            await imitateCustomer(options.imitateCustomerId, await getMxtsEnv(globalApiContext()), globalApiContext().mxtsApi);
        }
    }
}

// eslint-disable-next-line max-lines-per-function
export async function prefetchApp(params: PrefetchAppParams): Promise<PrefetchedApp | CMSProvidedProperties> {
    const {
        site,
        theme,
        location,
        onTitle,
        onMetaTitle,
        onMetaKeywords,
        onMetaDescription,
        onMetaRobots,
        onImageStructured,
        onTitleStructured,
        onUrlStructured,
        onDescriptionStructured,
        onAuthorStructured,
        onStructuredData,
        onStructuredDataMarkup,
        onEnableRedirect,
        onRedirect,
        onDisableCanonicalUrl,
        currentLocale,
        flow,
        distributionChannelId,
        test,
        device,
        rateTypes,
        reservationCategoryId,
        paths,
        isolatedCacheApi,
        isolatedCacheMxtsApi,
        logger,
        customPath,
        cacheStrategy,
        dataLayerSettings,
        chunkExtractor,
    } = params;

    setLocaleFallbackConfig(site, currentLocale);

    const apiContext = {
        cmsApi: isolatedCacheApi || Api.CmsApi,
        mxtsApi: isolatedCacheMxtsApi || MxtsApi,
        logger: logger || Api.globalLogger,
    };
    const reduxStore = await getStore(location, currentLocale, site, apiContext, distributionChannelId, reservationCategoryId, flow);
    const currency = await fetchCurrencyForDC(apiContext, Number(distributionChannelId || reduxStore.initialState.dynamicFilter.distributionChannel?.distributionChannelId));

    const context: CMSProviderProperties = {
        site,
        theme,
        onTitle,
        onMetaTitle,
        onMetaKeywords,
        onMetaDescription,
        onMetaRobots,
        onImageStructured,
        onTitleStructured,
        onUrlStructured,
        onDescriptionStructured,
        onAuthorStructured,
        onStructuredData,
        onStructuredDataMarkup,
        onEnableRedirect,
        onRedirect,
        onDisableCanonicalUrl,
        location,
        currentLocale,
        alerts: new Alerts(),
        reservationCategoryId,
        flow,
        distributionChannelId: reduxStore.initialState.dynamicFilter.distributionChannel?.distributionChannelId,
        rateTypes: rateTypes!,
        isAdmin: !!location?.href?.includes("webmanager"),
        ...apiContext,
        customPath,
        device,
        reduxStore,
        cacheStrategy,
        dataLayerSettings,
        currency,
        chunkExtractor,
    };
    if (site.useDCGroup && site.useDistributionChannelType && isServerSide()) {
        return context;
    }
    if (!test || context.customPath !== "") {
        if (isClientSide() && reduxStore.persistor) {
            ReduxPersistenceUtil.doAfterReduxStateHydrated(reduxStore.persistor, () => initMxtsDataAccessTokenManager(reduxStore));
        }
        await handleCustomerImitateUrlParams(location);

        const actualPath = customPath ? [customPath] : paths;
        const [routes, env] = await Promise.all([loadRoutes(context, device), getEnvironment(Api.CmsApi)]);
        const loadTask = actualPath.map((path) => loadPath(routes!, decodeURI(path), isAuthorized(env), context));
        const prepared = (await Promise.all(loadTask)) as Array<{ key: string; value: PathComponent }>;
        const cache: PathCache = objFromEntries(prepared);
        let childComponent = null;
        const requestedPath = customPath !== "" ? customPath : (location as any).pathname;
        const entry = await loadPath(routes, requestedPath, isAuthorized(env), context);
        if (entry) {
            childComponent = entry.value;
        }
        const appComponent = <App context={context} prepared={cache} routes={routes!} env={env} childComponent={childComponent} />;
        return {
            app: appComponent,
            store: reduxStore.store,
        };
    }
    return context;
}

const gps = new StackTraceGPS();

type Nullable<E> = {
    [P in keyof E]: E[P] | null;
};
