import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CurrentLocale } from "../../../app.types";
import { DocumentType } from "@maxxton/cms-mxts-api";
import { StringMultiSelectOption } from "../../mxts/selectOption.types";
import { getCMSOptions } from "../../settings";
import { globalApiContext } from "../../../containers/CmsProvider";

export const getFallbackLocaleIds = (currentLocale: CurrentLocale) => {
    const { fallbackLocaleMultiSelect: currentLocaleFallbackLocaleMultiSelect } = currentLocale;
    return currentLocaleFallbackLocaleMultiSelect?.map((option: StringMultiSelectOption) => option.value) || [];
};

export const getSelectedDocumentUrl = async (context?: CMSProviderProperties, selectedDocument?: DocumentType | null) => {
    const cmsOptions = await getCMSOptions((context || globalApiContext()).cmsApi);
    const currentEnv = cmsOptions.env.includes("dev") ? "dev" : cmsOptions.env.includes("test") ? "test" : "prod";
    const documentUrlHost = currentEnv === "prod" ? "https://docs.maxxton.net/" : `https://docs-${currentEnv}.maxxton.net/`;
    return selectedDocument ? `${documentUrlHost}${selectedDocument.gcUri}` : "";
};
