import * as React from "react";
import * as classNames from "classnames";

import { ActivityAreaResources } from "@maxxton/cms-mxts-api";
import { DynamicFieldLink } from "../../../utils/withDynamicField";
import { SmartLink } from "../../../components/SmartLink";

export interface ActivityLocationProps {
    activityAreaResources: ActivityAreaResources[];
    className?: string;
    style?: React.CSSProperties;
    locationKey?: string;
    dynamicFieldLink?: DynamicFieldLink;
}

export const ActivityLocation = (props: ActivityLocationProps) => {
    const { activityAreaResources, className, locationKey, style, dynamicFieldLink } = props;
    return (
        <React.Fragment>
            {activityAreaResources?.map((activityAreaResource, index) => (
                <div className={classNames("location activity-item", className)} style={style} key={index}>
                    {!dynamicFieldLink?.url ? (
                        <React.Fragment>
                            {locationKey && <span className="location-label">{locationKey}</span>}
                            <span className="location-value">{activityAreaResource?.areaName}</span>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <SmartLink key={index} href={dynamicFieldLink?.url} target={dynamicFieldLink?.target}>
                                {locationKey && <span className="location-label">{locationKey}</span>}
                                <span className="location-value">{activityAreaResource?.areaName}</span>
                            </SmartLink>
                        </React.Fragment>
                    )}
                </div>
            ))}
        </React.Fragment>
    );
};
