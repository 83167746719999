import { DataLayer, DataLayerApi } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { FormSpec } from "../";
import { dataLayerContent } from "../../utils/datalayer.constants";
import { findAffectedSites } from "../utils";
import namespaceList from "../../i18n/namespaceList";

export const dataLayerSpec: FormSpec<DataLayer> = {
    id: "datalayer",
    name: getI18nLocaleObject(namespaceList.admin, "datalayer"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "datalayers"),
    api: DataLayerApi,
    permission: "webmanager.datalayers",
    display(datalayer: DataLayer): string {
        return datalayer.name;
    },
    async linkage(id: string) {
        const affectedSites = await findAffectedSites(id, "datalayer");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedSites"),
                links: affectedSites,
                specId: "site",
            },
        ];
    },
    tableColumns: [
        { name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "creationDate"),
            variable: "createdAt",
            type: "date",
        },
        {
            name: getI18nLocaleObject(namespaceList.genericCrud, "updationDate"),
            variable: "updatedAt",
            type: "date",
        },
    ],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            {
                                variable: "dataLayerContent",
                                type: "multiselect",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "contentType"),
                                optionList: () => dataLayerContent.map((content) => ({ value: content, text: content })),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "transactionProducts"),
                                variable: "transactionProducts",
                                type: "checkbox",
                                visible: (options: DataLayer) => !!(options.dataLayerContent && options.dataLayerContent.length),
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enableEec"),
                                variable: "enableEec",
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enableGA4DataLayer"),
                                variable: "enableGA4DataLayer",
                                type: "checkbox",
                                visible: (options: DataLayer) => !!options.enableEec,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enabledEecMsg"),
                                type: "paragraph",
                                visible: (options: any) => options.enableEec,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "enableCustomFields"),
                                variable: "customFields",
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: "Configuration Fields",
                    visible: (options) => !!(options.dataLayerContent && options.dataLayerContent.length),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "overRideDataLayerFields"),
                            },
                            ...dataLayerContent.map((content): any => ({
                                label: content,
                                variable: content,
                                type: "text",
                                visible: (item: DataLayer) => !!item.dataLayerContent && item.dataLayerContent.some((option) => option.value === content),
                            })),
                        ],
                    ],
                },
                {
                    name: "Custom Fields",
                    visible: (options) => options.customFields,
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};
