import { AvailabilityAction, AvailabilityActionType } from "../actions/availabilityAction";

import { ActionType } from "../actions";
import { AvailabilityState } from "./availability.types";
import { Reducer } from "redux";

export const availabilityReducer: Reducer<AvailabilityState> = (state: AvailabilityState = {}, action: AvailabilityAction): AvailabilityState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.Availability:
            switch (actionType) {
                case AvailabilityActionType.fetching:
                    return { ...state, availabilityResult: undefined, fetching: true };
                case AvailabilityActionType.fetched:
                case AvailabilityActionType.error:
                    return { ...state, ...action.payload, fetching: false };
                default:
                    return state;
            }
        default:
            return state;
    }
};
