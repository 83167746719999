import * as React from "react";

import { AsyncPaginate } from "react-select-async-paginate";
import { CMSProviderProperties } from "../../../../containers/cmsProvider.types";
import { Site } from "@maxxton/cms-api";
import { getI18nLocaleString } from "../../../../i18n/index";
import namespaceList from "../../../../i18n/namespaceList";

export interface ReactSelectOption<T> {
    value: string;
    label: string;
    obj: T;
}

export const SiteMultiSelect = (props: {
    context: CMSProviderProperties;
    sites: Array<ReactSelectOption<Site>>;
    onSitesChange: React.Dispatch<React.SetStateAction<Array<ReactSelectOption<Site>>>>;
}): JSX.Element => {
    const { context, sites, onSitesChange } = props;

    const loadOptions = React.useCallback(
        async (searchQuery: string, loadedOptions: unknown, { page }: { page: number }) => {
            const sites = await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
            return {
                options: sites.map((site) => ({ value: site._id, label: `${site.name} (${site.host})`, obj: site })),
                hasMore: false,
                additional: {
                    page: page + 1,
                },
            };
        },
        [context.cmsApi.siteApi]
    );

    return (
        <AsyncPaginate
            value={sites}
            loadOptions={loadOptions}
            isMulti
            closeMenuOnSelect={false}
            onChange={onSitesChange}
            placeholder={getI18nLocaleString(namespaceList.admin, "selectSites")}
            additional={{
                page: 0,
            }}
        />
    );
};
