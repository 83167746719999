import { Locale, LocaleApi, MultiSelectOptionStringValue, Site, SiteApi, WithId } from "@maxxton/cms-api";
import { MxtsApi, ReservationCategory, getAll } from "@maxxton/cms-mxts-api";
import { convertToNumberArray, distributionChannelOptions, getAdminMxtsEnv, getMxtsEnv } from "../plugins/mxts";

import { MXTS } from "./constants";
import { WidgetOptions as ReservationContainerWidgetOptions } from "../plugins/dynamic/reservation/container";
import { WidgetOptions as ReservationPickerWidgetOptions } from "../plugins/dynamic/reservation/picker";
import { chunk } from "lodash";
import { getCMSOptions } from "../plugins/settings";
import { getCurrentLocaleId } from "../app";
import { getLocaleCodeFromCMSOptions } from "./widget.util";
import { globalApiContext } from "../containers/CmsProvider";
import { isMobileDeviceDetected } from "./localizedContent.util";

export const getReservationCategorySelectOptions = async (item: any, tabLocale?: string) => {
    const reservationCategories = await fetchReservationCategories(item, tabLocale);
    return reservationCategories
        ? reservationCategories.map((category) => ({
              value: category.reservationCategoryId,
              label: `${(category.name || "") + " | " + (category.reservationCategoryId || "")}`,
          }))
        : [];
};

export const getReservationCategoryMultiSelectOptions = async (item?: any, tabLocale?: string) => {
    let reservationCategories;
    if (item?.distributionChannelIds?.length) {
        const dcIds = await filterExcludedDistributionChannelIds(item);
        reservationCategories = await fetchMultipleDcReservationCategories(dcIds);
    } else {
        reservationCategories = await fetchReservationCategories(item, tabLocale);
    }
    return reservationCategories
        ? reservationCategories.map((category) => ({
              value: category.reservationCategoryId,
              text: `${(category.name || "") + " | " + (category.reservationCategoryId || "")}`,
          }))
        : [];
};

export const getReservationCategoriesFromDc = async () => {
    const env = await getAdminMxtsEnv();
    const distributionChannelId = await getDcIdFromSettings();
    const reservationCategoriesArray = distributionChannelId ? await MxtsApi.getDcReservationCategories(env, {}, [{ key: "dcId", value: distributionChannelId }]) : undefined;
    const reservationCategoryIds = reservationCategoriesArray?.content.map((category) => category.reservationCategoryId);
    const reservationCategories = reservationCategoryIds && (await MxtsApi.getReservationCategories(env, { size: reservationCategoryIds.length, reservationCategoryIds }))?.content;
    return reservationCategories?.length
        ? reservationCategories.map((category) => ({
              value: category.reservationCategoryId,
              label: `${(category.name || "") + " | " + (category.reservationCategoryId || "")}`,
          }))
        : [];
};

export const getDcIdFromSettings = async () => {
    const currentSite = (window as typeof window & { currentSite?: Site & WithId }).currentSite;
    const homepageUrl = new URL((window as any).pageUrl);
    const locationHostname = homepageUrl.host;
    const site = currentSite && currentSite.host === locationHostname ? currentSite : await SiteApi.findByHost({ host: locationHostname });
    const cmsOptions = await getCMSOptions(globalApiContext().cmsApi);
    const locale: (Locale & WithId) | null = await LocaleApi.findByCode({
        code: getLocaleCodeFromCMSOptions(cmsOptions),
    });
    const localizedSiteOptions = site?.localizedOptions?.find((lo) => lo.locale === getCurrentLocaleId(locale, site));
    let distributionChannelId = "";
    if (localizedSiteOptions?.mobileDCId && isMobileDeviceDetected() && site?.useMobileDC) {
        distributionChannelId = localizedSiteOptions.mobileDCId;
    } else if (localizedSiteOptions?.distributionChannelId) {
        distributionChannelId = localizedSiteOptions.distributionChannelId;
    } else {
        distributionChannelId = cmsOptions.distributionChannelId || "";
    }
    return distributionChannelId;
};

const fetchReservationCategories = async (item: any, tabLocale?: string) => {
    const env = await getMxtsEnv(globalApiContext());
    const localeContent = tabLocale ? item?.find((i: any) => i.locale === tabLocale) : item;
    const distributionChannelId = localeContent?.distributionChannelId;
    const reservationCategoriesArray = distributionChannelId ? await MxtsApi.getDcReservationCategories(env, {}, [{ key: "dcId", value: distributionChannelId }]) : undefined;
    const reservationCategoryIds = reservationCategoriesArray?.content.map((category) => category.reservationCategoryId);
    let reservationCategories: ReservationCategory[];
    if (reservationCategoryIds?.length) {
        reservationCategories = (await MxtsApi.getReservationCategories(env, { size: reservationCategoryIds?.length, reservationCategoryIds }))?.content;
    } else {
        reservationCategories = (await getAll((page: number) => MxtsApi.getReservationCategories(env, { page, size: 20 })))?.content || [];
    }
    return reservationCategories || [];
};

export const filterExcludedDistributionChannelIds = async (widgetOptions: ReservationContainerWidgetOptions | ReservationPickerWidgetOptions) => {
    let distributionChannelIds =
        widgetOptions?.distributionChannelIds?.reduce((acc: string[], dc: MultiSelectOptionStringValue | string) => {
            if (typeof dc === "string") {
                acc.push(dc);
            } else if (dc.value) {
                acc.push(dc.value);
            }
            return acc;
        }, []) ?? [];
    if (distributionChannelIds.includes("all")) {
        const allDistributionChannelIds = (await distributionChannelOptions(MxtsApi))?.map((dc) => dc.value);
        distributionChannelIds = allDistributionChannelIds ?? [];
    }
    const disallowedDistributionChannelIds =
        widgetOptions?.disallowedDistributionChannelIds?.reduce((acc: string[], dc: MultiSelectOptionStringValue | string) => {
            if (typeof dc === "string") {
                acc.push(dc);
            } else if (dc.value) {
                acc.push(dc.value);
            }
            return acc;
        }, []) ?? [];

    const dcIds = distributionChannelIds.filter((id: string) => !disallowedDistributionChannelIds.includes(id));
    return dcIds;
};

const fetchMultipleDcReservationCategories = async (dcIds: string[]) => {
    const env = await getMxtsEnv(globalApiContext());
    const ids = convertToNumberArray(dcIds);
    const dcIdChunks: number[][] = chunk(ids, 100);
    const reservationCategoriesArray: ReservationCategory[] = [];
    const reservationCategories: ReservationCategory[] = [];
    const allObtainedDcIdChunks = await Promise.all(
        dcIdChunks.map((ids) => MxtsApi.getReservationCategories(env, { size: MXTS.MAX_RESULTS, distributionChannelIds: ids?.length ? ids : undefined }, undefined))
    );
    allObtainedDcIdChunks.map((category) => {
        reservationCategoriesArray.push(...category?.content);
    });
    const reservationCategoryIds = reservationCategoriesArray?.map((category: any) => category.reservationCategoryId);
    const rcIdChunks: number[][] = chunk(reservationCategoryIds, 100);
    const allObtainedRcIdChunks = await Promise.all(
        rcIdChunks.map((reservationCategoryIds) =>
            MxtsApi.getReservationCategories(env, { size: MXTS.MAX_RESULTS, reservationCategoryIds: reservationCategoryIds?.length ? reservationCategoryIds : undefined }, undefined)
        )
    );
    allObtainedRcIdChunks.map((category) => {
        reservationCategories.push(...category?.content);
    });
    return reservationCategoriesArray ? reservationCategories : [];
};
