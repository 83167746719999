import { Theme } from "./";
import baseTheme from "./base";

const theme: Theme = {
    id: "colorfulrentals",
    name: "Colorful Rentals Theme",
    stylesheets: ["/styles/themes/colorfulrentals/colorfulrentals-admin.css"],
    frontendStylesheets: ["/styles/themes/colorfulrentals/colorfulrentals.css"],
    styles: [...baseTheme.styles],
};

export default theme;
