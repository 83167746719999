import * as React from "react";

import { CMSProvidedProperties, CMSProviderProperties } from "../../../../containers/cmsProvider.types";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { isServerSide } from "../../../../utils/generic.util";

export async function getReCaptchaSiteKey(context: CMSProviderProperties): Promise<string | undefined> {
    if (isServerSide()) {
        return undefined;
    }
    const hostName = window.location.href.split("/");
    const siteData = await context.cmsApi.siteApi.findByHost({ host: hostName[2], projection: { sitemap: 0 } });
    return siteData?.reCaptchaSiteKey;
}

/**
 * By default the reCaptchaProvider is disabled as you can see in the AppWideRecapthaProvider and SiteRecapthaProvider.
 * By using this hook you enable the reCaptchaProvider which allows you to use reCaptcha. It will provide components with the ability to use:
 *    const { executeRecaptcha } = useGoogleReCaptcha();
 * And
 *    googleReCaptchaProps?: IWithGoogleReCaptchaProps;
 */
export function RecaptchaProviderEnabler(props: { children: React.ReactElement }) {
    const { children } = props;
    const { isReCaptchaEnabled, setReCaptchaEnabled } = React.useContext(ReCaptchaContext);
    if (!isReCaptchaEnabled) {
        setReCaptchaEnabled(true);
    }
    return children;
}

export function SiteRecaptchaProvider(props: { context: CMSProvidedProperties; children: React.ReactElement }) {
    const { context, children } = props;
    const [reCaptchaKey, setReCaptchaKey] = React.useState<string>();
    const { isReCaptchaEnabled } = React.useContext(ReCaptchaContext);

    React.useEffect(() => {
        (async () => {
            if (isReCaptchaEnabled) {
                const reCaptchaSiteKey = await getReCaptchaSiteKey(context);
                setReCaptchaKey(reCaptchaSiteKey);
                if (!reCaptchaSiteKey) {
                    console.error("ReCaptcha key not configured in site settings! This is mandatory in order to use the forgot password feature");
                }
            }
        })();
    }, [isReCaptchaEnabled]);
    return isReCaptchaEnabled && reCaptchaKey ? <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>{children}</GoogleReCaptchaProvider> : children;
}

export function AppWideReCaptchaProvider(props: { context: CMSProvidedProperties; children: React.ReactElement }) {
    const { children, context } = props;
    const [isReCaptchaEnabled, setReCaptchaEnabled] = React.useState(false);

    return (
        <ReCaptchaContext.Provider value={{ isReCaptchaEnabled, setReCaptchaEnabled }}>
            <SiteRecaptchaProvider context={context}>{children}</SiteRecaptchaProvider>
        </ReCaptchaContext.Provider>
    );
}

interface ReCaptchaContextType {
    isReCaptchaEnabled?: boolean;
    setReCaptchaEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReCaptchaContext = React.createContext<ReCaptchaContextType>({ setReCaptchaEnabled: () => undefined });
