import * as moment from "moment";

import { AddOnType, DisplayType } from "../plugins/dynamic/add-ons/add-on/addOn.util";
import {
    ApiCallOptions,
    ChoiceRequest,
    ChoiceReservedResource,
    ChoiceResourceType,
    ChoiceResult,
    Customer,
    MxtsApi,
    PriceEngineState,
    PriceTask,
    RefreshBillRequest,
    SubjectQuantity,
} from "@maxxton/cms-mxts-api";
import { DateSubjectSelection, SelectedAddOn, shouldAddAddOn, shouldRemoveAddOn, shouldUpdateAddOn } from "../redux/reducers/add-ons/selectedAddOnsReducer";
import { getFlattenedMyEnvData, getMainCustomerIdFromLoginToken } from "../redux/reducers/myEnv/myEnv.util";

import { BillChoice } from "../redux/reducers/billReducer";
import { BillUtil } from "../utils/bill.util";
import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { DateUtil } from "../utils/date.util";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { MyEnvState } from "../redux/reducers/myEnv/myEnvState";
import { getI18nLocaleString } from "../i18n";
import { globalApiContext } from "../containers/CmsProvider";
import namespacesList from "../i18n/namespaceList";

export async function refreshBillIncludingSelectedAddOns(params: {
    myEnvState: MyEnvState;
    billChoice: BillChoice;
    rateTypeId?: number;
    env: ApiCallOptions;
    updatedAddOns?: SelectedAddOn[];
    clearAddOns: boolean;
    selectedAddOns?: SelectedAddOn[];
    dynamicFilter?: DynamicFilter;
}): Promise<ChoiceResult | null> {
    const { myEnvState, billChoice, rateTypeId, env, updatedAddOns, clearAddOns, selectedAddOns, dynamicFilter } = params;
    const choiceRequest: ChoiceRequest = getBaseMyEnvChoiceRequest(myEnvState, dynamicFilter);

    if (updatedAddOns?.length || clearAddOns) {
        const refreshBillRequest: RefreshBillRequest = { ...choiceRequest, ...BillUtil.convertChoiceResultToRefreshRequest(billChoice), tasks: [PriceTask.RECALCULATE] };
        let childReservedResources = refreshBillRequest.reservedResources[0].children;

        if (selectedAddOns?.length && clearAddOns) {
            const clearedAddOnIds = selectedAddOns.map((addOn: SelectedAddOn) => addOn.resourceId);
            childReservedResources = childReservedResources.filter((child) => !clearedAddOnIds.includes(child.resourceId));
        } else if (updatedAddOns?.length) {
            let updatedSelectedAddOns = selectedAddOns || [];
            const updatedAddOnIds: number[] = updatedAddOns.map((addOn: SelectedAddOn) => addOn.resourceId);
            childReservedResources = childReservedResources.filter((child) => !updatedAddOnIds.includes(child.resourceId));

            updatedAddOns.forEach((updatedAddOn: SelectedAddOn) => {
                const currentlySelectedAddOn: SelectedAddOn | undefined = selectedAddOns?.find((addOn: SelectedAddOn) => addOn.resourceId === updatedAddOn.resourceId);

                if (shouldAddAddOn({ updatedAddOn, selectedAddOn: currentlySelectedAddOn })) {
                    updatedSelectedAddOns = updatedSelectedAddOns.concat(updatedAddOn);
                }
                if (shouldUpdateAddOn({ updatedAddOn, selectedAddOn: currentlySelectedAddOn })) {
                    updatedSelectedAddOns = updatedSelectedAddOns.map((addOn: SelectedAddOn) => (addOn.resourceId === updatedAddOn.resourceId ? updatedAddOn : addOn));
                }
                if (shouldRemoveAddOn({ updatedAddOn, selectedAddOn: currentlySelectedAddOn })) {
                    updatedSelectedAddOns = updatedSelectedAddOns.filter((addOn: SelectedAddOn) => addOn.resourceId !== updatedAddOn.resourceId);
                }
            });

            const updatedSelectedAddOnsRequestBodies = await getAllPriceEngineAddOnRequestBodies({ rateTypeId, selectedAddOns: updatedSelectedAddOns });
            childReservedResources = childReservedResources.concat(updatedSelectedAddOnsRequestBodies);
        }

        const accommodationResource = refreshBillRequest.reservedResources.find((resource) => resource.type === ChoiceResourceType.ACCOMMODATIONTYPE) || null;
        if (accommodationResource) {
            accommodationResource.children = [...new Set([...accommodationResource.children, ...childReservedResources])];
        } else {
            refreshBillRequest.reservedResources[0].children = childReservedResources;
        }

        refreshBillRequest.customerBill = refreshBillRequest.customerBill.filter((bill) => bill.state !== PriceEngineState.DELETED);
        refreshBillRequest.agentBill = refreshBillRequest.agentBill?.filter((bill) => bill.state !== PriceEngineState.DELETED);
        // Passing `customerStatusIds` can be optional and may vary depending on the specific customer.
        const customerStatusId = await getCustomerStatusId(env, myEnvState);
        if (customerStatusId) {
            refreshBillRequest.customerStatusIds = [customerStatusId];
        }

        return MxtsApi.refreshBill(env, refreshBillRequest);
    }

    return { choices: [billChoice] };
}

export async function getCustomerStatusId(env: ApiCallOptions, myEnvState?: MyEnvState) {
    const mainCustomerId = await getMainCustomerIdFromLoginToken();
    if (mainCustomerId && myEnvState?.selectedReservation?.reservation?.reservationDate) {
        const customerDetails: Customer = await MxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: mainCustomerId }]);
        const reservationDate = moment(myEnvState?.selectedReservation?.reservation?.reservationDate);

        const validCluster = customerDetails?.customerClusterManagers?.find((manager) => {
            const startDate = moment(manager.startDate);
            const endDate = manager.endDate ? moment(manager.endDate) : null; // Handle open-ended ranges

            return reservationDate.isSameOrAfter(startDate) && (endDate === null || reservationDate.isSameOrBefore(endDate));
        });
        return validCluster?.clusterId;
    }
}

export function getBaseMyEnvChoiceRequest(myEnvState: MyEnvState, dynamicFilter?: DynamicFilter): ChoiceRequest {
    const { startDate, endDate, distributionChannelId, reservationCategoryId } = getFlattenedMyEnvData(myEnvState, dynamicFilter);
    if (!reservationCategoryId) {
        throw new Error("Cannot generate the bill. No reservation category found inside the my environment reservation");
    }
    return {
        startDate,
        endDate,
        distributionChannelId,
        reservationCategoryId,
        reservedResources: [],
        tasks: [PriceTask.CHEAPEST_CHOICE, PriceTask.GENERATE_BILL],
    };
}

/**
 * Creates the request bodies that are sent to the Price Engine for a selected add-on.
 * When the add-on is a date-select variant, a request body is created for each selected day.
 */
export const getSinglePriceEngineAddOnRequestBodies = async ({ rateTypeId, selectedAddOn }: { rateTypeId?: number; selectedAddOn: SelectedAddOn }): Promise<ChoiceReservedResource[]> => {
    const { dateSubjectSelections, dates, subjects, quantity, resourceId } = selectedAddOn;
    const baseChildReservedResource: ChoiceReservedResource = new ChoiceReservedResource({
        task: "CALCULATE",
        resourceId,
        quantity: 1,
        rateTypeId,
        status: "INITIAL",
        type: getReservedResourceType(selectedAddOn),
    });

    const addOnRequestBodies: ChoiceReservedResource[] = [];
    const apiContext = globalApiContext();

    switch (selectedAddOn.displayType) {
        case DisplayType.DATE_SELECT_SUBJECT:
            if (dateSubjectSelections) {
                await Promise.all(
                    dateSubjectSelections.map(async (selection: DateSubjectSelection) =>
                        addOnRequestBodies.push({
                            ...baseChildReservedResource,
                            subjects: selection.subjects as SubjectQuantity[],
                            startDate: await DateUtil.getMXTSDateTimeString(apiContext, selection.date),
                            endDate: await DateUtil.getMXTSDateTimeString(apiContext, selection.date),
                        })
                    )
                );
            }
            break;

        case DisplayType.DATE_SELECT:
            if (dates) {
                await Promise.all(
                    dates.map(async (date: Date) =>
                        addOnRequestBodies.push({
                            ...baseChildReservedResource,
                            startDate: await DateUtil.getMXTSDateTimeString(apiContext, date),
                            endDate: await DateUtil.getMXTSDateTimeString(apiContext, date),
                        })
                    )
                );
            }
            break;

        /* jscpd:ignore-start */
        case DisplayType.SUPPLIER_ADDON:
            if (dates?.length && quantity) {
                const rangeStartDate: Date = dates[0];
                const rangeEndDate: Date = dates[dates.length - 1];

                addOnRequestBodies.push({
                    ...baseChildReservedResource,
                    startDate: await DateUtil.getMXTSDateTimeString(apiContext, rangeStartDate),
                    endDate: await DateUtil.getMXTSDateTimeString(apiContext, rangeEndDate),
                    quantity,
                    price: selectedAddOn.overridePrice,
                    hasPriceOverride: selectedAddOn.overridePrice != null,
                });
            }
            break;
        case DisplayType.DATE_RANGE:
            if (dates?.length && quantity) {
                const rangeStartDate: Date = dates[0];
                const rangeEndDate: Date = dates[dates.length - 1];

                addOnRequestBodies.push({
                    ...baseChildReservedResource,
                    startDate: await DateUtil.getMXTSDateTimeString(apiContext, rangeStartDate),
                    endDate: await DateUtil.getMXTSDateTimeString(apiContext, rangeEndDate),
                    quantity,
                });
            }
            break;
        /* jscpd:ignore-end */

        case DisplayType.SUBJECT:
            if (subjects) {
                addOnRequestBodies.push({
                    ...baseChildReservedResource,
                    subjects: subjects as SubjectQuantity[],
                });
            }
            break;

        default:
            if (quantity) {
                addOnRequestBodies.push({
                    ...baseChildReservedResource,
                    quantity,
                });
            }
            break;
    }

    return addOnRequestBodies;
};

const getReservedResourceType = (selectedAddOn: SelectedAddOn): ChoiceResourceType => {
    const { displayType, extraCancelPremium, addOnType } = selectedAddOn;
    let type = ChoiceResourceType.PRODUCTTYPE;
    if (displayType === DisplayType.PACKAGE) {
        type = ChoiceResourceType.COMPOSITION;
    } else if (extraCancelPremium || addOnType === AddOnType.DEPOSIT) {
        type = ChoiceResourceType.EXTRA;
    }
    return type;
};

export const getAllPriceEngineAddOnRequestBodies = async ({ rateTypeId, selectedAddOns }: { rateTypeId?: number; selectedAddOns: SelectedAddOn[] }): Promise<ChoiceReservedResource[]> => {
    const addOnRequestBodies: ChoiceReservedResource[] = [];
    await Promise.all(selectedAddOns.map(async (selectedAddOn: SelectedAddOn) => addOnRequestBodies.push(...(await getSinglePriceEngineAddOnRequestBodies({ rateTypeId, selectedAddOn })))));
    return addOnRequestBodies;
};

export const createAddOnNotification = ({
    cmsContext,
    updatedAddOn,
    selectedAddOn,
    success,
}: {
    cmsContext: CMSProviderProperties;
    updatedAddOn: SelectedAddOn;
    selectedAddOn?: SelectedAddOn;
    success: boolean;
}): void => {
    const { alerts, currentLocale, site } = cmsContext;
    if (shouldAddAddOn({ updatedAddOn, selectedAddOn })) {
        alerts.push({
            message: `${updatedAddOn.name} ${getI18nLocaleString(namespacesList.widgetAddOns, success ? "addOnSelectionSuccess" : "addOnSelectionFailed", currentLocale, site)}`,
            color: success ? "success" : "danger",
        });
    }
    if (shouldUpdateAddOn({ updatedAddOn, selectedAddOn })) {
        alerts.push({
            message: `${updatedAddOn.name} ${getI18nLocaleString(namespacesList.widgetAddOns, success ? "addOnUpdationSuccess" : "addOnUpdationFailed", currentLocale, site)}`,
            color: success ? "success" : "danger",
        });
    }
    if (shouldRemoveAddOn({ updatedAddOn, selectedAddOn })) {
        alerts.push({
            message: `${updatedAddOn.name} ${getI18nLocaleString(namespacesList.widgetAddOns, success ? "addOnDeletionSuccess" : "addOnDeletionFailed", currentLocale, site)}`,
            color: success ? "success" : "danger",
        });
    }
};
