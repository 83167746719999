import { Menu, MenuApi } from "@maxxton/cms-api";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { FormSpec } from "../";
import { findAffectedAssets } from "../utils";
import { getTimeStampOptions } from "../columnSpec.util";
import namespaceList from "../../i18n/namespaceList";
import { widgetHierarchy } from "./";

export const menuSpec: FormSpec<Menu> = {
    id: "menu",
    name: getI18nLocaleObject(namespaceList.admin, "menu"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "menus"),
    api: MenuApi,
    permission: "webmanager.menus",
    display(menu: Menu): string {
        return menu.name;
    },
    tableColumns: [{ name: getI18nLocaleObject(namespaceList.admin, "name"), variable: "name", type: "text" }, ...getTimeStampOptions()],
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "name"),
                                variable: "name",
                                type: "text",
                                required: true,
                            },
                            widgetHierarchy<Menu, "children">({
                                label: getI18nLocaleObject(namespaceList.admin, "menuItems"),
                                variable: "children",
                                rootTitle: getI18nLocaleObject(namespaceList.admin, "menu"),
                                templatesTitle: getI18nLocaleObject(namespaceList.admin, "availableMenuComponents"),
                                widgetType: "menu",
                                rootType: "multiple",
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "tabOptions"),
                    properties: [
                        [
                            {
                                type: "checkbox",
                                variable: "enableResponsiveMenu",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "enableResponsiveMenu"),
                                default: true,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
    async linkage(id: string) {
        const affectedModels = await findAffectedAssets(id, "menuId");
        return [
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedPages"),
                links: affectedModels.affectedPages,
                specId: "page",
            },
            {
                affectedModelName: getI18nLocaleString(namespaceList.admin, "affectedTemplates"),
                links: affectedModels.affectedTemplates,
                specId: "template",
            },
        ];
    },
};
