import { GroupOptions, InputSpecGroup, SomeInputSpec, localized, pageSpec } from "../form-specs/index";
import { accoKindMultiSelector, dynamicFieldPrice } from "../components/utils";

import { ConditionalLinkContentType } from "../utils/linking.util";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { NumberMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { autocompleteSiteSpec } from "../form-specs/models/autocompleteSite";
import { getI18nLocaleObject } from "../i18n/loader";
import { getReservationCategoriesFromDc } from "../utils/reservationCategories.util";
import namespaceList from "../i18n/namespaceList";

export interface ConditionalLinkingSpecOptions {
    useConditionalAnchorLink?: boolean;
    conditionalAnchorLink?: string;
    conditionalLocalizedLinkButtonOptions?: ConditionalLocalizedLinkButtonOptions[];
    conditionalAccoKindIds?: NumberMultiSelectOption[];
    conditionalContentTypes?: NumberMultiSelectOption[];
}

export interface LinkingSpecOptions extends ConditionalLinkingSpecOptions {
    useAnchorLink?: boolean;
    anchorLink?: string;
    localizedLinkButtonOptions?: LocalizedLinkButtonOptions[];
    useDynamicFieldAsLink?: boolean;
    dynamicFieldCodesForLinking?: NumberMultiSelectOption[];
    showActivityDynamicFieldForLinking?: boolean;
}

// Since parent variables are ignored, it seems to be necessary to have only unique variable names for each (sub) variable
// Let's hope we never need a third link
export interface LocalizedLinkButtonOptions extends LocalizedContentBase {
    externalUrl: string;
    noFollowLink: boolean;
    openInNewTab: boolean;
    pageId: string;
    siteId: string;
    useExternalUrl: boolean;
    selectReservationCategory: boolean;
    reservationCategoryId?: number;
}
export interface ConditionalLocalizedLinkButtonOptions extends LocalizedContentBase {
    conditionalExternalUrl: string;
    conditionalNoFollowLink: boolean;
    conditionalOpenInNewTab: boolean;
    conditionalPageId: string;
    conditionalSiteId: string;
    useConditionalExternalUrl: boolean;
}

export const linkingSpec = <E, P extends keyof E>(options: GroupOptions<E, P>, showDynamicFieldLinkOption?: boolean): InputSpecGroup<E, P, LinkingSpecOptions, keyof LinkingSpecOptions> => {
    const { variable, visible } = options;
    return { variable, visible, type: "group", specs: getLinkingSpec(showDynamicFieldLinkOption) };
};

export const conditionalLinkingSpec = <E, P extends keyof E>(options: GroupOptions<E, P>): InputSpecGroup<E, P, LinkingSpecOptions, keyof LinkingSpecOptions> => {
    const { variable, visible } = options;
    return { variable, visible, type: "group", specs: conditionalLinkingSpecs };
};

// eslint-disable-next-line max-lines-per-function
const getLinkingSpec = (showDynamicFieldLinkOption?: boolean) => {
    const defaultLinkingSpecs: Array<SomeInputSpec<LinkingSpecOptions, keyof LinkingSpecOptions>> = [
        {
            variable: "useDynamicFieldAsLink",
            label: getI18nLocaleObject(namespaceList.widgetButton, "useDynamicFieldAsLink"),
            type: "checkbox",
            default: false,
            visible: () => !!showDynamicFieldLinkOption,
        },
        {
            variable: "showActivityDynamicFieldForLinking",
            label: getI18nLocaleObject(namespaceList.dynamicPlugin, "showActivityDynamicField"),
            type: "checkbox",
            visible: (options: LinkingSpecOptions) => !!options?.useDynamicFieldAsLink,
        },
        {
            ...dynamicFieldPrice("dynamicFieldCodesForLinking", undefined, undefined, "Activity"),
            visible: (options: LinkingSpecOptions) => !!options?.useDynamicFieldAsLink && !!options.showActivityDynamicFieldForLinking,
        },
        {
            ...dynamicFieldPrice("dynamicFieldCodesForLinking"),
            visible: (options: LinkingSpecOptions) => !!options?.useDynamicFieldAsLink && !options.showActivityDynamicFieldForLinking,
        },
        localized({
            variable: "localizedLinkButtonOptions",
            tabContent: [
                {
                    variable: "useExternalUrl",
                    label: getI18nLocaleObject(namespaceList.linkingSpec, "useExternalUrl"),
                    type: "checkbox",
                    default: false,
                },
                {
                    variable: "externalUrl",
                    label: getI18nLocaleObject(namespaceList.linkingSpec, "externalUrl"),
                    type: "text",
                    default: "",
                    visible: (options: LocalizedLinkButtonOptions) => !!options?.useExternalUrl,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "site"),
                    variable: "siteId",
                    type: "autocomplete",
                    default: "",
                    refType: autocompleteSiteSpec,
                    visible: (options: LocalizedLinkButtonOptions) => !options?.useExternalUrl,
                },
                {
                    label: getI18nLocaleObject(namespaceList.admin, "page"),
                    variable: "pageId",
                    type: "autocomplete",
                    default: "",
                    refType: pageSpec,
                    dependsOnSiteSpec: "siteId",
                    visible: (options: LocalizedLinkButtonOptions) => !options?.useExternalUrl,
                },
                {
                    variable: "openInNewTab",
                    label: getI18nLocaleObject(namespaceList.linkingSpec, "openInNewTab"),
                    type: "checkbox",
                    default: false,
                },
                {
                    variable: "selectReservationCategory",
                    label: getI18nLocaleObject(namespaceList.linkingSpec, "selectReservationCategory"),
                    type: "checkbox",
                    default: false,
                },
                {
                    type: "paragraph",
                    label: getI18nLocaleObject(namespaceList.linkingSpec, "selectReservationCategoryInfo"),
                    visible: (options: LocalizedLinkButtonOptions) => !!options?.selectReservationCategory,
                },
                {
                    variable: "reservationCategoryId",
                    label: getI18nLocaleObject(namespaceList.admin, "reservationCategoryId"),
                    type: "autocomplete" as const,
                    isClearable: false,
                    options: async () => getReservationCategoriesFromDc(),
                    placeholder: getI18nLocaleObject(namespaceList.admin, "selectReservationCategoryId"),
                    visible: (options: LocalizedLinkButtonOptions) => !!options?.selectReservationCategory,
                },
                {
                    variable: "noFollowLink",
                    label: getI18nLocaleObject(namespaceList.linkingSpec, "noFollowLink"),
                    type: "checkbox",
                    default: false,
                    visible: (options: LocalizedLinkButtonOptions) => !!options?.useExternalUrl,
                },
            ],
            visible: (options: LinkingSpecOptions) => !options?.useDynamicFieldAsLink,
        }),
        localized({
            variable: "localizedLinkButtonOptions",
            tabContent: [
                {
                    variable: "openInNewTab",
                    label: getI18nLocaleObject(namespaceList.linkingSpec, "openInNewTab"),
                    type: "checkbox",
                    default: false,
                },
            ],
            visible: (options: LinkingSpecOptions) => !!options?.useDynamicFieldAsLink,
        }),
        {
            variable: "useAnchorLink",
            label: getI18nLocaleObject(namespaceList.linkingSpec, "useAnchorLinkLabel"),
            default: false,
            type: "checkbox",
        },
        {
            type: "paragraph",
            label: getI18nLocaleObject(namespaceList.linkingSpec, "anchorLinkInfoWhenExternalUrlIsEnabled"),
            visible: (options: LinkingSpecOptions) => !!options?.localizedLinkButtonOptions?.some((linkButtonOptions: LocalizedLinkButtonOptions) => linkButtonOptions.useExternalUrl),
        },
        {
            variable: "anchorLink",
            label: getI18nLocaleObject(namespaceList.linkingSpec, "anchorLinklabel"),
            type: "text",
            placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForAnchorLink"),
            visible: (options: LinkingSpecOptions) => !!options?.useAnchorLink,
        },
    ];
    return defaultLinkingSpecs;
};

const conditionalLinkingSpecs: Array<SomeInputSpec<LinkingSpecOptions, keyof LinkingSpecOptions>> = [
    localized<any, "conditionalLocalizedLinkButtonOptions", ConditionalLocalizedLinkButtonOptions>({
        variable: "conditionalLocalizedLinkButtonOptions",
        visible: (options: LinkingSpecOptions) => !options?.useConditionalAnchorLink,
        tabContent: [
            {
                variable: "useConditionalExternalUrl",
                label: getI18nLocaleObject(namespaceList.linkingSpec, "useExternalUrl"),
                type: "checkbox",
                default: false,
            },
            {
                variable: "conditionalExternalUrl",
                label: getI18nLocaleObject(namespaceList.linkingSpec, "externalUrl"),
                type: "text",
                default: "",
                visible: (options: ConditionalLocalizedLinkButtonOptions) => !!options?.useConditionalExternalUrl,
            },
            {
                label: getI18nLocaleObject(namespaceList.admin, "site"),
                variable: "conditionalSiteId",
                type: "autocomplete",
                default: "",
                refType: autocompleteSiteSpec,
                visible: (options: ConditionalLocalizedLinkButtonOptions) => !options?.useConditionalExternalUrl,
            },
            {
                label: getI18nLocaleObject(namespaceList.admin, "page"),
                variable: "conditionalPageId",
                type: "autocomplete",
                default: "",
                refType: pageSpec,
                dependsOnSiteSpec: "conditionalSiteId",
                visible: (options: ConditionalLocalizedLinkButtonOptions) => !options?.useConditionalExternalUrl,
            },
            {
                variable: "conditionalOpenInNewTab",
                label: getI18nLocaleObject(namespaceList.linkingSpec, "openInNewTab"),
                type: "checkbox",
                default: false,
            },
            {
                variable: "conditionalNoFollowLink",
                label: getI18nLocaleObject(namespaceList.linkingSpec, "noFollowLink"),
                type: "checkbox",
                default: false,
                visible: (options: ConditionalLocalizedLinkButtonOptions) => !!options?.useConditionalExternalUrl,
            },
        ],
    }),
    {
        variable: "useConditionalAnchorLink",
        label: getI18nLocaleObject(namespaceList.linkingSpec, "useAnchorLinkLabel"),
        default: false,
        type: "checkbox",
    },
    {
        type: "paragraph",
        label: getI18nLocaleObject(namespaceList.linkingSpec, "anchorLinkInfoWhenExternalUrlIsEnabled"),
        visible: (options: any) => !!options?.useConditionalExternalUrl,
    },
    {
        variable: "conditionalAnchorLink",
        label: getI18nLocaleObject(namespaceList.linkingSpec, "anchorLinkLabel"),
        type: "text",
        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForAnchorLink"),
        visible: (options: LinkingSpecOptions) => !!options?.useConditionalAnchorLink,
    },
    {
        variable: "conditionalContentTypes",
        label: getI18nLocaleObject(namespaceList.linkingSpec, "conditionalLinkContentTypes"),
        type: "multiselect",
        async optionList(): Promise<NumberMultiSelectOption[]> {
            return [
                {
                    value: ConditionalLinkContentType.ACCO_KIND,
                    text: getI18nLocaleObject(namespaceList.admin, "accoKind"),
                },
            ];
        },
        placeholder: getI18nLocaleObject(namespaceList.linkingSpec, "conditionalLinkContentTypePlaceholder"),
    },
    {
        ...accoKindMultiSelector("conditionalAccoKindIds", true),
        visible: (options: LinkingSpecOptions) => !!options?.conditionalContentTypes?.find((contentType: NumberMultiSelectOption) => contentType.value === ConditionalLinkContentType.ACCO_KIND),
    },
];
