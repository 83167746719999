import * as React from "react";

import { Alert, Button } from "reactstrap";
import { FormSpec, getSiteLoginUrl } from "../../../form-specs/index";
import { ReactSelectOption, SiteMultiSelect } from "./components/SiteMultiSelect";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n/index";
import withRouter, { RouteComponentProps } from "../../../routing/withRouter";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { MyEnvUserType } from "@maxxton/cms-mxts-api";
import { OpenInNew } from "@mui/icons-material";
import { RandomUserPicker } from "./components/RandomUserPicker";
import { Site } from "@maxxton/cms-api";
import { getAdminMxtsEnv } from "../../../plugins/mxts";
import { getMyEnvUserTypes } from "../../../redux/reducers/myEnv/myEnv.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";

const EmptyAdminPage = loadable(() => loadableRetry(() => import("./EmptyAdminPage")), {
    resolveComponent: ({ EmptyAdminPage }) => EmptyAdminPage,
});

export const IMITATE_USER_DUMMY_SPEC: FormSpec<any> = {
    id: "imitate-user",
    name: getI18nLocaleObject(namespaceList.imitateUser, "imitateUser"),
    pluralName: getI18nLocaleObject(namespaceList.imitateUser, "imitateUser"),
    permission: "webmanager.imitateuser",
    properties: [],
};

let lastRequestedCustomerId: number | undefined;

const ImitateUserAdmin = (props: { context: CMSProviderProperties; routeComponentProps: RouteComponentProps }): JSX.Element => {
    const { context, routeComponentProps } = props;
    const [userId, setUserId] = React.useState<number>();
    const [userExists, setUserExists] = React.useState<boolean>();
    const [myEnvUserTypes, setMyEnvUserTypes] = React.useState<MyEnvUserType[]>([]);
    const [userHasEmailConfigured, setUserHasEmailConfigured] = React.useState<boolean>();
    const [sites, onSitesChange] = React.useState<Array<ReactSelectOption<Site>>>([]);
    const clearUserId = React.useCallback(() => setUserId(undefined), []);
    const onUserIdChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUserId(e.target.value ? +e.target.value : undefined), []);

    React.useEffect(() => {
        setMyEnvUserTypes([]);
        setUserHasEmailConfigured(undefined);
        setUserExists(undefined);
        lastRequestedCustomerId = userId;
        const obtainMyEnvUserTypes = async () => {
            const env = await getAdminMxtsEnv();
            const customer = await context.mxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: userId }]).catch((err) => undefined);
            if (lastRequestedCustomerId !== userId) {
                return;
            }
            if (customer) {
                setUserHasEmailConfigured(!!customer.addresses?.find((address) => address.managerId === customer.mailAddressManagerId)?.email?.trim());
            } else {
                setUserExists(false);
            }
            setMyEnvUserTypes(customer ? await getMyEnvUserTypes(customer, context) : []);
        };
        if (userId) {
            obtainMyEnvUserTypes();
        }
    }, [userId]);

    return (
        <EmptyAdminPage context={context} adminPageClassName={"user-imitate"} adminPageName={getI18nLocaleString(namespaceList.imitateUser, "imitateUser")}>
            <div className="admin-imitate-edit">
                <div className="content-box">
                    <div className="group-input-types">
                        <div className="form-group">
                            <label>{getI18nLocaleString(namespaceList.imitateUser, "selectSitesLabel")}</label>
                            <SiteMultiSelect context={context} sites={sites} onSitesChange={onSitesChange}></SiteMultiSelect>
                        </div>
                        <div className="form-group">
                            <label>{getI18nLocaleString(namespaceList.imitateUser, "customerID")}</label>
                            <div className="customer-id-wrap">
                                <input
                                    placeholder={getI18nLocaleString(namespaceList.imitateUser, "userIdInputPlaceHolder")}
                                    className="search form-control"
                                    value={userId || ""}
                                    onChange={onUserIdChange}
                                    type="number"
                                />

                                <Button className="reset-button" onClick={clearUserId}>
                                    {getI18nLocaleString(namespaceList.imitateUser, "reset")}
                                </Button>
                            </div>
                            {!!myEnvUserTypes.length && <span>{getI18nLocaleString(namespaceList.imitateUser, "customerRoles") + " " + getMyEnvUserTypeTranslations(myEnvUserTypes).join(", ")}</span>}
                            {userHasEmailConfigured === false && <Alert color="warning">{getI18nLocaleString(namespaceList.imitateUser, "userHasNoEmailConfigured")}</Alert>}
                            {userExists === false && <Alert color="warning">{getI18nLocaleString(namespaceList.imitateUser, "userIdDoesntExist")}</Alert>}
                        </div>
                        {sites?.length && userId
                            ? sites.map((site) => (
                                  <a key={site.value} type="button" className="imitate-user-button" href={getSiteLoginUrl(site.obj, `?imitateCustomerId=${userId}`)} target="_blank" rel="noreferrer">
                                      <OpenInNew />
                                      {`${getI18nLocaleString(namespaceList.imitateUser, "goImitateUser")} ${site.obj.host}`}
                                  </a>
                              ))
                            : ""}
                        <RandomUserPicker context={context} setUserId={setUserId} sites={sites} />
                    </div>
                </div>
            </div>
        </EmptyAdminPage>
    );
};

function getMyEnvUserTypeTranslations(myEnvUserTypes: MyEnvUserType[]): string[] {
    return myEnvUserTypes.map((userType) => {
        switch (userType) {
            case MyEnvUserType.CUSTOMER:
                return getI18nLocaleString(namespaceList.imitateUser, "customer");
            case MyEnvUserType.OWNER:
                return getI18nLocaleString(namespaceList.imitateUser, "owner");
            case MyEnvUserType.NON_RENTABLE_OWNER:
                return getI18nLocaleString(namespaceList.imitateUser, "nonRentableOwner");
        }
    });
}

export const ImitateUserAdminPage = withRouter(ImitateUserAdmin);
