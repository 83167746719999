import { Image, ImageUrls } from "@maxxton/cms-mxts-api";

import { ApiContext } from "../../../containers/cmsProvider.types";
import { getMxtsEnv } from "../../../plugins/mxts";

export function getDefaultFallBackImageUrls(): ImageUrls & { image_fill: string; medium_square: string; medium_face: string; favicon: string } {
    return {
        large: "https://newyse-res.cloudinary.com/image/upload/t_mcms_larger/f_auto/image-fallback.jpg",
        image_fill: "https://newyse-res.cloudinary.com/image/upload/t_mcms_larger_fill_ratio/f_auto/image-fallback.jpg",
        medium: "https://newyse-res.cloudinary.com/image/upload/t_mcms_medium/f_auto/image-fallback.jpg",
        medium_square: "https://newyse-res.cloudinary.com/image/upload/t_mcms_medium_square/f_auto/image-fallback.jpg",
        medium_face: "https://newyse-res.cloudinary.com/image/upload/t_mcms_medium_face/f_auto/image-fallback.jpg",
        original: "https://newyse-res.cloudinary.com/image/upload/t_mcms_original/f_auto/image-fallback.jpg",
        favicon: "https://newyse-res.cloudinary.com/image/upload/t_mcms_favicon/f_auto/image-fallback.jpg",
        small: "https://newyse-res.cloudinary.com/image/upload/t_mcms_small/f_auto/image-fallback.jpg",
    };
}

export async function getImages(apiContext: ApiContext, imageManagerId?: number, fallbackImageManagerId?: number, locale?: string, signal?: AbortSignal, size?: number): Promise<Image[]> {
    const ops = await getMxtsEnv(apiContext, locale);
    let images: Image[] = [];

    if (imageManagerId) {
        images = await apiContext.mxtsApi.imagesPerManager(ops, size ? { size } : {}, [{ key: "imageManagerId", value: imageManagerId }], signal);
    }
    if (fallbackImageManagerId && (images === undefined || images.length === 0)) {
        images = await apiContext.mxtsApi.imagesPerManager(ops, {}, [{ key: "imageManagerId", value: fallbackImageManagerId }], signal);
    }
    return images;
}

export async function getFallbackImages(apiContext: ApiContext, fallbackImageManagerIds?: number[], locale?: string, signal?: AbortSignal, size?: number): Promise<Image[]> {
    const ops = await getMxtsEnv(apiContext, locale);
    let images: Image[] = [];
    const leftoverImageManagerIds = [...(fallbackImageManagerIds || [])];
    while (!images?.length && leftoverImageManagerIds.length) {
        images = await apiContext.mxtsApi.imagesPerManager(ops, size ? { size } : {}, [{ key: "imageManagerId", value: leftoverImageManagerIds.shift() }], signal);
    }
    return images?.length ? images : [];
}
