import { SpecialSearchAction, SpecialSearchActionType } from "../actions/specialSearchAction";

import { Reducer } from "redux";

export interface SpecialSearchState {
    loading?: boolean;
    lastSearchCode?: string;
    lastSearchCodeWasValid?: boolean;
    appliedSearchCode?: string;
}

export const specialSearchReducer: Reducer<SpecialSearchState> = (state: SpecialSearchState = {}, action: SpecialSearchAction): SpecialSearchState => {
    switch (action.actionType) {
        case SpecialSearchActionType.loading:
            return { ...state, loading: true };
        case SpecialSearchActionType.fetched:
            return {
                ...state,
                loading: false,
                lastSearchCode: action.payload?.lastSearchCode,
                lastSearchCodeWasValid: true,
                appliedSearchCode: action.payload?.lastSearchCode,
            };
        case SpecialSearchActionType.error:
            return {
                ...state,
                loading: false,
                lastSearchCode: action.payload?.lastSearchCode,
                lastSearchCodeWasValid: false,
            };
        case SpecialSearchActionType.removed:
            return {
                ...state,
                loading: false,
                lastSearchCode: undefined,
                lastSearchCodeWasValid: undefined,
                appliedSearchCode: undefined,
            };

        default:
            return state;
    }
};
