import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { DESCRIPTION, DESCRIPTION2, SHORT_DESCRIPTION } from "../../../components/utils";
/* eslint-disable max-lines-per-function */
import { MxtsApi, Unit, UnitDescription } from "@maxxton/cms-mxts-api";
import { SendingStatus, sendEmailToResort } from "./description.utils";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DynamicMyEnvContainerChildOptions } from "../container/myenv/dynamicMyEnvContainer.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { State } from "../../../redux";
import { UncontrolledAlert } from "reactstrap";
import { executeRecaptchaCheck } from "../amenities/amenitiesEditor.util";
import { getI18nLocaleString } from "../../../i18n";
import { getMxtsEnv } from "../../mxts";
import namespaceList from "../../../i18n/namespaceList";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useSelector } from "react-redux";

export function DescriptionEditor(props: {
    context: CMSProviderProperties;
    childOptions: DynamicMyEnvContainerChildOptions | undefined;
    descriptionTypeMultiSelector: NumberMultiSelectOption[] | undefined;
    content: Unit;
    isEditModalOpen: boolean | undefined;
    toggleModal: () => void;
    setIsdescriptionUpdating: (isDescriptionsUpdating: boolean) => void;
    updateContent: () => {
        defaultDescription: string;
        defaultDescription2: string;
        defaultShortDescription: string;
    };
}) {
    const { childOptions, context, descriptionTypeMultiSelector, content, isEditModalOpen, setIsdescriptionUpdating, toggleModal, updateContent } = props;
    const { currentLocale, site } = context;
    const [description, setDescription] = React.useState<string | null>();
    const [description2, setDescription2] = React.useState<string | null>();
    const [shortDescription, setShortDescription] = React.useState<string | null>();
    const [emailSendStatus, setEmailSendStatus] = React.useState<SendingStatus | undefined>();
    const [isEmailSent, setIsEmailSent] = React.useState<boolean>();
    const myEnvState: MyEnvState = useSelector((state: State) => state.myEnvState);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const getReCaptchaToken = React.useCallback(async (): Promise<string> => executeRecaptchaCheck(executeRecaptcha), [executeRecaptcha]);

    React.useEffect(() => {
        const updatedDescriptions = updateContent();
        setDescription(updatedDescriptions.defaultDescription);
        setDescription2(updatedDescriptions.defaultDescription2);
        setShortDescription(updatedDescriptions.defaultShortDescription);
    }, []);

    React.useEffect(() => {
        setEmailSendStatus(undefined);
    }, [isEditModalOpen]);

    const handleEmailSend = () => {
        setIsEmailSent(true);
        setEmailSendStatus(undefined);
        sendEmailToResort({
            childOptions,
            context,
            myEnvState,
            setEmailSendStatus,
            setIsEmailSent,
            getReCaptchaToken,
            description,
            description2,
            shortDescription,
            content,
        });
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };

    const handleDescription2Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription2(event.target.value);
    };

    const handleShortDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setShortDescription(event.target.value);
    };

    const updateUnitDescription = () => {
        setIsdescriptionUpdating(true);
        updateUnitDescriptions();
        toggleModal();
    };

    const updateUnitDescriptions = async () => {
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const language = await MxtsApi.languages(env, { shortName: currentLocale.code });
        const unitDescriptionsRequest = (content as Unit).i18n?.map((data) => {
            if (data.languageId === language[0].languageId) {
                data.description = description || null;
                data.description2 = description2 || null;
                data.shortDescription = shortDescription || null;
            } else if (!(content as Unit).i18n?.some((data) => data.languageId === language[0].languageId)) {
                const descriptionsRequest: UnitDescription = {
                    description: description || null,
                    description2: description2 || null,
                    shortDescription: shortDescription || null,
                    internalDescription: null,
                    language: null,
                    noteForHkInspector: null,
                    name: (content as Unit).name,
                    languageId: language[0].languageId,
                    unitId: (content as Unit).unitId,
                };
                return descriptionsRequest;
            }
            return data;
        });
        if (unitDescriptionsRequest) {
            const updateUnitDescriptionsData = await MxtsApi.updateUnitDescriptions(env, unitDescriptionsRequest, [{ key: "unitId", value: (content as Unit).unitId }]);
            if (updateUnitDescriptionsData.length) {
                const updatedDescriptions = updateContent();
                setDescription(updatedDescriptions.defaultDescription);
                setDescription2(updatedDescriptions.defaultDescription2);
                setShortDescription(updatedDescriptions.defaultShortDescription);
            }
        }
    };

    return (
        <div className="descriptions-edit-fields">
            <div className="edit-image-loader">
                {isEmailSent && <div className="edit-image-overlay" />}
                {isEmailSent && (
                    <div className="loader-wrapper">
                        <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                    </div>
                )}
                <div className="descriptions-edit-fields__wrapper">
                    {descriptionTypeMultiSelector?.some((option) => option.value === DESCRIPTION) && (
                        <div className="unit-description">
                            <label htmlFor="description">{getI18nLocaleString(namespaceList.dynamicPlugin, "description", currentLocale, site)}</label>
                            <textarea onChange={handleDescriptionChange} defaultValue={description!} name="description" id="description" cols={20} rows={5}></textarea>
                        </div>
                    )}
                    {descriptionTypeMultiSelector?.some((option) => option.value === DESCRIPTION2) && (
                        <div className="unit-description2">
                            <label htmlFor="description2">{getI18nLocaleString(namespaceList.dynamicPlugin, "description2", currentLocale, site)}</label>
                            <textarea onChange={handleDescription2Change} defaultValue={description2!} name="description2" id="description2" cols={20} rows={5}></textarea>
                        </div>
                    )}
                    {descriptionTypeMultiSelector?.some((option) => option.value === SHORT_DESCRIPTION) && (
                        <div className="unit-short-description">
                            <label htmlFor="shortDescription">{getI18nLocaleString(namespaceList.dynamicPlugin, "shortDescription", currentLocale, site)}</label>
                            <textarea onChange={handleShortDescriptionChange} defaultValue={shortDescription!} name="shortDescription" id="shortDescription" cols={20} rows={5}></textarea>
                        </div>
                    )}
                    <div className="alerts">
                        {emailSendStatus === SendingStatus.Success && (
                            <UncontrolledAlert color="success">{getI18nLocaleString(namespaceList.amenitiesWidget, "emailSend", currentLocale, site)}</UncontrolledAlert>
                        )}
                        {emailSendStatus === SendingStatus.Failed && (
                            <UncontrolledAlert color="danger">{getI18nLocaleString(namespaceList.amenitiesWidget, "emailSendFailed", currentLocale, site)}</UncontrolledAlert>
                        )}
                    </div>
                </div>
            </div>
            {childOptions?.askForApprovalByEmail ? (
                <button className="descriptions-edit-fields__button" onClick={handleEmailSend}>
                    {getI18nLocaleString(namespaceList.admin, "askForApproval", currentLocale, site)}
                </button>
            ) : (
                <button className="descriptions-edit-fields__button" onClick={updateUnitDescription}>
                    {getI18nLocaleString(namespaceList.admin, "modalChangesSave", currentLocale, site)}
                </button>
            )}
        </div>
    );
}
