import { CurrentLocale } from "../app.types";
import { LocaleFallbackConfig } from "@maxxton/cms-mxts-api";
import { Site } from "@maxxton/cms-api";
import { getFallbackLocaleCodesFromSettings } from "../i18n/loader";

export const localeFallbackConfig: LocaleFallbackConfig = {};

export function setLocaleFallbackConfig(site: Site, currentLocale: CurrentLocale) {
    const fallbackLocales = getFallbackLocaleCodesFromSettings({ site, currentLocale });
    localeFallbackConfig[currentLocale.code] = fallbackLocales;
}
