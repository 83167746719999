import * as moment from "moment";

export const plLocaleSpecification: moment.LocaleSpecification = {
    calendar: {
        sameDay: "[Dziś o] LT",
        nextDay: "[Jutro o] LT",
        lastDay: "[Wczoraj o] LT",
        sameElse: "L",
    },
    longDateFormat: {
        LTS: "HH:mm:ss",
        LT: "HH:mm",
        L: "DD.MM.YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd, D MMMM YYYY HH:mm",
    },
    invalidDate: "Invalid date",
    ordinal: () => "%d.",
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    relativeTime: {
        future: "za %s",
        past: "%s temu",
        s: "kilka sekund",
        d: "1 dzień",
        dd: "%d dni",
        w: "tydzień",
        M: "miesiąc",
        y: "rok",
    },
    monthsShort: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
    week: {
        dow: 1,
        doy: 4,
    },
    weekdays: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"],
    weekdaysMin: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
    weekdaysShort: ["ndz", "pon", "wt", "śr", "czw", "pt", "sob"],
    monthsParse: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    longMonthsParse: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    shortMonthsParse: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    dayOfMonthOrdinalParseLenient: {},
};
