import { getI18nLocaleObject, getI18nLocaleString } from "../i18n";

import { SomeInputSpec } from "../form-specs";
import namespaceList from "../i18n/namespaceList";

export enum ImageType {
    MAP = "MAP",
    EXTERIOR = "EXTERIOR",
    FLOORPLAN = "FLOORPLAN",
    HEADER = "HEADER",
    ICON = "ICON",
    IMPRESSION = "IMPRESSION",
    INTERIOR = "INTERIOR",
    LOCATION_MAP = "LOCATION_MAP",
    LOGO = "LOGO",
    MUGSHOT = "MUGSHOT",
    PICTURE = "PICTURE",
    ROADMAP = "ROADMAP",
    UNKNOWN = "UNKNOWN",
    VIDEO360 = "VIDEO360",
    OPERATIONS = "OPERATIONS",
    STATUSICON = "STATUSICON",
    VIDEO = "VIDEO",
}

const chooseImageTypeMultiSelectSpec = (): Array<SomeInputSpec<any, any>> => [
    {
        variable: "chooseImageTypes",
        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "chooseImageType"),
        default: false,
        type: "checkbox",
    },
    {
        variable: "imageTypes",
        type: "multiselect",
        label: getI18nLocaleObject(namespaceList.dynamicPlugin, "imageType"),
        optionList: () => Object.values(ImageType).map((imageType) => ({ value: imageType, text: getI18nLocaleString(namespaceList.admin, "imageType_" + imageType) })),
        visible: (options: any) => options.chooseImageTypes,
    },
];

export default chooseImageTypeMultiSelectSpec;
