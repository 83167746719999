import { Aggregation, ApiCallOptions, AvailabilityRequest, AvailabilityResult, StayPeriodDef } from "@maxxton/cms-mxts-api";

import { AVAILABILITY_CONSTANTS } from "./constants";
import { AggregationRequest } from "../plugins/shared/priceMatrix/priceMatrix.types";
import { ApiContext } from "../containers/cmsProvider.types";
import { DEFAULT_PRICE_STAY_TABLE_SIZE } from "../plugins/shared/priceMatrix/priceMatrix.enum";
import { getAvailability } from "./availability.util";
import { getMxtsEnv } from "../plugins/mxts";

export const getPriceAggregation = async (apiContext: ApiContext, aggregationRequest: AggregationRequest): Promise<AvailabilityResult> => {
    const { arrivalDate, distributionChannelId, resourceId, env, size, specialCodes } = aggregationRequest;

    const PRICE_TABLE_AGGREGATION: Aggregation = {
        name: "PRICE_STAY_TABLE",
        type: "OTHER",
        date: arrivalDate,
        size: size || DEFAULT_PRICE_STAY_TABLE_SIZE,
    };
    const baseFilter: AvailabilityRequest = {
        application: AVAILABILITY_CONSTANTS.APPLICATION,
        distributionChannelId: distributionChannelId!,
        maxDuration: AVAILABILITY_CONSTANTS.MAX_DURATION,
        resourceId,
        aggregations: [PRICE_TABLE_AGGREGATION],
        releaseStatus: "OPEN",
        specialCodes,
    };
    const availability: AvailabilityResult = await getAvailability(apiContext, env, baseFilter);
    return availability;
};

export const getStayPeriodDefData = async (apiContext: ApiContext, env?: ApiCallOptions) => {
    const environment = env || (await getMxtsEnv(apiContext, "en"));
    const fetchedStayPeriodDefs: StayPeriodDef[] = await apiContext.mxtsApi.stayPeriodDefs(environment, { stayPeriodDefIds: undefined, size: 34 }).then((result) => result.content);
    // sort by priority
    fetchedStayPeriodDefs.sort((a: any, b: any) => a.priority - b.priority);
    return fetchedStayPeriodDefs;
};
