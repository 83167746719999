import * as React from "react";

import { getI18nLocaleString } from "../../i18n";
import namespaceList from "../../i18n/namespaceList";

interface SpinnerInputProps {
    value: number;
    maxValue: number;
    minValue: number;
    onChange: (value: number) => void;
}

interface SpinnerInputState {}

export class SpinnerInput extends React.PureComponent<SpinnerInputProps, SpinnerInputState> {
    constructor(props: SpinnerInputProps) {
        super(props);
        this.state = { value: props.value };
    }

    public render(): JSX.Element | null {
        const { value, minValue, maxValue } = this.props;
        return (
            <div className="search-window__count">
                <div className={`search-window__button ${value > minValue ? "" : "search-window__button--disabled"}`} onClick={value > minValue ? this.decrease.bind(this) : null}>
                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "decreaseSign")}
                </div>
                <input className="search-window__input" value={value} type="number" onChange={this.onValueChange.bind(this)} />
                <div className={`search-window__button ${value < maxValue ? "" : "search-window__button--disabled"}`} onClick={value < maxValue ? this.increase.bind(this) : null}>
                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "increaseSign")}
                </div>
            </div>
        );
    }

    private decrease = () => {
        this.onValueChange(undefined, this.props.value - 1);
    };

    private increase = () => {
        this.onValueChange(undefined, this.props.value + 1);
    };

    private onValueChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: number) => {
        const { minValue, maxValue } = this.props;
        if (event) {
            newValue = +event.target.value;
        }
        newValue = newValue || 0;
        if (newValue > maxValue) {
            newValue = maxValue;
        }
        if (newValue < minValue) {
            newValue = minValue;
        }
        this.props.onChange(newValue);
    };
}
