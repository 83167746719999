import * as React from "react";

interface WithChildrenProps {
    children?: React.ReactNode;
}

function withChildrenProps<P>(WrappedComponent: React.ComponentType<P & WithChildrenProps>) {
    return React.forwardRef((props: P & WithChildrenProps, forwardedRef: React.ForwardedRef<unknown>) => {
        const { children, ...restProps } = props;
        return <WrappedComponent {...(restProps as P)} children={children} ref={forwardedRef as unknown} />;
    });
}

export default withChildrenProps;
