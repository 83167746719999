import { BillLine, ChoiceReservedResource } from "@maxxton/cms-mxts-api";
import { billLinesSelector, billReservedResourcesSelector, totalBillLinesSelector } from "../redux/selectors/BillStoreSelectors";

import { ActivityPlannerState } from "../redux/reducers/activityPlannerReducer";
import { AdditionState } from "../redux/reducers/additionReducer";
import { AvailabilityState } from "../redux/reducers/availability.types";
import { BillState } from "../redux/reducers/billReducer";
import { DynamicFilter } from "../redux/reducers/dynamicFilter.types";
import { InstalmentsState } from "../redux/reducers/instalmentsState";
import { MyEnvState } from "../redux/reducers/myEnv/myEnvState";
import { ReservationCustomerState } from "../redux/reducers/reservationCustomerReducer";
import { ReservationState } from "../redux/reducers/reservationReducer";
import { SelectedAddOnsState } from "../redux/reducers/add-ons/selectedAddOnsReducer";
import { SpecialSearchState } from "../redux/reducers/specialSearchReducer";
import { State } from "../redux";
import { travelPartyState } from "../redux/reducers/travelParty";

export class SagaUtils {
    static getAdditionState(state: State): AdditionState {
        return state.additionState;
    }

    static getActivityPlannerState(state: State): ActivityPlannerState {
        return state.activityPlannerState;
    }

    static getAvailabilityState(state: State): AvailabilityState {
        return state.availabilityState;
    }

    static getBillState(state: State): BillState {
        return state.billState;
    }

    static getTotalBillLines(state: State): BillLine[] {
        return totalBillLinesSelector(state);
    }

    static getBillLines(state: State): BillLine[] {
        return billLinesSelector(state);
    }

    static getBillReservedResources(state: State): ChoiceReservedResource[] {
        return billReservedResourcesSelector(state);
    }

    static getMyEnvState(state: State): MyEnvState {
        return state.myEnvState;
    }

    static getInstalmentsState(state: State): InstalmentsState {
        return state.instalmentsState;
    }

    static getDynamicFilter(state: State): DynamicFilter {
        return state.dynamicFilter;
    }

    static getReservationState(state: State): ReservationState {
        return state.reservationState;
    }

    static getSelectedAddOnsState(state: State): SelectedAddOnsState {
        return state.selectedAddOnsState;
    }

    static getSpecialSearchState(state: State): SpecialSearchState {
        return state.specialSearchState;
    }

    static getReservationCustomerState(state: State): ReservationCustomerState {
        return state.reservationCustomerState;
    }

    static getTravelPartyState(state: State): travelPartyState {
        return state.travelPartyState;
    }
}
