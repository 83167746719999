import * as React from "react";

const useDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = React.useState(false);

    React.useEffect(() => {
        const darkModeMediaQuery = window?.matchMedia("(prefers-color-scheme: dark)");
        const handleDarkModeChange = (e: MediaQueryListEvent) => {
            setIsDarkMode(e.matches);
        };

        setIsDarkMode(darkModeMediaQuery.matches);
        darkModeMediaQuery.addEventListener("change", handleDarkModeChange);

        return () => {
            darkModeMediaQuery.removeEventListener("change", handleDarkModeChange);
        };
    }, []);

    return isDarkMode;
};

export default useDarkMode;
