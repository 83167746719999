import { ApiCallOptions, MxtsApi, PagedResult, PointsOfInterestCategoriesResponse, getAll } from "@maxxton/cms-mxts-api";
import { TipsAndTripsInfo, TipsAndTripsViewStyle } from "./tipsAndTrips.enum";

import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { MXTS } from "../../../utils/constants";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { SelectOptionLazyLoadResponse } from "../../../components/generic-form/LazyLoadAutoComplete";
import { getAdminMxtsEnv } from "../../mxts";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export const tipsAndTripsSelectionOptions = () => [
    { text: getI18nLocaleObject(namespaceList.admin, "title"), value: TipsAndTripsInfo.TITLE },
    { text: getI18nLocaleObject(namespaceList.dynamicPlugin, "headText"), value: TipsAndTripsInfo.HEADTEXT },
    { text: getI18nLocaleObject(namespaceList.admin, "text"), value: TipsAndTripsInfo.TEXT },
    { text: getI18nLocaleObject(namespaceList.admin, "description"), value: TipsAndTripsInfo.DESCRIPTION },
    { text: getI18nLocaleObject(namespaceList.dynamicPlugin, "description2"), value: TipsAndTripsInfo.DESCRIPTION2 },
    { text: getI18nLocaleObject(namespaceList.admin, "image"), value: TipsAndTripsInfo.IMAGE },
    { text: getI18nLocaleObject(namespaceList.widgetAddOns, "actionButtons"), value: TipsAndTripsInfo.ACTION_BUTTON },
];

export const getViewStyles = (currentLayout: string, gridStyles?: string, listStyles?: string) => (currentLayout === TipsAndTripsViewStyle.GRID || "" ? gridStyles : listStyles || "");

export const fetchPointsOfInterest = async (env: ApiCallOptions, ids?: number[]) => {
    const pointsOfInterest = await getAll((page: number) => MxtsApi.getPointsOfInterest(env, { page, size: MXTS.MAX_RESULTS, pointsOfInterestIds: ids }));
    return pointsOfInterest?.content;
};

export const getResortAndPoiLinks = async (env: ApiCallOptions, ids?: number[]) => {
    const resortAndPoiLinking = await MxtsApi.getPointsOfInterestLinking(env, { size: MXTS.MAX_RESULTS, resortIds: ids });
    return resortAndPoiLinking?.content;
};

export const fetchSpecificPointsOfInterest = async (env: ApiCallOptions, dynamicFilter: DynamicFilter) => {
    const { pointsOfInterestIds, resortids } = dynamicFilter;
    if (resortids?.length) {
        const resortAndPoiLinks = await getResortAndPoiLinks(env, resortids);
        if (resortAndPoiLinks?.length) {
            const response = await fetchPointsOfInterest(
                env,
                resortAndPoiLinks.map((link) => link?.pointsOfInterestId)
            );
            return response;
        }
    } else if (pointsOfInterestIds?.length) {
        const response = await fetchPointsOfInterest(env, pointsOfInterestIds);
        return response;
    }
};

export const getPointsOfInterestCategories = async (env: ApiCallOptions, ids?: number[]) => {
    const categories = await MxtsApi.getPointsOfInterestCategories(env, { size: MXTS.MAX_RESULTS, pointsOfInterestCategoryIds: ids });
    return categories?.content;
};

export async function pointsOfInterestCategoryOptions(page: number, searchQuery?: string, ids?: string[]): Promise<SelectOptionLazyLoadResponse<PointsOfInterestCategoriesResponse, string>> {
    const env = await getAdminMxtsEnv();
    const categories: PagedResult<PointsOfInterestCategoriesResponse> = await MxtsApi.getPointsOfInterestCategories(env, {
        size: MXTS.MAX_RESULTS,
        page,
        name: searchQuery,
        ids: ids?.map((id) => +id),
    });
    return {
        selectOptions: categories?.content?.map(
            (category: PointsOfInterestCategoriesResponse): SelectOption<string> => ({
                value: category.pointsOfInterestCategoryId.toString(),
                label: category?.name || category?.code,
            })
        ),
        pagedResult: categories,
    };
}
