import { isClientSide } from "./generic.util";

// Function to get the device width
type DeviceWidthType = () => number;

const deviceWidth: DeviceWidthType = () => (isClientSide() ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth : 0);

export default deviceWidth;

export function isLandscapeOrientation(): boolean {
    return isClientSide() ? window.matchMedia("(orientation: landscape)").matches : false;
}

export function isPortraitOrientation(): boolean {
    return isClientSide() ? window.matchMedia("(orientation: portrait)").matches : false;
}
