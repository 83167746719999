import * as Icons from "../plugins/page/icons";

import { CacheManagerApi, Category, CategoryApi, CmsApi, Locale, LocaleApi, Post, PostApi, Site, SiteApi, SiteGroupApi, Tag, WebContent, WebContentApi, Widget, WithId } from "@maxxton/cms-api";
import { GroupOptionSpecs, InputSpec, InputSpecMulti, InputSpecSelect, InputSpecStaticTabs, InputSpecTabbed, InputSpecTag, SomeInputSpec, StaticTab, Tab, isStaticTabsSpec } from "./";
import { I18nLocaleObject, getI18nLocaleObject } from "../i18n";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { findStyles, findTheme } from "../themes";

import { SelectOption } from "./formSpec.types";
import { WidgetSpec } from "../plugins";
import { cloneDeep } from "lodash";
import { getCMSOptions } from "../plugins/settings";
import { getDynamicTags } from "../components/generic-form/util/tags.util";
import namespaceList from "../i18n/namespaceList";

interface LocalizedOptions<E, P extends keyof E, TE> extends GroupOptionSpecs {
    variable: P;
    label?: I18nLocaleObject | string;
    tabContent: Array<SomeInputSpec<TE, keyof TE>>;
    visible?: (item: any) => boolean;
}

export interface GroupOptions<E, P extends keyof E> extends GroupOptionSpecs {
    variable: P;
    visible?: (item: E) => boolean;
}

interface TagOptions<E, P extends keyof E> {
    variable: P;
    tags: (item: E) => Tag[];
}

export const findAffectedSites = async (id: string, type: string) => {
    const sites = await SiteApi.find({ projection: { sitemap: 0 } });
    const affectedSites = [];
    if (type === "page") {
        await Promise.all(
            sites.map(async (site: Site & WithId) => {
                if (site.host !== "*") {
                    const isAffectedSite = await SiteApi.findSitemapByPageAndSite({ siteId: site._id, pageId: id });
                    if (isAffectedSite) {
                        affectedSites.push({ id: site._id, name: site.name, updatedAt: (site as any).updatedAt });
                    }
                }
            })
        );
    } else if (type === "flow") {
        for (const site of sites) {
            if (site.host !== "*" && site.flowId === id.toString()) {
                affectedSites.push({ id: site._id, name: site.name, updatedAt: (site as any).updatedAt });
            }
        }
    }
    return affectedSites;
};

export const findAffectedAssets = async (id: string, variable: string) => CacheManagerApi.findAffectedPagesAndTemplates(id, variable);

export type IconColoring = "color-brand" | "color-cta" | "color-brand-alt" | "color-white" | "color-grey";

export const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

export function localized<E extends { P: TE[] }, P extends keyof E, TE extends { locale: string }>(options: LocalizedOptions<E, P, TE>): InputSpecTabbed<E, P, TE, "locale"> {
    const { variable, label, tabContent, visible, groupName, groupTitle, groupDescription } = options;
    return {
        type: "tabbed",
        label,
        visible,
        variable,
        tabVariable: "locale",
        groupName,
        groupTitle,
        groupDescription,
        async tabs(): Promise<Array<Tab<string>>> {
            const locales = await LocaleApi.find();
            const cmsOptions = await getCMSOptions(CmsApi);
            const localeCodes = cmsOptions.selectedLocales ? cmsOptions.selectedLocales.map((locale) => locale.value) : undefined;
            const newLocales: Array<Locale & WithId> = [];
            if (localeCodes) {
                localeCodes.forEach((locale) => {
                    newLocales.push(...locales.filter((loc) => loc.code === locale));
                });
            }
            return (newLocales.length > 0 ? newLocales : locales).map(
                (locale: Locale & WithId): Tab<string> => ({
                    name: locale.name,
                    value: locale._id,
                })
            );
        },
        tabContent,
    };
}

export const getEnvType = () =>
    typeof location !== "undefined" ? (location.hostname.indexOf("test") > -1 || location.hostname.indexOf("dev") > -1 || location.hostname.indexOf("local") ? "Test" : "Production") : "Test";

// Used to convert the input string into a hexadecimal value for mongoDb id
export function getHelpId(str: string) {
    let hex = "";
    for (let i = 0; i < str.length; i++) {
        hex += "" + str.charCodeAt(i).toString(16);
    }
    return hex.substr(0, 24);
}

export function tagSpec<E, P extends keyof E>(options: TagOptions<E, P>): InputSpecTag<E, P> {
    const { variable, tags } = options;
    return {
        label: getI18nLocaleObject(namespaceList.admin, "tag"),
        variable,
        type: "tag",
        tags,
        async suggestions(): Promise<Tag[]> {
            return tagList();
        },
    };
}

export function multiSelectSpec<E, P extends keyof E>(
    variable: P,
    label: I18nLocaleObject | string,
    isStatic: boolean,
    callFun: (item?: E) => Promise<any[]> | any[],
    visible?: (item: E) => boolean,
    groupName?: string,
    groupTitle?: string,
    groupDescription?: string
): InputSpecMulti<E, P> {
    const groupingOptions = {
        groupName,
        groupTitle,
        groupDescription,
    };

    if (!isStatic) {
        return {
            variable,
            visible,
            label,
            type: "multiselect",
            ...groupingOptions,
            async optionList({ item }): Promise<any[]> {
                return callFun(item);
            },
        };
    }
    return {
        variable,
        visible,
        label,
        type: "multiselect",
        ...groupingOptions,
        optionList({ item }) {
            return callFun(item);
        },
    };
}

export function multiSelectCategory<E, P extends keyof E>(variable: P, label?: I18nLocaleObject | string): InputSpecMulti<E, P> {
    return {
        variable,
        label: label || getI18nLocaleObject(namespaceList.widgetAssetPublisher, "followingCategory"),
        type: "multiselect",
        async optionList(): Promise<any[]> {
            const categoryNamesList: Category[] = [];
            const categories = await CategoryApi.find();
            categories.forEach((key) => {
                categoryNamesList.push(key);
            });
            return categoryNamesList.map(
                (category: Category): NumberMultiSelectOption => ({
                    value: category.categoryId,
                    text: category.name,
                })
            );
        },
    };
}

export async function tagList(): Promise<Tag[]> {
    const webContents = await WebContentApi.find({ projection: { tags: 1 } });
    const posts = await PostApi.find({ projection: { tags: 1 } });
    let tags: Tag[] = [];
    let uniqueTags: Tag[] = [];
    webContents.forEach((element: WebContent) => {
        tags = tags.concat(element.tags);
    });
    posts.forEach((element: Post) => {
        tags = tags.concat(element.tags);
    });
    const dynamicTags = await getDynamicTags();
    if (dynamicTags?.length) {
        uniqueTags = [...uniqueTags, ...dynamicTags];
    }
    for (const tagItem of tags) {
        const item = uniqueTags.find((tag) => tag.text === tagItem.text);
        if (!item) {
            uniqueTags.push(tagItem);
        }
    }
    return uniqueTags;
}

export async function categoryList(): Promise<Category[]> {
    const categories = await CategoryApi.find({ projection: { name: 1, categoryId: 1 } });
    const categoryList: Category[] = [];
    categories.forEach((element: Category) => {
        categoryList.push(element);
    });
    return categoryList;
}

export function stylePicker<E, P extends keyof E>(variable: P, targets: string[]): InputSpecSelect<E, P> {
    return {
        variable,
        label: getI18nLocaleObject(namespaceList.admin, "style"),
        type: "select",
        async optionList(): Promise<Array<SelectOption<E[P]>>> {
            const options = await getCMSOptions(CmsApi);
            const theme = findTheme(options.theme.themeId);
            const staticStyles = [{ value: null, label: getI18nLocaleObject(namespaceList.admin, "noStyle") }];
            const dynamicStyles = findStyles(theme, targets).map((style) => ({
                value: style.id,
                label: () => style.name,
            }));
            return ([...staticStyles, ...dynamicStyles] as any) as Array<SelectOption<E[P]>>;
        },
    };
}

export function multiSelectStylePicker<E, P extends keyof E>(variable: P, targets: string[], visible?: (item: E) => boolean): InputSpecMulti<E, P> {
    return {
        variable,
        visible,
        label: getI18nLocaleObject(namespaceList.admin, "style"),
        type: "multiselect",
        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForStyleSelector"),
        async optionList(): Promise<any[]> {
            const options = await getCMSOptions(CmsApi);
            const theme = findTheme(options.theme.themeId);
            const staticStyles = [{ value: null, label: getI18nLocaleObject(namespaceList.admin, "noStyle") }];
            const dynamicStyles = findStyles(theme, targets).map((style) => ({
                value: style.id,
                text: style.name,
            }));
            return [...staticStyles, ...dynamicStyles] as any[];
        },
    };
}

export function mergeProperties<E, P extends keyof E>(
    widgetSpec: Array<SomeInputSpec<E, P>>,
    parentWidgetSpec: Exclude<WidgetSpec<E>["childOptions"], undefined>,
    parentValues: Widget
): Array<SomeInputSpec<E, P>> {
    const parentWidgetSpecChildOptions = cloneDeep(parentWidgetSpec) as Array<InputSpecStaticTabs<E, P>>;
    const filteredParentWidgetChildOptions = parentWidgetSpecChildOptions[0].tabs.filter((tab) => !tab.visible || tab.visible({} as E, undefined, parentValues));
    parentWidgetSpecChildOptions[0].tabs = filteredParentWidgetChildOptions;
    const propArrays = [widgetSpec, parentWidgetSpecChildOptions];
    if (propArrays.length === 0) {
        throw new Error("mergeProperties needs at least one array of properties");
    }
    const general: Array<SomeInputSpec<E, P>> = [];
    const tabs: Array<StaticTab<E>> = [];
    for (const props of propArrays) {
        const first: InputSpec<E, P> = props[0];
        if (props.length === 1 && isStaticTabsSpec(first)) {
            tabs.push(...first.tabs);
        } else {
            general.push(...props);
        }
    }
    if (general.length > 0) {
        tabs.unshift({
            name: getI18nLocaleObject(namespaceList.admin, "general"),
            properties: [general],
        });
    }

    const uniqueTabs: Array<StaticTab<E>> = [];
    tabs.forEach((tab) => {
        const itemIndex = uniqueTabs.findIndex((tabItem) => tabItem.name === tab.name);
        if (itemIndex === -1) {
            uniqueTabs.push(tab);
        } else {
            uniqueTabs[itemIndex] = { ...uniqueTabs[itemIndex], properties: [...uniqueTabs[itemIndex].properties, ...tab.properties] };
        }
    });

    return [
        {
            type: "statictabs",
            tabs: uniqueTabs,
        },
    ];
}

export async function getSiteGroupOptions(): Promise<StringMultiSelectOption[]> {
    const siteGroups = await SiteGroupApi.find();
    const siteGroupOptions = siteGroups.map(
        (siteGroup): StringMultiSelectOption => ({
            value: siteGroup._id,
            text: siteGroup.name,
        })
    );

    return siteGroupOptions;
}
