import * as React from "react";
import * as moment from "moment";

import { PathCache, loadPath, loadSiteRoutes } from "../routing";
import { PathComponent, Route } from "../routing/routing.types";
import { Route as RouteCase, Routes } from "react-router-dom";
import { WebContent, WithId } from "@maxxton/cms-api";
import { getNoDataFoundContent, getNoDataFoundTemplate, getWebManagerEnvironment, isClientLoggedIn } from "./utils";
import withRouter, { RouteComponentProps } from "../routing/withRouter";

import { AlertsPopup } from "./AlertsPopup";
import { AppWideReCaptchaProvider } from "../plugins/page/guestInterfaceWidget/recaptcha/reCaptcha.util";
import { CMSProvider } from "../containers/CmsProvider";
import { CMSProviderProperties } from "../containers/cmsProvider.types";
import { Environment, getMxtsEnv } from "../plugins/mxts";
import LinearProgressBar from "./LinearProgressBar";
import { PageWrapper } from "./PageWrapper";
import { Permissions } from "@maxxton/cms-mxts-api";
import { PermissionsProvider } from "../containers/PermissionsProvider";
import { Provider } from "react-redux";
import { daLocaleSpecification } from "../i18n/momentLocales/daLocale";
import { deLocaleSpecification } from "../i18n/momentLocales/deLocale";
import { esLocaleSpecification } from "../i18n/momentLocales/esLocale";
import { frLocaleSpecification } from "../i18n/momentLocales/frLocale";
import { gaLocaleSpecification } from "../i18n/momentLocales/gaLocale";
import { getPermissions } from "../utils/permissions.utils";
import { huLocaleSpecification } from "../i18n/momentLocales/huLocale";
import { initializeMixPanel } from "./mix-panel/mixpanel.util";
import { isAuthorized } from "../app";
import { isClientSide } from "../utils/generic.util";
import { isEqual } from "lodash";
import { itLocaleSpecification } from "../i18n/momentLocales/itLocale";
import { nlLocaleSpecification } from "../i18n/momentLocales/nlLocale";
import { parse } from "query-string";
import { plLocaleSpecification } from "../i18n/momentLocales/plLocale";
import { renderNoResultsFoundContent } from "../plugins/dynamic/containerWidget.util";

interface AppBaseProps {
    prepared: PathCache;
    routes: Route[];
    env: Environment | null;
    context: CMSProviderProperties;
    childComponent: PathComponent | null;
}

export type AppProps = AppBaseProps & RouteComponentProps;

export interface AppState {
    isAdmin: boolean;
    path: string;
    permissions: Permissions;
    loading: boolean;
    childComponent: PathComponent | null;
    isDCTypeOfConfigDCType: boolean;
    fallbackContentForDCType: JSX.Element | null;
}

class App extends React.Component<AppProps, AppState> {
    private pathCache: PathCache;

    constructor(props: AppProps) {
        super(props);
        this.pathCache = { ...props.prepared };
        this.state = {
            isAdmin: false,
            path: props.context.customPath !== "" ? props.context.customPath : (props.context.location as any).pathname,
            permissions: {},
            loading: false,
            childComponent: props.childComponent,
            isDCTypeOfConfigDCType: true,
            fallbackContentForDCType: null,
        };
    }

    public componentDidMount() {
        if ((window as any).mixpanel && isClientLoggedIn()) {
            const activeEnvironment = getWebManagerEnvironment() || "dev";
            activeEnvironment === "dev" && initializeMixPanel(activeEnvironment, true, this.props);
        }
        if (!this.props.childComponent) {
            this.load();
        }
        this.validateDCTypeAgainstConfiguredDCType(this.props);
        this.loadPermissions();
        this.loadMomentLocaleSpec();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<AppProps>): void {
        if (nextProps.context.location.pathname !== this.state.path) {
            this.load(nextProps);
        }
    }

    public componentDidUpdate(prevProps: Readonly<AppProps>): void {
        const { reduxStore } = this.props.context;
        const { reduxStore: prevReduxStore } = prevProps.context;
        if (!isEqual(prevReduxStore.store.getState().dynamicFilter, reduxStore.store.getState().dynamicFilter)) {
            this.validateDCTypeAgainstConfiguredDCType(this.props);
        }
    }

    public componentWillUnmount(): void {
        localStorage.removeItem("pageNavigation");
    }

    private validateDCTypeAgainstConfiguredDCType = async (props: AppProps) => {
        const { context } = props;
        const { reduxStore } = context;
        const env = await getMxtsEnv(context);
        let isDCTypeOfConfigDCType = true;
        let fallbackTemplate: JSX.Element[] | null = null;
        let fallbackWebContent: (WebContent & WithId) | null = null;
        let fallbackContentForDCType: JSX.Element | null = null;
        if (context?.site?.useDCGroup && context?.site.useDistributionChannelType) {
            const options = parse(context.location.search);
            const reduxState = reduxStore.store.getState();
            const dynamicFilterDistributionChannel = reduxState.dynamicFilter.distributionChannel?.code;
            const urlDistributionChannelCode = options.dc || dynamicFilterDistributionChannel?.toUpperCase();
            if (env && urlDistributionChannelCode) {
                const distributionChannel = await context.mxtsApi.distributionChannels(env, { codes: [urlDistributionChannelCode] });
                if (!distributionChannel.content.length || distributionChannel.content[0]?.inheritedType !== context?.site?.useDistributionChannelType) {
                    isDCTypeOfConfigDCType = false;
                }
            }
            if (context.site?.dcFallbackTemplateId) {
                fallbackTemplate = await getNoDataFoundTemplate(context.site.dcFallbackTemplateId, context);
            }
            if (context.site?.dcFallbackWebContentId) {
                fallbackWebContent = await getNoDataFoundContent(context.site.dcFallbackWebContentId);
            }
            if (!urlDistributionChannelCode && !context.location.pathname?.startsWith("/webmanager")) {
                isDCTypeOfConfigDCType = false;
            }
            fallbackContentForDCType = renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context });
        }

        this.setState({ isDCTypeOfConfigDCType, fallbackContentForDCType });
    };

    public render(): JSX.Element | null {
        const { context } = this.props;
        const { path, permissions, loading, isDCTypeOfConfigDCType, fallbackContentForDCType } = this.state;
        const isAdminPage = path.startsWith("/webmanager");
        const pageWrapperElement =
            !isDCTypeOfConfigDCType && !isAdminPage ? fallbackContentForDCType : <PageWrapper pathName={path} {...{ ...this.props, childComponent: this.state.childComponent }} />;

        const mainComponent = (
            <CMSProvider {...context}>
                <PermissionsProvider value={permissions}>
                    <AppWideReCaptchaProvider context={context}>
                        <React.Fragment>
                            <div className="page-loading-progressbar">
                                <LinearProgressBar loading={loading} />
                            </div>
                            {isClientSide() && context.customPath !== "" ? (
                                pageWrapperElement
                            ) : (
                                <Routes>
                                    <RouteCase path="/*" element={pageWrapperElement} />
                                </Routes>
                            )}
                            <AlertsPopup alerts={context.alerts} />
                        </React.Fragment>
                    </AppWideReCaptchaProvider>
                </PermissionsProvider>
            </CMSProvider>
        );
        const appComponent = mainComponent;
        return path ? <Provider store={context.reduxStore.store}>{appComponent}</Provider> : null;
    }

    private async loadPermissions() {
        const { permissions } = this.state;
        const isAdmin = !!document.getElementsByClassName("backend").item(0);
        let newPermissions: Permissions = permissions;
        if (!isAdmin) {
            if (localStorage) {
                const stack = JSON.parse(localStorage.getItem("pageNavigation") || "[]");
                if (stack.length > 0 && !(window.location.href.split("?")[0] === stack[stack.length - 1]) && stack[stack.length - 1] !== window.location.href) {
                    stack.push(window.location.href);
                } else if (stack.length === 0) {
                    stack.push(window.location.href);
                }
                localStorage.setItem("pageNavigation", JSON.stringify(stack));
            }
        } else {
            newPermissions = await getPermissions(this.props.context);
        }
        this.setState({ isAdmin, permissions: newPermissions, loading: false });
    }

    private async load(props = this.props) {
        this.setState({ loading: true });
        const { context, routes, env } = props;
        const requestedPath = props.context.customPath !== "" ? props.context.customPath : (props.context.location as any).pathname;
        const currentRoute = await loadSiteRoutes(context, context.device);
        loadPath([...(currentRoute ?? []), ...routes], requestedPath, isAuthorized(env), context)
            .then(async (entry: any) => {
                if (entry === undefined) {
                    throw Error("Failed to load path");
                }
                this.pathCache = {};
                this.pathCache[entry.key] = entry.value;
                const currentPath = props.context.customPath !== "" ? props.context.customPath : (props.context.location as any).pathname;
                if (requestedPath === currentPath) {
                    this.setState(
                        {
                            path: requestedPath,
                            loading: false,
                            childComponent: entry.value,
                        },
                        () => {
                            if (window.scrollY) {
                                // reset the scroll position to the top left of the document.
                                window.scroll(0, 0);
                            }
                        }
                    );
                }
            })
            .catch();
    }

    private loadMomentLocaleSpec() {
        switch (this.props.context.currentLocale.code) {
            case "da":
                moment.updateLocale("da", daLocaleSpecification);
                break;
            case "de":
                moment.updateLocale("de", deLocaleSpecification);
                break;
            case "es":
                moment.updateLocale("es", esLocaleSpecification);
                break;
            case "fr":
                moment.updateLocale("fr", frLocaleSpecification);
                break;
            case "ga":
                moment.updateLocale("ga", gaLocaleSpecification);
                break;
            case "hu":
                moment.updateLocale("hu", huLocaleSpecification);
                break;
            case "it":
                moment.updateLocale("it", itLocaleSpecification);
                break;
            case "nl":
                moment.updateLocale("nl", nlLocaleSpecification);
                break;
            case "pl":
                moment.updateLocale("pl", plLocaleSpecification);
                break;
        }
    }
}

export default withRouter(App);
