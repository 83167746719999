import { ApiCallOptions, Currency, LinkedRateTypesPerDC, MxtsApi, MxtsApiWrapper, RateTypes, ResourceType, getAll } from "@maxxton/cms-mxts-api";
import { ApiContext, CMSProvidedProperties } from "../containers/cmsProvider.types";
import { LocalizedContentBase, LocalizedOptions, MultiSelectOptionNumberValue, MultiSelectOptionStringValue, globalLogger } from "@maxxton/cms-api";
import { distributionChannelOptions, getDistributionChannelLazyLoadPage, getMxtsEnv, rateTypeOptions } from "../plugins/mxts";

import { MXTS as MXTS_CONSTANTS } from "./constants";
import { SomeInputSpec } from "../form-specs";
import { StringMultiSelectOption } from "../plugins/mxts/selectOption.types";
import { getI18nLocaleObject } from "../i18n";
import { getLocalizedContent } from "./localizedContent.util";
import { globalApiContext } from "../containers/CmsProvider";
import namespaceList from "../i18n/namespaceList";
import { uniq } from "lodash";

export async function getRateTypeFromReservation(params: {
    reservationId: number;
    env: ApiCallOptions;
    mxtsApi: MxtsApiWrapper;
}): Promise<(Pick<RateTypes, "rateTypeId" | "code"> & { currency: Currency }) | undefined> {
    const { reservationId, env, mxtsApi } = params;
    const reservedResources = await mxtsApi.getReservedResources(env, { reservationId, size: MXTS_CONSTANTS.MAX_RESULTS }).catch(() => undefined);
    const accoType = reservedResources?.content.find((reservedResource) => reservedResource.type === ResourceType.ACCOMMODATIONTYPE);

    let rateType: RateTypes | undefined;
    if (accoType?.rateTypeId) {
        const rateTypes = await mxtsApi.rates(env, {
            rateTypeId: accoType?.rateTypeId,
        });
        rateType = rateTypes.content?.[0];
    }
    return rateType && { code: rateType.code, rateTypeId: rateType.rateTypeId, currency: rateType.currency };
}

export const getCurrencyRT = async ({
    currentLocale,
    site,
    apiCallOptions,
    mxtsApi,
    dcId,
}: Pick<CMSProvidedProperties, "currentLocale" | "site" | "mxtsApi"> & { apiCallOptions: ApiCallOptions; dcId?: number }): Promise<RateTypes | undefined> => {
    const localizedSiteOptions: LocalizedOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: site.localizedOptions });
    let selectedRateTypeIds: number[] | undefined = localizedSiteOptions?.rateTypes?.map((rateType) => rateType.value);
    if (site.useDCGroup && dcId) {
        const rateTypeId = (await mxtsApi.rateTypesPerDC(apiCallOptions, { dcId })).content[0].rateTypeId;
        selectedRateTypeIds = [rateTypeId];
    }
    const currencyId: number | undefined = localizedSiteOptions?.currencySelector;
    let rateType: RateTypes | undefined;
    if (selectedRateTypeIds?.length && ((currencyId && typeof currencyId === "number") || site.useDCGroup)) {
        const rateTypes: RateTypes[] | undefined = await mxtsApi
            .rates(apiCallOptions, {
                rateTypeId: selectedRateTypeIds,
            })
            .then((rates) => rates?.content || [])
            .catch((error: Error) => {
                globalLogger.error(error);
                return undefined;
            });
        rateType = rateTypes?.find((rateType) => (site.useDCGroup ? rateType.currency.currencyId : rateType.currency?.currencyId === currencyId));
    }
    return rateType;
};

export interface LocalizedCurrencySelector extends LocalizedContentBase {
    distributionChannelId?: string;
    rateTypes?: MultiSelectOptionNumberValue[];
    currencySelector?: StringMultiSelectOption[];
}

export interface LocalizedMultiDCCurrencySelector extends LocalizedContentBase {
    distributionChannelIds?: MultiSelectOptionStringValue[];
    rateTypes?: MultiSelectOptionStringValue[];
    currencySelector?: MultiSelectOptionStringValue[];
}

export const distributionChannelSpec = {
    variable: "distributionChannelId",
    label: getI18nLocaleObject(namespaceList.admin, "distributionChannel"),
    type: "lazyLoadAutoComplete" as const,
    lazyLoadOptions: (page: number, searchQuery: string, id: string) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
    placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
};

export const rateTypesSpec = {
    variable: "rateTypes",
    label: getI18nLocaleObject(namespaceList.admin, "rateTypes"),
    type: "multiselect" as const,
    optionList: ({ item, locale }: { item?: LocalizedCurrencySelector[]; locale?: string }) => (item && locale ? rateTypeOptions(MxtsApi, item, locale) : []),
    visible: (item: LocalizedCurrencySelector) => !!item?.distributionChannelId,
};

export const multiDistributionChannelSpec = {
    variable: "distributionChannelIds",
    label: getI18nLocaleObject(namespaceList.admin, "distributionChannels"),
    type: "multiselect" as const,
    optionList: async () => (await distributionChannelOptions(MxtsApi)).map(({ label, value }) => ({ text: label, value })),
    placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
};

export const multiDCRateTypesSpec = {
    variable: "rateTypes",
    label: getI18nLocaleObject(namespaceList.admin, "rateTypes"),
    type: "multiselect" as const,
    optionList: ({ item, locale }: { item?: LocalizedMultiDCCurrencySelector[]; locale?: string }) => (item && locale ? getMutliDCRateTypeOptions(globalApiContext(), item, locale) : []),
    visible: (item: LocalizedMultiDCCurrencySelector) => !!item?.distributionChannelIds?.length,
};

interface RateTypesGroupByDC {
    [key: string]: LinkedRateTypesPerDC[];
}

export async function getMutliDCRateTypeOptions(apiContext: ApiContext, localizedOptions: LocalizedMultiDCCurrencySelector[], locale: string): Promise<StringMultiSelectOption[]> {
    const selectedDCIds = localizedOptions.find((i) => i.locale === locale)?.distributionChannelIds?.map((dc) => +dc.value);
    const rateTypeOptions: StringMultiSelectOption[] = [];
    if (!selectedDCIds?.length) {
        return rateTypeOptions;
    }
    const ops = await getMxtsEnv(apiContext);
    const rateTypesForSelectedDCs = (await getAll((page: number) => apiContext.mxtsApi.rateTypesPerDC(ops, { dcId: selectedDCIds, page, size: MXTS_CONSTANTS.MAX_RESULTS }))).content;
    const selectedRateTypeIds = rateTypesForSelectedDCs.map((rateType) => rateType.rateTypeId);
    const rateTypes: RateTypes[] = await apiContext.mxtsApi
        .rates(ops, {
            rateTypeId: uniq(selectedRateTypeIds),
        })
        .then((rates) => rates.content);
    const rateTypesGroupByDC: RateTypesGroupByDC = rateTypesForSelectedDCs.reduce((acc: RateTypesGroupByDC, rateType) => {
        const { distributionChannelId } = rateType;
        if (acc[rateType.distributionChannelId]) {
            acc[distributionChannelId] = [...acc[rateType.distributionChannelId], rateType];
        } else {
            acc[distributionChannelId] = [rateType];
        }
        return acc;
    }, {});

    selectedDCIds.forEach((dcId) => {
        const linkedRateTypes = rateTypesGroupByDC[dcId];
        linkedRateTypes.forEach((linkedRateType) => {
            const {
                rateTypeId,
                distributionChannel: { distributionChannelId, code: distributionChannelCode },
            } = linkedRateType;
            const { code: rateTypeCode, name: rateTypeName } = rateTypes.find((rt) => rt.rateTypeId === rateTypeId) || {};
            rateTypeOptions.push({
                text: `${distributionChannelCode}-${rateTypeCode} ${rateTypeName}`,
                value: `dcId:${distributionChannelId},rtId:${rateTypeId}`,
            });
        });
    });
    return rateTypeOptions;
}

async function getMultiDCCurrencyOptions(apiContext: ApiContext, localeContent?: LocalizedMultiDCCurrencySelector | null): Promise<StringMultiSelectOption[]> {
    const currencyOptions: StringMultiSelectOption[] = [];
    const selectedDCIds = localeContent?.distributionChannelIds?.map((dc) => +dc.value);
    const selectedRateTypeIds = localeContent?.rateTypes?.map((dcAndRateTypeId) => +dcAndRateTypeId.value.split(",")[1].split(":")[1]);
    if (!selectedDCIds?.length || !selectedRateTypeIds?.length) {
        return currencyOptions;
    }
    const ops = await getMxtsEnv(apiContext);
    const rateTypesForSelectedDCs = (await getAll((page: number) => apiContext.mxtsApi.rateTypesPerDC(ops, { dcId: selectedDCIds, page, size: MXTS_CONSTANTS.MAX_RESULTS }))).content;
    const rateTypes: RateTypes[] = await apiContext.mxtsApi
        .rates(ops, {
            rateTypeId: uniq(selectedRateTypeIds),
        })
        .then((rates) => rates.content);
    selectedRateTypeIds.forEach((rateTypeId, index) => {
        const selectedDCId = +localeContent!.rateTypes![index].value.split(",")[0].split(":")[1];
        const linkedRateTypes = rateTypesForSelectedDCs.find((linkedRateType) => linkedRateType.rateTypeId === rateTypeId && linkedRateType.distributionChannelId === selectedDCId);
        if (linkedRateTypes) {
            const {
                rateTypeId,
                distributionChannel: { distributionChannelId, code: distributionChannelCode },
            } = linkedRateTypes;
            const { currency, code: rateTypeCode } = rateTypes.find((rt) => rt.rateTypeId === rateTypeId) || {};
            if (currency) {
                currencyOptions.push({
                    value: `dcId:${distributionChannelId},rtId:${rateTypeId},ccyId:${currency.currencyId}`,
                    text: `${distributionChannelCode}-${rateTypeCode}-${currency.code} (${currency.symbol})`,
                });
            }
        }
    });
    return currencyOptions;
}

export async function getCurrencyOptions(apiContext: ApiContext, localeContent?: Pick<LocalizedCurrencySelector, "rateTypes"> | null): Promise<StringMultiSelectOption[]> {
    const selectedRateTypeIds = localeContent?.rateTypes?.map((rateType) => rateType.value);
    let rateTypes: RateTypes[] | undefined;
    if (selectedRateTypeIds?.length) {
        const env = await getMxtsEnv(apiContext);
        rateTypes = await apiContext.mxtsApi
            .rates(env, {
                rateTypeId: selectedRateTypeIds,
            })
            .then((rates) => rates.content);
    }
    const currencyOptions: StringMultiSelectOption[] = [];
    rateTypes?.forEach((rateType) => {
        const { currency, rateTypeId, code } = rateType;
        if (currency) {
            currencyOptions.push({ value: `rtId:${rateTypeId},ccyId:${currency.currencyId}`, text: `${code}-${currency.code} (${currency.symbol})` });
        }
    });
    return currencyOptions;
}

export async function fetchCurrencyForDC(apiContext: ApiContext, distributionChannelId?: number) {
    if (distributionChannelId) {
        const env = await getMxtsEnv(apiContext);
        const distributionChannel = await apiContext.mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: distributionChannelId }]).catch((error: Error) => undefined);
        let currency;
        if (distributionChannel) {
            currency = await apiContext.mxtsApi.currency(env, {}, [{ key: "currencyId", value: +distributionChannel?.currencyId }]).catch((error: Error) => undefined);
        }
        return currency;
    }
}

export const currencySelectorSpec = {
    variable: "currencySelector",
    label: getI18nLocaleObject(namespaceList.admin, "currencySelector"),
    type: "multiselect" as const,
    optionList: async ({ item, locale }: { item?: LocalizedMultiDCCurrencySelector[]; locale?: string }) => {
        const localeContent = item?.find((i) => i.locale === locale);
        const currencyOptions = await getMultiDCCurrencyOptions(globalApiContext(), localeContent);
        return currencyOptions;
    },
    placeholder: getI18nLocaleObject(namespaceList.admin, "defaultPlaceholder"),
    visible: (item: LocalizedMultiDCCurrencySelector) => !!item?.rateTypes?.length,
};

type CurrencySelectorWithMultiDC = "distributionChannelIds" | "rateTypes" | "currencySelector";

export const getCurrencySelector = (): Array<SomeInputSpec<LocalizedMultiDCCurrencySelector, CurrencySelectorWithMultiDC>> => [
    { ...multiDistributionChannelSpec },
    { ...multiDCRateTypesSpec } as any, // TODO: get rid of "any" type
    { ...currencySelectorSpec },
];
