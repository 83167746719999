import { LocaleApi, SiteApi, Translation, TranslationApi, WithId } from "@maxxton/cms-api";
import namespaceList, { namesCorrespondingNamespaces } from "../../i18n/namespaceList";

import { FormSpec } from "../";
import { SelectOption } from "../formSpec.types";
import { translations as dynamicTranslations } from "../../i18n/translations/index";
import { getI18nLocaleObject } from "../../i18n";
import { getSiteOptions } from "../../utils/site.util";
import { getTimeStampOptions } from "../columnSpec.util";
import { localeSpec } from "./locale";
import { siteSpec } from "./site";

export const translationSpec: FormSpec<Translation> = {
    id: "translation",
    name: getI18nLocaleObject(namespaceList.admin, "translation"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "translations"),
    api: TranslationApi,
    permission: "webmanager.translations",
    display(translation: Translation): string {
        return translation.value;
    },
    tableColumns: [
        {
            name: getI18nLocaleObject(namespaceList.admin, "translation"),
            variable: "value",
            type: "text",
        },
        {
            name: getI18nLocaleObject(namespaceList.admin, "translationKey"),
            variable: "key",
            type: "text",
        },
        {
            name: getI18nLocaleObject(namespaceList.admin, "name"),
            variable: "namespace",
            type: "text",
        },
        {
            name: getI18nLocaleObject(namespaceList.admin, "locale"),
            variable: "locale",
            type: "ref",
            refSpec: localeSpec,
        },
        {
            name: getI18nLocaleObject(namespaceList.admin, "site"),
            variable: "site",
            type: "ref",
            refSpec: siteSpec,
        },
        ...getTimeStampOptions(),
    ],
    properties: [
        {
            label: getI18nLocaleObject(namespaceList.admin, "site"),
            variable: "site",
            type: "select",
            placeholder: getI18nLocaleObject(namespaceList.admin, "allSites"),
            async optionList() {
                return getSiteOptions();
            },
            enabled: (item: Translation & WithId) => item._id === undefined,
            required: false,
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "namespace"),
            variable: "namespace",
            type: "autocomplete",
            required: true,
            enabled: (item: Translation & WithId) => item._id === undefined,
            isClearable: false,
            async options() {
                return Object.keys(namespaceList).map(
                    (key: string): SelectOption<string> => ({
                        label: (namesCorrespondingNamespaces as any)[(namespaceList as any)[key]],
                        value: (namespaceList as any)[key],
                    })
                );
            },
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "locale"),
            variable: "locale",
            type: "autocomplete",
            required: true,
            dependsOnSiteSpec: "site",
            enabled: (item: Translation & WithId) => item._id === undefined,
            isClearable: false,
            async options(item: Translation & WithId) {
                const languages: any[] = [];
                if (item.site) {
                    const site = await SiteApi.findById({ id: item.site, projection: { sitemap: 0 } });
                    if (site && site.localeMultiSelect && site.localeMultiSelect.length > 0) {
                        for (const lang of site.localeMultiSelect) {
                            languages.push(await LocaleApi.findById({ id: lang.value }));
                        }
                    } else {
                        languages.push(...(await LocaleApi.find()));
                    }
                } else {
                    // If "All Sites" is selected
                    languages.push(...(await LocaleApi.find()));
                }
                return languages.map((key) => ({
                    label: key.name,
                    value: key._id,
                }));
            },
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "translationKey"),
            variable: "key",
            type: "autocomplete",
            isClearable: false,
            async options(item: any) {
                const { en } = await import("../../i18n/translations/poeditor/en");
                const namespace = Object.keys(en).find((ns: any) => ns === item.namespace);
                const translationKeys = namespace ? Object.keys((en as any)[namespace]) : [];
                const locale = item.locale ? await LocaleApi.findById({ id: item.locale }) : undefined;
                if (locale && namespace && translationKeys.length) {
                    const localeCode = locale.code;
                    const builtinTranslations = await dynamicTranslations[localeCode]();
                    return translationKeys.map((key) => ({
                        label: `${builtinTranslations[locale.code][namespace!][key]} [${key}]`,
                        value: key,
                    }));
                }
                return [];
            },
            enabled: (item: Translation & WithId) => item._id === undefined,
            required: false,
        },
        {
            label: getI18nLocaleObject(namespaceList.admin, "translation"),
            variable: "value",
            type: "textarea",
            required: false,
        },
    ],
};
