import { MxtsDataAccesTokenAction, MxtsDataAccesTokenActionType } from "../actions/MxtsDataAccessTokenAction";

import { ActionType } from "../actions";
import { MxtsDataAccessToken } from "@maxxton/cms-mxts-api";
import { Reducer } from "redux";

export interface MxtsDataAccessTokenState extends MxtsDataAccessToken {}

export const mxtsDataAccessTokenReducer: Reducer<MxtsDataAccessTokenState> = (state: MxtsDataAccessTokenState = { accessKeys: [] }, action: MxtsDataAccesTokenAction): MxtsDataAccessTokenState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.MxtsDataAccessToken:
            switch (actionType) {
                case MxtsDataAccesTokenActionType.update:
                    return { ...state, ...action.payload };
            }
    }
    return state;
};
