import { Theme } from ".";

import baseTheme from "./base";

const theme: Theme = {
    id: "olympia",
    name: "Olympia Theme",
    stylesheets: ["/styles/themes/olympia/olympia-admin.css"],
    frontendStylesheets: ["/styles/themes/olympia/olympia.css"],
    styles: [...baseTheme.styles],
};

export default theme;
