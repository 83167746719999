import { Theme } from "./";
import myEnvironmentTheme from "./myenvironment";

const theme: Theme = {
    id: "farmcamps",
    name: "Farmcamps Theme",
    stylesheets: ["/styles/themes/farmcamps/farmcamps-admin.css"],
    frontendStylesheets: ["/styles/themes/farmcamps/farmcamps.css"],
    styles: [
        ...myEnvironmentTheme.styles,
        {
            id: "my-env-button",
            name: "My Environment button",
            targets: ["button", "description"],
            classnames: ["my-env-button"],
        },
    ],
};

export default theme;
