import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { DependencyMask, PagedResult, ProductSubject, RentabilityMarkerCacheResult, Resource, ResourceType } from "@maxxton/cms-mxts-api";
import { ProductDay, ProductSubjectSelection } from "./products.types";

import { AddOnsWidgetOptions } from "../../add-ons/AddOns.types";
import { AdditionsUtil } from "../additions.util";
import { CMSProvidedProperties } from "../../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../../utils/constants";
import { DateUtil } from "../../../../utils/date.util";
import { DomainObjectUtil } from "../../../../utils/domainobject.util";
import { DynamicFilter } from "../../../../redux/reducers/dynamicFilter.types";
import { GenericAddition } from "../additions.types";
import { Label } from "reactstrap";
import { Loader } from "../../../../components/Loader";
import { NumberUtil } from "../../../../utils/number.util";
import Select from "../../../../components/generic-form/multiselect-component";
import { SpinnerInput } from "../../../../components/generic-form/spinnerInput";
import { StringUtil } from "../../../../utils/string.util";
import { WidgetOptions } from "../index";
import { getI18nLocaleString } from "../../../../i18n";
import { getMxtsEnv } from "../../../mxts";
import namespacesList from "../../../../i18n/namespaceList";

export interface DateOptions {
    label: string;
    value: Date;
}

export enum DateType {
    startDate = "startDate",
    endDate = "endDate",
}

interface DayProductState {
    days?: ProductDay[];
    productSubjects?: ProductSubjectSelection[];
    loaders: { subjects?: boolean; confirmation?: boolean; days?: boolean };
    startDate?: DateOptions;
    endDate?: DateOptions;
    dateChoiceQuantity: number;
    addOnsIdWithRelativePrice: number[];
}

interface DayProductProps {
    addition: GenericAddition;
    context: CMSProvidedProperties;
    confirmProduct: (
        addition: GenericAddition,
        days?: ProductDay[],
        productSubjects?: ProductSubjectSelection[],
        price?: number,
        startDate?: Date,
        endDate?: Date,
        dateChoiceQuantity?: number
    ) => void;
    dynamicFilter: DynamicFilter;
    rateType?: number;
    additionWidgetOptions: WidgetOptions;
}

export class DaySubjectProduct extends React.PureComponent<DayProductProps, DayProductState> {
    constructor(props: DayProductProps) {
        super(props);
        const isDayOrSubjectProduct = AdditionsUtil.isDayOrSubjectProduct(props.addition);
        this.state = {
            loaders: { subjects: isDayOrSubjectProduct, days: isDayOrSubjectProduct || props.addition.datesInternet },
            dateChoiceQuantity: 1,
            addOnsIdWithRelativePrice: [],
        };
    }

    componentDidMount() {
        const { addition } = this.props;
        if (StringUtil.equalsIgnoreCase(addition.type, ResourceType.PRODUCTTYPE)) {
            this.obtainProductSubjects();
            if (AdditionsUtil.isDayProduct(addition) || addition.datesInternet) {
                this.obtainDays();
            } else {
                this.setState({ loaders: { ...this.state.loaders, days: false } });
            }
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { addition, context, dynamicFilter } = this.props;
        const { currentLocale, site } = context;
        const { addOnsIdWithRelativePrice, days, productSubjects, loaders, startDate, endDate, dateChoiceQuantity } = this.state;
        const isDateChoiceProduct = addition.datesInternet && !AdditionsUtil.isDayProduct(addition);
        return (
            <div>
                <div className="package-info">
                    <img
                        loading="lazy"
                        src={
                            addition.image && addition.image.urls
                                ? addition.image.urls.large?.replace("t_newyse", "t_mcms")
                                : "https://newyse-res.cloudinary.com/image/upload/t_mcms_larger/f_auto/image-fallback.jpg"
                        }
                    />
                    <div className="package-title">
                        <h6>
                            <span>{addition.name}</span>
                            {typeof addition.price === "number" ? (
                                <span>
                                    {NumberUtil.priceWithCurrency({
                                        price: addition.price,
                                        isRelativePrice: addOnsIdWithRelativePrice.includes(addition.resourceId),
                                        context,
                                        currencyCode: dynamicFilter?.currency?.code,
                                    })}
                                </span>
                            ) : (
                                <span dangerouslySetInnerHTML={{ __html: addition.price || "-" }} />
                            )}
                        </h6>
                    </div>
                </div>
                <p>{addition.description}</p>
                <div className="row addition-product">
                    <div className="col-md-6 col-12">
                        {loaders.subjects && <Loader type={"additionsList"}></Loader>}
                        {!!productSubjects?.length && (
                            <div className="addition-product__travel-group">
                                <h6>{getI18nLocaleString(namespacesList.widgetAdditions, "subjectProductTravelGroup", currentLocale, site)}</h6>
                                <ul className="travel-group__list">
                                    {productSubjects.map((subject: ProductSubjectSelection) => (
                                        <li key={subject.subjectId}>
                                            <SpinnerInput
                                                value={subject.quantity}
                                                minValue={subject.minQuantity}
                                                maxValue={subject.maxQuantity}
                                                onChange={this.handleSubjectQuantityChange.bind(this, subject)}
                                            ></SpinnerInput>
                                            <span className="travel-group__label">{subject.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {loaders.days && isDateChoiceProduct && <Loader type={"additionsList"}></Loader>}
                        {!!days?.length && isDateChoiceProduct && (
                            <div className="addition-product__dates">
                                <h6>{getI18nLocaleString(namespacesList.widgetAdditions, "bookPeriod", currentLocale, site)}</h6>
                                <ul className="dates__list">
                                    <label className="date-range-label">{getI18nLocaleString(namespacesList.widgetAdditions, "startDate", currentLocale, site)}</label>
                                    <Select
                                        value={startDate}
                                        onChange={(value: DateOptions) => this.handleDateChange(value, DateType.startDate)}
                                        options={this.getDateOptions(DateType.startDate)}
                                        clearable={false}
                                        searchable={false}
                                    />
                                    <label className="date-range-label">{getI18nLocaleString(namespacesList.widgetAdditions, "endDate", currentLocale, site)}</label>
                                    <Select
                                        value={endDate}
                                        onChange={(value: DateOptions) => this.handleDateChange(value, DateType.endDate)}
                                        options={this.getDateOptions(DateType.endDate)}
                                        clearable={false}
                                        searchable={false}
                                    />
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="col-md-6 col-12">
                        {loaders.days && !isDateChoiceProduct && <Loader type={"additionsList"}></Loader>}
                        {!!days?.length && !isDateChoiceProduct && (
                            <div className="addition-product__dates">
                                <h6>{getI18nLocaleString(namespacesList.widgetAdditions, "whichDates", currentLocale, site)}</h6>
                                <ul className="dates__list">
                                    {days.map((day: ProductDay, index) => (
                                        <li key={day.date.getTime()}>
                                            <input type="checkbox" id={`dates-list-${index}`} onChange={this.handleDayChecked.bind(this, day)} checked={!!day.checked} />
                                            <Label className="dates__label" htmlFor={`dates-list-${index}`}>
                                                {DateUtil.formatDate(day.date, DATE_FORMAT.DAY_WITH_DATE)}
                                            </Label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {loaders.days && isDateChoiceProduct && <Loader type={"additionsList"}></Loader>}
                        {!!days?.length && isDateChoiceProduct && (
                            <div className="addition-product__dates">
                                <ul className="dates__list">
                                    <label className="date-range-label">{getI18nLocaleString(namespacesList.widgetAdditions, "quantity", currentLocale, site)}</label>
                                    <SpinnerInput
                                        value={dateChoiceQuantity}
                                        minValue={addition.minBookableQuantity}
                                        maxValue={addition.maxBookableQuantity}
                                        onChange={this.handleDateChoiceQuantity}
                                    ></SpinnerInput>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                <div className="package-submit">
                    <button onClick={this.confirmProduct.bind(this, addition)} className="button button--m button--primary" disabled={loaders.confirmation}>
                        {getI18nLocaleString(namespacesList.widgetAdditions, "addToBill", currentLocale, site)}&nbsp;
                        {loaders.confirmation && <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />}
                    </button>
                </div>
            </div>
        );
    }

    private handleDateChoiceQuantity = (newQuantity: number): void => {
        this.setState({ dateChoiceQuantity: newQuantity });
    };

    private getDateOptions = (dateType: string) => {
        const { days, startDate, endDate } = this.state;
        if (days?.length) {
            if (dateType === DateType.startDate) {
                const endDateIndex = days.findIndex((day) => day.date === endDate?.value);
                return days.filter((_day, index) => index < endDateIndex).map((day: ProductDay) => ({ value: day.date, label: DateUtil.formatDate(day.date, DATE_FORMAT.DISPLAY) }));
            } else if (dateType === DateType.endDate) {
                const startDateIndex = days.findIndex((day) => day.date === startDate?.value);
                return days.filter((_day, index) => index > startDateIndex).map((day: ProductDay) => ({ value: day.date, label: DateUtil.formatDate(day.date, DATE_FORMAT.DISPLAY) }));
            }
        }
    };

    private handleDateChange = (value: DateOptions, dateType: string): void => {
        if (dateType === DateType.startDate) {
            this.setState({ startDate: value });
        } else {
            this.setState({ endDate: value });
        }
    };

    private handleSubjectQuantityChange = (subject: ProductSubjectSelection, newQuantity: number) => {
        subject.quantity = newQuantity;
        this.setState({ productSubjects: [...this.getSubjectsWithUpdatedMaxQuantities(this.state.productSubjects || [])] });
    };

    private handleDayChecked = (day: ProductDay, event: React.ChangeEvent<HTMLInputElement>) => {
        day.checked = event.target.checked;
        this.setState({ days: [...this.state.days!] });
    };

    private confirmProduct = (addition: GenericAddition) => {
        const { startDate, endDate, dateChoiceQuantity } = this.state;
        const isDateChoiceProduct = addition.datesInternet && !AdditionsUtil.isDayProduct(addition);
        const isConfirmationAllowed = this.isConfirmationAllowed();
        if (isDateChoiceProduct || isConfirmationAllowed) {
            this.setState({ loaders: { ...this.state.loaders, confirmation: true } });
            this.fetchAdditionPrice(addition).then((addOn: { price: string | number; isRelativePrice: boolean }) => {
                if (addOn.isRelativePrice) {
                    this.setState(({ addOnsIdWithRelativePrice }) => ({
                        addOnsIdWithRelativePrice: [...addOnsIdWithRelativePrice, addition.resourceId],
                    }));
                }
                this.setState({ loaders: { ...this.state.loaders, confirmation: false } });
                this.props.confirmProduct(
                    addition,
                    this.state.days,
                    this.state.productSubjects,
                    typeof addOn.price === "number" ? addOn.price : undefined,
                    startDate?.value,
                    endDate?.value,
                    dateChoiceQuantity
                );
            });
        }
    };

    private obtainDays(): void {
        const { dynamicFilter } = this.props;
        // TODO: Shouldn't we take the derived endDate here? Based on duration, stayPeriod etc?
        if (!dynamicFilter.startdate || !dynamicFilter.enddate) {
            return;
        }
        const allDays: ProductDay[] = DateUtil.createRangeOfDates(DateUtil.parseDate(dynamicFilter.startdate), DateUtil.parseDate(dynamicFilter.enddate)).map((date) => ({
            date,
        }));
        this.filterDaysByRentability(allDays).then((filteredDays: ProductDay[]) => {
            this.setState({ days: filteredDays, loaders: { ...this.state.loaders, days: false } }, () => {
                const { days } = this.state;
                if (days?.length) {
                    this.setState({
                        startDate: { value: days[0].date, label: DateUtil.formatDate(days[0].date, DATE_FORMAT.DISPLAY) },
                        endDate: { value: days[days.length - 1].date, label: DateUtil.formatDate(days[days.length - 1].date, DATE_FORMAT.DISPLAY) },
                    });
                }
            });
        });
    }

    private async obtainProductSubjects() {
        const { context, addition } = this.props;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const productSubjects: ProductSubject[] = await context.mxtsApi.productSubjects(env, {}, [{ key: "resourceId", value: addition.resourceId }]);
        if (productSubjects?.length) {
            const productSubjectsSelection: ProductSubjectSelection[] = productSubjects.map((subject: ProductSubject) => ({
                subjectId: subject.subjectId,
                resortId: subject.resortId,
                maxAge: subject.maxAge,
                name: subject.name,
                quantity: 0,
                minQuantity: 0,
                maxQuantity: 99,
            }));
            this.setState({ productSubjects: this.getSubjectsWithUpdatedMaxQuantities(productSubjectsSelection) });
        }
        this.setState({ loaders: { ...this.state.loaders, subjects: false } });
    }

    private isConfirmationAllowed(): boolean {
        const { days, productSubjects } = this.state;
        let subjectsValid = true;
        if (productSubjects?.length) {
            subjectsValid = productSubjects.some((productSubject) => productSubject.quantity > 0);
        }
        let daysValid = true;
        if (days?.length) {
            daysValid = days.some((day) => day.checked);
        }
        return subjectsValid && daysValid;
    }

    private async fetchAdditionPrice(
        addition: GenericAddition
    ): Promise<{
        price: string | number;
        isRelativePrice: boolean;
    }> {
        const { context, dynamicFilter, rateType, additionWidgetOptions } = this.props;
        const { productSubjects, days, startDate, endDate } = this.state;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const dynamicFieldCode = AdditionsUtil.getDynamicFieldCode(additionWidgetOptions.dynamicFieldCode);
        const widgetOptionsDynamicFieldCodesPaths: Array<keyof AddOnsWidgetOptions> = ["dynamicFieldCodes"];

        return AdditionsUtil.fetchAdditionPrice({
            apiContext: context,
            env,
            addition,
            dynamicFilter,
            dynamicFieldCode,
            rateType,
            subjects: productSubjects,
            days,
            startDate: startDate?.value,
            endDate: endDate?.value,
            widgetOptionsId: additionWidgetOptions._id,
            widgetOptionsDynamicFieldCodesPaths,
        }).catch(() => ({ price: "-", isRelativePrice: false }));
    }

    private async filterDaysByRentability(allDays: ProductDay[]): Promise<ProductDay[]> {
        // TODO: reuse env in this class?
        const { context, addition, dynamicFilter } = this.props;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        // TODO: obtain this in parallel with getRentabilityMarkerCache to increase performance
        const productResourceDetails: Resource | null = await DomainObjectUtil.getResourceById(context.mxtsApi, addition.resourceId, env);
        if (!dynamicFilter.startdate || !dynamicFilter.enddate || !dynamicFilter.distributionChannel?.distributionChannelId || !productResourceDetails) {
            return allDays;
        }
        const reservationStartDate = DateUtil.parseDate(dynamicFilter.startdate);
        const reservationEndDate = DateUtil.parseDate(dynamicFilter.enddate);
        const currentDateTime = DateUtil.formatDate(new Date(), DATE_FORMAT.MXTS_DATETIME);

        const rentabilityMarkerCaches: RentabilityMarkerCacheResult[] = await context.mxtsApi
            .getRentabilityMarkerCache(env, {
                size: 2000,
                type: "START",
                resourceId: addition.resourceId,
                distributionChannelId: dynamicFilter.distributionChannel.distributionChannelId,
                dateBegin: DateUtil.formatDate(reservationStartDate, DATE_FORMAT.MXTS),
                dateEnd: DateUtil.formatDate(reservationEndDate, DATE_FORMAT.MXTS),
                validFrom: currentDateTime,
                validTo: currentDateTime,
            })
            .then((rentabilityMarkerResult: PagedResult<RentabilityMarkerCacheResult>) => rentabilityMarkerResult.content);

        return allDays
            .filter((day: ProductDay) =>
                rentabilityMarkerCaches.some((markerCache: RentabilityMarkerCacheResult) => DateUtil.isDateDayTheSame(DateUtil.parseDate(markerCache.day, DATE_FORMAT.MXTS), day.date))
            )
            .filter((day: ProductDay) => {
                if (!productResourceDetails.applyDependencies && productResourceDetails.applyDependencyMask != DependencyMask.NONE) {
                    if (
                        (productResourceDetails.applyDependencyMask == DependencyMask.ARRIVAL || productResourceDetails.applyDependencyMask == DependencyMask.ARRIVAL_DEPARTURE) &&
                        DateUtil.isDateDayTheSame(day.date, reservationStartDate)
                    ) {
                        return false;
                    } else if (
                        (productResourceDetails.applyDependencyMask == DependencyMask.DEPARTURE || productResourceDetails.applyDependencyMask == DependencyMask.ARRIVAL_DEPARTURE) &&
                        DateUtil.isDateDayTheSame(day.date, reservationEndDate)
                    ) {
                        return false;
                    }
                    return true;
                } else if (productResourceDetails.applyDependencies && productResourceDetails.applyDependencyMask != DependencyMask.NONE) {
                    if (
                        (productResourceDetails.applyDependencyMask == DependencyMask.ARRIVAL || productResourceDetails.applyDependencyMask == DependencyMask.ARRIVAL_DEPARTURE) &&
                        DateUtil.isDateDayTheSame(day.date, reservationStartDate)
                    ) {
                        return true;
                    } else if (
                        (productResourceDetails.applyDependencyMask == DependencyMask.DEPARTURE || productResourceDetails.applyDependencyMask == DependencyMask.ARRIVAL_DEPARTURE) &&
                        DateUtil.isDateDayTheSame(day.date, reservationEndDate)
                    ) {
                        return true;
                    }
                    return false;
                }
                return true;
            });
    }

    private getSubjectsWithUpdatedMaxQuantities(productSubjects: ProductSubjectSelection[]) {
        const { dynamicFilter } = this.props;
        let subjectsInReservation = 0;
        if (dynamicFilter.subject?.size) {
            dynamicFilter.subject.forEach((subjQuantity: number) => (subjectsInReservation += subjQuantity));
        }
        const productSubjectCount = productSubjects.map((product) => product.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        const allowedIncrease = (subjectsInReservation || 999) - productSubjectCount;
        return productSubjects.map((product) => ({ ...product, maxQuantity: product.quantity + allowedIncrease }));
    }
}
