import { LocalizedCardContent, LocalizedSpecialCardContent, Site } from "@maxxton/cms-api";

import { CurrentLocale } from "../../../app.types";
import { getLocalizedContent } from "../../../utils/localizedContent.util";

export const getLocalizedCardContent = ({
    site,
    localizedCardContents,
    currentLocale,
}: {
    site: Site;
    localizedCardContents: LocalizedCardContent[];
    currentLocale: CurrentLocale;
}): LocalizedCardContent => {
    const getLocalizedCardContentByKey = <Key extends keyof LocalizedCardContent>(key: Key): LocalizedCardContent | null =>
        getLocalizedContent({ site, currentLocale, localizedContent: localizedCardContents, keys: [key] });

    return {
        content: getLocalizedCardContentByKey("content")?.content,
        cardTitle: getLocalizedCardContentByKey("cardTitle")?.cardTitle,
        cardImage: getLocalizedCardContentByKey("cardImage")?.cardImage,
        cardSubTitle: getLocalizedCardContentByKey("cardSubTitle")?.cardSubTitle,
        location: getLocalizedCardContentByKey("location")?.location,
        startDate: getLocalizedCardContentByKey("startDate")?.startDate,
        endDate: getLocalizedCardContentByKey("endDate")?.endDate,
        cardAlt: getLocalizedCardContentByKey("cardAlt")?.cardAlt,
        cardDescription: getLocalizedCardContentByKey("cardDescription")?.cardDescription,
        richTextDesc: getLocalizedCardContentByKey("richTextDesc")?.richTextDesc,
        cardButton: getLocalizedCardContentByKey("cardButton")?.cardButton,
        externalLink: getLocalizedCardContentByKey("externalLink")?.externalLink,
        clickOnCard: getLocalizedCardContentByKey("clickOnCard")?.clickOnCard,
        removeOverlayFromImage: getLocalizedCardContentByKey("removeOverlayFromImage")?.removeOverlayFromImage,
        siteId: getLocalizedCardContentByKey("siteId")?.siteId,
        pageId: getLocalizedCardContentByKey("pageId")?.pageId,
        isSitePageUrl: getLocalizedCardContentByKey("isSitePageUrl")?.isSitePageUrl,
        openNewTab: getLocalizedCardContentByKey("openNewTab")?.openNewTab,
        noFollowLink: getLocalizedCardContentByKey("noFollowLink")?.noFollowLink,
        locale: getLocalizedCardContentByKey("locale")?.locale || site.locale._id,
    };
};

export const getLocalizedSpecialCardContent = ({
    site,
    localizedSpecialCardContents,
    currentLocale,
}: {
    site: Site;
    localizedSpecialCardContents: LocalizedSpecialCardContent[];
    currentLocale: CurrentLocale;
}): LocalizedSpecialCardContent => {
    const getLocalizedSpecialCardContentByKey = <Key extends keyof LocalizedSpecialCardContent>(key: Key): LocalizedSpecialCardContent | null =>
        getLocalizedContent({ site, currentLocale, localizedContent: localizedSpecialCardContents, keys: [key] });

    return {
        cardSpecialTitle: getLocalizedSpecialCardContentByKey("cardSpecialTitle")?.cardSpecialTitle,
        cardSpecialPrice: getLocalizedSpecialCardContentByKey("cardSpecialPrice")?.cardSpecialPrice,
        cardSpecialDiscount: getLocalizedSpecialCardContentByKey("cardSpecialDiscount")?.cardSpecialDiscount,
        cardOriginalPrice: getLocalizedSpecialCardContentByKey("cardOriginalPrice")?.cardOriginalPrice,
        rentability: getLocalizedSpecialCardContentByKey("rentability")?.rentability,
        addRequestParams: getLocalizedSpecialCardContentByKey("addRequestParams")?.addRequestParams,
        addStartDate: getLocalizedSpecialCardContentByKey("addStartDate")?.addStartDate,
        startDate: getLocalizedSpecialCardContentByKey("startDate")?.startDate,
        addEndDate: getLocalizedSpecialCardContentByKey("addEndDate")?.addEndDate,
        endDate: getLocalizedSpecialCardContentByKey("endDate")?.endDate,
        stay: getLocalizedSpecialCardContentByKey("stay")?.stay,
        amenities: getLocalizedSpecialCardContentByKey("amenities")?.amenities,
        resort: getLocalizedSpecialCardContentByKey("resort")?.resort,
        resourceId: getLocalizedSpecialCardContentByKey("resourceId")?.resourceId,
        specialCode: getLocalizedSpecialCardContentByKey("specialCode")?.specialCode,
        locale: getLocalizedSpecialCardContentByKey("locale")?.locale || site.locale._id,
    };
};
