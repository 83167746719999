import { CurrentLocale } from "../app.types";
import { isClientSide } from "./generic.util";

export interface GlobalFields {
    nonExistingStateUuid?: string;
    isReservationStateStored?: boolean;
    currentLocale?: CurrentLocale;
    landingPageUrl?: string;
    pageLoadTillTypeSearchPopulatedTime?: number;
}

/**
 * Use this instead of window.yourVariable
 * This will make sure we have an overview of all the fields we store in the window object.
 * WARNING: Use this as a last resort. We want as little data on the window object as possible.
 */
export function getGlobalFields(): GlobalFields {
    if (typeof window === "undefined") {
        return {};
    }
    if (!(window as any).__mcms) {
        (window as any).__mcms = {};
    }
    return (window as any).__mcms as GlobalFields;
}

export function isGlobalFieldsAvailable(): boolean {
    return isClientSide();
}

/**
 * You can land on krim.nl/some-page and then navigate to krim.nl/prijzen-en-beschikbaarheid using page links(it will behave as a single-page-application).
 * With this method you can check if you are still on your original landing page or if you have navigated to a differnet path.
 */
export function isOnLandingPage() {
    return getGlobalFields().landingPageUrl === window.location.pathname + window.location.search;
}
