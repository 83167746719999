// eslint-enable
export const freeSearchData = [
    {
        amenityCategory: "Region",
        amenityName: "Kaanapali",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "256003",
        amenityIdLive: "256003",
        amenitySearchString: "kaanapali, lahaina",
        regionIdAcc: "6001",
        regionIdLive: "6001",
        icon: "map-marker",
        nrOfResults: "141",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Kihei",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "256003",
        amenityIdLive: "256003",
        amenitySearchString: "kiehei",
        regionIdAcc: "6002",
        regionIdLive: "6002",
        icon: "map-marker",
        nrOfResults: "141",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Oahu",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006",
        amenityIdLive: "256006",
        amenitySearchString: "oahu, the gathering place",
        icon: "map-marker",
        regionIdAcc: "4003",
        regionIdLive: "4002",
        nrOfResults: "111",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Waikiki",
        amenitySubtitle: "Waikiki, Honolulu, Oahu, Hawaii",
        amenityIdAcc: "256006",
        amenityIdLive: "256006",
        amenitySearchString: "waikiki, honolulu",
        regionIdAcc: "6003",
        regionIdLive: "6003",
        icon: "map-marker",
        nrOfResults: "111",
        isDefault: "1",
    },
    {
        amenityCategory: "Region",
        amenityName: "Maui",
        amenitySubtitle: "Maui, Hawaii",
        amenityIdAcc: "256003",
        amenityIdLive: "256003",
        amenitySearchString: "maui, the valley isle",
        regionIdAcc: "4006",
        regionIdLive: "4004",
        icon: "map-marker",
        nrOfResults: "141",
        isDefault: "1",
    },
    {
        amenityCategory: "Region",
        amenityName: "Kauai",
        amenitySubtitle: "Kauai, Hawaii",
        amenityIdAcc: "256002",
        amenityIdLive: "256002",
        regionIdAcc: "4004",
        regionIdLive: "4001",
        amenitySearchString: "kauai, garden isle",
        icon: "map-marker",
        nrOfResults: "115",
        isDefault: "1",
    },
    {
        amenityCategory: "Region",
        amenityName: "Lihue",
        amenitySubtitle: "Lihue, Kauai, Hawaii",
        amenityIdAcc: "256002",
        amenityIdLive: "256002",
        amenitySearchString: "lihue",
        regionIdAcc: "6004",
        regionIdLive: "6004",
        icon: "map-marker",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Poipu",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "256002",
        amenityIdLive: "256002",
        regionIdAcc: "6005",
        regionIdLive: "6005",
        amenitySearchString: "poipu, koloa",
        icon: "map-marker",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Kapaa",
        amenitySubtitle: "Kapaa, Kauai, Hawaii",
        amenityIdAcc: "256002",
        amenityIdLive: "256002",
        amenitySearchString: "kapaa",
        regionIdAcc: "6006",
        regionIdLive: "6006",
        icon: "map-marker",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Big Island",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001",
        amenityIdLive: "256001",
        amenitySearchString: "big island, island of hawaii, hawaii island",
        regionIdAcc: "4002",
        regionIdLive: "2002",
        icon: "map-marker",
        nrOfResults: "132",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Kona",
        amenitySubtitle: "Kailua-Kona, Big Island, Hawaii",
        amenityIdAcc: "256001",
        amenityIdLive: "256001",
        amenitySearchString: "kona, kailuakona",
        regionIdAcc: "6007",
        regionIdLive: "6007",
        icon: "map-marker",
        nrOfResults: "132",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Waikoloa",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001",
        amenityIdLive: "256001",
        amenitySearchString: "waikoloa",
        regionIdAcc: "6008",
        regionIdLive: "6008",
        icon: "map-marker",
        nrOfResults: "132",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Naalehu",
        amenitySubtitle: "Naalehu, Big Island, Hawaii",
        amenityIdAcc: "256001",
        amenityIdLive: "256001",
        amenitySearchString: "naalehu",
        regionIdAcc: "10001",
        regionIdLive: "10001",
        icon: "map-marker",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Region",
        amenityName: "Molokai",
        amenitySubtitle: "Molokai, Hawaii",
        amenityIdAcc: "256004",
        amenityIdLive: "256004",
        amenitySearchString: "molokai, kaunakakai, the friendly isle",
        regionIdAcc: "4005",
        regionIdLive: "4003",
        icon: "map-marker",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Waikiki Shore",
        amenitySubtitle: "Waikiki, Honolulu, Oahu, Hawaii",
        amenityIdAcc: "1639769",
        amenityIdLive: "1639769",
        amenitySearchString: "castle, waikiki shore, honolulu, oahu, hawaii, kalia road, 2161",
        icon: "building-o",
        nrOfResults: "63",
        isDefault: "1",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Waikiki Grand",
        amenitySubtitle: "Waikiki, Honolulu, Oahu, Hawaii",
        amenityIdAcc: "1639770",
        amenityIdLive: "1639770",
        amenitySearchString: "castle, waikiki grand, honolulu, Oahu, hawaii, kapahulu avenue, 134",
        icon: "building-o",
        nrOfResults: "48",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Kamaole Sands",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "1640010",
        amenityIdLive: "1640010",
        amenitySearchString: "castle, kamaole sands, maui, hawaii, south kihei road, kihei road, 2695",
        icon: "building-o",
        nrOfResults: "109",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Paki Maui",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "1640009",
        amenityIdLive: "1640009",
        amenitySearchString: "castle, paki maui, kaanapali, lahaina, maui, hawaii,  lower honoapiilani, 3615",
        icon: "building-o",
        nrOfResults: "29",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Poipu Shores",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "1640007",
        amenityIdLive: "1640007",
        amenitySearchString: "castle, poipu shores, kauai, hawaii, pee road, koloa, 1775",
        icon: "building-o",
        nrOfResults: "15",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Kiahuna Plantation Resort",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "1640005",
        amenityIdLive: "1640005",
        amenitySearchString: "castle, kiahuna plantation, kiahuna resort, poipu, kauai, hawaii, koloa",
        icon: "building-o",
        nrOfResults: "57",
        isDefault: "1",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Makahuena at Poipu",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "1640006",
        amenityIdLive: "1640006",
        amenitySearchString: "castle, makaheuena at poipu, poipu, kauai, hawaii, pee road, koloa, 1661",
        icon: "building-o",
        nrOfResults: "4",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Maui Banyan",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "420001",
        amenityIdLive: "420001",
        amenitySearchString: "castle, maui banyan, kihei, maui",
        icon: "building-o",
        nrOfResults: "29",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Palms Waikiki",
        amenitySubtitle: "Waikiki, Honolulu, Oahu, Hawaii",
        amenityIdAcc: "1894013",
        amenityIdLive: "1894013",
        amenitySearchString: "castle, palms, palms waikiki, waikiki, honolulu, hawaii, oahu",
        icon: "building-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Palm Villas at Mauna Lani",
        amenitySubtitle: "Island of Hawaii, Hawaii",
        amenityIdAcc: "1918002",
        amenityIdLive: "1918002",
        amenitySearchString: "castle, palm villas, palm villas at mauna lani, mauna lani, big island, island of hawaii, hawaii",
        icon: "building-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Vacation homes Naalehu",
        amenitySubtitle: "Island of Hawaii, Hawaii",
        amenityIdAcc: "402001",
        amenityIdLive: "402001",
        amenitySearchString: "castle, naalehu, vacation homes, hawaii",
        icon: "building-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Kaha Lani Resort",
        amenitySubtitle: "Lihue, Kauai, Hawaii",
        amenityIdAcc: "1640003",
        amenityIdLive: "1640003",
        amenitySearchString: "castle, kaha lani resort, lihue, kauai, hawaii, nehe road, 4460",
        icon: "building-o",
        nrOfResults: "29",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Kauai Kailani",
        amenitySubtitle: "Kapaa, Kauai, Hawaii",
        amenityIdAcc: "1640004",
        amenityIdLive: "1640004",
        amenitySearchString: "castle, kauai kailani, kapaa, kauai, hawaii, kuhio highway, 4856, 4-856",
        icon: "building-o",
        nrOfResults: "10",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Molokai Resort",
        amenitySubtitle: "Molokai, Hawaii",
        amenityIdAcc: "1640008",
        amenityIdLive: "1640008",
        amenitySearchString: "castle, molokai resort, kamehameha highway, kaunakakai",
        icon: "building-o",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Kanaloa at Kona",
        amenitySubtitle: "Kailua-Kona, Big Island, Hawaii",
        amenityIdAcc: "1640726",
        amenityIdLive: "1640726",
        amenitySearchString: "castle, kanaloa at kona, kailua, kona, big island, island of hawaii, hawaii island, manukai street, 78261, 78-261",
        icon: "building-o",
        nrOfResults: "46",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Kona Bali Kai",
        amenitySubtitle: "Kailua-Kona, Big Island, Hawaii",
        amenityIdAcc: "1640002",
        amenityIdLive: "1640002",
        amenitySearchString: "castle, kona bali kai, kailua, big island, island of hawaii, hawaii island, alii drive, 76-6246, 766246",
        icon: "building-o",
        nrOfResults: "42",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Kona Reef",
        amenitySubtitle: "Kailua-Kona, Big Island, Hawaii",
        amenityIdAcc: "1640001",
        amenityIdLive: "1640001",
        amenitySearchString: "castle, kona reef, big island, kailua, island of hawaii, hawaii Island, alii dirive, 756-5888, 755888",
        icon: "building-o",
        nrOfResults: "7",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Hali'i Kai at Waikoloa",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "1639768",
        amenityIdLive: "1639768",
        amenitySearchString: "castle, halii kai at waikoloa, waikoloa village, big island, island of hawaii, hawaii island, nawahine pl, 69-1029, 691029",
        icon: "building-o",
        nrOfResults: "37",
        isDefault: "1",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Polynesian Shores",
        amenitySubtitle: "Lahaina, Maui, Hawaii",
        amenityIdAcc: "1640011",
        amenityIdLive: "1640011",
        amenitySearchString: "castle, polynesian shores, lahaina, big island, island of hawaii, hawaii island, lower hnoapiilani road, 3975",
        icon: "building-o",
        nrOfResults: "3",
        isDefault: "0",
    },
    {
        amenityCategory: "Resort",
        amenityName: "Golf Villas at Mauna lani",
        amenitySubtitle: "Island of Hawaii, Hawaii",
        amenityIdAcc: "428001",
        amenityIdLive: "428001",
        amenitySearchString: "castle, golf, golf villas, kohala, south kohana, big island, kona, oahu",
        icon: "building-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630002",
        amenityIdLive: "1630002",
        amenitySearchString: "hawaii, beach front, ocean front, beach house",
        icon: "sun-o",
        nrOfResults: "139",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,1630002",
        amenityIdLive: "256006,1630002",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "63",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,1630002",
        amenityIdLive: "256006,1630002",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "63",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Kauai, Hawaii",
        amenityIdAcc: "256002,1630002",
        amenityIdLive: "256002,1630002",
        amenitySearchString: "kauai",
        icon: "sun-o",
        nrOfResults: "57",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "256002,1630002",
        amenityIdLive: "256002,1630002",
        amenitySearchString: "poipu",
        icon: "sun-o",
        nrOfResults: "57",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Kapaa, Kauai, Hawaii",
        amenityIdAcc: "256002,1630002",
        amenityIdLive: "256002,1630002",
        amenitySearchString: "kapaa",
        icon: "sun-o",
        nrOfResults: "57",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Lihue, Kauai, Hawaii",
        amenityIdAcc: "256002,1630002",
        amenityIdLive: "256002,1630002",
        amenitySearchString: "lihue",
        icon: "sun-o",
        nrOfResults: "57",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Beachfront",
        amenitySubtitle: "Molokai, Hawaii",
        amenityIdAcc: "256004,1630002",
        amenityIdLive: "256004,1630002",
        amenitySearchString: "Molokai",
        icon: "sun-o",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "304003",
        amenityIdLive: "304003",
        amenitySearchString: "hawaii, family friendly, kids friendly, ohana",
        icon: "sun-o",
        nrOfResults: "404",
        isDefault: "1",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Maui, Hawaii",
        amenityIdAcc: "256003,304003",
        amenityIdLive: "256003,304003",
        amenitySearchString: "maui",
        icon: "sun-o",
        nrOfResults: "141",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "256003,304003",
        amenityIdLive: "256003,304003",
        amenitySearchString: "kihei",
        icon: "sun-o",
        nrOfResults: "141",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "256003,304003",
        amenityIdLive: "256003,304003",
        amenitySearchString: "kaanapali, kanapali, lahaina",
        icon: "sun-o",
        nrOfResults: "141",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,304003",
        amenityIdLive: "256006,304003",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "39",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,304003",
        amenityIdLive: "256006,304003",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "39",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Kauai, Hawaii",
        amenityIdAcc: "256002,304003",
        amenityIdLive: "256002,304003",
        amenitySearchString: "kauai",
        icon: "sun-o",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "256002,304003",
        amenityIdLive: "256002,304003",
        amenitySearchString: "poipu",
        icon: "sun-o",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Kapaa, Kauai, Hawaii",
        amenityIdAcc: "256002,304003",
        amenityIdLive: "256002,304003",
        amenitySearchString: "kapaa",
        icon: "sun-o",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Lihue, Kauai, Hawaii",
        amenityIdAcc: "256002,304003",
        amenityIdLive: "256002,304003",
        amenitySearchString: "lihue",
        icon: "sun-o",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Molokai, Hawaii",
        amenityIdAcc: "256004,304003",
        amenityIdLive: "256004,304003",
        amenitySearchString: "Molokai",
        icon: "sun-o",
        nrOfResults: "12",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,304003",
        amenityIdLive: "256001,304003",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "97",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,304003",
        amenityIdLive: "256001,304003",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "97",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Family Friendly",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,304003",
        amenityIdLive: "256001,304003",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "97",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "180001",
        amenityIdLive: "180001",
        amenitySearchString: "hawaii, ocean front, beach front, beach house",
        icon: "sun-o",
        nrOfResults: "332",
        isDefault: "1",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Maui, Hawaii",
        amenityIdAcc: "256003,180001",
        amenityIdLive: "256003,180001",
        amenitySearchString: "maui",
        icon: "sun-o",
        nrOfResults: "37",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "256003,180001",
        amenityIdLive: "256003,180001",
        amenitySearchString: "kihei",
        icon: "sun-o",
        nrOfResults: "37",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "256003,180001",
        amenityIdLive: "256003,180001",
        amenitySearchString: "kaanapali, kanapali, lahaina",
        icon: "sun-o",
        nrOfResults: "37",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,180001",
        amenityIdLive: "256006,180001",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "63",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,180001",
        amenityIdLive: "256006,180001",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "63",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Kauai, Hawaii",
        amenityIdAcc: "256002,180001",
        amenityIdLive: "256002,180001",
        amenitySearchString: "kauai",
        icon: "sun-o",
        nrOfResults: "105",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "256002,180001",
        amenityIdLive: "256002,180001",
        amenitySearchString: "poipu",
        icon: "sun-o",
        nrOfResults: "105",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Kapaa, Kauai, Hawaii",
        amenityIdAcc: "256002,180001",
        amenityIdLive: "256002,180001",
        amenitySearchString: "kapaa",
        icon: "sun-o",
        nrOfResults: "105",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Lihue, Kauai, Hawaii",
        amenityIdAcc: "256002,180001",
        amenityIdLive: "256002,180001",
        amenitySearchString: "lihue",
        icon: "sun-o",
        nrOfResults: "105",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Molokai, Hawaii",
        amenityIdAcc: "256004,180001",
        amenityIdLive: "256004,180001",
        amenitySearchString: "Molokai",
        icon: "sun-o",
        nrOfResults: "12",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,180001",
        amenityIdLive: "256001,180001",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,180001",
        amenityIdLive: "256001,180001",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Oceanfront",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,180001",
        amenityIdLive: "256001,180001",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "115",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630009",
        amenityIdLive: "1630009",
        amenitySearchString: "hawaii, romance, honeymoon, romantic",
        icon: "sun-o",
        nrOfResults: "189",
        isDefault: "1",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Maui, Hawaii",
        amenityIdAcc: "256003,1630009",
        amenityIdLive: "256003,1630009",
        amenitySearchString: "maui",
        icon: "sun-o",
        nrOfResults: "84",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "256003,1630009",
        amenityIdLive: "256003,1630009",
        amenitySearchString: "kihei",
        icon: "sun-o",
        nrOfResults: "84",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "256003,1630009",
        amenityIdLive: "256003,1630009",
        amenitySearchString: "kaanapali, kanapali, lahaina",
        icon: "sun-o",
        nrOfResults: "84",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,1630009",
        amenityIdLive: "256006,1630009",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "32",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,1630009",
        amenityIdLive: "256006,1630009",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "32",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Kauai, Hawaii",
        amenityIdAcc: "256002,1630009",
        amenityIdLive: "256002,1630009",
        amenitySearchString: "kauai",
        icon: "sun-o",
        nrOfResults: "53",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "256002,1630009",
        amenityIdLive: "256002,1630009",
        amenitySearchString: "poipu",
        icon: "sun-o",
        nrOfResults: "53",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Kapaa, Kauai, Hawaii",
        amenityIdAcc: "256002,1630009",
        amenityIdLive: "256002,1630009",
        amenitySearchString: "kapaa",
        icon: "sun-o",
        nrOfResults: "53",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Lihue, Kauai, Hawaii",
        amenityIdAcc: "256002,1630009",
        amenityIdLive: "256002,1630009",
        amenitySearchString: "lihue",
        icon: "sun-o",
        nrOfResults: "53",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Molokai, Hawaii",
        amenityIdAcc: "256004,1630009",
        amenityIdLive: "256004,1630009",
        amenitySearchString: "Molokai",
        icon: "sun-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,1630009",
        amenityIdLive: "256001,1630009",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,1630009",
        amenityIdLive: "256001,1630009",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Romance",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,1630009",
        amenityIdLive: "256001,1630009",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630004",
        amenityIdLive: "1630004",
        amenitySearchString: "hawaii, luxury, exclusive",
        icon: "sun-o",
        nrOfResults: "15",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Maui, Hawaii",
        amenityIdAcc: "256003,1630004",
        amenityIdLive: "256003,1630004",
        amenitySearchString: "maui",
        icon: "sun-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "256003,1630004",
        amenityIdLive: "256003,1630004",
        amenitySearchString: "kihei",
        icon: "sun-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "256003,1630004",
        amenityIdLive: "256003,1630004",
        amenitySearchString: "kaanapali, kanapali, lahaina",
        icon: "sun-o",
        nrOfResults: "1",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,1630004",
        amenityIdLive: "256006,1630004",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "4",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,1630004",
        amenityIdLive: "256006,1630004",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "4",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,1630004",
        amenityIdLive: "256001,1630004",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "10",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,1630004",
        amenityIdLive: "256001,1630004",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "10",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Luxury",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,1630004",
        amenityIdLive: "256001,1630004",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "10",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Off The Grid",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630007",
        amenityIdLive: "1630007",
        amenitySearchString: "hawaii, off the grid, secluded, remote",
        icon: "sun-o",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Off The Grid",
        amenitySubtitle: "Molokai, Hawaii",
        amenityIdAcc: "256004,1630007",
        amenityIdLive: "256004,1630007",
        amenitySearchString: "Molokai",
        icon: "sun-o",
        nrOfResults: "19",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Budget",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630011",
        amenityIdLive: "1630011",
        amenitySearchString: "hawaii, budget, cheap, affordable",
        icon: "sun-o",
        nrOfResults: "97",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Budget",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,1630011",
        amenityIdLive: "256006,1630011",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "48",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Budget",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,1630011",
        amenityIdLive: "256006,1630011",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "48",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Budget",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,1630011",
        amenityIdLive: "256001,1630011",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "49",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Budget",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,1630011",
        amenityIdLive: "256001,1630011",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "49",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Budget",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,1630011",
        amenityIdLive: "256001,1630011",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "49",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "LGBTQ+",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630006",
        amenityIdLive: "1630006",
        amenitySearchString: "hawaii, lgbtttqqiaa",
        icon: "sun-o",
        nrOfResults: "48",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "LGBTQ+",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,1630006",
        amenityIdLive: "256006,1630006",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "48",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "LGBTQ+",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,1630006",
        amenityIdLive: "256006,1630006",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "48",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630010",
        amenityIdLive: "1630010",
        amenitySearchString: "hawaii, nightlife, party",
        icon: "sun-o",
        nrOfResults: "256",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Maui, Hawaii",
        amenityIdAcc: "256003,1630010",
        amenityIdLive: "256003,1630010",
        amenitySearchString: "maui",
        icon: "sun-o",
        nrOfResults: "138",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "256003,1630010",
        amenityIdLive: "256003,1630010",
        amenitySearchString: "kihei",
        icon: "sun-o",
        nrOfResults: "138",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "256003,1630010",
        amenityIdLive: "256003,1630010",
        amenitySearchString: "kaanapali, kanapali, lahaina",
        icon: "sun-o",
        nrOfResults: "138",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,1630010",
        amenityIdLive: "256006,1630010",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "111",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,1630010",
        amenityIdLive: "256006,1630010",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "111",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,1630010",
        amenityIdLive: "256001,1630010",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "7",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,1630010",
        amenityIdLive: "256001,1630010",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "7",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Nightlife",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,1630010",
        amenityIdLive: "256001,1630010",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "7",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630003",
        amenityIdLive: "1630003",
        amenitySearchString: "hawaii, active, fitness, health",
        icon: "sun-o",
        nrOfResults: "404",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Maui, Hawaii",
        amenityIdAcc: "256003,1630003",
        amenityIdLive: "256003,1630003",
        amenitySearchString: "maui",
        icon: "sun-o",
        nrOfResults: "109",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Kihei, Maui, Hawaii",
        amenityIdAcc: "256003,1630003",
        amenityIdLive: "256003,1630003",
        amenitySearchString: "kihei",
        icon: "sun-o",
        nrOfResults: "109",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Kaanapali, Maui, Hawaii",
        amenityIdAcc: "256003,1630003",
        amenityIdLive: "256003,1630003",
        amenitySearchString: "kaanapali, kanapali, lahaina",
        icon: "sun-o",
        nrOfResults: "109",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Oahu, Hawaii",
        amenityIdAcc: "256006,1630003",
        amenityIdLive: "256006,1630003",
        amenitySearchString: "oahu",
        icon: "sun-o",
        nrOfResults: "111",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Waikiki, Oahu, Hawaii",
        amenityIdAcc: "256006,1630003",
        amenityIdLive: "256006,1630003",
        amenitySearchString: "waikiki, honolulu",
        icon: "sun-o",
        nrOfResults: "111",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Kauai, Hawaii",
        amenityIdAcc: "256002,1630003",
        amenityIdLive: "256002,1630003",
        amenitySearchString: "kauai",
        icon: "sun-o",
        nrOfResults: "52",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Poipu, Kauai, Hawaii",
        amenityIdAcc: "256002,1630003",
        amenityIdLive: "256002,1630003",
        amenitySearchString: "poipu",
        icon: "sun-o",
        nrOfResults: "52",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Kapaa, Kauai, Hawaii",
        amenityIdAcc: "256002,1630003",
        amenityIdLive: "256002,1630003",
        amenitySearchString: "kapaa",
        icon: "sun-o",
        nrOfResults: "52",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Lihue, Kauai, Hawaii",
        amenityIdAcc: "256002,1630003",
        amenityIdLive: "256002,1630003",
        amenitySearchString: "lihue",
        icon: "sun-o",
        nrOfResults: "52",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,1630003",
        amenityIdLive: "256001,1630003",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "132",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,1630003",
        amenityIdLive: "256001,1630003",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "132",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Active",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,1630003",
        amenityIdLive: "256001,1630003",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "132",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Golf",
        amenitySubtitle: "Hawaii",
        amenityIdAcc: "1630005",
        amenityIdLive: "1630005",
        amenitySearchString: "hawaii, golf, golf course",
        icon: "sun-o",
        nrOfResults: "83",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Golf",
        amenitySubtitle: "Big Island, Hawaii",
        amenityIdAcc: "256001,1630005",
        amenityIdLive: "256001,1630005",
        amenitySearchString: "big island, island of hawaii",
        icon: "sun-o",
        nrOfResults: "83",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Golf",
        amenitySubtitle: "Kona, Big Island, Hawaii",
        amenityIdAcc: "256001,1630005",
        amenityIdLive: "256001,1630005",
        amenitySearchString: "kona, kailua",
        icon: "sun-o",
        nrOfResults: "83",
        isDefault: "0",
    },
    {
        amenityCategory: "Vacation Idea",
        amenityName: "Golf",
        amenitySubtitle: "Waikoloa, Big Island, Hawaii",
        amenityIdAcc: "256001,1630005",
        amenityIdLive: "256001,1630005",
        amenitySearchString: "waikoloa",
        icon: "sun-o",
        nrOfResults: "83",
        isDefault: "0",
    },
];
// eslint-enable
