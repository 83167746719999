import * as fetch from "isomorphic-fetch";

import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { getGlobalFields } from "../../utils/globalFields";
import { isClientSide } from "../../utils/generic.util";

function getReduxStateEndpointUrl(key: string): string {
    const homepageUrl = new URL((window as any).pageUrl);
    const locationHostname = homepageUrl.host;
    const host = locationHostname !== location.host ? `https://${locationHostname}` : "";
    return `${host}/redux-state?uuid=${key}`;
}

export const ReduxRedisStorage = {
    getItem: (key: string): Promise<string | void> => {
        if (isClientSide() && getGlobalFields().nonExistingStateUuid && key.endsWith(getGlobalFields().nonExistingStateUuid!)) {
            // No need to try and fetch a stateUuid that has just been generated and isn't in redis yet..
            getGlobalFields().nonExistingStateUuid = undefined;
            return Promise.reject();
        }
        // TODO: for some reason the server instead of the client is calling this in certain cases.
        if (typeof localStorage !== "undefined") {
            const myEnvAuthToken = localStorage.getItem(LOCAL_STORAGE_KEYS.MY_ENV_TOKEN);
            const url = getReduxStateEndpointUrl(key);
            return fetch(url, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Headers": "X-Requested-With",
                    "Access-Control-Allow-Origin": "*",
                    "myEnvAuthToken": `${myEnvAuthToken}`,
                },
            }).then(async (result) => {
                if (!result.ok) {
                    throw new Error(`GET ${url} responded with status ${result.status} (${await result.text()})`);
                }
                return result.text();
            });
        }
        return Promise.resolve();
    },
    setItem: (key: string, item: string): Promise<Response | void> => {
        // TODO: for some reason the server instead of the client is calling this in certain cases.
        //  But there's no need to store the state if it was the server. Perhaps fix this in a cleaner way..
        if (typeof localStorage !== "undefined") {
            const body = JSON.parse(item);
            const url = getReduxStateEndpointUrl(key);
            const postPromise = fetch(url, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Headers": "X-Requested-With",
                    "Access-Control-Allow-Origin": "*",
                },
                body: item,
            }).then(async (resp) => {
                if (!resp.ok) {
                    throw new Error(`POST ${url} responded with status ${resp.status} (${await resp.text()})`);
                }
                return resp;
            });
            const reservation = body.reservationState && JSON.parse(body.reservationState).reservation;
            if (reservation) {
                postPromise.then(() => {
                    getGlobalFields().isReservationStateStored = true;
                });
            }
            return postPromise;
        }
        return Promise.resolve();
    },
    removeItem: (key: string): Promise<Response | void> => {
        const url = getReduxStateEndpointUrl(key);
        return fetch(url, {
            method: "DELETE",
            headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                "Access-Control-Allow-Origin": "*",
            },
        }).then(async (resp) => {
            if (!resp.ok) {
                throw new Error(`DELETE ${url} responded with status ${resp.status} (${await resp.text()})`);
            }
            return resp;
        });
    },
};
