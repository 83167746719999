import * as moment from "moment";

export const esLocaleSpecification: moment.LocaleSpecification = {
    calendar: {
        sameElse: "L",
    },
    longDateFormat: {
        LTS: "H:mm:ss",
        LT: "H:mm",
        L: "DD/MM/YYYY",
        LL: "D [de] MMMM [de] YYYY",
        LLL: "D [de] MMMM [de] YYYY H:mm",
        LLLL: "dddd, D [de] MMMM [de] YYYY H:mm",
    },
    invalidDate: "Fecha inválida",
    ordinal: () => "%d.",
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    relativeTime: {
        future: "en %s",
        past: "hace %s",
        s: "unos segundos",
        ss: "%d segundos",
        m: "un minuto",
        mm: "%d minutos",
        h: "una hora",
        hh: "%d horas",
        d: "un día",
        dd: "%d días",
        w: "una semana",
        ww: "%d semanas",
        M: "un mes",
        MM: "%d meses",
        y: "un año",
        yy: "%d años",
    },
    months: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    week: {
        dow: 1,
        doy: 4,
    },
    weekdays: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    weekdaysMin: ["do", "lu", "ma", "mi", "ju", "vi", "sá"],
    weekdaysShort: ["dom.", "lun.", "mar.", "mié.", "jue.", "vie.", "sáb."],
    monthsRegex: {},
    monthsShortRegex: {},
    monthsStrictRegex: {},
    monthsShortStrictRegex: {},
    monthsParse: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    longMonthsParse: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    shortMonthsParse: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    weekdaysParseExact: true,
    dayOfMonthOrdinalParseLenient: {},
};
