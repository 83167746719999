import { PageLockAction, PageLockActionType } from "../actions/pageLockAction";

import { ActionType } from "../actions";
import { Reducer } from "redux";

export interface PageLockState {
    lockTakenOverFrom?: string;
}

export const pageLockReducer: Reducer<PageLockState> = (state: PageLockState = {}, action: PageLockAction): PageLockState => {
    const actionType = action.actionType;
    switch (action.type) {
        case ActionType.PageLock:
            switch (actionType) {
                case PageLockActionType.takeOverLock:
                    return { ...state, ...action.payload };
            }
        default:
            return state;
    }
};
