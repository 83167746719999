import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import ContentLoader from "react-content-loader";

const ProgressBar = ({ isImage, infinite }: { isImage?: boolean; infinite?: boolean }) => {
    if (isImage) {
        return (
            <div className={"bg-img-wrap"}>
                <div className="single-img-slider">
                    <div className="inline-img-slider-wrap">
                        <section className="container-fluid filter-wrapper inline-img-slider types-popup-wrapper">
                            <a className="prev-arrow hover-effect">
                                <FontAwesome name="angle-left" />
                            </a>
                            <div className="image-scroller">
                                <div className="image-scroller-items">
                                    <div
                                        // eslint-disable-next-line max-len
                                        className={"card slide-content"}
                                    >
                                        <ContentLoader data-testid="content-loader" height={300} style={{ width: "100%" }} backgroundColor="#f8f8f8" speed={3} foregroundColor="rgba(15, 86, 120, 0.1)">
                                            <rect width="100%" rx="6" height="100%" />
                                        </ContentLoader>
                                    </div>
                                </div>
                            </div>
                            <a className="next-arrow hover-effect">
                                <FontAwesome name="angle-right" />
                            </a>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="progress-bar">
            <div className={`indeterminate${infinite ? " infinite" : ""}`} />
        </div>
    );
};

export default ProgressBar;
