import { ApiCallOptions, MxtsApi, PagedResult, Resort, Resource, Unit } from "@maxxton/cms-mxts-api";

import { DynamicParams } from "../server/controllers/page.controller";
import { LocaleAssets } from "@maxxton/cms-api";

export const DYNAMIC_SEO_OPTIONS = ["Location name", "Type name", "Unit name"];

export enum DynamicSeoFields {
    LOCATION_NAME = "Location name",
    TYPE_NAME = "Type name",
    UNIT_NAME = "Unit name",
}

export interface MetaData {
    [key: string]: PagedResult<Resource> | PagedResult<Unit> | PagedResult<Resort>;
}

export const getRequiredDynamicFields = (localizedField: Partial<LocaleAssets>): string[] => {
    let dynamicFields: string[] = [];
    for (const field in localizedField) {
        if (localizedField.hasOwnProperty(field)) {
            for (const option of DYNAMIC_SEO_OPTIONS) {
                if (localizedField[field as keyof LocaleAssets]?.includes(option) && !dynamicFields.includes(option)) {
                    dynamicFields = [...dynamicFields, option];
                }
            }
        }
    }
    return dynamicFields;
};

export const getMetaEndpoint = (env: ApiCallOptions, serverSideDynamicFilter: DynamicParams, field: string) => {
    const { resourceid, unitid, resortids, resortid } = serverSideDynamicFilter;
    if (field === DynamicSeoFields.TYPE_NAME && resourceid) {
        return MxtsApi.resources(env, { size: 1, resourceIds: [+resourceid] });
    } else if (field === DynamicSeoFields.UNIT_NAME && unitid) {
        return MxtsApi.units(env, { size: 1, unitIds: [+unitid] });
    } else if (field === DynamicSeoFields.LOCATION_NAME && (resortids || resortid)) {
        const selectedResort: number = resortid || (Array.isArray(resortids) && resortids?.length) ? Number(resortids?.[0]) : Number(resortids);
        if (selectedResort) {
            return MxtsApi.resorts(env, { size: 1, resortIds: [selectedResort] });
        }
    }
};

export const applyMetaTagsToField = (str: string, metaData: MetaData) => {
    DYNAMIC_SEO_OPTIONS?.forEach((dynamicField) => {
        if (str?.includes(dynamicField)) {
            const metaValue = metaData[dynamicField]?.content[0]?.name;
            if (metaValue) {
                str = str.replace(`${dynamicField} `, metaValue);
            }
        }
    });
    return str;
};
