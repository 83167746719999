import { Action } from "./";
import { PriceMatrixState } from "../reducers/priceMatrixReducer";

export enum PriceMatrixActionType {
    SHOW_MATRIX_WIDGET = "SHOW_MATRIX_WIDGET",
}

export interface PriceMatrixAction extends Action {
    actionType: PriceMatrixActionType;
    payload: PriceMatrixState;
}
