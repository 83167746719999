import { Action, ActionType } from "../";
import { AddOnsState, MarketingGroupWithAddOns } from "../../reducers/add-ons/addOnsReducer";

import { AddOn } from "@maxxton/cms-mxts-api";
import { BillType } from "../../reducers/billReducer";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { Action as ReduxAction } from "redux";
import { SelectedAddOn } from "../../reducers/add-ons/selectedAddOnsReducer";
import { SelectedAddOnsActionPayload } from "./selectedAddOnsAction";

export enum AddOnsActionType {
    FETCHING_ADD_ONS = "FETCHING_ADD_ONS",
    FETCH_ADD_ONS_SUCCESS = "FETCH_ADD_ONS_SUCCESS",
    FETCH_ADD_ONS_ERROR = "FETCH_ADD_ONS_ERROR",
}

export interface AddOnsReduxAction extends Action {
    type: ActionType.AddOns;
    actionType: AddOnsActionType;
    payload?: AddOnsState;
}

export const startFetchingAddOns = (): AddOnsReduxAction => ({
    type: ActionType.AddOns,
    actionType: AddOnsActionType.FETCHING_ADD_ONS,
});

export const fetchAddOnsSuccess = ({
    addOns,
    marketingGroupsWithAddOns,
    recommendedAddOns,
}: {
    addOns: AddOn[];
    marketingGroupsWithAddOns: MarketingGroupWithAddOns[];
    recommendedAddOns: AddOn[];
}): AddOnsReduxAction => ({
    type: ActionType.AddOns,
    actionType: AddOnsActionType.FETCH_ADD_ONS_SUCCESS,
    payload: {
        addOns,
        marketingGroupsWithAddOns,
        recommendedAddOns,
    },
});

export const fetchAddOnsError = (error: unknown): AddOnsReduxAction => ({
    type: ActionType.AddOns,
    actionType: AddOnsActionType.FETCH_ADD_ONS_ERROR,
    payload: {
        error,
    },
});

// Since Redux Saga takeEvery function watches for action.type, we need a separate SagaAction to prevent the saga from triggering unnecessarily.
export enum AddOnsSagaActionType {
    START_FETCH_ADD_ONS_SAGA = "START_FETCH_ADD_ONS_SAGA",
    START_UPDATE_SELECTED_ADD_ONS_SAGA = "START_UPDATE_SELECTED_ADD_ONS_SAGA",
    START_CLEAR_SELECTED_ADD_ONS_SAGA = "START_CLEAR_SELECTED_ADD_ONS_SAGA",
}

export interface RecommendedAddOns {
    showRecommendedAddOns?: boolean;
    recommendedAddOnsSize?: number;
    showColdStartsRecommendedAddOns?: boolean;
}

export interface AddOnsSagaAction extends ReduxAction<AddOnsSagaActionType> {
    cartReservedResourceId?: number;
    obtainMyEnvAddOns?: boolean;
    recommendedAddOns?: RecommendedAddOns;
    targetBill?: BillType;
    payload?: SelectedAddOnsActionPayload;
    cmsContext?: CMSProviderProperties;
}

export const startFetchAddOnsSaga = (obtainMyEnvAddOns?: boolean, recommendedAddOns?: RecommendedAddOns): AddOnsSagaAction => ({
    type: AddOnsSagaActionType.START_FETCH_ADD_ONS_SAGA,
    obtainMyEnvAddOns,
    recommendedAddOns,
});

export const startUpdateSelectedAddOnsSaga = ({
    updatedAddOn,
    cartReservedResourceId,
    targetBill,
    cmsContext,
}: {
    updatedAddOn: SelectedAddOn;
    cartReservedResourceId?: number;
    targetBill?: BillType;
    cmsContext?: CMSProviderProperties;
}): AddOnsSagaAction => ({
    type: AddOnsSagaActionType.START_UPDATE_SELECTED_ADD_ONS_SAGA,
    targetBill,
    cartReservedResourceId,
    payload: { updatedAddOn },
    cmsContext,
});

export const startClearSelectedAddOnsSaga = ({ cartReservedResourceId, targetBill }: { cartReservedResourceId?: number; targetBill?: BillType }): AddOnsSagaAction => ({
    type: AddOnsSagaActionType.START_CLEAR_SELECTED_ADD_ONS_SAGA,
    targetBill,
    cartReservedResourceId,
});
